import { Player } from "../entities/player"
import { StatType, StatOperator } from "../stats/stat-interfaces-enums"
import { SecondaryWeaponType, AllWeaponTypes } from "../weapons/weapon-types"
import { UpgradeCollections } from "./upgrade-definitions"
import { AffectedEntities } from './upgrade-entities'
import { AcidBottlesWeapon } from "../weapons/actual-weapons/secondary/acid-bottles-weapon"
import { LuteWeapon } from "../weapons/actual-weapons/secondary/lute-weapon"
import { FireFairiesWeapon } from "../weapons/actual-weapons/secondary/fire-fairies-weapon"
import { IgniteFieryPool } from "../entities/hazards/ignite-fiery-pool-hazard"
import { PhoenixBombs } from "../weapons/actual-weapons/secondary/phoenix-bomb-weapon"
import { ObjectPool } from "../utils/third-party/object-pool"

export const SECONDARY_WEAPON_UPGRADES: Partial<UpgradeCollections> = {
	'ratParadeUnlock': {
		name: 'Rat Parade Unlock',
		desc: '',
		shape: 'single',
		isSecondaryUnlock: true,
		top: {
			name: 'Unlock: Rat Parade',
			desc: `Release Rats in the direction you're facing that march in a line to attack enemies.`,
			icon: 'upgrade-unlock-rat-parade',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.RatParade)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.RatParade)
			},
			unlocks: ['ratParadeUpgrades'],
			affectedEntities: [AffectedEntities.RatParade]
		},
	},
	'ratParadeUpgrades': {
		name: 'Rat Parade Upgrades',
		desc: '',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Where your nightmares end...',
			desc: 'Rat Parade: Releases rats behind you as well',
			icon: 'upgrade-where-your-nightmares-end',
			binaryFlags: ['rat-parade-shoot-behind'],
			affectedEntities: [AffectedEntities.RatParade]
		},
		left: {
			name: "Tear 'em up!",
			desc: 'Rat Parade: Gain three more rats, and rats gain three {glossary.pierce}.',
			icon: 'upgrade-ankle-height-terror',
			statBonuses: [
				[['RatParade'], StatType.projectileCount, StatOperator.SUM, 3],
				[['RatParade'], StatType.attackPierceCount, StatOperator.SUM, 3]
			],
			affectedEntities: [AffectedEntities.RatParade]
		},
		right: {
			name: 'Fat Rat',
			desc: 'Rat Parade: Double in {glossary.attack_size} and greatly increase {glossary.damage}',
			icon: 'upgrade-fat-rat',
			statBonuses: [
				[['RatParade'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['RatParade'], StatType.attackSize, StatOperator.MULTIPLY, 1],
			],
			binaryFlags: ['rat-parade-fat-rat'],
			affectedEntities: [AffectedEntities.RatParade]
		},
		leftLeaf: {
			name: "Ankle-Height Terror",
			desc: 'Rat Parade: Rats now {glossary.pierce} everything in the way',
			icon: 'upgrade-tear-em-up',
			unlocks: ['ratParadeLeftUpgrades'],
			statBonuses: [
				[['RatParade'], StatType.attackPierceCount, StatOperator.SUM, 10_000],
			],
			affectedEntities: [AffectedEntities.RatParade]
		},
		middleLeaf: {
			name: "We're the Rats",
			desc: 'Rat Parade: Releases rats above and below you',
			icon: 'upgrade-we-are-the-rats',
			unlocks: ['ratParadeMiddleUpgrades'],
			binaryFlags: ['rat-parade-shoot-all-directions'],
			affectedEntities: [AffectedEntities.RatParade]
		},
		rightLeaf: {
			name: 'Educated Rodents',
			desc: 'Rat Parade: Rats carry bombs and explode for double damage',
			icon: 'upgrade-socrates-revenge',
			unlocks: ['ratParadeRightUpgrades'],
			binaryFlags: ['rat-parade-bombs'],
			affectedEntities: [AffectedEntities.RatParade]
		}
	},

	'ratParadeLeftUpgrades': {
		name: 'Ankle Height Terror Upgrades`',
		desc: 'Unlock an additional set of upgrades',
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Do those rats have knives?',
				desc: 'Rat Parade: Greatly increase {glossary.damage}',
				icon: 'upgrade-do-those-rats-have-knives',
				statBonuses: [
					[['RatParade'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
			{
				name: 'On a Wave of Mutilation',
				desc: 'Rat Parade: Rats {glossary.knockback} enemies',
				icon: 'upgrade-on-a-wave-of-mutilation',
				statBonuses: [
					[['RatParade'], StatType.attackKnockback, StatOperator.SUM, 300],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
			{
				name: 'A Horrible Night for a Curse', // can't change this name without change to unlock validator on griddle
				desc: 'Rat Parade: Add a new rat and moderately increase {glossary.damage}',
				icon: 'upgrade-a-horrible-night-for-a-curse',
				statBonuses: [
					[['RatParade'], StatType.projectileCount, StatOperator.SUM, 1],
					[['RatParade'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
		]
	},

	'ratParadeMiddleUpgrades': {
		name: 'We\'re the Rats Upgrades`',
		desc: 'Unlock an additional set of upgrades',
		shape: 'line',
		isUnlock: true,
		upgrades: [
			{
				name: 'They stalk at night',
				desc: 'Rat Parade: Adds a few new rats in each direction',
				icon: 'upgrade-they-stalk-at-night',
				statBonuses: [
					[['RatParade'], StatType.projectileCount, StatOperator.SUM, 3],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
			{
				name: 'Getting into Rat Trouble',
				desc: 'Rat Parade: Greatly increase {glossary.damage} of the rats',
				icon: 'upgrade-getting-into-rat-trouble',
				statBonuses: [
					[['RatParade'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
			{
				name: 'Rats are making all the rules', // can't change this name without change to unlock validator on griddle
				desc: 'Rat Parade: Increase {glossary.cooldown_speed} by 2 seconds',
				icon: 'upgrade-rats-are-making-all-the-rules',
				statBonuses: [
					[['RatParade'], StatType.cooldownInterval, StatOperator.SUM, -2_000],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
		]
	},

	'ratParadeRightUpgrades': {
		name: 'Educated Rodents Upgrades',
		desc: 'Unlock an additional set of upgrades',
		shape: 'line',
		isUnlock: true,
		upgrades: [
			{
				name: 'Pythagoras\' theorem... of Explosions',
				desc: 'Rat Parade: Massively increase {glossary.damage}',
				icon: 'upgrade-pythagoras-theorum-of-explosions',
				statBonuses: [
					[['RatParade'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.6],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
			{
				name: 'Aristotle\'s (lack of) Motion',
				desc: 'Rat Parade: Explosions briefly {glossary.stun} enemies',
				icon: 'upgrade-aristotles-lack-of-motion',
				binaryFlags: ['rat-parade-stun'],
				affectedEntities: [AffectedEntities.RatParade]
			},
			{
				name: 'Plato\'s Ideal', // can't change this name without change to unlock validator on griddle
				desc: 'Rat Parade: Increase explosion radius and moderately increase {glossary.damage}',
				icon: 'upgrade-platos-ideal',
				binaryFlags: ['rat-parade-bigger-explosions'],
				statBonuses: [
					[['RatParade'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				],
				affectedEntities: [AffectedEntities.RatParade]
			},
		]
	},


	'acidBottlesUnlock': {
		name: 'Acid Bottles Unlock',
		desc: '',
		shape: 'single',
		isSecondaryUnlock: true,
		top: {
			name: 'Unlock: Acid Bottles',
			desc: 'Throw acid bottles at the ground randomly that deal damage-over-time to enemies.',
			icon: 'upgrade-unlock-poison-bottles',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.AcidBottles)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.AcidBottles)
			},
			unlocks: ['acidBottlesUpgrades'],
			affectedEntities: [AffectedEntities.AcidBottles]
		},
	},
	'acidBottlesUpgrades': {
		name: 'Acid Bottle Upgrades',
		desc: '',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'A Better Acid',
			desc: 'Acid Bottles: Puddles gain one second of {glossary.skill_duration}, and do moderately more {glossary.damage}',
			icon: 'upgrade-a-better-poison',
			statBonuses: [
				[['AcidBottles'], StatType.skillDuration, StatOperator.SUM, 1],
				[['AcidBottles'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3]
			],
			affectedEntities: [AffectedEntities.AcidBottles]
		},
		left: {
			name: "Brewer's Choice",
			desc: 'Acid Bottles: Gain two more Acid Bottles, and you throw bottles further',
			icon: 'upgrade-brewers-choice',
			statBonuses: [
				[['AcidBottles'], StatType.projectileCount, StatOperator.SUM, 2],
				[['AcidBottles'], StatType.placementRange, StatOperator.SUM_THEN_MULTIPLY, 0.7]
			],
			affectedEntities: [AffectedEntities.AcidBottles]
		},
		right: {
			name: 'Accelerated Acid',
			desc: 'Acid Bottles: Increase {glossary.cooldown_speed}, and bottles land closer to you',
			icon: 'upgrade-a-very-personal-bottle-of-poison',
			statBonuses: [
				[['AcidBottles'], 'cooldownInterval', StatOperator.MULTIPLY, -0.2],
				[['AcidBottles'], 'placementRange', StatOperator.MULTIPLY, -0.4],
			],
			affectedEntities: [AffectedEntities.AcidBottles]
		},
		leftLeaf: {
			name: 'The Stuff That Really Lasts',
			desc: 'Acid Bottles: Gain three seconds of {glossary.skill_duration}. Enemies carry acid out of the circle with them, lasting the same duration they were in the acid',
			icon: 'upgrade-the-stuff-that-really-lasts',
			statBonuses: [
				[['AcidBottles'], StatType.skillDuration, StatOperator.SUM, 3],
			],
			simpleApplyFn(player) {
				const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
				if (bottles) {
					bottles.carryOverAcid = true
				}
			},
			simpleRemoveFn(player) {
				const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
				if (bottles) {
					bottles.carryOverAcid = false
				}
			},
			affectedEntities: [AffectedEntities.AcidBottles],
			unlocks: ['acidBottlesLeftUpgrades']
		},
		middleLeaf: {
			name: 'Better Living Through Acid',
			desc: 'Acid Bottles: Massively increase {glossary.attack_size}. While you stand in acid, you recharge the cooldown for Acid Bottles faster',
			icon: 'upgrade-better-living-through-poison',
			statBonuses: [
				[['AcidBottles'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.8],
			],
			simpleApplyFn(player) {
				const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
				if (bottles) {
					bottles.acidBottleRechargeInAcidSpeed += 0.25
				}
			},
			simpleRemoveFn(player) {
				const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
				if (bottles) {
					bottles.acidBottleRechargeInAcidSpeed -= 0.25
				}
			},
			affectedEntities: [AffectedEntities.AcidBottles],
			unlocks: ['acidBottlesMiddleUpgrades']
		},
		rightLeaf: {
			name: 'Soaking It In',
			desc: 'Acid Bottles: Greatly increase {glossary.attack_rate} and {glossary.damage}. Enemies standing in acid take significantly more damage from your primary weapon',
			icon: 'upgrade-soaking-it-in',
			statBonuses: [
				[['AcidBottles'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['AcidBottles'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.20],
			],
			simpleApplyFn(player) {
				const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
				if (bottles) {
					bottles.applyPrimaryWeaponDamageDebuff = true
				}
			},
			simpleRemoveFn(player) {
				const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
				if (bottles) {
					bottles.applyPrimaryWeaponDamageDebuff = false
				}
			},
			affectedEntities: [AffectedEntities.AcidBottles],
			unlocks: ['acidBottlesRightUpgrades']
		}
	},
	'acidBottlesLeftUpgrades': {
		name: "Lasting Acid Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Acidic Purification',
				desc: 'Acid Bottles: Deal greatly increased {glossary.damage}',
				icon: 'upgrade-acidic-purification',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4]
				]
			},
			{
				name: 'Extra Deep Vials',
				desc: 'Acid Bottles: Greatly increase {glossary.skill_duration}',
				icon: 'upgrade-extra-deep-vials',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.skillDuration, StatOperator.SUM, 3]
				]
			},
			{
				name: 'pH 0 Acid', // can't change this name without change to unlock validator on griddle
				desc: 'Acid Bottles: Throw two more bottles, and deal moderately increased {glossary.damage}',
				icon: 'upgrade-ph-0-acid',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.projectileCount, StatOperator.SUM, 2],
					[['AcidBottles'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				]
			},
		]
	},
	'acidBottlesMiddleUpgrades': {
		name: "Living Through Acid Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Anything but Basic',
				desc: 'Acid Bottles: Deal greatly increased {glossary.damage}',
				icon: 'upgrade-anything-but-basic',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4]
				]
			},
			{
				name: 'A Little More Coating',
				desc: 'Acid Bottles: Moderately increase {glossary.attack_size}',
				icon: 'upgrade-a-little-more-coating',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.25]
				]
			},
			{
				name: 'The Best of Living Through Acid', // can't change this name without change to unlock validator on griddle
				desc: 'Acid Bottles: Moderately increase {glossary.attack_size}, and Acid Bottle {glossary.cooldown_speed} is increased even more when standing in acid',
				icon: 'upgrade-the-best-of-living-through-acid',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.2]
				],
				simpleApplyFn(player) {
					const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
					if (bottles) {
						bottles.acidBottleRechargeInAcidSpeed += 0.25
					}
				},
				simpleRemoveFn(player) {
					const bottles = player.getWeapon(AllWeaponTypes.AcidBottles) as AcidBottlesWeapon
					if (bottles) {
						bottles.acidBottleRechargeInAcidSpeed -= 0.25
					}
				},
			},
		]
	},
	'acidBottlesRightUpgrades': {
		name: "Soaking It In Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Lye-ing it on Thick',
				desc: 'Acid Bottles: Deal greatly increased {glossary.damage}',
				icon: 'upgrade-lye-ing-it-on-thick',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4]
				]
			},
			{
				name: 'An Acid-ent Waiting to Happen',
				desc: 'Acid Bottles: Decrease {glossary.range}, and deal moderately more {glossary.damage}',
				icon: 'upgrade-an-acid-ent-waiting-to-happen',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.placementRange, StatOperator.MULTIPLY, -0.2],
					[['AcidBottles'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				]
			},
			{
				name: 'A Quick Reaction to a Good Burn', // can't change this name without change to unlock validator on griddle
				desc: 'Acid Bottles: Increase {glossary.cooldown_speed}',
				icon: 'upgrade-a-quick-reaction-to-a-good-burn',
				affectedEntities: [AffectedEntities.AcidBottles],
				statBonuses: [
					[['AcidBottles'], StatType.cooldownInterval, StatOperator.SUM, -1_500]
				]
			},
		]
	},

	'teslaCoilUnlock': {
		name: 'Tesla Coil Unlock',
		desc: '',
		shape: 'single',
		isSecondaryUnlock: true,
		top: {
			name: 'Unlock: Tesla Coil',
			desc: 'Place a Tesla Coil at your feet that zaps enemies with chain lightning.',
			icon: 'upgrade-unlock-tesla-coil',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.TeslaCoil)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.TeslaCoil)
			},
			unlocks: ['teslaCoilUpgrades'],
			affectedEntities: [AffectedEntities.TeslaCoil]
		},

	},

	'teslaCoilUpgrades': {
		name: 'Tesla Coil Upgrades',
		desc: '',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Multi-Facet Burst',
			desc: 'Tesla Coil: Gain one {glossary.split}',
			icon: 'upgrade-multi-facet-burst',
			statBonuses: [
				[['TeslaCoil'], 'projectileSplitCount', StatOperator.SUM, 1],
				[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.11],
			],
			affectedEntities: [AffectedEntities.TeslaCoil]
		},
		left: {
			name: 'Amped Up',
			desc: 'Tesla Coil: Gain one {glossary.chain}, and moderately increase tesla coil {glossary.damage}',
			icon: 'upgrade-amped-up',
			statBonuses: [
				[['TeslaCoil'], 'projectileChainCount', StatOperator.SUM, 1],
				[['TeslaCoil'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.11],
			],
			affectedEntities: [AffectedEntities.TeslaCoil]
		},
		right: {
			name: 'Linked Chains',
			desc: 'Tesla Coil: Attacks can {glossary.chain} back to the same target, gain two {glossary.chain}, and moderately increase {glossary.attack_rate}',
			icon: 'upgrade-linked-chains',
			statBonuses: [
				[['TeslaCoil'], StatType.projectileChainCount, StatOperator.SUM, 2],
				[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.11],
			],
			binaryFlags: ['chain-same-target'],
			affectedEntities: [AffectedEntities.TeslaCoil]
		},
		leftLeaf: {
			name: 'Rapid Engagement',
			desc: 'Tesla Coil: Greatly increase {glossary.cooldown_speed} and {glossary.damage}, and gain one {glossary.split}',
			icon: 'upgrade-rapid-engagement',
			statBonuses: [
				[['TeslaCoil'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.5],
				[['TeslaCoil'], StatType.projectileSplitCount, StatOperator.SUM, 1],
				[['TeslaCoil'], StatType.baseDamage, StatOperator.SUM, 7],
				[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.11],
			],
			affectedEntities: [AffectedEntities.TeslaCoil],
			unlocks: ['teslaCoilRapidExtras'],
		},
		middleLeaf: {
			name: 'Coil Tower',
			desc: 'Tesla Coil: Massively increases {glossary.attack_size}, greatly increases {glossary.skill_duration}, attacks {glossary.split} once more, and can {glossary.chain} further - but place coils less often',
			icon: 'upgrade-coil-tower',
			statBonuses: [
				[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.60],
				[['TeslaCoil'], StatType.projectileSplitCount, StatOperator.SUM, 1],
				[['TeslaCoil'], StatType.projectileChainDistanceMultiplier, StatOperator.SUM_THEN_MULTIPLY, 1.5],
				[['TeslaCoil'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 1.5],
				[['TeslaCoil'], StatType.cooldownInterval, StatOperator.SUM_THEN_MULTIPLY, 1.5],
			],
			affectedEntities: [AffectedEntities.TeslaCoil],
			unlocks: ['teslaCoilTowerExtras'],
		},
		rightLeaf: {
			name: 'Long-Distance Power',
			desc: 'Tesla Coil: Massively increase {glossary.attack_rate}, gain two {glossary.chain}, and every {glossary.chain} strikes an extra enemy',
			icon: 'upgrade-long-distance-power',
			binaryFlags: ['tesla-coil-arc-on-chain'],
			statBonuses: [
				[['TeslaCoil'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.50],
				[['TeslaCoil'], StatType.projectileChainCount, StatOperator.SUM, 2],
				[['TeslaCoil'], StatType.projectileChainDistanceMultiplier, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.11],
			],
			affectedEntities: [AffectedEntities.TeslaCoil],
			unlocks: ['teslaCoilLongDistanceExtras'],
		},
	},

	"teslaCoilRapidExtras": {
		name: "Rapid Engagement Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Faster Shocks",
				desc: "Tesla Coil: Greatly increase {glossary.attack_rate}",
				icon: 'upgrade-faster-shocks',
				statBonuses: [
					[['TeslaCoil'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
			{
				name: "Quick Coil Placement",
				desc: "Tesla Coil: Greatly increase {glossary.cooldown_speed}",
				icon: 'upgrade-quick-coil-placement',
				statBonuses: [
					[['TeslaCoil'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.3333],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
			{
				name: "Lightning Fast", // can't change this name without change to unlock validator on griddle
				desc: "Tesla Coil: Massively increase {glossary.attack_rate}",
				icon: 'upgrade-lightning-fast',
				statBonuses: [
					[['TeslaCoil'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
		],
	},
	"teslaCoilTowerExtras": {
		name: "Coil Tower Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Focal Prism",
				desc: "Tesla Coil: Moderately increases {glossary.attack_size} and can {glossary.chain} further",
				icon: 'upgrade-focal-prism',
				statBonuses: [
					[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.2],
					[['TeslaCoil'], StatType.projectileChainDistanceMultiplier, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
			{
				name: "Continuing Chains",
				desc: "Tesla Coil: {glossary.chain} to two more enemies",
				icon: 'upgrade-continuing-chains',
				statBonuses: [
					[['TeslaCoil'], StatType.projectileChainCount, StatOperator.SUM, 2],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
			{
				name: "Grand Tower", // can't change this name without change to unlock validator on griddle
				desc: "Tesla Coil: Massively increase {glossary.attack_size} and {glossary.damage}, and can {glossary.chain} further",
				icon: 'upgrade-grand-tower',
				statBonuses: [
					[['TeslaCoil'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.40],
					[['TeslaCoil'], StatType.projectileChainDistanceMultiplier, StatOperator.SUM_THEN_MULTIPLY, 0.6],
					[['TeslaCoil'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.6],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
		],
	},
	"teslaCoilLongDistanceExtras": {
		name: "Long Distance Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Longer Chains",
				desc: "Tesla Coil: Gain two {glossary.chain}",
				icon: 'upgrade-longer-chains',
				statBonuses: [
					[['TeslaCoil'], StatType.projectileChainCount, StatOperator.SUM, 2],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
			{
				name: "Boosted Lightning",
				desc: "Tesla Coil: Greatly increase {glossary.damage}",
				icon: 'upgrade-boosted-lightning',
				statBonuses: [
					[['TeslaCoil'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
			{
				name: "Chain Infinitum", // can't change this name without change to unlock validator on griddle
				desc: "Tesla Coil: Gain four {glossary.chain} and {glossary.chain} further",
				icon: 'upgrade-chain-infinitum',
				statBonuses: [
					[['TeslaCoil'], StatType.projectileChainCount, StatOperator.SUM, 4],
					[['TeslaCoil'], StatType.projectileChainDistanceMultiplier, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				],
				affectedEntities: [AffectedEntities.TeslaCoil],
			},
		],
	},


	'nikolaScopeUnlock': {
		name: 'Nikola Scope Unlock',
		desc: '',
		shape: 'single',
		isSecondaryUnlock: true,
		metaStartsLocked: true,
		top: {
			name: `Unlock: Nikola's Scope`,
			desc: `Equip a powerful beam weapon that charges up a laser, then fires in a random direction, rapidly damaging enemies.`,
			icon: 'upgrade-unlock-nikolas-scope',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.NikolaScope)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.NikolaScope)
			},
			unlocks: ['nikolaScopeUpgrades'],
			affectedEntities: [AffectedEntities.NikolaScope]
		},
	},
	'nikolaScopeUpgrades': {
		name: 'Nikola Scope Upgrades',
		desc: '',
		shape: `tree`,
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Bigger Beam',
			desc: 'Nikola Scope: Slightly increase {glossary.attack_size} and greatly increase {glossary.damage}',
			icon: 'upgrade-bigger-beam',
			statBonuses: [
				[['NikolaScope'], StatType.attackSize, StatOperator.SUM, 0.15],
				[['NikolaScope'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.5]
			],
			affectedEntities: [AffectedEntities.NikolaScope]
		},
		left: {
			name: 'Accelerated Technology',
			desc: 'Nikola Scope: Greatly increase {glossary.attack_rate}',
			icon: 'upgrade-accelerated-technology',
			statBonuses: [
				[['NikolaScope'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.333333],
			],
			affectedEntities: [AffectedEntities.NikolaScope]
		},
		right: {
			name: 'Plasma Overload',
			desc: 'Nikola Scope: Slightly increase {glossary.attack_size} and {glossary.damage}, greatly increase {glossary.skill_duration}',
			icon: 'upgrade-plasma-overload',
			statBonuses: [
				[['NikolaScope'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['NikolaScope'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 0.40],
				[['NikolaScope'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.15]
			],
			affectedEntities: [AffectedEntities.NikolaScope]
		},
		leftLeaf: {
			name: 'Laser Light Show',
			desc: 'Nikola Scope: Greatly increase {glossary.damage} and tick rate. Your beam now fires multiple times in quick succession.',
			icon: 'upgrade-laser-light-show',
			binaryFlags: ['nikola-scope-light-show'],
			statBonuses: [
				[['NikolaScope'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				[['NikolaScope'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4]
			],
			affectedEntities: [AffectedEntities.NikolaScope],
			unlocks: ['nikolaScopeLaserLightExtras'],
		},
		middleLeaf: {
			name: 'Shock-Infused',
			desc: 'Nikola Scope: Greatly increase shock chance, and slightly increase stun chance. Shocked enemies take double damage from ALL damage sources.',
			icon: 'upgrde-shock-infused',
			statBonuses: [
				[['NikolaScope'], StatType.shockChance, StatOperator.SUM, 0.3],
				[['NikolaScope'], StatType.shockPotency, StatOperator.SUM, 10],
				[['NikolaScope'], StatType.stunChance, StatOperator.SUM, 0.08],
			],
			affectedEntities: [AffectedEntities.NikolaScope],
			unlocks: ['nikolaScopeShockInfusedExtras'],
		},
		rightLeaf: {
			name: 'Pretty Overpowered Laser',
			desc: 'Nikola Scope: Moderately increase {glossary.attack_size} and massively increase {glossary.skill_duration}. A small chance to instantly kill basic enemies, or deal massive damage to bosses.',
			icon: 'upgrade-pretty-overpowered-laser',
			binaryFlags: ['nikola-scope-op'],
			statBonuses: [
				[['NikolaScope'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.30],
				[['NikolaScope'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 0.75],
			],
			affectedEntities: [AffectedEntities.NikolaScope],
			unlocks: ['nikolaScopePrettyOPExtras'],
		}
	},



	"nikolaScopeLaserLightExtras": {
		name: "Laser Light Show Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Light It Up',
				desc: 'Nikola Scope: Greatly increase chance to {glossary.ignite} enemies for double {glossary.damage}',
				icon: 'upgrade-light-it-up',
				statBonuses: [
					[['NikolaScope'], StatType.igniteChance, StatOperator.SUM, 0.4],
					[['NikolaScope'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 1.0],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
			{
				name: 'High-Efficacy Lasers',
				desc: 'Nikola Scope: Greatly increase {glossary.damage}',
				icon: 'upgrade-high-efficacy-lasers',
				statBonuses: [
					[['NikolaScope'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
			{
				name: 'Multi-Point Show', // can't change this name without change to unlock validator on griddle
				desc: 'Nikola Scope: Gain another laser',
				icon: 'upgrade-multi-point-show',
				statBonuses: [
					[['NikolaScope'], StatType.projectileCount, StatOperator.SUM, 1],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
		],
	},
	"nikolaScopeShockInfusedExtras": {
		name: "Shock-Infused Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: `A Shockingly Good Time`,
				desc: 'Nikola Scope: Greatly increase {glossary.damage}',
				icon: 'upgrade-a-shockingly-good-time',
				statBonuses: [
					[['NikolaScope'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
			{
				name: 'Stunning Effectiveness',
				desc: 'Nikola Scope: Slightly increase stun chance and shock chance',
				icon: 'upgrade-stunning-effectiveness',
				statBonuses: [
					[['NikolaScope'], StatType.shockChance, StatOperator.SUM, 0.15],
					[['NikolaScope'], StatType.stunChance, StatOperator.SUM, 0.07],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
			{
				name: 'Shocked to Death', // can't change this name without change to unlock validator on griddle
				desc: `Nikola Scope: Massively increase Shock potency`,
				icon: 'upgrade-shocked-to-death',
				statBonuses: [
					[['NikolaScope'], StatType.shockPotency, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
		],
	},
	"nikolaScopePrettyOPExtras": {
		name: "Pretty Overpowered Laser Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: `Maximum Amperage`,
				desc: 'Nikola Scope: Greatly increase {glossary.attack_rate}',
				icon: 'upgrade-maximum-amperage',
				statBonuses: [
					[['NikolaScope'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
			{
				name: `Firepower Overload`,
				desc: 'Nikola Scope: Greatly increase {glossary.attack_rate}',
				icon: 'upgrade-firepower-overload',
				statBonuses: [
					[['NikolaScope'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
			{
				name: 'Orbital Laser', // can't change this name without change to unlock validator on griddle
				desc: `Nikola Scope: Greatly increase {glossary.damage} and moderately increase {glossary.attack_size}`,
				icon: 'upgrade-orbital-lasers',
				statBonuses: [
					[['NikolaScope'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
					[['NikolaScope'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				],
				affectedEntities: [AffectedEntities.NikolaScope],
			},
		],
	},

	'beesUnlock': {
		name: 'Bees Unlock',
		desc: '',
		shape: 'single',
		metaStartsLocked: true,
		isSecondaryUnlock: true,
		top: {
			name: 'Unlock: BEES!',
			desc: 'Equip a backpack full of Bees that seek out enemies and sting them.',
			icon: 'upgrade-unlock-bees',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.Bees)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.Bees)
			},
			unlocks: ['beesUpgrades'],
			affectedEntities: [AffectedEntities.Bees],
		},
	},
	'beesUpgrades': {
		name: 'BEES! Upgrades',
		desc: '',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Everyone Gets Bees!',
			desc: 'Bees: Reduces the {glossary.cooldown_speed} by 2 seconds, and release an extra Bee',
			icon: 'upgrade-everyone-gets-bees',
			statBonuses: [
				[['Bees'], StatType.cooldownInterval, StatOperator.SUM, -2000],
				[['Bees'], StatType.projectileCount, StatOperator.SUM, 0.5],
			],
			affectedEntities: [AffectedEntities.Bees],

		},
		left: {
			name: "Biggest Beehive",
			desc: 'Bees: Release a lot more Bees',
			icon: 'upgrade-biggest-beehive',
			statBonuses: [
				[['Bees'], StatType.projectileCount, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.Bees],
		},
		right: {
			name: 'Absolute Units',
			desc: 'Bees: Your Bees are nearly twice as big, twice as strong, pierce twice as many targets, and move faster - but you have half as many',
			icon: 'upgrade-absolute-units',
			statBonuses: [
				[['Bees'], StatType.projectileCount, StatOperator.MULTIPLY, -0.5],
				[['Bees'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 1],
				[['Bees'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.75],
				[['Bees'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Bees'], StatType.attackPierceCount, StatOperator.SUM_THEN_MULTIPLY, 1],
			],
			affectedEntities: [AffectedEntities.Bees],
		},
		leftLeaf: {
			name: 'BEESTORM!',
			desc: 'Bees: Release a lot more Bees, with a small chance to release TRIPLE the Bees! Your Bees are faster',
			icon: 'upgrade-beestorm',
			binaryFlags: ['bees-chance-for-triple'],
			statBonuses: [
				[['Bees'], StatType.projectileCount, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Bees'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.25],
			],
			unlocks: ['beesBeestormExtras'],
			affectedEntities: [AffectedEntities.Bees],
		},
		middleLeaf: {
			name: 'Attack of the Killer Wasps',
			desc: 'Bees: Your Bees become Wasps that inflict Poison with every attack',
			icon: 'upgrade-attack-of-the-killer-wasps',
			statBonuses: [
				[['Bees'], StatType.initialImpactDamageMult, StatOperator.MULTIPLY, -0.5],
				[['Bees'], StatType.poisonChance, StatOperator.SUM, 1.0],
				[['Bees'], StatType.poisonPotency, StatOperator.SUM_THEN_MULTIPLY, 1.25],
			],
			binaryFlags: ['killer-wasps'],
			unlocks: ['beesKillerWaspsExtras'],
			affectedEntities: [AffectedEntities.Bees],
		},
		rightLeaf: {
			name: 'Bees Forever',
			desc: 'Bees: Bees hang out longer to bee your friend, and can pierce through twice as many targets',
			icon: 'upgrade-bees-forever',
			statBonuses: [
				[['Bees'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 4],
				[['Bees'], StatType.attackPierceCount, StatOperator.MULTIPLY, 1],
			],
			unlocks: ['beesBeesForeverExtras'],
			affectedEntities: [AffectedEntities.Bees],
		}
	},

	"beesBeestormExtras": {
		name: "BEESTORM! Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "More Bees!",
				desc: "Bees: Gain even more Bees",
				icon: 'upgrade-MORE-bees',
				statBonuses: [
					[['Bees'], StatType.projectileCount, StatOperator.SUM, 1],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
			{
				name: "More Bees!?",
				desc: "Bees: Gain even more Bees",
				icon: 'upgrade-some-more-bees',
				statBonuses: [
					[['Bees'], StatType.projectileCount, StatOperator.SUM, 1],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
			{
				name: "MOAR BEES!!!", // can't change this name without change to unlock validator on griddle
				desc: "Bees: Gain even more Bees",
				icon: 'upgrade-MOAR-BEES',
				statBonuses: [
					[['Bees'], StatType.projectileCount, StatOperator.SUM, 1.5],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
		],
	},
	"beesKillerWaspsExtras": {
		name: "Killer Wasps Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Corrosive Wasps",
				desc: "Bees: Massively increase your Wasps' Poison {glossary.damage}",
				icon: 'upgrade-corrosive-wasps',
				statBonuses: [
					[['Bees'], StatType.poisonPotency, StatOperator.SUM_THEN_MULTIPLY, 0.6],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
			{
				name: "Rapid Stingers",
				desc: "Bees: Massively increase your Wasps' speed",
				icon: 'upgrade-rapid-stingers',
				statBonuses: [
					[['Bees'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
			{
				name: "Wasp Outbreak", // can't change this name without change to unlock validator on griddle
				desc: "Bees: Release a lot more Wasps",
				icon: 'upgrade-wasp-outbreak',
				statBonuses: [
					[['Bees'], StatType.projectileCount, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
		],
	},
	"beesBeesForeverExtras": {
		name: "Bees Forever Upgrades",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Stronger Bees",
				desc: "Bees: Massively increase your Bees' {glossary.damage}",
				icon: 'upgrade-stronger-bees',
				statBonuses: [
					[['Bees'], StatType.allDamageMult, StatOperator.SUM, 0.6],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
			{
				name: "Weight Training",
				desc: "Bees: Massively increase your Bees' {glossary.damage} and {glossary.pierce} even more targets",
				icon: 'upgrade-weight-training',
				statBonuses: [
					[['Bees'], StatType.allDamageMult, StatOperator.SUM, 0.6],
					[['Bees'], StatType.attackPierceCount, StatOperator.SUM, 1],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
			{
				name: "CHONKERS", // can't change this name without change to unlock validator on griddle
				desc: "Bees: Massively increase your Bees' {glossary.damage} and make them even bigger",
				icon: 'upgrade-chonkers',
				statBonuses: [
					[['Bees'], StatType.allDamageMult, StatOperator.SUM, 0.4],
					[['Bees'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				],
				affectedEntities: [AffectedEntities.Bees],
			},
		],
	},


	'luteUnlock': {
		name: 'Lute Unlock',
		desc: 'Unlock the Lute Weapon',
		shape: 'single',
		isSecondaryUnlock: true,
		metaStartsLocked: true,
		top: {
			name: 'Unlock: Lute',
			desc: `Periodically start a jam session, releasing projectiles at random enemies!`,
			icon: 'upgrade-unlock-lute',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.Lute)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.Lute)
			},
			unlocks: ['luteUpgrades'],
			affectedEntities: [AffectedEntities.Lute]
		},
	},
	'luteUpgrades': {
		name: 'Lute Upgrades',
		desc: 'Upgrades for the Lute weapon',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Legato',
			desc: 'Lute: Moderately increase the {glossary.skill_duration} of your jam sessions',
			icon: 'upgrade-legato',
			affectedEntities: [AffectedEntities.Lute],
			statBonuses: [
				[['Lute'], StatType.skillDuration, StatOperator.SUM, 2]
			]
		},
		left: {
			name: 'You Should Be Dancing',
			desc: 'Lute: Moderately increase {glossary.skill_duration} and while jamming a random directional arrow will appear at your feet. Pressing that direction causes your lute to fire a projectile and for a new arrow to appear immediately.',
			icon: 'upgrade-you-should-be-dancing',
			statBonuses: [
				[['Lute'], StatType.skillDuration, StatOperator.SUM, 2]
			],
			affectedEntities: [AffectedEntities.Lute],
			simpleApplyFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				lute.enableDDR = true
			},
			simpleRemoveFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				if (lute) {
					lute.enableDDR = false
				}
			},
		},
		right: {
			name: 'Bardcore',
			desc: 'Lute: Moderately increase {glossary.attack_rate}, and every second attack now drops a power-up that temporarily grants a small increase in {glossary.damage} to ALL of your attacks. This bonus stacks up to 5 times',
			icon: 'upgrade-bardcore',
			affectedEntities: [AffectedEntities.Lute],
			statBonuses: [
				[['Lute'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25]
			],
			simpleApplyFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				lute.enablePowerUp = true
			},
			simpleRemoveFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				if (lute) {
					lute.enablePowerUp = false
				}
			},
		},
		leftLeaf: {
			name: "Ramping BPM",
			desc: 'Lute: Your Lute gains {glossary.attack_rate} while jamming for each correct directional arrow hit',
			icon: 'upgrade-ramping-bpm',
			affectedEntities: [AffectedEntities.Lute],
			unlocks: ['luteDDRExtras'],
			simpleApplyFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				lute.ddrRampAttackRate = true
			},
			simpleRemoveFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				if (lute) {
					lute.ddrRampAttackRate = false
				}
			},
		},
		middleLeaf: {
			name: "Improv",
			desc: 'Lute: Your projectiles have a huge chance to apply a random status effect on hit, and deal moderately increased {glossary.damage}',
			icon: 'upgrade-improv',
			affectedEntities: [AffectedEntities.Lute],
			unlocks: ['luteStatusExtras'],
			statBonuses: [
				[['Lute'], StatType.baseDamage, StatOperator.SUM, 10],
				[['Lute'], StatType.randomAilmentChance, StatOperator.SUM, 0.5],
			]
		},
		rightLeaf: {
			name: "Prog Rock",
			desc: 'Lute: Picking up your power-up increases your jam session duration if you are still jamming',
			icon: 'upgrade-prog-rock',
			affectedEntities: [AffectedEntities.Lute],
			unlocks: ['lutePowerUpExtras'],
			simpleApplyFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				lute.powerUpIncreasesJamDuration = true
			},
			simpleRemoveFn(player, state) {
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				if (lute) {
					lute.powerUpIncreasesJamDuration = false
				}
			},
		}
	},

	'luteDDRExtras': {
		name: "Lute Dancing Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Damage Damage Resolution",
				desc: "Lute: Projectiles created from hitting a directional arrow deal double {glossary.damage} and have moderately increased {glossary.attack_size}",
				icon: 'upgrade-damage-damage-resolution',
				affectedEntities: [AffectedEntities.Lute],
				simpleApplyFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					lute.ddrProjectileDamageScale = 2
					lute.ddrProjectileSizeBonus = 1.5
				},
				simpleRemoveFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					if (lute) {
						lute.ddrProjectileDamageScale = 1
						lute.ddrProjectileSizeBonus = 1
					}
				},
			},
			{
				name: "Fortississimo",
				desc: "Lute: Greatly increase {glossary.damage}.",
				icon: 'upgrade-fortississimo',
				affectedEntities: [AffectedEntities.Lute],
				statBonuses: [
					[['Lute'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.6]
				]
			},
			{
				name: "Grand Finale",
				desc: "Lute: When your jam session ends, fire another projectile for each directional arrow hit",
				icon: 'upgrade-grand-finale',
				affectedEntities: [AffectedEntities.Lute],
				simpleApplyFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					lute.ddrGrandFinale = true
				},
				simpleRemoveFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					if (lute) {
						lute.ddrGrandFinale = false
					}
				},
			},
		],
	},
	'luteStatusExtras': {
		name: "Lute Status Effect Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Tempo Change",
				desc: "Lute: Greatly increase {glossary.attack_rate} and the chance to apply a random status effect moderately increases",
				icon: 'upgrade-tempo-change',
				affectedEntities: [AffectedEntities.Lute],
				statBonuses: [
					[['Lute'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.4],
					[['Lute'], StatType.randomAilmentChance, StatOperator.SUM, 0.25],

				]
			},
			{
				name: "The Entire Symphony",
				desc: "Lute: Projectiles now have a 100% chance to apply a random status effect on hit and slightly increase jam session {glossary.skill_duration}",
				icon: 'upgrade-the-entire-symphony',
				affectedEntities: [AffectedEntities.Lute],
				statBonuses: [
					[['Lute'], StatType.skillDuration, StatOperator.SUM, 2],
					[['Lute'], StatType.randomAilmentChance, StatOperator.SUM, 0.25],
				]
			},
			{
				name: "Great Tone",
				desc: "Lute: Double the potency of all status effects applied by your Lute",
				icon: 'upgrade-great-tone',
				affectedEntities: [AffectedEntities.Lute],
				statBonuses: [
					[['Lute'], StatType.allAilmentPotencyMult, StatOperator.MULTIPLY, 2]
				],
			},
		],
	},
	'lutePowerUpExtras': {
		name: "Lute Power Up Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: "Strolling Minstrel",
				desc: "Lute: Your power-up takes longer to disappear and the bonus from your power-up lasts longer",
				icon: 'upgrade-strolling-minstrel',
				affectedEntities: [AffectedEntities.Lute],
				simpleApplyFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					lute.powerUpIncreasedGroundDuration = 5 // in seconds
					lute.powerUpIncreasedBuffDuration = 1.5 // as a percent
				},
				simpleRemoveFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					if (lute) {
						lute.powerUpIncreasedGroundDuration = 0
						lute.powerUpIncreasedBuffDuration = 1
					}
				},
			},
			{
				name: "When is the next Gig?",
				desc: "Lute: Moderately increases {glossary.attack_rate}, and picking up your power-up when you are not jamming reduces the cooldown of your Lute.",
				icon: 'upgrade-when-is-the-next-gig',
				affectedEntities: [AffectedEntities.Lute],
				statBonuses: [
					[['Lute'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25]
				],
				simpleApplyFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					lute.powerUpDecreasesCooldown = true
				},
				simpleRemoveFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					if (lute) {
						lute.powerUpDecreasesCooldown = false
					}
				},
			},
			{
				name: "Song of Haste",
				desc: "Lute: Your power-up bonus also slightly increases your {glossary.attack_rate}",
				icon: 'upgrade-song-of-haste',
				affectedEntities: [AffectedEntities.Lute],
				simpleApplyFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					lute.powerUpAttackRate = true
				},
				simpleRemoveFn(player, state) {
					const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
					if (lute) {
						lute.powerUpAttackRate = false
					}
				},
			},
		],
	},

	'darkStormyNightUnlock': {
		name: 'Dark Stormy Night Unlock',
		desc: '',
		shape: 'single',
		isSteam: true,
		isSecondaryUnlock: true,
		metaStartsLocked: true,
		top: {
			name: 'Unlock: Dark Stormy Night',
			desc: `Summon lightning strikes from the sky to destroy your enemies.`,
			icon: 'upgrade-unlock-dark-stormy-night',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.DarkStormyNight)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.DarkStormyNight)
			},
			unlocks: ['darkStormyNightUpgrades'],
			affectedEntities: [AffectedEntities.DarkStormyNight]
		},
	},

	'darkStormyNightUpgrades': {
		name: 'Dark Stormy Night Upgrades',
		desc: 'Upgrades for the Dark Stormy Night weapon',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Multi-Strike',
			desc: 'Dark Stormy Night: Gain an additional Lightning Strike',
			icon: 'upgrade-multi-strike',
			affectedEntities: [AffectedEntities.DarkStormyNight],
			statBonuses: [
				[['DarkStormyNight'], StatType.projectileCount, StatOperator.SUM, 1]
			],
		},
		left: {
			name: 'Strike After Strike',
			desc: 'Dark Stormy Night: Gain two additional Lightning Strikes',
			icon: 'upgrade-strike-after-strike',
			affectedEntities: [AffectedEntities.DarkStormyNight],
			statBonuses: [
				[['DarkStormyNight'], StatType.projectileCount, StatOperator.SUM, 2]
			]
		},
		right: {
			name: 'Thunderous Blows',
			desc: 'Dark Stormy Night: Moderately increase {glossary.attack_size} and {glossary.damage}',
			icon: 'upgrade-thunderous-blows',
			affectedEntities: [AffectedEntities.DarkStormyNight],
			statBonuses: [
				[['DarkStormyNight'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.20],
				[['DarkStormyNight'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.50],
			]
		},
		leftLeaf: {
			name: 'Electrical Discharge',
			desc: 'Dark Stormy Night: Gain four additional Lightning Strikes, but increase the {glossary.cooldown} and decrease the {glossary.damage} and {glossary.attack_size} of all strikes',
			icon: 'upgrade-electrical-discharge',
			unlocks: ['darkStormyNightStrikesExtras'],
			affectedEntities: [AffectedEntities.DarkStormyNight],
			statBonuses: [
				[['DarkStormyNight'], StatType.projectileCount, StatOperator.SUM, 4],
				[['DarkStormyNight'], StatType.allDamageMult, StatOperator.MULTIPLY, -0.20],
				[['DarkStormyNight'], StatType.attackSize, StatOperator.MULTIPLY, -0.20],
				[['DarkStormyNight'], StatType.cooldownInterval, StatOperator.SUM_THEN_MULTIPLY, 0.15],
			]
		},
		middleLeaf: {
			name: 'SkillStrike Gadget',
			desc: 'Dark Stormy Night: Unleash Lightning Strikes every time you use your Character Skill, and moderately lower the {glossary.cooldown} of your strikes',
			icon: 'upgrade-skillstrike-gadget',
			unlocks: ['darkStormyNightSkillExtras'],
			affectedEntities: [AffectedEntities.DarkStormyNight],
			binaryFlags: ['lightning-strikes-on-skill-use'],
			statBonuses: [
				[['DarkStormyNight'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.25],
			]
		},
		rightLeaf: {
			name: 'Lightning Rod',
			desc: 'Dark Stormy Night: Wear a lightning rod, unleashing frequent, powerful Lightning Strikes on your own location',
			icon: 'upgrade-lightning-rod',
			unlocks: ['darkStormyNightThunderExtras'],
			affectedEntities: [AffectedEntities.DarkStormyNight],
			binaryFlags: ['lightning-rod'],
			statBonuses: [
				[['DarkStormyNight'], StatType.attackKnockback, StatOperator.SUM, 900],
			]
		}
	},

	'darkStormyNightStrikesExtras': {
		name: "Dark Stormy Night Strike Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Shocking Percussion',
				desc: 'Dark Stormy Night: Gain a moderate chance to Shock enemies',
				icon: 'upgrade-shocking-percussion',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				statBonuses: [
					[['DarkStormyNight'], StatType.shockChance, StatOperator.SUM, 0.5],
					[['DarkStormyNight'], StatType.shockPotency, StatOperator.SUM, 10],
				]
			},
			{
				name: 'Double Strike',
				desc: 'Dark Stormy Night: Gain two additional Lightning Strikes',
				icon: 'upgrade-double-strike',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				statBonuses: [
					[['DarkStormyNight'], StatType.projectileCount, StatOperator.SUM, 2],
				]
			},
			{
				name: 'Lightning Tempest',
				desc: 'Dark Stormy Night: Gain three additional Lightning Strikes',
				icon: 'upgrade-lightning-tempest',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				statBonuses: [
					[['DarkStormyNight'], StatType.projectileCount, StatOperator.SUM, 3],
				]
			},
		],
	},

	'darkStormyNightSkillExtras': {
		name: "Dark Stormy Skill Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Bone Conduction',
				desc: 'Dark Stormy Night: Moderately increase {glossary.damage}',
				icon: 'upgrade-bone-conduction',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				statBonuses: [
					[['DarkStormyNight'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				]
			},
			{
				name: 'Expertise in Action',
				desc: `Dark Stormy Night: Slightly increase {glossary.cooldown_speed} and {glossary.reload} of your Character Skill`,
				icon: 'upgrade-expertise-in-action',
				affectedEntities: [AffectedEntities.Player],
				statBonuses: [
					[['PlayerSkill'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.15],
					[['PlayerSkill'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.15],
				]
			},
			{
				name: 'Lightning Prowess',
				desc: 'Dark Stormy Night: Massively increase the number of Lightning Strikes unleashed automatically and when using your Character Skill',
				icon: 'upgrade-lightning-prowess',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				statBonuses: [
					[['DarkStormyNight'], StatType.projectileCount, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				]
			},
		],
	},

	'darkStormyNightThunderExtras': {
		name: "Dark Stormy Skill Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Cloudburst',
				desc: 'Dark Stormy Night: Moderately increase {glossary.damage}',
				icon: 'upgrade-cloudburst',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				statBonuses: [
					[['DarkStormyNight'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				]
			},
			{
				name: 'BOOM!',
				desc: 'Dark Stormy Night: Moderately increase {glossary.attack_size} and slightly increase {glossary.damage}',
				icon: 'upgrade-boom',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				statBonuses: [
					[['DarkStormyNight'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.4],
					[['DarkStormyNight'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.15],
				]
			},
			{
				name: 'Thunderous Squall',
				desc: 'Dark Stormy Night: Unleash even more frequent Lightning Strikes at your position',
				icon: 'upgrade-thunderous-squall',
				affectedEntities: [AffectedEntities.DarkStormyNight],
				binaryFlags: ['lightning-rod-faster'],
				statBonuses: [
					[['DarkStormyNight'], StatType.attackKnockback, StatOperator.SUM, 500],
				]
			},
		],
	},


	'fireFairiesUnlock': {
		name: 'Fire Fairy Unlock',
		desc: '',
		shape: 'single',
		isSecondaryUnlock: true,
		isSteam: true,
		metaStartsLocked: true,
		top: {
			name: 'Unlock: Fire Fairy',
			desc: `Gain a fairy spirit that shoots projectiles at your foes!`,
			icon: 'upgrade-unlock-fire-fairy',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.FireFairies)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.FireFairies)
			},
			unlocks: ['fireFairiesUpgrades'],
			affectedEntities: [AffectedEntities.FireFairy]
		},
	},
	'fireFairiesUpgrades': {
		name: 'Fire Fairies Upgrades',
		desc: '',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Fairy Flame on!',
			desc: 'Fire Fairy: Fairy attacks {glossary.ignite} enemies on hit.',
			icon: 'upgrade-fairy-flame-on',
			statBonuses: [
				[['FireFairies'], StatType.igniteChance, StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.FireFairy]
		},
		left: {
			name: 'Fairy Fireworks',
			desc: 'Fire Fairy: Fairy attacks damage enemies around them for full damage.',
			icon: 'upgrade-fairy-fireworks',
			statBonuses: [
				[['FireFairies'], StatType.projectileSplashDamage, StatOperator.SUM, 1], // % of base damage to deal
				[['FireFairies'], StatType.projectileSplashRadius, StatOperator.SUM, 200],
			],
			affectedEntities: [AffectedEntities.FireFairy]
		},
		right: {
			name: 'Three Fairies are Better than One',
			desc: 'Fire Fairy: Gain two more fairies!',
			icon: 'upgrade-three-fairies-are-better-than-one',
			affectedEntities: [AffectedEntities.FireFairy],
			statBonuses: [
				[['FireFairies'], StatType.reloadInterval, StatOperator.SUM, 1200],
			],
			simpleApplyFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon
				fairyWep.addTwoFairies()
			},
			simpleRemoveFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon
				if (fairyWep) {
					fairyWep.removeTwoFairies()
				}
			},
		},
		leftLeaf: {
			name: 'Skyfire Fairy',
			desc: `Fire Fairy: Greatly increase {glossary.damage} and increase the area a Fairy's attack damages enemies.`,
			icon: 'upgrade-skyfire-fairy',
			statBonuses: [
				[['FireFairies'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.7],
				[['FireFairies'], StatType.projectileSplashRadius, StatOperator.SUM, 125],
			],
			affectedEntities: [AffectedEntities.FireFairy],
			unlocks: ['fireFairiesSkyfireFairyExtras'],
			simpleApplyFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon
				fairyWep.setGfx('fire-fairy-skyfire')
			},
			simpleRemoveFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon
				if (fairyWep) {
					fairyWep.setGfx('fire-fairy-base')
				}
			},
		},
		middleLeaf: {
			name: 'Leading the Fairy Dance',
			desc: 'Fire Fairy: Fairy attacks now follow your cursor, and have increased {glossary.attack_rate} and {glossary.reload}.',
			icon: 'upgrade-leading-the-fairy-dance',
			statBonuses: [
				[['FireFairies'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['FireFairies'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.3],
			],
			affectedEntities: [AffectedEntities.FireFairy],
			simpleApplyFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon
				fairyWep.aimAtCursor = true

				fairyWep.setGfx('fire-fairy-dance')
			},
			simpleRemoveFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon
				if (fairyWep) {
					fairyWep.aimAtCursor = false
					fairyWep.setGfx('fire-fairy-base')
				}
			},
			unlocks: ['fireFairiesFairyDanceExtras']
		},
		rightLeaf: {
			name: 'Fairy Flames Forever',
			desc: 'Fire Fairy: Increase {glossary.ignite} damage and add one {glossary.pierce}. Fairies now target non-burning enemies with priority.',
			icon: 'upgrade-fairy-flames-forever',
			statBonuses: [
				[['FireFairies'], StatType.ignitePotency, StatOperator.SUM, 0.6],
				[['FireFairies'], StatType.attackPierceCount, StatOperator.SUM, 1],
			],
			simpleApplyFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon
				fairyWep.targetNonBurning = true

				fairyWep.setGfx('fire-fairy-flames-forever')
			},
			simpleRemoveFn(player, state) {
				const fairyWep = player.getWeapon(AllWeaponTypes.FireFairies) as FireFairiesWeapon

				if (fairyWep) {
					fairyWep.targetNonBurning = false
					fairyWep.setGfx('fire-fairy-base')
				}
			},
			affectedEntities: [AffectedEntities.FireFairy],
			unlocks: ['fireFairiesFlamesForeverExtras']
		}
	},
	'fireFairiesSkyfireFairyExtras': {
		name: "Skyfire Fairy Extras",
		desc: '',
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Greater SkyFire',
				desc: `Fire Fairy: Deal increased {glossary.damage} and increase the area a Fairy's attack damages enemies.`,
				icon: 'upgrade-greater-skyfire',
				affectedEntities: [AffectedEntities.FireFairy],
				statBonuses: [
					[['FireFairies'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
					[['FireFairies'], StatType.projectileSplashRadius, StatOperator.SUM, 100],
				]
			},
			{
				name: 'Lumbering Burst',
				desc: 'Fire Fairy: Greatly increase {glossary.attack_size} and {glossary.damage}.',
				icon: 'upgrade-lumbering-burst',
				affectedEntities: [AffectedEntities.FireFairy],
				statBonuses: [
					[['FireFairies'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.6],
					[['FireFairies'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.5],
					[['FireFairies'], StatType.projectileSpeed, StatOperator.MULTIPLY, -0.25],
				]
			},
			{
				name: 'Comet Blast',
				desc: 'Fire Fairy: Gain a small bonus to {glossary.attack_size} for ALL weapons, greatly increase fairy {glossary.damage}, and increase fairy {glossary.attack_size} at the cost of some fairy {glossary.attack_rate} and {glossary.reload}.',
				icon: 'upgrade-comet-blast',
				affectedEntities: [AffectedEntities.FireFairy],
				statBonuses: [
					[['Player'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.1],
					[['FireFairies'], StatType.projectileSplashRadius, StatOperator.SUM, 120],
					[['FireFairies'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, -0.2],
					[['FireFairies'], StatType.reloadInterval, StatOperator.MULTIPLY, 0.2],
					[['FireFairies'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.9],
					[['FireFairies'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, -0.15],
					[['FireFairies'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				]
			},
		]
	},
	'fireFairiesFairyDanceExtras': {
		name: "Fairy Dance Extras",
		desc: '',
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Extra Steps',
				desc: 'Fire Fairy: Your fairies attack twice in a row, and deal a bit more {glossary.damage}.',
				icon: 'upgrade-extra-steps',
				affectedEntities: [AffectedEntities.FireFairy],
				statBonuses: [
					[['FireFairies'], StatType.maxAmmo, StatOperator.SUM, 1],
					[['FireFairies'], StatType.reloadAmmoIncrement, StatOperator.SUM, 1],
					[['FireFairies'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				]
			},
			{
				name: 'Up Tempo the Fairy Beat',
				desc: 'Fire Fairy: Your fairies attack faster.',
				icon: 'upgrade-up-tempo-the-fairy-beat',
				affectedEntities: [AffectedEntities.FireFairy],
				statBonuses: [
					[['FireFairies'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.25],
					[['FireFairies'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				]
			},
			{
				name: 'Dance Forever with the Fairies',
				desc: 'Fire Fairy: Your fairies attack two additional times, and deal a bit more {glossary.damage}.',
				icon: 'upgrade-dance-forever-with-the-fairies',
				affectedEntities: [AffectedEntities.FireFairy],
				statBonuses: [
					[['FireFairies'], StatType.maxAmmo, StatOperator.SUM, 2],
					[['FireFairies'], StatType.reloadAmmoIncrement, StatOperator.SUM, 2],
					[['FireFairies'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				]
			},
		]
	},
	'fireFairiesFlamesForeverExtras': {
		name: "Fairy Flames Forever Extras",
		desc: '',
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Light the Night Again!',
				desc: 'Fire Fairy: Add two {glossary.pierce} and deal a bit more {glossary.damage}.',
				icon: 'upgrade-light-the-night-again',
				affectedEntities: [AffectedEntities.FireFairy],
				statBonuses: [
					[['FireFairies'], StatType.attackPierceCount, StatOperator.SUM, 2],
					[['FireFairies'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				]
			},
			{
				name: 'Flames Burn All Night!',
				desc: 'Fire Fairy: Increase {glossary.ignite} potency and double ALL ignite durations.',
				icon: 'upgrade-flames-burn-all-night',
				affectedEntities: [AffectedEntities.FireFairy],
				binaryFlags: ['double-ignite-duration'],
				statBonuses: [
					[['FireFairies'], StatType.ignitePotency, StatOperator.SUM, 0.66],
				]
			},
			{
				name: 'Burn Burn Burn!',
				desc: 'Fire Fairy: Enemies that die from {glossary.ignite} leave behind a burning pool for the remaining duration of their ignite.',
				icon: 'upgrade-burn-burn-burn',
				affectedEntities: [AffectedEntities.FireFairy],
				binaryFlags: ['ignite-pool'],
				simpleApplyFn() {
					if (!IgniteFieryPool.pool) {
						IgniteFieryPool.pool = new ObjectPool(() => {
							return new IgniteFieryPool()
						}, {}, 35, 1)
					}
				}
			},
		]
	},
	'phoenixBombsUnlock': {
		name: "Phoenix Bombs Unlock",
		desc: '',
		shape: 'single',
		isSecondaryUnlock: true,
		isSteam: true,
		metaStartsLocked: true,
		top:{
			name: 'Unlock: Phoenix Bombs',
			desc: 'Equip Phoenix Bombs that explode dealing {glossary.damage}.',
			icon: 'upgrade-unlock-phoenix-bombs',
			simpleApplyFn(player: Player, state) {
				player.equipSecondaryWeapon(SecondaryWeaponType.PhoenixBombs)
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.PhoenixBombs)
			},
			unlocks: ['phoenixBombsUpgrades'],
			affectedEntities: [AffectedEntities.PhoenixBombs]
		}
	},
	'phoenixBombsUpgrades': {
		name: 'Phoenix Bombs Upgrades',
		desc: '',
		shape: 'tree',
		isUnlock: true,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Flame Bright!',
			desc: 'Phoenix Bombs: Gain a large chance to {glossary.ignite} enemies hit by the bomb\'s explosion, and slightly increase {glossary.attack_size}',
			icon: 'upgrade-flame-bright',
			statBonuses: [
				[['PhoenixBombs'], StatType.igniteChance, StatOperator.SUM, 0.5],
				[['PhoenixBombs'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.125],
			],
			affectedEntities: [AffectedEntities.PhoenixBombs]
		},
		left: {
			name: 'Phoenix Flock!',
			desc: `Throw two additional Phoenix Bombs and increase the area they're thrown in`,
			icon: 'upgrade-phoenix-flock',
			statBonuses: [
				[['PhoenixBombs'], StatType.projectileCount, StatOperator.SUM, 2],
			],
			
			// If we need to do anything else probably just stick a new stat in StatFormula for the two functions
			simpleApplyFn(player: Player, state) {
				const phoenixWeapon = player.getWeapon(AllWeaponTypes.PhoenixBombs) as PhoenixBombs
				phoenixWeapon.updateAngle(0.25)
			},
			affectedEntities: [AffectedEntities.PhoenixBombs]
		},
		right: {
			name: 'Quick Fuse',
			desc: 'Phoenix Bombs: Shorten the fuse time and moderately increase {glossary.ignite} chance and slightly increase {glossary.attack_size}',
			icon: 'upgrade-quick-fuse',
			affectedEntities: [AffectedEntities.PhoenixBombs],
			statBonuses: [
				[['PhoenixBombs'], StatType.igniteChance, StatOperator.SUM, 0.2],
				[['PhoenixBombs'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.125],
			],

			simpleApplyFn(player: Player, state) {
				const phoenixWeapon = player.getWeapon(AllWeaponTypes.PhoenixBombs) as PhoenixBombs
				phoenixWeapon.reduceFuseTime(0.33) // This needs to be positive, as it is a subtractive multiplier in the formula.
			}

		},
		leftLeaf: {
			name: 'Phoenixpalooza',
			desc: `Phoenix Bombs: Throw two additional Phoenix Bombs and increase the area they're thrown in`,
			icon: 'upgrade-phoenixpalooza',
			unlocks: ['phoenixpaloozaExtras'],
			statBonuses: [
				[['PhoenixBombs'], StatType.placementRange, StatOperator.SUM_THEN_MULTIPLY, 0.10],
				[['PhoenixBombs'], StatType.projectileCount, StatOperator.SUM, 2],
			],
			simpleApplyFn(player: Player, state) {
				const phoenixWeapon = player.getWeapon(AllWeaponTypes.PhoenixBombs) as PhoenixBombs
				phoenixWeapon.updateAngle(0.20)
			},
			affectedEntities: [AffectedEntities.PhoenixBombs],
		},
		middleLeaf: {
			name: 'Phoenix Growth Hormone',
			desc: 'Phoenix Bombs: Increase the {glossary.attack_size} and {glossary.damage} of your Phoenix Bombs',
			icon: 'upgrade-growth-hormone',
			unlocks: ['phoenixGrowthExtras'],
			statBonuses: [
				[['PhoenixBombs'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.6],
				[['PhoenixBombs'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
			],
			affectedEntities: [AffectedEntities.PhoenixBombs],
		},
		rightLeaf: {
			name: 'Rapid Rebirth',
			desc: 'Phoenix Bombs: Moderately increase {glossary.cooldown_speed}, chance to {glossary.ignite}, and {glossary.damage}',
			icon: 'upgrade-rapid-rebirth',
			unlocks: ['phoenixRebirthExtras'],
			statBonuses: [
				[['PhoenixBombs'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.25],
				[['PhoenixBombs'], StatType.allDamageMult, StatOperator.MULTIPLY, 0.1],
				[['PhoenixBombs'], StatType.igniteChance, StatOperator.SUM, 0.3],
			],
			affectedEntities: [AffectedEntities.FireFairy],
		}
	},

	'phoenixpaloozaExtras': {
		name: "Phoenix Bomb Palooza Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: `A Lil' Spice`,
				desc: 'Phoenix Bombs: Throw an extra Phoenix Bomb, and gain a moderate chance to {glossary.ignite}',
				icon: 'upgrade-a-lil-spice',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				statBonuses: [
					[['PhoenixBombs'], StatType.igniteChance, StatOperator.SUM, 0.2],
					[['PhoenixBombs'], StatType.projectileCount, StatOperator.SUM, 1],
				]
			},
			{
				name: 'Phoenix Multiplier',
				desc: 'Phoenix Bombs: Every third Phoenix Bomb will cause you to throw an extra Bomb, and slightly increase {glossary.damage}',
				icon: 'upgrade-phoenix-multiplier',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				statBonuses: [
					[['PhoenixBombs'], StatType.projectileCount, StatOperator.SUM_THEN_MULTIPLY, 0.3334],
					[['PhoenixBombs'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.2],
				]
			},
			{
				name: 'Firebird Flock',
				desc: `Phoenix Bombs: Throw three additional Phoenix Bombs and increase the area they're thrown in, moderately increase {glossary.damage}`,
				icon: 'upgrade-firebird-flock',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				statBonuses: [
					[['PhoenixBombs'], StatType.projectileCount, StatOperator.SUM, 3],
					[['PhoenixBombs'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				],
				simpleApplyFn(player: Player, state) {
					const phoenixWeapon = player.getWeapon(AllWeaponTypes.PhoenixBombs) as PhoenixBombs
					phoenixWeapon.updateAngle(0.20)
				},
			},
		],
	},

	'phoenixGrowthExtras': {
		name: "Phoenix Bomb Growth Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Roost Boost',
				desc: 'Phoenix Bombs: Moderately increase {glossary.damage}, {glossary.attack_size}, but increase their {glossary.cooldown}',
				icon: 'upgrade-roost-boost',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				statBonuses: [
					[['PhoenixBombs'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
					[['PhoenixBombs'], StatType.cooldownInterval, StatOperator.SUM_THEN_MULTIPLY, 0.25],
					[['PhoenixBombs'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				]
			},
			{
				name: 'Living Bombs',
				desc: `Phoenix Bombs: Greatly increase {glossary.attack_size}, and enemies that have {glossary.ignite} will explode on death!`,
				icon: 'upgrade-living-bombs',
				affectedEntities: [AffectedEntities.Player],
				binaryFlags: ['ignite-explodes'],
				statBonuses: [
					[['PhoenixBombs'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.40],
				]
			},
			{
				name: 'Heat Index Warning',
				desc: 'Phoenix Bombs: Massively increase {glossary.damage}, slightly increase {glossary.ignite} potency, and double the duration of {glossary.ignite}',
				icon: 'upgrade-index-warning',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				binaryFlags: ['double-ignite-duration'],
				statBonuses: [
					[['PhoenixBombs'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.6],
					[['PhoenixBombs'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 0.2],
				]
			},
		],
	},

	'phoenixRebirthExtras': {
		name: "Phoenix Bomb Rebirth Extras",
		desc: 'Unlock a further set of upgrades',
		shape: "line",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Ember Charm',
				desc: 'Phoenix Bombs: Slightly increase both {glossary.ignite} chance and potency, slightly decrease {glossary.cooldown}',
				icon: 'upgrade-ember-charm',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				statBonuses: [
					[['PhoenixBombs'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 0.2],
					[['PhoenixBombs'], StatType.igniteChance, StatOperator.SUM_THEN_MULTIPLY, 0.2],
					[['PhoenixBombs'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.1],
				]
			},
			{
				name: 'Quick Temper',
				desc: 'Phoenix Bombs: Slightly decrease {glossary.cooldown} and shorten fuse duration, moderately increase {glossary.attack_size}',
				icon: 'upgrade-quick-temper',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				statBonuses: [
					[['PhoenixBombs'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.25],
					[['PhoenixBombs'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.15],
				],
				simpleApplyFn(player: Player, state) {
					const phoenixWeapon = player.getWeapon(AllWeaponTypes.PhoenixBombs) as PhoenixBombs
					phoenixWeapon.reduceFuseTime(0.16666) // This needs to be positive as it is a subtractive multiplier in the formula.
				},
			},
			{
				name: 'Conflagration',
				desc: 'Phoenix Bombs: Moderately increase {glossary.damage} and {glossary.ignite} potency, and when an enemy with {glossary.ignite} dies, the {glossary.ignite} spreads to 4 nearby enemies',
				icon: 'upgrade-conflagration',
				affectedEntities: [AffectedEntities.PhoenixBombs],
				binaryFlags: ['ignite-spreads-on-death'],
				statBonuses: [
					[['PhoenixBombs'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
					[['PhoenixBombs'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				]
			},
		],
	},


}

export const SECONDARY_WEAPON_UNLOCKS = {}
{
	for (const key in SECONDARY_WEAPON_UPGRADES) {
		if (key.endsWith('Unlock')) {
			const value = SECONDARY_WEAPON_UPGRADES[key]
			SECONDARY_WEAPON_UNLOCKS[key] = value
		}
	}
}
