export enum AllWeaponTypes {
	Enemy = -1,
	Unknown = -1,
	None = 0, // empty skill/passive

	// primary
	Bow,
	Wand,
	Boomerang,
	Spear,

	// secondary
	TeslaCoil,
	AcidBottles,
	RatParade,
	NikolaScope,
	ConeDogThorns,
	Bees,
	Lute,
	DarkStormyNight,
	FireFairies,
	PhoenixBombs,

	// skills
	ElfRangerSkill,
	BarbarianSkill,
	DogSkill,
	SolaraSkill,
	RadarSkill,

	// passives
	ElfRangerPassive,
	BarbarianPassive,
	SolaraPassive,
	RadarPassive,

	// system
	DamageOverTime,
	WorldHazard,
	Pet,
}

export enum PrimaryWeaponType {
	None = AllWeaponTypes.None,
	Bow = AllWeaponTypes.Bow,
	Wand = AllWeaponTypes.Wand,
	Boomerang = AllWeaponTypes.Boomerang,
	Spear = AllWeaponTypes.Spear,
}


export const WeaponConfig: {
	[k in AllWeaponTypes]: {
		dbName: string,
		name: string,
		description: string,
		icon: string,
		primary?: {
			assetName: string,
			evolveDescription1: string,
			evolveDescription2: string,
			evolveDescription3: string,
		},
	}
} = {
	[AllWeaponTypes.Enemy]: {
		dbName: "enemy",
		name: "Enemy",
		description: "",
		icon: "",
	},
	[AllWeaponTypes.Unknown]: {
		dbName: "unknown",
		name: "Unknown",
		description: "",
		icon: "",
	},
	[AllWeaponTypes.None]: {
		dbName: "none",
		name: "None",
		description: "",
		icon: "",
	},
	[AllWeaponTypes.WorldHazard]: {
		dbName: "worldHazard",
		name: "World Hazard",
		description: "",
		icon: "",
	},
	[AllWeaponTypes.DamageOverTime]: {
		dbName: "damageOverTime",
		name: "Damage-over-time",
		description: "",
		icon: "perk-ailment-potency-bonus", //@TODO real icon
	},
	[AllWeaponTypes.Bow]: {
		dbName: "bow",
		name: "Bow",
		description: "Charge up and release powerful arrows that pierce through targets and deal incredible damage.",
		icon: "primary-bow",
		primary: {
			assetName: "weapon-bow",
			evolveDescription1: "Longbow: Crashing power and fierce forces let you stagger and blow away your foes. ",
			evolveDescription2: "Assassin's Arch: High speed and piercing let this bow carve lines of death across the field.",
			evolveDescription3: "Storm Breaker: One arrow becomes a dozen as you charge this bow beyond normal limits."
		},
	},
	[AllWeaponTypes.Wand]: {
		dbName: "wand",
		name: "Wand",
		description: "Rapidly fire magical blasts to pelt enemies, then reload and do it again.",
		icon: "primary-wand",
		primary: {
			assetName: "weapon-wand",
			evolveDescription1: "Markswitch: Precision & accuracy will grant you stacking bonuses and unleash elemental powers.",
			evolveDescription2: "Tactical Strike: 3-round burst fire will make quick work of enemies, stacking damage-over-time and debuffs along the way.",
			evolveDescription3: "Arcane Rain: Double down on ultra-fast firing with massive magical reserves. Unleash dozens of shots before reloading."
		},
	},
	[AllWeaponTypes.Boomerang]: {
		dbName: "boomerang",
		name: "Boomerang",
		description: "Toss boomerangs that sweep through enemies dealing damage, then return to you dealing damage again.",
		icon: "primary-boomerang",
		primary: {
			assetName: "weapon-boomerang",
			evolveDescription1: "Chakram: Rapidly throw 'rangs that alternate directions, travel fast, and inflict elemental ailments.",
			evolveDescription2: "Outback Boomerang: Throw 'rangs that hover in the area, dealing damage continuously until they return.",
			evolveDescription3: "Coming soon!",
		},
	},
	[AllWeaponTypes.Spear]: {
		dbName: "spear",
		name: "Spear",
		description: "Thrust to attack with the spear, dealing powerful damage to all enemies in a line.",
		icon: "primary-spear",
		primary: {
			assetName: "weapon-spear",
			evolveDescription1: "Naginata: Sweeping Strikes and clever movement lead you to victory. ",
			evolveDescription2: "Grand Pike: Pure force and area control dominate the battlefield.",
			evolveDescription3: "Holy Spear: Precision and elemental effects let this spear provide judgement to the evil forces.",
		},
	},
	[AllWeaponTypes.TeslaCoil]: {
		dbName: "teslaCoil",
		name: "Tesla Coil",
		description: "",
		icon: "secondary-tesla-coil",
	},
	[AllWeaponTypes.DarkStormyNight]: {
		dbName: "darkStormyNight",
		name: "Dark Stormy Night",
		description: "",
		icon: "secondary-dark-stormy-night",
	},
	[AllWeaponTypes.AcidBottles]: {
		dbName: "poisonBottles",
		name: "Acid Bottles",
		description: "",
		icon: "secondary-acid-bottle",
	},
	[AllWeaponTypes.RatParade]: {
		dbName: "ratParade",
		name: "Rat Parade",
		description: "",
		icon: "secondary-rat-parade",
	},
	[AllWeaponTypes.NikolaScope]: {
		dbName: "nikolaScope",
		name: "Nikola's Scope",
		description: "",
		icon: "secondary-nikolas-scope",
	},
	[AllWeaponTypes.ConeDogThorns]: {
		dbName: "coneDogThorns",
		name: "Thorns",
		description: "",
		icon: "secondary-cone-dog-thorns",
	},
	[AllWeaponTypes.Bees]: {
		dbName: "bees",
		name: "Bees",
		description: "",
		icon: "secondary-bees",
	},
	[AllWeaponTypes.Lute]: {
		dbName: "lute",
		name: "Lute",
		description: "",
		icon: "secondary-lute",
	},
	[AllWeaponTypes.FireFairies]: {
		dbName: "fireFairies",
		name: "Fire Fairies",
		description: "",
		icon: "secondary-fire-fairy",
	},
	[AllWeaponTypes.PhoenixBombs]: {
		dbName: "phoenixBombs",
		name: "Phoenix Bombs",
		description: "",
		icon: "secondary-phoenix-bombs",
	},
	[AllWeaponTypes.ElfRangerSkill]: {
		dbName: "rangerTrap",
		name: "Ranger's Trap",
		description: "",
		icon: "skills-thorn-trap",
	},
	[AllWeaponTypes.BarbarianSkill]: {
		dbName: "barbarianRoll",
		name: "Fearsome Roar",
		description: "",
		icon: "skills-tumble-roll",
	},
	[AllWeaponTypes.DogSkill]: {
		dbName: "chomp",
		name: "Chomp",
		description: "",
		icon: "skills-chomp",
	},
	[AllWeaponTypes.SolaraSkill]: {
		dbName: "solaraChange",
		name: "Change Aspect",
		description: "",
		icon: "skills-aspect",
	},
	[AllWeaponTypes.RadarSkill]: {
		dbName: "radarSkill",
		name: "Shock Pulse",
		description: "",
		icon: "skills-pulse-shock"
	},
	[AllWeaponTypes.RadarPassive]: {
		dbName: "radarPassive",
		name: "FieldOp™ Gadget",
		description: "",
		icon: "skills-tracker"
	},
	[AllWeaponTypes.ElfRangerPassive]: {
		dbName: "rangerMark",
		name: "Ranger's Mark",
		description: "",
		icon: "skills-tracker",
	},
	[AllWeaponTypes.BarbarianPassive]: {
		dbName: "barbarianAura",
		name: "Barbarian Aura",
		description: "",
		icon: "character-barb",
	},
	[AllWeaponTypes.SolaraPassive]: {
		dbName: "aspectAttunement",
		name: "Aspect Attunement",
		description: "",
		icon: "skills-aspect",
	},
	[AllWeaponTypes.Pet]: {
		dbName: "pets",
		name: "Pets",
		description: "",
		icon: "secondary-pets",
	},
}

export enum SkillWeaponType {
	NoSkill = AllWeaponTypes.None,
	ElfRangerSkill = AllWeaponTypes.ElfRangerSkill,
	BarbarianSkill = AllWeaponTypes.BarbarianSkill,
	RadarSkill = AllWeaponTypes.RadarSkill
}

export enum SecondaryWeaponType {
	TeslaCoil = AllWeaponTypes.TeslaCoil,
	AcidBottles = AllWeaponTypes.AcidBottles,
	RatParade = AllWeaponTypes.RatParade,
	NikolaScope = AllWeaponTypes.NikolaScope,
	ConeDogThorns = AllWeaponTypes.ConeDogThorns,
	Bees = AllWeaponTypes.Bees,
	Lute = AllWeaponTypes.Lute,
	DarkStormyNight = AllWeaponTypes.DarkStormyNight,
	FireFairies = AllWeaponTypes.FireFairies,
	PhoenixBombs = AllWeaponTypes.PhoenixBombs
}