<template>
	<Panel id="vote-progress" :panel-identifier="'levelUp'" :height="-1">
		<template #panel-content>
			<div v-if="getIsPlayerWinner || getPlayerMeetsVoteThreshold" class="win-vote">
				<Header :header-text="'Vote Progress'" :text-alignment="'center'" :font-face="'pixl'" :font-size="'large'" :enable-drop-shadow="true"></Header>
				<div class="row-margin">
					<ProgressBarWrapper :class="'huge green swipe twinkle glow'" :value="`100`" :max-value="`100`" :subtitle="''" :title="''"></ProgressBarWrapper>
				</div>
				<div class="text-body" :lang="this.$i18n.locale">
					{{ $t('end_chapter.vote_earned_vote') }}<br />
					{{ $t('end_chapter.vote_plot_twist_explanation') }}<br />
					{{ $t('end_chapter.vote_place_cta') }}<br />
				</div>
				<div class="rolled-twists">
					<ItemUpdated
						v-for="(twist, index) in indexedTwists"
						:key="index"
						:item="twist"
						:disable-circle="true"
						:small-icons="false"
						:enable-glow="true"
						:selected="bookSelected(twist)"
						:on-click-prop="updateSelectedTwist"
						:disable-book="false"
						:component="'twist'"
						:show-book-background="true"
						:tooltip-enabled="true"
					></ItemUpdated>
				</div>
			</div>
			<div v-else class="loss-vote">
				<div class="row-margin">
					<ProgressBarWrapper :class="'huge green'" :value="voteScore" :max-value="voteScoreThreshold" :subtitle="''" :title="'Vote Progress'"></ProgressBarWrapper>
				</div>
				<div class="text-body" :lang="this.$i18n.locale">
					{{ $t('end_chapter.vote_not_earned') }}<br />
					<br />
					<div class="rolled-twists">
						<ItemUpdated v-for="(twist, index) in indexedTwists" :key="index" :item="twist" :disable-circle="true" :small-icons="false" :enable-glow="true" :disable-book="false" :component="'twist'" :show-book-background="true" :tooltip-enabled="true"> </ItemUpdated>
					</div>
					<br />
					{{ $t('end_chapter.vote_try_again') }}<br />
				</div>
			</div>
			<div class="button-container">
				<TextButton :button-label="$t('vote_progress.button_book_report')" :button-style="'green'" @on-click-fn="showBookReview" :disabled="bookReviewPosted" :noWrap="true"></TextButton>
				<TextButton v-if="getIsPlayerWinner || getPlayerMeetsVoteThreshold" :button-label="$t('vote_progress.button_skip')" :button-style="'green'" @on-click-fn="showSkipVotePopUp" :noWrap="true"></TextButton>
				<TextButton v-if="getIsPlayerWinner || getPlayerMeetsVoteThreshold" :button-label="$t('vote_progress.button_confirm')" :button-style="'bright-green'" @on-click-fn="submitVote" :disabled="!selectedTwist"></TextButton>
				<TextButton v-else :button-label="$t('vote_progress.button_confirm')" :button-style="'bright-green'" @on-click-fn="dismissVoteModal"></TextButton>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import ProgressBarWrapper from '../ui-components/bars-sliders/progress-bar-wrapper.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import { showGenericInfoPromptUI } from '../store/generic-prompt'
import { saveToLocalStorage } from '../../utils/local-storage'
import { NUMBER_OF_RUNS_LOCAL_KEY } from '../store/end-chapter-store'
import { STEAM_PARAMS, STEAM_URL } from '../steam-links'
import { showGenericYesNoUI } from '../store/generic-two-btn-prompt-store'

export default {
	name: 'VoteProgress',
	components: {
		Header,
		ProgressBarWrapper,
		Panel,
		ItemUpdated,
		TextButton,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('endChapter', ['indexedTwists', 'bookReviewPosted', 'voteScore', 'voteScoreThreshold', 'selectedTwist']),
		...mapGetters('endChapter', ['getIsPlayerWinner', 'getPlayerMeetsVoteThreshold', 'queueWishlistPrompt']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('endChapter', ['updateShowVoteProgressModal', 'updateShowBookReviewModal', 'updateSelectedTwist', 'setQueueWishlistPrompt']),
		...mapMutations('ui', ['updateDarkenOverlay']),
		...mapActions('endChapter', ['postMutatorVote', 'fetchMutators']),
		returnBookReport() {
			this.$emit('return-book-report')
		},
		showBookReview() {
			this.updateShowBookReviewModal(true)
			this.updateShowVoteProgressModal(false)
		},
		bookSelected(twist) {
			if (this.selectedTwist != null || this.selectedTwist != undefined) {
				return twist.id == this.selectedTwist.id
			}
		},
		submitVote() {
			this.closeModal()
			this.postMutatorVote()
			this.fetchMutators()
		},
		dismissVoteModal() {
			this.closeModal()
		},
		showSkipVotePopUp() {
			this.updateShowVoteProgressModal(false)

			showGenericYesNoUI(
				'vote_progress.skip_warning_title',
				'vote_progress.skip_warning_description',
				'vote_progress.button_skip',
				'vote_progress.button_cancel',
				() => {
					this.closeModal()
				},
				() => {
					this.updateShowVoteProgressModal(true)
				},
			)
		},
		closeModal() {
			this.updateShowVoteProgressModal(false)
			this.updateDarkenOverlay(false)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#vote-progress {
	width: 780px;
	:deep(.panel-inner) {
		border-width: 46px 28px 70px;
	}

	.loss-vote {
		.text-body {
			margin: 40px 0px;
			.twist-container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				margin: 20px;
			}
		}
	}

	.win-vote,
	.loss-vote {
		.row-margin {
			margin: 15px 0px;
		}

		.text-body {
			margin: 20px 0px;
			text-align: center;
			color: @offWhite;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 24px;
			}
		}

		.rolled-twists {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			.item {
				margin: 15px;
			}
		}
	}
	.loss-vote {
	}
	.button-container {
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		margin: 20px 0px;
		.text-button {
			margin: 0px 15px;
			width: 230px;
		}
	}
}
</style>
