<template>
	<div id="scrollable-items">
		<div class="arrow-start" :class="{ 'arrow-background': enableArrowBackground }">
			<button v-if="showScrollButtons" @click="$emit('onClickFn', 'left')">
				<img src="../../../../assets/ui/buttons/settings-arrow-left.png" width="36" height="38" alt="" />
			</button>
		</div>
		<div class="arrow-middle" :class="{ 'arrow-background': enableArrowBackground }">
			<slot name="arrow-content"></slot>
		</div>
		<div class="arrow-end"  :class="{ 'arrow-background': enableArrowBackground }">
			<button v-if="showScrollButtons" @click="$emit('onClickFn', 'right')">
				<img src="../../../../assets/ui/buttons/settings-arrow-right.png" width="36" height="38" alt="" />
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'ScrollableItemsContainer',
	components: {},
	data() {
		return {}
	},
	props: {
		showScrollButtons: {
			type: Boolean,
			required: false,
			default: true,
		},
		enableArrowBackground: {
			type: Boolean,
			required: false,
			default: false,
		},
		disableButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
#scrollable-items {
	position: relative;
	display: flex;
	// margin: 33px 0px;
	.arrow-start {
		position: relative;
		&.arrow-background {
			background-image: url('~/assets/ui/backgrounds/arrow-background/bg-banner-start.png');
		}

		width: 89px;
		height: 108px;
		background-repeat: no-repeat;
		button {
			position: absolute;
			top: 35px;
			right: 6px;
		}
	}
	.arrow-middle {
		&.arrow-background {
			background-image: url('~/assets/ui/backgrounds/arrow-background/bg-banner-middle.png');
		}
		width: auto;
		height: 108px;
		display: flex;
		align-items: center;
	}
	.arrow-end {
		position: relative;
		&.arrow-background {
			background-image: url('~/assets/ui/backgrounds/arrow-background/bg-banner-end.png');
		}

		width: 89px;
		height: 108px;
		background-repeat: no-repeat;
		button {
			position: absolute;
			top: 35px;
			left: 8px;
		}
	}
}
</style>