import { ColliderType } from '../../../engine/collision/colliders'
import { EnemyBipedSkinType, SpineDataName } from '../../../spine-config/spine-config'
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, PersistedBehaviours, ShotLeadPrecision } from '../ai-types'
import { ENEMY_NAME } from '../enemy-names'
import { Vector } from 'sat'
import { EnemyDefaults } from './enemy-defaults'
import { CollisionLayerBits } from '../../../engine/collision/collision-layers'
import { PLAYER_DEFAULT_MOVEMENTSPEED } from '../../../game-data/player-formulas'

const abominableYeti: EnemyAI = {
	name: ENEMY_NAME.YETI,
	type: EnemyType.BOSS,
	objectPoolInitialSize: 1,
	objectPoolGrowthSize: 1,
	appearance: {
		asset: SpineDataName.YETI,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		useIdleAnim: true,
		mixSettings: [],
		scale: 1,
		zOffset: 200,
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Box,
				width: 250,
				height: 275,
				position: [-95, 0],
			},
		],
		colliderLayer: CollisionLayerBits.Enemy,
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.6,
			maxHealth: 65,
			stunChance: 1,
			stunPotency: 1,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		immuneToRecycle: true,
		avoidanceRadius: 50,
		knockbackImmune: true,
		animationSpeeds: 0.25,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHARGE_ATTACK,
				params: {
					chargeLerpSpeed: [{ targetSpeedMult: 4.25, maxTime:  5}],
					holdDirectionTime: 0.85, // How long we must spend charging at minimum (can be 0)
					holdDirectionDistance: 1200, // how far away from the player we must be, before our charge is over
					aimTime: 1.85, // how long they spend standing still aiming
				},
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 700,
			engagementMinDistance: 0,
			modelCenterOffset: 60,
			movementMaxDistance: 750, // how close should the enemy be before charging?
			movementMinDistance: 130,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default abominableYeti
