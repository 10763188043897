<template>
	<div id="checkbox" :class="{ inline: inlineLabel }" @click="$emit('onClickFn', optionalParam)">
		<div class="checkbox"  :class="{ error: error }">
			<div class="check-mark" :class="{ active: checked }"></div>
		</div>
		<div class="label" :lang="this.$i18n.locale" :style="[labelFontSize]">{{ $t(label) }}</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
const FONT_SIZE = ['xSmall', 'small', 'medium', 'large']
export default {
	name: 'Checkbox',
	components: {},
	data() {
		return {
			fontSizes: {
				xSmall: { fontSize: '24px' },
				small: { fontSize: '32px' },
				medium: { fontSize: '40px' },
				large: { fontSize: '50px' },
			},
		}
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		inlineLabel: {
			type: Boolean,
			required: true,
			default: true,
		},
		checked: {
			type: Boolean,
			required: true,
			default: false,
		},
		optionalParam: {
			type: Object,
			required: false,
			default: null,
		},
		error: {
			type: Boolean,
			required: false,
		},
		fontSize: {
			type: String,
			required: true,
			default: 'small',
			validator: function (value) {
				return FONT_SIZE.includes(value)
			},
		},
	},
	computed: {
		// ...mapGetters('ui', ),
		labelFontSize() {
			if (this.fontSizes.hasOwnProperty(this.fontSize)){
				return this.fontSizes[this.fontSize]
			}
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#checkbox {
	display: flex;
	flex-direction: column;
	align-items: center;
	&.inline {
		flex-direction: row;

		.label {
			margin-left: 15px;
		}
	}
	.checkbox {
		width: 35px;
		height: 35px;
		background-color: #040e26;
		position: relative;

		&.error {
			border: 2px solid red;
		}

		.check-mark {
			position: relative;
			width: 44px;
			height: 40px;
			left: -6px;
			top: -3px;
			transition: 150ms;
			background-image: url('../../../../assets/ui/checkmark/toggle-skull.png');
			background-size: cover;
			opacity: 0;
			&.active {
				opacity: 1;
			}
		}
	}
	.label {
		align-items: center;
		color: @offWhite;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
}
</style>
