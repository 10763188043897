import { Buff } from "../buffs/buff"
import { BuffIdentifier } from "../buffs/buff.shared"
import { GameState } from "../engine/game-state"
import { POI } from "../pois/poi"
import { distanceSquaredVV } from "../utils/math"
import { timeInSeconds } from "../utils/primitive-types"
import { InGameTime } from "../utils/time"
import { EventTypes } from "./event-types"
import { CombatArenaGameplayEventPresets } from "./gameplay-event-definitions"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"
import { ShrineGameplayEvent } from "./shrine-gameplay-event"

const BUFF_REWARDS: BuffIdentifier[] = [
    BuffIdentifier.CombatArenaAttackRate, // attack rate, charge rate, reload rate 
    BuffIdentifier.CombatArenaDamage, // damage and size
    BuffIdentifier.CombatArenaMovement, // movement speed and pickup radius
]
const REMOVE_SHRINE_DIST_2 = 2_000 ** 2

export class CombatArenaGameplayEvent extends ShrineGameplayEvent {
    constructor() {
        super()
        this.poiParams.lockPlayerInZone = true
    }

	override setRandomPreset() {
        const key = Object.keys(CombatArenaGameplayEventPresets).pickRandom()
		this.eventPresetConfig = CombatArenaGameplayEventPresets[key]
    }

    update(delta: timeInSeconds) {
        if (!this.activePoi) {
            return
        }

        const now = InGameTime.timeElapsedInSeconds
        const playerPos = GameState.player.position 

        if (!this.activePoi.poiEnding && now > this.activePoi.timeStarted + this.eventStartData.timeLimit) {
            // failure, but don't remove unless off the screen
            const dist = distanceSquaredVV(playerPos, this.activePoi.position)
            if (dist > REMOVE_SHRINE_DIST_2) {
                this.activePoi.onComplete(false)
            }
        }
    }

    override onEventDoneInternal(poi: POI) {
		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.CombatArena)

        if (poi.playerWon) {
            Buff.apply(BUFF_REWARDS.pickRandom(), this, GameState.player)
        }

        this.activePoi = null
    }
}
