import { TeslaCoilHazard } from "../../../entities/hazards/tesla-coil-hazard"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { AllWeaponTypes, SecondaryWeaponType } from "../../weapon-types"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"

const TESLA_COIL_BASE_DATA = {
    activationTime: 0.5,
    effectRadius: 340,
    triggerRadius: 340,
    secondsPerAttack: 2,


    expiryTime: 5,
    removalTime: 0,
}

export class TeslaCoilWeapon extends AutoFireSecondaryWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.TeslaCoil
    
    fire() {
        // place a thing at player's position
        TeslaCoilHazard.pool.alloc({
            weaponStatList: this.statList,
            position: this.player.position,
            activationTime: TESLA_COIL_BASE_DATA.activationTime,
            effectRadius: this.statList.getStat(StatType.attackSize),
            triggerRadius: TESLA_COIL_BASE_DATA.triggerRadius * this.statList.getStat(StatType.projectileChainDistanceMultiplier),

            expiryTime: TESLA_COIL_BASE_DATA.expiryTime * this.statList.getStat(StatType.skillDuration),
            removalTime: TESLA_COIL_BASE_DATA.removalTime,
            secondsPerAttack: 1 / this.statList.getStat(StatType.attackRate),
            weaponType: this.weaponType
        })
    }
    
    forceStopFiring() {
        // don't need to do anything
    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
        statList._actualStatValues.projectileChainCount = 3
        statList._actualStatValues.projectileSplitCount = 0
        statList._actualStatValues.projectileCount = 1

        statList._actualStatValues.skillDuration = 1
        statList._actualStatValues.projectileChainDistanceMultiplier = 1
		statList._actualStatValues.attackSize = TESLA_COIL_BASE_DATA.effectRadius

        statList._actualStatValues.baseDamage = 10
        statList._actualStatValues.allDamageMult = 1
        statList._actualStatValues.attackRate = 0.666666
        

        statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 6_250
		statList._actualStatValues.reloadInterval = 50
    }
}