import { Beam } from './beams'
import { timeInSeconds } from '../utils/primitive-types'
import { Effect } from '../engine/graphics//pfx/effect'
import { Renderer } from '../engine/graphics/renderer'

export const BEAM_WIDTH_INVERSE = 1 / 66

export enum BeamConfigType {
	NikolaScopePreFire,
	NikolaScopeFiring,
}

interface BeamConfig {
	beamOutTime: number
	mildPulseMag: number,
	mildPulseFreq: number,
	majorPulseTimeSinceDamage: number,
	majorPulseMag: number,
	startScaleAdjust: number,
	zOffset: number,
	debugDrawing: false,
	startAssetName?: string,
	middleAssetName?: string,
	endAssetName?: string,
	color?: number // not hooked up at the moment
}

const BEAM_CONFIGS: Record<BeamConfigType, BeamConfig> = {
	[BeamConfigType.NikolaScopePreFire]: {
		beamOutTime: 0.1,
		mildPulseMag: 0.1,
		mildPulseFreq: 10,
		majorPulseTimeSinceDamage: 0.1,
		majorPulseMag: 1.5,
		startScaleAdjust: 1,
		zOffset: 270,
		debugDrawing: false,
		// startAssetName: 'beam-start',
		middleAssetName: 'nikola-pre-fire-beam',
		// endAssetName: 'beam-end',
	},
	[BeamConfigType.NikolaScopeFiring]: {
		beamOutTime: 0.1,
		mildPulseMag: 0.1,
		mildPulseFreq: 10,
		majorPulseTimeSinceDamage: 0.1,
		majorPulseMag: 1.5,
		startScaleAdjust: 1,
		zOffset: 270,
		debugDrawing: false,
		startAssetName: 'nikola-beam-start',
		middleAssetName: 'nikola-beam-center',
		endAssetName: 'nikola-beam-end',
	},
}

export class BeamGraphics {
	private effectStart: Effect
	private effectCenter: Effect
	private effectEnd: Effect
	private timeAlive: number = 0

	private ownerBeam: Beam

	isInScene: boolean = false

	private beamConfig: BeamConfig

	constructor(beam: Beam) {
		this.ownerBeam = beam

		// const affector: AffectorConfig = {
		// 	id: 'scale',
		// 	cfg: { mode: ScaleAffectorMode.InheritEmitter },
		// }

		// this.effectEnd.emitters.forEach((e) => {
		// 	e.addAffector(affector)
		// })
	}

	addToScene(configType: BeamConfigType) {
		this.beamConfig = BEAM_CONFIGS[configType]

		if (this.beamConfig.startAssetName) {
			this.effectStart = Renderer.getInstance().addEffectToScene(this.beamConfig.startAssetName, this.ownerBeam.x, this.ownerBeam.y)
			this.effectStart.prewarm()
			this.effectStart.zIndex = -222_220

		}

		if (this.beamConfig.middleAssetName) {
			this.effectCenter = Renderer.getInstance().addEffectToScene(this.beamConfig.middleAssetName, this.ownerBeam.x, this.ownerBeam.y)
			this.effectCenter.prewarm()
			this.effectCenter.zIndex = -222_221

		}

		if (this.beamConfig.endAssetName) {
			this.effectEnd = Renderer.getInstance().addEffectToScene(this.beamConfig.endAssetName, this.ownerBeam.x, this.ownerBeam.y)
			this.effectEnd.prewarm()
			this.effectEnd.zIndex = -222_220
		}

		// if (this.effectCenter.emitters.length) {
		// 	this.effectCenter.emitters[0].startColor = ColorUtil.toRGB(this.ownerBeam.color)
		// 	this.effectCenter.emitters[0].startColor.a = this.ownerBeam.alpha
		// }
		
		this.update(this.ownerBeam, 0)
		this.isInScene = true
	}

	update(beam: Beam, dt: timeInSeconds) {
		// if (this.effectCenter.emitters.length) {
		// 	this.effectCenter.emitters[0].startColor = ColorUtil.toRGB(beam.color)
		// 	this.effectCenter.emitters[0].startColor.a = beam.alpha
		// }

		const angle = beam.angle
		const length = beam.length * Math.min(1, this.timeAlive / this.beamConfig.beamOutTime)

		const offsetx = Math.cos(angle) * length
		const offsety = Math.sin(angle) * length

		const width = beam.width
		const beamWidth = width * (1 + Math.sin(this.timeAlive * this.beamConfig.mildPulseFreq * Math.PI * 2) * this.beamConfig.mildPulseMag)

		if (this.effectStart) {
			this.effectStart.x = beam.x
			this.effectStart.y = beam.y
			this.effectStart.scale = beam.width * BEAM_WIDTH_INVERSE * this.beamConfig.startScaleAdjust
		}
		
		if (this.effectEnd) {
			this.effectEnd.x = beam.x + offsetx
			this.effectEnd.y = beam.y + offsety
			this.effectEnd.scale = beam.width * BEAM_WIDTH_INVERSE * this.beamConfig.startScaleAdjust
		}
		
		// const hitScale = beam.timeSinceDamage < beamConfig.majorPulseTimeSinceDamage ? beamConfig.majorPulseMag : 1
		// this.effectEnd.scale = Math.clamp(width * BEAM_WIDTH_INVERSE * hitScale, 0.1, 1.25)

		if (this.effectCenter) {
			this.effectCenter.x = beam.x + offsetx * 0.5
			this.effectCenter.y = beam.y + offsety * 0.5
			this.effectCenter.scaleX = length * BEAM_WIDTH_INVERSE
			this.effectCenter.scaleY = beamWidth * BEAM_WIDTH_INVERSE
			this.effectCenter.rot = beam.angle
		}

		// const minZ = Math.max(this.effectCenter.y, this.effectStart.y, this.effectEnd.y) + beamConfig.zOffset

		this.timeAlive += dt
	}

	removeFromScene() {
		if (this.effectStart) {
			Renderer.getInstance().removeEffectFromScene(this.effectStart)
			this.effectStart = null
		}

		if (this.effectCenter) {
			Renderer.getInstance().removeEffectFromScene(this.effectCenter)
			this.effectCenter = null
		}

		if (this.effectEnd) {
			Renderer.getInstance().removeEffectFromScene(this.effectEnd)
			this.effectEnd = null
		}

		this.isInScene = false

	}
}
