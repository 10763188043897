import { GameState } from '../engine/game-state'
import { VictoryDeathManager } from '../engine/victory-death-manager'
import { UI } from '../ui/ui'
import { debugtool } from './decorators'
import { percentage, timeInMilliseconds, timeInSeconds } from './primitive-types'

export const FRAME_TIMES: number[] = []
export let STORE_FRAME_TIMES: boolean = false

export const GAMEPLAY_SPEED_MULTIPLIER: percentage = 1.2

export const MAX_DELTA_MS: timeInMilliseconds = 100
export const ACT_TIMER = {
	ACT_DURATION: 300,
	ACT_1_START_TIME: 0,
	ACT_1_INTRO_BANNER_TIME: 5,
	ACT_1_END_TIME: 300,
	ACT_2_START_TIME: 301,
	ACT_2_END_TIME: 600,
	ACT_3_START_TIME: 601,
	ACT_3_END_TIME: 900,
	INSANITY_START_TIME: 1100,
	WIN_TIME_IN_SECONDS: 1080,
}

export function setStoreFrameTimes(store: boolean) {
	STORE_FRAME_TIMES = store
}
export class InGameTime {
	private static timePaused: number
	private static pauseStart: timeInMilliseconds
	private static paused: boolean
	private static initializedAt
	private static inGamePauseStart: timeInMilliseconds
	private static initialized: boolean
	static timeSinceLastCountdownTick = 0
	static timeScale = 1.0
	static timeAccumulated: timeInMilliseconds = 0

	static get currentAct() {
		// return Math.ceil(this.timeElapsedInSeconds / 300) // I mean this is way shorter but...
		if (this.timeElapsedInSeconds.between(ACT_TIMER.ACT_1_START_TIME, ACT_TIMER.ACT_1_END_TIME)) {
			return 1
		} else if (this.timeElapsedInSeconds.between(ACT_TIMER.ACT_2_START_TIME, ACT_TIMER.ACT_2_END_TIME)) {
			return 2
		} else if (this.timeElapsedInSeconds.between(ACT_TIMER.ACT_3_START_TIME, ACT_TIMER.ACT_3_END_TIME)) {
			return 3
		}
	}

	static start() {
		this.timePaused = 0
		this.initialized = true
		this.initializedAt = performance.now()
		// console.log(`InGameStart.start() ${performance.now()}`)
	}

	static pause() {
		if (!this.initialized) {
			return
		}
		// console.log(`InGameStart.PAUSE(${this.paused}) ${performance.now()}`)
		if (!this.paused) {
			this.pauseStart = performance.now()
			this.inGamePauseStart = this.highResolutionTimestamp()
			this.paused = true

			UI.getInstance().emitMutation('time/setIsPaused', true)
		}
	}

	static unpause() {
		if (!this.initialized) {
			return
		}
		// console.log(`InGameStart.UNPAUSE(${this.paused}) ${performance.now()}`)
		if (this.paused) {
			const now = performance.now()
			this.timePaused += now - this.pauseStart
			this.paused = false

			UI.getInstance().emitMutation('time/setIsPaused', false)
		}
	}

	static highResolutionTimestamp(): timeInMilliseconds {
		return this.timeAccumulated
	}

	static get timeElapsedInSeconds(): timeInSeconds {
		return this.highResolutionTimestamp() / 1000
	}

	static get wholeSecondsPassed(): timeInSeconds {
		return Math.floor(this.timeElapsedInSeconds)
	}

	static getCurrentAct(): number {
		if (this.timeElapsedInSeconds <= ACT_TIMER.ACT_1_END_TIME) {
			return 1
		} else if (this.timeElapsedInSeconds <= ACT_TIMER.ACT_2_END_TIME) {
			return 2
		} else if (this.timeElapsedInSeconds <= ACT_TIMER.ACT_3_END_TIME) {
			return 3
		}
		return 0
	}

	static percentOfRunPassed(): percentage {
		const now = InGameTime.highResolutionTimestamp()

		return Math.clamp((now / 1000) / ACT_TIMER.ACT_3_END_TIME, 0, 1)
	}

	static isInitialized() {
		return this.initialized
	}

	static reset() {
		InGameTime.timePaused = 0
		InGameTime.pauseStart = 0
		InGameTime.paused = false
		InGameTime.initializedAt = undefined
		InGameTime.inGamePauseStart = 0
		InGameTime.initialized = false
		InGameTime.timeSinceLastCountdownTick =0
		InGameTime.timeScale = 1.0
		InGameTime.timeAccumulated = 0
	}

	@debugtool
	static debugAddSeconds(time: timeInSeconds) {
		this.timeAccumulated += time * 1_000
		this.initializedAt -= (time * 1_000) * 1 / this.timeScale
		this.timeSinceLastCountdownTick -= 1 * 1 / this.timeScale
	}

	@debugtool
	static debugSetTime(time: timeInSeconds) {
		const currentTime = this.timeAccumulated / 1_000
		const diff = (time - currentTime) * 1_000
		if (time < currentTime) {
			console.warn('We cannot time travel into the past, that would cause a paradox')
			return
		}
		this.timeAccumulated += diff
		this.initializedAt -= (diff) * 1 / this.timeScale
		this.timeSinceLastCountdownTick -= 1 * 1 / this.timeScale
	}

	@debugtool
	static dumpLog() {
		console.log({
			timeScale: this.timeScale,
			now: performance.now(),
			initializedAt: this.initializedAt,
			timePaused: this.timePaused,
			timeElapsedInSeconds: this.timeElapsedInSeconds,
		})
	}
}

export class RealTime {
	static currentFrame: number = 0
	static clampedDelta: timeInSeconds = 0
	static frameStartTimestamp: timeInMilliseconds
	static previousFrameStartTimestamp: timeInMilliseconds
	// static timeElapsedSinceStartup: timeInSeconds = 0 // not functional atm
}

export function realTimeHighResolutionTimestamp(): timeInMilliseconds {
	return performance.now()
}

export function updateCountdownTimer(): void {
	const wholeSecondsNow = InGameTime.wholeSecondsPassed
	if (wholeSecondsNow - InGameTime.timeSinceLastCountdownTick >= 1) {
		InGameTime.timeSinceLastCountdownTick = wholeSecondsNow
		const countdownToWin = secondsToPrettyString(Math.max(ACT_TIMER.ACT_3_END_TIME - wholeSecondsNow, 0))

		UI.getInstance().emitMutation('ui/updateCountdownToWin', countdownToWin)
	}

	// victory hack to auto-win after 3 minutes of bossfighting
	if (wholeSecondsNow >= ACT_TIMER.WIN_TIME_IN_SECONDS && !GameState.player.isDead() && !UI.getInstance().store.state.ui.roundWon) {
		VictoryDeathManager.victory(GameState.player)
	}
}

export function secondsToPrettyString(timeInSeconds: timeInSeconds): string {
	const minutes = Math.floor(Math.floor(timeInSeconds) / 60)
	const seconds = Math.floor(timeInSeconds % 60)
	const minuteString = minutes < 10 ? `0${minutes}` : `${minutes}`
	const secondString = seconds < 10 ? `0${seconds}` : `${seconds}`

	return `${minuteString}:${secondString}`
}

export function waitUntil(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}
