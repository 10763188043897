import logger from '../../utils/client-logger'
import { UI } from '../ui'

export interface PromptMessage {
	title: string
	description: string[]
	replacements?: any
	okButtonText?: string
	dimBackground?: boolean
}

interface PromptMessages extends Array<PromptMessage>{}

interface GenericPrompt {
	title: string
	description: string[]
	replacements?: any
	okButtonText?: string
	dimBackground?: boolean
	panelIdentifier?: string
	callback?: () => void
}
interface GenericPromptSequence {
	prompts: PromptMessages
	panelIdentifier?: string
	callback?: () => void
}

export const showGenericInfoPromptUI = (prompt: PromptMessage, overridePanelIdentifier?: string, callback?: () => void) => {
	const buttonText = prompt.okButtonText || 'vote_progress.button_confirm'
	const panelIdentifier = overridePanelIdentifier || 'genericPrompt'
	UI.getInstance().emitMutation('genericPrompt/showMenu', { 
		title: prompt.title, 
		description: prompt.description, 
		replacements: prompt.replacements,
		okButtonText: buttonText,
		panelIdentifier: panelIdentifier, 
		dimBackground: prompt.dimBackground ?? true,
		callback
	})

	UI.getInstance().emitMutation('ui/updateDarkenOverlay', true)
}

export const showGenericInfoPromptUISequence = (prompts: PromptMessage[], overridePanelIdentifier?: string, callback?: () => void) => {
	const panelIdentifier = overridePanelIdentifier || 'genericPrompt'
	UI.getInstance().emitMutation('genericPrompt/showPromptSequence', { prompts, panelIdentifier: panelIdentifier, callback})
	UI.getInstance().emitMutation('ui/updateDarkenOverlay', true)
}

const initialState = {
	prompts: [],
	promptVisible: false,
	panelIdentifier: 'genericPrompt',
	callback: () => {
		console.log(`default prompt handler`)
	},
}

const store = {
	namespaced: true,
	state: initialState, 
	getters: {
		getTitle(state: GenericPromptStoreState) {
			if (state.prompts.length > 0) {
				return state.prompts[0].title
			}
			return '404'
		},
		getDescription(state: GenericPromptStoreState) {
			if (state.prompts.length > 0) {
				return state.prompts[0].description
			}
			return ['404']
		},
		getPromptVisible(state: GenericPromptStoreState) {
			return state.promptVisible
		},
		getTranslationReplacements(state: GenericPromptStoreState) {
			if (state.prompts.length > 0) {
				return state.prompts[0].replacements
			}
			return null
		},
		getOkButtonText(state: GenericPromptStoreState) {
			if (state.prompts.length > 0) {
				const text = state.prompts[0].okButtonText
				if (text && text.length > 0) {
					return text
				}
			}
			return 'vote_progress.button_confirm'
		},
		getPanelIdentifier(state: GenericPromptStoreState) {
			return state.panelIdentifier
		},
		getPromptDimBackground(state: GenericPromptStoreState) {
			if (state.prompts.length > 0) {
				return state.prompts[0].dimBackground ?? true
			}
			return true
		},
	},
	mutations: {
		showMenu(state: GenericPromptStoreState, param: GenericPrompt) {
			const newPrompt = { title: param.title, description: param.description, okButtonText: param.okButtonText ?? 'vote_progress.button_confirm', dimBackground: param.dimBackground, replacements: param.replacements }
			state.panelIdentifier = param.panelIdentifier
			state.prompts = [newPrompt]

			state.callback = param.callback
			state.promptVisible = true
		},
		showPromptSequence(state: GenericPromptStoreState, param: GenericPromptSequence) {
			state.panelIdentifier = param.panelIdentifier
			state.prompts = param.prompts
			state.callback = param.callback

			state.promptVisible = true
		},
	},
	actions: {
		closePromptPanel({ state, commit } : {state: GenericPromptStoreState, commit: any}): void {
			state.prompts.shift()

			state.promptVisible = state.prompts.length > 0
			if (!state.promptVisible) {
				commit('ui/updateDarkenOverlay', false, { root: true })

				const oldCallback = state.callback
				state.callback = null
				if (oldCallback) {
					oldCallback()
				}
			}
		},
	},
}
export type GenericPromptStoreState = typeof initialState
export const GenericPromptStore = typeof store

export const genericPromptStore = () => {
	return store
}
