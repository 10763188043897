import { each, keys, values } from 'lodash'
import { UI } from '../ui/ui'
import '../utils/standard-library-additions'
import { Audio } from '../engine/audio'
import { GoogleAnalyticsHandler } from '../analytics/google-analytics'

// var spector = new SPECTOR.Spector();
// spector.displayUI();
// spector.spyCanvases();

const requiredEnvVars = {
	NODE_ENV: process.env.NODE_ENV,
}

console.log('Starting app with...')

each(requiredEnvVars, (v, k) => {
	console.log(`${k}`, v)
})

if (values(requiredEnvVars).includes(undefined)) {
	console.error(`Missing required environment variables, unable to start app. Required: ${keys(requiredEnvVars)}`)
	process.exit(1)
}

import { AssetManager } from './asset-manager'
import { LocalSettings } from '../settings/local-settings'
import { initSentry } from '../analytics/sentry'

async function init() {
	GoogleAnalyticsHandler.getInstance()
	// Initial UI creation, needs to be the first use of UI.getInstance()

	UI.getInstance().mount()

	UI.getInstance().emitAction('ui/fetchMaintenanceModeFlag')

	if (!process.env.IS_ELECTRON) { // When we are electron we call this when we have steam information
		UI.getInstance().emitAction('user/fetchInitialUserState', true)
	}

	UI.getInstance().emitAction('settings/fetchLocalWebSettings')

	// Initial Asset Loader creation, needs to be the first use of AssetManager.getInstance()
	AssetManager.getInstance(() => {
		UI.getInstance().emitAction('ui/finishedLoadingAssets')
		const url = new URL(window.location.toString())
		if (url.searchParams.get('skipToGame')) {
			UI.getInstance().emitAction('ui/startGame')
		}
	})

	LocalSettings.init()

	Audio.getInstance()

	initSentry()
}

init()