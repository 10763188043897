import { RiggedSpineModel } from "../engine/graphics/spine-model"
import { degToRad, radToDeg } from "../utils/math"

// flips the model and aims the bone base on rot
export const updateAimFacing = function (model: RiggedSpineModel, facingDirection: number, enemy: boolean) {
	const skeleton = model.skeleton

	if (enemy) {
		facingDirection *= -1
	}

	skeleton.scaleX = facingDirection
}

export const updateAimRotation = function (bone: PIXI.spine.core.Bone, rot: number, facingDirection: number) {
	if (facingDirection < 0) {
		rot = Math.PI - rot
	}

	bone.rotation = radToDeg(rot)
}

export const getFacingDirection = function (rot: number, currentFacing: number, overAimDegrees: number = 0) {
	const overAimRad = degToRad(overAimDegrees)
	const QC = Math.PI * 0.5 //Quarter circle

	if ((rot > QC + overAimRad || rot < -QC - overAimRad) && currentFacing > 0) {
		currentFacing = -currentFacing
	}
	if (rot < QC - overAimRad && rot > -QC + overAimRad && currentFacing < 0) {
		currentFacing = -currentFacing
	}

	return currentFacing
}
