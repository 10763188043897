import { Vector } from "sat"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { CircleCollider, CircleColliderConfig, ColliderType, updateColliderPositions } from "../../engine/collision/colliders"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { ComponentOwner } from "../../engine/component-owner"
import { GameState } from "../../engine/game-state"
import { DamageSource } from "../../projectiles/damage-source"
import EntityStatList from "../../stats/entity-stat-list"
import { VectorXY } from "../../utils/math"
import { timeInSeconds, timeInMilliseconds, nid } from "../../utils/primitive-types"
import { PoolableObject } from "../../utils/third-party/object-pool"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { EntityType, IEntity } from "../entity-interfaces"
import { EllipseGroundHazardParams } from "./ellipse-hazard-params"

export interface GroundHazardParams {
    triggerRadius: number
    position: VectorXY
}

type EntityInZone = {
    inZoneThisFrame: boolean
    collider: ColliderComponent
}

const BASE_COLLIDER_CONFIG: CircleColliderConfig[] = [
    {
        type: ColliderType.Circle,
        position: [0, 0],
        radius: 40,
    }
]

export abstract class GroundHazard implements ComponentOwner, PoolableObject, DamageSource {
    entityType: EntityType = EntityType.GroundHazard
	timeScale: number = 1

    nid: number
    position: Vector
    originalPosition: Vector

    colliderComponent: ColliderComponent
    triggerRadius: number

	numEntitiesPierced: number
    entitiesInZone: Map<nid, EntityInZone>

    weaponType: AllWeaponTypes = AllWeaponTypes.Unknown

    showImmediateDamageNumber: boolean = false

    abstract isPlayerOwned(): boolean
    abstract statList: EntityStatList
    abstract numEntitiesChained: number

    constructor() {
        this.position = new Vector()
        this.originalPosition = new Vector()
        const collisionLayer = this.isPlayerOwned() ? CollisionLayerBits.PlayerGroundHazard : CollisionLayerBits.NeutralGroundHazard
        this.colliderComponent = new ColliderComponent(BASE_COLLIDER_CONFIG, this, collisionLayer, this.onEntityCollision.bind(this), true, true, true)
        this.colliderComponent.onCollisionChecksDoneCallback = this.onCollisionChecksDone.bind(this)
        this.entitiesInZone = new Map()
    }

    cleanup() {
        CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
        GameState.removeEntity(this)

        this.entitiesInZone.forEach((zoneEntity, key: nid) => {
            this.onEntityLeaveTrigger(zoneEntity.collider)
        })

		this.triggerRadius = 0

        this.entitiesInZone.clear()
    }

    setDefaultValues(defaultValues: any, overrideValues?: GroundHazardParams) {
        const collider = this.colliderComponent.colliders[0]
        if (overrideValues) {
            if (collider.type === ColliderType.Circle) {
                collider.r = overrideValues.triggerRadius
                this.colliderComponent.recalculateBounds()
            } else if (collider.type === ColliderType.Ellipse) {
                // this is wack but I think to make it not wack would be more effort than it's worth
                const elipseValues = overrideValues as EllipseGroundHazardParams
                collider.rX = elipseValues.radiusX
                collider.rY = elipseValues.radiusY
                this.colliderComponent.recalculateBounds()
            }

            this.triggerRadius = overrideValues.triggerRadius

            this.position.x = overrideValues.position.x
            this.position.y = overrideValues.position.y
            this.originalPosition.copy(this.position)

            CollisionSystem.getInstance().addCollidable(this.colliderComponent)
            GameState.addEntity(this)
        }


		// because we are a static pooled entity
		updateColliderPositions(this.colliderComponent.colliderConfigs, this.colliderComponent.colliders, this.position)

    }

    update(delta: timeInSeconds, now?: timeInMilliseconds): void {

    }

    onEntityCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
        if (otherEntity.owner.entityType === EntityType.GroundHazard && this.colliderComponent.layer !== CollisionLayerBits.TemporalDistortion) {
            return // temporal distortion (and we aren't temporal distortion)
        }

        const zoneEntity = this.entitiesInZone.get(otherEntity.id)
        if (!zoneEntity) {
            const entity = {
                inZoneThisFrame: true,
                collider: otherEntity
            }

            this.entitiesInZone.set(otherEntity.id, entity)

            this.onEntityEnterTrigger(otherEntity)
        } else {
            zoneEntity.inZoneThisFrame = true
        }
    }

    onCollisionChecksDone() {
        this.entitiesInZone.forEach((zoneEntity, key: nid) => {
            if (!zoneEntity.inZoneThisFrame) {
                this.entitiesInZone.delete(key)
                this.onEntityLeaveTrigger(zoneEntity.collider)
            } else {
                zoneEntity.inZoneThisFrame = false
            }
        })
    }

    // This is used by prop recycling (elemental pools need to be recycled)
    setOffset(offset: Vector) {
        this.position.x = this.originalPosition.x + offset.x
        this.position.y = this.originalPosition.y + offset.y

        CollisionSystem.getInstance().reinsertEntity(this.colliderComponent)
    }

    getKnockbackDirection(mutableEntityPos: Vector): Vector {
		return mutableEntityPos.sub(this.position).normalize()
	}

    abstract onEntityEnterTrigger(entity: ColliderComponent)
    abstract onEntityLeaveTrigger(entity: ColliderComponent)

    abstract returnToPool()
}