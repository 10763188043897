<template>
	<div @click="$emit('onClickFn', optionalParam)" class="radial">
		<div class="radial-outline" :class="{ selected: selected }">
			<div class="inner-radial" :class="color"></div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
const FONT_SIZE = ['xSmall', 'small', 'medium', 'large']
export default {
	name: 'Radial',
	components: {},
	data() {
		return {
			fontSizes: {
				xSmall: { fontSize: '24px' },
				small: { fontSize: '32px' },
				medium: { fontSize: '40px' },
				large: { fontSize: '50px' },
			},
		}
	},
	props: {
		selected: {
			type: Boolean,
			required: true,
			default: false,
		},
		optionalParam: {
			type: Object,
			required: false,
			default: null,
		},
		error: {
			type: Boolean,
			required: false,
		},
		color: {
			type: String,
			required: false,
		}
	},
	computed: {
	},
	methods: {
		getSelectionStyle(option: string) {
			return this.selectedValue === option ? 'selected' : 'unselected'
		},
		//Move Elsewhere
		setSelectedValue(option: string) {
			this.selectedValue = option
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.radial {
	pointer-events: all;
	/*&.selected{
		.radial-outline{
			background-image: url('../../../../assets/ui/pips/player-icon-colour-select.png');
			opacity: 50;
			.inner-radial{}
		}
	}*/
    .radial-outline {
		background-image: url('../../../../assets/ui/pips/player-icon-colour.png');
		background-size: contain;
		width: 30px;
		height: 30px;
		position: relative;
		&.selected{
			background-image: url('../../../../assets/ui/pips/player-icon-colour-select.png');
		}
      .inner-radial {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 5px;
		left: 5px;
		//opacity: 0;
		//transition: opacity 0.25s ease-in-out;
		&.blue {
			background-image: url('../../../../assets/ui/pips/player-icon-colour-blue.png');
		}
		&.green {
			background-image: url('../../../../assets/ui/pips/player-icon-colour-green.png');
		}
		&.orange {
			background-image: url('../../../../assets/ui/pips/player-icon-colour-orange.png');
		}
      }
    }
  }
</style>
