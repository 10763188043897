import logger from '../utils/client-logger'
import { App, createApp, h } from 'vue'
import Vuex, { CommitOptions, DispatchOptions, Store, createStore } from 'vuex'
import Multiselect from 'vue-multiselect'
//@ts-expect-error
import UIOverlay from './ui-overlay.vue'
import VueGtag from 'vue-gtag'
import ColorInput from 'vue-color-input'
import devtools from '@vue/devtools'


// Store
import { uiStore } from './store/ui-store'
import { genericPromptStore } from './store/generic-prompt'
import { genericTwoButtonPromptStore } from './store/generic-two-btn-prompt-store'
import { uiScalingStore } from './store/ui-scaling-store'
import { levelUpStore } from './store/level-up-store'
import { pausedStore } from './store/paused-store'
import { userAccountStore } from './store/user-account-store'
import { endChapterStore } from './store/end-chapter-store'
import { characterWeaponStore } from './store/character-weapon-select-store'
import { storyStore } from './store/story-store'
import { tooltipStore } from './store/tooltip-store'
import { playerStore } from './store/player-info-store'
import { debugStore } from './store/debug-store'
import { metaProgressionStore } from './store/meta-progression-store'
import { timeUIStore, startTimeUpdateInterval } from './store/time-store'
import { settingsStore } from './store/settings-store'
import { activityFeed } from './store/activity-feed-store'
import { handleReceivedSteamAuthTicket, initializeWebPortalSpecificOverrides } from '../web-portals/web-portal-init'
import { errorPromptStore } from './store/error-store'
import { vuei18n } from '../utils/i18n'

// Language
import { createI18n, Locale, useI18n } from 'vue-i18n'
import en from './locales/en.json'
import zh from './locales/zh-hans.json'
import "../../node_modules/intro.js/introjs.css" // required for intro-js to function (don't delete!)

interface I18n {
	locale: string,
	fallbackLocale: string,
	messages: object[],
}

type MessageSchema = typeof en
import { PauseManager } from '../engine/pause-manager'

// CSS Imports
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'vue-multiselect/dist/vue-multiselect.css'
import { ftueManagerStore } from './store/ftue-manager-store'
import { contextBridge } from 'electron'
import { steamCall } from '../electron-bridge'
import ClientPlayerInput from 'src/engine/client-player-input'
import { debugConfig } from '../utils/debug-config'

/*
const waitUntilLoadingCompleteToStartGameClient = (gameMode: any) => {
	const loadedPercentage = UI.getInstance().store.getters['mainMenu/loadedPercentage']

	logger.debug('Still loading... current progress:', loadedPercentage)

	//TODO2 replace hack with long-term solution
	if (loadedPercentage >= 100 && userFetched(UI.getInstance().store.state.user)) {
		const connectionPayload: ConnectionPayload = {
			visibleWorldWidth: getVisibleWorldWidth() + NENGI_VIEW_X_PADDING,
			visibleWorldHeight: getVisibleWorldHeight() + NENGI_VIEW_Y_PADDING,
			userType: UI.getInstance().store.state.user.userType,
			identifier: UI.getInstance().store.state.user.userType === 'anonymous' ? UI.getInstance().store.state.user.id : UI.getInstance().store.state.user.authentication.token,
			spawnStyle: SpawnStyle.Default,
			biomeIndex: clientConfig.biomeIndex,
			worldDifficulty: WorldDifficulty.Normal,
			pressureLoadout: {},
			playToken: UI.getInstance().store.state.loginQueue.playToken || 'test',
		}

		const skipToAdventure = clientConfig.skipToGame && (clientConfig.connectTo === GameModeType.Adventure || clientConfig.connectTo === GameModeType.TestRealm) 
		// For this particular load (the initial one done via 'waitUntilLoadingCompleteToStartGameClient') hardcode world tier 0 since they're going to hub
		const worldDifficulty = skipToAdventure ? WorldDifficulty.Normal : WorldDifficulty.Hub

		const partyId = ''

		// @ts-ignore TODO2: better connection payload storage in upcoming PR
		window.originalConnectionPayload = connectionPayload
		startGame(gameMode, connectionPayload, partyId)
	} else {
		UI.getInstance().emitEvent('updateLoadedPercentage', loadedPercentage)
		setTimeout(waitUntilLoadingCompleteToStartGameClient.bind(this, gameMode), 100)
	}
}
*/

// Add a globally available truncate filter to Vue template engine
// Vue.filter('truncate', function(value: any, amount: number) {
// 	if (!value) {
// 		return ''
// 	}
// 	value = value.toString()
// 	if (value.length <= amount) {
// 		return value
// 	}
// 	return value.substring(0, amount) + '...'
// })

if (!document.documentElement.onfullscreenchange) {
	document.documentElement.onfullscreenchange = (event) => {

		// UI.getInstance().emitEvent('setIsFullscreen', document.fullscreenElement !== null)
	}
}

type ElectronEventTypes = "controllerMove" | "controllerButton"

if (process.env.IS_ELECTRON) {
	/* 	//TODO: needs typing
		window.bridge.ElectronToDiebrary(async (event, eventType: ElectronEventTypes, ...payload) => {
			switch(eventType) {
				case 'controllerMove': {
					//console.log(JSON.stringify(payload))
					const controllerEvent = new CustomEvent('ControllerMove', {detail: payload[0]})
					document.dispatchEvent(controllerEvent)
				}
					break
				case 'controllerButton': {
					console.log(JSON.stringify(payload))
					//const controllerEvent = new CustomEvent("PlayerInputDown", {detail: 'shoot'})
					//document.dispatchEvent(controllerEvent)
					break
				}
			}
		})
	 */
	type ElectronSteamEventTypes = "receivedAuthTicket" | "useSteam"

	window.bridge.ElectronSteamToDiebrary(async (event, eventType: ElectronSteamEventTypes, ...payload) => {
		console.log(`[Diebrary] ElectronSteamToDiebrary`, { eventType, payload })
		switch (eventType) {
			case 'useSteam':
				const searchParams = new URLSearchParams(window.location.search)
				searchParams.set('portal', 'steam')
				console.log(`Set portal to Steam`)
				initializeWebPortalSpecificOverrides('steam')
				break
			case 'receivedAuthTicket':
				console.log(`${eventType}`, payload)
				const { accountId, steamId32, steamId64 } = await steamCall('localplayer', 'getSteamId')
				const username = await steamCall('localplayer', 'getName')
				const [authTicket, appId] = payload
				const response = await handleReceivedSteamAuthTicket(username, accountId, authTicket, appId)
				//TODO: move this to UI init/auth stuff
				// await send to griddle
				// continue with web portal auth flow
				break
		}
	})
}

if (process.env.IS_ELECTRON) {
	async function testSteamCalls() {
		const name = await steamCall('localplayer', 'getName')
		const level = await steamCall('localplayer', 'getLevel')
		console.log({
			name,
			level,
		})
	}
	testSteamCalls()
}

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging' && debugConfig.enableVueDevTools) {
	devtools.connect(/* host, port */)
}

export class UI {
	static getInstance(): UI {
		if (!UI.instance) {
			UI.instance = new UI()
			// this is for time-ui-state
			// startTimeUpdateInterval(UI.instance)
		}

		return UI.instance
	}

	static shutdown() {

	}

	store: Store<any>
	vueInstance: App<Element>

	private static instance: UI

	private constructor() {
		logger.debug('Initializing UI module...')

		this.store = createStore({
			state() { },
			modules: {
				//@ts-expect-error
				ui: uiStore(),
				debug: debugStore(),
				genericPrompt: genericPromptStore(),
				genericTwoButtonPrompt: genericTwoButtonPromptStore(),
				uiScaling: uiScalingStore(),
				levelUp: levelUpStore(),
				player: playerStore(),
				time: timeUIStore(),
				paused: pausedStore(),
				user: userAccountStore(),
				endChapter: endChapterStore(),
				characterSelect: characterWeaponStore(),
				story: storyStore(),
				tooltip: tooltipStore(),
				metaProgression: metaProgressionStore(),
				settings: settingsStore(),
				activityFeed: activityFeed(),
				ftue: ftueManagerStore(),
				error: errorPromptStore()
			},
		})

		this.vueInstance = createApp(UIOverlay)
		this.vueInstance.use(this.store)
		this.vueInstance.use(vuei18n)
		this.vueInstance.component('Multiselect', Multiselect)
		this.vueInstance.use(VueGtag, { config: { id: "G-P5MG7J8HY5", params: { anonymize_ip: true } }, enabled: false })
		this.vueInstance.component('ColorInput', ColorInput)

		startTimeUpdateInterval(this)
		// window.addEventListener('blur', (event) => {
		// 	PauseManager.pauseGame()
		// })

		// window.addEventListener('focus', (event) => {
		// 	PauseManager.unpauseGame()
		// })
	}

	mount() {
		// console.log("Mounting!")
		this.vueInstance.mount('#ui-overlay')

		const searchParams = new URLSearchParams(window.location.search)
		const portal = searchParams.get('portal') || 'none'
		console.log({ portal })

		initializeWebPortalSpecificOverrides(portal)
	}

	emitMutation(type: string, payload?: any, options?: CommitOptions) {
		// console.log('emitMutation', { type, payload })
		this.store.commit(type, payload, options)
	}

	emitAction(type: string, payload?: any, options?: DispatchOptions) {
		// console.log('emitAction', { type, payload })
		this.store.dispatch(type, payload, options)
	}

	chatCurrentlyFocused(): boolean {
		return false
	}
}
