import { BuffIdentifier } from './buff.shared'
// import { PlayerBuffs } from './player-buffs'
// import { EnemyBuffs } from './enemy-buffs'
import { BuffDefinition } from './buff-definition'
import { StackStyle } from './buff-enums'
import { some } from 'lodash'
import { isNullOrUndefined } from 'util'
import { SkillBuffs } from './skill-buff-definitions'
import { getUpgradesOfCollection, UPGRADE_COLLECTIONS } from '../upgrades/upgrade-definitions'
import { GenericBuffs } from './generic-buff-definitions'

class BuffData {
	static map = new Map<BuffIdentifier, BuffDefinition>()
	static isInitialized: boolean = false

	static initializeBuffMapData() {
		if (BuffData.isInitialized) {
			return
		}

		SkillBuffs.forEach(setBuff)
		GenericBuffs.forEach(setBuff)
		
		for (const key in UPGRADE_COLLECTIONS) {
			const coll = UPGRADE_COLLECTIONS[key]
			const upgrades = getUpgradesOfCollection(coll)
			upgrades.forEach((upgrade) => {
				if (upgrade.autoAppliedBuff) {
					setBuff(upgrade.autoAppliedBuff)
				}
				upgrade.extraBuffs?.forEach((buff) => {
					setBuff(buff)
				})
			})
		}
		
		Object.freeze(SkillBuffs)
		Object.freeze(this.map)

		this.map.forEach(validateBuffDefinition)
		BuffData.isInitialized = true
	}
}

function validateBuffDefinition(buffDef: BuffDefinition) {
	const invalid = `Invalid buff definition for ${buffDef.identifier}:`
	if (buffDef.stackStyle === StackStyle.None) {
		if (some([buffDef.reapplyStacks, buffDef.reapplyDuration], !isNullOrUndefined)) {
			throw new Error(`${invalid} superfluous reapplyX fields on a non-stacking buff.`)
		}
	}
	if (buffDef.stackStyle === StackStyle.IncreaseDuration || buffDef.stackStyle === StackStyle.RefreshDuration || buffDef.stackStyle === StackStyle.RollingStackDurationSeparately) {
		if (some([buffDef.reapplyStacks, buffDef.reapplyDuration], isNullOrUndefined)) {
			throw new Error(`${invalid} missing reapplyX fields on a stacking buff.`)
		}
	}
	if (buffDef.lastsForever && buffDef.duration !== 0) {
		throw new Error(`${invalid} buff lasts forever but has a non-zero duration.`)
	}
	if (buffDef.lastsForever && buffDef.reapplyDuration) {
		throw new Error(`${invalid} buff lasts forever but has a reapply duration.`)
	}
	//TODO2: consider adding checks for if we have an applyFn() but not a wearOffFn() and vice versa
	//TODO2: can we add checks to make sure we're properly removing any added statList bonuses?
}

function setBuff(buffDef: BuffDefinition) {
	if (BuffData.map.get(buffDef.identifier)) {
		throw new Error(`buff with identifier:${buffDef.identifier} has already been added`)
	}
	BuffData.map.set(buffDef.identifier, buffDef)
}

export default BuffData
