<template>
	<div
		class="panel"

	>
		<div ref="panelRef" class="panel-inner" :style="{ height: height > 0 ? `${height}px` : '', transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg)`}">
			<div class="content">
				<slot name="panel-content"></slot>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'

export default {
	name: 'Panel',
	components: {},
	props: {
		height: {
			type: Number,
			required: false,
			default: 800,
		},
		underConstruction: {
			type: Boolean,
		},
	},
	computed: {
		...mapGetters('uiScaling', ['currentScale']),
	},
	methods: {},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.panel {
	.under-construction {
		position: absolute;
		transform: rotate(-35deg);
		top: 30%;
		left: 15px;
		font-family: 'Do Hyeon';
		font-size: 50px;
		font-weight: 1000;
		text-shadow: 0 0 10px black, 0 0 10px black, 0 0 30px white;
		color: #00ffc4;
	}
	.panel-inner {
		border-style: solid;
		border-width: 46px 11px 70px;
		min-width: 100%;
		min-height: 125px;
		position: relative;
		pointer-events: all;
		perspective: 1px;
		transform: translateZ(0);
		border-image-source: url('~/assets/ui/panel/main-panel.png');
		border-image-slice: 94 133 94 133 fill;
		border-image-width: 94px 133px 94px 133px;
		.content {
			&.noBanner {
				margin-top: 90px;
				height: 100%;
			}
		}
	}
}
</style>
