import { Player } from "../entities/player"
import { RangerSlowHazard, RangerSlowHazardParams } from "../entities/hazards/ranger-slow-hazard"
import { CharacterType } from "../game-data/characters"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { EmptyPassiveSkill } from "./actual-weapons/passives/empty-passive-skill"
import { PassiveSkill } from "./passive-skill"
import { SkillWeapon } from "./skill-weapon"
import { TrapParams } from "../entities/hazards/trap-ground-hazard"
import { RangerTrapGroundHazard } from "../entities/hazards/ranger-trap-hazard"
import { TeslaCoilWeapon } from "./actual-weapons/secondary/tesla-coil-weapon"
import { TeslaCoilHazard, TeslaCoilHazardParams } from "../entities/hazards/tesla-coil-hazard"
import { ChainLightning, ChainLightningParams } from "../entities/chain-lightning"
import { EmptySkillWeapon } from "./actual-weapons/skill/empty-skill-weapon"
import { SecondaryWeaponType } from "./weapon-types"
import { RangerSkillWeapon } from "./actual-weapons/skill/ranger-skill-weapon"
import { AcidBottlesWeapon } from "./actual-weapons/secondary/acid-bottles-weapon"
import { AcidBottlesHazard } from "../entities/hazards/acid-bottles-hazard"
import { ThrownAcidBottle, ThrownAcidBottleParams } from "./actual-weapons/secondary/thrown-acid-bottle"
import { RatParadeWeapon } from "./actual-weapons/secondary/rat-parade-weapon"
import { DamagingGroundHazardParams } from "../entities/hazards/damaging-ground-hazard"
import { NikolaScope } from "./actual-weapons/secondary/nikola-scope-weapon"
import { Beam, BeamParams, makeBeamPool } from "../beams/beams"
import { RangerPassiveSkill } from "./actual-weapons/passives/ranger-passive-skill"
import { BarbarianSkillWeapon } from "./actual-weapons/skill/barbarian-skill-weapon"
import { BarbarianPassiveSkill } from "./actual-weapons/passives/barbarian-passive-skill"
import { DogSkillWeapon } from "./actual-weapons/skill/dog-chomp-skill-weapon"
import { ConeDogThornsWeapon } from "./actual-weapons/secondary/cone-dog-thorns-weapon"
import { BeesWeapon } from "./actual-weapons/secondary/bees-weapon"
import { LuteWeapon } from "./actual-weapons/secondary/lute-weapon"
import { DarkStormyNightWeapon } from "./actual-weapons/secondary/dark-stormy-night-weapon"
import { AutoFireSecondaryWeapon } from "./actual-weapons/secondary/auto-fire-secondary-weapon"
import { SolaraSkillWeapon } from "./actual-weapons/skill/solara-skill-weapon"
import { SolaraPassiveSkill } from "./actual-weapons/passives/solara-passive-skill"
import { RadarSkillWeapon } from "./actual-weapons/skill/radar-skill-weapon"
import { RadarPassiveSkill } from "./actual-weapons/passives/radar-passive-skill"
import { FireFairiesWeapon } from "./actual-weapons/secondary/fire-fairies-weapon"
import { PhoenixBombs } from "./actual-weapons/secondary/phoenix-bomb-weapon"
import { ThrownPhoenixBomb, ThrownPhoenixBombParams } from "./actual-weapons/secondary/thrown-phoenix-bombs"

export function makeSkillWeapon(weaponType: CharacterType, player: Player): SkillWeapon {
    switch(weaponType) {
        case CharacterType.ElfRanger:
            if(!RangerTrapGroundHazard.pool) {
                RangerTrapGroundHazard.pool = new ObjectPoolTyped<RangerTrapGroundHazard, TrapParams>(() => new RangerTrapGroundHazard(), {}, 2, 1)
            }

            if(!RangerSlowHazard.pool) {
                RangerSlowHazard.pool = new ObjectPoolTyped<RangerSlowHazard, RangerSlowHazardParams>(() => new RangerSlowHazard(), {}, 2, 1)
            }
            return new RangerSkillWeapon(player, player.stats)
      case CharacterType.Barbarian:
        return new BarbarianSkillWeapon(player, player.stats)
      case CharacterType.ConeDog:
          return new DogSkillWeapon(player, player.stats)
      case CharacterType.SolaraSun:
      case CharacterType.SolaraMoon:
        return new SolaraSkillWeapon(player, player.stats)
      case CharacterType.Radar:
        return new RadarSkillWeapon(player, player.stats)
    }

    return new EmptySkillWeapon(player, player.stats)
}

export function makeSecondaryWeapon(weaponType: SecondaryWeaponType, player: Player): AutoFireSecondaryWeapon {
    switch(weaponType) {
        case SecondaryWeaponType.TeslaCoil:
            if(!TeslaCoilHazard.pool) {
                TeslaCoilHazard.pool = new ObjectPoolTyped<TeslaCoilHazard, TeslaCoilHazardParams>(() => new TeslaCoilHazard(), {}, 2, 1)
                ChainLightning.pool = new ObjectPoolTyped<ChainLightning, ChainLightningParams>(() => new ChainLightning(), {}, 6, 1)
            }
            return new TeslaCoilWeapon(player, player.secondaryStatList)
        case SecondaryWeaponType.AcidBottles:
            if(!AcidBottlesHazard.pool) {
                AcidBottlesHazard.pool = new ObjectPoolTyped<AcidBottlesHazard, DamagingGroundHazardParams>(() => new AcidBottlesHazard(), {}, 10, 1)
                ThrownAcidBottle.pool = new ObjectPoolTyped<ThrownAcidBottle, ThrownAcidBottleParams>(() => new ThrownAcidBottle(), {}, 10, 1)
            }
            return new AcidBottlesWeapon(player, player.secondaryStatList)
        case SecondaryWeaponType.RatParade:
            return new RatParadeWeapon(player, player.secondaryStatList)
        case SecondaryWeaponType.NikolaScope:
            makeBeamPool()
            return new NikolaScope(player, player.secondaryStatList)
        case SecondaryWeaponType.ConeDogThorns:
            return new ConeDogThornsWeapon(player, player.secondaryStatList)
		case SecondaryWeaponType.Bees:
			return new BeesWeapon(player, player.secondaryStatList)
        case SecondaryWeaponType.Lute:
            return new LuteWeapon(player, player.secondaryStatList)
        case SecondaryWeaponType.DarkStormyNight:
            return new DarkStormyNightWeapon(player, player.secondaryStatList)
        case SecondaryWeaponType.FireFairies:
            return new FireFairiesWeapon(player, player.secondaryStatList)
        case SecondaryWeaponType.PhoenixBombs: 
            if(!ThrownPhoenixBomb.pool) {
                ThrownPhoenixBomb.pool = new ObjectPoolTyped<ThrownPhoenixBomb, ThrownPhoenixBombParams>(() => new ThrownPhoenixBomb(), {}, 9, 3)
            }
            return new PhoenixBombs(player, player.secondaryStatList)
    }
}

export function makePassiveSkill(character: CharacterType, player: Player): PassiveSkill {
    switch(character) {
      case CharacterType.ElfRanger:
        return new RangerPassiveSkill(player)
      case CharacterType.Barbarian:
        return new BarbarianPassiveSkill(player)
      case CharacterType.SolaraSun:
      case CharacterType.SolaraMoon:
        return new SolaraPassiveSkill(player)
      case CharacterType.Radar:
        return new RadarPassiveSkill(player)
    }

    return new EmptyPassiveSkill(player)
}