import { Container } from "pixi.js"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { Audio } from "../../../engine/audio"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import CollisionSystem from "../../../engine/collision/collision-system"
import { GameState } from "../../../engine/game-state"
import { InstancedSprite } from "../../../engine/graphics/instanced-sprite"
import { EffectConfig } from "../../../engine/graphics/pfx/effectConfig"
import { Renderer } from "../../../engine/graphics/renderer"
import { Enemy } from "../../../entities/enemies/enemy"
import { Player } from "../../../entities/player"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { DEFAULT_AOE_EXPLOSION_DURATION, DEFAULT_AOE_EXPLOSION_PFX_SIZE, setExplosionColor } from "../../../projectiles/explosions"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { timeInMilliseconds } from "../../../utils/primitive-types"
import { AssetManager } from "../../../web/asset-manager"
import { AllWeaponTypes } from "../../weapon-types"
import { AutoFirePassiveSkill } from "./auto-fire-passive-skill"

const BASE_ATTACK_SIZE = 260
const BASE_EXPLOSION_SCALE = BASE_ATTACK_SIZE / DEFAULT_AOE_EXPLOSION_PFX_SIZE

export class RangerPassiveSkill extends AutoFirePassiveSkill {

	weaponType: AllWeaponTypes = AllWeaponTypes.ElfRangerPassive

	markedEnemy: Enemy
	markGfx: InstancedSprite
	explosionEffectConfig: EffectConfig

	private gfxIsInScene: boolean

	onCreate(player: Player): void {
		super.onCreate(player)

		const markTex = AssetManager.getInstance().getAssetByName('ranger-mark').texture
		this.markGfx = new InstancedSprite(markTex, 0, 0, 100) //@TODO remove all this gfx code and use mark-gfx.ts
		this.explosionEffectConfig = AssetManager.getInstance().getAssetByName('aoe-explosion-white').data
		//setExplosionColor(this.explosionEffectConfig, 'fire')
	}

	resetStatsFunction(statList: EntityStatList): void {
		defaultStatAttribute(statList)

		statList._actualStatValues.skillDuration = 3_000
		statList._actualStatValues.attackSize = BASE_ATTACK_SIZE // explosion
		statList._actualStatValues.placementRange = 500 // search range
		statList._actualStatValues.damagingAilmentPotencyMult = 0.5 // +40% base damage to explosion around the hit enemy 
		statList._actualStatValues.slowAmount = 0.5

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 7_000
		statList._actualStatValues.reloadInterval = 50
	}

	firePassiveSkill() {
		let highestHPEnemy: Enemy
		for (let i = 1; i <= 2; i++) {
			const enemies = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, this.statList.getStat(StatType.placementRange) * i, CollisionLayerBits.HitEnemyOnly)

			// find the highest health enemy
			let highestHP: number = Number.MIN_SAFE_INTEGER
			for (let i = 0; i < enemies.length; ++i) {
				const enemy = enemies[i].owner as Enemy
				if (!enemy.isDead() && enemy.currentHealth > highestHP) {
					highestHPEnemy = enemy
					highestHP = enemy.currentHealth
				}
			}

			if (highestHPEnemy) {
				// mark them!
				this.markedEnemy = highestHPEnemy
				const duration = this.statList.getStat(StatType.skillDuration) as timeInMilliseconds
				Buff.apply(BuffIdentifier.RangerMarkDebuff, this.player, highestHPEnemy, 1, duration)

				this.markGfx.x = highestHPEnemy.x
				this.markGfx.y = highestHPEnemy.y
				if (!this.gfxIsInScene) {
					Renderer.getInstance().fgRenderer.addPropToScene(this.markGfx)
					this.gfxIsInScene = true
				}

				this.skillSuccessfullyUsed = true
				let markScale = 1.65
				this.markGfx.scaleX = markScale
				this.markGfx.scaleY = markScale
				const interval = setInterval(() => {
					if (!this.markGfx) {
						return
					}
					markScale -= 0.05
					if (markScale <= 1.0) {
						this.markGfx.scaleX = 1.0
						this.markGfx.scaleY = 1.0
						clearInterval(interval)
						return
					}
					this.markGfx.scaleX = markScale
					this.markGfx.scaleY = markScale
				}, 16)
				Audio.getInstance().playSfx('SFX_Hunters_Mark')
				break
			}
		}

		this.finishedUsingPassiveSkill()
	}

	override update(delta: number): void {
		super.update(delta)

		if (this.markedEnemy) {
			const isDead = this.markedEnemy.isDead()
			if (isDead || !this.markedEnemy.hasBuff(BuffIdentifier.RangerMarkDebuff)) {
				this.markedEnemy = null
				if (this.gfxIsInScene) {
					Renderer.getInstance().fgRenderer.removeFromScene(this.markGfx)
					this.gfxIsInScene = false
				}

				if (isDead && GameState.player.binaryFlags.has('rangers-mark-spreads-on-death')) {
					this.firePassiveSkill()
				}
			} else {
				this.markGfx.x = this.markedEnemy.x
				this.markGfx.y = this.markedEnemy.y
			}
		} else if (this.gfxIsInScene) {
			Renderer.getInstance().fgRenderer.removeFromScene(this.markGfx)
			this.gfxIsInScene = false
		}
	}

	override onEnemyHit(enemy: Enemy, damageDealt: number) {
		if (this.markedEnemy && enemy.nid === this.markedEnemy.nid) {
			// ker-blamo
			const player = GameState.player
			const damageToDeal = damageDealt * this.statList.getStat(StatType.damagingAilmentPotencyMult) + player.getLevelDamage(1.0)

			const enemiesInArea = CollisionSystem.getInstance().getEntitiesInArea(enemy.position, this.statList.getStat(StatType.attackSize), CollisionLayerBits.HitEnemyOnly)
			for (let i = 0; i < enemiesInArea.length; ++i) {
				const enemy = enemiesInArea[i].owner as Enemy
				if (enemy.nid !== this.markedEnemy.nid) {
					// take damage
					enemy.takeDamageSimple(damageToDeal)
				}

				// slow them
				Buff.apply(BuffIdentifier.RangerMarkSlowDebuff, this.player, enemy, this.statList.getStat(StatType.slowAmount) * 100)
			}

			// show an effect
			const explosionScale = this.statList.getStat(StatType.attackSize) / BASE_ATTACK_SIZE
			const pfx = Renderer.getInstance().addOneOffEffectByConfig(this.explosionEffectConfig, enemy.position.x, enemy.position.y, enemy.position.y + 200, BASE_EXPLOSION_SCALE * explosionScale, DEFAULT_AOE_EXPLOSION_DURATION, true, true)
			setExplosionColor(pfx, 'fire')
		}
	}

	onShot(): void {
	}

	onEnemyKilled(enemy: Enemy): void {
	}
}
