import { CollisionLayerBits } from "../engine/collision/collision-layers"
import CollisionSystem, { getClosestEntity } from "../engine/collision/collision-system"
import { GameState } from "../engine/game-state"
import { Vector } from "sat"
import { Player } from "../entities/player"
import { angleDiff, angleDistanceRads, distance, distanceSquaredVV, distanceVV, throwIfNotFinite, triangleWave, VectorXY } from "../utils/math"
import { gameUnits, nid, radians, timeInSeconds } from "../utils/primitive-types"
import { angleBetweenVectors, angleInRadsFromVector } from "../utils/vector"
import { Boomerang, OUTBACK_ORBIT_RADIUS_UPGRADE_AMOUNT } from "../weapons/actual-weapons/primary/boomerang-weapon"
import { PlayerProjectile } from "./projectile"
import { TrajectoryMod, TrajectoryModType, TrajectoryModValue, TrajectoryModValueType } from "./trajectory-presets"
import { StatType } from "../stats/stat-interfaces-enums"
import { Buff } from "../buffs/buff"
import { BuffIdentifier } from "../buffs/buff.shared"
import { BOOMERANG_BASE_ATTACK_RATE } from "../weapons/weapon-definitions"

const RADIUS_SPREAD_FACTOR = 0.7
// const CURVED_BOOMERANG_STAGE_1_TIME: timeInSeconds = 0.75
// const CURVED_BOOMERANG_STAGE_2_TIME: timeInSeconds = 1.25
const CURVED_BOOMERANG_STAGE_1_DISTANCE: gameUnits = 625
const CURVED_BOOMERANG_STAGE_2_DISTANCE: gameUnits = CURVED_BOOMERANG_STAGE_1_DISTANCE * 2
// const CURVED_BOOMERANG_STAGE_2_CUTOFF_TIME: timeInSeconds = 1.5 + CURVED_BOOMERANG_STAGE_1_TIME
// 9/16ths of a full circle before homing back toward shooter
// const CURVED_BOOMERANG_STAGE_2_RADS_PER_SECOND: radians = ((Math.PI*2) * 9/16) / CURVED_BOOMERANG_STAGE_2_TIME
const CURVED_BOOMERANG_STAGE_2_RADS_PER_DISTANCE: radians = ((Math.PI * 2) * 9 / 16) / CURVED_BOOMERANG_STAGE_2_DISTANCE * 2
const OUTBACK_BOOMERANG_STAGE_1_DISTANCE: gameUnits = 400
const OUTBACK_BOOMERANG_ORBIT_CUTOFF_TIME = 0.5 // can orbit for an additional 0.5 seconds until it's forced back to the player
const OUTBACK_BOOMERANG_CLEAR_TARGETS_TIME = 0.25


const BOOMERANG_RETURN_CUTOFF_DIST = 50 * 50



export enum TrajectoryBoomerangStage {
	PreCurve = 0,
	Curving = 1,
	Returning = 2,
}


export function applyTrajectoryModInitialState(projectile: PlayerProjectile, mod: TrajectoryMod): void {
	// if (!projectile.spreadModifiersSet) {
	// 	if (!projectile.baseTrajectory) {
	// 		// console.error("no base trajectory set, setting a fake one to stop crashes")
	// 		projectile.baseTrajectory = { x: 0, y: 0 }
	// 	}
	// 	const baseAngle = angleInRadsFromVector(projectile.baseTrajectory)
	// 	const spread = Math.abs(Math.abs(baseAngle) - Math.abs(projectile.aimAngleInRads))
	// 	projectile.radiusSpreadModifier = 1 + (spread * RADIUS_SPREAD_FACTOR)
	// 	// TODO: Experiment with wave spread offset if you want wave trajectories to start at a different point in the arc
	// 	//       The only piece that's missing is making sure zigzags start at the correct spot
	// 	projectile.waveSpreadOffset = 0//spread

	// 	projectile.spreadModifiersSet = true
	// }

	switch (mod.modType) {
		// case TrajectoryModType.HARD_TURN:
		// 	projectile.aimAngleInRads += parseTrajectoryModValue(projectile, mod.value) * (projectile.leftRightFlip ? -1 : 1)
		// 	break
		case TrajectoryModType.WAVE:
			projectile.leftRightFlip = Math.random() > 0.5
			break
		// case TrajectoryModType.ZIGZAG:
		// 	const zigzagPeriod = parseTrajectoryModValue(projectile, mod.period)
		// 	const zigzagAmplitude = parseTrajectoryModValue(projectile, mod.amplitude) / 4 / zigzagPeriod
		// 	projectile.aimAngleInRads += Math.atan(zigzagAmplitude / (zigzagPeriod / 4)) * (projectile.leftRightFlip ? -1 : 1)
		// 	break
		// case TrajectoryModType.CIRCLE_SELF:
		// case TrajectoryModType.CIRCLE_POINT:
		// 	limitCircularSpeed(projectile, parseTrajectoryModValue(projectile, mod.radius))
		// 	break
	}
}

export function applyTrajectoryMod(projectile: PlayerProjectile, mod: TrajectoryMod, delta: number, distanceThisFrame: gameUnits): void {
	const turnSpeedMod = 0.02 // Used for circular mods and is somewhat arbitrary, I just picked a number that I felt gave decent looking results

	switch (mod.modType) {
		// case TrajectoryModType.ACCELERATION:
		// 	projectile.speed += projectile.speed * parseTrajectoryModValue(projectile, mod.value) * delta
		// 	break
		// case TrajectoryModType.ABS_ACCELERATION:
		// 	projectile.speed += projectile.initialSpeed * parseTrajectoryModValue(projectile, mod.value) * delta * (projectile.reversedTrajectory ? -1 : 1)
		// 	if (projectile.speed < 0) {
		// 		projectile.reversedTrajectory = true
		// 		projectile.willReverseTrajectory = false
		// 	}
		// 	break
		case TrajectoryModType.CHAKRAM_BOOMERANG: {
			if (projectile.trajectoryStage === TrajectoryBoomerangStage.PreCurve && projectile.distanceTravelled >= CURVED_BOOMERANG_STAGE_1_DISTANCE) {
				projectile.trajectoryStage = TrajectoryBoomerangStage.Curving
				projectile.speed -= (projectile.initialSpeed / 4)
			} else if (projectile.trajectoryStage === TrajectoryBoomerangStage.Curving) {
				if (!projectile.owningEntity) { // dunno if this is *really* necessary
					projectile.toBeDeleted = true
					break
				}
				const pos = projectile.position
				const dest = (projectile.owningEntity as any).position
				const angleDiff = angleBetweenVectors(pos, dest)

				if (angleDistanceRads(angleDiff, projectile.aimAngleInRads) < 0.1 || projectile.distanceTravelled >= CURVED_BOOMERANG_STAGE_2_DISTANCE) {
					projectile.trajectoryStage = TrajectoryBoomerangStage.Returning
					projectile.clearStatsToAllowMultiHitting(true)
					projectile.speed += (projectile.initialSpeed / 4)
					if (projectile.isPlayerOwned()) {
						const player = projectile.owningEntity as Player
						if (player.binaryFlags.has('catch-em-all')) {
							const pos = projectile.position
							const dest = (projectile.owningEntity as any).position
							const angleDiff = angleBetweenVectors(pos, dest)
							projectile.aimAngleInRads = angleDiff
						}
					}
					break
				}

				// projectile.trajectoryStage = 3
				// projectile.speed += (projectile.initialSpeed / 4)
				const boomerangParity = projectile.reversedTrajectory ? -1 : 1
				projectile.aimAngleInRads -= (boomerangParity * CURVED_BOOMERANG_STAGE_2_RADS_PER_DISTANCE * distanceThisFrame)
			} else if (projectile.trajectoryStage === TrajectoryBoomerangStage.Returning) {
				if (!projectile.owningEntity) { // dunno if this is *really* necessary
					projectile.toBeDeleted = true
					break
				}

				if (projectile.isPlayerOwned()) {
					const player = projectile.owningEntity as Player
					const pos = projectile.position
					const dest = player.position
					const angleDiff = angleBetweenVectors(pos, dest)
					if (!player.binaryFlags.has('catch-em-all')) {
						projectile.aimAngleInRads = angleDiff
					}
					if (distanceSquaredVV(pos, dest) < BOOMERANG_RETURN_CUTOFF_DIST) {
						if (player.binaryFlags.has('catch-em-all')) {
							Buff.apply(BuffIdentifier.CatchEmAll, player, player)
						}
						projectile.toBeDeleted = true
					}
				}
			}
			break
		}
		case TrajectoryModType.CURVED_BOOMERANG:
			if (projectile.player.binaryFlags.has('boomerang-chaining') && projectile.numEntitiesChained >= projectile.maxChainCount) {
				projectile.trajectoryStage = TrajectoryBoomerangStage.Returning
			}
			if (projectile.trajectoryStage === TrajectoryBoomerangStage.PreCurve && projectile.distanceTravelled >= CURVED_BOOMERANG_STAGE_1_DISTANCE) {
				projectile.trajectoryStage = TrajectoryBoomerangStage.Curving
				projectile.speed -= (projectile.initialSpeed / 4)
			} else if (projectile.trajectoryStage === TrajectoryBoomerangStage.Curving) {
				if (!projectile.owningEntity) { // dunno if this is *really* necessary
					projectile.toBeDeleted = true
					break
				}
				const pos = projectile.position
				const dest = (projectile.owningEntity as any).position
				const angleDiff = angleBetweenVectors(pos, dest)

				if (angleDistanceRads(angleDiff, projectile.aimAngleInRads) < 0.1 || projectile.distanceTravelled >= CURVED_BOOMERANG_STAGE_2_DISTANCE) {
					projectile.trajectoryStage = TrajectoryBoomerangStage.Returning
					projectile.clearStatsToAllowMultiHitting(true)
					projectile.speed += (projectile.initialSpeed / 4)
					break
				}

				// projectile.trajectoryStage = 3
				// projectile.speed += (projectile.initialSpeed / 4)
				projectile.aimAngleInRads -= CURVED_BOOMERANG_STAGE_2_RADS_PER_DISTANCE * distanceThisFrame
			} else if (projectile.trajectoryStage === TrajectoryBoomerangStage.Returning) {
				if (!projectile.owningEntity) { // dunno if this is *really* necessary
					projectile.toBeDeleted = true
					break
				}
				const pos = projectile.position
				const dest = (projectile.owningEntity as any).position
				const angleDiff = angleBetweenVectors(pos, dest)
				projectile.aimAngleInRads = angleDiff
				if (distanceSquaredVV(pos, dest) < BOOMERANG_RETURN_CUTOFF_DIST) {
					projectile.toBeDeleted = true
				}
			}
			break
		case TrajectoryModType.OUTBACK_BOOMERANG:
			if (projectile.trajectoryStage === TrajectoryBoomerangStage.PreCurve && projectile.distanceTravelled >= OUTBACK_BOOMERANG_STAGE_1_DISTANCE) {
				projectile.trajectoryStage = TrajectoryBoomerangStage.Curving
				// projectile.speed *= 2
				projectile.isCircling = true
				projectile.knockbackUsingCurrentPosition = true

				projectile.outbackSpinTime = projectile.travelTimeElapsedInSeconds + projectile.statList.getStat(StatType.skillDuration)

				const player = projectile.owningEntity as Player
				const boomerang = player.primaryWeapon as Boomerang
				if (boomerang.increaseOrbitSpeed) {
					projectile.speed *= OUTBACK_ORBIT_RADIUS_UPGRADE_AMOUNT
				}
			} else if (projectile.trajectoryStage === TrajectoryBoomerangStage.Curving) {
				if (projectile.travelTimeElapsedInSeconds >= projectile.outbackSpinTime + OUTBACK_BOOMERANG_ORBIT_CUTOFF_TIME) {
					projectile.trajectoryStage = TrajectoryBoomerangStage.Returning
					projectile.clearStatsToAllowMultiHitting(true)
					// projectile.speed /= 2
					projectile.isCircling = false
					projectile.knockbackUsingCurrentPosition = false

					const player = projectile.owningEntity as Player
					const boomerang = player.primaryWeapon as Boomerang
					if (boomerang.increaseOrbitSpeed) {
						projectile.speed /= OUTBACK_ORBIT_RADIUS_UPGRADE_AMOUNT
					}
					break
				}

				projectile.aimAngleInRads -= projectile.circleRadsPerSecond * delta
				projectile.clearMultiHitAcc += delta
				const attackSpeedAdjustedTime = OUTBACK_BOOMERANG_CLEAR_TARGETS_TIME * BOOMERANG_BASE_ATTACK_RATE / projectile.statList.getStat('attackRate')
				if (projectile.clearMultiHitAcc >= attackSpeedAdjustedTime) {
					projectile.clearMultiHitAcc -= attackSpeedAdjustedTime
					projectile.clearStatsToAllowMultiHitting(true)
				}
			} else if (projectile.trajectoryStage === TrajectoryBoomerangStage.Returning) {
				if (!projectile.owningEntity) { // dunno if this is *really* necessary
					projectile.toBeDeleted = true
					break
				}
				const pos = projectile.position
				const dest = (projectile.owningEntity as any).position
				const angleDiff = angleBetweenVectors(pos, dest)
				projectile.aimAngleInRads = angleDiff
				if (distanceSquaredVV(pos, dest) < BOOMERANG_RETURN_CUTOFF_DIST) {
					projectile.toBeDeleted = true
				}
			}
			break
		case TrajectoryModType.TURN:
			projectile.aimAngleInRads += parseTrajectoryModValue(projectile, mod.value) * delta * (projectile.leftRightFlip ? -1 : 1)
			break
		
		case TrajectoryModType.ZIGZAG:
			const zigzagPeriod = parseTrajectoryModValue(projectile, mod.period)
			const zigzagAmplitude = parseTrajectoryModValue(projectile, mod.amplitude) / 4
			for (let t = 1; t < ((projectile.lifespan * (projectile.speed / 500)) / zigzagPeriod) * 2; ++t) {
				if (!projectile.reversedTrajectory) {
					if ((projectile.travelTimeElapsedInSeconds + projectile.waveSpreadOffset) < t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25 && (projectile.travelTimeElapsedInSeconds + projectile.waveSpreadOffset) >= t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25) {
						projectile.aimAngleInRads += 2 * Math.atan(zigzagAmplitude / (zigzagPeriod / 4)) * (t % 2 === 0 ? 1 : -1) * (projectile.leftRightFlip ? -1 : 1)
						break
					}
				} else {
					if ((projectile.travelTimeElapsedInSeconds + projectile.waveSpreadOffset) > t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25 && (projectile.travelTimeElapsedInSeconds + projectile.waveSpreadOffset) <= t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25) {
						projectile.aimAngleInRads += 2 * Math.atan(zigzagAmplitude / (zigzagPeriod / 4)) * (t % 2 === 0 ? 1 : -1) * (projectile.leftRightFlip ? -1 : 1)
						break
					}
				}
			}
			break
		case TrajectoryModType.CIRCLE_POINT:
			const targetA = angleInRadsFromVector(projectile.baseTrajectory)
			const targetDistance = parseTrajectoryModValue(projectile, mod.range)

			applyCircularMod(projectile, parseTrajectoryModValue(projectile, mod.radius) * projectile.radiusSpreadModifier, projectile.speed * turnSpeedMod, projectile.startPos.x + Math.cos(targetA) * targetDistance, projectile.startPos.y + Math.sin(targetA) * targetDistance, delta)
			break
		case TrajectoryModType.CIRCLE_SELF:
			const owner = projectile.owningEntity as Player
			if (owner) {
				const offset = new Vector(projectile.speed / 8, 0)
				offset.rotate(projectile.aimAngleInRads)

				projectile.position.x = owner.x + offset.x
				projectile.position.y = owner.y + offset.y

				projectile.aimAngleInRads -= projectile.circleRadsPerSecond * delta
			}
			break
		case TrajectoryModType.HOMING:
			let target = GameState.entityMap.get(projectile.homingTargetNid) as any
			if (!target || (target.isDead && target.isDead())) {
				projectile.homingTargetNid = null
			}
			if (!projectile.homingTargetNid) {
				if (projectile.homingTargetBackoffAcc > 0) {
					projectile.homingTargetBackoffAcc -= delta
					return
				}
				const result = getHomingTarget(projectile)
				if (!result) {
					projectile.homingTargetBackoffAcc = 0.5
					return
				}
				projectile.homingTargetNid = result[0]
				target = result[1]
			}
			const angleDiff = angleBetweenVectors(projectile.position, target.position) - projectile.aimAngleInRads
			const turningRate = parseTrajectoryModValue(projectile, mod.value)
			projectile.aimAngleInRads = projectile.aimAngleInRads + Math.clamp(angleDiff, -turningRate, turningRate)
			break
		case TrajectoryModType.SQUARE:
			const period = mod.period.value as number
			const squareSin = Math.round(triangleWave(Math.abs(projectile.travelTimeElapsedInSeconds * period - 1)))

			if (projectile.trajectoryStage !== squareSin) {
				const amplitude = mod.amplitude.value as number
				const diff = squareSin - projectile.trajectoryStage
				const angleChange = (diff * amplitude) * (projectile.leftRightFlip ? -1 : 1)
				projectile.aimAngleInRads += angleChange

				if (squareSin === 0 && projectile.trajectoryStage === -1) {
					projectile.leftRightFlip = !projectile.leftRightFlip
				}

				projectile.trajectoryStage = squareSin
			}

			break
		case TrajectoryModType.WAVE:
			const wavePeriod = mod.period.value as number
			const modAmplitude = mod.amplitude.value as number
			const sinAngle = Math.sin((projectile.travelTimeElapsedInSeconds + 0.5) * wavePeriod) * modAmplitude
			projectile.aimAngleInRads = projectile.startAimAngleInRads + (sinAngle * (projectile.leftRightFlip ? 1 : -1))
			break
	}
}

function getHomingTarget(projectile: PlayerProjectile, searchRadius: gameUnits = 1000): [nid, any] | null {
	let nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(projectile.position, searchRadius, projectile.isPlayerOwned() ? CollisionLayerBits.HitEnemyOnly : CollisionLayerBits.Player)
	nearbyEntities = nearbyEntities.filter((collider) => {
		return !projectile.entitiesCollided.includes(collider.owner.nid)
	})
	const targetCollider = getClosestEntity(nearbyEntities, projectile.position)
	if (!targetCollider) {
		return null
	}
	return [targetCollider.owner.nid, targetCollider.owner]
}

export function applyCircularMod(projectile: PlayerProjectile, radius: number, turnSpeed: number, centerX: number, centerY: number, delta: number) {
	const distanceFromTarget = distance(projectile.x, projectile.y, centerX, centerY)
	const distanceFromOrbit = distanceFromTarget - radius
	const angleToTarget = Math.atan2(projectile.y - centerY, projectile.x - centerX)
	const diffAngle = angleDiff(angleToTarget, projectile.aimAngleInRads)
	const clockwise = diffAngle <= 0 ? 1 : -1
	const orbitAngle = angleToTarget + Math.PI * 0.5 * clockwise
	const idealAngle = orbitAngle + Math.atan(distanceFromOrbit / radius - 1) * clockwise
	const diff = angleDiff(idealAngle, projectile.aimAngleInRads) * Math.min(turnSpeed * delta, 1)
	projectile.radiansTravelled += Math.abs(diff)
	projectile.aimAngleInRads += diff

	projectile.limitCircularSpeed(radius)
}

export function limitCircularSpeed(projectile: PlayerProjectile, radius: number) {
	const circumference = 2 * radius * Math.PI
	projectile.speed = Math.clamp(projectile.speed, 0, circumference * 4) // the *4 is just based on look-and-feel testing
}

export function parseTrajectoryModValue(projectile: PlayerProjectile, modValue: TrajectoryModValue): number {
	const r = parseTrajectoryModValueNoTry(projectile, modValue)
	if (!isFinite(r)) {
		parseTrajectoryModValueNoTry(projectile, modValue)
	}
	//throwIfNotFinite(r, `bad trajectory mod value from: ${JSON.stringify(modValue)}`)
	return r
}

export function parseTrajectoryModValueNoTry(projectile: PlayerProjectile, modValue: TrajectoryModValue): number {
	switch (modValue.modValueType) {
		case TrajectoryModValueType.VALUE:
			return modValue.value as number
		case TrajectoryModValueType.RANDOM:
			return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * projectile.randomMod
		case TrajectoryModValueType.DISTANCE:
			return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * projectile.distanceMod
		case TrajectoryModValueType.LIFETIME:
			return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * (projectile.travelTimeElapsedInSeconds / projectile.lifespan)
		case TrajectoryModValueType.LIFETIME_SQUARED:
			return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * (Math.pow(projectile.travelTimeElapsedInSeconds, 2) / Math.pow(projectile.lifespan, 2))
	}
}

