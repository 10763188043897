import { Audio } from "../../../engine/audio"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { Player } from "../../../entities/player"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { PlayerProjectile, ProjectileInitialParams } from "../../../projectiles/projectile"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { ResourceType } from "../../weapon-definitions"
import { AllWeaponTypes } from "../../weapon-types"
import { DogSkillWeapon } from "../skill/dog-chomp-skill-weapon"
import { Vector } from "sat"
import { angleBetweenVectors } from "../../../utils/vector"


export class ConeDogThornsWeapon extends AutoFireSecondaryWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.ConeDogThorns

    autoFire: boolean

	projectileCreationParams: ProjectileInitialParams

    reloadChompOnShootThorns: boolean

    constructor(player: Player, statList: EntityStatList) {
        super(player, statList)

        this.projectileCreationParams = {
            owningEntityId: player.nid,
			position: player.position,
			speed: this.statList.getStat(StatType.projectileSpeed),
			aimAngleInRads: 0, // gets changed when fired
			radius: this.statList.getStat(StatType.attackSize),
			trajectoryMods: [],
			collisionLayer: CollisionLayerBits.PlayerProjectile,
			statList: this.statList,
			resourceType: ResourceType.NONE,
			player: this.player,
			weaponType: this.weaponType,
            effectType: ParticleEffectType.PROJECTILE_HEAD_THORNWOLF,
            trailType: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
        }

        this.reloadChompOnShootThorns = false
    }

    fire() {
        if(this.autoFire) {
            this.shootThorns()
        }
    }

    forceStopFiring() {
        // don't need to do anything
    }

    shootThorns() {
        this.projectileCreationParams.speed = this.statList.getStat(StatType.projectileSpeed)
        this.projectileCreationParams.radius = this.statList.getStat(StatType.attackSize)
        this.projectileCreationParams.position = this.player.position

        let angle = 0
        const numProjectiles = this.statList.getStat(StatType.projectileCount)
        const angleIncrement = (Math.PI * 2) / numProjectiles

        for (let i =0; i < numProjectiles; ++i) {
            this.projectileCreationParams.aimAngleInRads = angle
            PlayerProjectile.objectPool.alloc(this.projectileCreationParams)

            angle += angleIncrement
        }

        Audio.getInstance().playSfx('SFX_Elemental_Thunder')

        if (this.reloadChompOnShootThorns) {
            const chompWep = this.player.skillWeapon as DogSkillWeapon
            chompWep.cooldown.reduceNextReload(999_999)
        }
    }

    releaseThornFromPosition(position: Vector) {
        this.projectileCreationParams.speed = this.statList.getStat(StatType.projectileSpeed)
        this.projectileCreationParams.radius = this.statList.getStat(StatType.attackSize)
        this.projectileCreationParams.position = position

        this.projectileCreationParams.aimAngleInRads = angleBetweenVectors(this.player.position, position)

        PlayerProjectile.objectPool.alloc(this.projectileCreationParams)
    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.baseDamage = 30
        statList._actualStatValues.attackSize = 35
        statList._actualStatValues.projectileCount = 8
		statList._actualStatValues.attackPierceCount = 1
		statList._actualStatValues.projectileSpeed = 1_100
		statList._actualStatValues.projectileLifeSpan = 0.6

        statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 10_000
		statList._actualStatValues.reloadInterval = 50
    }
}