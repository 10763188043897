<template>
	<div class="progress-bar-wrapper">
		<div class="progress-bar-inner" :class="{ glow: value >= maxValue }" :style="{ width: (Math.clamp(getWidth() * 100, 0, 100)) + '%' }">
			<!-- {{ value }} / {{ maxValue }} -->
		</div>
	</div>	
</template>

<script lang="ts">
import { percentage } from '../../../utils/primitive-types'
// PROP-ONLY COMPONENT!
// This component is not intended to pluck state directly from Vuex, unless it is related to
// screen size, layout, rendering, or other device-specific/responsive concerns.

export default {
	name: 'ProgressBar',
	components: {

	},
	data() {
		return {}
	},
	props: {
		maxValue: {
			type: Number,
			required: false,
			default: 1,
		},
		value: {
			type: Number,
			required: true,
		},
		minValue: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	methods: {
		getWidth(): percentage {
			const width = (this.value - this.minValue) / (this.maxValue - this.minValue)
			return width
		},
	}
}
</script>

<style lang="less" scoped>
.progress-bar-wrapper {
	position: relative;
	background-color: #0a1f4b;
	height: 100%;
	min-height: 20px;
	// border-radius: 30px;

	// &.thick {
	// 	margin: 20px 40px;
	// }

	// &.thin {
	// 	margin: 5px 10px;
	// }

	.progress-bar-inner {
		background-color: #ffa914;
		height: 100%;
		min-height: 20px;
		// border-radius: 30px;

		&.glow {
			filter: drop-shadow(0px 0px 10px rgba(255, 169, 20, 0.8));
		}
	}
}

</style>
