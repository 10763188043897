import { EntityType } from '../entities/entity-interfaces'
import { UI } from '../ui/ui'
import { MetaUnlocksManager } from '../upgrades/meta/meta-unlocks-manager'
import { EVENT_UPGRADE_COLLECTIONS, NON_WEAPON_UPGRADE_COLLECTIONS, WEAPON_UPGRADE_COLLECTIONS } from '../upgrades/upgrade-definitions'
import { UpgradeManager } from '../upgrades/upgrade-manager'
import { debugConfig } from '../utils/debug-config'
import { timeInSeconds } from '../utils/primitive-types'
import { InGameTime } from '../utils/time'
import { GameClient } from './game-client'
import { GameState } from './game-state'
import MersenneTwister from 'mersenne-twister'
import { PRIMARY_WEAPON_UPGRADES } from '../upgrades/primary-weapon-upgrades'
import { SECONDARY_WEAPON_UNLOCKS, SECONDARY_WEAPON_UPGRADES } from '../upgrades/secondary-weapon-upgrades'
import { StatOperator, StatType } from '../stats/stat-interfaces-enums'

const LOG_HITCHING = false

// TODO Remove this once a spawner system has been finalized
let timer: number = 0
let lastSecondPassed: number = 0
let lastTenthSecondPassed: number = 0

let finalCount = 0
const gcRegistry = new FinalizationRegistry((val) => {
	finalCount++
	console.debug(`[${finalCount.toString().padStart(6)}] GC freed ${val} `)
})

export const WEAPON_URL_KEY = 'weapon'
export const CHARACTER_URL_KEY = 'character'
const DEBUG_SPEAR_MOVEMENT_MODE_KEY = 'spearMove'

export class GameClientDEBUG {
	static addDummyStuff(self: GameClient) {
        
		const searchParams = new URLSearchParams(window.location.search)

		debugConfig.spearHopMode = Number.parseInt(searchParams.get('hopMode')) || 0
		debugConfig.spearHopTime = Number.parseInt(searchParams.get('hopTime')) || 1
		debugConfig.spearHopForce = Number.parseInt(searchParams.get('hopForce')) || 1_200
		debugConfig.spearHopDuration = Number.parseFloat(searchParams.get('hopDuration')) || 0.2
		// MetaUpgradeManager.getInstance().setStacks(MetaUpgradeName.GameSpeed, 0)

		if (debugConfig.benchmarkMode) {
			InGameTime.debugAddSeconds(debugConfig.benchmarkTimeSkip)
			MetaUnlocksManager.getInstance().unlockEverything()
			MetaUnlocksManager.getInstance().applyUnlocks()

			GameState.player.showLevelUpModal = false
			GameState.player.godmode = true

			// upgrades
			const upgradeSeed = debugConfig.benchmarkUpgradeSeed
			const numUpgrades = debugConfig.benchmarkLevels
			const mt = new MersenneTwister(upgradeSeed)
			for (let i = 0; i < numUpgrades; ++i) {
				const up = UpgradeManager.rollUpgrades(2, 'level-up', false, mt)
				UpgradeManager.redeemUpgrade(up.rolledUpgrades[Math.round(mt.random())])
			}

			let bossUp = UpgradeManager.rollUpgrades(1, 'boss', false, mt)
			UpgradeManager.redeemUpgrade(bossUp.rolledUpgrades[0])
			bossUp = UpgradeManager.rollUpgrades(1, 'boss', false, mt)
			UpgradeManager.redeemUpgrade(bossUp.rolledUpgrades[0])
		}

		if (debugConfig.hugeDamageBuff) {
			GameState.player.stats.addStatBonus(StatType.allDamageMult, StatOperator.MULTIPLY, 100)
		}
	}

	static debugUpdate(delta: timeInSeconds, self): void {
		timer += delta
		// ghetto timer to do one-shot things every __ seconds/tenths-of-seconds. See conditionals below.
		const secondPassed = (Math.floor(timer) > lastSecondPassed) ? Math.floor(timer) : 0
		const tenthSecondPassed = (Math.floor(timer * 10) > lastTenthSecondPassed) ? Math.floor(timer * 10) : 0


		if (secondPassed) {
			lastSecondPassed = secondPassed
		}
		if (tenthSecondPassed) {
			lastTenthSecondPassed = tenthSecondPassed
		}
	}

	static initializeDebugTools() {
		// add upgrades to picker
		UI.getInstance().emitMutation('ui/updateAllUpgrades', UpgradeManager.getAllCollectionAndUpgradesByName())
		UI.getInstance().emitMutation('ui/updateWeaponUpgrades', {
			all: UpgradeManager.getAllCollectionAndUpgradesByName(WEAPON_UPGRADE_COLLECTIONS),
			primary: UpgradeManager.getAllCollectionAndUpgradesByName(PRIMARY_WEAPON_UPGRADES),
			secondary: UpgradeManager.getAllCollectionAndUpgradesByName(SECONDARY_WEAPON_UPGRADES),
			secondaryUnlocks: UpgradeManager.getAllCollectionAndUpgradesByName(SECONDARY_WEAPON_UNLOCKS),
		})
		UI.getInstance().emitMutation('ui/updateNonWeaponUpgrades', UpgradeManager.getAllCollectionAndUpgradesByName(NON_WEAPON_UPGRADE_COLLECTIONS))
		UI.getInstance().emitMutation('ui/updateEventUpgrades', UpgradeManager.getAllCollectionAndUpgradesByName(EVENT_UPGRADE_COLLECTIONS))

		//TODO: add mutations to picker
	}
}

if (LOG_HITCHING) {
	document.addEventListener('GameLoopHitched', (event: CustomEvent) => {
		console.log(`HITCH! ${event.detail.actualDuration}ms (${event.detail.overage}ms / ${~~(event.detail.actualDuration / event.detail.expectedDuration * 100)}% over)`)
		const entityCounts = {
			players: 1,
			enemies: GameState.enemyList.length,
			projectiles: GameState._entityListByType.get(EntityType.Projectile).length,
			pickups: GameState._entityListByType.get(EntityType.Pickup).length,
			pros: GameState._entityListByType.get(EntityType.Prop).length,
		}
		console.log('Entity counts:', entityCounts)
	})
}