import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"

const winterGoblin: EnemyAI ={
    name: ENEMY_NAME.WINTER_GOBLIN_1,
    type: EnemyType.BASIC,
    objectPoolGrowthSize: 8,
    objectPoolInitialSize: 300,
    appearance: {
        asset: SpineDataName.WINTER_GOBLIN_1,
        skin: EnemyBipedSkinType.DEFAULT,
        spriteSheet: true,
        mixSettings: [],
    },
    baseAttributes: {
        ...EnemyDefaults,
        colliders: [
			{
				type: ColliderType.Circle,
				radius: 60,
				position: [0, 0],
			},
		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.65,
			maxHealth: 0.85,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		animationSpeeds: 0.25,

    },
    soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
    states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 100,
			movementMinDistance: 0,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default winterGoblin