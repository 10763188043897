import { CharacterType } from "../../game-data/characters"
import { InGameTime } from "../../utils/time"

const store = {
    namespaced: true,
    state: {
        nextCooldown: 0,
		nextRecharge: 0,
		ammo: 0,
		maxAmmo: 0,
        cooldownSetAt: 0,
		rechargeSetAt: 0,
		killstreakEnabled: false,
		currentKillstreak: 0,
		lastKillTime: 0,
		killstreakExpiryTime: 0,
		skillIcon: ''
    },
    getters: {

    },
    mutations: {
        updateSkillCooldown(state, cooldownData) {
			const { ammo, maxAmmo, nextCooldown, nextRecharge } = cooldownData
			state.ammo = ammo
			state.maxAmmo = maxAmmo
            state.nextCooldown = nextCooldown
			state.nextRecharge = nextRecharge
            state.cooldownSetAt = InGameTime.highResolutionTimestamp()
            state.rechargeSetAt = InGameTime.highResolutionTimestamp()
        },

		updateKillstreak(state, [newKillstreak, lastKillTime, killstreakExpiryTime]) {
			state.currentKillstreak = newKillstreak
			state.lastKillTime = lastKillTime
			state.killstreakExpiryTime = killstreakExpiryTime
		},

		updateSkillIcon(state, skillIcon){
			state.skillIcon = skillIcon
		},

		toggleKillstreak(state, enabled?: boolean) {
			if (enabled !== undefined) {
				state.killstreakEnabled = enabled
			} else {
				state.killstreakEnabled = !state.killstreakEnabled
			}
		},
		reset(state) {
			state.nextCooldown = 0
			state.nextRecharge = 0
			state.ammo = 0
			state.maxAmmo = 0
			state.cooldownSetAt = 0
			state.rechargeSetAt = 0
			state.killstreakEnabled = false
			state.currentKillstreak = 0
			state.lastKillTime = 0
			state.killstreakExpiryTime = 0
			state.skillIcon = ''
		}
    }
}

export const playerStore = () => {
    return store
}