<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div class="group">
				<div class="label" :lang="this.$i18n.locale">{{ $t('account_linking.header_link_account') }}</div>
				<div v-if="isElectron" class="button-container">
					<TextButton :button-label="$t('account_linking.button_link_code')" :button-style="'green'" :font-color="'green'" @on-click-fn="changeView"></TextButton>
				</div>
				<div v-else-if="!isElectron" class="button-container">
					<TextButton :button-label="$t('account_linking.button_link_generate')" :button-style="'green'" :font-color="'green'" @on-click-fn="openGenerateLinkCode()" :disabled="isPlayerCodeInfoLoading" :loading="isPlayerCodeInfoLoading"></TextButton>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextButton from '../buttons/text-button.vue'
import { Views } from '../../store/ui-store'
import EmbellishmentContainer from '../embellishment-container.vue'

export default {
	name: 'AccountLinkingModule',
	components: {
		TextButton,
		EmbellishmentContainer,
	},
	data() {
		return {
			isElectron: process.env.IS_ELECTRON,
		}
	},
	props: {},
	computed: {
		...mapState('user', ['isPlayerCodeInfoLoading']),
	},
	methods: {
		...mapActions('ui', ['changeActiveView']),
		...mapActions('user', ['generateAccountLinkCode']),
		changeView() {
			this.changeActiveView(Views.ACCOUNT_LINKING)
		},
		openGenerateLinkCode() {
			this.generateAccountLinkCode() // the action changes the view on success or pops an error
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.group {
	display: flex;
	align-items: center;
	width: 660px;
	margin: 5px 0px 0px 0px;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 238px;
		margin-right: 85px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.button-container {
		display: flex;
		width: 423px;
		.text-button{
			width: 350px ;
		}
	}
}
</style>
