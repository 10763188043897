<template>
	<div class="health-bar-wrapper">
		<div v-if="useHalves">
			<div v-for="i in halfPips" :key="i" class="heart-segment" :class="{
				'filled': i * 2 <= value,
				'half': i * 2 === value + 1,
				'empty': i * 2 > value + 1,
			}"></div>
		</div>
	</div>
</template>

<script lang="ts">
// PROP-ONLY COMPONENT!
// This component is not intended to pluck state directly from Vuex, unless it is related to
// screen size, layout, rendering, or other device-specific/responsive concerns.

export default {
	name: 'HealthBar',
	components: {},
	data() {
		return {}
	},
	props: {
		useHalves: {
			type: Boolean,
			required: true,
		},
		pips: {
			type: Number,
			required: false,
			default: 1,
		},
		value: {
			type: Number,
			required: true,
		},
	},
	computed: {
		halfPips() {
			return Math.round(this.pips / 2)
		},
	},
}
</script>

<style lang="less" scoped>
.health-bar-wrapper {
	position: relative;
	div {
		display: flex;
	}

	.heart-segment {
		margin: 0 2px;

		&.filled {
			background-image: url('../../../../assets/ui/health-containers/heart-container-full.png');
			width: 36px;
			height: 40px;
		}
		&.half {
			background-image: url('../../../../assets/ui/health-containers/heart-container-half.png');
			width: 32px;
			height: 34px;
			position: relative;
			top: 5px;
		}
		&.empty {
			background-image: url('../../../../assets/ui/health-containers/heart-container-empty.png');
			width: 29px;
			height: 28px;
			position: relative;
			top: 5px;
		}
	}
}
</style>
