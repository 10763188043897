import { Vector } from 'sat'
import { throttle } from 'lodash'
import { invertRecord } from '../utils/records'
import { Renderer } from './graphics/renderer'
import { GameState } from './game-state'
import { GameClient } from './game-client'

export const InputAction = {
	MOVE_UP: 'moveUp',
	MOVE_DOWN: 'moveDown',
	MOVE_LEFT: 'moveLeft',
	MOVE_RIGHT: 'moveRight',
	SHOOT: 'shoot',
	SKILL: 'skill',
	PAUSE: 'pause'
} as const

export type InputAction = typeof InputAction[keyof typeof InputAction]

// We'll consider making this configurable
const DEAD_ZONE = 0.03
const INPUT_SWITCH_VEC_LEN2_MIN = 0.2

function clipDeadZone(n) {
	if (Math.abs(n) <= DEAD_ZONE) {
		return 0
	}
	return n
}

interface ControllerEvent extends Event {
	detail: { x: number, y: number }
}

const KeyNames = {
	KEY_W: 'KeyW',
	KEY_S: 'KeyS',
	KEY_A: 'KeyA',
	KEY_D: 'KeyD',
	MOUSE_L: 'MouseL',
	MOUSE_R: 'MouseR',
	SPACE: 'Space',
	KEY_P: 'KeyP'
} as const

export type KeyName = typeof KeyNames[keyof typeof KeyNames]

export interface FrameInput {
	currentFrame: boolean
	currentState: boolean
}

type ElectronEventTypes = "controllerMove" | "controllerButton" | "controllerAim" | 'updateControllers'

export const INPUT_DOWN_ACTION_EVENT_NAME = 'PlayerInputDown'
export const INPUT_PRESS_ACTION_EVENT_NAME = 'PlayerInputPress'
export const INPUT_UP_ACTION_EVENT_NAME = 'PlayerInputUp'
let steamInputHandler

if (process.env.IS_ELECTRON) {
	steamInputHandler = async (event, eventType: ElectronEventTypes, payload) => {
		if (GameClient.isShutDown) {
			return
		}

		switch (eventType) {
			case 'controllerMove': {
				let { x, y } = payload
				ClientPlayerInput.controllerMoveVector.x = x
				ClientPlayerInput.controllerMoveVector.y = -y

				if (ClientPlayerInput.controllerMoveVector.len2() >= INPUT_SWITCH_VEC_LEN2_MIN) {
					ClientPlayerInput.controllerActive = true
					document.body.requestPointerLock()
				}
				/* 						x = clipDeadZone(x)
										y = clipDeadZone(y)
										x = Math.sign(x)
										y = Math.sign(y)
				
										if (x > 0) {
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_RIGHT, true)
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_LEFT, false)
										} else if (x < 0) {
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_LEFT, true)
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_RIGHT, false)
										} else {
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_LEFT, false)
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_RIGHT, false)
										}
										if (y > 0) {
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_DOWN, false)
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_UP, true)
										} else if (y < 0) {
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_UP, false)
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_DOWN, true)
										} else {
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_UP, false)
											ClientPlayerInput.currentFrameInput.set(InputAction.MOVE_DOWN, false)
										} */
				break
			}
			case 'controllerAim': {
				if (Math.abs(payload.x) + Math.abs(payload.y) >= INPUT_SWITCH_VEC_LEN2_MIN) {
					ClientPlayerInput.controllerActive = true
					document.body.requestPointerLock()

					ClientPlayerInput.controllerAimVector.x = payload.x
					ClientPlayerInput.controllerAimVector.y = -payload.y
				}
				break
			}
			case 'controllerButton': {
				const { action, state } = payload
				const eventType = state ? INPUT_DOWN_ACTION_EVENT_NAME : INPUT_UP_ACTION_EVENT_NAME
				ClientPlayerInput.currentFrameInput.set(action, state)
				const buttonEvent = new CustomEvent(eventType, { detail: action })
				document.dispatchEvent(buttonEvent)
				//const controllerEvent = new CustomEvent("PlayerInputDown", {detail: 'shoot'})
				//document.dispatchEvent(controllerEvent)
				
				ClientPlayerInput.controllerActive = true
				document.body.requestPointerLock()
				break
			}
		}
	}
	window.bridge.ElectronToDiebrary(steamInputHandler)
}

export default class ClientPlayerInput {
	static getInstance(renderer?: Renderer): ClientPlayerInput {
		if (!ClientPlayerInput.instance) {
			if (renderer === undefined) {
				throw new Error('No game client state params given to ClientPlayerInput getInstance(); aborting startup')
			} else {
				ClientPlayerInput.instance = new ClientPlayerInput(renderer)
			}
		}
		return this.instance
	}

	static shutdown() {
		for (const [eventName, handler] of ClientPlayerInput.getInstance().eventHandlers) {
			document.removeEventListener(eventName, handler)
		}

		window.removeEventListener('blur', ClientPlayerInput.instance.blurHandler)
		ClientPlayerInput.instance.eventHandlers.clear()
		ClientPlayerInput.instance.blurHandler = null
		ClientPlayerInput.instance.releaseKeys()

		ClientPlayerInput.instance = null
	}

	static hasInstance() {
		return Boolean(this.instance)
	}

	private static instance: ClientPlayerInput

	eventHandlers: Map<string, (event: KeyboardEvent | MouseEvent) => void> = new Map()

	blurHandler: any

	static controllerMoveVector: Vector = new Vector()
	static controllerAimVector: Vector = new Vector()

	static controllerActive: boolean

	static mouseWindowX: number = 0
	static mouseWindowY: number = 0

	static worldMouseX: number = 0
	static worldMouseY: number = 0


	renderer: Renderer

	static currentFrameInput: Map<InputAction, boolean> = new Map()
	// lastFrameInput: Map<string, boolean> = new Map()

	static actionToControlMap: Record<InputAction, KeyName> = {
		moveUp: 'KeyW',
		moveDown: 'KeyS',
		moveLeft: 'KeyA',
		moveRight: 'KeyD',
		shoot: 'MouseL',
		skill: 'Space',
		pause: 'KeyP'
		// note that this will fail if either the key or value is wrong
		// moveBlown: 'hi',
	}

	updateActionToControlMap(mapping) {
		for (const key in mapping) {
			ClientPlayerInput.actionToControlMap[key] = mapping[key];
		}
		ClientPlayerInput.controlToActionMap = invertRecord(ClientPlayerInput.actionToControlMap)
	}

	static controlToActionMap = invertRecord(this.actionToControlMap)

	static preventSpaceInput: boolean = false

	constructor(renderer: Renderer) {
		ClientPlayerInput.controllerAimVector = new Vector(0, 0)
		ClientPlayerInput.controllerMoveVector = new Vector(0, 0)
		if (ClientPlayerInput.controllerActive === null) {
			ClientPlayerInput.controllerActive = false
			document.exitPointerLock()
		}

		this.renderer = renderer

		this.setupDOMListeners()
	}

	releaseKeys() {
		ClientPlayerInput.currentFrameInput.clear()
	}

	update(delta: number) {
		const worldPos = Renderer.getInstance().mouseCoordinatesToWorldCoordinates(ClientPlayerInput.mouseWindowX, ClientPlayerInput.mouseWindowY)

		ClientPlayerInput.worldMouseX = worldPos.x
		ClientPlayerInput.worldMouseY = worldPos.y
	}

	getThisFrameAction(action: InputAction) {
		return Boolean(ClientPlayerInput.currentFrameInput.get(action))
	}

	addEventListener(eventName: string, handler: (event: KeyboardEvent | MouseEvent | ControllerEvent) => void) {
		this.eventHandlers.set(eventName, handler)
		document.addEventListener(eventName, handler)
	}

	private setupDOMListeners() {

		if (process.env.IS_ELECTRON) {
			// TODO use controller selection etc to determine controller activity
			window.bridge.setControllerMode('InGameControls')
		}

		this.addEventListener('contextmenu', (event: MouseEvent) =>
			// prevents right click from showing the context menu
			event.preventDefault(),
		)

		this.addEventListener(
			'wheel',
			throttle(
				() => {
					//@TODO CAKES mousewheel input
				},
				333,
				{ trailing: false },
			),
		)

		this.addEventListener('keydown', (event: KeyboardEvent) => {
			if (ClientPlayerInput.preventSpaceInput && event.code === 'Space') {
				event.preventDefault()
			}

			ClientPlayerInput.controllerActive = false
			document.exitPointerLock()

			const playerAction: InputAction = ClientPlayerInput.controlToActionMap[event.code]

			const old = ClientPlayerInput.currentFrameInput.get(playerAction)
			ClientPlayerInput.currentFrameInput.set(playerAction, true)

			if (playerAction) {
				const event = new CustomEvent(INPUT_DOWN_ACTION_EVENT_NAME, { detail: playerAction })
				document.dispatchEvent(event)

				if (!old) {
					const event = new CustomEvent(INPUT_PRESS_ACTION_EVENT_NAME, { detail: playerAction })
					document.dispatchEvent(event)
				}
			}
		})

		this.addEventListener('keyup', (event: KeyboardEvent) => {
			const playerAction: InputAction = ClientPlayerInput.controlToActionMap[event.code]

			ClientPlayerInput.currentFrameInput.set(playerAction, false)

			if (playerAction) {
				const event = new CustomEvent(INPUT_UP_ACTION_EVENT_NAME, { detail: playerAction })
				document.dispatchEvent(event)
			}

			// if (document.activeElement.tagName === 'INPUT') {
			// 	return
			// }

			// events that don't result in keyUp behavior go below here
		})

		this.addEventListener('visibilitychange', () => {
			this.releaseKeys()
		})

		this.blurHandler = () => {
			this.releaseKeys()
		}
		//WINDOW intentional
		window.addEventListener('blur', this.blurHandler)

		this.addEventListener('mousemove', (event: MouseEvent) => {
			ClientPlayerInput.mouseWindowX = event.clientX
			ClientPlayerInput.mouseWindowY = event.clientY

			ClientPlayerInput.controllerAimVector.x = ((event.clientX / window.innerWidth) * -2) + 1
			ClientPlayerInput.controllerAimVector.y = ((event.clientY / window.innerHeight) * 2) - 1

			document.exitPointerLock()
		})

		this.addEventListener('pointerdown', (event: MouseEvent) => {
			let controlString: KeyName

			switch (event.button) {
				case 0:
				case 1:
					controlString = KeyNames.MOUSE_L
					break
				case 2:
					controlString = KeyNames.MOUSE_R
					break
				default:
					return
			}

			ClientPlayerInput.controllerActive = false
			document.exitPointerLock()

			const playerAction = ClientPlayerInput.controlToActionMap[controlString]
			ClientPlayerInput.currentFrameInput.set(playerAction, true)
			if (playerAction) {
				const event = new CustomEvent(INPUT_DOWN_ACTION_EVENT_NAME, { detail: playerAction })
				document.dispatchEvent(event)
			}
		})





		this.addEventListener('mouseup', (event: MouseEvent) => {
			let controlString: KeyName

			switch (event.button) {
				case 0:
				case 1:
					controlString = KeyNames.MOUSE_L
					break
				case 2:
					controlString = KeyNames.MOUSE_R
					break
				default:
					return
			}


			const playerAction: InputAction = ClientPlayerInput.controlToActionMap[controlString]
			ClientPlayerInput.currentFrameInput.set(playerAction, false)
			if (playerAction) {
				const event = new CustomEvent(INPUT_UP_ACTION_EVENT_NAME, { detail: playerAction })
				document.dispatchEvent(event)
			}
		})
	}
}
