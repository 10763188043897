import { Weapon } from "./weapon"
import { ChargeDefinition, ResourceType, WEAPON_STATS } from '../weapons/weapon-definitions'
import { Player } from "../entities/player"
import EntityStatList from "../stats/entity-stat-list"
import { ParticleEffectType } from "../engine/graphics/pfx/particle-config"
import { TrajectoryMod } from "../projectiles/trajectory-presets"
import { WeaponConfig } from "./weapon-types"


export abstract class PrimaryWeapon extends Weapon {
	resourceType: ResourceType
	chargeIncrements: ChargeDefinition[]
	trajectory: TrajectoryMod
	player: Player

	abstract projectileEffectType: ParticleEffectType
	abstract projectileTrailType: ParticleEffectType

	constructor(player: Player, statList: EntityStatList){
		super(player, statList)
		this.resourceType = this.weaponResourceType()
		this.chargeIncrements = this.getChargeIncrements()
		this.trajectory = this.getTrajectory()
	}

	abstract init(player: Player, playerStatList: EntityStatList)

	weaponResourceType(): ResourceType {
        const weaponName = WeaponConfig[this.player.primaryWeaponType].dbName
        return WEAPON_STATS[weaponName].resourceType
	}

	getTrajectory(): TrajectoryMod | null {
        const weaponName = WeaponConfig[this.player.primaryWeaponType].dbName
		return WEAPON_STATS[weaponName].trajectory || null
	}
	
	getChargeIncrements(): ChargeDefinition[] | null {
		if(this.resourceType === ResourceType.CHARGE_UP_SHOT) {
			return WEAPON_STATS.bow.chargeIncrements || null
		}
		return null
	}
}
