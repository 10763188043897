import { Container, Sprite, Texture } from "pixi.js"
import { AssetManager } from "../../web/asset-manager"
import { getVisibleWorldHeight, getVisibleWorldWidth } from "./camera-logic"
import { Vector } from "sat"

export class Vignette {
	container: Container
	texture: Texture
	width: number
	height: number

	constructor(zoomLevel: number) {
		this.container = new Container()
		this.texture = AssetManager.getInstance().getAssetByName('vignetteTopLeftCorner').texture
		this.container.name = 'vignette'
		this.resizeVignette(zoomLevel)
	}

	destroy(): void {
		this.container.destroy({children: true, texture: true, baseTexture: true})
	}

	private setupVignette(): void {
		for (let i = 0; i < 4; ++i) {
			const sprite: Sprite = new Sprite(this.texture)
			sprite.name = 'vignette-tex'
			this.container.addChild(sprite)
			if (i === 0) {
				sprite.position.set(0, 0)
				sprite.rotation = 0
			} else if (i === 1) {
				sprite.position.set(this.width, 0)
				sprite.rotation = Math.PI / 2
			} else if (i === 2) {
				sprite.position.set(this.width, this.height)
				sprite.rotation = Math.PI
			} else if (i === 3) {
				sprite.position.set(0, this.height)
				sprite.rotation = (3 * Math.PI) / 2
			}
		}
	}

	updatePosition(position: Vector): void {
			this.container.position.set(position.x - this.width / 2, position.y - this.height / 2)
	}

	resizeVignette(zoomLevel): void {
		this.width = getVisibleWorldWidth(zoomLevel)
		this.height = getVisibleWorldHeight(zoomLevel)
		this.container.removeChildren()
		if (this.texture) {
			this.setupVignette()
		}
	}
	
}