import { Vector } from "sat"
import { SingleSpriteGraphicsComponent } from "../../engine/graphics/single-sprite-graphics-component"
import { timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../../utils/third-party/object-pool"
import { InGameTime } from "../../utils/time"
import { EntityType, IEntity } from "../entity-interfaces"
import { allocGroundPickup } from "./ground-pickup"
import { GroundPickupConfigType } from "./ground-pickup-types"
import { GameState, getNID } from "../../engine/game-state"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { AssetManager } from "../../web/asset-manager"
import { Renderer } from "../../engine/graphics/renderer"

export interface PlantedXPParams {
    dropPickup: GroundPickupConfigType
    plantTime: timeInSeconds
    position: Vector
}

const FARM_GRID_SIZE = 75

export class PlantedXP implements PoolableObject, IEntity {
    static pool: ObjectPoolTyped<PlantedXP, PlantedXPParams>
    static pfxConfig: EffectConfig

    nid: number
    entityType: EntityType = EntityType.Prop
	timeScale: number = 1

    position: Vector


    smallPlantGfx: SingleSpriteGraphicsComponent
    largePlantGfx: SingleSpriteGraphicsComponent

    private plantExpiryTime: timeInSeconds
    private nextSpriteTime: timeInSeconds

    private dropPickup: GroundPickupConfigType

    private isSmallPlant: boolean

    constructor() {
        this.position = new Vector()

        this.nid = getNID(this)
        this.makeGfx()

        if (!PlantedXP.pfxConfig) {
            PlantedXP.pfxConfig = AssetManager.getInstance().getAssetByName('spectral-farmer-planted-pfx').data
        }
    }

    setDefaultValues(defaultValues: any, overrideValues?: PlantedXPParams) {
        if (overrideValues) {

            this.plantExpiryTime = InGameTime.timeElapsedInSeconds + overrideValues.plantTime
            this.dropPickup = overrideValues.dropPickup

            this.position.copy(PlantedXP.gridSnapToNearestPosition(overrideValues.position, 2))

            this.smallPlantGfx.instancedSprite.zIndex = this.position.y
            this.largePlantGfx.instancedSprite.zIndex = this.position.y

            this.isSmallPlant = true
            this.smallPlantGfx.update()
            this.smallPlantGfx.addToScene()

            this.nextSpriteTime = InGameTime.timeElapsedInSeconds + (overrideValues.plantTime / 2)

            GameState.addEntity(this)

            Renderer.getInstance().addOneOffEffectByConfig(PlantedXP.pfxConfig, this.position.x, this.position.y, this.position.y-20, 1, 0.5, true)
        }
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        if (this.isSmallPlant && InGameTime.timeElapsedInSeconds >= this.nextSpriteTime) {
            this.isSmallPlant = false
            this.smallPlantGfx.removeFromScene()

            this.largePlantGfx.update()
            this.largePlantGfx.addToScene()
        }

        if (InGameTime.timeElapsedInSeconds >= this.plantExpiryTime) {
            this.onPlantExpired()
        }
    }

    cleanup() {
        if (this.isSmallPlant) {
            this.smallPlantGfx.removeFromScene()
        } else {
            this.largePlantGfx.removeFromScene()
        }
        
        GameState.removeEntity(this)
    }

	static gridSnapToNearestPosition(pos: Vector, fuzz: number = 0) {
		if (fuzz) {
			fuzz *= FARM_GRID_SIZE
			pos.x += Math.getRandomInt(-fuzz, fuzz)
			pos.y += Math.getRandomInt(-fuzz, fuzz)
		}
		pos.x = Math.round(pos.x / FARM_GRID_SIZE) * FARM_GRID_SIZE
		pos.y = Math.round(pos.y / FARM_GRID_SIZE) * FARM_GRID_SIZE
		return pos
	}

    private onPlantExpired() {
        allocGroundPickup(this.dropPickup, this.position.x, this.position.y)

        // free this
        PlantedXP.pool.free(this)
    }

    private makeGfx() {
        this.smallPlantGfx = new SingleSpriteGraphicsComponent('pickup-icons', 'xp-farm-plant-01.png', this)
        this.largePlantGfx = new SingleSpriteGraphicsComponent('pickup-icons', 'xp-farm-plant-02.png', this)
    }
}
