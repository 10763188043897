// 1) We are heavy on transitions so there is a chance .getElementById('menu') might be called before the element is rendered
// To get around that I used transition hooks for entering which is called a frame after the element is inserted. 
// this is an alt solution to using mounted where I still can't guarantee the element is rendered. 

import { assign, chain, debounce, orderBy } from 'lodash'
import { UI } from '../ui'
import logger from '../../utils/client-logger'
import { mapToRange } from '../../utils/math'
import { nextTick } from 'vue'
const { removeScalingFromWidthHeight, addDeviceScalingToWidthHeight } = require('../../electron-required/add-remove-scaling-helper')


// Living list of common resolutions the game will support for Electron
export const commonResolutions = {
	ratio16By9: [
		{ width: 4480, height: 2520 },
		{ width: 3840, height: 2160 },
		{ width: 2560, height: 1440 },
		{ width: 1920, height: 1080 },
		{ width: 1600, height: 900 },
		{ width: 1366, height: 768 },
		{ width: 1280, height: 720 },
		{ width: 1024, height: 576 },
	],
	ratio16By10: [
		{ width: 3840, height: 2400 },
		{ width: 2560, height: 1600 },
		{ width: 1920, height: 1200 },
		{ width: 1680, height: 1050 },
		{ width: 1440, height: 900 },
		{ width: 1280, height: 800 },
		{ width: 1024, height: 640 },
	],
	ratio21By9: [
		{ width: 5120, height: 2160 },
		{ width: 3440, height: 1440 },
		{ width: 2560, height: 1080 },
	]
}



const rescale = debounce(() => {
	UI.getInstance().emitMutation('uiScaling/updateAspectRatio', getGeneralAspectRatio())
	// getting the scale for your screen needs to be done on the nextTick
	// hence the action to call getResponsiveScaleAmount
	UI.getInstance().emitAction('uiScaling/fetchResponsiveScale')
}, 100)

const getResponsiveScaleAmount = (aspectRatio) => {
	const elSize = document.getElementById('menu').getBoundingClientRect()
	let scaling

	// 21 by 9 isn't using the full width of the page for the UI so we can piggy back off 16 by 9 and just have all 
	// content into a view centered in the middle of the screen. 
	if (aspectRatio === 'ratio16By9' || aspectRatio === 'ratio21By9') {
		scaling = {
			from: { min: 540, max: 2160 },
			to: { min: 0.5, max: 2.0 },
			clamped: true,
		}
	} else {
		scaling = {
			from: { min: 540, max: 2400 },
			to: { min: 0.45, max: 2.0 },
			clamped: true,
		}
	}
	return mapToRange(elSize.height, scaling.from.min, scaling.from.max, scaling.to.min, scaling.to.max, scaling.clamped)
}


const getGeneralAspectRatio = () => {
	let aspectRatio

	if (process.env.IS_ELECTRON) {
		aspectRatio = screen.width / screen.height
	} else {
		aspectRatio = window.innerWidth / window.innerHeight
	}


	const diff_16_9 = Math.abs(aspectRatio - (16 / 9));
	const diff_16_10 = Math.abs(aspectRatio - (16 / 10));
	const diff_21_9 = Math.abs(aspectRatio - (21 / 9));

	// This stuff isn't heavily used in the UI anymore for setting classes beside maybe
	// 21 by 9 for a few things size wise. This is now mostly for Electron and window sizing stuff
	if (diff_21_9 < diff_16_9 && diff_21_9 < diff_16_10) {
		return 'ratio21By9';
	} else if (diff_16_9 < diff_16_10) {
		return 'ratio16By9'
	} else if (diff_16_10 < diff_16_9) {
		return 'ratio16By10'
	} else {
		return 'ratio16By9'
	}
}


window.addEventListener('resize', rescale)

const initialState = {
	responsiveScale: 1,
	aspectRatio: getGeneralAspectRatio(),
	selectedWindowResolution: null,
	devicePixelRatio: window.devicePixelRatio
}

const store = {
	namespaced: true,
	state: initialState,
	getters: {
		getSupportedResolutions: (state: UiScalingStoreState) => {
			const { width, height } = removeScalingFromWidthHeight(screen, state.devicePixelRatio)
			const { ratio16By9, ratio16By10, ratio21By9 } = commonResolutions;

			let supportedResolutions = []

			if (state.aspectRatio === 'ratio16By9') {
				supportedResolutions = ratio16By9.filter(resolution => (
					resolution.width <= width ||
					resolution.height <= height
				));
			} else if (state.aspectRatio === 'ratio16By10') {
				supportedResolutions = ratio16By10.filter(resolution => (resolution.width <= width || resolution.height <= height
				));
			} else if (state.aspectRatio === 'ratio21By9') {
				supportedResolutions = ratio21By9.concat(
					ratio16By9,
					ratio16By10
				).filter(resolution => (resolution.width <= width || resolution.height <= height
				));
			}

			const sortedSupportedResolutions = chain(supportedResolutions)
				.orderBy('height', 'asc')
				.map(obj => addDeviceScalingToWidthHeight(obj, state.devicePixelRatio))
				.filter(obj => obj.height >= 540)
				.map((obj, index) => assign({ id: index + 1, name: `${obj.width} x ${obj.height}` }, obj))
				.value()

			return sortedSupportedResolutions
		},
		getResolutionIdx(state: UiScalingStoreState) {
			return state.selectedWindowResolution.id
		}
	},
	mutations: {
		updateAspectRatio(state: UiScalingStoreState, aspectRatio: any) {
			state.aspectRatio = aspectRatio
		},
		updateResponsiveScale(state: UiScalingStoreState, scale: any) {
			state.responsiveScale = scale
		},
		updateWindowResolution(state: UiScalingStoreState, resolution: any) {
			state.selectedWindowResolution = resolution
		}
	},
	actions: {
		fetchResponsiveScale({ state, commit },) {
			nextTick(() => {
				const scale = getResponsiveScaleAmount(state.aspectRatio)
				commit('updateResponsiveScale', scale)
				commit('settings/updateResponsiveWidthHeight', scale, { root: true })
			})
		},
		updateWindowResolution({ state, commit, getters }: { state: UiScalingStoreState, commit: any, getters: any }, payload) {
			const { selectedWindowResolution } = state;
			const { getSupportedResolutions } = getters;

			let resolution;

			resolution = getSupportedResolutions.find(res => res.id === payload)

			commit('updateWindowResolution', resolution);
			if (resolution !== selectedWindowResolution) {
				window.bridge.setElectronWindowSize(resolution)
				window.bridge.updateElectronStore('width', removeScalingFromWidthHeight(resolution, state.devicePixelRatio).width);
				window.bridge.updateElectronStore('height', removeScalingFromWidthHeight(resolution, state.devicePixelRatio).height);
			}
		},
		refreshResolution({ state, dispatch }: { state: UiScalingStoreState, dispatch: any }) {
			dispatch('updateWindowResolution', state.selectedWindowResolution.id)
		}
	},
}

export type UiScalingStoreState = typeof initialState
export const UiScalingStore = typeof store

export const uiScalingStore = () => {
	return store
}


