import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { getDamageByPlayerLevel } from "../../game-data/player-formulas"
import { ObjectPool } from "../../utils/third-party/object-pool"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"
import { getPoisonStacks } from '../../buffs/generic-buff-definitions'
import { SpritesheetAnimatorComponent } from "../../engine/graphics/spritesheet-animator-component"
import { timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { HAZARD_ELIPSE_COLLIDER_CONFIG } from "./ellipse-hazard-params"

const POISON_STACK_PLAYER_LEVEL_MOD = 0.5

export interface PoisonPoolHazardParams extends DamagingGroundHazardParams {
	animateIn?: boolean
}

const GFX_WIDTH_SCALAR = 2.2
const GFX_HEIGHT_SCALAR = 1.2

const ANIMATE_IN_TOTAL_TIME = 0.33

export class PoisonPoolHazard extends DamagingGroundHazard {
	
	static pool: ObjectPool

	spriteSheet: SpritesheetAnimatorComponent


	private animateIn: boolean
	private animationTime: number
	private maxScale: number

	static gfxContainerWidth: number
	static gfxContainerHeight: number

	constructor() {
		super()

		const spriteSheet = AssetManager.getInstance().getAssetByName('elemental-maelstrom').spritesheet
		this.spriteSheet = new SpritesheetAnimatorComponent(this, spriteSheet, 'acid-idle', 0.1)
		this.spriteSheet.spriteSheetAnimator.activeSprite.activeFrame = Math.getRandomInt(0, this.spriteSheet.spriteSheetAnimator.activeSprite.frames.length - 1)

		this.colliderComponent.setColliders(HAZARD_ELIPSE_COLLIDER_CONFIG)

		if (!PoisonPoolHazard.gfxContainerWidth) {
			PoisonPoolHazard.gfxContainerWidth = spriteSheet.textures['acid-idle_00.png'].width
			PoisonPoolHazard.gfxContainerHeight = spriteSheet.textures['acid-idle_00.png'].height
		}
	}

	override setDefaultValues(defaultValues: any, overrideValues?: PoisonPoolHazardParams): void {
		super.setDefaultValues(defaultValues, overrideValues)

		if(overrideValues) {
			this.spriteSheet.spriteSheetAnimator.position.x = overrideValues.position.x
			this.spriteSheet.spriteSheetAnimator.position.y = overrideValues.position.y
			this.spriteSheet.spriteSheetAnimator.zIndex = -9_999_999

			this.animateIn = Boolean(overrideValues.animateIn)

			if (this.animateIn) {
				this.animationTime = 0
				this.spriteSheet.spriteSheetAnimator.scale.x = 0
				this.spriteSheet.spriteSheetAnimator.scale.y = 0
			} else {
				this.spriteSheet.spriteSheetAnimator.scale.x = overrideValues.triggerRadius / (PoisonPoolHazard.gfxContainerWidth / GFX_WIDTH_SCALAR)
				this.spriteSheet.spriteSheetAnimator.scale.y = overrideValues.triggerRadius / (PoisonPoolHazard.gfxContainerHeight / GFX_HEIGHT_SCALAR)
			}

			this.spriteSheet.addToScene()
		}
	}

	override cleanup(): void {
		super.cleanup()
		
		this.spriteSheet.spriteSheetAnimator.scale.x = 1
		this.spriteSheet.spriteSheetAnimator.scale.y = 1
		this.spriteSheet.spriteSheetAnimator.position.x = 0
		this.spriteSheet.spriteSheetAnimator.position.y = 0
		this.spriteSheet.removeFromScene()
	}

	onEntityEnterTrigger(entity: ColliderComponent): void {
		// Buff.apply(BuffIdentifier.Weakened, null, entity.owner, 1, Number.MAX_SAFE_INTEGER)
	}

	onEntityLeaveTrigger(entity: ColliderComponent): void {
		// const weakenedBuff = Buff.getBuff(entity.owner, BuffIdentifier.Weakened)

		// if (weakenedBuff) {
		// 	weakenedBuff.expiresAtTime = InGameTime.highResolutionTimestamp() + POISON_WEAKENED_DURATION
		// 	weakenedBuff.expiresAtTimeAbsolute = weakenedBuff.appliedAtTimeAbsolute + POISON_WEAKENED_DURATION
		// }
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		super.update(delta, now)

		if (this.animateIn) {
			this.animationTime += delta
			
			if (this.animationTime >= ANIMATE_IN_TOTAL_TIME) {
				this.animateIn = false
				this.spriteSheet.spriteSheetAnimator.scale.x = this.triggerRadius / (PoisonPoolHazard.gfxContainerWidth / GFX_WIDTH_SCALAR)
				this.spriteSheet.spriteSheetAnimator.scale.y = this.triggerRadius / (PoisonPoolHazard.gfxContainerHeight / GFX_HEIGHT_SCALAR)
			} else {
				const scaleScale = this.animationTime / ANIMATE_IN_TOTAL_TIME
				this.spriteSheet.spriteSheetAnimator.scale.x = scaleScale * (this.triggerRadius / (PoisonPoolHazard.gfxContainerWidth / GFX_WIDTH_SCALAR))
				this.spriteSheet.spriteSheetAnimator.scale.y = scaleScale * (this.triggerRadius / (PoisonPoolHazard.gfxContainerHeight / GFX_HEIGHT_SCALAR))
			}
		}

		this.spriteSheet.update(delta)
	}

	returnToPool() {
		PoisonPoolHazard.pool.free(this)
	}

	isPlayerOwned(): boolean {
		return false
	}

	onHitEnemy(enemy: Enemy) {
		const stacks = getPoisonStacks(getDamageByPlayerLevel() * POISON_STACK_PLAYER_LEVEL_MOD)
		Buff.apply(BuffIdentifier.Poison, this, enemy, stacks)
	}

	override setOffset(offset: SAT.Vector): void {
		super.setOffset(offset)

		this.spriteSheet.spriteSheetAnimator.position.x = this.position.x
		this.spriteSheet.spriteSheetAnimator.position.y = this.position.y
	}
}