import { Player } from "../../entities/player"
import { StatBonus } from "../../stats/entity-stat-list"
import { selectorsToStatLists } from "../../stats/stat-selector"
import { getPerks } from "../../utils/api/griddle-requests"
import clientLogger from "../../utils/client-logger"
import { MetaPerkDefinition, MetaUpgradeDefinitions } from "./meta-perk-definitions"
import { MetaPerkName } from "./meta-perk-name"

export type InstancedMetaUpgrade = {
    upgradeDefinition: MetaPerkDefinition
    isLocked: boolean
    unlockProgress: number
    currentStacks: number
}

export class MetaPerkManager {
    private static _instance: MetaPerkManager
    static getInstance() {
        if (!this._instance) {
            this._instance = new MetaPerkManager()
        }
        return this._instance
    }

    instancedUpgrades: Map<MetaPerkName, InstancedMetaUpgrade>

    constructor() {
        this.instancedUpgrades = new Map()
        for (const key in MetaUpgradeDefinitions) {
            const upgradeName = key as MetaPerkName
            this.instancedUpgrades.set(upgradeName, {
                upgradeDefinition: MetaUpgradeDefinitions[key],
                isLocked: Boolean(MetaUpgradeDefinitions[key].startsLocked),
                currentStacks: 0,
                unlockProgress: 0
            })
        }
    }

    setStacks(upgradeName: MetaPerkName, stacks: number) {
        const upgrade = this.instancedUpgrades.get(upgradeName)
        upgrade.currentStacks = Math.clamp(stacks, 0, upgrade.upgradeDefinition.maxStacks)
    }

    setPerkLocked(upgradeName: MetaPerkName, locked: boolean) {
        const upgrade = this.instancedUpgrades.get(upgradeName)
        upgrade.isLocked = locked
    }


    applyPerks(player: Player) {
        this.instancedUpgrades.forEach((upgrade: InstancedMetaUpgrade) => {
            if (!upgrade.isLocked && upgrade.currentStacks > 0) {
                const definition = upgrade.upgradeDefinition
                const stacks = Math.clamp(upgrade.currentStacks, 0, upgrade.upgradeDefinition.maxStacks) // safety clamp

                if (definition.getStatBonuses) {
                    const bonuses = definition.getStatBonuses(stacks)
                    for (const [selectors, ...bonus] of bonuses) {
                        const statLists = selectorsToStatLists(player, selectors)
                        for (const statList of statLists) {
                            const statBonus = new StatBonus(statList, ...bonus)
                            statList.addStatBonusDirect(statBonus)
                        }
                    }
                }

                if (definition.simpleApplyFn) {
                    definition.simpleApplyFn(player, stacks, {})
                }
            }
        })
    }

    setPerks(perksResponse: any, player: Player) {
        try {
            const metaUpgradeIds = Object.values(MetaPerkName)
            for (let i = 0; i < perksResponse.length; ++i) {
                const perk = perksResponse[i]
                const id = perk.id
                const ranks = perk.ranks

                if (!(metaUpgradeIds.includes(id))) {
                    clientLogger.error(`Couldn't find perk on client: ${id}`)
                    continue
                }

                this.setStacks(id, ranks)
            }

            this.applyPerks(player)
        } catch (err) {
            clientLogger.error(`Error when getting and applying perks:`, err)
        }
    }
}
