

const initialState = {
	errorTitle: '',
	errorDescription: '',
	showErrorModal: false,
}

const store = {
	namespaced: true,
	state: initialState,
	getters: {
		getErrorTitle(state: ErrorPromptStoreState) {
			return state.errorTitle
		},
		getErrorDescription(state: ErrorPromptStoreState) {
			return state.errorDescription
		},
	},
	mutations:{
		setActiveError(state: ErrorPromptStoreState) {
			state.showErrorModal = !state.showErrorModal
		},

	},
	actions:{
		ErrorDescriptions({state, commit}: {state:ErrorPromptStoreState, commit:any}, payload) {
			const {title, description} = payload
			state.errorDescription = description
			state.errorTitle = title
			state.showErrorModal = true
			commit('ui/updateDarkenOverlay', true, { root: true })
		},
	},
}
export type ErrorPromptStoreState = typeof initialState
export const ErrorPromptStore = typeof store

export const errorPromptStore = () => {
	return store
}
