<template>
	<Panel id="landing-page" :height="950">
		<template #panel-content>
			<div class="info">
				<div class="header-text" :lang="this.$i18n.locale">{{ $t('game_info.the_game') }}</div>
				<div class="game-description" :lang="this.$i18n.locale">{{ $t('game_info.game_welcome') }}<br>{{ $t('game_info.game_description') }}</div>
				<div style="min-height: 75px; width: 1px;"></div>
				<Controls></Controls>

				<!-- <div class="info-container">
					<Divider :label="'game_info.controls_label'" :flip-horizontal="false" :text-alignment="'center'" :font-size="'medium'"></Divider>
					<div class="text-container">
						<div class="content">
							<div :lang="this.$i18n.locale">{{ $t('game_info.controls_description_a') }}</div>
							<div :lang="this.$i18n.locale">{{ $t('game_info.controls_description_b') }}</div>
							<div :lang="this.$i18n.locale">{{ $t('game_info.controls_description_c') }}</div>
							<div :lang="this.$i18n.locale">{{ $t('game_info.controls_description_d') }}</div>
						</div>
					</div>
				</div>
				<div class="info-container">
					<Divider :label="'game_info.third_label'" :flip-horizontal="false" :text-alignment="'center'" :font-size="'medium'"></Divider>
					<div class="text-container">
						<div class="content">
							<div :lang="this.$i18n.locale">{{ $t('game_info.third_description_a') }}</div>
							<div :lang="this.$i18n.locale">{{ $t('game_info.third_description_b') }}</div>
							<div :lang="this.$i18n.locale">{{ $t('game_info.third_description_c') }}</div>
						</div>
					</div>
				</div>
				<div class="info-container">
					<Divider :label="'game_info.updates_label'" :flip-horizontal="false" :text-alignment="'center'" :font-size="'medium'"></Divider>

					<div class="text-container">
						<div class="content">
							<div :lang="this.$i18n.locale">{{ $t('game_info.updates_description') }}</div>
						</div>
					</div>
				</div> -->
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Divider from '../ui-components/dividers-headers/divider.vue'
import Panel from '../ui-components/panel/panel.vue'
import Controls from '../ui-components/controls.vue'

export default {
	name: 'GameInfo',
	components: {
		Panel,
		Divider,
		Controls
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#landing-page {
	height: 1080px;
	margin: auto 0;
	display: flex;
    align-items: center;
	:deep(.panel-inner) {
			border-width: 45px 28px 70px;
	}
	.info {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 792px;
		width: 340px;

		.header-text {
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 50px;
				font-variant: small-caps;
				color: @cream;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
			
			text-shadow: 4px 4px #000000;
		}

		.game-description {
			text-align: center;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
				font-size: 24px;
				font-variant: small-caps;
				color: @offWhite;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}

		.info-container {
			position: relative;
			margin-bottom: 40px;
			.embellishment {
				img {
					transform: scaleX(-1);
				}
			}
			.text-container {
				position: relative;
				top: 10px;
				width: 400px;

				.content {
					text-align: center;
					overflow-y: auto;
					div {
						&[lang='en'] {
							.regularFont(@enSecondaryFontPTBold);
							font-size: 26px;
							font-variant: small-caps;
							color: @offWhite;
						}

						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}
				}
			}
		}
	}
}
</style>
