import { CircleCollider, EllipseCollider } from "../../engine/collision/colliders"
import { callbacks_addCallback } from "../../utils/callback-system"
import { timeInSeconds } from "../../utils/primitive-types"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"
import { InstancedSpriteSheetAnimator } from "../../engine/graphics/instanced-spritesheet-animator"
import { HAZARD_ELIPSE_COLLIDER_CONFIG } from "./ellipse-hazard-params"

const DESPAWN_DELAY: timeInSeconds = 0.7

const NOXIOUS_LEAK_ATTACK_SIZE = 160
const RADIUS_GROWTH_FN = (damage) => { return 375 + Math.pow(damage, 0.75)}

const MAGIC_COLLIDER_HEIGHT_SCALE = 1.5
const MAGIC_GFX_WIDTH_SCALE = 2.2

export class NoxiousLeakHazard extends DamagingGroundHazard {
   
    static pool: ObjectPoolTyped<NoxiousLeakHazard, DamagingGroundHazardParams>
    static gfxContainerWidth: number
    static gfxContainerHeight: number

    gfx: InstancedSpriteSheetAnimator

	private impactDamageTotal: number = 0
    private boundDespawnCallback: () => void

    constructor() {
        super()
        this.makeGraphics()
        this.boundDespawnCallback = this.despawnDelayFinished.bind(this)
        this.colliderComponent.isStatic = false
		this.colliderComponent.setColliders(HAZARD_ELIPSE_COLLIDER_CONFIG)
    }

    override setDefaultValues(defaultValues: any, overrideValues?: DamagingGroundHazardParams): void {
        super.setDefaultValues(defaultValues, overrideValues)

        if(overrideValues) {
            this.gfx.position.x = this.position.x
            this.gfx.position.y = this.position.y
            this.gfx.scale.x = this.triggerRadius / NOXIOUS_LEAK_ATTACK_SIZE
            this.gfx.scale.y = this.triggerRadius / NOXIOUS_LEAK_ATTACK_SIZE
            this.gfx.zIndex = this.position.y
            this.gfx.playAnimation('spawn/poison-aoe-spawn', 'idle/poison-aoe-idle')
            this.gfx.addToScene(true)

            this.ignoreKnockback = true
        }
    }

    override cleanup(): void {
        super.cleanup()
		this.impactDamageTotal = 0
        this.damaging = false

        this.gfx.removeFromScene()
    }
    
    returnToPool() {
        this.gfx.playAnimation('despawn/poison-aoe-despawn')

        this.damaging = false
        callbacks_addCallback(this, this.boundDespawnCallback, DESPAWN_DELAY)
    }

    despawnDelayFinished() {
        NoxiousLeakHazard.pool.free(this)
    }

    makeGraphics() {
        const sheet = AssetManager.getInstance().getAssetByName('poison-bottle').spritesheet
        this.gfx = new InstancedSpriteSheetAnimator(sheet, 'spawn/poison-aoe-spawn')
        
        this.gfx.position.x = this.position.x
        this.gfx.position.y = this.position.y

        if (!NoxiousLeakHazard.gfxContainerWidth) {
			NoxiousLeakHazard.gfxContainerWidth = sheet.textures['idle/poison-aoe-idle_00.png'].width
			NoxiousLeakHazard.gfxContainerHeight = sheet.textures['idle/poison-aoe-idle_00.png'].height
		}
    }

    isPlayerOwned(): boolean {
        return true
    }

	private setRadius(radius: number) {
		this.triggerRadius = radius
		this.gfx.scale.x = this.triggerRadius / (NoxiousLeakHazard.gfxContainerWidth / MAGIC_GFX_WIDTH_SCALE) 
        this.gfx.scale.y = this.triggerRadius / NoxiousLeakHazard.gfxContainerHeight

		const collider = this.colliderComponent.colliders[0] as EllipseCollider
        collider.rX = this.triggerRadius
        collider.rY = (this.triggerRadius / (NoxiousLeakHazard.gfxContainerWidth / NoxiousLeakHazard.gfxContainerHeight) / MAGIC_COLLIDER_HEIGHT_SCALE)
        this.colliderComponent.recalculateBounds()
        this.colliderComponent.forceDirty = true
	}

	addImpactDamage(damage: number) {
		this.impactDamageTotal += damage
		const radius = RADIUS_GROWTH_FN(this.impactDamageTotal)
		this.setRadius(radius)
	}

    onHitEnemy(enemy: Enemy) {
        
    }
}
