<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div class="window-mode-container container-formatting">
				<div class="label" :lang="this.$i18n.locale">{{ $t('settings.resolution') }}</div>
				<div class="mode-selector">
					<Pagination :disablePips="true" :title-string="selectCategoryString" @on-click-back-fn="previousCategory" @on-click-forward-fn="nextCategory" :disable="currentSettings.windowMode === 'fullscreen' || currentSettings.windowMode === 'windowedFullscreen'"></Pagination>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Toggle from '../form-inputs/toggle.vue'
import Pagination from '../pagination/pagination.vue'

import { paginationMixins } from '../mixins/Pagination-mixin'

export default {
	name: 'ResolutionModule',
	components: {
		EmbellishmentContainer,
		Pagination,
	},
	data() {
		return {
		}
	},
	props: {},

	computed: {
		// ...mapGetters('ui', ),
		...mapState('settings', ['fullscreen', 'currentSettings']),
		...mapState('uiScaling', ['selectedWindowResolution']),
		...mapGetters('uiScaling', ['getSupportedResolutions', 'getResolutionIdx']),

		categories() {
			return this.getSupportedResolutions
		},

		defaultSelectedCategory: {
			get() {
				return this.getResolutionIdx
			},
			set(value) {
				this.updateWindowResolution(value)
			},
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('settings', ['checkFullscreen', 'toggleWindowMode']),
		...mapActions('uiScaling', ['updateWindowResolution']),
	},
	mixins: [paginationMixins],
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.window-mode-container {
	display: flex;
	align-items: center;
	width: 660px;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 258px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.mode-selector {
		display: flex;
		width: 60%;
		height: 40px;
	}
}
</style>
