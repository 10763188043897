<template>
	<div class="activity-feed-prompt">
		<Panel id="activity-feed" :height="910">
			<template #panel-content>
				<div class="flex">
					<ActivityFeed></ActivityFeed>
					<TextButton :button-label="$t(`activityFeed.button_return`)" :button-style="'blue'" @on-click-fn="closeActivityModal"></TextButton>
				</div>
			</template>
		</Panel>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ActivityFeed from '../ui-components/activity-feed/activity-feed.vue'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'

export default {
	name: 'ActivityFeedPrompt',
	components: {
		ActivityFeed,
		Panel,
		TextButton,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('activityFeed', ['updateActivityFeedModal']),
		...mapMutations('ui', ['updateDarkenOverlay']),
		closeActivityModal() {
			this.updateActivityFeedModal(false)
			this.updateDarkenOverlay(false)
		},
	},
}
</script>

<style lang="less" scoped>
.activity-feed-prompt {
	#activity-feed {
		:deep(.panel-inner) {
			border-width: 53px 51px 68px 46px;
		}
		:deep(#chat) {
			.chat-inner {
				height: 750px !important;
			}
		}
		.flex{
			display: flex;
			flex-direction: column;
			align-items: center;
			.text-button {
				margin-top: 20px;
			}
		}
	}
}
</style>
