import EntityStatList from "../../../stats/entity-stat-list"
import { SkillWeapon } from "../../skill-weapon"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { AllWeaponTypes } from "../../weapon-types"

export class EmptySkillWeapon extends SkillWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.None
    
    protected useSkill(): void {
    }
    
    update(delta: number) {
    }
    
    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
    }
    
    protected canUseSkill(): boolean {
		return false
	}
}