import { Vector } from "sat"
import { Effect } from "../../engine/graphics/pfx/effect"
import EntityStatList from "../../stats/entity-stat-list"
import { Pet, PetCollectionName } from "./pet"
import { AssetManager } from "../../web/asset-manager"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { Renderer } from "../../engine/graphics/renderer"
import { timeInSeconds } from "../../utils/primitive-types"
import { GameState } from "../../engine/game-state"
import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { defaultStatAttribute } from "../../game-data/stat-formulas"

enum RainbowPetState {
    IDLE,
    GO_TO_PLAYER,
    SPRAYING
}

enum SprayType {
    RED,
    GREEN,
    BLUE
}

const GO_TO_PLAYER_TIME: timeInSeconds = 0.75
const SPRAYING_TIME: timeInSeconds = 0.45

const END_POSITION_OFFSET: number = 100

export class RainbowPet extends Pet {

    private greenPfx: Effect
    private bluePfx: Effect
    private redPfx: Effect

    private lastSprayType: SprayType

    private activePfx: Effect
    private timeInState: timeInSeconds = 0

    private startPosition: Vector = new Vector()
    private offsetEndPosition: Vector = new Vector()

    private state: RainbowPetState = RainbowPetState.IDLE


    constructor(name: PetCollectionName, parentStatList: EntityStatList, position: Vector, uncaged?: boolean) {
        super(name, parentStatList, position, uncaged)

        const greenConfig = AssetManager.getInstance().getAssetByName('magic-dust-green').data as EffectConfig
        const blueConfig = AssetManager.getInstance().getAssetByName('magic-dust-blue').data as EffectConfig
        const redConfig = AssetManager.getInstance().getAssetByName('magic-dust-red').data as EffectConfig
    
        const camera = Renderer.getInstance().cameraState

        this.greenPfx = new Effect(greenConfig, camera)
        this.bluePfx = new Effect(blueConfig, camera)
        this.redPfx = new Effect(redConfig, camera)
    }

    resetStatsFn(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.cooldownInterval = 21_000
		statList._actualStatValues.reloadInterval = 50,
		statList._actualStatValues.reloadAmmoIncrement = 1
    }

    update(delta: timeInSeconds): void {
        switch (this.state) {
            case RainbowPetState.IDLE:
                super.update(delta)
                break
            case RainbowPetState.GO_TO_PLAYER:
                this.updateGoToPlayer()
        		this.gfx.update(delta)
                break
            case RainbowPetState.SPRAYING:
                this.updateSpraying()
        		this.gfx.update(delta)
                break
        }

        this.timeInState += delta
    }

    useAbility() {
        // pick a random ability
        const roll = Math.random()
        if (roll >= 0.66666) {
            this.lastSprayType = SprayType.GREEN
        } else if (roll >= 0.33333) {
            this.lastSprayType = SprayType.BLUE
        } else {
            this.lastSprayType = SprayType.RED
        }

        this.timeInState = 0
        this.state = RainbowPetState.GO_TO_PLAYER

        const player = GameState.player

        this.startPosition.copy(this.position).sub(player.position)
        this.offsetEndPosition.copy(player.position).sub(this.position)
        this.offsetEndPosition.normalize()
        this.offsetEndPosition.scale(-END_POSITION_OFFSET, -END_POSITION_OFFSET)

        this.updateCooldownGfx()
    }

    private updateGoToPlayer() {
        if (this.timeInState >= GO_TO_PLAYER_TIME) {
            this.state = RainbowPetState.SPRAYING
            this.timeInState = 0
            this.keepPositionWithPlayer()

            switch (this.lastSprayType) {
                case SprayType.RED:
                    this.activateSprayPfx(this.redPfx)
                    break
                case SprayType.BLUE:
                    this.activateSprayPfx(this.bluePfx)
                    break
                case SprayType.GREEN:
                    this.activateSprayPfx(this.greenPfx)
                    break
            }

        } else {
            const player = GameState.player
            const percentDone = this.timeInState / GO_TO_PLAYER_TIME
            this.position.x = Math.lerp(player.x + this.startPosition.x, player.x + this.offsetEndPosition.x, percentDone)
            this.position.y = Math.lerp(player.y + this.startPosition.y, player.y + this.offsetEndPosition.y, percentDone)
        }
    }

    private updateSpraying() {
        if (this.timeInState >= SPRAYING_TIME) {
            this.timeInState = 0

            Renderer.getInstance().mgRenderer.removeFromScene(this.activePfx)
            for (let i = 0; i < this.activePfx.emitters.length; ++i) {
                this.activePfx.emitters[i].reset()
            }
            this.activePfx = null

            this.state = RainbowPetState.IDLE
            this.applySprayBuff()
            this.finishedPetAbility()
        } else {
            this.activePfx.x = this.position.x
            this.activePfx.y = this.position.y
            // this.activePfx.rot = this.getRotToPlayer()
        }

        this.keepPositionWithPlayer()
    }

    private activateSprayPfx(effect: Effect) {
        this.activePfx = effect

        this.activePfx.x = this.position.x
        this.activePfx.y = this.position.y
        // this.activePfx.rot = this.getRotToPlayer()

        Renderer.getInstance().mgRenderer.addEffectToScene(this.activePfx)
    }

    private applySprayBuff() {
        const player = GameState.player

        switch (this.lastSprayType) {
            case SprayType.RED:
                Buff.apply(BuffIdentifier.RainbowPetRed, this, player)
                break
            case SprayType.BLUE:
                Buff.apply(BuffIdentifier.RainbowPetBlue, this, player)
                break
            case SprayType.GREEN:
                Buff.apply(BuffIdentifier.RainbowPetGreen, this, player)
                break
        }
    }

    private keepPositionWithPlayer() {
        const player = GameState.player
        
        this.position.x = player.x + this.offsetEndPosition.x 
        this.position.y = player.y + this.offsetEndPosition.y 
    }

    private getRotToPlayer() {
        const player = GameState.player

        const xOff = player.x - this.position.x
        const yOff = player.y - this.position.y

        return Math.atan2(yOff, xOff)
    }
}
