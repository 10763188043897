<template>
	<div style="pointer-events: none">
		<div id="debug-output" :class="{ visible: isOutputVisible }">
			<div v-for="(stage, i) in enemySpawnerStages" :key="i">
				<b>Stage ({{ stage.min }}s - {{ stage.max }}s)</b><br />
				<table class="stupid-table">
					<tr>
						<th>Enemy</th>
						<th># Spawn</th>
						<th>% Cap</th>
						<th>Equil.</th>
						<th>Rate%</th>
					</tr>
					<tr v-for="(pack, u) in stage.packs" :key="u">
						<td>{{ pack.enemyName }}</td>
						<td>{{ pack.currentAmount }}/{{ pack.calcMaxAmount }}</td>
						<td><ProgressBar :value="pack.currentAmount" :max-value="pack.calcMaxAmount"></ProgressBar></td>
						<td>{{ pack.equilibriumAmount }}</td>
						<td>{{ Math.round(pack.lastSpawnRateMulti * 100) }}% ({{ pack.calcSpawnRate?.toFixedIfFloat(1) }}s)</td>
					</tr>
				</table>
			</div>
			<div v-show="stragglerEnemies.length">
				<b>Stragglers</b><br />
				<table class="stupid-table">
					<tr>
						<th>Enemy</th>
						<th># Spawn</th>
					</tr>
					<tr v-for="record in stragglerEnemies" :key="record">
						<td>{{ record.enemyName }}</td>
						<td>{{ record.currentAmount }}</td>
					</tr>
				</table>
			</div>
			<br />
			Enemies: {{ enemiesInWorld.toString().commafy().padStart(5, '&nbsp;') }} / {{ enemiesSpawnedLifetime.toString().commafy().padStart(5, '&nbsp;') }} | {{ enemiesInWorldHealth.toString().commafy().padStart(8, '&nbsp;') }} hp<br />
			Pickups: {{ pickupsInWorld.toString().commafy().padStart(5, '&nbsp;') }} | {{ expInWorld.toString().commafy().padStart(5, '&nbsp;') }} exp<br />
			DPS: {{ Math.round(playerDPS) }}<br />
			<!-- Lvl vs Expect: {{ levelVsExpectationPercent.toString().padStart(3, '&nbsp;') }}% -->
		</div>
		<button  v-if="(env !== 'production' && env !== 'staging') || isQa" id="toggle-debug-output" class="toggle-button" @click="toggleDebugOutput()">&#62;</button>

		<div id="debug-tools" :class="{ visible: isToolsPanelVisible, hidden: !isToolsPanelVisible }">
			<h1>Debug Console</h1>

			<label>Select Plot Twists</label><br />
			<Multiselect :modelValue="mutatorsActive" :options="mutatorsAvailable" @update:modelValue="updateMutators" :multiple="true" :hide-selected="true" :close-on-select="false" :max-height="400" group-values="mutators"></Multiselect>
			<br />
			<label>Select ALL Upgrades (long)</label><br />
			<Multiselect
				class="debug-small"
				:modelValue="upgradesActive"
				:options="allUpgrades"
				:limit="20"
				@update:modelValue="updateUpgrades"
				:multiple="true"
				:hide-selected="true"
				:close-on-select="false"
				:max-height="600"
				:group-select="true"
				group-values="upgradeNames"
				group-label="name"
			></Multiselect>
			<br />
			<label>Select Generic &amp; Character Upgrades</label><br />
			<Multiselect
				class="debug-small"
				:modelValue="upgradesActive"
				:options="nonWeaponUpgrades"
				:limit="0"
				@update:modelValue="updateUpgrades"
				:multiple="true"
				:hide-selected="true"
				:close-on-select="false"
				:max-height="600"
				:group-select="true"
				group-values="upgradeNames"
				group-label="name"
			></Multiselect>
			<br />
			<label>Select 1️⃣ Weapon Upgrades</label><br />
			<Multiselect
				class="debug-small"
				:modelValue="upgradesActive"
				:options="primaryWeaponUpgrades"
				:limit="0"
				@update:modelValue="updateUpgrades"
				:multiple="true"
				:hide-selected="true"
				:close-on-select="false"
				:max-height="600"
				:group-select="true"
				group-values="upgradeNames"
				group-label="name"
			></Multiselect>
			<br />
			<label>🔓 Unlock 2️⃣ Weapons</label><br />
			<Multiselect
				class="debug-small"
				:modelValue="upgradesActive"
				:options="secondaryWeaponUnlocks"
				:limit="0"
				@update:modelValue="updateUpgrades"
				:multiple="true"
				:hide-selected="true"
				:close-on-select="false"
				:max-height="600"
				:group-select="true"
				group-values="upgradeNames"
				group-label="name"
			></Multiselect>
			<br />
			<label>Select 2️⃣ Weapon Upgrades</label><br />
			<Multiselect
				class="debug-small"
				:modelValue="upgradesActive"
				:options="secondaryWeaponUpgrades"
				:limit="0"
				@update:modelValue="updateUpgrades"
				:multiple="true"
				:hide-selected="true"
				:close-on-select="false"
				:max-height="600"
				:group-select="true"
				group-values="upgradeNames"
				group-label="name"
			></Multiselect>
			<br />
			<label>Select Event Upgrades</label><br />
			<Multiselect
				class="debug-small"
				:modelValue="upgradesActive"
				:options="eventUpgrades"
				:limit="0"
				@update:modelValue="updateUpgrades"
				:multiple="true"
				:hide-selected="true"
				:close-on-select="false"
				:max-height="600"
				:group-select="true"
				group-values="upgradeNames"
				group-label="name"
			></Multiselect>
			<br />
			<label>Select Primary Weapon</label><br />
			<Multiselect :modelValue="primaryWeaponActive" :options="allPrimaryWeapons" @update:modelValue="setPrimaryWeapon" :multiple="false" :hide-selected="false" :close-on-select="true" :max-height="400"></Multiselect>
			<br />
			<label>Select Pet</label><br />
			<Multiselect :options="allPets" @update:modelValue="addPet" :multiple="false" :hide-selected="false" :close-on-select="true" :max-height="400"></Multiselect>
		</div>
		<button v-if="(env !== 'production' && env !== 'staging') || isQa" id="toggle-debug-tools" class="toggle-button" @click="toggleDebugTools()">&#60;</button>

		<div id="debug-toolbar" :class="{ visible: isToolsPanelVisible, hidden: !isToolsPanelVisible }">
			<div class="button-list">
				<button @click="toggleGodmode()">Godmode</button>
				<button @click="toggleGhostwalk()">Ghostwalk</button>
				<button @click="heal()">Full Heal</button>
				<button @click="killAllEnemies()">Kill All</button>
				<button @click="collectAllPickups()">Collect All</button>
				<button @click="toggleLevelUpModal()">Toggle Level Modal</button>
				<button @click="toggleZoomOut()">Toggle Zoom Out</button>
				<button @click="addLevel(1)">+1 Level</button>
				<button @click="addLevel(5)">+5 Levels</button>
				<button @click="addExpToLevel()">Fill XP</button>
				<button @click="showDebuggerStats()">My Stats</button>

				<button class="no-min-width no-margin clock" @click="addGameSeconds(30)">+30 Seconds</button>
				<button class="no-min-width no-margin clock" @click="addGameSeconds(60)">+1 Minute</button>
				<button class="no-min-width no-margin clock" @click="addGameSeconds(120)">+2 Minutes</button>
				<button class="no-min-width no-margin clock" @click="addGameSeconds(180)">+3 Minutes</button>
				<button class="no-min-width no-margin clock" @click="addGameSeconds(300)">+5 Minutes</button>
				<button class="no-min-width no-margin clock" @click="gotoGameTime(297)">Act 1 Boss</button>
				<button class="no-min-width no-margin clock" @click="gotoGameTime(326)">Act 2 Start</button>
				<button class="no-min-width no-margin clock" @click="gotoGameTime(597)">Act 2 Boss</button>
				<button class="no-min-width no-margin clock" @click="gotoGameTime(626)">Act 3 Start</button>
				<button class="no-min-width no-margin clock" @click="gotoGameTime(897)">Act 3 Boss</button>
				<button class="no-min-width no-margin clock" @click="gotoGameTime(1099)">Act 3 TimeWin</button>

				<button class="no-min-width no-margin speed" @click="setGameSpeed(0.016666)">1F ⏪</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(0.25)">0.25x ⏪</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(0.5)">0.5x ⏪</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(1.0)">1x ⏯</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(1.5)">1.5x ⏩</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(2.0)">2x ⏩</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(3.0)">3x ⏩</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(4.0)">4x ⏩</button>
				<button class="no-min-width no-margin speed" @click="setGameSpeed(6.0)">6x ⏩</button>

				<!-- <button @click="triggerNextEvent()">Next Event</button> -->
				<!-- <button @click="createPowerup()">Pow (Random)</button> -->
				<!-- <button @click="createPowerup('magnet')">Pow (Magnet)</button> -->
				<button @click="killPlayer()">DIE ☠️</button>
				<button @click="toggleEnemyColliderVisuals()">Enemy Collider Visuals</button>
				<button @click="addGoodBoyStacks()">Add 50 Good Boy Stackss</button>
				<button @click="setDebugIconScreen">Icons</button>
				<button @click="setDebugLightingColor">Lighting Color</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { Buff } from '../../buffs/buff'
import { BuffIdentifier } from '../../buffs/buff.shared'
import { Audio } from '../../engine/audio'
import { GameState } from '../../engine/game-state'
import { Camera } from '../../engine/graphics/camera-logic'
import AISystem from '../../entities/enemies/ai-system'
import { killEnemiesInRadius } from '../../entities/pickups/ground-pickup'
import { debugConfig } from '../../utils/debug-config'
import { timeInSeconds } from '../../utils/primitive-types'
import { InGameTime } from '../../utils/time'
import ProgressBar from '../ui-components/bars-sliders/progress-bar.vue'

const DebugTools = {
	name: 'DebugTools',
	components: {
		ProgressBar,
	},
	mounted() {
		document.addEventListener('keypress', (event) => {
			if (event.code === 'Backquote') {
				this.toggleDebugTools()
			}
		})
	},
	data() {
		return {
			isToolsPanelVisible: false,
			isOutputVisible: false,
			isZoomedOut: false,
			env: process.env.NODE_ENV,
		}
	},
	props: {},
	computed: {
		...mapGetters('ui', ['upgradesActive', 'mutatorsActive', 'mutatorsAvailable', 'primaryWeaponActive', 'allPrimaryWeapons', 'allPets', 'isEnvProductionLike']),
		...mapState('debug', ['playerDPS', 'enemiesInWorld', 'enemiesSpawnedLifetime', 'enemiesInWorldHealth', 'pickupsInWorld', 'expInWorld', 'levelVsExpectationPercent', 'enemySpawnerStages', 'stragglerEnemies']),
		...mapState('ui', ['weaponUpgrades', 'primaryWeaponUpgrades', 'secondaryWeaponUnlocks', 'secondaryWeaponUpgrades', 'nonWeaponUpgrades', 'eventUpgrades', 'allUpgrades']),
		...mapState('user', ['isQa'])
	},
	methods: {
		...mapMutations('ui', ['setDebugIconScreen', 'setDebugLightingColor']),
		...mapActions('ui', ['updateMutators', 'updateUpgrades', 'setPrimaryWeapon', 'addPet']),

		toggleDebugOutput() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			Audio.getInstance().playSfx('UI_Toggle')
			this.isOutputVisible = !this.isOutputVisible
		},
		toggleDebugTools() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			Audio.getInstance().playSfx('UI_Toggle')
			this.isToolsPanelVisible = !this.isToolsPanelVisible
		},
		toggleGodmode() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			GameState.player.godmode = !GameState.player.godmode
			Audio.getInstance().playSfx('UI_Slider_Tick')
		},
		toggleGhostwalk() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			GameState.player.ghostwalk = !GameState.player.ghostwalk
			Audio.getInstance().playSfx('UI_Slider_Tick')
		},
		toggleLevelUpModal() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			GameState.player.showLevelUpModal = !GameState.player.showLevelUpModal
			Audio.getInstance().playSfx('UI_Slider_Tick')
		},
		killAllEnemies() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			killEnemiesInRadius(GameState.player, 999999)
			Audio.getInstance().playSfx('SFX_Enemy_Blimpie_Death')
		},
		collectAllPickups() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			const player = GameState.player
			GameState.pickupList.forEach((pickup) => {
				pickup.onPickedUp(player)
			})
			Audio.getInstance().playSfx('SFX_Level_Up')
		},
		addLevel(amount: number) {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			const player = GameState.player
			while (amount > 0) {
				amount--
				setTimeout(() => {
					player.addXP(player.nextLevel, true)
				}, amount * 100)
			}
		},
		addExpToLevel() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			const player = GameState.player
			const expNeeded = player.nextLevel - player.currentXP
			let expAdded = 0
			while (expAdded < expNeeded) {
				expAdded += expNeeded / 48
				setTimeout(() => {
					player.addXP(expNeeded / 48, true)
				}, (expAdded / expNeeded) * 800)
			}
		},
		heal() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			const player = GameState.player
			Audio.getInstance().playSfx('SFX_Player_Fully_Healed')
			let i = player.stats.maxHealth - player.currentHealth
			while (i) {
				i--
				setTimeout(() => {
					GameState.player.heal(1)
				}, i * 50)
			}
		},
		killPlayer() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			const player = GameState.player
			player.takeDamage(999, null, false)
		},
		showDebuggerStats() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			const player = GameState.player
			const playerStats_ = player.stats
			const playerValues_ = playerStats_._actualStatValues
			const playerMultipliers_ = playerStats_.statBonusMultiplier
			const weapons_ = {
				primary: player.primaryWeapon.statList,
				secondary1: player.secondaryWeapons[0]?.statList,
				secondary2: player.secondaryWeapons[1]?.statList,
				secondary3: player.secondaryWeapons[2]?.statList,
			}
			debugger
		},
		toggleZoomOut() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			this.isZoomedOut = !this.isZoomedOut

			const camera = Camera.getInstance()
			if (this.isZoomedOut) {
				camera.allZoomMultiplier = 0.5
			} else {
				camera.allZoomMultiplier = 1
			}
		},
		toggleEnemyColliderVisuals() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			debugConfig.collisions.drawEnemyColliders = !debugConfig.collisions.drawEnemyColliders
		},
		addGameSeconds(seconds: timeInSeconds) {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			InGameTime.debugAddSeconds(seconds)
		},
		gotoGameTime(seconds: timeInSeconds) {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			InGameTime.debugSetTime(seconds)
		},
		setGameSpeed(timeScale) {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			InGameTime.timeScale = timeScale
		},
		addGoodBoyStacks() {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 50)
		},
	},
}
export default DebugTools
</script>

<style lang="less" scoped>
#debug-tools {
	position: absolute;
	top: 0px;
	left: -9000px;
	z-index: 999999999;
	margin: 5px 10px 5px 10px;
	padding: 5px;
	height: calc(100vh - 20px);
	overflow-y: auto;
	min-width: 280px;
	max-width: 330px;
	color: white;
	background-color: rgba(51, 51, 51, 0.7);
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
	&.visible {
		left: 0px;
		pointer-events: all;
	}
}

#toggle-debug-output {
	position: absolute;
	bottom: 10px;
	right: 10px;
}

#toggle-debug-tools {
	position: absolute;
	bottom: 15px;
	left: 10px;
	z-index: 100001;
}

.toggle-button {
	border: 1px solid black;
	width: 24px;
	height: 24px;
	pointer-events: all;
}

#debug-output {
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-family: 'Courier New', Courier, monospace;
	font-size: 14px;
	color: white;
	background-color: rgba(51, 51, 51, 0.3);
	visibility: hidden;

	.stupid-table {
		// all tables are stupid
		width: 100%;
		padding: 3px;
		font-size: 14px;
	}

	&.visible {
		visibility: visible;
	}
}

#debug-toolbar {
	position: absolute;
	bottom: 10px;
	left: -9000px;
	margin: 5px 10px 5px 10px;
	padding: 5px;
	width: calc(100vw - 360px);
	height: 120px;
	color: white;
	overflow: auto;
	background-color: rgba(51, 51, 51, 0.7);
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

	&.visible {
		left: 340px;
		pointer-events: all;
	}

	.button-list {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% - 180px);

		button {
			min-width: 80px;
			min-height: 32px;
			border: 1px solid white;
			border-radius: 2px;
			background-color: rgb(51,51,51);
			padding: 4px;
			margin: 2px;

			&.no-min-width {
				min-width: unset;
			}

			&.no-margin {
				margin-left: 0px;
				margin-right: 0px;
			}

			&.speed {
				background-color: #7a4100;
			}
			&.clock {
				background-color: #00337a;
			}
		}
	}
}
</style>

<style lang="less">
.debug-small .multiselect__tags {
	font-size: 12px;

	.multiselect__tag {
		margin-bottom: 2px;
	}
}
</style>