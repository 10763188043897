import { Container, Graphics } from 'pixi.js'
import { Vector } from 'sat'
import { ColliderComponent } from '../engine/collision/collider-component'
import { CircleCollider, CircleColliderConfig, ColliderType } from '../engine/collision/colliders'
import { CollisionLayerBits } from '../engine/collision/collision-layers'
import CollisionSystem, { getClosestEntity, getClosestNotHitEntity } from '../engine/collision/collision-system'
import { ComponentOwner } from '../engine/component-owner'
import { GameState, getNID } from "../engine/game-state"
import { getRandomProjectileParticle, getRandomProjectileTrail, ParticleEffectType } from '../engine/graphics/pfx/particle-config'
import { Renderer } from '../engine/graphics/renderer'
import { Enemy, stunEnemy } from '../entities/enemies/enemy'
import { EntityType, IEntity } from "../entities/entity-interfaces"
import EntityStatList from '../stats/entity-stat-list'
import { StatType } from '../stats/stat-interfaces-enums'
import { degToRad, distanceVV, sub, VectorXY } from "../utils/math"
import { nid, degrees, gameUnits, timeInSeconds } from "../utils/primitive-types"
import { ObjectPool, ObjectPoolTyped, PoolableObject } from '../utils/third-party/object-pool'
import { angleInRadsFromVector } from "../utils/vector"
import { DamageSource, getDamageFromDamageSource } from './damage-source'
import { IRangedProjectile } from "./projectile-types"
import { applyTrajectoryMod, applyTrajectoryModInitialState, TrajectoryBoomerangStage } from "./trajectory"
import { ChargeDefinition, getChargeDefinition, ResourceType } from '../weapons/weapon-definitions'
import { Player } from "../entities/player"
import { ApplyBuffOnHitState, BOOMERANG_LIGHTNING_STRIKE_DAMAGE_MULT, BOOMERANG_LIGHTNING_STRIKE_SPLASH_RADIUS } from '../buffs/buff-system'
import { Buff } from '../buffs/buff'
import { BuffIdentifier } from '../buffs/buff.shared'
import { getBleedStacks, PIN_DOWN_BONUS_DURATION, PIN_DOWN_BOSS_DURATION_SCALAR, PIN_DOWN_DURATION } from '../buffs/generic-buff-definitions'
import { GraphicsComponent } from '../engine/graphics/graphics-component'
import { debugConfig } from '../utils/debug-config'
import { AllWeaponTypes } from '../weapons/weapon-types'
import { BOOMERANG_ACCELERATION_SPEED_INCREASE as BOOMERANG_ACCELARATION_SPEED_INCREASE, getRampingDamageBonus, ShrapnelConfig, ShrapnelConfigMap, SHRAPNEL_NUM_PIERCE,  } from '../game-data/player-formulas'
import { Effect } from '../engine/graphics/pfx/effect'
import { OUTBACK_ORBIT_DAMAGE_SCALE } from '../weapons/actual-weapons/primary/boomerang-weapon'
import { TrajectoryMod, TrajectoryModType } from './trajectory-presets'
import { ChargePrimaryWeapon } from '../weapons/charge-primary-weapon'
import { dealAOEDamageDamageSource } from './explosions'
import { EnemyType } from '../entities/enemies/ai-types'
import { LightningStrike } from '../entities/lightning-strike'
import { EffectConfig } from '../engine/graphics/pfx/effectConfig'
import { GAMEPLAY_SPEED_MULTIPLIER } from '../utils/time'

const RADIANS_FOR_CIRCULAR_TARGET_RESET: number = Math.PI * 1.5 // 75% of a full revolution
const PLAYER_WAVE_TRAVEL_TIME_DEFAULT_SPEED = 500 // Used for travelTimeElapsedForWaveFunctions, completely arbitrary
const PROJECTILE_AND_BEAM_MAX_RANGE = 2500
const PROJECTILE_DEFAULT_CHAIN_DISTANCE = 550
const PLAYER_INITIAL_PROJECTILE = 0
const SPLIT_MINIMUM_SPREAD: degrees = 30

export interface ProjectileInitialParams {
	owningEntityId: number
	position: Vector
	speed: number
	aimAngleInRads: number
	trajectoryMods: TrajectoryMod[]

	radius: number
	collisionLayer: CollisionLayerBits

	statList: EntityStatList
	resourceType: ResourceType
	energy?: number
	damageScale?: number
	player?: Player

	noChaining?: boolean

	isPrimaryWeaponProjectile?: boolean

	emitShrapnel?: ShrapnelConfig
	isShrapnel?: boolean

	isSplit?: boolean
	alreadyCollided?: number[]

	effectType?: ParticleEffectType
	trailType?: ParticleEffectType
	
	splashDamageEffect?: EffectConfig

	noEffectRotation?: boolean
	resetEffectOnChain?: boolean

	reversedTrajectory?: boolean

	rampDamage?: boolean

	projectileGroup?: PlayerProjectile[]

	graphicsComponent?: GraphicsComponent
	graphicsComponentPool?: ObjectPool

	weaponType?: AllWeaponTypes

	ignoreProps?: boolean

	onCleanup?: (projectile: PlayerProjectile) => void
	getGraphicsComponentAndPoolForSplit?: (projectile: PlayerProjectile) => { pool: ObjectPool, graphics: GraphicsComponent }
	onSplit?: (projectile: PlayerProjectile) => void
}

const BASE_COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		position: [0, 0],
		radius: 40,
	}
]
export class PlayerProjectile implements IEntity, IRangedProjectile, ComponentOwner, PoolableObject, DamageSource {
	static objectPool: ObjectPoolTyped<PlayerProjectile, ProjectileInitialParams>

	model: Container //TODO: move this
	player: Player

	// engine & collision
	nid: number
	entityType: EntityType = EntityType.Projectile
	timeScale: number = 1

	position: Vector

	get x() {
		return this.position.x
	}

	get y() {
		return this.position.y
	}

	startPos: Vector
	speed: number
	initialSpeed: number

	radius: number

	// entity references
	owningEntityId: number
	splashedAlready: boolean
	get owningEntity() {
		return GameState.entityMap.get(this.owningEntityId)
	}
	entitiesCollided: number[] = []
	isPlayerOwned(): boolean {
		return this.owningEntity && this.owningEntity.entityType === EntityType.Player
	}

	// performance & tickrate
	catchupTime: timeInSeconds
	toBeDeleted: boolean

	// stat tracking
	statList: EntityStatList
	lifespan: timeInSeconds
	maxRangeForDeletion: gameUnits
	reachedMaxRange: boolean
	distanceTravelled: gameUnits
	radiansTravelled: any
	travelTimeElapsedInSeconds: number
	travelTimeElapsedForWaveFunctionsPrev: any
	travelTimeElapsedForWaveFunctions: any

	isPrimaryWeaponProjectile: boolean
	energy: number
	chargeDefinition?: ChargeDefinition

	maxPierceCount: number
	maxSplitCount: number

	isSplit: boolean
	isOriginalProjectile: boolean
	maxChainCount: number
	resetEffectOnChain: boolean

	numEntitiesChained: number
	numEntitiesPierced: number

	totalEntitiesHit: number

	accelerateOnHit: boolean

	damageScale: number
	applyBuffsOnHit: ApplyBuffOnHitState[] = []

	// trajectories
	trajectoryMods: TrajectoryMod[] = []
	reversedTrajectory: any
	trajectoryStage: number
	leftRightFlip: boolean
	aimAngleInRads: number
	startAimAngleInRads: number
	angleToPeakOfArc: any
	willReverseTrajectory: boolean
	spreadModifiersSet: any
	baseTrajectory: any
	radiusSpreadModifier: number
	waveSpreadOffset: number
	randomMod: number
	distanceMod: number
	homingTargetNid: nid
	homingTargetBackoffAcc: timeInSeconds
	autoSplitAfterDistanceTravelled: gameUnits

	clearMultiHitAcc: timeInSeconds

	knockbackUsingCurrentPosition: boolean
	isOutback: boolean
	outbackSpinTime: number

	circleRadsPerSecond: number
	circlesPerSecond: number
	circleTimeAcc: number

	isCircling: boolean = false
	isOrbit: boolean = false

	emitShrapnel: ShrapnelConfig
	isShrapnel: boolean

	rampDamage: boolean
	isSpinning: boolean

	ignoreProps: boolean

	// gfx
	particleEffectType: ParticleEffectType
	bulletTrailParticleEffectType: ParticleEffectType

	splashDamageEffect?: EffectConfig

	isBoomerang: boolean
	noEffectRotation: boolean

	effects: Effect[]

	colliderComponent: ColliderComponent

	graphicsComponent?: GraphicsComponent
	graphicsComponentPool?: ObjectPool

	getGraphicsComponentAndPoolForSplit?: (projectile: PlayerProjectile) => { pool: ObjectPool, graphics: GraphicsComponent }
	onSplit?: (projectile: PlayerProjectile) => void
	onCleanup?: (projectile: PlayerProjectile) => void

	get showImmediateDamageNumber() {
		return this.isPrimaryWeaponProjectile
	}

	// Weapon
	resourceType: ResourceType
	weaponType: AllWeaponTypes

	private groupHitSomething: boolean
	private projectileGroup?: PlayerProjectile[]

	private lastChainHitVector: Vector
	private lastHitChained: boolean

	constructor() {
		this.nid = getNID(this) //TODO: move this to initialize lifecycle once object pooling is in... don't forget to clean up old nids

		this.position = new Vector()
		this.startPos = new Vector()
		this.lastChainHitVector = new Vector()
		this.colliderComponent = new ColliderComponent(BASE_COLLIDER_CONFIG, this, CollisionLayerBits.PlayerProjectile, this.onCollision.bind(this), false, true, true)
	}

	setDefaultValues(defaultParams: any, overrideValues?: ProjectileInitialParams) {
		if (overrideValues) {
			this.owningEntityId = overrideValues.owningEntityId
			this.position.copy(overrideValues.position)
			this.speed = overrideValues.speed
			this.aimAngleInRads = overrideValues.aimAngleInRads
			this.startAimAngleInRads = this.aimAngleInRads
			this.trajectoryMods = overrideValues.trajectoryMods // sharing a ref ?
			this.trajectoryStage = 0
			this.isPrimaryWeaponProjectile = overrideValues.isPrimaryWeaponProjectile
			this.radius = overrideValues.radius
			this.statList = overrideValues.statList
			this.player = overrideValues.player
			this.resourceType = overrideValues.resourceType
			this.emitShrapnel = overrideValues.emitShrapnel
			this.isShrapnel = Boolean(overrideValues.isShrapnel)
			this.resetEffectOnChain = Boolean(overrideValues.resetEffectOnChain)
			this.ignoreProps = Boolean(overrideValues.ignoreProps)
			this.damageScale = overrideValues.damageScale === undefined ? 1 : overrideValues.damageScale
			this.leftRightFlip = false
			this.groupHitSomething = false
			this.projectileGroup = overrideValues.projectileGroup
			this.splashDamageEffect = overrideValues.splashDamageEffect

			this.startPos.copy(this.position)
			this.initialSpeed = this.speed
			this.distanceTravelled = 0
			this.autoSplitAfterDistanceTravelled = null
			this.travelTimeElapsedInSeconds = 0
			this.travelTimeElapsedForWaveFunctions = 0
			this.travelTimeElapsedForWaveFunctionsPrev = 0
			this.radiansTravelled = 0
			this.totalEntitiesHit = 0
			this.waveSpreadOffset = 0
			this.radiusSpreadModifier = 1
			this.clearMultiHitAcc = 0
			this.maxRangeForDeletion = PROJECTILE_AND_BEAM_MAX_RANGE
			this.lifespan = overrideValues.statList.getStat(StatType.projectileLifeSpan) as timeInSeconds || 10
			this.weaponType = overrideValues.weaponType === undefined ? null : overrideValues.weaponType

			this.accelerateOnHit = this.isPrimaryWeaponProjectile && this.player.binaryFlags.has('accelerate-projectiles')

			this.reversedTrajectory = overrideValues.reversedTrajectory

			this.graphicsComponentPool = overrideValues.graphicsComponentPool
			this.graphicsComponent = overrideValues.graphicsComponent
			if (!overrideValues.graphicsComponent) {
				this.particleEffectType = overrideValues.effectType ?? ParticleEffectType.PROJECTILE_WAND0
				this.bulletTrailParticleEffectType = overrideValues.trailType ?? ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL
				this.effects = Renderer.getInstance().registerProjectile(this)
			} else {
				overrideValues.graphicsComponent.owner = this

				overrideValues.graphicsComponent.update(0)
				overrideValues.graphicsComponent.addToScene()
			}

			this.onCleanup = overrideValues.onCleanup
			this.getGraphicsComponentAndPoolForSplit = overrideValues.getGraphicsComponentAndPoolForSplit
			this.onSplit = overrideValues.onSplit

			this.statList = overrideValues.statList
			this.player = overrideValues.player

			this.maxPierceCount = overrideValues.statList.getStat(StatType.attackPierceCount)

			if (this.resourceType === ResourceType.CHARGE_UP_SHOT) {
				this.energy = overrideValues.energy
				const baseStatPierceCount = overrideValues.statList.getStat(StatType.attackPierceCount)
				const charges = this.player.primaryWeapon.chargeIncrements

				this.chargeDefinition = getChargeDefinition(this.energy, charges)
				this.maxPierceCount = baseStatPierceCount * this.chargeDefinition.chargeStats.attackPierceCount
				this.damageScale *= this.chargeDefinition.chargeStats.damage

				this.maxSplitCount = overrideValues.statList.getStat(StatType.projectileSplitCount)
				this.maxChainCount = overrideValues.statList.getStat(StatType.projectileChainCount)

				const chargeWeapon = this.player.primaryWeapon as ChargePrimaryWeapon

				if (this.energy >= 100) {
					const bonusesPerMaxCharge = chargeWeapon.bonusesPerMaxCharge
					const numMaxCharges = Math.floor(this.energy / 100)

					this.maxSplitCount += bonusesPerMaxCharge.splitting * numMaxCharges
					this.maxPierceCount += bonusesPerMaxCharge.pierce * numMaxCharges
					this.damageScale *= 1 + bonusesPerMaxCharge.damagePercent * numMaxCharges
				}
			} else {
				if (this.isShrapnel) {
					this.maxPierceCount = SHRAPNEL_NUM_PIERCE
					this.maxSplitCount = 0
					this.maxChainCount = 0

					this.isSplit = true // don't split
				} else {
					this.maxPierceCount = overrideValues.statList.getStat(StatType.attackPierceCount)
					this.maxSplitCount = overrideValues.statList.getStat(StatType.projectileSplitCount)
					this.maxChainCount = overrideValues.statList.getStat(StatType.projectileChainCount)
				}

				this.chargeDefinition = undefined
			}

			if (overrideValues.noChaining) {
				this.maxChainCount = 0
			}

			this.isSplit = Boolean(overrideValues.isSplit)
			this.isOriginalProjectile = !Boolean(overrideValues.isSplit)

			this.numEntitiesChained = 0
			this.numEntitiesPierced = 0

			const trajectoryType = this.trajectoryMods[0]?.modType
			this.isBoomerang = trajectoryType === TrajectoryModType.OUTBACK_BOOMERANG
				|| trajectoryType === TrajectoryModType.CURVED_BOOMERANG
				|| trajectoryType === TrajectoryModType.CIRCLE_SELF
				|| trajectoryType === TrajectoryModType.CHAKRAM_BOOMERANG

			this.noEffectRotation = Boolean(overrideValues.noEffectRotation) || this.isBoomerang

			this.knockbackUsingCurrentPosition = false
			if (trajectoryType === TrajectoryModType.OUTBACK_BOOMERANG || trajectoryType === TrajectoryModType.CIRCLE_SELF) {
				this.circlesPerSecond = this.statList.getStat(StatType.attackRate)
				if (trajectoryType === TrajectoryModType.CIRCLE_SELF) {
					this.circlesPerSecond /= 10
				}
				this.circleRadsPerSecond = this.circlesPerSecond * (Math.PI * 2)
				this.circleTimeAcc = 0

				if (trajectoryType === TrajectoryModType.OUTBACK_BOOMERANG) {
					this.isOutback = true
				} else {
					this.isCircling = true
					this.isOrbit = true
					this.knockbackUsingCurrentPosition = true
				}
			}

			this.rampDamage = Boolean(overrideValues.rampDamage)

			this.trajectoryMods.forEach((mod) => {
				applyTrajectoryModInitialState(this, mod)
			})

			if (overrideValues.alreadyCollided) {
				for (let i = 0; i < overrideValues.alreadyCollided.length; ++i) {
					this.entitiesCollided.push(overrideValues.alreadyCollided[i])
				}
			}

			const circleCollider = (this.colliderComponent.colliders[0] as CircleCollider)
			circleCollider.r = overrideValues.radius
			this.colliderComponent.recalculateBounds()
			this.colliderComponent.setLayer(overrideValues.collisionLayer)

			this.colliderComponent.previousPosition.x = this.position.x
			this.colliderComponent.previousPosition.y = this.position.y
			if (debugConfig.collisions.drawProjectileColliders) {
				this.colliderComponent.drawColliders()
			}

			CollisionSystem.getInstance().addCollidable(this.colliderComponent)
			GameState.addEntity(this)
		}
	}

	cleanup() {
		Renderer.getInstance().unregisterProjectile(this.nid)
		CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
		if (this.colliderComponent.isDrawingDebugVisuals) {
			this.colliderComponent.stopDrawingColliders()
		}

		if (!this.groupHitSomething && this.isPrimaryWeaponProjectile && !this.isSplit) {
			if (!this.projectileGroup || this.projectileGroup.length === 1) {
				if (this.player.binaryFlags.has('careful-shooter')) {
					Buff.remove(this.player, BuffIdentifier.CarefulShooter, this.player)
				}
				if (this.player.binaryFlags.has('markswitch-final-form')) {
					Buff.remove(this.player, BuffIdentifier.MarkswitchFinalForm, this.player)
				}
			}
		}

		if (this.isPrimaryWeaponProjectile && this.isOriginalProjectile) {
			if (this.player.binaryFlags.has('recover-ammo-when-primary-projectiles-are-deleted')) {
				this.player.boomerangReturned()
			}
		}

		this.applyBuffsOnHit = []
		this.trajectoryMods = []

		GameState.removeEntity(this)

		if (this.onCleanup) {
			this.onCleanup(this)
			this.onCleanup = null
		}
		this.onSplit = null
		this.getGraphicsComponentAndPoolForSplit = null

		this.toBeDeleted = false
		this.entitiesCollided.length = 0
		this.statList = null
		this.numEntitiesChained = 0
		this.lastHitChained = false
		this.numEntitiesPierced = 0
		this.totalEntitiesHit = 0
		this.damageScale = 1
		this.isCircling = false
		this.isOrbit = false
		this.player = null
		this.splashDamageEffect = null

		if (this.graphicsComponent) {
			this.graphicsComponent.removeFromScene()

			if (this.graphicsComponentPool) {
				this.graphicsComponentPool.free(this.graphicsComponent as any)
			} else {
				console.error(`NO POOL TO RETURN GRAPHICS COMPONENT TO!`)
			}

			this.graphicsComponent = null
		}

		if (this.projectileGroup) {
			this.projectileGroup.remove(this)
			this.projectileGroup = null
		}
	}

	setDebugModel() {
		this.model = new Container()
		const gfx = new Graphics()
		gfx.beginFill(0x00FF00, 1)
		gfx.drawCircle(0, 0, this.radius)
		gfx.endFill()
		this.model.addChild(gfx)
	}

	setRandomEffect() {
		this.particleEffectType = getRandomProjectileParticle()
		this.bulletTrailParticleEffectType = getRandomProjectileTrail()
	}

	update(delta: timeInSeconds): void {
		this.update2(delta)

		if (this.isBoomerang) {
			this.effects[0].rot += delta * 20
		}

		if (this.isCircling) {
			this.circleTimeAcc += delta

			if (this.circleTimeAcc >= 1 / this.circlesPerSecond) {
				this.entitiesCollided.length = 0
				this.circleTimeAcc -= 1 / this.circlesPerSecond
			}
		}
	}

	update2(delta: number): void {
		this.travelTimeElapsedInSeconds += delta
		if (this.travelTimeElapsedInSeconds > this.lifespan) {
			this.toBeDeleted = true
			// console.log('marked because lifespan')
		}

		if (this.owningEntity && this.owningEntity.entityType === EntityType.Player) {
			this.speed = Math.clamp(this.speed, 0, 15000)
		}

		const distThisFrame = this.speed * delta * GAMEPLAY_SPEED_MULTIPLIER
		this.distanceTravelled += distThisFrame
		if (this.autoSplitAfterDistanceTravelled && this.distanceTravelled >= this.autoSplitAfterDistanceTravelled) {
			this.autoSplitAfterDistanceTravelled = null
			this.split()
		}

		if (!this.isCircling) {
			projectileCheckRange(this, this.position, delta)
		}

		this.position.x += Math.cos(this.aimAngleInRads) * distThisFrame
		this.position.y += Math.sin(this.aimAngleInRads) * distThisFrame

		// const oldReversedTrajectory = this.reversedTrajectory
		// const oldRadiansTravelled = this.radiansTravelled

		for (let i = 0; i < this.trajectoryMods.length; ++i) {
			applyTrajectoryMod(this, this.trajectoryMods[i], delta, distThisFrame)
		}

		// const shouldCircularTargetReset = Math.floor(oldRadiansTravelled / RADIANS_FOR_CIRCULAR_TARGET_RESET) < Math.floor(this.radiansTravelled / RADIANS_FOR_CIRCULAR_TARGET_RESET)
		// if (shouldCircularTargetReset) {
		// 	this.clearStatsToAllowMultiHitting(false)
		// }

		// if (this.reversedTrajectory !== oldReversedTrajectory) {
		// 	// we flipped on this frame
		// 	this.clearStatsToAllowMultiHitting(true)

		// 	this.leftRightFlip = !this.leftRightFlip

		// 	this.aimAngleInRads += Math.PI
		// 	this.travelTimeElapsedForWaveFunctions -= (this.speed / defaultSpeed) * delta
		// 	this.angleToPeakOfArc = angleInRadsFromVector(sub(this.position, this.startPos))
		// }

		// if (this.owningEntity && this.owningEntity.entityType == EntityType.Player && this.reversedTrajectory) {
		// 	const a = angleInRadsFromVector(sub(this.position, this.startPos))
		// 	const angleDifference = Math.abs(angleDistanceRads(a, this.angleToPeakOfArc))

		// 	if (angleDifference > Math.PI / 2) {
		// 		this.toBeDeleted = true
		// 		// console.log('marked because pi')
		// 	}
		// }

		if (this.graphicsComponent) {
			this.graphicsComponent.update(delta)
		}

		if (this.toBeDeleted) {
			this.destroy()
		}
	}

	onCollision(otherEntity: ColliderComponent) {
		if (this.isPlayerOwned()) {
			if (otherEntity.owner.isEnemy) {
				const enemy = otherEntity.owner as Enemy

				const foundId = this.entitiesCollided.find((val) => val === enemy.nid)
				if (foundId) {
					return
				}
				this.entitiesCollided.push(enemy.nid)

				let damageScale = this.damageScale
				if (damageScale === null || damageScale === undefined) {
					damageScale = 1
				}
				
				if (this.isOutback && this.isCircling) {
					damageScale = damageScale * OUTBACK_ORBIT_DAMAGE_SCALE
				}

				if (this.rampDamage) {
					damageScale *= getRampingDamageBonus(this.totalEntitiesHit)
				}

				enemy.onHitByDamageSource(this, damageScale)
				this.applyBuffsOnHit.forEach((buffState) => {
					if (buffState.buffId === BuffIdentifier.Stun) {
						stunEnemy(enemy, buffState.owner, buffState.duration)
					} else  {
						Buff.apply(buffState.buffId, buffState.owner, enemy, buffState.stacks, buffState.duration)
					}
				})

				if (this.isBoomerang) {
					if (this.player.binaryFlags.has('boomerang-lightning-strike-on-hit')) {
						const damage = this.player.primaryWeapon.statList.getStat('baseDamage') * BOOMERANG_LIGHTNING_STRIKE_DAMAGE_MULT
						const radius = BOOMERANG_LIGHTNING_STRIKE_SPLASH_RADIUS
						LightningStrike.strikeEnemiesOnScreen(1, this.player.primaryWeapon.statList, radius, 0, BOOMERANG_LIGHTNING_STRIKE_DAMAGE_MULT)
					}
				}

				this.splash(enemy.position, enemy)

				if (this.isPrimaryWeaponProjectile) {
					if (enemy.isDead() && this.emitShrapnel) {
						this.burstShrapnel(enemy.position, this.emitShrapnel, !this.isBoomerang)
					}

					if (!enemy.isDead() && this.player.binaryFlags.has('bow-pin-down')) {
						let duration = PIN_DOWN_DURATION
						if (this.player.binaryFlags.has('bow-pin-down-increased-duration')) {
							duration += PIN_DOWN_BONUS_DURATION
						}

						if (enemy.config.type === EnemyType.BOSS) {
							duration *= PIN_DOWN_BOSS_DURATION_SCALAR
						}
						Buff.apply(BuffIdentifier.BowPinDown, this.player, enemy, 1, duration)
					}
				}

				this.totalEntitiesHit++

				if (!this.isSplit) {
					if (this.isPrimaryWeaponProjectile && !this.groupHitSomething && this.player.binaryFlags.has('careful-shooter')) {
						Buff.apply(BuffIdentifier.CarefulShooter, this.player, this.player)
					}
					if (this.isPrimaryWeaponProjectile && !this.groupHitSomething && this.player.binaryFlags.has('markswitch-final-form')) {
						Buff.apply(BuffIdentifier.MarkswitchFinalForm, this.player, this.player)
					}
					this.split()
				}
				this.setGroupHitSomething()

				if (this.lastHitChained && this.player && this.player.binaryFlags.has('chained-together')) {
					this.lastChainHitVector.sub(enemy.position)
					this.lastChainHitVector.normalize()
					this.lastChainHitVector.scale(this.player.binaryFlagState['chained-together'].knockback)

					enemy.addKnockBack(this.lastChainHitVector)
				}

				let chained = false
				if (this.numEntitiesChained < this.maxChainCount) {
					chained = this.nextChain(enemy.colliderComponent)
				}
				this.lastHitChained = chained

				if (!chained) {
					if (this.maxPierceCount <= this.numEntitiesPierced) {
						this.toBeDeleted = true
					} else {
						this.numEntitiesPierced++

						// target was pierced and projectile still exists
						if (this.player && !enemy.isDead() && this.player.binaryFlags.has('apply-bleed-to-pierced-enemies')) {
							Buff.apply(BuffIdentifier.Bleed, this.player, enemy, getBleedStacks(getDamageFromDamageSource(this)) * 0.5)
						}
						if(GameState.player.binaryFlags.has('random-ricochet')){
							this.aimAngleInRads = Math.random() * 2 * Math.PI
						}
					}
				} else {
					if (GameState.player.binaryFlags.has('chain-same-target')) {
						// remove all collided except our current collided
						this.entitiesCollided.length = 1
						this.entitiesCollided[0] = enemy.nid
					}

					this.lastChainHitVector.copy(enemy.position)
				}

				if (this.accelerateOnHit) {
					this.speed *= BOOMERANG_ACCELARATION_SPEED_INCREASE
					Buff.apply(BuffIdentifier.BoomerangAccelerationPlayerBuff, this.player, this.player)
				}
			} else if (otherEntity.owner.entityType !== EntityType.GroundHazard && !this.ignoreProps) {
				this.toBeDeleted = true
			}
		}
	}

	split() {
		if (this.maxSplitCount > 0) {

			this.isSplit = true
			const aimAngle = this.aimAngleInRads

			const projectileParams: ProjectileInitialParams = {
				owningEntityId: this.owningEntityId,
				weaponType: this.weaponType,
				position: this.position.clone(),
				speed: this.initialSpeed,
				aimAngleInRads: this.aimAngleInRads,
				trajectoryMods: this.trajectoryMods,
				radius: this.radius,
				collisionLayer: this.colliderComponent.layer,
				statList: this.statList,
				resourceType: this.resourceType,
				energy: this.energy,
				player: this.player,
				effectType: this.particleEffectType,
				trailType: this.bulletTrailParticleEffectType,
				isSplit: true,
				alreadyCollided: this.entitiesCollided,
				isPrimaryWeaponProjectile: this.isPrimaryWeaponProjectile,
			}

			const spreadAngle = GameState.player.binaryFlags.has('starburst') ? (360 / this.maxSplitCount) : this.statList.getStat(StatType.projectileSpreadAngle)
			const offsetAngle = GameState.player.binaryFlags.has('starburst') ? Math.PI : 0

			for (let i = 0; i <= this.maxSplitCount; ++i) {
				const { projectileAngle, aimOffset } = splitAngle(i, this.maxSplitCount, spreadAngle)
				if (i === PLAYER_INITIAL_PROJECTILE) {
					if (GameState.player.binaryFlags.has('random-ricochet')) {
						this.aimAngleInRads = Math.random() * 2 * Math.PI
					} else {
						this.aimAngleInRads = aimAngle + projectileAngle - aimOffset - offsetAngle
					}
					this.startAimAngleInRads = this.aimAngleInRads

					// hacky way to prevent the initial projectile from being deleted when it hits something
					this.maxPierceCount += 1
				} else {
					if (this.getGraphicsComponentAndPoolForSplit) {
						const res = this.getGraphicsComponentAndPoolForSplit(this)
						projectileParams.graphicsComponent = res.graphics
						projectileParams.graphicsComponentPool = res.pool
					}
					if (GameState.player.binaryFlags.has('random-ricochet')) {
						projectileParams.aimAngleInRads = Math.random() * 2 * Math.PI
					} else {
						projectileParams.aimAngleInRads = aimAngle + projectileAngle - aimOffset - offsetAngle
					}

					const proj = PlayerProjectile.objectPool.alloc(projectileParams)
					if (this.onSplit) {
						this.onSplit(proj)
					}
				}
			}
		}
	}

	burstShrapnel(position: Vector, shrapnelType: ShrapnelConfig, cloneTrajectories: boolean = true) {
		const shrapnel = ShrapnelConfigMap[shrapnelType]

		const projectileParams = {
			isShrapnel: true,
			owningEntityId: this.owningEntityId,
			position,
			speed: this.initialSpeed,
			trajectoryMods: (cloneTrajectories ? this.trajectoryMods : []),
			radius: Math.max(this.radius / 2, shrapnel.minProjectileSize),
			collisionLayer: this.colliderComponent.layer,
			statList: this.statList,
			resourceType: ResourceType.NONE,
			damageScale: this.damageScale * shrapnel.damageScale,
			player: this.player,
			effectType: shrapnel.particleEffectType ?? this.particleEffectType,
			trailType: this.bulletTrailParticleEffectType,
			weaponType: this.weaponType,
			aimAngleInRads: undefined
		} as ProjectileInitialParams

		for (let i = 0; i < shrapnel.projectileCount; ++i) {
			projectileParams.aimAngleInRads = Math.getRandomFloat(0, Math.PI * 2)
			const proj = PlayerProjectile.objectPool.alloc(projectileParams)
			proj.maxPierceCount = shrapnel.attackPierceCount

			if (shrapnelType === ShrapnelConfig.LongbowWalkItOff) {
				proj.applyBuffsOnHit.push({
					buffId: BuffIdentifier.Stun,
					owner: GameState.entityMap.get(this.owningEntityId) as any,
					stacks: 1,
				})
			}
		}
	}

	splash(position: Vector, hitEntity: Enemy) {
		const splashRadius = this.statList.getStat(StatType.projectileSplashRadius)
		if (splashRadius > 0) {
			const nearbyEntities = dealAOEDamageDamageSource(CollisionLayerBits.HitEnemyOnly, splashRadius, position, this, this.statList.getStat(StatType.projectileSplashDamage), true, hitEntity.nid, undefined, undefined, this.splashDamageEffect)
			// Renderer.getInstance().drawCircle({ x: position.x, y: position.y, radius: splashRadius, destroyAfterSeconds: 0.1, scale: 1, permanent: false, color: 0xFF0000 })

			if (this.isPrimaryWeaponProjectile && this.player.binaryFlags.has('bow-pin-down')) {
				let duration = PIN_DOWN_DURATION
				if (this.player.binaryFlags.has('bow-pin-down-increased-duration')) {
					duration += PIN_DOWN_BONUS_DURATION
				}

				for (let i = 0; i < nearbyEntities.length; ++i) {
					const enemy = nearbyEntities[i].owner as Enemy
					if (enemy.nid !== hitEntity.nid) {
						Buff.apply(BuffIdentifier.BowPinDown, this.player, enemy, 1, duration * (enemy.config.type === EnemyType.BOSS ? PIN_DOWN_BOSS_DURATION_SCALAR : 1))
					}
				}
			}
		}
	}

	nextChain(lastHitEntity: ColliderComponent): boolean {
		const searchRadius = PROJECTILE_DEFAULT_CHAIN_DISTANCE * this.statList.getStat(StatType.projectileChainDistanceMultiplier)
		const nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(this.position, searchRadius, CollisionLayerBits.HitEnemyOnly)

		let closestEntity: ColliderComponent

		if (GameState.player.binaryFlags.has('chain-same-target')) {
			nearbyEntities.remove(lastHitEntity) // get any closest entity... except the one we just hit
			closestEntity = getClosestEntity(nearbyEntities, this.position)
		} else {
			closestEntity = getClosestNotHitEntity(nearbyEntities, this.position, this.entitiesCollided)
		}

		if (closestEntity) {
			const enemy = closestEntity.owner as Enemy
			this.travelTimeElapsedInSeconds = 0
			this.numEntitiesChained++

			const xDiff = enemy.position.x - this.position.x
			const yDiff = enemy.position.y - this.position.y

			if (this.resetEffectOnChain){
				this.effects.forEach((effect) => {
					effect.emitters.forEach((emitter) => {
						emitter.reset()
						emitter.ensureParticleEmitted()
					})
				})
			}
			if (GameState.player.binaryFlags.has('random-ricochet')) {
				this.aimAngleInRads = Math.random() * 2 * Math.PI
			} else {
				this.aimAngleInRads = Math.atan2(yDiff, xDiff)
			}

			// @TODO, not sure if this always gives the right angle
			this.startAimAngleInRads = this.aimAngleInRads

			return true
		}

		return false
	}

	destroy() {
		PlayerProjectile.objectPool.free(this)
	}

	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		let kbSource: Vector
		if (this.knockbackUsingCurrentPosition) {
			kbSource = this.position
		} else {
			kbSource = this.startPos
		}

		return mutableEntityPos.sub(kbSource).normalize()
	}

	clearStatsToAllowMultiHitting(clearShotgunningCount?: boolean) {
		// this.entitiesHit.length = 0
		this.entitiesCollided.length = 0
		this.splashedAlready = false
	}

	limitCircularSpeed(radius: number) {
		const circumference = 2 * radius * Math.PI
		this.speed = Math.clamp(this.speed, 0, circumference * 4) // the *4 is just based on look-and-feel testing
	}

	setRadius(radius: number) {
		const circleCollider = (this.colliderComponent.colliders[0] as CircleCollider)
		circleCollider.r = radius
		this.colliderComponent.recalculateBounds()

		this.radius = radius
	}

	private setGroupHitSomething() {
		if (!this.groupHitSomething) {
			if (this.projectileGroup) {
				for (let i = 0; i < this.projectileGroup.length; ++i) {
					this.projectileGroup[i].groupHitSomething = true
				}
			}
			this.groupHitSomething = true
		}
	}
}

export function projectileCheckRange(projectile: IRangedProjectile, position: VectorXY, delta: timeInSeconds) {
	const defaultSpeed = PLAYER_WAVE_TRAVEL_TIME_DEFAULT_SPEED

	//TODO2: change this distance function to something proper
	const distanceFromStart = distanceVV(projectile.startPos, position)
	if (distanceFromStart >= projectile.maxRangeForDeletion) {
		if (!projectile.reachedMaxRange) {
			if (projectile.willReverseTrajectory && !projectile.reversedTrajectory) {
				projectile.reversedTrajectory = true
				projectile.willReverseTrajectory = false
				projectile.reachedMaxRange = true

				projectile.clearStatsToAllowMultiHitting(true)

				projectile.leftRightFlip = !projectile.leftRightFlip
				projectile.aimAngleInRads += Math.PI

				projectile.travelTimeElapsedForWaveFunctions -= (projectile.speed / defaultSpeed) * delta
				projectile.angleToPeakOfArc = angleInRadsFromVector(sub(projectile.position, projectile.startPos))
			} else {
				projectile.toBeDeleted = true
				// console.log('marked because max range')
			}
		}
	} else {
		projectile.reachedMaxRange = false
	}
}

export function splitAngle(projectileNumber: number, totalProjectiles: number, projectileSpread: number) {
	const spreadAngle = degToRad(Math.max(SPLIT_MINIMUM_SPREAD, projectileSpread))
	return { projectileAngle: spreadAngle * projectileNumber, aimOffset: spreadAngle * totalProjectiles / 2 }
}
