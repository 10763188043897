import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const snowFury2: VariantAI = {
    extends: ENEMY_NAME.SNOW_FURY_1,
    name: ENEMY_NAME.SNOW_FURY_2,
    appearance: {
        asset: SpineDataName.SNOW_FURY_2,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.15,
    maxHealth: 0.45
}

export const snowFury3: VariantAI = {
    extends: ENEMY_NAME.SNOW_FURY_1,
    name: ENEMY_NAME.SNOW_FURY_3,
    appearance: {
        asset: SpineDataName.SNOW_FURY_3,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.15,
    maxHealth: 0.45
}