import { Vector } from "sat"
import { Audio } from "../../../engine/audio"
import { RangerTrapGroundHazard, RANGER_TRAP_ANIMATION_TIME } from "../../../entities/hazards/ranger-trap-hazard"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { SkillWeapon } from "../../skill-weapon"
import { AllWeaponTypes } from "../../weapon-types"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"

// cooldown, see COOLDOWN_DEFINITIONS.rangerTrap
export const RANGER_SKILL_BASE_STATS_DATA = {
    triggerRadius: 100,
    activationTime: 1,
    effectRadius: 330,
    expiryTime: 30,
    slowAmount: 0.6,
    damagePercent: 0.2,

    tossMaxRange: 400,
    tossMaxRangeSquared: 400 * 400
}

export class RangerSkillWeapon extends SkillWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.ElfRangerSkill
    
    update(delta: number) {

    }
    
    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
        statList._actualStatValues.attackSize = 1
        statList._actualStatValues.attackRate = 1
        statList._actualStatValues.placementRange = 0
        statList._actualStatValues.skillDuration = 1
        statList._actualStatValues.slowAmount = 0.6

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 300
		statList._actualStatValues.reloadInterval = 25_000
    }

    useSkill() {
        const sizeScalar = this.statList.getStat(StatType.attackSize)
       
        let position: Vector
        if(this.player.binaryFlags.has('ranger-trap-toss')) {
            const placementRange = this.statList.getStat(StatType.placementRange)
            position = this.player.getSkillShotPlace(placementRange, placementRange * placementRange)
        } else {
            position = this.player.position
        }

        const trap = RangerTrapGroundHazard.pool.alloc({
            triggerRadius: RANGER_SKILL_BASE_STATS_DATA.triggerRadius * sizeScalar,
            activationTime: RANGER_SKILL_BASE_STATS_DATA.activationTime,
            effectRadius: RANGER_SKILL_BASE_STATS_DATA.effectRadius * sizeScalar,
            expiryTime: RANGER_SKILL_BASE_STATS_DATA.expiryTime,
            removalTime: RANGER_TRAP_ANIMATION_TIME,
            position,
            primaryWeaponStatList: this.player.primaryWeapon.statList,
            skillStatList: this.statList
        })

        Audio.getInstance().playSfx('UI_Click_Minor')
    }

    protected canUseSkill(): boolean {
		return true
	}
}