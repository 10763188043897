import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, FightingBehaviours, ShotLeadPrecision, VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const eyesicle2: VariantAI = {
    extends: ENEMY_NAME.EYESICLE_1,
    name: ENEMY_NAME.EYESICLE_2,
    appearance: {
        asset: SpineDataName.EYESICLE_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.5, // base eyebat has a scale of 1.5
    },
	baseDamage: 0,
	attackSize: 30,
	projectileCount: 4,
	projectileSpreadAngle: 90,
	projectileLifeSpan: 3,
	chillChance: 1,
	chillPotency: 0.5, // currently doesn't do anything
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_ARCANEFOCUS0,
				cooldownDef: {
					// name: "enemyProjectile",
					maxAmmo: 1,
					cooldownInterval: 15000,
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 600,
			movementMinDistance: 400,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}
