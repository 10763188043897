type hazardConfig = {
    hazardRadiusX: number,
    hazardRadiusY: number,
    hazardTriggerRadius: number
}

export enum MapSpecificHazardsTypes {
    TundraIce = 'ice-field.png',
}

export const mapHazardConfigRecord: Record<MapSpecificHazardsTypes, hazardConfig> = {
    [MapSpecificHazardsTypes.TundraIce]: {
        hazardRadiusX: 200,
        hazardRadiusY: 180,
        hazardTriggerRadius: 200
    }
}