import { percentage, timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { cloneDeep } from 'lodash'
import { AllWeaponTypes, WeaponConfig } from "../weapons/weapon-types"
import { ACT_TIMER, InGameTime } from "../utils/time"
import { ENEMY_NAME } from "../entities/enemies/enemy-names"
import { DamageSource } from "../projectiles/damage-source"
import { Enemy } from "../entities/enemies/enemy"
import { getPropWithFallback } from "../utils/object-util"
import { PetCollectionName } from "../entities/pets/pet"
import { BuffIdentifier } from "../buffs/buff.shared"
import { AllEventTypes } from "../events/event-stat-types"

const LOGGING = false

export enum TrackableMetricEventNameStrings {
	ENEMIES_KILLED,
	WEAPON_KILL,
	EXPERIENCE_GAINED,
	PLAYER_LEVEL,
	TIME_SURVIVED,
	HEARTS_GAINED,
	CROSSBOW_DAMAGE,
	WAND_DAMAGE,
	TESLA_COIL_DAMAGE,
	ACID_BOTTLES_DAMAGE,
	RAT_PARADE_DAMAGE,
	NIKOLA_SCOPE_DAMAGE,
	WEAPON_DAMAGE,
	EVENT_COMPLETED,
	PET_ADDED,
	DEBUFF_APPLIED,
	LONGEST_KILLSTREAK,
	SKILL_USED,
}

type SumMetric = number

type MaxMetric = number

type MinMetric = number

type StringMetric = string

type CollectedStringMetric = string[]

type timeInMillisecondsDurationMetric = timeInSeconds

type DamageData = [DamageSource, number, Enemy]

type KillData = [Enemy]

type DamageSourceData = [DamageSource, Enemy]

interface WeaponStats {
	name: string,
	weaponType: number,
	dps: number,
	totalDamage: SumMetric,
	totalKills: SumMetric,
	firstAttackTime: timeInMillisecondsDurationMetric,
	enemyTypesKilled: { [k in ENEMY_NAME]?: SumMetric}
}

interface EnemyStats {
	name: ENEMY_NAME,
	totalDamage: SumMetric,
	maxDamage: MaxMetric,
	totalKills: SumMetric,
	weaponDamage: { [k in AllWeaponTypes]?: WeaponStats }
}

export interface MetricBundle {
	sumOfEnemiesKilled: SumMetric

	sumOfExperienceGained: SumMetric

	sumOfSkillsUsed: SumMetric

	sumOfPetsRescued: SumMetric

	maxLevelAchievedThisRun: MaxMetric

	totalRunDurationInSeconds: timeInMillisecondsDurationMetric

	sumOfHeartsGained: SumMetric

	debuffsAfflicted: { [k in BuffIdentifier]?: SumMetric }

	totalScore: SumMetric
	scoreBreakdown: {
		fromLevel: number,
		fromKills: number,
		fromEvents: number,
	}

	longestKillStreak: MaxMetric

	weaponDamage: { [k in AllWeaponTypes]?: WeaponStats }

	eventStats: { [k in AllEventTypes]?: SumMetric }

	enemyStats: { [k in ENEMY_NAME]?: EnemyStats }
}

class MetricTracker implements MetricBundle {
	sumOfEnemiesKilled: SumMetric = 0

	sumOfExperienceGained: SumMetric = 0

	sumOfSkillsUsed: SumMetric = 0

	sumOfPetsRescued: SumMetric = 0

	maxLevelAchievedThisRun: MaxMetric = 1

	totalRunDurationInSeconds: timeInMillisecondsDurationMetric = 0

	sumOfHeartsGained: SumMetric = 0

	petsCollected: CollectedStringMetric = []

	debuffsAfflicted: { [k in BuffIdentifier]?: SumMetric } = {}

	totalScore: SumMetric = 0
	scoreBreakdown: {
		fromLevel: number,
		fromKills: number,
		fromEvents: number,
	} = {
			fromLevel: 0,
			fromKills: 0,
			fromEvents: 0,
		}

	longestKillStreak: MaxMetric = 0

	weaponDamage: { [k in AllWeaponTypes]?: WeaponStats } = {}

	eventStats: { [k in AllEventTypes]?: SumMetric } = {}

	enemyStats: { [k in ENEMY_NAME]?: EnemyStats } = {}

	trackSumOfEnemiesKilled() {
		this.sumOfEnemiesKilled += 1
	}

	trackLongestKillstreak(killstreak: number) {
		if (killstreak > this.longestKillStreak) {
			this.longestKillStreak = killstreak
		}
	}

	trackExperienceGained([amount]: [number]) {
		this.sumOfExperienceGained += amount
	}

	trackLevelAchieved() {
		this.maxLevelAchievedThisRun += 1
	}

	trackFinalRunTime() {
		let roundEndTime = InGameTime.highResolutionTimestamp() / 1000
		this.totalRunDurationInSeconds = ~~roundEndTime
	}

	trackHeartsGained() {
		this.sumOfHeartsGained += 1
	}

	trackPetAdded([petName]: PetCollectionName) {
		this.petsCollected.push(petName)
		this.sumOfPetsRescued += 1
	}

	trackDebuffApplied([debuff]: BuffIdentifier) {
		if (debuff in this.debuffsAfflicted) {
			this.debuffsAfflicted[debuff] += 1
		} else {
			this.debuffsAfflicted[debuff] = 1
		}
	}

	trackEvents(event) {
		let eventType = event[0]

		if (eventType === undefined) {
			return
		}
		this.eventStats[eventType] = getPropWithFallback(this.eventStats, eventType, 0) + 1
	}

	trackWeaponDamage(weaponDamage: { [k in AllWeaponTypes]?: WeaponStats }, [incomingDamageSource, incomingWeaponDamage, _damagedEntity]: DamageData) {
		let inGameTime = InGameTime.highResolutionTimestamp()
		let incomingWeaponType = incomingDamageSource.weaponType || -1
		const weaponPrettyName = WeaponConfig[incomingWeaponType]?.name || `unknown`
		if (weaponPrettyName === `unknown` || incomingWeaponType <= 0) {
			console.log('trackWeaponDamage() with unknown weapon type') // change this to console.trace to see where from; it gets spammy
		}

		const record = getPropWithFallback(weaponDamage, incomingWeaponType, {
			dps: 0,
			totalDamage: incomingWeaponDamage,
			totalKills: 0,
			name: weaponPrettyName,
			weaponType: incomingWeaponType,
			firstAttackTime: inGameTime,
			enemyTypesKilled: {}
		})
		record.totalDamage += incomingWeaponDamage
		if (LOGGING) {
			console.log(`weapon damage ${incomingWeaponType} [${weaponPrettyName}] = ${record.totalDamage}`)
		}
		weaponDamage[incomingWeaponType] = record
	}

	trackWeaponKill(weaponDamage: { [k in AllWeaponTypes]?: WeaponStats }, [incomingDamageSource, enemy]: DamageSourceData) {
		let inGameTime = InGameTime.highResolutionTimestamp()
		let incomingWeaponType = incomingDamageSource.weaponType
		const weaponPrettyName = WeaponConfig[incomingWeaponType]?.name || `unknown`
		if (weaponPrettyName === `unknown` || incomingWeaponType <= 0) {
			console.log('trackWeaponDamage() with unknown weapon type') // change this to console.trace to see where from; it gets spammy
		}

		const record = getPropWithFallback(weaponDamage, incomingWeaponType, {
			dps: 0,
			totalDamage: 0,
			totalKills: 0,
			name: weaponPrettyName,
			weaponType: incomingWeaponType,
			firstAttackTime: inGameTime,
			enemyTypesKilled: {}
		})
		record.totalKills += 1
		
		if (!Object.hasOwn(record.enemyTypesKilled, enemy.name)) {
			record.enemyTypesKilled[enemy.name] = 1
		} else {
			record.enemyTypesKilled[enemy.name] += 1
		} 
		
		if (LOGGING) {
			console.log(`weapon kill ${incomingWeaponType} = ${record.totalKills}, ${enemy.name} kills = ${record.enemyTypesKilled[enemy.name]}`)
		}
		weaponDamage[incomingWeaponType] = record
	}

	trackEnemyAndEnemyWeaponDamage([incomingDamageSource, incomingWeaponDamage, damagedEntity]: DamageData) {
		const name: ENEMY_NAME = damagedEntity.config.baseVariant
		const enemyRecord = this.getEnemyRecord(name)
		enemyRecord.totalDamage += incomingWeaponDamage
		enemyRecord.maxDamage = Math.max(enemyRecord.maxDamage, incomingWeaponDamage)
		if (LOGGING) {
			console.group(`enemy ${name}, dmg = ${enemyRecord.totalDamage}`)
		}
		this.trackWeaponDamage(enemyRecord.weaponDamage, [incomingDamageSource, incomingWeaponDamage, damagedEntity])
		this.enemyStats[name] = enemyRecord
		if (LOGGING) {
			console.groupEnd()
		}
	}

	trackEnemyTypeKill([damagedEntity]: KillData) {
		const name: ENEMY_NAME = damagedEntity.isBoss ? damagedEntity.config.name : damagedEntity.config.baseVariant
		const enemyRecord = this.getEnemyRecord(name)
		enemyRecord.totalKills += 1
		if (LOGGING) {
			console.group(`enemy ${name}, kills = ${enemyRecord.totalKills}`)
		}
		this.enemyStats[name] = enemyRecord
		console.groupEnd()
	}

	trackSkillsUsed() {
		this.sumOfSkillsUsed += 1
	}

	private getEnemyRecord(name: ENEMY_NAME): EnemyStats {
		return getPropWithFallback(this.enemyStats, name, {
			name: name,
			totalDamage: 0,
			maxDamage: 0,
			totalKills: 0,
			weaponDamage: new Map(),
		})
	}

}

const GENERIC_SCORE_EXPONENT = 1.1
const LEVEL_SCORE_EXPONENT = 1.32
const LEVEL_SCORE_MULT = 100
const ACT_COMPLETE_SCORE_MAP = {
	0: 0,
	1: 5000,
	2: 10000,
	3: 20000,
}
const EVENT_SCORE_EXPONENT = 1.2
const PET_RESCUE_SCORE = 500
const LOOT_GOBLIN_SCORE = 250


export default class PlayerMetricsSystem {
	private static instance: PlayerMetricsSystem

	static getInstance() {
		if (!PlayerMetricsSystem.instance) {
			PlayerMetricsSystem.instance = new PlayerMetricsSystem()
		}

		return PlayerMetricsSystem.instance
	}
	static destroy() {
		PlayerMetricsSystem.instance = null
	}

	private playerMetrics: MetricTracker

	constructor() {
		this.playerMetrics = new MetricTracker()
	}

	getFinalMetrics(): MetricBundle {
		let playerMetrics = cloneDeep(this.playerMetrics)
		const inGameTime = InGameTime.highResolutionTimestamp()
		if (LOGGING) {
			console.group('getFinalMetrics()')
		}

		const killScore = ~~Math.pow(playerMetrics.sumOfEnemiesKilled, GENERIC_SCORE_EXPONENT)
		const levelScore = ~~Math.pow(Math.pow(playerMetrics.maxLevelAchievedThisRun, LEVEL_SCORE_EXPONENT) * LEVEL_SCORE_MULT, GENERIC_SCORE_EXPONENT)
		let eventScore = 0
		const actsCompleted = playerMetrics.eventStats[AllEventTypes.ACT_COMPLETE] ?? 0
		const petsRescued = playerMetrics.eventStats[AllEventTypes.PETS_RESCUE] ?? 0
		const goblinsKilled = playerMetrics.eventStats[AllEventTypes.LOOT_GOBLINS] ?? 0
		eventScore += ACT_COMPLETE_SCORE_MAP[Math.clamp(actsCompleted, 0, 3)]
		eventScore += Math.pow(petsRescued * PET_RESCUE_SCORE, EVENT_SCORE_EXPONENT)
		eventScore += Math.pow(goblinsKilled * LOOT_GOBLIN_SCORE, EVENT_SCORE_EXPONENT)
		eventScore = ~~eventScore
		const totalScore = ~~(levelScore + killScore + eventScore)

		playerMetrics.scoreBreakdown.fromKills = killScore
		playerMetrics.scoreBreakdown.fromLevel = levelScore
		playerMetrics.scoreBreakdown.fromEvents = eventScore
		playerMetrics.totalScore = totalScore
		playerMetrics.sumOfExperienceGained = Math.floor(playerMetrics.sumOfExperienceGained)

		// console.log({
		// 	eventStats: playerMetrics.eventStats,
		// 	actsCompleted,
		// 	petsRescued,
		// 	goblinsKilled,
		// 	killScore,
		// 	levelScore,
		// 	eventScore,
		// 	totalScore,
		// })

		for (const wkey in playerMetrics.weaponDamage) {
			const weapon: WeaponStats = playerMetrics.weaponDamage[wkey]
			weapon.dps = Math.round(weapon.totalDamage / (inGameTime - weapon.firstAttackTime) * 1000)
			if (LOGGING) {
				console.log(`weapon: ${weapon.dps} dps, damage: ${weapon.totalDamage}, time: ${inGameTime - weapon.firstAttackTime}`)
			}
		}
		for (const ekey in playerMetrics.enemyStats) {
			const enemy: EnemyStats = playerMetrics.enemyStats[ekey]
			if (LOGGING) {
				console.group(`enemy: ${enemy.name}`)
			}
			for (const wkey in enemy.weaponDamage) {
				const weapon: WeaponStats = enemy.weaponDamage[wkey]
				weapon.dps = Math.round(weapon.totalDamage / (inGameTime - weapon.firstAttackTime) * 1000)
				if (LOGGING) {
					console.log(`weapon: ${weapon.dps} dps, damage: ${weapon.totalDamage}, time: ${inGameTime - weapon.firstAttackTime}`)
				}
			}
			if (LOGGING) {
				console.groupEnd()
			}
		}

		if (LOGGING) {
			console.groupEnd()
		}

		return playerMetrics
	}

	getSerializableMetrics(): MetricBundle {
		const playerMetrics = this.getFinalMetrics()

		const sendingBuffs = [BuffIdentifier.Ignite, BuffIdentifier.Poison, BuffIdentifier.Bleed, BuffIdentifier.Chill, BuffIdentifier.Shock, BuffIdentifier.Stun, BuffIdentifier.Doom]
		for (const key in playerMetrics.debuffsAfflicted) {
			if (!sendingBuffs.includes(key as BuffIdentifier)) {
				delete playerMetrics.debuffsAfflicted[key]
			}
		}

		return playerMetrics
	}

	trackMetric(metricEventString: keyof typeof TrackableMetricEventNameStrings, ...metricData: any) {
		const metricName = TrackableMetricEventNameStrings[metricEventString]
		const playerMetrics = this.playerMetrics

		switch (metricName) {
			case TrackableMetricEventNameStrings.EXPERIENCE_GAINED:
				playerMetrics.trackExperienceGained(metricData)
				break
			case TrackableMetricEventNameStrings.EVENT_COMPLETED:
				playerMetrics.trackEvents(metricData)
				break
			case TrackableMetricEventNameStrings.ENEMIES_KILLED:
				playerMetrics.trackSumOfEnemiesKilled()
				playerMetrics.trackEnemyTypeKill(metricData)
				break
			case TrackableMetricEventNameStrings.WEAPON_KILL:
				playerMetrics.trackWeaponKill(this.playerMetrics.weaponDamage, metricData)
				break
			case TrackableMetricEventNameStrings.HEARTS_GAINED:
				playerMetrics.trackHeartsGained()
				break
			case TrackableMetricEventNameStrings.PLAYER_LEVEL:
				playerMetrics.trackLevelAchieved()
				break
			case TrackableMetricEventNameStrings.TIME_SURVIVED:
				playerMetrics.trackFinalRunTime()
				break
			case TrackableMetricEventNameStrings.WEAPON_DAMAGE:
				playerMetrics.trackWeaponDamage(this.playerMetrics.weaponDamage, metricData)
				playerMetrics.trackEnemyAndEnemyWeaponDamage(metricData)
				break
			case TrackableMetricEventNameStrings.PET_ADDED:
				playerMetrics.trackPetAdded(metricData)
				break
			case TrackableMetricEventNameStrings.DEBUFF_APPLIED:
				playerMetrics.trackDebuffApplied(metricData)
				break
			case TrackableMetricEventNameStrings.LONGEST_KILLSTREAK:
				playerMetrics.trackLongestKillstreak(metricData[0])
				break
			case TrackableMetricEventNameStrings.SKILL_USED:
				playerMetrics.trackSkillsUsed()
				break
		}
	}
}