import { Player } from "../../entities/player"
import { CharacterType } from "../../game-data/characters"
import clientLogger from "../../utils/client-logger"
import { debugtool } from "../../utils/decorators"
import { PrimaryWeaponType } from "../../weapons/weapon-types"
import { MapNames, mapNames } from "../../world-generation/world-data"
import { UpgradeManager } from "../upgrade-manager"
import { MetaPerkManager } from "./meta-perk-manager"
import { MetaUnlockDefinition, UnlockDefinitions, MetaUnlockTag } from "./meta-unlocks-definitions"

export type InstancedUnlockDefinition = {
    unlockDefinition: MetaUnlockDefinition
    isLocked: boolean
}

export class MetaUnlocksManager {
    private static _instance: MetaUnlocksManager
    static getInstance() {
        if (!this._instance) {
            this._instance = new MetaUnlocksManager()
        }
        return this._instance
    }

    private instancedUpgrades: Map<MetaUnlockTag, InstancedUnlockDefinition>

    private primaryWeaponsUnlocked: Set<PrimaryWeaponType> // janky but simple
    private charactersUnlocked: Set<CharacterType>
    private mapsUnlocked: Set<MapNames>

    constructor() {
        this.instancedUpgrades = new Map()

        this.primaryWeaponsUnlocked = new Set()
        const primaries = Object.values(PrimaryWeaponType)
        for (const p of primaries) {
            const primaryValue = Number(p)
            if (!isNaN(primaryValue)) {
                this.primaryWeaponsUnlocked.add(primaryValue)
            }
        }

        this.charactersUnlocked = new Set()
        const characters = Object.values(CharacterType)
        for (const c of characters) {
            const characterValue = Number(c)
            if (!isNaN(characterValue)) {
                this.charactersUnlocked.add(characterValue)
            }
        }

        this.mapsUnlocked = new Set()
        const maps = Object.values(mapNames)
        for (const m of maps) {
            this.mapsUnlocked.add(m)
        }

        for (const key in UnlockDefinitions) {
            const definition = UnlockDefinitions[key] as MetaUnlockDefinition
            this.instancedUpgrades.set(key as MetaUnlockTag, { isLocked: true, unlockDefinition: definition })
        }

        this.setAllLocked()
    }

    setAllLocked() {
        this.instancedUpgrades.forEach((instancedUnlock) => {
            instancedUnlock.isLocked = true

            // Just stuff that is needed for front-end UI here
            const definition = instancedUnlock.unlockDefinition
            if (definition.unlockPrimaryWeapon !== undefined) {
                this.primaryWeaponsUnlocked.delete(definition.unlockPrimaryWeapon)
            }

            if (definition.unlockCharacter !== undefined) {
                this.charactersUnlocked.delete(definition.unlockCharacter)
            }

            if (definition.perkUnlock) {
                MetaPerkManager.getInstance().setPerkLocked(definition.perkUnlock, true)
            }

            if (definition.mapUnlock) {
              this.mapsUnlocked.delete(definition.mapUnlock)
            }
        })
    }

    setUnlocked(tag: MetaUnlockTag) {
        const unlock = this.instancedUpgrades.get(tag)
        if (unlock) {
            unlock.isLocked = false

            // Just stuff that is needed for front-end UI here,
            // the rest is applied in aaplyUnlocks()
            const definition = unlock.unlockDefinition
            if (definition.unlockPrimaryWeapon !== undefined) {
                this.primaryWeaponsUnlocked.add(definition.unlockPrimaryWeapon)
            }

            if (definition.unlockCharacter !== undefined) {
                this.charactersUnlocked.add(definition.unlockCharacter)
            }

            if (definition.perkUnlock) {
                MetaPerkManager.getInstance().setPerkLocked(definition.perkUnlock, false)
            }

            if (definition.mapUnlock !== undefined) {
              this.mapsUnlocked.add(definition.mapUnlock)
            }
        } else {
            clientLogger.error(`Could not find unlock with tag: ${tag}`)
        }
    }

    isUnlocked(tag: MetaUnlockTag) {
        const upgrade = this.instancedUpgrades.get(tag)
        if (upgrade) {
            return !upgrade.isLocked
        }
        return false
    }

    setUnlocks(tags: MetaUnlockTag[]) {
        this.setAllLocked()

        for (let i = 0; i < tags.length; ++i) {
            this.setUnlocked(tags[i])
        }
    }

    isPrimaryWeaponAllowed(primaryType: PrimaryWeaponType) {
        return this.primaryWeaponsUnlocked.has(primaryType)
    }

    isCharacterAllowed(characterType: CharacterType) {
        return this.charactersUnlocked.has(characterType)
    }

    isMapAllowed(mapName: MapNames) {
      return this.mapsUnlocked.has(mapName)
    }

    applyUnlocks() {
        this.instancedUpgrades.forEach((instancedUnlock) => {
            if (!instancedUnlock.isLocked) {
                const definition = instancedUnlock.unlockDefinition

                if (definition.unlockUpgradeName) {
                    UpgradeManager.unlockUpgrade(definition.unlockUpgradeName)
                }

                if (definition.unlockPrimaryWeapon !== undefined) {
                    this.primaryWeaponsUnlocked.add(definition.unlockPrimaryWeapon)
                }

                if (definition.unlockCharacter !== undefined) {
                    this.charactersUnlocked.add(definition.unlockCharacter)
                }

                if (definition.perkUnlock) {
                    MetaPerkManager.getInstance().setPerkLocked(definition.perkUnlock, false)
                }

                if (definition.twistUnlock) {
                    // idk if there is anything to do for this
                }

                if (definition.mapUnlock) {
                  this.mapsUnlocked.add(definition.mapUnlock)
                }

                if (definition.applyFunction) {
                    definition.applyFunction()
                }
            }
        })
    }

    isPlayerLoadoutValid(player: Player) {
        return this.primaryWeaponsUnlocked.has(player.primaryWeaponType) && this.charactersUnlocked.has(player.characterType)
    }

    @debugtool
    unlockEverything() {
        this.instancedUpgrades.forEach((iud, tag) => {
            this.setUnlocked(tag)
        })
    }
}
