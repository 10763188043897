import { Vector } from "sat";
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types";
// Hey you ever wanna import just the types from something without importing any of the actual code?
// Originally importing these types caused some dependency problems on startup, but this fixed it.
import type { Enemy } from "./enemies/enemy";
import type { Prop } from "./prop";
import type { Player } from "./player"

export enum EntityType {
	Player,
	Enemy,
	Projectile,
	EnemyProjectile,
	Pickup,
	Prop,
	Buff,
	Cooldown,
	GroundHazard,
	POI,
	Pet,
	PetWrapper,
	Beam,
}

export interface IEntity {
	nid: number
	entityType: EntityType
	timeScale: number
	update(delta: timeInSeconds, now?: timeInMilliseconds): void
}

/** Asserts that the entity is an enemy */
export function isEnemy(entity: any): entity is Enemy {
	return entity.entityType === EntityType.Enemy
}

/** Asserts that the entity is a prop */
export function isProp(entity: any): entity is Prop {
	return entity.entityType === EntityType.Prop
}

export function isPlayer(entity: any): entity is Player {
	return entity.entityType === EntityType.Player
}

export enum DamageableEntityType {
	Player,
	Enemy,
	PlayerAndEnemy,
}
