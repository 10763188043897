<template>
    <div class="container">
        <div class="text" :lang="this.$i18n.locale" v-on:click="onClick">
            {{$t('discord.join_community')}}
        </div>
        <div class="icon">
        </div>
    </div>
</template>

<script lang="ts">

export default {
    name: 'JoinDiscordButton',
    props: {},
    methods: {
        onClick() {
            window.open('https://discord.gg/xbXhxe7Dxs')
        }
    }
}

</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @discordBlue;
    border: 3px solid black;

    &:hover,
    &.hovered {
        background-color: @white;
        .text {
            color: @discordBlue;
        }

        .icon {
            background-image: url('../../../../assets/ui/social-media-icons/discord-mark-blue.png');
        }
    }

    width: 480px;
    height: 60px;

    .text {
        color: @white;

        &[lang='en'] {
            .regularFont(@enRegularFontPixl);
            font-size: 52px;
            font-variant: small-caps;           
        }
        &[lang='zh'] {
            .regularFont(@zhHansHeaderFont);
			font-size: 35px;
		}
    }

    .icon {
        margin-left: 20px;
        background-image: url('../../../../assets/ui/social-media-icons/discord-mark-white.png');
        background-size: cover;

        width: 50px;
        height: 38px;
    }
}
</style>
