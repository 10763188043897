export class ReadOnlyMap<T extends Record<string, unknown>> {
    _object: Readonly<T>
    _entries
    get<K extends keyof T>(key: K): Readonly<T[K]> {
        return this._object[key]
    }

    constructor(obj: T) {
        this._object = obj
        this._entries = Object.entries(this._object)
    }

    [Symbol.iterator](): IterableIterator<[keyof T, T[keyof T]]> {
        return this._entries.values()
    }
}