import { Vector } from "sat"
import { ObjectPool, ObjectPoolTyped, PoolableObject } from "../../utils/third-party/object-pool"
import { Pet, PetCollectionName } from "./pet"
import { createNewPet } from "./pet-util"
import { ComponentOwner } from "../../engine/component-owner"
import { GameState, getNID } from "../../engine/game-state"
import { EntityType, IEntity } from "../entity-interfaces"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { distanceSquaredVV, vecLerp } from "../../utils/math"
import { AllWeaponTypes } from "../../weapons/weapon-types"

export interface PooledPetParams {
    lerpFromPos: Vector
    lerpToPos: Vector
    lerpTime: timeInSeconds

    activeDuration: timeInSeconds
}

const VANISH_DIST_2_FROM_PLAYER = 2_900 * 2_900

const EXIT_SCREEN_SPEED = 750

export class PooledPet implements PoolableObject, ComponentOwner, IEntity {
    entityType: EntityType = EntityType.PetWrapper
    nid: number
	timeScale: number = 1

    pet: Pet
    position: Vector

    originPos: Vector
    endPos: Vector
    differenceVec: Vector
    exitDirection: Vector

    lerpTime: timeInSeconds

    activeDuration: timeInSeconds

    timeInState: timeInSeconds

    isLerpingToPos: boolean
    isLeavingScreen: boolean

    pool: ObjectPool

    private useDoubleAttack: boolean
    private followPetOnActive: boolean
    private attacksSeen: number
    private wasAttacking: boolean

    constructor(petName: PetCollectionName) {
        this.nid = getNID(this)
        this.position = new Vector()

        this.pet = createNewPet(petName, new Vector())
        this.pet.releaseFromCage(true, true)
        this.pet.removeFromScene()
        this.pet.removeCooldownGfx()

        this.pet.followTarget = this
        this.pet.blockAbility = true
        this.pet.targetDistance = 0
        this.pet.dampedLerp = false

        this.originPos = new Vector()
        this.endPos = new Vector()
        this.exitDirection = new Vector()
        this.differenceVec = new Vector()

        this.followPetOnActive = (petName === 'pet-cat') 
    }

    setDefaultValues(defaultValues: any, overrideValues?: PooledPetParams) {
        if (overrideValues) {
            this.position.copy(overrideValues.lerpFromPos)
            this.originPos.copy(overrideValues.lerpFromPos)
            this.endPos.copy(overrideValues.lerpToPos)

            this.differenceVec.x = overrideValues.lerpToPos.x - overrideValues.lerpFromPos.x
            this.differenceVec.y = overrideValues.lerpToPos.y - overrideValues.lerpFromPos.y

            this.lerpTime = overrideValues.lerpTime
            this.activeDuration = overrideValues.activeDuration

            this.timeInState = 0

            this.exitDirection.x = overrideValues.lerpFromPos.x - overrideValues.lerpToPos.x
            this.exitDirection.y = overrideValues.lerpFromPos.y - overrideValues.lerpToPos.y
            this.exitDirection.normalize()

            this.pet.blockAbility = true
            this.pet.position.copy(overrideValues.lerpFromPos)
            this.pet.addToScene()
			this.pet.showCooldownBar = false

            this.isLerpingToPos = true
            this.isLeavingScreen = false
            this.useDoubleAttack = GameState.player.binaryFlags.has('pet-double-attack')
            this.attacksSeen = 0

            GameState.addEntity(this)
        }
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds) {
        this.timeInState += delta

        if (this.isLerpingToPos) {
            let lerpPercent = Math.clamp(this.timeInState / this.lerpTime, 0, 1)
            if (lerpPercent >= 1) {
                this.isLerpingToPos = false
                this.timeInState = 0

                this.position.x = this.endPos.x
                this.position.y = this.endPos.y
                this.pet.blockAbility = false
                this.pet.refreshCooldown()
                this.pet.update(0)
                this.wasAttacking = true
                this.attacksSeen = 1
            } else {
                this.position.x = this.originPos.x + (this.differenceVec.x / lerpPercent)
                this.position.y = this.originPos.y + (this.differenceVec.y / lerpPercent)
            }
        } else if (this.isLeavingScreen) {
            this.position.x += this.exitDirection.x * EXIT_SCREEN_SPEED * delta
            this.position.y += this.exitDirection.y * EXIT_SCREEN_SPEED * delta

            const distToPlayer = distanceSquaredVV(this.position, GameState.player.position)
            if (distToPlayer >= VANISH_DIST_2_FROM_PLAYER) {
                // goodbye
                this.pool.free(this)
            }
        } else {
            // we're active at the spot
            if (this.followPetOnActive) {
                this.position.copy(this.pet.position)
            }
            
            if (this.pet.usingAbility && !this.wasAttacking) {
                this.wasAttacking = true
                this.attacksSeen++
            } else if (!this.pet.usingAbility) {
                this.wasAttacking = false
            }

            if (this.timeInState >= this.activeDuration && !this.pet.usingAbility && (!this.useDoubleAttack || this.attacksSeen > 1)) {
                this.isLeavingScreen = true
                this.timeInState = 0
                this.pet.blockAbility = true
            }
        }
    }

    cleanup() {
        GameState.removeEntity(this)
        this.pet.removeFromScene()
    }
}
