<template>
	<div class="main-container">
		<div class="icon">
			<SquareItem :item="{ icon: this.skillIcon }" :goldtrimEnabled="false" :disabled="false"></SquareItem>
			<svg viewBox="0 0 32 32" :class="{ cooldown: nextCooldown > nowTimestamp }">
				<circle class="slice" :stroke-dasharray="fillPercent" r="16" cx="16" cy="16"></circle>
			</svg>
			<div class="cooldown-text-container">
				<div class="text timer-text" :lang="this.$i18n.locale">
					{{ cooldownTime }}
				</div>
			</div>
		</div>
		<div class="pip-container">
			<div v-for="i in maxAmmo" :key="i" class="pip-column">
				<Pip :class="i <= ammo ? 'selected' : ''"></Pip>
			</div>
		</div>
		<div class="cooldown-icon-header" :class="this.getSelectedMap" :lang="this.$i18n.locale">{{ getSkillBinding }}</div>
	</div>
</template>

<script lang="ts">
import { mapState, mapGetters } from 'vuex'
import SquareItem from '../ui-components/item/square-items.vue'
import Pip from '../ui-components/pips/pip.vue'
import { MapOption } from '../../world-generation/world-data'
import { keycodeToCharMixin } from './mixins/KeycodeToChar'

export default {
	name: 'CooldownIndicator',
	components: {
		SquareItem,
		Pip,
	},
	props: {},
	computed: {
		...mapState('player', ['ammo', 'maxAmmo', 'nextCooldown', 'nextRecharge', 'cooldownSetAt', 'rechargeSetAt', 'skillWeaponType', 'skillIcon']),
		...mapState('ui', ['nowTimestamp', 'selectedMap']),
		...mapGetters('characterSelect', ['selectedCharacter']),
		...mapGetters('settings', ['getSelectedInputDeviceId']),
		...mapState('settings', ['currentSettings']),

		cooldownTime() {
			const seconds = Math.round(this.getRemainingTime().timeRemaining / 1000)
			const readyText = this.ammo > 0 || seconds == 0 ? '' : `${Math.round(seconds)}`

			return `${readyText}`
		},
		cooldownAmmo() {
			return `${this.ammo}/${this.maxAmmo}`
		},
		fillPercent() {
			const diff = this.getRemainingTime()
			let percent = diff.duration ? (diff.timeRemaining / diff.duration) * 100 : 0
			return this.ammo > 0 && this.nextCooldown <= this.nowTimestamp ? `0 100` : `${percent} 100`
		},
		getSelectedMap() {
			if (this.selectedMap === MapOption.Tundra) {
				return 'mountain-color'
			} else {
				return 'forest-color'
			}
		},
		getSkillBinding() {
			let skillBinding = ''
			if (this.getSelectedInputDeviceId === 'keyboard') {
				skillBinding = this.keyCodeToChar(this.currentSettings.keyboardMappings['skill'])
			} else {
				// TODO: show the actual controller mapping if/when we can set it in-game
				skillBinding = 'Skill'
			}
			return skillBinding
		}
	},
	mixins: [keycodeToCharMixin],
	methods: {
		getRemainingTime() {
			let timeRemaining = 0
			let duration = 0
			if (this.nextCooldown > this.nowTimestamp) {
				timeRemaining = this.nextCooldown - this.nowTimestamp
				duration = this.nextCooldown - this.cooldownSetAt
			} else if (this.nextRecharge > this.nowTimestamp) {
				timeRemaining = this.nextRecharge - this.nowTimestamp
				duration = this.nextRecharge - this.rechargeSetAt
			}
			return {
				timeRemaining,
				duration,
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.main-container {
	width: 109px;
	height: 105px;
	position: relative;
	.icon {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: black;
		overflow: hidden;
		transform: scale(0.75);
		transform-origin: top left;
		:deep(.square-item) {
			margin: 0 0px;
		}

		.cooldown-text-container {
			position: absolute;
			display: flex;
			flex-direction: column;
			.text {
				z-index: 1;
				text-align: center;
				color: @cream;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 24px;
					letter-spacing: 2px;
				}

				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				&.timer-text {
					font-size: 60px;
					position: absolute;
					top: -25px;
					align-self: center;
				}
				&.ammo-text {
					position: absolute;
					left: 25;
					top: 35;
				}
			}
		}
		.cooldown-fill {
			position: absolute;
			border-radius: 50%;
			width: 185px;
			height: 170px;
			transform-origin: center center;
			transform: translateX(-3px) translateY(0px);
		}
		svg {
			width: 185px;
			border-radius: 50%;
			background: transparent;
			transform: rotate(-270deg) scaleX(-1);
			position: absolute;
			left: -22px;
			top: -22px;

			&.cooldown {
				background-color: black;
				opacity: 0.5;
			}
		}

		circle {
			fill: none;
			stroke-width: 32;
		}

		.slice {
			stroke: gray;
			opacity: 0.5;
		}
	}
	.pip-container {
		position: absolute;
		top: 0px;
		bottom:0px;
		margin: auto 0px;
		left: -40px;
		height: 105px;
		display: flex;
		flex-flow: column wrap-reverse;
		align-content: flex-start;
		transform: scale(0.75);
		transform-origin: top right;
		.pip-column {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-content: flex-start;
		}
	}
	.cooldown-icon-header {
		position: absolute;
		top: -30px;
		width: 109px; // We're setting the width of this container to stop it from ever going wider, and also so we can text center it
		text-align: center;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			color: @cream;
			font-size: 30px;
			font-variant: all-small-caps;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}

		&.forest-color{
			color: @cream;
		}
		&.mountain-color{
			color: @green;
		}
	}
}
</style>
