import { Loader, LoaderResource } from 'pixi.js'
import { reduce } from 'lodash'
import logger from '../utils/client-logger'
import { debugConfig } from '../utils/debug-config'
import { Effect } from '../engine/graphics/pfx/effect'
import { EffectConfig } from '../engine/graphics/pfx/effectConfig'
import { ModelPaths } from '../spine-config/model-paths'
import { propConfigs } from '../entities/prop-config'

type AssetLoadingPhase = 1 | 2
export type AssetIdentifier = string
type AssetPath = string

interface AssetDefinition {
	name: AssetIdentifier
	path: AssetPath
	numberOfTransitiveAssets: number
	phase: AssetLoadingPhase
	dependsOnSpineAtlas?: AssetIdentifier
}

type WeaponPFXIdentifier = 'sword' | 'staff' | 'wand' | 'crossbow' | 'arcane-focus' | 'scythe'
type ElementalIdentifier = 'physical' | 'fire' | 'ice' | 'lightning' | 'poison'

const MAX_RETRY_ATTEMPTS = 10

export const LOADABLE_WEAPON_PFX: WeaponPFXIdentifier[] = ['sword', 'staff', 'wand', 'crossbow', 'arcane-focus', 'scythe']
export const LOADABLE_ELEMENTS: ElementalIdentifier[] = ['physical', 'fire', 'ice', 'lightning', 'poison']

// Static assets
const assetList: AssetDefinition[] = [
	// map assets
	{
		name: `test-grass`,
		path: `./ground/test-grass-big-tile.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `grass-tile`,
		path: `./ground/grass-tile.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `forest-ground`,
		path: `./ground/forest-ground.json`,
		numberOfTransitiveAssets: 1,
		phase: 1,
	},
	{
		name: `cursed-tile`,
		path: `./ground/cursed-tile.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `cursed-ground`,
		path: `./ground/cursed-ground.json`,
		numberOfTransitiveAssets: 1,
		phase: 1,
	},
	{
		name: `forest-props`,
		path: `./sprites/props/forest-props/forest-props.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'barrel',
		path: './sprites/props/forest-props/barrel.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'lantern',
		path: './sprites/props/forest-props/lantern.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'skeleton',
		path: './sprites/props/forest-props/skeleton.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'tanning-rack',
		path: './sprites/props/forest-props/tanning-rack.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'flower-pot',
		path: './sprites/props/forest-props/flower-pot.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'papyrus-plant',
		path: './sprites/props/forest-props/papyrus-plant.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'magnet-pinecone',
		path: './sprites/props/forest-props/magnet-pinecone.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'chest',
		path: './sprites/props/forest-props/chest.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'fungi-props', //visual separation for fungi assets
		path: './sprites/props/fungi-props/fungi-props.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'enchanted-stone',
		path: './sprites/props/fungi-props/enchanted-stone.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'fungi-lantern-post',
		path: './sprites/props/fungi-props/fungi-lantern-post.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'skull',
		path: './sprites/props/fungi-props/skull.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'log',
		path: './sprites/props/fungi-props/log.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'fungi-skeleton',
		path: './sprites/props/fungi-props/fungi-skeleton.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'fungi-grass-tile',
		path: './ground/fungi-grass-tile.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'fungi-ground',
		path: './ground/fungi-ground.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hollow-grass-tile', //visual separation for hollow assets
		path: './ground/hollow-grass-tile.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hollow-ground',
		path: './ground/hollow-ground.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'banner',
		path: './sprites/props/hollow-props/banner.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'cursed-gem',
		path: './sprites/props/hollow-props/cursed-gem.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'cursed-tome',
		path: './sprites/props/hollow-props/cursed-tome.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'fire-pit',
		path: './sprites/props/hollow-props/fire-pit.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'stone-eyebat',
		path: './sprites/props/hollow-props/stone-eyebat.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hollow-props',
		path: './sprites/props/hollow-props/hollow-props.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'tundra-ground-tile', //visual separation for tundra assets
		path: './ground/tundra-ground-tile.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'tundra-ground',
		path: './ground/tundra-ground.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'tundra-props',
		path: './sprites/props/tundra-props/tundra-props.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'giants-axe',
		path: './sprites/props/tundra-props/giants-axe.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'giants-shield',
		path: './sprites/props/tundra-props/giants-shield.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'giants-sword',
		path: './sprites/props/tundra-props/giants-sword.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'icy-grave',
		path: './sprites/props/tundra-props/icy-grave.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'icy-flag',
		path: './sprites/props/tundra-props/icy-flag.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},

	// Ambient
	{
		name: 'vignetteTopLeftCorner',
		path: './sprites/lighting/vignette-top-left-corner.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `god-rays`,
		path: `./sprites/lighting/god-rays.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `fog`,
		path: `./sprites/lighting/fog.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	// random
	{
		name: `pickup-icons`, 
		path: `./sprites/ground-items/ground-items.json`,
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'rat-parade-rats',
		path: `./sprites/weapons/rat-parade/rat-parade.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'lightning-strike',
		path: `./sprites/weapons/lightning-strike/lightning.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'dark-stormy-night',
		path: `./sprites/weapons/dark-and-stormy-night/dark-and-stormy-night.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ranger-trap',
		path: './sprites/traps/ranger-trap/ranger-trap.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ranger-trap-vine-01',
		path: './sprites/traps/ranger-trap/ranger-trap-vine-01.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ranger-trap-vine-02',
		path: './sprites/traps/ranger-trap/ranger-trap-vine-02.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ranger-trap-crystal-01',
		path: './sprites/traps/ranger-trap/crystal-01.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ranger-trap-crystal-02',
		path: './sprites/traps/ranger-trap/crystal-02.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},

	//enemies
	{
		name: `blimpie-01-spritesheet`, // Yellow
		path: `./sprites/enemies/blimpie/blimpie-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `blimpie-02-spritesheet`, // Green
		path: `./sprites/enemies/blimpie/blimpie-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `blimpie-03-spritesheet`, // Red
		path: `./sprites/enemies/blimpie/blimpie-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `crab-spritesheet`,
		path: `./sprites/enemies/crab-sprite/crab.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `chaos-seer-boss-spritesheet`,
		path: `./sprites/enemies/chaos-seer-boss/chaos-seer-boss.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `crystal-spider-01-spritesheet`, // Red, small crystal
		path: `./sprites/enemies/crystal-spider/crystal-spider-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `crystal-spider-02-spritesheet`, // Red, med crystal
		path: `./sprites/enemies/crystal-spider/crystal-spider-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `crystal-spider-03-spritesheet`, // Red, full crystal
		path: `./sprites/enemies/crystal-spider/crystal-spider-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `crystal-spider-04-spritesheet`, // Green, full crystal
		path: `./sprites/enemies/crystal-spider/crystal-spider-04.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `prism-fly-boss-spritesheet`,
		path: `./sprites/enemies/prism-fly-boss/prism-fly-boss.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `prism-fly-01-spritesheet`, // Green
		path: `./sprites/enemies/prismfly/prism-fly-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `prism-fly-02-spritesheet`, // Red
		path: `./sprites/enemies/prismfly/prism-fly-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shambling-mound-01-spritesheet`, // Yellow
		path: `./sprites/enemies/shambling-mounds/shambling-mound-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shambling-mound-02-spritesheet`, // Yellow-orange
		path: `./sprites/enemies/shambling-mounds/shambling-mound-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shambling-mound-03-spritesheet`, // Orange
		path: `./sprites/enemies/shambling-mounds/shambling-mound-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shambling-mound-04-spritesheet`, //Red-orange
		path: `./sprites/enemies/shambling-mounds/shambling-mound-04.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shambling-mound-05-spritesheet`, // Red
		path: `./sprites/enemies/shambling-mounds/shambling-mound-05.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shambling-tower-spritesheet`,
		path: `./sprites/enemies/shambling-tower/shambling-tower.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `goblin-jester-spritesheet`,
		path: `./sprites/enemies/goblin-hat/goblin-hat.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `eyebat-spritesheet`, // OG Purple, not used
		path: `./sprites/enemies/eyebat/eyebat.json`, 
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `eyebat-01-spritesheet`, // Green
		path: `./sprites/enemies/eyebat/eye-bat-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `eyebat-02-spritesheet`, // Red
		path: `./sprites/enemies/eyebat/eye-bat-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `hermit-crab-spritesheet`,
		path: `./sprites/enemies/hermit-crab/hermit-crab.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mosquito-spritesheet`, // OG, not used
		path: `./sprites/enemies/mosquito/mosquito-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mosquito-01-spritesheet`,  // Yellow
		path: `./sprites/enemies/mosquito/mosquito-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mosquito-02-spritesheet`, // Red
		path: `./sprites/enemies/mosquito/mosquito-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mosquito-03-spritesheet`, // Green
		path: `./sprites/enemies/mosquito/mosquito-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mushie-spritesheet`, // OG not used
		path: `./sprites/enemies/mushie/mushie.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mushie-01-spritesheet`, // Red
		path: `./sprites/enemies/mushie/mushie-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mushie-02-spritesheet`, // Red w bumps
		path: `./sprites/enemies/mushie/mushie-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mushie-03-spritesheet`, // Green
		path: `./sprites/enemies/mushie/mushie-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shrieker-spritesheet`, // Red
		path: `./sprites/enemies/shriekers/shrieker.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shrieker-vines-spritesheet`, // Red with arms
		path: `./sprites/enemies/shriekers/shrieker-vines.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shrieker-knight-spritesheet`, // Red with helmet, no plume
		path: `./sprites/enemies/shriekers/shrieker-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shrieker-knight-red-plume-spritesheet`,
		path: `./sprites/enemies/shriekers/shrieker-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shrieker-knight-blue-plume-spritesheet`,
		path: `./sprites/enemies/shriekers/shrieker-03-blue.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shrieker-knight-blue-spear-spritesheet`,
		path: `./sprites/enemies/shriekers/shrieker-04.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `shrieker-knight-red-spear-spritesheet`,
		path: `./sprites/enemies/shriekers/shrieker-04-red.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `skeleton-mage-01-spritesheet`, // Green with lantern
		path: `./sprites/enemies/skeleton-mage/skeleton-mage-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `skeleton-mage-02-spritesheet`, // Green and light green with lantern
		path: `./sprites/enemies/skeleton-mage/skeleton-mage-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `skeleton-mage-03-spritesheet`, // Red with staff
		path: `./sprites/enemies/skeleton-mage/skeleton-mage-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `spider-01-spritesheet`, // Yellow
		path: `./sprites/enemies/spider/spider-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `spider-02-spritesheet`, // Red
		path: `./sprites/enemies/spider/spider-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `wasp-01-spritesheet`, // Yellow
		path: `./sprites/enemies/wasps/wasp-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `wasp-02-spritesheet`, // Red
		path: `./sprites/enemies/wasps/wasp-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `wasp-03-spritesheet`, // Red with mandibles
		path: `./sprites/enemies/wasps/wasp-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `wasp-bees-upgrade-spritesheet`, // Wasp with hat and knife
		path: `./sprites/enemies/wasps/wasp-bees-upgrade.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `wisp-01-spritesheet`, // Green
		path: `./sprites/enemies/wisps/wisp-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `wisp-02-spritesheet`, // Red
		path: `./sprites/enemies/wisps/wisp-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `snow-fury-blue-spritesheet`, // Blue
		path: `./sprites/enemies/snow-fury/snow-fury-blue.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `snow-ball-spritesheet`,
		path: `./sprites/enemies/snow-ball/snow-ball.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `snow-fury-green-spritesheet`, // Green
		path: `./sprites/enemies/snow-fury/snow-fury-green.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `ice-elemental-spritesheet`,
		path: `./sprites/enemies/ice-elemental/ice-elemental.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `snow-fury-pink-spritesheet`, // Pink
		path: `./sprites/enemies/snow-fury/snow-fury-pink.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `ice-elemental-02-spritesheet`,
		path: `./sprites/enemies/ice-elemental/ice-elemental-cracked.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `winter-golblin-01-spritesheet`, // white
		path: `./sprites/enemies/winter-goblin/winter-goblin-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `winter-golblin-02-spritesheet`, // two tone white/maroon
		path: `./sprites/enemies/winter-goblin/winter-goblin-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `winter-golblin-03-spritesheet`, // maroon
		path: `./sprites/enemies/winter-goblin/winter-goblin-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `winter-golblin-04-spritesheet`, // brown
		path: `./sprites/enemies/winter-goblin/winter-goblin-04.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `winter-golblin-05-spritesheet`, // orange
		path: `./sprites/enemies/winter-goblin/winter-goblin-05.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `eyesicle-01-spritesheet`,
		path: `./sprites/enemies/eyesicle/eyesicle-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `eyesicle-02-spritesheet`,
		path: `./sprites/enemies/eyesicle/eyesicle-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `mr-cuddles`,
		path: `./sprites/enemies/mr-cuddles/mr-cuddles.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},

	//random cont.
	{
		name: `mr-cuddles-swipe-pfx`,
		path: `./sprites/enemy-effects/mr-cuddles-swipe-pfx/swipe-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `bee-spritesheet`, // Regular bee
		path: `./sprites/weapons/bees/bee.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `queen-bee-spritesheet`, // Bee with crown
		path: `./sprites/weapons/bees/queen-bee.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `poison-bee-spritesheet`, // Green bee
		path: `./sprites/weapons/bees/poison-bee.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `poison-queen-bee-spritesheet`, // Green bee with crown
		path: `./sprites/weapons/bees/poison-queen-bee.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `phoenix-bombs-spritesheet`,
		path: `./sprites/weapons/phoenix-bombs/phoenix-bombs.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},

	//pets
	{
		name: `pet-plant-spritesheet`,
		path: `./sprites/pets/pet-plant-leafy-red.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `pet-horse-spritesheet`,
		path: `./sprites/pets/pet-horse-gilded-scion-pegasus.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `pet-cat-spritesheet`,
		path: `./sprites/pets/pet-cat-spotted-tabby.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `pet-crystal-spritesheet`,
		path: `./sprites/pets/pet-crystal.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `pet-rot-son-spritesheet`,
		path: `./sprites/pets/pet-rot-son.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `pet-rainbow-plant-spritesheet`,
		path: `./sprites/pets/pet-charlotte-rainbow-plant.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `pet-sea-turtle-spritesheet`,
		path: `./sprites/pets/pet-sea-turtle.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'magic-dust-blue',
		path: './pfx/pets/editor-pfx/magic-dust-blue.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'magic-dust-green',
		path: './pfx/pets/editor-pfx/magic-dust-green.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'magic-dust-red',
		path: './pfx/pets/editor-pfx/magic-dust-red.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'navigation_arrow',
		path: './sprites/misc/tutorial-arrow-2.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'pet-ransom-arrow',
		path: './sprites/misc/pet-ransom-arrow.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'pet-ransom-icon',
		path: './sprites/misc/pet-ransom-icon.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	
	// Old map Shelby Made
	{
		name: 'map',
		path: './sprites/misc/map.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'pet-cage',
		path: './sprites/pets/pet-cage.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'damage-numbers',
		path: './pfx/damage-numbers/damage-numbers.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `elemental-maelstrom`,
		path: `./sprites/props/elemental-maelstrom.json`,
		numberOfTransitiveAssets: 1,
		phase: 1,
	},

	// Generic Map Used for navigation
	{
		name: 'map_icon',
		path: './sprites/misc/map_icon.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'enemy-projectile-01',
		path: './pfx/enemy-projectiles/enemy-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'enemy-projectile-trail',
		path: './pfx/enemy-projectiles/enemy-projectile-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `prism-aoe`,
		path: `./sprites/enemy-hazards/prism-aoe.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'smoke-explosion',
		path: './pfx/despawn-poof/smoke-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'enemy-death-explosion',
		path: './pfx/despawn-poof/enemy-death-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'ice-drake-summon',
		path: './pfx/misc/summon-furies-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `tesla-coil-prop`,
		path: `./sprites/weapons/tesla-coil/tesla-coil.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `arc-lightning-1`,
		path: `./sprites/weapons/tesla-coil/arc-lightning-1.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `arc-lightning-2`,
		path: `./sprites/weapons/tesla-coil/arc-lightning-2.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `arc-lightning-3`,
		path: `./sprites/weapons/tesla-coil/arc-lightning-3.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `tesla-coil-range-circle`,
		path: `./sprites/weapons/tesla-coil/tesla-coil-range-circle.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'nikola-beam-start',
		path: './pfx/beam/nikola-beam-start.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'nikola-beam-center',
		path: './pfx/beam/nikola-beam-center.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'nikola-pre-fire-beam',
		path: './pfx/beam/nikola-pre-fire-beam.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'nikola-beam-end',
		path: './pfx/beam/nikola-beam-end.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pulse-shock',
		path: './sprites/weapons/pulse-shock/pulse-shock.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'radar-passives',
		path: './sprites/weapons/radar-passives/radar-passives.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'supply-crate',
		path: './sprites/weapons/supply-crate/supply-crate.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'supply-crate-shadow',
		path: './sprites/weapons/supply-crate/supply-crate-shadow.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-boomerang-projectile-01',
		path: './pfx/weapon-projectiles/boomerang/player-boomerang-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'bonus-player-boomerang-shark-projectile-01',
		path: './pfx/weapon-projectiles/boomerang/bonus-player-boomerang-shark-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-outback-projectile-01',
		path: './pfx/weapon-projectiles/boomerang/player-outback-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-chakram-projectile-01',
		path: './pfx/weapon-projectiles/boomerang/player-chakram-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-boomerang-flame-projectile-01',
		path: './pfx/weapon-projectiles/boomerang/player-boomerang-flame-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'burnarang-flame-burst-explosion',
		path: './pfx/weapon-projectiles/boomerang/burnarang-flame-burst-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-crossbow-projectile-01',
		path: './pfx/weapon-projectiles/crossbow/player-crossbow-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-ricochetrang-projectile-01',
		path: './pfx/weapon-projectiles/boomerang/player-ricochetrang-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-ricochetrang-projectile-02',
		path: './pfx/weapon-projectiles/boomerang/player-ricochetrang-projectile-02.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-scythe-projectile-01',
		path: './pfx/weapon-projectiles/scythe/player-scythe-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-wand-projectile-01',
		path: './pfx/weapon-projectiles/wand/player-wand-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'weapon-projectile-trail-fire',
		path: './pfx/trails/weapon-projectile-trail-fire.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'weapon-projectile-trail-ice',
		path: './pfx/trails/weapon-projectile-trail-ice.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'weapon-projectile-trail-poison',
		path: './pfx/trails/weapon-projectile-trail-poison.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'ranger-mark',
		path: './sprites/weapons/temp-ranger-mark.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'bite-pfx',
		path: './sprites/weapons/bite-pfx/bite-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-thornwolf-head',
		path: './pfx/player_projectile/projectile-thornwolf-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'spear-hit',
		path: './sprites/weapons/spear-fake-pfx/spear-hit.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'spear-faded-hit',
		path: './sprites/weapons/spear-fake-pfx/spear-faded-hit.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'spear-dash',
		path: './sprites/weapons/spear-fake-pfx/spear-dash.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'spear-holy-light-aoe',
		path: './sprites/weapons/spear-holy-light/holy-light-aoe.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
  {
		name: 'gravity-pin-down',
		path: './sprites/weapons/bow-gravity-pin-down/gravity-pin-down.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'longbow-pfx',
		path: './sprites/weapons/longbow-pfx/longbow-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'twice-dead-pfx',
		path: './pfx/misc/twice-dead-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'spectral-farmer-planted-pfx',
		path: './pfx/misc/spectral-farmer-planted-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'spectral-hoe',
		path: './sprites/misc/spectral-hoe.json',
		numberOfTransitiveAssets: 1,
		phase: 2,
	},
	{
		name: 'temporal-distortion-crystal',
		path: './sprites/props/temporal-distortion-crystals.png',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'temporal-distortion-crystal-glow',
		path: './sprites/props/temporal-distortion-crystals-glow.png',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'temporal-distortion-hourglass',
		path: './sprites/props/temporal-distortion-hourglass.png',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'temporal-distortion-hourglass-glow',
		path: './sprites/props/temporal-distortion-hourglass-glow.png',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'destructible-props-fx',
		path: './pfx/misc/destructible-props-fx.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'stun-shrapnel-projectile',
		path: './pfx/weapon-projectiles/bow/stun-shrapnel-projectile.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'dreamer-projectile',
		path: './pfx/enemy-projectiles/dreamer-projectile.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'ice-drake-projectile',
		path: './pfx/enemy-projectiles/everfrost-breath-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'eyebat-projectile',
		path: './pfx/enemy-projectiles/eyebat-projectile.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'gugnir-blood-projectile',
		path: './pfx/weapon-projectiles/spear/gugnir-blood-projectile.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'gungnir-projectile',
		path: './pfx/weapon-projectiles/spear/gungnir-projectile.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'spear-projectile',
		path: './pfx/weapon-projectiles/spear/spear-projectile.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'stormbreaker-charge',
		path: './pfx/charge/stormbreaker-charge.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'solara-lunar-blow',
		path: './pfx/misc/solara-lunar-blow.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'solara-solar-flare',
		path: './pfx/misc/solara-solar-flare.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'solara-transformation',
		path: './pfx/misc/solara-transformation.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'moon-soul',
		path: './pfx/misc/moon-soul.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'fire-fairy-explosion',
		path: './pfx/weapon-projectiles/fire-fairy/fire-fairy-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'fire-fairy-projectile',
		path: './pfx/weapon-projectiles/fire-fairy/fire-fairy-projectile.json',
		numberOfTransitiveAssets: 0,
		phase: 2
	},
	{
		name: 'fire-fairy-dance',
		path: './sprites/weapons/fire-fairy/fairy-dance.json',
		numberOfTransitiveAssets: 1,
		phase: 2
	},
	{
		name: 'fire-fairy-flames-forever',
		path: './sprites/weapons/fire-fairy/fairy-flames-forever.json',
		numberOfTransitiveAssets: 1,
		phase: 2
	},
	{
		name: 'fire-fairy-pool',
		path: './sprites/weapons/fire-fairy/fiery-pool.json',
		numberOfTransitiveAssets: 1,
		phase: 2
	},
	{
		name: 'fire-fairy-base',
		path: './sprites/weapons/fire-fairy/fire-fairy.json',
		numberOfTransitiveAssets: 1,
		phase: 2
	},
	{
		name: 'fire-fairy-skyfire',
		path: './sprites/weapons/fire-fairy/skyfire-fairy.json',
		numberOfTransitiveAssets: 1,
		phase: 2
	},

	// trails
	{
		name: 'dust',
		path: './pfx/dust/dust.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'quick-feet',
		path: './pfx/dust/quick-feet.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	// status effects
	{
		name: 'status-ignite',
		path: './pfx/status-effects/fire_status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-bleed',
		path: './pfx/status-effects/bleed-status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-chill',
		path: './pfx/status-effects/ice_status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-poison',
		path: './pfx/status-effects/poison.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-shock',
		path: './pfx/status-effects/lightning_status_subtle.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-stun',
		path: './pfx/status-effects/lightning_status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-doom',
		path: './pfx/explosion_doom/doom-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-doom-big',
		path: './pfx/explosion_doom/big-doom-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-battle-cry',
		path: './pfx/status-effects/status-battle-cry.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-overshield',
		path: './pfx/status-effects/status-overshield.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-berserking',
		path: './pfx/status-effects/status-berserking.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-holy-light',
		path: './pfx/status-effects/status-holy-light.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `poison-bottle`,
		path: `./sprites/weapons/poison-bottle/poison-bottle.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `poison-ground-hazard`,
		path: `./sprites/weapons/poison-bottle/poison-aoe.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `aoe-explosion-white`,
		path: `./pfx/aoe-explosion/aoe-explosion-white.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `plot-twist-explosion`,
		path: `./pfx/misc/plot-twist-explosion.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `explosion-warning`,
		path: `./pfx/misc/explosion-warning.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `aether-magnet-pfx`,
		path: `./pfx/misc/aether-magnet-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `split-personality-pfx`,
		path: `./pfx/misc/split-personality-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `lute-jam-pfx`,
		path: `./pfx/lute/lute-jam-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `lute-pfx`,
		path: `./pfx/lute/lute-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'barbarian-aura-pfx',
		path: './pfx/misc/barbarian-rage-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	{
		name: `arrow-fill`,
		path: `./sprites/weapons/lute/arrow-fill.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `arrow-glow`,
		path: `./sprites/weapons/lute/arrow-glow.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `arrow-preview`,
		path: `./sprites/weapons/lute/arrow-preview.png`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'health-container-full',
		path: './ui/health-containers/heart-container-full.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'health-container-half',
		path: './ui/health-containers/heart-container-half.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'health-container-empty',
		path: './ui/health-containers/heart-container-empty.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ammo-full-gold',
		path: './ui/hud/ammo-full-gold.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ammo-full-green',
		path: './ui/hud/ammo-full-green.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ammo-full-red',
		path: './ui/hud/ammo-full-red.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ammo-full-blue',
		path: './ui/hud/ammo-full-blue.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ammo-full-pink',
		path: './ui/hud/ammo-full-pink.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ammo-empty',
		path: './ui/hud/ammo-empty.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'reload-base',
		path: './ui/reload-circle/reload-base.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'reload-fill',
		path: './ui/reload-circle/reload-fill.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'shrine-brick-01',
		path: './sprites/props/shrine-bricks-asset-01.png',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	{
		name: 'shrine-brick-02',
		path: './sprites/props/shrine-bricks-asset-02.png',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	{
		name: 'shrine-chain',
		path: './sprites/props/shrine-chain-asset.png',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	{
		name: 'shrine-glow-brick-01',
		path: './sprites/props/shrine-glow-bricks-asset-01.png',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	{
		name: 'shrine-glow-brick-02',
		path: './sprites/props/shrine-glow-bricks-asset-02.png',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	{
		name: 'shrine-glow-chain',
		path: './sprites/props/shrine-glow-chain-asset.png',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	{
		name: 'little-plague-bearers-poison-pool',
		path: './sprites/props/little-plague-bearers-poison-pool.png',
		numberOfTransitiveAssets: 0,
		phase: 1
	},
	// Pet effects
	{
		name: 'pet-range-circle',
		path: './sprites/pets/pet-range-circle.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'pet-range-circle-glow',
		path: './sprites/pets/pet-range-circle-glow.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'pet-healing',
		path: './pfx/pets/editor-pfx/healing.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pet-stomp',
		path: './sprites/pets/spine-pfx/stomp-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'pet-narwhal-horn',
		path: `./pfx/pets/editor-pfx/narwhal-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pet-dragon-fireball',
		path: `./pfx/pets/editor-pfx/dragon-fireball-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'tsunami-pfx',
		path: `./pfx/pets/editor-pfx/tsunami-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'hearts-explosion',
		path: `./pfx/misc/hearts-explosion.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'merge-charge',
		path: `./pfx/misc/merge-charge.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'egg-drop',
		path: `./pfx/misc/egg-drop.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'confetti-explosion',
		path: `./pfx/misc/confetti-explosion.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'roaming-wildlings',
		path: `./pfx/misc/roaming-wildlings-pfx.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	
	//ambience
	{
		name: `town-ambience`,
		path: `./pfx/biome-ambience/town.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `forest-ambience`,
		path: `./pfx/map-ambience/forest-ambience.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `fungi-ambience`,
		path: `./pfx/map-ambience/fungi-ambience.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `hollow-ambience`,
		path: `./pfx/map-ambience/hollow-ambience.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `tundra-ambience`,
		path: `./pfx/map-ambience/tundra-ambience.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: `tundra-blizzard`,
		path: `./sprites/props/tundra-props/blizzard.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
]

for (const [name, path] of ModelPaths) {
	assetList.push({
		name,
		path,
		numberOfTransitiveAssets: 2,
		phase: 2,
	})
}

for (const name in propConfigs) {
	if (propConfigs.hasOwnProperty(name)) {
		const propConfig = propConfigs[name]
		if (propConfig.isSingleImage) {
			assetList.push({
				name: name,
				path: `./sprites/props/${name}.png`,
				numberOfTransitiveAssets: 0,
				phase: 1,
			})
		} else {
			if (!propConfig.dontLoadAsset && !propConfig.useSpriteSheet) {
				let atlas
				if (propConfig.overrideAtlas) {
					atlas = propConfig.overrideAtlas
				} else {
					atlas = propConfig.ignoreSharedAtlas ? undefined : `PropAtlas`
				}
				const numberOfTransitiveAssets = propConfig.ignoreSharedAtlas ? 2 : 0
				assetList.push({
					name: name,
					path: `./sprites/props/${name}.json`,
					numberOfTransitiveAssets,
					phase: 2,
					dependsOnSpineAtlas: atlas,
				})
			}
		}
	}
}

LOADABLE_WEAPON_PFX.forEach((weapon: WeaponPFXIdentifier) => {
	assetList.push({
		name: `${weapon}-projectile-01`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `${weapon}-projectile-02`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `${weapon}-projectile-03`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `${weapon}-projectile-04`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-04.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
})

LOADABLE_ELEMENTS.forEach((element: ElementalIdentifier) => {
	assetList.push({
		name: `${element}-projectile-trail`,
		path: `./pfx/trails/player-projectile-trail-${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})

	assetList.push({
		name: `explosion-${element}`,
		path: `./pfx/explosion_${element}/explosion_${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `hit-${element}`,
		path: `./pfx/hit_${element}/hit_${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `aoe-explosion-${element}`,
		path: `./pfx/aoe-explosion/aoe-explosion-${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})

	assetList.push({
		name: `projectile-shoot-${element}`,
		path: `./pfx/shoot/projectile-shoot-${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
})

assetList.push({
	name: 'combined',
	path: './pfx/combined/combined.json',
	numberOfTransitiveAssets: 0,
	phase: 1,
})

assetList.push({
	name: `charging`,
	path: `./pfx/charge/charge.json`,
	numberOfTransitiveAssets: 0,
	phase: 2,
})

export function getAllPfxDefinitions() {
	return assetList.filter(isPfxAssetDefinition)
}

const allPfxAssets = getAllPfxDefinitions()

export class AssetManager {
	static getInstance(loadingDoneCallback?: any): AssetManager {
		if (!AssetManager.instance) {
			AssetManager.instance = new AssetManager(loadingDoneCallback)
		}

		return AssetManager.instance
	}
	assets: AssetDefinition[] = assetList
	cacheAssetLoader: Loader
	firstPhaseAssetLoader: Loader
	secondPhaseAssetLoader: Loader
	loadedAssets: Map<string, LoaderResource>
	failedAssets: LoaderResource[]
	debugAssetAliases: Map<string, string> // map from alias->real
	assetsLoaded: number = 0
	numberOfAssetsToBeLoaded: number = 100
	percentageOfAssetsLoaded: number = 0
	retryAttempts: number = 0
	jsonCache
	loadingDoneCallback: any

	private static instance: AssetManager

	private constructor(loadingDoneCallback) {
		logger.info('Starting asset loading')

		this.cacheAssetLoader = new Loader()
		this.firstPhaseAssetLoader = new Loader()
		this.secondPhaseAssetLoader = new Loader()

		this.addCallbacksToLoader(this.cacheAssetLoader)
		this.addCallbacksToLoader(this.firstPhaseAssetLoader)
		this.addCallbacksToLoader(this.secondPhaseAssetLoader)

		this.loadedAssets = new Map<AssetIdentifier, LoaderResource>()
		this.failedAssets = []
		this.debugAssetAliases = new Map<string, string>()

		this.numberOfAssetsToBeLoaded = reduce(
			assetList,
			(tally, asset) => {
				return tally + asset.numberOfTransitiveAssets + 1
			},
			0,
		)

		this.numberOfAssetsToBeLoaded += allPfxAssets.length
		this.loadingDoneCallback = loadingDoneCallback

		logger.info(`Done counting assets, total # of assets to be loaded: ${this.numberOfAssetsToBeLoaded}`)

		this.populateJSONCache()
		this.loadFirstPhaseAssets(assetList)
		this.cacheAssetLoader.load(this.onCacheLoadCompletion.bind(this))
	}

	hasAssetByName(assetName: AssetIdentifier): boolean {
		const foundAsset = this.loadedAssets.get(assetName)
		return foundAsset !== undefined
	}

	getAssetByName(assetName: AssetIdentifier): LoaderResource {
		const foundAsset = this.loadedAssets.get(assetName)
		if (foundAsset === undefined) {
			throw new Error(`No loaded asset found by that identifier! ${assetName}`)
		} else {
			return foundAsset
		}
	}

	getAssetByNameAsync(assetName: AssetIdentifier, onLoaded: (asset: LoaderResource) => void) {
		const foundAsset = this.loadedAssets.get(assetName)

		if (foundAsset) {
			onLoaded(foundAsset)
		} else {
			const assetPath = null //OnDemandClientModelPaths.get(assetName)

			if (assetPath) {
				const onDemandLoader = new Loader()
				onDemandLoader.add(assetName, assetPath)
				onDemandLoader.load((_loader: Loader, resources: Partial<Record<string, LoaderResource>>) => {
					checkResourses(resources, console.error)
					this.addResources(resources)
					const foundAsset2 = this.loadedAssets.get(assetName)
					onLoaded(foundAsset2)
				})
			} else if (onLoaded) {
				throw new Error(`asset is being loaded on demand but there is no path defined in OnDemandClientModelPaths`)
			} else {
				throw new Error(`No loaded asset found by that identifier! ${assetName}`)
			}
		}
	}

	replaceAssetByNameOrAlias(assetName: AssetIdentifier, contents: any) {
		if (!this.hasAssetByName(assetName) && this.debugAssetAliases.has(assetName)) {
			assetName = this.debugAssetAliases.get(assetName)
		}
		const asset = this.getAssetByName(assetName)
		asset.data = contents
	}

	private cacheLookupCallback(resource: LoaderResource, next) {
		if (resource.name === 'jsonCache') {
			return next()
		}
		if (resource.extension === 'json' && this.jsonCache.hasOwnProperty(resource.url)) {
			resource.data = this.jsonCache[resource.url]
			resource.type = LoaderResource.TYPE.JSON
			resource.complete()
		}
		return next()
	}

	private addCallbacksToLoader(loader: Loader) {
		loader.onProgress.add(this.onLoadProgress.bind(this))
		loader.onError.add(this.onErrorCallback.bind(this))
		loader.onLoad.add(this.onLoadedCallback.bind(this))
		loader.pre(this.cacheLookupCallback.bind(this))
	}

	private populateJSONCache() {
		this.cacheAssetLoader.add('jsonCache', 'compiled.json')
	}

	private onCacheLoadCompletion(loader: Loader, resources: Partial<Record<string, LoaderResource>>): void {
		logger.info('Cache loading phase complete!')
		if (this.failedAssets.length > 0 || !resources.jsonCache.data) {
			// retry
			if (this.retryAttempts < MAX_RETRY_ATTEMPTS) {
				console.error(`Try to reload compiled.json`)
				this.retryAttempts++
				this.cacheAssetLoader.reset()
				this.cacheAssetLoader.add('jsonCache', 'compiled.json')
				this.cacheAssetLoader.load(this.onCacheLoadCompletion.bind(this))
				this.failedAssets.length = 0
			} else {
				// I guess we just die
				throw new Error(`Could not load compiled.json ; very bad! Maybe try refreshing the page if you're reading this and not a dev.`)
			}
		} else {
			this.retryAttempts = 0
			this.jsonCache = resources.jsonCache.data
			this.firstPhaseAssetLoader.load(this.onFirstPhaseLoadCompletion.bind(this))
		}
	}

	private loadFirstPhaseAssets(assets: AssetDefinition[]): void {
		for (const asset of assets) {
			const { name, path, phase, dependsOnSpineAtlas } = asset
			if (phase === 1) {
				if (debugConfig.assets.logEachAsset) {
					logger.debug('loading', name, path)
				}
				if (dependsOnSpineAtlas) {
					throw new Error('First phase assets cannot have dependencies')
				}
				this.firstPhaseAssetLoader.add(name, path)
			}
		}
	}

	private loadSecondPhaseAssets(assets: AssetDefinition[]): void {
		for (const asset of assets) {
			const { name, path, phase, dependsOnSpineAtlas } = asset
			if (phase === 2) {
				if (debugConfig.assets.logEachAsset) {
					logger.debug('loading', name, path)
				}
				if (dependsOnSpineAtlas) {
					const spineAtlas = this.loadedAssets.get(dependsOnSpineAtlas).spineAtlas
					const options = {
						metadata: {
							spineAtlas,
						},
					}
					this.secondPhaseAssetLoader.add(name, path, options)
				} else {
					this.secondPhaseAssetLoader.add(name, path)
				}
			}
		}
	}

	private onLoadProgress(_, resource: LoaderResource) {
		if (resource !== null) {
			checkResource(resource, console.warn)
		}

		this.assetsLoaded++
		const newPercentage = Math.floor((this.assetsLoaded / this.numberOfAssetsToBeLoaded) * 100)
		if (newPercentage > this.percentageOfAssetsLoaded) {
			this.percentageOfAssetsLoaded = newPercentage
		}
		// @TODO CAKES
		// UI.getInstance().emitEvent('mainMenu/loadingProgressPercentageUpdated', this.percentageOfAssetsLoaded)
	}

	private onFirstPhaseLoadCompletion(loader: Loader, resources: Partial<Record<string, LoaderResource>>): void {
		if (debugConfig.assets.logging) {
			logger.info('onFirstPhaseLoadCompletion')
		}
		this.addResources(resources)
		if (this.failedAssets.length > 0) {
			this.retryAttempts++
			if (debugConfig.assets.logging) {
				logger.info('retryAttempts', this.retryAttempts)
			}
			if (this.retryAttempts > MAX_RETRY_ATTEMPTS) {
				logger.info(`Loading failed after ${MAX_RETRY_ATTEMPTS} attempts. Aborting.`)
				// @TODO CAKES
				// UI.getInstance().emitEvent('errors/setErrorDescriptions', {
				// title: `Asset Loading Failed`,
				// description: `Unable to load all required assets. Sorry, try again later. If this is a recurring issue contact support.`
				// }, { root: true })
				// UI.getInstance().emitEvent('errors/setActiveError', undefined, { root: true })
				return
			}

			logger.info(`First loading phase complete, but ${this.failedAssets.length} failed. Retrying...`)
			this.firstPhaseAssetLoader.reset()
			for (let i = 0; i < this.failedAssets.length; ++i) {
				const failedAsset = this.failedAssets[i]
				this.reloadOrReloadParent(failedAsset)
			}
			this.failedAssets = []
			this.firstPhaseAssetLoader.load(this.onFirstPhaseLoadCompletion.bind(this))
		} else {
			logger.info('First loading phase complete!')
			this.loadSecondPhaseAssets(assetList)
			this.secondPhaseAssetLoader.load(this.onSecondPhaseLoadCompletion.bind(this))
		}
	}

	private onSecondPhaseLoadCompletion(loader: Loader, resources: Partial<Record<string, LoaderResource>>): void {
		if (debugConfig.assets.logging) {
			logger.info('onSecondPhaseLoadCompletion', Object.keys(resources).length)
		}
		this.addResources(resources)
		if (this.failedAssets.length > 0) {
			this.retryAttempts++
			if (debugConfig.assets.logging) {
				logger.info('retryAttempts', this.retryAttempts)
			}
			if (this.retryAttempts > MAX_RETRY_ATTEMPTS) {
				logger.info(`Loading failed after ${MAX_RETRY_ATTEMPTS} attempts. Aborting.`)
				// @TODO CAKES
				// UI.getInstance().emitEvent('errors/setErrorDescriptions', {
				// title: `Asset Loading Failed`,
				// description: `Unable to load all required assets. Sorry, try again later. If this is a recurring issue contact support.`
				// }, { root: true })
				// UI.getInstance().emitEvent('errors/setActiveError', undefined, { root: true })
				return
			}

			logger.info(`Second loading phase complete, but ${this.failedAssets.length} failed. Retrying...`)
			this.secondPhaseAssetLoader.reset()
			for (let i = 0; i < this.failedAssets.length; ++i) {
				const failedAsset = this.failedAssets[i]
				const assetDefinition = this.tryGetAssetDefinitionFromResource(failedAsset)

				if (assetDefinition?.dependsOnSpineAtlas) {
					const spineAtlas = this.loadedAssets.get(assetDefinition.dependsOnSpineAtlas).spineAtlas
					const options = {
						metadata: {
							spineAtlas,
						},
					}
					this.secondPhaseAssetLoader.add(failedAsset.name, failedAsset.url, options)
				} else {
					this.reloadOrReloadParent(failedAsset)
				}
			}
			this.failedAssets = []
			this.secondPhaseAssetLoader.load(this.onSecondPhaseLoadCompletion.bind(this))
		} else {
			logger.info('Second loading phase complete!')

			// @TODO CAKES
			// addColliderConfigsToLookupCollidersClient()

			allPfxAssets
				.map((def) => this.getEffectConfigByName(def.name))
				.forEach((effectConfig) => {
					Effect.LoadRequiredAtlases(effectConfig, () => {
						this.onLoadProgress(undefined, null)
					})
				})

			this.loadingDoneCallback()
		}
	}

	private addResources(resources: Partial<Record<string, LoaderResource>>) {
		if (debugConfig.assets.logEachAsset) {
			logger.info('addResources:', Object.keys(resources).length)
		}
		let added = 0
		for (const resourceIdentifier in resources) {
			if (resources.hasOwnProperty(resourceIdentifier)) {
				const resource = resources[resourceIdentifier]
				if (debugConfig.assets.logEachAsset) {
					logger.info('adding:', resourceIdentifier)
				}
				if (resource.data?.name !== undefined) {
					this.debugAssetAliases.set(resource.data.name, resourceIdentifier)
				}
				if (!resource.error) {
					this.loadedAssets.set(resourceIdentifier, resource)
					added++
				}
			}
		}
		if (debugConfig.assets.logEachAsset) {
			logger.info(` added ${added} out of ${Object.keys(resources).length}`)
		}
	}

	/**
	 * This reloads `failedAsset` OR reloads the failed asset's *parent* asset.
	 * What I mean by parent here is and asset that causes other transitive loads,
	 * like a spine .json that causes a transitive load of a .atlas and .png file.
	 * If we try and reload the .atlas or .png without reloading the parent .json, the parent will
	 * not be notified of the spine data (asset.spineData will not be set)
	 * @param failedAsset an asset that has failed loading that we want to retry
	 */
	private reloadOrReloadParent(failedAsset: LoaderResource) {
		const parentAssetDefinition = this.tryGetParentAssetDefinition(failedAsset)
		if (parentAssetDefinition) {
			if (failedAsset.url.includes('.png')) {
				this.numberOfAssetsToBeLoaded += 2
			} else if (failedAsset.url.includes('.atlas')) {
				this.numberOfAssetsToBeLoaded += 1
			}
			this.secondPhaseAssetLoader.add(parentAssetDefinition.name, parentAssetDefinition.path)
		} else {
			this.secondPhaseAssetLoader.add(failedAsset.name, failedAsset.url)
		}
	}

	private tryGetParentAssetDefinition(resource: LoaderResource) {
		// currently only spine sprites (.json) have transitive assets, which are always .png or .atlas
		const parentUrl = resource.url.replace('.png', '.json').replace('.atlas', '.json')
		const parentAssetDefinition = assetList.find((asset) => {
			return asset.path === parentUrl
		})
		return parentAssetDefinition
	}

	private tryGetAssetDefinitionFromResource(resource: LoaderResource) {
		const assetDefinition = assetList.find((asset) => {
			return asset.name === resource.name && asset.path === resource.url
		})
		return assetDefinition
	}

	private onErrorCallback(error: Error, loader: Loader, resource: LoaderResource) {
		console.error(error.message, resource.url)

		this.assetsLoaded--
		this.failedAssets.push(resource)
	}

	private onLoadedCallback(loader: Loader, resource: LoaderResource) {
		if (debugConfig.assets.logEachAsset) {
			logger.info(`loaded ${resource.url}`)
		}
	}

	private getEffectConfigByName(effectConfigName: string) {
		const pfxAsset = AssetManager.getInstance().getAssetByName(effectConfigName).data as EffectConfig
		if (!pfxAsset.emitters) {
			return undefined
		}
		return pfxAsset
	}
}

function checkResourses(resources: Partial<Record<string, LoaderResource>>, logFunc: (message: string) => void) {
	for (const resourceIdentifier in resources) {
		if (Object.prototype.hasOwnProperty.call(resources, resourceIdentifier)) {
			const resource = resources[resourceIdentifier]
			checkResource(resource, logFunc)
		}
	}
}

function checkResource(resource: LoaderResource, logFunc: (message: string) => void) {
	if (resource !== null) {
		if (resource.extension === 'png') {
			const size = resource.data.height * resource.data.width
			const oneM = 1024 * 1024
			if (size > oneM) {
				logFunc(`Asset: ${resource.name} exceeds maximum texture size of 1Mpx (its ${resource.data.height} X ${resource.data.width} = ${(size / oneM).toFixed(1)}Mpx). May cause client hitches as this texture is loaded/unloaded on GPU.`)
			}
		}
	}
}

export function debugIterateAssetDefinitionList(callback: (assetDef: AssetDefinition) => void) {
	assetList.forEach(callback)
}

function isPfxAssetDefinition(assetDef: AssetDefinition) {
	return assetDef.path.includes('/pfx/')
}
