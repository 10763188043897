export enum EventTypes {
	Pet = 'pet',
	Goblin = 'goblin',
	Shrine = 'shrine',
	ShamblingTower = 'shamblingTower',
	CombatArena = 'combatArena',
	FountainsOfMana = 'fountainsOfMana',
	CreepyEgg = 'creepyEgg',
	TemporalDistortion = 'temporalDistortion',
	WildRotSons = 'wildRotSons',
	RoamingWildlings = 'roamingWildlings'
}