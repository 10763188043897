import { UI } from "../ui/ui"

// https://stackoverflow.com/questions/47620801/js-ts-decorator-to-conditionally-prevent-method-execution
export function debugtool(target: any, name: string, descriptor: PropertyDescriptor): any {
	const method = descriptor.value

	descriptor.value = function (...args) {
		if (!UI.getInstance().store.getters['user/isQa']) {
			if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
				return
			}
		}
		
		// we're not on production or staging, so go ahead and call the decorated method
		method.apply(this, args)
	}
}