<template>
	<div class="copy-container">
		<img src="../../../../assets/ui/copy-icon.png" alt="" /> <span class="muted copy-on-click"  @click="copyToClipboard(copyText)" :title="placeholderTitle">{{ computedText }}</span>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'CopyToClipboard',
	components: {},
	data() {
		return {
			timeout: null,
		}
	},
	props: {
		displayText: {
			type: String,
			required: true,
		},
		copyText: {
			type: String,
			required: true,
		},
		placeholderTitle: {
			type: String,
			required: true,
		},
		copiedText: {
			type: String,
			required: false,
			default: 'Copied link!'
		}
	},
	computed: {
		computedText() {
			return this.timeout !== null ? this.copiedText : this.displayText
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		copyToClipboard() {
			const commandExists = document.queryCommandSupported('copy')

			if (commandExists === true) {
				let copyElement = document.createElement('input')
				document.body.appendChild(copyElement)
				copyElement.value = this.copyText
				console.log(`Copied to clipboard: ${this.copyText}`)
				copyElement.select()
				document.execCommand('copy', false)
				copyElement.remove()
				this.displayCopiedMessage()
			} else {
				console.warn(`Copy-to-clipboard not supported on this browser :(`)
			}
		},
		displayCopiedMessage() {
			this.timeout = setTimeout(() => {
				this.timeout = null
			}, 2000)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.copy-container {
	display: flex;
	align-items: center;

	&.small {
		img {
			width: 26px;
			height: 26px;
			margin-right: 8px;
		}
	}

	img {
		width: 42px;
		height: 42px;
		margin-right: 15px;
	}

	.span {
		.copy-on-click {
			pointer-events: all;
			cursor: pointer !important;
		}
	}
}
</style>
