import { Container, Spritesheet } from "pixi.js"
import { ComponentOwner } from "../component-owner"
import { GraphicsComponent } from "./graphics-component"
import { Renderer } from "./renderer"
import { InstancedSpriteSheetAnimator } from "./instanced-spritesheet-animator"
import { SpriteAnimationConfigs } from "../../spine-config/sprite-animation-config"

export class SpritesheetAnimatorComponent extends GraphicsComponent {
    
    spriteSheetAnimator: InstancedSpriteSheetAnimator
    owner: ComponentOwner

    autoUpdateZIndex: boolean
    zIndexOffset: number = 0

    private isInScene: boolean

    constructor(owner: ComponentOwner, spriteSheet: Spritesheet, defaultAnimationName: string, overrideAnimSpeed?: number, playAnimationsConcurrently?: boolean, autoUpdateZ?: boolean, spriteAnimationConfigsOverride?: SpriteAnimationConfigs) {
        super()
        this.owner = owner
        
        this.spriteSheetAnimator = new InstancedSpriteSheetAnimator(spriteSheet, defaultAnimationName, overrideAnimSpeed, playAnimationsConcurrently, spriteAnimationConfigsOverride)

        this.autoUpdateZIndex = Boolean(autoUpdateZ)

        this.isInScene = false
    }

    update(delta: number) {
        this.spriteSheetAnimator.position.x = this.owner.position.x
        this.spriteSheetAnimator.position.y = this.owner.position.y

        if (this.autoUpdateZIndex) {
            this.spriteSheetAnimator.zIndex = this.owner.position.y + this.zIndexOffset
        }
    }

    playAnimation(name: string, nextAnim?: string, callback?: () => void) {
        this.spriteSheetAnimator.playAnimation(name, nextAnim, callback)
    }

    addToScene() {
        if (this.isInScene) {
            return
        }

        if (this.autoUpdateZIndex) {
            this.spriteSheetAnimator.zIndex = this.owner.position.y
        }

        this.spriteSheetAnimator.addToScene()
        this.isInScene = true
    }

    removeFromScene() {
        if (!this.isInScene) {
            return
        }

        this.spriteSheetAnimator.removeFromScene()
        this.isInScene = false
    }
    
    overrideZindex(zIndexValue: number) {
        this.spriteSheetAnimator.zIndex = zIndexValue
    }
    
    setScale(x: number, y: number) {
        this.spriteSheetAnimator.scale.x = x
        this.spriteSheetAnimator.scale.y = y
    }
}