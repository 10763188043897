<template>
	<Panel id="genericYesNo" :height="-1">
		<template #panel-content>
			<div class="description-body">
				<div class="information-content">
					<div class="header" :lang="this.$i18n.locale">{{ $t(getTitle) }}</div>
					<div class="description" :lang="this.$i18n.locale">
						<div v-for="name in getDescription" :key="name" v-html="$t(name, getReplacements)"></div>
					</div>
				</div>
			</div>
			<div class="buttons-container">
				<div class="button">
					<TextButton :button-label="$t(getNoButtonText)" :disabled="noDisabled" :button-style="getNoButtonColor" :fontColor="'failureRed'" @on-click-fn="callNoCallbackAndCloseMenu" style="min-width: 200px; width: 100%"></TextButton>
				</div>
				<div class="button">
					<TextButton :button-label="$t(getYesButtonText)" :disabled="yesDisabled" :button-style="getYesButtonColor" @on-click-fn="callYesCallbackAndCloseMenu" style="min-width: 200px; width: 100%"></TextButton>
				</div>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'

export default {
	name: 'GenericTwoBtnPrompt',
	components: {
		Panel,
		TextButton,
	},
	computed: {
		...mapGetters('genericTwoButtonPrompt', ['getDescription', 'getTitle', 'getNoButtonText', 'getYesButtonText', 'getYesButtonColor', 'getNoButtonColor', 'noDisabled', 'yesDisabled', 'getOptionalItem', 'getPanelIdentifier', 'getReplacements']),
	},
	methods: {
		...mapActions('genericTwoButtonPrompt', ['closeActiveYesNoPanel', 'callNoCallbackAndCloseMenu', 'callYesCallbackAndCloseMenu']),
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
.description-body {
	.information-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.header {
			color: @cream;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 45;
				font-variant: small-caps;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
		.description {
			color: @cream;
			padding: 10px 30px;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 32px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
	}
}

.buttons-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	.button {
		margin: 30px 10px 10px;
	}
}
</style>
