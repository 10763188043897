import { invert } from "lodash";

type KeyFromValue<V, T extends Record<PropertyKey, PropertyKey>> = {
    [K in keyof T]: V extends T[K] ? K : never
}[keyof T];

type Invert<T extends Record<PropertyKey, PropertyKey>> = {
    [V in T[keyof T]]: KeyFromValue<V, T>
}

// Sometimes you just wanna reverse an object and know the types are good, and you don't want to
// write gross types like this. We call lodashes invert under the hood but lodashes invert type is
// wrong, so we don't use it
export function invertRecord<T extends Record<PropertyKey, PropertyKey>>(record: T): Invert<T> {
    return invert(record) as Invert<T>
}