<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div class="group">
				<div class="music-container container-formatting">
					<div class="label" :lang="this.$i18n.locale">{{ $t('settings.music') }}</div>
					<div class="music-user-input">
						<Range :min="'0'" :max="'100'" :type="'bgm'" :label-id="'music-input'" @on-change="updateBGMVolume"></Range>
					</div>
				</div>
				<div class="sfx-container container-formatting">
					<div class="label" :lang="this.$i18n.locale">{{ $t('settings.sfx') }}</div>
					<div class="sfx-user-input">
						<Range :min="'0'" :max="'100'" :type="'sfx'" :label-id="'sfx-input'" @on-change="updateSFXVolume"></Range>
					</div>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Range from '../form-inputs/range.vue'


export default {
	name: 'AudioModule',
	components: {
		EmbellishmentContainer,
		Range,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('settings', ['fullscreen']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('settings', ['checkFullscreen', 'updateBGMVolume', 'updateSFXVolume'])
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.music-container,
.sfx-container {
	display: flex;
	align-items: center;
	width: 660px;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 200px;
		margin-right: 175px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.fullscreen-on-off {
		display: flex;
		width: 70px;
		height: 40px;
	}
}
.music-container,
.sfx-container {
	.music-user-input,
	.sfx-user-input {
		display: flex;
		position: relative;
		align-items: center;

		:deep(#range) {
			width: 160px;
			input {
				width: 160px;
			}
		}
	}
}
</style>
