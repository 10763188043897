import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, ENEMY_OBJECT_POOL_GROWTH_SIZE, ENEMY_OBJECT_POOL_INITIAL_SIZE, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"

const eyeBat: EnemyAI = {
	name: ENEMY_NAME.EYEBAT_1,
	type: EnemyType.BASIC,
	objectPoolInitialSize: ENEMY_OBJECT_POOL_INITIAL_SIZE,
	objectPoolGrowthSize: ENEMY_OBJECT_POOL_GROWTH_SIZE,
	appearance: {
		asset: SpineDataName.EYEBAT_1,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
		scale: 1.5,
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 36,
				position: [-8, -17],
			},
		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.75,
			maxHealth: 2.0,
			projectileSpeed: 440,
			attackSize: 40,
			attackKnockback: 0,
		},
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: 360,
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		animationSpeeds: 0.25
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_ENEMY0,
				cooldownDef: {
					// name: "enemyProjectile",
					maxAmmo: 1,
					cooldownInterval: 15000,
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 800,
			movementMinDistance: 500,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default eyeBat
