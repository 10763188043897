<template>
	<div id="CallToAction">
		<div class="content-container">
			<div class="floating-border"></div>
			<div class="another-container">
				<div class="thank-you-logo-container">
					<div class="thank-you-container">
						<img src="../../../assets/ui/text-art/thank-you-for-playing-text.png" class="thank-you" />
					</div>
					<div class="logo-container">
						<img src="../../../assets/ui/logos/diebrary.png" alt="" class="logo" />
					</div>
				</div>
				<div class="text-container" :lang="this.$i18n.locale">
					<div class="blurb1">This wraps up the demo of Diebrary, but this doesn't conclude your adventure!</div>
					<div class="blurb2">Wishlist the full game on Steam to meet more heroes, explore new maps, and slay enemies with new weapons!</div>
				</div>
				<div class="button-container">
					<div class="steam-button">
						<TextButton :button-label="'Wishlist Diebrary'" :disabled="false" :button-style="'bright-green'" @on-click-fn="openSteamPage"></TextButton>
					</div>
					<div class="discord-button">
						<TextButton :button-label="'Join Our Community'" :disabled="false" :button-style="'blue'" @on-click-fn="openDiscord"></TextButton>
					</div>
					<TextButton :button-label="'Continue'" :disabled="false" :button-style="'blue'" @on-click-fn="updateShowCallToAction(false)"></TextButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextButton from '../ui-components/buttons/text-button.vue'
import { Views } from '../store/ui-store'
import { GoogleAnalyticsHandler } from '../../analytics/google-analytics'
import { STEAM_PARAMS, STEAM_URL } from '../steam-links'

export default {
	name: 'CallToAction',
	components: {
		TextButton,
	},
	data() {
		return {
			Views: Views,
		}
	},
	props: {},
	computed: {},
	methods: {
		...mapMutations('ui', ['updateShowCallToAction']),
		openSteamPage() {
			GoogleAnalyticsHandler.getInstance().trackWishlist()
			window.open(`${STEAM_URL + STEAM_PARAMS.dogear}`, '_blank')
		},
		openDiscord() {
			window.open('https://discord.gg/xbXhxe7Dxs')
		},
		continueDemo() {
			this.updateShowCallToAction(false)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
#CallToAction {
	background-repeat: no-repeat;
	background-size: contain;
	backdrop-filter: blur(10px);
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	&.ratio21By9 {
		.bottom-container {
			.group {
				margin: 0 auto;
				width: 1920px;
			}
		}
	}
	.content-container {
		width: 1920px;
		height: 100%;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		display: flex;
		position: relative;
		.floating-border {
			background-image: url('~/assets/ui/backgrounds/decorative-border.png');
			background-size: cover;
			width: 1920px;
			height: 1080px;
			transform: translateY(-50%);
			top: 50%;
			position: absolute;
		}
		.another-container {
			width: 970px;
			height: 100%;
			flex-direction: column;
			align-items: center;
			display: flex;
			justify-content: space-around;
			margin: 42px 0 172px;
			.thank-you-container {
				margin-top: 25px;

				.thank-you {
					width: 831px;
					height: 91px;
				}
			}
			.logo-container {
				margin-top: 25px;

				.logo {
					width: 800px;
					height: 312px;
				}
			}
			.text-container {
				color: @green;

				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 36px;
					letter-spacing: 2px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansSecondaryFont);
					font-size: 36px;
				}
				height: fit-content;
				text-align: center;
				display: flex;
				flex-flow: column;
				.blurb1 {
					width: 100%;
					margin-bottom: 10px;
				}
				.blurb2 {
					width: 100%;
				}
			}
			.button-container {
				display: flex;
				flex-flow: column;
				justify-content: space-evenly;
				margin-top: 30px;
				.text-button {
					margin-top: 10px;
					height: 50px;
					width: 450px;
				}
				.steam-button {
					:deep(.text-button) {
						filter: drop-shadow(0px 0px 8px @green);
						.button-text {
							&:after {
								background-image: url('~/assets/ui/social-media-icons/steam-icon.png');
								background-size: 30px; /* Change the size of the background image */
								background-position: center; /* Change the position of the background image */
								background-size: contain;
								background-repeat: no-repeat;

								// The following properties are required for vertical alignment and sizing
								content: ''; /* Empty content */
								display: inline-block; /* Ensure the pseudo-element takes up space */
								height: 30px; /* Set the height of the pseudo-element */
								width: 30px; /* Set the width of the pseudo-element */
								margin-left: 30px;
								margin-bottom: 5px;
								vertical-align: middle;
							}
						}
					}
				}
				.discord-button {
					:deep(.text-button) {
						.button-text {
							&:after {
								background-image: url('~/assets/ui/social-media-icons/discord-icon-green.png');
								background-size: 30px; /* Change the size of the background image */
								background-position: center; /* Change the position of the background image */
								background-size: contain;
								background-repeat: no-repeat;

								// The following properties are required for vertical alignment and sizing
								content: ''; /* Empty content */
								display: inline-block; /* Ensure the pseudo-element takes up space */
								height: 30px; /* Set the height of the pseudo-element */
								width: 30px; /* Set the width of the pseudo-element */
								margin-left: 10px;
								margin-bottom: 5px;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}
}
</style>
