import { UI } from "../../ui/ui"
import { loginExternalKongregateRequest } from "../../utils/api/griddle-requests"
import { Views } from '../../ui/store/ui-store'
import { saveToLocalStorage, saveToSessionStorage } from "../../utils/local-storage"
import { GoogleAnalyticsHandler } from "../../analytics/google-analytics"

const TEST_MODE: boolean = false // process.env.NODE_ENV === 'local'
const LOGGING: boolean = false

export class Kongregate {
	static getInstance(API?: any) {
		if (!Kongregate.instance) {
			if (API === undefined) {
				throw new Error('Attempted to use Kongregate singleton without initializing it first')
			}
			console.log('Welcome to Kongregate!')
			Kongregate.instance = new Kongregate(API)
		}

		return Kongregate.instance
	}
	private static instance: Kongregate

	API: any
	username: string
	externalUserId: string
	externalAuthToken: string // AKA game auth token

	constructor(API: any) {
		this.API = API
		window['kongregate'] = API
		this.API.services.addEventListener('login', async (event) => {
			console.log(`[Kong EVENT] login`, event)
			await this.postLoginHandler()
		})
		this.API.chat.addEventListener('message', (event) => {
			console.log(`[Kong EVENT] chat message`, event)
		})
		this.API.chat.addEventListener('room.message', (event) => {
			console.log(`[Kong EVENT] chat room.message`, event)
		})
	}

	async fetchUserDetails() {
		//TODO: could wrap all this in promises for speed
		if (TEST_MODE) {
			this.username = 'OtherOcean'
			this.externalUserId = ''// your ID here
			this.externalAuthToken = '' // your token here
			return this.getUserDetails()
		}
		this.username = this.API.services.getUsername()
		this.externalUserId = this.API.services.getUserId()
		this.externalAuthToken = this.API.services.getGameAuthToken()
		return this.getUserDetails()
	}

	async tryImmediateLogin() {
		UI.getInstance().emitMutation('user/setExternalConnectionsLoading', true)
		// const user = await this.fetchUserDetails()
		// console.log('tryImmediateLogin user', user)
		if (this.API.services.isGuest()) {
			if (LOGGING) {
				console.log('Kongregate user is a guest, prompting for required login')
			}
			UI.getInstance().emitMutation('user/setExternalUserAccountLoggedIn', false)
			this.API.services.showRegistrationBox()
			if (TEST_MODE) { // this bypasses the need for the login event listener
				await this.postLoginHandler()
			}
		} else {
			if (LOGGING) {
				console.log(`Kongregate user is logged in, let's play!`)
			}
			await this.postLoginHandler()
		}
		UI.getInstance().emitMutation('user/setExternalConnectionsLoading', false)
	}

	private async postLoginHandler() {
		const loginPayload = await this.fetchUserDetails()
		if (loginPayload.externalUserId && loginPayload.externalAuthToken) {
			const result = await loginExternalKongregateRequest(loginPayload)
			if (LOGGING) {
				console.log(`Kongregate login result: `, result)
			}
			saveToLocalStorage('auth-token', result.authentication.token)
			saveToSessionStorage('auth-token', result.authentication.token)
			UI.getInstance().emitAction('user/fetchInitialUserState')
			this.postSuccessfulLogin()
			GoogleAnalyticsHandler.getInstance().trackRegisterPortalAccount(UI.getInstance().store.state.user.externalUserAccountType)
		} else {
			console.error('Kongregate postLoginHandler() found no external user information, even though we received a login event from Kongregate')
		}
	}

	private async postSuccessfulLogin() {
		UI.getInstance().emitMutation('user/setExternalUserAccountLoggedIn', true)
	}

	getUserDetails() {
		return {
			username: this.username,
			externalUserId: this.externalUserId,
			externalAuthToken: this.externalAuthToken,
		}
	}

	submitScore(score: number) {
		this.API.stats.submit('Global Score', score)
	}

	sendVirtualChatMessage(username: string, message: string) {
		this.API.chat.displayMessage(message, username) // yes the order is inverted
	}
}
