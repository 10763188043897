import { GameState, getNID } from "../engine/game-state"
import { InstancedSprite } from "../engine/graphics/instanced-sprite"
import { Renderer } from "../engine/graphics/renderer"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { PoolableJsObject } from "../utils/third-party/object-pool"
import { Enemy } from "./enemies/enemy"
import { EntityType, IEntity } from "./entity-interfaces"

export interface MarkGfxParams {
    enemy: Enemy
}

const START_SCALE = 1.65
const SCALE_SPEED = 3.125

export class MarkGfx implements PoolableJsObject, IEntity {
    nid: number
    entityType: EntityType = EntityType.Prop
	timeScale: number = 1

    sprite: InstancedSprite

    enemy: Enemy

    private scalingIn: boolean

    constructor(texture: PIXI.Texture) {
        this.nid = getNID(this)

        this.sprite = new InstancedSprite(texture, 0, 0, 100)
    }
    
    setDefaultValues(defaultValues: any, overrideValues?: MarkGfxParams) {
        if (overrideValues) {
            this.enemy = overrideValues.enemy

            this.sprite.x = overrideValues.enemy.x
            this.sprite.y = overrideValues.enemy.y

            this.sprite.scaleX = START_SCALE
            this.sprite.scaleY = START_SCALE

            Renderer.getInstance().fgRenderer.addPropToScene(this.sprite)

            this.scalingIn = true

            GameState.addEntity(this)
        }
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds) {
        if (this.scalingIn) {
            this.sprite.scaleX -= SCALE_SPEED * delta
            this.sprite.scaleY -= SCALE_SPEED * delta

            if (this.sprite.scaleX <= 1) {
                this.scalingIn = false

                this.sprite.scaleX = 1
                this.sprite.scaleY = 1
            }
        }

        this.sprite.x = this.enemy.x
        this.sprite.y = this.enemy.y
    }

    cleanup() {
        this.enemy = null
        Renderer.getInstance().fgRenderer.removeFromScene(this.sprite)
        GameState.removeEntity(this)
    }

}
