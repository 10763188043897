import { UpgradeManager } from '../../upgrades/upgrade-manager'
import { UpgradeDefinition } from '../../upgrades/upgrade-definitions'
import { Audio } from '../../engine/audio'
import { GameState } from '../../engine/game-state'
import { UI } from '../ui'
import ClientPlayerInput, { InputAction, INPUT_DOWN_ACTION_EVENT_NAME } from '../../engine/client-player-input'

const initialState = {
	level: 1,
	upgrades: [],
	allocatedIds: [],
	selectedUpgrade: null,
	hoveredUpgrade: null,
	showLevelUpModal: false,
	currentXP: 0,
	nextLevelXP: 10,
	excludedUpgrades: [],
	upgradeTreeWithIds: [],
	playerLoadout: []
}
interface levelUpState {
	level: number
	upgrades: UpgradeDefinition[]
	allocatedIds: []
	selectedUpgrade: []
	hoveredUpgrade: []
	showLevelUpModal: boolean
	currentXP: number
	nextLevelXP: number
	excludedUpgrades: []
	upgradeTreeWithIds: []
	playerLoadout: []
}

const store = {
	namespaced: true,
	state: initialState as levelUpState,
	getters: {
		playerLevel(state) {
			return state.level
		},
		getUpgrade(state: LevelUpStoreState) {
			if (state.selectedUpgrade !== null) {
				const upgradeTree = state.upgradeTreeWithIds.find((upgradeTree) => upgradeTree.parentIndex === state.selectedUpgrade.upgradeParent)
				return upgradeTree
			}
		},
		getPlayerLoadout(state: LevelUpStoreState) {
			return state.playerLoadout
		},
		activeDesc(state) {
			if (state.hoveredUpgrade) {
				return state.hoveredUpgrade.desc
			}

			return state.selectedUpgrade ? state.selectedUpgrade.desc : 'You gained enough experience to level up! Choose an upgrade.'
		},
		activeName(state) {
			if (state.hoveredUpgrade) {
				return state.hoveredUpgrade.name
			}

			return state.selectedUpgrade ? state.selectedUpgrade.name : 'Select an Upgrade'
		},
		statSummary(state) {
			const upgrade = state.hoveredUpgrade || state.selectedUpgrade
		}
	},
	mutations: {
		// TODO get type of upgrades
		setUpgrades(state: LevelUpStoreState, upgrades,) {
			state.upgrades = upgrades
			// state.selectedUpgrade = upgrades[0]
		},
		setExcludedUpgrades(state: LevelUpStoreState, exlcudedUpgrades) {
			state.excludedUpgrades = exlcudedUpgrades
		},
		setUpgradeTreesWithIds(state: LevelUpStoreState, upgradeTreeWithIds) {
			state.upgradeTreeWithIds = upgradeTreeWithIds
		},
		setAllocatedIds(state: LevelUpStoreState, allocatedIds) {
			state.allocatedIds = allocatedIds
		},
		setPlayerLoadout(state: LevelUpStoreState, playerLoadout) {
			state.playerLoadout = playerLoadout
		},
		openLevelUpModal(state: LevelUpStoreState) {
			state.showLevelUpModal = true
		},
		//TODO Remove tempUpgradeItem with an empty array
		closeModal(state: LevelUpStoreState) {
			state.showLevelUpModal = false
			state.upgrades = []
			state.selectedUpgrade = null
		},
		updateExperience(state: LevelUpStoreState, [currentXP, nextLevelXP, level]) {
			state.currentXP = currentXP
			state.nextLevelXP = nextLevelXP
			state.level = level
		},
		setSelectedUpgrade(state: LevelUpStoreState, upgrade: UpgradeDefinition) {
			const selectedUpgrade = Object.assign({}, upgrade)
			state.selectedUpgrade = selectedUpgrade
		},

		setHoverUpgrade(state: LevelUpStoreState, upgrade: UpgradeDefinition) {
			if (!upgrade) {
				state.hoveredUpgrade = null
				return
			}
			const hoveredUpgrade = Object.assign({}, upgrade)
			state.hoveredUpgrade = hoveredUpgrade
		},
		reset(state: LevelUpStoreState) {
			state.level = 1
			state.upgrades = []
			state.allocatedIds = []
			state.selectedUpgrade = null
			state.hoveredUpgrade = null
			state.showLevelUpModal = false
			state.currentXP = 0
			state.nextLevelXP = 10
			state.excludedUpgrades = []
			state.upgradeTreeWithIds = []
			state.playerLoadout = []
		}
	},
	actions: {
		postUpgrades({ state, commit, dispatch }: { state: LevelUpStoreState; commit: any, dispatch: any }, { rolledUpgrades, upgradeTree, excludedBy, upgradeTreeWithIds, allocatedIds, playerLoadout }: { rolledUpgrades: any[], upgradeTree: Object, excludedBy: any[], upgradeTreeWithIds: any[], allocatedIds: any[], playerLoadout: string[] }) {
			commit('setUpgrades', rolledUpgrades)
			commit('setExcludedUpgrades', excludedBy)
			commit('setUpgradeTreesWithIds', upgradeTreeWithIds)
			commit('setAllocatedIds', allocatedIds)
			commit('setPlayerLoadout', playerLoadout)
			if (rolledUpgrades.length) {
				dispatch('ui/setPause', null, { root: true })
				//TODO polish: level-up animation *before* popping the modal
				commit('openLevelUpModal')
				commit('ui/updateDarkenOverlay', true, { root: true })
			}
		},
		toggleSelectedUpgradeItem({ state, commit }: { state: LevelUpStoreState; commit: any }, upgrade: UpgradeDefinition) {
			commit('setSelectedUpgrade', upgrade)
		},
		upgradeOver({ state, commit }: { state: LevelUpStoreState; commit: any }, upgrade: UpgradeDefinition) {
			commit('setHoverUpgrade', upgrade)
		},
		applyUpgrades({ state, commit, dispatch }: { state: LevelUpStoreState; commit: any, dispatch: any }) {
			if (state.selectedUpgrade) {
				dispatch('ui/setUnpause', null, { root: true })
				UpgradeManager.redeemUpgrade(state.selectedUpgrade)
				commit('closeModal')
				GameState.player.levelUpModalOpen = false
				commit('ui/updateDarkenOverlay', false, { root: true })
				Audio.getInstance().playSfx('UI_Pit_Reward')

				commit('tooltip/showTooltip', null, { root: true })
			}
		},
	},
}

export type LevelUpStoreState = typeof initialState
export const LevelUpStore = typeof store

export const levelUpStore = () => {
	return store
}

document.addEventListener(INPUT_DOWN_ACTION_EVENT_NAME, (navigationThroughUpgrades))

export function navigationThroughUpgrades(e: CustomEvent<InputAction>) {
	const inputAction = e.detail
	const ui = UI.getInstance()

	if (!ClientPlayerInput.hasInstance() || ClientPlayerInput.getInstance().getThisFrameAction(InputAction.SHOOT)) {
		return
	}

	if (!UI.getInstance().store.state.ui.animationTransitioning) {
		const levelUpState = ui.store.state.levelUp
		const totalUpgrades = ui.store.state.levelUp.upgrades.length
		switch (inputAction) {
			case InputAction.MOVE_RIGHT:
				if (levelUpState.showLevelUpModal) {
					if (levelUpState.selectedUpgrade === null) {
						ui.emitAction('levelUp/toggleSelectedUpgradeItem', levelUpState.upgrades[0])
					} else {
						const currentItemIndex = levelUpState.upgrades.findIndex((upgrade) => upgrade.id === levelUpState.selectedUpgrade.id)
						const selectIndex = currentItemIndex + 1 >= totalUpgrades ? 0 : currentItemIndex + 1
						ui.emitMutation('levelUp/setSelectedUpgrade', levelUpState.upgrades[selectIndex])
					}
					return
				}
				break
			case InputAction.MOVE_LEFT:
				if (levelUpState.showLevelUpModal) {
					if (levelUpState.selectedUpgrade === null) {
						ui.emitAction('levelUp/toggleSelectedUpgradeItem', levelUpState.upgrades[totalUpgrades - 1])
					} else {
						const currentItemIndex = levelUpState.upgrades.findIndex((upgrade) => upgrade.id === levelUpState.selectedUpgrade.id)
						const selectIndex = currentItemIndex - 1 < 0 ? totalUpgrades - 1 : currentItemIndex - 1
						ui.emitMutation('levelUp/setSelectedUpgrade', levelUpState.upgrades[selectIndex])
					}
					return
				}
				break
			case InputAction.SKILL:
				if (levelUpState.showLevelUpModal) {
					if (levelUpState.selectedUpgrade !== null) {
						UI.getInstance().emitAction('levelUp/applyUpgrades')
					}
					break
				}
		}
	}
}