import { GameState } from "../../engine/game-state"
import { gameUnits, radians, timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { Enemy, EnemyInitialParams } from "./enemy"
import { Player } from "../player"
import { Vector } from "sat"
import { ENEMY_NAME } from "./enemy-names"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import ShuffleBag from "../../utils/shuffle-bag"
import { InGameTime } from "../../utils/time"
import { angleDiff, distanceSquaredVV, sub, VectorXY } from "../../utils/math"
import { AGGRESSIVE_ENEMY_SPAWN_DISTANCE, AGGRESSIVE_RECYCLE_CULL_TIME, AGGRESSIVE_RECYCLE_DISTANCE_FROM_PLAYER, AGGRESSIVE_RECYCLE_SPAWN_DISTANCE, ENEMY_GROUP_RAD_OFFSET, ENEMY_RECYCLE_DISTANCE_FROM_PLAYER, ENEMY_RECYCLE_SPAWN_DISTANCE, ENEMY_RECYCLE_TIME, ENEMY_SPAWN_DISTANCE, getEnemyTargetHealth, RENDERABLE_ENEMY_CULL_DISTANCE, STANDARD_ENEMY_SIZE } from "./enemy-spawn-config"
import { ShuffleBagEntryOverTime, ITEM_DROP_SHUFFLE_BAG_ENTRIES } from "../../game-data/levelling"
import { checkIfPointIsInView } from "../../engine/graphics/camera-logic"
import CollisionSystem from "../../engine/collision/collision-system"
import { angleInRadsFromVector } from "../../utils/vector"
import { CURRENCY_DROP_SHUFFLE_BAG_ENTRIES } from "../../game-data/currency"
import { debugConfig } from "../../utils/debug-config"
import { GameClient } from "../../engine/game-client"
import { moveToPosition } from "./ai-util"
import { remove } from "lodash"
import assert from "assert"
import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { Renderer } from "../../engine/graphics/renderer"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { AssetManager } from "../../web/asset-manager"
import { attachments_addAttachment } from "../../utils/attachments-system"
import { callbacks_addCallback } from "../../utils/callback-system"
import { GroundPickupConfigType } from "../pickups/ground-pickup-types"
import { PLOT_TWIST_EXPLOSIONS_PER_BATCH } from "../../game-data/player-formulas"

const MONSTER_MERGE_HEALTH_MULTIPLIER = 4
const MONSTER_MERGE_DROP_MULTIPLIER = 4

export default class AISystem {
	static getInstance() {
		if (!AISystem.instance) {
			AISystem.instance = new AISystem()
		}

		return AISystem.instance
	}
	static destroy() {
		AISystem.instance.enemyPoolMap.forEach((value, key) => {
			const allEnemies = value.getAllObjects()
			allEnemies.forEach((e) =>{
				const enemy = e as Enemy
				enemy.destroy()
			})
			value.destroy()
		})
		AISystem.instance.enemyPoolMap.clear()
		AISystem.instance = null
	}
	private static instance: AISystem

	private enemyPoolMap: Map<ENEMY_NAME, ObjectPoolTyped<Enemy, EnemyInitialParams>> = new Map()
	
	xpDropShuffleBag: ShuffleBag
	nextShuffleBagConfig: ShuffleBagEntryOverTime
	nextShuffleBagConfigIndex: number

	currencyDropShuffleBag: ShuffleBag
	nextCurrencyShuffleBagConfig: ShuffleBagEntryOverTime
	nextCurrencyShuffleBagConfigIndex: number

	twistExplosionNextCooldown: timeInMilliseconds = -1
	twistExplosionsRemaining: number = PLOT_TWIST_EXPLOSIONS_PER_BATCH

	fuseRecycledShambers: boolean = false
	shamblerFusionRecycleCount: number = 0
	shamblerFusionRecycleCooldown: number = 0

	bruteTrioKillCount: number = 0

	// Merge Monster Twist
	monsterMergeTwist: boolean = false
	monsterMergeRadiusPadding: number = 0
	monsterMergeCooldown: number = 0 
	monsterMergeAmount: number = 0
	monsterMergeChance: number = 0
	monsterMergeQueue: Enemy[][] = []
	monsterMergeScale: number = 1
	monsterMergeEffectConfig: EffectConfig

	// Spooky Ghost twists
	spookyGhostTwist: boolean = false
	spookyGhostCooldown: timeInSeconds
	spookyGhostChance: number
	spookyGhostRadius: number
	spookyGhostCount: number
	
	lastGroupDirectionRad: number = undefined

	private reuseEnemySpawnVector: Vector = new Vector()

	private recycledShamblersFusionCount: number = 0
	private nextRecycledShamblersFusionCooldown: number = 0
	private nextMonsterMergeCooldown: number = 0
	private nextSpookyGhostCooldown: number = 0

	//TODO restore spatialEnemies
	private constructor() {
		//this.spatialEnemies = spatialEnemies

		this.xpDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(ITEM_DROP_SHUFFLE_BAG_ENTRIES[0], this.xpDropShuffleBag) // don't really need to check the time for these
		this.nextShuffleBagConfig = ITEM_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextShuffleBagConfigIndex = 1

		this.currencyDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[0], this.currencyDropShuffleBag)
		this.nextCurrencyShuffleBagConfig = CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextCurrencyShuffleBagConfigIndex = 1

		const enemyDefinitions = GameClient.getInstance().enemyDefintions
		console.groupCollapsed('Initializing enemy object pools')
		for (let i = 0; i < enemyDefinitions.length; ++i) {
			const enemyDef = enemyDefinitions[i]

			const pool = new ObjectPoolTyped<Enemy, EnemyInitialParams>(() => new Enemy(enemyDef, new Vector()), {}, enemyDef.objectPoolInitialSize, enemyDef.objectPoolGrowthSize, `${enemyDef.name}-pool`)
			console.log(`${enemyDef.name.padStart(35)} - size ${enemyDef.objectPoolInitialSize} (+${enemyDef.objectPoolGrowthSize})`)

			this.enemyPoolMap.set(enemyDef.name, pool)
		}
		console.groupEnd()
	}

	removePlayerTarget(player: Player) {
		GameState.enemyList.forEach((enemy: Enemy) => {
			if (enemy.target) {
				if (enemy.target === player) {
					enemy.target = null
				}
			}
		})
	}

	update(delta: timeInSeconds): void {
		// Make each AI agent aware of the players and fellow AI agents that are near itself
		const playerPos = GameState.player.position

		let recycleReappearDist: number
		if (debugConfig.enemy.aggressiveRecycling) {
			recycleReappearDist = AGGRESSIVE_RECYCLE_SPAWN_DISTANCE
		} else {
			recycleReappearDist = ENEMY_RECYCLE_SPAWN_DISTANCE
		}
		recycleReappearDist *= debugConfig.enemy.recycleOppositeOfPlayer ? 1 : -1

		GameState.enemyList.forEach((enemy: Enemy) => {
			// Delay enemy deletion by one frame so things like the corpse manager can do things once it is determined the entity should be deleted
			if (enemy.toBeDeleted) {
				this.removeEnemy(enemy)
			} else {

				if (enemy.distanceToPlayer2 >= RENDERABLE_ENEMY_CULL_DISTANCE) {
					if (enemy.gfx.isRendering) {
						enemy.gfx.removeFromScene()
					}
				} else {
					if (!enemy.gfx.isRendering) {
						enemy.gfx.addToScene()
					}
				}
			
				enemy.recycleTime += delta
				if (!enemy.isBoss && !enemy.immuneToRecycle && !enemy.isChoreoSpawn && enemy.recycleTime >= ENEMY_RECYCLE_TIME) {
					enemy.recycleTime -= ENEMY_RECYCLE_TIME

					if (debugConfig.enemy.aggressiveRecycling) {
						if (enemy.distanceToPlayer2 >= AGGRESSIVE_RECYCLE_DISTANCE_FROM_PLAYER) {
							enemy.offScreenTime += delta

							if (enemy.offScreenTime >= AGGRESSIVE_RECYCLE_CULL_TIME) {
								this.onEnemyToBeRecycled(enemy)
								if (debugConfig.enemy.noTeleportOnRecycle) {
									this.removeEnemy(enemy)
								} else {
									// little weird since this isn't really supposed to be mutable, but when we teleport it's fixed right away
									enemy.directionToPlayer.normalize()
									enemy.directionToPlayer.scale(recycleReappearDist)
									enemy.directionToPlayer.add(playerPos)
									enemy.offScreenTime = 0

									enemy.teleport(enemy.directionToPlayer)
								}
							}
						} else {
							enemy.offScreenTime = 0
						}
					} else {
						if (enemy.distanceToPlayer2 >= ENEMY_RECYCLE_DISTANCE_FROM_PLAYER) {
							this.onEnemyToBeRecycled(enemy)
							if (debugConfig.enemy.noTeleportOnRecycle) {
								this.removeEnemy(enemy)
							} else {
								// little weird since this isn't really supposed to be mutable, but when we teleport it's fixed right away
								enemy.directionToPlayer.normalize()
								enemy.directionToPlayer.scale(recycleReappearDist)
								enemy.directionToPlayer.add(playerPos)

								enemy.teleport(enemy.directionToPlayer)
							}
						}
					}
				}
			}
		})

		if (this.nextShuffleBagConfig && InGameTime.timeElapsedInSeconds >= this.nextShuffleBagConfig.start) {
			this.populateShuffleBag(this.nextShuffleBagConfig, this.xpDropShuffleBag)

			this.nextShuffleBagConfig = ITEM_DROP_SHUFFLE_BAG_ENTRIES[++this.nextShuffleBagConfigIndex]
		}

		if (this.nextCurrencyShuffleBagConfig && InGameTime.timeElapsedInSeconds >= this.nextCurrencyShuffleBagConfig.start) {
			this.populateShuffleBag(this.nextCurrencyShuffleBagConfig, this.currencyDropShuffleBag)

			this.nextCurrencyShuffleBagConfig = CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[++this.nextCurrencyShuffleBagConfigIndex]
		}

		if (this.fuseRecycledShambers) {
			this.nextRecycledShamblersFusionCooldown -= delta
		}

		if (this.monsterMergeTwist) {
			this.updateMergeQueue(delta)
			this.nextMonsterMergeCooldown -= delta
		}

		if (this.spookyGhostTwist) {
			this.nextSpookyGhostCooldown -= delta
		}
	}

	addEnemiesToMergeQueue(enemies: Enemy[]) {
		this.monsterMergeQueue.push(enemies)
		this.nextMonsterMergeCooldown = this.monsterMergeCooldown
	}

	updateMergeQueue(delta: timeInSeconds) {
		const removeGroups = []
		this.monsterMergeQueue.forEach((enemyGroup) => {
			if (enemyGroup.length !== this.monsterMergeAmount || enemyGroup.some((e) => e.isDead())) {
				removeGroups.push(enemyGroup)
			} else {
				let readyToMerge = true
				for (let i = 0; i < enemyGroup.length; i++) {
					const enemy = enemyGroup[i]
					if (distanceSquaredVV(enemy.position, enemy.targetPosition) > enemy.colliderComponent.bounds.width ** 2) {
						readyToMerge = false
						moveToPosition(enemy, enemy.targetPosition, enemy.movementSpeed, delta)
					} else {
						enemy.velocity.x = 0
						enemy.velocity.y = 0
					}
				}
				if (readyToMerge) {
					removeGroups.push(enemyGroup)
					AISystem.getInstance().onEnemyMerge(enemyGroup)
				}
			}
		})
		removeGroups.forEach((group) => {
			group.forEach((e: Enemy) => {
				e.toBeMerged = false
			})
		})
		remove(this.monsterMergeQueue, (e) => removeGroups.includes(e))
	}

	private onEnemyMerge(enemies: Enemy[]) {
		assert(enemies.length === this.monsterMergeAmount, `WARNING: Trying to merge incorrect number of enemies. Tried to merge ${enemies.length} but correct value is ${this.monsterMergeAmount}`)

		const enemyType = enemies[0].name as ENEMY_NAME
		const {x, y} = enemies[0].position
		const effect = Renderer.getInstance().addOneOffEffectByConfig(this.monsterMergeEffectConfig, x, y, y + 200, 4, 0.5, true)
		enemies.forEach((enemy) => {
			this.removeEnemy(enemy)
		})
		const mergedEnemy = this.spawnEnemyAtPos(enemyType, x, y)
		mergedEnemy.setCircularScale(mergedEnemy.scale, mergedEnemy.scale * this.monsterMergeScale) // todo: account for non-circular colliders?
		mergedEnemy.maxHealth = enemies[0].maxHealth * MONSTER_MERGE_HEALTH_MULTIPLIER
		mergedEnemy._currentHealth = mergedEnemy.maxHealth
		mergedEnemy.bonusDropMult = MONSTER_MERGE_DROP_MULTIPLIER
		mergedEnemy.isMerged = true
		attachments_addAttachment(effect, mergedEnemy)
		callbacks_addCallback(this, () => Renderer.getInstance().removeEffectFromScene(effect), 0.5)
	}

	mergeCooldownUp() {
		return this.nextMonsterMergeCooldown <= 0
	}

	mergeRoll() {
		return Math.random() <= this.monsterMergeChance
	}

	setMonsterMergeTwist(mergeAmount: number, cooldown: timeInSeconds, mergeChance: number, mergeRadiusPadding: number, mergeScale: number) {
		this.monsterMergeTwist = true
		this.monsterMergeRadiusPadding = mergeRadiusPadding
		this.monsterMergeCooldown = cooldown
		this.monsterMergeAmount = mergeAmount
		this.monsterMergeChance = mergeChance
		this.monsterMergeScale = mergeScale
		this.monsterMergeEffectConfig = AssetManager.getInstance().getAssetByName('merge-charge').data
	}

	setSpookyGhostsTwist(cooldown: timeInSeconds, chanceToGhost: number, ghostRadius: number, enemiesToGhost: number) {
		this.spookyGhostTwist = true
		this.spookyGhostCooldown = cooldown
		this.spookyGhostChance =  chanceToGhost
		this.spookyGhostRadius = ghostRadius
		this.spookyGhostCount = enemiesToGhost
	}

	spookyGhostCooldownUp() {
		return this.nextSpookyGhostCooldown <= 0
	}

	spookyGhostRoll() {
		return Math.random() <= this.spookyGhostChance
	}

	applySpookyGhost(enemy: Enemy) {
		Buff.apply(BuffIdentifier.SpookyGhosted, enemy, enemy)
		const nearbyEnemies = CollisionSystem.getInstance().getEntitiesInArea(enemy.position, this.spookyGhostRadius, CollisionLayerBits.HitEnemyOnly)
		let enemiesGhosted = 1
		for (let i = 0; i < nearbyEnemies.length && enemiesGhosted < this.spookyGhostCount; i++) {
			const nearbyEnemy = nearbyEnemies[i].owner as Enemy
			if (!nearbyEnemy.ghosted) {
				Buff.apply(BuffIdentifier.SpookyGhosted, nearbyEnemy, nearbyEnemy)
				enemiesGhosted++
			}
		}
		this.nextSpookyGhostCooldown = this.spookyGhostCooldown
	}

	spawnGroup(enemyAI: ENEMY_NAME, count: number): Enemy[] {		
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		this.reuseEnemySpawnVector.x = debugConfig.enemy.aggressiveRecycling ? AGGRESSIVE_ENEMY_SPAWN_DISTANCE : ENEMY_SPAWN_DISTANCE
		this.reuseEnemySpawnVector.y = 0

		let direction = Math.getRandomFloat(0, Math.PI * 2)
		
		while(this.lastGroupDirectionRad !== undefined && Math.abs(angleDiff(direction, this.lastGroupDirectionRad)) < ENEMY_GROUP_RAD_OFFSET) {
			direction = Math.getRandomFloat(0, Math.PI * 2)
		}
		this.lastGroupDirectionRad = direction

		this.reuseEnemySpawnVector.rotate(direction)

		const playerPosition = GameState.player.position
		this.reuseEnemySpawnVector.add(playerPosition)

		const distApart = (count * STANDARD_ENEMY_SIZE) / 2 // mostly a guess

		const enemiesSpawned = []
		for (let i = 0; i < count; ++i) {
			const ex = Math.getRandomInt(this.reuseEnemySpawnVector.x - distApart, this.reuseEnemySpawnVector.x + distApart)
			const ey = Math.getRandomInt(this.reuseEnemySpawnVector.y - distApart, this.reuseEnemySpawnVector.y + distApart)

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	spawnGroupFromDirection(enemyAI: ENEMY_NAME, count: number, direction: radians) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		this.reuseEnemySpawnVector.x = debugConfig.enemy.aggressiveRecycling ? AGGRESSIVE_ENEMY_SPAWN_DISTANCE : ENEMY_SPAWN_DISTANCE
		this.reuseEnemySpawnVector.y = 0

		this.reuseEnemySpawnVector.rotate(direction)

		const playerPosition = GameState.player.position
		this.reuseEnemySpawnVector.add(playerPosition)

		const distApart = (count * STANDARD_ENEMY_SIZE) / 2 // mostly a guess

		const enemiesSpawned = []
		for (let i = 0; i < count; ++i) {
			const ex = Math.getRandomInt(this.reuseEnemySpawnVector.x - distApart, this.reuseEnemySpawnVector.x + distApart)
			const ey = Math.getRandomInt(this.reuseEnemySpawnVector.y - distApart, this.reuseEnemySpawnVector.y + distApart)

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	spawnEnemyAtRandomPos(enemyAI: ENEMY_NAME): Enemy {
		this.reuseEnemySpawnVector.x = debugConfig.enemy.aggressiveRecycling ? AGGRESSIVE_ENEMY_SPAWN_DISTANCE : ENEMY_SPAWN_DISTANCE
		this.reuseEnemySpawnVector.y = 0

		let direction: number = Math.getRandomFloat(0, Math.PI * 2)
		this.reuseEnemySpawnVector.rotate(direction)

		const playerPosition = GameState.player.position
		this.reuseEnemySpawnVector.add(playerPosition)

		const pool = this.enemyPoolMap.get(enemyAI)
		const enemy = pool.alloc({
			x: this.reuseEnemySpawnVector.x,
			y: this.reuseEnemySpawnVector.y
		})

		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		enemy.maxHealth = targetHealth * enemy.maxHealth
		enemy.currentHealth = enemy.maxHealth

		return enemy
	}

	spawnEnemyAtPlayerOffsetPos(enemyName: ENEMY_NAME, x: number, y: number, directionOverride?: VectorXY, deathTimer?: number): Enemy {
		const playerPosition = GameState.player.position
		
		const pool = this.enemyPoolMap.get(enemyName)
		const enemy = pool.alloc({
			x: playerPosition.x + x,
			y: playerPosition.y + y,
		})
		
		enemy.deathTimer = deathTimer
		if(directionOverride) {
			enemy.setMovementOverride(directionOverride.x, directionOverride.y)
		}
		
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		enemy.maxHealth = targetHealth * enemy.maxHealth
		enemy.currentHealth = enemy.maxHealth

		return enemy
	}

	spawnEnemyAtPos(enemyName: ENEMY_NAME, x: number, y: number) {
		const pool = this.enemyPoolMap.get(enemyName)
		const enemy = pool.alloc({
			x,
			y,
		})
		
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		enemy.maxHealth = targetHealth * enemy.maxHealth
		enemy.currentHealth = enemy.maxHealth

		return enemy
	}

	spawnEnemiesInRectangle(enemyAI: ENEMY_NAME, count: number, x: gameUnits, y: gameUnits, maxX: gameUnits, maxY: gameUnits, directionOverride?: VectorXY, deathTimer?: timeInSeconds): Enemy[] {
		// TODO restore this when we have some debug config settings
		/* if (debugConfig.enemy.absolutelyUnderNoCircumstanceSpawnEnemies) {
			return
		} */
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		const enemiesSpawned = []
		for (let i = 0; i < count; i++) {
			const ex = Math.getRandomInt(x, maxX)
			const ey = Math.getRandomInt(y, maxY)

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			if(directionOverride) {
				enemy.setMovementOverride(directionOverride.x, directionOverride.y)
			}
			enemy.deathTimer = deathTimer

			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}
		return enemiesSpawned
	}

	spawnEnemiesInVerticalLine(enemyAI: ENEMY_NAME, count: number, centerY: gameUnits, x: gameUnits, spacing: gameUnits, directionOverride?: any, deathTimer?: timeInSeconds) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		const height = (count * spacing) - spacing
		const start = centerY - (height / 2)
		
		const enemiesSpawned = []
		for (let i = 0; i < count; i++) {
			const ex = x
			const ey = start + (i * spacing)

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			if(directionOverride) {
				enemy.setMovementOverride(directionOverride.x, directionOverride.y)
			}

			enemy.deathTimer = deathTimer
			enemy.colliderComponent.setLayer(CollisionLayerBits.FlyingEnemy)
			
			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	spawnEnemiesInHorizontalLine(enemyAI: ENEMY_NAME, count: number, centerX: gameUnits, y: gameUnits, spacing: gameUnits, directionOverride?: VectorXY, deathTimer?: timeInSeconds) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		const width = (count * spacing) - spacing
		const start = centerX - (width / 2)

		const enemiesSpawned = []
		for (let i = 0; i < count; i++) {
			const ex = start + (i * spacing)
			const ey = y

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			if(directionOverride) {
				enemy.setMovementOverride(directionOverride.x, directionOverride.y)
			}

			enemy.deathTimer = deathTimer
			enemy.colliderComponent.setLayer(CollisionLayerBits.FlyingEnemy)
			
			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	spawnEnemiesAlongArc(enemyName: ENEMY_NAME, centerX: number, centerY: number, numberOfEnemies: number, xRadius: number, yRadius: number, moveTowardsCenter?: boolean, deathTimer?: timeInSeconds, angleStart: radians = 0, angleEnd: radians = Math.PI * 2) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		const enemiesSpawned = []

		const totalAngle = angleEnd - angleStart

		const stepSize = totalAngle / numberOfEnemies

		for(let a = angleStart; a < angleEnd; a += stepSize) {
			const circleX = Math.sin(a)
			const circleY = Math.cos(a)
			const xPos = circleX * xRadius + centerX
			const yPos = circleY * yRadius + centerY

			const pool = this.enemyPoolMap.get(enemyName)
			const enemy = pool.alloc({
				x: xPos,
				y: yPos
			})

			if (moveTowardsCenter) {
				enemy.setMovementOverride(-circleX, -circleY)
			}

			enemy.deathTimer = deathTimer
			enemy.colliderComponent.setLayer(CollisionLayerBits.FlyingEnemy)
			
			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	// if this turns out useful for non-debug functionality then rename please
	debugForEachEnemy(func) {
		GameState.enemyList.forEach((enemy) => func(enemy))
	}

	getOnScreenEnemies(): Enemy[] {
		const onScreenEnemies = []
		
		GameState.enemyList.forEach((enemy: Enemy) => {
			if (checkIfPointIsInView(enemy.position)){
				onScreenEnemies.push(enemy)
			}
		})
		return onScreenEnemies
	}

	getEnemiesInAngleRange(position: Vector, baseAngleRadians: number, spreadAngleRadians: number, radius: number): Enemy[] {
		const result: Enemy[] = []
		const enemiesInRadius = CollisionSystem.getInstance().getEntitiesInArea(position, radius, CollisionLayerBits.HitEnemyOnly)
		
		const upperBoundAngle = baseAngleRadians + spreadAngleRadians / 2
		const lowerBoundAngle = (baseAngleRadians - spreadAngleRadians / 2) + Math.PI * 2

		if (enemiesInRadius.length) {
			enemiesInRadius.forEach((collider) => {
				const enemy = collider.owner as Enemy
				let enemyAngle =  angleInRadsFromVector(sub(enemy.position, position))
				if (enemyAngle < 0) {
					enemyAngle += Math.PI * 2
				}
				const enemyWithinUpperBound = enemyAngle <= upperBoundAngle
				const enemyWithinLowerBound = enemyAngle + Math.PI * 2 >= lowerBoundAngle
				if (enemyWithinUpperBound && enemyWithinLowerBound){
					result.push(enemy)
				}
			})
		}

		// Temp debug visuals
		// Renderer.getInstance().drawLine({sourceX: position.x, sourceY: position.y, destX: position.x + Math.cos(upperBoundAngle) * radius, destY: position.y + Math.sin(upperBoundAngle) * radius, color: Colors.blue, permanent: false, destroyAfterSeconds: 0.2})
		// Renderer.getInstance().drawLine({sourceX: position.x, sourceY: position.y, destX: position.x + Math.cos(lowerBoundAngle) * radius, destY: position.y + Math.sin(lowerBoundAngle) * radius, color: Colors.blue, permanent: false, destroyAfterSeconds: 0.2})
		return result
	}

	private onEnemyToBeRecycled(enemy: Enemy) {
		if (this.fuseRecycledShambers) {
			if (enemy.isBasicShambler && !enemy.isMerged) {
				this.recycledShamblersFusionCount++

				if (this.nextRecycledShamblersFusionCooldown <= 0) {
					if (this.recycledShamblersFusionCount >= this.shamblerFusionRecycleCount) {
						this.nextRecycledShamblersFusionCooldown = this.shamblerFusionRecycleCooldown
						this.recycledShamblersFusionCount = 0 // intentional not -= shamblerFusionRecycleCount

						const baseName = ENEMY_NAME.SHAMBLING_MOUND
						let spawnedEnemy: Enemy
						if (baseName.length === enemy.name.length) {
							spawnedEnemy = this.spawnEnemyAtRandomPos(ENEMY_NAME.HUGE_SHAMBLING_MOUND)
						} else {
							const suffix = enemy.name[baseName.length + 1]
							const name = ENEMY_NAME.HUGE_SHAMBLING_MOUND + ' ' + suffix
							spawnedEnemy = this.spawnEnemyAtRandomPos(name as ENEMY_NAME)
						}
					}
				}
			}
		}
	}

	removeEnemy(enemy: Enemy) {
		const pool = this.enemyPoolMap.get(enemy.name)
		pool.free(enemy)
	}

	private populateShuffleBag(shuffleEntry: ShuffleBagEntryOverTime, shuffleBag: ShuffleBag) {
		const items: GroundPickupConfigType[] = []
		for(let i = 0 ; i < shuffleEntry.entries.length; ++i) {
			const entry = shuffleEntry.entries[i]
			for(let ei = 0; ei < entry.count; ++ei) {
				items.push(entry.pickupType)
			}
		}
		if (items[0] === GroundPickupConfigType.CommonCurrencySmall || items[0] === GroundPickupConfigType.CommonCurrencyMedium || items[0] === GroundPickupConfigType.CommonCurrencyLarge){
			console.log(`populating shuffle bag with ${items.length} items`)
		}

		shuffleBag.setItems(items)
	}

	cleanUp() {
		this.xpDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(ITEM_DROP_SHUFFLE_BAG_ENTRIES[0], this.xpDropShuffleBag)
		this.nextShuffleBagConfig = ITEM_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextShuffleBagConfigIndex = 1

		this.currencyDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[0], this.currencyDropShuffleBag)
		this.nextCurrencyShuffleBagConfig = CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextCurrencyShuffleBagConfigIndex = 1

		this.twistExplosionNextCooldown = -1
		this.fuseRecycledShambers = false
		this.shamblerFusionRecycleCount = 0
		this.shamblerFusionRecycleCooldown = 0
		this.bruteTrioKillCount = 0
		this.recycledShamblersFusionCount = 0 
		this.nextRecycledShamblersFusionCooldown = 0
	}
}
