<template>
	<div class="container">
		<transition name="fade" class="text-container">
			<div v-show="!dismissedPhotoWarning">
				<div class="header" :lang="this.$i18n.locale">
					{{ $t('splash.photo_warning') }}
				</div>
				<div class="warning-text" :lang="this.$i18n.locale">
					{{ $t('splash.photo_text1') }}
					<br />
					<br />
					{{ $t('splash.photo_text2') }}
				</div>
				<br />
				<br />
				<div class="input-prompt" :lang="this.$i18n.locale">
					{{ $t('splash.press_any_button') }}
				</div>
			</div>
		</transition>
		<video v-show="dismissedPhotoWarning" ref="splashVideo" style="width: 100%; height: 100%; background-color: black" muted>
			<source src="../../../assets/videos/splash.webm" type="video/webm" />
			<source src="../../../assets/videos/splash.mp4" type="video/mp4" />
		</video>
	</div>
</template>

<script lang="ts">
import { Views } from '../store/ui-store'
import { mapActions, mapGetters } from 'vuex'
import { INPUT_DOWN_ACTION_EVENT_NAME } from '../../engine/client-player-input'
import { saveToSessionStorage } from '../../utils/local-storage'
import { SAW_SPLASH_KEY } from '../store/user-account-store'
import { Audio } from '../../engine/audio'

export default {
	name: 'SplashScreen',
	data() {
		return {
			dismissedPhotoWarning: false,
			videoPlayer: null,
		}
	},
	mounted() {
		this.videoPlayer = this.$refs.splashVideo

		this.videoPlayer.onended = () => {
			this.leaveScreen()
		}

		this.boundAnyKeyPressed = this.onAnyKeyPressed.bind(this)
		document.addEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.boundAnyKeyPressed) // needed for controller
		document.addEventListener('keydown', this.boundAnyKeyPressed) // any keyboard
		document.addEventListener('mousedown', this.boundAnyKeyPressed)
	},
	unmounted() {
		document.removeEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.boundAnyKeyPressed)
		document.removeEventListener('keydown', this.boundAnyKeyPressed)
		document.removeEventListener('mousedown', this.boundAnyKeyPressed)
	},
	computed: {
		...mapGetters('user', ['userType']),
	},
	methods: {
		...mapActions('ui', ['changeActiveView']),
		leaveScreen() {
			saveToSessionStorage(SAW_SPLASH_KEY, 'true')

			this.changeActiveView(Views.LANDING)
		},
		onAnyKeyPressed() {
			if (!this.dismissedPhotoWarning) {
				this.dismissedPhotoWarning = true

				this.videoPlayer.play()
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.container {
	width: 100%;
	height: 100%;

	.text-container {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.header {
			color: @red;
			text-align: center;
			margin-bottom: 50px;

			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 60px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}

		.warning-text {
			color: @offWhite;
			text-align: center;

			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}

			margin-right: 10%;
			margin-left: 10%;
		}
	}

	.input-prompt {
		text-align: center;
		color: #2ca665;

		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 30px;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
}

.fade-enter-active {
	transition: all 0.3s ease-out;
}

.fade-leave-active {
	transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>