import EntityStatList from "../../../stats/entity-stat-list"
import { StatOperator, StatType, StatUnit } from "../../../stats/stat-interfaces-enums"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { AllWeaponTypes, SecondaryWeaponType } from "../../weapon-types"
import { ThrownAcidBottle } from "./thrown-acid-bottle"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { PlayerProjectile, ProjectileInitialParams } from "../../../projectiles/projectile"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { SpritesheetAnimatorComponent } from "../../../engine/graphics/spritesheet-animator-component"
import { Player } from "../../../entities/player"
import { ObjectPool } from "../../../utils/third-party/object-pool"
import { AssetManager } from "../../../web/asset-manager"
import { ResourceType } from "../../weapon-definitions"
import { TrajectoryModPreset, TrajectoryModPresets } from "../../../projectiles/trajectory-presets"
import { degToRad } from "../../../utils/math"
import { nid, timeInSeconds } from "../../../utils/primitive-types"
import { GameState } from "../../../engine/game-state"
import { remove } from "lodash"
import { InGameTime } from "../../../utils/time"
import { Audio } from "../../../engine/audio"
import { GraphicsComponent } from "../../../engine/graphics/graphics-component"

const BEES_GFX_BASE_SIZE = 50

const BASE_ATTACK_SIZE = 20
const UNFROZEN_DURATION = 800
const FROZEN_DURATION = 300
const FULL_DURATION = FROZEN_DURATION + UNFROZEN_DURATION

interface Beehaviour {
	beeInitialFreezeTime: timeInSeconds
	beeCycleStartTime: timeInSeconds
	beeCycleAcc: timeInSeconds
	beeIsFrozen: boolean
}

// this is a nice trick to "subclass" a projectile and keep all the logic in one place
type BeeProjectile = PlayerProjectile & Beehaviour

export class BeesWeapon extends AutoFireSecondaryWeapon {
	weaponType: AllWeaponTypes = AllWeaponTypes.Bees

	static graphicsPool: ObjectPool
	static graphicsPoolPoison: ObjectPool
	static graphicsPoolQueen: ObjectPool
	static graphicsPoolPoisonQueen: ObjectPool
	static graphicsPoolWaspUpgrade: ObjectPool

	projectileCreationParams: ProjectileInitialParams

	activeProjectiles: nid[] = []

	constructor(player: Player, statList: EntityStatList) {
		super(player, statList)

		this.projectileCreationParams = {
			owningEntityId: player.nid,
			position: player.position,
			speed: this.statList.getStat(StatType.projectileSpeed),
			aimAngleInRads: 0,
			radius: this.statList.getStat(StatType.attackSize),
			trajectoryMods: [],
			collisionLayer: CollisionLayerBits.PlayerProjectile,
			statList: this.statList,
			resourceType: ResourceType.NONE,
			player: this.player,
			weaponType: this.weaponType,
			getGraphicsComponentAndPoolForSplit: this.getGraphicsComponentAndPoolForSplit.bind(this),
			onSplit: this.onSplit.bind(this),
		}
		
		if (!BeesWeapon.graphicsPool) {
			const beeSpriteSheet = AssetManager.getInstance().getAssetByName('bee-spritesheet').spritesheet
			const poisonBeeSpriteSheet =  AssetManager.getInstance().getAssetByName('poison-bee-spritesheet').spritesheet
			const queenBeeSpriteSheet = AssetManager.getInstance().getAssetByName('queen-bee-spritesheet').spritesheet
			const posionQueenBeeSpriteSheet = AssetManager.getInstance().getAssetByName('poison-queen-bee-spritesheet').spritesheet
			const waspUpgradeBeeSpriteSheet = AssetManager.getInstance().getAssetByName('wasp-bees-upgrade-spritesheet').spritesheet

			BeesWeapon.graphicsPool = new ObjectPool(() => {
				return new SpritesheetAnimatorComponent(null, beeSpriteSheet, 'movement', 0.1)
			}, {}, 25, 5)
	
			BeesWeapon.graphicsPoolPoison = new ObjectPool(() => {
				return new SpritesheetAnimatorComponent(null, poisonBeeSpriteSheet, 'movement', 0.1)
			}, {}, 25, 5)
	
			BeesWeapon.graphicsPoolQueen = new ObjectPool(() => {
				return new SpritesheetAnimatorComponent(null, queenBeeSpriteSheet, 'movement', 0.1)
			}, {}, 25, 5)
	
			BeesWeapon.graphicsPoolPoisonQueen = new ObjectPool(() => {
				return new SpritesheetAnimatorComponent(null, posionQueenBeeSpriteSheet, 'movement', 0.1)
			}, {}, 25, 5)

			BeesWeapon.graphicsPoolWaspUpgrade = new ObjectPool(() => {
				return new SpritesheetAnimatorComponent(null, waspUpgradeBeeSpriteSheet, 'movement', 0.1)
			}, {}, 25, 5)
		}
		
		this.statList.addConverter({
			inputStatType: StatType.projectileChainCount,
			inputRatio: 1.0,
			inputMinReserve: 0,
			inputStatUnit: StatUnit.Number,
			inputFreeRatio: 0,
			outputStatType: StatType.attackPierceCount,
			outputRatio: 1.0,
			outputStatOperator: StatOperator.SUM,
			outputStatUnit: StatUnit.Number,
		})
		this.statList.addStatBonus(StatType.projectileCount, StatOperator.SUM_THEN_MULTIPLY, 1.0)
	}

	override update(delta: number) {
		super.update(delta)
		
		const deadNids = []
		this.activeProjectiles.forEach((nid) => {
			const projectile = GameState.playerProjectileMap.get(nid) as BeeProjectile
			const gfx = projectile?.graphicsComponent as SpritesheetAnimatorComponent
			// handle deletions
			if (!projectile || !gfx) {
				deadNids.push(nid)
				return
			}

			// facing
			if (projectile.aimAngleInRads.between(Math.PI/2, Math.PI*3/2)) {
				gfx.spriteSheetAnimator.scale.x = Math.abs(gfx.spriteSheetAnimator.scale.x)
				// face left
			} else {
				gfx.spriteSheetAnimator.scale.x = Math.abs(gfx.spriteSheetAnimator.scale.x) * -1
				// face right
			}

			// special behavior
			projectile.beeCycleAcc += delta
			const beeWasFrozen = projectile.beeIsFrozen
			if (projectile.beeCycleAcc >= projectile.beeCycleStartTime) {
				const n = (projectile.beeCycleAcc - projectile.beeInitialFreezeTime) * 1000
				const int = n % FULL_DURATION
				if (int.between(UNFROZEN_DURATION, FULL_DURATION)) {
					projectile.beeIsFrozen = true
					if (beeWasFrozen != projectile.beeIsFrozen) {
						freezeBee(projectile)
					}
				} else {
					projectile.beeIsFrozen = false
					if (beeWasFrozen != projectile.beeIsFrozen) {
						unfreezeAndResetBee(projectile)
					}
				}
			} else {
				if (projectile.beeCycleAcc > projectile.beeInitialFreezeTime) {
					projectile.beeIsFrozen = true
					if (beeWasFrozen != projectile.beeIsFrozen) {
						freezeBee(projectile)
						clearBeeTarget(projectile)
						addBeeTrajectory(projectile)
					}
				}
			}
		})

		remove(this.activeProjectiles, (nid) => {
			return deadNids.includes(nid)
		})
	}

	fire() {
		const now = InGameTime.highResolutionTimestamp()
		const facing = this.player.facingDirection
		this.projectileCreationParams.aimAngleInRads = this.player.facingDirection < 0 ? degToRad(-60) : degToRad(-120) // come out of the backpack
		this.projectileCreationParams.radius = this.statList.getStat(StatType.attackSize)
		const speed = this.statList.getStat(StatType.projectileSpeed)
		const scale = this.statList.getStat(StatType.attackSize) / BEES_GFX_BASE_SIZE
		let count = this.statList.getStat('projectileCount')
		if (this.player.binaryFlags.has('bees-chance-for-triple') && Math.random() <= 0.1) {
			count *= 3
		}
		const angleInc = degToRad(60 / count)
		const projectiles: BeeProjectile[] = []

		this.playAudio()

		for (let i = 0; i < count; i++) {
			const pool = this.getGraphicsComponentPool()
			const gfx = pool.alloc() as SpritesheetAnimatorComponent

			this.projectileCreationParams.graphicsComponent = gfx
			this.projectileCreationParams.graphicsComponentPool = pool

			gfx.spriteSheetAnimator.scale.x = scale
			gfx.spriteSheetAnimator.scale.y = scale

			gfx.playAnimation('movement')

			this.projectileCreationParams.speed = speed * Math.getRandomFloat(1.0, 1.3)
			const projectile = PlayerProjectile.objectPool.alloc(this.projectileCreationParams) as BeeProjectile

			// schedule a bunch of special beehaviour
			projectile.beeInitialFreezeTime = (150 + Math.getRandomInt(0, 300)) / 1000
			projectile.beeCycleStartTime = (500 + Math.getRandomInt(0, 400)) / 1000
			projectile.beeCycleAcc = 0
			projectile.beeIsFrozen = false
			projectiles.push(projectile)
			this.projectileCreationParams.aimAngleInRads -= facing * angleInc // offset further bees
		}

		this.activeProjectiles = this.activeProjectiles.concat(projectiles.map((proj) => proj.nid))
	}

	forceStopFiring() {
        // don't need to do anything
    }

	getGraphicsComponentPool(): ObjectPool {
		if (this.player.binaryFlags.has('killer-wasps')) {
			return BeesWeapon.graphicsPoolWaspUpgrade
		} else {
			return BeesWeapon.graphicsPool
		}
	}

	getGraphicsComponentAndPoolForSplit(projectile: PlayerProjectile): { pool: ObjectPool, graphics: GraphicsComponent } {
		const pool = this.getGraphicsComponentPool()
		const scale = projectile.radius / BEES_GFX_BASE_SIZE
		const gfx = pool.alloc() as SpritesheetAnimatorComponent
		gfx.spriteSheetAnimator.scale.x = scale
		gfx.spriteSheetAnimator.scale.y = scale
		gfx.playAnimation('movement')

		return { pool, graphics: gfx }
	}

	onSplit(projectile: PlayerProjectile) {
		this.activeProjectiles.push(projectile.nid)
	}

	playAudio() {
		Audio.getInstance().playSfx('SFX_Enemy_Wasp_Hit', { volume: 0.7 })
		setTimeout(() => {
			Audio.getInstance().playSfx('SFX_Enemy_Wasp_Hit', { volume: 0.7 })
		}, 50)
		setTimeout(() => {
			Audio.getInstance().playSfx('SFX_Enemy_Wasp_Death', { volume: 0.55 })
		}, 100)
		setTimeout(() => {
			Audio.getInstance().playSfx('SFX_Enemy_Wasp_Hit', { volume: 0.7 })
		}, 150)
	}

	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		
		statList._actualStatValues.baseDamage = 8
		statList._actualStatValues.projectileCount = 3
		statList._actualStatValues.projectileSpreadAngle = 60
		statList._actualStatValues.projectileSpeed = 600
		statList._actualStatValues.attackPierceCount = 2
		statList._actualStatValues.skillDuration = 5
		statList._actualStatValues.attackRate = 1
		statList._actualStatValues.attackSize = BASE_ATTACK_SIZE

		statList._actualStatValues.allDamageMult = 1
		statList._actualStatValues.projectileChainDistanceMultiplier = 1
		statList._actualStatValues.projectileChainCount = 0
		statList._actualStatValues.projectileSplitCount = 0

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 8_000
		statList._actualStatValues.reloadInterval = 50
	}
}


function freezeBee(projectile: BeeProjectile) {
	projectile.speed = 0
}

function addBeeTrajectory(projectile: BeeProjectile) {
	projectile.trajectoryMods = [TrajectoryModPresets.get(TrajectoryModPreset.HomingModerate)]
}

function unfreezeAndResetBee(projectile: BeeProjectile) {
	projectile.entitiesCollided.length = 0
	projectile.speed = projectile.initialSpeed
	// projectile.homingTargetNid = null
	// projectile.homingTargetBackoffAcc = 0
}

function clearBeeTarget(projectile: BeeProjectile) {
	projectile.homingTargetNid = null
	projectile.homingTargetBackoffAcc = 0
}