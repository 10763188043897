<template>
	<div id="range">
		<input v-if="type === 'bgm'" :id="labelId" :value="getCurrentBGMVolume" class="slider" type="range" :min="min" :max="max" @change="updateBGM" />
		<input v-else-if="type === 'sfx'" :id="labelId" :value="getCurrentSFXVolume" class="slider" type="range" :min="min" :max="max" @change="updateSFX" />
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'Range',
	components: {},
	props: {
		labelId: {
			type: String,
			required: false,
			default: 'default',
		},
		min: {
			type: String,
			required: false,
			default: 'default',
		},
		max: {
			type: String,
			required: false,
			default: 'default',
		},
		type: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
	},
	computed: {
		...mapGetters('settings', ['getCurrentBGMVolume', 'getCurrentSFXVolume']),
	},
	data() {
		return {
			rangeInput: this.value,
		}
	},
	methods: {
		updateBGM(e) {
			this.$store.dispatch('settings/updateBGMVolume', e.target.value)
		},
		updateSFX(e) {
			this.$store.dispatch('settings/updateSFXVolume', e.target.value)
		},
		onChange() {
			this.$emit('update-range-input', this.rangeInput)
		},
		...mapActions('settings', ['updateBGMVolume', 'updateSFXVolume']),
	},
}
</script>

<style lang="less" scoped>
#range {
	width: 100%;
	.slider {
		width: 100%;
	}
}
</style>
