import { AnyColliderConfig, ColliderType } from "../engine/collision/colliders"
import { CollisionLayerBits } from "../engine/collision/collision-layers"

export interface VisualBlockerConfig {
	blockerYOffset: number
	blockerYRadius: number
	blockerXRadius: number
	alpha: number
}

type LightColor = { r: number, g: number, b: number, a: number }

export interface LightingConfig {
	colors: LightColor[]
	minScale: number
	maxScale: number
}

export interface PropConfig {
	/** WARNING: only for non-animated props right now */
	zeroHeight?: string[]
	/** adds this to the zIndex of the prop (changes rendering order based on y coord) */
	zOffset?: number

	// The scale! Does not affect colliders!!
	scale?: number

	/**
	 * one of the 5 collider types:
	 *
	 * **Circle**: type:'circle, position:[0,0], radius:30, 
	 *
	 * **Ellipse**: type:'ellipse, position:[0,0], rX:50, rY:30, 
	 *
	 * **Box**: type:'box, position:[0,0], width:100, height:120, 
	 *
	 * **Polygon**: type:'polygon', position:[0,0], vertices:[0,0, 100,0, 100,100, 0,100], 
	 *
	 * **Lookup**: type:'lookup', position:[0,0],
	 */

    colliders?: AnyColliderConfig[]
	collisionLayer?: CollisionLayerBits

	// Settings for transparency when player gets close
	visualBlocker?: VisualBlockerConfig

    // Forces the whole prop to be one asset (no sub props)
    rigged?: boolean

	// Is this prop part of a spritesheet? if so, what is the asset name?
	useSpriteSheet?: string
	// Is this spritesheet'd prop animated?
	// spriteSheetAnimation?: string

	// Is this prop just an image?
	isSingleImage?: boolean
	// flip the xScale on this prop randomly
	randomFlip?: boolean 

	useBgRenderer?: boolean
	lightingConfig?: LightingConfig

	accompanyMinRange?: number

	// Does colliding with this prop damage the player?
	damagePlayer?: boolean

    // If this is some sub prop that you want to change the z for
    dontLoadAsset?: boolean

    /** props assume their atlas comes from ${biome}PropAtls, but some have their own atlas */
	ignoreSharedAtlas?: boolean
	/** like ignoreSharedAtlas, but can specify the atlas' name */
	overrideAtlas?: string

    disableFileOrdering?: boolean
	disableSubPropZOffs?: boolean

	animate?: boolean
	overrideAnimSpeed?: number

	/** causes the prop to be destructible, requiring this many hits */
	isDestructible?: boolean
	hitsToDestroy?: number
	pickupsToDrop?: number
	onHitSound?: string
	onDestroySound?: string
}

interface PropConfigs {
	[index: string]: PropConfig
}

export const propConfigs: PropConfigs = {
	'flower-pot.png': {
		useSpriteSheet: 'forest-props',
		colliders: [{ type: ColliderType.Circle, position: [-10, 60], radius: 40 }],
		zOffset: 85,
	},
	'green-mushroom-02.png': {
		useSpriteSheet: 'forest-props',
		collisionLayer: CollisionLayerBits.NoProjectileProp,
		zOffset: 20
	},
	'small-grass-01.png': {
		useSpriteSheet: 'forest-props',
		zOffset: -20,
	},
	'small-grass-02.png': {
		useSpriteSheet: 'forest-props',
		zOffset: -20,
	},
	'small-grass-03.png': {
		useSpriteSheet: 'forest-props',
		zOffset: -20,
	},
	'small-green-group.png': {
		useSpriteSheet: 'forest-props',
		zOffset: 0,
	},
	'small-rocks-01.png': {
		useSpriteSheet: 'forest-props',
		zOffset: -999_999
	},
	'small-rocks-02.png': {
		useSpriteSheet: 'forest-props',
		zOffset: -999_999
	},
	
	'small-yellow-group.png': {
		useSpriteSheet: 'forest-props',
		zOffset: 0,
	},
	'tree-01.png': {
		useSpriteSheet: 'forest-props',
		colliders: [{ type: ColliderType.Circle, position: [0, 160], radius: 40 }],
		zOffset: 180,
		accompanyMinRange: 75,
		visualBlocker: {
			alpha: 0.5,
			blockerYOffset: 0,
			blockerYRadius: 150,
			blockerXRadius: 150
		}
	},
	'tree-02.png': {
		useSpriteSheet: 'forest-props',
		colliders: [{ type: ColliderType.Circle, position: [0, 200], radius: 40 }],
		zOffset: 180,
		accompanyMinRange: 75,
		visualBlocker: {
			alpha: 0.5,
			blockerYOffset: -20,
			blockerYRadius: 150,
			blockerXRadius: 225
		}
	},
	'tree-03.png': {
		useSpriteSheet: 'forest-props',
		colliders: [{ type: ColliderType.Circle, position: [0, 170], radius: 40 }],
		zOffset: 180,
		accompanyMinRange: 75,
		visualBlocker: {
			alpha: 0.5,
			blockerYOffset: 0,
			blockerYRadius: 150,
			blockerXRadius: 150
		}	
	},
	'yellow-mushroom-02.png': {
		useSpriteSheet: 'forest-props',
		zOffset: 25,
		collisionLayer: CollisionLayerBits.NoProjectileProp
	},

	'chest.png': {
		useSpriteSheet: 'forest-props',
		colliders: [{ type: ColliderType.Circle, position: [-5, 40], radius: 65 }],
		zOffset: 40,
		accompanyMinRange: 100,
		hitsToDestroy: 5,
		pickupsToDrop: 5,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'barrel': {
		useSpriteSheet: 'barrel',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [0, 10], radius: 43 }],
		zOffset: 40,
		accompanyMinRange: 100,
		hitsToDestroy: 3,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'lantern': {
		useSpriteSheet: 'lantern',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [0, 95], radius: 60 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'tanning-rack': {
		useSpriteSheet: 'tanning-rack',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [75, 75], radius: 45 }, { type: ColliderType.Circle, position: [20, 0], radius: 90 }],
		zOffset: 60,
		accompanyMinRange: 100,
		hitsToDestroy: 5,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'chest': {
		useSpriteSheet: 'chest',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-5, 20], radius: 60 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'flower-pot': {
		useSpriteSheet: 'flower-pot',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-10, 20], radius: 40 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 2,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',	
	},
	'papyrus-plant': {
		useSpriteSheet: 'papyrus-plant',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-10, 20], radius: 40 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 2,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',	
	},
	'magnet-pinecone': {
		useSpriteSheet: 'magnet-pinecone',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-10, 20], radius: 40 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 2,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',	
	},
	'grass-01.png': {
		useSpriteSheet: 'forest-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'grass-02.png': {
		useSpriteSheet: 'forest-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'grass-03.png': {
		useSpriteSheet: 'forest-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'grass-04.png': {
		useSpriteSheet: 'forest-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'sand-02.png': {
		useSpriteSheet: 'forest-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1,
	},
	'sand-01.png': {
		useSpriteSheet: 'forest-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.5,
	},
	//Fungi props
	'green-bone-mushrooms.png': {
		useSpriteSheet: 'fungi-props',
		collisionLayer: CollisionLayerBits.NoProjectileProp,
		zOffset: 20,
	},
	'small-fungi-grass-01.png': {
		useSpriteSheet: 'fungi-props',
		zOffset: -20,

		scale: 2,
	},
	'small-fungi-grass-02.png': {
		useSpriteSheet: 'fungi-props',
		zOffset: -20,
	},
	'small-fungi-grass-03.png': {
		useSpriteSheet: 'fungi-props',
		zOffset: -20,
	},
	'small-red-mushrooms.png': {
		useSpriteSheet: 'fungi-props',
		zOffset: 0,

		scale: 1.5,
	},
	'small-fungi-stone-01.png': {
		useSpriteSheet: 'fungi-props',
		zOffset: -999_999,

		scale: 2,
	},
	'small-fungi-stone-02.png': {
		useSpriteSheet: 'fungi-props',
		zOffset: -999_999,
	},
	
	'small-fungi-stone-03.png': {
		useSpriteSheet: 'fungi-props',
		zOffset: 0,

		scale: 2,
	},
	'fungi-tree-01.png': {
		useSpriteSheet: 'fungi-props',
		colliders: [{ type: ColliderType.Circle, position: [-10, 120], radius: 130 }],
		zOffset: 180,
		accompanyMinRange: 75
	},
	'fungi-tree-02.png': {
		useSpriteSheet: 'fungi-props',
		colliders: [{ type: ColliderType.Circle, position: [20, 120], radius: 120 }],
		zOffset: 180,
		accompanyMinRange: 75
	},
	'fungi-tree-03.png': {
		useSpriteSheet: 'fungi-props',
		colliders: [{ type: ColliderType.Circle, position: [-30, 160], radius: 130 }],
		zOffset: 180,
		accompanyMinRange: 75
	
	},
	'fungi-stump.png': {
		useSpriteSheet: 'fungi-props',
		colliders: [{ type: ColliderType.Box, position: [-110, 0], width:210, height:130 }],
		//colliders: [{ type: ColliderType.Circle, position: [-10, 10], radius: 100 }],//
		zOffset: 25,
		accompanyMinRange: 75,

		scale: 1.2,
	},

	'log': {
		useSpriteSheet: 'log',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [20, 30], radius: 90 }],
		zOffset: 40,
		accompanyMinRange: 100,
		hitsToDestroy: 3,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',

		scale: 1.2,
	},
	'fungi-lantern-post': {
		useSpriteSheet: 'fungi-lantern-post',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [0, 95], radius: 60 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',

	},
	'enchanted-stone': {
		useSpriteSheet: 'enchanted-stone',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [10, 20], radius: 70 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',

		scale: 1,
	},
	'skull': {
		useSpriteSheet: 'skull',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [20, 30], radius: 90 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 2,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',	

		scale: 1.2,
	},
	'fungi-skeleton': {
		useSpriteSheet: 'fungi-skeleton',
		animate: true,
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 75 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 3,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'fungi-grass-01.png': {
		useSpriteSheet: 'fungi-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 2,
	},
	'fungi-grass-02.png': {
		useSpriteSheet: 'fungi-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 2,
	},
	'fungi-grass-03.png': {
		useSpriteSheet: 'fungi-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.5,
	},
	'puddle-01.png': {
		useSpriteSheet: 'fungi-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.5,
	},
	'puddle-02.png': {
		useSpriteSheet: 'fungi-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 2,
	},
	'puddle-03.png': {
		useSpriteSheet: 'fungi-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 0.8,
	},
	//Hollow props
	'impaled-skelly-01.png': {
		useSpriteSheet: 'hollow-props',
		collisionLayer: CollisionLayerBits.NoProjectileProp,
		zOffset: 20,
	},
	'small-roots-01.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: -20,

		scale: 2,
	},
	'small-grass-arrow-01.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: -20,
	},
	'small-lantern-01.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: -20,
	},
	'small-roots-02.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: 0,

		scale: 1.2,
	},
	'small-stones-01.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: -999_999,

		scale: 2,
	},
	'small-skull-pile-01.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: -999_999,

		scale: 1,
	},
	
	'small-roots-03.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: 0,

		scale: 1.2,
	},
	'hollow-tree-01.png': {
		useSpriteSheet: 'hollow-props',
		colliders: [{ type: ColliderType.Circle, position: [0, 180], radius: 60 }],
		zOffset: 180,
		accompanyMinRange: 75
	},
	'hollow-tree-02.png': {
		useSpriteSheet: 'hollow-props',
		colliders: [{ type: ColliderType.Circle, position: [20, 200], radius: 50 }],
		zOffset: 180,
		accompanyMinRange: 75
	},
	'hollow-tree-03.png': {
		useSpriteSheet: 'hollow-props',
		colliders: [{ type: ColliderType.Circle, position: [0, 180], radius: 60 }],
		zOffset: 180,
		accompanyMinRange: 75
	
	},
	'med-skull-pile-01.png': {
		useSpriteSheet: 'hollow-props',
		zOffset: 25,
		collisionLayer: CollisionLayerBits.NoProjectileProp,

		scale: 1.2,
	},

	'fire-pit': {
		useSpriteSheet: 'fire-pit',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [0, 30], radius: 43 }],
		zOffset: 40,
		accompanyMinRange: 100,
		hitsToDestroy: 3,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',

		scale: 1.5,
	},
	'banner': {
		useSpriteSheet: 'banner',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [0, 95], radius: 40 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
		
		scale: 2,
	},
	'stone-eyebat': {
		useSpriteSheet: 'stone-eyebat',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-5, 20], radius: 60 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',

		scale: 1,
	},
	'cursed-tome': {
		useSpriteSheet: 'cursed-tome',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-10, 20], radius: 40 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 2,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',	

		scale: 1,
	},
	'cursed-gem': {
		useSpriteSheet: 'cursed-gem',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-10, 10], radius: 100 }],
		zOffset: 110,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 3,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',

		scale: 1,
	},
	'hollow-grass-01.png': {
		useSpriteSheet: 'hollow-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.5,
	},
	'hollow-grass-02.png': {
		useSpriteSheet: 'hollow-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1,
	},
	'hollow-grass-03.png': {
		useSpriteSheet: 'hollow-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.2,
	},
	'hollow-grass-04.png': {
		useSpriteSheet: 'hollow-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1,
	},
	'hollow-sand-01.png': {
		useSpriteSheet: 'hollow-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 2,
	},
	'hollow-sand-02.png': {
		useSpriteSheet: 'hollow-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.5,
	},
	//Tundra props
	'icy-rock-01.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: -15,
	},
	'small-icy-rocks-01.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: -20,
	},
	'small-icy-rocks-02.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: -20,
	},
	'small-icy-rocks-03.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: -20,
	},
	'icy-flowers-01.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: -16,
	},
	'icy-flowers-02.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: 10,
	},
	'icy-rock-02.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: -15,
	},
	'icy-chunk-01.png': {
		useSpriteSheet: 'tundra-props',
		zOffset: -15,
	},
	'icy-tree-01.png': {
		useSpriteSheet: 'tundra-props',
		colliders: [{ type: ColliderType.Circle, position: [10, 170], radius: 60 }],
		zOffset: 160,
		accompanyMinRange: 200,
		visualBlocker: {
			alpha: 0.5,
			blockerYOffset: 0,
			blockerYRadius: 150,
			blockerXRadius: 150
		}
	},
	'icy-tree-02.png': {
		useSpriteSheet: 'tundra-props',
		colliders: [{ type: ColliderType.Circle, position: [10, 190], radius: 60 }],
		zOffset: 160,
		accompanyMinRange: 200,
		visualBlocker: {
			alpha: 0.5,
			blockerYOffset: -20,
			blockerYRadius: 150,
			blockerXRadius: 225
		}
	},
	'icy-tree-03.png': {
		useSpriteSheet: 'tundra-props',
		colliders: [{ type: ColliderType.Circle, position: [10, 160], radius: 60 }],
		zOffset: 160,
		accompanyMinRange: 200,
		visualBlocker: {
			alpha: 0.5,
			blockerYOffset: 0,
			blockerYRadius: 150,
			blockerXRadius: 150
		}	
	},
	'giants-axe': {
		useSpriteSheet: 'giants-axe',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [-5, 45], radius: 75 }],
		zOffset: 40,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'giants-shield': {
		useSpriteSheet: 'giants-shield',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [33, 15], radius: 82 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 5,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'giants-sword': {
		useSpriteSheet: 'giants-sword',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [70, 100], radius: 65 }, { type: ColliderType.Circle, position: [-5, 100], radius: 70 }],
		zOffset: 60,
		accompanyMinRange: 100,
		hitsToDestroy: 4,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'icy-grave': {
		useSpriteSheet: 'icy-grave',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [8, 12], radius: 63 }],
		zOffset: 50,
		accompanyMinRange: 100,
		hitsToDestroy: 3,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',
	},
	'icy-flag': {
		useSpriteSheet: 'icy-flag',
		isDestructible: true,
		colliders: [{ type: ColliderType.Circle, position: [30, 130], radius: 45 }],
		zOffset: 85,
		accompanyMinRange: 136,
		hitsToDestroy: 3,
		pickupsToDrop: 1,
		onHitSound: 'SFX_Impact_Enemy_Rock',
		onDestroySound: 'SFX_Player_Shield_Block',	
	},
	'tundra-ice-stamp-01.png': {
		useSpriteSheet: 'tundra-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.5,
	},
	'tundra-dirt-stamp-01.png': {
		useSpriteSheet: 'tundra-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1,
	},
	'tundra-dirt-stamp-02.png': {
		useSpriteSheet: 'tundra-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1,
	},
	'tundra-snow-stamp-01.png': {
		useSpriteSheet: 'tundra-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 2,
	},
	'tundra-snow-stamp-02.png': {
		useSpriteSheet: 'tundra-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1,
	},
	'tundra-snow-stamp-03.png': {
		useSpriteSheet: 'tundra-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.75,
	},

	// placeholder for testing
	'grass-01-cursed.png': {
		useSpriteSheet: 'cursed-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'grass-02-cursed.png': {
		useSpriteSheet: 'cursed-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'grass-03-cursed.png': {
		useSpriteSheet: 'cursed-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'grass-04-cursed.png': {
		useSpriteSheet: 'cursed-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 3,
	},
	'sand-02-cursed.png': {
		useSpriteSheet: 'cursed-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1,
	},
	'sand-01-cursed.png': {
		useSpriteSheet: 'cursed-ground',
		zOffset: -999_998,
		useBgRenderer: true,

		scale: 1.5,
	},
	'god-rays-forest': {
		useSpriteSheet: 'god-rays',
		animate: true,
		zOffset: 165,
		lightingConfig: {
			colors:[
				{r: 0.153, g: 0.933, b: 0.561, a: 0.5}
			],
			minScale: 1.75,
			maxScale: 2.25,
		}
	},
	'god-rays-fungi': {
		useSpriteSheet: 'god-rays',
		animate: true,
		zOffset: 165,
		lightingConfig: {
			colors:[
				{r: 1, g: 0.835, b: 0.220, a: 1}
			],
			minScale: 1.75,
			maxScale: 2.25,
		},
	},
	'god-rays-tundra': {
		useSpriteSheet: 'god-rays',
		animate: true,
		zOffset: 165,
		lightingConfig: {
			colors:[
				{r: 1, g: 0.996, b: 0.882, a: 1}
			],
			minScale: 1.75,
			maxScale: 2.25,
		},
		
	},
	'fog-hollow': {
		useSpriteSheet: 'fog',
		animate: true,
		zOffset: 999_998,
		lightingConfig: {
			colors:[
				{r: 0, g: 0, b: 0, a: 1}
			],
			minScale: 1.75,
			maxScale: 2.25,
		}
	},

	
}
