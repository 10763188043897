// !!! THESE NAMES HAVE TO MATCH THE DATABASE !!!
// Can't be changed without a migration! New additions need griddle/database work!

export enum MetaPerkName {
    AllDamage = 'allDamage',
    MoveSpeed = 'moveSpeed',
    ExtraHearts = 'extraHearts',
    Splitting = 'splitting',
    Chaining = 'chaining',
    CooldownSpeed = 'cooldownSpeed',
    ReloadSpeed = 'reloadSpeed',
    GameSpeed = 'gameSpeed',
    AttackRate = 'attackRate',
    AttackSize = 'attackSize',
    PickUpRange = 'pickupRange',
    RandomPet = 'randomPet',
    Piercing = 'pierce',
    Knockback = 'knockback',
    Regeneration = 'regeneration',
    AilmentPotency = 'ailmentPotency',
    XPBoost = 'xpBoost',
    ProjectileCount = 'projectileCount',
    CharacterSkillCooldown = 'characterSkillCooldown'
}
