import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { POI, POIParams } from "./poi"

export interface KillEnemiesInCirclePOIParams extends POIParams {
	enemyCount: number
}

export class KillEnemiesInCirclePOI extends POI {
	static pool: ObjectPoolTyped<KillEnemiesInCirclePOI, KillEnemiesInCirclePOIParams>

	numberOfEnemiesToKill: number
	numberOfEnemiesKilled: number

	constructor() {
		super()

		this.showGraphics = true
	}

	override setDefaultValues(defaultValues: any, overrideValues?: KillEnemiesInCirclePOIParams) {
		super.setDefaultValues(defaultValues, overrideValues)
		if(overrideValues) {
			this.numberOfEnemiesToKill = overrideValues.enemyCount
			this.numberOfEnemiesKilled = 0

			this.updateBannerText()
		}
	}

	// Todo Replace w/ events when system is finalized
	onEnemyKilled() {
		if(this.isActive && this.isPlayerInZone) {
			this.numberOfEnemiesKilled++
			if(this.numberOfEnemiesKilled >= this.numberOfEnemiesToKill) {
				this.onComplete(true)
			}

			this.updateBannerText()
		}
	}

	updateBannerText() {
		this.setBannerText(`Defeat ${this.numberOfEnemiesToKill - this.numberOfEnemiesKilled} enemies`)
	}

	freeFromPool() {
		KillEnemiesInCirclePOI.pool.free(this)
	}
}