import { Emitter, Particle } from './emitter'
import {
	FadeAffectorConfig,
	FadeAffectorMode,
	getFloatFromPropConfig,
	ScaleAffectorConfig,
	ScaleAffectorMode,
	RotateAffectorMode,
	RotateAffectorConfig,
	ColorBlendAffectorConfig,
	ColorBlendAffectorMode,
	getColorFromPropConfig,
	FlipbookAffectorMode,
	FlipbookAffectorConfig,
	ForceAffectorConfig,
	ForceAffectorMode,
	getVec2FromPropConfig,
} from './emitterConfig'

export function aff_fadeAddProps(p: Particle) {
	p.affectors.fade = {
		constSpeed: 0,
		times: [0, 0],
		endDelta: 0,
	}
}

export function aff_fadeOnSpawn(p: Particle, cfg: FadeAffectorConfig) {
	if (cfg.mode === FadeAffectorMode.Constant) {
		p.affectors.fade.constSpeed = getFloatFromPropConfig(cfg.constSpeed)
	} else if (cfg.mode === FadeAffectorMode.OverLife) {
		p.affectors.fade.endDelta = getFloatFromPropConfig(cfg.target) - p.startColor[3]
	} else if (cfg.mode === FadeAffectorMode.InOutOverLife) {
		p.affectors.fade.endDelta = getFloatFromPropConfig(cfg.target) - p.startColor[3]
		p.affectors.fade.times[0] = getFloatFromPropConfig(cfg.times[0])
		p.affectors.fade.times[1] = getFloatFromPropConfig(cfg.times[1])
	}
}

export function aff_fade(dt: number, particles: Particle[], numParticles: number, cfg: FadeAffectorConfig) {
	if (cfg.mode === FadeAffectorMode.Constant) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			p.color[3] += getFloatFromPropConfig(cfg.constSpeed) * dt
		}
	} else if (cfg.mode === FadeAffectorMode.OverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			p.color[3] = p.startColor[3] + p.affectors.fade.endDelta * p.age
		}
	} else if (cfg.mode === FadeAffectorMode.InOutOverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const age = p.age
			const fadeTimes = p.affectors.fade.times

			if (fadeTimes[0] > 0 && age <= fadeTimes[0]) {
				p.color[3] = p.startColor[3] * (age / fadeTimes[0])
			} else if (age > fadeTimes[1]) {
				p.color[3] = p.startColor[3] + p.affectors.fade.endDelta * ((age - fadeTimes[1]) / (1.0 - fadeTimes[1]))
			}
		}
	}
}

export function aff_scaleAddProps(p: Particle) {
	p.affectors.scale = {
		constSpeed: 0,
		times: [0, 0],
		endDelta: 0,
	}
}

export function aff_scaleOnSpawn(p: Particle, cfg: ScaleAffectorConfig) {
	if (cfg.mode === ScaleAffectorMode.Constant) {
		p.affectors.scale.constSpeed = getFloatFromPropConfig(cfg.constSpeed)
	} else if (cfg.mode === ScaleAffectorMode.OverLife) {
		p.affectors.scale.endDelta = getFloatFromPropConfig(cfg.target) - p.startScale
	} else if (cfg.mode === ScaleAffectorMode.InOutOverLife) {
		p.affectors.scale.endDelta = getFloatFromPropConfig(cfg.target) - p.startScale
		p.affectors.scale.times[0] = getFloatFromPropConfig(cfg.times[0])
		p.affectors.scale.times[1] = getFloatFromPropConfig(cfg.times[1])
	}
}

export function aff_scale(dt: number, particles: Particle[], numParticles: number, cfg: ScaleAffectorConfig) {
	if (cfg.mode === ScaleAffectorMode.Constant) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			p.scale += p.affectors.scale.constSpeed * dt
		}
	} else if (cfg.mode === ScaleAffectorMode.OverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			p.scale = p.startScale + p.affectors.scale.endDelta * p.age
		}
	} else if (cfg.mode === ScaleAffectorMode.InOutOverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const age = p.age
			const times = p.affectors.scale.times

			if (times[0] > 0 && age <= times[0]) {
				p.scale = p.startScale * (age / times[0])
			} else if (age > times[1]) {
				p.scale = p.startScale + p.affectors.scale.endDelta * ((age - times[1]) / (1.0 - times[1]))
			}
		}
	} else if (cfg.mode === ScaleAffectorMode.InheritEmitter) {
		const emitter = Emitter.CurrentEmitter
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			p.scale = getFloatFromPropConfig(emitter.cfg.scale, 1.0) * emitter.scale
		}
	}
}

export function aff_rotateAddProps(p: Particle) {
	p.affectors.rotate = {
		constSpeed: 0,
		times: [0, 0],
		endDelta: 0,
	}
}

export function aff_rotateOnSpawn(p: Particle, cfg: RotateAffectorConfig) {
	if (cfg.mode === RotateAffectorMode.Constant) {
		p.affectors.rotate.constSpeed = getFloatFromPropConfig(cfg.constSpeed)
	} else if (cfg.mode === RotateAffectorMode.OverLife) {
		p.affectors.rotate.endDelta = getFloatFromPropConfig(cfg.target) - p.startRot
	} else if (cfg.mode === RotateAffectorMode.InOutOverLife) {
		p.affectors.rotate.endDelta = getFloatFromPropConfig(cfg.target) - p.startRot
		p.affectors.rotate.times[0] = getFloatFromPropConfig(cfg.times[0])
		p.affectors.rotate.times[1] = getFloatFromPropConfig(cfg.times[1])
	}
}

export function aff_rotate(dt: number, particles: Particle[], numParticles: number, cfg: RotateAffectorConfig) {
	if (cfg.mode === RotateAffectorMode.Constant) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			p.rot += p.affectors.rotate.constSpeed * dt
		}
	} else if (cfg.mode === RotateAffectorMode.OverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			p.rot = p.startRot + p.affectors.rotate.endDelta * p.age
		}
	} else if (cfg.mode === RotateAffectorMode.InOutOverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const age = p.age
			const times = p.affectors.rotate.times

			if (times[0] > 0 && age <= times[0]) {
				p.rot = p.startRot * (age / times[0])
			} else if (age > times[1]) {
				p.rot = p.startRot + p.affectors.rotate.endDelta * ((age - times[1]) / (1.0 - times[1]))
			}
		}
	}
}

export function aff_colorBlendAddProps(p: Particle) {
	p.affectors.colorBlend = {
		times: [0, 0],
		mid: [0, 0, 0],
		midDelta: [0, 0, 0],
		endDelta: [0, 0, 0],
	}
}

export function aff_colorBlendOnSpawn(p: Particle, cfg: ColorBlendAffectorConfig) {
	if (cfg.mode === ColorBlendAffectorMode.OverLife) {
		const endDelta = p.affectors.colorBlend.endDelta
		getColorFromPropConfig(cfg.target, endDelta)
		endDelta[0] -= p.startColor[0]
		endDelta[1] -= p.startColor[1]
		endDelta[2] -= p.startColor[2]
	} else if (cfg.mode === ColorBlendAffectorMode.InOutOverLife) {
		const midColor = p.affectors.colorBlend.mid
		const midDelta = p.affectors.colorBlend.midDelta
		const endDelta = p.affectors.colorBlend.endDelta
		const times = p.affectors.colorBlend.times
		getColorFromPropConfig(cfg.mid, midColor)
		getColorFromPropConfig(cfg.target, endDelta)
		endDelta[0] -= midColor[0]
		endDelta[1] -= midColor[1]
		endDelta[2] -= midColor[2]
		midDelta[0] = midColor[0] - p.startColor[0]
		midDelta[1] = midColor[1] - p.startColor[1]
		midDelta[2] = midColor[2] - p.startColor[2]
		times[0] = getFloatFromPropConfig(cfg.times[0])
		times[1] = getFloatFromPropConfig(cfg.times[1])
	}
}

export function aff_colorBlend(dt: number, particles: Particle[], numParticles: number, cfg: ColorBlendAffectorConfig) {
	if (cfg.mode === ColorBlendAffectorMode.OverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const age = p.age
			const end = p.affectors.colorBlend.endDelta
			const start = p.startColor
			p.color[0] = start[0] + end[0] * age
			p.color[1] = start[1] + end[1] * age
			p.color[2] = start[2] + end[2] * age
		}
	} else if (cfg.mode === ColorBlendAffectorMode.InOutOverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const age = p.age
			const midDelta = p.affectors.colorBlend.midDelta
			const endDelta = p.affectors.colorBlend.endDelta
			const mid = p.affectors.colorBlend.mid
			const times = p.affectors.colorBlend.times
			const start = p.startColor

			if (times[0] > 0 && age <= times[0]) {
				const ratio = age / times[0]
				p.color[0] = start[0] + midDelta[0] * ratio
				p.color[1] = start[1] + midDelta[1] * ratio
				p.color[2] = start[2] + midDelta[2] * ratio
			} else if (age > times[1]) {
				const ratio = (age - times[1]) / (1.0 - times[1])
				p.color[0] = mid[0] + endDelta[0] * ratio
				p.color[1] = mid[1] + endDelta[1] * ratio
				p.color[2] = mid[2] + endDelta[2] * ratio
			}
		}
	}
}

export function aff_flipbookAddProps(p: Particle) {
	p.affectors.flipbook = {
		times: [0, 0],
		animFrames: null,
		fps: 0,
		lengths: [0, 0],
		frame: 0,
	}
}

export function aff_flipbookOnSpawn(p: Particle, cfg: FlipbookAffectorConfig) {
	// Choose an anim to play
	const choice = Math.floor(Math.random() * p.owner.anims.length)
	const anim = p.owner.anims[choice]
	const flipbook = p.affectors.flipbook

	flipbook.animFrames = anim
	flipbook.frame = 0
	p.frame = anim[0]

	if (cfg.mode === FlipbookAffectorMode.OverLife) {
		flipbook.lengths[0] = anim.length
	} else if (cfg.mode === FlipbookAffectorMode.Constant) {
		flipbook.lengths[0] = anim.length
		flipbook.fps = getFloatFromPropConfig(cfg.constSpeed)
	} else if (cfg.mode === FlipbookAffectorMode.OverLifeWithHold) {
		const holdFrame = getFloatFromPropConfig(cfg.holdFrame)
		flipbook.lengths[0] = holdFrame + 1
		flipbook.lengths[1] = anim.length - holdFrame

		const times = flipbook.times
		times[0] = getFloatFromPropConfig(cfg.times[0])
		times[1] = getFloatFromPropConfig(cfg.times[1])
	}
}

export function aff_flipbook(dt: number, particles: Particle[], numParticles: number, cfg: FlipbookAffectorConfig) {
	if (cfg.mode === FlipbookAffectorMode.OverLife) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const flipbook = p.affectors.flipbook
			const length = flipbook.lengths[0]

			let frameIdx = length * p.age
			if (frameIdx >= length) {
				frameIdx = length - 1
			}

			p.frame = flipbook.animFrames[Math.floor(frameIdx)]
		}
	} else if (cfg.mode === FlipbookAffectorMode.Constant) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const flipbook = p.affectors.flipbook

			flipbook.frame += flipbook.fps * dt

			if (flipbook.frame >= flipbook.lengths[0]) {
				if (cfg.loop) {
					while (flipbook.frame >= flipbook.lengths[0]) {
						flipbook.frame -= flipbook.lengths[0]
					}
				} else {
					flipbook.frame = flipbook.lengths[0] - 1
				}
			}
			p.frame = flipbook.animFrames[Math.floor(flipbook.frame)]
		}
	} else if (cfg.mode === FlipbookAffectorMode.OverLifeWithHold) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const flipbook = p.affectors.flipbook
			const lengths = flipbook.lengths
			const age = p.age
			const times = flipbook.times
			let frameIdx = 0

			if (times[0] > 0 && age <= times[0]) {
				const ratio = age / times[0]
				frameIdx = lengths[0] * ratio
				p.frame = flipbook.animFrames[Math.floor(frameIdx)]
			} else if (age > times[1]) {
				const ratio = (age - times[1]) / (1.0 - times[1])
				frameIdx = lengths[0] - 1 + lengths[1] * ratio
				p.frame = flipbook.animFrames[Math.floor(frameIdx)]
			}
		}
	}
}

// I'm treating the velocity affector differently - it's a binary on/off thing and doesn't require a config
export function aff_velocity(dt: number, particles: Particle[], numParticles: number) {
	for (let i = 0; i < numParticles; ++i) {
		const p = particles[i]
		const vel = p.vel
		p.x += vel[0] * dt
		p.y += vel[1] * dt
	}
}

export function aff_forceAddProps(p: Particle) {
	p.affectors.force = {
		force: [0, -100],
	}
}

export function aff_forceOnSpawn(p: Particle, cfg: ForceAffectorConfig) {
	if (cfg.mode === ForceAffectorMode.Constant) {
		getVec2FromPropConfig(cfg.force, p.affectors.force.force)
	}
}

export function aff_force(dt: number, particles: Particle[], numParticles: number, cfg: ForceAffectorConfig) {
	if (cfg.mode === ForceAffectorMode.Constant) {
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const force = p.affectors.force.force
			p.vel[0] += force[0] * dt
			p.vel[1] += force[1] * dt
		}
	}
}
