// Not technically a table but just a stylized way to list data 
// "cells" vertically but wrap or move to a new row if there isn't enough width in the container
<template>
	<div id="flex-table" :style="{ width: containerWidth > 0 ? `${containerWidth}px` : '' }">
		<div class="table-body">
			<div v-for="(rowData, index) in tableRowData" :key="index" class="table-row" :style="{ flex: cellSize > 0 ? `0 1 ${cellSize}px` : '1 1 100px' }">
				<div v-for="(columnData, index) in rowData" :key="index" :style="cellFontSize" class="table-cell" :lang="this.$i18n.locale">
					{{ columnData }}
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
const FONT_SIZE = ['small', 'medium', 'large']

export default {
	name: 'FlexTable',
	components: {},
	data() {
		return {
			fontSizes: {
				small: { fontSize: '24px' },
				medium: { fontSize: '30px' },
				large: { fontSize: '40px' },
			},
		}
	},
	props: {
		containerWidth: {
			type: Number,
			default: 300,
			required: true,
		},
		cellSize: {
			type: Number,
			default: 100,
			required: true,
		},
		tableRowData: {
			type: Array,
			required: true,
		},
		fontSize: {
			type: String,
			required: true,
            default: 'small',
            validator: function (value) {
				return FONT_SIZE.includes(value)
			},
		},
	},
	computed: {
		// ...mapGetters('ui', ),
		cellFontSize() {
           if (this.fontSizes.hasOwnProperty(this.fontSize)){
            return this.fontSizes[this.fontSize]
           }
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#flex-table {
	.table-body {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		.table-row {
			flex: 0 1 100px;
			display: flex;
			.table-cell {
				width: 100%;
				display: flex;
				color: @cream;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 24px;
					letter-spacing: 2px;
				}

				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				&:first-child {
					text-align: left;
					color: @green;
				}
				&:last-child {
					justify-content: flex-end;
					text-align: right;
					margin-right: 10px;
				}
				&.large-font {
					font-size: 30px;
				}
				&.xl-large-font {
					font-size: 40px;
				}
			}
		}
	}
}
</style>
