<template>
	<div class="container-embellishment">
		<div class="embellishment">
			<img src="../../../assets/ui/divider/separator-embellishment.png" alt="" />
		</div>
		<div ref="container-embellishmentRef" class="container-embellishment-inner">
			<div class="content">
				<slot name="embellishment-content"></slot>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'EmbellishmentContainer',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {},
	methods: {},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.container-embellishment {
	display: flex;
	position: relative;
	// min-width: 400px;
	min-height: 100px;
	background-color: @darkBlue;
	filter: drop-shadow(7px 7px 0px black);
	margin: 30px 0px;
	display: flex;
	align-items: center;
	max-width: 674px;
	.container-embellishment-inner {
		.content {
			width: 100%;
			margin: 15px 0 15px 0px;
			padding: 0px 0px 0px 18px;
			// left: 22px;
			position: relative;
		}
	}
	.embellishment {
		pointer-events: none;
		position: absolute;
		top: -20px;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
</style>
