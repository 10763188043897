import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { ColliderType, EllipseColliderConfig } from "../../engine/collision/colliders"
import { SingleSpriteGraphicsComponent } from "../../engine/graphics/single-sprite-graphics-component"
import EntityStatList from "../../stats/entity-stat-list"
import { ObjectPool } from "../../utils/third-party/object-pool"
import { Player } from "../player"
import { HAZARD_ELIPSE_COLLIDER_CONFIG } from "./ellipse-hazard-params"
import { GroundHazard, GroundHazardParams } from "./ground-hazard"


export class TundraIceHazard extends GroundHazard {
	numEntitiesChained: number
	static pool: ObjectPool

	statList: EntityStatList
	graphics: SingleSpriteGraphicsComponent

	constructor(){
		super()

		this.graphics = new SingleSpriteGraphicsComponent('tundra-ground', 'ice-field.png', this)
		this.graphics.instancedSprite.zIndex = -999_997
		this.colliderComponent.setColliders(HAZARD_ELIPSE_COLLIDER_CONFIG)
	}

	override setDefaultValues(defaultValues: any, overrideValues?: GroundHazardParams): void {
		super.setDefaultValues(defaultValues, overrideValues)

		if(overrideValues) {
			const sprite = this.graphics.instancedSprite
			sprite.x = overrideValues.position.x
			sprite.y = overrideValues.position.y
			
			this.graphics.addToScene(true)
		}
	}

	override cleanup(): void {
		super.cleanup()

		const sprite = this.graphics.instancedSprite
		sprite.scaleX = 1
		sprite.scaleY = 1
		sprite.x = 0
		sprite.y = 0
		this.graphics.removeFromScene()
	}

	isPlayerOwned(): boolean {
		return false
	}

	onEntityEnterTrigger(entity: ColliderComponent) {
		if(entity.owner as Player) {
			entity.owner.iceFieldMovement = true
		}
	}
	
	onEntityLeaveTrigger(entity: ColliderComponent) {
		if(entity.owner as Player) {
			entity.owner.iceFieldMovement = false
		}
	}

	returnToPool() {
		// this won't ever be called
		TundraIceHazard.pool.free(this)
	}

	override setOffset(offset: SAT.Vector): void {
		super.setOffset(offset)

		const sprite = this.graphics.instancedSprite
		sprite.x = this.position.x
		sprite.y = this.position.y
	}

}