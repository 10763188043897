export enum ENEMY_NAME {
	CHAOS_SEER_BOSS = 'Chaos Seer',
	FINAL_CRAB = 'Final Crab',
	BLIMPIE = 'Blimpie',
	BLIMPIE_2 = 'Blimpie 2',
	BLIMPIE_3 = 'Blimpie 3',
	CRAB = 'Crab',
	CRYSTAL_SPIDER_1 = 'Crystal Spider 1',
	CRYSTAL_SPIDER_2 = 'Crystal Spider 2',
	CRYSTAL_SPIDER_3 = 'Crystal Spider 3',
	CRYSTAL_SPIDER_4 = 'Crystal Spider 4',
	DEMON_SKEETER = 'Demon Skeeter',
	DREAMER_BOSS = 'The Dreamer',
	ICE_DRAKE = 'Ice Drake',
	MR_CUDDLES = 'Mr. Cuddles',
	EYEBAT_1 = 'Eye-Bat 1',
	EYEBAT_2 = 'Eye-Bat 2',
	EYEBAT_3 = 'Eye-Bat 3',
	EYEBAT_CHOREO = 'Eye-Bat Choreo',
	GOBLIN = 'Goblin',
	LOOT_GOBLIN_JESTER = 'Jester Goblin',
	MOSQUITO = 'Mosquito',
	MOSQUITO_1 = 'Mosquito 1',
	MOSQUITO_2 = 'Mosquito 2',
	MOSQUITO_3 = 'Mosquito 3',
	PRISM_WISP = 'Prism Wisp',
	MUSHIE_1 = 'Mushie 1',
	MUSHIE_2 = 'Mushie 2',
	MUSHIE_3 = 'Mushie 3',
	PRISMFLY = 'Prismfly',
	PRISMFLY_1 = 'Prism Fly 1',
	PRISMFLY_2 = 'Prism Fly 2',
	PRISMFLY_ACT3 = 'PrismflyAct3',
	PRISM_FLY_BOSS = 'Prism Fly Boss',
	SHAMBLING_MOUND = 'Shambling Mound',
	SHAMBLING_MOUND_2 = 'Shambling Mound 2',
	SHAMBLING_MOUND_3 = 'Shambling Mound 3',
	SHAMBLING_MOUND_4 = 'Shambling Mound 4',
	SHAMBLING_MOUND_5 = 'Shambling Mound 5',
	HUGE_SHAMBLING_MOUND = 'Huge Shambling Mound',
	HUGE_SHAMBLING_MOUND_2 = 'Huge Shambling Mound 2',
	HUGE_SHAMBLING_MOUND_3 = 'Huge Shambling Mound 3',
	HUGE_SHAMBLING_MOUND_4 = 'Huge Shambling Mound 4',
	HUGE_SHAMBLING_MOUND_5 = 'Huge Shambling Mound 5',
	SHAMBLING_TOWER = 'Shambling Tower',
	HERMIT_CRAB = 'Hermit Crab',
	SHRIEKER = 'Shrieker',
	SHRIEKER_VINES = 'Vine Shrieker',
	SHRIEKER_KNIGHT = 'Knight Shrieker',
	SHRIEKER_KNIGHT_RED_PLUME = 'Red Plume Knight Shrieker',
	SHRIEKER_KNIGHT_BLUE_PLUME = 'Blue Plume Knight Shrieker',
	SHRIEKER_KNIGHT_BLUE_PLUME_FLYING = 'Flying Blue Plume Knight Shrieker',
	SHRIEKER_KNIGHT_BLUE_SPEAR = 'Blue Spear Knight Shrieker',
	SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING = 'Flying Blue Spear Knight Shrieker',
	SHRIEKER_KNIGHT_RED_SPEAR = 'Red Spear Knight Shrieker',
	THE_ONE_EYEBAT = 'The One Eye-Bat',
	BRUTE_SHAMBLER = `Brute Shambling Mound`,
	BRUTE_SHRIEKER = `Brute Shrieker`,
	BRUTE_EYE_BAT = `Brute Eye Bat`,
	SKELETON_MAGE_1 = 'Skeleton Mage 1',
	SKELETON_MAGE_2 = 'Skeleton Mage 2',
	SKELETON_MAGE_3 = 'Skeleton Mage 3',
	WASP_1 = 'Wasp 1',
	WASP_2 = 'Wasp 2',
	WASP_3 = 'Wasp 3',
	WISP_1 = 'Wisp 1',
	WISP_2 = 'Wisp 2',
	SPIDER_1 = 'Spider 1',
	SPIDER_2 = 'Spider 2',
	SNOW_FURY_1 = 'Snow Fury 1',
	SNOW_FURY_2 = 'Snow Fury 2',
	SNOW_FURY_3 = 'Snow Fury 3',
	EYESICLE_1 = 'Eyesicle 1',
	EYESICLE_2 = 'Eyesicle 2',
	WINTER_GOBLIN_1 = 'Winter Goblin 1',
	WINTER_GOBLIN_2 = 'Winter Goblin 2',
	WINTER_GOBLIN_3 = 'Winter Goblin 3',
	WINTER_GOBLIN_4 = 'Winter Goblin 4',
	WINTER_GOBLIN_5 = 'Winter Goblin 5',
	YETI = 'Yeti',
	SNOWBALL = 'Snowball',
	ICE_ELEMENTAL = 'Ice Elemental',
	ICE_ELEMENTAL_2 = 'Ice Elemental 2',
}

export const ALL_ENEMY_NAMES_VALUES = Object.values(ENEMY_NAME)
export const BOSS_ENEMY_NAMES = [ENEMY_NAME.PRISMFLY_ACT3, ENEMY_NAME.PRISM_FLY_BOSS, ENEMY_NAME.CHAOS_SEER_BOSS, ENEMY_NAME.FINAL_CRAB, ENEMY_NAME.DREAMER_BOSS, ENEMY_NAME.BRUTE_EYE_BAT, ENEMY_NAME.BRUTE_SHAMBLER, ENEMY_NAME.BRUTE_SHRIEKER, ENEMY_NAME.ICE_DRAKE, ENEMY_NAME.YETI, ENEMY_NAME.MR_CUDDLES]
export const BRUTE_TRIO_ENEMIES = [ENEMY_NAME.BRUTE_EYE_BAT, ENEMY_NAME.BRUTE_SHAMBLER, ENEMY_NAME.BRUTE_SHRIEKER]
export let CHAPTER_ENDING_BOSS_ENEMY_NAMES = [ENEMY_NAME.DREAMER_BOSS, ENEMY_NAME.ICE_DRAKE]
export const NORMAL_ENEMY_NAMES = ALL_ENEMY_NAMES_VALUES.filter((name) => !BOSS_ENEMY_NAMES.includes(name))
export const SNOWBALL_ENEMY_NAMES = [
	ENEMY_NAME.SNOWBALL,
]
export const SHAMBLING_MOUND_ENEMY_NAMES = [
	ENEMY_NAME.SHAMBLING_MOUND,
	ENEMY_NAME.SHAMBLING_MOUND_2,
	ENEMY_NAME.SHAMBLING_MOUND_3,
	ENEMY_NAME.SHAMBLING_MOUND_4,
	ENEMY_NAME.SHAMBLING_MOUND_5,
]
export const WINTER_GOBLIN_ENEMY_NAMES = [
	ENEMY_NAME.WINTER_GOBLIN_1,
	ENEMY_NAME.WINTER_GOBLIN_2,
	ENEMY_NAME.WINTER_GOBLIN_3,
	ENEMY_NAME.WINTER_GOBLIN_4,
	ENEMY_NAME.WINTER_GOBLIN_5,
]
export const BASIC_ENEMY_NAMES = [...SHAMBLING_MOUND_ENEMY_NAMES, ...WINTER_GOBLIN_ENEMY_NAMES]