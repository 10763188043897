import { Vector } from "sat"
import { AssetManager } from "../../web/asset-manager"
import { InstancedSpriteSheetAnimator } from "../../engine/graphics/instanced-spritesheet-animator"
import { AnimationTrack } from "../../spine-config/animation-track"
import { Pet } from "./pet"
import { timeInSeconds } from "../../utils/primitive-types"
import { GraphicsComponent } from "../../engine/graphics/graphics-component"

export class PetGFX extends GraphicsComponent {
	spriteSheetAnimator: InstancedSpriteSheetAnimator
	cageSpriteAnimator: InstancedSpriteSheetAnimator
	idleAnimation: AnimationTrack
	position: Vector
	cageSprite: PIXI.Sprite

	owner: Pet

	private _isCaged: boolean = true

	constructor(pet: Pet, uncaged?: boolean){
		super()

		this.owner = pet
		this._isCaged = !Boolean(uncaged)

		this.makeModel()
	}	

	getSkinName(): string {
		return 'default'
	}

	getAssetName(): string {
		const parent = this.owner as Pet
		return parent.config.appearance.asset
	}

	makeModel() {
		const parent = this.owner as Pet
		const asset = AssetManager.getInstance().getAssetByName(parent.config.appearance.asset)
		const sheet = asset.spritesheet as PIXI.Spritesheet
		this.spriteSheetAnimator = new InstancedSpriteSheetAnimator(sheet, AnimationTrack.IDLE)
		this.spriteSheetAnimator.zIndex = parent.position.y

		const cageAsset = AssetManager.getInstance().getAssetByName('pet-cage').spritesheet as PIXI.Spritesheet
		this.cageSpriteAnimator = new InstancedSpriteSheetAnimator(cageAsset, AnimationTrack.IDLE, 1, false)
		this.cageSpriteAnimator.position.x = parent.position.x
		this.cageSpriteAnimator.position.y = parent.position.y - 50
		this.cageSpriteAnimator.scale.x = 0.45
		this.cageSpriteAnimator.scale.y = 0.45
		this.cageSpriteAnimator.zIndex = parent.position.y + 165
	}

	update(delta: timeInSeconds) {
		this.spriteSheetAnimator.position.x = this.owner.position.x
		this.spriteSheetAnimator.position.y = this.owner.position.y
		this.spriteSheetAnimator.zIndex = this.owner.position.y + 109
	}

	removeCage() {
		this._isCaged = false
		this.cageSpriteAnimator.removeFromScene()
	}

	override addToScene() {
		if (this._isCaged) {
			this.cageSpriteAnimator.addToScene()
		}
		
		this.spriteSheetAnimator.addToScene()
	}

	override removeFromScene() {
		if (this._isCaged) {
			this.cageSpriteAnimator.removeFromScene()
		}
		
		this.spriteSheetAnimator.removeFromScene()
	}
}
