import { SecondaryWeapon } from "../../secondary-weapon";

export abstract class AutoFireSecondaryWeapon extends SecondaryWeapon {
    
    override update(delta: number) {
        if(this.cooldown.isUp() && !this.player.noFireWeapons) {
            this.fire()
            this.cooldown.useCharge()
        }
    }

    abstract fire()
    abstract forceStopFiring()
}