<template>
	<div id="chat">
		<div class="chat-inner" :class="{'large-feed': largeFeed}">
			<div ref="chat" class="messages">
				<div v-for="(msg, index) in activityMessages" :key="index" class="message" :lang="this.$i18n.locale">
					<div v-if="msg.type" :class="[msg.type + '-msg']">
						<span v-if="msg.type !== 'wishlist'" :class="[msg.type + '-timestamp']">
							[{{ getPrettyTime(msg.createdAt) }}]
						</span>
						<span v-if="msg.type !== 'wishlist'">
							{{ $t(`activityFeed.${getMessageTypeI18nKey(msg.type)}`, { ...msg }) }}
						</span>
						<a v-else :href="steamURL" target="_blank" class="wishlist">
							{{ $t(`activityFeed.${getMessageTypeI18nKey(msg.type)}`, { ...msg }) }}
						</a>
					</div>
				</div>
			</div>
			<form :class="{'chat-input': true, disabled: isDisabled}" :lang="this.$i18n.locale" onsubmit="return false">
				<input id="chat-input-text-el" ref="message" :disabled="isDisabled" v-model="message" type="text" maxlength="255" :placeholder="placeHolderChatText" autocomplete="off" :lang="this.$i18n.locale" @focus="setChatFocus(true)" @blur="setChatFocus(false)"/>
				<button @click="sendChat()" :class="{disabled: isDisabled}">
					<img src="../../../../assets/ui/buttons/play-icon.png" alt="" />
				</button>
			</form>
		</div>
	</div>
</template>

<script lang="ts">
import { format, parseISO } from 'date-fns'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ActivityMessageTypeI18n } from '../../store/activity-feed-store'
import { postChat } from '../../../utils/api/griddle-requests'
import { STEAM_PARAMS, STEAM_URL } from '../../steam-links'

export default {
	name: 'ActivityFeed',
	components: {},
	data() {
		return {
			steamURL: STEAM_URL+STEAM_PARAMS.story_activity_feed
		}
	},
	props: {
		largeFeed: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('activityFeed', []),
		...mapGetters('story', ['getSelectedStoryId', 'getSelectedStory']),
		...mapState('activityFeed', ['activityMessages', 'chatMessage', 'chatFocused']),
		message: {
			get() {
				return this.chatMessage
			},
			set(value) {
				this.setChatMessage(value)
			}
		},
		isDisabled() {
			return this.getSelectedStory.isSolo || this.getSelectedStory.isPublic
		},
		placeHolderChatText() {
			if (this.isDisabled) {
				return "Only private stories can chat"
			} else {
				return "[Enter] to chat"
			}
		}
	},
	methods: {
		...mapMutations('activityFeed', ['setChatMessage', 'setChatScrollbar', 'setChatFocus']),
		...mapActions('activityFeed', ['fetchLatestActivity']),
		scrollToBottom() {
			const chat = this.$refs.chat
			if (chat) {
				chat.scrollTop = chat.scrollHeight
			}
		},
		getMessageTypeI18nKey(mt) {
			return ActivityMessageTypeI18n[mt]
		},
		getPrettyTime(time: string) {
			const parseTime = parseISO(time)
			return format(parseTime, 'LLL d HH:mm')
		},
		sendChat() {
			if (this.chatMessage && this.chatMessage.length > 0) {
				postChat(this.getSelectedStoryId, this.chatMessage)
			}
			this.message = ''
		},
		keypressEventHandler(event) {
			if (event.code === 'Enter') {
				if (this.chatFocused) {
					this.sendChat()
				} else {
					// focus the chat
					const input = this.$refs.message
					input.focus()
				}
			}
		},
	},
	mounted() {
		this.scrollToBottom()
		this.fetchLatestActivity() // @TODO remove?

		this.setChatScrollbar(this.$refs.chat)
		document.addEventListener('keydown', this.keypressEventHandler)
	},
	unmounted() {
		document.removeEventListener('keydown', this.keypressEventHandler)
	}
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
#chat {
	pointer-events: all;
	min-width: 760px;
	width: 760px;
	background-color: @darkBlue;
	height: 100%; // unsure if this is even needed. Remove when finished chat in modals
	border-top-right-radius: 3px;
	.chat-inner {
		position: relative;
		width: 100%;
		border-radius: 7px;
		height: 264px;
		transition: all 0.25s ease;
		
		&.large-feed{
			height: 532px;
		}

		.messages {
			height: calc(100% - 60px);
			overflow-y: scroll;
			flex-direction: column;
			padding:5px 10px 0px 10px;
			.message {
				&[lang='en'] {
					.regularFont(@enSecondaryFontPT);
					font-variant: small-caps;
					font-size: 27px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				.won-msg, .voted-msg, .twist-won-msg {
					color: @green;
					.won-stamp {
					}
				}

				.chat-msg {
					color: @offWhite;
				}

				.lost-msg {
					color: @failureRed;
				}

				.wishlist {
					font-size: 27px;

					&:link {
						text-decoration: none;
						color: @skyBlue;
					}

					/* visited link */
					&:visited {
						color: @skyBlue;
					}

					/* mouse over link */
					&:hover {
						color: #ffffff;
					}

					/* selected link */
					&:active {
						color: @skyBlue;
					}
				}

			}
		}

		.chat-input {
			width: 100%;
			display: flex;
			border: solid 4px @darkBlue;
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;
			position: relative;

			&.disabled {
				cursor: default;
				border-image: url('~/assets/ui/buttons/basic-button-dark.png') 37 61 fill / 37px 61px;
				filter: brightness(1) grayscale();
			}

			input {
				outline: none;
				height: 60px;
				border: none;
				width: calc(100% - 70px);
				background-color: @cyprus;
				color: @offWhite;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPT);
					font-size: 32px;
					letter-spacing: 2px;
				}

				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
			}
			button {
				width: 70px;
				height: 60px;
				background-color: @pastelGreen;
				img {
					filter: invert(1);
				}
				&:hover {
					background-color: lighten(@pastelGreen, 2%);
				}
				&:active {
					background-color: darken(@pastelGreen, 2%);

				}

				&.disabled {
					pointer-events: none;
				}
			}
		}
	}
}
</style>
