<template>
	<div class="drop-down">
		<label class="label" :for="labelId" :lang="this.$i18n.locale">{{ labelText }}</label>
		<select name="drop-down" :id="labelId" :lang="this.$i18n.locale" v-model="handleChange">
			<option v-for="option in options" :key="option" class="option" :value="option.id" :style="{ fontSize: optionsFontSize + 'px' }">{{ option.name }}</option>
		</select>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'DropDown',
	components: {},
	data() {
		return {
			// selected: ''
			defaultFontSize: 24,
		}
	},
	props: {
		options: {
			type: Array,
			required: true,
			default: null,
		},
		labelText: {
			type: String,
			required: true,
			default: null,
		},
		labelId: {
			type: String,
			required: false,
			default: 'default',
		},
		selected: {
			type: String,
			required: false,
			default: null,
		},
	},
	watch: {},
	computed: {
		...mapState('uiScaling', ['newScale']),
		handleChange: {
			get() {
				return this.selected
			},
			set(newValue) {
				this.$emit('onClickFn', newValue)
			},
		},
		optionsFontSize() {
			return Math.floor(this.newScale * this.defaultFontSize)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.drop-down {
	&.center-label {
		.label {
			width: 100%;
			display: block;
			text-align: center;
		}
	}
	.label {
		margin-top: 10px;
		font-size: 32px;
		color: @offWhite;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	select {
		width: 100%;
		background-color: @darkBlue;
		color: @green;
		font-size: 24px;
		background-image: url("data:image/svg+xml,<svg height='20px' width='20px' viewBox='0 0 16 16' fill='%230DD14D' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
		background-repeat: no-repeat;
		// the larger the height/padding of the select input the more you'll have to adjust BG position y value
		background-position: 100% 13px;
		appearance: none !important;
		padding: 8px;
		border: none;
		.regularFont(@enSecondaryFontPTBold);
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
}
</style>
