import { Rectangle, Texture } from "pixi.js"
import { radians, timeInSeconds } from "../../utils/primitive-types"
import { InstancedSprite } from "./instanced-sprite"
import { Vector } from "sat"
import { Renderer } from "./renderer"
import { RenderQueue } from "./render-queue"

// pixi was 1 frame per second and then we sped it up
const MAGIC_DEFAULT_SPEED = 0.01

export class InstancedAnimatedSprite {

    loop: boolean = true

    frames: InstancedSprite[] = []
    trims: Rectangle[] = []
    originals: Rectangle[] = []

    position: Vector = new Vector()
    scale: Vector = new Vector(1, 1)
    zIndex: number = 0
    useTrimOffset: boolean = true

    activeFrame: number = 0
    activeFrameTime: timeInSeconds = 0
    private timeToNextFrame: timeInSeconds = MAGIC_DEFAULT_SPEED

    private _isPlaying: boolean = false
    private _isInScene: boolean = false
    private _isBgLayer: boolean

    private _animationSpeed: number = 1

    currentFrameSprite: InstancedSprite

    get animationSpeed() {
        return this._animationSpeed
    }

    set animationSpeed(value: number) {
        this._animationSpeed = value
        this.timeToNextFrame = MAGIC_DEFAULT_SPEED / value
    }

    private _rotation: radians = 0
    private _alpha: number = 1

    get rotation() {
        return this._rotation
    }
    set rotation(val) {
        this._rotation = val
        for (let i = 0; i < this.frames.length; ++i) {
            this.frames[i].rot = val
        }

        this.updateAnchorOffset()
    }

    get alpha() {
        return this._alpha
    }

    set alpha(val) {
        this._alpha = val
        for (let i = 0; i < this.frames.length; ++i) {
            this.frames[i].setAlpha(val)
        }

        this._color[0] = val
        this._color[1] = val
        this._color[2] = val
        this._color[3] = val
    }

    private _anchor: Vector = new Vector(0, 0)
    private _anchorPosOffset: Vector = new Vector()
    private _color: number[] = [1, 1, 1, 1]

    onComplete?: () => void
    onLoop?: () => void

    constructor(frames: Texture[]) {

        for (let i = 0; i < frames.length; ++i) {
            this.frames[i] = new InstancedSprite(frames[i], 0, 0, 0, undefined, undefined, undefined, undefined, this._color)
            this.trims[i] = frames[i].trim
            this.originals[i] = frames[i].orig
        }

        this.currentFrameSprite = this.frames[0]
    }

    addToScene(useBgLayer?: boolean) {
        if (!this._isInScene) {
            this._isInScene = true

            const activeSprite = this.currentFrameSprite
            activeSprite.x = this.position.x + this._anchorPosOffset.x
            activeSprite.y = this.position.y + this._anchorPosOffset.y
            activeSprite.zIndex = this.zIndex
            activeSprite.scaleX = this.scale.x
            activeSprite.scaleY = this.scale.y

            this._isBgLayer = Boolean(useBgLayer)
            const layer = useBgLayer ? Renderer.getInstance().bgRenderer : Renderer.getInstance().mgRenderer
            layer.addPropToScene(this as any) // hacky
        }
    }

    removeFromScene() {
        if (this._isInScene) {
            this._isInScene = false

            const layer = this._isBgLayer ? Renderer.getInstance().bgRenderer : Renderer.getInstance().mgRenderer
            layer.removeFromScene(this)
        }
    }

    setAnchor(x: number, y: number) {
        this._anchor.x = x
        this._anchor.y = y

        this.updateAnchorOffset()
    }

    render(renderQueue: RenderQueue) {
        this.currentFrameSprite.render(renderQueue)
    }

    play() {
        this._isPlaying = true
    }

    stop() {
        this._isPlaying = false
    }

    gotoAndPlay(frame: number) {
        this.switchFrames(frame)
        this._isPlaying = true
        this.activeFrameTime = 0
    }

    setColor(r: number, g: number, b: number, a: number) {
        this._color[0] = r
        this._color[1] = g
        this._color[2] = b
        this._color[3] = a
    }

    getColor(): number[] {
        return this._color
    }

    update(delta: timeInSeconds) {
        if (this._isPlaying) {
            this.activeFrameTime += delta
            if (this.activeFrameTime >= this.timeToNextFrame) {
                this.activeFrameTime = 0
                if (this.activeFrame === this.frames.length - 1) {
                    if (this.loop) {
                        this.switchFrames(0)

                        if (this.onLoop) {
                            this.onLoop()
                        }
                    } else {
                        if (this.onComplete) {
                            this.onComplete()
                        }

                        this._isPlaying = false
                    }
                } else {
                    this.switchFrames(this.activeFrame+1)
                }
            }
        }

        const activeSprite = this.currentFrameSprite
        activeSprite.x = this.position.x + this._anchorPosOffset.x
        activeSprite.y = this.position.y + this._anchorPosOffset.y
        
        activeSprite.scaleX = this.scale.x
        activeSprite.scaleY = this.scale.y
        activeSprite.zIndex = this.zIndex
    }

    private switchFrames(newFrame: number) {
        const nextFrame = this.frames[newFrame]
        
        this.currentFrameSprite = nextFrame
        this.activeFrame = newFrame

        this.updateAnchorOffset()
    }

    private updateAnchorOffset() {
        const original = this.originals[this.activeFrame]
        
        this._anchorPosOffset.x = Math.lerp(0, -(original.width/2), this._anchor.x)
        this._anchorPosOffset.y = Math.lerp(0, -(original.height/2), this._anchor.y)

        const trim = this.trims[this.activeFrame]
        if (trim) {
            // idk why this works but it's to keep sprites that change size look like they are in the same place
            const actualCenterX = trim.x - (original.width * 0.5)
            const actualCenterY = trim.y - (original.height * 0.5)

            const assumedCenterX = trim.width/2
            const assumedCenterY = trim.height/2

            this._anchorPosOffset.x += (actualCenterX + assumedCenterX)
            this._anchorPosOffset.y += (actualCenterY + assumedCenterY)
        }

        this._anchorPosOffset.scale(this.scale.x, this.scale.y)
        this._anchorPosOffset.rotate(this.rotation)
    }
}