import { Enemy } from "../enemy"
import { add, sub } from "../../../utils/math"
import { angleInRadsFromVector } from "../../../utils/vector"
import { timeInSeconds } from "../../../utils/primitive-types"
import { moveInDirection, updateAim } from "./fighting"

export const fleeingBehaviours = {

	flee(entity: Enemy, delta: timeInSeconds): void {
		const target = entity.target
		const targetVelocity = sub(entity.position, target)
		
		entity.shooting = false
		moveInDirection(targetVelocity, entity, delta)
		
		entity.velocityAngle = angleInRadsFromVector(entity.velocity)
	},
}