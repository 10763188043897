import { Wallet } from '../../game-data/currency'
import { UpgradeDefinition } from '../../upgrades/upgrade-definitions'
import { interleave } from '../../utils/array-util'
import { t } from '../../utils/i18n'

const initialState = {
	upgradesActive: [],
	hoveredUpgrade: null,
	selectedUpgrade: null,
	upgradeTrees: [],
	commonCurrency: 0,
	rareCurrency: 0

}
interface pausedState {
	upgradesActive: UpgradeDefinition[]
	hoveredUpgrade: []
	selectedUpgrade: [],
	upgradeTrees: [],
	commonCurrency: number,
	rareCurrency: number,
}

const store = {
	namespaced: true,
	state: initialState as pausedState,
	getters: {
		upgradesActive(state: PausedStoreState) {
			return state.upgradesActive
		},
		getUpgrade(state: PausedStoreState) {
			if (state.selectedUpgrade) {
				const upgradeTree = state.upgradeTrees.find((upgradeTree) => upgradeTree.parent === state.selectedUpgrade.upgradeParent)
				return upgradeTree
			}
		},
		getCommonCurrency(state: PausedStoreState) {
			return state.commonCurrency 
		},
		getRareCurrency(state: PausedStoreState) {
			return state.rareCurrency 
		},
	},
	mutations: {
		updateUpgrades(state: PausedStoreState, upgrades: any[]) {
			state.upgradesActive = []
			for(const upgrade of upgrades){
				let formattedDesc = parseDescription(upgrade.desc)
				upgrade.desc = formattedDesc
				state.upgradesActive.push(upgrade)
			}
		},
		setUpgradeTree(state: PausedStoreState, upgradeTree: any[]) {
			state.upgradeTrees = upgradeTree
		},
		updateCurrency(state: PausedStoreState, wallet: Wallet) {
			state.commonCurrency = wallet.commonCurrency
			state.rareCurrency = wallet.rareCurrency
		},
		reset(state: PausedStoreState) {
			state.upgradesActive = []
			state.hoveredUpgrade = null
			state.selectedUpgrade = null
			state.upgradeTrees = []
			state.commonCurrency = 0
			state.rareCurrency = 0
		}
	},
	actions: {
		postPauseUpgrades({ state: any, commit }, {upgradeDefintions, upgradeTrees}: { upgradeDefintions: any[], upgradeTrees: any[]}) {
			commit('updateUpgrades', upgradeDefintions)
			commit('setUpgradeTree', upgradeTrees)
		},
		postCurrency({state: any, commit}, {wallet}: {wallet: Wallet}) {
			commit('updateCurrency', wallet)
		}
	},
}

export type PausedStoreState = typeof initialState
export const PausedStore = typeof store

export const pausedStore = () => {
	return store
}

function parseDescription(description: string){
	const pattern = /\{([^\}]+)\}/g
	const delimiterPattern = /\{[^\}]*\}/
	let glossaryDefinitions = []
	const matches = description.matchAll(pattern)
	const splitDesc = description.split(delimiterPattern)
	for (const match of matches) {
		const transName = t('en', `${match[1]}_name`)
		const translation = `${transName || match[1]}`
		glossaryDefinitions.push(translation)
	}
	const ret = interleave(splitDesc, glossaryDefinitions).join('')
	return ret
}
