import { Vector } from "sat"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import CollisionSystem from "../../../engine/collision/collision-system"
import { distanceSquaredVV, getRandomPointInCircleRange } from "../../../utils/math"
import { timeInSeconds } from "../../../utils/primitive-types"
import AISystem from "../ai-system"
import { EnemyType, PersistedConfigInterface, ShriekConfig, SummonConfig } from "../ai-types"
import { Enemy } from "../enemy"
import { Renderer } from "../../../engine/graphics/renderer"
import { AssetManager } from "../../../web/asset-manager"
import { EffectConfig } from "../../../engine/graphics/pfx/effectConfig"
import { callbacks_addCallback } from "../../../utils/callback-system"

type ShriekState = {
    berserkedEnemiesCount?: number
    berserkedSelf?: boolean
    timeAcc?: timeInSeconds
}

type SummonState = {
    timeAcc?: timeInSeconds
}

const SHRIEK_CHECK_TIME: timeInSeconds = 0.25

export const persistedBehaviours = {
    shriek(entity: Enemy, configGeneric: PersistedConfigInterface, state: ShriekState, delta: timeInSeconds) {
        if (entity.isDead()) {
            return
        }

        const config = configGeneric as ShriekConfig

        if (!state.berserkedSelf) {
            Buff.apply(BuffIdentifier.Berserk, entity, entity)
            
            state.berserkedSelf = true
            state.timeAcc = 0
            state.berserkedEnemiesCount = 0
        }

        if (state.berserkedEnemiesCount < config.enemyCount) {
            state.timeAcc -= delta

            if (state.timeAcc <= 0) {
                const range = config.range
                const berserkCount = config.enemyCount - state.berserkedEnemiesCount

                const nearbyEnemies = CollisionSystem.getInstance().getEntitiesInArea(entity.position, range, CollisionLayerBits.HitEnemyOnly)
                nearbyEnemies.remove(entity.colliderComponent)

                nearbyEnemies.forEach((co) => {
                    const enemy = co.owner as Enemy
                    if (enemy.hasBuff(BuffIdentifier.Berserk)) {
                        nearbyEnemies.remove(co)
                    }
                })

                if (nearbyEnemies.length > berserkCount ) {
                    // need to make nearbyEnemies just the closest {shriek_number}
                    nearbyEnemies.forEach((colliderComponent) => {
                        colliderComponent.jankyCacheDistanceForSorting = distanceSquaredVV(colliderComponent.position, entity.position)
                    })

                    nearbyEnemies.sort((a,b) => a.jankyCacheDistanceForSorting - b.jankyCacheDistanceForSorting)

                    nearbyEnemies.length = berserkCount
                }

                for (let i =0; i < nearbyEnemies.length; ++i) {
                    const enemy = nearbyEnemies[i].owner as Enemy
                    Buff.apply(BuffIdentifier.Berserk, entity, enemy)
                }

                state.berserkedEnemiesCount += nearbyEnemies.length
                state.timeAcc += SHRIEK_CHECK_TIME
            }
        }        
    },
    summon(entity: Enemy, configGeneric: PersistedConfigInterface, state: SummonState, delta: timeInSeconds) {
        if (entity.isDead()) {
            return
        }

        const config = configGeneric as SummonConfig

        if (!state.timeAcc) {
            state.timeAcc = delta
        } else { 
            state.timeAcc += delta
        }

        if (state.timeAcc >= config.cooldownTime) {
            state.timeAcc = 0
            const aiSys = AISystem.getInstance()
            let pfxConfig: EffectConfig
            let renderer: Renderer
            if (config.pfx) {
                renderer = Renderer.getInstance()
                pfxConfig = AssetManager.getInstance().getAssetByName(config.pfx).data
            }

            const positions = []

            for (let i = 0; i < config.enemyCount; ++i) {
                const pos = getRandomPointInCircleRange(entity.x, entity.y, config.minSpawnRange, config.maxSpawnRange, undefined)
                if (!Boolean(config.summonDelay)) {
                    aiSys.spawnEnemyAtPos(config.enemy, pos.x, pos.y)
                }

                if (pfxConfig) {
                    renderer.addOneOffEffectByConfig(pfxConfig, pos.x, pos.y, undefined, undefined, config.pfxTime, true)
                    positions.push(pos)
                }
            }

            if (config.summonDelay) {
                callbacks_addCallback(entity, () => {
                    for (let i = 0; i < config.enemyCount; ++i) {
                        const pos = positions[i]
                        aiSys.spawnEnemyAtPos(config.enemy, pos.x, pos.y)
                    }
                }, config.summonDelay)
            }
        }
    }
}
