<template>
	<div :lang="this.$i18n.locale" v-html="parseDescription(descriptionString)"></div>
</template>

<script lang="ts">
import { interleave } from '../../../utils/array-util'
import { t } from '../../../utils/i18n'
export default {
	name: 'Description',
	components: {},
	props: {
		descriptionString: {
			type: String,
			required: true,
			default: '',
		},
	},
	data() {
		return {}
	},
	watch: {},
	methods: {
		parseDescription(description: string) {
			const pattern = /\{([^\}]+)\}/g
			const delimiterPattern = /\{[^\}]*\}/
			let glossaryDefinitions = []
			const matches = description.matchAll(pattern)
			const splitDesc = description.split(delimiterPattern)
			for (const match of matches) {
				// TODO don't hardcode lang
				const transName = t('en', `${match[1]}_name`)
				const translation = `<span id="${match[1]}" definition="${t('en', `${match[1]}_def`)}" class="glossary-term">${transName || match[1]}</span>`
				glossaryDefinitions.push(translation)
			}
			const ret = interleave(splitDesc, glossaryDefinitions).join('')
			return ret
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

/** Why doesn't this work scoped*/
div {
	color: @offWhite;
	text-align: center;
	&[lang='en'] {
		.regularFont(@enSecondaryFontPTBold);
		font-variant: small-caps;
		font-size: 30px;
	}

	&[lang='zh'] {
		.regularFont(@zhHansHeaderFont);
	}
	:deep(.glossary-term) {
		border-bottom: 1px dashed #000;
		color: @green;
		&:before {
			content: attr(definition); /* here's the magic */
			position: absolute;

			/* vertically center */
			top: 50%;
			transform: translateY(-50%);

			/* move to right */
			left: 100%;
			margin-left: 15px; /* and add a small left margin */

			/* basic styles */
			width: 200px;
			padding: 10px;
			border-radius: 10px;
			background: #000;
			color: #fff;
			text-align: center;

			display: none; /* hide by default */
		}

		&:hover:before {
			display: block;
		}
	}
}
</style>
