import { EntityType, IEntity } from "../entities/entity-interfaces"
import { GameState, getNID } from "../engine/game-state"
import { timeInSeconds, timeInMilliseconds, gameUnits, radians } from "src/utils/primitive-types"
import { BeamConfigType, BeamGraphics } from "./beam-graphics"
import { ColliderComponent } from "../engine/collision/collider-component"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import { ComponentOwner } from "../engine/component-owner"
import { Vector } from "sat"
import { BoxCollider, BoxColliderConfig, ColliderType } from "../engine/collision/colliders"
import { Enemy } from "../entities/enemies/enemy"
import CollisionSystem from "../engine/collision/collision-system"
import EntityStatList from "../stats/entity-stat-list"
import { DamageSource } from "../projectiles/damage-source"
import { StatType } from "../stats/stat-interfaces-enums"
import { RANDOM_CHANCE_FOR_MASSIVE_BEAM_DAMAGE } from "../game-data/player-formulas"
import { distanceSquaredVV } from "../utils/math"
import { AllWeaponTypes } from "../weapons/weapon-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { debugConfig } from "../utils/debug-config"
import { vModelCheckbox } from "vue"
import { GroundPickup } from "../entities/pickups/ground-pickup"

export interface BeamParams {
	x: gameUnits
	y: gameUnits

	angle: radians
	width: gameUnits
	length: gameUnits

	statList: EntityStatList

	noCollisions?: boolean

	noGfx?: boolean
	beamConfigType?: BeamConfigType

	maxLength?: gameUnits
	color?: number
	alpha?: number

	noDoubleStrike?: boolean

	getDamageScaleFunction?: () => number
	overrideGetKnockbackFunction?: (mutableEntityPos: Vector, beam: Beam) => Vector
	onHitCallback?: (enemy: Enemy) => void
	onPickupHitCallback?: (pickup: GroundPickup) => void

	hasRandomChanceForMassiveDamage?: boolean
	weaponType?: AllWeaponTypes
	isPrimaryWeaponBeam?: boolean
}

export const DEFAULT_BEAM_COLLIDER_CONFIG: BoxColliderConfig = {
	type: ColliderType.Box,
	width: 1,
	height: 1,
	position: [0, 0],
	angle: 0,
}

export class Beam implements IEntity, ComponentOwner, DamageSource {
	static pool: ObjectPoolTyped<Beam, BeamParams>

	nid: number
	entityType: EntityType = EntityType.Beam
	timeScale: number = 1
	
	numEntitiesChained: number = 0
	numEntitiesPierced: number = 0

	angle: radians = 0
	originalAngle: radians = 0
	numAngleIncrements: number = 0 // used by nikola scope laser light show

	width: gameUnits = 0
	length: gameUnits = 0
	maxLength: gameUnits = 0

	color: number
	alpha: number = 1

	beamGFX: BeamGraphics
	
	colliderComponent: ColliderComponent
	colliderConfigs: BoxColliderConfig[]

	statList: EntityStatList
	noCollisions: boolean

	position: Vector

	attackCooldown: number = 0
	firedLastFrame: boolean = false
	hitEntities: Map<number, ColliderComponent>
	alreadyHitEntities: Set<number>

	noDoubleStrike: boolean = false

	hasRandomChanceForMassiveDamage: boolean = false
	dealMassiveDamage: boolean = false
	weaponType: AllWeaponTypes

	beamConfigType: BeamConfigType

	isPrimaryWeaponBeam: boolean

	getDamageScaleFunction?: () => number
	overrideGetKnockbackFunction?: (mutableEntityPos: Vector, beam: Beam) => Vector
	onHitCallback?: (enemy: Enemy) => void
	onPickupHitCallback?: (pickup: GroundPickup) => void

	get showImmediateDamageNumber() {
		return this.isPrimaryWeaponBeam
	}

	private _endPosition: Vector
	private _addedColliders: boolean = false
	private _changedLayer: boolean = false

	private _colliderInScene: boolean = false
	get isColliderInScene(): boolean {
		return this._colliderInScene
	}

	get x(): number {
		return this.position.x
	}

	get y(): number {
		return this.position.y
	}

	constructor() {
		this.position = new Vector()
		this._endPosition = new Vector()
		this.nid = getNID(this)
		
		const colliderConfigCopy = {} as BoxColliderConfig // TS doesn't know what's going on
		Object.assign(colliderConfigCopy, DEFAULT_BEAM_COLLIDER_CONFIG)
		colliderConfigCopy.position = [0, 0]
		this.colliderConfigs = [colliderConfigCopy] 

		this.colliderComponent = new ColliderComponent(this.colliderConfigs, this, CollisionLayerBits.PlayerProjectile, this.onCollision.bind(this), false, true, true)
		this.colliderComponent.onCollisionChecksDoneCallback = this.onCollisionChecksDone.bind(this)

		this.hitEntities = new Map()
		this.alreadyHitEntities = new Set()

		this.beamGFX = new BeamGraphics(this)
	}

	setDefaultValues(defaultValues: any, overrideValues?: BeamParams) {
		if(overrideValues) {
			this.position.x = overrideValues.x
			this.position.y = overrideValues.y

			this.angle = overrideValues.angle
			this.originalAngle = this.angle
			this.width = overrideValues.width - 22 // temp to match up the GFX with colliders better
			this.length = overrideValues.length
			this.maxLength = overrideValues.maxLength
			this.color = overrideValues.color
			this.alpha = overrideValues.alpha !== undefined ? overrideValues.alpha : 1
			this.beamConfigType = overrideValues.beamConfigType
			this.isPrimaryWeaponBeam = Boolean(overrideValues.isPrimaryWeaponBeam)
			this.noDoubleStrike = Boolean(overrideValues.noDoubleStrike)
			this.getDamageScaleFunction = overrideValues.getDamageScaleFunction
			this.overrideGetKnockbackFunction = overrideValues.overrideGetKnockbackFunction
			this.onHitCallback = overrideValues.onHitCallback
			this.onPickupHitCallback = overrideValues.onPickupHitCallback

			this.statList = overrideValues.statList
			this.attackCooldown = 0
			this.hasRandomChanceForMassiveDamage = Boolean(overrideValues.hasRandomChanceForMassiveDamage)

			this.weaponType = overrideValues.weaponType === undefined ? null : overrideValues.weaponType
		
			this.colliderConfigs[0].angle = overrideValues.angle
			this.colliderConfigs[0].width = overrideValues.length // intentional reversal
			this.colliderConfigs[0].height = overrideValues.width
			this.colliderComponent.setColliders(this.colliderConfigs)

			this.noCollisions = Boolean(overrideValues.noCollisions)
			if (!this.noCollisions) {
				CollisionSystem.getInstance().addCollidable(this.colliderComponent)
				this._colliderInScene = true
				if (debugConfig.collisions.drawBeamColliders) {
					this.colliderComponent.drawColliders()
				}
			}

			if (!overrideValues.noGfx) {
				this.beamGFX.addToScene(this.beamConfigType)
			}

			GameState.addEntity(this)
		}
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		if (this.beamGFX && this.beamGFX.isInScene) {
			this.beamGFX.update(this, delta)
		}

		this.attackCooldown -= delta

		this.colliderComponent.forceDirty = true
	}

	cleanup() {
		if (this.beamGFX.isInScene) {
			this.beamGFX.removeFromScene()
		}

		this.dealMassiveDamage = false
		this.firedLastFrame = false
		this.numAngleIncrements = 0
		this.statList = null
		this.hitEntities.clear()

		if (!this.noCollisions) {
			CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
		}
		
		if (debugConfig.collisions.drawBeamColliders) {
			this.colliderComponent.stopDrawingColliders()
		}

		if (this._addedColliders) {
			this._addedColliders = false
			this.colliderConfigs = [this.colliderConfigs[0]]
			this.colliderComponent.setColliders(this.colliderConfigs)
		}

		if (this._changedLayer) {
			this.colliderComponent.setLayer(CollisionLayerBits.PlayerProjectile)
			this._changedLayer = false
		}

		this.onHitCallback = null
		this.onPickupHitCallback = null
		this.getDamageScaleFunction = null
		this.overrideGetKnockbackFunction = null

		GameState.removeEntity(this)
	}

	onCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
		if (otherEntity.owner.entityType === EntityType.GroundHazard) { // temporal distortion
			return
		}

		if (this.noDoubleStrike) {
			if (this.alreadyHitEntities.has(otherEntity.owner.nid)) {
				return
			} else {
				this.alreadyHitEntities.add(otherEntity.owner.nid)
			}
		}
		
		this.hitEntities.set(otherEntity.owner.nid, otherEntity)
	}

	onCollisionChecksDone() {
		if(this.hitEntities.size > 0) {
			const pierceCount = this.statList.getStat(StatType.attackPierceCount)
			const entities = Array.from(this.hitEntities.values())

			if (entities.length > pierceCount) {
				for (let i =0; i < entities.length; ++i) {
					entities[i].jankyCacheDistanceForSorting = distanceSquaredVV(entities[i].position, this.position)
				}
				
				entities.sort((a, b) => {
					return a.jankyCacheDistanceForSorting - b.jankyCacheDistanceForSorting
				})

				entities.length = pierceCount + 1
				const maxDistSquared = entities[entities.length-1].jankyCacheDistanceForSorting
				const dist = Math.sqrt(maxDistSquared)

				this.length = dist
			} else {
				this.length = this.maxLength
			}

			if(this.attackCooldown <= 0) {
				if(this.hasRandomChanceForMassiveDamage) {
					if(Math.random() <= RANDOM_CHANCE_FOR_MASSIVE_BEAM_DAMAGE) {
						this.dealMassiveDamage = true
					} else {
						this.dealMassiveDamage = false
					}
				}

				this.numEntitiesPierced = Math.min(entities.length, pierceCount)
				const damageScale = this.getDamageScaleFunction ? this.getDamageScaleFunction() :  1
				for (const collider of entities) {
					if (collider.owner.isEnemy)  {
						const enemy = collider.owner as Enemy
						enemy.onHitByDamageSource(this, damageScale)
						if (this.onHitCallback) {
							this.onHitCallback(enemy)
						}
					} else if (collider.owner.entityType === EntityType.Pickup) {
						if (this.onPickupHitCallback) {
							this.onPickupHitCallback(collider.owner)
						}
					}
				}

				this.attackCooldown += this.getAttackTickTime()
				this.firedLastFrame = true
			} else {
				this.firedLastFrame = false
			}
			
			this.hitEntities.clear()
		} else {
			this.length = this.maxLength

			if(this.attackCooldown <= 0) {
				this.attackCooldown += this.getAttackTickTime()
				this.firedLastFrame = true
			} else {
				this.firedLastFrame = false
			}
		}
	}

	setColliderProperties(length: number, width: number, angle: number) {
		for (let i =0; i < this.colliderConfigs.length; ++i) {
			this.colliderConfigs[i].width = length // intentional reversi
			this.colliderConfigs[i].height = width 
		}

		this.length = length
		this.maxLength = length
		this.width = width

		this.colliderComponent.setColliders(this.colliderConfigs)
		this.colliderComponent.setAngleBoxColliderOnly(angle, true) 
	}

	addColliderToScene() {
		CollisionSystem.getInstance().addCollidable(this.colliderComponent)
		this.attackCooldown = 0
		this.firedLastFrame = false
		this._colliderInScene = true
		if (debugConfig.collisions.drawBeamColliders) {
			this.colliderComponent.drawColliders()
		}
	}

	removeColliderFromScene() {
		CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
		this._colliderInScene = false
		if (debugConfig.collisions.drawBeamColliders) {
			this.colliderComponent.stopDrawingColliders()
		}
	}

	addColliders(colliders: BoxColliderConfig[]) {
		this.colliderConfigs = this.colliderConfigs.concat(colliders)
		this.setColliderProperties(this.length, this.width, this.angle)

		this._addedColliders = true
	}
	
	revertColliders() {
		if (this._addedColliders) {
			this._addedColliders = false
			this.colliderConfigs = [this.colliderConfigs[0]]
			this.colliderComponent.setColliders(this.colliderConfigs)
		}
	}

	changeCollisionLayer(layer: CollisionLayerBits) {
		this.colliderComponent.setLayer(layer)
		this._changedLayer = true
	}

	setAngle(angle: radians) {
		this.colliderComponent.setAngleBoxColliderOnly(angle, true)
		this.angle = angle

		this._endPosition.x = this.length
		this._endPosition.y = this.width
		this._endPosition.rotate(this.angle)
		this._endPosition.add(this.position)
	}

	getStartPosition(): Vector {
		return this.position
	}

	getEndPosition(): Vector {
		return this._endPosition
	}

	isPlayerOwned(): boolean {
		return true
	}

	getAttackTickTime() {
		return 1 / this.statList.getStat(StatType.attackRate)
	}

	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		if (this.overrideGetKnockbackFunction) {
			return this.overrideGetKnockbackFunction(mutableEntityPos, this)
		}

		return mutableEntityPos.sub(this.position).normalize()
	}
}

export function makeBeamPool() {
	if (!Beam.pool) {
		Beam.pool = new ObjectPoolTyped<Beam, BeamParams>(() => new Beam(),{}, 5, 1)
   	}
}