<template>
	<div id="chapter-summary">
		<div class="main">
			<div class="chapter-select">
				<Panel id="chapter-selector" :height="225">
					<template #panel-content>
						<Header :header-text="selectedStory.name" :text-alignment="'center'" :font-size="'large'"></Header>
						<Header :header-text="`Authored by: ${selectedStory.author}`" :text-alignment="'center'" :font-size="'medium'" :font-color="'green'" :enableDropShadow="false"></Header>
						<div class="mutators">
							<ScrollableItemsContainer @on-click-fn="arrowButton" :show-scroll-buttons="this.selectedStory.chapter > 11" :enable-arrow-background="false">
								<template #arrow-content>
									<div class="chapter-container" id="moveable">
										<div class="chapter-list">
											<div class="chapter-buttons">
												<TextButton
													v-for="(c, index) in currentChapterNumber + 1"
													:key="index"
													:id="`chapter-${index}`"
													class="start-button"
													:class="{ prelude: index === 0 }"
													:button-label="index === 0 ? $t('chapter_select.button_prelude') : ['chapter_select.chapter_number', { chapter: index }]"
													:button-style="'green'"
													@on-click-fn="selectChapter(index)"
													:selected="chapterButtonActive(index)"
												></TextButton>
											</div>
										</div>
									</div>
								</template>
							</ScrollableItemsContainer>
						</div>
					</template>
				</Panel>
			</div>
			<div class="story-stats">
				<div class="leaderboard-table-container">
					<div v-if="!chapterRunDataLoading" class="leaderboard">
						<ChapterSummaryTable :headers="playerChapterHeader" :table-row-data="selectedChapterTableStats"></ChapterSummaryTable>
					</div>
				</div>
				<div class="chat-twists">
					<transition name="fade" @after-leave="twistTransitionFinished" appear>
						<div v-if="!startTransition" class="twists-wrapper">
							<Header :header-text="'Plot Twists'" :text-alignment="'center'" :font-size="'large'" :font-face="'pixl'"></Header>
							<ItemPagination :items="getStoryTwists" :icon-style="'twist'"></ItemPagination>
						</div>
					</transition>

					<div class="chat-wrapper" :class="{ 'expanded-position': largeActivityFeed }">
						<div class="header-button">
							<Header :header-text="'Story Chat'" :text-alignment="'center'" :font-size="'large'" :font-face="'boldPT'"></Header>
							<button @click="beginActivityFeedTransition">
								<img src="../../../assets/ui/buttons/arrow-left-drop-shadow.png" width="36" height="38" alt="" :class="{ 'large-feed': largeActivityFeed }" />
							</button>
						</div>
						<ActivityFeed :large-feed="largeActivityFeed"></ActivityFeed>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-container">
			<div class="group" :style="{ width: `${currentSettings.width}px` }">
				<div class="active-twists-container">
					<div class="twits">
						<Header header-text="Twists Up for Vote" :text-alignment="'center'" :font-face="'pixl'" :font-size="'medium'" :font-color="'green'"></Header>
						<div class="icon-container">
							<ItemUpdated v-for="twist in indexedTwists" :key="twist" :item="twist" :component="'twist'" :tooltip-enabled="true" :small-icons="true"></ItemUpdated>
						</div>
					</div>
					<div class="vote-timer" :lang="this.$i18n.locale" v-if="!this.selectedStory.isSolo">
						<div v-if="isVotingOpen">Voting ends in {{ voteEndTime }}</div>
						<div v-else>Voting has ended: next Chapter is open!</div>
					</div>
				</div>
				<div class="button-container">
					<TextButton :button-label="$t(`chapter_select.button_return`)" :button-style="'blue'" @on-click-fn="backButton"></TextButton>
					<TextButton v-if="Views.CHARACTER_SELECT !== previousView" :button-label="$t(`chapter_select.button_character_select`)" :button-style="'green'" @on-click-fn="changeView" :optional-param="{ view: Views.CHARACTER_SELECT, urlParam: storyParam, value: getSelectedStory.id }"></TextButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Background from '../ui-components/background/background.vue'
import ChapterSummaryTable from '../ui-components/table/chapter-summary-table.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import GameIdContainer from '../ui-components/container/text-container.vue'
import ScrollableItemsContainer from '../ui-components/arrow/arrow-background.vue'
import Pagination from '../ui-components/pagination/pagination.vue'
import ItemPagination from '../ui-components/container/item-pagination.vue'
import parseISO from 'date-fns/parseISO'
import ActivityFeed from '../ui-components/activity-feed/activity-feed.vue'
import { differenceInMilliseconds, formatDistance } from 'date-fns'
import { Views } from '../store/ui-store'
import { STORY_PAGE_PARAM_KEY } from '../store/end-chapter-store'

export default {
	name: 'ChapterSummary',
	components: {
		Background,
		ChapterSummaryTable,
		Header,
		Panel,
		TextButton,
		ItemUpdated,
		GameIdContainer,
		ScrollableItemsContainer,
		Pagination,
		ItemPagination,
		ActivityFeed,
	},
	data() {
		return {
			Views: Views,
			playerChapterHeader: [{ headerName: '' }, { headerName: 'Character' }, { headerName: 'Enemies Slain' }, { headerName: 'Run Time' }, { headerName: 'Score' }, { headerName: 'Win' }],
			currentTwistsPage: 1,
			itemPerPage: 14,
			largeActivityFeed: false,
			startTransition: false,
			showHideArrow: false,
			storyParam: STORY_PAGE_PARAM_KEY,
		}
	},
	props: {},
	watch: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('story', ['selectedStoryId', 'selectedStory', 'selectedChapter', 'selectedChapterTableStats', 'oldSelectedChapter', 'chapterRunDataLoading']),
		...mapGetters('story', ['getStoryTwists', 'votingTimeLeft', 'getSelectedStory', 'isVotingOpen']),
		...mapState('time', ['date']),
		...mapState('endChapter', ['indexedTwists']),
		...mapState('ui', ['activeView', 'previousView']),
		...mapState('settings', ['currentSettings']),
		voteEndTime() {
			const endTime = parseISO(this.getSelectedStory.nextTally)
			return formatDistance(endTime, this.date)
		},
		currentChapterNumber() {
			return this.selectedStory.chapter
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('story', ['updateSelectedChapter']),
		...mapActions('ui', ['changeActiveView']),
		...mapActions('story', ['fetchChapterRuns']),
		...mapActions('endChapter', ['fetchMutators']),
		changeView(params) {
			const { view, value, urlParam } = params
			this.changeActiveView(view)
		},
		selectChapter(chapter) {
			this.updateSelectedChapter(chapter)
			this.fetchChapterRuns(chapter)
		},
		chapterButtonActive(chapter) {
			return this.selectedChapter === chapter
		},
		arrowButton(direction) {
			const el = document.getElementById('chapter-1')
			const scrollableContainer = document.getElementById('moveable')
			let width = el.offsetWidth
			let style = getComputedStyle(el)
			width += parseInt(style.marginLeft) + parseInt(style.marginRight)

			switch (direction) {
				case 'left':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft - width * 3, behavior: 'smooth' })
					break
				case 'right':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft + width * 3, behavior: 'smooth' })
					break
			}
		},
		backButton() {
			this.changeActiveView(this.previousView)
		},

		beginActivityFeedTransition() {
			this.startTransition = !this.startTransition
			if (!this.startTransition) {
				this.changeChangeSize(false)
			}
		},
		twistTransitionFinished() {
			this.changeChangeSize(true)
		},
		changeChangeSize(toggle) {
			this.largeActivityFeed = toggle
		},
	},
	mounted() {
		this.fetchMutators()
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#chapter-summary {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	&.ratio21By9 {
		.bottom-container {
			.group {
				margin: 0 auto;
				width: 1920px;
			}
		}
	}
	.main {
		height: calc(100% - 170px);
		margin: calc(150px / 4) auto auto;

		.chapter-select {
			.panel {
				width: 1590px;
				:deep(.panel-inner) {
					border-width: 21px 52px 20px 46px;
				}
			}

			.mutators {
				display: flex;
				flex-direction: column;
				align-items: center;
				color: @green;
				&.no-mutator {
					margin-top: 10px;
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						font-size: 32px;
						letter-spacing: 2px;
					}
					&[lang='zh'] {
						.regularFont(@zhHansSecondaryFont);
						font-size: 32px;
					}
				}

				:deep(#scrollable-items) {
					margin: 15px 0px;
				}
				:deep(#arrow-background) {
					margin: 6px 0;
				}
				.chapter-container {
					overflow: hidden;
					min-width: 180px;
					max-width: 1330px;
					margin-bottom: 8px;
					.chapter-list {
						display: flex;
						.chapter-buttons {
							display: flex;
							margin: 0px 10px;
							.text-button {
								&:not(.prelude) {
									width: 90px;
									margin: 0px 10px;
								}
							}
						}
					}
				}
			}
		}
		.story-stats {
			display: flex;

			margin: 44px 0px;
			.leaderboard-table-container {
				width: 790px;
				height: 600px;
			}
			.chat-twists {
				width: 760px;
				height: 600px;
				margin: 0px 20px;
				position: relative;
				.twists-wrapper {
					width: 100%;
					height: 197px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.items {
						position: relative;
					}
				}
				.chat-wrapper {
					min-height: 364px;
					position: absolute;
					bottom: 0px;
					&.expanded-position {
						bottom: 6px;
					}
					.header-button {
						justify-content: center;
						display: flex;
						gap: 25px;
						padding-left: 53px;
						img {
							position: relative;
							top: 5px;
							transform: rotate(90deg);
							&.large-feed {
								top: 0px;
								transform: rotate(-90deg);
							}
						}
					}
				}
			}
		}
	}
	.bottom-container {
		position: absolute;
		background-color: @darkBlue;
		height: 150px;
		width: 100%;
		bottom: 0px;
		.group {
			max-width: 1920px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			position: relative;
			padding: 0px 60px;
		}
		.button-container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			pointer-events: all;
			margin: 0px 9px;
			.text-button {
				width: 320px;
				height: 100px;
			}
		}
		.active-twists-container {
			width: 100%;
			padding: 0px 450px;
			position: absolute;
			right: 0;
			display: flex;
			justify-content: space-between;
			.twits {
				display: flex;
				flex-direction: column;
				.icon-container {
					display: flex;
				}
			}
			.vote-timer {
				align-self: center;
				color: @green;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 32px;
					letter-spacing: 2px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansSecondaryFont);
					font-size: 32px;
				}
			}
		}
	}
}
.fade-enter-active {
	transition: all 0.25s ease-out;
}
.fade-leave-active {
	transition: all 0.25s ease-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
