<template>
	<div id="ammo" v-if="totalAmmo > 0">
		<div class="ammo-image">
			<img src="../../../../assets/ui/hud/bullet.png" alt="" width="30" />
		</div>
		<div class="ammo-text" :lang="this.$i18n.locale">{{ currentAmountCount }}/{{ totalAmmo }}</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'Ammo',
	components: {},
	data() {
		return {}
	},
	props: {
		totalAmmo: {
			type: Number,
			required: true,
		},
		currentAmountCount: {
			type: Number,
			required: true,
		},
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#ammo {
	display: flex;
	flex-flow: row;
	align-items: center;
	.ammo-image {
		margin-right: 30px;
		img {
			transform: rotate(90deg);
		}
	}
	.ammo-text {
		padding-right: 50px;
		color: @darkBlue;
		filter: drop-shadow(0px 0px 3px green);
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
			font-size: 65px;
			letter-spacing: 4px;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
}

@supports (-webkit-text-stroke: 3px @green) {
	#ammo {
		.ammo-text {
			-webkit-text-stroke: 2px @green;
			-webkit-text-fill-color: @darkBlue;
		}
	}
}
</style>
