<template>
	<div id="hud">
		<transition name="slide-up">
			<div v-show="!showLevelUpModal && !pauseToggledByUser && !roundIsOver && !roundWon" class="progressbar-container">
				<XpBar :value="currentXP" :maxValue="nextLevelXP" :level="playerLevel" id="experience-bar" class="thick"></XpBar>
			</div>
		</transition>
		<transition name="slide-up">
			<div v-show="!showLevelUpModal && !pauseToggledByUser && !roundIsOver && !roundWon" class="time-container" :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': 'center top' }">
				<div class="timer-container">
					<Countdown :countDown="getCountdownToWin"></Countdown>
				</div>
			</div>
		</transition>
		<div class="bottom-container">
			<transition name="slide-down">
				<div v-show="!showLevelUpModal && !pauseToggledByUser && !roundIsOver && !roundWon"  :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': 'center bottom' }">
                    <div class="cooldown-container" >
                        <CooldownIndicator id="cooldown"></CooldownIndicator>
                    </div>
                    <div class="killstreak-goodboy-container">
                        <div class="wrapper" :style="{ height: killstreakAndGoodboyContainerHeight + 'px'}">
                            <div class="goodboy-container" v-show="showGoodBoyStacks">
                                <GoodBoyUI></GoodBoyUI>
                            </div>
                            <div class="killstreak-container" v-show="killstreakEnabled">
                                <Killstreak></Killstreak>
                            </div>
                        </div>
                    </div>
                </div>
			</transition>
		</div>
		<div class="left-container">
			<div v-show="!isElectron && !pauseToggledByUser && !showLevelUpModal">
				<Controls :class="{ 'fade-out': fadeOutControls }" :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': 'top left' }"></Controls>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import XpBar from '../ui-components/bars-sliders/xp-bar.vue'
import Countdown from '../view/countdown.vue'
import Ammo from '../ui-components/bars-sliders/ammo.vue'
import GoodBoyUI from '../ui-components/bars-sliders/good-boy-ui.vue'
import Killstreak from '../ui-components/bars-sliders/killstreak.vue'
import HealthBar from '../ui-components/bars-sliders/health-bar.vue'
import CooldownIndicator from '../ui-components/cooldown-indicator.vue'
import Controls from '../ui-components/controls.vue'

export default {
	name: 'Hud',
	components: {
		XpBar,
		Countdown,
		Ammo,
		Killstreak,
		GoodBoyUI,
		HealthBar,
		CooldownIndicator,
		Controls,
	},
	data() {
		return {
			isElectron: process.env.IS_ELECTRON,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapGetters('ui', ['getCountdownToWin', 'pauseToggledByUser']),
		...mapGetters('levelUp', ['playerLevel']),
		...mapGetters('ftue', ['getFlag']),
		...mapGetters('time', ['inGameTimestamp']),
		...mapState('ui', ['totalAmmo', 'currentAmmo', 'roundIsOver', 'roundWon', 'showGoodBoyStacks', 'currentHealth', 'maxHealth']),
		...mapState('player', ['killstreakEnabled', 'currentKillstreak']),
		...mapState('levelUp', ['showLevelUpModal', 'currentXP', 'nextLevelXP']),
		...mapState('uiScaling', ['aspectRatio', 'responsiveScale']),
		killstreakAndGoodboyContainerHeight() {
			if (this.killstreakEnabled && !this.showGoodBoyStacks) {
				return 113
			} else if (this.killstreakEnabled && this.showGoodBoyStacks) {
				return 145
			} else {
				return 78
			}
		},
		fadeOutControls() {
			return this.inGameTimestamp > 10_000
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
#hud {
	width: 100vw;
	height: 100vh;
	position: absolute;
	.progressbar-container {
		// Move the progress bar down 10 pixels
		margin: 10px 0px;
		// All the content inside of the progress bar will have 10px padding on the left and right
		padding: 0px 10px;
		// Remove the element from the DOM flow so we can position it absolutely
		position: absolute;
		// Need to make sure the bar takes up the full width of the screen
		width: 100vw;
		#experience-bar.thick {
		}
	}
	.health-container {
		top: 85px;
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%) scale(1.1);
		transform: translateX(-50%) scale(1.1);
	}
	.time-container {
		position: relative;
		top: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.timer-container {
			countdown {
			}
		}
	}

	.bottom-container {
		position: absolute;
		bottom: 20px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		// Width / height we don't care about cause the cooldownIndicator will dictate the width since we have it set there.
		// and since the only thing that dictates the height is the icon, cause the pips and text and positioned absolutely, and that's 0.25 % of the icons original Width / Height
		.killstreak-goodboy-container {
			position: absolute;
			bottom: 0;
			margin: auto 0px;
			left: 130px;

			.wrapper {
				display: flex;
				flex-direction: column;
			}
			.killstreak-container {
				position: relative;
				// Use the potential height of the icon for this container height so the two line up
				// We wont put height on the parent div because it be tricky to position it with the goodboy component and when it's gone.
				flex-shirk: 1;
			}
			.goodboy-container {
				position: relative;
				min-width: 300px;
				margin: 4px 0px;
			}
		}
	}

	.left-container {
		position: absolute;
		display: flex;
		left: 5%;
		top: 20%;
		width: 110px;
		height: 220px;

		.fade-out {
			visibility: hidden;
			opacity: 0;
			transition: visibility 0s 2s, opacity 2s linear;
		}
	}
}

.slide-down-leave-active,
.slide-up-leave-active,
.slide-left-leave-active {
	transition: all 0.25s ease-out;
}
.slide-down-enter-active,
.slide-up-enter-active,
.slide-left-enter-active {
	transition: all 0.05s ease-out;
}

.slide-up-enter-from {
	opacity: 0;
	transform: translateY(-30px);
}

.slide-up-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}

.slide-down-enter-from {
	opacity: 0;
	transform: translateY(30px);
}

.slide-down-leave-to {
	opacity: 0;
	transform: translateY(30px);
}

.slide-left-enter-from {
	opacity: 0;
	transform: translateX(-30px);
}

.slide-left-leave-to {
	opacity: 0;
	transform: translateX(-30px);
}
</style>
