import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"

// currently unused, a charging eyebat
const eyeBat: EnemyAI = {
	name: ENEMY_NAME.THE_ONE_EYEBAT,
	type: EnemyType.BASIC,
	objectPoolInitialSize: 10,
	objectPoolGrowthSize: 2,
	appearance: {
		asset: SpineDataName.EYEBAT_1,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
		scale: 3,
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 32,
				position: [-20, -16],
			},
		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.98,
			maxHealth: 20.0,
		},
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: 360,
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHARGE_ATTACK,
				params: {
					holdDirectionTime: 1.5,
					aimTime: 3,
					chargeLerpSpeed: [
						{ targetSpeedMult: 7, maxTime: 1.5 },
					]
				},
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 700,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 600,
			movementMinDistance: 130,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default eyeBat
