/**
 * Finds first gap in array or incrementing numbers
 * @param array array of generally incrementing numbers, (ie [0,1,2,4,5])
 * @returns array index of 1st gap in count discovered (ie: 3 in above example), -1 if no gaps
 */
export function findGap(array: number[]) {
	for (let i = 0; i < array.length; i++) {
		if (array[i] > i) {
			return i
		}
	}
	return -1
}

/** Interleaves two arrays, alternating their elements
 * @param xs, first array (i.e. [0, 2, 4])
 * @param ys, second array (i.e [1, 3, 5])
 * @returns an array combining the arrays alternately (i.e [0, 1, 2, 3, 4, 5])
 */
export function interleave(xs, ys = []) {
	const [x, ...xss] = xs
	return x === undefined
		? ys                             // base: no x
		: [x, ...interleave(ys, xss)]  // inductive: some x
}