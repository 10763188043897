<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div class="group">
				<div class="icons-label-container">
					<div class="label" :lang="this.$i18n.locale">Player Icons</div>
				</div>
				<div class="player-icons-grid">
					<ItemPagination :items="getPlayerIcons" :icon-style="'player-icon'" :iconColor="getSelectedColor" :onClickPropFn="updateSelectedIcon" :onSelectedPropFn="(item) => item === getSelectedIcon" :class="'square-items'"> </ItemPagination>
				</div>
				<div class="icons-color-container">
					<div class="label custom" :lang="this.$i18n.locale">Icon Color</div>
					<div class="color-select-input">
						<div class="radial-container">
							<Radial :id="'radial-'+ i" v-for="i in getPlayerIconColors" :key="i" :selected="i === getSelectedColor" :color="i" @click="updateSelectedColor(i)"></Radial>
						</div>
					</div>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Range from '../form-inputs/range.vue'
import { getIconTags, getIconColors } from '../../store/settings-store'
import ItemPagination from '../container/item-pagination.vue'
import Radial from '../form-inputs/radial.vue'

export default {
	name: 'PlayerIconsModule',
	components: {
		EmbellishmentContainer,
		Range,
		ItemPagination,
		Radial
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('settings', ['fullscreen']),
		...mapGetters('settings', ['getSelectedIcon', 'getSelectedColor']),

				getPlayerIcons() {
			return getIconTags()
		},
		getPlayerIconColors() {
			return getIconColors()
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('settings', ['checkFullscreen']),
		...mapMutations('settings', ['updateSelectedColor', 'updateSelectedIcon']),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.icons-label-container {
	display: flex;
	flex-flow: row;
	height: 100%;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 200px;
		margin-right: 175px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	
	}
	.color-select-input {
		.radial-container {
			display: flex;
			flex-flow: row;
		}
	}
}
.icons-color-container {
	display: flex;
	//width: 615px; // Parent container width of 630px - 15px from padding-right
	position: relative;
	//justify-content: center;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 200px;
		margin-right: 175px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
		&.custom{
			margin-right: 140px;
		}
	}
	.color-select-input {
		.radial-container {
			display: flex;
			flex-flow: row;
		}
	}
}
.player-icons-grid {
	border-radius: 7px;
	//background-color: @darkBlue;
	widows: 100%;
	height: calc(200px + 62px);
}
</style>
