import { maxBy, minBy } from "lodash"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import CollisionSystem from "../engine/collision/collision-system"
import { GameState } from "../engine/game-state"
import { Renderer } from "../engine/graphics/renderer"
import { Enemy } from "../entities/enemies/enemy"
import { DamageableEntityType, EntityType, isEnemy, isPlayer } from "../entities/entity-interfaces"
import { ElementalPoolType, LAVA_POOL_MINIMUM_DAMAGE, LAVA_POOL_PERCENT_HEALTH_DAMAGE, POISON_WEAKENED_DURATION } from "../entities/hazards/elemental-pools-data"
import { StatOperator, StatType } from "../stats/stat-interfaces-enums"
import { callbacks_addCallback } from "../utils/callback-system"
import { AssetManager } from "../web/asset-manager"
import { Buff } from "./buff"
import { BuffDefinition } from "./buff-definition"
import { BuffTags, StackStyle } from "./buff-enums"
import { BuffIdentifier } from "./buff.shared"
import { gameUnits, percentage, timeInSeconds } from "../utils/primitive-types"
import { DamageSource } from "../projectiles/damage-source"
import { AllWeaponTypes, PrimaryWeaponType } from "../weapons/weapon-types"
import { Vector } from "sat"
import { DeadBehaviours } from "../entities/enemies/ai-types"
import EntityStatList from "../stats/entity-stat-list"
import { dealAOEDamageSimple } from "../projectiles/explosions"
import { BOILING_BLOOD_DAMAGE_SCALE, BOILING_BLOOD_RADIUS, PLAYER_POISON_POOL_DURATION } from "../game-data/player-formulas"
import { PropPlacer } from "../world-generation/prop-placement"
import { PLOT_TWIST_BERSERKER_STAT_MULTIPLIER, Player } from "../entities/player"
import { InGameTime } from "../utils/time"
import { Audio } from "../engine/audio"
import { GravityPinDown } from "../entities/gravity-pin-down"
import { FIERY_POOL_DAMAGE_RADIUS_X, FIERY_POOL_DAMAGE_RADIUS_Y, IgniteFieryPool } from "../entities/hazards/ignite-fiery-pool-hazard"
import AISystem from "../entities/enemies/ai-system"
import { BRUTE_TRIO_ENEMIES } from "../entities/enemies/enemy-names"
import { CreepyEggGameplayEvent } from "../events/creepy-egg-gameplay-event"
import { SPECTRAL_FARMER_SLOW_BONUS, SPECTRAL_FARMER_SLOW_DURATION, SPICY_PEPPER_DURATION } from "../game-data/plot-twist-misc"
import { SpectralHoeGfx } from "../entities/spectral-hoe-gfx"
import { DEFAULT_STUN_DURATION } from "./buff-system"
import { Boomerang } from "../weapons/actual-weapons/primary/boomerang-weapon"

export const IGNITE_DURATION = 2000
export const IGNITE_TICK_INTERVAL = 250
export const POISON_DURATION = 6000
export const POISON_TICK_INTERVAL = 250
export const POISON_EXTENDED_DURATION = 2000
export const BLEED_DURATION = 3000
export const BLEED_TICK_INTERVAL = 250
export const CHILL_DURATION = 2000
export const CHILL_MOVEMENT_SPEED_PENALTY = -0.4
export const DOOM_BASE_RADIUS = 320
export const DOOM_DIMINISHING_RADIUS_EXP = 2.7
export const DOOM_MINIMUM_RADIUS = 75

export const DAMAGE_OVER_TIME_SOURCE: DamageSource = {
	weaponType: AllWeaponTypes.DamageOverTime,
	statList: null,
	numEntitiesChained: 0,
	numEntitiesPierced: 0,
	timeScale: 1,
	isPlayerOwned: () => true,
	getKnockbackDirection: () => new Vector(0, 0),
	showImmediateDamageNumber: false,
	nid: -1,
	entityType: EntityType.Buff,
	update: (delta, now) => { },
}

export const CATCH_EM_ALL_DURATION = 10_000
export const CATCH_EM_ALL_REAPPLY_DURATION = 10_000

export const PIN_DOWN_DURATION = 2_000
export const PIN_DOWN_BONUS_DURATION = 500
export const PIN_DOWN_BOSS_DURATION_SCALAR = 0.25

export function getIgniteStacks(damage: number) {
	return damage * IGNITE_TICK_INTERVAL / IGNITE_DURATION
}

export function getPoisonStacks(damage: number) {
	return damage * POISON_TICK_INTERVAL / POISON_DURATION
}

export function getBleedStacks(damage: number) {
	return damage * BLEED_TICK_INTERVAL / BLEED_DURATION
}

export function getChillStacks(damage: number) {
	return 100
}

export function getStacksForAilment(buff: BuffIdentifier, damage: number) {
	switch (buff) {
		case BuffIdentifier.Ignite:
			return getIgniteStacks(damage)
		case BuffIdentifier.Poison:
			return getPoisonStacks(damage)
		case BuffIdentifier.Bleed:
			return getBleedStacks(damage)
		case BuffIdentifier.Chill:
			return getChillStacks(damage)
	}

	return 1
}

export const AILMENT_TO_POTENCY_STAT = {
	[BuffIdentifier.Poison]: StatType.poisonPotency,
	[BuffIdentifier.Ignite]: StatType.ignitePotency,
	[BuffIdentifier.Bleed]: StatType.bleedPotency,
	[BuffIdentifier.Chill]: StatType.chillPotency,
	[BuffIdentifier.Shock]: StatType.shockPotency,
	[BuffIdentifier.Stun]: StatType.stunPotency,
	[BuffIdentifier.Doom]: StatType.doomPotency,
}

export const GenericBuffs: BuffDefinition[] = [
	new BuffDefinition({
		identifier: BuffIdentifier.Ignite,
		tags: [BuffTags.DamageOverTime],
		startingStacks: 1,
		duration: IGNITE_DURATION,
		tickInterval: IGNITE_TICK_INTERVAL,
		lastsForever: false,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: IGNITE_DURATION,
		showToClient: true,
		applyFn(buff: Buff) {
			const player = GameState.player
			if (isEnemy(buff.appliedTo)) {
				if (player.binaryFlags.has('ignited-enemies-gain-movement-speed')) {
					const enemy = buff.appliedTo
					buff.state = {
						bonus: enemy.statList.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.4)
					}
				}

			}
		},
		tickFn(buff: Buff) {
			const target = buff.appliedTo
			const maxDamage = maxBy(buff.rollingStacksApplied, (stack) => stack[1])[1]
			if (isEnemy(target)) {
				target.takeDamageSimple(maxDamage, false, true)

				if (target.isDead()) {
					GameState.player.tryDropSunSoul(target)

					if (GameState.player.binaryFlags.has('ignite-pool')) {
						const lifeTime = buff.timeRemaining / 1_000

						IgniteFieryPool.pool.alloc({
							damagePerTick: maxDamage,
							damageTargetType: DamageableEntityType.Enemy,
							ignoreKnockback: true,
							weaponType: AllWeaponTypes.FireFairies,
							lifeTime,
							triggerRadius: FIERY_POOL_DAMAGE_RADIUS_X,
							radiusX: FIERY_POOL_DAMAGE_RADIUS_X,
							radiusY: FIERY_POOL_DAMAGE_RADIUS_Y,
							position: target.position
						})
					}
				}
			} else {
				console.warn('Ignite proccing on non-enemy, buff:')
				console.warn(buff)
			}
		},
		wearOffFn(buff: Buff) {
			if (buff.state?.bonus) {
				buff.state.bonus.remove()
			}
		},
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.CatchEmAll,
		tags: [BuffTags.Upgrade],
		startingStacks: 1,
		duration: CATCH_EM_ALL_DURATION,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		stackLimit: 20,
		lastsForever: false,
		showToClient: true,
		reapplyStacks: 1,
		reapplyDuration: CATCH_EM_ALL_REAPPLY_DURATION,
		applyFn(buff: Buff) {
			if (isPlayer(buff.appliedTo)) {
				const player = buff.appliedTo
				buff.state.damage = player.stats.addStatBonus('allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.125)
				buff.state.xpDrop = player.stats.addStatBonus('xpReDropChance', StatOperator.SUM_THEN_MULTIPLY, 0.05)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.damage.remove()
			buff.state.xpDrop.remove()
		},
		updateStacksFn(buff: Buff, oldStacks: number, newStacks: number) {
			if (buff.appliedTo.entityType === EntityType.Player) {
				buff.state.damage.update(newStacks * 0.125)
				buff.state.xpDrop.update(newStacks * 0.05)
			}
		}
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.Poison,
		tags: [BuffTags.DamageOverTime],
		startingStacks: 1,
		duration: POISON_DURATION,
		tickInterval: POISON_TICK_INTERVAL,
		lastsForever: false,
		stackStyle: StackStyle.IncreaseDuration,
		durationLimit: 30000,
		reapplyStacks: 1,
		reapplyDuration: POISON_EXTENDED_DURATION,
		showToClient: true,
		tickFn(buff: Buff) {
			const target = buff.appliedTo
			if (isEnemy(target)) {
				target.takeDamageSimple(buff.stacks, false, true)
			} else if (isPlayer(target)) {
				const damageSource = buff.owner as DamageSource
				target.takeDamage(buff.stacks, damageSource, true)
			}

		},
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.Bleed,
		tags: [BuffTags.DamageOverTime],
		startingStacks: 1,
		duration: BLEED_DURATION,
		tickInterval: BLEED_TICK_INTERVAL,
		lastsForever: false,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: BLEED_DURATION,
		showToClient: true,
		tickFn(buff: Buff) {
			const target = buff.appliedTo
			const multi = 1 + ((buff.rollingStackApplicationCount - 1) * 0.1)
			if (isEnemy(target) && !target.isDead()) {
				if (GameState.player.binaryFlags.has('boiling-blood')) {
					// there is no way this is performant
					dealAOEDamageSimple(CollisionLayerBits.HitEnemyOnly, BOILING_BLOOD_RADIUS, target.position, buff.stacks * multi * BOILING_BLOOD_DAMAGE_SCALE, GameState.player, false, target.nid, DAMAGE_OVER_TIME_SOURCE)
				}
				target.takeDamageSimple(buff.stacks * multi, false, true)
				if (buff.state.pfx){
					const pfx = buff.state.pfx as GravityPinDown
					pfx.setRemainingTime(buff.timeRemaining / 1000)
				}
			} else if (isPlayer(target)) {
				const damageSource = buff.owner as DamageSource
				target.takeDamage(buff.stacks * multi, damageSource, true)
			}
		},
		applyFn(buff: Buff) {
			const target = buff.appliedTo
			if (isEnemy(target) && !target.isDead()) {
				if (GameState.player.binaryFlags.has('boiling-blood') && !buff.state.pfx) {
					buff.state.pfx = GravityPinDown.pool.alloc({
						position: target.position,
						radius: BOILING_BLOOD_RADIUS,
						duration: buff.timeRemaining / 1000,
						onTargetDeath: () => { return target.isDead() }
					})
				}
			}
		},
		wearOffFn(buff: Buff) {
			if (buff.state.pfx) {
				buff.state.pfx = null
			}
		}
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.Chill,
		tags: [BuffTags.Movement],
		duration: CHILL_DURATION,
		startingStacks: 100,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 100,
		reapplyDuration: CHILL_DURATION,
		stackLimit: 200,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			let statList
			if (isPlayer(buff.appliedTo)) {
				const player = buff.appliedTo
				statList = player.stats
			} else if (isEnemy(buff.appliedTo)) {
				const enemy = buff.appliedTo
				statList = enemy.statList

				enemy.gfx.addColorModifier(-0.4, -0.4, 0.3, 0)
			}

			const slowMulti = CHILL_MOVEMENT_SPEED_PENALTY * buff.stacks / 100
			buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, slowMulti)
		},
		updateStacksFn(buff, oldStacks, newStacks) {
			const slowMulti = CHILL_MOVEMENT_SPEED_PENALTY * newStacks / 100
			buff.state.bonus.update(slowMulti)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()

			if (isEnemy(buff.appliedTo)) {
				const enemy = buff.appliedTo
				enemy.gfx.addColorModifier(0.4, 0.4, -0.3, 0)
			}
		},
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.IcePool,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 1,
		reapplyDuration: 0,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			let slowAmount
			let statList
			if (isPlayer(buff.appliedTo)) {
				const player = buff.appliedTo
				statList = player.stats
				slowAmount = -0.5
			} else if (isEnemy(buff.appliedTo)) {
				const enemy = buff.appliedTo
				statList = enemy.statList
				slowAmount = -0.3
			}

			buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, slowAmount)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Weakened,
		tags: [BuffTags.DamageDown],
		duration: POISON_WEAKENED_DURATION,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 0,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			let statList
			if (isPlayer(buff.appliedTo)) {
				const player = buff.appliedTo
				statList = player.stats
			} else if (isEnemy(buff.appliedTo)) {
				const enemy = buff.appliedTo
				statList = enemy.statList
			}

			buff.state.bonus = statList.addStatBonus(StatType.allDamageMult, StatOperator.MULTIPLY, -0.5)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Invulnerable,
		tags: [],
		duration: 1,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 1,
		showToClient: false,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = player.godmode
			player.godmode = true
			console.log(`god mode on ${InGameTime.timeElapsedInSeconds}`)
		},
		wearOffFn(buff: Buff) {
			const player = buff.appliedTo as Player
			player.godmode = buff.state
			console.log(`god mode restored back to ${player.godmode} ${InGameTime.timeElapsedInSeconds}`)

		},
	}),
	//TODO: remove unused?
	new BuffDefinition({
		identifier: BuffIdentifier.LavaPool,
		tags: [BuffTags.DamageOverTime],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 1,
		reapplyDuration: 0,
		tickInterval: 500,
		showToClient: false,
		tickFn(buff: Buff) {
			const target = buff.appliedTo
			// for the player we let DamagingGroundHazard handle the damage, since it's just one pip.
			// because the damage code for the enemy here is bespoke and it's not super easy pull that into enemies, we'll let the buff
			// do that damage here
			if (isEnemy(target)) {
				//TODO: don't do massive % health damage to bosses
				// alternatively, just scale the damage based on the current gametime and don't do % at all
				const enemy = target
				const damage = Math.max(Math.ceil(enemy.maxHealth * LAVA_POOL_PERCENT_HEALTH_DAMAGE), LAVA_POOL_MINIMUM_DAMAGE)
				enemy.takeDamageSimple(damage, false)
			}
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.PermanentSmallSlow,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const enemy = buff.appliedTo
			if (isEnemy(enemy)) {
				const statList = enemy.statList
				buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -0.4)
			}

		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.PermanentSlow,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const enemy = buff.appliedTo
			if (isEnemy(enemy)) {
				const statList = enemy.statList
				buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -0.75)
			}

		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.PermanentSlower,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const enemy = buff.appliedTo
			if (isEnemy(enemy)) {
				const statList = enemy.statList
				buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -0.85)
			}

		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.PermanentNoMove,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const enemy = buff.appliedTo
			if (isEnemy(enemy)) {
				const statList = enemy.statList
				buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -1)
			}

		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.ChoreoSpeedy,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const enemy = buff.appliedTo
			if (isEnemy(enemy)) {
				const statList = enemy.statList
				buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.SUM, 200)
			}

		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Shock,
		tags: [BuffTags.Debuff],
		duration: 1_000,
		startingStacks: 1,
		reapplyStacks: 1,
		reapplyDuration: 1_000,
		stackStyle: StackStyle.IncreaseDuration,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}
			const target = buff.appliedTo
			let statList: EntityStatList
			if (isPlayer(target)) {
				statList = target.stats
			} else if (isEnemy(target)) {
				statList = target.statList
			}
			buff.state.bonus = statList.addStatBonus(StatType.damageTakenMult, StatOperator.SUM_THEN_MULTIPLY, 1)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Stun,
		tags: [BuffTags.Movement],
		duration: DEFAULT_STUN_DURATION,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyDuration: DEFAULT_STUN_DURATION,
		reapplyStacks: 1,
		showToClient: false,
		applyFn(buff: Buff) {
			if (Buff.getBuff(buff.appliedTo, BuffIdentifier.StunImmune)) {
				return buff.wearOff()
			}
			buff.state = {
				bonus: null,
			}
			const target = buff.appliedTo
			let statList: EntityStatList
			if (isPlayer(target)) {
				statList = target.stats
			} else if (isEnemy(target)) {
				statList = target.statList
			}
			buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -1)
		},
		wearOffFn(buff: Buff) {
			buff.state?.bonus?.remove()
			if (buff.appliedTo.entityType === EntityType.Player) {
				Buff.apply(BuffIdentifier.StunImmune, buff.appliedTo, buff.appliedTo, 1, 3_000)
			}
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.StunImmune,
		tags: [BuffTags.Movement],
		duration: 1_000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyDuration: 1_000,
		reapplyStacks: 1,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.StunMildPFX,
		tags: [BuffTags.Movement],
		duration: 1_000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyDuration: 1_000,
		reapplyStacks: 1,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}
			const target = buff.appliedTo
			let statList: EntityStatList
			if (isPlayer(target)) {
				statList = target.stats
			} else if (isEnemy(target)) {
				statList = target.statList
			}
			buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -1)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SlowlyRampMovementSpeed,
		tags: [BuffTags.Movement],
		duration: 1_000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyDuration: 1_000,
		reapplyStacks: 1,
		tickInterval: 250,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}
			const target = buff.appliedTo
			let statList: EntityStatList
			if (isPlayer(target)) {
				statList = target.stats
			} else if (isEnemy(target)) {
				statList = target.statList
			}
			buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0)
		},
		tickFn(buff: Buff) {
			const elapsed = Math.clamp(buff.timeElapsedPercent, 0, 1)
			const bonus = Math.lerp(0, 0.01 * buff.stacks, elapsed)
			// console.log({
			// 	elapsed,
			// 	bonus,
			// 	speed: (buff.appliedTo as Enemy).statList._actualStatValues.movementSpeed,
			// })
			buff.state.bonus.update(bonus)
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.BowPinDown,
		tags: [BuffTags.Movement],
		duration: PIN_DOWN_DURATION,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyDuration: PIN_DOWN_DURATION,
		reapplyStacks: 1,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const target = buff.appliedTo
			if (isEnemy(target)) {
				const statList = target.statList
				buff.state.bonus = statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -1)
				if (!buff.state.pfx){
					buff.state.pfx = GravityPinDown.pool.alloc({
						position: target.position,
						radius: target.colliderComponent.bounds.width,
						duration: buff.timeRemaining / 1000,
						onTargetDeath: () => { return target.isDead() }
					})
				}
				else {
					const pfx = buff.state.pfx as GravityPinDown
					pfx.setRemainingTime(buff.timeRemaining / 1000)
				}
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
			buff.state.pfx = null
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Phase,
		tags: [BuffTags.Movement],
		duration: 7_000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			const player = buff.appliedTo
			if (isPlayer(player)) {
				buff.state = {
					bonus: player.stats.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.5)
				}
				player.ghostwalk = true
			}
		},
		wearOffFn(buff: Buff) {
			const player = buff.appliedTo
			if (isPlayer(player)) {
				player.ghostwalk = false
				buff.state.bonus.remove()
			}
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.AcidBottleCarryOver,
		tags: [BuffTags.DamageOverTime],
		startingStacks: 1,
		duration: 333,
		tickInterval: 333,
		lastsForever: false,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		durationLimit: 30000,
		reapplyStacks: 1,
		reapplyDuration: 333,
		showToClient: true,
		tickFn(buff: Buff) {
			const target = buff.appliedTo
			if (isEnemy(target)) {
				target.onHitByDamageSource(buff.state.damageSource, 1, true)
			}
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.PrimaryWeaponVulnerable,
		tags: [],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		applyFn(buff: Buff) {
			const target = buff.appliedTo
			if (isEnemy(target)) {
				target.primaryWeaponDamageBonus += 0.5
			}
		},
		wearOffFn(buff: Buff) {
			const target = buff.appliedTo
			if (isEnemy(target)) {
				target.primaryWeaponDamageBonus -= 0.5
			}
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Doom,
		tags: [],
		duration: 5_000,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 5_000,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = { damage: 0, exploding: false }
		},
		updateStacksFn(buff: Buff, oldStacks: number, newStacks: number) {
			if (!buff.state.exploding && newStacks >= 5 && buff.state.damage >= 1) {
				buff.state.exploding = true
				triggerDoomExplosion(buff, 0.15, buff.state.potency, buff.state.damage)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.potency = 0
			buff.state.damage = 0
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.HolyLight,
		tags: [],
		duration: 5_000,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 5_000,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}
			const target = buff.appliedTo
			if (isEnemy(target)) {
				const statList = target.statList
				buff.state.bonus = statList.addStatBonus(StatType.damageTakenMult, StatOperator.MULTIPLY, 0.3)
				target.gfx.addColorModifier(1, 0.843, 0, 0)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
			if (isEnemy(buff.appliedTo)) {
				buff.appliedTo.gfx.addColorModifier(-1, -0.843, 0, 0)
			}
		},

	}),
	new BuffDefinition({
		identifier: BuffIdentifier.LikeABoss,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 0,
		lastsForever: true,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Sturdy,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 0,
		lastsForever: true,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}
			const target = buff.appliedTo
			if (isEnemy(target)) {
				const statList = target.statList
				buff.state.bonus1 = statList.addStatBonus(StatType.knockbackResist, StatOperator.SUM, 10)
				buff.state.bonus2 = statList.addStatBonus(StatType.knockbackResist, StatOperator.MULTIPLY, 3)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Immovable,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 0,
		lastsForever: true,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}
			const target = buff.appliedTo
			if (isEnemy(target)) {
				target.knockbackImmune = true
			}
		},
		wearOffFn(buff: Buff) {
			(buff.appliedTo as Enemy).knockbackImmune = false
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.ProjectileShield,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 5000,
		reapplyStacks: 1,
		reapplyDuration: 5000,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.AllDamageShield,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 5000,
		reapplyStacks: 1,
		reapplyDuration: 5000,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Berserk,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 0,
		lastsForever: true,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus1: null,
				bonus2: null,
			}

			const enemy = buff.appliedTo as Enemy
			buff.state.bonus1 = enemy.statList.addStatBonus(StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.33)
			buff.state.bonus2 = enemy.statList.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 1)

			enemy.gfx.addColorModifier(0.8, -0.4, -0.4, 0)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()

			const enemy = buff.appliedTo as Enemy
			enemy.gfx.addColorModifier(-0.8, 0.4, 0.4, 0)

		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.CombatArenaAttackRate,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 45_000,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus1: null,
				bonus2: null,
				bonus3: null,
			}

			const player = buff.appliedTo as Player
			buff.state.bonus1 = player.stats.addStatBonus(StatType.reloadInterval, StatOperator.MULTIPLY, -0.44)
			buff.state.bonus2 = player.stats.addStatBonus(StatType.chargeRate, StatOperator.SUM_THEN_MULTIPLY, 0.77)
			buff.state.bonus3 = player.stats.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.77)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()
			buff.state.bonus3.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.CombatArenaDamage,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 45_000,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus1: null,
				bonus2: null,
			}

			const player = buff.appliedTo as Player
			buff.state.bonus1 = player.stats.addStatBonus(StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.75)
			buff.state.bonus2 = player.stats.addStatBonus(StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.5)
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.CombatArenaMovement,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 45_000,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus1: null,
				bonus2: null,
			}

			const player = buff.appliedTo as Player
			buff.state.bonus1 = player.stats.addStatBonus(StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.4)
			buff.state.bonus2 = player.stats.addStatBonus(StatType.pickupRange, StatOperator.SUM_THEN_MULTIPLY, 0.75)
			player.updateColliderRadius(player.pickupColliderComponent, player.getStat(StatType.pickupRange))
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()

			const player = buff.appliedTo as Player
			player.updateColliderRadius(player.pickupColliderComponent, player.getStat(StatType.pickupRange))
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.ParanormalExerciseApplier,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 0,
		lastsForever: true,
		stackStyle: StackStyle.None,
		showToClient: false,
		tickInterval: 100,
		applyFn(buff: Buff) {
			buff.state = {
				lastGhostTime: InGameTime.timeElapsedInSeconds - 10 // start with first cd of 30 seconds
			}
		},
		tickFn(buff: Buff) {
			const now = InGameTime.timeElapsedInSeconds
			const ghostTimeInterval: timeInSeconds = 40 // (10 seconds of ghost, 30 seconds without)

			if (now >= buff.state.lastGhostTime + ghostTimeInterval) {
				const player = buff.appliedTo as Player
				Buff.apply(BuffIdentifier.ParanormalGhosted, this, player)
				buff.state.lastGhostTime = now
			}
		},
		wearOffFn(buff: Buff) {
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.ParanormalGhosted,
		tags: [BuffTags.Movement],
		duration: 7_000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
		tickInterval: 100,
		// flash the ghost effect when it's about to wear off
		tickFn(buff: Buff) {
			const player = buff.appliedTo
			if (isPlayer(player) && buff.timeRemaining <= 1000) {
				if (player.model.alpha != buff.state.alpha) {
					player.model.alpha = buff.state.alpha
				} else {
					player.model.alpha = 0.3
				}
			}
		},
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			// save the player's current values just in case
			buff.state = {
				alpha: player.model.alpha,
				bonus1: null,
				hadOrbitBoomerang: false,
			}
			player.ghostwalk = true
			player.model.alpha = 0.3

			buff.state.bonus1 = player.stats.addStatBonus(StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.4)

			player.noFireWeapons = true
			player.stopSecondaryWeapons()

			player.loseCharge()

			if (player.primaryWeaponType === PrimaryWeaponType.Boomerang) {
				const boomerang = player.primaryWeapon as Boomerang
				if (boomerang.hasOrbitRang) {
					buff.state.hadOrbitBoomerang = true
					boomerang.stopOrbitBoomerang()
				}
				boomerang.blockOrbitBoomerang = true
			}
		},
		wearOffFn(buff: Buff) {
			const player = buff.appliedTo as Player
			player.model.alpha = buff.state.alpha
			player.ghostwalk = false
			player.noFireWeapons = false
			buff.state.bonus1.remove()

			if (player.primaryWeaponType === PrimaryWeaponType.Boomerang) {
				const boomerang = player.primaryWeapon as Boomerang
				if (buff.state.hadOrbitBoomerang || boomerang.orbitBoomerangQueued) {
					boomerang.blockOrbitBoomerang = false
					boomerang.startOrbitBoomerang()
					boomerang.orbitBoomerangQueued = false
				}
			}
			
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Enraged,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 10_000,
		reapplyStacks: 1,
		reapplyDuration: 10_000,
		stackStyle: StackStyle.None,
		lastsForever: false,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus1: null,
				bonus2: null,
				bonus3: null,
				bonus4: null,
			}

			const player = buff.appliedTo as Player
			if(isPlayer(player)){
				buff.state.bonus1 = player.stats.addStatBonus('attackRate', StatOperator.SUM_THEN_MULTIPLY, 0.25)
				buff.state.bonus2 = player.stats.addStatBonus('chargeRate', StatOperator.SUM_THEN_MULTIPLY, 0.25)
				buff.state.bonus3 = player.stats.addStatBonus('cooldownInterval', StatOperator.MULTIPLY, -0.25)
				buff.state.bonus4 = player.stats.addStatBonus('reloadInterval', StatOperator.MULTIPLY, -0.25)
			}
		},

		wearOffFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()
			buff.state.bonus3.remove()
			buff.state.bonus4.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.BiggifyElixir,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 20_000,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus1: null,
				bonus2: null,
			}

			const player = buff.appliedTo as Player
			buff.state.bonuses = [
				player.stats.addStatBonus(StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4),
				player.stats.addStatBonus(StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.4),
				player.stats.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, -0.25),
				player.stats.addStatBonus(StatType.chargeRate, StatOperator.SUM_THEN_MULTIPLY, -0.25),
				player.stats.addStatBonus(StatType.reloadInterval, StatOperator.MULTIPLY, 0.25),
				player.stats.addStatBonus(StatType.cooldownInterval, StatOperator.MULTIPLY, 0.25),
			]

			player.adjustPlayerSize(1)
		},
		wearOffFn(buff: Buff) {
			for (let i = 0; i < buff.state.bonuses.length; ++i) {
				buff.state.bonuses[i].remove()
			}

			const player = buff.appliedTo as Player
			player.adjustPlayerSize(-1)
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.DwindleyElixir,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 20_000,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			buff.state = {
				bonus1: null,
				bonus2: null,
			}

			const player = buff.appliedTo as Player
			buff.state.bonuses = [
				player.stats.addStatBonus(StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, -0.4),
				player.stats.addStatBonus(StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, -0.4),
				player.stats.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25),
				player.stats.addStatBonus(StatType.chargeRate, StatOperator.SUM_THEN_MULTIPLY, 0.25),
				player.stats.addStatBonus(StatType.reloadInterval, StatOperator.MULTIPLY, -0.25),
				player.stats.addStatBonus(StatType.cooldownInterval, StatOperator.MULTIPLY, -0.25),
			]

			player.adjustPlayerSize(-0.5)
		},
		wearOffFn(buff: Buff) {
			for (let i = 0; i < buff.state.bonuses.length; ++i) {
				buff.state.bonuses[i].remove()
			}

			const player = buff.appliedTo as Player
			player.adjustPlayerSize(0.5)
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.CreepyEgg,
		tags: [],
		duration: 30_000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 0,
		reapplyDuration: 0,
		showToClient: false,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				slow: player.stats.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -0.15)
			}
		},
		wearOffFn(buff: Buff, naturalWearOff?: boolean) {
			const player = buff.appliedTo as Player
			buff.state.slow.remove()
			CreepyEggGameplayEvent.getInstance().setPlayerHoldingEgg(false, naturalWearOff)
			
			if (naturalWearOff) {				
				player.addXP(player.nextLevel, true)
			} else {
				const enemyName = BRUTE_TRIO_ENEMIES.pickRandom()
				AISystem.getInstance().spawnEnemyAtRandomPos(enemyName)

				Buff.apply(BuffIdentifier.Weakened, player, player, undefined, 15_000)
			}
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SpicyPepper,
		tags: [],
		duration: SPICY_PEPPER_DURATION * 1_000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 1,
		showToClient: false,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			player.isSpicyPepperDashing = true
		},
		wearOffFn(buff: Buff) {
			const player = buff.appliedTo as Player
			player.isSpicyPepperDashing = true
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SpookyGhosted,
		tags: [BuffTags.Movement],
		duration: 5_000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
		tickInterval: 100,
		// flash the ghost effect when it's about to wear off
		tickFn(buff: Buff) {
			const enemy = buff.appliedTo
			if (isEnemy(enemy) && buff.timeRemaining <= 1000) {
				if (enemy.gfx.activeAnimator.alpha !== buff.state.alpha) {
					enemy.gfx.activeAnimator.alpha = buff.state.alpha
				} else {
					enemy.gfx.activeAnimator.alpha = 0.3
				}
			}
		},
		applyFn(buff: Buff) {
			if (isEnemy(buff.appliedTo)){
				const enemy = buff.appliedTo as Enemy
				// save the player's current values just in case
				buff.state = {
					alpha: enemy.gfx.activeAnimator.alpha,
					bonus1: null,
					bonus2: null,
				}
				enemy.ghosted = true
				enemy.gfx.activeAnimator.alpha = 0.3

				buff.state.bonus1 = enemy.statList.addStatBonus(StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.35)
				// Zero out damage for enemies that use projectiles
				buff.state.bonus2 = enemy.statList.addStatBonus(StatType.baseDamage, StatOperator.MULTIPLY, -1)
			}
		},
		wearOffFn(buff: Buff) {
			if (isEnemy(buff.appliedTo)){
				const enemy = buff.appliedTo as Enemy
				enemy.gfx.activeAnimator.alpha = buff.state.alpha
				enemy.ghosted = false
				buff.state.bonus1.remove()
				buff.state.bonus2.remove()
			}
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SpectralFarmerApplier,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 0,
		lastsForever: true,
		stackStyle: StackStyle.None,
		showToClient: false,
		tickInterval: 100,
		applyFn(buff: Buff) {
			buff.state = {
				lastFarmTime: InGameTime.timeElapsedInSeconds - 48
			}
		},
		tickFn(buff: Buff) {
			const now = InGameTime.timeElapsedInSeconds
			const farmTimeInterval: timeInSeconds = 50

			if (now >= buff.state.lastFarmTime + farmTimeInterval) {
				const player = buff.appliedTo as Player
				Buff.apply(BuffIdentifier.SpectralFarmer, this, player)
				buff.state.lastFarmTime = now
			}
		},
		wearOffFn(buff: Buff) {
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SpectralFarmer,
		tags: [BuffTags.Buff],
		startingStacks: 1,
		duration: 20_000,
		lastsForever: false,
		stackStyle: StackStyle.None,
		showToClient: false,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			if (!player.spectralHoeGfx) {
				player.spectralHoeGfx = new SpectralHoeGfx(player)
			}

			player.binaryFlags.add('twist-spectral-hoe')

			player.spectralHoeGfx.addToScene()

			buff.state = {
				debuff: player.stats.addStatBonus(StatType.pickupRange, StatOperator.MULTIPLY, -0.2)
			}

			player.pickupColliderRefreshTime = 1.1 //refresh collider radius immediately
		},
		wearOffFn(buff: Buff) {
			const player = buff.appliedTo as Player
			player.spectralHoeGfx.removeFromScene()

			player.binaryFlags.delete('twist-spectral-hoe')

			buff.state.debuff.remove()
			player.pickupColliderRefreshTime = 1.1 //refresh collider radius immediately
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SpectralFarmerSlow,
		tags: [BuffTags.Movement],
		duration: SPECTRAL_FARMER_SLOW_DURATION,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: SPECTRAL_FARMER_SLOW_DURATION,
		showToClient: false,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				bonus: player.stats.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, SPECTRAL_FARMER_SLOW_BONUS),
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Berserker,
		tags: [BuffTags.Buff],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				bonus1: player.stats.addStatBonus('attackRate', StatOperator.SUM_THEN_MULTIPLY, PLOT_TWIST_BERSERKER_STAT_MULTIPLIER),
				bonus2: player.stats.addStatBonus('chargeRate', StatOperator.SUM_THEN_MULTIPLY, PLOT_TWIST_BERSERKER_STAT_MULTIPLIER),
				bonus3: player.stats.addStatBonus('cooldownInterval', StatOperator.SUM_THEN_MULTIPLY, -PLOT_TWIST_BERSERKER_STAT_MULTIPLIER),
			}
			//
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()
			buff.state.bonus3.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.RainbowPetBlue,
		tags: [BuffTags.Buff],
		duration: 8_000,
		reapplyDuration: 8_000,
		reapplyStacks: 1,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				bonus: player.stats.addStatBonus(StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.15),
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.RainbowPetGreen,
		tags: [BuffTags.Buff],
		duration: 12_000,
		reapplyDuration: 12_000,
		reapplyStacks: 1,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				bonus1: player.stats.addStatBonus(StatType.pickupRange, StatOperator.SUM_THEN_MULTIPLY, 0.30),
				bonus2: player.stats.addStatBonus(StatType.xpValueMulti, StatOperator.SUM_THEN_MULTIPLY, 0.30),
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus1.remove()
			buff.state.bonus2.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.RainbowPetRed,
		tags: [BuffTags.Buff],
		duration: 10_000,
		reapplyDuration: 10_000,
		reapplyStacks: 1,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				bonus: player.stats.addStatBonus(StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.20),
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
]

export function triggerDoomExplosion(buff: Buff, delay: timeInSeconds, potency: number, buffDamage: number) {
	const target = buff.appliedTo
	if (isEnemy(target)) {
		const position = target.position.clone()
		callbacks_addCallback(this, () => {
			// blam !
			const damage = Math.floor(buffDamage / 4) * potency
			if (damage <= 1.9999) { // prevent wasteful explosions
				return
			}
			const halfDamage = damage / 2
			const sizingStacks = Math.abs(buff.stacks - 5) + 1
			let explosionRadius = DOOM_BASE_RADIUS - Math.pow(sizingStacks + 1, DOOM_DIMINISHING_RADIUS_EXP)
			if (explosionRadius <= DOOM_MINIMUM_RADIUS) {
				return
			}
			explosionRadius *= Math.clamp(GameState.player.getStat('attackSize'), 1.0, 2.0)
			if (GameState.player.binaryFlags.has('doom-attack-size-50')) {
				// console.log(`doom explosion size! ${explosionRadius}`)
				explosionRadius *= 1.5
			}
			const stacksToSpread = buff.stacks - 1

			// console.log(`spread: ${stacksToSpread} damage: ${damage} sizingStacks: ${buff.stacks}=>${sizingStacks} radius: ${explosionRadius}`)

			if (!target.isDead()) {
				target.takeDamageSimple(damage)
				buff.removeStacksDirect(buff.stacks)
			}

			const hitEnemies = CollisionSystem.getInstance().getEntitiesInArea(position, explosionRadius, CollisionLayerBits.HitEnemyOnly)
			// play sound
			Audio.getInstance().playSfx('SFX_Doom_Explosion')

			// show pfx
			const effectConfig = AssetManager.getInstance().getAssetByName('status-doom').data
			const magicScalingValue = 48 // approx base size of the PFX's "hitbox" boundary, except it's circles vs squares etc.
			Renderer.getInstance().addOneOffEffectByConfig(effectConfig, position.x, position.y, 9999, explosionRadius / magicScalingValue, 0.7, undefined, true)
			// Renderer.getInstance().drawCircle({ x: position.x, y: position.y, radius: explosionRadius, destroyAfterSeconds: 0.3, scale: 1, permanent: false, color: 0xFF0000 })

			callbacks_addCallback(this, () => {
				for (let i = 0; i < hitEnemies.length; ++i) {
					const enemy = hitEnemies[i].owner as Enemy

					if (enemy.nid !== target.nid || GameState.player.binaryFlags.has('doom-self-explosion-damage')) {
						enemy.takeDamageSimple(damage)
						if (GameState.player.binaryFlags.has('doom-self-explosion-damage')) {
							// console.log(`doom self own! ${damage}`)
							continue
						}
						const buffInstance = Buff.apply(BuffIdentifier.Doom, buff.owner, enemy, stacksToSpread)
						buffInstance.state.damage += halfDamage
						buffInstance.state.potency = potency
						// console.log(`damage spread increased to ${buffInstance.state.damage}`)
					}
				}
			}, delay / 3)

			buff.state.exploding = false
			buff.state.damage = 0
			buff.state.potency = 0
		}, delay)
	}
}

export function triggerBuffSpread(buff: Buff, radius: gameUnits, stackMult: percentage, stackCountMode: 'all' | 'highest-rolling' | 'lowest-rolling', numTargets: number, splitStacksAcrossTargets: boolean = false) {
	const target = buff.appliedTo
	if (isEnemy(target)) {
		let stacksAvailable: number
		if (stackCountMode === 'all') {
			stacksAvailable = buff.stacks
		} else if (stackCountMode === 'highest-rolling') {
			stacksAvailable = maxBy(buff.rollingStacksApplied, (stack) => stack[1])[1]
		} else if (stackCountMode === 'lowest-rolling') {
			stacksAvailable = minBy(buff.rollingStacksApplied, (stack) => stack[1])[1]
		}

		const stacksToSpread = Math.ceil(stacksAvailable * stackMult / (splitStacksAcrossTargets ? numTargets : 1))

		const position = target.position.clone()
		const hitEnemies = CollisionSystem.getInstance().getEntitiesInArea(position, radius, CollisionLayerBits.HitEnemyOnly)
		for (let i = 0; i < Math.min(hitEnemies.length, numTargets); i++) {
			const enemy = hitEnemies[i].owner as Enemy
			Buff.apply(buff.identifier, buff.owner, enemy, stacksToSpread)
		}
	}
}

export function triggerPoisonPool(buff: Buff, radius: gameUnits) {
	const target = buff.appliedTo
	if (isEnemy(target)) {
		const position = target.position.clone()
		PropPlacer.getInstance().placeHazardAtPosition(ElementalPoolType.Poison, position, radius, PLAYER_POISON_POOL_DURATION, false)
	}
}
