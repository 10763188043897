import { shuffle } from "lodash"

/**
 * An exhaustive randomizer where you put a bunch of things in a bag, then draw them out
 * in a random order each time.
 */
export default class ShuffleBag {
	private seed: number //TODO: MT seeded RNG?
	private allItems: any[] = []
	private currentPosition: number
	private shouldRecycleContents: boolean = true

	constructor(seed: number, shouldRecycleContents: boolean = true) {
		this.setSeed(seed)
		this.shouldRecycleContents = shouldRecycleContents
	}

	setSeed(seed: number) {
		this.seed = seed
	}

	setItems(items: any[]) {
		this.allItems = items
		this.reset()
	}

	addItem(item: any, amount: number = 1) {
		for (let i = amount; i > 0; i--) {
			this.allItems.push(item)
		}
	}

	reset() {
		this.currentPosition = this.allItems.length - 1
		const randomized: any[] = shuffle(this.allItems) //TODO: MT seeded RNG?
		this.allItems = randomized
	}

	next() {
		this.currentPosition--
		if (this.currentPosition === -1) {
			if (this.shouldRecycleContents) {
				this.reset()
			} else {
				return undefined
			}
		}
		const el = this.allItems[this.currentPosition]
		return el
	}

	toString() {
		return JSON.stringify({
			allItems: this.allItems,
			currentPosition: this.currentPosition,
			recycleOnNext: this.currentPosition === 0,
		}, null, 2)
	}
}