import { POI } from "../pois/poi";
import { EventTypes } from "./event-types"
import { FountainsOfManaGameplayEventPresets } from "./gameplay-event-definitions";
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system";
import { ShrineGameplayEvent } from "./shrine-gameplay-event";

export const FOUNTAINS_OF_MANA_XP_DROP_CHANCE = 0.7

export class FountainsOfManaGameplayEvent extends ShrineGameplayEvent {

    // instead of it being random, hardcoding it to use the new event. 
    setRandomPreset(): void {
        this.eventPresetConfig = FountainsOfManaGameplayEventPresets['fountainsOfMana']
    }
    
    protected onEventDoneInternal(poi: POI) {
		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.FountainsOfMana)
	}
}