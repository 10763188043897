<template>
	<Panel id="game-search" :panel-identifier="'levelUp'" :height="-1">
		<template #panel-content>
			<div class="container">
				<Header :header-Text="`Search for Story ID`" :text-alignment="'center'" :font-face="'pixl'" :font-size="'large'"></Header>
				<div class="game-id-input">
					<input id="game-id" :value="gameId" @input="updateGameId" />
				</div>
				<div :class="{error: gameIdError}" class="text-error" :lang="this.$i18n.locale">No Story with that ID</div>
				<div class="button-container">
					<TextButton :button-label="$t('story_select.button_back')" :button-style="'blue'" @on-click-fn="closeModal"></TextButton>
					<TextButton :button-label="$t('story_select.button_join')" :button-style="'bright-green'" @on-click-fn="lookupStory" :disabled="attemptLogIn"></TextButton>
				</div>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import TextButton from '../ui-components/buttons/text-button.vue'

export default {
	name: 'GameIdSearch',
	components: { Panel, Header, TextButton },
	data() {
		return {
			pasteEventListener: null,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('story', ['gameIdInput', 'gameIdError']),
		gameId: {
			get() {
				return this.gameIdInput
			},
			set(value) {
				// Dispatch an action to update the message in the store
				this.updateGameIdInput(value)
			},
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('story', ['verifyGameId']),
		...mapMutations('story', ['updateGameIdInput', 'updateGameIdSearchPrompt', 'resetGameIdState']),
		...mapMutations('ui', ['updateDarkenOverlay']),

		updateGameId(event) {
			this.gameId = event.target.value
		},

		lookupStory() {
			this.verifyGameId()
		},
		closeModal() {
			this.updateGameIdSearchPrompt()
			this.updateDarkenOverlay(false)
			this.resetGameIdState()
		},

		pasteEventFn(event: ClipboardEvent) {
			const pastedString: string = event.clipboardData.getData('Text')
			if (pastedString.length === 6 && pastedString.match(/[A-Z0-9]{6}/)) {
				return
			}
			event.preventDefault()
			const rgx = /.*storyId=([A-Z0-9]{6})/g // pluck the storyId if they pasted a link
			const storyId = pastedString.replace(rgx, '$1')
			this.gameId = storyId
		},
	},
	mounted() {
		this.pasteEventListener = document.addEventListener("paste", this.pasteEventFn)
	},
	unmounted() {
		this.pasteEventListener = document.removeEventListener("paste", this.pasteEventFn)
	}
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#game-search {
	width: 500px;
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		.game-id-input {
			margin: 20px 0px;
			input {
				outline: none;
				height: 55px;
				border: none;
				width: 200px;
				background-color: #040e26 !important;
				font-size: 32px;
				color: @darkGreen;
				text-align: center;
				caret-color: red;
				border-radius: 2px;
			}
		}
		.text-error {
			display: block;
			margin-bottom: 20px;
			height: 0px;
			color: red;
			opacity: 0;
			transition: all 250ms ease-in-out;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
				font-size: 30px;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 24px;
			}
			&.error {
				opacity: 1;
				height: 50px;
			}
		}
		.button-container {
			display: flex;
			.text-button {
				width: 200px;
				margin: 0px 10px;
			}
		}
	}
}
</style>
