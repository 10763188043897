<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
            <div>
                <div class="auto-container container-formatting">
                    <div class="label" :lang="this.$i18n.locale">{{ $t('settings.auto_aim') }}</div>
                    <div class="auto-on-off">
                        <Toggle :label="''" :inline-label="true" @on-click-fn="toggleAutoAim" :checked="getAutoAim" @select="() => {}" :font-size="'small'"></Toggle>
                    </div>
                </div>
                <div class="auto-container container-formatting">
                    <div class="label" :lang="this.$i18n.locale">{{ $t('settings.auto_shoot') }}</div>
                    <div class="auto-on-off">
                        <Toggle :label="''" :inline-label="true" @on-click-fn="toggleAutoShoot" :checked="getAutoShoot" @select="() => {}" :font-size="'small'"></Toggle>
                    </div>
			    </div>
				<div class="explanation" :lang="this.$i18n.locale">{{ $t('settings.auto_shoot_explain') }}</div>
            </div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Toggle from '../form-inputs/toggle.vue'

export default {
	name: 'AutoAimShootModule',
	components: {
		EmbellishmentContainer,
		Toggle,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapGetters('settings', ['getAutoAim', 'getAutoShoot']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('settings', ['toggleAutoAim', 'toggleAutoShoot']),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.auto-container {
	display: flex;
	align-items: center;
	width: 660px;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 200px;
		margin-right: 175px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.auto-on-off {
		display: flex;
		width: 70px;
		height: 40px;
	}
}

.explanation {
	color: @offWhite;
	font-size: 18px;
	padding-left: 50px;
	&[lang='en'] {
		.regularFont(@enSecondaryFontPTBold);
		font-variant: small-caps;
	}
	&[lang='zh'] {
		.regularFont(@zhHansHeaderFont);
	}
}
</style>
