export enum GroundPickupType {
	Healing,
	Experience,
	CommonCurrency,
	RareCurrency,
	Magnet,
	Nuke,
	Map,
	LutePower,
	SunSoul,
	CreepyEgg,
	BiggifyElixir,
	DwindleyElixir,
	SpicyPepper,
	MonsterWhistle,
	RottenHeart
}

export enum GroundPickupConfigType {
	None = 0,
	XPSmall,
	XPMedium,
	XPLarge,
	XPHuge,
	supernaturalXP,
	HealingSmall,
	HealingLarge,
	MagnetSmall,
	MagnetLarge,
	Nuke,
	Map,
	CommonCurrencySmall,
	CommonCurrencyMedium,
	CommonCurrencyLarge,
	RareCurrency,
	LutePower,
	SunSoul,
	CreepyEgg,
	BiggifyElixir,
	DwindleyElixir,
	SpicyPepper,
	MonsterWhistle,
	RottenHeart
}