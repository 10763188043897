import { createI18n, Locale, useI18n } from 'vue-i18n'
import en from '../ui/locales/en.json'
import zh from '../ui/locales/zh-hans.json'
import _ from 'lodash'

const strings = {
	'en': en,
	'zh': zh
} as const

export function t(locale: keyof typeof strings, token: string, replacements?: any) {
	const getter = `${locale}.${token}`
	const translatedString = _.get(strings, getter)
	if (!strings[locale]) {
		console.error(`Missing translation locale [${locale}]`)
	}
	if (!translatedString) {
		console.error(`Missing translation token [${token}]`)
	}
	return translatedString
}

export const vuei18n = createI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: { en, zh },
})