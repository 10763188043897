<template>
	<Panel id="link-account-through-code" :panel-identifier="'link-account-through-code'" :height="-1">
		<template #panel-content>
            <div class="container" v-if="!hasPlayerCodeInfo">
                <div class="header" :lang="this.$i18n.locale">
                    {{$t('account_link.input_code')}}
                </div>
                <div class="description" :lang="this.$i18n.locale">
                    {{$t('account_link.input_code_description')}}
                </div>
                <div class="error" v-show="accountLinkError.length > 0" :lang="this.$i18n.locale">
                    {{$t(accountLinkError)}}
                </div>
                <div class="input-container">
                    <input type="text" id="code" :lang="this.$i18n.locale" v-model="inputCode" :placeholder="$t('account_link.paste_here')" maxlength="20"/>
                </div>
                <div class="buttons">
                    <TextButton :button-label="$t('account_link.back')" :button-style="'blue'" @on-click-fn="exitLinking" :loading="isPlayerCodeInfoLoading" :disabled="isPlayerCodeInfoLoading"></TextButton>
                    <TextButton :button-label="$t('account_link.next')" :button-style="'bright-green'" @on-click-fn="nextPressed" :loading="isPlayerCodeInfoLoading" :disabled="isPlayerCodeInfoLoading"></TextButton>
                </div>
            </div>
            <div v-else-if="hasPlayerCodeInfo && !accountLinkSuccess" class="container">
                <div class="header" :lang="this.$i18n.locale">
                    {{$t('account_link.link_account')}}
                </div>
                <div class="username" :lang="this.$i18n.locale">
                    {{$t('account_link.username')}} {{accountLinkUserInfo.username}}<br/>
					{{$t('account_link.created_time')}} {{accountLinkUserInfo.createdAt}}<br/>
					{{$t('account_link.paper_scraps')}} {{accountLinkUserInfo.paperScraps}}, 
					{{$t('account_link.lost_scrolls')}} {{accountLinkUserInfo.lostScrolls}}, 
					{{$t('account_link.magic_tomes')}} {{accountLinkUserInfo.magicTomes}}
                </div>
                <div class="warning" :lang="this.$i18n.locale">
                    {{$t('account_link.link_warning')}} <span class="procceed">{{$t('account_link.proceed')}}</span>
                </div>
                <div class="error" :lang="this.$i18n.locale" v-show="accountLinkError.length > 0">
                    {{$t(accountLinkError)}}
                </div>
                <div class="buttons">
                    <TextButton :button-label="$t('account_link.back')" :button-style="'blue'" @on-click-fn="backToCodeInput" :loading="isPlayerCodeInfoLoading" :disabled="isPlayerCodeInfoLoading"></TextButton>
                    <TextButton :button-label="$t('account_link.link_account')" :button-style="'bright-green'" @on-click-fn="linkAccount" :loading="isPlayerCodeInfoLoading" :disabled="isPlayerCodeInfoLoading"></TextButton>
                </div>
            </div>
            <div v-else class="container">
                <div class="header" :lang="this.$i18n.locale">
                    {{$t('account_link.successful_link')}}
                </div>
                <div class="description" :lang="this.$i18n.locale">
                    {{$t('account_link.successful_link_description')}}
                </div>
                <div class="buttons" :lang="this.$i18n.locale">
                    <TextButton v-if="!accountLinkSuccess" :button-label="$t('account_link.back_to_menu')" :button-style="'bright-green'" @on-click-fn="exitLinking"></TextButton>
                    <TextButton v-if="accountLinkSuccess" :button-label="$t('account_link.reload_game')" :button-style="'bright-green'" @on-click-fn="exitLinking"></TextButton>
                </div>
            </div>
        </template>
    </Panel>
</template>

<script lang="ts">
import { mapActions, mapState, mapMutations } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'

export default {
    name: 'LinkAccountThroughCode',
    components: {
        Panel,
        TextButton
    },
    data() {
        return {
            inputCode: ''
        }
    },
    computed: {
        ...mapState('user', ['isPlayerCodeInfoLoading', 'accountLinkUserInfo', 'accountLinkError', 'hasPlayerCodeInfo', 'accountLinkSuccess']),
        ...mapState('ui', ['previousView'])
    },
    methods: {
        ...mapMutations('ui', ['setActiveView']),
        ...mapMutations('user', ['setHasPlayerCodeInfo', 'clearLinkError']),
        ...mapActions('user', ['fetchAccountLinkInfo', 'tryLinkAccount']),
        nextPressed() {
            this.fetchAccountLinkInfo(this.inputCode)
        },
        linkAccount() {
            this.tryLinkAccount()
        },
        exitLinking() {
            this.clearLinkError()
			if (this.accountLinkSuccess) {
				if (process.env.IS_ELECTRON) {
					window['bridge'].reloadGame()
				} else {
					// we should never hit this code path unless we change our account linking feature to enable non-Steam to non-Steam accounts
					window.location.reload()
				}
			}
            this.setActiveView(this.previousView)
        },
        backToCodeInput() {
            this.setHasPlayerCodeInfo(false)
        }
    },
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#link-account-through-code {
    width: 650px;
    display: flex;
    flex-direction: column;
	align-items: center;
    justify-content: center;

    .container {
        .header {
            color: @cream;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 50px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
        }

        .description {
            color: @offWhite;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansRegularFont);
			}

            margin-left: 5px;
            margin-right: 5px;
        }

        .error {
            color: @red;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansRegularFont);
			}

            margin-left: 5px;
            margin-right: 5px;
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 10px;
        }

        .username {
            color: @offWhite;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansRegularFont);
			}

            margin-left: 5px;
            margin-right: 5px;
        }

        .warning {
            color: @green;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansRegularFont);
			}

            .procceed {
                color: @offWhite;
            }

            margin-left: 5px;
            margin-right: 5px;
        }

        .input-container {
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            margin-top: 10px;

            input {
                outline: none;
                height: 35px;
                border: 0px solid red;
                width: 400px;
                background-color: #040e26 !important;
                font-size: 18px;
                color: @darkGreen;
                margin-bottom: 10px;
                &[lang='en'] {
                    .regularFont(@enSecondaryFontPTBold);
                    font-size: 24px;
                }
                &[lang='zh'] {
                    .regularFont(@zhHansHeaderFont);
                    font-size: 24px;
                }
                &.error {
                    border: 2px solid red;
                    border-radius: 2px;
                    filter: drop-shadow(0px 0px 2px red);
                }
            }
        }
        
    }
}
</style>