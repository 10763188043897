{
	"menu": {
		"header": "DIEBRARY",
		"beta_fnf_disclaimer": "Closed Beta",
		"play_button": "Play",
		"wire_frame": "Wireframe",
		"options": "options",
		"log_out": "Log Out"
	},
	"error": {
		"header": "Some Error",
		"description": "This is some example error description!"
	},
	"default_two_btn_prompt": {
		"example_description_1": "Description line 1.",
		"example_description_2": "Description line 2",
		"no_default": "no-default",
		"yes_default": "yes-default"
	},
	"level_up": {
		"title": "Level Up"
	},
	"sign_in": {
		"log_in": "Log In",
		"email_label": "email address",
		"password_label": "password",
		"button_log_in": "Log In",
		"button_log_in_kongregate": "Log in with Kongregate",
		"button_create_account": "Create an Account",
		"button_anon_account": "Play Without An Account",
		"reset_password": "RESET PASSWORD"
	},
	"sign_up": {
		"button_back": "Back",
		"button_next": "Next",
		"button_create_account": "Create Account",
		"email_label": "email address",
		"password_label": "password",
		"confirm_password_label": "confirm password",
		"username": "username",
		"create_account": "Create Account",
		"tou": "terms of use",
		"terms": "i agree to the terms of use",
		"ooi_contact": "allow ooi to contant me"
	},
	"logged_in": {
		"button_play": "Play",
		"button_settings": "Settings",
		"button_log_out": "Log Out"
	},
	"links": {
		"terms_of_use": "TERMS OF USE"
	},
	"game_info": {
		"controls_label": "Controls",
		"controls_description_a": "W/A/S/D - movement",
		"controls_description_b": "Mouse 1 - shoot your primary weapon",
		"controls_description_c": "Spacebar - use your character skill",
		"controls_description_d": "Secondary weapons will auto-fire!",
		"updates_label": "Updates",
		"updates_description": "crabs now spin. how cool is that",
		"third_label": "The Game",
		"third_description": "shoot stuff with friends"
	},
	"errors": {
		"email_taken": "Email Already Taken",
		"invalid_email_format": "Invalid email format",
		"username_length": "Username must be between 3 and 18 characters",
		"username_taken": "Username already taken",
		"invalid_password": "Invalid password length",
		"password_dont_match": "passwords do not match",
		"must_agree_tos": "Must agree to the terms of use",
		"server_error_header": "Connection Error",
		"server_error_description": "Error connecting to the server. Please refresh and try again.",
		"server_error_dismiss": "Close",
		"end_run_submit_error_description": "Error sending information about your run to the server. Please refresh and try again."
	},
	"meta_progression_store": {
		"characters_and_weapons": "Characters & Weapons",
		"plot_twists": "Plot Twists",
		"upgrades": "Upgrades",
		"special": "Special",
		"refund_everything": "Refund Everything",
		"refund_category": "Refund Category",
		"buy_perk": "Buy Perk",
		"refund_perk": "Refund Perk",
		"hide_unlocks": "Hide Unlocks"
	}
}