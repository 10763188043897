<template>
	<div id="cookieAcceptBar" class="cookieAcceptBar">
		This website uses cookies. By continuing to use this website or by clicking "Accept all Cookies," you are giving consent to cookies being used. For more information on cookies and how you can disable them visit our
		<span v-on:click="openCookiePolicy" class="text-link">Cookie Policy</span>
		<br />
		<button @click="acceptBtn" class="btn btn-success">Accept all Cookies</button>
		<button @click="rejectBtn" class="btn btn-reject">Reject all Cookies</button>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { loadFromLocalStorage, loadFromSessionStorage, removeFromLocalStorage, saveToLocalStorage, saveToSessionStorage } from '../../utils/local-storage'
import { WebPortalConfig } from '../../web-portals/web-portal-init'

import { UI } from '../ui'
import { showGenericInfoPromptUI } from '../store/generic-prompt'
import { Views } from '../store/ui-store'

export default {
	name: '',
	components: {},
	data() {
		return {
			cookieName: 'acceptCookies',
			rejectCookiesSessionKey: 'reject-cookies',
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('user', ['externalUserAccountType']),
		...mapGetters('ui', ['getActiveView']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('ui', ['updateShowCookiesUI']),
		...mapActions('ui', ['changeActiveView']),
		acceptBtn() {
			this.$gtag.optIn()
			this.setCookies()
		},
		rejectBtn() {
			this.$gtag.optOut()
			this.rejectCookies()
		},
		setCookies() {
			const cookieExpires = 30
			let date = new Date()
			date.setTime(date.getTime() + cookieExpires * 24 * 60 * 60 * 1000)
			const expires = date.toUTCString()
			saveToLocalStorage(this.cookieName, expires)
			this.hideCookiesPrompt()
		},
		rejectCookies() {
			this.hideCookiesPrompt()
			localStorage.clear()

			const uiInst = UI.getInstance()
			uiInst.emitMutation('ui/setRejectCookies', true)
			this.$gtag.optOut()
			// have we passed the landing page and are an anonymous account?
			const userType = uiInst.store.getters['user/userType']
			if (userType !== 'registered') {
				const activeView: Views = this.getActiveView
				if (activeView !== Views.LANDING && activeView !== Views.COOKIE_POLICY && activeView !== Views.PRIVACY_POLICY && activeView !== Views.TERMS_OF_USE && activeView !== Views.CREATE_ACCOUNT && activeView !== Views.SIGN_UP && activeView !== Views.FORGOT_PASSWORD) {
					showGenericInfoPromptUI(
						{
							title: 'cookies.heads_up',
							description: ['cookies.no_account_warning'],
						},
						undefined,
					)
				}
			}

			saveToSessionStorage(this.rejectCookiesSessionKey, 'true')
		},
		hideCookiesPrompt() {
			this.updateShowCookiesUI(false)
		},
		openCookiePolicy() {
			this.changeActiveView(Views.COOKIE_POLICY)
		}
	},
	mounted() {
		const rejected = loadFromSessionStorage(this.rejectCookiesSessionKey)
		const accepted = loadFromLocalStorage(this.cookieName)
		if (rejected || accepted) {
			if (rejected) {
				this.hideCookiesPrompt()

				UI.getInstance().emitMutation('ui/setRejectCookies', true)
				this.$gtag.optOut()

				localStorage.clear()
			} else if (accepted) {
				const expiryDate = Date.parse(accepted)
				const now = new Date()
				if (now.getTime() > expiryDate) {
					// we expired
					removeFromLocalStorage(this.cookieName)
				} else {
					this.hideCookiesPrompt()
				}

				this.$gtag.optIn()
			}
		}

		const portalConfig = WebPortalConfig[this.externalUserAccountType]
		if (portalConfig && !portalConfig.enableCookiePolicy) {
			this.rejectCookies()
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.cookieAcceptBar {
	.text-link {
		color: @blueishOne;
		text-decoration: underline;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 28px;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 28px;
		}
		&:hover {
			color: @darkBlue;
		}
	}
}
</style>
