import { AssetManager } from "../../web/asset-manager"
import { ComponentOwner } from "../component-owner"
import { InstancedSprite } from "./instanced-sprite"
import { Renderer } from "./renderer"

export class SingleSpriteGraphicsComponent {

    owner: ComponentOwner

    get instancedSprite() {
        return this._instancedSprite
    }
    private _instancedSprite: InstancedSprite

    get isInScene() {
        return this._isInScene
    }
    private _isInScene: boolean

    private _usingBgLayer: boolean

    constructor(spriteSheetName: string, spriteName: string, owner: ComponentOwner) {
        this.owner = owner
        
        const asset = AssetManager.getInstance().getAssetByName(spriteSheetName)
		const sheet = asset.spritesheet as PIXI.Spritesheet
        
        this._instancedSprite = new InstancedSprite(sheet.textures[spriteName], owner.position.x, owner.position.y, owner.position.y)
        this._isInScene = false
    }

    update() {
        this._instancedSprite.x = this.owner.position.x
        this._instancedSprite.y = this.owner.position.y
    }

    addToScene(useBgLayer?: boolean) {
        if (!this._isInScene) {
            this._isInScene = true
            
            this._instancedSprite.x = this.owner.position.x
            this._instancedSprite.y = this.owner.position.y

            this._usingBgLayer = Boolean(useBgLayer)
            const layer = useBgLayer ? Renderer.getInstance().bgRenderer : Renderer.getInstance().mgRenderer

            layer.addPropToScene(this._instancedSprite)
        } 
    }

    removeFromScene() {
        if (this._isInScene) {
            const layer = this._usingBgLayer ? Renderer.getInstance().bgRenderer : Renderer.getInstance().mgRenderer
            layer.removeFromScene(this._instancedSprite)
            this._isInScene = false
        }
    }
}