<template>
	<div class="background">
		<div ref="backgroundRef" class="background-inner">
			<div class="content">
				<slot name="background-content"></slot>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'Background',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
.background {
	display: flex;
	background-image: url('~/assets/ui/backgrounds/basic-bg.png');
	width: 100%;
	height: 100%;
	background-size: cover;
	.background-inner {
		.content {
			slot {
			}
		}
	}
}
</style>
