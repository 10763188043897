import { Container } from "pixi.js"
import { Renderer } from "../engine/graphics/renderer"
import { InstancedSpriteSheetAnimator } from "../engine/graphics/instanced-spritesheet-animator"
import { timeInSeconds } from "../utils/primitive-types"
import { ObjectPool } from "../utils/third-party/object-pool"
import { AssetManager } from "../web/asset-manager"

const VINE_SCALE = 0.65
const STEP_DISTANCE = 100
const LAYER_SPACING = 75

export const BORDER_DISTANCE = 50

export const VINE_ANIMATIONS = {
	SPAWN: "vine-spawn",
	IDE: "vine-idle",
	DEATH: "vine-death"
}

type PooledAnimatedSprite = {
	spriteSheetAnimator: InstancedSpriteSheetAnimator
	poolIndex: number
}

export class RangerTrapVineGraphics {
	static instancedSpritePools: ObjectPool[]

	sprites: PooledAnimatedSprite[] = []

	constructor() {
		if (!RangerTrapVineGraphics.instancedSpritePools) {
			const assetManager = AssetManager.getInstance()

			const vineSheet01 = assetManager.getAssetByName('ranger-trap-vine-01').spritesheet
			const vineSheet02 = assetManager.getAssetByName('ranger-trap-vine-02').spritesheet

			const spritePools = [
				new ObjectPool(
					() => {
						const sprite = new InstancedSpriteSheetAnimator(vineSheet01, VINE_ANIMATIONS.SPAWN, 0.1)
						sprite.scale.x = VINE_SCALE
						sprite.scale.y = VINE_SCALE

						return { spriteSheetAnimator: sprite, poolIndex: 0 }
					}, {}, 25, 5),
				new ObjectPool(
					() => {
						const sprite = new InstancedSpriteSheetAnimator(vineSheet02, VINE_ANIMATIONS.SPAWN, 0.1)
						sprite.scale.x = VINE_SCALE
						sprite.scale.y = VINE_SCALE

						return { spriteSheetAnimator: sprite, poolIndex: 1 }
					}, {}, 25, 5),
			]

			RangerTrapVineGraphics.instancedSpritePools = spritePools
		}
	}

	update(delta: timeInSeconds) {
	}

	playAnimation(name: string) {
		this.sprites.forEach((sprite) => {
			sprite.spriteSheetAnimator.playAnimation(name)
		})
	}

	addToScene(x: number, y: number, radius: number) {

		const outerRadius = radius - BORDER_DISTANCE
		const layers = Math.floor(outerRadius / LAYER_SPACING)
		const distanceBetweenLayers = outerRadius / layers
		for(let layer = 0; layer <= layers; layer++){

			const layerRadius = outerRadius - (distanceBetweenLayers * layer)

			const circumfrance = 2 * Math.PI * layerRadius
			const numberOfSprites = circumfrance / STEP_DISTANCE

			const stepSize = (Math.PI * 2) / numberOfSprites

			const start = Math.random() * (2 * Math.PI)
			const end = start + (2 * Math.PI)

			for(let a = start; a < end; a += stepSize) {

				const randomPool = RangerTrapVineGraphics.instancedSpritePools[Math.getRandomInt(0, RangerTrapVineGraphics.instancedSpritePools.length - 1)]
				const pooledSprite: PooledAnimatedSprite = randomPool.alloc()
				const vineSpirte = pooledSprite.spriteSheetAnimator

				const circleX = Math.sin(a)
				const circleY = Math.cos(a)
				vineSpirte.position.x = circleX * layerRadius + x
				vineSpirte.position.y = circleY * layerRadius + y

				vineSpirte.zIndex = vineSpirte.position.y - (vineSpirte.height * 0.15)

				vineSpirte.playAnimation(VINE_ANIMATIONS.SPAWN)
				vineSpirte.addToScene()

				this.sprites.push(pooledSprite)
			}
		}
	}

	removeFromScene() {
		const renderer = Renderer.getInstance().mgRenderer

		for (let i = 0; i < this.sprites.length; ++i) {
			const pooledSprite = this.sprites[i]
			pooledSprite.spriteSheetAnimator.removeFromScene()
			RangerTrapVineGraphics.instancedSpritePools[this.sprites[i].poolIndex].free(pooledSprite as any)
		}

		this.sprites.length = 0
	}
}
