import { Cooldown } from "../cooldowns/cooldown"
import { CooldownSystem } from "../cooldowns/cooldown-system"
import { Player } from "../entities/player"
import EntityStatList from "../stats/entity-stat-list"
import { AllWeaponTypes } from '../weapons/weapon-types'
import { IStatListOwner } from "./stat-list-owner"

export abstract class Weapon implements IStatListOwner {
    player: Player
    statList: EntityStatList
    abstract weaponType: AllWeaponTypes

    cooldown: Cooldown

    constructor(player: Player, parentStatList: EntityStatList) {
        this.player = player
        this.statList = new EntityStatList(this.resetStatsFunction.bind(this), parentStatList) 
        this.cooldown = new Cooldown(this.statList)
        CooldownSystem.getInstance().addCooldown(this.cooldown)
    }

    abstract update(delta: number)
    abstract resetStatsFunction(statList: EntityStatList)
}