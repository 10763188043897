  {
    "MUS_RIPPLE_LP": {
      "offset": [
        0,
        112340
      ],
      "volume": 0.7,
      "loop": true
    },
    "MUS_FLOATING_LP": {
      "offset": [
        112340,
        136000
      ],
      "volume": 0.7,
      "loop": true
    },
    "MUS_TRAVELER_LP": {
      "offset": [
        248340,
        125607
      ],
      "volume": 0.7,
      "loop": true
    }
  }