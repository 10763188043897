import { Vector } from "sat"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import { RotSonPet } from "../entities/pets/rot-son-pet"
import { WildPet, WildPetParams } from "../entities/pets/wild-pet"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../game-data/player-formulas"
import { randomRange } from "../utils/math"
import { timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { InGameTime } from "../utils/time"
import { PropPlacer } from "../world-generation/prop-placement"
import { EventTypes } from "./event-types"
import { EventStartData, GameplayEvent } from "./gameplay-event-definitions"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"

const DEFAULT_TIME_LIMIT = 60
const MIN_SPAWN_DISTANCE_FROM_PLAYER = 1900
const MAX_SPAWN_DISTANCE_FROM_PLAYER = 2300
const MOVEMENT_SPEED = PLAYER_DEFAULT_MOVEMENTSPEED * 0.5


export class WildRotSonEventSystem implements GameplayEvent {

	static getInstance() {
		if (!WildRotSonEventSystem.instance) {
			WildRotSonEventSystem.instance = new WildRotSonEventSystem()
		}

		return WildRotSonEventSystem.instance
	}

	static destroy() {
		WildRotSonEventSystem.instance = null
	}

	private static instance: WildRotSonEventSystem

	protected eventStartData: EventStartData
	static pool: ObjectPoolTyped<WildPet, WildPetParams>

	private eventIsActive: boolean
	private endTime: timeInSeconds
	private activePets: WildPet[] = []
	private reusableSpawnPos: Vector = new Vector()

	constructor() {
		//Create rot son pool
		if (!WildRotSonEventSystem.pool){
			WildRotSonEventSystem.pool =  new ObjectPoolTyped<WildPet, WildPetParams>(() => {
				return new WildPet('pet-rot-son')
				}, {}, 5, 1, 'wild-rot-son')
			this.eventIsActive = false
		}
	}

	update(delta: timeInSeconds) {
		if (this.eventIsActive && InGameTime.timeElapsedInSeconds >= this.endTime) {
			this.endEvent()
		}
	}

	setStartData(data: EventStartData) {
		this.eventStartData = data
	}

	startEvent(): void {
		this.eventIsActive =  true
		const timeLimit = this.eventStartData.timeLimit ?? DEFAULT_TIME_LIMIT
		this.endTime =  InGameTime.timeElapsedInSeconds + timeLimit
		const numPets = Math.round(randomRange(4, 5))
		PropPlacer.getInstance().getRandomValidPositionInWorld(MIN_SPAWN_DISTANCE_FROM_PLAYER, MAX_SPAWN_DISTANCE_FROM_PLAYER, 0, this.reusableSpawnPos)

		for (let i = 0; i < numPets; i++) {
			const wildPet = WildRotSonEventSystem.pool.alloc({
				spawnX: this.reusableSpawnPos.x,
				spawnY: this.reusableSpawnPos.y,
				movementBehavior: 'wanderAttack',
				movementSpeed: MOVEMENT_SPEED,
				maxDistanceFromPlayer: 1900,
				maxWanderTime: 30
			})
			// Assign the pet's pool so it can call cleanup on itself
			wildPet.pool = WildRotSonEventSystem.pool
			const rotSon = wildPet.pet as RotSonPet
			rotSon.setTargetCollisionLayer(CollisionLayerBits.EnemyAndPlayer)
			rotSon.overrideSearchEnemies = true
			this.activePets.push(wildPet)
		}
	}

	endEvent() {
		this.eventIsActive = false
		this.activePets.forEach((pet) => {
			pet.despawn(true)
		})
		this.activePets = []
		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.WildRotSons)
	}

}