import { AnimatedVector } from './simple-animation-system'
import logger from './client-logger'
import { Vectors, VectorXY } from './math'

// attachments system
// Simple system for attaching an object to another object:
//  addAttachment(pfx, player, new Vector(0,-50))
//  ...
//  removeAttachment(pfx)

const DEBUG = false

/** the entity that is attached to */
export interface IAttachee {
	x: number
	y: number
	zIndex: number
}

interface AttachedObject {
	obj: VectorXY & { zIndex?: number }
	attachedTo: IAttachee
	offset: AnimatedVector
	time: number
	renderBehind: boolean
	onDetach: () => void
}

export function attachments_addAttachment(obj: VectorXY, attachedTo: IAttachee, offset?: AnimatedVector, renderBehind = false, onDetach?: () => void) {
	if (DEBUG) {
		logger.debug(`addAttachment:${(obj as any).name}`)
	}
	offset = offset || ((t) => Vectors.Zero)
	console.assert(!attachedObjects.find((ao) => ao.obj === obj), `attempt to attach obj to more than 1 thing, this can't end well`)
	attachedObjects.push({ obj, attachedTo, offset, time: 0, renderBehind, onDetach })
}

export function attachments_removeAttachments(obj: VectorXY) {
	if (DEBUG) {
		logger.debug(`removeAttachments:${(obj as any).name}`)
	}

	const toRemove = (ao) => ao.obj === obj || ao.attachedTo === obj
	attachedObjects.filter(toRemove).forEach((ao) => {
		const onDetach = ao.onDetach
		ao.onDetach = null // to prevent possible infinite recursion
		if (onDetach) {
			onDetach()
		}
	})
	attachedObjects = attachedObjects.filter((ao) => !toRemove(ao))
}

export function attachments_update(delta) {
	attachedObjects.forEach((ao) => {
		const attachedTo = ao.attachedTo
		const obj = ao.obj

		const t = (ao.time += delta)

		obj.x = attachedTo.x + ao.offset(t).x
		obj.y = attachedTo.y + ao.offset(t).y

		if (ao.renderBehind) {
			obj.zIndex = attachedTo.zIndex - 10
		} else {
			obj.zIndex = attachedTo.zIndex + 1
		}
	})
}

export function attachments_shutdown() {
	attachedObjects = []
}

export function attachments_numCallbacks() {
	return attachedObjects.length
}

let attachedObjects: AttachedObject[] = []
