import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const crystalSpider2: VariantAI = {
    extends: ENEMY_NAME.CRYSTAL_SPIDER_1,
    name: ENEMY_NAME.CRYSTAL_SPIDER_2,
    appearance: {
        asset: SpineDataName.CRYSTAL_SPIDER_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
}

export const crystalSpider3: VariantAI = {
    extends: ENEMY_NAME.CRYSTAL_SPIDER_1,
    name: ENEMY_NAME.CRYSTAL_SPIDER_3,
    appearance: {
        asset: SpineDataName.CRYSTAL_SPIDER_3,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
}

export const crystalSpider4: VariantAI = {
    extends: ENEMY_NAME.CRYSTAL_SPIDER_1,
    name: ENEMY_NAME.CRYSTAL_SPIDER_4,
    appearance: {
        asset: SpineDataName.CRYSTAL_SPIDER_4,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
}