import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { GroundHazard, GroundHazardParams } from "./ground-hazard"

export interface TimedGroundHazardParams extends GroundHazardParams {
    lifeTime: timeInSeconds
}

export abstract class TimedGroundHazard extends GroundHazard {
    remainingLifeTime: number
    
    triedToReturnToPool: boolean

    override setDefaultValues(defaultValues: any, overrideValues?: TimedGroundHazardParams): void {
        super.setDefaultValues(defaultValues, overrideValues)
        
        if(overrideValues) {
            this.remainingLifeTime = overrideValues.lifeTime

            this.triedToReturnToPool = false
        }
    }

    override update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        super.update(delta, now)

        this.remainingLifeTime -= delta
        if(!this.triedToReturnToPool && this.remainingLifeTime <= 0) {
            this.returnToPool()
            this.triedToReturnToPool = true
        }
    }

}