import { BuffDefinition } from './buff-definition'
import { BuffTags, StackStyle } from './buff-enums'
import { BuffIdentifier } from './buff.shared'
import { Buff } from './buff'
import { StatOperator, StatType } from '../stats/stat-interfaces-enums'
import { StatBonus } from '../stats/entity-stat-list'
import { Enemy } from '../entities/enemies/enemy'
import { Player } from '../entities/player'
import { RangerSkillWeapon } from '../weapons/actual-weapons/skill/ranger-skill-weapon'
import { CONE_DOG_GOOD_BOY_DAMAGE_PER_STACK, CONE_DOG_GOOD_BOY_MOVEMENT_SPEED_PER_STACK, CONE_DOG_GOOD_BOY_SIZE_PER_STACK, CONE_DOG_GOOD_BOY_STACKS_FOR_AMMO, CONE_DOG_GOOD_BOY_STACKS_PER_BUFF } from '../game-data/characters'
import { UI } from '../ui/ui'
import { GameState } from '../engine/game-state'
import { AllWeaponTypes } from '../weapons/weapon-types'
import { getGoodBoyMoveSpeedBonus, getGoodBoySizeBonus } from '../game-data/player-formulas'
import { LuteWeapon, POWERUP_BUFF_DURATION } from '../weapons/actual-weapons/secondary/lute-weapon'
import { isPlayer } from '../entities/entity-interfaces'
import { RadarPassiveSkill } from '../weapons/actual-weapons/passives/radar-passive-skill'


export const SkillBuffs: BuffDefinition[] = [
	new BuffDefinition({
		identifier: BuffIdentifier.RangerTrapDebuff,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 1,
		reapplyDuration: 0,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const enemy = buff.appliedTo as Enemy

			buff.state.bonus = enemy.statList.addStatBonus('movementSpeed', StatOperator.MULTIPLY, -(buff.stacks / 100))
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.RangerMarkSlowDebuff,
		tags: [BuffTags.Movement],
		duration: 3_500,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 0,
		showToClient: false,
		applyFn(buff: Buff) {
			buff.state = {
				bonus: null,
			}

			const enemy = buff.appliedTo as Enemy

			buff.state.bonus = enemy.statList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -(buff.stacks / 100))
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.RangerMarkDebuff,
		tags: [BuffTags.Movement],
		duration: 3_000,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 1,
		reapplyDuration: 3_000,
		showToClient: false,
		applyFn(buff: Buff) {
			const enemy = buff.appliedTo as Enemy
		},
		wearOffFn(buff: Buff) {

		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.WealthyEnemy,
		tags: [BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 1,
		reapplyDuration: 0,
		showToClient: true,
		applyFn(buff: Buff) {
			const enemy = buff.appliedTo as Enemy
			enemy.isWealthy = true

			buff.state = { gfx: RadarPassiveSkill.wealthyEnemyMarkGfxPool.alloc({ enemy }) }
		},
		wearOffFn(buff: Buff) {
			const enemy = buff.appliedTo as Enemy
			enemy.isWealthy = false

			RadarPassiveSkill.wealthyEnemyMarkGfxPool.free(buff.state.gfx)
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.GoodBoyBuff,
		tags: [BuffTags.Buff, BuffTags.Movement],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 0,
		showToClient: true,
		applyFn(buff: Buff) {
			const thornStacks = GameState.player.binaryFlags.has('cone-dog-thorn-stacks')
			const doubleDamage = GameState.player.binaryFlags.has('cone-dog-double-damage-stacks')
			const doubleSize = GameState.player.binaryFlags.has('cone-dog-double-size-stacks')
			const stackAmmo = GameState.player.binaryFlags.has('cone-dog-more-ammo-stacks')
			buff.state = {
				forceUpdate: false,
				stackThornDamage: thornStacks,
				thornWeapon: null,
				doubleDamageScale: doubleDamage,
				doubleSizeScale: doubleSize,
				stackAmmo: stackAmmo,
				moveSpeedBonus: null,
				attackSizeBonus: null,
				chompDamageBonus: null,
				thornDamageBonus: null,
				ammoBonus: null,
				incrementAmmoBonus: null,
			}
		},
		updateStacksFn(buff, oldStacks: number, newStacks: number) {
			const oldRealStacks = Math.floor(oldStacks / CONE_DOG_GOOD_BOY_STACKS_PER_BUFF)
			const realStacks = Math.floor(newStacks / CONE_DOG_GOOD_BOY_STACKS_PER_BUFF)

			let chompDamageScale = 1
			let thornDamageScale = 0
			let chompSizeScale = 1
			let stackAmmo = 0

			if (buff.state.stackThornDamage) {
				chompDamageScale = 0.5
				thornDamageScale = 0.5
			}
			if (buff.state.doubleDamageScale) {
				chompDamageScale *= 2
			}
			if (buff.state.doubleSizeScale) {
				chompSizeScale = 2
			}
			if (buff.state.stackAmmo) {
				stackAmmo = 1
			}

			if (oldRealStacks !== realStacks || buff.state.forceUpdate) {
				const player = buff.appliedTo as Player
				const sizeBonus = getGoodBoySizeBonus(realStacks)
				const movementBonus = getGoodBoyMoveSpeedBonus(realStacks)

				if (!buff.state.moveSpeedBonus) {
					buff.state.moveSpeedBonus = player.stats.addStatBonus(StatType.movementSpeed, StatOperator.SUM, movementBonus)
					buff.state.attackSizeBonus = player.skillWeapon.statList.addStatBonus(StatType.attackSize, StatOperator.SUM, sizeBonus * chompSizeScale)
					buff.state.chompDamageBonus = player.skillWeapon.statList.addStatBonus(StatType.baseDamage, StatOperator.SUM, realStacks * CONE_DOG_GOOD_BOY_DAMAGE_PER_STACK * chompDamageScale)
					buff.state.ammoBonus = player.skillWeapon.statList.addStatBonus(StatType.maxAmmo, StatOperator.SUM, Math.floor(realStacks / CONE_DOG_GOOD_BOY_STACKS_FOR_AMMO) * stackAmmo)
					buff.state.incrementAmmoBonus = player.skillWeapon.statList.addStatBonus(StatType.reloadAmmoIncrement, StatOperator.SUM, Math.floor(realStacks / CONE_DOG_GOOD_BOY_STACKS_FOR_AMMO) * stackAmmo)

				} else {
					buff.state.moveSpeedBonus.update(movementBonus)
					buff.state.attackSizeBonus.update(sizeBonus * chompSizeScale)
					buff.state.chompDamageBonus.update(realStacks * CONE_DOG_GOOD_BOY_DAMAGE_PER_STACK * chompDamageScale)
					buff.state.ammoBonus.update(Math.floor(realStacks / CONE_DOG_GOOD_BOY_STACKS_FOR_AMMO) * stackAmmo)
					buff.state.incrementAmmoBonus.update(Math.floor(realStacks / CONE_DOG_GOOD_BOY_STACKS_FOR_AMMO) * stackAmmo)
				}

				if (!buff.state.thornDamageBonus && buff.state.thornWeapon) {
					buff.state.thornDamageBonus = buff.state.thornWeapon.statList.addStatBonus(StatType.baseDamage, StatOperator.SUM, realStacks * CONE_DOG_GOOD_BOY_DAMAGE_PER_STACK * thornDamageScale)
				} else if (buff.state.thornDamageBonus) {
					buff.state.thornDamageBonus.update(realStacks * CONE_DOG_GOOD_BOY_DAMAGE_PER_STACK * thornDamageScale)
				}

				buff.state.forceUpdate = false
			}

			UI.getInstance().emitMutation('ui/updateGoodBoyStacks', newStacks)
		},
		wearOffFn(buff: Buff) {
			if (buff.state.moveSpeedBonus) {
				buff.state.moveSpeedBonus.remove()
				buff.state.attackSizeBonus.remove()
				buff.state.chompDamageBonus.remove()
				buff.state.ammoBonus.remove()
				buff.state.incrementAmmoBonus.remove()
			}

			if (buff.state.thornDamageBonus) {
				buff.state.thornDamageBonus.remove()
			}
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.ChompMoveSpeed,
		tags: [BuffTags.Buff, BuffTags.Movement],
		duration: 1400,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 1400,
		showToClient: false,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				bonus: player.stats.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.3)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.BoomerangAccelerationPlayerBuff,
		tags: [BuffTags.Movement],
		duration: 5_000,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 5_000,
		showToClient: false,
		applyFn(buff: Buff) {
			const enemy = buff.appliedTo as Enemy
			buff.state = {
				bonus: null,
			}
		},
		updateStacksFn(buff, oldStacks: number, newStacks: number) {
			const statPerStack = 0.02 // 2%
			const stacks = Math.min(newStacks, 10)
			if (!buff.state.bonus && isPlayer(buff.appliedTo)) {
				buff.state.bonus = buff.appliedTo.stats.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, stacks * statPerStack)
			} else {
				const bonus = buff.state.bonus as StatBonus
				bonus.update(stacks * statPerStack)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.bonus.remove()
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.LutePowerUp,
		tags: [],
		duration: POWERUP_BUFF_DURATION,
		lastsForever: false,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: POWERUP_BUFF_DURATION,
		showToClient: true,
		applyFn(buff: Buff) {
			const player = buff.appliedTo as Player
			buff.state = {
				damageBonus: null,
				attackRateBonus: null,
				lute: player.getWeapon(AllWeaponTypes.Lute)
			}
		},
		updateStacksFn(buff, oldStacks: number, newStacks: number) {
			const player = buff.appliedTo as Player
			const lute = buff.state.lute as LuteWeapon
			const bonusAmount = Math.clamp(newStacks, 0, 5) * 0.1

			if (buff.state.damageBonus) {
				buff.state.damageBonus.update(bonusAmount)
			} else {
				buff.state.damageBonus = player.stats.addStatBonus(StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, bonusAmount)
			}

			if (buff.state.attackRateBonus) {
				buff.state.attackRateBonus.update(bonusAmount)
			} else if (lute.powerUpAttackRate) {
				buff.state.attackRateBonus = player.stats.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, bonusAmount)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.damageBonus.remove()
			if (buff.state.attackRateBonus) {
				buff.state.attackRateBonus.remove()
			}
		},
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SolarAttunement,
		tags: [BuffTags.Upgrade, BuffTags.Buff],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 0,
		reapplyDuration: 0,
		showToClient: true,
		applyFn(buff: Buff) {
			if (isPlayer(buff.appliedTo)) {
				const player = buff.appliedTo
				buff.state.damageMult = player.stats.addStatBonus('allDamageMult', StatOperator.MULTIPLY, 0.2)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.damageMult.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.LunarAttunement,
		tags: [BuffTags.Upgrade, BuffTags.Buff],
		duration: 0,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 0,
		reapplyDuration: 0,
		showToClient: true,
		applyFn(buff: Buff) {
			if (isPlayer(buff.appliedTo)) {
				const player = buff.appliedTo
				buff.state.movementSpeed = player.stats.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.1)
				buff.state.attackSize = player.stats.addStatBonus('attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.25)
			}
		},
		wearOffFn(buff: Buff) {
			buff.state.movementSpeed.remove()
			buff.state.attackSize.remove()
		}
	}),
	// new BuffDefinition({
	// 	identifier: BuffIdentifier.Invulnerable,
	// 	tags: [BuffTags.Buff, BuffTags.Defensive],
	// 	duration: 3000,
	// 	startingStacks: 1,
	// 	stackStyle: StackStyle.RollingStackDurationSeparately,
	// 	reapplyStacks: 1,
	// 	reapplyDuration: 3000,
	// 	showToClient: true,
	// }),
	// new BuffDefinition({
	// 	identifier: BuffIdentifier.NoCollideWithProjectile,
	// 	tags: [BuffTags.Buff, BuffTags.Defensive],
	// 	duration: 3000,
	// 	startingStacks: 1,
	// 	stackStyle: StackStyle.RefreshDuration,
	// 	reapplyStacks: 1,
	// 	reapplyDuration: 3000,
	// 	showToClient: true,
	// }),
]

