import { TrajectoryMod, TrajectoryModPreset, TrajectoryModPresets } from '../projectiles/trajectory-presets'
import {StatName} from '../stats/stat-interfaces-enums'
import { STEAM_PARAMS, STEAM_URL } from '../ui/steam-links'
import { PrimaryWeaponType } from './weapon-types'

const weaponCollectionNames = [
	'bow',
	'wand',
	'boomerang',
	'spear',
	'none',
] as const
type WeaponCollectionName = typeof weaponCollectionNames[number]

export enum ResourceType {
	NONE = 0,
	CHARGE_UP_SHOT = 1,
	ENERGY_COST_SHOT = 2,
	FREE = 3,
}

export type ChargeStats = {
	damage: number,
	projectileSpeed: number,
	attackPierceCount: number,
	attackSize: number,
	pfxScale: number
}

export type ChargeDefinition = {
	chargePercent: number,
	chargeStats: ChargeStats
}

export type WeaponDefinition = {
	stats: Partial<Record<StatName, number>>
	resourceType: ResourceType
	trajectory?: TrajectoryMod
	chargeIncrements?: ChargeDefinition[]
	weaponType: PrimaryWeaponType
	icon?: string
}

export type WeaponCollections = Record<WeaponCollectionName, WeaponDefinition>

export const BOOMERANG_BASE_ATTACK_RATE = 4

const WEAPON_STATS: WeaponCollections = {
	"wand": {
		resourceType: ResourceType.NONE,
		stats: {
			baseDamage: 12,
			allDamageMult: 1,
			attackSize: 20,
			attackRate: 4.0,
			
			walkSpeedScalar: 0.62,

			reloadInterval: 2_000,
			maxAmmo: 9,
			reloadAmmoIncrement: 9,
			cooldownInterval: 0,

			projectileSpeed: 1_800,
			projectileSplitCount: 0,
			projectileChainCount: 0,
			projectileCount: 1,
			attackKnockback: 200,
		},
		weaponType: PrimaryWeaponType.Wand,
		icon: 'primary-wand'
	},
	"bow": {
		resourceType: ResourceType.CHARGE_UP_SHOT,
		stats:{
			baseDamage: 45,
			chargeRate: 90,
			attackSize: 20,
			attackPierceCount: 2,

			walkSpeedScalar: 0.62,

			reloadInterval: 250,
			maxAmmo: 1,
			reloadAmmoIncrement: 1,
			cooldownInterval: 0,

			projectileSpeed: 1_800,
			projectileSplitCount: 0,
			projectileChainCount: 0,
			projectileCount: 1,
			attackKnockback: 420,
		},
		chargeIncrements: [
			{
				chargePercent: 19,
				chargeStats: {
					damage: 0.3,
					projectileSpeed: 0.5,
					attackPierceCount: 0.3,
					attackSize: 0.25,
					pfxScale: 0.3,
				}
			},
			{
				chargePercent: 50,
				chargeStats: {
					damage: 0.55,
					projectileSpeed: 0.8,
					attackPierceCount: 0.5,
					attackSize: 0.5,
					pfxScale: 0.5,
				}
			},
			{
				chargePercent: 75,
				chargeStats: {
					damage: 0.8,
					projectileSpeed: 0.9,
					attackPierceCount: 0.75,
					attackSize: 0.75,
					pfxScale: 0.7,
				}
			},
			{
				chargePercent: 100,
				chargeStats: {
					damage: 1.0,
					projectileSpeed: 1.0,
					attackPierceCount: 1.0,
					attackSize: 1.0,
					pfxScale: 1.0,
				}
			},
		],
		weaponType: PrimaryWeaponType.Bow,
		icon: 'primary-bow'
	},
	"boomerang": {
		resourceType: ResourceType.NONE,
		trajectory: TrajectoryModPresets.get(TrajectoryModPreset.CurvedBoomerang),
		stats: {
			baseDamage: 12,
			allDamageMult: 1,
			attackSize: 35,
			attackRate: BOOMERANG_BASE_ATTACK_RATE,
			
			walkSpeedScalar: 0.55,

			reloadInterval: 2_500,
			maxAmmo: 1,
			reloadAmmoIncrement: 1,
			cooldownInterval: 0,

			projectileSpeed: 1_450,
			projectileSplitCount: 0,
			projectileChainCount: 0,
			projectileCount: 1,
			attackPierceCount: 0,
			attackKnockback: 300,
		},
		weaponType: PrimaryWeaponType.Boomerang,
		icon: 'primary-boomerang'
	},
	"spear": {
		resourceType: ResourceType.FREE,
		weaponType: PrimaryWeaponType.Spear,
		stats: {
			baseDamage: 22,
			attackRate: 0.8333,
			attackKnockback: 600,
			attackSize: 1,

			walkSpeedScalar: 0.68,

			attackPierceCount: 999_999,

			reloadInterval: 0,
			maxAmmo: 0,
			reloadAmmoIncrement: 0,
			cooldownInterval: 0,
		},
		icon: 'primary-spear'
	},
	"none": {
		resourceType: ResourceType.NONE,
		weaponType: PrimaryWeaponType.None,
		stats: {}
	}
}

export const WISHLIST_WEAPON = {
	resourceType: ResourceType.NONE,
	weaponType: PrimaryWeaponType.None,
	stats: {},
	icon: 'locked-weapon',
	isFakeWishlistWeapon: true,
	name: '???',
	description: `An additional weapon is available in the full version of the game. <a href="${STEAM_URL+STEAM_PARAMS.weapon}" target="_blank" style="font-size:30px;">Buy it on Steam!</a> now!`, // loc when rest of weapons are loc'd
    hideTooltip: true,
}

for (const key in WEAPON_STATS) {
	const def = WEAPON_STATS[key] as WeaponDefinition
	if (def.chargeIncrements) {
		def.chargeIncrements.sort((a, b) => a.chargePercent - b.chargePercent)
	}

}

export { WEAPON_STATS }

export function getChargeDefinition(energy: number, charges: ChargeDefinition[]): ChargeDefinition {
	let chargeDef: ChargeDefinition = charges[0]
	let chargeIndex = 1
	while (chargeIndex < charges.length && energy >= charges[chargeIndex].chargePercent) {
		chargeDef = charges[chargeIndex]
		chargeIndex++
	}

	return chargeDef
}