import { degToRad } from '../utils/math'


export enum TrajectoryModType {
	STRAIGHT = 'straight',
	ACCELERATION = 'acceleration',
	ABS_ACCELERATION = 'absAcceleration',
	CURVED_BOOMERANG = 'curveBoomerang',
	OUTBACK_BOOMERANG = 'outbackBoomerang',
	CHAKRAM_BOOMERANG = 'chakramBoomerang',
	TURN = 'turn',
	HARD_TURN = 'hardTurn',
	WAVE = 'wave',
	ZIGZAG = 'zigzag',
	CIRCLE_POINT = 'circlePoint',
	CIRCLE_SELF = 'circleSelf',
	SIZE = 'size',
	HOMING = 'homing',
	SQUARE = 'square',
}

export enum TrajectoryModValueType {
	VALUE = 'value',
	RANDOM = 'random',
	DISTANCE = 'distance',
	LIFETIME = 'lifetime',
	LIFETIME_SQUARED = 'lifetimeSquared',
}



export interface TrajectoryModRollMapping {
	rollId: number
	min: number
	max: number
}


export interface TrajectoryModValue {
	modValueType: TrajectoryModValueType
	value?: number | TrajectoryModRollMapping
	min?: number | TrajectoryModRollMapping
	max?: number | TrajectoryModRollMapping
}


export interface TrajectoryMod {
	modType: TrajectoryModType
	value?: TrajectoryModValue
	amplitude?: TrajectoryModValue
	period?: TrajectoryModValue
	radius?: TrajectoryModValue
	range?: TrajectoryModValue
	computed?: number
}


export enum TrajectoryModPreset {
	// made for this
	SquirrellySineWave,

	// imported from LOOT
	SineWave,
	Accelerate,
	Deccelerate,
	Rangefinder,
	Zigzag,
	StraightBoomerang,
	CurvedBoomerang,
	OutbackBoomerang,
	ChakramBoomerang,
	Circular,
	Orbit,
	Spiral,
	Looping,
	RangeAdjustingCircular,
	RangeAdjustingOrbit,
	HomingInstant,
	HomingFast,
	HomingModerate,
	HomingSlow,
	Square,
	Triangle,
	BigTriangle
}

export const TrajectoryModPresets: Map<TrajectoryModPreset, TrajectoryMod> = new Map()

TrajectoryModPresets.set(TrajectoryModPreset.SquirrellySineWave, {
	modType: TrajectoryModType.WAVE,
	amplitude: { modValueType: TrajectoryModValueType.VALUE, min: 15, max: 20, value: 1 },
	period: { modValueType: TrajectoryModValueType.VALUE, min: 0.3, max: 2, value: 1 },
})




TrajectoryModPresets.set(TrajectoryModPreset.Accelerate, {
	modType: TrajectoryModType.ACCELERATION,
	value: { modValueType: TrajectoryModValueType.VALUE, min: 0.25, max: 1.0 },
})
TrajectoryModPresets.set(TrajectoryModPreset.Deccelerate, {
	modType: TrajectoryModType.ACCELERATION,
	value: { modValueType: TrajectoryModValueType.VALUE, min: -0.25, max: -0.6 },
})
TrajectoryModPresets.set(TrajectoryModPreset.Rangefinder, {
	modType: TrajectoryModType.ACCELERATION,
	value: { modValueType: TrajectoryModValueType.DISTANCE, min: -1.0, max: 1.0 },
})
TrajectoryModPresets.set(TrajectoryModPreset.StraightBoomerang, {
	modType: TrajectoryModType.ABS_ACCELERATION,
	value: { modValueType: TrajectoryModValueType.VALUE, min: -0.1, max: -0.1 },
})
TrajectoryModPresets.set(TrajectoryModPreset.CurvedBoomerang, {
	modType: TrajectoryModType.CURVED_BOOMERANG,
	value: { modValueType: TrajectoryModValueType.VALUE, min: 1, max: 1 },
})
TrajectoryModPresets.set(TrajectoryModPreset.ChakramBoomerang, {
	modType: TrajectoryModType.CHAKRAM_BOOMERANG,
	value: { modValueType: TrajectoryModValueType.VALUE, min: 1, max: 1 }
})
TrajectoryModPresets.set(TrajectoryModPreset.OutbackBoomerang, {
	modType: TrajectoryModType.OUTBACK_BOOMERANG,
	value: { modValueType: TrajectoryModValueType.VALUE, min: 1, max: 1 },
})
TrajectoryModPresets.set(TrajectoryModPreset.Circular, {
	modType: TrajectoryModType.CIRCLE_POINT,
	radius: { modValueType: TrajectoryModValueType.VALUE, min: 100, max: 100 },
	range: { modValueType: TrajectoryModValueType.VALUE, min: 300, max: 300 },
})
TrajectoryModPresets.set(TrajectoryModPreset.Orbit, {
	modType: TrajectoryModType.CIRCLE_SELF,
	radius: { modValueType: TrajectoryModValueType.VALUE, min: 150, max: 150 },
})
TrajectoryModPresets.set(TrajectoryModPreset.Spiral, {
	modType: TrajectoryModType.CIRCLE_SELF,
	radius: { modValueType: TrajectoryModValueType.LIFETIME_SQUARED, min: 50, max: 400 },
})
TrajectoryModPresets.set(TrajectoryModPreset.Looping, {
	modType: TrajectoryModType.CIRCLE_POINT,
	radius: { modValueType: TrajectoryModValueType.VALUE, min: 50, max: 50 },
	range: { modValueType: TrajectoryModValueType.LIFETIME, min: 200, max: 800 },
})
TrajectoryModPresets.set(TrajectoryModPreset.RangeAdjustingCircular, {
	modType: TrajectoryModType.CIRCLE_POINT,
	radius: { modValueType: TrajectoryModValueType.VALUE, min: 100, max: 100 },
	range: { modValueType: TrajectoryModValueType.DISTANCE, min: 100, max: 800 },
})
TrajectoryModPresets.set(TrajectoryModPreset.RangeAdjustingOrbit, {
	modType: TrajectoryModType.CIRCLE_SELF,
	radius: { modValueType: TrajectoryModValueType.DISTANCE, min: 100, max: 800 },
})
TrajectoryModPresets.set(TrajectoryModPreset.HomingInstant, {
	modType: TrajectoryModType.HOMING,
	value: { modValueType: TrajectoryModValueType.VALUE, value: degToRad(9999) },
})
TrajectoryModPresets.set(TrajectoryModPreset.HomingFast, {
	modType: TrajectoryModType.HOMING,
	value: { modValueType: TrajectoryModValueType.VALUE, value: degToRad(3) },
})
TrajectoryModPresets.set(TrajectoryModPreset.HomingModerate, {
	modType: TrajectoryModType.HOMING,
	value: { modValueType: TrajectoryModValueType.VALUE, value: degToRad(2) },
})
TrajectoryModPresets.set(TrajectoryModPreset.HomingSlow, {
	modType: TrajectoryModType.HOMING,
	value: { modValueType: TrajectoryModValueType.VALUE, value: degToRad(1) },
})
TrajectoryModPresets.set(TrajectoryModPreset.Square, {
	modType: TrajectoryModType.SQUARE,
	period: { modValueType: TrajectoryModValueType.VALUE, value: 20 },
	amplitude: { modValueType: TrajectoryModValueType.VALUE, value: degToRad(90) },
})
TrajectoryModPresets.set(TrajectoryModPreset.Triangle, {
	modType: TrajectoryModType.SQUARE,
	period: { modValueType: TrajectoryModValueType.VALUE, value: 20 },
	amplitude: { modValueType: TrajectoryModValueType.VALUE, value: degToRad(45) },
})
TrajectoryModPresets.set(TrajectoryModPreset.BigTriangle, {
	modType: TrajectoryModType.SQUARE,
	period: { modValueType: TrajectoryModValueType.VALUE, value: 1 },
	amplitude: { modValueType: TrajectoryModValueType.VALUE, value: degToRad(45) },
})
TrajectoryModPresets.set(TrajectoryModPreset.SineWave, {
	modType: TrajectoryModType.WAVE,
	period: { modValueType: TrajectoryModValueType.VALUE, value: 4 },
	amplitude: { modValueType: TrajectoryModValueType.VALUE, value: 0.5 },
})