<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div v-for="(key, idx) in Object.keys(currentSettings.keyboardMappings)" :key="idx" class="controller-binding-container container-formatting">
				<div class="label" :lang="this.$i18n.locale">{{ $t(getNameFromAction(key)) }}</div>
				<div class="binding-selector">
					<div class="inputs">
						<div v-if="rebindKey === key" class="input" @keydown="inputEvent(key, $event)" :lang="this.$i18n.locale">
							{{ $t(rebindInputText) + ` ...${countdownValue} ` + $t('settings.rebind_countdown') }}
							<Rebind :idx="key" @key-press="inputEvent" @mouse-click="clickEvent" @countdown="handleCountdown"></Rebind>
						</div>
						<button :class="{ wide: keyCodeToChar(currentSettings.keyboardMappings[key]).length > 1, mouse: isMouseMapping(key) }" v-else @click="rebind(key)" :disabled="rebindKey !== '' && rebindKey !== key">
							<div class="button-gfx">
								<div v-if="isMouseMapping(key)" :class="{ left: isLeftMouse(key), right: isRightMouse(key) }" class="mouse-icon" :lang="this.$i18n.locale">
									<div class="icon-fill"></div>
								</div>
								<div v-else class="key" :lang="this.$i18n.locale">{{ keyCodeToChar(currentSettings.keyboardMappings[key]) }}</div>
							</div>
						</button>
					</div>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Rebind from './rebind.vue'
import Toggle from '../form-inputs/toggle.vue'
import Pagination from '../pagination/pagination.vue'

import { keycodeToCharMixin } from '../mixins/KeycodeToChar'
import { some } from 'lodash'

export default {
	name: 'InputRebindingModule',
	components: {
		EmbellishmentContainer,
		Pagination,
		Rebind,
	},
	data() {
		return {
			rebindKey: '',
			rebindInputText: 'settings.rebind_input',
			countdownValue: 0,
		}
	},
	watch: {},
	props: {},

	computed: {
		...mapState('settings', ['currentSettings']),
		...mapGetters('settings', ['getNameFromAction']),
	},
	methods: {
		...mapActions('settings', ['upgradeBindings', 'saveKeys']),

		isMouseMapping(key) {
			const mapping = this.currentSettings.keyboardMappings[key]
			return mapping === 'MouseL' || mapping === 'MouseR'
		},
		isLeftMouse(key) {
			return this.currentSettings.keyboardMappings[key] === 'MouseL'
		},
		isRightMouse(key) {
			return this.currentSettings.keyboardMappings[key] === 'MouseR'
		},

		rebind(key) {
			this.rebindKey = key
		},
		inputEvent(event) {
			const key = this.rebindKey
			const keyCode = event.code

			if (keyCode === 'Escape') {
				this.rebindInputText = 'settings.rebind_input'
				this.rebindKey = ''
				return
			}

			if (some(this.currentSettings.keyboardMappings, (value) => value.includes(keyCode))) {
				this.rebindInputText = 'settings.rebind_input_error'
				return
			}

			this.rebindInputText = 'settings.rebind_input'
			this.rebindKey = ''
			this.upgradeBindings({ action: key, code: keyCode })
		},
		clickEvent(button) {
			const key = this.rebindKey

			if (some(this.currentSettings.keyboardMappings, (value) => value.includes(button))) {
				this.rebindInputText = 'settings.rebind_input_error'
				return
			}

			if (button === 'MouseL' && key === 'pause') {
				this.rebindInputText = 'settings.rebind_input_pause_error'
				return
			}

			this.rebindInputText = 'settings.rebind_input'
			this.rebindKey = ''
			this.upgradeBindings({ action: key, code: button })
		},
		handleCountdown(value) {
			this.countdownValue = value
			if (value === 0) {
				this.rebindInputText = 'settings.rebind_input'
				this.rebindKey = ''
			}
		},
	},
	mixins: [keycodeToCharMixin],
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
:deep(.content) {
	flex-direction: column;
}
.controller-binding-container {
	display: flex;
	align-items: center;
	width: 660px;
	margin: 5px 0px;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 258px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.binding-selector {
		display: flex;
		justify-content: center;
		width: 60%;
		height: 40px;
		button {
			width: 35px;
			height: 35px;
			color: @darkBlue;
			background: @green;
			border-radius: 3px;
			padding-top: 4px;
			padding-left:3px;
			&.mouse {
				background: none;
				&:hover {
					border: none;
				}
				&:active {
					background: none;
				}
			}
			&:hover {
				border: 2px solid @darkGreen;
			}
			&:active {
				background: @darkGreen;
			}
			&.wide {
				width: 100%;
				padding: 3px 5px 0px;
			}
			.key {
				&[lang='en'] {
					font-size: 32px;
					.regularFont(@enRegularFontPixl);
					text-transform: uppercase;
					line-height: 23px;
				}
				&[lang='zh'] {
					.regularFont(@enRegularFontPixl);
				}
			}
			.mouse-icon {
				position: relative;
				background-image: url('~/assets/ui/control-graphics/Mouse.png');
				width: 55px;
				height: 35px;
				background-size: cover;
				&.right {
					transform: scaleX(-1);
				}
			}
		}
		.input {
			height: 33px;
			width: 400px;
			background-color: #003948 !important;
			font-size: 18px;
			color: @darkGreen;
			display: flex;
			align-items: center;
			justify-content: center;

			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
				font-size: 28px;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 30px;
			}
		}
	}
}
</style>
