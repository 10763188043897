<template>
	<Panel id="account-linking" :height="-1">
		<template #panel-content>
			<div class="header" :lang="this.$i18n.locale">
				{{ $t('account_linking.header_main') }}
			</div>
			<div class="main-content" :lang="this.$i18n.locale" v-html="$t('account_linking.content_main')"></div>
			<div class="button-container">
				<TextButton :button-label="$t('account_linking.button_back')" :button-style="'blue'" @on-click-fn="backBtnFn"></TextButton>
				<div v-if="isElectron">
					<TextButton :button-label="$t('account_linking.button_link')" :button-style="'green'" :font-color="'green'" @on-click-fn="openLinkAccountThroughCode()"></TextButton>
				</div>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import { Views } from '../store/ui-store'

export default {
	name: 'AccountLinking',
	components: {
		Panel,
		TextButton,
	},
	data() {
		return {
			isElectron: process.env.IS_ELECTRON,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('ui', ['selectedPortal']),
		...mapState('user', ['isPlayerCodeInfoLoading']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('ui', ['updateSelectedPortal', 'setActiveView']),
		...mapActions('ui', ['changeActiveView']),
		...mapActions('user', ['generateAccountLinkCode']),
		backBtnFn() {
			this.changeActiveView(Views.SETTINGS)
		},
		selectBtnFn() {

		},
		openGenerateLinkCode() {
			this.generateAccountLinkCode() // the action changes the view on success or pops an error
		},
		openLinkAccountThroughCode() {
			this.setActiveView(Views.LINK_ACCOUNT_THROUGH_CODE)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#account-linking {
	max-width: 730px;
	:deep(.panel-inner) {
		border-width: 53px 51px 68px 46px;
	}
	.header {
		color: @cream;
		text-align: center;
		margin-bottom: 10px;
		color: @green;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-variant: small-caps;
			font-size: 50px;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.main-content {
		max-width: 730px;
		color: @offWhite;
		text-align: center;
		margin: 10px 0px;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 32px;
		}
	}
	.website-buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.button-container {
		display: flex;
		justify-content: center;

		margin: 40px 0px 20px 0px;
		.text-button {
			margin: 0px 15px;
			width: 290px;
		}
	}
}
</style>
