import { PlayerBinaryFlags } from "../buffs/buff-system"
import { StatType } from "../stats/stat-interfaces-enums"
import { STEAM_PARAMS, STEAM_URL } from "../ui/steam-links"
import { timeInSeconds } from "../utils/primitive-types"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "./player-formulas"

export enum CharacterType {
    ElfRanger,
    Barbarian,
    ConeDog,
    SolaraSun,
	SolaraMoon,
    Radar
}

export const PLAYER_SKINS = {
    [CharacterType.ElfRanger]: 'elph-n-ranjar',
    [CharacterType.Barbarian]: 'barb-barian',
    [CharacterType.SolaraSun]: 'solara-sun',
		[CharacterType.SolaraMoon]: 'solara-moon',
    [CharacterType.ConeDog]: 'default',
    [CharacterType.Radar]: 'radar'
}

export const CHARACTER_STATS = {
    [CharacterType.ElfRanger]: {
        [StatType.movementSpeed]: PLAYER_DEFAULT_MOVEMENTSPEED
    },
    [CharacterType.Barbarian]: {
        [StatType.movementSpeed]: PLAYER_DEFAULT_MOVEMENTSPEED
    },
    [CharacterType.ConeDog]: {
        [StatType.movementSpeed]: PLAYER_DEFAULT_MOVEMENTSPEED * 1.2
    },
    [CharacterType.SolaraSun]: {
      [StatType.movementSpeed]: PLAYER_DEFAULT_MOVEMENTSPEED
    },
    [CharacterType.SolaraMoon]: {
      [StatType.movementSpeed]: PLAYER_DEFAULT_MOVEMENTSPEED
		},
    [CharacterType.Radar]: {
        [StatType.movementSpeed]: PLAYER_DEFAULT_MOVEMENTSPEED
    },
}

export const CONE_DOG_GOOD_BOY_STACKS_PER_BUFF = 50
export const CONE_DOG_GOOD_BOY_MOVEMENT_SPEED_PER_STACK = 2.5
export const CONE_DOG_GOOD_BOY_SIZE_PER_STACK = 5
export const CONE_DOG_GOOD_BOY_DAMAGE_PER_STACK = 1.2
export const CONE_DOG_GOOD_BOY_STACKS_FOR_AMMO = 20


export const CONE_DOG_CHOMP_MOVE_SPEED_MIN_ENEMIES = 1
export const CONE_DOG_CHOMP_CDR_MIN_ENEMIES = 3
export const CONE_DOG_CHOMP_CDR_AMOUNT = 250 //ms reduced off of skill cooldown


// UI
export const characterDefinitions = {
    'elph-n-ranjar': {
        name: 'Elph N. Ranjar', //TODO: localization
        description: `A highly skilled elven ranger that controls his enemy's movements with traps and targeted strikes.`, //TODO: localization
        hp: 10,
        characterType: CharacterType.ElfRanger,
        icon: 'character-elf-n-ranjar',
        skillName: 'Woodland Trap',
        skillDefinition: 'A trap that slow enemies that walk over it for a short time.',
        passiveName: `Ranger's Mark`,
        passiveDefinition: 'A nearby enemy will be marked and when hit, a small explosion damages and slows enemies.',

    },
    'cone-dog': {
        name: 'Cone Dog', //TODO: localization
        description: 'Shunned by his peers for his constant need of a cone and his lack of hands, this mangy mutt bites back at his enemies for revenge.', //TODO: localization
        hp: 10,
        characterType: CharacterType.ConeDog,
        icon: 'character-cone-dog',
        skillName: 'Chomp',
        skillDefinition: 'Cone Dog chomps directly in front of him, striking all enemies in the area. Chomp gains Good Boy Stacks and gets bigger and stronger as you chomp and kill enemies.',
        passiveName: `More Back Space`,
        passiveDefinition: `The Cone Dog's broad back allows it to pick an extra secondary weapon.`,
    },
    'barb-barian': {
        name: 'Barb Barian', //TODO: localization
        description: 'A skilled fighter, she uses her intimidating aura to control melee combat, while nimbly tumbling out of dangerous situations.', //TODO: localization
        hp: 10,
        characterType: CharacterType.Barbarian,
        icon: 'character-barb',
        skillName: 'Tumble Roll',
        skillDefinition: 'Allows the player to quickly roll out of the way of danger.',
        passiveName: 'Barbarian Aura',
        passiveDefinition: 'A powerful aura of fierceness makes enemies drop hearts more often.',
    },
    'solara-sun': {
      name: 'Solara', //TODO: add real descriptions and stats
      description: 'Solara is a celestial champion, embracing alternately the power of the sun and the moon.',
      hp: 10,
      characterType: CharacterType.SolaraSun,
      icon: 'character-solara',
      skillName: 'Change Aspect',
      skillDefinition: 'Switch between Solar and Lunar Aspects',
      passiveName: 'Aspect Attunement',
      passiveDefinition: 'While in Solar Aspect, Solara gains a moderate damage boost on all attacks, but takes double the damage whenever she is hurt. While in Lunar Aspect, Solara gains a slight movement speed increase as well as a moderate amount of attack size. XP Pick up values are reduced while she is in this aspect. ',
   },
    'radar': {
        name: 'Radar',
        description: 'A talented Scout with vision a-plenty from using his trusty gadget.', //TODO: localization
        hp: 10,
        characterType: CharacterType.Radar,
        icon: 'character-radar',
        skillName: 'Shock Pulse',
        skillDefinition: `Emit a powerful cone-shaped pulse in the direction you're facing, knocking back enemies.`,
        passiveName: 'FieldOp™ Gadget',
        passiveDefinition: 'Every 30 seconds, trigger your gadget which has a large variety of effects.',
    },
}

export const WISHLIST_CHARACTER = {
    name: '???',
    description: `Additional characters are available in the full version of the game! <a href="${STEAM_URL+STEAM_PARAMS.character}" target="_blank" style="font-size:24px;">Buy it on Steam</a> now!`,
    hp: 10,
    characterType: -1,
    icon: 'locked-character',
    skillName: '???',
    skillDefinition: '???',
    passiveName: '???',
    passiveDefinition: '???',
    hideTooltip: true,
}

export const CHARACTER_NAMES = {
    [CharacterType.ElfRanger]: 'ranger',
    [CharacterType.Barbarian]: 'barbarian',
    [CharacterType.ConeDog]: 'coneDog',
    [CharacterType.SolaraSun]: 'solara',
		[CharacterType.SolaraMoon]: 'solara',
    [CharacterType.Radar]: 'radar'
}

export function getSkillIconFromCharacterType(characterType: CharacterType, optionalBinaryFlag?: PlayerBinaryFlags){
	switch(characterType){
		case CharacterType.ElfRanger:
			return 'skills-thorn-trap'
		case CharacterType.Barbarian:
			return 'skills-tumble-roll'
		case CharacterType.ConeDog:
			return 'skills-chomp'
		case CharacterType.SolaraSun:
			return optionalBinaryFlag === 'solar-supremacy' ? 'skills-solar-flare' : 'skills-lunar-blow'
		case CharacterType.SolaraMoon:
			return optionalBinaryFlag === 'lunar-lady' ? 'skills-lunar-blow' : 'skills-solar-flare'
        case CharacterType.Radar:
			return 'skills-pulse-shock'
	}
}