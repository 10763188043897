import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, PersistedBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"

const mrCuddles: EnemyAI = {
	name: ENEMY_NAME.MR_CUDDLES,
	type: EnemyType.BOSS,
	objectPoolInitialSize: 1,
	objectPoolGrowthSize: 1,
	appearance: {
		asset: SpineDataName.MR_CUDDLES,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
		scale: 1,
		zOffset: 200
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				position: [0, 0],
				radius: 150
			}

		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.84,
			maxHealth: 90.0,
			projectileSpeed: 700,
			attackSize: 200,
			projectileCount: 5,
			projectileSpreadAngle: 120,
			knockbackResist: 9999,

		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 720,
		attackOffset: new Vector(400, 0),
		lootDropOffset: new Vector(0, 0),
		immuneToRecycle: true,
		damageConfig: null,
		playHitAnimation: false,
		animationSpeeds: 0.25,
		
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.MELEE,
				cooldownDef: {
					maxAmmo: 1,
					cooldownInterval: 2_400,
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
				// How long into the model attack animation do we wait until PFX are played and damage is dealt
				telegraphDelay: .33,
				particleEffectType: ParticleEffectType.PROJECTILE_NONE
			},
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 300,
			movementMinDistance: 50,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default mrCuddles
