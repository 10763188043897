import EntityStatList from "../../../stats/entity-stat-list"
import {WEAPON_STATS} from '../../weapon-definitions'
import { AllWeaponTypes } from "../../weapon-types"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { Player } from "../../../entities/player"
import { Vector } from "sat"

import { ChargePrimaryWeapon } from "../../charge-primary-weapon"
import { LongbowBlowback, LongbowBlowbackParams } from "../../../entities/longbow-blowback"
import { ObjectPoolTyped } from "../../../utils/third-party/object-pool"
import { GravityPinDown, GravityPinDownParams } from "../../../entities/gravity-pin-down"
import { Effect } from "../../../engine/graphics/pfx/effect"

export class Bow extends ChargePrimaryWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.Bow

	projectileEffectType: ParticleEffectType = ParticleEffectType.PROJECTILE_CROSSBOW
    projectileTrailType: ParticleEffectType = ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL

	init(player, playerStatList: EntityStatList) {

		if(!LongbowBlowback.pool) {
			LongbowBlowback.pool = new ObjectPoolTyped<LongbowBlowback, LongbowBlowbackParams>(() => new LongbowBlowback(), {}, 4, 1)
		}
    if(!GravityPinDown.pool) {
      GravityPinDown.pool = new ObjectPoolTyped<GravityPinDown, GravityPinDownParams>(() => new GravityPinDown(), {}, 4, 1)
    }
	}
    
    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
        for (const stat of Object.keys(WEAPON_STATS.bow.stats)) {
            statList._actualStatValues[stat] = WEAPON_STATS.bow.stats[stat]
        }

        statList._actualStatValues.projectileChainDistanceMultiplier = 1
    }

    update(delta: number) {

    }
}

export function handleLongbowEnemyBlowback(player: Player, damage: number) {
	LongbowBlowback.pool.alloc({
		position: new Vector(player.projectileOriginBone.worldX, player.projectileOriginBone.worldY).add(player.position),
		angleBehindPlayer: player.aimAngle + Math.PI,
		statList: player.primaryWeapon.statList,
		damage: damage
	})
}

type StormBreakerChargeColorPresets = 'gold' | 'green' | 'red' | 'blue' | 'pink'
type ChargeColor = {r: number, g: number, b: number, a: number}

export type StormBreakerChargeColorConfigs =  Partial<Record<StormBreakerChargeColorPresets, ChargeColor>>

// Used to color generic aoe-explosion-white to whatever is needed for a given elemental effect 
export const StormBreakerChargeColors: StormBreakerChargeColorConfigs =  {
	"gold": {
		r: 0.957,
		g: 0.643,
		b: 0.106,
		a: 0.5
	},
	"green": {
		r: 0.035,
		g: 0.698,
		b: 0.078,
		a: 0.5
	},
	"red": {
		r: 1,
		g: 0.078,
		b: 0.063,
		a: 0.5
	},
	"blue": {
		r: 0.106,
		g: 0.588,
		b: .957,
		a: 0.5
	},
	"pink": {
		r: 0.804,
		g: 0.475,
		b: 0.843,
		a: 0.5
	}
} as const

export function setChargePfxColor(effect: Effect, colorConfig: StormBreakerChargeColorPresets) {
	const color = StormBreakerChargeColors[colorConfig]
	effect.emitters.forEach((e)=>{
			e.startColor = color
	})
}