import { nextTick } from "vue"
import introJs from 'intro.js'

export function startWalkthrough(steps) {
    nextTick(() => {
        // https://introjs.com/docs/intro/options
        introJs().setOptions({
            // @TODO set options (css classes) to make it not look ugly
			tooltipClass: 'introjs-override',
            exitOnEsc: false,
            exitOnOverlayClick: false,
			showBullets: false,
            steps
        }).start()
    })
}

// loc loc loc loc loc loc loc loc loc l-l-l-l-l-l-l-l-loc!

export function startCreateStoryWalkthrough() {
    const steps = [
        {
			title: 'Diebrary',
            intro: 'Welcome To Diebrary!'
        }, 
        {
			title: 'Create Story',
            intro: `Click "Create Story" to author a new story`,
            element: document.querySelector('#create-story-btn')
        }, 
    ]

    startWalkthrough(steps)
}

export function startStoriesWalkthrough() {
    // @TODO color the words in <b></b>
    const steps = [
        {
			title: 'How Stories Work',
            intro: `This is the story screen. You can have up to 15 active stories at once.`
        },
        {
			title: 'How Stories Work',
            intro: `You can join and leave a story at any time. You can find past stories in your <b>Archived Stories</b>.`,
            element: document.querySelector('#archived-stories-btn')
        },
        {
			title: 'How Stories Work',
            intro: `<img src="ui/ftue/ftue-image-book.png" style="width: 275px; height: 219px;"/> Find new community stories to join and play with others!`
        },
        {
			title: 'How Stories Work',
            intro: `<img src="ui/ftue/ftue-image-async.png" style="width: 275px; height: 219px;"/> And share your own stories with your friends to <b>play together!</b>`
        }
    ]

    startWalkthrough(steps)
}

export function startArchivedStoriesWalkthrough() {
    const steps = [
        {
			title: 'How Stories Work',
            intro: 'These are your past Archived Stories. Your most recent stories will be displayed here.'
        },
        {
			title: 'How Stories Work',
            intro: 'Rejoin them instantly to play them again.<br>Tip: You can always join a story by using the Story ID link.'
        }
    ]

    startWalkthrough(steps)
}

export function startFirstChapterWinCharacterSelectWalkthrough(plotTwistName: string) {
    const steps = [
        {
			title: 'Plot Twists',
            intro: 'Congratulations, you beat the chapter!'
        },
        {
			title: 'Plot Twists',
            intro: `You authored the ${plotTwistName} Plot Twist, which will appear on all future chapters.`,
            element: document.querySelector('#plot-twist-container')
        },
        {
			title: 'Plot Twists',
            intro: 'See how far you can progress your story and what additional Plot Twists  you can unlock!'
        },
        {
			title: 'Plot Twists',
            intro: 'You also receive currency for playing and beating chapters, spend it in the Perks & Unlocks store.',
            element: document.querySelector('#perks-and-unlocks-btn')
        }
    ]

    startWalkthrough(steps)
}