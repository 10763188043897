import { Container, Graphics } from 'pixi.js'
import { Vector } from 'sat'
import { ColliderComponent } from '../engine/collision/collider-component'
import { CircleCollider, CircleColliderConfig, ColliderType } from '../engine/collision/colliders'
import { CollisionLayerBits } from '../engine/collision/collision-layers'
import CollisionSystem from '../engine/collision/collision-system'
import { ComponentOwner } from '../engine/component-owner'
import { GameState, getNID } from "../engine/game-state"
import { getRandomProjectileParticle, getRandomProjectileTrail, ParticleEffectType } from '../engine/graphics/pfx/particle-config'
import { Renderer } from '../engine/graphics/renderer'
import { EntityType, IEntity } from "../entities/entity-interfaces"
import { gameUnits, timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from '../utils/third-party/object-pool'
import { IRangedProjectile } from "./projectile-types"
import { Player } from "../entities/player"
import { AbstractRiggedSpineGFXComponent } from '../engine/graphics/abstract-rigged-spine-component'
import { DamageSource } from './damage-source'
import EntityStatList from '../stats/entity-stat-list'
import { StatType } from '../stats/stat-interfaces-enums'
import { AllWeaponTypes } from '../weapons/weapon-types'

const PROJECTILE_AND_BEAM_MAX_RANGE = 2500

const BASE_COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		position: [0, 0],
		radius: 40,
	}
]

export interface EnemyProjectileInitialParams {
	position: Vector
	speed: number
	aimAngleInRads: number
	radius: number
	collisionLayer: CollisionLayerBits
	baseDamage: number
	statList: EntityStatList
	particleEffectType: ParticleEffectType

	damageBonus?: number
	alreadyCollided?: number[]
	riggedSpineComponent?: AbstractRiggedSpineGFXComponent
	onCleanup?: (projectile: EnemyProjectile) => void
}

export class EnemyProjectile implements IEntity, IRangedProjectile, ComponentOwner, PoolableObject, DamageSource {
	static objectPool: ObjectPoolTyped<EnemyProjectile, EnemyProjectileInitialParams>

	// engine & collision
	nid: number
	entityType: EntityType
	position: Vector

	get x() {
		return this.position.x
	}

	get y() {
		return this.position.y
	}

	startPos: Vector
	speed: number
	initialSpeed: number
	timeScale: number = 1

	radius: number

	baseDamage: number

	entitiesCollided: number[] = []
	
	// This property would normally be inherited from the DamageSource interface
	isPlayerOwned(): boolean {
		return false
	}

	// performance & tickrate
	catchupTime: timeInSeconds
	toBeDeleted: boolean
	weaponType: AllWeaponTypes = AllWeaponTypes.Enemy

	// stat tracking
	lifespan: number
	maxRangeForDeletion: gameUnits
	reachedMaxRange: boolean
	distanceTravelled: gameUnits
	travelTimeElapsedInSeconds: number
	travelTimeElapsedForWaveFunctionsPrev: any // Needed for IRangedProjectile
	travelTimeElapsedForWaveFunctions: any // Nedded for IRangedProjectile

	statList: EntityStatList
    numEntitiesChained: number
	numEntitiesPierced: number

	// trajectories
	reversedTrajectory: any // Required for IRangedProjectile
	leftRightFlip: boolean // Required for IRangedProjectile
	angleToPeakOfArc: any // Required for IRangedProjectile
	aimAngleInRads: number // Required for IRangedProjectile
	willReverseTrajectory: boolean // Required for IRangedProjectile

	// gfx
	particleEffectType: ParticleEffectType
	bulletTrailParticleEffectType: ParticleEffectType
	// debug gfx
	model: Container

	colliderComponent: ColliderComponent

	showImmediateDamageNumber: boolean = false

	isBoomerang: boolean = false
	noEffectRotation: boolean = false

	riggedSpineComponent?: AbstractRiggedSpineGFXComponent
	onCleanup?: (projectile: EnemyProjectile) => void

	constructor() {
		this.nid = getNID(this) //TODO: move this to initialize lifecycle once object pooling is in... don't forget to clean up old nids
		this.entityType = EntityType.EnemyProjectile
		this.position = new Vector()
		this.colliderComponent = new ColliderComponent(BASE_COLLIDER_CONFIG, this, CollisionLayerBits.EnemyProjectile, this.onCollision.bind(this), false, true, true)
	}

	setDefaultValues(defaultParams: any, overrideValues?: EnemyProjectileInitialParams) {
		if(overrideValues) {
			this.position.copy(overrideValues.position)
			this.speed = overrideValues.speed
			this.aimAngleInRads = overrideValues.aimAngleInRads
			this.radius = overrideValues.radius
			this.baseDamage = overrideValues.baseDamage
			this.statList = overrideValues.statList

			this.startPos = this.position.clone()
			this.initialSpeed = this.speed
			this.distanceTravelled = 0
			this.travelTimeElapsedInSeconds = 0
			this.travelTimeElapsedForWaveFunctions = 0
			this.travelTimeElapsedForWaveFunctionsPrev = 0
			this.maxRangeForDeletion = PROJECTILE_AND_BEAM_MAX_RANGE
			this.lifespan = this.statList.getStat(StatType.projectileLifeSpan)

			this.riggedSpineComponent = overrideValues.riggedSpineComponent

			if(!overrideValues.riggedSpineComponent) {
				this.particleEffectType = overrideValues.particleEffectType
				this.setParticleEffectTrail()
				Renderer.getInstance().registerProjectile(this)
			} else {
				overrideValues.riggedSpineComponent.owner = this
				
				overrideValues.riggedSpineComponent.update(0)
				overrideValues.riggedSpineComponent.addToScene()
			}

			this.onCleanup = overrideValues.onCleanup

			if(overrideValues.alreadyCollided) {
				for(let i = 0; i < overrideValues.alreadyCollided.length; ++i) {
					this.entitiesCollided.push(overrideValues.alreadyCollided[i])
				}
			}

			const circleCollider = (this.colliderComponent.colliders[0] as CircleCollider)
			circleCollider.r = overrideValues.radius
			this.colliderComponent.recalculateBounds()
			this.colliderComponent.setLayer(overrideValues.collisionLayer)

			this.colliderComponent.previousPosition.x = this.position.x
			this.colliderComponent.previousPosition.y = this.position.y

			CollisionSystem.getInstance().addCollidable(this.colliderComponent)
			GameState.addEntity(this)
		}
	} 

	cleanup() {
		Renderer.getInstance().unregisterProjectile(this.nid)
		CollisionSystem.getInstance().removeCollidable(this.colliderComponent)

		GameState.removeEntity(this)

		if(this.onCleanup) {
			this.onCleanup(this)
			this.onCleanup = null
		}

		this.toBeDeleted = false
		this.entitiesCollided.length = 0

		if(this.riggedSpineComponent) {
			this.riggedSpineComponent.removeFromScene()
			this.riggedSpineComponent = null
		}
	}

	setDebugModel() {
		this.model = new Container()
		const gfx = new Graphics()
		gfx.beginFill(0x00FF00, 1)
		gfx.drawCircle(0, 0, this.radius)
		gfx.endFill()
		this.model.addChild(gfx)
	}

	setRandomEffect() {
		this.particleEffectType = getRandomProjectileParticle()
		this.bulletTrailParticleEffectType = getRandomProjectileTrail()
	}

	update(delta: timeInSeconds): void {
		this.update2(delta)

		// if (this.catchupTime > 0) {
		// 	this.catchupTime -= delta
		// 	this.update2(delta) // going over?? hello? 
		// }
	}

	update2(delta: number): void {
		this.travelTimeElapsedInSeconds += delta
		if (this.travelTimeElapsedInSeconds > this.lifespan) {
			this.toBeDeleted = true
			// console.log('marked because lifespan')
		}

		this.distanceTravelled += this.speed * delta

		this.position.x += Math.cos(this.aimAngleInRads) * this.speed * delta
		this.position.y += Math.sin(this.aimAngleInRads) * this.speed * delta

		if(this.riggedSpineComponent) {
			this.riggedSpineComponent.update(delta)
		}

		if (this.toBeDeleted) {
			this.destroy()
		}
	}

	onCollision(otherEntity: ColliderComponent) {
		if(otherEntity.owner.entityType === EntityType.Player) {
			const player = otherEntity.owner as Player
		
			const foundId = this.entitiesCollided.find((val) => val === player.nid)
			if(foundId) {
				return
			}	
			this.entitiesCollided.push(player.nid)
			this.toBeDeleted = true
		} else if (otherEntity.owner.entityType !== EntityType.GroundHazard) {
			this.toBeDeleted = true
		}
	}

	destroy() {
		EnemyProjectile.objectPool.free(this)
	}

	clearStatsToAllowMultiHitting(clearShotgunningCount?: boolean) {
		this.entitiesCollided.length = 0
	}

	limitCircularSpeed(radius: number) {
		throw new Error("Method not implemented.")
	}
	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		const kbSource: Vector = this.startPos
		return mutableEntityPos.sub(kbSource).normalize()
	}

	// Sets elemental trail based on elemental damage chance. Does not yet handle enemy projectiles with multiple elements
	private setParticleEffectTrail() {
		const statList = this.statList
		if (!this.statList) {
			this.bulletTrailParticleEffectType = ParticleEffectType.PROJECTILE_ENEMY_TRAIL
			return
		}
		if (statList.getStat(StatType.igniteChance) > 0) {
			this.bulletTrailParticleEffectType = ParticleEffectType.PROJECTILE_FIRE_TRAIL
		} else if (statList.getStat(StatType.chillChance) > 0) {
			this.bulletTrailParticleEffectType = ParticleEffectType.PROJECTILE_ICE_TRAIL
		} else if (statList.getStat(StatType.poisonChance) > 0) {
			this.bulletTrailParticleEffectType = ParticleEffectType.PROJECTILE_POISON_TRAIL
		} else if (statList.getStat(StatType.shockChance) > 0) {
			this.bulletTrailParticleEffectType = ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL
		} else {
			this.bulletTrailParticleEffectType = ParticleEffectType.PROJECTILE_ENEMY_TRAIL
		}
	}
}
