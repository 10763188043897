<template>
	<Panel id="error-prompt" :height="-1">
		<template #panel-content>
			<div class="prompt">
				<img src="../../../assets/ui/angry_crab.png" alt="">
				<div class="header" :lang="this.$i18n.locale">{{ $t(errorTitle) }}</div>
				<div :lang="this.$i18n.locale" class="description" v-html="$t(errorDescription)"></div>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'

export default {
	name: 'Error',
	components: {
		Panel
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapState('error', ['errorTitle', 'errorDescription'])
	},
	methods: {
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#error-prompt {
	max-width: 500px;
	.prompt {
		display: flex;
		flex-direction: column;
		align-items: center;
		img{
			margin-bottom: 10px;
		}
		.header {
			color: @cream;
			margin-bottom: 20px;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 45;
				font-variant: small-caps;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
		.description {
			text-align: center;
			color: @cream;
			padding: 10px 30px;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 32px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
	}
}
</style>
