import { Container, Graphics, Sprite, Text } from "pixi.js"
import { cloneDeep, map, random, throttle } from 'lodash'
import ClientPlayerInput, { InputAction, INPUT_DOWN_ACTION_EVENT_NAME, INPUT_UP_ACTION_EVENT_NAME } from "../engine/client-player-input"
import { percentage, radians, timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { EntityType, IEntity } from "./entity-interfaces"
import { Vector } from 'sat'
import { RiggedSpineModel } from "../engine/graphics/spine-model"
import { AssetManager } from "../web/asset-manager"
import { SpineDataName } from "../spine-config/spine-config"
import configureAnimationTracks, { MixSettings, PLAYER_BOW_MIX_SETTINGS, PLAYER_DOG_MIX_SETTINGS, PLAYER_MIX_SETTINGS } from "../engine/graphics/configure-animation-tracks"
import { AnimationTrack } from "../spine-config/animation-track"
import playAnimation from "../engine/graphics/play-animation"
import { getFacingDirection, updateAimFacing, updateAimRotation } from "../spine-config/aim-util"
import EntityStatList, { GlobalStatList, StatBonus } from "../stats/entity-stat-list"
import { StatName, StatOperator, StatType } from "../stats/stat-interfaces-enums"
import { GameState, getNID } from "../engine/game-state"
import { IProjectileShooter } from "../projectiles/projectile-types"
import { PlayerProjectile } from "../projectiles/projectile"
import { angleInRadsFromVector } from "../utils/vector"
import { Buff, updateSerializedBuffProperties } from "../buffs/buff"
import { BuffIdentifier } from "../buffs/buff.shared"
import { CircleColliderConfig, ColliderType } from "../engine/collision/colliders"
import { ColliderComponent } from "../engine/collision/collider-component"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import CollisionSystem from "../engine/collision/collision-system"
import { allocGroundPickup, GroundPickup, GroundPickupConfig, PickupAmountConfig, PickupRangeConfig } from "./pickups/ground-pickup"
import { levelToXPThreshold } from "../game-data/levelling"
import { UI } from "../ui/ui"
import { ComponentOwner } from "../engine/component-owner"
import { GAMEPLAY_SPEED_MULTIPLIER, InGameTime } from "../utils/time"
import { BinaryFlagDefaultState, BLOOD_SOAKED_ROUNDS_AMMO_CHANCE, BLOOD_SOAKED_ROUNDS_AMMO_CHANCE_MAX, BOOST_SPEED_PER_TICK, CAREFUL_SHOOTER_STACKS_REQUIRED_FOR_BLOOD_SOAKED_ROUNDS_BONUS, CONSTANT_MOVEMENT_YOINK_DURATION, IBuffableEntity, KILLSTREAK_ATTACK_RATE_MULTI, KILLSTREAK_COOLDOWN_RATE_MULTI, KILLSTREAK_DAMAGE_MULTI, KILLSTREAK_MOVESPEED_MULTI, KILLSTREAK_PICKUP_RANGE_MULTI as KILLSTREAK_PICKUP_RANGE_MULTI, KILLSTREAK_RELOAD_RATE_MULTI, KILLSTREAK_XP_DROP_MULTI, MAX_BOOST_SPEED, MAX_BOOST_TIME, MIN_BOOST_SPEED, PartialFlagToStateMap, PlayerBinaryFlags, YOINK_FAST_GHOSTFORM_COOLDOWN, YOINK_FAST_GHOSTFORM_XP_NEEDED } from "../buffs/buff-system"
import { UpgradeManager } from "../upgrades/upgrade-manager"
import { StackStyle } from "../buffs/buff-enums"
import { Audio } from "../engine/audio"
import { PrimaryWeapon } from "../weapons/primary-weapon"
import { PrimaryWeaponType, AllWeaponTypes, SecondaryWeaponType, WeaponConfig } from "../weapons/weapon-types"
import { ChargeDefinition, getChargeDefinition, ResourceType } from "../weapons/weapon-definitions"
import { SkillWeapon } from "../weapons/skill-weapon"
import { Renderer } from "../engine/graphics/renderer"
import { Effect } from "../engine/graphics/pfx/effect"
import { makePassiveSkill, makeSkillWeapon, makeSecondaryWeapon } from "../weapons/weapon-factories"
import { characterDefinitions, CharacterType, CHARACTER_STATS, PLAYER_SKINS, getSkillIconFromCharacterType } from "../game-data/characters"
import { PassiveSkill } from "../weapons/passive-skill"
import { IStatListOwner } from "../weapons/stat-list-owner"
import { Bow, setChargePfxColor } from "../weapons/actual-weapons/primary/bow-weapon"
import { Wand } from "../weapons/actual-weapons/primary/wand-weapon"
import { Boomerang, OUTBACK_ORBIT_RADIUS_UPGRADE_AMOUNT } from "../weapons/actual-weapons/primary/boomerang-weapon"
import { CHARGE_WEAPON_MAX_VISUAL_PIPS, CLARITY_AURA_COLOR, CLARITY_AURA_ENEMY_COUNT_MIN, CLARITY_AURA_LERP_TIME, CLARITY_AURA_MAX_STRENGTH, CONEDOG_BACK_FOOT_DUST_OFFSET, CONEDOG_FRONT_FOOT_DUST_OFFSET, DEMIGOD_OF_THUNDER_DAMAGE_MULTIPLIER, DEMIGOD_OF_THUNDER_RADIUS, getAdjustedKillstreakDuration, getConeDogThornCooldownReduction, getEffectMultiplierBasedOnSkill, getKillstreakExplosionDamageScale, getDamageByPlayerLevel, LARGE_XP_VALUE, MEDIUM_XP_VALUE, PLAYER_CHARGE_MISFIRE_PERCENT, PLAYER_DEFAULT_MOVEMENTSPEED, PLAYER_HIT_KNOCKBACK_SEARCH_DISTANCE, PLAYER_HIT_KNOCKBACK_STRENGTH, PLAYER_KILLSTREAK_EXPLOSION_RADIUS, PLAYER_KILLSTREAK_MAJOR_INTERVAL, PLAYER_KILLSTREAK_MINOR_INTERVAL, PLAYER_LEVEL_UP_KNOCKBACK_SEARCH_DISTANCE, PLAYER_LEVEL_UP_KNOCKBACK_STRENGTH, PLAYER_MAX_SECONDARY_WEAPONS, PLAYER_SHOOT_CAM_SHAKE, PLOT_TWIST_BUTTERFINGERS_MAX_FORCE, PLOT_TWIST_BUTTERFINGERS_MIN_FORCE, PLOT_TWIST_HARDCORE_SURVIVAL_EXHAUSTED_MOVEMENT_DEBUFF_AMOUNT, PLOT_TWIST_HARDCORE_SURVIVAL_EXHAUSTED_STAMINA_RECOVERY_RATE, PLOT_TWIST_HARDCORE_SURVIVAL_LARGE_HEART_STAMINA_RECOVERY, PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA, PLOT_TWIST_HARDCORE_SURVIVAL_NORMAL_STAMINA_RECOVERY_RATE, PLOT_TWIST_HARDCORE_SURVIVAL_SMALL_HEART_STAMINA_RECOVERY, PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_CONSUMPTION_RATE, PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_CONSUMPTION_RATE_DOG, PLOT_TWIST_HEAD_START_LEVEL_BOOST_START_TIME, PLOT_TWIST_HEAD_START_LEVEL_CAP, PLOT_TWIST_HEAD_START_XP_SLOWDOWN_LEVEL, PLOT_TWIST_HEAD_START_XP_SLOWDOWN_MULT, SMALL_XP_VALUE, INTELLIGENCE_DUMP_STAT_LEVEL_DAMAGE_BONUS } from "../game-data/player-formulas"
import { Camera, FocusablePosition, SHAKE_TRAUMA_MAX } from "../engine/graphics/camera-logic"
import { DamageSource } from "../projectiles/damage-source"
import { EnemyProjectile } from "../projectiles/enemy-projectile"
import { defaultStatAttribute } from "../game-data/stat-formulas"
import { debugConfig, REVIVE_ON_DEATH_URL_KEY } from "../utils/debug-config"
import { Enemy } from "./enemies/enemy"
import { PauseManager } from "../engine/pause-manager"
import { AppliedBuffVisuals, clearAttachedPfx, handleBuffChange } from "../buffs/buff-visuals"
import PlayerMetricsSystem from "../metrics/metric-system"
import { angleOfVector, chanceToLoops, degToRad, getRandomDirectionVector, getRandomPointInCircleRange, mapToRange, sub, withinDistanceVV } from "../utils/math"
import NavigationArrow from "../events/navigation-arrow"
import { ExternalPhysicsForce, updatePhysics } from "../engine/physics/physics"
import { VictoryDeathManager } from "../engine/victory-death-manager"
import { EmptyPrimaryWeapon } from "../weapons/actual-weapons/primary/empty-primary-weapon"
import { ConeDogThornsWeapon } from "../weapons/actual-weapons/secondary/cone-dog-thorns-weapon"
import { attachments_addAttachment } from "../utils/attachments-system"
import { SpearWeapon } from "../weapons/actual-weapons/primary/spear-weapon"
import { DAMAGE_OVER_TIME_SOURCE, getBleedStacks, getChillStacks, getIgniteStacks, getPoisonStacks } from "../buffs/generic-buff-definitions"
import { AttackTypes } from "./enemies/ai-types"
import { GroundHazard } from "./hazards/ground-hazard"
import { Wallet } from "../game-data/currency"
import { callbacks_addCallback } from "../utils/callback-system"
import { DarkStormyNightWeapon } from "../weapons/actual-weapons/secondary/dark-stormy-night-weapon"
import { simpleAnimation_addScaleAnimation } from "../utils/simple-animation-system"
import { easeOutBounceReversed } from "../utils/easing-functions"
import { TrajectoryBoomerangStage } from "../projectiles/trajectory"
import { AutoFireSecondaryWeapon } from "../weapons/actual-weapons/secondary/auto-fire-secondary-weapon"
import { dealAOEDamageDamageSource } from "../projectiles/explosions"
import { PetRescueEventSystem } from "../events/pet-rescue-gameplay-event"
import { filterFromString } from "../engine/graphics/filters"
import { GlowFilter } from "pixi-filters"
import { LUNAR_ATTUNEMENT_XP_REDUCTION, SOLAR_SUPREMACY_DROP_CHANCE, WISDOM_OF_GODDESS_XP_REDUCTION } from "../weapons/actual-weapons/skill/solara-skill-weapon"
import { GenerationType, WORLD_DATA, MapOption, MapConfig } from "../world-generation/world-data"
import { SNOWBALL_ENEMY_NAMES } from "./enemies/enemy-names"
import { SPICY_PEPPER_KNOCKBACK_STRENGTH, spicyPepperApplyIgnite } from "../game-data/plot-twist-misc"
import { GroundPickupConfigType, GroundPickupType } from "./pickups/ground-pickup-types"
import { SpectralHoeGfx } from "./spectral-hoe-gfx"
import { LightningStrike } from "./lightning-strike"
import { getBarbarianPassiveSkillRange } from "../weapons/actual-weapons/passives/barbarian-passive-skill"
import { GameClient } from "../engine/game-client"
import AISystem from "./enemies/ai-system"
import { LocalSettings } from "../settings/local-settings"

const RANGEFINDER_URL_KEY = 'rangefinder'
export const PLAYER_OVER_AIM_DEGREES = 0//40
const PLAYER_BUFF_LOGGING = false
const PLAYER_DPS_SAMPLE_DURATION: timeInSeconds = 10

const YETI_STUN_DURATION = 5250

const HEALTH_CONTAINER_OFFSET = 35 / 2
const HEART_SCALE_BASE = 1
const HEART_SCALE_MAX = 1.2
const HEART_SCALE_MIN = 0.9
const HEART_PULSE_TIME = 0.5
// The indices of the filled / half-filled heart sprites for the first two heart containers to allow easier iteration
const HEART_INDICES = [1, 2, 4, 5]
const HEART_GLOW_FILTER_PARAMS = `{
	"color": "0x880808",
	"outerStrength": 2
  }`
const HEALTH_PER_HEART = 2

export const JANK_AIM_Y_OFFSET = 79.465 // camera origin is on the model origin, the player's arm is a bit higher than that
export const JANK_AIM_X_OFFSET = 100

const ON_DAMAGE_INVULN_TIME: timeInMilliseconds = 700
const ON_DAMAGE_MOVEMENT_LOCK_TIME: timeInMilliseconds = 150

const NO_SHOOT_RELOAD_START_TIME: timeInSeconds = 0.6

const RELOAD_GRAPHICS_RADIUS = 60

const TUNDRA_DOWNHILL_MULTIPLIER = 1.05
const TUNDRA_UPHILL_MULTIPLIER = 0.8
const PLAYER_FRICTION = 0.75

const PLAYER_COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		position: [0, -14],
		radius: 16,
	},
]

const PLAYER_PICKUP_COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		position: [0, 0],
		radius: 400,
	}
]
export const PLOT_TWIST_INTELLIGENCE_DUMP = 0.5
export const PLOT_TWIST_BERSERKER_STAT_MULTIPLIER = 0.33

enum ChargeAmmoMode {
	Ammo,
	Charge,
	None,
}

export enum ClarityAuraReason {
	None = 0,
	BehindProp = 1 << 0,
	EnemyCount = 1 << 1
}

const throttledEnergyPercentageEventEmitter = throttle((percentageOfMaxEnergy) => {
	UI.getInstance().emitMutation('ui/updateEnergyBar', percentageOfMaxEnergy)
}, 100)


export class Player implements IEntity, IProjectileShooter, ComponentOwner, IBuffableEntity {
	currentAttackCooldown: number

	// without this assertion it won't assign to IProjectileShooter
	entityType = EntityType.Player as const
	nid: number
	timeScale: number = 1

	model: Container = new Container() //TODO: this will become a spine asset
	binaryFlags: Set<PlayerBinaryFlags>
	binaryFlagState: PartialFlagToStateMap = cloneDeep(BinaryFlagDefaultState)

	riggedModel: RiggedSpineModel
	weapon: RiggedSpineModel
	primaryWeaponType: PrimaryWeaponType
	rotationBone: PIXI.spine.core.Bone
	positionBone: PIXI.spine.core.Bone
	projectileOriginBone: PIXI.spine.core.Bone

	reloadGraphicsContainer: Container
	reloadGraphicsMask: Graphics
	reloadSprite: Sprite

	healthBarContainer: Container
	healthBarsFull: PIXI.Sprite
	healthBarsHalf: PIXI.Sprite
	healthBarsEmpty: PIXI.Sprite
	ammoBarContainer: Container
	chargeAmmoTextures = {}
	chargeAmmoCurrentTexture: PIXI.Texture

	staminaBarContainer: Container
	staminaBarBg: PIXI.Graphics
	staminaBarFg: PIXI.Graphics

	currentXP: number
	showLevelUpModal: boolean = true
	levelUpModalOpen: boolean = false
	level: number
	nextLevel: number
	currentHealth: number
	lastMaxHealth: number
	healthbarPulseAcc: timeInSeconds = 0
	healthbarScale: number = HEART_SCALE_BASE
	healthbarScaleStart: number = HEART_SCALE_BASE
	healthbarScaleTarget: number = HEART_SCALE_MAX

	// Meta Prog
	private _wallet: Wallet

	get commonCurrency() {
		return this._wallet.commonCurrency
	}

	get rareCurrency() {
		return this._wallet.rareCurrency
	}

	private _ghostwalk: boolean = false
	private _ghostwalkCounter: number = 0 // a little spooky

	set ghostwalk(val: boolean) {
		if (val) {
			this.colliderComponent.isColliderActive = false
			this._ghostwalkCounter++
			this._ghostwalk = val
		} else {
			this._ghostwalkCounter--
			if (this._ghostwalkCounter <= 0) {
				this.colliderComponent.isColliderActive = true
				this._ghostwalk = val
			}
		}
	}

	get ghostwalk() {
		return this._ghostwalk
	}

	noFireWeapons: boolean = false

	godmode: boolean = false
	invulnUntilTime: timeInMilliseconds
	movementLockedUntilTime: timeInSeconds = 0
	aimLockedUntilTime: timeInSeconds = 0
	tempDebugMovementSlowedTime: timeInSeconds = 0

	knockbackVelocity: Vector = new Vector(0, 0)
	knockbackDecelerationRate: number = 10

	autoAimEnabled: boolean = true
	autoShootEnabled: boolean = true
	autoShootToggledOn: boolean = true
	autoAimNearestEnemy: Enemy = null
	aimVector: Vector = new Vector()

	stats: EntityStatList
	secondaryStatList: EntityStatList
	petStatList: EntityStatList

	characterType: CharacterType

	allWeapons: Map<AllWeaponTypes, IStatListOwner> = new Map()

	primaryWeapon: PrimaryWeapon
	secondaryWeapons: AutoFireSecondaryWeapon[] = []
	skillWeapon: SkillWeapon
	passiveSkill: PassiveSkill

	bonusesAcquired: string[] = []
	coneDogThornWeapon: ConeDogThornsWeapon
	coneDogThornBerserkStatBonus: StatBonus

	externalForces: ExternalPhysicsForce[] = []

	get isUsingBow() {
		return this.primaryWeaponType === PrimaryWeaponType.Bow
	}

	get aimDecidesFacing() {
		return this.characterType !== CharacterType.ConeDog
	}

	get scale() {
		return this.modelScale
	}

	nextHealthRegenTime: timeInMilliseconds = 0

	killstreakEnabled: boolean = false
	currentKillstreak: number = 0
	lastKillTime: timeInMilliseconds = 0
	highestKillstreak: number = 0
	killstreakDamageSource: DamageSource

	dpsSamples: Array<[number, number]> = []
	get currentDPS() {
		const damageDealt = this.dpsSamples.reduce((prev, [_, damage]) => {
			return prev + damage
		}, 0)
		return damageDealt / PLAYER_DPS_SAMPLE_DURATION
	}

	position = new Vector(0, 0)
	previousPosition = new Vector(0, 0)
	velocity = new Vector(0, 0)
	movementInput = new Vector()
	nonZeroMovementInput = new Vector()

	reuseKnockbackVector = new Vector()

	aimAngle: radians = 0
	facingDirection: number = -1 // 1 (facing right) or -1 (facing left)

	buffs: Buff[] = []
	attachedBuffEffects: Map<BuffIdentifier, AppliedBuffVisuals> = new Map<BuffIdentifier, AppliedBuffVisuals>()
	footDustPfx: Effect
	// For Quadrupeds
	frontFootDustPfx?: Effect

	aetherMagnetPfx: Effect

	colliderComponent: ColliderComponent
	pickupColliderComponent: ColliderComponent
	pickupColliderRefreshTime: timeInSeconds
	lastPickupRange: number

	// Charge Weapons 
	isCharging: boolean = false
	shooting: boolean = false
	hasMouseUp: boolean = false
	chargePfxName: string = 'charging'
	chargePfx: Effect

	timeSpentNotShooting: number = 0
	isReloading: boolean = false
	reloadStartTime: number
	ammoCount: number
	lastMaxChargeAmmo: number
	maxSecondaryWeapons: number

	isTumbleRolling = false

	currentEnergy: number = 0

	nextProjectileDamageBonus: number = 1
	boomerangAmmoCount: number
	boomerangsOut: boolean = false

	navigationArrow: NavigationArrow

	aetherMagnetPfxConfig: any

	isSpicyPepperDashing: boolean = false
	spectralHoeGfx: SpectralHoeGfx

	iceFieldMovement: boolean = false

	mapConfig: MapConfig

	get IsStaminaActive(): boolean {
		return this.staminaActive
	}
	get isStaminaExhausted(): boolean {
		return this.staminaExhausted
	}
	private currentStamina: number = 100
	private staminaConsumptionRate: number // set per character when plot twist applied
	private staminaActive: boolean = false
	private staminaExhausted: boolean = false
	private exhaustedDebuff: StatBonus
	private intDumpStatBuff: StatBonus

	private clarityAuraReason: ClarityAuraReason = ClarityAuraReason.None
	private clarityAuraFilter: GlowFilter
	private clarityAuraPropCount: number = 0
	private clarityAuraTime: timeInSeconds = 0

	private boundOnInputDown: (e: CustomEvent<InputAction>) => void
	private boundOnInputUp: (e: CustomEvent<InputAction>) => void

	private clampedPosition: boolean = false
	private minXClamp: number
	private maxXClamp: number
	private maxYClamp: number

	private modelScale: number = 1
	
	set x(v) {
		this.previousPosition.x = this.position.x
		this.position.x = v
		this.model.x = v
	}

	get x() {
		return this.position.x
	}

	set y(v) {
		this.previousPosition.y = this.position.y
		this.position.y = v
		this.model.y = v
	}

	get y() {
		return this.position.y
	}

	get zIndex() {
		return this.model.zIndex
	}

	constructor(character: CharacterType, primaryWeaponType: PrimaryWeaponType) {
		this.characterType = character
		this.nid = getNID(this)
		primaryWeaponType = character === CharacterType.ConeDog ? PrimaryWeaponType.None : primaryWeaponType
		this.primaryWeaponType = primaryWeaponType

		this.stats = new EntityStatList(this.resetBaseStatsFn.bind(this), GlobalStatList)
		this.stats.addClamp({
			statType: StatType.cooldownInterval,
			clampMin: 0.25,
		})
		this.secondaryStatList = new EntityStatList(defaultStatAttribute, this.stats)
		this.petStatList = new EntityStatList(defaultStatAttribute, GlobalStatList)

		this.makePlayerModel(character, primaryWeaponType)
		this.makeReloadGraphics()
		this.makeClarityAuraFilter()

		this.model['update'] = (dt) => {
			this.model.zIndex = this.y
			this.riggedModel.update(dt)
		}

		this.setDustEffects('dust')

		this.aetherMagnetPfxConfig = AssetManager.getInstance().getAssetByName('aether-magnet-pfx').data

		this.binaryFlags = new Set()
		// arbitrary cooldown
		// TODO when we add any weapon more interesting than this we'll want to use the cooldown system and add it to the IProjectileShooter interface
		this.currentAttackCooldown = 1.0 / this.stats.getStat('attackRate')
		// this.drawDebugModel()

		this.boundOnInputDown = this.onInputDown.bind(this)
		this.boundOnInputUp = this.onInputUp.bind(this)
		document.addEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.boundOnInputDown)
		document.addEventListener(INPUT_UP_ACTION_EVENT_NAME, this.boundOnInputUp)

		this.stats.resetStats()

		this.level = 1
		this.currentXP = 0
		this.nextLevel = levelToXPThreshold(1)
		this.currentHealth = this.stats.getStat('maxHealth')

		this.createHealthbarContainer()

		this._wallet = {
			commonCurrency: 0,
			rareCurrency: 0
		}

		this.colliderComponent = new ColliderComponent(PLAYER_COLLIDER_CONFIG, this, CollisionLayerBits.Player, this.onCollision.bind(this))
		CollisionSystem.getInstance().addCollidable(this.colliderComponent) //@TODO remove on death ? game is over anyway maybe doesn't matter

		if (debugConfig.disablePickups) {
			PLAYER_PICKUP_COLLIDER_CONFIG[0].radius = 0
		}
		this.pickupColliderComponent = new ColliderComponent(PLAYER_PICKUP_COLLIDER_CONFIG, this, CollisionLayerBits.PlayerPickup, this.onPickupCollision.bind(this), false, true, true)
		CollisionSystem.getInstance().addCollidable(this.pickupColliderComponent)
		this.updateColliderRadius(this.pickupColliderComponent, this.stats.getStat('pickupRange'))

		this.equipPrimaryWeapon(primaryWeaponType)
		UI.getInstance().emitMutation('ui/updatePrimaryWeapon', PrimaryWeaponType[primaryWeaponType])
		UI.getInstance().emitMutation('ui/updateShowGoodBoyStacks', this.characterType === CharacterType.ConeDog)

		this.skillWeapon = makeSkillWeapon(character, this)
		this.passiveSkill = makePassiveSkill(character, this)
		this.passiveSkill.onCreate(this)

		this.allWeapons.set(this.skillWeapon.weaponType, this.skillWeapon)
		this.allWeapons.set(this.primaryWeapon.weaponType, this.primaryWeapon)
		this.allWeapons.set(this.passiveSkill.weaponType, this.passiveSkill)

		UI.getInstance().emitMutation('player/updateSkillIcon', getSkillIconFromCharacterType(this.characterType))

		this.stats.addClamp({ statType: StatType.allDamageMult, clampMin: 0.2, clampMax: Number.MAX_SAFE_INTEGER })
		this.secondaryStatList.addClamp({ statType: StatType.allDamageMult, clampMin: 0.2, clampMax: Number.MAX_SAFE_INTEGER })

		this.setupChargeAmmoTextures()
		this.createChargeAmmoContainer()
		this.updateChargeAmmoIndicators()
		this.navigationArrow = new NavigationArrow()

		this.maxSecondaryWeapons = PLAYER_MAX_SECONDARY_WEAPONS
		if (this.characterType === CharacterType.ConeDog) {
			this.maxSecondaryWeapons++ // should I make a whole skill class that only does this? ...maybe?? idk let me know if you read this in PR,
			// if you still see this comment and you're not doing PR somebody didn't read PR carefully ;p
		}

		this.killstreakDamageSource = {
			weaponType: AllWeaponTypes.DamageOverTime,
			statList: this.stats,
			numEntitiesChained: 0,
			numEntitiesPierced: 0,
			isPlayerOwned: () => true,
			getKnockbackDirection: () => new Vector(0, 0),
			nid: -1,
			entityType: EntityType.Buff,
			timeScale: 1,
			update: (delta, now) => { },
			showImmediateDamageNumber: false
		}

		this.autoAimEnabled = LocalSettings.settings.autoAim
		this.autoShootEnabled = LocalSettings.settings.autoShoot

		UI.getInstance().emitAction('paused/postCurrency', { wallet: this._wallet })
	}

	setMapConfig(mapConfig) {
		this.mapConfig = mapConfig
		this.setVerticalMapClamps()
	}

	setVerticalMapClamps() {
		if (this.mapConfig.generationConfig.type === GenerationType.Vertical) {
			this.minXClamp = 0
			this.maxXClamp = this.mapConfig.generationConfig.width
			this.maxYClamp = WORLD_DATA.maxYInVerticalMap
			this.clampedPosition = true
		}
	}

	resetBaseStatsFn(statList: EntityStatList) {
		defaultStatAttribute(statList)
		statList._actualStatValues.movementSpeed = CHARACTER_STATS[this.characterType][StatType.movementSpeed]
		statList._actualStatValues.maxHealth = 10
		statList._actualStatValues.allDamageMult = 1.0
		statList._actualStatValues.initialImpactDamageMult = 1.0
		statList._actualStatValues.baseDamage = 5
		statList._actualStatValues.attackRate = 1.0
		statList._actualStatValues.pickupRange = debugConfig.disablePickups ? 0 : 200
		statList._actualStatValues.projectileChainCount = 0
	}

	applyVisualBuff(buffId: BuffIdentifier, stacks?: number) {
		handleBuffChange(this, buffId, false)
	}
	removeVisualBuff(buffId: BuffIdentifier, stacks?: number) {
		handleBuffChange(this, buffId, true)
	}

	setMovementLockTime(time: timeInMilliseconds) {
		this.movementLockedUntilTime = Math.max(this.movementLockedUntilTime, time + InGameTime.highResolutionTimestamp())
	}

	isMovementLocked() {
		return this.movementLockedUntilTime > InGameTime.highResolutionTimestamp()
	}

	setAimLockTime(time: timeInMilliseconds) {
		this.aimLockedUntilTime = Math.max(this.aimLockedUntilTime, time + InGameTime.highResolutionTimestamp())
	}

	update(delta: timeInSeconds, nowInGame: timeInMilliseconds): void {
		if (!this.isDead()) {
			const targetVelocity = new Vector(0, 0)

			if (process.env.IS_ELECTRON && ClientPlayerInput.controllerActive) {
				targetVelocity.x = ClientPlayerInput.controllerMoveVector.x
				targetVelocity.y = ClientPlayerInput.controllerMoveVector.y
			} else {
				if (ClientPlayerInput.currentFrameInput.get(InputAction.MOVE_RIGHT)) {
					targetVelocity.x += 1
				}
				if (ClientPlayerInput.currentFrameInput.get(InputAction.MOVE_LEFT)) {
					targetVelocity.x -= 1
				}
				if (ClientPlayerInput.currentFrameInput.get(InputAction.MOVE_UP)) {
					targetVelocity.y -= 1
				}
				if (ClientPlayerInput.currentFrameInput.get(InputAction.MOVE_DOWN)) {
					targetVelocity.y += 1
				}
			}

			targetVelocity.normalize()
			this.movementInput.copy(targetVelocity)

			if (this.movementInput.x !== 0 || this.movementInput.y !== 0) {
				this.nonZeroMovementInput.copy(this.movementInput)
			}

			if (this.binaryFlags.has('speed-drafting')) {
				this.setSpeedDraftingMultiplier(delta)
			}

			if (this.level < PLOT_TWIST_HEAD_START_LEVEL_CAP && this.binaryFlags.has('head-start') && InGameTime.timeSinceLastCountdownTick >= PLOT_TWIST_HEAD_START_LEVEL_BOOST_START_TIME){
				this.applyHeadStart()
			}

			let speed = this.stats.getStat('movementSpeed') * GAMEPLAY_SPEED_MULTIPLIER

			// IDK if I like doing it this way.
			if (this.mapConfig.mapType === MapOption.Tundra) {
				if (targetVelocity.y < 0) {
					speed *= TUNDRA_UPHILL_MULTIPLIER
				} else if (targetVelocity.y > 0) {
					speed *= TUNDRA_DOWNHILL_MULTIPLIER
				}
			}

			if(this.binaryFlags.has('berserker')){
				if(this.currentHealth <= HEALTH_PER_HEART*2){
					Buff.apply(BuffIdentifier.Berserker, this, this)
				} else if(this.currentHealth > HEALTH_PER_HEART*2){
					Buff.remove(this, BuffIdentifier.Berserker, this)
				}
			}


			let max
			switch (this.getChargeAmmoMode()) {
				case ChargeAmmoMode.Charge:
					max = this.primaryWeapon.statList.getStat(StatType.maxCharge)
					break
				case ChargeAmmoMode.Ammo:
					max = this.primaryWeapon.statList.getStat(StatType.maxAmmo)
					break
				case ChargeAmmoMode.None:
					max = undefined
					break
			}
			if (max !== this.lastMaxChargeAmmo) {
				this.lastMaxChargeAmmo = max
				this.deleteChargeAmmoContainer()
				this.createChargeAmmoContainer()
			}

			const maxHealth = this.stats.getStat(StatType.maxHealth)
			if (maxHealth !== this.lastMaxHealth) {
				this.lastMaxHealth = maxHealth
				this.deleteHealthbarContainer()
				this.createHealthbarContainer()
			}

			if (this.currentHealth <= HEALTH_PER_HEART*2) { // 2 hearts
				this.healthbarPulseAcc += delta
				this.updateHealthbarScale()
			}

			if (this.shouldBeSlowedFromShooting()) {
				// multiply by walkSpeedScalar for the first 100% movement speed, then the bonusScalar for the remainder
				const bonusScalar = 0.45
				const walkSpeedScalar = this.primaryWeapon.statList.getStat(StatType.walkSpeedScalar)
				speed = (speed - PLAYER_DEFAULT_MOVEMENTSPEED) * bonusScalar + PLAYER_DEFAULT_MOVEMENTSPEED * walkSpeedScalar
				if (this.binaryFlags.has('gatling-gun')) {
					speed *= 0.60
				}
			} else {
				if (this.binaryFlags.has('gatling-gun')) {
					const state = this.binaryFlagState["gatling-gun"]
					if (state.shotCount) {
						state.notFiringAcc += delta
						// if the player isn't firing, rapidly lose "stacks" and slow the rate-of-fire
						if (state.notFiringAcc >= 0.5) {
							state.shotCount = Math.max(0, state.shotCount - 2)
							// console.log(`shots -2 = ${state.shotCount}`)
							if (state.shotCount === 0) {
								// console.log('all stacks gone! :(')
								state.notFiringAcc = 0
							}
						}
					}
				}
			}

			if (this.iceFieldMovement) {
				this.onIceFieldMovement(targetVelocity, speed, delta)
			} else {
				this.velocity.x = targetVelocity.x * speed
				this.velocity.y = targetVelocity.y * speed
			}

			// console.log(`${this.x},${this.y}`, delta)
			if (this.movementLockedUntilTime > InGameTime.highResolutionTimestamp()) {
				this.velocity.x = 0
				this.velocity.y = 0
			}

			this.updateStamina(delta)

			if (this.pickupColliderRefreshTime > 1.0) {
				this.pickupColliderRefreshTime -= 1.0
				const pickupRange = this.stats.getStat('pickupRange')
				if (pickupRange !== this.lastPickupRange) {
					this.lastPickupRange = pickupRange
					this.updateColliderRadius(this.pickupColliderComponent, pickupRange)
				}
			}

			if (this.binaryFlags.has('constant-movement-yoink')) {
				const state = this.binaryFlagState["constant-movement-yoink"]
				if (this.velocity.len2() || this.knockbackVelocity.len2()) {
					state.timeSpentMoving += delta
					state.timeSafeguard = 0.5
					if (state.timeSpentMoving >= 10) {
						state.timeSpentMoving = 0
						const duration = CONSTANT_MOVEMENT_YOINK_DURATION

						this.setAetherMagnetPfx()
						this.updateColliderRadius(this.pickupColliderComponent, this.stats.getStat('pickupRange') * 3)
						callbacks_addCallback(this, () => {
							this.removeAetherMagnetPfx(duration)
							this.updateColliderRadius(this.pickupColliderComponent, this.stats.getStat('pickupRange'))
						}, duration)
					}
				} else if (state.timeSafeguard > 0) {
					state.timeSafeguard -= delta
				} else {
					state.timeSpentMoving = 0
				}
			}

			this.x += this.velocity.x * delta + this.knockbackVelocity.x * delta
			this.y += this.velocity.y * delta + this.knockbackVelocity.y * delta

			if (this.clampedPosition) {
				this.x = Math.clamp(this.x, this.minXClamp, this.maxXClamp)
				this.y = Math.min(this.y, this.maxYClamp)
			}

			// console.log(`${this.x},${this.y}`, this.velocity, this.knockbackVelocity)

			this.knockbackVelocity.x = Math.lerp(this.knockbackVelocity.x, 0, Math.clamp(this.knockbackDecelerationRate * delta, 0, 1))
			this.knockbackVelocity.y = Math.lerp(this.knockbackVelocity.y, 0, Math.clamp(this.knockbackDecelerationRate * delta, 0, 1))
			if (this.knockbackVelocity.len2() < 0.1) {
				this.knockbackVelocity.x = 0
				this.knockbackVelocity.y = 0
			}

			updatePhysics(this, this, delta, true)

			if (this.aimLockedUntilTime < InGameTime.highResolutionTimestamp()) {
				
				if (this.autoAimEnabled) {
					// get closest enemy
					let nearestEnemy: Enemy = null
					let closestDist = Number.MAX_SAFE_INTEGER
					for (let i=0; i < GameState.enemyList.length; ++i) {
						const enemy = GameState.enemyList[i] as Enemy
						if (!enemy.isDead() && enemy.distanceToPlayer2 < closestDist) {
							nearestEnemy = enemy
							closestDist = enemy.distanceToPlayer2
						}
					}

					if (nearestEnemy) {
						const xOff = nearestEnemy.x - this.position.x
						const yOff = nearestEnemy.y - this.position.y
						this.aimVector.x = xOff
						this.aimVector.y = yOff						
					}

					this.autoAimNearestEnemy = nearestEnemy
				} else {
					// Controller mode:
					// this is a vector (-1, -1) to (1, 1) of where the mouse is on the screen
					// It makes more sense to think of it as a joystick
					// const aimVector = ClientPlayerInput.aimVector

					// Mouse mode:
					// We need to calculate our angle based on where the mouse is on the screen,
					// and where the player is in the world
					
					if (ClientPlayerInput.controllerActive) {
						this.aimVector = ClientPlayerInput.controllerAimVector
					} else {
						this.aimVector.x = ClientPlayerInput.worldMouseX - this.x
						this.aimVector.y = ClientPlayerInput.worldMouseY - this.y + JANK_AIM_Y_OFFSET * this.modelScale
					}
				}
				

				this.aimVector.normalize()

				if (debugConfig.benchmarkMode) {
					this.aimAngle += delta * 4
				} else {
					this.aimAngle = angleInRadsFromVector(this.aimVector)
				}
			}

			if (this.velocity.x === 0 && this.velocity.y === 0) {
				playAnimation(this.riggedModel, AnimationTrack.IDLE)
				this.footDustPfx.enabled = false
				if (this.frontFootDustPfx) {
					this.frontFootDustPfx.enabled = false
				}
			} else {
				let track: AnimationTrack
				if (this.isUsingBow && this.isCharging) {
					track = AnimationTrack.MOVEMENT_BOW_DRAWN
				} else {
					track = AnimationTrack.MOVEMENT
				}
				const entry = playAnimation(this.riggedModel, track)
				entry.trackEnd = entry.trackTime + 0.15

				if (!this.aimDecidesFacing && this.velocity.x !== 0) {
					const facing = -Math.sign(this.velocity.x)
					this.riggedModel.scale.x = facing
					this.facingDirection = -facing
				}
				this.footDustPfx.enabled = true
				if (this.frontFootDustPfx) {
					this.frontFootDustPfx.enabled = true
				}
			}

			if (this.aimDecidesFacing) {
				this.facingDirection = getFacingDirection(this.aimAngle, this.facingDirection, this.isUsingBow ? 0 : PLAYER_OVER_AIM_DEGREES)
				updateAimFacing(this.riggedModel, this.facingDirection, false)
			}

			updateAimRotation(this.rotationBone, -this.aimAngle, this.facingDirection)

			const wasShooting = this.shooting
			if (this.autoShootEnabled && this.autoShootToggledOn) {
				if (this.primaryWeapon.resourceType === ResourceType.CHARGE_UP_SHOT) {
					const maxEnergy = this.primaryWeapon.statList.getStat(StatType.maxCharge)
					this.shooting = this.currentEnergy < maxEnergy
				} else {
					this.shooting = !this.noFireWeapons
				}
			} else {
				this.shooting = (debugConfig.benchmarkMode || ClientPlayerInput.currentFrameInput.get(InputAction.SHOOT)) && !this.noFireWeapons
			}
			this.hasMouseUp = wasShooting && !this.shooting

			if (!this.shooting) {
				this.timeSpentNotShooting += delta

				if (this.timeSpentNotShooting >= NO_SHOOT_RELOAD_START_TIME) {
					this.weaponReload()
				}
			} else {
				this.timeSpentNotShooting = 0

				if (this.isReloading) {
					// we're trying to shoot and reload, stop if we have ammo to shoot
					if (this.ammoCount > 0) {
						this.stopReload()
					}
				}
			}

			if (!this.isReloading) {
				this.updateEnergy(delta)
				this.weaponCharging()
				this.updateChargePfxLocation()
				this.updateChargePfxScale()
			} else {
				this.weaponReload()
			}

			for (let i = 0; i < this.secondaryWeapons.length; ++i) {
				this.secondaryWeapons[i].update(delta)
			}

			if (this.binaryFlags.has('yoink-fast-ghosted')) {
				const state = this.binaryFlagState["yoink-fast-ghosted"]
				if (state.cooldown > 0) {
					state.cooldown -= delta
				}
				const oldlen = state.xpCollectedRecently.length
				state.xpCollectedRecently = state.xpCollectedRecently.filter((time) => {
					return time >= nowInGame - state.duration
				})
			}

			if (this.lastKillTime < nowInGame - getAdjustedKillstreakDuration(this.currentKillstreak)) {
				this.setKillstreak(0)
			}

			if (this.navigationArrow.isShowing) {
				this.navigationArrow.setPosition(this.x, this.y)
			}

			if (this.nextHealthRegenTime > 0) {
				if (nowInGame >= this.nextHealthRegenTime) {
					const maxHealth = this.stats.getStat(StatType.maxHealth)
					if (this.currentHealth < maxHealth) {
						this.heal(1)
						Audio.getInstance().playSfx('SFX_Player_Fully_Healed', { volume: 0.5 })
						if (this.currentHealth < maxHealth) {
							this.nextHealthRegenTime = nowInGame + this.stats.getStat(StatType.healthRegenInterval)
						}
					} else {
						this.nextHealthRegenTime = 0
					}
				}
			}

			if (this.binaryFlags.has('demigod-of-thunder')) {
				const state = this.binaryFlagState['demigod-of-thunder']
				state.acc -= delta
				if (state.acc <= 0) {
					state.acc += state.cooldown
					const numLightningStrikes = state.numLightningStrikes + chanceToLoops(this.stats.getStat('projectileCount')) + chanceToLoops(this.stats.getStat('projectileChainCount')) + chanceToLoops(this.stats.getStat('projectileSplitCount'))
					const radius = DEMIGOD_OF_THUNDER_RADIUS * this.stats.getStat('attackSize')
					LightningStrike.strikeEnemiesOnScreen(numLightningStrikes, null, radius, 0, getDamageByPlayerLevel(this.level) * DEMIGOD_OF_THUNDER_DAMAGE_MULTIPLIER)
				}
			}

			this.updateClarityAura(delta)
		}

		// console.log(velocity, delta)
		this.primaryWeapon.update(delta)
		this.passiveSkill.update(delta)
		this.skillWeapon.update(delta)

		//TODO: slow this down, can happen every 100ms or so
		this.dpsSamples = this.dpsSamples.filter(([time, damageDealt]) => {
			return time >= nowInGame - PLAYER_DPS_SAMPLE_DURATION * 1000
		})
		UI.getInstance().emitMutation('debug/updatePlayerDPS', this.currentDPS)

		if (this.binaryFlags.has('xp-awarded-every-60-seconds')) {
			const state = this.binaryFlagState['xp-awarded-every-60-seconds']
			const currentMinute = Math.floor(nowInGame / 60 / 1000)
			if (state.lastMinute !== currentMinute) {
				this.addXP(state.xpBanked, true)
				Audio.getInstance().playSfx('SFX_Item_Drop_Epic')
				state.lastMinute = currentMinute
				state.xpBanked = 0
			}
		}

		if (this.currentXP >= this.nextLevel) {
			this.levelup()
		}
	}

	onIceFieldMovement(targetVelocity: Vector, speed: number, delta: timeInSeconds) {
		this.velocity.x = Math.lerp(this.velocity.x, targetVelocity.x * speed, Math.clamp(PLAYER_FRICTION * delta, 0, 1))
		this.velocity.y = Math.lerp(this.velocity.y, targetVelocity.y * speed, Math.clamp(PLAYER_FRICTION * delta, 0, 1))

		const minVelocity = 100

		if (Math.abs(this.velocity.x) < minVelocity && targetVelocity.x === 0) {
			this.velocity.x = 0
		}

		if (Math.abs(this.velocity.y) < minVelocity && targetVelocity.y === 0) {
			this.velocity.y = 0
		}
	}

	onInputDown(e: CustomEvent<InputAction>) {
		const inputAction = e.detail

		if (this.isDead()) {
			return
		}

		if (PauseManager.isPaused()) {
			if (inputAction !== InputAction.PAUSE) {
				return
			}

			const isRebinding = UI.getInstance().store.getters['settings/getIsRebindingKey']
			if (isRebinding) {
				return
			}
		}

		switch (inputAction) {
			case InputAction.PAUSE: {
				if (PauseManager.isPaused()) {
					UI.getInstance().emitAction('settings/saveSettings')
					UI.getInstance().emitAction('genericTwoButtonPrompt/closeActiveYesNoPanel')
				}
	
				UI.getInstance().emitAction('ui/togglePauseByUser')
				break
			}
			case InputAction.SHOOT:
				if (this.noFireWeapons) {
					return
				}

				if (this.autoShootEnabled) {
					this.autoShootToggledOn = !this.autoShootToggledOn
				}

				if (this.characterType !== CharacterType.ConeDog) {
					if (this.boomerangsOut && this.currentAttackCooldown <= 0 && this.binaryFlags.has('boomerang-recall-with-click')) {
						this.boomerangRecall()
					}
					break
				} // cone dog, shoot button = use skill as well
			// eslint-disable-next-line no-fallthrough
			case InputAction.SKILL: {
				if (this.noFireWeapons) {
					return
				}

				const used = this.skillWeapon.tryUseSkill()
				if (used) {
					this.onUsedSkill()
				}
				break
			}
		}
	}

	onUsedSkill() {
		const cooldown = this.skillWeapon.cooldown.getReloadInterval()
		const ammo = this.skillWeapon.cooldown.getMaxAmmo()
		if (this.binaryFlags.has('big-pickup-on-skill-use')) {
			const pickupRange = 1 + 2 * getEffectMultiplierBasedOnSkill(cooldown, ammo)
			this.updateColliderRadius(this.pickupColliderComponent, this.stats.getStat('pickupRange') * pickupRange)
			callbacks_addCallback(this, () => {
				this.updateColliderRadius(this.pickupColliderComponent, this.stats.getStat('pickupRange'))
			}, 1)
		}
		if (this.binaryFlags.has('lightning-strikes-on-skill-use')) {
			const weapon: DarkStormyNightWeapon = this.secondaryWeapons.find((weapon) => {
				return weapon.weaponType === AllWeaponTypes.DarkStormyNight
			}) as DarkStormyNightWeapon
			weapon.lightningStrikesOnSkillUse(cooldown, ammo)
		}
		PlayerMetricsSystem.getInstance().trackMetric('SKILL_USED')
	}

	onInputUp(e: CustomEvent<InputAction>) {
		const inputAction = e.detail
	}

	teleport(x: number, y: number) {
		this.position.x = x
		this.position.y = y

		CollisionSystem.getInstance().reinsertEntity(this.colliderComponent)
		CollisionSystem.getInstance().reinsertEntity(this.pickupColliderComponent)
	}

	getStat(statName: StatName) {
		return this.stats.getStat(statName)
	}

	getWeapon(weaponType: AllWeaponTypes) {
		return this.allWeapons.get(weaponType)
	}

	reduceEnergy(amount: number): void {
		// TODO reduce energy
	}

	isDead(): boolean {
		if (this.godmode) {
			return false
		}
		return this.currentHealth <= 0
	}

	madeProjectile(proj: PlayerProjectile): void {

	}

	ownedProjectileDied(proj: PlayerProjectile): void {

	}

	canShoot(): boolean {
		if (this.isDead()) {
			return false
		}

		if (this.noFireWeapons) {
			return false
		}

		const resourceType = this.primaryWeapon.resourceType
		if (this.binaryFlags.has('boomerang-recall-with-click') && this.boomerangsOut) {
			return false
		}

		if (debugConfig.benchmarkMode) {
			return this.currentAttackCooldown <= 0 && this.ammoCount > 0
		}

		if (resourceType === ResourceType.NONE) {
			return this.ammoCount > 0 && this.currentAttackCooldown <= 0 && !this.isTumbleRolling && (ClientPlayerInput.currentFrameInput.get('shoot') || (this.autoShootEnabled && this.autoShootToggledOn))

		} else if (resourceType === ResourceType.ENERGY_COST_SHOT) {
			return this.ammoCount > 0 && this.currentAttackCooldown <= 0 && !this.isTumbleRolling && (ClientPlayerInput.currentFrameInput.get('shoot') || (this.autoShootEnabled && this.autoShootToggledOn)) //TODO: and energy costs

		} else if (resourceType === ResourceType.CHARGE_UP_SHOT) {
			if (this.hasMouseUp) {
				return this.currentEnergy > PLAYER_CHARGE_MISFIRE_PERCENT
			}

			return false
		} else if (resourceType === ResourceType.FREE) {
			return this.currentAttackCooldown <= 0 && !this.isTumbleRolling && (ClientPlayerInput.currentFrameInput.get('shoot') || (this.autoShootEnabled && this.autoShootToggledOn))
		}
	}

	setSlowMovementTime(time: timeInMilliseconds) {
		this.tempDebugMovementSlowedTime = Math.max(this.tempDebugMovementSlowedTime, time + InGameTime.highResolutionTimestamp())
	}

	shouldBeSlowedFromShooting(): boolean {
		if (this.primaryWeapon.resourceType === ResourceType.NONE) {
			return this.currentAttackCooldown >= 0.018
		} else if (this.primaryWeapon.resourceType === ResourceType.ENERGY_COST_SHOT) {
			return this.currentAttackCooldown >= 0.018
		} else if (this.primaryWeapon.resourceType === ResourceType.CHARGE_UP_SHOT) {
			return this.currentEnergy && !this.hasMouseUp
		} else {
			return this.tempDebugMovementSlowedTime > InGameTime.highResolutionTimestamp()
		}
	}

	onShot(shotSuccess: boolean, numProjectilesFired: number = 1) {
		const weapon = this.primaryWeapon
		if (weapon instanceof Boomerang) {
			weapon.trajectoryParity = !weapon.trajectoryParity
			this.boomerangsOut = true
		}

		if (this.primaryWeapon.resourceType === ResourceType.NONE) {
			if (this.binaryFlags.has('gatling-gun')) {
				const state = this.binaryFlagState["gatling-gun"]
				state.shotCount++
				state.notFiringAcc = 0
				state.attackRateMulti = Math.clamp(0.18 + 0.14 * (state.shotCount - 1), 0.18, 2.5)
				// console.log(`shots +1 = ${state.shotCount}, bonus: ${state.attackRateMulti*100}%`)

				this.currentAttackCooldown = 1.0 / this.primaryWeapon.statList.getStat('attackRate') / state.attackRateMulti

			} else {
				this.currentAttackCooldown = 1.0 / this.primaryWeapon.statList.getStat('attackRate')
			}

		} else if (this.primaryWeapon.resourceType === ResourceType.ENERGY_COST_SHOT) {
			this.currentAttackCooldown = 1.0 / this.primaryWeapon.statList.getStat('attackRate')

		} else if (this.primaryWeapon.resourceType === ResourceType.CHARGE_UP_SHOT) {
			this.hasMouseUp = false
			this.isCharging = false
			this.shooting = false
			this.currentEnergy = 0
			this.updateChargeAmmoIndicators()
			this.updateChargePfxColor()
		}

		if (shotSuccess) {
			this.ammoCount = Math.max(0, this.ammoCount - numProjectilesFired)

			this.updateChargeAmmoIndicators()
			this.updateChargePfxColor()
			if (this.ammoCount <= 0) {
				this.weaponReload()
				if (this.primaryWeaponType === PrimaryWeaponType.Boomerang) {
					this.weapon.visible = false
				}
			}

			if (this.primaryWeaponType === PrimaryWeaponType.Boomerang) {
				Audio.getInstance().playSfx("SFX_Boomerang")
			}

			this.passiveSkill.onShot()
			if (this.primaryWeapon.weaponType === AllWeaponTypes.Wand) {
				Audio.getInstance().playSfx('Projectile_Zap')
			} else if (this.primaryWeapon.weaponType === AllWeaponTypes.Bow) {
				Audio.getInstance().playSfx('SFX_Bow')
			} else {
				this.checkBoomerangAmmoCount()
			}

			this.updateAmmoCount()

			Camera.getInstance().triggerShootShake(PLAYER_SHOOT_CAM_SHAKE)
		}
	}

	checkBoomerangAmmoCount() {
		if (this.primaryWeaponType === PrimaryWeaponType.Boomerang) {
			const maxAmmo = this.primaryWeapon.statList.getStat(StatType.maxAmmo)
			const ammoDiff = maxAmmo - this.boomerangAmmoCount
			if (ammoDiff > 0) {
				this.ammoCount += ammoDiff
			}

			this.updateChargeAmmoIndicators()
			this.updateAmmoCount()
			this.boomerangAmmoCount = maxAmmo
		}
	}

	loseCharge() {
		if (this.isCharging) {
			this.currentEnergy = 0
			this.updateChargeAmmoIndicators()
		}
	}

	setAttackCooldown() {
		this.currentAttackCooldown = 1.0 / this.primaryWeapon.statList.getStat('attackRate')
	}

	getSkillShotPlace(maxDistance: number, maxDistanceSquared: number): Vector {
		const playerMousePos = new Vector(ClientPlayerInput.worldMouseX, ClientPlayerInput.worldMouseY)
		const distance = new Vector(ClientPlayerInput.worldMouseX - this.position.x, ClientPlayerInput.worldMouseY - this.position.y)

		const distOverMaxRange = maxDistanceSquared / distance.len2()
		if (distOverMaxRange >= 1) {
			return playerMousePos
		} else {
			const direction = distance.clone().normalize()
			const maxDirection = direction.scale(maxDistance, maxDistance)

			return maxDirection.add(this.position)
		}
	}

	onCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
		if (otherEntity.layer & CollisionLayerBits.Enemy || otherEntity.layer & CollisionLayerBits.FlyingEnemy) {
			return this.onEnemyCollision(otherEntity, collisionVX, collisionVY)
		} else if (otherEntity.layer & CollisionLayerBits.EnemyProjectile) {
			return this.onProjectileCollision(otherEntity, collisionVX, collisionVY)
		}
	}

	onEnemyCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
		if (this.isDead() || this.godmode || InGameTime.highResolutionTimestamp() < this.invulnUntilTime) {
			if (this.isSpicyPepperDashing) {
				const enemy = otherEntity.owner as Enemy
				this.reuseKnockbackVector.copy(enemy.directionToPlayer) // reverse?
				this.reuseKnockbackVector.normalize()
				this.reuseKnockbackVector.scale(SPICY_PEPPER_KNOCKBACK_STRENGTH, SPICY_PEPPER_KNOCKBACK_STRENGTH)
				enemy.addKnockBack(this.reuseKnockbackVector)

				spicyPepperApplyIgnite(enemy)
			}
			return
		}
		//console.log(`damaging collision with enemy, us ${this.colliderComponent.layer} (#${this.colliderComponent.id}) vs ${otherEntity.layer} (#${otherEntity.id})  ${collisionVX},${collisionVY}`)
		const enemy = otherEntity.owner as Enemy
		if (enemy.fightingAttackType === AttackTypes.EXPLODE_ON_CONTACT) {
			enemy.transitionToDead(false)
		}
		let damage = 1
		if (enemy.isInRage()) {
			damage = enemy.currentRage.damageBonus
		}
		if (SNOWBALL_ENEMY_NAMES.includes(enemy.name)) {
			// bigger snowballs deal more damage
			if (enemy.scale > 0.6) {
				damage++
			}
		}
		if (!enemy.ghosted) {
			if (!this.isDead() && this.binaryFlags.has('player-takes-knockback')) {
				this.takeKnockback(this.x - otherEntity.position.x, this.y - otherEntity.position.y, 2000, true)
			}
			this.takeDamage(damage, enemy)
		}
	}

	onProjectileCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
		if (this.isDead() || this.godmode || InGameTime.highResolutionTimestamp() < this.invulnUntilTime) {
			return
		}
		const shield = Buff.getBuff(this, BuffIdentifier.ProjectileShield)
		if (shield) {
			Audio.getInstance().playSfx('SFX_Player_Shield_Block')
			shield.wearOffStacks(1)
			return
		}
		const projectile = otherEntity.owner as EnemyProjectile
		const damage = projectile.baseDamage
		this.takeDamage(damage, projectile)
	}

	onPickupCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
		const pickup = otherEntity.owner as GroundPickup

		if (pickup.pickupType === GroundPickupType.Healing) {
			if (this.currentHealth === this.lastMaxHealth) {
				if (this.staminaActive && !this.isStaminaExhausted) {
					const missingStamina = PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA - this.currentStamina
					if (pickup.pickupConfigType === GroundPickupConfigType.HealingLarge) {
						if (missingStamina < PLOT_TWIST_HARDCORE_SURVIVAL_LARGE_HEART_STAMINA_RECOVERY) {
							return
						}
					} else {
						// small healing
						if (missingStamina < PLOT_TWIST_HARDCORE_SURVIVAL_SMALL_HEART_STAMINA_RECOVERY) {
							return
						}
					}
				} else {
					return
				}
			}
		}

		if(pickup.pickupType === GroundPickupType.RottenHeart) {
			if( withinDistanceVV(this.position, pickup.position, (pickup.pickupConfig as PickupRangeConfig).range)){
				pickup.onPickedUp(this)
			}
		} else {
			pickup.onPickedUp(this)
		}

		if (pickup.pickupType === GroundPickupType.Experience) {
			if (this.binaryFlags.has('yoink-fast-ghosted')) {
				const state = this.binaryFlagState["yoink-fast-ghosted"]
				state.xpCollectedRecently.push(InGameTime.highResolutionTimestamp())

				if (state.xpCollectedRecently.length >= YOINK_FAST_GHOSTFORM_XP_NEEDED && state.cooldown <= 0) {
					Buff.apply(BuffIdentifier.Phase, this, this)
					state.cooldown = YOINK_FAST_GHOSTFORM_COOLDOWN
					state.xpCollectedRecently.length = 0
				}
			}
		}
	}

	onTargetDamaged(damagedEntity, damageSource: DamageSource, damageDealt: number) {
		//TODO: make this apply only to non-lethal hits; verify with Liam
		if (this.binaryFlags.has("attack-rate-stacking-attack-rate") && damagedEntity?.currentHealth) {
			Buff.apply(BuffIdentifier.DeathTreadmill, this, this, 1)
		}

		this.dpsSamples.push([InGameTime.highResolutionTimestamp(), damageDealt])

		this.passiveSkill.onEnemyHit(damagedEntity, damageDealt)

		PlayerMetricsSystem.getInstance().trackMetric('WEAPON_DAMAGE', damageSource, damageDealt, damagedEntity)
	}

	onTargetKilled(killedEntity, killingEntity: DamageSource) {
		if (this.binaryFlags.has("damage-bottom-upgrade")) {
			const buff = Buff.apply(BuffIdentifier.RapidKiller, this, this, 1)
			console.log(`RapidKiller stacks: ${buff.stacks}`)
		}

		this.setKillstreak(this.currentKillstreak + 1)

		const enemy = killedEntity as Enemy
		if (enemy.isEnemy) {
			if (killingEntity.entityType === EntityType.Projectile) {
				const proj = killingEntity as PlayerProjectile

				if (proj.weaponType === AllWeaponTypes.Bow && this.binaryFlags.has('longbow-taking-names')) {
					const buff = Buff.apply(BuffIdentifier.LongbowTakingNames, this, this)
				} else if (proj.weaponType === AllWeaponTypes.Wand) {
					if (this.binaryFlags.has("careful-shooter") && this.binaryFlags.has("blood-soaked-rounds")) {
						let chance = 0
						const carefulShooterBuff = Buff.getBuff(this, BuffIdentifier.CarefulShooter, this)
						if (carefulShooterBuff && carefulShooterBuff.stacks >= CAREFUL_SHOOTER_STACKS_REQUIRED_FOR_BLOOD_SOAKED_ROUNDS_BONUS) {
							chance = BLOOD_SOAKED_ROUNDS_AMMO_CHANCE_MAX
						} else {
							chance = BLOOD_SOAKED_ROUNDS_AMMO_CHANCE
						}
						if (Math.random() <= chance && this.ammoCount < this.primaryWeapon.cooldown.getMaxAmmo()) {
							this.ammoCount++
							this.updateChargeAmmoIndicators()
						}
					}
				}
			}
		}
	}

	rollBossReward() {
		const upgradeOptions = 3
		const upgrades = UpgradeManager.rollUpgrades(upgradeOptions, "boss")
		UI.getInstance().emitAction('levelUp/postUpgrades', upgrades)
		this.knockbackSurroundingEnemies(PLAYER_LEVEL_UP_KNOCKBACK_SEARCH_DISTANCE, PLAYER_LEVEL_UP_KNOCKBACK_STRENGTH)
	}

	enableKillstreaks() {
		this.killstreakEnabled = true
		UI.getInstance().emitMutation('player/toggleKillstreak', true)
	}

	setKillstreak(value: number) {
		if (!this.killstreakEnabled) {
			return
		}
		if (value > this.currentKillstreak) {
			this.lastKillTime = InGameTime.highResolutionTimestamp()
			PlayerMetricsSystem.getInstance().trackMetric('LONGEST_KILLSTREAK', value)
		} else if (value === 0 && this.currentKillstreak > 0 && this.binaryFlags.has('twist-killstreak-better-go-fast')) {
			// dropped, take damage
			this.takeDamage(2, this)
		}
		const old = this.currentKillstreak
		this.currentKillstreak = value
		this.highestKillstreak = Math.max(this.currentKillstreak, this.highestKillstreak)

		// only trigger this logic when we increase streak by intervals of PLAYER_KILLSTREAK_MINOR_INTERVAL~
		if (value == 0 || ~~(value / PLAYER_KILLSTREAK_MINOR_INTERVAL) > ~~(old / PLAYER_KILLSTREAK_MINOR_INTERVAL)) {

			if (this.binaryFlags.has('killstreak-damage')) {
				const state = this.binaryFlagState['killstreak-damage']
				const damage = killstreakMapRange(this.currentKillstreak, KILLSTREAK_DAMAGE_MULTI)
				if (!state.bonus) {
					state.bonus = this.stats.addStatBonus('allDamageMult', StatOperator.SUM_THEN_MULTIPLY, damage)
				}
				state.bonus.update(damage)
			}

			if (this.binaryFlags.has('killstreak-pickup-movement')) {
				const state = this.binaryFlagState['killstreak-pickup-movement']
				const pickup = killstreakMapRange(this.currentKillstreak, KILLSTREAK_PICKUP_RANGE_MULTI)
				const movement = killstreakMapRange(this.currentKillstreak, KILLSTREAK_MOVESPEED_MULTI)
				if (!state.bonus1) {
					state.bonus1 = this.stats.addStatBonus('pickupRange', StatOperator.SUM_THEN_MULTIPLY, pickup)
					state.bonus2 = this.stats.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, movement)
				}
				state.bonus1.update(pickup)
				state.bonus2.update(movement)
			}

			//unused
			if (this.binaryFlags.has('killstreak-xp')) {
				const state = this.binaryFlagState['killstreak-xp']
				const xp = killstreakMapRange(this.currentKillstreak, KILLSTREAK_XP_DROP_MULTI)
				if (!state.bonus) {
					state.bonus = this.stats.addStatBonus('xpReDropChance', StatOperator.SUM_THEN_MULTIPLY, xp)
				}
				state.bonus.update(xp)
			}

			if (this.binaryFlags.has('killstreak-fast-attacks')) {
				const state = this.binaryFlagState['killstreak-fast-attacks']
				const attackRate = killstreakMapRange(this.currentKillstreak, KILLSTREAK_ATTACK_RATE_MULTI)
				const cooldownRate = killstreakMapRange(this.currentKillstreak, KILLSTREAK_COOLDOWN_RATE_MULTI)
				const reloadRate = killstreakMapRange(this.currentKillstreak, KILLSTREAK_RELOAD_RATE_MULTI)
				if (!state.bonus1) {
					state.bonus1 = this.stats.addStatBonus('attackRate', StatOperator.SUM_THEN_MULTIPLY, attackRate)
					state.bonus2 = this.stats.addStatBonus('chargeRate', StatOperator.SUM_THEN_MULTIPLY, attackRate)
					state.bonus3 = this.stats.addStatBonus('cooldownInterval', StatOperator.SUM_THEN_MULTIPLY, -cooldownRate)
					state.bonus4 = this.stats.addStatBonus('reloadInterval', StatOperator.SUM_THEN_MULTIPLY, -reloadRate)
				}
				state.bonus1.update(attackRate)
				state.bonus2.update(attackRate)
				state.bonus3.update(cooldownRate)
				state.bonus4.update(reloadRate)
			}

			if (value > 0 && value % PLAYER_KILLSTREAK_MAJOR_INTERVAL === 0) {
				if (this.binaryFlags.has('killstreak-fire-random-weapon')) {
					const weapon = this.secondaryWeapons.pickRandom()
					if (weapon) {
						weapon.fire()
					}
				}
				if (this.binaryFlags.has('killstreak-fire-all-weapons')) {
					this.secondaryWeapons.forEach((weapon) => {
						weapon.fire()
					})
				}
				if (this.binaryFlags.has('killstreak-explosion')) {
					const damageScale = getKillstreakExplosionDamageScale(this)
					const adjRadius = PLAYER_KILLSTREAK_EXPLOSION_RADIUS + value / 2
					dealAOEDamageDamageSource(CollisionLayerBits.HitEnemyOnly, adjRadius, this.position.clone(), this.killstreakDamageSource, damageScale, false, null, true, 'lightning')
					Audio.getInstance().playSfx('SFX_Elemental_Fire')
				}
			}
		}

		this.updateKillstreak()
	}

	updateKillstreak() {
		UI.getInstance().emitMutation('player/updateKillstreak', [this.currentKillstreak, this.lastKillTime, this.lastKillTime + getAdjustedKillstreakDuration(this.currentKillstreak)])
	}

	addXP(amount: number, force: boolean = false) {
		if (this.binaryFlags.has('on-pickup-gain-banditry')) {
			Buff.apply(BuffIdentifier.Banditry, this, this)

		}
		if (this.binaryFlags.has('big-pickup-on-skill-use') && Math.isPercentChance(1)) {
			this.heal(1)
		}
		if (!force && this.binaryFlags.has('xp-awarded-every-60-seconds')) {
			const state = this.binaryFlagState['xp-awarded-every-60-seconds']
			state.xpBanked += amount
			return
		}

		if (this.characterType === CharacterType.SolaraMoon) {
			const xpReduction = this.binaryFlags.has('wisdom-of-the-goddess') ? WISDOM_OF_GODDESS_XP_REDUCTION : LUNAR_ATTUNEMENT_XP_REDUCTION
			amount *= (1 - xpReduction)
		}
		this.currentXP += amount
		PlayerMetricsSystem.getInstance().trackMetric('EXPERIENCE_GAINED', amount)

		if (this.currentXP >= this.nextLevel) {
			this.levelup()
		}

		UI.getInstance().emitMutation('levelUp/updateExperience', [this.currentXP, this.nextLevel, this.level])
	}

	levelup() {
		if (this.levelUpModalOpen || VictoryDeathManager.victoryOrDeathCalled) {
			return
		}

		if (process.env.IS_ELECTRON) {
			global.gc()
		}

		this.level += 1
		this.currentXP -= this.nextLevel
		this.nextLevel = levelToXPThreshold(this.level)
		if (this.level >= PLOT_TWIST_HEAD_START_XP_SLOWDOWN_LEVEL && this.binaryFlags.has('head-start')) {
			this.nextLevel *= PLOT_TWIST_HEAD_START_XP_SLOWDOWN_MULT
		}
		console.log(`Level Up! Level: ${this.level}, Current XP: ${this.currentXP}, next level: ${this.nextLevel}`)
		PlayerMetricsSystem.getInstance().trackMetric('PLAYER_LEVEL')

		if (this.binaryFlags.has('intelligence-dump')) {
			this.updateIntelligenceDump()
		}

		if (!this.showLevelUpModal) {
			return
		}
		// extract this logic once we have more things that affect this
		let upgradeOptions = 4
		if (this.binaryFlags.has('mutator-narrow-focus')) {
			upgradeOptions -= 1
		}
		if (this.binaryFlags.has('1-additional-upgrade-choice')) {
			upgradeOptions += 1
		}
		const upgrades = UpgradeManager.rollUpgrades(upgradeOptions, "level-up")
		if (upgrades.rolledUpgrades.length) {
			this.levelUpModalOpen = true
			UI.getInstance().emitAction('levelUp/postUpgrades', upgrades)
			Audio.getInstance().playSfx('SFX_Upgrade')
		}

		this.knockbackSurroundingEnemies(PLAYER_LEVEL_UP_KNOCKBACK_SEARCH_DISTANCE, PLAYER_LEVEL_UP_KNOCKBACK_STRENGTH)

		UI.getInstance().emitMutation('levelUp/updateExperience', [this.currentXP, this.nextLevel, this.level])
	}

	getLevelDamage(multi: percentage = 1.0): number {
		return this.level * multi
	}

	knockbackSurroundingEnemies(searchDistance: number, strength: number) {
		const enemies = CollisionSystem.getInstance().getEntitiesInArea(this.position, searchDistance, CollisionLayerBits.HitEnemyOnly)
		for (let i = 0; i < enemies.length; ++i) {
			const enemy = enemies[i].owner as Enemy

			this.reuseKnockbackVector.copy(enemy.position)
			this.reuseKnockbackVector.sub(this.position)
			this.reuseKnockbackVector.normalize()
			this.reuseKnockbackVector.scale(strength, strength)

			enemy.addKnockBack(this.reuseKnockbackVector)
		}
		return enemies
	}

	applyDebuffsFromDamage(damageSource: DamageSource, damageDealt: number) {
		if (!this.isDead()) {
			const shockChance = damageSource.statList.getStat(StatType.shockChance)
			if (Math.random() <= shockChance) {
				Buff.apply(BuffIdentifier.Shock, damageSource, this, damageSource.statList.getStat(StatType.shockPotency))
			}

			const stunChance = damageSource.statList.getStat(StatType.stunChance)
			if (Math.random() <= stunChance) {
				Buff.apply(BuffIdentifier.Stun, damageSource, this)
			}

			const poisonChance = damageSource.statList.getStat(StatType.poisonChance)
			if (Math.random() <= poisonChance) {
				const stacks = getPoisonStacks(damageDealt) * damageSource.statList.getStat(StatType.poisonPotency)
				Buff.apply(BuffIdentifier.Poison, damageSource, this, stacks)
			}

			const igniteChance = damageSource.statList.getStat(StatType.igniteChance)
			if (Math.random() <= igniteChance) {
				const stacks = getIgniteStacks(damageDealt) * damageSource.statList.getStat(StatType.ignitePotency)
				Buff.apply(BuffIdentifier.Ignite, damageSource, this, stacks)
			}

			const chillChance = damageSource.statList.getStat(StatType.chillChance)
			if (Math.random() <= chillChance) {
				const stacks = getChillStacks(damageDealt) * damageSource.statList.getStat(StatType.chillPotency)
				Buff.apply(BuffIdentifier.Chill, damageSource, this, stacks)
			}

			const bleedChance = damageSource.statList.getStat(StatType.bleedChance)
			if (Math.random() <= bleedChance) {
				const stacks = getBleedStacks(damageDealt) * damageSource.statList.getStat(StatType.bleedPotency)
				Buff.apply(BuffIdentifier.Bleed, damageSource, this, stacks)
			}
		}
	}

	// Unsure if I want to do the applying of the buff this way, or add enemy to the dmg source above
	// And condition off which stun to apply (boss vs regular source)
	addRemoveBossStunDebuff(attacker: Enemy) {
		if (!this.isDead()) {
			const stunChance = attacker.statList.getStat(StatType.stunChance)
			if (Math.random() <= stunChance && !this.buffs.find(buff => buff.identifier === BuffIdentifier.Stun)) {
				Buff.apply(BuffIdentifier.Stun, attacker, this, null, YETI_STUN_DURATION)
			} else {
				Buff.remove(this, BuffIdentifier.Stun)
			}
		}
	}

	takeDamage(amount: number, attacker: any, checkInvuln = false) {
		if (checkInvuln && this.isDead() || this.godmode || InGameTime.highResolutionTimestamp() < this.invulnUntilTime) {
			return
		}
		
		const shield = Buff.getBuff(this, BuffIdentifier.AllDamageShield)
		if (shield) {
			this.invulnFromDamage()
			this.knockbackFromDamage()
			Audio.getInstance().playSfx('SFX_Player_Shield_Block')
			shield.wearOffStacks(1)
			return
		}
		if(this.binaryFlags.has('enraged')){
			amount += 1
			Buff.apply(BuffIdentifier.Enraged, this, this)
		}
		if (attacker instanceof EnemyProjectile || attacker instanceof GroundHazard) {
			this.applyDebuffsFromDamage(attacker, amount)
		}
		if (attacker) {
			if (attacker instanceof EnemyProjectile || attacker instanceof GroundHazard ) {
				this.applyDebuffsFromDamage(attacker, amount)
			} else if(attacker instanceof Enemy && attacker.isYeti){
				this.addRemoveBossStunDebuff(attacker)
			}

			const creepyEggBuff = Buff.getBuff(this, BuffIdentifier.CreepyEgg)
			if (creepyEggBuff) {
				creepyEggBuff.wearOff()
			}
		}
		
		if (this.characterType === CharacterType.SolaraSun) {
			let damageMult = 2
			if (this.binaryFlags.has('resilient-fire')) {
				const state = this.binaryFlagState['resilient-fire']
				state.hitsTaken += 1
				if (state.hitsTaken >= 2) {
					state.hitsTaken = 0
					damageMult = 1
				}
			}
			amount *= damageMult
		}

		//TODO: do stat stuff, like damage reduction or whatever
		this.currentHealth = Math.max(0, this.currentHealth - amount)
		this.updateHealth()

		Camera.getInstance().triggerShake(Math.min(amount / 1.25, SHAKE_TRAUMA_MAX))

		if (this.currentHealth > 0) { // still living!
			playAnimation(this.riggedModel, AnimationTrack.HIT)//, null, 0.5)
			this.invulnFromDamage()
			this.knockbackFromDamage()
			Audio.getInstance().playSfx('SFX_Player_Hit_Health')
			if (this.nextHealthRegenTime === 0) {
				const regenInterval = this.stats.getStat(StatType.healthRegenInterval)
				if (regenInterval > 0) {
					this.nextHealthRegenTime = InGameTime.highResolutionTimestamp() + regenInterval
				}
			}

			if (this.binaryFlags.has('butterfingers')) {
				this.scatterXP()
			}
		} else {
			playAnimation(this.riggedModel, AnimationTrack.HIT, null, 0.15)
			this.die() // guess I'll die
		}
	}

	scatterXP() {
		const xPos = this.x
		const yPos = this.y
		const randomForce = new Vector()
		while (this.currentXP >= SMALL_XP_VALUE) {
			getRandomPointInCircleRange(0, 0, PLOT_TWIST_BUTTERFINGERS_MIN_FORCE, PLOT_TWIST_BUTTERFINGERS_MAX_FORCE, undefined, randomForce)
			let configType: GroundPickupConfigType
			let damageMulti = 1
			if (this.currentXP >= LARGE_XP_VALUE && this.currentXP >= 30) {
				this.currentXP -= LARGE_XP_VALUE
				damageMulti = LARGE_XP_VALUE
				configType = GroundPickupConfigType.XPLarge
			} else if (this.currentXP >= MEDIUM_XP_VALUE && this.currentXP >= 15) {
				this.currentXP -= MEDIUM_XP_VALUE
				damageMulti = MEDIUM_XP_VALUE
				configType = GroundPickupConfigType.XPMedium
			} else {
				this.currentXP -= SMALL_XP_VALUE
				configType = GroundPickupConfigType.XPSmall
			}

			const xp = allocGroundPickup(configType, xPos, yPos, randomForce.x, randomForce.y, false)
			xp.addDamagingColliderToScene(damageMulti)
		}

		UI.getInstance().emitMutation('levelUp/updateExperience', [this.currentXP, this.nextLevel, this.level])
	}

	invulnFromDamage(overrideTime: timeInMilliseconds = 0) {
		this.invulnUntilTime = InGameTime.highResolutionTimestamp() + (overrideTime ? overrideTime : ON_DAMAGE_INVULN_TIME)
	}

	knockbackFromDamage() {
		const enemyColliders = this.knockbackSurroundingEnemies(PLAYER_HIT_KNOCKBACK_SEARCH_DISTANCE, PLAYER_HIT_KNOCKBACK_STRENGTH)

		const hasChillFlag = this.binaryFlags.has('enemies-permanently-chilled-on-touch')
		
		enemyColliders.forEach((coll) => {
			const enemy = coll.owner as Enemy
			if (!enemy.isDead() && !enemy.isBoss) {
				if (hasChillFlag) {
					Buff.apply(BuffIdentifier.Chill, this, enemy, getChillStacks(0), Number.MAX_SAFE_INTEGER)
				}
			}
		})

		if (this.coneDogThornWeapon) {
			this.coneDogThornWeapon.shootThorns()
		}
	}

	updateConeDogThornBerserk() {
		if (this.coneDogThornWeapon && this.binaryFlags.has('cone-dog-missing-health-thorn-cooldowns')) {
			const cdr = getConeDogThornCooldownReduction(this.currentHealth, this.stats.getStat(StatType.maxHealth))
			if (!this.coneDogThornBerserkStatBonus) {
				this.coneDogThornBerserkStatBonus = this.coneDogThornWeapon.statList.addStatBonus(StatType.cooldownInterval, StatOperator.MULTIPLY, cdr) as StatBonus
			} else {
				this.coneDogThornBerserkStatBonus.update(cdr)
			}
		}
	}

	die() {
		Audio.getInstance().playSfx('SFX_Enemy_SporeKid_Death') // the bamboozle
		playAnimation(this.riggedModel, AnimationTrack.DEATH)
		clearAttachedPfx(this)
		this.removeDustPfx()

		let reviving = false
		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			const searchParams = new URLSearchParams(window.location.search)
			const revive = searchParams.get(REVIVE_ON_DEATH_URL_KEY)

			if (debugConfig.reviveOnDeath || (revive && revive.toUpperCase() === 'TRUE')) {
				reviving = true

				callbacks_addCallback(this, () => {
					this.revive()
				}, 4)
			}
		}

		if (!reviving) {
			this.colliderComponent.isColliderActive = false
			VictoryDeathManager.death(this)
		}
	}

	revive() {
		this.currentHealth = this.stats.getStat('maxHealth')
		UI.getInstance().emitMutation('ui/startRound')
		Audio.getInstance().playSfx('UI_Receive_Pit') // lol I guess it works
		this.riggedModel.state.clearTracks()
		configureAnimationTracks(this.riggedModel, PLAYER_MIX_SETTINGS)
		playAnimation(this.riggedModel, AnimationTrack.IDLE)
		this.setDustEffects('dust')
	}

	takeKnockback(x: number, y: number, strength: number, lockMovement: boolean = false) {
		strength *= 1 + GlobalStatList.getStat('attackKnockback') // HUH??
		this.knockbackVelocity.x = x
		this.knockbackVelocity.y = y
		this.knockbackVelocity.normalize().scale(strength, strength)
		// this.knockbackVelocity = new Vector(collisionVX, collisionVY).clone().normalize().scale(strength, strength)
		if (lockMovement) {
			this.setMovementLockTime(ON_DAMAGE_MOVEMENT_LOCK_TIME)
		}
	}

	heal(amount: number) {
		if (this.isDead()) {
			return
		}
		const previousHealth = this.currentHealth
		this.currentHealth = Math.clamp(this.currentHealth + amount, 0, this.stats.getStat('maxHealth'))
		// Remove filter and pulsing effect if we have over 2 hearts (4 health)
		if (previousHealth <= HEALTH_PER_HEART*2 && this.currentHealth > HEALTH_PER_HEART*2) {
			this.resetHeartScale()
			this.removeHeartGlowFilter()
		}
		this.updateHealth()
	}

	updateHealth() {
		this.updateHealthbarIndicators()
		// this.removeHealthbarContainer()
		// this.createHealthContainer()
		this.updateConeDogThornBerserk()
	}

	updateColliderRadius(component: ColliderComponent, radius: number) {
		let success = false
		for (const c of component.colliders) {
			if (c.type === ColliderType.Circle) {
				c.r = radius
				success = true
			}
		}

		component.recalculateBounds()

		return success
	}

	equipSecondaryWeapon(weaponType: SecondaryWeaponType) {
		const weapon = makeSecondaryWeapon(weaponType, this)
		this.secondaryWeapons.push(weapon)
		this.allWeapons.set(weaponType as unknown as AllWeaponTypes, weapon)
		return weapon
	}

	unequipSecondaryWeapon(weaponType: AllWeaponTypes) {
		const weapon = this.secondaryWeapons.find((w) => w.weaponType === weaponType)
		if (weapon) {
			this.secondaryWeapons.remove(weapon)
			this.allWeapons.delete(weaponType as unknown as AllWeaponTypes)
		}
	}

	makePlayerModel(character: CharacterType, primaryWeaponType: PrimaryWeaponType) {
		let assetName: string
		let mixSettings: MixSettings
		if (character === CharacterType.ConeDog) {
			assetName = SpineDataName.PLAYER_SKIN_DOG
			mixSettings = PLAYER_DOG_MIX_SETTINGS
		} else {
			if (this.isUsingBow) {
				assetName = SpineDataName.PLAYER_SKINS_BOW
				mixSettings = PLAYER_BOW_MIX_SETTINGS
			} else {
				assetName = SpineDataName.PLAYER_SKINS
				mixSettings = PLAYER_MIX_SETTINGS
			}
		}

		const asset = AssetManager.getInstance().getAssetByName(assetName)
		this.riggedModel = new RiggedSpineModel(asset.spineData)
		this.model.addChild(this.riggedModel)
		this.riggedModel.skeleton.setSkinByName(PLAYER_SKINS[character])
		this.riggedModel.skeleton.setToSetupPose()
		this.riggedModel.filters = []
		configureAnimationTracks(this.riggedModel, mixSettings)
		playAnimation(this.riggedModel, AnimationTrack.IDLE)

		this.rotationBone = this.riggedModel.skeleton.findBone('root-aim')
		this.positionBone = this.riggedModel.skeleton.findBone('root-position')
		this.projectileOriginBone = this.riggedModel.skeleton.findBone('projectile-origin')

		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			const searchParams = new URLSearchParams(window.location.search)
			const rangefinder = searchParams.get(RANGEFINDER_URL_KEY)
			console.log({ searchParams, rangefinder })
			if (rangefinder || debugConfig.render.rangeFinder) {
				this.drawDebugRangefinders()
			}
		}
	}

	setAetherMagnetPfx() {
		this.aetherMagnetPfx = Renderer.getInstance().addEffectToScene('aether-magnet-pfx', 0, 0)
		attachments_addAttachment(this.aetherMagnetPfx, this, null, true)
	}

	removeAetherMagnetPfx(fadeOutDuration: timeInSeconds = 1) {
		simpleAnimation_addScaleAnimation(this.aetherMagnetPfx, (t) => easeOutBounceReversed(t, fadeOutDuration))
		callbacks_addCallback(
			this.aetherMagnetPfx,
			() => {
				Renderer.getInstance().removeEffectFromScene(this.aetherMagnetPfx)
			},
			fadeOutDuration + 0.5,
		)
	}

	setDustEffects(name: string) {
		this.footDustPfx = Renderer.getInstance().addEffectToScene(name, 0, 0)

		if (this.characterType === CharacterType.ConeDog) {
			this.frontFootDustPfx = Renderer.getInstance().addEffectToScene(name, 0, 0)
			// Attach effect to front feet
			attachments_addAttachment(this.frontFootDustPfx, this, () => {
				return sub(new Vector(this.position.x + CONEDOG_FRONT_FOOT_DUST_OFFSET * this.facingDirection, this.position.y), this)
			}, true)
			// Attach effect to back feet
			attachments_addAttachment(this.footDustPfx, this, () => {
				return sub(new Vector(this.position.x + CONEDOG_BACK_FOOT_DUST_OFFSET * this.facingDirection, this.position.y), this)
			}, true)
		} else {
			attachments_addAttachment(this.footDustPfx, this, null, true)
		}
	}

	removeDustPfx() {
		Renderer.getInstance().removeEffectFromScene(this.footDustPfx)
		if (this.frontFootDustPfx) {
			Renderer.getInstance().removeEffectFromScene(this.frontFootDustPfx)
		}
	}

	applyBigHeadModel() {
		const headBone = this.riggedModel.skeleton.findBone('head')
		headBone.scaleX = 1.4
		headBone.scaleY = 1.4
	}

	makeReloadGraphics() {
		this.reloadGraphicsContainer = new Container()
		this.reloadGraphicsContainer.visible = false

		this.reloadGraphicsMask = new Graphics()
		this.reloadGraphicsMask.scale.y = 0.5
		this.reloadGraphicsMask.scale.x = 1.25

		this.reloadSprite = Sprite.from('reload-fill')
		const reloadBg = Sprite.from('reload-base')

		this.reloadSprite.position.x = -70
		this.reloadSprite.position.y = -20

		reloadBg.position.x = -70
		reloadBg.position.y = -20

		reloadBg.alpha = 0.5

		this.reloadSprite.mask = this.reloadGraphicsMask

		this.reloadGraphicsContainer.addChild(this.reloadGraphicsMask)
		this.reloadGraphicsContainer.addChild(reloadBg)
		this.reloadGraphicsContainer.addChild(this.reloadSprite)


		this.model.addChildAt(this.reloadGraphicsContainer, 0)
	}

	drawDebugModel() {
		const gfx = new Graphics()
		gfx.beginFill(0xDAA520)
		gfx.drawCircle(50, 50, 50)
		gfx.endFill()
		gfx.beginFill(0x4F7942)
		gfx.drawRect(15, 25, 20, 40)
		gfx.drawRect(45, 25, 20, 40)
		gfx.endFill()
		gfx.x = -50
		gfx.y = -50
		this.model.addChild(gfx)
	}

	drawDebugRangefinders() {
		const textStyle = {
			fontSize: 16,
			fontWeight: 900,
			letterSpacing: 1,
			align: 'left',
			fill: 'white',
			lineJoin: 'bevel',
			miterLimit: 5,
		}
		const gfx = new Graphics()
		const circleSizes = [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1800, 2000, 2200, 2400]
		gfx.lineStyle(2, 0xDAA520, 0.5)
		gfx.drawRect(0, 0, circleSizes[circleSizes.length - 1], 1)
		circleSizes.forEach((size) => {
			const bigTick = size % 100 === 0
			gfx.lineStyle(2, bigTick ? 0xDAA520 : 0xD4C295, bigTick ? 0.5 : 0.3)
			gfx.drawCircle(0, 0, size)
			const textRadius = new Text(`${size}`, textStyle)
			textRadius.position.y = -size
			gfx.addChild(textRadius)

			gfx.drawRect(size, -20, 1, 40)
			const textLength = new Text(`${size / 2}`, textStyle)
			textLength.position.x = size
			textLength.position.y = -textStyle.fontSize - 2
			gfx.addChild(textLength)
		})
		let vec = new Vector(500, 0)
		for (let deg = 0; deg < 360; deg += 22.5) {
			const rad = degToRad(deg)
			const text = new Text(`${deg}° ${rad.toFixedIfFloat(3)}r`, textStyle)
			const pos = vec.clone().rotate(rad)
			text.position.x = pos.x
			text.position.y = pos.y
			gfx.addChild(text)
		}
		this.model.addChild(gfx)
	}

	playAnimation(track: AnimationTrack, timeScale: number = 1, restartAnim?: boolean) {
		this.riggedModel.state.setEmptyAnimations(0)
		playAnimation(this.riggedModel, track, undefined, timeScale, restartAnim)
	}

	onBuffApplied(buffId: BuffIdentifier): void {
		updateSerializedBuffProperties(this)
		if (PLAYER_BUFF_LOGGING) { //TODO: also add an environment check
			console.groupCollapsed(`Player buff applied: ${buffId}`)
			this.logBuffDetails(buffId)
			console.groupEnd()
		}
	}
	onBuffUpdateStacks(buffId: BuffIdentifier, oldStacks: number, newStacks: number): void {
		if (PLAYER_BUFF_LOGGING) { //TODO: also add an environment check
			console.groupCollapsed(`Player buff updated: ${buffId}, ${oldStacks} -> ${newStacks}`)
			this.logBuffDetails(buffId)
			console.groupEnd()
		}
	}
	onBuffWearOff(buffId: BuffIdentifier): void {
		updateSerializedBuffProperties(this)
		if (PLAYER_BUFF_LOGGING) { //TODO: also add an environment check
			console.log(`Player buff worn off: ${buffId}`)
		}
	}

	equipPrimaryWeapon(primaryWeaponType: PrimaryWeaponType): void {
		const hadOldPrimary = Boolean(this.primaryWeapon)
		const oldPrimaryType = this.primaryWeaponType

		if (hadOldPrimary && oldPrimaryType === primaryWeaponType) {
			return
		}

		this.primaryWeaponType = primaryWeaponType
		switch (primaryWeaponType) {
			case PrimaryWeaponType.Bow:
				this.primaryWeapon = new Bow(this, this.stats)
				break
			case PrimaryWeaponType.Wand:
				this.primaryWeapon = new Wand(this, this.stats)
				break
			case PrimaryWeaponType.Boomerang:
				this.primaryWeapon = new Boomerang(this, this.stats)
				break
			case PrimaryWeaponType.Spear:
				this.primaryWeapon = new SpearWeapon(this, this.stats)
				break
			case PrimaryWeaponType.None:
				this.primaryWeapon = new EmptyPrimaryWeapon(this, this.stats)
				break
		}
		this.primaryWeapon.init(this, this.stats)

		this.allWeapons.set(this.primaryWeapon.weaponType, this.primaryWeapon)
		this.ammoCount = this.primaryWeapon.statList.getStat(StatType.maxAmmo)
		this.boomerangAmmoCount = this.ammoCount
		this.updateAmmoCount()

		this.primaryWeapon.statList.addClamp({ statType: StatType.allDamageMult, clampMin: 0.2, clampMax: Number.MAX_SAFE_INTEGER })

		if (hadOldPrimary) {
			if (this.weapon) {
				this.riggedModel.detachSpineSprite('weapon-attach')
				this.weapon.destroy()
				this.weapon = null
			}

			this.allWeapons.delete(oldPrimaryType as any)
			if (oldPrimaryType === PrimaryWeaponType.Bow || primaryWeaponType === PrimaryWeaponType.Bow) {
				this.model.removeChild(this.riggedModel)
				this.riggedModel.destroy()
				this.makePlayerModel(this.characterType, primaryWeaponType)
			}
		}

		if (this.primaryWeaponType === PrimaryWeaponType.None) {
			return
		}

		const weaponName = WeaponConfig[this.primaryWeaponType].dbName
		const skinName = this.primaryWeaponType === PrimaryWeaponType.Bow ? 'bow-regular' : `${weaponName}-starter-01`
		this.setWeaponSkin(skinName)
	}

	setWeaponSkin(skinName: string) {
		if (this.weapon) {
			const skin = new PIXI.spine.core.Skin('myWeapon')
			const weaponSkin = this.weapon.skeleton.data.findSkin(skinName)
			skin.addSkin(weaponSkin)
			this.weapon.skeleton.setSkin(skin)
			if (this.primaryWeaponType === PrimaryWeaponType.Bow) {
				switch (skinName) {
					case 'bow-stormbreaker':
					case 'bow-arrow-hurricane':
						this.chargePfxName = 'stormbreaker-charge'
						break
					default:
						this.chargePfxName = 'charging'
				}
			}
			this.weapon.update(0)
		} else {
			if (this.primaryWeaponType === PrimaryWeaponType.None) {
				return
			}

			const spineAssetName = WeaponConfig[this.primaryWeaponType].primary.assetName
			AssetManager.getInstance().getAssetByNameAsync(spineAssetName, (asset) => {
				const weaponAsset = asset.spineData
				this.weapon = new RiggedSpineModel(weaponAsset)

				const skin = new PIXI.spine.core.Skin('myWeapon')
				const weaponSkin = this.weapon.skeleton.data.findSkin(skinName)
				skin.addSkin(weaponSkin)
				this.weapon.skeleton.setSkin(skin)

				const attachPoint = this.primaryWeaponType === PrimaryWeaponType.Bow ? 'bow-attachment' : `weapon-attach`
				this.riggedModel.attachSpineSprite(attachPoint, this.weapon)
				this.weapon.update(0)
			})
		}
	}

	updateEnergy(delta): void {
		const isOnCooldown = this.currentAttackCooldown > 0.05
		if (!isOnCooldown && this.primaryWeapon.resourceType === ResourceType.CHARGE_UP_SHOT) {
			const maxEnergy = this.primaryWeapon.statList.getStat(StatType.maxCharge)
			if ((ClientPlayerInput.currentFrameInput.get('shoot') || (this.autoShootEnabled && this.currentEnergy < maxEnergy && this.autoShootToggledOn)) && !this.noFireWeapons) {
				const oldEnergy = this.currentEnergy

				let chargePercentGained = (delta * this.primaryWeapon.statList.getStat(StatType.chargeRate)) / 100
				if (oldEnergy > 100) {
					chargePercentGained *= 2 // extra charges charge up faster
				}

				const oldEnergyBarsCharged = Math.floor(this.currentEnergy / 100)
				this.currentEnergy = Math.clamp(oldEnergy + chargePercentGained * 100, 1, maxEnergy)
				this.updateChargeAmmoIndicators()
				this.updateChargePfxColor()

				if (oldEnergyBarsCharged < Math.floor(this.currentEnergy / 100)) {
					Audio.getInstance().playSfx('SFX_Enemy_SporeKid_Shoot')
				}

				if (!this.isCharging && this.primaryWeaponType === PrimaryWeaponType.Bow) {
					playAnimation(this.riggedModel, AnimationTrack.IDLE_BOW_DRAW_BACK)
				}

				this.isCharging = true
			} else {
				if (this.isCharging && this.primaryWeaponType === PrimaryWeaponType.Bow) {
					playAnimation(this.riggedModel, AnimationTrack.IDLE_BOW_RELEASE)
					if (this.currentEnergy <= PLAYER_CHARGE_MISFIRE_PERCENT) {
						this.currentEnergy = 0
						this.updateChargeAmmoIndicators()
						this.updateChargePfxColor()
					}
				}

				this.isCharging = false
			}
		} else {
			this.isCharging = false
		}

		throttledEnergyPercentageEventEmitter(this.currentEnergy)
	}

	weaponCharging(): void {
		if (this.isCharging) {
			// show PFX
			// start charge-up SFX and ramp volume to 100% over time
			if (!this.chargePfx) {
				// entity.chargePfx = Renderer.getInstance().mgRenderer.addEffectToScene('charge', entity.aimPositionX, entity.aimPositionY)
				const renderer = Renderer.getInstance()
				const cam = renderer.cameraState
				const pfx = new Effect(AssetManager.getInstance().getAssetByName(this.chargePfxName).data, cam)
				pfx.x = this.x
				pfx.y = this.y
				pfx.scale = 1
				pfx.zIndex = this.model.zIndex + 150
				this.chargePfx = pfx
				Renderer.getInstance().mgRenderer.addEffectToScene(this.chargePfx)
			}
		} else {
			// hide PFX
			// end charge-up SFX
			if (this.chargePfx) {
				Renderer.getInstance().mgRenderer.removeFromScene(this.chargePfx)
				this.chargePfx = null
			}
		}
	}

	updateChargePfxLocation() {
		if (this.chargePfx) {
			const weaponLocation = this.riggedModel.skeleton.findBone('weapon-attach')
			const pfxLocation = new Vector(weaponLocation.worldX, weaponLocation.worldY)
			this.chargePfx.zIndex = this.model.zIndex + 150
			this.chargePfx.x = this.x + pfxLocation.x
			this.chargePfx.y = this.y + pfxLocation.y
		}
	}

	updateChargePfxScale() {
		if (!this.chargePfx) {
			return
		}

		const chargeDef = this.getChargeDefinition()
		this.chargePfx.scale = chargeDef.chargeStats.pfxScale
	}

	getChargeDefinition(): ChargeDefinition {
		return getChargeDefinition(this.currentEnergy, this.primaryWeapon.chargeIncrements)
	}

	updateChargePfxColor() {
		if (!this.chargePfx) {
			return
		}
		if (this.currentEnergy.between(0, 100)) {
			setChargePfxColor(this.chargePfx, "gold")
		} else if (this.currentEnergy.between(101, 200)) {
			setChargePfxColor(this.chargePfx, "green")
		} else if (this.currentEnergy.between(201, 300)) {
			setChargePfxColor(this.chargePfx, "red")
		} else if (this.currentEnergy.between(301, 400)) {
			setChargePfxColor(this.chargePfx, "blue")
		} else {
			setChargePfxColor(this.chargePfx, "pink")
		}
	}


	weaponReload() {
		const maxAmmo = this.primaryWeapon.statList.getStat(StatType.maxAmmo)

		if (this.ammoCount < maxAmmo && !this.isReloading && this.primaryWeaponType !== PrimaryWeaponType.Boomerang) {
			this.isReloading = true
			this.reloadStartTime = InGameTime.highResolutionTimestamp()
			Audio.getInstance().playSfx('SFX_Reload_Start')
			// Make sure the UI refreshes
			throttledEnergyPercentageEventEmitter(this.currentEnergy)
		}

		if (this.isReloading) {
			const now = InGameTime.highResolutionTimestamp()
			const weaponReloadTime = this.primaryWeapon.statList.getStat(StatType.reloadInterval)
			const timeSinceReloading = now - this.reloadStartTime
			if (timeSinceReloading > weaponReloadTime) {
				this.isReloading = false
				this.ammoCount = Math.clamp(this.primaryWeapon.statList.getStat(StatType.reloadAmmoIncrement) + this.ammoCount, 0, this.primaryWeapon.statList.getStat('maxAmmo'))
				if (this.binaryFlags.has('attack-rate-after-reload')) {
					Buff.apply(BuffIdentifier.QuickReloadAttackRate, this, this, 1, 1000)
				}
				Audio.getInstance().playSfx('SFX_Reload_Finish')
				this.updateAmmoCount()
				this.updateChargeAmmoIndicators()
				this.updateChargePfxColor()
				this.hideReloadGraphics()
			} else {
				this.updateReloadGraphics(timeSinceReloading / weaponReloadTime)
			}
		}
	}

	instantReload() {
		this.isReloading = false
		this.ammoCount = Math.clamp(this.primaryWeapon.statList.getStat(StatType.reloadAmmoIncrement) + this.ammoCount, 0, this.primaryWeapon.statList.getStat('maxAmmo'))
		if (this.binaryFlags.has('attack-rate-after-reload')) {
			Buff.apply(BuffIdentifier.QuickReloadAttackRate, this, this, 1, 1000)
		}
		Audio.getInstance().playSfx('SFX_Reload_Finish')
		this.updateAmmoCount()
		this.updateChargeAmmoIndicators()
		this.updateChargePfxColor()
		this.hideReloadGraphics()
	}

	stopSecondaryWeapons() {
		for (let i = 0; i < this.secondaryWeapons.length; ++i) {
			const secondary = this.secondaryWeapons[i]
			secondary.forceStopFiring()
	}
	}

	boomerangReturned() {
		if (GameClient.isShutDown) {
			return
		}

		const maxAmmo = this.primaryWeapon.statList.getStat('maxAmmo')
		this.ammoCount = Math.clamp(1 + this.ammoCount, 0, maxAmmo)
		this.updateAmmoCount()
		this.updateChargeAmmoIndicators()

		if (this.binaryFlags.has('boomerang-recall-with-click')) {
			this.boomerangRecall()
		}

		this.weapon.visible = true
		if (this.boomerangsOut) {
			this.boomerangsOut = false
		}
	}

	boomerangRecall() {
		const boomerang = this.primaryWeapon as Boomerang

		GameState.playerProjectileList.forEach((proj) => {
			if (proj.isBoomerang && !proj.isOrbit && proj.trajectoryStage !== TrajectoryBoomerangStage.Returning) {
				proj.trajectoryStage = TrajectoryBoomerangStage.Returning
				proj.clearStatsToAllowMultiHitting(true)
				proj.isCircling = false
				proj.knockbackUsingCurrentPosition = false

				if (boomerang.increaseOrbitSpeed && proj.trajectoryStage === TrajectoryBoomerangStage.Curving) {
					proj.speed /= OUTBACK_ORBIT_RADIUS_UPGRADE_AMOUNT
				}
			}
		})
	}

	updateReloadGraphics(reloadProgress: number) {
		this.reloadGraphicsContainer.visible = true

		this.reloadGraphicsMask.clear()
		this.reloadGraphicsMask.beginFill(0x000000)
		const endAngle = Math.lerp(-Math.PI / 2, (-Math.PI / 2) + Math.PI * 2, reloadProgress)
		this.reloadGraphicsMask.arc(0, 0, RELOAD_GRAPHICS_RADIUS, -Math.PI / 2, endAngle)
		this.reloadGraphicsMask.lineTo(0, 0)
		this.reloadGraphicsMask.endFill()

		this.reloadGraphicsContainer.scale.x = this.facingDirection
	}

	hideReloadGraphics() {
		this.reloadGraphicsContainer.visible = false
	}

	stopReload() {
		this.isReloading = false
		this.reloadStartTime = 0
		this.hideReloadGraphics()
	}

	updateAmmoCount(): void {
		UI.getInstance().emitMutation('ui/updateAmmoCount', { totalAmmo: this.primaryWeapon.statList.getStat(StatType.maxAmmo), currentAmmo: this.ammoCount })
	}

	private logBuffDetails(buffId: BuffIdentifier) {
		const now = InGameTime.highResolutionTimestamp()
		const buff = Buff.getBuff(this, buffId)
		const { stacks, appliedAtTime, expiresAtTime, rollingStacksApplied, rollingStackApplicationCount } = buff
		const lastsForever = buff.definition.lastsForever
		const timeSinceApplied = now - appliedAtTime
		const stacksObj: any = {
			stacks,
		}
		if (buff.definition.stackStyle === StackStyle.RollingStackDurationSeparately) {
			stacksObj.rollingStackApplicationCount = rollingStackApplicationCount
			stacksObj.rollingStacksApplied = rollingStacksApplied
		}
		let timingObj: any
		if (lastsForever) {
			timingObj = {
				lastsForever,
				now,
				appliedAtTime,
				timeSinceApplied,
			}
		} else {
			const timeUntilExpiry = expiresAtTime - now
			timingObj = {
				stacks,
				now,
				appliedAtTime,
				timeSinceApplied,
				expiresAtTime,
				timeUntilExpiry,
			}
		}
		console.log('timing', timingObj)
		console.log('stacks', stacksObj)
	}

	getPlayerLoadout() {
		const loadout = []
		loadout.push({ icon: UI.getInstance().store.state.characterSelect.selectedCharacter.icon })

		// Disregard primary weapon that are none/unknown/enemy
		if (this.primaryWeapon.weaponType >= 1) {
			loadout.push({ icon: WeaponConfig[this.primaryWeapon.weaponType].icon })
		}

		for (const key in this.secondaryWeapons) {
			if (WeaponConfig[this.secondaryWeapons[key].weaponType] !== undefined) {
				loadout.push({ icon: (WeaponConfig[this.secondaryWeapons[key].weaponType].icon) })
			}
		}
		if (PetRescueEventSystem.getInstance().totalPets > 0) {
			loadout.push({ icon: 'secondary-pets' })
		}

		return loadout
	}

	createHealthbarContainer() {
		const maxHealth = this.stats.getStat('maxHealth')
		this.healthBarContainer = new Container()
		this.healthBarContainer.filters = []
		this.healthBarContainer.zIndex = 999_999

		const healthFullAsset = AssetManager.getInstance().getAssetByName('health-container-full')
		const healthHalfAsset = AssetManager.getInstance().getAssetByName('health-container-half')
		const healthEmptyAsset = AssetManager.getInstance().getAssetByName('health-container-empty')

		const healthFull = healthFullAsset.texture as PIXI.Texture
		const healthhalf = healthHalfAsset.texture as PIXI.Texture
		const healthEmpty = healthEmptyAsset.texture as PIXI.Texture


		for (let index = 1; index <= maxHealth / 2; index++) {
			this.healthBarsEmpty = new PIXI.Sprite(healthEmpty)
			this.healthBarsEmpty.position.x = 35 * index
			this.healthBarsEmpty.visible = false
			this.healthBarsEmpty.name = `empty${index}`
			this.healthBarsEmpty.filters = null
			this.healthBarsEmpty.anchor.x = 0.5
			this.healthBarsEmpty.anchor.y = 0.5

			this.healthBarsHalf = new PIXI.Sprite(healthhalf)
			this.healthBarsHalf.position.x = 35 * index
			this.healthBarsHalf.visible = false
			this.healthBarsHalf.name = `half${index}`
			this.healthBarsHalf.filters = null
			this.healthBarsHalf.anchor.x = 0.5
			this.healthBarsHalf.anchor.y = 0.5

			this.healthBarsFull = new PIXI.Sprite(healthFull)
			this.healthBarsFull.position.x = 35 * index
			this.healthBarsFull.visible = false
			this.healthBarsFull.name = `full${index}`
			this.healthBarsFull.filters = null
			this.healthBarsFull.anchor.x = 0.5
			this.healthBarsFull.anchor.y = 0.5

			if (index * 2 <= this.currentHealth) {
				this.healthBarsFull.visible = true
			} else if (index * 2 === this.currentHealth + 1) {
				this.healthBarsHalf.visible = true
			} else if (index * 2 > this.currentHealth + 1) {
				this.healthBarsEmpty.visible = true
			}

			this.healthBarContainer.addChild(this.healthBarsEmpty, this.healthBarsHalf, this.healthBarsFull) // order is important
		}

		const offset = -this.healthBarContainer.width / 2 - HEALTH_CONTAINER_OFFSET

		this.healthBarContainer['update'] = () => {
			this.healthBarContainer.position.x = this.model.position.x + offset
			this.healthBarContainer.position.y = this.model.position.y - (200*this.modelScale - HEALTH_CONTAINER_OFFSET)
		}

		Renderer.getInstance().fgRenderer.addDisplayObjectToScene(this.healthBarContainer)
	}

	updateHealthbarIndicators(currentHealth?: number) {
		if (currentHealth === undefined) {
			currentHealth = this.currentHealth
		}
		for (let i = 0; i < this.healthBarContainer.children.length; i++) {
			const child: PIXI.DisplayObject = this.healthBarContainer.children[i]
			const modi = i % 3
			const ihp = Math.round((i + 1) * 2 / 3) + 1 // math
			if (modi === 0) { // empty heart
				child.visible = ihp > this.currentHealth
			} else if (i % 3 === 1) { // half heart
				child.visible = ihp === this.currentHealth + 1
			} else if (i % 3 === 2) { // full heart
				child.visible = ihp <= this.currentHealth + 1
			}
		}
		if (this.currentHealth <= HEALTH_PER_HEART*2) {
			this.addHeartGlowFilter()
		}
	}

	destroy() {
		Buff.removeAll(this)

		document.removeEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.boundOnInputDown)
		document.removeEventListener(INPUT_UP_ACTION_EVENT_NAME, this.boundOnInputUp)

		GlobalStatList.removeChild(this.stats)
		GlobalStatList.removeChild(this.petStatList)

		this.secondaryWeapons.forEach((w) => {
			w.destroy()
		})

		this.allWeapons.clear()
		this.secondaryWeapons.length = 0
		this.primaryWeapon = null
		this.stats.clearAllState()
		this.stats.removeAllChildren()
		this.stats = null
		this.secondaryStatList.clearAllState()
		this.secondaryStatList = null
		this.petStatList.clearAllState()
		this.petStatList = null
		this.skillWeapon = null
		this.passiveSkill = null
		this.coneDogThornWeapon = null
		this.buffs.length = 0
	}

	private addHeartGlowFilter() {
		HEART_INDICES.forEach((index) => {
			const heart = this.healthBarContainer.getChildAt(index)
			if (!heart.filters) {
				heart.filters = [filterFromString('glow', HEART_GLOW_FILTER_PARAMS)]
			}
		})
	}

	private removeHeartGlowFilter() {
		HEART_INDICES.forEach((index) => {
			const heart = this.healthBarContainer.getChildAt(index)
			if (heart.filters) {
				heart.filters = null
			}
		})
	}

	private updateHealthbarScale() {
		if (this.healthbarPulseAcc < HEART_PULSE_TIME) {
			this.healthbarScale = Math.lerp(this.healthbarScaleStart, this.healthbarScaleTarget, this.healthbarPulseAcc / HEART_PULSE_TIME)
		} else {
			this.healthbarScale = this.healthbarScaleTarget
			this.healthbarPulseAcc = 0
			if (this.healthbarScaleTarget === HEART_SCALE_MAX) {
				this.healthbarScaleTarget = HEART_SCALE_MIN
				this.healthbarScaleStart = HEART_SCALE_MAX
			} else {
				this.healthbarScaleTarget = HEART_SCALE_MAX
				this.healthbarScaleStart = HEART_SCALE_MIN
			}
		}

		HEART_INDICES.forEach((index) => {
			const heart = this.healthBarContainer.getChildAt(index)
			heart.scale.x = this.healthbarScale
			heart.scale.y = this.healthbarScale
		})
	}

	private resetHeartScale() {
		HEART_INDICES.forEach((index) => {
			const heart = this.healthBarContainer.getChildAt(index)
			heart.scale.x = HEART_SCALE_BASE
			heart.scale.y = HEART_SCALE_BASE
		})
	}

	deleteHealthbarContainer() {
		Renderer.getInstance().fgRenderer.removeFromScene(this.healthBarContainer)
	}

	getChargeAmmoMode(): ChargeAmmoMode {
		const resourceType = this.primaryWeapon.resourceType
		if (resourceType === ResourceType.CHARGE_UP_SHOT) {
			return ChargeAmmoMode.Charge
		} else if (this.primaryWeapon.statList.getStat(StatType.maxAmmo) > 1) {
			return ChargeAmmoMode.Ammo
		} else {
			return ChargeAmmoMode.None
		}
	}

	createChargeAmmoContainer() {
		let maxAmmo: number

		this.ammoBarContainer = new Container()
		this.ammoBarContainer.filters = []

		switch (this.getChargeAmmoMode()) {
			case ChargeAmmoMode.Ammo:
				maxAmmo = this.primaryWeapon.statList.getStat(StatType.maxAmmo)
				break
			case ChargeAmmoMode.Charge:
				maxAmmo = CHARGE_WEAPON_MAX_VISUAL_PIPS
				break
			case ChargeAmmoMode.None:
				return // where we're goin, we don't need no indicator
		}

		const ammoFullAsset = AssetManager.getInstance().getAssetByName('ammo-full-gold')
		const ammoEmptyAsset = AssetManager.getInstance().getAssetByName('ammo-empty')

		const ammoFull = ammoFullAsset.texture as PIXI.Texture
		const ammoEmpty = ammoEmptyAsset.texture as PIXI.Texture

		for (let index = 0; index < maxAmmo; index++) {
			const ammoEmptySprite = new PIXI.Sprite(ammoEmpty)
			ammoEmptySprite.position.x = 10 * (index % 30)
			ammoEmptySprite.position.y = 20 * (Math.floor(index / 30))
			ammoEmptySprite.visible = false
			ammoEmptySprite.name = `empty${index}`

			const ammoFullSprite = new PIXI.Sprite(ammoFull)
			ammoFullSprite.position.x = 10 * (index % 30)
			ammoFullSprite.position.y = 20 * (Math.floor(index / 30))
			ammoFullSprite.visible = false
			ammoFullSprite.name = `full${index}`

			this.ammoBarContainer.addChild(ammoEmptySprite, ammoFullSprite) // order is important
		}

		this.ammoBarContainer['update'] = this.updateAmmoBarContainerFn.bind(this)
		this.ammoBarContainer.zIndex = 999_999

		Renderer.getInstance().fgRenderer.addDisplayObjectToScene(this.ammoBarContainer)
	}

	updateAmmoBarContainerFn() {
		let maxAmmo: number
		switch (this.getChargeAmmoMode()) {
			case ChargeAmmoMode.Ammo:
				maxAmmo = this.primaryWeapon.statList.getStat(StatType.maxAmmo)
				break
			case ChargeAmmoMode.Charge:
				maxAmmo = CHARGE_WEAPON_MAX_VISUAL_PIPS
				break
			case ChargeAmmoMode.None:
				return // where we're goin, we don't need no indicator
		}

		const offset = -this.ammoBarContainer.width / 2
		this.ammoBarContainer.position.x = this.model.position.x + offset
		this.ammoBarContainer.position.y = this.model.position.y + 50 // 50 units below the player
	}

	setupChargeAmmoTextures() {
		this.chargeAmmoTextures["gold"] = AssetManager.getInstance().getAssetByName('ammo-full-gold').texture as PIXI.Texture
		this.chargeAmmoTextures["red"] = AssetManager.getInstance().getAssetByName('ammo-full-red').texture as PIXI.Texture
		this.chargeAmmoTextures["blue"] = AssetManager.getInstance().getAssetByName('ammo-full-blue').texture as PIXI.Texture
		this.chargeAmmoTextures["green"] = AssetManager.getInstance().getAssetByName('ammo-full-green').texture as PIXI.Texture
		this.chargeAmmoTextures["pink"] = AssetManager.getInstance().getAssetByName('ammo-full-pink').texture as PIXI.Texture
	}

	updateChargeAmmoIndicatorColor(color: "gold" | "red" | "blue" | "green" | "pink") {
		const ammoFull = this.chargeAmmoTextures[color] as PIXI.Texture
		if (this.chargeAmmoCurrentTexture === ammoFull) {
			return
		}
		this.chargeAmmoCurrentTexture = ammoFull
		for (let i = 1; i < this.ammoBarContainer.children.length; i += 2) { // only grab the "full" ones
			const child: PIXI.Sprite = this.ammoBarContainer.children[i] as PIXI.Sprite
			child.texture = ammoFull
		}
	}

	updateChargeAmmoIndicators() {
		let currentValue: number
		switch (this.getChargeAmmoMode()) {
			case ChargeAmmoMode.Ammo:
				currentValue = this.ammoCount
				break
			case ChargeAmmoMode.Charge:
				currentValue = this.currentEnergy % 101 * CHARGE_WEAPON_MAX_VISUAL_PIPS / 100
				if (this.currentEnergy.between(0, 100)) {
					this.updateChargeAmmoIndicatorColor("gold")
				} else if (this.currentEnergy.between(101, 200)) {
					this.updateChargeAmmoIndicatorColor("green")
				} else if (this.currentEnergy.between(201, 300)) {
					this.updateChargeAmmoIndicatorColor("red")
				} else if (this.currentEnergy.between(301, 400)) {
					this.updateChargeAmmoIndicatorColor("blue")
				} else {
					this.updateChargeAmmoIndicatorColor("pink")
				}
				break
			case ChargeAmmoMode.None:
				return
		}
		let isThisTheEmptyChild = true // n a m i n g
		for (let i = 0; i < this.ammoBarContainer.children.length; i++) {
			const ammoIndex = Math.floor(i / 2)
			const child: PIXI.DisplayObject = this.ammoBarContainer.children[i]
			child.visible = isThisTheEmptyChild ? (ammoIndex >= currentValue) : (ammoIndex < currentValue)
			isThisTheEmptyChild = !isThisTheEmptyChild
		}
	}

	deleteChargeAmmoContainer() {
		Renderer.getInstance().fgRenderer.removeFromScene(this.ammoBarContainer)
	}

	makeClarityAuraFilter() {
		this.clarityAuraFilter = new GlowFilter()
		this.clarityAuraFilter.color = CLARITY_AURA_COLOR
		this.clarityAuraFilter.outerStrength = 0
	}

	activateClarityAura(reason: ClarityAuraReason) {
		const wasInactive = this.clarityAuraReason === ClarityAuraReason.None && this.clarityAuraFilter.outerStrength === 0
		this.clarityAuraReason = this.clarityAuraReason | reason

		if (reason === ClarityAuraReason.BehindProp) {
			this.clarityAuraPropCount++
		}

		if (wasInactive) {
			this.riggedModel.filters.push(this.clarityAuraFilter)
			this.healthBarContainer.filters.push(this.clarityAuraFilter)
			if (this.ammoBarContainer) {
				this.ammoBarContainer.filters.push(this.clarityAuraFilter)
			}
		}
	}

	deactivateClarityAura(reason: ClarityAuraReason) {
		if (reason === ClarityAuraReason.BehindProp) {
			this.clarityAuraPropCount--
			if (this.clarityAuraPropCount === 0) {
				this.clarityAuraReason = this.clarityAuraReason & ~reason
			}
		} else {
			this.clarityAuraReason = this.clarityAuraReason & ~reason
		}
	}

	updateClarityAura(delta: timeInSeconds) {
		if (GameState.enemyList.length >= CLARITY_AURA_ENEMY_COUNT_MIN) {
			this.activateClarityAura(ClarityAuraReason.EnemyCount)
		} else {
			this.deactivateClarityAura(ClarityAuraReason.EnemyCount)
		}

		if (this.clarityAuraReason === ClarityAuraReason.None) {
			this.clarityAuraTime = Math.clamp(this.clarityAuraTime - delta, 0, CLARITY_AURA_LERP_TIME)
		} else {
			this.clarityAuraTime = Math.clamp(this.clarityAuraTime + delta, 0, CLARITY_AURA_LERP_TIME)
		}

		this.clarityAuraFilter.outerStrength = Math.lerp(0, CLARITY_AURA_MAX_STRENGTH, this.clarityAuraTime / CLARITY_AURA_LERP_TIME)

		if (this.clarityAuraReason === ClarityAuraReason.None && this.clarityAuraFilter.outerStrength === 0) {
			this.riggedModel.filters.remove(this.clarityAuraFilter)
			this.healthBarContainer.filters.remove(this.clarityAuraFilter)
			if (this.ammoBarContainer) {
				this.ammoBarContainer.filters.remove(this.clarityAuraFilter)
			}
		}
	}

	createStaminaBars() {
		this.staminaBarContainer = new Container()
		this.staminaBarContainer['update'] = () => {
			this.staminaBarContainer.x = this.position.x - 75
			this.staminaBarContainer.y = this.position.y - 230 * this.modelScale
		}

		this.staminaBarFg = new Graphics()
		this.staminaBarBg = new Graphics()

		this.staminaBarBg.beginFill(0x000000)
		this.staminaBarBg.lineStyle(1, 0x000000)
		this.staminaBarBg.drawRect(0, 0, 154, 15)

		this.staminaBarFg.beginFill(0x0DD14E)
		this.staminaBarFg.lineStyle(1, 0x00FF00)
		this.staminaBarFg.drawRect(2, 2, 150, 13)

		this.staminaBarContainer.addChild(this.staminaBarBg)
		this.staminaBarContainer.addChild(this.staminaBarFg)

		Renderer.getInstance().fgRenderer.addDisplayObjectToScene(this.staminaBarContainer)
	}

	adjustPlayerSize(scale: number) {
		this.riggedModel.scale.x += scale
		this.riggedModel.scale.y += scale

		this.modelScale += scale

		this.colliderComponent.recalculateBounds()
	}

	addCurrency(currency: GroundPickupType, amount) {
		if (currency === GroundPickupType.CommonCurrency) {
			this._wallet.commonCurrency += Math.floor(amount)
		} else if (currency === GroundPickupType.RareCurrency) {
			this._wallet.rareCurrency += Math.floor(amount)
		}
		UI.getInstance().emitAction('paused/postCurrency', { wallet: this._wallet })
	}

	applyHardcoreSurvivalMutator() {
		this.staminaActive = true
		this.exhaustedDebuff = this.stats.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, 0) as StatBonus
		this.createStaminaBars()

		if (this.characterType === CharacterType.ConeDog) {
			this.staminaConsumptionRate = PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_CONSUMPTION_RATE_DOG
		} else {
			this.staminaConsumptionRate = PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_CONSUMPTION_RATE
		}
	}

	updateStamina(delta: timeInSeconds) {
		if (this.staminaActive) {
			if (this.staminaExhausted) {
				this.currentStamina += PLOT_TWIST_HARDCORE_SURVIVAL_EXHAUSTED_STAMINA_RECOVERY_RATE * delta
				if (this.currentStamina >= PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA) {
					this.currentStamina = PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA
					this.staminaExhausted = false
					this.exhaustedDebuff.update(0)
					this.staminaBarFg.tint = 0xFFFFFF
				}
			} else {
				if (this.velocity.len2()) {
					// moving
					this.currentStamina -= this.staminaConsumptionRate * delta
					if (this.currentStamina <= 0) {
						this.currentStamina = 0
						this.staminaExhausted = true
						this.staminaBarFg.tint = 0x8F8F8F
						this.exhaustedDebuff.update(PLOT_TWIST_HARDCORE_SURVIVAL_EXHAUSTED_MOVEMENT_DEBUFF_AMOUNT)
					}
				} else {
					// recovery
					this.currentStamina = Math.clamp(this.currentStamina + PLOT_TWIST_HARDCORE_SURVIVAL_NORMAL_STAMINA_RECOVERY_RATE * delta,
						0, PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA)
				}
			}

			this.staminaBarFg.scale.x = this.currentStamina / PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA
		}
	}

	addStamina(amount: number) {
		this.currentStamina = Math.clamp(this.currentStamina + amount, 0, PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA)
	}

	tryDropSunSoul(enemy: Enemy) {
		if (this.characterType === CharacterType.SolaraSun && this.binaryFlags.has('solar-supremacy')) {
			const roll = Math.random() <= SOLAR_SUPREMACY_DROP_CHANCE
			if (roll) {
				allocGroundPickup(GroundPickupConfigType.SunSoul, enemy.position.x, enemy.position.y, 0, 0)
			}
		}
	}

	setSpeedDraftingMultiplier(deltaTime: timeInSeconds) {
		const inZoneMult = this.isInSpeedBoostZone() ? 1 : -1
		// you could mess with these two numbers to adjust the feel too
		// e.g. a lower negative would make you decelerate faster

		const state = this.binaryFlagState['speed-drafting']

		state.timeInBoost = Math.clamp(state.timeInBoost + (deltaTime * inZoneMult), 0, MAX_BOOST_TIME)

		let newMultiplier = Math.log(state.timeInBoost) / 1//SOME_CONST
		// mess with the formula / SOME_CONST for feel!
		newMultiplier = Math.clamp(newMultiplier, MIN_BOOST_SPEED, MAX_BOOST_SPEED)

		if (newMultiplier >= MAX_BOOST_SPEED && inZoneMult !== -1) {
			// Once we hit top speed, just set the timer to max
			// so that our time at top speed is consistent, and not 
			// dependent on how long we were next to the enemy
			state.timeInBoost = MAX_BOOST_TIME
		}

		state.speedMult.update(newMultiplier)
	}

	isInSpeedBoostZone() {
		// using the pickup collider because it is bigger
		for (const cell of this.pickupColliderComponent.cells) {
			for (const entity of cell) {
				if (entity.owner.isEnemy && !entity.owner.isDead()) {
					const enemy = entity.owner as Enemy
					const distance = enemy.distanceToPlayer2
					const speedBoostDist = enemy.speedBoostRadius2
					if (distance <= speedBoostDist) {
						return true
					}
				}
			}
		}

		return false
	}

	applyHeadStart() {
		this.addXP(this.nextLevel);
	}
	
	updateIntelligenceDump() {
		if (!this.intDumpStatBuff) {
			this.intDumpStatBuff = this.stats.addStatBonus(StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, INTELLIGENCE_DUMP_STAT_LEVEL_DAMAGE_BONUS * (this.level - 1)) as StatBonus
		} else {
			this.intDumpStatBuff.update((this.level - 1) * INTELLIGENCE_DUMP_STAT_LEVEL_DAMAGE_BONUS)
		}
	}
}

function killstreakMapRange(current: number, outputMax: number) {
	return mapToRange(current, 0, 1000, 0, outputMax, true)
}
