import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner";
import { debugConfig } from "../utils/debug-config";
import { MapConfig, MapConfigRecord, MapNames, MapOption, mapNames, stringNameToMapOption } from "./world-data";

export class MapSystem {
    static getInstance(): MapSystem{
        if(!MapSystem.instance){
            MapSystem.instance = new MapSystem()
        }
        return this.instance
     }
   	private static instance: MapSystem

    mapType: MapOption = MapOption.Forest
	mapName: MapNames = mapNames.FOREST

    constructor(){
    }

    setMap(mapName: MapNames){
		let mapOption = stringNameToMapOption(mapName)
        
		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			if (debugConfig.enableOverrideMap) {
				this.mapType = debugConfig.overrideMap
				this.mapName = debugConfig.overrideMap
				return
			}

			const searchParams = new URLSearchParams(window.location.search)
			if (searchParams.has('map')) {
				this.mapType = searchParams.get('map') as MapOption
				this.mapName = this.mapType
				return
			}
		}
		this.mapType = mapOption
		this.mapName = mapName
		console.log(`Map: ${this.mapType}`)
	}

    getMapConfig(): MapConfig {
		return MapConfigRecord[this.mapType]
	}
}