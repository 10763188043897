// Used in: input-bindings.vue, controls.vue

export const keycodeToCharMixin = {
	computed: {},
	methods: {
		keyCodeToChar(code) {
			const formattedKey = code.replace('Key', '')
			if (/^[A-Z][a-z]+(?:[A-Z][a-z]*|\d*)+$/.test(formattedKey)) {
				return formattedKey.replace(/([a-z])([A-Z]|[A-Z][a-z])/g, '$1 $2')
			}
			return formattedKey
		},
	},
}
