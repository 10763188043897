import MersenneTwister from 'mersenne-twister'
import { Vector } from 'sat'
import { GameState } from '../engine/game-state'
import { DamageableEntityType } from '../entities/entity-interfaces'
import { DamagingGroundHazard } from '../entities/hazards/damaging-ground-hazard'
import { ElementalPoolType, ELEMENTAL_POOL_TRIGGER_RADIUS, ELEMENTAL_POOL_RADIUS_RATIO, ELEMENTAL_POOL_RADIUS_X, ELEMENTAL_POOL_RADIUS_Y, resetLavaPoolHazardStatListStats, resetPoisonPoolHazardStatListStats } from '../entities/hazards/elemental-pools-data'
import { GroundHazard } from '../entities/hazards/ground-hazard'
import { IcePoolHazard } from '../entities/hazards/ice-pool-hazard'
import { LavaPoolHazard } from '../entities/hazards/lava-pool-hazard'
import { PoisonPoolHazard } from '../entities/hazards/poison-pool-hazard'
import { Player } from '../entities/player'
import { Prop } from '../entities/prop'
import { propConfigs } from '../entities/prop-config'
import { PropModifier } from '../mutators/mutator-definitions'
import EntityStatList, { GlobalStatList } from '../stats/entity-stat-list'
import { debugConfig } from '../utils/debug-config'
import { getRandomPointInCircleRange, randomRange } from '../utils/math'
import { gameUnits } from '../utils/primitive-types'
import { ObjectPool } from '../utils/third-party/object-pool'
import WeightedList from '../utils/weighted-list'
import { AllWeaponTypes } from '../weapons/weapon-types'
import { GenerationType, VerticalScrollConfig, WORLD_DATA, MapOption, MapConfig } from './world-data'
import { MapSystem } from './map-system'
import { CAMERA_CLAMP_BUFFER, Camera } from '../engine/graphics/camera-logic'
import { RiggedSpineModel } from '../engine/graphics/spine-model'
import { AssetManager } from '../web/asset-manager'
import { Container } from 'pixi.js'
import { Renderer } from '../engine/graphics/renderer'
import { AnimationTrack } from '../spine-config/animation-track'
import playAnimation from '../engine/graphics/play-animation'
import { TundraIceHazard } from '../entities/hazards/tundra-ice'
import { MapSpecificHazardsTypes, mapHazardConfigRecord } from '../entities/hazards/map-specific-hazard-data'
import AISystem from '../entities/enemies/ai-system'
import { ENEMY_NAME } from '../entities/enemies/enemy-names'

const PROP_CULL_DISTANCE = 3_500
const WALL_HEIGHT = 1023
const NUM_WALLS = 4

const WALL_CULL_DIST = 2_200

export interface PropHolder {
	props: (Prop | GroundHazard)[]
	position: Vector
}

export type OnDestroyDestructibleCallback = (prop: Prop) => void

export class PropPlacer {

	private static instance: PropPlacer
	static getInstance() {
		if (!PropPlacer.instance) {
			PropPlacer.instance = new PropPlacer()
		}
		return PropPlacer.instance
	}
	static destroy() {
		const inst = PropPlacer.instance
		for (let i = 0; i < inst.allProps.length; ++i) {
			const prop = inst.allProps[i]
			prop.removeFromScene()
		}

		inst.elementalHazardStatLists.forEach((statList) => {
			GlobalStatList.removeChild(statList)
		})
		
		PropPlacer.instance = null
	}

	mapConfig: MapConfig

	bigPropChoices: WeightedList<string>
	soloPropChoices: WeightedList<string>
	accompanyChances: WeightedList<number>
	accompanyPropChoices: WeightedList<string>
	elementalPoolChoices: WeightedList<any>
	stampPropChoices:  WeightedList<string>
	mapSpecificHazardPropChoices: WeightedList<string>

	addElementalPools?: boolean
	addMapSpecificHazards?: boolean

	elementalHazardPools: Map<ElementalPoolType, ObjectPool> = new Map()
	elementalHazardStatLists: Map<ElementalPoolType, EntityStatList> = new Map()

	mapSpecificHazards:Map<MapSpecificHazardsTypes, ObjectPool> = new Map()

	onDestroyDestructibleCallbacks: OnDestroyDestructibleCallback[] = []

	player: Player

	topProps: PropHolder[]
	bottomProps: PropHolder[]
	leftProps: PropHolder[]
	rightProps: PropHolder[]

	allProps: Prop[]

	allPropHolders: PropHolder[]
	changePropGridSize: number
	changePropGridSize2x: number
	changePropGridOffset: number

	playerPastCellX: number
	playerPastCellY: number

	leftWallSmokes: RiggedSpineModel[]
	rightWallSmokes: RiggedSpineModel[]
	leftWallTiles: RiggedSpineModel[]
	rightWallTiles: RiggedSpineModel[]

	playerPastWallCell: number

	private _leftWallViewMin: number
	private _rightWallViewMin: number
	private _isLeftWallInScene: boolean
	private _isRightWallInScene: boolean

	constructor() {
	}

	setPropMapConfig(mapConfig){
		this.mapConfig = mapConfig
		this.setPropWeights()
	}

	setPropWeights(){
		this.bigPropChoices = new WeightedList(this.mapConfig.bigPropChances)
		this.soloPropChoices = new WeightedList(this.mapConfig.soloPropChances)
		this.accompanyChances = new WeightedList(this.mapConfig.bigPropAccompanyChances)
		this.accompanyPropChoices = new WeightedList(this.mapConfig.accompanimentProps)
		this.stampPropChoices = new WeightedList(this.mapConfig.groundStampChances)

		this.elementalPoolChoices = new WeightedList()
		this.mapSpecificHazardPropChoices = new WeightedList()
		if (!LavaPoolHazard.pool) {
			LavaPoolHazard.pool = new ObjectPool(() => new LavaPoolHazard(), {}, 1, 1)
			IcePoolHazard.pool = new ObjectPool(() => new IcePoolHazard(), {}, 1, 1)
			PoisonPoolHazard.pool = new ObjectPool(() => new PoisonPoolHazard(), {}, 1, 1)
			TundraIceHazard.pool = new ObjectPool(() => new TundraIceHazard(), {}, 1, 1)
		}
		this.elementalHazardPools.set(ElementalPoolType.Lava, LavaPoolHazard.pool)
		this.elementalHazardPools.set(ElementalPoolType.Ice, IcePoolHazard.pool)
		this.elementalHazardPools.set(ElementalPoolType.Poison, PoisonPoolHazard.pool)

		this.mapSpecificHazards.set(MapSpecificHazardsTypes.TundraIce, TundraIceHazard.pool)

		this.elementalHazardStatLists.set(ElementalPoolType.Lava, new EntityStatList(resetLavaPoolHazardStatListStats, GlobalStatList))
		this.elementalHazardStatLists.set(ElementalPoolType.Poison, new EntityStatList(resetPoisonPoolHazardStatListStats, GlobalStatList))
	}

	addMutator(mutatorName: PropModifier) {		
		switch(mutatorName) {
			case 'destructive-tendencies':
				const nullIndex = this.mapConfig.bigPropIndexOfNull
				this.bigPropChoices.weights[nullIndex] = Math.round(this.bigPropChoices.weights[nullIndex] / 3)
				break
			case 'elemental-maelstrom':
				this.addElementalPools = true
				this.elementalPoolChoices.pushMany([
					[null, 490],
					[ElementalPoolType.Ice, 40],
					[ElementalPoolType.Lava, 6],
					[ElementalPoolType.Poison, 14],
				])
				
				break
			case 'floor-is-lava':
				this.addElementalPools = true
				this.elementalPoolChoices.pushMany([
					[null, 920],
					[ElementalPoolType.Lava, 80],
				])
				break
			case 'insect-fever':
				const propSpawnRate = 2.0
				const destPropNullIndex = this.mapConfig.bigPropIndexOfNull
				this.bigPropChoices.weights[destPropNullIndex] = Math.round(this.bigPropChoices.weights[destPropNullIndex] / propSpawnRate)
				
				const numInsects =  Math.ceil(randomRange(10, 15))
				this.onDestroyDestructibleCallbacks.push((prop: Prop) => {
					const pos = prop.position.clone()
					const offset = 100
					AISystem.getInstance().spawnEnemiesInRectangle(ENEMY_NAME.MOSQUITO, numInsects, pos.x - offset, pos.y - offset, pos.x + offset, pos.y+ offset)
				})
				break
		}
	}

	addMapSpecificHazardProps(){
		if(this.mapConfig.statusProps){
			this.addMapSpecificHazards = true
			this.mapSpecificHazardPropChoices.pushMany(this.mapConfig.statusProps)
		}
	}

	placeHazardAtPosition(poolType: ElementalPoolType, position: Vector, radius: gameUnits, lifetime: number = 0, damagePlayer: boolean = false): GroundHazard {
		const poolPool = this.elementalHazardPools.get(poolType)
		const statList = this.elementalHazardStatLists.get(poolType)
		const hazard: GroundHazard = poolPool.alloc({
			radiusX: radius,
			radiusY: radius * ELEMENTAL_POOL_RADIUS_RATIO, // assumes Y rad is smaller than X
			triggerRadius: radius,
			position,
			statList,
			damageTargetType: damagePlayer ? DamageableEntityType.PlayerAndEnemy : DamageableEntityType.Enemy,
			permanent: !Boolean(lifetime),
			lifeTime: lifetime,
			weaponType: AllWeaponTypes.WorldHazard,
			animateIn: true
		})
		return hazard
	}

	placeProps(seed: number) {
		this.allPropHolders = []
		for (let i = 0; i < 4; ++i) {
			this.allPropHolders[i] = {
				props: [],
				position: new Vector()
			}
		}
		this.rightProps = [this.allPropHolders[0], this.allPropHolders[1]]
		this.leftProps = [this.allPropHolders[2], this.allPropHolders[3]]
		this.topProps = [this.allPropHolders[0], this.allPropHolders[2]]
		this.bottomProps = [this.allPropHolders[1], this.allPropHolders[3]]
		this.allProps = []

		this.changePropGridSize2x = WORLD_DATA.infiniteWorldDimension + this.mapConfig.propCellPadding * 2 // "WORLD_DATA.propCellPadding * 2" not sure if this is correct, or just coincidentally giving the number I actually need here. But it works
		this.changePropGridSize = this.changePropGridSize2x / 2
		this.changePropGridOffset = this.changePropGridSize2x / 4

		this.player = GameState.player

		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			if (!debugConfig.props) {
				return
			}
		}

		const mt = new MersenneTwister(seed)
		
		const accompanyMaxRange = this.mapConfig.accompanimentMaxPropRange
	
		let cellDimension = this.mapConfig.propCellDimension
		let cellPadding = this.mapConfig.propCellPadding

		let worldXDimension: number
		let worldYDimension: number

		if (this.mapConfig.generationConfig.type === GenerationType.Infinite) {
			worldXDimension = WORLD_DATA.infiniteWorldDimension
			worldYDimension = WORLD_DATA.infiniteWorldDimension
		} else {
			// it's vertical shape (mountain)
			worldXDimension = this.mapConfig.generationConfig.width
			worldYDimension = WORLD_DATA.infiniteWorldDimension

			Camera.getInstance().setCameraClamp(0 + CAMERA_CLAMP_BUFFER, worldXDimension - CAMERA_CLAMP_BUFFER, GameState.player.y + 500)

			const wallAsset = AssetManager.getInstance().getAssetByName(this.mapConfig.generationConfig.wallAssetName)
			const totalHeight = NUM_WALLS * WALL_HEIGHT
			const playerY = GameState.player.position.y 
			const baseOffset = playerY -(totalHeight / 2) + 800
			const leftXOffset = -600
			const rightXOffset = worldXDimension + 600

			this._leftWallViewMin = WALL_CULL_DIST
			this._rightWallViewMin = worldXDimension - WALL_CULL_DIST

			this.leftWallSmokes = []
			this.leftWallTiles = []
			this.rightWallSmokes = []
			this.rightWallTiles = []

			for (let i = 0; i < NUM_WALLS; ++i) {
				const yOffset = (i * WALL_HEIGHT) + baseOffset
				const leftSmoke = this.makeSmokeGfx(wallAsset, true)
				leftSmoke.y = yOffset
				leftSmoke.scale.x = -1
				leftSmoke.x += leftXOffset

				const leftTile = this.makeTileGfx(wallAsset)
				leftTile.y = yOffset
				leftTile.scale.x = -1
				leftTile.zIndex = -500
				leftTile.x += leftXOffset

				this.leftWallSmokes.push(leftSmoke)
				this.leftWallTiles.push(leftTile)

				const rightSmoke = this.makeSmokeGfx(wallAsset, false)
				rightSmoke.y = yOffset
				rightSmoke.x += rightXOffset

				const rightTile = this.makeTileGfx(wallAsset)
				rightTile.y = yOffset
				rightTile.zIndex = -500
				rightTile.x += rightXOffset

				this.rightWallSmokes.push(rightSmoke)
				this.rightWallTiles.push(rightTile)
			}

			this.playerPastWallCell = Math.ceil(playerY / WALL_HEIGHT)
		}
		
		for (let x = 0; x < worldXDimension; x += cellDimension + cellPadding) {
			for (let y = 0; y < worldYDimension; y += cellDimension + cellPadding) {
				const bigPropAssetName = this.bigPropChoices.pickRandom(mt.random()).value[0]
				if (bigPropAssetName !== null) {
					const xPos = randomRange(x, x + cellDimension, mt)
					const yPos = randomRange(y, y  + cellDimension, mt)
	
					let propOwner: PropHolder = this.getPropHolder(x, y)

					const prop = new Prop(bigPropAssetName, new Vector(xPos, yPos))
					GameState.addEntity(prop)
					propOwner.props.push(prop)
					this.allProps.push(prop)
					prop.propHolder = propOwner

					const numJoiningProps = this.accompanyChances.pickRandom(mt.random()).value[0]
					if (numJoiningProps > 0) {
						const propConfig = propConfigs[bigPropAssetName]
						const zOff = propConfig.zOffset || 0 // I'm using this as a 'Y' off to the center of the prop; since they are the same thing
						const minJoinDist = propConfig.accompanyMinRange || 0

						for (let i =0; i < numJoiningProps; ++i) {
							const joiningAsset = this.accompanyPropChoices.pickRandom(mt.random()).value[0]
							const joinPos = getRandomPointInCircleRange(xPos, yPos + zOff, minJoinDist, minJoinDist + accompanyMaxRange, mt)
							propOwner = this.getPropHolder(x, y)
							const joiningProp = new Prop(joiningAsset, joinPos)
							GameState.addEntity(joiningProp)
							propOwner.props.push(joiningProp)
							this.allProps.push(joiningProp)
							prop.propHolder = propOwner
						}
					}
				} else {
					const soloAssetName = this.soloPropChoices.pickRandom(mt.random()).value[0]
					if (soloAssetName !== null) {
						const xPos = randomRange(x, x + cellDimension, mt)
						const yPos = randomRange(y, y  + cellDimension, mt)
		
						let propOwner: PropHolder = this.getPropHolder(x, y)

						const prop = new Prop(soloAssetName, new Vector(xPos, yPos))
						GameState.addEntity(prop)
						propOwner.props.push(prop)
						this.allProps.push(prop)
						prop.propHolder = propOwner
					}
				}

				if(this.addMapSpecificHazards && this.mapSpecificHazardPropChoices?.length){
					const poolType = this.mapSpecificHazardPropChoices.pickRandom(mt.random()).value[0] as MapSpecificHazardsTypes
					const test = MapSpecificHazardsTypes.TundraIce
					if (poolType !== null) {
						const mapHazardConfig = mapHazardConfigRecord[poolType]
						const xPos = randomRange(x, x + cellDimension, mt)
						const yPos = randomRange(y, y  + cellDimension, mt)

						const poolPool = this.mapSpecificHazards.get(poolType)

						const hazard: GroundHazard = poolPool.alloc({
							radiusX: mapHazardConfig.hazardRadiusX,
							radiusY: mapHazardConfig.hazardRadiusY,
							triggerRadius: mapHazardConfig.hazardTriggerRadius,
							position: { x: xPos, y: yPos },
							damageTargetType: DamageableEntityType.PlayerAndEnemy,
							permanent: true,
							lifeTime: 0,
							weaponType: AllWeaponTypes.WorldHazard
							
						})

						let propOwner: PropHolder = this.getPropHolder(x, y)
						propOwner.props.push(hazard)
				}
			}

				if(this.addElementalPools && this.elementalPoolChoices?.length) {
					const poolType = this.elementalPoolChoices.pickRandom(mt.random()).value[0]
					if (poolType !== null) {
						const xPos = randomRange(x, x + cellDimension, mt)
						const yPos = randomRange(y, y  + cellDimension, mt)

						const poolPool = this.elementalHazardPools.get(poolType)
						const statList = this.elementalHazardStatLists.get(poolType)

						const hazard: GroundHazard = poolPool.alloc({
							radiusX: ELEMENTAL_POOL_RADIUS_X,
							radiusY: ELEMENTAL_POOL_RADIUS_Y,
							triggerRadius: ELEMENTAL_POOL_TRIGGER_RADIUS,
							position: { x: xPos, y: yPos },
							statList,
							damageTargetType: DamageableEntityType.PlayerAndEnemy,
							permanent: true,
							lifeTime: 0,
							weaponType: AllWeaponTypes.WorldHazard
						})

						let propOwner: PropHolder = this.getPropHolder(x, y)
						propOwner.props.push(hazard)
					}
				}
			}
		}

		// Ground stamps (they use different cell sizes)
		cellDimension = this.mapConfig.stampCellDimension
		cellPadding = this.mapConfig.stampCellPadding

		for (let x = 0; x < worldXDimension; x += cellDimension + cellPadding) {
			for (let y = 0; y < worldYDimension; y += cellDimension + cellPadding) {
				const groundStamp = this.stampPropChoices.pickRandom(mt.random()).value[0]
				if (groundStamp !== null) {
					const xPos = randomRange(x, x + cellDimension, mt)
					const yPos = randomRange(y, y  + cellDimension, mt)
	
					let propOwner: PropHolder = this.getPropHolder(x, y)

					const prop = new Prop(groundStamp, new Vector(xPos, yPos))
					GameState.addEntity(prop)
					propOwner.props.push(prop)
					this.allProps.push(prop)
					prop.propHolder = propOwner
				}
			}
		}

		const playerPos = this.player.position
		this.playerPastCellX = Math.floor((playerPos.x + this.changePropGridOffset) / this.changePropGridSize)
		this.playerPastCellY = Math.floor((playerPos.y + this.changePropGridOffset) / this.changePropGridSize)
	}

	update(delta: number) {
		const playerPos = this.player.position

		if (this.leftWallSmokes) {
			this.updateVerticalWalls(playerPos)
		}

		// what cell is the player in?
		const cellX = Math.floor((playerPos.x + this.changePropGridOffset) / this.changePropGridSize)
		const cellY = Math.floor((playerPos.y + this.changePropGridOffset) / this.changePropGridSize)

		// is that different from last time?
		if(cellX !== this.playerPastCellX) {
			if(cellX > this.playerPastCellX) {
				this.leftProps[0].position.x += this.changePropGridSize2x
				this.leftProps[1].position.x += this.changePropGridSize2x

				this.updatePropPositions(this.leftProps[0])
				this.updatePropPositions(this.leftProps[1])

				// console.log(`PROPS: left to right`)
			} else {
				this.rightProps[0].position.x -= this.changePropGridSize2x
				this.rightProps[1].position.x -= this.changePropGridSize2x

				this.updatePropPositions(this.rightProps[0])
				this.updatePropPositions(this.rightProps[1])

				// console.log(`PROPS: right to left`)
			}

			let tempHolder = this.rightProps
			this.rightProps = this.leftProps
			this.leftProps = tempHolder

			this.playerPastCellX = cellX
		}

		if (cellY !== this.playerPastCellY) {
			if(cellY < this.playerPastCellY) {
				this.bottomProps[0].position.y -= this.changePropGridSize2x
				this.bottomProps[1].position.y -= this.changePropGridSize2x

				this.updatePropPositions(this.bottomProps[0])
				this.updatePropPositions(this.bottomProps[1])

				// console.log(`PROPS: bottom to top`)
			} else {
				this.topProps[0].position.y += this.changePropGridSize2x
				this.topProps[1].position.y += this.changePropGridSize2x

				this.updatePropPositions(this.topProps[0])
				this.updatePropPositions(this.topProps[1])

				// console.log(`PROPS: top to bottom`)
			}

			let tempHolder = this.topProps
			this.topProps = this.bottomProps
			this.bottomProps = tempHolder

			this.playerPastCellY = cellY
		}

		const playerX = this.player.x
		const playerY = this.player.y

		// @TODO does not include ground hazards!
		// this dumb culling is faster by 1-3 ms on Callum PC then just rendering/batching them all each frame
		for (let i = 0; i < this.allProps.length; ++i) {
			const prop = this.allProps[i]
			const xOff = prop.position.x - playerX
			const xDiff = Math.abs(xOff)
			if (xDiff >= PROP_CULL_DISTANCE) {
				prop.removeFromScene()
			} else {
				const yOff = prop.position.y - playerY
				const yDiff = Math.abs(yOff)
				if (yDiff >= PROP_CULL_DISTANCE) {
					prop.removeFromScene()
				} else {
					prop.distToPlayerX = xOff
					prop.distToPlayerY = yOff
					prop.addToScene()
				}
			}
		}
	}

	removeProp(prop: Prop) {
		this.allProps.remove(prop)
		prop.propHolder.props.remove(prop)
		prop.propHolder = undefined
	}

	getRandomValidPositionInWorld(minDistanceToPlayer: number, maxDistanceToPlayer: number, maxDistToEdgeOfWorld: number, outVec?: Vector, onlyNorth?: boolean) {
		if (this.mapConfig.generationConfig.type === GenerationType.Infinite) {
			return getRandomPointInCircleRange(GameState.player.x, GameState.player.y, minDistanceToPlayer, maxDistanceToPlayer, undefined, outVec)
		} else {
			const verticalConfig = this.mapConfig.generationConfig as VerticalScrollConfig
			
			// x has to be between 0 + maxDistToEdgeOfWorld and mapWidth - maxDistToEdgeOfWorld
			const mapWidth = verticalConfig.width
			// y has to be maxY or less
			const worldMaxY = WORLD_DATA.maxYInVerticalMap

			let randomX: number
			if (maxDistToEdgeOfWorld >= mapWidth) {
				// no valid location but we'll just place in the middle then
				randomX = mapWidth / 2
			} else {
				randomX = Math.getRandomFloat(maxDistToEdgeOfWorld, mapWidth - maxDistToEdgeOfWorld)
			}

			// this part confused me because all of our coordinates are upside down so I'm not confident in it
			let randomY: number
			const playerY = GameState.player.y
			const yGenerationRange = maxDistanceToPlayer - minDistanceToPlayer
			
			if (playerY + minDistanceToPlayer > worldMaxY || onlyNorth) {
				// we can't place higher on Y value (below player)
				randomY = playerY - minDistanceToPlayer - Math.getRandomFloat(0, yGenerationRange)
			} else {

				const minDistToWorldMaxDist = worldMaxY - (playerY + minDistanceToPlayer)
				// we can place below or above player
				const rangeToMax = Math.min(minDistToWorldMaxDist, yGenerationRange) 
				const totalRange = rangeToMax + yGenerationRange // above and below distances combined
				const spotOnRange = Math.getRandomFloat(0, totalRange)

				if (spotOnRange <= rangeToMax) {
					// place on higher Y value
					randomY = minDistanceToPlayer + spotOnRange
				} else {
					// place on lower Y value
					randomY = -minDistanceToPlayer - spotOnRange + rangeToMax
				}
				randomY += playerY
			}

			// technically can be more than maxDistance away, but don't really care b/c the world is so thin

			if (outVec) {
				outVec.x = randomX
				outVec.y = randomY
				return outVec
			}
			return new Vector(randomX, randomY)
		}
	}

	private updatePropPositions(propHolder: PropHolder) {
		const offset = propHolder.position
		for (let i =0 ; i < propHolder.props.length; ++i) {
			propHolder.props[i].setOffset(offset)
		}
	}

	private getPropHolder(x: number, y: number): PropHolder {
		if (x > WORLD_DATA.infiniteWorldDimension / 2) {
			// right
			if (y < WORLD_DATA.infiniteWorldDimension / 2) {
				// top
				return this.allPropHolders[0]
			} else {
				// bottom
				return this.allPropHolders[1]
			}
		} else {
			// left
			if (y < WORLD_DATA.infiniteWorldDimension / 2) {
				// top
				return this.allPropHolders[2]
			} else {
				// bottom
				return this.allPropHolders[3]
			}
		}
	}

	private makeSmokeGfx(asset, isLeft: boolean): RiggedSpineModel {
		const smokeOffset = 300 * (isLeft ? -1 : 1)
		const smoke = new RiggedSpineModel(asset.spineData)
		smoke.skeleton.setSkinByName('default')
		smoke.skeleton.setToSetupPose()
		smoke.x = smokeOffset
		playAnimation(smoke, AnimationTrack.BLIZZARD_CLOUDS, undefined, 0.5)
		smoke.update(0)

		return smoke
	}

	private makeTileGfx(asset): RiggedSpineModel {
		const tile = new RiggedSpineModel(asset.spineData)
		tile.skeleton.setSkinByName('default')
		tile.skeleton.setToSetupPose()
		playAnimation(tile, AnimationTrack.BLIZZARD_TILE)
		tile.update(0)

		return tile
	}

	private updateVerticalWalls(playerPos: Vector) {
		const playerWallCell = Math.ceil(playerPos.y / WALL_HEIGHT)
		if (this.playerPastWallCell > playerWallCell) {
			this.playerPastWallCell = playerWallCell

			// left
			let movingWall = this.leftWallSmokes.pop()				
			const newWallYPos = this.leftWallSmokes[0].y - WALL_HEIGHT

			movingWall.y = newWallYPos
			this.leftWallSmokes.unshift(movingWall)

			let movingTile = this.leftWallTiles.pop()
			movingTile.y = newWallYPos
			this.leftWallTiles.unshift(movingTile)

			// right
			movingWall = this.rightWallSmokes.pop()				

			movingWall.y = newWallYPos
			this.rightWallSmokes.unshift(movingWall)

			movingTile = this.rightWallTiles.pop()
			movingTile.y = newWallYPos
			this.rightWallTiles.unshift(movingTile)

		} else if (this.playerPastWallCell < playerWallCell) {
			this.playerPastWallCell = playerWallCell
			
			// left
			let movingWall = this.leftWallSmokes.shift()				
			const newWallYPos = this.leftWallSmokes[this.leftWallSmokes.length -1].y + WALL_HEIGHT

			movingWall.y = newWallYPos
			this.leftWallSmokes.push(movingWall)

			let movingTile = this.leftWallTiles.shift()				
			movingTile.y = newWallYPos
			this.leftWallTiles.push(movingTile)

			// right
			movingWall = this.rightWallSmokes.shift()				

			movingWall.y = newWallYPos
			this.rightWallSmokes.push(movingWall)

			movingTile = this.rightWallTiles.shift()				
			movingTile.y = newWallYPos
			this.rightWallTiles.push(movingTile)
		}

		// left/right culling
		if (this.player.x <= this._leftWallViewMin) {
			if (!this._isLeftWallInScene) {
				this._isLeftWallInScene = true
				this.addRiggedModelsToFG(this.leftWallSmokes)
				this.addRiggedModelsToFG(this.leftWallTiles)
			}
		} else if (this._isLeftWallInScene) {
			this._isLeftWallInScene = false
			this.removeRiggedModelsFromFG(this.leftWallSmokes)
			this.removeRiggedModelsFromFG(this.leftWallTiles)
		}

		if (this.player.x > this._rightWallViewMin) {
			if (!this._isRightWallInScene) {
				this._isRightWallInScene = true
				this.addRiggedModelsToFG(this.rightWallSmokes)
				this.addRiggedModelsToFG(this.rightWallTiles)
			}
		} else if (this._isRightWallInScene) {
			this._isRightWallInScene = false
			this.removeRiggedModelsFromFG(this.rightWallSmokes)
			this.removeRiggedModelsFromFG(this.rightWallTiles)
		}
	}

	private addRiggedModelsToFG(models: RiggedSpineModel[]) {
		const fgRenderer = Renderer.getInstance().fgRenderer
		for (let i = 0; i < models.length; ++i) {
			fgRenderer.addDisplayObjectToScene(models[i])
		}
	}

	private removeRiggedModelsFromFG(models: RiggedSpineModel[]) {
		const fgRenderer = Renderer.getInstance().fgRenderer
		for (let i = 0; i < models.length; ++i) {
			fgRenderer.removeFromScene(models[i])
		}
	}
}
