import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, FightingBehaviours, ShotLeadPrecision, VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const eyeBat2: VariantAI = {
    extends: ENEMY_NAME.EYEBAT_1,
    name: ENEMY_NAME.EYEBAT_2,
    appearance: {
        asset: SpineDataName.EYEBAT_1,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.5, // base eyebat has a scale of 1.5
    },
	baseDamage: 0,
	attackSize: 30,
	projectileCount: 3,
	projectileSpreadAngle: 90,
	projectileLifeSpan: 3,
	chillChance: 1,
	chillPotency: 0.5, // currently doesn't do anything
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_ENEMY0,
				cooldownDef: {
					// name: "enemyProjectile",
					maxAmmo: 1,
					cooldownInterval: 15000,
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 600,
			movementMinDistance: 400,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export const eyeBat3: VariantAI = {
	extends: ENEMY_NAME.EYEBAT_1,
	name: ENEMY_NAME.EYEBAT_3,
	appearance: {
		asset: SpineDataName.EYEBAT_2,
		spriteSheet: true,
		skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.33, // this is actually smaller than the base eyebat, who has a scale of 1.5
	},
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.88,
	maxHealth: 1.5,
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_ENEMY0,
				cooldownDef: {
					// name: "enemyProjectile",
					maxAmmo: 1,
					cooldownInterval: 10_000,
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 800,
			movementMinDistance: 500,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export const eyeBatChoreo: VariantAI = {
	extends: ENEMY_NAME.EYEBAT_1,
	name: ENEMY_NAME.EYEBAT_CHOREO,
	appearance: {
		asset: SpineDataName.EYEBAT_1,
		spriteSheet: true,
		skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.5
	},
	movementSpeed: 550,
	projectileSpeed: 650,
	layer: CollisionLayerBits.FlyingEnemy,
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_ENEMY0,
				cooldownDef: {
					// name: "enemyProjectile",
					maxAmmo: 1,
					cooldownInterval: 10_000,
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 1000,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 800,
			movementMinDistance: 500,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}