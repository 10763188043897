<template>
	<div id="paused">
		<div class="upgrade-container">
			<div class="upgrades" :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': 'top left' }">
				<Divider :label="'Upgrades'" :flip-horizontal="false" :text-alignment="'left'" :font-size="'large'"></Divider>
				<div class="upgrade-list" >

					<TransitionGroup name="slide-left">
						<ItemUpdated
								v-for="item in upgradesActive"
								:key="item"
								:item="item"
								:small-icons="true"
								:tooltip-enabled="true"
								:on-click-prop="()=>{}"
								:selected="false"
								:hovered="false"
								:disable-circle="false"
							></ItemUpdated>
					</TransitionGroup>
				</div>
			</div>
		</div>
		<div class="settings-container" :style="{height: `${currentSettings.height}px`}">
			<Settings :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': 'center top', height: `${currentSettings.height}px` }"></Settings>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapState } from 'vuex'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import Settings from '../view/settings.vue'
import Currency from '../ui-components/currency/currency.vue'
import Divider from '../ui-components/dividers-headers/divider.vue'


export default {
	name: 'Paused',
	components: {
		ItemUpdated,
		Settings,
		Currency,
		Divider
	},
	data() {
		return {}
	},
	props: {
	},
	computed: {
		...mapGetters('paused', ['upgradesActive', 'getCommonCurrency', 'getRareCurrency']),
		...mapState('paused', ['upgrades', 'selectedUpgrade', 'hoveredUpgrade']),
		...mapState('uiScaling', ['scale', 'newScale', 'aspectRatio', 'responsiveScale']),
		...mapState('settings', ['currentSettings']),

		pointerEventsStyle() {
			if (this.upgradesActive.length === 0) {
				return 'pointer-events: none;'
			}
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		...mapActions('paused', ['upgradeOver']),
		upgradeIsSelected(upgradeName) {
			if (this.selectedUpgrade) {
				return upgradeName === this.selectedUpgrade.icon
			}
		},
		upgradeIsHovered(upgradeName) {
			if (this.hoveredUpgrade && this.selectedUpgrade !== this.hoveredUpgrade) {
				return upgradeName === this.hoveredUpgrade.icon
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#paused {
    position: relative;
    z-index: 9999999;
	.upgrade-desc {
		position: absolute;
		justify-content: left;
		left: 40%;
		top: 50%;
	}
	.upgrade-container {
		position: absolute;
		.upgrades {
			height: 100vh;
			width: 200px;
			display: flex;
			    flex-direction: column;
			position: relative;
			.upgrade-list {
				pointer-events: all;
				height: 904px;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-content: flex-start;
					:deep(.item) {
						flex: 0 0 82px;
						display: flex;
						align-items: center;
						margin: 0px 5px;
					}
				
			}
		}
	}
	.settings-container{
		z-index: 9999999;

		// width: 100%;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		pointer-events: all;
	}

	.controls {
		position: absolute;
		right: 2%;
		bottom: 9.5%;
		justify-content: right;
		display: flex;
		flex-direction: column;

		div {
			color: @offWhite;
			text-align: right;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 35px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
	}
}

.slide-left-leave-active {
	transition: all 0.25s ease-out;
}
.slide-left-enter-active {
	transition: all 0.05s ease-out 15s;
}

.slide-left-enter-from {
	opacity: 0;
	transform: translateX(-30px);
}

.slide-left-leave-to {
	opacity: 0;
	transform: translateX(-30px);
}
</style>
