import { Vector } from "sat"
import { Enemy } from "../../../entities/enemies/enemy"
import { Player } from "../../../entities/player"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { PlayerProjectile, ProjectileInitialParams } from "../../../projectiles/projectile"
import EntityStatList from "../../../stats/entity-stat-list"
import { PassiveSkill } from "../../passive-skill"
import { AllWeaponTypes } from "../../weapon-types"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { ResourceType } from "../../weapon-definitions"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { InGameTime } from "../../../utils/time"
import { allocGroundPickup } from "../../../entities/pickups/ground-pickup"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { TrajectoryModPreset, TrajectoryModPresets, TrajectoryModType } from "../../../projectiles/trajectory-presets"
import { CharacterType } from "../../../game-data/characters"
import { GameState } from "../../../engine/game-state"
import { BALM_OF_NIGHT_ACTIVE_SECONDS, MOON_SOUL_DROP_RADIUS } from "../skill/solara-skill-weapon"
import { GroundPickupConfigType } from "../../../entities/pickups/ground-pickup-types"

export class SolaraPassiveSkill extends PassiveSkill {
	weaponType: AllWeaponTypes = AllWeaponTypes.SolaraPassive

	private moonSoulParams: ProjectileInitialParams
	private moonSoulStatList: EntityStatList

	onEnemyKilled(enemy: Enemy): void {
		this.tryMoonSoulDrop(enemy)
		this.tryBalmOfNight(enemy)
	}

	onCreate(player: Player): void {
		this.moonSoulStatList = new EntityStatList(this.resetMoonSoulParamsStats, this.statList)
		this.moonSoulParams = {
			owningEntityId: player.nid,
			position: new Vector(),
			speed: 0,
			aimAngleInRads: 0,
			radius: 0,
			trajectoryMods: [],
			collisionLayer: CollisionLayerBits.PlayerProjectile,
			statList: this.moonSoulStatList,
			damageScale: 1,
			resourceType: ResourceType.NONE,
			player: this.player,
			weaponType: this.weaponType,
			effectType: ParticleEffectType.PROJECTILE_MOON_SOUL,
		}
	}

	onShot(): void {
	}

	onEnemyHit(enemy: Enemy, damageDealt: number): void {
	}

	update(delta: number): void {
	}

	onPowerUpPickedUp() {
		Buff.apply(BuffIdentifier.SunSoul, this.player, this.player)
	}

	private tryMoonSoulDrop(enemy: Enemy) {
		if (this.player.characterType === CharacterType.SolaraMoon){
			const dist = enemy.distanceToPlayer2
			if (dist <= MOON_SOUL_DROP_RADIUS ** 2 && this.player.binaryFlags.has('quietly-into-the-night')){
				this.moonSoulParams.radius = this.moonSoulStatList.getStat(StatType.attackSize)
				this.moonSoulParams.position.copy(enemy.position)
				this.moonSoulStatList._actualStatValues.baseDamage = enemy.maxHealth * 0.4
				if (this.player.binaryFlags.has('lunar-lady') || this.player.binaryFlags.has('eminence-of-equinox')) {
					this.moonSoulParams.speed = this.player.primaryWeapon.statList['projectileSpeed'] / 2
					this.moonSoulParams.trajectoryMods = [TrajectoryModPresets.get(TrajectoryModPreset.HomingInstant)]
				}
				PlayerProjectile.objectPool.alloc(this.moonSoulParams)
			}
		}
	}

	private tryBalmOfNight(enemy: Enemy) {
		if (this.player.binaryFlags.has('balm-of-the-night')) {
			const state = this.player.binaryFlagState['balm-of-the-night']
			const now = InGameTime.timeElapsedInSeconds
			if (now - state.timeSinceTransfrom <= BALM_OF_NIGHT_ACTIVE_SECONDS && !state.heartDropped) {
				allocGroundPickup(GroundPickupConfigType.HealingLarge, enemy.position.x, enemy.position.y, 0, 0)
				state.heartDropped = true
			}
		}
	}

	resetStatsFunction(statList: EntityStatList): void {
		defaultStatAttribute(statList)
	}

	private resetMoonSoulParamsStats(stats: EntityStatList) {
		defaultStatAttribute(stats)
		stats._actualStatValues.baseDamage = 0
		stats._actualStatValues.projectileSpeed = 0
		stats._actualStatValues.projectileLifeSpan = 3
		stats._actualStatValues.projectileSpreadAngle = 0

		stats._actualStatValues.attackPierceCount = 0
		stats._actualStatValues.projectileChainCount = 0
		stats._actualStatValues.attackSize = 15
		stats._actualStatValues.projectileCount = 1
}
}