<template>
	<div class="progress-bar-wrapper">
		<div id="inner-bar" class="inner-bar">
			<div class="progress-bar-inner" :style="{ width: Math.clamp((value / maxValue) * 100, 0, 100) + '%',  left: xpBarPosition + 'px' }"></div>
		</div>
		<div class="inner-text">
			<div :lang="this.$i18n.locale" class="level-text">{{$t('progress_bar.level')}} {{ level }}</div>
		</div>
	</div>
</template>

<script lang="ts">
import { method } from 'lodash'
// PROP-ONLY COMPONENT!
// This component is not intended to pluck state directly from Vuex, unless it is related to
// screen size, layout, rendering, or other device-specific/responsive concerns.

export default {
	name: 'XpBar',
	components: {},
	data() {
		return {
			xpBarPosition: -50
		}
	},
	watch:{
		value(newValue, oldValue){
			this.innerBarStartingPosition(newValue)
		}
	},
	props: {
		maxValue: {
			type: Number,
			required: false,
			default: 1,
		},
		value: {
			type: Number,
			required: true,
		},
		level:{
			type: Number,
			required: true
		}
	},
	computed:{
	},
	methods:{
		innerBarStartingPosition(newValue){
			if( newValue > 0){
				this.xpBarPosition = 0
			} else {
				this.xpBarPosition = -50
			}
		}
	}
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.progress-bar-wrapper {
	position: relative;
	background-color: @darkBlue;
	width: 100%;
	height: 60px;
	border-radius: 4px;
	.inner-bar, .inner-text  {
		position: absolute;
		width: calc(100% - 10px);
		margin: 0px 5px;
		height: 60px;
		display: flex;
		align-items: center;

		overflow: hidden;

		.progress-bar-inner {
			transition: all 200ms ease-in;
			position: relative;
			background-color: @green;
			width: 0%;
			height: 50px;
			margin: 0px;
			left:-50px;
			&::after{
				content: ' ';
				position: absolute;
				right: -50px;
				border-style: solid;
				border-width: 50px 0 0 50px;
				border-color: transparent transparent transparent @green;
			}
		}
	}

	.inner-text {
		justify-content: center;
		.level-text {
			color: @offWhite;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 40px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
	}
}
</style>
