import { UI } from "../ui/ui"

const storagePrefix = 'hotcakes_alpha_v1:'

export const LAST_LOGGED_IN_CLIENT_KEY = 'last-logged-in-'

export const saveToLocalStorage = (k: string, v: string) => {
	if (UI.getInstance().store.getters['ui/cookiesRejected']) {
		return
	}

	return localStorage.setItem(storagePrefix + k, v)
}

export const  loadFromLocalStorage = (k: string) => {
	const valueFoundInLocalStorage = localStorage.getItem(storagePrefix + k)
	return valueFoundInLocalStorage === null ? undefined : valueFoundInLocalStorage
}

export const removeFromLocalStorage = (k: string) => {
	return localStorage.removeItem(storagePrefix + k)
}

export function saveToSessionStorage(k: string, v: string) {
	sessionStorage.setItem(storagePrefix + k, v)
} 

export function loadFromSessionStorage(k: string) {
	const valueFoundInSessionStorage = sessionStorage.getItem(storagePrefix + k)
	return valueFoundInSessionStorage === null ? undefined : valueFoundInSessionStorage
}

export function removeFromSessionStorage(k: string) {
	return sessionStorage.removeItem(storagePrefix + k)
}