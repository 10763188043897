import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, FightingBehaviours, ShotLeadPrecision, VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const blimpie2: VariantAI = {
	extends: ENEMY_NAME.BLIMPIE,
	name: ENEMY_NAME.BLIMPIE_2,
	appearance: {
		asset: SpineDataName.BLIMPIE_2,
		spriteSheet: true,
		skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.1
	},
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.7,
	chillChance: 1,
	chillPotency: 0.5, // Does nothing yet
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 100,
			movementMinDistance: 0,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.EXPLODE,
			explosionRadius: 375,
			explosionDamage: 0.33,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export const blimpie3: VariantAI = {
	extends: ENEMY_NAME.BLIMPIE,
	name: ENEMY_NAME.BLIMPIE_3,
	appearance: {
		asset: SpineDataName.BLIMPIE_3,
		spriteSheet: true,
		skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.1,
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.EXPLODE_ON_CONTACT,
			},
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 100,
			movementMinDistance: 0,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.EXPLODE,
			explosionRadius: 240,
			explosionDamage: 1.0,
			corpseTimeoutInSeconds: 1,
		},
	},
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.05,
	maxHealth: 1.5
}