import { over } from "lodash"
import { Container, Graphics, Sprite } from "pixi.js"
import { Vector } from "sat"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { Audio } from "../../engine/audio"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { Renderer } from "../../engine/graphics/renderer"
import EntityStatList from "../../stats/entity-stat-list"
import { StatType } from "../../stats/stat-interfaces-enums"
import { distanceSquaredVV } from "../../utils/math"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { ChainLightning } from "../chain-lightning"
import { Enemy } from "../enemies/enemy"
import { TrapGroundHazard, TrapParams } from "./trap-ground-hazard"

export interface TeslaCoilHazardParams extends TrapParams {
    weaponStatList: EntityStatList
    secondsPerAttack: number
    weaponType?: AllWeaponTypes
}

const SEARCH_RADIUS_BONUS = 50
const ARC_ORIGIN_Y_OFFSET = -100
const RANGE_CIRCLE_ROT_SPEED = 200

const RANGE_CIRCLE_GFX_RADIUS = 1556 / 2

// idk if TrapGroundHazard or GroundHazard would be better here
export class TeslaCoilHazard extends TrapGroundHazard {
    static pool: ObjectPoolTyped<TeslaCoilHazard, TeslaCoilHazardParams>

    weaponStatList: EntityStatList
    secondsPerAttack: number

    reusableHitEnemiesArray: Enemy[] = []

    visuals: Container
    rangeCircleVisuals: Container
    rangeCircle: Sprite
    halfOpacityRangeCircle: Sprite

    statList: EntityStatList = null // we don't use these, just from inheritence
    numEntitiesChained: number = 0

    startPositionVector: Vector

    weaponType: AllWeaponTypes

    constructor() {
        super()
        this.startPositionVector = new Vector()

        this.makeVisuals()
    }

    override setDefaultValues(defaultValues: any, overrideValues?: TeslaCoilHazardParams): void {
        super.setDefaultValues(defaultValues, overrideValues)

        if (overrideValues) {
            this.weaponStatList = overrideValues.weaponStatList
            this.secondsPerAttack = overrideValues.secondsPerAttack

            this.weaponType = overrideValues.weaponType === undefined ? null : overrideValues.weaponType

            // set visuals
            this.visuals.position.x = this.position.x
            this.visuals.position.y = this.position.y
            this.rangeCircleVisuals.position.x = this.position.x
            this.rangeCircleVisuals.position.y = this.position.y

            this.scaleRangeCircle()

            this.startPositionVector.copy(this.position)
            this.startPositionVector.y += ARC_ORIGIN_Y_OFFSET

            this.visuals.zIndex = this.position.y - 20
            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.visuals)
            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.rangeCircleVisuals)
        }
    }

    override cleanup(): void {
        super.cleanup()
        this.statList = null
        this.weaponStatList = null
        Renderer.getInstance().mgRenderer.removeFromScene(this.visuals)
        Renderer.getInstance().mgRenderer.removeFromScene(this.rangeCircleVisuals)
    }

    override onTriggered(triggeringEntity: ColliderComponent): void {

        this.activationTime = this.aliveTime + this.secondsPerAttack
        this.armed = false

        const projectileCount = this.weaponStatList.getStat(StatType.projectileCount)

        // get the entities we are going to hit
        const searchRadius = this.effectRadius + SEARCH_RADIUS_BONUS

        let foundEnemy = false

        for (let i = 0; i < projectileCount; ++i) {
            // the entity that triggered the mine isn't necessarily the first entity that is closest to us
            // since we're not triggering when our attack is off cooldown
            const startingNearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(this.position, searchRadius, CollisionLayerBits.HitEnemyOnly)
            // find the closest
            let enemy = this.getClosestUnMarkedEnemy(startingNearbyEntities)

            if (!enemy) {
                break
            }

            this.reusableHitEnemiesArray.push(enemy)
            enemy.markedForMultiProjectile = true

            // start a chain lightning off of this enemy
            const chainLightning = ChainLightning.pool.alloc({
                weaponStatList: this.weaponStatList,
                searchRadius: this.triggerRadius,
                startingPosition: this.startPositionVector,
                targetEntity: enemy,
                weaponType: this.weaponType
            })

            foundEnemy = true
        }

        //unmark the enemies
        for (let i = 0; i < this.reusableHitEnemiesArray.length; ++i) {
            this.reusableHitEnemiesArray[i].markedForMultiProjectile = false
        }
        this.reusableHitEnemiesArray.length = 0

        if (foundEnemy) {
            Audio.getInstance().playSfx('SFX_Tesla_Coil')
        } else {
            this.activationTime = this.aliveTime + 0.1
        }
    }

    getClosestUnMarkedEnemy(entities: ColliderComponent[]): Enemy {
        let closestDistance = Number.MAX_SAFE_INTEGER
        let closestEnemy: Enemy = null

        for (let i = 0; i < entities.length; ++i) {
            const enemy = entities[i].owner as Enemy

            if (!enemy.markedForMultiProjectile) {
                const distance = distanceSquaredVV(enemy.position, this.position)
                if (distance < closestDistance) {
                    closestDistance = distance
                    closestEnemy = enemy
                }
            }
        }

        return closestEnemy
    }

    applyEffect(enemy: Enemy) {
        // nothing, we have some arcing logic stemming from onTriggered() instead
    }

    override onExpired() {
        // future: fade / shrink away or something
        this.returnToPool()
    }

    returnToPool() {
        TeslaCoilHazard.pool.free(this)
    }

    makeVisuals() {
        this.visuals = new Container()
        this.visuals['update'] = () => { }

        const sprite = Sprite.from('tesla-coil-prop')
        sprite.anchor.x = 0.5
        sprite.anchor.y = 0.5
        sprite.scale.x = 0.5
        sprite.scale.y = 0.5
        sprite.position.y = -37

        this.visuals.addChild(sprite)

        this.rangeCircleVisuals = new Container()
        this.rangeCircleVisuals.zIndex = -99999
        this.rangeCircle = Sprite.from('tesla-coil-range-circle')
        this.halfOpacityRangeCircle = Sprite.from('tesla-coil-range-circle')
        this.rangeCircle.anchor.x = 0.5
        this.halfOpacityRangeCircle.anchor.x = 0.5
        this.rangeCircle.anchor.y = 0.5
        this.halfOpacityRangeCircle.anchor.y = 0.5

        this.halfOpacityRangeCircle.alpha = 0.5

        this.rangeCircle.angle = Math.getRandomFloat(0, Math.PI * 2)
        this.halfOpacityRangeCircle.angle = Math.getRandomFloat(0, Math.PI * 2)

        this.rangeCircleVisuals.addChild(this.rangeCircle)
        this.rangeCircleVisuals.addChild(this.halfOpacityRangeCircle)

        this.rangeCircleVisuals['update'] = (delta: number) => {
            this.rangeCircle.angle += delta * RANGE_CIRCLE_ROT_SPEED
            this.halfOpacityRangeCircle.angle -= delta * RANGE_CIRCLE_ROT_SPEED
        }
    }

    scaleRangeCircle() {
        const scale = this.effectRadius / RANGE_CIRCLE_GFX_RADIUS
        this.rangeCircle.scale.x = scale
        this.rangeCircle.scale.y = scale
        this.halfOpacityRangeCircle.scale.x = scale
        this.halfOpacityRangeCircle.scale.y = scale
    }

    isPlayerOwned(): boolean {
        return true
    }
}