import { Vector } from "sat"
import { GameState, getNID } from "../engine/game-state"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { EntityType, IEntity } from "./entity-interfaces"
import { Player } from "./player"
import { damp, distanceSquaredVV } from "../utils/math"

export abstract class PlayerFollower implements IEntity {
    entityType: EntityType = EntityType.Prop
    nid: number
	timeScale: number = 1

    position: Vector

    player: Player

    wobbleTime: timeInSeconds

    protected wobbleHeight: number
    protected wobbleTimeScale: number
    protected lerpSpeed: number
    protected followDelayMin: timeInSeconds
    protected followDelayMaxAcc: timeInSeconds
    protected targetDistance2: number

    private followDelayAcc: timeInSeconds
    private isInScene: boolean

    constructor(player: Player, lerpSpeed: number, followDelayMin: timeInSeconds, followDelayMaxAcc: timeInSeconds, targetDistance2: number, wobbleHeight?: number, wobbleTimeScale?: number) {
        this.nid = getNID(this)
        this.position = new Vector()
        this.player = player
        this.wobbleTime = 0
        this.followDelayAcc = 0
        this.isInScene = false

        this.lerpSpeed = lerpSpeed
        this.followDelayMin = followDelayMin
        this.followDelayMaxAcc = followDelayMaxAcc
        this.targetDistance2 = targetDistance2
        this.wobbleHeight = wobbleHeight
        this.wobbleTimeScale = wobbleTimeScale
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        this.wobbleTime += delta

        const wobbleYOffset = Math.sin(this.wobbleTime * this.wobbleTimeScale) * this.wobbleHeight

        const distToPlayer = distanceSquaredVV(this.position, this.player.position)

        if (distToPlayer > this.targetDistance2) {
            if (this.followDelayAcc >= this.followDelayMin) {
                this.position.x = damp(this.position.x, this.player.x, this.lerpSpeed, delta)
                this.position.y = damp(this.position.y, this.player.y, this.lerpSpeed, delta)
            }

            this.followDelayAcc = Math.min(this.followDelayMaxAcc, this.followDelayAcc + delta)
        } else {
            this.followDelayAcc = Math.max(0, this.followDelayAcc - delta)
        }

        this.position.y += wobbleYOffset
    }

    addToScene() {
        if (!this.isInScene) {
            this.isInScene = true
            GameState.addEntity(this)

            this.position.copy(this.player.position)
        }
    }

    removeFromScene() {
        if (this.isInScene) {
            GameState.removeEntity(this)
            this.isInScene = false
        }
    }
}