import { Container } from 'pixi.js'

export class ContainerTreeAdaptor {
	name: string
	childCount: number
	container: Container
	constructor(container: Container) {
		this.container = container
		this.name = `${container.name} (${container.constructor.name}) (${container.x},${container.y})`
		this.childCount = container.children.length
	}
	getChild(i: number) {
		const child = this.container.children[i]
		if (child as Container) {
			return new ContainerTreeAdaptor(child as Container)
		}
	}
}
