import { Container, Sprite } from "pixi.js"
import { Vector } from "sat"
import { AllWeaponTypes } from "../../weapon-types"
import { Audio } from "../../../engine/audio"
import { GameState, getNID } from "../../../engine/game-state"
import playAnimation from "../../../engine/graphics/play-animation"
import { Renderer } from "../../../engine/graphics/renderer"
import { RiggedSpineModel } from "../../../engine/graphics/spine-model"
import { DamageableEntityType, EntityType, IEntity } from "../../../entities/entity-interfaces"
import { AcidBottlesHazard } from "../../../entities/hazards/acid-bottles-hazard"
import { AnimationTrack } from "../../../spine-config/animation-track"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { distanceVV } from "../../../utils/math"
import { timeInMilliseconds, timeInSeconds } from "../../../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../../../utils/third-party/object-pool"
import { vectorLerpMutate } from "../../../utils/vector"
import { AssetManager } from "../../../web/asset-manager"
import { AcidBottlesWeapon } from "./acid-bottles-weapon"
import { callbacks_addCallback } from "../../../utils/callback-system"
import { InstancedAnimatedSprite } from "../../../engine/graphics/instanced-animated-sprite"
import { InstancedSpriteSheetAnimator } from "../../../engine/graphics/instanced-spritesheet-animator"

export interface ThrownAcidBottleParams {
    targetX: number
    targetY: number

    originPosition: Vector
    weapon: AcidBottlesWeapon
    weaponType: AllWeaponTypes
}

const THROW_SPEED = 300
const ROTATE_SPEED = 2

const SCALE_MIN = 0.65
const SCALE_MAX = 1.25

const DESPAWN_DELAY: timeInSeconds = 0.6

export class ThrownAcidBottle implements PoolableObject, IEntity {

    static pool: ObjectPoolTyped<ThrownAcidBottle, ThrownAcidBottleParams>

    targetPosition: Vector = new Vector()
    originPosition: Vector = new Vector()

    weapon: AcidBottlesWeapon
    weaponStatList: EntityStatList

    position: Vector = new Vector()

    aliveTime: number = 0
    totalTravelTime: number

    nid: number
    entityType: EntityType = EntityType.Projectile
	timeScale: number = 1

    weaponType: AllWeaponTypes

    gfx: InstancedSpriteSheetAnimator

    hitGround: boolean = false
    boundDespawnCallback: () => void


    constructor() {
        this.nid = getNID(this)
        this.makeGraphics()
        this.boundDespawnCallback = this.despawnDelayFinished.bind(this)
    }

    setDefaultValues(defaultValues: any, overrideValues?: ThrownAcidBottleParams) {
        if (overrideValues) {
            this.targetPosition.x = overrideValues.targetX
            this.targetPosition.y = overrideValues.targetY

            this.originPosition.copy(overrideValues.originPosition)

            this.position.copy(overrideValues.originPosition)

            this.weapon = overrideValues.weapon
            this.weaponStatList = overrideValues.weapon.statList
            this.weaponType = overrideValues.weaponType === undefined ? null : overrideValues.weaponType

            this.totalTravelTime = distanceVV(this.originPosition, this.targetPosition) / THROW_SPEED

            this.gfx.rotation = Math.getRandomFloat(0, Math.PI * 2)
            this.gfx.position.x = this.position.x
            this.gfx.position.y = this.position.y
            this.gfx.scale.x = SCALE_MIN
            this.gfx.scale.y = SCALE_MIN
            this.gfx.zIndex = 999_999
            this.gfx.playAnimation('spawn/poison-bottle-spawn', 'idle/poison-bottle-idle')

            this.gfx.addToScene()

            GameState.addEntity(this)
        }
    }

    cleanup() {
        this.weapon = null
        this.weaponStatList = null
        this.position.x = 0
        this.position.y = 0
        this.aliveTime = 0
        this.hitGround = false

        this.gfx.removeFromScene()

        GameState.removeEntity(this)
    }

    update(delta: number, now?: timeInMilliseconds) {
        this.aliveTime += delta

        if (this.aliveTime >= this.totalTravelTime) {
            if (!this.hitGround) {
                this.onHitDestination()
            }
            return
        }

        const travelPercent = this.aliveTime / this.totalTravelTime
        vectorLerpMutate(this.position, this.originPosition, this.targetPosition, travelPercent)
        this.gfx.rotation = this.gfx.rotation + (delta * ROTATE_SPEED)

        const rads = Math.lerp(0, Math.PI, travelPercent)
        const sineScale = Math.sin(rads)
        const scale = Math.lerp(SCALE_MIN, SCALE_MAX, sineScale)
        this.gfx.scale.x = scale
        this.gfx.scale.y = scale

        this.gfx.position.x = this.position.x
        this.gfx.position.y = this.position.y
    }

    onHitDestination() {
        this.hitGround = true

        console.assert(this.weaponStatList, 'Weapon stat list should exist, but does not')

        // make a ground hazard
        AcidBottlesHazard.pool.alloc({
            statList: this.weaponStatList,
            lifeTime: this.weaponStatList.getStat(StatType.skillDuration) as timeInSeconds,
            triggerRadius: this.weaponStatList.getStat(StatType.attackSize),
            position: this.targetPosition,
            damageTargetType: DamageableEntityType.Enemy,
            weaponType: this.weaponType,
            weapon: this.weapon
        })

        this.gfx.playAnimation('despawn/poison-bottle-despawn')
        callbacks_addCallback(this, this.boundDespawnCallback, DESPAWN_DELAY)

        Audio.getInstance().playSfx('SFX_Acid_Bottle')
    }

    despawnDelayFinished() {
        ThrownAcidBottle.pool.free(this)
    }

    makeGraphics() {
        const sheet = AssetManager.getInstance().getAssetByName('poison-bottle').spritesheet
        this.gfx = new InstancedSpriteSheetAnimator(sheet, 'spawn/poison-bottle-spawn')
    }
}