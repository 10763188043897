import { AnyColliderConfig } from "../../engine/collision/colliders"
import ModelConfig from "../../spine-config/model-config"
import { gameUnits, percentage, radians, timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { Vector } from "sat"
import { DamageConfig } from "../../spine-config/animation-track"
import { EnemyStatNames } from "../../stats/stat-interfaces-enums"
import { ENEMY_NAME } from "./enemy-names"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import { CooldownDefinition } from "../../cooldowns/cooldown"
import { Enemy } from "./enemy"
import { PostSpawnAfterDeathMod } from "./behaviours/dead"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { ParticleEffectType } from "../../engine/graphics/pfx/particle-config"
import { Prop } from "../prop"
import { GroundPickupConfigType } from "../pickups/ground-pickup-types"

export const ENEMY_OBJECT_POOL_INITIAL_SIZE = 50
export const ENEMY_OBJECT_POOL_GROWTH_SIZE = 4

export enum AIStates {
	IDLE = 'idle',
	FIGHTING = 'fighting',
	FLEEING = 'fleeing',
	DEAD = 'dead',
}

export enum DeadBehaviours {
	// Do nothing. Sit there. Be a corpse.
	BE_A_CORPSE = 'beACorpse',
	EXPLODE = 'explode',
	TWIST_EXPLODE = 'twistExplode'
}

export enum PersistedBehaviours {
	SHRIEK = 'shriek',
	SUMMON = 'summon'
}

export interface PersistedConfigInterface {
	behaviour: PersistedBehaviours
}

export interface ShriekConfig extends PersistedConfigInterface {
	behaviour: PersistedBehaviours.SHRIEK
	enemyCount: number
	range: number
}

export interface SummonConfig extends PersistedConfigInterface {
	behaviour: PersistedBehaviours.SUMMON
	enemy: ENEMY_NAME
	enemyCount: number
	cooldownTime: timeInSeconds
	minSpawnRange: number
	maxSpawnRange: number
	pfx?: string
	pfxTime?: number
	summonDelay?: number
}

export type PersistedConfig = ShriekConfig | SummonConfig

export enum OnHitBehaviours {
	BUFF = 'buff'
}

export type BuffOnHitConfig = {
	behaviour: OnHitBehaviours.BUFF
	buff: BuffIdentifier
	duration?: timeInMilliseconds
	stacks?: number
}

export type OnHitConfig = BuffOnHitConfig

export enum FightingBehaviours {
	// Chases towards the target using steering behaviour and shoots when within shooting range (engagement distance)
	CHASE_AND_ATTACK = 'chaseAndAttack',
	STRAFE_AND_ATTACK = 'strafeAndAttack',
	HOP_STRAFE_AND_ATTACK = 'hopStrafeAndAttack',
	CHARGE_ATTACK = 'chargeAttack',
	LERP_SPEED_ATTACK = 'lerpSpeedAttack',
	SPAWNER = 'spawn'
}

export type LerpSpeed = { targetSpeedMult: number, maxTime: timeInSeconds }

export type LerpSettings = [
	LerpSpeed,
	LerpSpeed?,
	LerpSpeed?,
	LerpSpeed?,
	LerpSpeed?,
]

// Some type definitions to make sure required params aren't forgotten when writing an Enemy's movement strategy
export type ChargeParams = {
	holdDirectionTime: timeInSeconds,
	holdDirectionDistance?: number, // If this is defined, it overrides holdDirectionTime
	aimTime: timeInSeconds,
	chargeLerpSpeed: LerpSettings,
	lerpSpeed?: LerpSettings
}

export type ChargeStrategy = {
	behaviour: FightingBehaviours.CHARGE_ATTACK,
	params: ChargeParams
}

export type LerpSpeedParams = {
	lerpSpeed: LerpSettings
}

export type LerpSpeedStrategy = {
	behaviour: FightingBehaviours.LERP_SPEED_ATTACK,
	params: LerpSpeedParams
}

export type SpawnerStrategy = {
	behaviour: FightingBehaviours.SPAWNER,
	spawnEnemy: ENEMY_NAME
	spawnTime: timeInSeconds
	minNumToSpawn: number
	spawnExtraPerGameSeconds: timeInSeconds
	minSpawnDistance: number
	maxSpawnDistance: number
}

export type HopStrafeStrategy = {
	behaviour: FightingBehaviours.HOP_STRAFE_AND_ATTACK,
	hopTime: number
	hopSpeed: number
	hopWaitTime: number
	hopAngleMin: radians
	hopAngleMax: radians
	attackDelayOnHop: number
}

export type MovementStrategyWithParams = ChargeStrategy | LerpSpeedStrategy | SpawnerStrategy | HopStrafeStrategy

export type ChaseStrategy = {
	behaviour: FightingBehaviours.CHASE_AND_ATTACK
}

export type StrafeStrategy = {
	behaviour: FightingBehaviours.STRAFE_AND_ATTACK
}

export type MovementStrategyWithoutParams = ChaseStrategy | StrafeStrategy

export type MovementStrategy = MovementStrategyWithParams | MovementStrategyWithoutParams

export enum LeashingBehaviours {
	// Moves back to pre-aggro position via steering vectors in a straight line
	MOVE = 'move',
	// Moves back to pre-aggro position via steering vectors in a straight line, heals to full health each tick
	MOVE_AND_FULL_HEAL = 'moveAndFullHeal',
	// Teleports back to pre-aggro position instantly
	TELEPORT = 'teleport',
}

export enum AttackTypes {
	PROJECTILE = 'projectile',
	GROUND_HAZARD = 'groundHazard',
	EXPLODE_ON_CONTACT = 'explode',
	MELEE = 'melee',
	NONE = 'none',
}

export type AttackWithCooldown = {
	attackType: AttackTypes.PROJECTILE | AttackTypes.GROUND_HAZARD | AttackTypes.MELEE
	cooldownDef: CooldownDefinition
	particleEffectType: ParticleEffectType
	telegraphDelay?: timeInSeconds
}

export type AttackWithoutCoolDown = {
	attackType: AttackTypes.NONE | AttackTypes.EXPLODE_ON_CONTACT
}

export type AttackConfig = AttackWithCooldown | AttackWithoutCoolDown

export enum ShotLeadPrecision {
	NONE,
	AVERAGE,
	PERFECT,
}

export enum EnemyType {
	BASIC = 1,
	BOSS = 2,
	DESTRICTIBLE_PROP = 3,
}


export type EnemyAIBaseStats = Record<EnemyStatNames, number | percentage>
export type EnemeyRageConfig = {
	enrageStart: timeInSeconds
	cooldownBonus: number
	movementBonus: number
	damageBonus: number,
	applyFunction?: (enemy: Enemy) => void
}

export interface EnemyAIBaseAttributes {
	noFlip?: boolean
	/**
	 * if true, this enemy will be unmovable
	 */
	colliderIsKinematic?: boolean
	/**
	 * **type**: circle/box/ellipse/polygon/lookup
	 */
	colliders: AnyColliderConfig[],
	colliderLayer: CollisionLayerBits,
	hasMovableCollider?: boolean
	defaultMovableColliderPosition?: number[]
	idleMovableColliderPosition?: number[]

	attackOffset: Vector
	//TODO HOTCAKES: Maybe remove this (should XP just drop at enemy's position?) or rename to xp drop offset
	lootDropOffset: Vector
	damageConfig: DamageConfig

	baseStats: EnemyAIBaseStats
	deathRewardRate: percentage

	decelerationRate: gameUnits
	knockbackDecelerationRate: gameUnits
	knockbackImmune?: boolean
	turningRatePerSecondInDegrees: number

	avoidanceRadius: number
	immuneToRecycle?: boolean

	animationSpeeds?: number
	playSpawnAnimation?: boolean
	playHitAnimation?: boolean

	isSpecial?: boolean

	buffOnSpawn?: [BuffIdentifier, number, timeInMilliseconds] // buff, stacks, duration
	bonusDropMult?: number
}

export interface IAnimationTimes {
	[key: string]: number
}

export interface AIStatesConfig {
	persisted?: PersistedConfig[]
	fighting: {
		movementStrategy: MovementStrategy
		attackConfig: AttackConfig
		engagementMaxDistance: number
		engagementMinDistance: number
		modelCenterOffset?: number
		movementMaxDistance: number
		movementMinDistance: number
		shotLeadPrecision: ShotLeadPrecision
		visualAimLockSeconds: number
		onCollisionFn?: (enemy: Enemy, player, collisionVX: number, collisionVY: number) => void
		onPropCollisionFn?: (enemy: Enemy, prop: Prop, collisionVX: number, collisionVY: number) => void
	}
	fleeing?: {
		timeToFlee: number
	}
	dead: {
		behaviour: DeadBehaviours
		corpseTimeoutInSeconds: number
		explosionRadius?: number
		explosionDamage?: number
		explosionPfxConfig?: string
		spawnAfterDeath?: Array<{ name: ENEMY_NAME, amount: number, delay: timeInMilliseconds, delayPerSpawn?: timeInMilliseconds, postSpawnMod?: PostSpawnAfterDeathMod }>
		onDeadFunctions?: Array<(enemy: Enemy) => void>
		persistentCorpse?: boolean
		excludeFromModifications?: boolean
		excludeGroundDrops?: GroundPickupConfigType[] // TODO: Currently only implemented for hearts
	}
	onHit?: OnHitConfig[]
	scaleWithDistanceRate?: number
}

export interface EnemyAI {
	/** the enemy name, needs to be the same as the key in enemy definitions */
	name: ENEMY_NAME
	baseVariant?: ENEMY_NAME
	type: EnemyType
	objectPoolInitialSize: number
	objectPoolGrowthSize: number
	/** Asset location and parameters to render enemy with Spine */
	appearance: ModelConfig
	baseAttributes: EnemyAIBaseAttributes
	soundEffects: {
		/** Sound effect group name for attack */
		attack: string
		impact: string
	},
	rage?: EnemeyRageConfig[]
	states: AIStatesConfig
}

export interface VariantAI {
	extends: ENEMY_NAME
	name: ENEMY_NAME
	appearance: ModelConfig
	type?: EnemyType
	maxHealth?: number
	movementSpeed?: number
	knockbackResist?: number
	baseDamage?: number
	attackSize?: number
	projectileSpeed?: number
	projectileCount?: number
	projectileSpreadAngle?: number
	projectileLifeSpan?: number
	chillChance?: number,
	chillPotency?: number,
	colliders?: AnyColliderConfig[]
	layer?: CollisionLayerBits
	states?: Partial<AIStatesConfig>
}

export function getMainAppearance(config: EnemyAI) {
	return Array.isArray(config.appearance) ? config.appearance[0] : config.appearance
}
