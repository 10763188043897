import { Enemy } from "../../../entities/enemies/enemy"
import { Player } from "../../../entities/player"
import EntityStatList from "../../../stats/entity-stat-list"
import { PassiveSkill } from "../../passive-skill"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { AllWeaponTypes } from "../../weapon-types"
import { GameState } from "../../../engine/game-state"
import { Effect } from "../../../engine/graphics/pfx/effect"
import { Renderer } from "../../../engine/graphics/renderer"
import { AssetManager } from "../../../web/asset-manager"

export const BARBARIAN_PASSIVE_BASE_STATS_DATA = {
	meleeRange: 550,
	heartDropRateMult: 1.5,
	strikerDamageMult: 1.25,
	paracticalSkillsXpMult: 1.15,
	intimidatingAuraSpeedMult: 0.76,
	widerAuthorityRangeMult: 1.4
}

const AURA_Z_INDEX = -100

export class BarbarianPassiveSkill extends PassiveSkill {
	weaponType: AllWeaponTypes = AllWeaponTypes.None

	aura: Effect

	onEnemyKilled(enemy: Enemy): void {
	}

	onCreate(player: Player): void {
		const cam = Renderer.getInstance().cameraState
		this.aura = new Effect(AssetManager.getInstance().getAssetByName('barbarian-aura-pfx').data, cam)
		this.aura.zIndex = AURA_Z_INDEX

		Renderer.getInstance().bgRenderer.addEffectToScene(this.aura)
	}

	onShot(): void {
	}

	onEnemyHit(enemy: Enemy, damageDealt: number): void {
	}

	update(delta: number): void {
		const barbarianSkillRange = getBarbarianPassiveSkillRange()
		this.aura.x = this.player.x
		this.aura.y = this.player.y
		this.aura.scale = barbarianSkillRange / 100
	}

	resetStatsFunction(statList: EntityStatList): void {
		defaultStatAttribute(statList)
	}
}

export function getBarbarianPassiveSkillRange() {
	let skillRange = BARBARIAN_PASSIVE_BASE_STATS_DATA.meleeRange
	if (GameState.player.binaryFlags.has('barbarian-wider-authority')) {
		skillRange *= BARBARIAN_PASSIVE_BASE_STATS_DATA.widerAuthorityRangeMult
	}
	return skillRange
}