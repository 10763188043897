<template>
    <div v-show="showBottom" class="container-bottom" :style="{ animation: isPaused ? 'none' : undefined }">
		<div class="warning-sign"></div>
        <div class="arrow"></div>
		<div class="arrow-right"></div>
		<div class="arrow-left"></div>
    </div>
	<div v-show="showTop" class="container-top" :style="{ animation: isPaused ? 'none' : undefined }">
		<div class="warning-sign"></div>
        <div class="arrow"></div>
		<div class="arrow-right"></div>
		<div class="arrow-left"></div>
    </div>
	<div v-show="showRight" class="container-right" :style="{ animation: isPaused ? 'none' : undefined }">
		<div class="warning-sign"></div>
        <div class="arrow"></div>
		<div class="arrow-top"></div>
		<div class="arrow-bottom"></div>
    </div>
	<div v-show="showLeft" class="container-left" :style="{ animation: isPaused ? 'none' : undefined }">
		<div class="warning-sign"></div>
        <div class="arrow"></div>
		<div class="arrow-top"></div>
		<div class="arrow-bottom"></div>
    </div>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex'

export default {
    name: "EventWarning",
    props: {
		showTop: {
			type: Boolean,
			required: true,
		},
		showBottom: {
			type: Boolean,
			required: true,
		},
		showRight: {
			type: Boolean,
			required: true,
		},
		showLeft: {
			type: Boolean,
			required: true,
		}
	},
    computed: {
		...mapState('time', ['isPaused']),
    }
}

</script>

<style lang="less" scoped>
.container-bottom {
    width: 100%;
    height: 50px;
    display: flex;
	align-items: center;
	justify-content: center;
    text-align: center;
    background-color: rgba(100,100,100,0);
    position: absolute;
    bottom: 0;
	right: 0;
	animation: blinker 0.5s linear infinite;

	.warning-sign {
		width: 100px;
		height: 100px;
		position: absolute;
		background-image: url('~/assets/ui/indicators/offscreen-warning.png');
		background-repeat: no-repeat;
		background-size: 100%;
		transform-origin: bottom center;
		bottom: 70px;
	}

    .arrow {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: bottom center;
    }
	.arrow-right {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: bottom center;
		right: 25%;
    }
	.arrow-left {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: bottom center;
		left: 25%;
    }

}
.container-top {
    width: 100%;
    height: 50px;
    display: flex;
	align-items: center;
	justify-content: center;
    text-align: center;
    background-color: rgba(100,100,100,0);
    position: absolute;
    top: 0;
	right: 0;
	animation: blinker 0.5s linear infinite;

	.warning-sign {
		width: 100px;
		height: 100px;
		position: absolute;
		background-image: url('~/assets/ui/indicators/offscreen-warning.png');
		background-repeat: no-repeat;
		background-size: 100%;
		transform-origin: bottom center;
		top: 70px;
	}

    .arrow {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: bottom center;
		transform:rotate(180deg) translateY(70px);
    }
	.arrow-right {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: bottom center;
		transform:rotate(180deg) translateY(70px);
		right: 25%;
    }
	.arrow-left {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: bottom center;
		transform:rotate(180deg) translateY(70px);
		left: 25%;
    }

}
.container-right {
    width: 50px;
    height: 100%;
    display: flex;
	align-items: center;
	justify-content: center;
    text-align: center;
    background-color: rgba(100,100,100,0.0);
    position: absolute;
    top: 0;
	right: 0;
	animation: blinker 0.5s linear infinite;

	.warning-sign {
		width: 100px;
		height: 100px;
		position: absolute;
		background-image: url('~/assets/ui/indicators/offscreen-warning.png');
		background-repeat: no-repeat;
		background-size: 100%;
		transform-origin: bottom center;
		right: 70px;
	}

    .arrow {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: center;
		transform:rotate(270deg);
    }
	.arrow-top {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
         transform-origin: center;
		transform:rotate(270deg);
		top: 25%;
    }
	.arrow-bottom {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: center;
		transform:rotate(270deg);
		bottom: 25%;
    }

}
.container-left {
    width: 50px;
    height: 100%;
    display: flex;
	align-items: center;
	justify-content: center;
    text-align: center;
    background-color: rgba(100,100,100,0.0);
    position: absolute;
    top: 0;
	left: 0;
	animation: blinker 0.5s linear infinite;

	.warning-sign {
		width: 100px;
		height: 100px;
		position: absolute;
		background-image: url('~/assets/ui/indicators/offscreen-warning.png');
		background-repeat: no-repeat;
		background-size: 100%;
		transform-origin: bottom center;
		left: 70px;
	}

    .arrow {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: center;
		transform:rotate(90deg);
    }
	.arrow-top {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
         transform-origin: center;
		transform:rotate(90deg);
		top: 25%;
    }
	.arrow-bottom {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url('~/assets/ui/indicators/offscreen-warning-arrow.png');
		background-repeat: no-repeat;
		background-size: 100%;
        transform-origin: center;
		transform:rotate(90deg);
		bottom: 25%;
    }

}
.blink_me {
  
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
