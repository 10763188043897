// this is copied from pixijs
//  we can't load pixi on server so using this
// some methods are ours (getCenter)

import { Vector } from 'sat'

export class Bounds {
	minY: number
	minX: number
	maxX: number
	maxY: number

	get width() {
		return this.maxX - this.minX
	}
	get height() {
		return this.maxY - this.minY
	}

	constructor() {
		this.minX = Infinity
		this.minY = Infinity
		this.maxX = -Infinity
		this.maxY = -Infinity
	}

	getCenter() {
		return new Vector((this.minX + this.maxX) * 0.5, (this.minY + this.maxY) * 0.5)
	}

	addVertexData(vertexData, beginOffset, endOffset) {
		let minX = this.minX
		let minY = this.minY
		let maxX = this.maxX
		let maxY = this.maxY
		for (let i = beginOffset; i < endOffset; i += 2) {
			const x = vertexData[i]
			const y = vertexData[i + 1]
			minX = x < minX ? x : minX
			minY = y < minY ? y : minY
			maxX = x > maxX ? x : maxX
			maxY = y > maxY ? y : maxY
		}
		this.minX = minX
		this.minY = minY
		this.maxX = maxX
		this.maxY = maxY
	}

	addBounds(bounds) {
		const minX = this.minX
		const minY = this.minY
		const maxX = this.maxX
		const maxY = this.maxY
		this.minX = bounds.minX < minX ? bounds.minX : minX
		this.minY = bounds.minY < minY ? bounds.minY : minY
		this.maxX = bounds.maxX > maxX ? bounds.maxX : maxX
		this.maxY = bounds.maxY > maxY ? bounds.maxY : maxY
	}

	move(position: SAT.Vector) {
		this.minX += position.x
		this.minY += position.y
		this.maxX += position.x
		this.maxY += position.y
	}
}
