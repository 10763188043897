{"frames": {

"player-icon--bees.png":
{
	"frame": {"x":5,"y":76,"w":64,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":60},
	"sourceSize": {"w":64,"h":60}
},
"player-icon--poison-bottles.png":
{
	"frame": {"x":527,"y":139,"w":54,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":54,"h":58},
	"sourceSize": {"w":54,"h":58}
},
"player-icon-3-round-burst.png":
{
	"frame": {"x":5,"y":5,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"player-icon-a-broken-heart-weeps-open.png":
{
	"frame": {"x":770,"y":143,"w":55,"h":41},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":55,"h":41},
	"sourceSize": {"w":55,"h":41}
},
"player-icon-a-quick-reaction-to-a-good-burn.png":
{
	"frame": {"x":361,"y":5,"w":61,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":58},
	"sourceSize": {"w":61,"h":58}
},
"player-icon-a-shockingly-good-time.png":
{
	"frame": {"x":509,"y":204,"w":55,"h":42},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":55,"h":42},
	"sourceSize": {"w":55,"h":42}
},
"player-icon-absolute-units.png":
{
	"frame": {"x":634,"y":5,"w":58,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":63},
	"sourceSize": {"w":58,"h":63}
},
"player-icon-aristotles-lack-of-motion.png":
{
	"frame": {"x":402,"y":133,"w":56,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":60},
	"sourceSize": {"w":56,"h":60}
},
"player-icon-banditry.png":
{
	"frame": {"x":204,"y":194,"w":55,"h":54},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":55,"h":54},
	"sourceSize": {"w":55,"h":54}
},
"player-icon-better-living-through-poison.png":
{
	"frame": {"x":429,"y":5,"w":62,"h":57},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":57},
	"sourceSize": {"w":62,"h":57}
},
"player-icon-big-brain-frail-body.png":
{
	"frame": {"x":732,"y":76,"w":35,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":35,"h":60},
	"sourceSize": {"w":35,"h":60}
},
"player-icon-big-brain-learning.png":
{
	"frame": {"x":5,"y":211,"w":57,"h":36},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":36},
	"sourceSize": {"w":57,"h":36}
},
"player-icon-bloated-blimpies.png":
{
	"frame": {"x":699,"y":5,"w":58,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":64},
	"sourceSize": {"w":58,"h":64}
},
"player-icon-blood-soaked-bullets.png":
{
	"frame": {"x":280,"y":132,"w":51,"h":55},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":51,"h":55},
	"sourceSize": {"w":51,"h":55}
},
"player-icon-chomp-bite-force.png":
{
	"frame": {"x":78,"y":72,"w":64,"h":48},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":48},
	"sourceSize": {"w":64,"h":48}
},
"player-icon-chonkers.png":
{
	"frame": {"x":465,"y":137,"w":55,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":55,"h":60},
	"sourceSize": {"w":55,"h":60}
},
"player-icon-do-those-rats-have-knives.png":
{
	"frame": {"x":498,"y":5,"w":61,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":60},
	"sourceSize": {"w":61,"h":60}
},
"player-icon-dry-heat.png":
{
	"frame": {"x":588,"y":141,"w":53,"h":57},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":53,"h":57},
	"sourceSize": {"w":53,"h":57}
},
"player-icon-fast-learner.png":
{
	"frame": {"x":707,"y":143,"w":56,"h":51},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":51},
	"sourceSize": {"w":56,"h":51}
},
"player-icon-fat-rat.png":
{
	"frame": {"x":5,"y":143,"w":62,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":61},
	"sourceSize": {"w":62,"h":61}
},
"player-icon-fire-storm.png":
{
	"frame": {"x":648,"y":141,"w":52,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":52,"h":60},
	"sourceSize": {"w":52,"h":60}
},
"player-icon-focal-prism.png":
{
	"frame": {"x":147,"y":129,"w":60,"h":46},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":46},
	"sourceSize": {"w":60,"h":46}
},
"player-icon-frenetic-activity.png":
{
	"frame": {"x":485,"y":72,"w":56,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":58},
	"sourceSize": {"w":56,"h":58}
},
"player-icon-getting-into-rat-trouble.png":
{
	"frame": {"x":292,"y":5,"w":62,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":58},
	"sourceSize": {"w":62,"h":58}
},
"player-icon-great-tone.png":
{
	"frame": {"x":74,"y":180,"w":58,"h":52},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":52},
	"sourceSize": {"w":58,"h":52}
},
"player-icon-have-heart.png":
{
	"frame": {"x":338,"y":133,"w":57,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":60},
	"sourceSize": {"w":57,"h":60}
},
"player-icon-heist.png":
{
	"frame": {"x":360,"y":70,"w":59,"h":56},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":59,"h":56},
	"sourceSize": {"w":59,"h":56}
},
"player-icon-high-density-energy.png":
{
	"frame": {"x":774,"y":70,"w":50,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":50,"h":58},
	"sourceSize": {"w":50,"h":58}
},
"player-icon-high-speed-plunderer.png":
{
	"frame": {"x":323,"y":200,"w":56,"h":48},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":48},
	"sourceSize": {"w":56,"h":48}
},
"player-icon-holy-spear-final-form.png":
{
	"frame": {"x":221,"y":5,"w":64,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":58},
	"sourceSize": {"w":64,"h":58}
},
"player-icon-hungry-hungry-doggo.png":
{
	"frame": {"x":386,"y":200,"w":53,"h":48},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":53,"h":48},
	"sourceSize": {"w":53,"h":48}
},
"player-icon-impatient.png":
{
	"frame": {"x":222,"y":70,"w":62,"h":55},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":55},
	"sourceSize": {"w":62,"h":55}
},
"player-icon-in-the-eye-of-mercy.png":
{
	"frame": {"x":78,"y":5,"w":64,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":60},
	"sourceSize": {"w":64,"h":60}
},
"player-icon-intimidating-aura.png":
{
	"frame": {"x":770,"y":191,"w":50,"h":57},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":50,"h":57},
	"sourceSize": {"w":50,"h":57}
},
"player-icon-jokers-wild.png":
{
	"frame": {"x":76,"y":127,"w":64,"h":46},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":46},
	"sourceSize": {"w":64,"h":46}
},
"player-icon-light-it-up.png":
{
	"frame": {"x":672,"y":76,"w":53,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":53,"h":58},
	"sourceSize": {"w":53,"h":58}
},
"player-icon-lye-ing-it-on-thick.png":
{
	"frame": {"x":707,"y":201,"w":55,"h":47},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":55,"h":47},
	"sourceSize": {"w":55,"h":47}
},
"player-icon-maximum-amperage.png":
{
	"frame": {"x":214,"y":132,"w":59,"h":55},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":59,"h":55},
	"sourceSize": {"w":59,"h":55}
},
"player-icon-meteoric-pace.png":
{
	"frame": {"x":764,"y":5,"w":61,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":58},
	"sourceSize": {"w":61,"h":58}
},
"player-icon-pet-caller.png":
{
	"frame": {"x":571,"y":205,"w":49,"h":41},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":49,"h":41},
	"sourceSize": {"w":49,"h":41}
},
"player-icon-rat-parade.png":
{
	"frame": {"x":611,"y":75,"w":54,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":54,"h":59},
	"sourceSize": {"w":54,"h":59}
},
"player-icon-split-personality.png":
{
	"frame": {"x":266,"y":194,"w":50,"h":53},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":50,"h":53},
	"sourceSize": {"w":50,"h":53}
},
"player-icon-strolling-minstrel.png":
{
	"frame": {"x":426,"y":70,"w":52,"h":56},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":52,"h":56},
	"sourceSize": {"w":52,"h":56}
},
"player-icon-stronger-better-faster-longer.png":
{
	"frame": {"x":548,"y":73,"w":56,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":59},
	"sourceSize": {"w":56,"h":59}
},
"player-icon-tactical-strike-final-form.png":
{
	"frame": {"x":566,"y":5,"w":61,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":61},
	"sourceSize": {"w":61,"h":61}
},
"player-icon-the-wise-one.png":
{
	"frame": {"x":139,"y":182,"w":58,"h":51},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":51},
	"sourceSize": {"w":58,"h":51}
},
"player-icon-thorns-panic-option.png":
{
	"frame": {"x":291,"y":70,"w":62,"h":53},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":53},
	"sourceSize": {"w":62,"h":53}
},
"player-icon-weight-training.png":
{
	"frame": {"x":149,"y":70,"w":66,"h":52},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":52},
	"sourceSize": {"w":66,"h":52}
},
"player-icon-where-your-nightmares-end.png":
{
	"frame": {"x":149,"y":5,"w":65,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":58},
	"sourceSize": {"w":65,"h":58}
},
"player-icon-zoomies.png":
{
	"frame": {"x":446,"y":204,"w":56,"h":44},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":44},
	"sourceSize": {"w":56,"h":44}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.1",
	"image": "player-icons-gray.png",
	"format": "RGBA8888",
	"size": {"w":830,"h":253},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:c3a704e3cc86cb74a59cb7e15d69ba52:8ade5315c83c0fcb42ef707446962571:9d4f8bf2889bddbbf841ee7c4d4c7b6c$"
}
}
