import { SingleSpriteGraphicsComponent } from "../engine/graphics/single-sprite-graphics-component"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { Player } from "./player"
import { Renderer } from "../engine/graphics/renderer"
import { EffectConfig } from "../engine/graphics/pfx/effectConfig"
import { AssetManager } from "../web/asset-manager"
import { PlayerFollower } from "./player-follower"

const EGG_Z_OFFSET = 10

const LERP_SPEED = 0.39

const FOLLOW_DELAY_MIN = 0.3
const MAX_FOLLOW_DELAY_ACC = FOLLOW_DELAY_MIN + 0.1

const TARGET_DISTANCE_2 = 100 * 100

export class CreepyEggGfx extends PlayerFollower {
    sprite: SingleSpriteGraphicsComponent

    private defeatPfx: EffectConfig
    private victoryPfx: EffectConfig

    constructor(player: Player) {
        super(player, LERP_SPEED, FOLLOW_DELAY_MIN, MAX_FOLLOW_DELAY_ACC, TARGET_DISTANCE_2, 0, 0)

        this.sprite = new SingleSpriteGraphicsComponent('pickup-icons', 'egg.png', this)

        this.defeatPfx = AssetManager.getInstance().getAssetByName('egg-drop').data
        this.victoryPfx = AssetManager.getInstance().getAssetByName('confetti-explosion').data
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        super.update(delta, now)
        this.sprite.instancedSprite.zIndex = this.position.y + EGG_Z_OFFSET
        this.sprite.update()
    }

    addEgg() {
        this.addToScene()
        this.sprite.addToScene()
    }

    removeEgg(victory: boolean) {
        this.removeFromScene()
        this.sprite.removeFromScene()

        Renderer.getInstance().addOneOffEffectByConfig(victory ? this.victoryPfx : this.defeatPfx, this.position.x, this.position.y, this.position.y, 1, 0.5, true)
    }
}