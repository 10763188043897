import { Container, Graphics } from 'pixi.js'
import { Vector } from 'sat'
import { radians } from '../../utils/primitive-types'
import { ComponentOwner } from '../component-owner'
import { Renderer } from '../graphics/renderer'
import { Bounds } from './bounds'
import { AnyCollider, AnyColliderConfig, BoxCollider, collidersBounds, ColliderType, createColliders } from './colliders'
import { CollisionLayerBits, COLLISION_VS_LAYERS } from './collision-layers'
import { SpatialEntity, SpatialGridCell } from './spatial-entity'

export class ColliderComponent implements SpatialEntity {

    get id(): number {
        return this.owner.nid
    }
    
    owner: any

    onCollisionCallback?: (otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) => void
    onCollisionChecksDoneCallback?: () => void

    cells: SpatialGridCell[]
    originCell: SpatialGridCell

    colliders: AnyCollider[]
    colliderConfigs: AnyColliderConfig[]
    bounds: Bounds

    isStatic: boolean
    isKinematic: boolean
    isTrigger: boolean 

    set isColliderActive(value: boolean) {
        this._isColliderActive = value
        this.forceDirty = true
        this.previousPosition.copy(this.position)
    }

    get isColliderActive() {
        return this._isColliderActive
    }

    isInScene: boolean = false

    private _isColliderActive: boolean = true


    layer: CollisionLayerBits
    collidesVsLayers: number | CollisionLayerBits

    get position(): Vector {
        return this.owner.position
    }
    set position(newValue) {
        this.owner.position = newValue
    }

    previousPosition: Vector
    forceDirty: boolean = false

    private collidedThisFrame: boolean = false
    collidedLastFrame: boolean = false


    private debugVisuals: Container
    isDrawingDebugVisuals: boolean = false

    jankyCacheDistanceForSorting: number = 0

    constructor(colliderConfigs: AnyColliderConfig[], owner: ComponentOwner, layer: CollisionLayerBits, onCollisionFunction?: (otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) => void, isStatic?: boolean, isKinematic?: boolean, isTrigger?: boolean) {
        this.owner = owner
        this.cells = []

        if (colliderConfigs) {
            this.setColliders(colliderConfigs)
        }

        this.isStatic = Boolean(isStatic)
        this.isKinematic = Boolean(isKinematic)
        this.isTrigger = Boolean(isTrigger)

        this.setLayer(layer)

        this.previousPosition = this.position.clone()

        this.onCollisionCallback = onCollisionFunction
    }

    onCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number): void {
        this.collidedThisFrame = true // true for a VERY short amount of time (it's private for a reason)
        
        if (this.onCollisionCallback) {
            this.onCollisionCallback(otherEntity, collisionVX, collisionVY)
        }
    }

    onCollisionChecksDone() {
        this.collidedLastFrame = this.collidedThisFrame
        this.collidedThisFrame = false

        if (this.onCollisionChecksDoneCallback) {
            this.onCollisionChecksDoneCallback()
        }
    }

    setColliders(colliderConfigs: AnyColliderConfig[]) {
        this.colliderConfigs = colliderConfigs
        this.colliders = createColliders(this.position, colliderConfigs)
        this.bounds = collidersBounds(this.colliders)
        this.forceDirty = true
    }

    // Only box colliders support rotation / angle
    setAngleBoxColliderOnly(angle: radians, keepOriginalAngle?: boolean) {
        for (let i =0; i < this.colliders.length; ++i) {
            const collider = this.colliders[i]
            if(collider.type === ColliderType.Box) {
                const box = this.colliders[i] as BoxCollider
                box.setAngle(angle, keepOriginalAngle)
            }
        }
        
        this.bounds = collidersBounds(this.colliders)
        this.forceDirty = true
    }

    setLayer(layer: CollisionLayerBits) {
        this.layer = layer
        this.collidesVsLayers = COLLISION_VS_LAYERS[layer]
    }

	recalculateBounds() {
		this.bounds = collidersBounds(this.colliders)
	}

    drawColliders() {
        if (!this.isDrawingDebugVisuals) {
            this.isDrawingDebugVisuals = true

            if (!this.debugVisuals) {
                this.debugVisuals = new Container()
                this.debugVisuals['update'] = () => {
                    this.debugVisuals.x = this.position.x
                    this.debugVisuals.y = this.position.y
                    this.debugVisuals.zIndex = this.position.y + 1000
                }

                for(const collider of this.colliders) {
                    const gfx = new Graphics()
                    gfx.lineStyle(3, 0xAA0000)
                    
                    switch (collider.type) {
                        case ColliderType.Circle:
                            gfx.drawCircle(collider.offsetPos.x, collider.offsetPos.y, collider.r)
                            break
                        case ColliderType.Box:
                            gfx.drawRect(collider.offsetPos.x, collider.offsetPos.y - (collider.h /2), collider.w, collider.h)
                            gfx.rotation = collider.angle
                            break
                        case ColliderType.Ellipse:
                            const minRad = Math.min(collider.rX, collider.rY)
                            gfx.drawCircle(collider.offsetPos.x, collider.offsetPos.y, minRad)
                            gfx.scale.x = collider.rX / minRad
                            gfx.scale.y = collider.rY / minRad  
                            break
                        // @TODO other shapes
                    }
                    
                    this.debugVisuals.addChild(gfx)
                }
            }

            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.debugVisuals)
        }
    }

    stopDrawingColliders() {
        if (this.isDrawingDebugVisuals) {
            this.isDrawingDebugVisuals = false

            Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)

            this.debugVisuals.destroy()
            this.debugVisuals = null
        }
    }
}