import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { Player } from "./player"
import { AssetManager } from "../web/asset-manager"
import { PlayerFollower } from "./player-follower"
import { InstancedAnimatedSprite } from "../engine/graphics/instanced-animated-sprite"

const Z_OFFSET = 10

const LERP_SPEED = 0.39

const FOLLOW_DELAY_MIN = 0.1
const MAX_FOLLOW_DELAY_ACC = FOLLOW_DELAY_MIN + 0.1

const TARGET_DISTANCE_2 = 100 * 100

export class SpectralHoeGfx extends PlayerFollower {
    sprite: InstancedAnimatedSprite

    constructor(player: Player) {
        super(player, LERP_SPEED, FOLLOW_DELAY_MIN, MAX_FOLLOW_DELAY_ACC, TARGET_DISTANCE_2, 0, 0)

        const frames = AssetManager.getInstance().getAssetByName('spectral-hoe').spritesheet.animations['idle']
        this.sprite = new InstancedAnimatedSprite(frames)
        this.sprite.animationSpeed = 0.1
        this.sprite.play()
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        super.update(delta, now)
        
        this.sprite.zIndex = this.position.y + Z_OFFSET
        this.sprite.position.copy(this.position)
        this.sprite.update(delta)
    }

    addToScene() {
        super.addToScene()
        this.sprite.addToScene()
    }

    removeFromScene() {
        super.addToScene()
        this.sprite.removeFromScene()
    }
}