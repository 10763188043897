import { add, mapToRange, scale, smoothDamp, smoothDampV, sub, withinDistanceVV } from "../../utils/math"
import { timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { Renderer } from "./renderer"
import { Vector } from 'sat'
import Noise from "../../utils/third-party/perlin"
import logger from '../../utils/client-logger'
import { GameState } from "../game-state"
import { CameraModifier, CameraMutatorDefinition, MutatorDefinition } from "../../mutators/mutator-definitions"
import { ACT_TIMER } from "../../utils/time"

// the most the camera translates while shaking
const SHAKE_OFFSET_MAX: number = 30
export const SHAKE_TRAUMA_MAX = 1
// how many times trauma is multiplied to get shake amount
const SHAKE_EXPONENT = 3
const SHAKE_DURATION_MAX: timeInSeconds = 2

// const SHAKE_DAMAGE_LOG_MIN = Math.log(ENEMY_SMALL_HIT_ON_PLAYER_DAMAGE)
// const SHAKE_DAMAGE_LOG_MAX = Math.log(ENEMY_BIG_HIT_ON_PLAYER_DAMAGE)
// how fast we move through our perlin-noise field
const SHAKE_TIME_MULT = 25
// only allow this much 'additional' trauma above the newly added trauma
//  ie: if you're standing in front of a firing machine-gun, do not go to trauma:1, as that is earthquake level
const SHAKE_TRAUMA_MAX_CUMULATIVE_ADD = 0.1

const RECOIL_DURATION = 0.1
// if a recoil has finsished within this time, reduce the amount of recoil on subsequent recoils
const RECOIL_MAG_ADJUST_TIME = 0.5

const NORMAL_WINDOW_WIDTH = 1920
const NORMAL_ZOOM = 0.7

const ZOOM_SCALE =  NORMAL_ZOOM / NORMAL_WINDOW_WIDTH
const MIN_ZOOM_OUT = 0.4

export const CAMERA_CLAMP_BUFFER = 900

const normalSettings: CamSettings = {
	smoothTime: 0.2,
	playerFocus: 1,
	poiFocus: 0,
	zoom: 0.75,
}

const cameraSettings = {
	maxDistanceFromPlayer: 500,
	normalSettings,
}

interface CamSettings {
	smoothTime: timeInSeconds
	playerFocus: number
	poiFocus: number
	zoom: number
}

type TemporaryOffset = {
	xOffset: number
	yOffset: number
	timeRemaining: number
}

export interface FocusablePosition extends CamSettings {
	position: Vector
}

// if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
// 	DevToolsManager.getInstance().addObjectByName('cameraSettings', cameraSettings)
// }

function getZoomLevelFromWindowWidth() {
	const w = window.innerWidth
	const h = window.innerWidth	

	const max = Math.max(w,h)
	
	return Math.max(MIN_ZOOM_OUT, max * ZOOM_SCALE)
}


export function checkIfPointIsInView(point: Vector): boolean {
	const cameraState = Renderer.getInstance().cameraState
	const player = GameState.player

	const cameraX = player.position.x - getVisibleWorldWidth(cameraState.zoom) / 2
	const cameraY = player.position.y - getVisibleWorldHeight(cameraState.zoom) / 2

	const cameraWidth = (player.position.x + getVisibleWorldWidth(cameraState.zoom) / 2) - (player.position.x - getVisibleWorldWidth(cameraState.zoom) / 2)
	const cameraHeight = (player.position.y + getVisibleWorldHeight(cameraState.zoom) / 2) - (player.position.y - getVisibleWorldHeight(cameraState.zoom) / 2)

	const cam = pointToCameraDistance(point, cameraX, cameraY, cameraWidth, cameraHeight)

	return cam <= 0
}

export function pointToCameraDistance(point: Vector, camX: number, camY: number, camWidth: number, camHeight: number): number {
	const cx = Math.max(Math.min(point.x, camX + camWidth), camX);
	const cy = Math.max(Math.min(point.y, camY + camHeight), camY);
	return Math.sqrt((point.x - cx) * (point.x - cx) + (point.y - cy) * (point.y - cy));
}

export function getVisibleWorldWidth(zoom?) {
	return Math.floor(getGameScreenWidth() / (zoom || getZoomLevelFromWindowWidth()))
}

export function getVisibleWorldHeight(zoom?) {
	return Math.floor(getGameScreenHeight() / (zoom || getZoomLevelFromWindowWidth()))
}

function getGameScreenWidth() {
	return window.innerWidth
}

function getGameScreenHeight() {
	return window.innerHeight
}

// for explanation of trauma vs shake see:
// https://youtu.be/tu-Qe66AvtY?t=220
export class Camera {
	static getInstance(): Camera {
		if (!Camera.instance) {
			Camera.instance = new Camera()
		}

		return Camera.instance
	}

	static shutdown() {
		Camera.instance = null
	}

	worldSpaceCamPos = new Vector()
	worldSpaceCameraVel = new Vector(0, 0)
	cameraZoomVel = 0

	allZoomMultiplier: number = 1
	private badEyesZoomMultiplier: number = 1

	private static instance

	private shakeTime = 0
	private trauma = 0
	private shootyShake = 0
	private noise = [new Noise(), new Noise()]
	private recoilTime = 0
	private recoilAngle = 0
	private recoilOffset = new Vector(0, 0)
	private recoilMagMult = 1
	private recoilMag = 5

	private temporaryOffsets: TemporaryOffset[] = []
	private positionFocus: FocusablePosition

	private isBadEyesActive: boolean = false
	private badEyesValues: CameraModifier
	private setBadEyesAct2: boolean = false
	private setBadEyesAct3: boolean = false

	private enableCameraClamp: boolean = false
	private xMinClamp: number
	private xMaxClamp: number
	private yMaxClamp: number

	private constructor() {
		for (let i = 0; i < this.noise.length; i++) {
			this.noise[i].seed(i)
		}
	}

	update(delta: number, now: timeInMilliseconds) {
		const renderer = Renderer.getInstance()

		const entityVisualPos = GameState.player.position
		let targetPos = entityVisualPos.clone()

		let settings = normalSettings

		if (this.positionFocus) {
			settings = this.positionFocus

			let w = settings.playerFocus + settings.poiFocus
			targetPos = add(scale(targetPos, settings.playerFocus), scale(this.positionFocus.position, settings.poiFocus))

			// targetPos = add(targetPos, scale(new Vector(boss.x, boss.y), settings.bossFocus))
			// w += settings.bossFocus

			targetPos = scale(targetPos, 1 / w)
		}

		this.temporaryOffsets.forEach((offset) => {
			targetPos.x += offset.xOffset
			targetPos.y += offset.yOffset

			offset.timeRemaining -= delta
			if (offset.timeRemaining <= 0) {
				this.temporaryOffsets.remove(offset)
			}
		})

		if (cameraSettings.maxDistanceFromPlayer && !withinDistanceVV(targetPos, entityVisualPos, cameraSettings.maxDistanceFromPlayer)) {
			const diff = sub(targetPos, entityVisualPos)
			targetPos = add(scale(diff.normalize(), cameraSettings.maxDistanceFromPlayer), entityVisualPos)
		}

		if (this.isBadEyesActive) {
			if (!this.setBadEyesAct2 && now / 1_000 > ACT_TIMER.ACT_2_START_TIME) {
				this.badEyesZoomMultiplier = this.badEyesValues.act2StartValue
				this.setBadEyesAct2 = true
			} else if (!this.setBadEyesAct3 && now / 1_000 > ACT_TIMER.ACT_3_START_TIME) {
				this.badEyesZoomMultiplier = this.badEyesValues.act3StartValue
				this.setBadEyesAct3 = true
			}
		}

		const targetZoom = this.allZoomMultiplier * this.badEyesZoomMultiplier * settings.zoom * getZoomLevelFromWindowWidth()

		smoothDampV(this.worldSpaceCamPos, this.worldSpaceCameraVel, targetPos, delta, settings.smoothTime)

		if (this.enableCameraClamp) {
			this.worldSpaceCamPos.x = Math.clamp(this.worldSpaceCamPos.x, this.xMinClamp, this.xMaxClamp)
			this.worldSpaceCamPos.y = Math.min(this.worldSpaceCamPos.y, this.yMaxClamp)
		}

		const out = smoothDamp(renderer.zoomLevel, this.cameraZoomVel, targetZoom, delta, settings.smoothTime)
		renderer.updateZoomLevel(out[0])
		this.cameraZoomVel = out[1]

		renderer.centerCameraOnPoint(this.worldSpaceCamPos.x, this.worldSpaceCamPos.y, 1)

		const cameraPosAfterMouse = renderer.getCameraCenterWorldPos()

		this.shakeTime += delta
		let offset = this.updateShake(entityVisualPos, delta)
		cameraPosAfterMouse.add(offset)

		offset = this.updateShootyShake(delta)
		cameraPosAfterMouse.add(offset)

		offset = this.updateRecoil(entityVisualPos, delta)
		cameraPosAfterMouse.add(offset)

		renderer.centerCameraOnPoint(cameraPosAfterMouse.x, cameraPosAfterMouse.y, 1)
	}

	triggerShake(traumaAmount: number) {
		if (traumaAmount > 0 && traumaAmount <= SHAKE_TRAUMA_MAX) {
			this.trauma += traumaAmount
		} else {
			logger.error(`bad trauma amount passed to triggerShake:${traumaAmount}`)
		}

		this.trauma = Math.clamp(this.trauma, 0, traumaAmount + SHAKE_TRAUMA_MAX_CUMULATIVE_ADD)
	}

	triggerShootShake(amount: number) {
		this.shootyShake = Math.max(amount, this.shootyShake)
	}

	// this is a special version of shake that maps damage to trauma
	triggerShakeWithDamage(damage: number) {
		const ldam = Math.log(damage)
		const trauma = 6 /*mapToRange(
			ldam,
			SHAKE_DAMAGE_LOG_MIN,
			SHAKE_DAMAGE_LOG_MAX, // input range
			CameraShakeIntensities.MILD,
			CameraShakeIntensities.INTENSE, // output range
			true,
		)*/

		// logger.debug(`ldam: ${ldam}, damage: ${damage}, trauma: ${trauma}`)

		this.triggerShake(trauma)
	}

	triggerRecoil(angle: number, recoilMagnitude: number) {
		//logger.debug(`triggerRecoil t:${this.recoilTime} m:${this.recoilMagMult}`)
		this.recoilMag = recoilMagnitude
		this.recoilAngle = angle
		this.recoilMagMult = mapToRange(this.recoilTime, -RECOIL_MAG_ADJUST_TIME, RECOIL_DURATION, 1, 0, true)
		this.recoilTime = RECOIL_DURATION
		// logger.debug(`triggerRecoil:${this.recoilTime} magMult:${this.recoilMagMult}, passed magnitude: ${recoilMagnitude}`)
	}

	getShakeAmount() {
		return this.trauma ** SHAKE_EXPONENT
	}

	updateShake(entityVisualPos: Vector, delta: number): Vector {

		const shakeAmount = this.getShakeAmount()

		const randx = this.getRandomFloatNegOneToOne(0, this.shakeTime * SHAKE_TIME_MULT)
		const randy = this.getRandomFloatNegOneToOne(1, this.shakeTime * SHAKE_TIME_MULT)
		const offset = new Vector()
		offset.x = SHAKE_OFFSET_MAX * shakeAmount * randx
		offset.y = SHAKE_OFFSET_MAX * shakeAmount * randy

		this.trauma -= delta / SHAKE_DURATION_MAX
		this.trauma = Math.clamp(this.trauma, 0, SHAKE_TRAUMA_MAX)

		return offset
	}

	updateShootyShake(delta: number): Vector {
		const shakeAmount = this.shootyShake ** SHAKE_EXPONENT

		const randx = this.getRandomFloatNegOneToOne(0, this.shakeTime * SHAKE_TIME_MULT)
		const randy = this.getRandomFloatNegOneToOne(1, this.shakeTime * SHAKE_TIME_MULT)
		const offset = new Vector()
		offset.x = SHAKE_OFFSET_MAX * shakeAmount * randx
		offset.y = SHAKE_OFFSET_MAX * shakeAmount * randy

		this.shootyShake -= delta / SHAKE_DURATION_MAX
		this.shootyShake = Math.clamp(this.shootyShake, 0, SHAKE_TRAUMA_MAX)

		return offset
	}

	updateRecoil(entityVisualPos: Vector, delta: number) {
		this.recoilTime -= delta

		let xoffset = 0

		if (this.recoilTime > 0) {
			const x = (RECOIL_DURATION - this.recoilTime) / RECOIL_DURATION
			// see camera.md to view this formula
			xoffset = Math.sin((x * 10) ** 0.5)
			xoffset *= -this.recoilMag * this.recoilMagMult
			//logger.debug(this.recoilTime, xoffset, this.recoilAngle)
		}

		this.recoilOffset.x = xoffset
		this.recoilOffset.y = 0

		// if (this.recoilTime > 0) {
		// 	console.log('before', this.recoilOffset)
		// }

		this.recoilOffset.rotate(this.recoilAngle)
		// if (this.recoilTime > 0) {
		// 	console.log('after', this.recoilOffset)
		// }

		return this.recoilOffset
	}

	setNormalZoomLevel(zoom: number) {
		normalSettings.zoom = zoom
	}

	getTargetZoom() {
		return this.allZoomMultiplier * this.badEyesZoomMultiplier * normalSettings.zoom * getZoomLevelFromWindowWidth()
	}

	temporarilyOffsetCamera(xOffset: number, yOffset: number, time: number) {
		// @TODO can probably not alloc here ez
		this.temporaryOffsets.push({
			xOffset,
			yOffset,
			timeRemaining: time
		})
	}

	startFocusOnPosition(position: FocusablePosition) {
		this.positionFocus = position
	}

	stopFocusOnPosition(position: FocusablePosition) {
		if (this.positionFocus === position) {
			this.positionFocus = null
		}
	}

	addMutator(mutator: MutatorDefinition, mutation: CameraMutatorDefinition) {
		if (mutator.id === 'badEyes') {
			this.isBadEyesActive = true
			this.badEyesValues = mutation.modifications

			this.badEyesZoomMultiplier = mutation.modifications.act1StartValue
		}
	}

	teleportCamera(x: number, y: number) {
		this.worldSpaceCamPos.x = x
		this.worldSpaceCamPos.y = y
	}

	setCameraClamp(minX: number, maxX: number, maxY: number) {
		this.xMinClamp = minX
		this.xMaxClamp = maxX
		this.yMaxClamp = maxY
		this.enableCameraClamp = true
	}

	private getRandomFloatNegOneToOne(channel: number, t: number) {
		const n = this.noise[channel].perlin2(t, 0) * 2
		return n
	}
}

function debugMessage(s: string) {
	logger.debug(s)
}
