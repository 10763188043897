import * as PIXI from 'pixi.js'
import { Vector } from 'sat'
import { InstancedSpriteRenderable } from './pfx/instanced-sprite-batcher'
import { RenderQueue, RenderQueueElementType } from './render-queue'


export class InstancedSprite {
	renderable: InstancedSpriteRenderable

	get zIndex() {
		return this.z
	}

	set zIndex(newZ) {
		this.z = newZ
	}
	
	get bottom() {
		return this.y + 0.5 * this.h
	}

	get x() {
		return this.position.x
	}

	set x(newX) {
		this.position.x = newX
		this.renderable.pos[0] = newX
	}

	get y() {
		return this.position.y
	}

	set y(newY) {
		this.position.y = newY
		this.renderable.pos[1] = newY
	}

	get pos() {
		return this.position
	}

	get scaleX() {
		return this._scaleX
	}

	get scaleY() {
		return this._scaleY
	}

	set scaleX(newScale) {
		this.w = this.texWidth * newScale
		this.renderable.scale[0] = this.w
		this._scaleX = newScale
	}

	set scaleY(newScale) {
		this.h = this.texHeight * newScale
		this.renderable.scale[1] = this.h
		this._scaleY = newScale
	}

	get rot() {
		return this.rotation
	}

	set rot(newRot) {
		this.rotation = newRot
		this.renderable.rot = newRot
	}

	get width() {
		return this.w
	}

	get height() {
		return this.h
	}

	debugName: string
	propOffset: Vector // Used in prop recycling

	color: number[]

	private z: number = 0
	private position: PIXI.Point = new PIXI.Point(0, 0)
	private rotation: number = 0
	private w: number = 0
	private h: number = 0
	private texWidth: number = 0
	private texHeight: number = 0
	private _scaleX: number = 1
	private _scaleY: number = 1


	constructor(tex: PIXI.Texture, x: number, y: number, zIndex: number, scaleX: number = 1, scaleY: number = 1, rot: number = 0, zOffset: number = 0, color: number[] = [1, 1, 1, 1], blendMode: PIXI.BLEND_MODES = PIXI.BLEND_MODES.NORMAL, debugName = '') {
		this.position.set(x, y)
		this.debugName = debugName
		this.z = zIndex
		this.rotation = rot
		this.texWidth = tex.trim ? tex.trim.width : tex.width
		this.texHeight = tex.trim? tex.trim.height : tex.height
		this.w = this.texWidth * scaleX
		this.h = this.texHeight * scaleY
		this._scaleX = scaleX
		this._scaleY = scaleY
		this.color = color

		// @ts-expect-error protected member access in pixi, wontfix
		const uvs = tex._uvs.uvsFloat32
		const uvExtents = [uvs[0], uvs[1], uvs[2] - uvs[0], uvs[5] - uvs[1]]

		this.renderable = {
			blendMode,
			color,
			pos: [x, y],
			rot,
			scale: [this.w, this.h],
			texture: tex.baseTexture,
			uvExtents,
		}
	}

	update(dt: number) {}

	render(renderQueue: RenderQueue) {
		renderQueue.addElement(this.renderable, RenderQueueElementType.InstancedSprite, this.zIndex)
	}

	setAlpha(alpha: number) {
		// yeah idk why this is how it has to be - Callum
		this.color[0] = alpha
		this.color[1] = alpha
		this.color[2] = alpha
		this.color[3] = alpha
	}
}

export class InstancedProjectile extends InstancedSprite {
	added: boolean = false

	constructor(tex: PIXI.Texture, x: number, y: number, zIndex: number, scale: number, rot: number, zOffset: number = 0, color: number[] = [1, 1, 1, 1]) {
		super(tex, x, y, zIndex, scale, rot, zOffset)
	}

	setAlpha(alpha: number): void {
		this.color[3] = alpha
		this.renderable.color[3] = alpha
	}
}
