import { Vector } from "sat"
import { PIERCE_DAMAGE_PER_PIERCE_REMAINING_MULTI } from "../buffs/buff-system"
import { GameState } from "../engine/game-state"
import { IEntity } from "../entities/entity-interfaces"
import EntityStatList from "../stats/entity-stat-list"
import { StatType } from "../stats/stat-interfaces-enums"
import { AllWeaponTypes } from "../weapons/weapon-types"

export interface DamageSource extends IEntity {
	weaponType: AllWeaponTypes
    statList: EntityStatList
    numEntitiesChained: number
	numEntitiesPierced: number
    showImmediateDamageNumber: boolean
    isPlayerOwned(): boolean
    getKnockbackDirection(mutableEntityPos: Vector): Vector
}

export function getDamageFromDamageSource(source: DamageSource) {
	const baseDamage = source.statList.getStat(StatType.baseDamage)
	let damageMultiplier = source.statList.getStat(StatType.allDamageMult)

	// @TODO I don't like how we get the flags/player
	if (GameState.player.binaryFlags.has('chain-damage-per-chain-remaining')) {
		const bonusData = GameState.player.binaryFlagState['chain-damage-per-chain-remaining'] as any
		const chainsRemaining = source.statList.getStat(StatType.projectileChainCount) - source.numEntitiesChained
		damageMultiplier *= 1 + (bonusData.damageBonus * chainsRemaining)
	}

	if (GameState.player.binaryFlags.has('pierce-damage-per-pierce-remaining')) {
		const pierceReamining = source.statList.getStat(StatType.attackPierceCount) - source.numEntitiesPierced
		damageMultiplier *= 1 + (PIERCE_DAMAGE_PER_PIERCE_REMAINING_MULTI * pierceReamining)
	}

	return baseDamage * damageMultiplier
}

