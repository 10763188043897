import { Vector } from "sat"
import { GameState } from "../../engine/game-state"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import EntityStatList from "../../stats/entity-stat-list"
import { timeInSeconds } from "../../utils/primitive-types"
import { Pet, PetCollectionName } from "./pet"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import { PlayerProjectile } from "../../projectiles/projectile"
import { ResourceType } from "../../weapons/weapon-definitions"
import { ParticleEffectType } from "../../engine/graphics/pfx/particle-config"

enum TsunamiPetState {
    IDLE,
    GO_TO_PLAYER,
    WAITING_AT_PLAYER
}

const GO_TO_PLAYER_TIME: timeInSeconds = 0.75
const PLAYER_OFFSET_POS: Vector = new Vector(0, 200)

const TSUNAMI_MAX_TIME: timeInSeconds = 1.0

const KNOCKBACK_FORCE: number = 1_200

// Projectile stuff
const PROJECTILE_COUNT = 20
const PROJECTILE_SPEED = 850
const PROJECTILE_SIZE = 80
const PROJECTILE_LIFESPAN: timeInSeconds = 1.5

export class TsunamiPet extends Pet {
    private state: TsunamiPetState = TsunamiPetState.IDLE
    private timeInState: timeInSeconds = 0

    private tsunamiUpdateTime: timeInSeconds = 0

    private startPosition: Vector = new Vector()
    private tsunamiOriginPosition: Vector = new Vector()

    constructor(name: PetCollectionName, parentStatList: EntityStatList, position: Vector, uncaged?: boolean) {
        super(name, parentStatList, position, uncaged)
    }

    resetStatsFn(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.attackKnockback = KNOCKBACK_FORCE

        statList._actualStatValues.attackPierceCount = 999_999
        statList._actualStatValues.baseDamage = 0
        statList._actualStatValues.projectileLifeSpan = PROJECTILE_LIFESPAN

        statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.cooldownInterval = 21_000
		statList._actualStatValues.reloadInterval = 50,
		statList._actualStatValues.reloadAmmoIncrement = 1
    }

    useAbility() {
        const player = GameState.player

        this.startPosition.copy(this.position).sub(player.position)

        this.timeInState = 0
        this.state = TsunamiPetState.GO_TO_PLAYER
    }

    update(delta: timeInSeconds): void {
        switch(this.state) {
            case TsunamiPetState.IDLE:
                super.update(delta)
                break
            case TsunamiPetState.GO_TO_PLAYER:
                this.updateGoToPlayer()
        		this.gfx.update(delta)
                break
            case TsunamiPetState.WAITING_AT_PLAYER:
                this.updateTsunami(delta)
                this.keepPositionWithPlayer()
        		this.gfx.update(delta)
                break
        }

        this.timeInState += delta
    }

    private updateGoToPlayer() {
        if (this.timeInState >= GO_TO_PLAYER_TIME) {
            this.timeInState = 0
            this.state = TsunamiPetState.WAITING_AT_PLAYER

            // fire the wave!
            this.tsunamiOriginPosition.copy(this.position)
            
            this.fireProjectiles()

            this.keepPositionWithPlayer()
        } else {
            const player = GameState.player
            const percentDone = this.timeInState / GO_TO_PLAYER_TIME
            this.position.x = Math.lerp(player.x + this.startPosition.x, player.x + PLAYER_OFFSET_POS.x, percentDone)
            this.position.y = Math.lerp(player.y + this.startPosition.y, player.y + PLAYER_OFFSET_POS.y, percentDone)
        }
    }

    private fireProjectiles() {
        const player = GameState.player
        for (let i =0; i < PROJECTILE_COUNT; ++i) {
            const angle = (i/PROJECTILE_COUNT) * Math.PI * 2
            PlayerProjectile.objectPool.alloc({
                owningEntityId: player.nid,
                position: this.tsunamiOriginPosition,
                speed: PROJECTILE_SPEED,
                aimAngleInRads: angle,
                trajectoryMods: [],
                radius: PROJECTILE_SIZE,
                collisionLayer: CollisionLayerBits.PlayerProjectile,
                statList: this.statList,
                isPrimaryWeaponProjectile: false,
                resourceType: ResourceType.FREE,
                energy: 100,
                effectType: ParticleEffectType.SEA_TURTLE_TSUNAMI,
                trailType: ParticleEffectType.PROJECTILE_NONE,
                player: player,
                ignoreProps: true
            })
        }
    }

    private updateTsunami(delta: timeInSeconds) {
        this.tsunamiUpdateTime += delta

        if (this.timeInState >= TSUNAMI_MAX_TIME) {
            this.timeInState = 0
            this.tsunamiUpdateTime = 0
            this.state = TsunamiPetState.IDLE
            this.finishedPetAbility()
        }

        return
    }

    private keepPositionWithPlayer() {
        const player = GameState.player
        
        this.position.x = player.x + PLAYER_OFFSET_POS.x
        this.position.y = player.y + PLAYER_OFFSET_POS.y 
    }
}