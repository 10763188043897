<template>
	<Panel id="generate-link-code" :panel-identifier="'generate-link-code'" :height="-1">
		<template #panel-content>
            <div class="container">
                <div class="header" :lang="this.$i18n.locale">
                    {{$t('account_link.generate_link_code')}}
                </div>
                <div class="description" :lang="this.$i18n.locale">
                    {{$t('account_link.link_code_description')}}
                </div>
                <div class="code-container">
            		<SmallPanel :height="75">
            			<template #small-panel-content>
			        		<CopyToClipboard class="text-field" :lang="this.$i18n.locale" :copy-text="generatedLinkCode" :placeholder-title="linkCodeText" :display-text="linkCodeText" :copiedText="'Copied to Clipboard'"></CopyToClipboard>
                        </template>
                    </SmallPanel>
                </div>
                <div class="buttons">
                    <TextButton :button-label="$t('account_link.back')" :button-style="'blue'" @on-click-fn="goBack"></TextButton>
                </div>
            </div>
        </template>
    </Panel>
</template>

<script lang="ts">
import { mapActions, mapState, mapMutations } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import CopyToClipboard from '../ui-components/labels/copy-to-clipboard.vue'
import SmallPanel from '../ui-components/panel/small-panel.vue'
import { Views } from '../store/ui-store'

export default {
    name: 'GenerateLinkCode',
    components: {
        Panel,
        TextButton,
        CopyToClipboard,
        SmallPanel
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState('user', ['generatedLinkCode', 'isPlayerCodeInfoLoading', 'linkCodeGenerationError']),
        linkCodeText() {
            if (this.isPlayerCodeInfoLoading) {
                'Generating...'
            }
            
            if (this.generatedLinkCode.length > 0) {
                return this.generatedLinkCode
            }

            return 'uhh link code text'
        }
    },
    methods: {
        ...mapActions('user', ['generateAccountLinkCode']),
        ...mapMutations('ui', ['setActiveView']),
        goBack() {
            this.setActiveView(Views.SETTINGS)
        }
    },
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#generate-link-code {
    width: 650px;
    display: flex;
    flex-direction: column;
	align-items: center;
    justify-content: center;

    .container {
        .header {
            color: @cream;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 50px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
        }

        .description {
            color: @offWhite;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansRegularFont);
			}

            margin-left: 5px;
            margin-right: 5px;
        }

        .error {
            color: @red;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansRegularFont);
			}

            margin-left: 5px;
            margin-right: 5px;
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        .code-container {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            .text-field {
                display: flex;
                align-items: center;
                height: 100%;
                margin: 0px 30px 0px 15px;
                color: @darkGreen;
                &[lang='en'] {
                    color: @green;
                    .regularFont(@enRegularFontPixl);
                    font-variant: small-caps;
                    font-size: 40px;
                }
            }
        }

        .input-container {
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            margin-top: 10px;

            input {
                outline: none;
                height: 35px;
                border: 0px solid red;
                width: 400px;
                background-color: #040e26 !important;
                font-size: 18px;
                color: @darkGreen;
                margin-bottom: 10px;
                &[lang='en'] {
                    .regularFont(@enSecondaryFontPTBold);
                    font-size: 24px;
                }
                &[lang='zh'] {
                    .regularFont(@zhHansHeaderFont);
                    font-size: 24px;
                }
                &.error {
                    border: 2px solid red;
                    border-radius: 2px;
                    filter: drop-shadow(0px 0px 2px red);
                }
            }
        }
    }
}
</style>