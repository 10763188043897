import { nextTick } from "vue"
import { MUTATOR_DEFINITIONS } from "../../mutators/mutator-definitions"
import { getStoryActivities, longPollGetStoryActivities } from "../../utils/api/griddle-requests"
import { Views } from "./ui-store"

export type ActivityMessageType = 'won' | 'lost' | 'voted' | 'twist-won' | 'chat' | 'wishlist'

export type ActivityMessage = {
	type: ActivityMessageType
	createdAt: string
	player?: string
	message?: string
	chapter?: number
	twist?: string
	time?: string
	score?: number
}

export const ActivityMessageTypeI18n = {
	'won': 'feed_msg_won',
	'lost': 'feed_msg_lost',
	'voted': 'feed_msg_voted',
	'twist-won': 'feed_msg_twist_won',
	'chat': 'feed_msg_chat',
	'wishlist': 'feed_msg_wishlist'
}

const WISHLIST_ACTIVITY_MESSAGE: ActivityMessage = {
	type: 'wishlist',
	createdAt: ""
}

const initialState: {
	activityMessages: Array<ActivityMessage>,
	chatFocused: boolean,
	chatMessage: string,
	chatScrollbar: any,
	largeFeedSize: boolean,
	showActivityFeedModal: boolean,
	oldLongPollStoryId: string,
	longPollAbortController: AbortController
} = {
	activityMessages: [
	],
	chatFocused: false,
	chatMessage: '',
	chatScrollbar: null,
	largeFeedSize: true,
	showActivityFeedModal: false,
	oldLongPollStoryId: '',
	longPollAbortController: null
}

const store = {
	namespaced: true,
	modules: {
	},
	state: initialState,
	getters: {
		chatFocused(state: ActivityFeedState) {
			return state.chatFocused
		},
	},
	mutations: {
		toggleLargeFeed(state: ActivityFeedState, toggle: boolean) {
			state.largeFeedSize = toggle
		},
		updateActivityFeed(state: ActivityFeedState, messages: Array<ActivityMessage>) {
			state.activityMessages = []
			for (let i=0; i < messages.length; ++i) {
				setActivityProperties(messages[i])
			}

			//Disable Steam wishlist for release
			// if (messages.length > 4) {
			// 	messages.splice(messages.length - 5, 0, WISHLIST_ACTIVITY_MESSAGE)
			// } else {
			// 	messages.push(WISHLIST_ACTIVITY_MESSAGE)
			// }

			state.activityMessages = messages

			if (state.chatScrollbar) {
				nextTick(() => {
					state.chatScrollbar.scrollTop = state.chatScrollbar.scrollHeight
				})
			}
		},
		updateActivityFeedModal(state: ActivityFeedState, toggle: boolean) {
			state.showActivityFeedModal = toggle
		},
		addNewActivityMessage(state: ActivityFeedState, message: ActivityMessage) {
			state.activityMessages.push(message)

			let scrollToBottom
			if (state.chatScrollbar) {
				if (state.chatScrollbar.offsetHeight === 0) {
					scrollToBottom = true
				} else {
					const diff = Math.abs(state.chatScrollbar.scrollTop - (state.chatScrollbar.scrollHeight - state.chatScrollbar.offsetHeight))
					scrollToBottom = diff < 7
				}
			} else {
				scrollToBottom = false
			}

			if (scrollToBottom) {
				nextTick(() => {
					state.chatScrollbar.scrollTop = state.chatScrollbar.scrollHeight
				})
			}
		},
		setChatMessage(state: ActivityFeedState, message: string) {
			state.chatMessage = message
		},
		setChatScrollbar(state: ActivityFeedState, scrollbar) {
			state.chatScrollbar = scrollbar
		},
		setChatFocus(state: ActivityFeedState, focused: boolean) {
			state.chatFocused = focused
		}
	},
	actions: {
		async fetchLatestActivity({ commit, state, rootGetters }: { commit: any, state: ActivityFeedState, rootGetters: any }) {
			const story = rootGetters['story/getSelectedStory']
			try {
				const messages = await getStoryActivities(story.id)
				commit('updateActivityFeed', messages)
			} catch (error) {
				console.error('There was an error when trying to fetch the latest activity feed messages.', error)
			}
		},
		startLongPollGetNewActivity({ commit, state, rootGetters, dispatch }) {
			const story = rootGetters['story/getSelectedStory']
			try {
				const id = story.id
				if (id === state.oldLongPollStoryId) {
					return
				} else if (state.longPollAbortController) {
					state.longPollAbortController.abort()
					state.longPollAbortController = null
				}

				const abortController = longPollGetStoryActivities(id, (apiResults) => {
					const data = apiResults.data
					// add new message with 'data'
					const newStory = rootGetters['story/getSelectedStory']
					if (newStory && newStory.id === id) {
						commit('addNewActivityMessage', data)
						state.oldLongPollStoryId = ''
						state.longPollAbortController = null
						const view = rootGetters['ui/getActiveView']
						if (view === Views.CHARACTER_SELECT || view === Views.END_CHAPTER || view === Views.CHAPTER_SELECT) {
							dispatch('startLongPollGetNewActivity')
						}
					}
				}, () => {
					const newStory = rootGetters['story/getSelectedStory']
					if (newStory && newStory.id === id) {
						state.oldLongPollStoryId = ''
						state.longPollAbortController = null
						const view = rootGetters['ui/getActiveView']
						if (view === Views.CHARACTER_SELECT || view === Views.END_CHAPTER || view === Views.CHAPTER_SELECT) {
							dispatch('startLongPollGetNewActivity')
						}
					}
				})

				state.oldLongPollStoryId = id
				state.longPollAbortController = abortController
			} catch (err) {
				console.error(`error while polling game activity`, err)
			}
		},
		cancelLongPollGetNewActivity({ commit, state }) {
			if (state.longPollAbortController) {
				state.longPollAbortController.abort()
				state.longPollAbortController = null
			}
			state.oldLongPollStoryId = ''
		}
	}
}

function setActivityProperties(activity: ActivityMessage) {
	switch(activity.type) {
		case 'twist-won':
		case 'voted':
			const twist = activity.twist
			const definition = MUTATOR_DEFINITIONS[twist]
			if (definition) {
				activity.twist = definition.name
			}
			break
	}
}

export type ActivityFeedState = typeof initialState
export const ActivityFeed = typeof store

export const activityFeed = () => {
	return store
}
