import { EntityType, IEntity } from "../entities/entity-interfaces"
import { Player } from "../entities/player"
import { PlayerProjectile } from "../projectiles/projectile"
import { Buff } from '../buffs/buff'
import { Cooldown } from "../cooldowns/cooldown"
import { DESTRUCTIBLE_DROP_WEIGHTED_LIST_VALUES, GROUND_PICKUP_HEARTS_WEIGHTED_LIST_VALUES, GROUND_PICKUP_WEIGHTED_LIST_VALUES, GroundPickup } from "../entities/pickups/ground-pickup"
import { KillEnemiesInCirclePOI } from "../pois/kill-enemies-in-circle"
import { Pet } from "../entities/pets/pet"
import { UI } from "../ui/ui"
import { EnemyProjectile } from "../projectiles/enemy-projectile"
import { Beam } from "../beams/beams"
import { MapConfig, MapConfigRecord, MapOption } from "../world-generation/world-data"
import { debugConfig } from "../utils/debug-config"
import WeightedList from "../utils/weighted-list"
import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"

let lastNid = 10000
export function getNID(entity: IEntity) {
	lastNid++
	return lastNid
}

type EntityMap = Map<number, IEntity>

class GameStateInternal {
	static instance: GameStateInternal = new GameStateInternal()

	readonly entityList: object[] = []
	readonly entityMap: EntityMap = new Map()
	readonly entityCountOverTimeMap: Map<EntityType, number> = new Map()

	readonly _entityListByType: Map<EntityType, IEntity[]> = new Map()
	readonly _entityMapByType: Map<EntityType, EntityMap> = new Map()


	//TODO: how can we type this to return an Enemy[]?
	get enemyList() {
		return this._entityListByType.get(EntityType.Enemy)
	}
	//TODO: how can we type this to return a Map<nid, Enemy>?
	get enemyMap() {
		return this._entityMapByType.get(EntityType.Enemy)
	}

	get playerProjectileList(): PlayerProjectile[] {
		// eughPlayerProjectile
		return this._entityMapByType.get(EntityType.Projectile) as unknown as PlayerProjectile[]
	}

	get playerProjectileMap() {
		return this._entityMapByType.get(EntityType.Projectile)
	}

	get enemyProjectileList(): EnemyProjectile[] {
		return this._entityMapByType.get(EntityType.EnemyProjectile) as unknown as EnemyProjectile[]
	}

	get buffList(): Buff[] {
		return this._entityMapByType.get(EntityType.Buff) as unknown as Buff[]
	}

	get cooldownList(): Cooldown[] {
		return this._entityListByType.get(EntityType.Cooldown) as unknown as Cooldown[]
	}

	get pickupList(): GroundPickup[] {
		return this._entityListByType.get(EntityType.Pickup) as unknown as GroundPickup[]
	}

	get petsList(): Pet[]{
		return this._entityListByType.get(EntityType.Pet) as unknown as Pet[]
	}

	get beamList(): Beam[] {
		return this._entityListByType.get(EntityType.Beam) as unknown as Beam[]
	}

	get poiList(): any[] {
		return this._entityListByType.get(EntityType.POI) as unknown as any[]
	}

	protected _player: Player
	get player(): Player {
		return this._player
	}
	get playerNID(): number {
		return this._player.nid
	}
	// userAccount: UserAccount

	enemyHeartPickups: WeightedList<GroundPickupConfigType>
	enemyGroundPickups: WeightedList<GroundPickupConfigType>
	propGroundPickups: WeightedList<GroundPickupConfigType>

	constructor() {
		for (const type of Object.values(EntityType)) {
			if (typeof type === 'string') {
				const entityType = EntityType[type]
				this._entityListByType.set(entityType, [])
				this._entityMapByType.set(entityType, new Map())
				this.entityCountOverTimeMap.set(entityType, 0)
			}
		}

		this.enemyHeartPickups = new WeightedList(GROUND_PICKUP_HEARTS_WEIGHTED_LIST_VALUES)
		this.enemyGroundPickups = new WeightedList(GROUND_PICKUP_WEIGHTED_LIST_VALUES)
		this.propGroundPickups = new WeightedList(DESTRUCTIBLE_DROP_WEIGHTED_LIST_VALUES)
	}

	setPlayer(player: Player) {
		this._player = player
	}

	/**
	 * Add an entity to GameState
	 * @param entity The entity to add to game state
	 * @param addToEntityList Don't add this to the entityList for update purposes.
	 * We should probably try to keep updates system specific here but this is a workaround
	 */
	addEntity(entity: IEntity, addToEntityList = true) {
		if (addToEntityList) {
			this.entityList.push(entity)
		}
		this.entityMap.set(entity.nid, entity)
		this._entityListByType.get(entity.entityType).push(entity)
		this._entityMapByType.get(entity.entityType).set(entity.nid, entity)
		const count = this.entityCountOverTimeMap.get(entity.entityType) + 1
		this.entityCountOverTimeMap.set(entity.entityType, count)
		if (entity.entityType === EntityType.Enemy) {
			UI.getInstance().emitMutation('debug/updateEnemiesInWorld', this.enemyList.length)
			UI.getInstance().emitMutation('debug/updateEnemiesSpawnedLifetime', count)
		} else if (entity.entityType === EntityType.Pickup) {
			UI.getInstance().emitMutation('debug/updatePickupsInWorld', this.pickupList.length)
		}
	}

	removeEntity(entity: IEntity) {
		this.entityList.remove(entity)
		this.entityMap.delete(entity.nid)
		this._entityListByType.get(entity.entityType).remove(entity)
		this._entityMapByType.get(entity.entityType).delete(entity.nid)
		if (entity.entityType === EntityType.Enemy) {
			UI.getInstance().emitMutation('debug/updateEnemiesInWorld', this.enemyList.length)
		} else if (entity.entityType === EntityType.Pickup) {
			UI.getInstance().emitMutation('debug/updatePickupsInWorld', this.pickupList.length)
		}
	}

	cleanUp() {
		this._player.destroy()

		this.entityList.length = 0
		this.entityMap.clear()
		this.entityCountOverTimeMap.clear()
		this._entityListByType.clear()
		this._entityMapByType.clear()
		this._player = undefined
		
		for (const type of Object.values(EntityType)) {
			if (typeof type === 'string') {
				const entityType = EntityType[type]
				this._entityListByType.set(entityType, [])
				this._entityMapByType.set(entityType, new Map())
				this.entityCountOverTimeMap.set(entityType, 0)
			}
		}

		this.enemyHeartPickups = new WeightedList(GROUND_PICKUP_HEARTS_WEIGHTED_LIST_VALUES)
		this.enemyGroundPickups = new WeightedList(GROUND_PICKUP_WEIGHTED_LIST_VALUES)
		this.propGroundPickups = new WeightedList(DESTRUCTIBLE_DROP_WEIGHTED_LIST_VALUES)
	}
}

/**
 * GameState is a central state management repository for **top-level objects** that contain meaningful state.
 * Lifecycle functions should be used (and added) as necessary, and you should not mutate the internal state anywhere outside of this class.
 *
 * What is a top-level object? These are usually entities or systems that are either responsible for their own behavior
 * (`Player`, `CollisionSystem`), or important objects that only have one parent system/manager/etc. above them (`Enemy`, `GroundPickup`, `Prop`).
 *
 * Objects that are fully "owned" by something else (e.g. the `Graphics`, `Model`, `Cooldowns`, etc. on a `Player`) likely do not belong here,
 * as their state is fully managed by their owner/parent (`Player`, in this case).
 */
export const GameState = GameStateInternal.instance