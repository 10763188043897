import logger from './client-logger'
import { VectorXY } from './math'
import { timeInSeconds } from './primitive-types'

/**
 * add simple animations to anything:
 *  simpleAnimation_addAnimation(player, (t) => player.scale.x = Math.sin(t))  // #1
 *  simpleAnimation_addScaleAnimation(player, (t) => Math.sin(t))  // #2
 *  simpleAnimation_addPropertyAnimation(player.scale, 'x', (t) => Math.sin(t))  // #3
 *
 * you MUST remember to remove them
 *  simpleAnimation_removeAnimations(player)  // handles #1 and #2 above
 *  simpleAnimation_removeAnimations(player.scale)  // handles #3 above
 * 
 * OR, you can supply a duration to remove them after:
 *  simpleAnimation_addAnimation(p,f, duration)
 */

export type AnimatedNumber = (time: timeInSeconds, dt: timeInSeconds) => number
export type AnimatedVector = (time) => VectorXY

const DEBUG_LEVEL = 0
const INVALID_TIME = -1

interface Scalable {
	scale: number
}

interface AnimatedObject {
	obj: object
	property: string
	animation: AnimatedNumber
	timeSeconds: timeInSeconds
	durationSeconds: timeInSeconds
}

export function simpleAnimation_addAnimation(obj: any, animation: AnimatedNumber, durationSeconds: timeInSeconds = INVALID_TIME) {
	if (DEBUG_LEVEL) {
		logger.debug(`added anim to ${obj.name}, count:${animatedObjects.length}`)
	}
	animation(0, 0)
	animatedObjects.push({ obj, property: undefined, animation, timeSeconds: 0, durationSeconds })
}

export function simpleAnimation_addPropertyAnimation(obj: any, property: string, animation: AnimatedNumber, durationSeconds: timeInSeconds = INVALID_TIME) {
	if (DEBUG_LEVEL) {
		logger.debug(`added anim to ${obj.name} on property:${property}, count:${animatedObjects.length}`)
	}
	obj[property] = animation(0, 0)
	animatedObjects.push({ obj, property, animation, timeSeconds: 0, durationSeconds })
}

export function simpleAnimation_addScaleAnimation(obj: Scalable, scale: AnimatedNumber) {
	simpleAnimation_addPropertyAnimation(obj, 'scale', scale)
}

export function simpleAnimation_removeAnimations(obj: any) {
	animatedObjects = animatedObjects.filter((ao) => ao.obj !== obj)
	if (DEBUG_LEVEL) {
		logger.debug(`removed anim, count:${animatedObjects.length}`)
	}
}

export function simpleAnimation_update(delta: timeInSeconds) {
	animatedObjects.forEach((ao) => {
		ao.timeSeconds += delta
		const value = ao.animation(ao.timeSeconds, delta)
		if (ao.property) {
			ao.obj[ao.property] = value
		}
		if (DEBUG_LEVEL >= 2) {
			logger.debug(`t:${ao.timeSeconds} prop=${ao.property}:${value}`)
		}
	})

	// remove any animations that have a maxTime set that are expired
	animatedObjects = animatedObjects.filter(ao => {
		return ao.durationSeconds === INVALID_TIME || ao.timeSeconds < ao.durationSeconds
	})
}

export function simpleAnimation_shutdown() {
	animatedObjects = []
}

export function simpleAnimation_numAnimations() {
	return animatedObjects.length
}

let animatedObjects: AnimatedObject[] = []
