<template>
	<div id="text-container">
		<SmallPanel :height="65">
			<template #small-panel-content>
				<CopyToClipboard class="text-field" placeholder-title="Copy sharable link to clipboard" :lang="this.$i18n.locale" :display-text="customText" :copy-text="copyGameInfo(storyLinkURL, customText)"></CopyToClipboard>
			</template>
		</SmallPanel>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SmallPanel from '../panel/small-panel.vue'
import CopyToClipboard from '../labels/copy-to-clipboard.vue'
import CopyGameInformation from '../mixins/CopyGameInfomation'

export default {
	name: 'GameIdContainer',
	components: {
		SmallPanel,
		CopyToClipboard,
	},
	data() {
		return {}
	},
	props: {
		customText: {
			type: String,
			default: '',
			required: true,
		},
	},
	computed: {
		...mapGetters('ui', ['storyLinkURL']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
	mixins: [CopyGameInformation]

}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#text-container {
	.text-field {
		display: flex;
		align-items: center;
		height: 100%;
		margin: 0px 30px 0px 15px;
		color: @darkGreen;
		&[lang='en'] {
			color: @offWhite;
			.regularFont(@enRegularFontPixl);
			font-variant: small-caps;
			font-size: 40px;
		}
	}
}
</style>
