import { Vector } from "sat"
import { CooldownDefinition } from "../../cooldowns/cooldown"
import { Audio } from "../../engine/audio"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem, { getClosestNotHitEntity } from "../../engine/collision/collision-system"
import { getDamageByPlayerLevel } from "../../game-data/player-formulas"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import EntityStatList, { StatBonus } from "../../stats/entity-stat-list"
import { StatOperator, StatType } from "../../stats/stat-interfaces-enums"
import { timeInSeconds } from "../../utils/primitive-types"
import { vectorLerpMutate } from "../../utils/vector"
import { Enemy } from "../enemies/enemy"
import { Pet, PetCollectionName } from "./pet"

const DAMAGE_MULTIPLIER = 4
const MAX_TIME_BETWEEN_POUNCES = 2
const POUNCE_DELAY = 0.1
const POUNCE_SPEED_SQUARED = 650_000
const CHAIN_DISTANCE = 400

const TARGET_SEARCH_FRAME_SKIP = 4

export class PouncingPet extends Pet {
	damageBonus: StatBonus

    isPouncing: boolean = false
    elapsedPounceTime: number = 0

    nextPounceTarget: Enemy
    nextPounceTargetSavedPosition: Vector
    hasNextPounceTarget: boolean = false

    targetSearchFrame: number = 0

    pounceOriginPosition: Vector
    pounceTravelTime: number
    pouncingTime: number

    hitEnemies: number[] = []

    private pouncedOnce: boolean = false

    constructor(name: PetCollectionName, parentStatList: EntityStatList, position: Vector, uncaged?: boolean) {
        super(name, parentStatList, position, uncaged)
		this.damageBonus = this.statList.addStatBonus("baseDamage", StatOperator.SUM, 0) as StatBonus

        this.nextPounceTargetSavedPosition = new Vector()
        this.pounceOriginPosition = new Vector()

        this.canDoubleAttack = true
    }

    resetStatsFn(statList: EntityStatList) {
        defaultStatAttribute(statList)
        statList._actualStatValues.baseDamage = 0
        statList._actualStatValues.projectileChainCount = 11
        statList._actualStatValues.allDamageMult = 1
        statList._actualStatValues.attackSize = 30

        statList._actualStatValues.maxAmmo = 1
        statList._actualStatValues.cooldownInterval = 14_000
        statList._actualStatValues.reloadInterval = 50
        statList._actualStatValues.reloadAmmoIncrement = 1
    }

    useAbility() {
      this.setFollowPosition()
      this.damageBonus.update(getDamageByPlayerLevel() * DAMAGE_MULTIPLIER)
      this.numEntitiesChained = 0
      this.numEntitiesPierced = 0
      this.isPouncing = true
      this.elapsedPounceTime = POUNCE_DELAY // don't stand in same spot waiting for next pounce on the first pounce
      this.pounceTravelTime = 0

      this.nextPounceTargetSavedPosition.x = 0
      this.nextPounceTargetSavedPosition.y = 0

      this.hasNextPounceTarget = false
      this.hitEnemies.length = 0

      this.pouncedOnce = false
    }

    override update(delta: timeInSeconds): void {
        if (!this.isPouncing) {
            this.useFollowPosition = false
            super.update(delta)
        } else {
            this.elapsedPounceTime += delta

            if (this.hasNextPounceTarget) {
                this.updateFollow(delta)
                if (this.nextPounceTarget && this.nextPounceTarget.isDead()) {
                    // dude died while we were on the way to beat his ass
                    this.nextPounceTargetSavedPosition.copy(this.nextPounceTarget.position)
                    this.nextPounceTarget = null
                }

                const targetPos = this.nextPounceTarget ? this.nextPounceTarget.position : this.nextPounceTargetSavedPosition
                if (this.elapsedPounceTime >= this.pouncingTime) {
                    this.position.copy(targetPos)

                    if (this.nextPounceTarget) {
                        this.nextPounceTarget.onHitByDamageSource(this)
                        Audio.getInstance().playSfx('SFX_Enemy_Ent_Swipe')
                        this.hitEnemies.push(this.nextPounceTarget.nid)
                        this.nextPounceTarget = null
                        this.pouncedOnce = false
                    }

                    this.hasNextPounceTarget = false
                    this.elapsedPounceTime = 0
                    this.numEntitiesChained++

                    if (this.numEntitiesChained >= this.statList.getStat(StatType.projectileChainCount)) {
                        this.isPouncing = false
                        this.finishedPetAbility()
                    }

                } else {
                    vectorLerpMutate(this.position, this.pounceOriginPosition, targetPos, this.elapsedPounceTime / this.pouncingTime)
                }
            } else {
                if (this.elapsedPounceTime > POUNCE_DELAY) {
                    this.useFollowPosition = false
                    super.update(delta) // moving slightly back towards the player while searching

                    if (this.targetSearchFrame++ % TARGET_SEARCH_FRAME_SKIP === 0) {
                        this.findNextPounceTarget()
                    }

                    if (this.pouncedOnce && this.elapsedPounceTime >= MAX_TIME_BETWEEN_POUNCES) {
                        this.isPouncing = false
                        this.finishedPetAbility()
                    }
                }

            }

            this.gfx.update(delta)
        }
    }

    private findNextPounceTarget() {
        const enemies = CollisionSystem.getInstance().getEntitiesInArea(this.position, CHAIN_DISTANCE, CollisionLayerBits.HitEnemyOnly)
        const closestEnemy = getClosestNotHitEntity(enemies, this.position, this.hitEnemies)

        if (closestEnemy) {
            this.nextPounceTarget = closestEnemy.owner as Enemy
            this.nextPounceTargetSavedPosition.copy(this.nextPounceTarget.position)
            this.hasNextPounceTarget = true
            this.elapsedPounceTime = 0

            this.pounceOriginPosition.copy(this.position)
            this.pouncingTime = this.nextPounceTargetSavedPosition.sub(this.position).len2() / POUNCE_SPEED_SQUARED
            this.setFollowPosition()
        }
    }
}
