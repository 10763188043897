export const enum Environments {
	LOCAL = 'local',
	STAGING = 'staging',
	ALPHA = 'alpha',
	QA = 'qa',
	BETA = 'beta',
	PRODUCTION = 'production',
}

type NonSensitiveEnvVarMapping = {
	[environment in Environments]: {
		LOG_LEVEL: string
		GRIDDLE_API_ADDRESS: string,
		GAME_URL: string
	}
}

export const nonSensitiveEnvVarsMapping: NonSensitiveEnvVarMapping = {
	[Environments.LOCAL]: {
		LOG_LEVEL: 'debug',
		GRIDDLE_API_ADDRESS: 'https://alpha-griddle.bitfox.dev',
		// GRIDDLE_API_ADDRESS: 'http://localhost:10000',
		GAME_URL: 'http://localhost:1234',
	},
	[Environments.STAGING]: { // unused, just for testing what prod looks like
		LOG_LEVEL: 'debug',
		GRIDDLE_API_ADDRESS: 'https://alpha-griddle.bitfox.dev',
		// GRIDDLE_API_ADDRESS: 'http://localhost:10000',
		GAME_URL: 'http://localhost:1234',
	},
	[Environments.ALPHA]: {
		LOG_LEVEL: 'debug',
		GRIDDLE_API_ADDRESS: 'https://alpha-griddle.bitfox.dev',
		GAME_URL: 'https://hotcakes.pages.dev',
	},
	[Environments.QA]: {
		LOG_LEVEL: 'debug',
		GRIDDLE_API_ADDRESS: 'https://qa-atlas.bitfox.dev',
		GAME_URL: 'https://qa.hotcakes.pages.dev',
	},
	[Environments.BETA]: {
		LOG_LEVEL: 'debug',
		GRIDDLE_API_ADDRESS: 'https://beta-griddle.bitfox.dev',
		GAME_URL: 'https://beta.diebrary.rip',
	},
	[Environments.PRODUCTION]: {
		LOG_LEVEL: 'debug',
		GRIDDLE_API_ADDRESS: 'https://prod-griddle.bitfox.dev',
		GAME_URL: 'https://play.diebrary.rip',
	},
}
