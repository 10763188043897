<template>
	<Panel id="signup-page" :panel-identifier="'sign-up'" :height="panelHeight">
		<template #panel-content>
			<div class="signup-content">
				<div class="header" :lang="this.$i18n.locale">
					{{ $t('sign_up.create_account') }}
				</div>
				<div class="form-progressbar">
					<div class="row">
						<div class="col step-1 active">
							<i class="badge circle" :class="{ active: formStep === 1, complete: formStep !== 1 }"></i>
						</div>
						<div class="col step-2">
							<i class="badge circle" :class="{ active: formStep === 2, complete: formStep === 3 }"></i>
						</div>
						<div class="col step-3">
							<i class="badge circle" :class="{ active: formStep === 3, complete: false }"></i>
						</div>
					</div>
					<div class="styled-line" :class="{half: formStep === 2, full: formStep === 3}"></div>
				</div>
				<div id="form-step-1" v-if="formStep === 1" class="create-username">
					<div>
						<label for="username" :lang="this.$i18n.locale">{{ $t('sign_up.username') }}</label>
						<input :class="{ error: errors.usernameTaken }" type="text" id="username" :lang="this.$i18n.locale" v-model="username" @input="resetForm" />
						<div :lang="this.$i18n.locale" :class="[errors.usernameLength | errors.usernameTaken | errors.invalidUsername ? 'error' : '']" class="text-error">
							<div v-for="errorMessage in errors.usernameMessage" :key="errorMessage">{{$t(errorMessage) }}</div>
						</div>
					</div>
				</div>
				<div id="form-step-2" v-if="formStep === 2" class="enter-email">
					<div>
						<label for="email" :lang="this.$i18n.locale">{{ $t('sign_up.email_label') }}</label>
						<input :class="{ error: errors.emailTaken }" type="text" id="email" :lang="this.$i18n.locale" v-model="emailAddress" @input="resetForm" />
						<div :lang="this.$i18n.locale" :class="[errors.emailTaken | errors.invalidEmailFormat ? 'error' : '']" class="text-error">
							<div v-for="errorMessage in errors.emailMessage" :key="errorMessage">{{$t(errorMessage) }}</div>
						</div>
					</div>
				</div>
				<div id="form-step-3" v-if="formStep === 3" class="enter-password">
					<div>
						<label for="password" :lang="this.$i18n.locale">{{ $t('sign_up.password_label') }}</label>
						<input :class="{ error: errors.passwordMatch || errors.passwordLength }" :type="passwordType" id="password" :lang="this.$i18n.locale" v-model="password" @input="resetForm" />
					</div>
					<div>
						<label for="confirmPassword" :lang="this.$i18n.locale">{{ $t('sign_up.confirm_password_label') }}</label>
						<input :class="{ error: errors.passwordMatch || errors.passwordLength }" :type="passwordType" id="confirmPassword" :lang="this.$i18n.locale" v-model="confirmPassword" @input="resetForm" />
					</div>
					<div class="checkbox-container">
						<Checkbox :label="'sign_up.terms'" :inline-label="true" @on-click-fn="tosClicked" :checked="termsOfUse" @select="resetForm" :error="errors.termsOfUse"></Checkbox>
					</div>
					<div class="checkbox-container">
						<Checkbox :label="'sign_up.ooi_contact'" :inline-label="true" @on-click-fn="allowContact = !allowContact" :checked="allowContact"></Checkbox>
					</div>
					<div :lang="this.$i18n.locale" :class="[errors.passwordMatch | errors.passwordLength | errors.termsOfUse ? 'error' : '']" class="text-error">
						<div v-for="errorMessage in errors.passwordMessage.concat(errors.termsOfUseMessage)" :key="errorMessage">{{ $t(errorMessage) }}</div>
					</div>
				</div>
				<div class="button-container">
					<TextButton :button-label="$t(formButtonLabel)" :button-style="'blue'" @on-click-fn="formButtonFn" :disabled="disableUserNameButton" :loading="fetchingUsernameStatus || fetchingEmailStatus"></TextButton>
					<TextButton :button-label="$t('sign_up.button_back')" :button-style="'blue'" @on-click-fn="previousStep"></TextButton>
				</div>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import Checkbox from '../ui-components/form-inputs/checkbox.vue'
import { Views } from '../store/ui-store'
import { ErrorCodes } from '../store/user-account-store'

export default {
	name: 'CreateAccount',
	components: {
		Panel,
		TextButton,
		Checkbox,
	},
	data() {
		return {
			emailAddress: '',
			username: '',
			formStep: 1,
			password: '',
			confirmPassword: '',
			Views: Views,
			ErrorCodes: ErrorCodes,
			termsOfUse: false,
			allowContact: false,
			passwordType: 'password',
			biEye: 'bi-eye-slash',
		}
	},
	props: {},
	watch: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('user', ['accountFormData', 'errors', 'fetchingUsernameStatus', 'fetchingEmailStatus', 'id', 'userType']),

		panelHeight() {
			switch (this.formStep) {
				case 1:
					return 511
				case 2:
					return 511
				case 3:
					return 848
			}
		},

		disableUserNameButton() {
			if (this.username.length < 3) {
				return true
			}
			return false
		},

		formButtonLabel() {
			switch (this.formStep) {
				case 1:
					return 'sign_up.button_next'
				case 2:
					return 'sign_up.button_next'
				case 3:
					return 'sign_up.button_create_account'
			}
		},
		spacerSize() {
			const content = document.getElementById('form-step-1')
			return `200px`
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		...mapActions('ui', ['startGame', 'changeActiveView']),
		...mapActions('user', ['submitRegister', 'checkEmailTakenStatus', 'checkUsernameTakenStatus']),
		...mapMutations('user', ['resetUsernameTaken', 'updateFormErrors', 'resetFormErrors']),

		resetForm() {
			this.resetFormErrors()
		},

		//TODO: Greeley - This will be needed when I enable transition
		formButtonFn() {
			switch (this.formStep) {
				case 1:
					this.checkUsername()
					break
				case 2:
					this.checkEmail()
					break
				case 3:
					this.submitForm()
					break
			}
		},

		previousStep() {
			if (this.formStep === 1) {
				this.changeActiveView(Views.LANDING)
			} else {
				this.formStep -= 1
			}
		},
		async checkUsername() {
			this.resetForm()
			await this.checkUsernameTakenStatus(this.username)
			if (!this.errors.usernameTaken && !this.errors.invalidUsername) {
				this.formStep += 1
			}
		},
		async checkEmail() {
			this.resetForm()
			await this.checkEmailTakenStatus(this.emailAddress)
			if (!this.errors.emailTaken) {
				this.formStep += 1
			}
		},
		tosClicked(){
			this.resetForm()
			this.termsOfUse = !this.termsOfUse
		},
		submitForm() {
			this.resetForm()
			if (this.password.length < 10) {
				this.updateFormErrors(ErrorCodes.INVALID_PASSWORD_LENGTH)
				return
			}

			if (this.password !== this.confirmPassword) {
				this.updateFormErrors(ErrorCodes.PASSWORD_DONT_MATCH)
				return
			}

			if (this.termsOfUse === false) {
				this.updateFormErrors(ErrorCodes.MUST_AGREE_TOS)
				return
			}

			let normalizedForm = {
				username: this.username,
				emailAddress: this.emailAddress.toLowerCase(),
				password: this.password,
				passwordConfirmation: this.confirmPassword,
				agreedToTerms: this.termsOfUse,
				agreedToPromotion: this.AllowContact ? true : false,
				sourceGame: 'hotcakes',
				isAnonymous: false,
				anonymousUserId: undefined
			}

			if (this.userType === 'anonymous') {
				normalizedForm.anonymousUserId = this.id
			}

			this.submitRegister(normalizedForm)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#signup-page {
	.signup-content {
		width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.header {
			color: @cream;
			text-align: center;
			margin-bottom: 10px;
			color: @green;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 50px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
		.form-progressbar {
			margin: 10px 0px;
			position: relative;
			text-align: center;
			width: 270px;
			.row {
				display: table;
				table-layout: fixed;
				width: 100%;
				margin: 0 0 10px;
				.col {
					display: table-cell;
					vertical-align: top;
					padding: 0 1.5rem;
					position: relative;
					transition-delay: 200ms;
					transition: all 500ms ease-in;
					&:first-child {
						padding-left: 0px;
						text-align: left;
					}
					&:last-child {
						padding-right: 0px;
						text-align: right;
					}
					&.complete {
						border: 2px solid @darkBlue;
						background: @green;
					}
				}
				.badge {
					display: inline-block;
					width: 30px;
					height: 30px;
					line-height: 30px;
					border-radius: 50%;
					text-align: center;
					background: @darkBlue;
					position: relative;
					z-index: 9;
					transition: all 500ms ease-in;
					transition-delay: 200ms;
					&.active {
						transform: scale(1.4);
						border: 2px solid @green;
					}
					&.error{
						transform: scale(1.4);
						border: 2px solid @darkBlue;
						background: red;	
					}
					&.complete {
						border: 2px solid @darkBlue;
						background: @green;
					}
				}
			}
			&::after {
				content: ' ';
				position: absolute;
				top: 13px;
				left: 0px;
				height: 7px;
				width: 99%;
				margin: 0px 1px;
				background: @darkBlue;
				z-index: 2;
			}
			.styled-line {
				height: 7px;
				width: 0%;
				z-index: 3;
				background: @green;
				margin: 0 1px;
				position: absolute;
				top: 13px;
				left: 0;
				transition: all 500ms ease-out;
				border: 2px solid @darkBlue;
				&.half{
					width: 50%;
				}
				&.full{
					width: 100%;
				}
			}
		}

		.create-username,
		.enter-email,
		.enter-password {
			width: 400px;
			label {
				display: block;
				margin: 10px 10px 5px 0px;
				    
				color: @offWhite;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 30px;
					font-variant: small-caps;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
					font-size: 24px;
				}
			}
			input {
				outline: none;
				height: 35px;
				border: 0px solid red;
				width: 400px;
				background-color: #040e26 !important;
				font-size: 18px;
				color: @darkGreen;
				margin-bottom: 10px;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 24px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
					font-size: 24px;
				}
				&.error {
					border: 2px solid red;
					border-radius: 2px;
					filter: drop-shadow(0px 0px 2px red);
				}
			}
			.bi {
				position: absolute;
				padding: 10px;

				&.bi-eye-slash {
				}
			}
			.checkbox-container {
				margin: 15px 0px 15px 0px;
				width: 400px;
			}

			.text-error {
				color: red;
				min-height: 26px;
				opacity: 0;
				transition: 500ms;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 20px;
					font-variant: small-caps;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
					font-size: 24px;
				}
				&.error {
					opacity: 1;
				}
			}
		}
		.button-container {
			.text-button {
				margin: 20px 0px;
				width: 410px;
			}
		}
	}
}
</style>
