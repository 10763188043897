import { sum } from "lodash"

class WeightedList<v> {
	weights: number[] = []
	data: v[] = []

	constructor(weightedListItems?: Array<[v, number]>) {
		if (weightedListItems) {
			this.pushMany(weightedListItems)
		}
	}

	push(weightedListItems: [v, number]) {
		this.data.push(weightedListItems[0])
		this.weights.push(weightedListItems[1])
	}

	pushMany(weightedListItems: Array<[v, number]>) {
		weightedListItems.forEach(([data, weight]) => {
			this.data.push(data)
			this.weights.push(weight)
		})
	}

	get length() {
		return this.weights.length
	}

	get totalWeightSum() {
		return sum(this.weights)
	}

	pickRandom(roll?: number) {
		if (roll < 0) {
			console.error('WeightedList pickRandom was handed a negative value - this is probably unexpected')
			roll = 0
		}
		if (this.data.length === 0 || this.weights.length === 0) {
			throw new Error('No data in the weighted list to pick from')
		}
		const weightSum = this.totalWeightSum
		roll = Math.floor((roll || Math.random()) * weightSum)
		let weightAcc = 0
		let index = 0
		let selected: [v, number]
		while (weightAcc <= roll) {
			selected = [this.data[index], this.weights[index]]
			weightAcc += selected[1]
			index++
		}
		return { roll, weightSum, value: selected }
	}
}

export default WeightedList
