import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { POI, POIParams } from "./poi"

export interface StandInCirclePOIParams extends POIParams {
	duration: timeInSeconds
}

export class StandInCirclePOI extends POI {
	static pool: ObjectPoolTyped<StandInCirclePOI, StandInCirclePOIParams>

	timeDuration: timeInSeconds
	timeAcc: timeInSeconds

	constructor() {
		super()

		this.showGraphics = true
	}

	override setDefaultValues(defaultValues: any, overrideValues?: StandInCirclePOIParams) {
		super.setDefaultValues(defaultValues, overrideValues)

		if(overrideValues) {
			this.timeDuration = overrideValues.duration
			this.timeAcc = 0

			this.updateBannerText()
		}
	}

	override update(delta: number, now: timeInMilliseconds) {
		super.update(delta, now)

		if (this.isActive && this.isPlayerInZone) {
			const oldTime = this.timeAcc
			this.timeAcc += delta
			if (this.timeAcc >= this.timeDuration) {
				this.onComplete(true)
			}
			if (~~this.timeAcc > ~~oldTime) {
				this.updateBannerText()
			}
		}
	}

	updateBannerText() {
		this.setBannerText(`Survive ${Math.round(this.timeDuration - this.timeAcc)} seconds`)
	}

	freeFromPool() {
		StandInCirclePOI.pool.free(this)
	}
}