import { getNID } from "../engine/game-state"
import { EntityType, IEntity } from "../entities/entity-interfaces"
import EntityStatList from "../stats/entity-stat-list"
import { StatType } from "../stats/stat-interfaces-enums"
import { UI } from "../ui/ui"
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { InGameTime } from "../utils/time"

export type CooldownDefinition = {
	maxAmmo: number
	cooldownInterval: number
	reloadInterval: number
	numAmmoPerIncrement: number
} 

export class Cooldown implements IEntity {
	/** Used to pluck certain stats needed for this cooldown */
	statList?: EntityStatList
	/** Used by enemies instead of a statlist; prioritized over statList */
	cooldownDefinition?: CooldownDefinition

	/** The current number of ammo/charges on this cooldown */
	ammo: number

	/** The cooldown is active right now*/
	isReady: boolean

	/** Entity's Id */
	nid: number
	entityType = EntityType.Cooldown
	timeScale: number = 1

	sendToUI: boolean = false
	
	/** Increases or decreases the rate the COOLDOWN comes back; ex. A value of 2 means the cooldown comes back twice as fast. 0.5 means half as fast. */
	cooldownSpeedModifier: number = 1

	/** The timestamp where this cooldown is active again*/
	private nextCooldown: timeInMilliseconds
	/** The timestamp where this cooldown gains charges */
	private nextRecharge: timeInMilliseconds

    private reloadStart: timeInMilliseconds
	private cooldownStart: timeInMilliseconds

    constructor(statList?: EntityStatList, cooldownDefinition?: CooldownDefinition) {
        this.nid = getNID(this)
		this.statList = statList
		this.cooldownDefinition = cooldownDefinition

		const now = InGameTime.highResolutionTimestamp()
		this.nextCooldown = now + this.getCooldownInterval()
		this.nextRecharge = now + this.getReloadInterval()
		this.cooldownStart = now
		this.ammo = this.getMaxAmmo()
		this.isReady = this.ammo > 0
	}

	/** Returns whether this cooldown is usable */
	isUp() {
		return this.isReady && this.ammo > 0
	}

	/** Uses a charge for this cooldown */
	useCharge() {
		// console.debug(`Charges: ${this.ammo}/${this.getMaxAmmo()} Is Ready: ${this.isReady}`)
		if (!this.isUp()) {
			console.warn(`trying to use a cooldown that's not up ? `)
		} else {
			const now = InGameTime.highResolutionTimestamp()

			this.isReady = false
            if(this.ammo === this.getMaxAmmo()) {
                this.nextRecharge = now + this.getReloadInterval()
                this.reloadStart = now
            }

			if(this.ammo === this.getMaxAmmo()) {
				this.nextRecharge = now + this.getReloadInterval()
			}

			this.ammo = Math.max(0, this.ammo - 1)
			this.nextCooldown = now + this.getCooldownInterval()

			this.cooldownStart = now
		}
	}

	getMaxAmmo(): number {
		if (this.cooldownDefinition) {
			return this.cooldownDefinition.maxAmmo 
		}

		return this.statList.getStat(StatType.maxAmmo)
	}

	getCooldownInterval(): number {
		if (this.cooldownDefinition) {
			return this.cooldownDefinition.cooldownInterval
		}

		return this.statList.getStat(StatType.cooldownInterval)
	}

	getReloadInterval(): number {
		if (this.cooldownDefinition) {
			return this.cooldownDefinition.reloadInterval
		}
		return this.statList.getStat(StatType.reloadInterval)
	}

	getNumAmmoPerIncrement(): number {
		if (this.cooldownDefinition) {
			return this.cooldownDefinition.numAmmoPerIncrement
		}

		return this.statList.getStat(StatType.reloadAmmoIncrement)
	}

	getNextCooldown(): number {
		return this.nextCooldown
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		if (this.cooldownSpeedModifier !== 1) {
			this.nextCooldown -= (delta * 1_000 * (this.cooldownSpeedModifier - 1))
		}

		// Update cooldown state
		if (!this.isReady && now > this.nextCooldown) {
			this.isReady = true
		}
		// Update charge state
		const maxAmmo = this.getMaxAmmo()
		if (this.ammo < maxAmmo && now > this.nextRecharge) {
			this.ammo = Math.min(this.ammo + this.getNumAmmoPerIncrement(), maxAmmo)
			if(this.ammo < maxAmmo) {
				this.nextRecharge = now + this.getReloadInterval()
			}
			this.updateUI()
		}
	}

	updateUI() {
		if (this.sendToUI) {
			UI.getInstance().emitMutation('player/updateSkillCooldown', this.getCooldownDataUI())
		}
	}

	getCooldownDataUI() {
		return {
			ammo: this.ammo,
			maxAmmo: this.getMaxAmmo(),
			nextCooldown: this.nextCooldown,
			nextRecharge: this.nextRecharge,
		}
	}

	percentToNextReload() {
        const now = InGameTime.highResolutionTimestamp()

        return Math.min((now - this.reloadStart) / this.getReloadInterval(), 1)
    }

	getReloadTimeRemaining() {
        const now = InGameTime.highResolutionTimestamp()

		return Math.max(this.nextRecharge - now, 0)
	}

	percentToNextCooldown() {
		const now = InGameTime.highResolutionTimestamp()

		const cooldownTotal = this.nextCooldown - this.cooldownStart
        return Math.min((now - this.cooldownStart) / cooldownTotal, 1)
	}

	setNextReload(nextReload: timeInMilliseconds) {
		if (nextReload < 0) {
			return
		}
		const now = InGameTime.highResolutionTimestamp()
		
		// Don't exceed the reloadInterval
		this.nextRecharge =  Math.min(now + nextReload, now + this.getReloadInterval())

		// Set reloadStart so that percentToNedtReload returns the correct percentage
		this.reloadStart = now - (this.getReloadInterval() - nextReload)
	}

	reduceNextCooldown(cooldownReduction: timeInMilliseconds) {
		const now = InGameTime.highResolutionTimestamp()
		
		if (cooldownReduction < 0 || this.nextCooldown <= now) {
			return
		}
		
		this.nextCooldown = Math.max(now, this.nextCooldown - cooldownReduction)
	}

	reduceNextReload(reloadReduction: timeInMilliseconds) {
		const now = InGameTime.highResolutionTimestamp()
		
		if (reloadReduction < 0 || this.nextRecharge <= now) {
			return
		}
		
		this.nextRecharge = Math.max(now, this.nextRecharge - reloadReduction)
	}
}