<template>
	<div class="lighting-container" :class="{ visible: showDebugLightingColor }">
		<div class="config-output">{{`Normalized: R: ${(color.r / 255).toFixed(3)} G: ${(color.g / 255).toFixed(3)} B: ${(color.b / 255).toFixed(3)} A: ${color.a}`}}</div>
		<div class="button-list">
			<color-input v-model="color" format="rgb object"></color-input>
			<button @click="this.setLightingColor(this.color)">Apply Color</button>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PropPlacer } from '../../world-generation/prop-placement'

export default {
	name: 'DebugLightingColor',
	components: {
	},
	data() {
		return {
			color: {r: 1, g: 1, b: 1, a: .5}
		}
	},
	props: {},
	computed: {
		...mapState('ui', ['showDebugLightingColor']),
	},
	methods: {
		setLightingColor(color) {
			if (this.isEnvProductionLike && !this.isQa) {
				return
			}
			PropPlacer.getInstance().allProps.forEach((p) => {
				if (Object.hasOwn(p.propConfig, 'lightingConfig')) {
					p.animatedSprite.spriteSheetAnimator.setColor(color.r / 255, color.g / 255, color.b / 255, color.a)
				}
			})
		},
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
.lighting-container{
	z-index: 100001;
	width: 100%;
	height: 100%;
	&.visible {
			pointer-events: all;
	}
	.config-output{
		background-color: lightgrey;
		color: black;
		font-size: 14px;
		width: fit-content
	}
	.button-list {
		pointer-events: all;
			display: flex;
			flex-wrap: wrap;
			.color-input.user {
				.box {}
				.picker {}
				//...
			}

			button {
				pointer-events: all;
				min-width: 100px;
				min-height: 40px;
				border: 1px solid white;
				border-radius: 2px;
				padding: 5px;
				margin: 3px;
				background-color: lightgray;

				&.no-min-width {
					min-width: unset;
				}

				&.no-margin {
					margin-left: 0px;
					margin-right: 0px;
				}

				&.speed {
					background-color: #7a4100;
				}
			}
	}
}

</style>
