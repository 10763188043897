<template>
	<button class="text-button" :class="{ [buttonStyle]: true && disabled === false, disabled: disabled, highlight: highlightButton, selected: selected }" @click="$emit('onClickFn', optionalParam)" :disabled="disabled">
		<div v-if="!loading" :lang="this.$i18n.locale" class="button-text" :class="[fontColor, { disabled: disabled, 'small-text': smallText }]" :style="noWrap ? 'white-space: nowrap;' : ''" v-html="Array.isArray(buttonLabel) ? $t(...buttonLabel) : $t(buttonLabel)"></div>
		<div v-else class="loading">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
	</button>
</template>

<script lang="ts">
import { VALID_BUTTON_STYLES, VALID_BUTTON_TEXT_STYLES } from './button-constants'
const FONT_COLOR = ['offWhite', 'cream', 'green', 'darkBlue', 'failureRed']

export default {
	name: 'TextButton',
	props: {
		buttonLabel: {
			type: String,
			required: true,
			default: function () {
				console.warn('Currently using default button label. Ensure <TextButton /> component has a custom attribute button-label')
				return 'DEFAULT LABEL'
			},
			validator: function (value: string) {
				return value.trim().length !== 0
			},
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		buttonStyle: {
			type: String,
			default: 'blue',
			validator: function (value: string) {
				return VALID_BUTTON_STYLES.includes(value)
			},
		},
		highlightButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		optionalParam: {
			type: Object,
			required: false,
			default: null,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		selected: {
			type: Boolean,
			required: false,
			default: false,
		},
		noWrap: {
			type: Boolean,
			required: false,
			default: false,
		},
		smallText: {
			type: Boolean,
			required: false,
			default: false,
		},
		fontColor: {
			type: String,
			required: false,
			validator: function (value: string) {
				return FONT_COLOR.includes(value)
			},
		},
	},
	methods: {},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.text-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
	height: 50px;
	perspective: 1px;
	transform: perspective(1px) translateZ(0px);
	line-height: 35px;

	&.disabled {
		cursor: default;
		border-image: url('~/assets/ui/buttons/basic-button-dark.png') 37 61 fill / 37px 61px;
		filter: brightness(1) grayscale();
	}
	&:active {
		.button-text:not(.disabled) {
			transform: scale(0.9);
		}
	}
	&.large-font {
		height: 100px;
	}

	&.blue {
		border-image: url('~/assets/ui/buttons/basic-button-dark.png') 37 61 fill / 37px 61px;

		&:hover {
			border-image: url('~/assets/ui/buttons/basic-button-dark-hover.png') 37 61 fill / 37px 61px;
		}
		&:active {
			border-image: url('~/assets/ui/buttons/basic-button-dark-active.png') 37 61 fill / 37px 61px;
		}
		&.selected {
			border-image: url('~/assets/ui/buttons/basic-button-dark-active.png') 37 61 fill / 37px 61px;
		}
	}
	&.green {
		border-image: url('~/assets/ui/buttons/basic-button-alt.png') 37 61 fill / 37px 61px;

		&:hover {
			border-image: url('~/assets/ui/buttons/basic-button-alt-hover.png') 37 61 fill / 37px 61px;
		}
		&:active {
			border-image: url('~/assets/ui/buttons/basic-button-alt-active.png') 37 61 fill / 37px 61px;
		}
		.button-text {
			color: @offWhite;
		}
		&.selected {
			border-image: url('~/assets/ui/buttons/basic-button-alt-active.png') 37 61 fill / 37px 61px;
		}
	}
	&.bright-green {
		border-image: url('~/assets/ui/buttons/bright-green-button.png') 37 61 fill / 37px 61px;

		&:hover {
			border-image: url('~/assets/ui/buttons/bright-green-button-hover.png') 37 61 fill / 37px 61px;
		}
		&:active {
			border-image: url('~/assets/ui/buttons/bright-green-button-active.png') 37 61 fill / 37px 61px;
		}
		.button-text {
			color: @darkBlue;
		}
		&.selected {
			border-image: url('~/assets/ui/buttons/basic-button-alt-active.png') 37 61 fill / 37px 61px;
		}
	}

	.button-text {
		pointer-events: none;
		color: @green;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 45px;
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansSecondaryFont);
			font-size: 32px;
		}

		&.small-text {
			&[lang='en'] {
				font-size: 35px;
			}
			&[lang='zh'] {
				font-size: 22px;
			}
		}

		&.disabled {
			opacity: 0.3;
		}

		&.offWhite {
			color: @offWhite !important;
		}
		&.green {
			color: @green !important;
		}
		&.cream {
			color: @cream !important;
		}
		&.darkBlue {
			color: @darkBlue !important;
		}
		&.failureRed {
			color: @failureRed !important;
		}
	}

	.loading {
		width: 70px;
		text-align: center;
		display: flex;
		justify-content: center;
		.bounce1 {
			-webkit-animation-delay: -0.32s;
			animation-delay: -0.32s;
		}
		.bounce2 {
			-webkit-animation-delay: -0.16s;
			animation-delay: -0.16s;
		}
		.bounce3 {
		}
	}
	.loading > div {
		width: 18px;
		height: 18px;
		background-color: @green;
		border-radius: 100%;
		display: inline-block;
		-webkit-animation: bounceDelay 1.4s infinite ease-in-out both;
		animation: bounceDelay 1.4s infinite ease-in-out both;
	}
	&.highlight {
		animation: pulseShadow 3s infinite;
	}
}
@keyframes bounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
</style>
