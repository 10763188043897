import { Renderer as PIXIRenderer } from 'pixi.js'
import { ObjectPool, PoolableJsObject } from '../../utils/third-party/object-pool'
import { InstancedSpriteBatcher } from './pfx/instanced-sprite-batcher'
import { IParticleRendererCamera } from './pfx/sprite-particle-renderer'
import { Renderer } from './renderer'
import { debugConfig } from '../../utils/debug-config'

export enum RenderQueueElementType {
	InstancedSprite,
	InstancedSpriteGroup,
	DisplayObject,
}

interface RenderQueueElement {
	elType: RenderQueueElementType
	zIndex: number
	element: any
	index: number
}

export class RenderQueue {
	static elementPool: ObjectPool = new ObjectPool(() => new PoolableJsObject(), undefined, 1_024, 128, 'render-element')
	private elements: RenderQueueElement[] = []
	private pixiRenderer: PIXIRenderer
	private instancedSpriteBatcher: InstancedSpriteBatcher
	private elementCounter: number = 0

	numSwitches: number = 0

	constructor(pixiRenderer: PIXIRenderer, instancedSpriteBatcher: InstancedSpriteBatcher) {
		this.pixiRenderer = pixiRenderer
		this.instancedSpriteBatcher = instancedSpriteBatcher
	}

	clear() {
		// TODO2 Mike pool could track objects and do this themselves
		this.elements.forEach((element) => {
			// @ts-expect-error there's no non-ridiculous way to extend PoolableObject to this, wontfix
			RenderQueue.elementPool.free(element)
			element.element = null
		})
		this.elements = []
		this.elementCounter = 0
	}

	addElement(element: any, elType: RenderQueueElementType, zIndex: number) {
		const elem = RenderQueue.elementPool.alloc()
		elem.elType = elType
		elem.zIndex = zIndex
		elem.element = element
		elem.index = this.elementCounter++
		this.elements.push(elem)
	}

	flush(camera: IParticleRendererCamera) {
		this._sort()
		this._render(camera)
	}

	private _sort() {
		this.elements.sort((a, b) => (a.zIndex !== b.zIndex ? a.zIndex - b.zIndex : a.index - b.index))
	}

	private _switchToPixiRendering(debugTimeKey: string) {
		const start = performance.now()
		this.instancedSpriteBatcher.flush() // debug time measurement inside here too
		this.pixiRenderer.batch.currentRenderer.start()
		if (debugConfig.benchmarkMode) {
			const end = performance.now()
			if (debugTimeKey) {
				Renderer.getInstance().addRenderTime(debugTimeKey + ' (?)', end - start)
			}
			this.numSwitches++
		}
	}

	private _switchToInstancedRendering(camera: IParticleRendererCamera, debugTimeKey: string) {
		const start = performance.now()
		this.pixiRenderer.batch.currentRenderer.flush()
		this.pixiRenderer.batch.flush()
		this.instancedSpriteBatcher.beginRender(camera)
		if (debugConfig.benchmarkMode) {
			const end = performance.now()
			if (debugTimeKey) {
				Renderer.getInstance().addRenderTime(debugTimeKey, end - start)
			}
			this.numSwitches++
		}
	}

	private _render(camera: IParticleRendererCamera) {
		let renderingInstances = false
		let oldTag = 'oldbatch'
		this.instancedSpriteBatcher.reset()

		this.elements.forEach((el) => {
			switch (el.elType) {
				case RenderQueueElementType.InstancedSprite:
					{
						const element = el.element
						if (!renderingInstances) {
							this._switchToInstancedRendering(camera, oldTag)
							renderingInstances = true
							oldTag = el.element.name || 'unknown instanced sprite'
						}
						this.instancedSpriteBatcher.addInstancedSprite(element)
					}
					break
				case RenderQueueElementType.InstancedSpriteGroup:
					{
						const element = el.element
						if (!renderingInstances) {
							this._switchToInstancedRendering(camera, oldTag)
							renderingInstances = true
							oldTag = el.element.name || 'unknown instanced sprite group'
						}
						this.instancedSpriteBatcher.addInstancedSprites(element.particles, element.numParticles)
					}
					break
				case RenderQueueElementType.DisplayObject:
					{
						if (renderingInstances) {
							this._switchToPixiRendering(oldTag)
							renderingInstances = false
							oldTag = el.element.name || 'Display Object'
						}
						const element = el.element as PIXI.DisplayObject
						element.updateTransform()
						// @ts-expect-error we break contract with pixi here by using a protected function. wontfix
						element.render(this.pixiRenderer)
					}
					break
			}
		})

		const start = performance.now()
		if (renderingInstances) {
			this.instancedSpriteBatcher.flush()
		} else {
			this.pixiRenderer.batch.currentRenderer.flush()
		}
		
		if (debugConfig.benchmarkMode) {
			const end = performance.now()
			Renderer.getInstance().addRenderTime(oldTag, end - start)
		}
	}
}
