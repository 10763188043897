import { GameState } from "../engine/game-state"
import { ISystem } from "../engine/isystem"
import { Player } from "../entities/player"
import { timeInSeconds } from "../utils/primitive-types"
import { PlayerProjectile } from "./projectile"
import { Vector } from "sat"
import { angleInRadsFromVector } from "../utils/vector"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import { BuffIdentifier } from "../buffs/buff.shared"
import { ResourceType } from "../weapons/weapon-definitions"
import { StatType } from "../stats/stat-interfaces-enums"
import { add, degToRad } from "../utils/math"
import { Enemy } from "../entities/enemies/enemy"
import { getIgniteStacks } from "../buffs/generic-buff-definitions"
import { EnemyProjectile } from "./enemy-projectile"
import { PrimaryWeapon } from "../weapons/primary-weapon"
import { Boomerang } from "../weapons/actual-weapons/primary/boomerang-weapon"
import { handleLongbowEnemyBlowback } from "../weapons/actual-weapons/primary/bow-weapon"
import { ChargePrimaryWeapon } from "../weapons/charge-primary-weapon"
import { ShrapnelConfig } from "../game-data/player-formulas"
import { callbacks_addCallback } from "../utils/callback-system"
import { getDamageFromDamageSource } from "./damage-source"

export class ProjectileSystem implements ISystem {
	private static instance: ProjectileSystem
	// private enemies: Map<number, Enemy>
	// private activeBeams: Map<number, ActiveBeam>
	private shotsInQueue: number = 0 // Only used for charge up shots released during tumble roll

	private reusePositionVector: Vector

	static getInstance() {
		if (!ProjectileSystem.instance) {
			ProjectileSystem.instance = new ProjectileSystem()
		}
		return ProjectileSystem.instance
	}

	constructor() {
		ProjectileSystem.instance = this
		this.reusePositionVector = new Vector()
	}

	update(delta: timeInSeconds) {
		const player = GameState.player
		//TODO BIG: decide if projectile system should handle ANY of this, or if player's update() or subfunction should
		if (player.currentAttackCooldown > 0) {
			player.currentAttackCooldown -= delta
		}

		let canShoot = false
		//TODO: currently no real difference, but will there be logic here in the future? if not just move the check to `if (canShoot())` below
		if (player.primaryWeapon.resourceType === ResourceType.NONE) {
			if (player.canShoot()) {
				canShoot = true
			}
		} else if (player.primaryWeapon.resourceType === ResourceType.CHARGE_UP_SHOT) {
			if (player.canShoot()) {
				if (player.isTumbleRolling) {
					canShoot = false
					this.shotsInQueue++
				} else {
					canShoot = true
				}
			}
			// Handle shots released during tumble roll
			if (!player.isTumbleRolling && this.shotsInQueue > 0) {
				canShoot = true
				this.shotsInQueue--
			}
		}

		if (canShoot) {
			let numProj = 1
			let burstDelay = 0

			if (player.binaryFlags.has('chakram-autofire')) {
				numProj = Math.min(player.ammoCount, 2)
				burstDelay = 0.05 * 1 / player.primaryWeapon.statList.getStat('attackRate')
			} else if (player.binaryFlags.has('3-round-burst')) {
				numProj = 3
				burstDelay = 0.1 * 1 / player.primaryWeapon.statList.getStat('attackRate')
			}

			for (let i = 0; i < numProj; i++) {
				// Don't break for charge up shots released during tumble roll
				if (!player.canShoot() && player.primaryWeapon.resourceType !== ResourceType.CHARGE_UP_SHOT) {
					numProj = i
					break
				}
				// handle the first shot, the typical case for most weapons
				if (i == 0) {
					this.addPlayerProjectile(player, player.primaryWeapon)
				} else {
					// handle burst firing after the primary shot
					callbacks_addCallback(this, () => {
						if (this && player && !player.isDead() && player.primaryWeapon) {
							this.addPlayerProjectile(player, player.primaryWeapon)
						}
					}, burstDelay * i)
				}
			}

			player.onShot(true, numProj)
		}
	}

	getProjectileOrigin(player: Player) : Vector {
		this.reusePositionVector.x = player.projectileOriginBone.worldX * player.scale
		this.reusePositionVector.y = player.projectileOriginBone.worldY * player.scale
		this.reusePositionVector.add(player.position)
		return this.reusePositionVector
		// 
		// this.reusePositionVector.x = JANK_AIM_X_OFFSET
		// this.reusePositionVector.y = 0
		// this.reusePositionVector.rotate(player.aimAngle)
		// this.reusePositionVector.add(player.position)
		// this.reusePositionVector.y -= JANK_AIM_Y_OFFSET
		// return this.reusePositionVector
	}

	addPlayerProjectile(player: Player, weapon: PrimaryWeapon): PlayerProjectile[] {
		const projectileOrigin = this.getProjectileOrigin(player)

		const baseStatProjectileSpeed = weapon.statList.getStat(StatType.projectileSpeed)
		const baseStatProjectileSize = weapon.statList.getStat(StatType.attackSize)

		let reversedTrajectory = false
		if (weapon instanceof Boomerang) {
			reversedTrajectory = weapon.trajectoryParity
		}

		const rampDamage = player.binaryFlags.has('ramp-primary-damage')

		let projectileSpeed
		let attackSize
		let totalProjectiles = weapon.statList.getStat('projectileCount')

		if (player.primaryWeapon.resourceType === ResourceType.CHARGE_UP_SHOT) {
			const chargeDef = player.getChargeDefinition()
			attackSize = baseStatProjectileSize * chargeDef.chargeStats.attackSize
			projectileSpeed = baseStatProjectileSpeed * chargeDef.chargeStats.projectileSpeed

			const chargeWeapon = player.primaryWeapon as ChargePrimaryWeapon
			const numMaxCharges = Math.floor(player.currentEnergy / 100)
			totalProjectiles += (chargeWeapon.bonusesPerMaxCharge.projectiles * numMaxCharges)
		} else {
			// non charge weapons
			projectileSpeed = baseStatProjectileSpeed
			attackSize = baseStatProjectileSize
		}

		const projectiles: PlayerProjectile[] = []
		const trajectoryMods = []
		if (player.primaryWeapon.trajectory) {
			trajectoryMods.push(player.primaryWeapon.trajectory)
		}

		let emitShrapnel: ShrapnelConfig
		if (player.binaryFlags.has('primary-weapon-emits-shrapnel')) {
			emitShrapnel = ShrapnelConfig.StormbreakerRazorang
		}
		if (player.binaryFlags.has('primary-weapon-emits-snare-nets')) {
			emitShrapnel = ShrapnelConfig.LongbowWalkItOff
		}
		const isBoomerangSpread = player.binaryFlags.has('boomerang-shoot-all-as-spread')

		if (totalProjectiles > 1) {
			const spreadAngle = weapon.statList.getStat(StatType.projectileSpreadAngle)
			const middleProjectileIndex = Math.floor(totalProjectiles / 2)
			for (let i = 0; i < totalProjectiles; i++) {
				const angleOffset = ProjectileSystem.projectileSpreadAngle(i, totalProjectiles, spreadAngle)

				projectiles.push(PlayerProjectile.objectPool.alloc({
					owningEntityId: player.nid,
					position: projectileOrigin,
					speed: projectileSpeed,
					aimAngleInRads: player.aimAngle + angleOffset,
					trajectoryMods,
					radius: attackSize,
					collisionLayer: CollisionLayerBits.PlayerProjectile,
					statList: weapon.statList,
					isPrimaryWeaponProjectile: true,
					emitShrapnel,
					resourceType: player.primaryWeapon.resourceType,
					energy: player.currentEnergy,
					effectType: weapon.projectileEffectType,
					trailType: weapon.projectileTrailType,
					rampDamage,
					reversedTrajectory,
					player: player,
					weaponType: weapon.weaponType,
					projectileGroup: projectiles,
				}))

				if (i != middleProjectileIndex && isBoomerangSpread) {
					projectiles[i].isOriginalProjectile = false
				}
			}
		} else {
			projectiles.push(PlayerProjectile.objectPool.alloc({
				owningEntityId: player.nid,
				position: projectileOrigin,
				speed: projectileSpeed,
				aimAngleInRads: player.aimAngle,
				trajectoryMods,
				radius: attackSize,
				collisionLayer: CollisionLayerBits.PlayerProjectile,
				statList: weapon.statList,
				isPrimaryWeaponProjectile: true,
				emitShrapnel,
				resourceType: player.primaryWeapon.resourceType,
				energy: player.currentEnergy,
				effectType: weapon.projectileEffectType,
				trailType: weapon.projectileTrailType,
				reversedTrajectory,
				weaponType: weapon.weaponType,
				rampDamage,
				player: player,
				projectileGroup: projectiles
			}))
		}

		if (player.binaryFlags.has('ignite-every-3rd-attack')) {
			const data = player.binaryFlagState['attack-rate-ignite-attacks']
			data.attackCounter += 1
			// console.log(`4th counter: ${data.attackCounter} ${data.attackCounter >= 4 ? 'TRUE' : ''}`)
			if (data.attackCounter >= 3) {
				data.attackCounter -= 3
				for (let i = 0; i < projectiles.length; i++) {
					const proj = projectiles[i] as PlayerProjectile
					//TODO: do fancy ignite attack
					proj.radius += 20
					proj.applyBuffsOnHit.push({
						buffId: BuffIdentifier.Ignite,
						owner: player,
						stacks: getIgniteStacks(getDamageFromDamageSource(proj) * 1.0),
					})
				}
			}
		}

		if (player.binaryFlags.has('flame-fast')) {
			const data = player.binaryFlagState['flame-fast']
			const n = player.binaryFlags.has('high-precision-torch') ? 1 : 3
			data.attackCounter += 1
			if (data.attackCounter >= n) {
				data.attackCounter -= n
				for (let i = 0; i < projectiles.length; i++) {
					const proj = projectiles[i] as PlayerProjectile
					proj.applyBuffsOnHit.push({
						buffId: BuffIdentifier.Ignite,
						owner: player,
						stacks: getIgniteStacks(getDamageFromDamageSource(proj) * 3.0),
						duration: 3000
					})
				}
			}
		}

		if (player.binaryFlags.has('wand-biggify-every-3rd-shot')) {
			const data = player.binaryFlagState['wand-biggify-every-3rd-shot']
			data.attackCounter += 1
			// console.log(`6th counter: ${data.attackCounter} ${data.attackCounter >= 6 ? 'TRUE' : ''}`)
			if (data.attackCounter >= 3) {
				data.attackCounter -= 3
				for (let i = 0; i < projectiles.length; i++) {
					const proj = projectiles[i] as PlayerProjectile
					proj.radius += 40
					proj.damageScale = 3
				}
			}
		}

		if (player.binaryFlags.has('auto-split')) {
			const dist = player.binaryFlagState['auto-split'].autoSplitDistance
			for (let i = 0; i < projectiles.length; i++) {
				const proj = projectiles[i] as PlayerProjectile
				proj.autoSplitAfterDistanceTravelled = dist
			}
		}

		if (player.binaryFlags.has('longbow-final-form') && projectiles.length) {
			handleLongbowEnemyBlowback(player, getDamageFromDamageSource(projectiles[0]))
		}

		return projectiles
	}

	addEnemyProjectile(enemy: Enemy) {
		const baseStatProjectileSpeed = enemy.statList.getStat(StatType.projectileSpeed)
		const baseStatProjectileSize = enemy.statList.getStat(StatType.attackSize)
		const baseStatProjectileDamage = enemy.statList.getStat(StatType.baseDamage)

		const projectiles = []
		const totalProjectiles = enemy.statList.getStat('projectileCount')

		const projectileOrigin = new Vector().copy(enemy.position)
		projectileOrigin.x += enemy.attackOffset.x * enemy.facingDirection
		projectileOrigin.y += enemy.attackOffset.y

		if (totalProjectiles > 1) {
			for (let i = 0; i < totalProjectiles; i++) {
				const angleOffset = ProjectileSystem.projectileSpreadAngle(i, totalProjectiles, enemy.statList.getStat('projectileSpreadAngle'))
				projectiles.push(EnemyProjectile.objectPool.alloc({
					position: projectileOrigin,
					speed: baseStatProjectileSpeed,
					aimAngleInRads: angleInRadsFromVector(enemy.aimVector) + angleOffset,
					radius: baseStatProjectileSize,
					collisionLayer: CollisionLayerBits.EnemyProjectile,
					baseDamage: baseStatProjectileDamage,
					statList: enemy.statList,
					particleEffectType: enemy.getParticleEffectType()
				}))
			}
		} else {
			projectiles.push(EnemyProjectile.objectPool.alloc({
				position: projectileOrigin,
				speed: baseStatProjectileSpeed,
				aimAngleInRads: angleInRadsFromVector(enemy.aimVector),
				radius: baseStatProjectileSize,
				collisionLayer: CollisionLayerBits.EnemyProjectile,
				baseDamage: baseStatProjectileDamage,
				statList: enemy.statList,
				particleEffectType: enemy.getParticleEffectType()
			}))
		}

		return projectiles
	}

	cleanUp() {
		this.shotsInQueue = 0
	}

	static projectileSpreadAngle(projectileNumber: number, totalProjectiles: number, projectileSpread: number) {
		const spreadAngle = degToRad(projectileSpread)
		const projectileSteps = spreadAngle / totalProjectiles

		// to help fan the projectiles out from the middle of where your aimming on shoot. 
		const aimOffset = (spreadAngle / 2) - (projectileSteps / 2)

		return (projectileSteps * projectileNumber) - aimOffset
	}

}
