import { Sprite } from "pixi.js"
import { Vector } from "sat"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { Audio } from "../../../engine/audio"
import ClientPlayerInput, { InputAction } from "../../../engine/client-player-input"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import CollisionSystem from "../../../engine/collision/collision-system"
import { ComponentOwner } from "../../../engine/component-owner"
import { GameState, getNID } from "../../../engine/game-state"
import playAnimation from "../../../engine/graphics/play-animation"
import { Renderer } from "../../../engine/graphics/renderer"
import { SpritesheetAnimatorComponent } from "../../../engine/graphics/spritesheet-animator-component"
import { EnemyType } from "../../../entities/enemies/ai-types"
import { Enemy } from "../../../entities/enemies/enemy"
import { EntityType, isEnemy, isProp } from "../../../entities/entity-interfaces"
import { Player } from "../../../entities/player"
import { CONE_DOG_CHOMP_CDR_AMOUNT, CONE_DOG_CHOMP_CDR_MIN_ENEMIES, CONE_DOG_CHOMP_MOVE_SPEED_MIN_ENEMIES } from "../../../game-data/characters"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { DamageSource } from "../../../projectiles/damage-source"
import { AnimationTrack } from "../../../spine-config/animation-track"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { AssetManager } from "../../../web/asset-manager"
import { SecondaryWeapon } from "../../secondary-weapon"
import { SkillWeapon } from "../../skill-weapon"
import { AllWeaponTypes } from "../../weapon-types"
import { timeInSeconds } from "../../../utils/primitive-types"
import { distanceSquaredVV } from "../../../utils/math"

const BASE_ATTACK_SIZE = 210

const TIME_TO_BITE = 0.3
const CHOMP_SCALE_DOWN_TIME = 0.2
const CHOMP_SCALE_UP_TIME = 0.7

const CHOMP_X_OFFSET = 200
const CHOMP_Y_OFFSET = -60

const SAFETY_KNOCKBACK_DIST = 250
const SAFETY_KNOCKBACK_STRENGTH = 500

// visuals
const MIN_CHOMP_SCALE = 1.5 * 0.6
const MAX_CHOMP_SCALE = 2.3 * 0.6
const START_CHOMP_SCALE = 2.1 * 0.6

enum ChompState {
    Wait,
    Startup,
    AnimScaleDown,
    Active,
    AnimScaleUp
}

export class DogSkillWeapon extends SkillWeapon implements DamageSource, ComponentOwner {
    weaponType: AllWeaponTypes = AllWeaponTypes.DogSkill

    numEntitiesChained: number = 0
    numEntitiesPierced: number = 0
    startPos: Vector
	timeScale: number = 1

    nid: number
    entityType: EntityType = EntityType.GroundHazard // will probably come back to haunt me, but needed for enemy on hit
    damageBonus = 1

    markAliveEnemiesToStackGoodBoy: boolean = false

    showImmediateDamageNumber: boolean = true

    private chompGfx: SpritesheetAnimatorComponent
    private chompState: ChompState
    private timeInState: number


    private timeToBite: number
    private scaleDownTime: number
    private scaleUpTime: number

    position: Vector
    private chompSize: number
    private chompGfxScale: number
    // player's facing direction at the start of the chomp state. Used to ensure chompGfx has the correct left/right offset throughout animation
    private chompDirection: number


    constructor(player: Player, statlist: EntityStatList) {
        super(player, statlist)

        this.nid = getNID(this)

        this.makeVisuals()

        this.chompState = ChompState.Wait
        this.timeInState = 0
        this.position = new Vector()
        this.startPos = new Vector()
        this.chompDirection = player.facingDirection
    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.baseDamage = 35 // you have until 5 mins when your stacks and upgrades need to increase damage so you can 1-shot enemies
        statList._actualStatValues.attackSize = BASE_ATTACK_SIZE
        // statList._actualStatValues.attackKnockback = 800 // (doesn't work when entity type is groundhazard atm; also this felt like a debuff instead of a buff)
        statList._actualStatValues.attackRate = 1

        statList._actualStatValues.maxAmmo = 2
        statList._actualStatValues.reloadAmmoIncrement = 2
        statList._actualStatValues.cooldownInterval = (TIME_TO_BITE + CHOMP_SCALE_DOWN_TIME) * 1_000 // should be dynamic on attack rate?
        statList._actualStatValues.reloadInterval = 5_000
    }

    protected useSkill(): void {
        const attackRate = this.statList.getStat(StatType.attackRate)
        const attackRateScale = attackRate / 1
        const inverseAttackRateScale = 1 / attackRate
        this.chompSize = this.statList.getStat(StatType.attackSize)
        this.chompGfxScale = this.chompSize / BASE_ATTACK_SIZE

        this.player.playAnimation(AnimationTrack.SHOOT, attackRateScale, true)

        this.timeToBite = TIME_TO_BITE * inverseAttackRateScale
        this.scaleDownTime = CHOMP_SCALE_DOWN_TIME * inverseAttackRateScale
        this.scaleUpTime = CHOMP_SCALE_UP_TIME * inverseAttackRateScale

        this.player.setMovementLockTime((this.timeToBite + this.scaleDownTime) * 1_000 * inverseAttackRateScale)
        this.player.knockbackSurroundingEnemies(SAFETY_KNOCKBACK_DIST, SAFETY_KNOCKBACK_STRENGTH)

        if (ClientPlayerInput.currentFrameInput.get(InputAction.SHOOT)) {
            // they used left click to activate the skill

            let facing: number
            if (ClientPlayerInput.controllerActive) {
                if (ClientPlayerInput.controllerAimVector.x) {
                    facing = -Math.sign(ClientPlayerInput.controllerAimVector.x)
                } else {
                    facing = -this.player.facingDirection
                }
            } else {
                facing = ClientPlayerInput.worldMouseX < this.player.x ? 1 : -1
            }


            this.player.facingDirection = -facing
            this.player.riggedModel.scale.x = facing

        }

        this.changeState(ChompState.Startup)
        Audio.getInstance().playSfx('SFX_Enemy_ThornWolf_Shoot')
    }

    protected canUseSkill(): boolean {
        return this.chompState === ChompState.Wait || this.chompState === ChompState.AnimScaleUp
    }

    private biteEnemies() {
        const enemies = CollisionSystem.getInstance().getEntitiesInArea(this.position, this.chompSize, CollisionLayerBits.PlayerProjectile)
        //Renderer.getInstance().drawCircle({ x: this.position.x, y: this.position.y, radius: this.chompSize, destroyAfterSeconds: 0.1, scale: 1, permanent: false, color: 0x3970bc })

        const thornOnKill = this.player.binaryFlags.has('cone-dog-chomp-thorn-kill')

        for (let i = 0; i < enemies.length; ++i) {
            const owner = enemies[i].owner

            if (isEnemy(owner)) {
                owner.onHitByDamageSource(this)

                if (owner.isDead()) {
                    // we killed 'em, good boy
					if (Math.random() <= owner.deathRewardRate) {
                        Buff.apply(BuffIdentifier.GoodBoyBuff, this.player, this.player, 1)
					}

                    if (thornOnKill) {
                        this.player.coneDogThornWeapon.releaseThornFromPosition(owner.position)
                    }
                } else if (this.markAliveEnemiesToStackGoodBoy) {
                    owner.stackGoodBoyOnDeath = true
                }
            } else if (isProp(owner)) {
                owner.takeDamage()
            }
        }

        if (this.player.binaryFlags.has('cone-dog-chomp-movement') && enemies.length >= CONE_DOG_CHOMP_MOVE_SPEED_MIN_ENEMIES) {
            Buff.apply(BuffIdentifier.ChompMoveSpeed, this.player, this.player)
        }

        if (this.player.binaryFlags.has('cone-dog-cooldown-reduction') && enemies.length >= CONE_DOG_CHOMP_CDR_MIN_ENEMIES) {
            this.player.secondaryWeapons.forEach((weapon: SecondaryWeapon) => {
                weapon.cooldown.reduceNextCooldown(CONE_DOG_CHOMP_CDR_AMOUNT)
            })
        }
    }

    update(delta: number) {
        this.timeInState += delta

        if (this.chompState === ChompState.Wait || this.chompState === ChompState.Startup) {
            this.chompDirection = this.player.facingDirection
            this.position.x = this.player.position.x + (this.chompDirection * CHOMP_X_OFFSET * this.player.scale)
            this.position.y = this.player.position.y + (CHOMP_Y_OFFSET * this.player.scale)
        }
        

        switch (this.chompState) {
            case ChompState.Startup:
                if (this.timeInState >= this.timeToBite) {
                    this.startPos.copy(this.player.position)
                    this.chompGfx.spriteSheetAnimator.activeSprite.scale.x = this.chompDirection * START_CHOMP_SCALE * this.chompGfxScale
                    this.chompGfx.spriteSheetAnimator.activeSprite.scale.y = START_CHOMP_SCALE * this.chompGfxScale

                    // @TODO make this less distracting when it's huge
                    this.chompGfx.addToScene()
                    this.chompGfx.spriteSheetAnimator.restartCurrentAnim()
                    this.changeState(ChompState.AnimScaleDown)
                }
                break
            case ChompState.AnimScaleDown:
                const downScale = Math.lerp(START_CHOMP_SCALE * this.chompGfxScale, MIN_CHOMP_SCALE * this.chompGfxScale, Math.clamp(this.timeInState / this.scaleDownTime, 0, 1))
                this.chompGfx.spriteSheetAnimator.activeSprite.scale.x = this.chompDirection * downScale
                this.chompGfx.spriteSheetAnimator.activeSprite.scale.y = downScale

                if (this.timeInState >= this.scaleDownTime) {
                    this.changeState(ChompState.Active)
                }
                break
            case ChompState.Active:
                this.biteEnemies()
                this.changeState(ChompState.AnimScaleUp)
                break
            case ChompState.AnimScaleUp:
                const lerpVal = Math.clamp(this.timeInState / this.scaleUpTime, 0, 1)
                const upScale = Math.lerp(MIN_CHOMP_SCALE * this.chompGfxScale, MAX_CHOMP_SCALE * this.chompGfxScale, lerpVal)
                this.chompGfx.spriteSheetAnimator.activeSprite.scale.x = this.chompDirection * upScale
                this.chompGfx.spriteSheetAnimator.activeSprite.scale.y = upScale

                if (this.timeInState >= this.scaleUpTime) {
                    this.chompGfx.removeFromScene()
                    this.changeState(ChompState.Wait)
                }
                break
        }
        this.chompGfx.update(delta)

        if (this.player.autoShootEnabled && this.player.autoShootToggledOn) {
            this.updateAutoShoot()
        }
    }

    updateAutoShoot() {
        const attackSize = this.statList.getStat(StatType.attackSize)
        const attackSize2 = attackSize * attackSize
        const halfAttackSize2 = attackSize2 / 2
        const isFacingRight = this.player.facingDirection === 1

        for (let i = 0; i < GameState.enemyList.length; ++i) {
            const enemy = GameState.enemyList[i] as Enemy
            if (!enemy.isDead() && enemy.distanceToPlayer2 <= attackSize2) {
                const dist2ToPos = distanceSquaredVV(this.position, enemy.position)
                if (dist2ToPos <= halfAttackSize2 || (dist2ToPos <= attackSize2 && (isFacingRight && enemy.position.x >= this.position.x) || (!isFacingRight && enemy.position.x <= this.position.x))) {
                    if (this.tryUseSkill()) {
                        this.player.onUsedSkill()
                    }
                    return
                }
            }
        }
    }

    getKnockbackDirection(mutableEntityPos: Vector): Vector {
        return mutableEntityPos.sub(this.player.position).normalize()
    }

    private changeState(state: ChompState) {
        this.chompState = state
        this.timeInState = 0
    }

    isPlayerOwned(): boolean {
        return true
    }

    makeVisuals() {
        const spriteSheet = AssetManager.getInstance().getAssetByName('bite-pfx').spritesheet
        this.chompGfx = new SpritesheetAnimatorComponent(this, spriteSheet, 'bite-pfx', 0.25, false, true)
    }

    setVisuals() {
        this.chompGfx.addToScene()
    }
}