import { ParticleEffectType } from "../engine/graphics/pfx/particle-config"

export enum DamageSeverity {
	ShieldHit,
	Hit,
	CriticalHit,
	SevereHit,
}

export enum DamageType {
	PHYSICAL = 1,
	FIRE,
	ICE,
	LIGHTNING,
	POISON,
}

export enum DamageNumberStyle {
	None,
	Player,
	Enemy,
	Heal,
	Shield,
	Critical,
}

export interface DamageReport {
	health: number
	shield: number
	isCritical: boolean
}

export const EMPTY_DAMAGE_REPORT: DamageReport = {
	health: 0,
	shield: 0,
	isCritical: false,
}

/** A severe hit is currently defined as 25% of a player's health or more */
const severeHit = 25

export function severityFromDamageReport(damageReport: DamageReport) {
	if (damageReport.shield > 0) {
		return DamageSeverity.ShieldHit
	} else if (damageReport.isCritical) {
		return DamageSeverity.CriticalHit
	} else if (damageReport.health > 25) {
		return DamageSeverity.SevereHit
	} else {
		return DamageSeverity.Hit
	}
}

export const elementToMuzzlePfxMap = {
	[DamageType.PHYSICAL]: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	[DamageType.FIRE]: ParticleEffectType.PROJECTILE_FIRE_SHOOT,
	[DamageType.ICE]: ParticleEffectType.PROJECTILE_ICE_SHOOT,
	[DamageType.LIGHTNING]: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
	[DamageType.POISON]: ParticleEffectType.PROJECTILE_POISON_SHOOT,
}

const elementToBeamColorMap = {
	[DamageType.PHYSICAL]: 0xff0000,
	[DamageType.FIRE]: 0xff7f00,
	[DamageType.ICE]: 0xb5d3e7,
	[DamageType.LIGHTNING]: 0x005288,
	[DamageType.POISON]: 0x009900,
}

export function getBeamColorFromDamageType(damageType: DamageType): number {
	return elementToBeamColorMap[damageType]
}
