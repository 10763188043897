<template>
	<div id="good">
		<div class="good-text" :lang="this.$i18n.locale">{{ realGoodBoyStacks }} ({{goodBoyStacks}})</div>
		<div class="good-image">
			<img src="../../../../assets/sprites/weapons/cone-dog-bite.png" alt="" width="30" />
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'GoodBoyUI',
	components: {},
	data() {
		return {}
	},
	props: {

	},
	computed: {
		...mapState('ui', ['goodBoyStacks']),
		realGoodBoyStacks() {
			return Math.floor(this.goodBoyStacks / 50)
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#good {
	display: flex;
	.good-text {
		color: @green;
		filter: drop-shadow(0px 0px 3px black);
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-variant: small-caps;
			font-size: 40px;
            letter-spacing: 5px;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.good-image {
        margin-left: 10px;
		img {
		}
	}
}

@supports (-webkit-text-stroke: 1px @darkBlue) {
	#good {
		.good-text {
			-webkit-text-stroke: 1px @darkBlue;
			-webkit-text-fill-color: @green;
		}
	}
}
</style>
