import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, PersistedBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { Enemy } from "../enemy"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { degToRad } from "../../../utils/math"

const iceDrake: EnemyAI = {
	name: ENEMY_NAME.ICE_DRAKE,
	type: EnemyType.BOSS,
	objectPoolInitialSize: 1,
	objectPoolGrowthSize: 1,
	appearance: {
		asset: SpineDataName.ICE_DRAKE,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
		scale: 1,
		zOffset: 200
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Box,
				width: 250,
				height: 350,
				position: [-125, 30],
			},
		],
		colliderLayer: CollisionLayerBits.FlyingEnemy,
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.67,
			maxHealth: 45.0,
			projectileSpeed: 550,
			attackSize: 40,
			projectileCount: 5,
			projectileSpreadAngle: 140,
			knockbackResist: 9999,

		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 720,
		attackOffset: new Vector(200, -20),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		playHitAnimation: false,
		animationSpeeds: 0.25,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	rage: [
		{
			enrageStart: 90, // 90 seconds after spawn
			cooldownBonus: 3, // get 3x statetime
			movementBonus: 2, // 2x movement speed
			damageBonus: 2, // 2x damage
		},
		{
			enrageStart: 120, // 120 seconds after spawn
			cooldownBonus: 10, // get 10x statetime
			movementBonus: 4, // 20x movement speed
			damageBonus: 4, // 4x damage
			applyFunction(enemy: Enemy) {
				enemy.fightingBehaviour = FightingBehaviours.CHASE_AND_ATTACK
				enemy.movementMinDistance = 0
				enemy.movementMaxDistance = 100
			},
		},
	],
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.HOP_STRAFE_AND_ATTACK,
				hopTime: 0.5,
				hopSpeed: 1_000,
				hopWaitTime: 1.1,
				hopAngleMin: degToRad(80),
				hopAngleMax: degToRad(100),
				attackDelayOnHop: 0.5
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_HEAD_ICE_DRAKE,
				cooldownDef: {
					maxAmmo: 1,
					cooldownInterval: 450, // just has to be low enough to shoot when we call shoot during our hop end
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 1_300,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 1_100,
			movementMinDistance: 500,
			shotLeadPrecision: ShotLeadPrecision.PERFECT,
			visualAimLockSeconds: 0.3,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
		persisted: [
			{
				behaviour: PersistedBehaviours.SUMMON,
				enemy: ENEMY_NAME.MOSQUITO,
				enemyCount: 5,
				cooldownTime: 6,
				minSpawnRange: 200,
				maxSpawnRange: 300,
				pfx: 'ice-drake-summon',
				pfxTime: 0.8,
				summonDelay: 0.5
			}
		]
	},
}

export default iceDrake
