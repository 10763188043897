import { Player } from "../entities/player"
import EntityStatList from "../stats/entity-stat-list"
import { Weapon } from "./weapon"

export abstract class SkillWeapon extends Weapon {

	constructor(player: Player, statList: EntityStatList) {
		super(player, statList)
		
		// skills have a UI cooldown
		this.cooldown.sendToUI = true
		this.cooldown.updateUI()
	}
    
    tryUseSkill(): boolean {
        if(this.cooldown.isUp() && this.canUseSkill()) {
            this.cooldown.useCharge()
            this.useSkill()
			this.cooldown.updateUI()
			return true
        }
		return false
    }

    protected abstract useSkill(): void
	protected abstract canUseSkill(): boolean
}
