import { Vector } from "sat"
import { CooldownDefinition } from "../../cooldowns/cooldown"
import { Audio } from "../../engine/audio"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { Renderer } from "../../engine/graphics/renderer"
import { SpritesheetAnimatorComponent } from "../../engine/graphics/spritesheet-animator-component"
import { getDamageByPlayerLevel } from "../../game-data/player-formulas"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import EntityStatList, { StatBonus } from "../../stats/entity-stat-list"
import { StatOperator, StatType } from "../../stats/stat-interfaces-enums"
import { callbacks_addCallback } from "../../utils/callback-system"
import { timeInSeconds } from "../../utils/primitive-types"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { Pet, PetCollectionName } from "./pet"
import { PetStompEffect, PetStompEffectParams, STOMP_EFFECT_RADIUS } from "./pet-stomp-effect"

const DAMAGE_MULTIPLIER = 2.5

export class StompingPet extends Pet {
	damageBonus: StatBonus
	aoeEffectConfig: EffectConfig

	constructor(name: PetCollectionName, parentStatList: EntityStatList, position: Vector, uncaged?: boolean) {
		super(name, parentStatList, position, uncaged)
		this.damageBonus = this.statList.addStatBonus("baseDamage", StatOperator.SUM, 0) as StatBonus

		if(!PetStompEffect.pool) {
			PetStompEffect.pool = new ObjectPoolTyped<PetStompEffect, PetStompEffectParams>(() => new PetStompEffect(), {}, 1, 1)
		}

		this.canDoubleAttack = true
	}

	resetStatsFn(statList: EntityStatList) {
		defaultStatAttribute(statList)
		statList._actualStatValues.attackSize = STOMP_EFFECT_RADIUS
		statList._actualStatValues.baseDamage = 0
		statList._actualStatValues.allDamageMult = 1

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.cooldownInterval = 21_000
		statList._actualStatValues.reloadInterval = 50,
		statList._actualStatValues.reloadAmmoIncrement = 1
	}

	useAbility() {
		this.damageBonus.update(getDamageByPlayerLevel() * DAMAGE_MULTIPLIER)
		PetStompEffect.pool.alloc({
			owner: this,
			splashRadius: this.statList.getStat(StatType.attackSize),
			damageDelay: 0.1
		})
	}
}
