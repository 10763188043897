<template>
	<div class="chapter-summary-table">
		<div class="list-data-container">
			<div class="embellishment">
				<img src="../../../../assets/ui/divider/separator-embellishment.png" alt="" />
			</div>
			<div class="header">
				<div class="cellData" role="columnheader" :lang="this.$i18n.locale">Player Name</div>
				<div class="cellData" role="columnheader"><Pagination :disablePips="true" :title-string="selectCategoryString" @on-click-back-fn="previousCategory" @on-click-forward-fn="nextCategory"></Pagination></div>
			</div>
			<div class="body" v-if="!chapterRunDataLoading && tableRowStats.length > 0">
				<div v-for="(rowData, rowIndex) in tableRowStats" :key="rowIndex" class="rowData">
					<div v-for="cellData in rowData" :key="cellData" class="cellData" :lang="this.$i18n.locale">{{ cellData }}</div>
				</div>
			</div>
			<div class="body" v-else>
				<div class="empty-cell" :lang="this.$i18n.locale">
					{{ $t('chapter_select.no_leaderboard_data') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Pagination from '../pagination/pagination.vue'
import { map, pick, sortBy } from 'lodash'

export default {
	name: 'ChapterSummaryTable',
	components: {
		Pagination,
	},
	data() {
		return {
			leaderboarderHeader: [
				{
					id: 'character',
					name: 'summary_table.header_character',
				},
				{
					id: 'sumOfEnemiesKilled',
					name: 'summary_table.header_sum_of_enemies_killed',
				},
				{
					id: 'totalRunDurationInSeconds',
					name: 'summary_table.header_total_run_duration_in_seconds',
				},
				{
					id: 'totalScore',
					name: 'summary_table.header_total_Score',
				},
				{
					id: 'isWin',
					name: 'summary_table.header_is_win',
				},
			],
			defaultSelectedCategory: 'totalScore',
			tableRowStats: [],
		}
	},
	props: {
		headers: {
			type: Array,
			required: false,
		},
		tableRowData: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState('story', ['chapterRunDataLoading', 'selectedChapterTableStats']),
		selectCategoryString() {
			return this.leaderboarderHeader.find((header) => header.id === this.defaultSelectedCategory).name
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		getTableStats() {
			if (this.defaultSelectedCategory === 'isWin') {
				const arrayColData = this.selectedChapterTableStats
					.sort((a, b) => {
						if (b.isWin && !a.isWin) {
							return 1
						} else if (!b.isWin && a.isWin) {
							return -1
						} else {
							return 0
						}
					})
					.map((runData) => ({ username: runData.username, metric: runData.isWin ? 'Victory!' : 'Defeated' }))
				this.tableRowStats = arrayColData
			} else {
				const arrayColData = this.selectedChapterTableStats.map((runData) => {
					return {
						username: runData.username,
						metric: runData[this.defaultSelectedCategory],
					}
				})
				arrayColData.sort((a, b) => b.metric - a.metric)

				if (this.defaultSelectedCategory === 'totalScore' || this.defaultSelectedCategory === 'sumOfEnemiesKilled') {
					arrayColData.map((runData) => {
						if (runData.metric) {
							runData.metric = runData.metric.toString().commafy()
						} else {
							runData.metric = ''
						}
					})
				}
				this.tableRowStats = arrayColData
			}
		},
		previousCategory() {
			const currentIndex = this.leaderboarderHeader.findIndex((header) => header.id === this.defaultSelectedCategory)

			if (currentIndex === 0) {
				this.defaultSelectedCategory = this.leaderboarderHeader[this.leaderboarderHeader.length - 1].id
			} else {
				this.defaultSelectedCategory = this.leaderboarderHeader[currentIndex - 1].id
			}
			this.getTableStats()
		},
		nextCategory() {
			const currentIndex = this.leaderboarderHeader.findIndex((header) => header.id === this.defaultSelectedCategory)

			if (currentIndex === this.leaderboarderHeader.length - 1) {
				this.defaultSelectedCategory = this.leaderboarderHeader[0].id
			} else {
				this.defaultSelectedCategory = this.leaderboarderHeader[currentIndex + 1].id
			}
			this.getTableStats()
		},
	},
	mounted() {
		this.getTableStats()
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.chapter-summary-table {
	.list-data-container {
		position: relative;
		overflow-x: hidden;
		.embellishment {
			position: absolute;
			top: 38px;
			left: 0;
			z-index: 3;
			pointer-events: none;
		}
		.header {
			display: flex;
			margin-bottom: 10px;
			&.empty {
				height: 42px;
			}
			.cellData {
				flex: 1;
				text-align: center;
				color: @cream;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 40px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				&:first-child {
					flex: 0 0 478px;
					text-align: left;
				}
				&:last-child {
					justify-content: flex-end;
					text-align: right;
					margin-right: 10px;
				}
			}
		}
		.body {
			height: 520px;
			overflow-x: hidden;
			.rowData {
				display: flex;
				align-items: center;
				background-color: @darkBlue;
				margin: 6px 0px;
				border-radius: 5px;
				position: relative;
				overflow-x: hidden;
				.cellData {
					text-align: center;
					color: @green !important;
					flex: 1;
					padding: 20px 0px;
					overflow-x: hidden;
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						letter-spacing: 2px;
						font-size: 42px;
					}
					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
					}
					&:first-child {
						color: @offWhite !important;
						letter-spacing: 1px;
						flex: 0 0 365px;
						margin-left: 112px; // Change this (56px) when we get player icons on the leaderboard.
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						text-align: left;
						overflow-x: hidden;
					}
					&:last-child {
						width: 302px;
						text-align: center;
						position: absolute;
						left: 478px;
						justify-content: flex-end;
						text-align: center;
						overflow-x: hidden;
					}
				}
			}
			.empty-cell {
				display: flex;
				align-items: center;
				background-color: @darkBlue;
				margin: 10px 0px;
				border-radius: 5px;
				position: relative;
				overflow-x: hidden;
				width: inherit;
				padding: 22px 0px;
				color: @offWhite;
				justify-content: center;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					letter-spacing: 2px;
					font-size: 38px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
			}
		}
	}
}
</style>
