// more here: https://easings.net/

export function easeOutBounce(t: number, length: number) {
	return bounded(t, length, easeOutBounceUnbounded)
}

export function easeOutElastic(t: number, length: number) {
	return bounded(t, length, easeOutElasticUnbounded)
}
export function easeOutElasticReversed(t: number, length: number) {
	return bounded(t, length, () => easeOutElastic(t, length))
}

export function easeOutBounceReversed(t: number, length: number) {
	return bounded(length - t, length, easeOutBounceUnbounded)
}

export function easeOutBounceUnbounded(x: number): number {
	const n1 = 7.5625
	const d1 = 2.75

	if (x < 1 / d1) {
		return n1 * x * x
	} else if (x < 2 / d1) {
		return n1 * (x -= 1.5 / d1) * x + 0.75
	} else if (x < 2.5 / d1) {
		return n1 * (x -= 2.25 / d1) * x + 0.9375
	} else {
		return n1 * (x -= 2.625 / d1) * x + 0.984375
	}
}

export function easeOutElasticUnbounded(x: number): number {
	const c4 = (2 * Math.PI) / 3

	return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1
}

export function linear10_2s(t) {
	return Math.max(0, 1 - t / 2)
}

export function linear10_4s(t) {
	return Math.max(0, 1 - t / 4)
}

export function bounceOutAndDecay(t) {
	return easeOutExpo(t) * linear10_2s(t)
}

function easeOutExpo(x: number): number {
	return x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
}

function bounded(t: number, length: number, f: (t) => number) {
	t = Math.clamp(t / length, 0, 1)
	return f(t)
}

/*
function drawFunction(label: string, func: (t: number) => number, duration) {
	const nx = 50
	console.log('\n-' + label + '-'.repeat(nx - label.length))
	for (let yi = 0; yi < 20; yi++) {
		const y = 1 - yi / 20
		let s = ' '
		for (let xi = 0; xi < nx; xi++) {
			const x = (xi / nx) * duration
			const yr = func(x)
			s += Math.abs(yr - y) < 0.02 ? 'X' : '.'
		}
		console.log(s)
	}
}

export function testDrawFunctions() {

	drawFunction('linear10_2s', linear10_2s, 2)
	//drawFunction('easeOutElasticUnbounded', easeOutElasticUnbounded, 2)
	//drawFunction('easeOutBounce', t => easeOutBounce(t, 2), 2)
	drawFunction('easeOutElastic', t => easeOutElastic(t, 2), 2)
	//drawFunction('easeOutBounceUnbounded', easeOutBounceUnbounded, 2)

	drawFunction('easeOutBounceReversed', t => easeOutBounceReversed(t, 2), 2)

	drawFunction('easeOutBounceUnbounded', t => easeOutBounceUnbounded(t), 2)

	drawFunction('easeOutBounce', t => easeOutBounce(t, 2), 2)

	drawFunction('easeOutExpo', easeOutExpo, 2)

	drawFunction('test', t => easeOutExpo(t) * linear10_2s(t), 2)
	process.exit(0)
}
/**/
