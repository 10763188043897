import { StatOperator, StatType, StatUnit } from "../stats/stat-interfaces-enums"
import { UpgradeCollections } from "./upgrade-definitions"
import { AffectedEntities, AffectedEntitiesAllPrimaryWeapons, AffectedEntitiesAllSecondaryWeapons } from './upgrade-entities'
import { PetRescueEventSystem } from "../events/pet-rescue-gameplay-event"
import { allocGroundPickup, splayGroundPickupsInRadius } from "../entities/pickups/ground-pickup"
import { callbacks_addCallback } from "../utils/callback-system"
import { Vector } from "sat"
import { percentage } from "../utils/primitive-types"
import { GroundPickupConfigType, GroundPickupType } from "../entities/pickups/ground-pickup-types"
import { LightningStrike, LightningStrikeParams } from "../entities/lightning-strike"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"

// 20~ will appear on the ground, the rest are added instantly
const RATHER_BE_RICH_COMMON_CURRENCY = 300
// 10~ will appear on the ground, the rest are added instantly
const RATHER_BE_RICH_RARE_CURRENCY = 20
export const MORE_GAIN_MORE_PAIN_BONUS_COMMON_CURRENCY: percentage = 1.5

export const EVENT_UPGRADES: Partial<UpgradeCollections> = {
	"bossCloseAndBloody": {
		name: "Boss Close and Bloody",
		desc: "",
		shape: "single",
		top:
		{
			name: "Close and Bloody",
			desc: "MASSIVELY increase {glossary.damage}, but you can no longer knock enemies back, and halve your stun and chill chance.",
			icon: 'event-close-and-bloody',
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 1.0],
				[['Player'], 'attackKnockback', StatOperator.MULTIPLY, -1.0],
				[['Player'], 'stunChance', StatOperator.MULTIPLY, -0.5],
				[['Player'], 'chillChance', StatOperator.MULTIPLY, -0.5],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},

	},
	"bossFasterThanBefore": {
		name: "Boss Faster Than Before",
		desc: "event-faster-than-before",
		shape: "single",
		top:
		{
			name: "Faster Than Before",
			desc: "Greatly increase {glossary.cooldown_speed} and {glossary.attack_rate}, at the cost of some {glossary.damage}",
			icon: 'event-faster-than-before',
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'cooldownInterval', StatOperator.MULTIPLY, -0.30],
				[['PlayerSkill'], 'cooldownInterval', StatOperator.MULTIPLY, -0.2857], // roughly -50% total for player skill
				[['Player'], 'attackRate', StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, -0.15],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},

	},
	"bossStrongerBetter": {
		name: "Boss Stronger, Better, Faster, Longer",
		desc: "",
		shape: "single",
		top:
		{
			name: "Stronger, Better, Faster, Longer",
			desc: "Greatly increase {glossary.attack_size} and {glossary.skill_duration}",
			icon: 'event-stronger-better-faster-longer',
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.50],
				[['Player'], 'skillDuration', StatOperator.SUM_THEN_MULTIPLY, 0.50],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},

	},
	"bossBulletHeaven": {
		name: "Boss Bullet Heaven",
		desc: "",
		shape: "single",
		top:
		{
			name: "Bullet Heaven",
			desc: "Add 1 {glossary.projectile_count} and 1 {glossary.split}",
			icon: 'event-bullet-heaven',
			unlocks: ['straightShooter'],
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'projectileCount', StatOperator.SUM, 1],
				[['Player'], 'projectileSpreadAngle', StatOperator.SUM, 30],
				[['Player'], 'projectileSplitCount', StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.Boomerang, AffectedEntities.AcidBottles, AffectedEntities.NikolaScope, AffectedEntities.DarkStormyNight, AffectedEntities.FireFairy, AffectedEntities.PhoenixBombs],
		},

	},
	"bossBrokenHeart": {
		name: "Boss Broken Heart",
		desc: "",
		shape: "single",
		top:
		{
			name: "A Broken Heart Weeps Open",
			desc: "IMMEDIATELY take 2 hearts of non-lethal damage, but gain 3 max hearts",
			icon: 'event-a-broken-heart-weeps-open',
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'maxHealth', StatOperator.SUM, 6]
			],
			simpleApplyFn(player, state) {
				const damage = Math.min(player.currentHealth - 1, 4)
				player.takeDamage(damage, null)
			},
			affectedEntities: [AffectedEntities.Player],
		},

	},
	"bossMasterOfElements": {
		name: "Boss Master of Elements",
		desc: "",
		shape: "single",
		top:
		{
			name: "Master of Elements",
			desc: "Massively increase {glossary.knockback} as well as potency of all elements: Poison, Ignite, Chill, Shock, Bleed, Doom, but lose a small amount of direct {glossary.damage}",
			icon: 'event-master-of-elements',
			unlocks: ['elementalConsistency'],
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player', 'Pets'], 'allAilmentPotencyMult', StatOperator.SUM_THEN_MULTIPLY, 1.2],
				[['Player'], 'attackKnockback', StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, -0.15],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
	},
	"bossHyperPets": {
		name: "Boss Hyper Pets",
		desc: "",
		shape: "single",
		top:
		{
			name: "Hyper Pets",
			desc: "Halve your Pet cooldowns, and gain a Pet instantly",
			icon: 'event-hyper-pets',
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Pets'], 'cooldownInterval', StatOperator.MULTIPLY, -0.5],
			],
			simpleApplyFn(player, state) {
				PetRescueEventSystem.getInstance().createAndAddPet()
			},
			affectedEntities: [AffectedEntities.Pet],
		},
	},
	"bossChainReaction": {
		name: "Boss Chain Reaction",
		desc: "",
		shape: "single",
		top:
		{
			name: "Chain Reaction",
			desc: "Gain 2 {glossary.chain}, attacks and Pets gain a large chance to inflict {glossary.doom}, but lose a moderate amount of {glossary.damage}",
			icon: 'event-chain-reaction',
			unlocks: ['elementalConsistency', 'straightShooter'],
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'projectileChainCount', StatOperator.SUM, 2],
				[['Player', 'Pets'], 'doomChance', StatOperator.SUM, 0.5],
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, -0.25],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
	},
	"bossForestFire": {
		name: "Boss Forest Fire",
		desc: "",
		shape: "single",
		top:
		{
			name: "Forest Fire",
			desc: "Gain 2 {glossary.pierce} and 2 {glossary.split}, attacks and Pets gain a large chance to {glossary.ignite} for bonus damage, but {glossary.sacrifice} a massive amount of {glossary.damage}",
			icon: 'event-forest-fire',
			unlocks: ['elementalConsistency', 'straightShooter'],
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'attackPierceCount', StatOperator.SUM, 2],
				[['Player'], 'projectileSplitCount', StatOperator.SUM, 2],
				[['Player', 'Pets'], 'igniteChance', StatOperator.SUM, 0.5],
				[['Player', 'Pets'], 'ignitePotency', StatOperator.SUM, 0.5],
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, -0.50],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
	},
	"bossBigBrainLearning": {
		name: "Boss Big Brain Learning",
		desc: "",
		shape: "single",
		top:
		{
			name: "Big Brain Learning",
			desc: "Greatly increase XP gained, and your {glossary.attack_size} also increases your {glossary.pick_up_range}",
			icon: 'event-big-brain-learning',
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[['Player'], 'xpValueMulti', StatOperator.SUM_THEN_MULTIPLY, 0.3],
			],
			simpleApplyFn(player, state) {
				// console.group('Big Brain Learning upgrade converter')
				// console.log(`before ${player.stats.getStat('pickupRange')} range, ${player.stats.getStat('attackSize')}`)
				state.converter = {
					inputStatType: StatType.attackSize,
					inputStatUnit: StatUnit.Percentage,
					inputRatio: 0.0,
					inputFreeRatio: 1.0,
					inputMinReserve: 1.0,
					outputStatType: StatType.pickupRange,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
					outputRatio: 1.0,
				}
				player.stats.addConverter(state.converter)
				// console.log(`after ${player.stats.getStat('pickupRange')} range, ${player.stats.getStat('attackSize')}`)
				// console.groupEnd()
			},
			simpleRemoveFn(player, state) {
				player.stats.removeConverter(state.converter)
			},
			affectedEntities: [AffectedEntities.Player],
		},
	},
	"bossJokersWild": {
		name: "Boss Joker's Wild",
		desc: "",
		shape: "single",
		top:
		{
			name: "Joker's Wild",
			desc: "Your upgrades are even luckier. Adds 1 additional choice during level-ups",
			icon: 'event-jokers-wild',
			pools: {
				"boss": 1,
			},
			binaryFlags: ['1-additional-upgrade-choice'],
			affectedEntities: [AffectedEntities.Player],
		},
	},
	"BossRatherBeRich": {
		name: "Boss I'd Rather be Rich",
		desc: "",
		shape: "single",
		top:
		{
			name: "I'd Rather be Rich",
			desc: "Immediately get rich with tons of lost scrolls and paper scraps",
			icon: 'event-id-rather-be-rich',
			pools: {
				"boss": 1,
			},
			simpleApplyFn(player, state) {
				const itemStack = new Array(50).fill(GroundPickupConfigType.CommonCurrencySmall, 0, 35).fill(GroundPickupConfigType.RareCurrency, 25, 50)
				player.addCurrency(GroundPickupType.CommonCurrency, RATHER_BE_RICH_COMMON_CURRENCY - 20)
				player.addCurrency(GroundPickupType.RareCurrency, RATHER_BE_RICH_RARE_CURRENCY - 15)
				callbacks_addCallback(player, () => {
					splayGroundPickupsInRadius(itemStack, player.x, player.y, 1200, 2200, 0.04)
				}, 0.75)
			},
			affectedEntities: [AffectedEntities.Player],
		},
	},
	"bossMoreGainMorePain": {
		name: "Boss More Gain, More Pain",
		desc: "",
		shape: "single",
		top:
		{
			name: "More Gain, More Pain",
			desc: "Greatly increase Paper Scrap drops, and gain {glossary.damage} based on Paper Scraps earned this run",
			icon: 'event-more-gain-more-pain',
			pools: {
				"boss": 1,
			},
			binaryFlags: ['more-paper-scrap-drops-50', 'gain-damage-based-on-paper-scraps-25'],
			affectedEntities: [AffectedEntities.Player, ...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
	},
	"bossIcyComeIcyGo": {
		name: "Boss Icy Come, Icy Go",
		desc: "",
		shape: "single",
		top:
		{
			name: "Icy Come, Icy Go",
			desc: "Attacks with your primary weapon always {glossary.chill} enemies, and enemies that touch you receive {glossary.chill} permanently",
			icon: 'event-icy-come-icy-go',
			unlocks: ['elementalConsistency'], //kind of redundant but consistent
			pools: {
				"boss": 1,
			},
			statBonuses: [
				[["PrimaryWeapon"], 'chillChance', StatOperator.SUM, 1.0],
			],
			binaryFlags: ['enemies-permanently-chilled-on-touch'],
			affectedEntities: [AffectedEntities.Player, ...AffectedEntitiesAllPrimaryWeapons],
		},
	},
	"bossDemigodOfThunder": {
		name: "Boss Demigod of Thunder",
		desc: "",
		shape: "single",
		top:
		{
			name: "Demigod of Thunder",
			desc: "Every 15 seconds, call down a lightning storm, adding more bolts for every {glossary.projectile_count}, {glossary.split}, and {glossary.chain}",
			icon: 'event-demigod-of-thunder',
			pools: {
				"boss": 1,
			},
			binaryFlags: ['demigod-of-thunder'],
			simpleApplyFn(player, state) {
				if(!LightningStrike.pool) {
					LightningStrike.pool = new ObjectPoolTyped<LightningStrike, LightningStrikeParams>(() => new LightningStrike(), {}, 16, 4)
				}
			},
			affectedEntities: [AffectedEntities.Player],
		},
	},
}