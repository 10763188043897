import { Enemy } from "../../../entities/enemies/enemy"
import { Player } from "../../../entities/player"
import EntityStatList from "../../../stats/entity-stat-list"
import { PassiveSkill } from "../../passive-skill"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { AllWeaponTypes } from "../../weapon-types"

export class EmptyPassiveSkill extends PassiveSkill {
    weaponType: AllWeaponTypes = AllWeaponTypes.None

    onEnemyKilled(enemy: Enemy): void {
    }
    
    onCreate(player: Player): void {
    }
    
    onShot(): void {
    }
    
    onEnemyHit(enemy: Enemy, damageDealt: number): void {
    }
    
    update(delta: number): void {
    }
    
    resetStatsFunction(statList: EntityStatList): void {
        defaultStatAttribute(statList)
    }

}