import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import { GameState } from "../../engine/game-state"
import { InstancedSpriteSheetAnimator } from "../../engine/graphics/instanced-spritesheet-animator"
import { callbacks_addCallback } from "../../utils/callback-system"
import { timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { AcidBottlesWeapon } from "../../weapons/actual-weapons/secondary/acid-bottles-weapon"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { EntityType } from "../entity-interfaces"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"

const POISON_BOTTLE_ATTACK_SIZE = 160

const DESPAWN_DELAY: timeInSeconds = 0.7

export interface AcidBottlesHazardParams extends DamagingGroundHazardParams {
    weapon: AcidBottlesWeapon
}

export class AcidBottlesHazard extends DamagingGroundHazard {
    static pool: ObjectPoolTyped<AcidBottlesHazard, AcidBottlesHazardParams>

    gfx: InstancedSpriteSheetAnimator

    weapon: AcidBottlesWeapon

    boundDespawnCallback: () => void

    constructor() {
        super()
        this.makeGraphics()
        this.boundDespawnCallback = this.despawnDelayFinished.bind(this)
    }

    override setDefaultValues(defaultValues: any, overrideValues?: AcidBottlesHazardParams): void {
        super.setDefaultValues(defaultValues, overrideValues)

        if(overrideValues) {
            this.weapon = overrideValues.weapon

            this.colliderComponent.setLayer(this.weapon.acidBottleRechargeInAcidSpeed > 1 ? CollisionLayerBits.NeutralGroundHazard : CollisionLayerBits.PlayerGroundHazard)

            this.gfx.position.x = this.position.x
            this.gfx.position.y = this.position.y
            this.gfx.scale.x = this.triggerRadius / POISON_BOTTLE_ATTACK_SIZE
            this.gfx.scale.y = this.triggerRadius / POISON_BOTTLE_ATTACK_SIZE
            this.gfx.zIndex = this.position.y

            this.gfx.playAnimation('spawn/poison-aoe-spawn', 'idle/poison-aoe-idle')

            this.gfx.addToScene(true)
        }
    }

    override cleanup(): void {
        super.cleanup()

        this.gfx.removeFromScene()
        this.weapon = null
        this.damaging = false
    }

    override onHitEnemy(enemy: Enemy): void {
        if (this.weapon.carryOverAcid) {
            if (!enemy.isDead()) {
                enemy.acidBottleTicks++
            }
        }
    }

    override onEntityEnterTrigger(entity: ColliderComponent): void {
        if (entity.owner.entityType === EntityType.Player) {
            if (this.weapon.acidBottleRechargeInAcidSpeed > 1) {
                this.weapon.standingInAcidPoolCount++
                this.weapon.cooldown.cooldownSpeedModifier = this.weapon.acidBottleRechargeInAcidSpeed
            }
        } else {
            if (this.weapon.applyPrimaryWeaponDamageDebuff) {
                Buff.apply(BuffIdentifier.PrimaryWeaponVulnerable, GameState.player, entity.owner)
            }
        }
    }

    override onEntityLeaveTrigger(entity: ColliderComponent): void {
        if (entity.owner.entityType === EntityType.Player) {
            if (this.weapon.acidBottleRechargeInAcidSpeed > 1) { // possible bug, but with DEBUG tools removing upgrades only; can lock the cdr higher if you remove the upgrade while standing in acid
                this.weapon.standingInAcidPoolCount--
                if (this.weapon.standingInAcidPoolCount === 0) {
                    this.weapon.cooldown.cooldownSpeedModifier = 1
                }
            }
        } else {
            if (entity.owner.isEnemy) {
                const enemy = entity.owner as Enemy
                if (this.weapon.carryOverAcid) {
                    if (!enemy.isDead() && enemy.acidBottleTicks > 0) {
                        // apply damaging debuff
                        const buff = Buff.apply(BuffIdentifier.AcidBottleCarryOver, GameState.player, enemy, enemy.acidBottleTicks)
                        buff.state.damageSource = this.weapon
                        enemy.acidBottleTicks = 0
                    }
                }
    
                if (this.weapon.applyPrimaryWeaponDamageDebuff) {
                    Buff.remove(enemy, BuffIdentifier.PrimaryWeaponVulnerable, GameState.player)
                }
            }
        }
    }

    returnToPool() {
        this.gfx.playAnimation('despawn/poison-aoe-despawn')
        this.damaging = false
        callbacks_addCallback(this, this.boundDespawnCallback, DESPAWN_DELAY)
    }

    despawnDelayFinished() {
        AcidBottlesHazard.pool.free(this)
    }

    makeGraphics() {
        const sheet = AssetManager.getInstance().getAssetByName('poison-bottle').spritesheet
        this.gfx = new InstancedSpriteSheetAnimator(sheet, 'spawn/poison-aoe-spawn')
        
        this.gfx.position.x = this.position.x
        this.gfx.position.y = this.position.y
    }

    isPlayerOwned(): boolean {
        return true
    }
}