<template>
	<div id="character-select">
		<div class="chat-button">
			<IconButton :icon-name="'chat'" :button-style="'green'" :on-click-prop="openActivityModal"></IconButton>
		</div>
		<div class="main-container">
			<div class="story-container">
				<div class="portrait">
					<div class="large-portrait" :class="[selectedCharacter.icon, { disabled:         characterIsLocked(selectedCharacter.characterType) }]"></div>
				</div>
				<div class="player-story-information">
					<Panel id="summary" :height="-1">
						<template #panel-content>
							<div class="story-header">
								<div class="flex-column">
									<div class="title" :lang="this.$i18n.locale">{{ selectedStory.name }}</div>
									<div class="author" :lang="this.$i18n.locale">Authored by {{ findAuthor }}</div>
								</div>
							</div>
							<div class="story-info">
								<div class="story-stats">
									<Table :disable-header="true" :table-row-data="chapterSummaryVue" :large-font="false"></Table>
								</div>
								<div v-if="!this.selectedStory.isSolo" class="story-id">
									<Header :header-text="`Selected Story`" :text-alignment="'center'" :font-face="'pixl'" :font-size="'medium'"></Header>
									<GameIdContainer :customText="selectedStoryId">
										<template #panel-content></template>
									</GameIdContainer>
								</div>
							</div>
						</template>
					</Panel>
					<div v-if="this.selectedStory.twists.length > 0" class="mutators">
						<ScrollableItemsContainer @on-click-fn="arrowButton" :show-scroll-buttons="true" :enable-arrow-background="true" v-if="getSelectedStory">
							<template #arrow-content>
								<div class="books-container" id="moveable">
									<div class="book-list" id="plot-twist-container">
										<ItemUpdated
											v-for="(i, index) in getSelectedStory.formattedTwists"
											:key="index"
											:id="`twist-${index}`"
											:item="i"
											:disable-circle="true"
											:small-icons="true"
											:enable-glow="false"
											:selected-item="false"
											:on-click-prop="() => {}"
											:disable-book="false"
											:component="'twist'"
											:show-book-background="true"
											:tooltip-enabled="true"
										></ItemUpdated>
									</div>
								</div>
							</template>
						</ScrollableItemsContainer>
					</div>
				</div>
			</div>
			<div class="chapter-weapon-container">
				<div class="character-container">
					<ScrollableItemsContainer :show-scroll-buttons="false">
						<template #arrow-content>
							<div v-for="(item, index) in getCharacterData" :key="index">
								<SquareItem :item="item" @on-click-fn="selectCharacter" :selected="characterSelectedFn(item)" :disabled="characterIsLocked(item.characterType)" :key="index + characterAndWeaponRefreshIndex"></SquareItem>
							</div>
						</template>
					</ScrollableItemsContainer>
					<div class="character-name-container">
						<div class="label" :lang="this.$i18n.locale">Selected Hero:</div>
						<Header :header-text="selectedCharacter.name" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'large'"></Header>
					</div>
					<div class="description-container">
						<div class="description-container">
							<div class="character-description" :lang="this.$i18n.locale" v-html="selectedCharacter.description"></div>
							<div v-if="selectedCharacter.skillName !== undefined" class="active-skill-description" :lang="this.$i18n.locale">
								<span class="label">Skill:</span> <span class="name">{{ selectedCharacter.skillName }}</span
								><span class="definition">- {{ selectedCharacter.skillDefinition }}</span>
							</div>
							<div v-if="selectedCharacter.passiveName !== undefined" class="passive-skill-description" :lang="this.$i18n.locale">
								<span class="label">Passive:</span> <span class="name">{{ selectedCharacter.passiveName }}</span
								><span class="definition">- {{ selectedCharacter.passiveDefinition }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="weapon-container" v-if="selectedCharacter.name !== 'Cone Dog'">
					<ScrollableItemsContainer :show-scroll-buttons="false">
						<template #arrow-content>
							<div v-if="selectedCharacter.name === 'Cone Dog'">
								<!-- TODO: Make the cone dog bite a primary and give it an icon -->
								<!-- <SquareItem :item="'none'" @on-click-fn="selectWeapon" :selected="true" :disabled="false"></SquareItem> -->
							</div>
							<div v-else v-for="(item, index) in getWeaponData" :key="index">
								<SquareItem :item="item" @on-click-fn="selectWeapon" :selected="weaponSelectedFn(item)" :disabled="weaponIsLocked(item.weaponType)" :key="index + characterAndWeaponRefreshIndex"></SquareItem>
							</div>
						</template>
					</ScrollableItemsContainer>

					<div class="weapon-name-container">
						<div class="label" :lang="this.$i18n.locale">Selected Weapon:</div>
						<Header :header-text="getWeaponName" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'large'"></Header>
					</div>
					<div class="description-container">
						<div class="weapon-description" :lang="this.$i18n.locale" v-html="getWeaponDescription"></div>
					</div>
					<!-- <Header header-text="Evolutions" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'"></Header>
							<div class="evolve-description-container" :lang="this.$i18n.locale">
								<div>
									{{ getWeaponEvolveDescription(1) }}
								</div>
								<div>
									{{ getWeaponEvolveDescription(2) }}
								</div>
								<div>
									{{ getWeaponEvolveDescription(3) }}
								</div>
							</div> -->
				</div>
			</div>
		</div>
		<div class="bottom-container">
			<div class="group" :style="{'width': `${currentSettings.width}px`}">
				<div class="left-container">
					<TextButton class="back-button" :button-label="$t('character_select.button_story_select')" :button-style="'blue'" @on-click-fn="backToStories" :no-wrap="true"></TextButton>
					<TextButton class="chapter-button" :button-label="$t('character_select.button_chapter_select')" :button-style="'green'" @on-click-fn="changeView" :optional-param="{ view: Views.CHAPTER_SELECT }" v-if="!isFirstRunFTUE" :no-wrap="true"></TextButton>
				</div>
				<div class="bottom-middle-container">
					<Header header-text="Twists Up for Vote" :text-alignment="'center'" :font-face="'pixl'" :font-size="'medium'" :font-color="'green'"></Header>
					<div class="icon-container">
						<ItemUpdated v-for="twist in indexedTwists" :key="twist" :item="twist" :component="'twist'" :tooltip-enabled="true" :small-icons="true"></ItemUpdated>
					</div>
				</div>
				<div class="right-container">
					<TextButton class="shop-button" id="perks-and-unlocks-btn" :button-label="$t('character_select.button_meta_progression')" :button-style="'green'" @on-click-fn="showOrHidePerks(true) || updateDarkenOverlay(true)" :disabled="false"></TextButton>
					<TextButton class="start-button" :button-label="$t('character_select.button_start_story')" :button-style="'bright-green'" @on-click-fn="joinStory" :disabled="!doneAssetLoading || !loadoutValid" :loading="!doneAssetLoading"></TextButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Background from '../ui-components/background/background.vue'
import SmallPanel from '../ui-components/panel/small-panel.vue'
import Panel from '../ui-components/panel/panel.vue'
import Table from '../ui-components/table/table.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import SquareItem from '../ui-components/item/square-items.vue'
import ScrollableItemsContainer from '../ui-components/arrow/arrow-background.vue'
import FlexTable from '../ui-components/table/flex-table.vue'
import GameIdContainer from '../ui-components/container/text-container.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import Currency from '../ui-components/currency/currency.vue'
import { Views } from '../store/ui-store'
import { clearAllUrlParams } from '../store/end-chapter-store'
import { AllWeaponTypes, WeaponConfig } from '../../weapons/weapon-types'
import { MetaUnlocksManager } from '../../upgrades/meta/meta-unlocks-manager'
import IconButton from '../ui-components/buttons/icon-button.vue'
import { TutorialFlagsEnum } from '../../ftue/tutorial-flags'
import { CharacterType } from '../../game-data/characters'

export default {
	name: 'CharacterSelect',
	components: {
		Background,
		SmallPanel,
		Panel,
		Table,
		Header,
		TextButton,
		SquareItem,
		ScrollableItemsContainer,
		FlexTable,
		GameIdContainer,
		ItemUpdated,
		Currency,
		IconButton,
	},
	data() {
		return {
			flags: this.flags,
			Views: Views,
			AllWeaponTypes: AllWeaponTypes,
		}
	},
	computed: {
		// ...mapGetters('ui', ),
		...mapGetters('characterSelect', ['selectedCharacter', 'selectedWeapon', 'getCharacterData', 'getWeaponData']),
		...mapGetters('story', ['getSelectedStory', 'selectedChapter']),
		...mapGetters('ftue', ['getFlag']),
		...mapGetters('ui', ['portal']),
		...mapState('ui', ['doneAssetLoading']),
		...mapState('story', ['selectedStoryId', 'selectedStory', 'selectedChapter']),
		...mapState('user', ['currencies', 'username']),
		...mapState('endChapter', ['indexedTwists']),
		...mapState('characterSelect', ['characterAndWeaponRefreshIndex', 'noWeaponData']),
		...mapState('settings', ['currentSettings']),

		chapterSummaryVue() {
			let formattedData = []
			formattedData.push(
				{ rowName: `Chapter Number`, value: this.selectedChapter === 0 ? 'Prelude' : this.selectedChapter },
				{ rowName: `Players`, value: this.selectedStory.players === undefined ? 1 : this.selectedStory.players.length },
				{ rowName: `Plot Twists`, value: this.selectedStory.twists.length },
			)
			return formattedData
		},
		getWeaponName() {
			if (this.selectedWeapon.isFakeWishlistWeapon) {
				return this.selectedWeapon.name
			}

			return WeaponConfig[this.selectedWeapon.weaponType].name
		},
		getWeaponDescription() {
			if (this.selectedWeapon.isFakeWishlistWeapon) {
				return this.selectedWeapon.description
			}

			return WeaponConfig[this.selectedWeapon.weaponType].description
		},
		loadoutValid() {
			if ((this.selectedCharacter.characterType !== CharacterType.ConeDog && this.selectedWeapon.isFakeWishlistWeapon) || this.selectedCharacter.characterType < 0) {
				return false
			}
			return !this.characterIsLocked(this.selectedCharacter.characterType) && !this.weaponIsLocked(this.selectedWeapon.weaponType)
		},
		findAuthor() {
			const creatorId = this.selectedStory.creator
			const author = this.selectedStory.players.find((p) => p.playerId === creatorId)
			return author.username
		},
		isFirstRunFTUE() {
			return !this.getFlag(TutorialFlagsEnum.PlayedStory)
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('characterSelect', ['updateSelectedCharacter', 'updateSelectedWeapon']),
		...mapMutations('metaProgression', ['showOrHidePerks']),
		...mapMutations('ui', ['updateDarkenOverlay']),
		...mapActions('ui', ['startGame', 'changeActiveView']),
		...mapActions('story', ['joinStory', 'fetchChapterRuns', 'refreshStory']),
		...mapActions('endChapter', ['fetchMutators']),
		...mapMutations('activityFeed', ['updateActivityFeedModal']),
		...mapActions('activityFeed', ['cancelLongPollGetNewActivity']),

		openActivityModal() {
			this.updateActivityFeedModal(true)
			this.updateDarkenOverlay(true)
		},

		selectCharacter(characterData) {
			this.updateSelectedCharacter(characterData)
			if (characterData.name === 'Cone Dog') {
				this.selectWeapon(this.noWeaponData)
			} else {
				if (this.selectedWeapon.weaponType === 0) {
					const defaultWand = this.getWeaponData.find((weapon) => weapon.weaponType === AllWeaponTypes.Wand)
					this.selectWeapon(defaultWand)
				}
			}
		},
		getWeaponEvolveDescription(num: number) {
			return WeaponConfig[this.selectedWeapon.weaponType].primary[`evolveDescription${num}`]
		},
		characterSelectedFn(selectedSkin) {
			return selectedSkin.name === this.selectedCharacter.name
		},
		selectWeapon(weaponData) {
			this.updateSelectedWeapon(weaponData)
		},
		weaponSelectedFn(selectedSkin) {
			return WeaponConfig[selectedSkin.weaponType].name === WeaponConfig[this.selectedWeapon.weaponType].name
		},
		changeView(params) {
			const { view } = params
			if (view === this.Views.CHAPTER_SELECT) {
				// kick this off but don't wait around, there's a loading indicator
				this.fetchChapterRuns(this.selectedChapter)
			}
			if (view === this.Views.STORY_SELECT) {
				clearAllUrlParams()
			}
			this.changeActiveView(view)
		},
		weaponIsLocked(weapon) {
			return !MetaUnlocksManager.getInstance().isPrimaryWeaponAllowed(weapon)
		},
		characterIsLocked(character) {
			return !MetaUnlocksManager.getInstance().isCharacterAllowed(character)
		},
		arrowButton(direction) {
			const el = document.getElementById('twist-0')
			const scrollableContainer = document.getElementById('moveable')
			let width = el.offsetWidth
			let style = getComputedStyle(el)
			width += parseInt(style.marginLeft) + parseInt(style.marginRight)

			switch (direction) {
				case 'left':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft - width, behavior: 'smooth' })
					break
				case 'right':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft + width, behavior: 'smooth' })
					break
			}
		},
		backToStories() {
			clearAllUrlParams()
			this.changeActiveView(Views.STORY_SELECT)
			this.cancelLongPollGetNewActivity()
		},
	},

	mounted() {
		// Make sure we are getting the twists for the newest chapter
		this.refreshStory().then(() => {
			this.fetchMutators()
		})
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#character-select {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	&.ratio21By9 {
		.bottom-container {
			.group {
				margin: 0 auto;
				width: 1920px;
			}
		}
	}
	.chat-button {
		position: absolute;
		top: 4%;
		left: 3%;
		.icon-button {
			width: 100px;
		}
	}
	.main-container {
		// width: 100%;
		height: calc(100% - 170px);
		display: flex;
		.story-container {
			display: flex;
			flex-direction: column;
			width: 820px;
			margin: 0px 0px 0px 12px;
			align-items: center;
			.text-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				justify-content: space-around;
				.header {
					color: @cream;
					margin: 0px 0px 0px 0px;
					&[lang='en'] {
						.regularFont(@enSecondaryFontPTBold);
						font-size: 40px;
						font-variant: small-caps;
					}
					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
						font-size: 24px;
					}
				}

				.game-id {
					display: flex;
					align-items: center;
					height: 100%;
					margin: 0px 30px;
					color: @darkGreen;
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						font-variant: small-caps;
						font-size: 40px;
					}
				}
			}
			.player-story-information {
				width: 700px;
				margin: 20px 0px;
				display: flex;
				flex-direction: column;
				:deep(.panel-inner) {
					border-width: 26px 11px 52px;
				}
				.story-header {
					margin-bottom: 15px;
					.flex-column {
						display: flex;
						flex-direction: column;
						text-align: center;
						margin: 0px 30px;
						.title {
							color: @cream;
							&[lang='en'] {
								.regularFont(@enSecondaryFontPTBold);
								font-size: 40px;
								font-variant: small-caps;
							}
							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
								font-size: 24px;
							}
						}
						.author {
							color: @green;
							&[lang='en'] {
								.regularFont(@enRegularFontPixl);
								font-size: 30px;
							}
							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
								font-size: 24px;
							}
						}
					}
				}
				.story-info {
					display: flex;
					justify-content: space-around;
					margin: 0px 20px;
					.story-stats {
						width: 380px;
					}
					.story-id {
						width: 380px;
						display: flex;
						flex-direction: column;
						align-items: center;
					}
				}
				.mutators {
					display: flex;
					flex-direction: column;
					align-items: center;
					:deep(#arrow-background) {
						margin: 6px 0;
					}
					.books-container {
						overflow: hidden;
						width: 418px;
						margin-bottom: 8px;
						.book-list {
							display: flex;
							.book {
								margin: 0px 10px;
							}
						}
					}
				}
			}

			.portrait {
				display: flex;
				justify-content: center;
				align-items: center;
				.large-portrait {
					width: 543px;
					height: 415px;
					background-size: contain;
					background-position: bottom;
					background-repeat: no-repeat;
					position: relative;
					&.disabled{
						filter: grayscale(1);
					}
					&.character-cone-dog {
						background-image: url('~/assets/ui/character-portraits/conewolf-portrait-fancy.png');
						top: 30px;
						left: 30px;
					}
					&.character-barb {
						background-image: url('~/assets/ui/character-portraits/barb-barian-portrait-fancy.png');
						top: 10px;
						left: 30px;
					}
					&.character-elf-n-ranjar {
						background-image: url('~/assets/ui/character-portraits/elf-portrait-fancy.png');
					}
					&.character-solara {
						background-image: url('~/assets/ui/character-portraits/solara-potrait-fancy.png');
					}
					&.character-radar {
						background-image: url('~/assets/ui/character-portraits/radar-portrait-fancy.png');
						transform: scale(0.95);
						top: 20px;
						left: 60px;
					}
					&.locked-character {
						background-image: url('~/assets/ui/character-portraits/locked-character-portrait-fancy.png');
					}
				}
			}
		}
		.chapter-weapon-container {
			width: 1103px;
			margin: 40px 60px 0px 0px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.character-container {
				.description-container {
					height: 230px;
					overflow-y: auto;
					text-align: center;
					.character-description {
						color: @offWhite;
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-size: 24px;
							letter-spacing: 2px;
						}
					}
					.active-skill-description,
					.passive-skill-description {
						margin: 5px 0px;
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-size: 30px;
							letter-spacing: 2px;
						}
						.label {
							color: @cream;
						}
						.name {
							color: @green;
						}
						.definition {
							color: @offWhite;
						}
					}
				}
			}
			.character-container,
			.weapon-container {
				width: 1103px;
				display: flex;
				flex-direction: column;
				align-items: center;
				:deep(#scrollable-items) {
					height: 135px;
				}
				.character-name-container,
				.weapon-name-container {
					display: flex;
					align-items: center;
					margin: 10px 0px;
					.label {
						color: @offWhite;
						margin-right: 30px;
						&[lang='en'] {
							.regularFont(@enSecondaryFontPTBold);
							font-size: 50px;
							font-variant: small-caps;
						}
						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
							font-size: 24px;
						}
					}
				}
				.weapon-description {
					margin: 5px 0px;
					text-align: center;
					color: @offWhite;
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						font-size: 30px;
						letter-spacing: 2px;
					}
				}

				.evolve-description-container {
					display: flex;
					flex-direction: row;
					justify-items: center;
					width: 980px;
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						font-size: 26px;
						letter-spacing: 1px;
					}

					div {
						width: 100%;
						color: @offWhite;
						margin: 0 10px;
					}
				}
			}

			.base-stats-container {
				margin-top: 30px;
			}
			.weapon-container {
				margin-top: 20px;
				.header-container {
					margin-top: 15px;
				}
			}
		}
	}
	.bottom-container {
		position: absolute;
		background-color: @darkBlue;
		height: 150px;
		width: 100%;
		bottom: 0px;
		.group {
			max-width: 1920px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			position: relative;
			padding: 0px 60px;
			.back-button,
			.chapter-button,
			.shop-button,
			.start-button {
				height: 77px;
				width: 290px;
				margin: 0px 8px;
			}

			.left-container {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
			.bottom-middle-container {
				flex: 0 1 100%;
				display: flex;
				flex-direction: column;
				align-self: center;
				position: relative;
				top: -10px;
				.icon-container {
					display: flex;
					flex-direction: row;
					justify-content: center;
				}
			}
			.right-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 968px;
				justify-content: flex-end;
			}
		}
	}
}
</style>
