import { Container } from 'pixi.js'
import {
	AnimFrame,
	EmitterConfig,
	Vec2PropertyMode,
	getColorFromPropConfig,
	getFloatFromPropConfig,
	getVec2FromPropConfig,
	getReciprocalFloatFromPropConfig,
	FadeAffectorMode,
	ScaleAffectorMode,
	RotateAffectorMode,
	ColorBlendAffectorMode,
	EmissionShape,
	FlipbookAffectorMode,
	EmissionMode,
	ForceAffectorMode,
	AffectorConfig,
} from './emitterConfig'
import { SpriteParticleRenderer } from './sprite-particle-renderer'
import * as affectors from './standard-affectors'
import { Effect } from './effect'
import { InstancedSpriteRenderable } from './instanced-sprite-batcher'
import { RenderQueue, RenderQueueElementType } from '../render-queue'
import logger from '../../../utils/client-logger'
import { deepFreeze } from '../../../utils/debug'

interface Affector {
	update: (dt: number, particles: Particle[], numParticles: number, cfg: any) => void
	onSpawn: (particle: Particle, cfg: any) => void
	cfg: any
}

const GLOBAL_MAX_PARTICLES = 50000
const DEG2RAD = 1.0 / (180.0 / Math.PI)

export interface Particle {
	dbgid: number
	owner: Emitter
	x: number
	y: number
	vel: number[]
	rot: number
	scale: number
	color: number[]
	age: number
	lifeRecip: number
	frame: AnimFrame
	startRot: number
	startScale: number
	startColor: number[]

	// Properties for the various affectors. They will be pre-allocated/initialized
	// when the particle pool is created
	affectors: any

	prev: Particle
	next: Particle
}

export class Emitter {
	static EnablePerformanceReporting() {
		this.performanceReportingEnabled = true
	}

	static PerformanceReport() {
		if (Emitter.totalPfxUpdates <= 0) {
			return
		}

		const now = performance.now()
		if ((now - Emitter.lastReportTime) / 1000 > Emitter.timeBetweenReports) {
			Emitter.lastReportTime = now
			const updates = Emitter.totalTimeUpdatingPfx / Emitter.totalPfxUpdates
			const emissions = Emitter.totalTimeEmittingParticles / Emitter.totalPfxUpdates
			const creating = Emitter.totalTimeCreatingEmitters
			const total = updates + emissions
			logger.debug('Avg updating pfx = ' + updates.toFixed(3) + 'ms, emitting = ' + emissions.toFixed(3) + 'ms, total = ' + total.toFixed(3) + '[ms, total time creating = ' + creating.toFixed(3) + ']')
		}
	}
	static CurrentEmitter: Emitter

	x: number = 0
	y: number = 0
	rot: number = 0
	ofsX: number = 0
	ofsY: number = 0
	scale: number = 0
	scaleX: number = 1
	scaleY: number = 1
	startColor?: { r: number; b: number; g: number; a: number }
	alpha: number = 1
	worldSpace = true
	followParent = false

	// Made public for a cheeky little hack in aff_flipbookOnSpawn
	anims: AnimFrame[][]

	cfg: EmitterConfig

	private static freeHead: Particle = null
	private static globalParticlePoolCreated = false
	private static totalTimeUpdatingPfx = 0
	private static totalTimeEmittingParticles = 0
	private static totalTimeCreatingEmitters = 0
	private static totalPfxUpdates = 0
	private static timeBetweenReports = 1
	private static lastReportTime = 0
	private static performanceReportingEnabled = false

	private readyCallbacks: Array<(emitter: Emitter) => void> = []
	private ready = false
	private parent: Container
	private numParticlesToEmit = 0
	private atlasTexture: PIXI.BaseTexture
	private atlas: PIXI.spine.core.TextureAtlas = null
	private currAtlasName: string = null
	private renderableParticles: InstancedSpriteRenderable[]
	private particles: Particle[] = []
	private numActiveParticles = 0
	private numParticlesRemovedThisFrame = 0
	private spriteRenderer: SpriteParticleRenderer
	private delayTimer: number = 0

	private activeHead: Particle = null
	private activeTail: Particle = null
	private affectors: Affector[] = []

	// The box emitter can be configured to have a random size - we need to choose it once and save it
	private boxEmitterSizes: number[]
	// Similarly, the circle emitter can have a random radius.
	private circleEmitterRadius: number

	private frames: AnimFrame[]
	private nextBurstTimer: number = 0
	private owner: Effect
	private isVisible = true

	private perfEmitTimeAccumulator = 0
	private perfEmitTimeCount = 0
	private perfUpdateTimeAccumulator = 0
	private perfUpdateTimeCount = 0

	constructor(owner: Effect, cfg: EmitterConfig) {
		this.owner = owner
		this.cfg = cfg
		this._createParticlePool()
		this._init()
	}

	get visible(): boolean {
		return this.isVisible
	}

	set visible(value: boolean) {
		this.isVisible = value
	}

	getNumAffectors() {
		return this.affectors.length
	}

	getNumActiveParticles() {
		return this.numActiveParticles
	}

	ensureParticleEmitted() {
		if (!this.cfg.continuous) {
			return
		}
		const emitTime: number = getFloatFromPropConfig(this.cfg.continuous.spawnRate)
		if (this.numParticlesToEmit < emitTime) {
			this.numParticlesToEmit = emitTime
		}
	}

	getAverageTimeEmittingSinceLastCheck() {
		if (this.perfEmitTimeCount === 0) {
			return 0
		}

		const ret = this.perfEmitTimeAccumulator / this.perfEmitTimeCount
		this.perfEmitTimeAccumulator = 0.0
		this.perfEmitTimeCount = 0
		return ret
	}

	getAverageTimeUpdatingSinceLastCheck() {
		if (this.perfUpdateTimeCount === 0) {
			return 0
		}

		const ret = this.perfUpdateTimeAccumulator / this.perfUpdateTimeCount
		this.perfUpdateTimeAccumulator = 0.0
		this.perfUpdateTimeCount = 0
		return ret
	}

	update(dt: number) {
		if (!this.ready) {
			return
		}

		//bail out of update if delay timer has not yet been reached
		this.delayTimer -= dt
		if (this.delayTimer > 0) {
			return
		}

		let now = 0
		let then = 0
		if (Emitter.performanceReportingEnabled) {
			then = performance.now()
		}

		if (this.owner.enabled) {
			this._emit(dt)
		}

		if (Emitter.performanceReportingEnabled) {
			now = performance.now()
			Emitter.totalTimeEmittingParticles += now - then
			this.perfEmitTimeAccumulator += now - then
			this.perfEmitTimeCount++
		}

		if (Emitter.performanceReportingEnabled) {
			then = performance.now()
		}

		this._simParticles(dt)

		// If this is a local space emitter and we have a parent, render won't get called directly.
		// This update does though.
		// Instead, we rely on pixi rendering it in its normal course of rendering of the sprite.
		// We attach to the container if needed and ready the sprites for when pixi gets to rendering
		// them (actually invoked by us via the RenderQueue)
		if (!this.worldSpace && this.owner.parent) {
			if (!this.parent) {
				this.parent = this.owner.parent
				this.spriteRenderer.addToContainer(this.parent)
			}
			this._fillRenderables()
			this.spriteRenderer.renderParticles(this.renderableParticles, this.numActiveParticles)
			this._removeDeadParticles()
		}

		if (Emitter.performanceReportingEnabled) {
			now = performance.now()
			Emitter.totalTimeUpdatingPfx += now - then
			Emitter.totalPfxUpdates++
			this.perfUpdateTimeAccumulator += now - then
			this.perfUpdateTimeCount++
		}
	}

	render(renderer: RenderQueue) {
		if (!this.worldSpace) {
			logger.debug(`Warning - an emitter is set to local space but is trying to be rendered directly. Attach the effect to a spine sprite`)
		} else {
			this._fillRenderables()

			// If not spawning along Z, yay, can sort them all together as one 'layer'
			if (!this.cfg.zFollowsY) {
				const z = this.owner.zIndex + (this.owner.parent ? this.owner.parent.zIndex : 0) + this.owner.zOffset
				renderer.addElement(
					{
						particles: this.renderableParticles,
						numParticles: this.numActiveParticles,
						name: this.owner.name + ' : ' + this.cfg.name, // for debug
					},
					RenderQueueElementType.InstancedSpriteGroup,
					z
				)
			} else {
				// Otherwise, each particle ultimately needs sorted against the other top level objects, so needs to
				// be added individually with own zIndex
				for (let i = 0, len = this.numActiveParticles; i < len; ++i) {
					const p = this.renderableParticles[i]
					const z = p.pos[1] + this.owner.zOffset
					renderer.addElement(p, RenderQueueElementType.InstancedSprite, z)
				}
			}
			this._removeDeadParticles()
		}
	}

	getConfig(): EmitterConfig {
		return this.cfg
	}

	reset() {
		let p = this.activeHead
		while (p) {
			const next = p.next
			this._addToFree(p)
			p = next
		}

		this.numParticlesToEmit = 0
		this.numActiveParticles = 0
		this.nextBurstTimer = 0
		this.numParticlesRemovedThisFrame = 0

		this.activeHead = null
		this.activeTail = null
		this.affectors = []
		this._createParticlePool()
		this._init()
	}

	setConfig(cfg: EmitterConfig) {
		this.cfg = cfg
		this._configureAnims()
		this._configureAffectors()
	}

	getAtlas(): PIXI.spine.core.TextureAtlas {
		return this.atlas
	}

	isReady(): boolean {
		return this.ready
	}

	whenReady(cb: (emitter: Emitter) => void) {
		if (this.isReady()) {
			cb(this)
		} else {
			this.readyCallbacks.push(cb)
		}
	}

	addAffector(aff: AffectorConfig) {
		this._addAffector(aff)
	}

	private _simParticles(dt: number) {
		Emitter.CurrentEmitter = this

		// Build up particles array to point to all the active particles
		// After this, numActiveParticles will store the total number of particles
		// This won't necessarily be the same as particles.length
		this._collectActiveParticles()

		// Run each affector, in turn, on all active particles.
		// Each affector can mutate the particle in any way it wants.
		// We're all friends around here.
		for (let i = 0; i < this.affectors.length; ++i) {
			this.affectors[i].update(dt, this.particles, this.numActiveParticles, this.affectors[i].cfg)
		}

		// Advance the age of the active particles and kill them if they've moved
		// beyond their predetermined lifetime, returning them to the pool.
		this._ageActiveParticles(dt)

		Emitter.CurrentEmitter = undefined
	}

	private _collectActiveParticles() {
		let p = this.activeHead
		let numParticles = 0

		while (p != null) {
			this.particles[numParticles] = p
			numParticles++
			p = p.next
		}
	}

	private _removeDeadParticles() {
		const len = this.numActiveParticles
		for (let i = 0; i < len; ++i) {
			const p = this.particles[i]
			if (p.age >= 1.0) {
				// it's removing it from the linked list for next frame, not this array which is this
				// frame's particles - so no need to worry about this array getting changed here
				this._removeParticle(p)
			}
		}

		this.numActiveParticles -= this.numParticlesRemovedThisFrame
		this.numParticlesRemovedThisFrame = 0
	}

	private _ageActiveParticles(dt: number) {
		const len = this.numActiveParticles

		for (let i = 0; i < len; ++i) {
			const p = this.particles[i]
			p.age += dt * p.lifeRecip
			if (p.age > 1.0) {
				p.age = 1.0
			}
		}
	}

	private _fillRenderables() {
		const len = this.numActiveParticles

		const scaleX = this.scaleX
		const scaleY = this.scaleY
		const thisScale = this.scale
		const thisAlpha = this.alpha

		for (let i = 0; i < len; ++i) {
			const renderParticle = this.renderableParticles[i]
			const p = this.particles[i]

			const alpha = p.color[3] * thisAlpha
			const frame = p.frame
			const scale = p.scale * thisScale
			const color = p.color

			renderParticle.pos[0] = p.x + frame.ofs[0]
			renderParticle.pos[1] = p.y + frame.ofs[1]

			renderParticle.scale[0] = scale * frame.size[0] * scaleX
			renderParticle.scale[1] = scale * frame.size[1] * scaleY

			// I'm deliberately not doing the next line. It never changes and these renderables are
			// preallocated and initialized up front. I put this line here just to show it wasn't
			// an omission (it wouldn't matter if it *was* here, just a tiny tiny tiny performance thing)
			// renderParticle.texture = this.atlasTexture
			// Same for this one
			// renderParticle.blendMode = this.cfg.blendMode

			// This pains me but is due to the way the editor works - I need things
			// to come through in degrees.
			renderParticle.rot = p.rot * DEG2RAD

			if (this.followParent) {
				renderParticle.pos[0] += this.x
				renderParticle.pos[1] += this.y
				renderParticle.rot += this.rot
			}

			// Premultiply alpha
			renderParticle.color[0] = color[0] * alpha
			renderParticle.color[1] = color[1] * alpha
			renderParticle.color[2] = color[2] * alpha
			renderParticle.color[3] = alpha

			renderParticle.uvExtents[0] = frame.uvShift[0]
			renderParticle.uvExtents[1] = frame.uvShift[1]
			renderParticle.uvExtents[2] = frame.uvSize[0]
			renderParticle.uvExtents[3] = frame.uvSize[1]
		}
	}

	private _createParticlePool() {
		let now = 0
		let then = 0

		if (!Emitter.globalParticlePoolCreated) {
			if (Emitter.performanceReportingEnabled) {
				then = performance.now()
			}
			const max = GLOBAL_MAX_PARTICLES
			for (let i = 0; i < max; ++i) {
				const p: Particle = {
					next: null,
					prev: null,
					scale: 1,
					startScale: 1,
					x: 0,
					y: 0,
					vel: [0, 0],
					rot: 0,
					startRot: 0,
					color: [1, 1, 1, 1],
					startColor: [1, 1, 1, 1],
					age: 0,
					lifeRecip: 0.0,
					affectors: {},
					frame: null,
					dbgid: i,
					owner: null,
				}

				// Not all emitters will have all affectors - but, there's only one global
				// pool of particles (for efficiency reasons). This means we have to add the
				// properties for each affector type, to ALL particles, just in case the emitter
				// using the particle will use it. Not a huge deal as it's only created once, here
				affectors.aff_fadeAddProps(p)
				affectors.aff_scaleAddProps(p)
				affectors.aff_rotateAddProps(p)
				affectors.aff_colorBlendAddProps(p)
				affectors.aff_flipbookAddProps(p)
				affectors.aff_forceAddProps(p)

				this._addToFree(p)
			}
			Emitter.globalParticlePoolCreated = true

			if (Emitter.performanceReportingEnabled) {
				now = performance.now()
				logger.debug(`---- Just took ${now - then}ms to create GLOBAL particle pool of ${GLOBAL_MAX_PARTICLES} particles`)
			}
		}

		if (Emitter.performanceReportingEnabled) {
			then = performance.now()
		}

		// This array will be updated each frame to store references to the current active particles
		this.particles = []
		this.particles.length = this.cfg.maxParticles

		if (Emitter.performanceReportingEnabled) {
			now = performance.now()
			Emitter.totalTimeCreatingEmitters += now - then
			logger.debug(`Just took ${now - then}ms to create particle pool`)
		}
	}

	private _emit(dt: number) {
		if (this.cfg.emissionMode === EmissionMode.Continuous) {
			this._emitContinuous(dt)
		} else {
			this._emitBurst(dt)
		}
	}

	private _emitBurst(dt: number) {
		if (this.nextBurstTimer >= 0.0) {
			this.nextBurstTimer -= dt

			if (this.nextBurstTimer <= 0) {
				const numParticlesToEmit = getFloatFromPropConfig(this.cfg.burst.numParticles)

				this._emitParticles(numParticlesToEmit)

				if (this.cfg.burst.repeat) {
					this.nextBurstTimer = getFloatFromPropConfig(this.cfg.burst.timeBetweenBursts)
				} else {
					this.nextBurstTimer = -1.0 // Don't burst again
				}
			}
		}
	}

	private _emitContinuous(dt: number) {
		this.numParticlesToEmit += getFloatFromPropConfig(this.cfg.continuous.spawnRate) * dt
		const numThisTime = Math.floor(this.numParticlesToEmit)
		this.numParticlesToEmit -= numThisTime
		this._emitParticles(numThisTime)
	}

	private _emitParticles(numToEmit: number) {
		const cosRot = Math.cos(this.rot)
		const sinRot = Math.sin(this.rot)

		for (let i = 0; i < numToEmit; ++i) {
			const p = this._getParticleFromPool()

			if (!p) {
				console.error(`Failed to get pooled particle for ${this.cfg.name}, bailing`)
				return
			}

			p.owner = this

			let x = this.x
			let y = this.y

			if (this.cfg.shape === EmissionShape.Box) {
				const boxX = this.scale * (-this.boxEmitterSizes[0] / 2.0 + this.boxEmitterSizes[0] * Math.random())
				const boxY = this.scale * (-this.boxEmitterSizes[1] / 2.0 + this.boxEmitterSizes[1] * Math.random())
				const boxXRot = boxX * cosRot - boxY * sinRot
				const boxYRot = boxX * sinRot + boxY * cosRot

				x = x + boxXRot
				y = y + boxYRot
			} else if (this.cfg.shape === EmissionShape.Circle) {
				if (this.cfg.circleEmitter.edgeOnly) {
					const angle = Math.random() * Math.PI * 2.0
					const scaledRad = this.scale * this.circleEmitterRadius
					x += Math.cos(angle) * scaledRad
					y += Math.sin(angle) * scaledRad
				} else {
					const a = Math.random() * 2.0 * Math.PI
					const scaledRad = this.scale * this.circleEmitterRadius
					const r = scaledRad * Math.sqrt(Math.random())
					x += r * Math.cos(a)
					y += r * Math.sin(a)
				}
			}

			if (this.followParent) {
				p.x = 0
				p.y = 0
				p.rot = 0
			} else {
				p.x = x
				p.y = y
				p.rot = this.rot
			}

			p.frame = null

			getVec2FromPropConfig(this.cfg.velocity, p.vel)

			const vx = p.vel[0] * cosRot - p.vel[1] * sinRot
			const vy = p.vel[0] * sinRot + p.vel[1] * cosRot
			p.vel[0] = vx
			p.vel[1] = vy

			if (this.startColor !== undefined) {
				const c = this.startColor
				p.startColor = [c.r, c.g, c.b, c.a]
			} else {
				getColorFromPropConfig(this.cfg.color, p.startColor)
			}
			p.startScale = getFloatFromPropConfig(this.cfg.scale, 1.0)
			p.startRot = getFloatFromPropConfig(this.cfg.rot)
			p.lifeRecip = getReciprocalFloatFromPropConfig(this.cfg.lifetime)

			this.affectors.forEach((aff: Affector, idx: number) => {
				if (aff.onSpawn) {
					aff.onSpawn(p, this.affectors[idx].cfg)
				}
			})

			// Set initial values
			p.age = 0
			p.color[0] = p.startColor[0]
			p.color[1] = p.startColor[1]
			p.color[2] = p.startColor[2]
			p.color[3] = p.startColor[3]
			p.scale = p.startScale
			p.rot = p.startRot

			// If no affector's onSpawn set the frame, just fallback to a random choice from "frames"
			if (p.frame == null) {
				let frameIdx = 0
				if (this.frames.length > 1) {
					frameIdx = Math.floor(Math.random() * this.frames.length)
				}

				p.frame = this.frames[frameIdx]
			}

			this._addParticle(p)
		}
	}

	private _addToFree(p: Particle) {
		p.next = Emitter.freeHead
		if (Emitter.freeHead) {
			Emitter.freeHead.prev = p
		}
		Emitter.freeHead = p
	}

	private _getParticleFromPool(): Particle {
		if (Emitter.freeHead != null && this.numActiveParticles < this.cfg.maxParticles) {
			const ret = Emitter.freeHead
			Emitter.freeHead = Emitter.freeHead.next
			if (Emitter.freeHead != null) {
				Emitter.freeHead.prev = null
			}
			ret.next = ret.prev = null

			// Took a new one from the free list, so we now have one more active
			this.numActiveParticles++
			return ret
		} else {
			// Recycling existing, so num active doesn't change
			const ret = this.activeTail
			if (ret != null) {
				this.activeTail = ret.prev
				if (this.activeTail) {
					this.activeTail.next = null
				}
				if (ret === this.activeHead) {
					this.activeHead = null
				}
			}
			return ret
		}
	}

	private _addParticle(p: Particle) {
		if (this.activeHead === null) {
			this.activeTail = p
		} else {
			this.activeHead.prev = p
		}

		p.next = this.activeHead
		p.prev = null
		this.activeHead = p
	}

	private _removeParticle(p: Particle) {
		if (p === this.activeTail) {
			this.activeTail = p.prev
		}
		if (p === this.activeHead) {
			this.activeHead = p.next
		}

		if (p.next !== null) {
			p.next.prev = p.prev
		}
		if (p.prev !== null) {
			p.prev.next = p.next
		}

		p.prev = p.next = null

		this.numParticlesRemovedThisFrame++

		this._addToFree(p)
	}

	private _init() {
		let now = 0
		let then = 0
		if (Emitter.performanceReportingEnabled) {
			then = performance.now()
		}

		// Guaranteed it'll be in the cache or _init wouldn't have been called
		const maxParticles = this.cfg.maxParticles
		this.atlasTexture = Effect.GetAtlasTexture(this.cfg.atlas).baseTexture
		this.atlas = Effect.GetAtlas(this.cfg.atlas)
		this.currAtlasName = this.cfg.atlas

		if (!this.cfg.worldSpace) {
			this.spriteRenderer = new SpriteParticleRenderer({
				blendMode: this.cfg.blendMode,
				maxParticles: this.cfg.maxParticles,
				texture: this.atlasTexture,
			})
		}

		let then2
		if (Emitter.performanceReportingEnabled) {
			then2 = performance.now()
		}

		// Preallocate the particle renderables - we'll fill in their details each
		// frame before passing them off to the particle renderer(s)
		// FIXME: This step isn't really necessary, or wouldn't be if InstancedSpriteRenderable
		//        has same property names as Particle
		this.renderableParticles = []
		this.renderableParticles.length = maxParticles
		for (let i = 0; i < maxParticles; ++i) {
			this.renderableParticles[i] = {
				color: [1, 1, 1, 1],
				pos: [0, 0],
				rot: 0,
				scale: [1, 1],
				uvExtents: [0, 0, 1, 1],
				texture: this.atlasTexture,
				blendMode: this.cfg.blendMode,
				name: this.owner.name + ' : ' + this.cfg.name
			}
		}

		if (Emitter.performanceReportingEnabled) {
			logger.debug(`====Took ${performance.now() - then2}ms to create rendererables`)
		}

		this.ofsX = this.cfg.ofsX
		this.ofsY = this.cfg.ofsY
		this.worldSpace = this.cfg.worldSpace
		this.followParent = this.cfg.followParent
		this._configureAnims()
		this._configureAffectors()

		if (this.cfg.shape === EmissionShape.Box) {
			this.boxEmitterSizes = [getFloatFromPropConfig(this.cfg.boxEmitter.width), getFloatFromPropConfig(this.cfg.boxEmitter.height)]
		} else if (this.cfg.shape === EmissionShape.Circle) {
			this.circleEmitterRadius = getFloatFromPropConfig(this.cfg.circleEmitter.radius)
		}

		if (this.cfg.startDelay) {
			this.delayTimer = getFloatFromPropConfig(this.cfg.startDelay.delayTime)
		}

		this.ready = true

		this.readyCallbacks.forEach((cb) => {
			cb(this)
		})

		if (Emitter.performanceReportingEnabled) {
			now = performance.now()
		}
		Emitter.totalTimeCreatingEmitters += now - then
		if (Emitter.performanceReportingEnabled) {
			logger.debug(`Just took ${now - then}ms to create effect`)
		}
	}

	private _configureAnims() {
		const existingFrames = frameConfigMap.get(this.cfg)
		if (existingFrames) {
			this.frames = existingFrames
		} else {
			this.frames = []
			this.frames.length = this.cfg.frames.length

			if (this.cfg.frames.length === 0) {
				// Can't ever have no frames - just use the first in the atlas by default
				const region = this.atlas.regions[0]
				this.frames.push(this._frameFromAtlasRegion(region))
			} else {
				for (let i = 0; i < this.cfg.frames.length; ++i) {
					const frame = this.cfg.frames[i]
					let region = this.atlas.findRegion(frame)
					if (!region) {
						region = this.atlas.regions[0]
					}
					this.frames[i] = this._frameFromAtlasRegion(region)
				}
			}

			frameConfigMap.set(this.cfg, this.frames)
			deepFreeze(this.frames)
		}

		// Resolve anim frame indices
		if (this.cfg.anims && this.cfg.anims.length !== 0) {
			const existingAnims = animConfigMap.get(this.cfg)
			if (existingAnims) {
				this.anims = existingAnims
			} else {
				this.anims = []
				this.anims.length = this.cfg.anims.length

				for (let i = 0; i < this.cfg.anims.length; ++i) {
					const anim = this.cfg.anims[i]

					this.anims[i] = []

					if (anim.frames.length === 0) {
						// Can't have 0 frames - just push on the first frame of the atlas as a default
						this.anims[i].push(this._frameFromAtlasRegion(this.atlas.regions[0]))
					} else {
						this.anims[i].length = anim.frames.length

						for (let x = 0; x < anim.frames.length; ++x) {
							let region = this.atlas.findRegion(anim.frames[x])
							if (!region) {
								region = this.atlas.regions[0]
							}
							this.anims[i][x] = this._frameFromAtlasRegion(region)
						}
					}
				}

				animConfigMap.set(this.cfg, this.anims)
				deepFreeze(this.anims)
			}
		}
	}

	private _configureAffectors() {
		this.affectors = []

		this.cfg.affectors.forEach((aff, idx) => {
			this._addAffector(aff)
		})

		// I'm treating the velocity affector differently - it's a binary on/off thing and doesn't require a config
		if (this.cfg.velocity.mode !== Vec2PropertyMode.Disabled) {
			this.affectors.push({
				cfg: null,
				onSpawn: null,
				update: affectors.aff_velocity,
			})
		}
	}

	private _addAffector(aff: AffectorConfig) {
		if (aff.id === 'fade' && aff.cfg.mode !== FadeAffectorMode.Disabled) {
			this.affectors.push({
				cfg: aff.cfg,
				onSpawn: affectors.aff_fadeOnSpawn,
				update: affectors.aff_fade,
			})
		} else if (aff.id === 'scale' && aff.cfg.mode !== ScaleAffectorMode.Disabled) {
			this.affectors.push({
				cfg: aff.cfg,
				onSpawn: affectors.aff_scaleOnSpawn,
				update: affectors.aff_scale,
			})
		} else if (aff.id === 'rotate' && aff.cfg.mode !== RotateAffectorMode.Disabled) {
			this.affectors.push({
				cfg: aff.cfg,
				onSpawn: affectors.aff_rotateOnSpawn,
				update: affectors.aff_rotate,
			})
		} else if (aff.id === 'color' && aff.cfg.mode !== ColorBlendAffectorMode.Disabled) {
			this.affectors.push({
				cfg: aff.cfg,
				onSpawn: affectors.aff_colorBlendOnSpawn,
				update: affectors.aff_colorBlend,
			})
		} else if (aff.id === 'force' && aff.cfg.mode !== ForceAffectorMode.Disabled) {
			this.affectors.push({
				cfg: aff.cfg,
				onSpawn: affectors.aff_forceOnSpawn,
				update: affectors.aff_force,
			})
		} else if (aff.id === 'flipbook' && aff.cfg.mode !== FlipbookAffectorMode.Disabled && this.anims.length > 0 /* make sure we have anims to flipbook over */) {
			this.affectors.push({
				cfg: aff.cfg,
				onSpawn: affectors.aff_flipbookOnSpawn,
				update: affectors.aff_flipbook,
			})
		}
	}

	private _frameFromAtlasRegion(region): AnimFrame {
		return {
			uvShift: [region.u, region.v],
			uvSize: [region.u2 - region.u, region.v2 - region.v],
			ofs: [region.offsetX + region.width * 0.5 - region.originalWidth * 0.5, region.pixiOffsetY + region.height * 0.5 - region.originalHeight * 0.5],
			size: [region.width, region.height],
		}
	}
}

const frameConfigMap: Map<EmitterConfig, AnimFrame[]> = new Map()
const animConfigMap: Map<EmitterConfig, AnimFrame[][]> = new Map()
