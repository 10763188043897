import TutorialFlags, { TutorialFlagsEnum } from '../../ftue/tutorial-flags'
import { postSetTutorialFlags } from '../../utils/api/griddle-requests'
import logger from '../../utils/client-logger'

export const ftueManagerStore = () => {
	logger.debug('Initializing FTUE Manager store module...')
	return {
		namespaced: true,
		state: {
			flags: new TutorialFlags(),
		},
		getters: {
            // gets a FUNCTION that you call
			getFlag: (state: any) => (flag: TutorialFlagsEnum) => {
				return state.flags.flagValues[flag]
			},
		},
		mutations: {
			loadAllFlags(state, flags) {
				if (flags) {
					state.flags.loadFromJson(flags)
				}
			},
			completeFlag(state: {flags: TutorialFlags}, flag: TutorialFlagsEnum) {
				const oldCompletion = state.flags.flagValues[flag]
				if (!oldCompletion) {
					state.flags.flagValues[flag] = true
					// update griddle / database
					postSetTutorialFlags(state.flags.flagValues)
				}
			}
		}
	}
}
