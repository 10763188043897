import { Vector } from "sat";
import { KillEnemiesInCirclePOI, KillEnemiesInCirclePOIParams } from "../pois/kill-enemies-in-circle"
import { POI, POIParams } from "../pois/poi"
import { StandInCirclePOI, StandInCirclePOIParams } from "../pois/stand-in-circle"
import { gameUnits, timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { InGameTime } from "../utils/time"
import { EventStartData, GameplayEvent, ShrineGameplayEventPresets } from "./gameplay-event-definitions"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"
import { EventTypes } from "./event-types"

export interface ShrineGameplayEventPOI {
	onPlayerEnteredFn?: (poi: any, state: any) => void
	onPlayerExitedFn?: (poi: any, state: any) => void
	whilePlayerInZoneFn?: (poi: any, delta: timeInSeconds, state: any) => void
	onEventDone?: (poi: any, state: any) => void
}

export interface ShrineGameplayEventConfig {
	poiType: any
	poiParams: any
	getPosition: () => { x: gameUnits, y: gameUnits } | Vector
	getRadius: () => gameUnits
	onPlayerEnteredFn: (poi: any, state: any) => void
	onPlayerExitedFn: (poi: any, state: any) => void
	whilePlayerInZoneFn: (poi: any, delta: timeInSeconds, state: any) => void
	onEventDone: (poi: any, state: any) => void
}

export class ShrineGameplayEvent implements GameplayEvent {
	protected eventPresetConfig: ShrineGameplayEventConfig
	protected poiParams: any
	protected eventStartData: EventStartData

	protected activePoi: POI

	constructor() {
		if (!KillEnemiesInCirclePOI.pool) {
			KillEnemiesInCirclePOI.pool = new ObjectPoolTyped<KillEnemiesInCirclePOI, KillEnemiesInCirclePOIParams>(() => new KillEnemiesInCirclePOI(), {}, 1, 1)
		}
		if (!StandInCirclePOI.pool) {
			StandInCirclePOI.pool = new ObjectPoolTyped<StandInCirclePOI, StandInCirclePOIParams>(() => new StandInCirclePOI(), {}, 1, 1)
		}

		this.poiParams = {
			onEventDone: (poi: POI, state: any) => {
				if (this.eventPresetConfig.onEventDone) {
					this.eventPresetConfig.onEventDone(poi, state)
				}
				this.onEventDoneInternal(poi)
			}
		}
	}

	setRandomPreset() {
		const key = Object.keys(ShrineGameplayEventPresets).pickRandom()
		this.eventPresetConfig = ShrineGameplayEventPresets[key]
	}
	
	startEvent() {				
		this.setRandomPreset()

		const pos = this.eventPresetConfig.getPosition()
		this.poiParams.xPosition = pos.x
		this.poiParams.yPosition = pos.y
		this.poiParams.radius = this.eventPresetConfig.getRadius()
		this.poiParams.state = {}
		this.poiParams.onPlayerEnteredFn = this.eventPresetConfig.onPlayerEnteredFn
		this.poiParams.onPlayerExitedFn = this.eventPresetConfig.onPlayerExitedFn
		this.poiParams.whilePlayerInZoneFn = this.eventPresetConfig.whilePlayerInZoneFn

		switch (this.eventPresetConfig.poiType) {
			case KillEnemiesInCirclePOI:
				this.activePoi = KillEnemiesInCirclePOI.pool.alloc({
					...this.poiParams,
					enemyCount: this.eventPresetConfig.poiParams.enemyCountMultiplier * getEnemyCountByInGameTime(),
				})
				break
			case StandInCirclePOI:
				this.activePoi = StandInCirclePOI.pool.alloc({
					...this.poiParams,
					duration: this.eventPresetConfig.poiParams.getDuration(),
				})
				break
		}
	}

	setStartData(data: EventStartData) {
		this.eventStartData = data
	}
	
	update(delta: timeInSeconds) { }

	protected onEventDoneInternal(poi: POI) {
		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.Shrine)
	}
}

export function getEnemyCountByInGameTime(inGameTime?: timeInSeconds) {
	if (!inGameTime) {
		inGameTime = InGameTime.timeElapsedInSeconds
	}
	return 20 + Math.floor(inGameTime / 20) * 5
}

export function getRadiusByInGameTime(inGameTime?: timeInSeconds) {
	if (!inGameTime) {
		inGameTime = InGameTime.timeElapsedInSeconds
	}
	return Math.clerp(450, 800, inGameTime/900)
}

export function getDecreasingRadiusByInGameTime(inGameTime?: timeInSeconds) {
	if (!inGameTime) {
		inGameTime = InGameTime.timeElapsedInSeconds
	}
	return Math.clerp(800, 500, inGameTime/900)
}