export enum AffectedEntities {
	Player = "player",
	Bow = 'primary-bow',
	Wand = 'primary-wand',
	Spear = 'primary-spear',
	Boomerang = 'primary-boomerang',
	TeslaCoil = "secondary-tesla-coil",
	AcidBottles = "secondary-acid-bottle",
	RatParade = "secondary-rat-parade",
	NikolaScope = "secondary-nikolas-scope",
	Pet = "secondary-pets",
	Bees = 'secondary-bees',
	Lute = 'secondary-lute',
	DarkStormyNight = 'secondary-dark-stormy-night',
	Thorns = 'secondary-cone-dog-thorns',
	FireFairy = 'secondary-fire-fairy',
	PhoenixBombs = 'secondary-phoenix-bombs'
}
export const AffectedEntitiesAllPrimaryWeapons = [AffectedEntities.Bow, AffectedEntities.Wand, AffectedEntities.Spear, AffectedEntities.Boomerang]
export const AffectedEntitiesAllSecondaryWeapons = [AffectedEntities.Bees, AffectedEntities.NikolaScope, AffectedEntities.AcidBottles, AffectedEntities.RatParade, AffectedEntities.TeslaCoil, AffectedEntities.Lute, AffectedEntities.DarkStormyNight, AffectedEntities.FireFairy, AffectedEntities.Thorns, AffectedEntities.PhoenixBombs]