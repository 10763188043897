import { Sprite } from "pixi.js"
import { Vector } from "sat"
import { InstancedSprite } from "../engine/graphics/instanced-sprite"
import { Renderer } from "../engine/graphics/renderer"
import { distanceVV } from "../utils/math"
import { ObjectPool, ObjectPoolTyped } from "../utils/third-party/object-pool"
import { angleBetweenVectors } from "../utils/vector"
import { AssetManager } from "../web/asset-manager"

const SINGLE_GRAPHIC_DISTANCE = 300
const DISTANCE_ADVANCE_VECTOR = new Vector(SINGLE_GRAPHIC_DISTANCE, 0)

type PooledSprite = {
	sprite: Sprite
	poolIndex: number
}

export class ChainLightningGraphic {
	static instancedSpritePools: ObjectPool[]

	sprites: PooledSprite[] = []

	constructor() {
		if (!ChainLightningGraphic.instancedSpritePools) {
			const assetManager = AssetManager.getInstance()
			const spritePools = [
				new ObjectPool(
					() => {  // instanced sprite not working for some reason; can investigate if regular sprite is slow
						const sprite = Sprite.from('arc-lightning-1')// new InstancedSprite(assetManager.getAssetByName('arc-lightning-1').texture, 0, 0, 0)
						sprite['update'] = () => {}
						sprite.anchor.x = 0
						sprite.anchor.y = 0.5
						sprite.zIndex = 99999

						return { sprite, poolIndex: 0 }
					}, {}, 10, 1),
				new ObjectPool(
					() => { 
						const sprite = Sprite.from('arc-lightning-2')//new InstancedSprite(assetManager.getAssetByName('arc-lightning-2').texture, 0, 0, 0)
						sprite['update'] = () => {}
						sprite.anchor.x = 0
						sprite.anchor.y = 0.5
						sprite.zIndex = 99999

						return { sprite, poolIndex: 1 }
					}, {}, 10, 1),
				new ObjectPool(
					() => { 
						const sprite = Sprite.from('arc-lightning-3')//new InstancedSprite(assetManager.getAssetByName('arc-lightning-3').texture, 0, 0, 0)
						sprite['update'] = () => {}
						sprite.anchor.x = 0
						sprite.anchor.y = 0.5
						sprite.zIndex = 99999

						return { sprite, poolIndex: 2 }
					}, {}, 10, 1),
			]

			ChainLightningGraphic.instancedSpritePools = spritePools
		}
	}

	addToScene(startPos: Vector, endPos: Vector) {
		const distance = distanceVV(startPos, endPos)
		const numGraphics = Math.max(Math.round(distance / SINGLE_GRAPHIC_DISTANCE), 1)
		
		const nextStartPos: Vector = new Vector()
		nextStartPos.copy(startPos)

		const scale = distance / (numGraphics * SINGLE_GRAPHIC_DISTANCE)
		const angle = angleBetweenVectors(startPos, endPos)

		DISTANCE_ADVANCE_VECTOR.x = SINGLE_GRAPHIC_DISTANCE
		DISTANCE_ADVANCE_VECTOR.y = 0
		DISTANCE_ADVANCE_VECTOR.rotate(angle)

		const renderer = Renderer.getInstance().mgRenderer

		for (let i = 0; i < numGraphics; ++i) {
			const randomPool = ChainLightningGraphic.instancedSpritePools[Math.getRandomInt(0, ChainLightningGraphic.instancedSpritePools.length - 1)]
			const pooledSprite: PooledSprite = randomPool.alloc()
			const sprite = pooledSprite.sprite

			sprite.x = nextStartPos.x
			sprite.y = nextStartPos.y
			sprite.rotation = angle
			sprite.scale.x = scale

			nextStartPos.add(DISTANCE_ADVANCE_VECTOR)

            renderer.addDisplayObjectToScene(sprite)
			this.sprites.push(pooledSprite)
		}
	}

	removeFromScene() {
		const renderer = Renderer.getInstance().mgRenderer

		for (let i = 0; i < this.sprites.length; ++i) {
			const pooledSprite = this.sprites[i]
			renderer.removeFromScene(pooledSprite.sprite)
			ChainLightningGraphic.instancedSpritePools[this.sprites[i].poolIndex].free(pooledSprite as any)
		}

		this.sprites.length = 0
	}
}
