import { Vector } from "sat"
import { Beam, DEFAULT_BEAM_COLLIDER_CONFIG } from "../../../beams/beams"
import { Audio } from "../../../engine/audio"
import { BoxColliderConfig } from "../../../engine/collision/colliders"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { EffectConfig } from "../../../engine/graphics/pfx/effectConfig"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { Renderer } from "../../../engine/graphics/renderer"
import { InstancedSpriteSheetAnimator } from "../../../engine/graphics/instanced-spritesheet-animator"
import { ExternalPhysicsForce } from "../../../engine/physics/physics"
import { Enemy } from "../../../entities/enemies/enemy"
import { Player } from "../../../entities/player"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { dealAOEDamageDamageSource, DEFAULT_AOE_EXPLOSION_DURATION, DEFAULT_AOE_EXPLOSION_PFX_SIZE, setExplosionColor } from "../../../projectiles/explosions"
import { PlayerProjectile, ProjectileInitialParams } from "../../../projectiles/projectile"
import { ProjectileSystem } from "../../../projectiles/projectile-system"
import { AnimationTrack } from "../../../spine-config/animation-track"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { callbacks_addCallback } from "../../../utils/callback-system"
import { debugConfig } from "../../../utils/debug-config"
import { angleOfVector, degToRad, isPointLeftOfLine, vecAngle } from "../../../utils/math"
import { radians, timeInSeconds } from "../../../utils/primitive-types"
import { angleBetweenVectors, angleInRadsFromVector } from "../../../utils/vector"
import { AssetManager } from "../../../web/asset-manager"
import { ResourceType, WEAPON_STATS } from "../../weapon-definitions"
import { AllWeaponTypes } from "../../weapon-types"
import { MeleePrimaryWeapon, MELEE_SAFETY_ACTIVE_FRAME_TIME } from "./melee-primary-weapon"
import { HolyLight, HolyLightParams } from "../../../entities/holy-light"
import { ObjectPoolTyped } from "../../../utils/third-party/object-pool"

export enum SpearHopTime {
    Startup,
    Active,
    Recovery,
}

export enum SpearHopMode {
    Movement,
    Aim
}

export enum SpearComboMode {
    Single,
    Double,
    Triple
}

interface SpearComboData {
    damageScale: number,
    delayToNextStrike: number,
}

const SPEAR_COMBO_DATA: Record<SpearComboMode, SpearComboData[]> = {
    [SpearComboMode.Single]: [
        {
            damageScale: 1,
            delayToNextStrike: 0.25
        }
    ],
    [SpearComboMode.Double]: [
        {
            damageScale: 1,
            delayToNextStrike: 0.25
        },
        {
            damageScale: 0.66,
            delayToNextStrike: 0
        }
    ],
    [SpearComboMode.Triple]: [
        {
            damageScale: 1,
            delayToNextStrike: 0.25
        },
        {
            damageScale: 0.75,
            delayToNextStrike: 0.25
        },
        {
            damageScale: 0.50,
            delayToNextStrike: 0
        }
    ],
}

const HOP_AOE_DAMAGE_SIZE = 300

const SPEAR_HIT_ANIM_NAME = 'spear-hit'
const SPEAR_DASH_ANIM_NAME = 'spear-dash'

const PFX_OFFSET_X = 65
const DASH_PFX_OFFSET = -12
const DASH_PFX_Y_OFFSET = -60

const DIVINE_PUNISHMENT_SCALE = 2
const DIVINE_PUNISHMENT_MAX_HITS = 3

export const EYE_OF_MERCY_AOE_SIZE = 280

export const SAINTS_SPEAR_AOE_SIZE = 320
const SPEAR_START_LENGTH = 600
const SPEAR_START_WIDTH = 85

const GRAND_PIKE_LENGTH_BONUS = 0.6
const GUNGIR_LENGTH_BONUS = 0.3

const GRAND_PIKE_BLOOD_DAMAGE_SCALE = 0.4

const GRAND_PIKE_AFTERSHOCK_WIDTH = 400
const GRAND_PIKE_AFTERSHOCK_WIDTH_DIFF = GRAND_PIKE_AFTERSHOCK_WIDTH / SPEAR_START_WIDTH

const GUNGIR_ELECTRIC_ATTACK_COUNT = 2 // how many attacks to shoot electric projectiles

export class SpearWeapon extends MeleePrimaryWeapon {

    attackStartupTime: timeInSeconds = 0.1
    attackRecoveryTime: timeInSeconds = 0.25

    attackPfxStartTime: timeInSeconds = 0.01
    attackPfxDuration: timeInSeconds = 0.21

    afterShockStartupTime: timeInSeconds = 0.1 // this starts after attackStartupTime is done, and isn't effected by attackRate
    afterShockPfxStartTime: timeInSeconds = 0.01
    afterShockPfxDuration: timeInSeconds = 0.21

    dashPfxStartTime: timeInSeconds = 0.05
    dashPfxDuration: timeInSeconds = 0.33

    attackAnimName: AnimationTrack = AnimationTrack.SPEAR_STAB
    attackAnimStartingTimeScale: number = 0.9

    startingLength: number = SPEAR_START_LENGTH
    startingWidth: number = SPEAR_START_WIDTH

    lengthScale: number = 1

    projectileEffectType: ParticleEffectType = ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT
    projectileTrailType: ParticleEffectType = ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL
    weaponType: AllWeaponTypes = AllWeaponTypes.Spear

    hopOnAttack: boolean = false
    hopTwice: boolean = false
    hopForce: ExternalPhysicsForce

    aoeOnHop: boolean = false

    bloodProjectilesOnHit: boolean = false
    useGrandPikeAfterShock: boolean = false
    gungirElectricProjectiles: boolean = false

    grandPikeBloodProjectileStatList: EntityStatList
    gungirElectricProjectileStatList: EntityStatList

    get comboMode(): SpearComboMode {
        return this._comboMode
    }
    private _comboMode: SpearComboMode
    comboData: SpearComboData[]

    private isHopping: boolean = false
    private isSecondHop: boolean = false
    private lastHopDirection: Vector = new Vector()
    private aimVectorOnAttackStart: Vector = new Vector()
    private addedConeColliders: boolean = false
    private comboIndex: number = 0

    private nextHitDamageScale: number = 1

    private startingAttackSize: number
    private explosionEffectConfig: EffectConfig

    private spearAttackSpriteSheets: InstancedSpriteSheetAnimator[]
    private attackSpriteSheetIndex: number = 0
    private spearDashSpriteSheets: InstancedSpriteSheetAnimator[]
    private dashSpriteSheetIndex: number = 0
    private afterShockSpriteSheet: InstancedSpriteSheetAnimator

    private reusePfxPosition: Vector = new Vector(PFX_OFFSET_X, 0)
    private boundKnockbackFunction: (mutableEntityPos: Vector, beam: Beam) => Vector
    private boundAfterShockDelayDoneFunction: () => void
    private boundAfterShockPfxStartFunction: () => void
    private boundAfterShockPfxDoneFunction: () => void

    private coneSpreadAngle: radians[]

    private grandPikeAfterShockBeam: Beam
    private lastAfterShockSize: number

    private grandPikeBloodProjectileParams: ProjectileInitialParams
    private gungirElectricProjectileParams: ProjectileInitialParams

    private gungirElectricAttackCount: number = 0

    init(player: Player, playerStatList: EntityStatList): void {
        super.init(player, playerStatList)

        this.hopForce = new ExternalPhysicsForce(new Vector(), debugConfig.spearHopDuration, this.onHopFinished.bind(this))
        this.setComboMode(SpearComboMode.Single)

        this.startingAttackSize = WEAPON_STATS.spear.stats.attackSize
        this.explosionEffectConfig = AssetManager.getInstance().getAssetByName('aoe-explosion-white').data

        const attackSpriteSheet = AssetManager.getInstance().getAssetByName('spear-hit').spritesheet
        this.spearAttackSpriteSheets = []
        for (let i = 0; i < 4 * 3; ++i) {
            const sprite = new InstancedSpriteSheetAnimator(attackSpriteSheet, SPEAR_HIT_ANIM_NAME)
            this.spearAttackSpriteSheets[i] = sprite
            sprite.zIndex = 999_999
            sprite.setLoop(false)
        }

        const aftershockSpriteSheet = AssetManager.getInstance().getAssetByName('spear-faded-hit').spritesheet
        this.afterShockSpriteSheet = new InstancedSpriteSheetAnimator(aftershockSpriteSheet, SPEAR_HIT_ANIM_NAME)
        this.afterShockSpriteSheet.zIndex = 999_999

        const dashSpriteSheet = AssetManager.getInstance().getAssetByName('spear-dash').spritesheet
        this.spearDashSpriteSheets = []
        for (let i = 0; i < 4; ++i) {
            const sprite = new InstancedSpriteSheetAnimator(dashSpriteSheet, SPEAR_DASH_ANIM_NAME)
            this.spearDashSpriteSheets[i] = sprite
        }

        this.boundKnockbackFunction = this.getPerpendicularKnockBack.bind(this)
        this.boundAfterShockDelayDoneFunction = this.onAfterShockDelayFinished.bind(this)
        this.boundAfterShockPfxStartFunction = this.onStartAfterShockPfx.bind(this)
        this.boundAfterShockPfxDoneFunction = this.onStopAfterShockPfx.bind(this)

        this.grandPikeBloodProjectileStatList = new EntityStatList(this.resetGrandPikeBloodProjectileStats, this.statList)
        this.gungirElectricProjectileStatList = new EntityStatList(this.resetGungirElectricProjectileStats, this.statList)

        this.grandPikeBloodProjectileParams = {
            owningEntityId: player.nid,
            position: player.position,
            speed: 0,
            aimAngleInRads: 0,
            radius: 0,
            trajectoryMods: [],
            collisionLayer: CollisionLayerBits.PlayerProjectile,
            statList: this.grandPikeBloodProjectileStatList,
            damageScale: GRAND_PIKE_BLOOD_DAMAGE_SCALE,
            resourceType: ResourceType.NONE,
            player: this.player,
            weaponType: this.weaponType,
            effectType: ParticleEffectType.PROJECTILE_GUGNIR_BLOOD,
            trailType: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
        }

        this.gungirElectricProjectileParams = {
            owningEntityId: player.nid,
            position: new Vector(),
            speed: 0,
            aimAngleInRads: 0,
            radius: 0,
            trajectoryMods: [],
            collisionLayer: CollisionLayerBits.PlayerProjectile,
            statList: this.gungirElectricProjectileStatList,
            damageScale: 0.4,
            resourceType: ResourceType.NONE,
            player: this.player,
            weaponType: this.weaponType,
            effectType: ParticleEffectType.PROJECTILE_GUGNIR_LIGHTNING,
            trailType: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
            resetEffectOnChain: true,
        }

        this.hitboxBeam.onHitCallback = this.onEntityHit.bind(this)

        const afterShockStatlist = new EntityStatList((statList) => {
            statList._actualStatValues.attackKnockback = 1_000
            statList._actualStatValues.attackRate = 1
            statList._actualStatValues.baseDamage = 0
            statList._actualStatValues.attackPierceCount = 999_999
        })

        this.grandPikeAfterShockBeam = Beam.pool.alloc({
            x: player.x,
            y: player.y,
            angle: player.aimAngle,
            width: 0,
            length: 0,
            maxLength: 0,
            overrideGetKnockbackFunction: this.boundKnockbackFunction,

            getDamageScaleFunction: this.getAfterShockDamageScale.bind(this),

            noGfx: true,
            noCollisions: true, // little jank, we add & remove the colliders ourselves

            statList: afterShockStatlist,
            weaponType: this.weaponType
        })

        if(!HolyLight.pool) {
          HolyLight.pool = new ObjectPoolTyped<HolyLight, HolyLightParams>(() => new HolyLight(), {}, 4, 1)
        }

        //@ts-expect-error
        this.grandPikeAfterShockBeam.debugMe = true
    }

    override update(delta) {
        super.update(delta)

        if (this.grandPikeAfterShockBeam.isColliderInScene) {
            if (this.grandPikeAfterShockBeam.firedLastFrame) {
                this.grandPikeAfterShockBeam.removeColliderFromScene()
            } else {
                this.grandPikeAfterShockBeam.position.copy(this.player.position)
                this.grandPikeAfterShockBeam.position.add(this.beamPosOffset)
                this.grandPikeAfterShockBeam.position.y += this.getYOffset()
            }
        }
    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
        for (const stat of Object.keys(WEAPON_STATS.spear.stats)) {
            statList._actualStatValues[stat] = WEAPON_STATS.spear.stats[stat]
        }
    }

    changeColliderToCone() {
        if (!this.addedConeColliders) {
            this.addedConeColliders = true

            const minAngleColliderConfig = {} as any as BoxColliderConfig
            const maxAngleColliderConfig = {} as any as BoxColliderConfig
            Object.assign(minAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
            Object.assign(maxAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)

            const angle = degToRad(15)
            minAngleColliderConfig.angle = angle
            maxAngleColliderConfig.angle = -angle

            this.coneSpreadAngle = []
            this.coneSpreadAngle[0] = angle
            this.coneSpreadAngle[1] = 0
            this.coneSpreadAngle[2] = -angle

            this.hitboxBeam.addColliders([minAngleColliderConfig, maxAngleColliderConfig])
        }
    }

    changeColliderLengthToNormal() {
        this.lengthScale = 1
        this.startingLength = SPEAR_START_LENGTH

        this.forceUpdateAttackSize()
    }

    changeColliderLengthToGrandPike() {
        this.lengthScale = (1 + GRAND_PIKE_LENGTH_BONUS)
        this.startingLength = SPEAR_START_LENGTH * this.lengthScale

        this.forceUpdateAttackSize()
    }

    changeColliderLengthToGungir() {
        this.lengthScale = (1 + GRAND_PIKE_LENGTH_BONUS + GUNGIR_LENGTH_BONUS)
        this.startingLength = SPEAR_START_LENGTH * this.lengthScale

        this.forceUpdateAttackSize()
    }

    setKnockAside(useKnockAside?: boolean) {
        if (useKnockAside) {
            this.hitboxBeam.overrideGetKnockbackFunction = this.boundKnockbackFunction
        } else {
            this.hitboxBeam.overrideGetKnockbackFunction = undefined
        }
    }

    setGrandPikeBloodProjectileDamageScale(damageScale: number) {
        this.grandPikeBloodProjectileParams.damageScale = damageScale
    }

    setComboMode(comboMode: SpearComboMode) {
        this._comboMode = comboMode
        this.comboData = SPEAR_COMBO_DATA[comboMode]
    }

    override onAttackStart() {
        this.aimVectorOnAttackStart.copy(this.player.aimVector)

        this.comboIndex = 0

        if (debugConfig.spearHopTime === SpearHopTime.Startup) {
            this.tryHop()
        }

        Audio.getInstance().playSfx('SFX_Spear')

        this.attackSpriteSheetIndex = (this.attackSpriteSheetIndex + (this.addedConeColliders ? 3 : 1)) % this.spearAttackSpriteSheets.length
        const index = this.attackSpriteSheetIndex
        callbacks_addCallback(this, () => {
            this.onStartAttackPfx(index, this.addedConeColliders)
        }, this.attackPfxStartTime * this.lastAttackRateMod)

        this.attackSpriteSheetIndex = 0
    }

    override onAttackStartupFinished(): boolean {
        this.nextHitDamageScale = this.comboData[this.comboIndex].damageScale

        if (this.comboData[this.comboIndex + 1]) {
            return false
        }

        if (debugConfig.spearHopTime === SpearHopTime.Active) {
            this.tryHop()
        }

        if (this.gungirElectricProjectiles) {
            if (this.gungirElectricAttackCount % GUNGIR_ELECTRIC_ATTACK_COUNT === 0) {
                this.fireGungirElectricProjectiles()
            }

            this.gungirElectricAttackCount++
        }

        if (this.player.binaryFlags.has('spear-holy-spear')) {
            this.dealHolySpearAoe(false)

            if (this.player.binaryFlags.has('spear-divine-punishment')) {
                this.dealHolySpearAoe(true)
            }
        }

        if (this.useGrandPikeAfterShock) {
            callbacks_addCallback(this, this.boundAfterShockDelayDoneFunction, this.afterShockStartupTime)
            callbacks_addCallback(this, this.boundAfterShockPfxStartFunction, this.afterShockPfxStartTime)
        }

        return true
    }

    override onActiveFrameFinished(): boolean {
        if (this.comboData[this.comboIndex + 1]) {
            const nextAttackTime = this.comboData[this.comboIndex].delayToNextStrike

            this.comboIndex++

            callbacks_addCallback(this, this.boundAttackStartupDoneFunc, nextAttackTime * this.lastAttackRateMod)
            this.player.setMovementLockTime(nextAttackTime * 1_000 * this.lastAttackRateMod + MELEE_SAFETY_ACTIVE_FRAME_TIME)
            this.player.setAimLockTime(nextAttackTime * 1_000 * this.lastAttackRateMod + MELEE_SAFETY_ACTIVE_FRAME_TIME)
            this.player.playAnimation(this.attackAnimName, this.lastAttackRateMod * this.attackAnimStartingTimeScale, true)

            this.attackSpriteSheetIndex = (this.attackSpriteSheetIndex + (this.addedConeColliders ? 3 : 1)) % this.spearAttackSpriteSheets.length
            const index = this.attackSpriteSheetIndex

            callbacks_addCallback(this, () => {
                this.onStartAttackPfx(index, this.addedConeColliders)
            }, this.attackPfxStartTime * this.lastAttackRateMod)

            return false

        }

        return true
    }

    private onAfterShockDelayFinished() {
        const attackSize = this.statList.getStat(StatType.attackSize)
        if (attackSize !== this.lastAfterShockSize) {
            this.lastAfterShockSize = attackSize
            this.grandPikeAfterShockBeam.setColliderProperties(this.startingLength * attackSize, GRAND_PIKE_AFTERSHOCK_WIDTH * attackSize, this.player.aimAngle)
        } else {
            this.grandPikeAfterShockBeam.setAngle(this.player.aimAngle)
        }

        this.grandPikeAfterShockBeam.position.copy(this.player.position)
        this.grandPikeAfterShockBeam.position.add(this.beamPosOffset)
        this.grandPikeAfterShockBeam.position.y += this.getYOffset()

        this.grandPikeAfterShockBeam.addColliderToScene()
    }

    override getDamageScale(): number {
        return this.nextHitDamageScale
    }

    getAfterShockDamageScale(): number {
        return 0
    }

    override onAttackRecoveryFinished() {
        if (debugConfig.spearHopTime === SpearHopTime.Recovery) {
            this.tryHop()
        }
    }

    private tryHop() {
        if (this.hopOnAttack && !this.isHopping) {
            let direction: Vector
            switch (debugConfig.spearHopMode) {
                case SpearHopMode.Movement:
                    direction = this.player.movementInput
                    break
                case SpearHopMode.Aim:
                    direction = this.aimVectorOnAttackStart
                    break
            }

            if (direction.x === 0 && direction.y === 0) {
                return
            }

            this.player.setMovementLockTime(debugConfig.spearHopDuration * 1_000)

            this.hopForce.force.copy(direction)
            this.hopForce.force.normalize()
            this.lastHopDirection.copy(this.hopForce.force)
            this.hopForce.force.scale(debugConfig.spearHopForce, debugConfig.spearHopForce)

            this.player.externalForces.push(this.hopForce)

            this.player.playAnimation(AnimationTrack.SPEAR_DASH_IDLE)
            this.isHopping = true

            if (this.aoeOnHop && !this.isSecondHop) {
                this.dealAoeDamage()
            }

            const index = this.dashSpriteSheetIndex
            callbacks_addCallback(this, () => {
                this.onStartHopPfx(index)
            }, this.dashPfxStartTime)
            this.dashSpriteSheetIndex = (this.dashSpriteSheetIndex + 1) % this.spearDashSpriteSheets.length
        }
    }

    private onHopFinished() {
        this.isHopping = false
        this.hopForce.reset()
        this.player.playAnimation(AnimationTrack.IDLE)

        if (this.aoeOnHop && this.hopTwice) {
            this.dealAoeDamage()
        }

        if (this.hopTwice && !this.isSecondHop) {
            this.isSecondHop = true
            this.tryHop()
        } else {
            this.isSecondHop = false
        }
    }

    private dealAoeDamage() {
        const explosionScale = (this.statList.getStat(StatType.attackSize) / this.startingAttackSize)
        const aoeSize = HOP_AOE_DAMAGE_SIZE * explosionScale
        dealAOEDamageDamageSource(CollisionLayerBits.HitEnemyOnly, aoeSize, this.player.position, this.hitboxBeam, 1, true)

        const pfx = Renderer.getInstance().addOneOffEffectByConfig(this.explosionEffectConfig,
            this.player.x, this.player.y, -999_999, (HOP_AOE_DAMAGE_SIZE / DEFAULT_AOE_EXPLOSION_PFX_SIZE) * explosionScale, DEFAULT_AOE_EXPLOSION_DURATION, true)
        setExplosionColor(pfx, 'fire')

        // Renderer.getInstance().drawCircle({
        //     x: this.player.x,
        //     y: this.player.y,
        //     radius: aoeSize,
        //     permanent: false,
        //     destroyAfterSeconds: 0.8,
        //     color: 0xFF0000,
        //     scale: 1
        // })
    }

    private onStartAttackPfx(index: number, coneMode: boolean) {
        const attackSize = this.statList.getStat(StatType.attackSize)

        this.reusePfxPosition.x = PFX_OFFSET_X //* attackSize
        this.reusePfxPosition.y = 0
        this.reusePfxPosition.rotate(this.player.aimAngle)

        this.reusePfxPosition.add(this.player.position)
        this.reusePfxPosition.y += this.getYOffset()

        if (coneMode) {
            for (let i = 0; i < 3; ++i) {
                const attackSprite = this.spearAttackSpriteSheets[(index + i) % this.spearAttackSpriteSheets.length]
                attackSprite.position.x = this.reusePfxPosition.x
                attackSprite.position.y = this.reusePfxPosition.y
                attackSprite.rotation = this.player.aimAngle + this.coneSpreadAngle[i] // different from activateSpriteSheet here
                attackSprite.scale.x = attackSize * this.lengthScale
                attackSprite.scale.y = attackSize

                attackSprite.restartCurrentAnim()

                attackSprite.addToScene()
            }
        } else {
            const attackSprite = this.spearAttackSpriteSheets[index]
            this.activateSpriteSheet(attackSprite, attackSize)
        }


        callbacks_addCallback(this, () => {
            this.onStopAttackPfx(index, coneMode)
        }, this.attackPfxDuration) //* this.lastAttackRateMod)
    }

    private activateSpriteSheet(spriteAnimator: InstancedSpriteSheetAnimator, attackSize: number, yScaleBonus: number = 1) {
        spriteAnimator.position.x = this.reusePfxPosition.x
        spriteAnimator.position.y = this.reusePfxPosition.y
        spriteAnimator.rotation = this.player.aimAngle
        spriteAnimator.scale.x = attackSize * this.lengthScale
        spriteAnimator.scale.y = attackSize * yScaleBonus

        spriteAnimator.restartCurrentAnim()

        spriteAnimator.addToScene()
    }

    private onStopAttackPfx(index: number, coneMode: boolean) {
        if (coneMode) {
            for (let i = 0; i < 3; ++i) {
                const attackSprite = this.spearAttackSpriteSheets[(index + i) % this.spearAttackSpriteSheets.length]
                attackSprite.removeFromScene()
            }
        } else {
            const attackSprite = this.spearAttackSpriteSheets[index]
            attackSprite.removeFromScene()
        }
    }

    private onStartAfterShockPfx() {
        this.activateSpriteSheet(this.afterShockSpriteSheet, this.statList.getStat(StatType.attackSize), GRAND_PIKE_AFTERSHOCK_WIDTH_DIFF)
        callbacks_addCallback(this, this.boundAfterShockPfxDoneFunction, this.afterShockPfxDuration)
    }

    private onStopAfterShockPfx() {
        this.afterShockSpriteSheet.removeFromScene()
    }

    private onStartHopPfx(index: number) {
        this.reusePfxPosition.copy(this.lastHopDirection).scale(DASH_PFX_OFFSET, DASH_PFX_OFFSET)
        this.reusePfxPosition.add(this.player.position)

        const angle = angleInRadsFromVector(this.lastHopDirection)

        const sprite = this.spearDashSpriteSheets[index]
        sprite.position.x = this.reusePfxPosition.x
        sprite.position.y = this.reusePfxPosition.y + DASH_PFX_Y_OFFSET
        sprite.rotation = angle

        sprite.restartCurrentAnim()

        sprite.addToScene()

        callbacks_addCallback(this, () => {
            this.onStopHopPfx(index)
        }, this.dashPfxDuration)
    }

    private onStopHopPfx(index: number) {
        const sprite = this.spearDashSpriteSheets[index]
        sprite.removeFromScene()
    }

    dealHolySpearAoe(isDivinePunishment: boolean) {
        const attackSize = this.statList.getStat(StatType.attackSize)
        const explosionScale = (this.statList.getStat(StatType.attackSize) / this.startingAttackSize)
        const position = new Vector(this.startingLength * attackSize, 0)//this.startingWidth * attackSize * 0.5)
        position.rotate(this.player.aimAngle)
        position.add(this.player.position)
        position.add(this.beamPosOffset)
        position.y += this.getYOffset()

        let defaultAoeSize = this.player.binaryFlags.has('spear-righteous-fervor') ? (this.startingWidth * 3) * 1.5 : (this.startingWidth * 3)
        let aoeRadius = defaultAoeSize * explosionScale

        if (isDivinePunishment) {
            defaultAoeSize = defaultAoeSize / DIVINE_PUNISHMENT_SCALE
            aoeRadius = aoeRadius / DIVINE_PUNISHMENT_SCALE
        }

        dealAOEDamageDamageSource(CollisionLayerBits.HitEnemyOnly, aoeRadius, position, this.hitboxBeam, 1, true)

        HolyLight.emitHolyLight(position, aoeRadius)
    }

    private onEntityHit(enemy: Enemy) {
        if (this.bloodProjectilesOnHit) {
            this.grandPikeBloodProjectileParams.speed = this.grandPikeBloodProjectileStatList.getStat(StatType.projectileSpeed)
            this.grandPikeBloodProjectileParams.radius = this.grandPikeBloodProjectileStatList.getStat(StatType.attackSize)
            this.grandPikeBloodProjectileParams.position = enemy.position

            this.grandPikeBloodProjectileParams.aimAngleInRads = angleBetweenVectors(this.player.position, enemy.position)

            PlayerProjectile.objectPool.alloc(this.grandPikeBloodProjectileParams)
        }
    }

    private fireGungirElectricProjectiles() {
        this.gungirElectricProjectileParams.speed = this.gungirElectricProjectileStatList.getStat(StatType.projectileSpeed)
        this.gungirElectricProjectileParams.radius = this.gungirElectricProjectileStatList.getStat(StatType.attackSize)
        this.gungirElectricProjectileParams.position.copy(this.player.position)
        this.gungirElectricProjectileParams.position.y += this.getYOffset()

        const projectileCount = this.gungirElectricProjectileStatList.getStat(StatType.projectileCount)

        const spreadAngle = this.gungirElectricProjectileStatList.getStat(StatType.projectileSpreadAngle)

        for (let i = 0; i < projectileCount; ++i) {
            const angleOffset = ProjectileSystem.projectileSpreadAngle(i, projectileCount, spreadAngle)
            this.gungirElectricProjectileParams.aimAngleInRads = this.player.aimAngle + angleOffset
            PlayerProjectile.objectPool.alloc(this.gungirElectricProjectileParams)
        }
    }

    private getPerpendicularKnockBack(mutableEntityPos: Vector, beam: Beam) {
        const isLeft = isPointLeftOfLine(beam.getStartPosition(), beam.getEndPosition(), mutableEntityPos)

        const awayVec = mutableEntityPos.sub(beam.position).normalize()
        // make a perpendicular vector to the left
        const xHolder = awayVec.x
        awayVec.x = -awayVec.y
        awayVec.y = xHolder

        if (isLeft) {
            return awayVec
        } else {
            // flip 180 deg
            awayVec.y *= -1
            awayVec.x *= -1
            return awayVec
        }
    }

    private resetGrandPikeBloodProjectileStats(stats: EntityStatList) {
        defaultStatAttribute(stats)
        const spearStats = WEAPON_STATS.spear.stats
        stats._actualStatValues.baseDamage = spearStats.baseDamage

        stats._actualStatValues.attackPierceCount = 0
        stats._actualStatValues.attackSize = 25
        stats._actualStatValues.projectileSpeed = 2_000
        stats._actualStatValues.projectileLifeSpan = 2
    }

    private resetGungirElectricProjectileStats(stats: EntityStatList) {
        defaultStatAttribute(stats)
        const spearStats = WEAPON_STATS.spear.stats
        stats._actualStatValues.baseDamage = spearStats.baseDamage


        stats._actualStatValues.projectileSpeed = 2_500
        stats._actualStatValues.projectileLifeSpan = 1
        stats._actualStatValues.projectileSpreadAngle = 20

        stats._actualStatValues.attackPierceCount = 0
        stats._actualStatValues.projectileChainCount = 3
        stats._actualStatValues.attackSize = 15
        stats._actualStatValues.projectileCount = 3
    }
}
