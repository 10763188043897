import { Audio } from "../../../engine/audio"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { EffectConfig } from "../../../engine/graphics/pfx/effectConfig"
import playAnimation from "../../../engine/graphics/play-animation"
import { Renderer } from "../../../engine/graphics/renderer"
import { SimpleRiggedSpineComponent } from "../../../engine/graphics/simple-rigged-spine-component"
import { Player } from "../../../entities/player"
import { PlayerProjectile, ProjectileInitialParams } from "../../../projectiles/projectile"
import { AnimationTrack } from "../../../spine-config/animation-track"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatOperator, StatType } from "../../../stats/stat-interfaces-enums"
import { ObjectPool, ObjectPoolTyped } from "../../../utils/third-party/object-pool"
import { AssetManager } from "../../../web/asset-manager"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { ResourceType } from '../../weapon-definitions'
import { AllWeaponTypes } from "../../weapon-types"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { dealAOEDamageDamageSource, DEFAULT_AOE_EXPLOSION_DURATION, DEFAULT_AOE_EXPLOSION_PFX_SIZE, setExplosionColor } from "../../../projectiles/explosions"
import { SpritesheetAnimatorComponent } from "../../../engine/graphics/spritesheet-animator-component"
import { GameClient } from "../../../engine/game-client"

const BASE_SPINE_PROJECTILE_SIZE = 50 // just for visuals
const FAT_RAT_PROJECTILE_SIZE = BASE_SPINE_PROJECTILE_SIZE * Math.PI

const RAT_EXPLOSION_RADIUS = 125
const RAT_EXPLOSION_DAMAGE_SCALAR = 2
const BASE_ATTACK_SIZE = 35
const RAT_PARADE_BIGGER_EXPLOSION_MULT = 0.3

enum Direction {
	Left,
	Right,
	Up,
	Down
}

const RAT_ANIMATIONS = {
	[Direction.Left]: 'rat-parade-basic-left',
	[Direction.Right]: 'rat-parade-basic-left',
	[Direction.Up]: 'rat-parade-basic-up',
	[Direction.Down]: 'rat-parade-basic-down',
}

const FAT_RAT_ANIMATIONS = {
	[Direction.Left]: 'rat-parade-fat-left',
	[Direction.Right]: 'rat-parade-fat-left',
	[Direction.Up]: 'rat-parade-fat-up',
	[Direction.Down]: 'rat-parade-fat-down',
}

const BOMB_RAT_ANIMATIONS = {
	[Direction.Left]: 'rat-parade-explosive-left',
	[Direction.Right]: 'rat-parade-explosive-left',
	[Direction.Up]: 'rat-parade-explosive-up',
	[Direction.Down]: 'rat-parade-explosive-down',
}

const KNIFE_RAT_ANIMATIONS = {
	[Direction.Left]: 'rat-parade-pierce-left',
	[Direction.Right]: 'rat-parade-pierce-left',
	[Direction.Up]: 'rat-parade-pierce-up',
	[Direction.Down]: 'rat-parade-pierce-down',
}

export class RatParadeWeapon extends AutoFireSecondaryWeapon {
	weaponType: AllWeaponTypes = AllWeaponTypes.RatParade

	isFiring: boolean = false
	firedRats: number = 0
	nextAttackTime: number = 0

	projectileCreationParams: ProjectileInitialParams

	static ratGraphicsPool: ObjectPoolTyped<SpritesheetAnimatorComponent, any>
	explosionEffectConfig: EffectConfig

	constructor(player: Player, statList: EntityStatList) {
		super(player, statList)

		if (!RatParadeWeapon.ratGraphicsPool) {
			const spriteSheet = AssetManager.getInstance().getAssetByName('rat-parade-rats').spritesheet
			RatParadeWeapon.ratGraphicsPool = new ObjectPool(() => {
				return new SpritesheetAnimatorComponent(null, spriteSheet, 'rat-parade-basic-left', 0.1, false, true)
			}, {}, 25, 5)
		}
		

		this.projectileCreationParams = {
			owningEntityId: player.nid,
			position: player.position,
			speed: this.statList.getStat(StatType.projectileSpeed),
			aimAngleInRads: 0, // gets changed when fired
			radius: this.statList.getStat(StatType.attackSize),
			trajectoryMods: [],
			collisionLayer: CollisionLayerBits.PlayerProjectile,
			statList: this.statList,
			resourceType: ResourceType.NONE,
			player: this.player,
			onCleanup: this.onProjectileCleanup.bind(this),
			graphicsComponentPool: RatParadeWeapon.ratGraphicsPool,
			weaponType: this.weaponType,
			isSplit: true // hacky to not split rats
		}
		
		this.statList.addStatBonus(StatType.projectileChainCount, StatOperator.MULTIPLY, -1) // NO CHAINING ON RATS!!
	}

	fire() {
		this.isFiring = true
		this.nextAttackTime = 0
		this.firedRats = 0

		this.projectileCreationParams.speed = this.statList.getStat(StatType.projectileSpeed)
		this.projectileCreationParams.radius = this.statList.getStat(StatType.attackSize)
	}

	forceStopFiring() {
		this.isFiring = false
	}

	override update(delta: number): void {
		super.update(delta)

		if (this.isFiring) {
			this.nextAttackTime -= delta
			if (this.nextAttackTime <= 0) {
				this.nextAttackTime += this.getAttackTickTime()

				const radius = this.projectileCreationParams.radius
				const baseSize = this.player.binaryFlags.has('rat-parade-fat-rat') ? FAT_RAT_PROJECTILE_SIZE : BASE_SPINE_PROJECTILE_SIZE
				const scale = radius / baseSize

				let totalRats
				if (this.player.binaryFlags.has('rat-parade-shoot-behind')) {
					this.fireRatProjectile(Direction.Left, scale)
					this.fireRatProjectile(Direction.Right, scale)

					totalRats = this.statList.getStat(StatType.projectileCount) * 2

					if (this.player.binaryFlags.has('rat-parade-shoot-all-directions')) {
						this.fireRatProjectile(Direction.Up, scale)
						this.fireRatProjectile(Direction.Down, scale)
						totalRats *= 2
					}
				} else {
					this.fireRatProjectile(this.player.facingDirection === 1 ? Direction.Right : Direction.Left, scale)
					totalRats = this.statList.getStat(StatType.projectileCount)
				}

				if (this.firedRats >= totalRats) {
					this.isFiring = false
				}

				const bigRats = this.statList.getStat(StatType.attackSize) >= 200
				if (bigRats) {
					Audio.getInstance().playSfx('SFX_Enemy_Mushie_Shoot')
				} else {
					Audio.getInstance().playSfx('SFX_Enemy_Mosquito_Shoot')
				}
			}
		}
	}


	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		statList._actualStatValues.baseDamage = 30
		statList._actualStatValues.attackSize = BASE_ATTACK_SIZE
		statList._actualStatValues.projectileCount = 6
		statList._actualStatValues.attackPierceCount = 1
		statList._actualStatValues.attackRate = 2.5 // 2 rats per second
		statList._actualStatValues.projectileSpeed = 650
		statList._actualStatValues.projectileLifeSpan = 5

		statList._actualStatValues.allDamageMult = 1
		statList._actualStatValues.projectileChainDistanceMultiplier = 1
		statList._actualStatValues.projectileChainCount = 0
		statList._actualStatValues.projectileSplitCount = 0

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 8_000
		statList._actualStatValues.reloadInterval = 50
	}

	fireRatProjectile(direction: Direction, scale: number): PlayerProjectile {
		const gfxComponent = RatParadeWeapon.ratGraphicsPool.alloc()
		this.projectileCreationParams.graphicsComponent = gfxComponent

		gfxComponent.spriteSheetAnimator.scale.x = scale
		gfxComponent.spriteSheetAnimator.scale.y = scale

		if (direction === Direction.Right) {
			gfxComponent.spriteSheetAnimator.scale.x *= -1
			this.projectileCreationParams.aimAngleInRads = 0
		} else {
			switch (direction) {
				case Direction.Left:
					this.projectileCreationParams.aimAngleInRads = Math.PI
					break
				case Direction.Down:
					this.projectileCreationParams.aimAngleInRads = Math.PI / 2
					break
				case Direction.Up:
					this.projectileCreationParams.aimAngleInRads = Math.PI + Math.PI / 2
					break
			}
		}

		let animations
		if (this.statList.getStat(StatType.attackPierceCount) > 100) {
			animations = KNIFE_RAT_ANIMATIONS
		} else if (this.player.binaryFlags.has('rat-parade-bombs')) {
			animations = BOMB_RAT_ANIMATIONS
		} else if (this.player.binaryFlags.has('rat-parade-fat-rat')) {
			animations = FAT_RAT_ANIMATIONS
		} else {
			animations = RAT_ANIMATIONS
		}

		gfxComponent.playAnimation(animations[direction])

		this.firedRats++
		return PlayerProjectile.objectPool.alloc(this.projectileCreationParams)
	}

	onProjectileCleanup(projectile: PlayerProjectile) {
		if (GameClient.isShutDown) {
			return // not sure if necessary, but doesn't hurt
		}

		if (this.player.binaryFlags.has('rat-parade-bombs')) {
			if (!this.explosionEffectConfig) {
				this.explosionEffectConfig = AssetManager.getInstance().getAssetByName('aoe-explosion-white').data
			}

			let explosionScale = this.statList.getStat(StatType.attackSize) / BASE_ATTACK_SIZE

			if (this.player.binaryFlags.has('rat-parade-bigger-explosions')) {
				explosionScale += RAT_PARADE_BIGGER_EXPLOSION_MULT
			}

			const pfx = Renderer.getInstance().addOneOffEffectByConfig(this.explosionEffectConfig,
				projectile.x, projectile.y, projectile.y + 200, (RAT_EXPLOSION_RADIUS / DEFAULT_AOE_EXPLOSION_PFX_SIZE) * explosionScale, DEFAULT_AOE_EXPLOSION_DURATION, true, true)

			setExplosionColor(pfx, 'fire')
			dealAOEDamageDamageSource(CollisionLayerBits.HitEnemyOnly, RAT_EXPLOSION_RADIUS * explosionScale, projectile.position, projectile, RAT_EXPLOSION_DAMAGE_SCALAR)
		}
	}

	getAttackTickTime(): number {
		return (1 / this.statList.getStat(StatType.attackRate))
	}
}