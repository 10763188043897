import { timeInMilliseconds, timeInSeconds } from './primitive-types'
import { InGameTime } from './time'

type callbackSystem_cb = () => void

export interface DelayedCallback {
	owner: any // this is just used for context... so we can cancel any applied callbacks on this owner
	callTime: timeInMilliseconds
	callbackFn: callbackSystem_cb
}

export function callbacks_addCallback(owner: any, callbackFn: callbackSystem_cb, delay: timeInSeconds) {
	const now: timeInMilliseconds = InGameTime.highResolutionTimestamp()
	
	const newDelayedCallback: DelayedCallback = {
		owner,
		callTime: now + (delay * 1_000),
		callbackFn,
	}

	callbacks.push(newDelayedCallback)

	return newDelayedCallback
}

export function callbacks_removeCallbacksFromOwner(owner: any) {
	callbacks = callbacks.filter((ao) => ao.owner !== owner)
}

export function callbacks_removeCallback(owner: any, callback: DelayedCallback) {
	callbacks.remove(callback)
}

export function callbacks_update(delta: timeInSeconds, now: timeInMilliseconds) {
	callbacks.forEach((cb) => {
		if (cb.callTime <= now) {
			//logger.debug(`callbackSystem_update calling, now:${nowInSeconds}`)
			cb.callbackFn()
		} 
	})

	const oldLen = callbacks.length
	callbacks = callbacks.filter((cb) => cb.callTime > now)
}

export function callbacks_shutdown() {
	callbacks = []
}

export function callbacks_numCallbacks() {
	return callbacks.length
}

let callbacks: DelayedCallback[] = []
