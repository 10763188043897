import { Vector } from "sat"
import { Beam, makeBeamPool } from "../../../beams/beams"
import { Player } from "../../../entities/player"
import { AnimationTrack } from "../../../spine-config/animation-track"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { callbacks_addCallback } from "../../../utils/callback-system"
import { timeInMilliseconds, timeInSeconds } from "../../../utils/primitive-types"
import { PrimaryWeapon } from "../../primary-weapon"
import { WEAPON_STATS } from "../../weapon-definitions"

const BEAM_ROTATED_OFFSET = new Vector(50, 0)
const MELEE_BEAM_Y_OFFSET = -75

export enum MeleeMoveMode {
    StopMovement = 0,
    SlowMovement = 1,
    ReallySlow = 2,
    NoPenalty = 3,
}

// we know how long the startup and recovery of the attack will take, but because collisions aren't checked every frame, we have to 
// guess how long the active frames will be. (could get the exact time, but it doesn't really matter)
export const MELEE_SAFETY_ACTIVE_FRAME_TIME: timeInMilliseconds = 250

export abstract class MeleePrimaryWeapon extends PrimaryWeapon {
    
    hitboxBeam: Beam

    abstract startingLength: number
    abstract startingWidth: number

    abstract attackStartupTime: timeInSeconds
    //@TODO: active frames? currently always 1 active frame (or collision tick)
    abstract attackRecoveryTime: timeInSeconds

    abstract attackAnimName: AnimationTrack
    abstract attackAnimStartingTimeScale: number

    private oldAttackSize: number
    private originalAttackRate: number
    private totalAttackTimeMs: timeInMilliseconds

    protected boundAttackStartupDoneFunc: () => void
    private boundAttackRecoveryDoneFunc: () => void

    private isAttacking: boolean
    private isRecovering: boolean

    protected lastAttackRateMod: number

    protected beamPosOffset: Vector

    init(player: Player, playerStatList: EntityStatList) {
		const weaponStatList = this.statList
        
        makeBeamPool()
        this.hitboxBeam = Beam.pool.alloc({
            x: player.x,
            y: player.y,
            angle: player.aimAngle,
            width: 0, // we set these later below
            length: 0,
            maxLength: 0,
            
            getDamageScaleFunction: this.getDamageScale.bind(this),

            noGfx: true,
            noCollisions: true, // little jank, we add & remove the colliders ourselves

            statList: weaponStatList,
            weaponType: this.weaponType,
            isPrimaryWeaponBeam: true
        })

        this.originalAttackRate = WEAPON_STATS.spear.stats.attackRate
        this.totalAttackTimeMs = (this.attackStartupTime + this.attackRecoveryTime) * 1_000

        this.boundAttackStartupDoneFunc = this._attackStartupDoneCallback.bind(this)
        this.boundAttackRecoveryDoneFunc = this._attackRecoveryDoneCallback.bind(this)

        this.isAttacking = false
        this.beamPosOffset = new Vector().copy(BEAM_ROTATED_OFFSET)
    }

    update(delta: number) {
        if (this.player.canShoot() && !this.isAttacking) {
            // hiya

            this.isAttacking = true

            const attackSize = this.statList.getStat(StatType.attackSize)
            if (attackSize !== this.oldAttackSize) {
                this.oldAttackSize = attackSize
                this.hitboxBeam.setColliderProperties(this.startingLength * attackSize, this.startingWidth * attackSize, this.player.aimAngle)
            } else {
                this.hitboxBeam.setAngle(this.player.aimAngle)
            }

            this.beamPosOffset.copy(BEAM_ROTATED_OFFSET)
            this.beamPosOffset.rotate(this.player.aimAngle)

            this.lastAttackRateMod = this.originalAttackRate / this.statList.getStat(StatType.attackRate)

            callbacks_addCallback(this, this.boundAttackStartupDoneFunc, this.attackStartupTime * this.lastAttackRateMod)
            this.player.setMovementLockTime(this.attackStartupTime * 1_000 * this.lastAttackRateMod + MELEE_SAFETY_ACTIVE_FRAME_TIME)
            this.player.setAimLockTime(this.attackStartupTime * 1_000 * this.lastAttackRateMod + MELEE_SAFETY_ACTIVE_FRAME_TIME)
            this.player.playAnimation(this.attackAnimName,  this.lastAttackRateMod * this.attackAnimStartingTimeScale)
            
            this.onAttackStart()
        } else if (this.hitboxBeam.isColliderInScene && this.hitboxBeam.firedLastFrame) {
            this.hitboxBeam.removeColliderFromScene()
            if (!this.isRecovering && this.onActiveFrameFinished()) {
                callbacks_addCallback(this, this.boundAttackRecoveryDoneFunc, this.attackRecoveryTime * this.lastAttackRateMod)
                
                this.player.setMovementLockTime(this.attackRecoveryTime * 1_000 * this.lastAttackRateMod)
                this.player.setAimLockTime(this.attackRecoveryTime * 1_000 * this.lastAttackRateMod)
    
                this.isRecovering = true
            }
        } else if (this.hitboxBeam.isColliderInScene) {
            this.hitboxBeam.position.copy(this.player.position)
            this.hitboxBeam.position.add(this.beamPosOffset)
            this.hitboxBeam.position.y += this.getYOffset()
        }
    }

    protected forceUpdateAttackSize() {
        this.oldAttackSize = this.statList.getStat(StatType.attackSize)
        this.hitboxBeam.setColliderProperties(this.startingLength * this.oldAttackSize, this.startingWidth * this.oldAttackSize, this.player.aimAngle)
    }

    abstract onAttackStart()
    abstract onAttackStartupFinished(): boolean
    abstract onActiveFrameFinished(): boolean
    abstract onAttackRecoveryFinished()
    
    getDamageScale() {
        return 1
    }

    getYOffset() {
        return MELEE_BEAM_Y_OFFSET * this.player.scale
    }

    private _attackStartupDoneCallback() {
        this.hitboxBeam.position.copy(this.player.position)
        this.hitboxBeam.position.add(this.beamPosOffset)
        this.hitboxBeam.position.y += this.getYOffset()

        this.hitboxBeam.addColliderToScene()

        if (this.onAttackStartupFinished()) {
            callbacks_addCallback(this, this.boundAttackRecoveryDoneFunc, this.attackRecoveryTime * this.lastAttackRateMod)

            this.player.setMovementLockTime(this.attackRecoveryTime * 1_000 * this.lastAttackRateMod)
            this.player.setAimLockTime(this.attackRecoveryTime * 1_000 * this.lastAttackRateMod)

            this.isRecovering = true
        }
    }

    private _attackRecoveryDoneCallback() {
        this.player.setAttackCooldown()
        this.isAttacking = false
        this.isRecovering = false
    }
}
