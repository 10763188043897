const ONE_OVER_255 = 1 / 255

export class Colors {
	static white: number = 0xffffff
	static black: number = 0x000000
	static silver: number = 0xb9b9b9
	static grey: number = 0x7f7f7f

	// primary colors
	static red: number = 0xff0000
	static green: number = 0x00ff00
	static blue: number = 0x0000ff

	static magenta: number = 0xff00ff
	static cyan: number = 0x00ffff
	static yellow: number = 0xffff00

	static orange: number = 0xff7f00
	static purple: number = 0x800080
	static pink: number = 0xfaafbe

	static darkRed: number = 0x7f0000
	static darkGreen: number = 0x007f00
	static darkBlue: number = 0x00007f

	static brown: number = 0x774b10
}

export class ColorUtil {
	/* tslint:disable:no-bitwise */
	static getR = (hexColor: number): number => {
		return ((hexColor & 0xff0000) >> 16) * ONE_OVER_255
	}
	static getG = (hexColor: number): number => {
		return ((hexColor & 0x00ff00) >> 8) * ONE_OVER_255
	}
	static getB = (hexColor: number): number => {
		return ((hexColor & 0x0000ff) >> 0) * ONE_OVER_255
	}

	static setR(hexColor: number, value: number): number {
		const hexValue = value * 0xff
		hexColor = hexColor | (hexValue << 16)
		return hexColor
	}
	static setG(hexColor: number, value: number): number {
		const hexValue = value * 0xff
		hexColor = hexColor | (hexValue << 8)
		return hexColor
	}
	static setB(hexColor: number, value: number): number {
		const hexValue = value * 0xff
		hexColor = hexColor | (hexValue << 0)
		return hexColor
	}

	static lerp(c1: number, c2: number, t: number) {
		let c = 0
		c = this.setR(c, Math.lerp(this.getR(c1), this.getR(c2), t))
		c = this.setG(c, Math.lerp(this.getG(c1), this.getG(c2), t))
		c = this.setB(c, Math.lerp(this.getB(c1), this.getB(c2), t))
		return c
	}

	/* tslint:enable:no-bitwise */

	static toRGB = (color: number) => {
		return {
			r: ColorUtil.getR(color),
			g: ColorUtil.getG(color),
			b: ColorUtil.getB(color),
			a: 1,
		}
	}

	static toHex(r: any, g: any, b: number): number {
		let color = 0
		color = this.setR(color, r)
		color = this.setG(color, g)
		color = this.setB(color, b)
		return color
	}

	// maps [0-1] -> [red-purple]
	static toRainbow(t: number): number {
		const rainbow = [Colors.red, Colors.orange, Colors.yellow, Colors.green, Colors.blue, Colors.purple]
		const { i, t: t2 } = ColorUtil.arrayLerppage(t, rainbow.length)
		return ColorUtil.lerp(rainbow[i], rainbow[i + 1], t2)
	}

	// maps [0-1] -> [{0,0}, {i:array.length-1, t:1}]
	static arrayLerppage(t: number, arrayLen: number) {
		const spread = arrayLen - 1
		let i = Math.floor(t * spread)
		if (i >= spread) {
			i = spread - 1
		}
		t = t * spread - i
		return { i, t }
	}
}

export const pickRandomColor = () => {
	const r = Math.random()
	switch (Math.floor(Math.random() * 3)) {
		case 0:
			return Math.round(0xff * r) * 0x10000 + Math.round(0xff * (1 - r)) * 0x100
		case 1:
			return Math.round(0xff * r) * 0x10000 + Math.round(0xff * (1 - r))
		case 2:
			return Math.round(0xff * r) * 0x100 + Math.round(0xff * (1 - r))
	}
}

// moved here from projectile.client as we're no longer coloring projectiles this way
//  remove if unneeded
const randomColor = () => {
	const rand = Math.random()
	switch (Math.floor(Math.random() * 3)) {
		case 0:
			return { r: rand, g: 1 - rand, b: 0 }
		case 1:
			return { r: 0, g: rand, b: 1 - rand }
		case 2:
			return { r: 1 - rand, g: 0, b: rand }
	}
}
