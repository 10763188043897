<template>
	<div id="end-run">
		<div class="top-container">
			<div class="chat-button">
				<IconButton :icon-name="'chat'" :button-style="'green'" :on-click-prop="openActivityModal"></IconButton>
			</div>
			<div class="group">
				<div class="portrait-text">
					<SquareItem :item="selectedCharacter" @on-click-fn="() => {}" :selected="false" :disabled="false" :small-icons="false" :goldtrim-enabled="false"></SquareItem>
					<div class="result-text" id="win-lose-text">
						<div class="win-header" :class="getIsPlayerWinner ? 'green' : 'red'" :lang="this.$i18n.locale">
							<div>{{ getIsPlayerWinner ? 'Survived' : 'You Died' }}</div>
							<div class="time">{{ getIsPlayerWinner ? `[${getEndGameTimeInMinutesSeconds}]` : '' }}</div>
						</div>
					</div>
					{{ selectedWeapon.skinName }}
					<SquareItem v-if="selectedWeapon && selectedWeapon.weaponType !== weaponTypes.None" :item="selectedWeapon" @on-click-fn="() => {}" :selected="false" :disabled="false" :small-icons="false" :goldtrim-enabled="false"></SquareItem>
				</div>
			</div>
			<div class="currency-container">
				<div class="currency-list">
					<div class="currency-list-item">
						<div class="currency-icon-1"></div>
						<div class="currency" :lang="this.$i18n.locale">{{ commonCurrency }}</div>
					</div>
					<div class="currency-list-item">
						<div class="currency-icon-2"></div>
						<div class="currency" :lang="this.$i18n.locale">{{ rareCurrency }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-container">
			<div class="weapon-stats-container">
				<div class="wrapper">
					<Divider :label="'end_chapter.weapon_label'" :flip-horizontal="false" :text-alignment="'left'" :font-size="'large'">></Divider>

					<div class="weapon-stats-table">
						<div class="header">
							<Header v-if="currentWeaponsPage === 1" :header-text="`Weapon`" :text-alignment="'center'" :font-face="'pixl'" :font-size="'medium'"></Header>
							<Header v-else :header-text="`Stats`" :text-alignment="'center'" :font-face="'pixl'" :font-size="'medium'"></Header>
						</div>
						<div class="pagination-container">
							<Pagination :pip-count="2" :selected-pip="currentWeaponsPage" @on-click-back-fn="decreaseWeaponsPage" @on-click-forward-fn="increaseWeaponsPage"></Pagination>
						</div>

						<div class="table-content">
							<div v-if="currentWeaponsPage === 1" class="weapons-table">
								<IconTable :row-data="getAllWeaponDamageWithIcons" :col-data="weaponDamageCol"></IconTable>
							</div>
							<div v-else class="stats-table">
								<Table class="stat-table-border" :disable-header="false" :table-row-data="getAllEventData" :headers="statsCol"></Table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="true" class="chapter-summary-container">
				<RunSummary></RunSummary>
			</div>
			<div class="upgrades-and-twists">
				<div class="upgrades">
					<div class="wrapper">
						<Divider :label="'end_chapter.upgrades'" :flip-horizontal="true" :text-alignment="'right'" :font-size="'large'"></Divider>
						<div class="upgrades-grid">
							<ItemPagination :items="upgradesActive" :icon-style="'upgrade'"></ItemPagination>
						</div>
					</div>
				</div>
				<div class="twists">
					<div class="wrapper">
						<Divider :label="'end_chapter.current_twist'" :flip-horizontal="true" :text-alignment="'right'" :font-size="'large'"></Divider>
						<div class="twists-grid">
							<ItemPagination :items="this.getSelectedStory.formattedTwists" :icon-style="'twist'"></ItemPagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-container">
			<div class="group">
				<div class="buttons-left-align">
					<TextButton :button-label="$t('end_chapter.button_main_menu')" :button-style="'blue'" @on-click-fn="changeView" :optional-param="{ view: Views.STORY_SELECT }"></TextButton>
					<TextButton :button-label="$t('end_chapter.button_chapter_summary')" :button-style="'green'" @on-click-fn="changeView" :optional-param="{ view: Views.CHAPTER_SELECT }"></TextButton>
				</div>
				<div class="buttons-right-align">
					<TextButton id="end-chapter-perk-store" :button-label="$t('end_chapter.button_perk_store')" :button-style="'green'" @on-click-fn="openPerkStore" :disabled="false"></TextButton>
					<TextButton :button-label="$t('end_chapter.button_character_select')" :button-style="'bright-green'" @on-click-fn="changeView" :optional-param="{ view: Views.CHARACTER_SELECT }"></TextButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Background from '../ui-components/background/background.vue'
import RunSummary from '../ui-components/end-run/run-summary.vue'
import Table from '../ui-components/table/table.vue'
import Divider from '../ui-components/dividers-headers/divider.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import ArrowBackground from '../ui-components/arrow/arrow-background.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import SquareItem from '../ui-components/item/square-items.vue'
import parseISO from 'date-fns/parseISO'
import { formatDistance } from 'date-fns'
import { AllWeaponTypes } from '../../weapons/weapon-types'
import { NUMBER_OF_DEMO_RUNS_LOCAL_KEY, STORY_PAGE_PARAM_KEY } from '../store/end-chapter-store'
import { STORIES_PAGE_PARAM_KEY } from '../store/end-chapter-store'
import Pagination from '../ui-components/pagination/pagination.vue'
import IconTable from '../ui-components/table/icon-table.vue'
import IconButton from '../ui-components/buttons/icon-button.vue'
import { Views } from '../store/ui-store'
import ItemPagination from '../ui-components/container/item-pagination.vue'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/local-storage'

export default {
	name: 'EndChapter',
	components: {
		Background,
		RunSummary,
		Table,
		Divider,
		TextButton,
		Header,
		ArrowBackground,
		ItemUpdated,
		SquareItem,
		Pagination,
		IconTable,
		IconButton,
		ItemPagination,
	},
	data() {
		return {
			weaponDamageCol: [{ headerName: 'weapon_damage_table.weapon_header' }, { headerName: 'weapon_damage_table.dps_header' }, { headerName: 'weapon_damage_table.damage_dealt_header' }],
			statsCol: [{ headerName: 'event_table.event_category' }, { headerName: 'event_table.event_total' }],
			endDate: new Date('2022-11-25T03:10:45.507Z'),
			storyParam: STORY_PAGE_PARAM_KEY,
			storiesParam: STORIES_PAGE_PARAM_KEY,
			weaponTypes: AllWeaponTypes,

			showWeaponDamage: true,
			itemPerPage: 14,
			currentUpgradesPage: 1,
			currentTwistsPage: 1,
			currentWeaponsPage: 1,
			Views: Views,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('time', ['date']),
		...mapGetters('story', ['getSelectedStory', 'votingTimeLeft']),
		...mapGetters('endChapter', ['getAllWeaponDamage', 'getAllWeaponDamageWithIcons', 'getAllEventData', 'getEndGameTimeInMinutesSeconds', 'getIsPlayerWinner']),
		...mapGetters('paused', ['upgradesActive']),
		...mapGetters('characterSelect', ['selectedCharacter', 'selectedWeapon']),
		...mapState('paused', ['upgrades', 'selectedUpgrade', 'hoveredUpgrade']),
		...mapState('endChapter', ['twistList', 'indexedTwists', 'selectedTwist', 'endRunResults', 'endChapterData']),
		...mapState('story', ['selectedChapter', 'oldSelectedChapter']),
		...mapState('ui', ['showCallToAction', 'nextAction']),
		commonCurrency() {
			if (this.endRunResults) {
				return this.endRunResults.paperScraps
			}
			return 0
		},
		rareCurrency() {
			if (this.endRunResults) {
				return this.endRunResults.lostScrolls
			}
			return 0
		},
		voteEndTimes() {
			const endTime = parseISO(this.getSelectedStory.nextTally)
			return formatDistance(endTime, this.date)
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('paused', ['upgradeOver', 'toggleSelectedUpgradeItem']),
		...mapActions('story', ['fetchStoryById']),
		...mapActions('endChapter', ['fetchMutators', 'postMutatorVote']),
		...mapActions('ui', ['changeActiveView']),
		...mapMutations('ui', ['updateDarkenOverlay', 'updateShowCallToAction', 'updateNextAction']),
		...mapMutations('metaProgression', ['showOrHidePerks']),
		...mapMutations('activityFeed', ['updateActivityFeedModal']),

		changeView(params) {
			if (this.tryCallToAction()) {
				let buttonName
				switch(params.view){
					case this.Views.STORY_SELECT:
						buttonName = 'storySelect'
						break
					case this.Views.CHARACTER_SELECT:
						buttonName = 'characterSelect'
						break
					case this.Views.CHAPTER_SELECT:
						buttonName = 'chapterSelect'
						break
				}
				this.updateNextAction(buttonName)
				return
			}
			const { view } = params
			this.changeActiveView(view)
		},

		tryCallToAction() {
			try {
				if (process.env.IS_ELECTRON){
					const numRuns = loadFromLocalStorage(NUMBER_OF_DEMO_RUNS_LOCAL_KEY)
					let num = Number.parseInt(numRuns)
					num = isNaN(num) ? 0 : num
					// num should only ever be -1 on the very fist play through
					if (num >= 6 || num < 0) {
						this.updateShowCallToAction(true)
						num = 0
						saveToLocalStorage(NUMBER_OF_DEMO_RUNS_LOCAL_KEY, num.toString())
						return true
					}
				}

			} catch (err) {
				console.error('Error in tryCallToAction', err)
			}
			return false
		},

		openActivityModal() {
			if (this.tryCallToAction()) {
				this.updateNextAction('chat')
				return
			}
			this.updateActivityFeedModal(true)
			this.updateDarkenOverlay(true)
		},

		openPerkStore() {
			if (this.tryCallToAction()) {
				this.updateNextAction('perkStore')
				return
			}
			this.showOrHidePerks(true)
			this.updateDarkenOverlay(true)
		},

		pipCounter(itemTotal) {
			return Math.ceil(itemTotal.length / this.itemPerPage)
		},

		upgradeIsSelected(upgradeName) {
			if (this.selectedUpgrade) {
				return upgradeName === this.selectedUpgrade.icon
			}
		},
		upgradeIsHovered(upgradeName) {
			if (this.hoveredUpgrade && this.selectedUpgrade !== this.hoveredUpgrade) {
				return upgradeName === this.hoveredUpgrade.icon
			}
		},
		arrowButton(direction) {
			const el = document.getElementById('book-0')
			const scrollableContainer = document.getElementById('moveable')
			let width = el.offsetWidth
			let style = getComputedStyle(el)
			width += parseInt(style.marginLeft) + parseInt(style.marginRight)

			switch (direction) {
				case 'left':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft - width, behavior: 'smooth' })
					break
				case 'right':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft + width, behavior: 'smooth' })
					break
			}
		},

		increaseUpgradePage() {
			if (this.currentUpgradesPage < this.pipCounter(this.upgradesActive)) {
				this.currentUpgradesPage++
			} else {
				this.currentUpgradesPage = 1
			}
		},
		decreaseUpgradePage() {
			if (this.currentUpgradesPage > 1) {
				this.currentUpgradesPage--
			}
		},
		increaseTwistPage() {
			if (this.currentTwistsPage < this.pipCounter(this.getSelectedStory.formattedTwists)) {
				this.currentTwistsPage++
			} else {
				this.currentUpgradesPage = 1
			}
		},
		decreaseTwistPage() {
			if (this.currentTwistsPage > 1) {
				this.currentTwistsPage--
			}
		},
		increaseWeaponsPage() {
			if (this.currentWeaponsPage < 2) {
				this.currentWeaponsPage++
			}
		},
		decreaseWeaponsPage() {
			if (this.currentWeaponsPage > 1) {
				this.currentWeaponsPage--
			}
		},
	},
	mounted() {
		if (process.env.IS_ELECTRON){
			if (!this.showCallToAction) {
				switch(this.nextAction) {
							case 'chat': 
								this.openActivityModal()
								break
							case 'storySelect':
								this.changeView({view: this.Views.STORY_SELECT})
								break
							case 'chapterSelect':
								this.changeView({view: this.Views.CHAPTER_SELECT})
								break
							case 'perkStore':
								this.openPerkStore()
								break
							case 'characterSelect':
								this.changeView({view: this.Views.CHARACTER_SELECT})
								break
				}
				this.updateNextAction('')
			}
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#end-run {
	display: flex;
	justify-content: center;
	position: relative;
	flex-direction: column;
	align-items: center;
	&.ratio21By9 {
		.top-container,
		.bottom-container {
			.group {
				margin: 0 auto;
				width: 1920px;
			}
		}
	}

	.top-container {
		top: 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.group {
			   
			.portrait-text {
				 margin: auto;
				display: flex;
				position: relative;
				:deep(.square-item) {
					transform: scale(0.58);
					transform-origin: center;
				}

				.result-text {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					.win-header {
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-variant: small-caps;
							font-size: 60px;
						}
						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
						&.green {
							color: @green;
						}
						&.red {
							color: @failureRed;
						}
						.time {
							font-size: 30px;
						}
					}
				}
			}
		}

		.currency-container {
			position: absolute;
			right: 65px;
			top: 27px;
			.currency-list {
				display: flex;
				flex-direction: column;

				.currency-list-item {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					.currency-icon-1,
					.currency-icon-2 {
						width: 38px;
						height: 38px;
						background-size: contain;
						background-repeat: no-repeat;
					}
					.currency-icon-1 {
						background-image: url('~/assets/ui/currency/icon-paper-scraps.png');
					}
					.currency-icon-2 {
						background-image: url('~/assets/ui/currency/icon-lost-scroll.png');
					}
					.currency {
						color: @offWhite;
						margin-left: 5px;
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-variant: small-caps;
							font-size: 30px;
						}

						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}
				}
			}
		}
	}

	.main-container {
		width: 1920px;
		height: calc(100% - 300px);
		display: flex;

		.weapon-stats-container {
			width: 614px;
			height: 100%;
			align-items: center;
			display: flex;
			margin-left: 40px;
			.wrapper {
				width: 480px;
				height: 710px;
				.pagination-container {
					background-color: @darkBlue;
					padding: 10px 0px;
					padding-left: 10px;
					padding-right: 10px;
				}
				.weapon-stats-table {
					background-color: @darkBlue;
					height: 620px;
					width: 480px;
					border-radius: 7px;
					.table-content {
						.weapons-table {
						}
					}
				}
			}
		}
		.chapter-summary-container {
			width: 692px;
			height: 100%;
			align-items: center;
			display: flex;
		}
		.upgrades-and-twists {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.upgrades,
			.twists {
				width: 614px;
				height: 352px;
				position: relative;
				margin: 3px 0px;
				.wrapper {
					width: 480px;
					height: 373px;
					position: absolute;
					right: 0px;
					margin-right: 40px;

					.twists-grid,
					.upgrades-grid {
						border-radius: 7px;
						background-color: @darkBlue;
						widows: 100%;
						height: calc(140px + 62px); // 140px is the height of the items grid list 62 is the height of the pagination container + padding
					}
					.pagination-container {
						padding: 10px 10px;
					}
					.upgrade-items-list,
					.twist-items-list {
						height: 198px;
						overflow-y: auto;
						display: flex;
						place-content: flex-start; // this removed the extra height for each item when only 1 or 2 rows out of 3 are present
						flex-wrap: wrap;
						overflow: hidden;

						.item {
						}
					}
					.twist-items-list {
						margin-left: 11px;
					}
				}
			}
		}
	}

	.top-container,
	.bottom-container {
		background-color: @darkBlue;
		height: 150px;
		position: relative;
		width: 100%;
		.group {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			position: relative;
			padding: 0px 60px;
		}
		.chat-button {
			position: absolute;
			top: 18%;
			left: 3%;
			pointer-events: all;
			z-index: 2;
			.icon-button {
				width: 100px;
			}
		}
	}
	.bottom-container {
		padding: 0px 60px 0px 60px;
		position: relative;
		top: 0;

		.group {
			.buttons-left-align,
			.buttons-right-align {
				display: flex;
				flex-direction: row;
				.text-button {
					width: 300px;
					height: 100px;
					margin: 0px 10px;
				}
			}
		}
	}

	.stat-table-border {
		padding: 0 30px;
	}
	.list-move,
	.list-enter-active,
	.list-leave-active {
		transition: all 0.5s ease;
	}

	.list-enter-active {
		transition-delay: 0.5s;
	}

	.list-enter-from {
		opacity: 0;
		transform: translateX(30px);
	}
	.list-leave-to {
		opacity: 0;
		transform: translateX(-30px);
	}
}
</style>
