<template>
	<div id="empty-story">
		<div class="text" :lang="this.$i18n.locale">
			<span class="block green-text">{{ $t('story_select.no_active_stories') }}</span>
			<div class="slots-remaining block">
				<span class="white-text count">{{ maxStories - getPlayerActiveStories.length }}</span><span
					class="green-text">{{ $t('story_select.slots_remaining') }}</span>
			</div>

		</div>
		<div class="button-container">
			<TextButton id="create-story-btn" :button-label="$t('story_select.button_create_story')" :disabled="false"
				:button-style="'bright-green'" @on-click-fn="createStoryModal" :font-color="'darkBlue'"></TextButton>
			<TextButton :button-label="$t('story_select.button_find_story')" :disabled="isFtueMode"
				:button-style="'bright-green'" @on-click-fn="changeScreenClickFn(storyScreens.SEARCH_STORY)"
				:font-color="'darkBlue'"></TextButton>
			<TextButton id="find-story-btn" :button-label="$t('story_select.button_find_story_id')" :disabled="false"
				:button-style="'bright-green'" @on-click-fn="gameIdSearchModal" :font-color="'darkBlue'"></TextButton>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextButton from '../../ui-components/buttons/text-button.vue'
import { StoryScreens } from '../../store/story-store'
import { TOTAL_ACTIVE_STORIES } from '../../store/story-store'
import { TutorialFlagsEnum } from '../../../ftue/tutorial-flags'

export default {
	name: 'EmptyStory',
	components: {
		TextButton,
	},
	data() {
		return {
			storyScreens: StoryScreens,
			maxStories: TOTAL_ACTIVE_STORIES,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapGetters('story', ['getPlayerActiveStories']),
		...mapGetters('ftue', ['getFlag']),
		...mapState('user', ['externalUserAccountType']),
		isFtueMode() {
			return !this.getFlag(TutorialFlagsEnum.MadeFirstStory)
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('story', ['updateCreateStoryPrompt', 'updateGameIdSearchPrompt']),
		...mapMutations('ui', ['updateDarkenOverlay']),
		...mapActions('story', ['changeStoryScreen']),

		gameIdSearchModal() {
			this.updateGameIdSearchPrompt()
			this.updateDarkenOverlay(true)
		},
		createStoryModal() {
			this.updateCreateStoryPrompt()
			this.updateDarkenOverlay(true)
		},
		changeScreenClickFn(storyScreen) {
			this.changeStoryScreen(storyScreen)
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#empty-story {
	width: 1290px;
	height: 210px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: @darkBlue;
	margin: 12px 0px;
	border-radius: 7px;

	&:hover {
		// border-image-source: url('../../../../assets/ui/item/highlight-hover.png');
		// border-image-slice: 27 fill;
		// border-image-width: 30px;
	}

	.text {
		text-align: center;
		font-size: 40px;

		block {
			display: block;
			padding-bottom: 10px;
		}

		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}

		.green-text {
			color: @green;
		}

		.white-text {
			color: @offWhite;
		}

		.count {
			font-size: 50px;
		}
	}

	.button-container {
		width: 970px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.text-button {
			width: 366px;
			height: 65px;
		}
	}
}</style>
