<template>
	<div id="loading">
		<div class="forest-background">
			<div class="background"></div>
			<div class="flower"></div>
			<canvas id="particles-canvas" class="particle-fx"></canvas>
			<div class="god-ray"></div>
		</div>
		<div class="banner" :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': 'top center' }">
			<div class="banner-img forest">
				<img src="../../../assets/ui/divider/divider-graphic.png" alt="" />
			</div>
			<div v-if="!showStartupError" class="text forest" :lang="this.$i18n.locale">
				<div class="title">{{$t(`loading.title_${currentNumber}`)}}</div>
				{{$t(`loading.description_text_${currentNumber}`)}}
			</div>
			<div v-else class="text forest" :lang="this.$i18n.locale">
				{{$t(startUpError)}}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'Loading',
	components: {},
	data() {
		return {
			totalTooltips: 5,
			lastNumber: null,
			currentNumber: null,
			randomInterval: null
		}
	},
	props: {},
	watch: {},
	computed: {
		...mapState('ui', ['doneAssetLoading', 'showStartupError', 'startUpError']),
		...mapState('uiScaling', ['aspectRatio', 'responsiveScale']),

	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		randomTooltip(){
			let number =  Math.floor(Math.random() * this.totalTooltips) + 1;

			while (number === this.lastNumber) {
				const number =  Math.floor(Math.random() * this.totalTooltips) + 1;
			}

			this.currentNumber = number
		}
	},
	beforeMount() {
		this.randomTooltip()
		
		this.randomInterval = setInterval(() => {
			this.randomTooltip()
		}, 5000)
	},
	beforeUnmount(){
		clearInterval(this.randomInterval)
	}
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
#loading {
	width: 100vw;
	height: 100vh;
	position: relative;
	.forest-background {
		position: absolute;
		width: inherit;
		height: inherit;
		.background {
			background-image: url('../../../assets/ui/backgrounds/forest.png');
			background-size: cover;
			background-position: center;
			width: inherit;
			height: inherit;
			position: absolute;
		}
		.flower {
			background-image: url('../../../assets/ui/backgrounds/flower.png');
			width: 171px;
			height: 218px;
			background-size: 171px 218px;
			position: absolute;
			top: 693px;
			left: 916px;
		}
		#particles-canvas {
		}
		.god-ray {
			background-image: url('../../../assets/ui/backgrounds/godrays.png');
			width: 1134px;
			height: 991px;
			background-size: 1134px 991px;
			position: absolute;
			top: -20px;
			left: 210px;
			mix-blend-mode: color-dodge;
			animation: godrays 20s infinite;
			transform-origin: 880px 0px;
		}
		@keyframes godrays {
			0% {
				opacity: 1;
				transform: rotate(0deg);
			}
			15% {
				opacity: 0.35;
			}
			25% {
				opacity: 0.55;
			}
			35% {
				opacity: 0.75;
			}
			50% {
				opacity: 1;
				transform: rotate(-1.5deg);
			}
			65% {
				opacity: 0.75;
				filter: saturate(1);
			}
			75% {
				opacity: 0.65;
			}
			85% {
				filter: saturate(1.2);
				opacity: 0.95;
			}
			100% {
				opacity: 1;
				transform: rotate(0deg);
				filter: saturate(1);
			}
		}
	}
	.banner {
		position: absolute;
		width: 900px;
		min-height: 296px;
		border-radius: 25% 25% 10% 10%;
		background-color: rgba(5, 25, 45, 0.55);
		// offset-x / offset-y / blur-radius / spread-radius / color
		box-shadow: 0px 0px 60px 70px rgba(5, 25, 45, 0.55);
		text-align: center;
		padding: 20px 0px;
		top: 120px;
		left: 0;
		right: 0;
		margin: 0 auto;
		.banner-img {
			img {
				height: auto;
				width: 650px;
			}
		}
		.text {
			margin-top: 20px;
			.title {
				font-size: 50px;
			}
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 30px;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 24px;
			}
			&.forest {
				color: @green;
			}
		}
	}
	.fade-enter-active {
		transition: all 0.25s ease-out;
	}
	.fade-leave-active {
		transition: all 0.15s ease-out;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
}
</style>
