// These names (will at some point) match something in Griddle! Make sure to update them there too!
export enum PlayerIconTag {
	Bees = "bees",
	PoisonBottles = "posionBottles",
	ThreeRoundBurst = "threeRoundBurst",
	BrokenHeartWeepsOpen = "brokenHeartWeepsOpen",
	QuickReactionGoodBurn = "quickReactionGoodBurn",
	ShockinglyGoodTime = "shockinglyGoodTime",
	AbsoluteUnits = "absoluteUnits",
	AristotlesLackOfMotion = "aristotlesLackOfMotion",
	Banditry = "banditry",
	BetterLivingThroughPoison = "betterLivingThroughPoison",
	BigBrainFrailBody = "bigBrainFrailBody",
	BigBrainLearning = "bigBrainLearning",
	BloatedBlimpies = "bloatedBlimpies",
	BloodSoakedBullets = "boodSoakedBullets",
	ChompBiteForce = "chompBiteForce",
	Chonkers = "chonkers",
	DoThoseRatsHaveKnives = "doThoseRatsHaveKnives",
	DryHeat = "dryHeat",
	FastLearner = "fastLearner",
	FatRat = "fatRat",
	FireStorm = "fireStorm",
	FocalPrism = "focalPrism",
	FreneticActivity = "freneticActivity",
	GettingIntoRatTrouble = "gettingIntoRatTrouble",
	GreatTone = "greatTone",
	HaveHeart = "haveHeart",
	Heist = "heist",
	HighDensityEnergy = "highDensityEnergy",
	HighSpeedPlunderer = "highSpeedPlunderer",
	HolySpearFinalForm = "holySpearFinalForm",
	HungryHungryDoggo = "hungryHungryDoggo",
	Impatient = "impatient",
	InTheEyeOfMercy = "inTheEyeOfMercy",
	IntimidatingAura = "intimidatingAura",
	JokersWild = "jokersWild",
	LightItUp = "lightItUp",
	LyeIngItOnThick = "lyeIngItOnThick",
	MaximumAmperage = "maximumAmperage",
	MeteoricPace = "meteoricPace",
	PetCaller = "petCaller",
	RatParade = "ratParade",
	SplitPersonality = "splitPersonality",
	StrollingMinstrel = "strollingMinstrel",
	StrongerBetterFasterLonger = "strongerBetterFasterLonger",
	TacticalStrikeFinalForm = "tacticalStrikeFinalForm",
	TheWiseOne = "theWiseOne",
	ThornsPanicOption = "thornsPanicOption",
	WeightTraining = "weightTraining",
	WhereYourNightmaresEnd = "whereYourNightmaresEnd",
	Zoomies = "zoomies"
}



export const PLAYER_ICON_NAMES = {
	[PlayerIconTag.Bees]: 'player-icon--bees',
	[PlayerIconTag.PoisonBottles]: 'player-icon--poison-bottles',
	[PlayerIconTag.ThreeRoundBurst]: 'player-icon-3-round-burst',
	[PlayerIconTag.BrokenHeartWeepsOpen]: 'player-icon-a-broken-heart-weeps-open',
	[PlayerIconTag.QuickReactionGoodBurn]: 'player-icon-a-quick-reaction-to-a-good-burn',
	[PlayerIconTag.ShockinglyGoodTime]: 'player-icon-a-shockingly-good-time',
	[PlayerIconTag.AbsoluteUnits]: 'player-icon-absolute-units',
	[PlayerIconTag.AristotlesLackOfMotion]: 'player-icon-aristotles-lack-of-motion',
	[PlayerIconTag.Banditry]: 'player-icon-banditry',
	[PlayerIconTag.BetterLivingThroughPoison]: 'player-icon-better-living-through-poison',
	[PlayerIconTag.BigBrainFrailBody]: 'player-icon-big-brain-frail-body',
	[PlayerIconTag.BigBrainLearning]: 'player-icon-big-brain-learning',
	[PlayerIconTag.BloatedBlimpies]: 'player-icon-bloated-blimpies',
	[PlayerIconTag.BloodSoakedBullets]: 'player-icon-blood-soaked-bullets',
	[PlayerIconTag.ChompBiteForce]: 'player-icon-chomp-bite-force',
	[PlayerIconTag.Chonkers]: 'player-icon-chonkers',
	[PlayerIconTag.DoThoseRatsHaveKnives]: 'player-icon-do-those-rats-have-knives',
	[PlayerIconTag.DryHeat]: 'player-icon-dry-heat',
	[PlayerIconTag.FastLearner]: 'player-icon-fast-learner',
	[PlayerIconTag.FatRat]: 'player-icon-fat-rat',
	[PlayerIconTag.FireStorm]: 'player-icon-fire-storm',
	[PlayerIconTag.FocalPrism]: 'player-icon-focal-prism',
	[PlayerIconTag.FreneticActivity]: 'player-icon-frenetic-activity',
	[PlayerIconTag.GettingIntoRatTrouble]: 'player-icon-getting-into-rat-trouble',
	[PlayerIconTag.GreatTone]: 'player-icon-great-tone',
	[PlayerIconTag.HaveHeart]: 'player-icon-have-heart',
	[PlayerIconTag.Heist]: 'player-icon-heist',
	[PlayerIconTag.HighDensityEnergy]: 'player-icon-high-density-energy',
	[PlayerIconTag.HighSpeedPlunderer]: 'player-icon-high-speed-plunderer',
	[PlayerIconTag.HolySpearFinalForm]: 'player-icon-holy-spear-final-form',
	[PlayerIconTag.HungryHungryDoggo]: 'player-icon-hungry-hungry-doggo',
	[PlayerIconTag.Impatient]: 'player-icon-impatient',
	[PlayerIconTag.InTheEyeOfMercy]: 'player-icon-in-the-eye-of-mercy',
	[PlayerIconTag.IntimidatingAura]: 'player-icon-intimidating-aura',
	[PlayerIconTag.JokersWild]: 'player-icon-jokers-wild',
	[PlayerIconTag.LightItUp]: 'player-icon-light-it-up',
	[PlayerIconTag.LyeIngItOnThick]: 'player-icon-lye-ing-it-on-thick',
	[PlayerIconTag.MaximumAmperage]: 'player-icon-maximum-amperage',
	[PlayerIconTag.MeteoricPace]: 'player-icon-meteoric-pace',
	[PlayerIconTag.PetCaller]: 'player-icon-pet-caller',
	[PlayerIconTag.RatParade]: 'player-icon-rat-parade',
	[PlayerIconTag.SplitPersonality]: 'player-icon-split-personality',
	[PlayerIconTag.StrollingMinstrel]: 'player-icon-strolling-minstrel',
	[PlayerIconTag.StrongerBetterFasterLonger]: 'player-icon-stronger-better-faster-longer',
	[PlayerIconTag.TacticalStrikeFinalForm]: 'player-icon-tactical-strike-final-form',
	[PlayerIconTag.TheWiseOne]: 'player-icon-the-wise-one',
	[PlayerIconTag.ThornsPanicOption]: 'player-icon-thorns-panic-option',
	[PlayerIconTag.WeightTraining]: 'player-icon-weight-training',
	[PlayerIconTag.WhereYourNightmaresEnd]: 'player-icon-where-your-nightmares-end',
	[PlayerIconTag.Zoomies]: 'player-icon-zoomies',
} as const

// These colors (will at asome point) match something in Griddle! Make sure to update them there too!
export enum PlayerIconColor {
	Orange = "orange",
	Blue = "blue",
	Green = "green",
}

export type PlayerIconSetting = {
	tag: PlayerIconTag,
	color: PlayerIconColor
}
