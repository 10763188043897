<template>
	<div id="currency-display" class="currency-container">
		<div class="currency-list">
			<div class="currency-list-item">
				<Currency :icon-name="'paper_scraps'" :animated="this.animateCommonCurrency" :currency="getCommonCurrency" :disableBackground="true" :tooltip-data="{ name: $t('currency.scrap_label'), description: $t('currency.scrap_description') }"></Currency>
			</div>
			<div class="currency-list-item">
				<Currency :icon-name="'lost_scrolls'" :animated="this.animateRareCurrency" :currency="getRareCurrency" :disableBackground="true" :tooltip-data="{ name: $t('currency.scroll_label'), description: $t('currency.scroll_description') }"></Currency>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapState } from 'vuex'
import Currency from './currency.vue'

export default {
	name: 'CurrencyDisplay',
	components: {
		Currency,
	},
	data() {
		return {
			animateCommonCurrency: false,
			animateRareCurrency: false,
			timeoutIDCommon: null,
			timeoutIDRare: null,
		}
	},
	watch: {
		getCommonCurrency(newVal, oldval) {
			this.animateCommonCurrency = true
			if (this.timeoutIDCommon) {
				clearTimeout(this.timeoutIDCommon)
			}
			this.timeoutIDCommon = setTimeout(() => {
				this.animateCommonCurrency = false
				this.timeoutIDCommon = null
			}, 1000) // If you change this timeout, be sure to adjust the animation time in &.animated in currency.vue
		},
		getRareCurrency(newVal, oldval) {
			this.animateRareCurrency = true
			if (this.timeoutIDRare) {
				clearTimeout(this.timeoutIDRare)
			}
			setTimeout(() => {
				this.animateRareCurrency = false
				this.timeoutIDRare = null
			}, 1000) // If you change this timeout, be sure to adjust the animation time in &.animated in currency.vue
		},
	},
	props: {},
	computed: {
		//TODO move this out of paused and into UI ovrlay
		...mapGetters('paused', ['getCommonCurrency', 'getRareCurrency']),
	},
	methods: {
		// ...mapMutations('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.currency-container {
	position: absolute;
    top: 92px;
    right: 14px;
	z-index: 9999999;
	.currency-list {
		.currency-list-item {
			position: relative;
			height: 80px;
			.currency-container {
				justify-content: flex-end;
			}
		}
	}
}
</style>
