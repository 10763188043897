
import { Spritesheet } from "pixi.js"
import { SpriteAnimationConfig, SPRITE_ANIMTATION_CONFIGS, SpriteAnimationConfigs } from "../../spine-config/sprite-animation-config"
import { InstancedAnimatedSprite } from "./instanced-animated-sprite"
import { Vector } from "sat"
import { radians } from "../../utils/primitive-types"

// It's spritesheet-animator but it uses instanced sprites
export class InstancedSpriteSheetAnimator {

    spriteSheet: Spritesheet
    animatedSprites: Map<string, InstancedAnimatedSprite>
    
    activeSprite: InstancedAnimatedSprite
    activeAnimation: string

	playConcurrently: boolean

    position: Vector = new Vector()
    scale: Vector = new Vector(1, 1)

    private _rotation: radians = 0
    private _zIndex: number = 0
    private _alpha: number = 1

    get zIndex() { 
        return this._zIndex
    }
    set zIndex(value: number) {
        this._zIndex = value
        this.activeSprite.zIndex = value
    }

    get rotation() {
        return this._rotation
    }
    set rotation(val) {
        this._rotation = val
        this.animatedSprites.forEach((v) => v.rotation = val)
    }

    get alpha() {
        return this._alpha
    }

    set alpha(val) {
        this._alpha = val
        this.animatedSprites.forEach((v) => v.alpha = val)
    }

    get height() {
        return this.activeSprite.frames[this.activeSprite.activeFrame].height
    }

    private _anchor: Vector = new Vector(0, 0)
    private _colors: number[] = []

    private _isInScene: boolean = false
    private _isBgLayer: boolean

    private _originalSourceWidth: number
    private _originalSourceHeight: number

    constructor(spriteSheet: Spritesheet, defaultAnimation: string, overrideAnimationSpeed?: number, playAnimationsConcurrently?: boolean, spriteAnimationConfigsOverride?: SpriteAnimationConfigs) {
        this.spriteSheet = spriteSheet

        this.animatedSprites = new Map()

        const animSpeed = overrideAnimationSpeed !== undefined ? overrideAnimationSpeed : 0.5

		this.playConcurrently = playAnimationsConcurrently

        for(const animationName in spriteSheet.animations) {
            const frames = spriteSheet.animations[animationName]
            
            const animatedSprite = new InstancedAnimatedSprite(frames)
            animatedSprite.position = this.position // these are references so they will update automatically now
            animatedSprite.scale = this.scale

            const config = (spriteAnimationConfigsOverride ?? SPRITE_ANIMTATION_CONFIGS)[animationName] as SpriteAnimationConfig
            if(config) {
                if(config.overrideSpeed) {
                    animatedSprite.animationSpeed = config.overrideSpeed
                } else {
                    animatedSprite.animationSpeed = animSpeed
                }

                animatedSprite.loop = config.loop

				if (this.playConcurrently) {
					animatedSprite.onComplete = () => {
						animatedSprite.removeFromScene()
					}
				} else if(config.nextAnimation) {
					animatedSprite.onComplete = () => {
						this.playAnimation(config.nextAnimation)
					}
				}

                if(config.anchor) {
                    animatedSprite.setAnchor(config.anchor.x, config.anchor.y)
                }

                if (config.useTrimOffset !== undefined) {
                    animatedSprite.useTrimOffset = config.useTrimOffset
                }
            } else {
                animatedSprite.animationSpeed = animSpeed
            }

            animatedSprite.removeFromScene()
            // this.addChild(animatedSprite)

            this.animatedSprites.set(animationName, animatedSprite)
        }

        this.activeSprite = this.animatedSprites.get(defaultAnimation)
        // this.activeSprite.addToScene()
        this.activeSprite.play()

        this.activeAnimation = defaultAnimation
        
        this._colors = [1,1,1,1]

        // Debug for where the center is
		// const gfx = new Graphics()
        // gfx.beginFill(0x0000FF, 1)
        // gfx.drawCircle(0,0, 15)
        // gfx.endFill()
        // this.addChild(gfx)
    }

    playAnimation(name: string, nextAnim?: string, callback?: () => void) {
        // would be great if we didn't have to do a string comparison here
        if(this.activeAnimation !== name && !this.playConcurrently) {
            this.activeSprite.stop()
            this.activeSprite.removeFromScene()

            this.activeSprite = this.animatedSprites.get(name)
            this.activeSprite.gotoAndPlay(0)
            this.activeSprite.zIndex = this._zIndex

            if (this._isInScene) {
                this.activeSprite.addToScene(this._isBgLayer)
            }
        } else if (!this.playConcurrently) {
            this.activeSprite.gotoAndPlay(0)
        }

        if (nextAnim) {
            this.activeSprite.loop = false
            
            this.activeSprite.onComplete = () => {
                this.playAnimation(nextAnim)

                if (callback) {
                    callback()
                }
            }
        } else if (callback) {
            const active = this.activeSprite // just in case we play another anim before looping
            // will be really hard to debug if that happens too
            if (this.activeSprite.loop) {
                this.activeSprite.onLoop = () => {
                    active.onLoop = null
                    callback()
                }
            } else {
                this.activeSprite.onComplete = () => {
                    active.onLoop = null
                    callback()
                }
            }
            
        }

        this.activeAnimation = name
    }

	playAnimationsConcurrently() {
		this.activeSprite.stop()
		this.animatedSprites.forEach((value, key) => {
			value.addToScene(this._isBgLayer)
			value.gotoAndPlay(0)
		})

        this.playConcurrently = true
	}

    restartCurrentAnim() {
        this.activeSprite.stop()
        this.activeSprite.gotoAndPlay(0)
    }

    addToScene(useBgLayer?: boolean) {
        if (!this._isInScene) {
            this.activeSprite.addToScene(useBgLayer)
            this._isInScene = true
            this._isBgLayer = Boolean(useBgLayer)
        }
    }

    removeFromScene() {
        if (this._isInScene) {
            if (this.playConcurrently) {
                this.animatedSprites.forEach((value, key) => {
                    value.removeFromScene()
                })
            } else {
                this.activeSprite.removeFromScene()
            }

            this._isInScene = false
        }
    }

    setAnchor(x: number, y: number) {
        this._anchor.x = x
        this._anchor.y = y

        this.animatedSprites.forEach((v) => v.setAnchor(x, y))
    }

    setLoop(loop: boolean) {
        this.animatedSprites.forEach((v) => v.loop = loop)
    }

    setColor(r: number, g: number, b: number, a: number) {
        this._colors[0] = r
        this._colors[1] = g
        this._colors[2] = b
        this._colors[3] = a

        this.animatedSprites.forEach((v) => v.setColor(r,g,b,a))
    }

    getColors(): number[] {
        return this._colors
    }
}
