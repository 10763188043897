<template>
	<div id="wishlist">
		<div :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `top right` }">
			<div class="animated" @click="openSteamPage()">
				<div class="logo-image"></div>
				<div class="text" :lang="this.$i18n.locale">Buy on Steam!</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapState } from 'vuex'
import { GoogleAnalyticsHandler } from '../../analytics/google-analytics'
import { STEAM_PARAMS, STEAM_URL } from '../steam-links'

export default {
	name: 'wishlistCorner',
	data() {
		return {}
	},
	computed: {
		...mapState('uiScaling', ['responsiveScale']),
	},
	methods: {
		openSteamPage() {
			GoogleAnalyticsHandler.getInstance().trackWishlist()
			if (process.env.IS_ELECTRON) {
				setTimeout(() => {
					window.location.replace('steam://openurl/https://store.steampowered.com/app/1619420')
				}, 1000)
			} else {
				window.open(`${STEAM_URL + STEAM_PARAMS.dogear}`, '_blank')
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
#wishlist {
	width: 100%;
	height: 100%;
	pointer-events: none;
	.animated {
		animation: grow ease-in 2s 0s infinite alternate;
		transform-origin: top right;
		width: 500px;
		height: 180px;
		background-color: @green;
		pointer-events: all;
		cursor: pointer;
		&:hover {
				background-color: white;
		}
		filter: drop-shadow(0 0 2px black);
		top: 0px;
		right: -250px;
		position: absolute;
		rotate: 45deg;
		z-index: 500;
		display: flex;
		flex-direction: column;
		align-items: center;
		transform-origin: center top;
		.logo-image {
				margin-top: 20px;
				pointer-events: none;

				position: relative;
				background-image: url('~/assets/ui/wishlist/steam-icon.png');
				background-size: cover;


				width: 72px;
				height: 71px;

				z-index: 501;
		}

		.text {
			pointer-events: none;
			margin-top: 20px;
			position: relative;

			z-index: 502;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 50px;
				font-variant: small-caps;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 24px;
			}
		}
	}
}

@keyframes grow {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(0.9);
	}
}
</style>