<template>
	<div id="killstreak">
		<div class="bar-container">
			<div class="header" :lang="this.$i18n.locale">
				<div class="header-left" >
					{{$t('game_info.timer')}}
				</div>
			</div>
			<ProgressBarWrapper class="small cream" title="Timer" :value="killTimeValue" :max-value="1" :subtitle="''"></ProgressBarWrapper>
		</div>
		
		<div class="bar-container" >
			<div class="header" :lang="this.$i18n.locale">
				<div class="header-left">
					{{$t('game_info.kills')}}
				</div>

				<div class="header-right">
					{{currentKillstreak}}
				</div>
			</div>
			
			<ProgressBarWrapper :class="'small red' + (currentKillstreak >= 25 ? ' pulsate' : '')" :value="currentKillstreak" :max-value="25" :subtitle="''"></ProgressBarWrapper>
		</div>
	</div>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex'
import ProgressBarWrapper from './progress-bar-wrapper.vue'

export default {
	name: 'Killstreak',
	components: {
		ProgressBarWrapper,
	},
	computed: {
		...mapGetters('time', ['inGameTimestamp']),
		...mapState('player', ['currentKillstreak', 'lastKillTime', 'killstreakExpiryTime']),
		killTimeValue() {
			const currentTime = this.inGameTimestamp + 74 // IGT updated every 50ms, timer lerp finishes in 75ms -> little offset to make it more accurate
			const maxTime = this.killstreakExpiryTime
			const minTime = this.lastKillTime
			return Math.clamp(1 - (currentTime - minTime) / (maxTime - minTime), 0, 1)
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#killstreak {
	display: flex;
	flex-direction: column;
	min-height: 100px;
	.bar-container {
		width: 200px;
		margin-bottom: 15px;

		.header {
			height: 20px;
			color: @offWhite;
			filter: drop-shadow(0px 0px 3px black);

			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 22px;
				letter-spacing: 1px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}

			.header-left {
				float: left;
			}

			.header-right {
				float: right;
			}
		}
	}
}



@supports (-webkit-text-stroke: 1px @darkBlue) {
	#killstreak {
		.killstreak-text {
			-webkit-text-stroke: 1px @darkBlue;
			-webkit-text-fill-color: @green;
		}
	}
}
</style>
