<template>
	<div class="currency-container" @mouseover="onMouseEnter" @mouseleave="onMouseLeave" :class="{ 'disable-background': disableBackground }">
		<div :class="iconName" class="currency-icon"></div>
		<div class="currency" :class="this.animated ? 'animated' : ''" :lang="this.$i18n.locale">{{ currency }}</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
//TODO: validate against common-names
const ICON_NAMES = ['paper_scraps', 'lost_scrolls', 'magic_tomes']

export default {
	name: 'Currency',
	components: {},
	data() {
		return {}
	},
	props: {
		iconName: {
			type: String,
			required: true,
			validator: function (value: string) {
				return ICON_NAMES.includes(value)
			},
		},
		currency: {
			type: Number,
			required: true,
			default: 0,
		},
		disableBackground: {
			type: Boolean,
			default: false,
		},
		tooltipData: {
			type: Object,
			required: false,
			default: null,
		},
		animated: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		...mapMutations('tooltip', ['showTooltip', 'currentComponentHovered', 'updateTooltipPosition']),

		onMouseEnter(event) {
			this.showTooltip({ item: this.tooltipData })
			this.updateTooltipPosition(event)
		},
		onMouseLeave() {
			this.updateTooltipPosition(event)
			this.showTooltip({ item: null, component: null })
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.currency-container {
	background-color: @pastelGreen;
	width: 219px;
	height: 48px;
	display: flex;
	align-items: center;
	margin-left: 20px;
	&.disable-background {
		background-color: transparent;
	}
	.currency-icon {
		background-size: contain;
		background-repeat: no-repeat;
		&.paper_scraps {
			background-image: url('~/assets/ui/currency/icon-paper-scraps.png');
			width: 54px;
			height: 55px;
		}
		&.lost_scrolls {
			background-image: url('~/assets/ui/currency/icon-lost-scroll.png');
			width: 54px;
			height: 65px;
			top: -8px;
			position: relative;
		}
		&.magic_tomes {
			width: 63px;
			height: 67px;
			background-image: url('~/assets/ui/currency/icon-magic-tome.png');
			top: -3px;
			position: relative;
		}
	}
	.currency {
		color: @offWhite;
		margin-left: 5px;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-variant: small-caps;
			font-size: 45px;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}

		&.animated {
			// If you change the animation time, be sure to change the timeout in the watchers in in-game-currency.vue to match it
			animation: number-pulse 1s infinite;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
		}

		@-webkit-keyframes number-pulse {
			0% { -webkit-transform: scale(1); }
			50% { -webkit-transform: scale(1.2); }
			100% { -webkit-transform: scale(1); }
		}

		@keyframes number-pulse {
		0% { transform: scale(1); }
		50% { transform: scale(1.2); }
		100% { transform: scale(1); }
		}
	}
}
</style>
