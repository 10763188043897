import { Enemy } from "../enemy"
import { Vector } from "sat"
import { add, crappyDerivative, degToRad, distanceSquared, distanceSquaredVV, getAcuteAngle, getRandomPointInCircleRange, makeTangent, smoothDampRad, sub, subVXY } from "../../../utils/math"
import { AttackTypes, AttackWithCooldown, FightingBehaviours, HopStrafeStrategy, SpawnerStrategy } from "../ai-types"
import { angleInRadsFromVector, vectorFromAngleInRads } from "../../../utils/vector"
import { radians, timeInSeconds } from "../../../utils/primitive-types"
import { getAimVector } from "../ai-util"
import { ProjectileSystem } from "../../../projectiles/projectile-system"
import { DamagingGroundHazardParams } from "../../hazards/damaging-ground-hazard"
import { ObjectPoolTyped } from "../../../utils/third-party/object-pool"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { getVisibleWorldHeight, getVisibleWorldWidth } from "../../../engine/graphics/camera-logic"
import { Renderer } from "../../../engine/graphics/renderer"
import { EnemyHazardWarning, EnemyHazardWarningParams } from "../../hazards/enemy-hazard-warning"
import { EnemyHazard } from "../../hazards/enemy-hazard"
import AISystem from "../ai-system"
import { InGameTime } from "../../../utils/time"
import { GameState } from "../../../engine/game-state"
import { ENEMY_SPAWNER_MIN_DIST_TO_PLAYER_2 } from "../enemy-spawn-config"
import { callbacks_addCallback } from "../../../utils/callback-system"
import { AnimationTrack } from "../../../spine-config/animation-track"
import assert from "assert"
import { EnemyMelee } from "../../../projectiles/enemy-melee-attack"


const DEBUG_FIGHTING = false

export enum FightingSubstate {
	None = 'none',
	LerpSpeed = 'lerpSpeed',
	Aim = 'aim',
	Charge = 'charge',
	Strafe = 'strafe',
	Wait = 'wait',
}

export enum MeleeSubstate {
	None = 'none',
	Attacking = 'attacking'
}


export const fightingBehaviours = {
	chaseAndAttack(entity: Enemy, delta: timeInSeconds): void {

		if (entity.fightingAttackType === AttackTypes.MELEE) {
			if (entity.cooldown && entity.cooldown.isUp()) {
				entity.substateData.meleeState = MeleeSubstate.None
				entity.keepFacingDirection = false
			}
		}

		const target = entity.target
		const enemyToPlayerDistance = entity.distanceToPlayer2
		const targetVelocity = new Vector(entity.directionToPlayer.x, entity.directionToPlayer.y) // @TODO don't alloc

		updateAim(entity, target.position, delta)

		if (enemyToPlayerDistance < entity.movementMinDistance2 && notInMeleeAttack(entity)) {
			//entity.currentSubState = 'distancing'
			entity.shooting = false
			moveInDirection(targetVelocity.scale(-1), entity, delta)
		} else if (enemyToPlayerDistance > entity.movementMaxDistance2 && notInMeleeAttack(entity)) {
			//entity.currentSubState = 'chasing'
			entity.shooting = false
			moveInDirection(targetVelocity, entity, delta)
		} else if (enemyToPlayerDistance >= entity.engagementMinDistance2 && enemyToPlayerDistance <= entity.engagementMaxDistance2 && entity.fightingAttackType !== AttackTypes.NONE) {
			//entity.currentSubState = 'attacking'
			updateAttacking(entity, delta)
		}
		entity.velocityAngle = angleInRadsFromVector(entity.velocity)
	},

	strafeAndAttack(entity: Enemy, delta: timeInSeconds): void {
		// Keep track of when the enemy began strafing
		if (entity.substateData.currentSubstate === FightingSubstate.Strafe) {
			entity.substateData.timeInSubstate += delta
		}
		else {
			entity.substateData.timeInSubstate = 0
		}

		const target = entity.target
		const enemyToPlayerDistance = entity.distanceToPlayer2
		const targetVelocity = new Vector(entity.directionToPlayer.x, entity.directionToPlayer.y) // @TODO don't alloc

		updateAim(entity, target.position, delta)

		let movementMaxDistance
		let movementMinDistance

		if (entity.strafeDistance !== undefined) {
			const screenHalfWidth = getVisibleWorldWidth(Renderer.getInstance().cameraState.zoom) / 2
			const screenHalfHeight = getVisibleWorldHeight(Renderer.getInstance().cameraState.zoom) / 2

			movementMaxDistance = entity.strafeDistance * Math.min(screenHalfWidth, screenHalfHeight)
			movementMinDistance = movementMaxDistance - 100

		} else {
			movementMaxDistance =  entity.movementMaxDistance2
			movementMinDistance =  entity.movementMinDistance2
		}

		let moved = false
		if (enemyToPlayerDistance < movementMinDistance) {
			entity.shooting = false
			moveInDirection(targetVelocity.scale(-1), entity, delta)
			moved = true
		} else if (enemyToPlayerDistance > movementMaxDistance) {
			entity.shooting = false
			moveInDirection(targetVelocity, entity, delta)
			moved = true
		}
		if (enemyToPlayerDistance >= entity.engagementMinDistance2 && enemyToPlayerDistance <= entity.engagementMaxDistance2) {
			entity.shooting = true
			makeStrafeVector(entity, targetVelocity)
			entity.substateData.currentSubstate = FightingSubstate.Strafe
			if (entity.fightingAttackType !== AttackTypes.NONE) {
				updateAttacking(entity, delta)
			}
			moveInDirection(targetVelocity, entity, delta)
			moved = true
		}
		if (!moved) {
			moveInDirection(targetVelocity, entity, delta)
		}

		entity.velocityAngle = angleInRadsFromVector(entity.velocity)
	},

	hopStrafeAndAttack(entity: Enemy, delta: timeInSeconds): void {
		const target = entity.target
		const enemyToPlayerDistance = entity.distanceToPlayer2
		let targetVelocity = new Vector(entity.directionToPlayer.x, entity.directionToPlayer.y) // @TODO don't alloc

		updateAim(entity, target.position, delta)

		let movementMaxDistance
		let movementMinDistance

		const movementConfig = entity.config.states.fighting.movementStrategy as HopStrafeStrategy

		if (entity.substateData.currentSubstate !== FightingSubstate.Wait) {
			let moved = false

			if (!entity.substateData.savedVelocity) {
				if (entity.strafeDistance !== undefined) {
					const screenHalfWidth = getVisibleWorldWidth(Renderer.getInstance().cameraState.zoom) / 2
					const screenHalfHeight = getVisibleWorldHeight(Renderer.getInstance().cameraState.zoom) / 2
		
					movementMaxDistance = entity.strafeDistance * Math.min(screenHalfWidth, screenHalfHeight)
					movementMinDistance = movementMaxDistance - 100
				} else {
					movementMaxDistance =  entity.movementMaxDistance2
					movementMinDistance =  entity.movementMinDistance2
				}
		
				if (enemyToPlayerDistance < movementMinDistance) {
					moveInDirection(targetVelocity.scale(-1), entity, delta)
					entity.substateData.currentSpeed = undefined
				} else if (enemyToPlayerDistance > movementMaxDistance) {
					moveInDirection(targetVelocity, entity, delta)
					entity.substateData.currentSpeed = undefined
				} else if (enemyToPlayerDistance >= entity.engagementMinDistance2 && enemyToPlayerDistance <= entity.engagementMaxDistance2) {			
					const randomAngle = Math.getRandomFloat(movementConfig.hopAngleMin, movementConfig.hopAngleMax)
					targetVelocity = targetVelocity.rotate(randomAngle)
					targetVelocity.scale(Math.getRandomInt(0, 1) ? 1 : -1)

					moveInDirection(targetVelocity, entity, delta)
					moved = true
					entity.substateData.savedVelocity = targetVelocity
				}
			} else {
				moved = true
			}
			
			if (moved) {
				entity.substateData.timeInSubstate += delta

				if (entity.substateData.timeInSubstate >= movementConfig.hopTime) {
					entity.substateData.currentSubstate = FightingSubstate.Wait
					entity.substateData.timeInSubstate = 0
					entity.substateData.savedVelocity = null
					entity.substateData.currentSpeed = 0

					entity.shooting = true
					entity.gfx.playAttackAnim(AnimationTrack.SHOOT)

					callbacks_addCallback(entity, () => {
						if (entity.isDead()) {
							return
						}

						updateAttacking(entity, delta)
					}, movementConfig.attackDelayOnHop)
				} else {
					entity.substateData.currentSpeed = movementConfig.hopSpeed
				}
			}
		} else {
			entity.substateData.timeInSubstate += delta
			
			// wait for hop time to end
			if (entity.substateData.timeInSubstate >= movementConfig.hopWaitTime) {
				entity.substateData.currentSubstate = FightingSubstate.Strafe
				entity.substateData.timeInSubstate = 0
				entity.shooting = false
			}
		}
		

		entity.velocityAngle = angleInRadsFromVector(entity.velocity)
	},

	lerpSpeedAttack(entity: Enemy, delta: timeInSeconds) {
		if (entity.movementStrategy.behaviour !== FightingBehaviours.LERP_SPEED_ATTACK){
			console.error('Entered lerpSpeedAttack function with incorrect movmentStrategy.behavior, it should be set to FightingBehaviours.LERP_SPEED_ATTACK')
			return
		}
		const target = entity.target
		const enemyToPlayerDistance = entity.distanceToPlayer2
		const targetVelocity = new Vector(entity.directionToPlayer.x, entity.directionToPlayer.y) // @TODO don't alloc

		updateAim(entity, target.position, delta)

		if (enemyToPlayerDistance < entity.movementMinDistance2) {
			// Distancing
			entity.shooting = false
			moveInDirection(targetVelocity.scale(-1), entity, delta)
		} else if (enemyToPlayerDistance > entity.movementMaxDistance2) {
			// Lerp Chase
			if (entity.substateData.currentSubstate !== FightingSubstate.LerpSpeed) {
				entity.substateData.lerpSettings = entity.movementStrategy.params.lerpSpeed
				entity.substateData.lerpIndex = 0
				entity.substateData.currentSpeed = entity.movementSpeed
				entity.substateData.currentSubstate = FightingSubstate.LerpSpeed
				entity.substateData.timeInSubstate = 0
				entity.substateData.lerpTimer = 0
			}
			entity.substateData.timeInSubstate += delta
			entity.shooting = false
			lerpInDirection(targetVelocity, entity, delta)
		} else if (enemyToPlayerDistance >= entity.engagementMinDistance2 && enemyToPlayerDistance <= entity.engagementMaxDistance2 && entity.fightingAttackType !== AttackTypes.NONE) {
			//entity.currentSubState = 'attacking'
			updateAttacking(entity, delta)
		}
		entity.velocityAngle = angleInRadsFromVector(entity.velocity)
	},

	// TODO HOTCAKES: Still need to figure out how to handle collision between charging enemies and other enemies as well as charging enemies with the player
	chargeAttack(entity: Enemy, delta: timeInSeconds): void {
		if (entity.movementStrategy.behaviour !== FightingBehaviours.CHARGE_ATTACK){
			console.error('Entered chargeAttack function with incorrect movmentStrategy.behavior, it should be set to FightingBehaviours.CHARGE_ATTACK')
			return
		}
		const target = entity.target
		const enemyToPlayerVector = entity.directionToPlayer
		const enemyToPlayerDistance = enemyToPlayerVector.len() // @TODO switch to len2 / entity.distanceToPlayer2

		if (enemyToPlayerDistance > entity.movementMaxDistance && entity.substateData.currentSubstate !== FightingSubstate.Charge && entity.substateData.currentSubstate !== FightingSubstate.Aim) {
			// Entering Move or Lerp
			entity.shooting = false
			entity.keepFacingDirection = false
			const targetVelocity = sub(target, entity.position)
			// lerpSpeed is an optional param for chargeAttack behavior
			if (entity.movementStrategy.params.lerpSpeed) {
				// Lerp Chase
				if (entity.substateData.currentSubstate !== FightingSubstate.LerpSpeed) {
					entity.substateData.lerpSettings = entity.movementStrategy.params.lerpSpeed
					entity.substateData.lerpIndex = 0
					entity.substateData.currentSpeed = entity.movementSpeed
					entity.substateData.currentSubstate = FightingSubstate.LerpSpeed
					entity.substateData.timeInSubstate = 0
					entity.substateData.lerpTimer = 0
				}
				entity.substateData.timeInSubstate += delta
				lerpInDirection(targetVelocity, entity, delta)
			} else {
				// Chase
				moveInDirection(targetVelocity, entity, delta)
			}
		} else {
			// Entering Charge or Aim
			if (entity.substateData.currentSubstate !== FightingSubstate.Aim && entity.substateData.currentSubstate !== FightingSubstate.Charge) {
				entity.substateData.timeInSubstate = 0
				entity.substateData.currentSubstate = FightingSubstate.Aim
				// Jank: using an undefined lerpIndex as a flag so the right properties are set on subState when going into CHARGE
				entity.substateData.lerpIndex = undefined
			}

			entity.substateData.timeInSubstate += delta

			const aimTime = entity.movementStrategy.params.aimTime
			
			if (entity.substateData.currentSubstate === FightingSubstate.Aim) {
				// Aim
				updateAim(entity, entity.target.position, delta)
				
				// Jank: Extra Jank: Special Liam brand Jank: This is to allow the Act3 boss to attack with its projectile behaviour during the AIM portion of its charge animation.
				// This will need to be amended if another enemy acquires this behaviour OR the boss' behaviour changes further.
				if (entity.fightingAttackType === AttackTypes.PROJECTILE){ 
					updateAttacking(entity, delta) 
				}
				
				entity.velocity.x = 0
				entity.velocity.y = 0

				if (entity.substateData.timeInSubstate > aimTime) {
					entity.substateData.currentSubstate = FightingSubstate.Charge
					entity.substateData.timeInSubstate = 0
					entity.velocity.copy(entity.aimVector) 
				}
			} else if (entity.substateData.currentSubstate === FightingSubstate.Charge) {
				// Charge
				entity.keepFacingDirection = true

				const targetVelocity = entity.aimVector
				if (chargeCriteriaMet(entity, enemyToPlayerDistance)) {
					entity.shooting = false
					// Jank: using an undefined lerpIndex as a flag so the right properties are set on subState when going into CHARGE
					if (entity.substateData.lerpIndex === undefined) {
						entity.substateData.lerpSettings = entity.movementStrategy.params.chargeLerpSpeed
						entity.substateData.lerpIndex = 0
						entity.substateData.currentSpeed = entity.movementSpeed
						entity.substateData.lerpTimer = 0
					}
					lerpInDirection(targetVelocity, entity, delta)
				} else {
					// Charge done, reset substate
					entity.substateData.lerpIndex = undefined
					entity.substateData.lerpTimer = undefined
					entity.substateData.lerpSettings = undefined
					entity.substateData.currentSpeed = undefined
					entity.substateData.currentSubstate = FightingSubstate.None
					entity.substateData.timeInSubstate = 0
					entity.velocity.x = 0
					entity.velocity.y = 0
					entity.keepFacingDirection = false
				}
			}
		}
		entity.velocityAngle = angleInRadsFromVector(entity.velocity)
	},
	spawn(entity: Enemy, delta: timeInSeconds) {
		entity.substateData.timeInSubstate += delta
		if (!entity.substateData.lastSpawnTime) {
			entity.substateData.lastSpawnTime = 0
		}

		const playerPos = GameState.player.position
		if (distanceSquaredVV(playerPos, entity.position) < ENEMY_SPAWNER_MIN_DIST_TO_PLAYER_2) {
			const spawnerStrategy = entity.config.states.fighting.movementStrategy as SpawnerStrategy

			if (entity.substateData.timeInSubstate - entity.substateData.lastSpawnTime >= spawnerStrategy.spawnTime) {
				entity.substateData.lastSpawnTime = entity.substateData.timeInSubstate

				let extraSpawns = 0
				if (spawnerStrategy.spawnExtraPerGameSeconds) {
					extraSpawns = Math.floor(InGameTime.timeElapsedInSeconds / spawnerStrategy.spawnExtraPerGameSeconds)
				}
				const numToSpawn = spawnerStrategy.minNumToSpawn + extraSpawns

				const positionVector = new Vector()
				for (let i = 0; i < numToSpawn; ++i) {
					getRandomPointInCircleRange(entity.position.x, entity.position.y, spawnerStrategy.minSpawnDistance, spawnerStrategy.maxSpawnDistance, undefined, positionVector)
					
					AISystem.getInstance().spawnEnemyAtPos(spawnerStrategy.spawnEnemy, positionVector.x, positionVector.y)
				}
			}
		}
	}
}

function updateAttacking(entity: Enemy, delta: number) {
	if (entity.cooldown) {
		if (entity.fightingAttackType === AttackTypes.MELEE) {
			updateMeleeAttack(entity, delta)
		} else if (entity.cooldown.isUp()) {
			if (entity.fightingAttackType === AttackTypes.PROJECTILE) {
				ProjectileSystem.getInstance().addEnemyProjectile(entity)
			}
			else if (entity.fightingAttackType === AttackTypes.GROUND_HAZARD) {
				// Move this pool initialization somewhere else
				if(!EnemyHazardWarning.pool) {
					EnemyHazardWarning.pool = new ObjectPoolTyped<EnemyHazardWarning, EnemyHazardWarningParams>(() => new EnemyHazardWarning(), {}, 10, 1)
					EnemyHazard.pool = new ObjectPoolTyped<EnemyHazard, DamagingGroundHazardParams>(() => new EnemyHazard(), {}, 10, 1)
				}
				EnemyHazardWarning.pool.alloc({
					targetX: entity.target.position.x,
					targetY: entity.target.position.y,
					warningTime: 5,
					enemyStatList: entity.statList
				})
			}
			entity.cooldown.useCharge()
		}
	}
}

function makeStrafeVector(entity: Enemy, targetVelocity: Vector) {
	// this deserves explaining
	//  I make a somewhat crazy curve: wave
	//  if derivative of wave is positive we strafe clockwise, otherwise counter-clockwise
	const secondsInState = entity.timeInStateMs() * 0.001
	const t = (secondsInState * 0.1) % (Math.PI * 2)
	const wave = (x) => Math.sin(x) + Math.sin(x * 4) * 0.7
	const positiveDerivative = crappyDerivative(t, wave) > 0

	// move along tangent (ie strafing as the kids say these days)
	makeTangent(targetVelocity, positiveDerivative)
}

export function updateAim(entity: Enemy, targetPosition: Vector, delta: number) {
	const speed = entity.statList.getStat(StatType.projectileSpeed)
	const releasePos = add(entity.attackOffset, entity.position)
	const targetVelocity = sub(targetPosition, entity.position)

	entity.aimVector = getAimVector(releasePos, speed, targetPosition, targetVelocity, entity.config.states.fighting.shotLeadPrecision)

	// TODO HOTCAKES: Restore this cooldown stuff as soon as some attack-cooldown patterns are defined
	//if (entity.currentAttackCooldown < entity.originalAttackCooldown() - entity.visualAimLockSeconds) {
		const newAngle = angleInRadsFromVector(entity.useFixedAimVector ? entity.fixedAimVector : entity.aimVector)
		const out = smoothDampRad(entity.visualAimAngle, entity.visualAimAngleSpeed, newAngle, delta, 0.5)
		entity.visualAimAngle = out[0]
		entity.visualAimAngleSpeed = out[1]
	//}

	/*if (DEBUG_FIGHTING) {
		debugDrawLine(entity.position, target.position, 0xff0000)
		debugDrawAim(entity, 0x00ff00)
	} */
}

export function moveInDirection(targetVelocity: Vector, entity: Enemy, delta: number) {
	if (targetVelocity.len2() > 1) {
		targetVelocity.normalize()
	}

	const turningRate = degToRad(entity.turningRateInDegrees)

	let currentAngle = angleInRadsFromVector(entity.velocity)
	const targetAngle = angleInRadsFromVector(targetVelocity)

	if (entity.turningRateInDegrees >= 0 && !entity.quickTurnReady) {
		currentAngle = approachAngle(currentAngle, targetAngle, turningRate, delta)
	} else {
		currentAngle = targetAngle
	}

	const acuteAngle = getAcuteAngle(currentAngle, targetAngle)

	const targetVelocityVectorAdjustedForTurningRateAndDirection = vectorFromAngleInRads(currentAngle)
	// TODO4 goose ask mike about this condition
	if (acuteAngle < Math.PI / 2 && acuteAngle > -Math.PI / 2) {
		targetVelocityVectorAdjustedForTurningRateAndDirection.scale(entity.movementSpeed)
	} else if (entity.movementSpeed === 0) {
		targetVelocityVectorAdjustedForTurningRateAndDirection.scale(entity.movementSpeed)
	}

	entity.velocity = targetVelocityVectorAdjustedForTurningRateAndDirection

	entity.quickTurnReady = false

	/* TODO HOTCAKES: Restore once debug visuals are in
	if (DEBUG_FIGHTING) {
		debugDrawLine(entity.position, add(entity.position, entity.velocity), 0xffffff)
	} */
}

// A copy of moveInDirection but with lerping. Keeping this as a discrete function for now despite the duplicate code
function lerpInDirection(targetVelocity: Vector, entity: Enemy, delta: number) {
	if (entity.substateData.lerpIndex === undefined) {
		console.error('Missing substateData: lerpIndex property is undefined')
		return	
	}
	if (entity.substateData.lerpSettings === undefined) {
		console.error('Missing substateData: lerpSettings property is undefined')
		return	
	}
	if (entity.substateData.lerpTimer === undefined) {
		console.error('Missing substateData: lerpTimer property is undefined')
		return	
	}
	if (entity.substateData.currentSpeed === undefined) {
		console.error('Missing substateData: currentSpeed property is undefined')
		return	
	}
	
	if (targetVelocity.len2() > 1) {
		targetVelocity.normalize()
	}

	const turningRate = degToRad(entity.turningRateInDegrees)

	let currentAngle = angleInRadsFromVector(entity.velocity)
	const targetAngle = angleInRadsFromVector(targetVelocity)

	if (entity.turningRateInDegrees >= 0 && !entity.quickTurnReady) {
		currentAngle = approachAngle(currentAngle, targetAngle, turningRate, delta)
	} else {
		currentAngle = targetAngle
	}

	const acuteAngle = getAcuteAngle(currentAngle, targetAngle)

	if (entity.substateData.lerpIndex < entity.substateData.lerpSettings.length) {
		entity.substateData.lerpTimer += delta
		const { targetSpeedMult, maxTime } = entity.substateData.lerpSettings[entity.substateData.lerpIndex]
		const targetMovementSpeed = entity.movementSpeed * targetSpeedMult
		entity.substateData.currentSpeed = Math.lerp(entity.substateData.currentSpeed, targetMovementSpeed, entity.substateData.lerpTimer / maxTime)
		if (entity.substateData.lerpTimer >= maxTime){
			entity.substateData.currentSpeed = targetMovementSpeed
			entity.substateData.lerpIndex++
			entity.substateData.lerpTimer = 0
		}
	} else {
		entity.substateData.lerpIndex = 0
		entity.substateData.lerpTimer = 0
	}

	const targetVelocityVectorAdjustedForTurningRateAndDirection = vectorFromAngleInRads(currentAngle)
	
	// TODO4 goose ask mike about this condition
	if (acuteAngle < Math.PI / 2 && acuteAngle > -Math.PI / 2) {
		targetVelocityVectorAdjustedForTurningRateAndDirection.scale(entity.substateData.currentSpeed)
	} else if (entity.movementSpeed === 0) {
		targetVelocityVectorAdjustedForTurningRateAndDirection.scale(entity.substateData.currentSpeed)
	}

	entity.velocity = targetVelocityVectorAdjustedForTurningRateAndDirection

	entity.quickTurnReady = false
}

export function approachAngle(currentAngle: radians, targetAngle: radians, turningRate: radians, delta: timeInSeconds): radians {
	const turningRateThisFrame = turningRate * delta
	const acuteAngle = getAcuteAngle(currentAngle, targetAngle)

	if (Math.abs(acuteAngle) <= turningRateThisFrame) {
		return targetAngle
	}

	const shouldTurnCounterClockwise = acuteAngle > 0
	if (shouldTurnCounterClockwise) {
		currentAngle += turningRateThisFrame
		if (targetAngle > 0 && currentAngle >= targetAngle) {
			currentAngle = targetAngle
		} else {
			if (currentAngle >= targetAngle) {
				// TODO4 Dan what is this?
				// if this else is triggered, it causes a "jostling back and forth" effect on every other/every few frames
				// logger.debug(`currentAngle ${currentAngle} >= ${targetAngle}, but ignored`)
				// logger.debug(`[${radToDeg(currentAngle)}] >= [${radToDeg(targetAngle)}], but ignored`)
				// logger.debug(`because ${targetAngle} <= 0`)
			}
		}
	} else {
		currentAngle -= turningRateThisFrame
		if (targetAngle < 0 && currentAngle <= targetAngle) {
			currentAngle = targetAngle
		} else {
			if (currentAngle <= targetAngle) {
				// TODO4 Dan what is this?
				// if this else is triggered, it causes a "jostling back and forth" effect on every other/every few frames
				// logger.debug(`currentAngle ${currentAngle} <= ${targetAngle}, but ignored`)
				// logger.debug(`[${radToDeg(currentAngle)}] <= [${radToDeg(targetAngle)}], but ignored`)
				// logger.debug(`because ${targetAngle} >= 0`)
			}
		}
	}
	return currentAngle
}

function chargeCriteriaMet(entity: Enemy, enemyToPlayerDistance: number): boolean {
	if (entity.movementStrategy.behaviour !== FightingBehaviours.CHARGE_ATTACK) {
		return false
	}
	const holdDirectionDistance = entity.movementStrategy.params.holdDirectionDistance
	const holdDirectionTime = entity.movementStrategy.params.holdDirectionTime

	if (holdDirectionDistance) {
		// Keep Chargin until we're far enough from player
		const stillInRange = enemyToPlayerDistance < holdDirectionDistance 
		
		if(holdDirectionTime) {
			return stillInRange || entity.substateData.timeInSubstate < holdDirectionTime
		}

		return stillInRange
	}

	return entity.substateData.timeInSubstate < holdDirectionTime
}

function updateMeleeAttack(entity: Enemy, delta: number) {
	assert(entity.fightingAttackType === AttackTypes.MELEE, 'WARNING: Attmpted to update melee attack state on enemy without a Melee config')
	const meleeConfig = entity.config.states.fighting.attackConfig as AttackWithCooldown

	if (!targetIsInMeleeRange(entity)) {
		return
	}

	if (entity.cooldown.isUp()){
		entity.shooting = true
		entity.substateData.meleeState = MeleeSubstate.Attacking
		entity.velocity.x = 0
		entity.velocity.y = 0
		entity.keepFacingDirection = true
		entity.gfx.playAttackAnim(AnimationTrack.SWIPE)
		callbacks_addCallback(entity, () => {
			if (entity.isDead()) {
				return
			}

			EnemyMelee.pool.alloc({
				targetX: entity.position.x + (entity.attackOffset.x * entity.facingDirection),
				targetY: entity.position.y,
				enemyStatList: entity.statList,
			})
		}, meleeConfig.telegraphDelay ?? 0)
	}
	entity.cooldown.useCharge()
}

function notInMeleeAttack(entity: Enemy) {
	return !entity.substateData.meleeState || entity.substateData.meleeState === MeleeSubstate.None
}

function targetIsInMeleeRange(entity: Enemy) {
	const attackX = entity.position.x + (entity.attackOffset.x * entity.facingDirection)
	const attackY = entity.position.y
	const dist2 = distanceSquared(attackX, attackY, entity.target.position.x, entity.target.position.y) 
	return dist2 <= entity.statList.getStat(StatType.attackSize) ** 2
}

/* TODO HOTCAKES: Restore this when debug visuals are in 
function debugDrawAim(entity: Enemy, color: number) {
	const pos = entity.position
	const target = pos.clone().add(entity.aimVector.clone().scale(100))
	debugDrawLine(pos, target, color)
} */
