import { Buff } from "../buffs/buff"
import { BuffDefinition } from "../buffs/buff-definition"
import { BuffTags, StackStyle } from "../buffs/buff-enums"
import { BuffIdentifier } from "../buffs/buff.shared"
import { Player } from "../entities/player"
import { TrajectoryModPreset, TrajectoryModPresets } from "../projectiles/trajectory-presets"
import { StatBonus } from "../stats/entity-stat-list"
import { StatType, StatOperator, StatUnit, StatConverter } from "../stats/stat-interfaces-enums"
import { Boomerang } from "../weapons/actual-weapons/primary/boomerang-weapon"
import { AllWeaponTypes } from "../weapons/weapon-types"
import { UpgradeCollections } from "./upgrade-definitions"
import { AffectedEntities } from './upgrade-entities'
import { SpearComboMode, SpearWeapon } from "../weapons/actual-weapons/primary/spear-weapon"
import { Enemy } from "../entities/enemies/enemy"
import { ChargePrimaryWeapon } from "../weapons/charge-primary-weapon"
import { UpgradePools } from "./upgrade-manager"
import { ParticleEffectType } from "../engine/graphics/pfx/particle-config"
import { isPlayer } from "../entities/entity-interfaces"
import { LightningStrike, LightningStrikeParams } from "../entities/lightning-strike"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { Wand } from "src/weapons/actual-weapons/primary/wand-weapon"

const PRIMARY_TOP_WEIGHT = 9
const PRIMARY_MIDDLE_WEIGHT = 6

export const PRIMARY_TOP_POOL: UpgradePools = {
	"level-up": PRIMARY_TOP_WEIGHT,
}
export const PRIMARY_MIDDLE_AND_BOTTOM_POOL: UpgradePools = {
	"level-up": PRIMARY_MIDDLE_WEIGHT,
}

export const PRIMARY_WEAPON_UPGRADES: Partial<UpgradeCollections> = {
	'bowUpgrades': {
		name: 'Bow Upgrades',
		desc: "",
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Bow,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Expert Marksmanship',
			desc: 'Bow: Gain two {glossary.pierce}, and moderately increase {glossary.damage}.',
			icon: 'upgrade-expert-marksmanship',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Bow'], 'attackPierceCount', StatOperator.SUM, 2],
				[['Bow'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.4]
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		left: {
			name: 'A Bigger Bolt',
			desc: 'Bow: Moderately increase Damage and add {glossary.knockback}',
			icon: 'upgrade-a-bigger-bolt',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				[['Bow'], StatType.attackKnockback, StatOperator.SUM, 350]
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		right: {
			name: 'One Arrow in Twain',
			desc: 'Bow: Gain one {glossary.split}',
			icon: 'upgrade-one-arrow-intwain',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.projectileSplitCount, StatOperator.SUM, 1],
				[['Bow'], StatType.projectileSpreadAngle, StatOperator.SUM, 30],
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		leftLeaf: {
			name: 'More Mass, More Oomph',
			desc: 'Bow: Moderately increase {glossary.attack_size} and greatly increase {glossary.damage}, at the cost of a moderate decrease in {glossary.charge_rate}.',
			icon: 'upgrade-more-mass-more-oomph',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['longbowEvolution'],
			statBonuses: [
				[['Bow'], StatType.attackSize, StatOperator.SUM, 0.3],
				[['Bow'], StatType.chargeRate, StatOperator.MULTIPLY, -0.15],
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.80],
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		middleLeaf: {
			name: 'Quick Firing, Straight as an Arrow',
			desc: 'Bow: Gain a moderate increase in {glossary.projectile_speed} and {glossary.charge_rate} at the cost of some {glossary.attack_size}',
			icon: 'upgrade-quick-firing',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.attackSize, StatOperator.MULTIPLY, -0.3],
				[['Bow'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['Bow'], StatType.chargeRate, StatOperator.SUM_THEN_MULTIPLY, 0.25],
			],
			affectedEntities: [AffectedEntities.Bow],
			unlocks: ['bowAssassinsArchUpgrades']
		},
		rightLeaf: {
			name: 'Making It Rain... Arrows',
			desc: 'Bow: Gain two {glossary.projectile_count}',
			icon: 'upgrade-making-it-rain-arrows',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.projectileCount, StatOperator.SUM, 2],
				[['Bow'], StatType.projectileSpreadAngle, StatOperator.SUM, 30],
			],
			affectedEntities: [AffectedEntities.Bow],
			unlocks: ['bowStormBreakerUpgrades']
		},
	},

	'longbowEvolution': {
		name: 'Evolution: Longbow evolution',
		desc: "Transform your bow to send foes reeling with the power of the Longbow upgrade tree",
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Bow,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Longbow',
			desc: 'Bow: Greatly increase base {glossary.damage} and moderately increase {glossary.attack_size}.',
			icon: 'upgrade-evolve-longbow',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Bow'], StatType.baseDamage, StatOperator.SUM, 20],
				[['Bow'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('bow-long-bow')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('bow-regular')
			},
			affectedEntities: [AffectedEntities.Bow],
		},
		left: {
			name: 'I like \'em when they stop moving',
			desc: 'Bow: When fully charged, attacks {glossary.stun} enemies for 1.5 seconds on hit. Greatly increase {glossary.damage}.',
			icon: 'upgrade-i-like-em-when-they-stopmoving',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['longbow-stun'],
			affectedEntities: [AffectedEntities.Bow],
			statBonuses: [
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.40],
			],
		},
		right: {
			name: 'You call that a hit?',
			desc: 'Bow: Moderately increase {glossary.damage}, and slightly increase {glossary.knockback}. Gives a Moderate chance to instantly kill non-boss enemies.',
			icon: 'upgrade-you-call-that-a-critical',
			binaryFlags: ['longbow-insta-kill-crits'],
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.critChance, StatOperator.SUM, 0.3],
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['Bow'], StatType.attackKnockback, StatOperator.SUM_THEN_MULTIPLY, 0.20],
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		leftLeaf: {
			name: 'Just walk it off!',
			desc: 'Bow: Inflicting {glossary.stun} on enemies permanently reduces {glossary.movement_speed}. Killed enemies produce shrapnel that is guaranteed to {glossary.stun} other enemies',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['longbowFinalForm'],
			icon: 'upgrade-just-walk-it-off',
			binaryFlags: ['longbow-walk-it-off', 'primary-weapon-emits-snare-nets'],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.LongbowWalkItOff,
					tags: [BuffTags.Buff],
					duration: 0,
					lastsForever: true,
					startingStacks: 1,
					stackStyle: StackStyle.None,
					reapplyDuration: 0,
					reapplyStacks: 0,
					showToClient: false,
					applyFn(buff: Buff) {
						if (!isPlayer(buff.appliedTo)) {
							const enemy = buff.appliedTo as Enemy
							buff.state.movementDefbuff = enemy.statList.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, -0.5)
						}
					},
					// Please add whatever is Needed for the Projectiles splash
				}),
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		middleLeaf: {
			name: 'Taking names',
			desc: 'Bow: Kills with the longbow add +2% rolling {glossary.damage} increase for 10 seconds that affects all weapons',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['longbowFinalForm'],
			icon: 'upgrade-taking-names',
			binaryFlags: ['longbow-taking-names'],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.LongbowTakingNames,
					tags: [BuffTags.Buff],
					duration: 10_000,
					lastsForever: false,
					startingStacks: 1,
					stackStyle: StackStyle.RollingStackDurationSeparately,
					reapplyDuration: 10_000,
					reapplyStacks: 1,
					showToClient: true,
					applyFn(buff: Buff) {
						if (isPlayer(buff.appliedTo)) {
							const player = buff.appliedTo as Player
							buff.state.damageBonus = player.stats.addStatBonus('allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.02)
						}
					},
					wearOffFn(buff: Buff) {
						buff.state.damageBonus.remove()
					},
					updateStacksFn(buff: Buff, oldStacks: number, newStacks: number) {
						if (isPlayer(buff.appliedTo)) {
							buff.state.damageBonus.update(newStacks * 0.01)
						}
					}
				}),
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		rightLeaf: {
			name: 'One Arrow to rule them all',
			desc: 'Bow: Moderately increase {glossary.attack_size}, and convert all {glossary.chain}, {glossary.split} and {glossary.projectile_count} to greatly increase {glossary.damage}.',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['longbowFinalForm'],
			icon: 'upgrade-right-in-the-soft-bits', // Using this as a placeholder instead of duplicating icons when swapping the place of Insta-kill arrows.
			affectedEntities: [AffectedEntities.Bow],
			statBonuses: [
				[['Bow'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.8],
			],
			simpleApplyFn(player, state) {
				// Convert all Projectile Count, Chaining, and Splitting to Damage.

				const converter1: StatConverter = {
					inputStatType: StatType.projectileCount,
					inputRatio: 1.0,
					inputMinReserve: 1.0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.allDamageMult,
					outputRatio: 0.45,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
				}
				state.converter1 = converter1
				player.primaryWeapon.statList.addConverter(state.converter1)

				const converter2: StatConverter = {
					inputStatType: StatType.projectileSplitCount,
					inputRatio: 1.0,
					inputMinReserve: 0.0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.allDamageMult,
					outputRatio: 0.45,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
				}
				state.converter2 = converter2
				player.primaryWeapon.statList.addConverter(state.converter2)

				const converter3: StatConverter = {
					inputStatType: StatType.projectileChainCount,
					inputRatio: 1.0,
					inputMinReserve: 0.0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.allDamageMult,
					outputRatio: 0.45,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
				}
				state.converter3 = converter3
				player.primaryWeapon.statList.addConverter(state.converter3)
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter1)
				player.primaryWeapon.statList.removeConverter(state.converter2)
				player.primaryWeapon.statList.removeConverter(state.converter3)
				state.converter1 = null
				state.converter2 = null
				state.converter3 = null
			},
		},
	},

	'longbowFinalForm': {
		shape: 'single',
		name: 'Longbow: Final Form',
		desc: "Transform your longbow to its final form. Its sheer power creates a damaging gust of wind knocking back enemies behind you.",
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Gilded Greatbow',
			desc: 'Bow: Greatly increase {glossary.attack_size}, {glossary.damage}, and {glossary.pierce} 3 additional enemies. Enemies caught behind you recieve {glossary.knockback} and 75% {glossary.damage}.',
			icon: 'upgrade-evolve-gilded-greatbow',
			pools: PRIMARY_TOP_POOL,
			binaryFlags: ['longbow-final-form'],
			statBonuses: [
				[['Bow'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.50],
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.40],
				[['Bow'], StatType.attackPierceCount, StatOperator.SUM, 3]
			],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('bow-gilded-great-bow')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('bow-long-bow')
			},
			affectedEntities: [AffectedEntities.Bow],
		}
	},

	'bowAssassinsArchUpgrades': {
		name: `Bow: Assassin's Arch`,
		desc: `Transform your bow and debilitate your enemies with the precise and deadly Assasin's Arch upgrade tree`,
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Bow,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',

		top: {
			name: `Evolve: Assassin's Arch`,
			desc: 'Bow: {glossary.pierce} FIVE more enemies, and greatly increase {glossary.damage}',
			icon: 'upgrade-evolve-assassins-arch',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Bow],
			statBonuses: [
				[['Bow'], StatType.attackPierceCount, StatOperator.SUM, 5],
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('bow-assassins-arch')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('bow-regular')
			},
		},
		left: {
			name: 'Hemorrhagic Arrows',
			desc: 'Bow: Attacks cause {glossary.bleed}',
			icon: 'upgrade-hemorrhagic-arrows',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.bleedChance, StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		right: {
			name: 'Barbed Arrows',
			desc: 'Bow: Enemies hit by your arrows are pinned-down for 2 seconds',
			icon: 'upgrade-barbed-arrows',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['bow-pin-down'],
			affectedEntities: [AffectedEntities.Bow],
		},
		leftLeaf: {
			name: 'Boiling Blood',
			desc: 'Bow: Bleeding enemies deal half of the {glossary.bleed} damage in a small area around them',
			icon: 'upgrade-boiling-blood',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Bow],
			unlocks: ['bowAssassinsArchFinalForm'],
			binaryFlags: ['boiling-blood']
		},
		middleLeaf: {
			name: 'The Heart of an Assassin',
			desc: 'Bow: Moderately increase {glossary.damage}, and your pin-down effect lasts longer OR your bleed is more potent', // w a c k
			icon: 'upgrade-the-heart-of-an-assassin',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Bow],
			statBonuses: [
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3]
			],
			simpleApplyFn(player, state) {
				if (player.binaryFlags.has('bow-pin-down')) {
					player.binaryFlags.add('bow-pin-down-increased-duration')
				} else {
					state.bonus = player.primaryWeapon.statList.addStatBonus(StatType.bleedPotency, StatOperator.SUM_THEN_MULTIPLY, 0.5)
				}
			},
			simpleRemoveFn(player, state) {
				if (state.bonus) {
					state.bonus.remove()
				} else {
					player.binaryFlags.delete('bow-pin-down-increased-duration')
				}
			},
			unlocks: ['bowAssassinsArchFinalForm']
		},
		rightLeaf: {
			name: 'Cast a Cruel Net',
			desc: 'Bow: Your attacks apply pin-down and a portion of your damage in a small area when hitting an enemy',
			icon: 'upgrade-cast-a-cruel-net',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.projectileSplashRadius, StatOperator.SUM, 150],
				[['Bow'], StatType.projectileSplashDamage, StatOperator.SUM, 0.25],
			],
			affectedEntities: [AffectedEntities.Bow],
			unlocks: ['bowAssassinsArchFinalForm']
		},
	},

	'bowAssassinsArchFinalForm': {
		shape: 'single',
		name: `Assassin's Arch: Final Form`,
		desc: `Transform your Assasin's Arch into its final form to execute enemies with precision and speed as your arrows infinitly pierce enemies`,
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: `Evolve: Lethal Sniper`,
			desc: 'Bow: Infinitely {glossary.pierce} enemies, and moderately increase {glossary.projectile_speed}, {glossary.damage}, and {glossary.charge_rate}.',
			icon: 'upgrade-assassins-arch-final-form',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Bow],
			statBonuses: [
				[['Bow'], StatType.attackPierceCount, StatOperator.SUM, 999_999],
				[['Bow'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Bow'], StatType.chargeRate, StatOperator.SUM_THEN_MULTIPLY, 0.2],
			],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('bow-lethal-sniper')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('bow-assassins-arch')
			},
		}
	},

	'bowStormBreakerUpgrades': {
		name: 'Bow: Storm Breaker',
		desc: 'Charge your bow beyond its limits to unleash a devastating storm of arrows with the Storm Breaker upgrade tree',
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Bow,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',

		top: {
			name: 'Evolve: Storm Breaker',
			desc: 'Bow: You can charge your bow an extra time after it is fully charged.  Gain additional {glossary.pierce} and {glossary.split} for each full charge',
			icon: 'upgrade-evolve-stormbreaker',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Bow],
			statBonuses: [
				[['Bow'], StatType.maxCharge, StatOperator.SUM, 100]
			],
			simpleApplyFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.pierce++
				bow.bonusesPerMaxCharge.splitting++

				player.setWeaponSkin('bow-stormbreaker')
			},
			simpleRemoveFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.pierce--
				bow.bonusesPerMaxCharge.splitting--

				player.setWeaponSkin('bow-regular')
			},

		},
		left: {
			name: 'Out for Blood',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			desc: 'Bow: Attacks cause {glossary.bleed}',
			icon: 'upgrade-out-for-blood',
			statBonuses: [
				[['Bow'], StatType.bleedChance, StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.Bow],
		},
		right: {
			name: 'Let the arrows hit the floor',
			desc: 'Bow: Gain another possible charge, and gain an additional {glossary.projectile_count} for each full charge',
			icon: 'upgrade-let-the-arrows-hit-the-floor',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.maxCharge, StatOperator.SUM, 100]
			],
			affectedEntities: [AffectedEntities.Bow],
			simpleApplyFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.projectiles++
			},
			simpleRemoveFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.projectiles--
			},
		},
		leftLeaf: {
			name: 'A Wind Named Fury', // this doesn't apply doom !
			desc: 'Bow: Gain two {glossary.split} count and deal moderately more {glossary.damage}',
			icon: 'upgrade-a-wind-named-doom',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.projectileSplitCount, StatOperator.SUM, 2],
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.30],
			],
			affectedEntities: [AffectedEntities.Bow],
			unlocks: ['bowStormBreakerFinalForm']
		},
		middleLeaf: {
			name: 'The Strength of the Storm',
			desc: 'Bow: Deal massively increased {glossary.damage} at the cost of some {glossary.charge_rate}',
			icon: 'upgrade-strength-of-the-storm',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.80],
				[['Bow'], StatType.chargeRate, StatOperator.SUM_THEN_MULTIPLY, -0.2],
			],
			affectedEntities: [AffectedEntities.Bow],
			unlocks: ['bowStormBreakerFinalForm']
		},
		rightLeaf: {
			name: 'Sky-Splitter Arrows', // this doesn't add split?!
			desc: 'Bow: Gain another possible charge, and {glossary.pierce} two more times for each full charge',
			icon: 'upgrade-sky-splitter-arrows',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Bow'], StatType.maxCharge, StatOperator.SUM, 100]
			],
			simpleApplyFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.pierce += 2
			},
			simpleRemoveFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.pierce -= 2
			},
			affectedEntities: [AffectedEntities.Bow],
			unlocks: ['bowStormBreakerFinalForm']
		},
	},

	'bowStormBreakerFinalForm': {
		shape: 'single',
		name: 'Storm Breaker: Final Form',
		desc: "Transform your Storm breaker into its final form, adding an additional charge that releases deadly shrapnel when your arrows explode",
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Final Hurricane',
			desc: 'Bow: Gain another possible charge, each full charge adds additional {glossary.damage}, and enemies that die to this weapon explode in shrapnel',
			icon: 'upgrade-stormbreaker-final-form',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Bow],
			binaryFlags: ['primary-weapon-emits-shrapnel'],
			statBonuses: [
				[['Bow'], StatType.maxCharge, StatOperator.SUM, 100]
			],
			simpleApplyFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.damagePercent += 0.2

				player.setWeaponSkin('bow-arrow-hurricane')
			},
			simpleRemoveFn(player, state) {
				const bow = player.primaryWeapon as ChargePrimaryWeapon
				bow.bonusesPerMaxCharge.damagePercent -= 0.2

				player.setWeaponSkin('bow-stormbreaker')
			},
		}
	},

	'wandUpgrades': {
		name: 'Wand Upgrades',
		desc: "",
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Wand,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Powerful Magic',
			desc: 'Wand: Greatly increase {glossary.damage} and gains three {glossary.ammo}',
			icon: 'upgrade-powerful-magic',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Wand'], StatType.baseDamage, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Wand'], StatType.maxAmmo, StatOperator.SUM, 3],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.SUM, 3]
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		left: {
			name: 'Charged Up',
			desc: 'Wand: Every 3rd attack is massively larger and deals triple {glossary.damage}',
			icon: 'upgrade-charged-up',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['wand-biggify-every-3rd-shot'],
			simpleApplyFn(player, state) {
				player.binaryFlagState['wand-biggify-every-3rd-shot'].attackCounter = 2 // make sure we lead with our big shot because it *looks cool*
			},
			affectedEntities: [AffectedEntities.Wand],
		},
		right: {
			name: 'Essence Loaded',
			desc: 'Wand: Gains six {glossary.ammo} and a slight amount of {glossary.cooldown_speed}, and gains one second of massively increased {glossary.attack_rate} and {glossary.charge_rate} on reload',
			icon: 'upgrade-essence-loaded',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['attack-rate-after-reload'],
			statBonuses: [
				[['Wand'], StatType.maxAmmo, StatOperator.SUM, 6],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.SUM, 6],
				[['Wand'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.12]
			],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.QuickReloadAttackRate,
					tags: [BuffTags.Buff],
					duration: 1000,
					lastsForever: false,
					startingStacks: 1,
					stackStyle: StackStyle.RefreshDuration,
					reapplyDuration: 1000,
					reapplyStacks: 0,
					showToClient: true,
					updateStacksFn(buff: Buff, oldStacks: number, newStacks: number) {
						const player = buff.appliedTo
						if (!buff.state.attackRateBonus) {
							if (isPlayer(player)) {
								buff.state.attackRateBonus = player.stats.addStatBonus('attackRate', StatOperator.SUM_THEN_MULTIPLY, 0.50 * newStacks)
								buff.state.chargeRateBonus = player.stats.addStatBonus('chargeRate', StatOperator.SUM_THEN_MULTIPLY, 0.50 * newStacks)
							} else {
								console.warn("Applying an upgrade to a non-player, you probably don't wanna do this!")
							}
						}
					},
					wearOffFn(buff: Buff) {
						const { attackRateBonus, chargeRateBonus } = buff.state
						attackRateBonus.remove()
						chargeRateBonus.remove()
					}
				}),
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		leftLeaf: {
			name: 'High Accuracy Shots',
			desc: 'Wand: Greatly increase {glossary.damage} and {glossary.attack_size}',
			icon: 'upgrade-high-accuracy-shots',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandMarkswitchEvolution'],
			statBonuses: [
				[['Wand'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.50],
				[['Wand'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 1.0],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		middleLeaf: {
			name: 'Quantum Ammo Chamber',
			desc: 'Wand: Moderately increase {glossary.damage}, slightly increase {glossary.projectile_speed}, and a portion of your {glossary.projectile_speed} is added as {glossary.damage}.',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandTacticalStrikeEvolution'],
			icon: 'upgrade-quantum-ammo-chamber',
			statBonuses: [
				[['Wand'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.30],
				[['Wand'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.2],
			],
			simpleApplyFn(player, state) {
				state.converter = {
					inputStatType: StatType.projectileSpeed,
					inputRatio: 0.0,
					inputMinReserve: 1.0,
					inputStatUnit: StatUnit.Percentage,
					inputFreeRatio: 0.333333,
					outputStatType: StatType.allDamageMult,
					outputRatio: 1.0,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
				}
				player.primaryWeapon.statList.addConverter(state.converter)
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter)
			},
			affectedEntities: [AffectedEntities.Wand],
		},
		rightLeaf: {
			name: 'Arcane Rain',
			desc: 'Wand: Moderately increase {glossary.attack_rate} and {glossary.reload}',
			icon: 'upgrade-arcane-rain',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandRapidFireEvolution'],
			statBonuses: [
				[['Wand'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['Wand'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.2],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
	},

	'wandTacticalStrikeEvolution': {
		name: 'Evolution: Tactical Strike Wand',
		desc: "Transform your wand into a precise instrument of death with the Tactical Strike upgrade tree",
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Wand,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Tactical Strike Wand',
			desc: 'Wand: Attacks fire in a 3-round {glossary.burst} and gain 6 {glossary.ammo}',
			icon: 'upgrade-3-round-burst',
			pools: PRIMARY_TOP_POOL,
			binaryFlags: ['3-round-burst'],
			statBonuses: [
				[['Wand'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, -0.733333],
				[['Wand'], StatType.maxAmmo, StatOperator.SUM, 6],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.SUM, 6]
			],
			affectedEntities: [AffectedEntities.Wand],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('wand-tactical-strike-01')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('wand-starter-01')
			},
		},
		left: {
			name: 'Poison Magic',
			desc: 'Wand: Shots {glossary.poison} enemies',
			icon: 'upgrade-poison-magic',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Wand'], StatType.poisonChance, StatOperator.SUM, 1],
				[['Wand'], StatType.poisonPotency, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		right: {
			name: 'Visceral Magic',
			desc: 'Wand: Shots inflict {glossary.bleed} on enemies',
			icon: 'upgrade-visceral-magic',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Wand'], StatType.bleedChance, StatOperator.SUM, 1],
				[['Wand'], StatType.bleedPotency, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		leftLeaf: {
			name: 'Noxious Leak',
			desc: 'Wand: Wand gains 6 {glossary.ammo}, striking a target 2 times creates a {glossary.poison} pool that grows with each hit on enemies within it.',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandTacticalStrikeFinalForm'],
			icon: 'upgrade-noxious-leak',
			binaryFlags: ['noxious-leak'],
			statBonuses: [
				[['Wand'], StatType.maxAmmo, StatOperator.SUM, 6],
				[['Wand'], StatType.baseDamage, StatOperator.SUM, 4],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.SUM, 6],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		middleLeaf: {
			name: 'Starburst',
			desc: 'Wand: Convert {glossary.projectile_count} to {glossary.split}',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandTacticalStrikeFinalForm'],
			icon: 'upgrade-starburst',
			statBonuses: [
				[['Wand'], StatType.projectileSplitCount, StatOperator.SUM, 3],
			],
			binaryFlags: ['auto-split', 'starburst'],
			simpleApplyFn(player, state) {
				player.binaryFlagState["auto-split"].autoSplitDistance = 700
				state.converter = {
					inputStatType: StatType.projectileCount,
					inputRatio: 1.0,
					inputMinReserve: 1,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.projectileSplitCount,
					outputRatio: 1.0,
					outputStatOperator: StatOperator.SUM,
					outputStatUnit: StatUnit.Number,
				}
				player.primaryWeapon.statList.addConverter(state.converter)
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter)
			},
			affectedEntities: [AffectedEntities.Wand],
		},
		rightLeaf: {
			name: 'Mark, Aim, Fire',
			desc: 'Wand: Enemies hit are affected by {glossary.doom}. All extra {glossary.projectile_count} is converted to {glossary.pierce}, and {glossary.split} is converted to greatly increased {glossary.doom} potency.',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandTacticalStrikeFinalForm'],
			icon: 'upgrade-mark-aim-fire',
			statBonuses: [
				[['Wand'], StatType.doomChance, StatOperator.SUM, 1],
				[['Wand'], StatType.doomPotency, StatOperator.SUM, 0.5]
			],
			simpleApplyFn(player, state) {
				state.converter1 = {
					inputStatType: StatType.projectileCount,
					inputRatio: 1.0,
					inputMinReserve: 1,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.attackPierceCount,
					outputRatio: 1.0,
					outputStatOperator: StatOperator.SUM,
					outputStatUnit: StatUnit.Number,
				}
				state.converter2 = {
					inputStatType: StatType.projectileSplitCount,
					inputRatio: 1.0,
					inputMinReserve: 0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.doomPotency,
					outputRatio: 0.25,
					outputStatOperator: StatOperator.SUM,
					outputStatUnit: StatUnit.Number,
				}
				player.primaryWeapon.statList.addConverter(state.converter1)
				player.primaryWeapon.statList.addConverter(state.converter2)
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter1)
				player.primaryWeapon.statList.removeConverter(state.converter2)
			},
			affectedEntities: [AffectedEntities.Wand],
		},
	},

	'wandTacticalStrikeFinalForm': {
		shape: 'single',
		name: 'Wand: Tactical Strike Final Form',
		desc: "Transform your Tactical Strike Wand into its final form, greatly increasing its attack rate and damage",
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Strategic Striker',
			desc: 'Wand: Greatly increase {glossary.attack_rate}, every 3 {glossary.ammo} grants a slight increase in {glossary.damage}',
			icon: 'upgrade-tactical-strike-final-form',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Wand'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25]
			],
			simpleApplyFn(player: Player, state) {
				state.converter = {
					inputStatType: StatType.maxAmmo,
					inputRatio: 0,
					inputMinReserve: 0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 1,
					outputStatType: StatType.allDamageMult,
					outputRatio: 0.04,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
				}
				player.primaryWeapon.statList.addConverter(state.converter)

				player.setWeaponSkin('wand-final-tactical-strike-01')
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter)

				player.setWeaponSkin('wand-tactical-strike-01')
			},
			affectedEntities: [AffectedEntities.Wand],
		}
	},

	'wandRapidFireEvolution': {
		name: 'Evolution: Rapid Fire Wand',
		desc: "Transform your wand into an instrument of destruction with the Rapid Fire upgrade tree",
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Wand,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Mana Sphere',
			desc: 'Wand: Gain double {glossary.ammo}, and all {glossary.chain} is converted to {glossary.pierce}',
			icon: 'upgrade-mana-sphere',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Wand'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 1.0],
				[['Wand'], StatType.maxAmmo, StatOperator.SUM_THEN_MULTIPLY, 1.0],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.SUM_THEN_MULTIPLY, 1.0],
			],
			simpleApplyFn(player, state) {
				state.converter = {
					inputStatType: StatType.projectileChainCount,
					inputRatio: 1.0,
					inputMinReserve: 0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.attackPierceCount,
					outputRatio: 1.0,
					outputStatOperator: StatOperator.SUM,
					outputStatUnit: StatUnit.Number,
				}
				player.primaryWeapon.statList.addConverter(state.converter)

				player.setWeaponSkin('wand-rapid-fire-01')
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter)

				player.setWeaponSkin('wand-starter-01')
			},
			affectedEntities: [AffectedEntities.Wand],
		},
		left: {
			name: 'Quickchange Artist',
			desc: 'Wand: Greatly increase {glossary.attack_rate} and slightly increase {glossary.reload}',
			icon: 'upgrade-quickchange-artist',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Wand'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Wand'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.1],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		right: {
			name: 'Pulverizing Arcana',
			desc: 'Wand: Gain one Pierce, and moderately increase {glossary.damage} and {glossary.knockback}',
			icon: 'upgrade-pulverizing-arcana',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Wand'], StatType.attackPierceCount, StatOperator.SUM, 1],
				[['Wand'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Wand'], StatType.attackKnockback, StatOperator.SUM, 250],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		leftLeaf: {
			name: 'Gatling Sorceries',
			desc: 'Wand: Your Wand becomes a rip-roaring gatling weapon. MASSIVELY increase {glossary.attack_rate} and {glossary.ammo}. Bonus {glossary.projectile_count} over 3 are converted to massive {glossary.damage}',
			unlocks: ['wandRapidFireFinalForm'],
			icon: 'upgrade-gatling-sorceries',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['gatling-gun'],
			statBonuses: [
				[['Wand'], StatType.maxAmmo, StatOperator.SUM_THEN_MULTIPLY, 3.0],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.SUM_THEN_MULTIPLY, 3.0],
				[['Wand'], StatType.reloadInterval, StatOperator.SUM_THEN_MULTIPLY, 0.85],
				[['Wand'], StatType.allDamageMult, StatOperator.MULTIPLY, -0.25],
				[['Wand'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 1.5],
			],
			simpleApplyFn(player, state) {
				state.converter = {
					inputStatType: StatType.projectileCount,
					inputRatio: 1.0,
					inputMinReserve: 3.0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 0,
					outputStatType: StatType.allDamageMult,
					outputRatio: 0.5,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
				}
				player.primaryWeapon.statList.addConverter(state.converter)
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter)
			},
			affectedEntities: [AffectedEntities.Wand],
		},
		middleLeaf: {
			name: 'Full Auto',
			desc: 'Wand: Greatly increase {glossary.damage} and {glossary.attack_rate}, and slightly increase {glossary.reload}',
			unlocks: ['wandRapidFireFinalForm'],
			icon: 'upgrade-full-auto',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Wand'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Wand'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				[['Wand'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.2],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		rightLeaf: {
			name: 'The Crowd Controller',
			desc: 'Wand: Massively increase {glossary.knockback}, {glossary.attack_size} and add a small chance to {glossary.stun}',
			unlocks: ['wandRapidFireFinalForm'],
			icon: 'upgrade-the-crowd-controller',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Wand'], StatType.attackKnockback, StatOperator.SUM, 300],
				[['Wand'], StatType.stunChance, StatOperator.SUM, 0.08],
				[['Wand'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 1.0],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
	},

	'wandRapidFireFinalForm': {
		shape: 'single',
		name: 'Wand: Rapid Fire Final Form',
		desc: "Transform your Rapid Fire Wand into its final form, greatly increasing its damage and ammo",
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Mana Blaster',
			desc: 'Wand: Moderately increase {glossary.ammo} and each {glossary.pierce} slightly increases damage',
			icon: 'upgrade-rapid-fire-final-form',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Wand'], StatType.maxAmmo, StatOperator.MULTIPLY, 0.25],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.MULTIPLY, 0.25],
			],
			simpleApplyFn(player: Player, state) {
				state.converter = {
					inputStatType: StatType.attackPierceCount,
					inputRatio: 0,
					inputMinReserve: 0,
					inputStatUnit: StatUnit.Number,
					inputFreeRatio: 1,
					outputStatType: StatType.allDamageMult,
					outputRatio: 0.1,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
				}
				player.primaryWeapon.statList.addConverter(state.converter)

				player.setWeaponSkin('wand-final-rapid-fire-01')
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter)

				player.setWeaponSkin('wand-rapid-fire-01')
			},
			affectedEntities: [AffectedEntities.Wand],
		}
	},

	'wandMarkswitchEvolution': {
		name: 'Evolution: Markswitch Wand',
		desc: "Transform your wand to harness the power of the elements with the Markswitch Wand upgrade tree.",
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Wand,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Markswitch Wand',
			desc: 'Wand: The first time your attack hits an enemy, gain a small {glossary.damage} bonus, up to doubling damage. Missing removes this bonus.',
			icon: 'upgrade-careful shooter',
			pools: PRIMARY_TOP_POOL,
			binaryFlags: ['careful-shooter'],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.CarefulShooter,
					tags: [BuffTags.Upgrade],
					duration: 0,
					lastsForever: true,
					startingStacks: 1,
					stackStyle: StackStyle.RefreshDuration,
					reapplyDuration: 0,
					reapplyStacks: 1,
					showToClient: true,
					updateStacksFn(buff: Buff, oldStacks: number, newStacks: number) {
						const player = buff.appliedTo
						const bonus = Math.min(0.08 * newStacks, 1)

						if (isPlayer(player)) {
							if (!buff.state.damageBonus) {
								buff.state.damageBonus = player.stats.addStatBonus('allDamageMult', StatOperator.SUM_THEN_MULTIPLY, bonus)
							} else {
								buff.state.damageBonus.update(bonus)
							}
						}
					},
					wearOffFn(buff: Buff) {
						const { damageBonus } = buff.state
						damageBonus.remove()
					}
				}),
			],
			affectedEntities: [AffectedEntities.Wand],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('wand-markswitch-01')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('wand-starter-01')
			},
		},
		left: {
			name: 'High Density Energy',
			desc: 'Wand: Reduce {glossary.ammo} by 3, but {glossary.pierce} two additional enemies and gain a massive increase to {glossary.damage} and {glossary.knockback}.',
			icon: 'upgrade-high-density-energy',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Wand'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 1],
				[['Wand'], StatType.attackKnockback, StatOperator.SUM_THEN_MULTIPLY, 4],
				[['Wand'], StatType.attackPierceCount, StatOperator.SUM, 2],
				[['Wand'], StatType.maxAmmo, StatOperator.SUM, -3],
				[['Wand'], StatType.reloadAmmoIncrement, StatOperator.SUM, -3],
			],
			affectedEntities: [AffectedEntities.Wand],
		},
		right: {
			name: 'Flame Fast',
			desc: 'Wand: Every third shot will {glossary.ignite} enemies',
			icon: 'upgrade-flame-fast',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['flame-fast'],
			affectedEntities: [AffectedEntities.Wand],
		},
		leftLeaf: {
			name: 'Lightning Always Strikes Twice',
			desc: 'Wand: Every second hit (or kill) on a specific enemy summons an additional {glossary.lightning_strike} on them, and every seventh hit with ANY weapon summons a {glossary.lightning_strike} on a random enemy',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandMarkswitchFinalForm'],
			icon: 'upgrade-lightning-always-strikes-twice',
			binaryFlags: ['lightning-strikes-twice'],
			affectedEntities: [AffectedEntities.Wand],
		},
		middleLeaf: {
			name: 'Blood-Soaked Rounds',
			desc: 'Wand: Shots cause enemies to {glossary.bleed}, and {glossary.bleed} deals more damage. A moderate chance to recover 1 {glossary.ammo} on kill, guaranteed if Careful Shooter has full stacks',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandMarkswitchFinalForm'],
			icon: 'upgrade-blood-soaked-bullets',
			statBonuses: [
				[['Wand'], StatType.bleedChance, StatOperator.SUM, 1],
				[['Player'], StatType.bleedPotency, StatOperator.SUM_THEN_MULTIPLY, 0.6],
			],
			binaryFlags: ['blood-soaked-rounds'],
			affectedEntities: [AffectedEntities.Wand],
		},
		rightLeaf: {
			name: 'High-Precision Torch',
			desc: 'Wand: Now every shot will {glossary.ignite} enemies and ALL sources of {glossary.ignite} deal massively increased {glossary.damage}.',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			unlocks: ['wandMarkswitchFinalForm'],
			icon: 'upgrade-high-precision-torch',
			binaryFlags: ['high-precision-torch'],
			statBonuses: [
				[['Player'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 0.5]
			],
			affectedEntities: [AffectedEntities.Wand],
		},
	},

	'wandMarkswitchFinalForm': {
		shape: 'single',
		desc: "Transform your Markswitch Wand into its final form, it grows more powerful with every consecutive hit.",
		name: 'Wand: Markswitch Final Form',
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Markswitch Final Form',
			desc: 'Wand: The first time your attack hits an enemy, gain a moderate {glossary.damage} bonus, up to triple bonus damage. Missing removes the bonus. At max stacks, gain massively increased {glossary.reload} speed.',
			icon: 'upgrade-markswitch-final-form',
			pools: PRIMARY_TOP_POOL,
			binaryFlags: ['markswitch-final-form'],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.MarkswitchFinalForm,
					tags: [BuffTags.Upgrade],
					duration: 0,
					lastsForever: true,
					startingStacks: 1,
					stackStyle: StackStyle.RefreshDuration,
					reapplyDuration: 0,
					reapplyStacks: 1,
					showToClient: true,
					updateStacksFn(buff: Buff, oldStacks: number, newStacks: number) {
						const player = buff.appliedTo
						const damageBonus = Math.min(0.16 * newStacks, 2)
						if (isPlayer(player)) {
							if (!buff.state.damageBonus) {
								buff.state.damageBonus = player.stats.addStatBonus('allDamageMult', StatOperator.SUM_THEN_MULTIPLY, damageBonus)
							} else {
								buff.state.damageBonus.update(damageBonus)
							}
							if (damageBonus >= 1.5) {
								const reloadSpeedBonus = -1
								if (!buff.state.reloadInterval) {
									buff.state.reloadInterval = player.primaryWeapon.statList.addStatBonus('reloadInterval', StatOperator.MULTIPLY, reloadSpeedBonus)
								}
							}
						}
					},
					wearOffFn(buff: Buff) {
						const { damageBonus, reloadInterval } = buff.state
						damageBonus.remove()
						if (reloadInterval !== undefined) {
							reloadInterval.remove()
						}
					}
				})
			],
			affectedEntities: [AffectedEntities.Wand],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('wand-final-markswitch-01')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('wand-markswitch-01')
			},
		}
	},

	'boomerangUpgrades': {
		name: 'Boomerang Upgrades',
		desc: "",
		shape: `tree`,
		requireWeapon: AllWeaponTypes.Boomerang,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: "Hard Hitting 'Rangs",
			desc: 'Boomerang: Adds to the base {glossary.damage}',
			icon: 'upgrade-hard-hitting-rangs',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Boomerang'], StatType.baseDamage, StatOperator.SUM, 5],
			],
			affectedEntities: [AffectedEntities.Boomerang],
		},
		left: {
			name: 'Speed Boomerang',
			desc: 'Boomerang: Moderately increased {glossary.projectile_speed} and {glossary.damage}.',
			icon: 'upgrade-speed-boomerang',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Boomerang'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.4]
			],
			affectedEntities: [AffectedEntities.Boomerang],
		},
		right: {
			name: 'Bounce-a-rang',
			desc: 'Boomerang: Your boomerangs {glossary.knockback} harder, have a chance to {glossary.stun}, and deal greatly increased {glossary.damage}.',
			icon: 'upgrade-bounce-a-rang',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				[['Boomerang'], StatType.stunChance, StatOperator.SUM, 0.15],
				[['Boomerang'], StatType.attackKnockback, StatOperator.SUM, 150],
			],
			affectedEntities: [AffectedEntities.Boomerang],
		},
		leftLeaf: {
			name: 'Acceleration',
			desc: 'Boomerang: When you hit an enemy, your boomerangs gains {glossary.projectile_speed}, and you gain {glossary.movement_speed}.',
			icon: 'upgrade-acceleration',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['accelerate-projectiles'],
			affectedEntities: [AffectedEntities.Boomerang],
			unlocks: ['chakramUpgrades']
		},
		middleLeaf: {
			name: 'Tempered Blades',
			desc: 'Boomerang: Add a large chance to {glossary.bleed} enemies they strike',
			icon: 'upgrade-venomous',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.bleedChance, StatOperator.SUM, 0.5],
			],
			affectedEntities: [AffectedEntities.Boomerang],
			unlocks: ['outbackUpgrades']
		},
		rightLeaf: {
			name: 'Heavy-Hitting',
			desc: 'Boomerang: Greatly increased {glossary.knockback} and {glossary.damage}.',
			icon: 'upgrade-heavy-hitting',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				[['Boomerang'], StatType.attackKnockback, StatOperator.SUM, 200],
			],
			affectedEntities: [AffectedEntities.Boomerang],
			unlocks: ['ricochetRangUpgrades'],
		},
	},

	'chakramUpgrades': {
		name: "Boomerang: Chakram Upgrades",
		desc: "Transform your boomerang into a Chakram", // TODO: better description
		shape: 'tree',
		requireWeapon: AllWeaponTypes.Boomerang,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Pain Fountain',
			desc: 'Boomerang: Gain one {glossary.ammo}, their firing path alternates left and right',
			pools: PRIMARY_TOP_POOL,
			binaryFlags: ['chakram-autofire'],
			// TODO placeholder icon
			icon: 'upgrade-pain-fountain',
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.maxAmmo, StatOperator.SUM, 1]
			],
			simpleApplyFn(player) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.trajectory = TrajectoryModPresets.get(TrajectoryModPreset.ChakramBoomerang)
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_CHAKRAM
				player.setWeaponSkin('boomerang-chakram-01')

			},
			simpleRemoveFn(player) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.trajectory = boomer.getTrajectory()
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_BOOMERANG
				player.setWeaponSkin('boomerang-starter-01')
			},
		},
		left: {
			name: 'Burn-a-Rang',
			desc: 'Boomerang: Your boomerangs catch fire, moderately increasing {glossary.projectile_speed}, and applying {glossary.ignite} to anything they hit',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.igniteChance, StatOperator.SUM, 1],
				[['Boomerang'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.2],
			],
			icon: 'upgrade-burn-a-rang',

			simpleApplyFn(player) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_BOOMERANG_FLAME

			},
			simpleRemoveFn(player) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_CHAKRAM
			},
		},
		right: {
			name: 'Death Storm',
			desc: 'Boomerang: Gain two {glossary.ammo} and a chance to {glossary.stun} enemies',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.maxAmmo, StatOperator.SUM, 2],
				[['Boomerang'], StatType.stunChance, StatOperator.SUM, 0.1]
			],
			icon: 'upgrade-death-storm',
		},
		leftLeaf: {
			name: 'Firestorm',
			desc: 'Boomerang: Enemies that have {glossary.ignite} will explode on death!',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			binaryFlags: ['ignite-explodes'],
			unlocks: ['chakramFinalForm'],
			icon: 'upgrade-fire-storm',
		},
		middleLeaf: {
			name: 'Return to Sender', // Name change for legal reasons :D
			desc: 'Boomerang: Massively increase {glossary.attack_size}. Boomerangs move much slower and no longer return to you. If you catch them, they grant a stacking bonus to {glossary.damage} and {glossary.xp_drop_rate}, stacking up to 20 times.',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			binaryFlags: ['catch-em-all'],
			unlocks: ['chakramFinalForm'],
			icon: 'upgrade-gotta-catch-em-all',
			statBonuses: [
				[['Boomerang'], StatType.projectileSpeed, StatOperator.MULTIPLY, -0.4],
				[['Boomerang'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.75],
			],
		},
		rightLeaf: {
			name: 'Fatal Speed',
			desc: 'Boomerang: The faster your boomerangs travel, the more {glossary.damage} it deals',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.2]
			],
			simpleApplyFn(player, state) {
				// this deviates from spec, which caps the gains at 120, but converters can't currently do that
				// so we're leaving it uncapped for the moment
				const speedToDamageConverter: StatConverter = {
					inputStatType: StatType.projectileSpeed,
					inputStatUnit: StatUnit.Percentage,
					inputRatio: 0,
					inputFreeRatio: 1,
					inputMinReserve: 0.666666, // this is to offset the boomerang baseline proj speed penalty of -33%
					outputStatType: StatType.allDamageMult,
					outputStatUnit: StatUnit.Percentage,
					outputRatio: 0.5,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY
				}
				state.converter = speedToDamageConverter
				player.primaryWeapon.statList.addConverter(state.converter)
			},
			simpleRemoveFn(player, state) {
				player.primaryWeapon.statList.removeConverter(state.converter)
				state.converter = null
			},
			icon: 'upgrade-blazing-speed',
			unlocks: ['chakramFinalForm']
		}
	},

	'chakramFinalForm': {
		shape: 'single',
		name: 'Boomerang: Final Form Chakram',
		desc: "Transform your Chakram into its final form, inflicting bleed on hit",
		upgradeEvolutionTier: 'tier3',
		isUnlock: true,
		top: {
			name: 'Evolve: Chakram',
			desc: 'Boomerang: Transform your boomerangs into Chakrams and inflict a powerful {glossary.bleed} on hit',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.bleedChance, StatOperator.SUM, 1],
				[['Boomerang'], StatType.bleedPotency, StatOperator.SUM_THEN_MULTIPLY, 0.5]
			],
			icon: 'upgrade-final-form-chakram',
		}
	},

	'outbackUpgrades': {
		name: 'Boomerang Outback Upgrades',
		shape: `tree`,
		desc: "Transform your boomerang with the Outback upgrade tree", // TODO: Better description
		requireWeapon: AllWeaponTypes.Boomerang,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: "Evolve: The Out-and-Back",
			desc: 'Boomerang: Boomerangs will now swirl in place at the end of their path, dealing damage multiple times over a short time. And add one additional projectile.',
			icon: 'upgrade-out-and-back',
			pools: PRIMARY_TOP_POOL,
			binaryFlags: ['boomerang-shoot-all-as-spread', 'boomerang-recall-with-click'],
			simpleApplyFn(player: Player, state) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.trajectory = TrajectoryModPresets.get(TrajectoryModPreset.OutbackBoomerang)
				player.setWeaponSkin('boomerang-outback-01')
				// Is this the matching PFX for the outback boomerang?? I don't see anytything else Austrailia-themed so I'm putting it in as a placeholder at least
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_OUTBACK
				boomer.removeCountToAmmoConverter()
				state.clamp = {
					statType: StatType.maxAmmo,
					clampMax: 1,
				}
				player.primaryWeapon.statList.addClamp(state.clamp)
			},
			simpleRemoveFn(player: Player, state) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.trajectory = boomer.getTrajectory()
				player.setWeaponSkin('boomerang-starter-01')
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_BOOMERANG
				boomer.addCountToAmmoConverter()
				player.primaryWeapon.statList.removeClamp(state.clamp)
				state.clamp = null
			},
			statBonuses: [
				[['Boomerang'], StatType.projectileSpreadAngle, StatOperator.SUM, 40],
				[['Boomerang'], StatType.attackRate, StatOperator.MULTIPLY, -0.5],
				[['Boomerang'], StatType.projectileCount, StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.Boomerang],
		},
		left: {
			name: 'Latent Destruction',
			desc: 'Boomerang: Greatly increase {glossary.attack_rate} and {glossary.skill_duration}',
			icon: 'upgrade-latent-destruction',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Boomerang'], StatType.skillDuration, StatOperator.SUM, 0.5]
			],
			affectedEntities: [AffectedEntities.Boomerang],
		},
		right: {
			name: 'Angular Momentum',
			desc: "Boomerang: Greatly increase your boomerangs' swirl radius and {glossary.damage}",
			icon: 'upgrade-angular-momentum',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.40],
			],
			simpleApplyFn(player, state) {
				//see OUTBACK_ORBIT_RADIUS_UPGRADE_AMOUNT for the amount (boomerang-weapon.ts)
				const boomer = player.primaryWeapon as Boomerang
				boomer.increaseOrbitSpeed = true
			},
			simpleRemoveFn(player, state) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.increaseOrbitSpeed = false
			},
			affectedEntities: [AffectedEntities.Boomerang],
		},
		leftLeaf: {
			name: 'Doom Mark',
			desc: 'Boomerang: Enemies hit gain a stack of {glossary.doom}',
			icon: 'upgrade-doom-mark',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.doomChance, StatOperator.SUM, 1]
			],
			affectedEntities: [AffectedEntities.Boomerang],
			unlocks: ['outbackFinalForm']
		},
		middleLeaf: {
			name: 'Damage Vault',
			desc: 'Boomerang: Gain {glossary.damage} for each enemy struck',
			icon: 'upgrade-damage-vault',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			binaryFlags: ['ramp-primary-damage'],
			affectedEntities: [AffectedEntities.Boomerang],
			unlocks: ['outbackFinalForm']
		},
		rightLeaf: {
			name: 'Whirlwind',
			desc: 'Boomerang: Greatly increase {glossary.damage} and {glossary.knockback} on enemies is inverted',
			icon: 'upgrade-whirlwind',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.4],
				[['Boomerang'], StatType.attackKnockback, StatOperator.MULTIPLY, -2],
			],
			affectedEntities: [AffectedEntities.Boomerang],
			unlocks: ['outbackFinalForm']
		},
	},
	'outbackFinalForm': {
		shape: 'single',
		name: 'Outback Boomerang: Final Form',
		desc: 'Transform your Outback Boomerang into its final form, increasing damage and adding a small boomerang that orbits your character',
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Outback Ringer',
			desc: 'Boomerang: Gain greatly increased {glossary.attack_size} and two half-sized boomerangs now orbit your character.',
			icon: 'upgrade-boomerang-final-form',
			pools: PRIMARY_TOP_POOL,
			statBonuses: [
				[['Boomerang'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.8]
			],
			simpleApplyFn(player: Player, state) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.startOrbitBoomerang()
			},
			simpleRemoveFn(player, state) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.stopOrbitBoomerang()
			},
			affectedEntities: [AffectedEntities.Boomerang],
		}
	},

	'ricochetRangUpgrades': {
		name: "Boomerang: Ricochet-Rang Upgrades",
		desc: "Transform your boomerang into a ricocheting racket", // TODO: better description
		shape: 'tree',
		requireWeapon: AllWeaponTypes.Boomerang,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Ricochet',
			desc: 'Boomerang: Gain one {glossary.ammo}, four {glossary.chain} and cause your Boomerang to ricochet between targets but deal moderately less {glossary.damage}. All your {glossary.pierce} and {glossary.split} are converted to {glossary.chain}',
			pools: PRIMARY_TOP_POOL,
			binaryFlags: ['boomerang-chaining'],
			// TODO placeholder icon
			icon: 'upgrade-evolve-ricochet-rang',
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.maxAmmo, StatOperator.SUM, 1],
				[['Boomerang'], StatType.projectileChainCount, StatOperator.SUM, 4],
				[['Boomerang'], StatType.allDamageMult, StatOperator.MULTIPLY, -0.333333],
			],
			simpleApplyFn(player, state) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_RICOCHETRANG_01
				player.setWeaponSkin('boomerang-ricochetrang-01')
				// boomer.trajectory = null

				const converter1: StatConverter = {
					inputStatType: StatType.attackPierceCount,
					inputStatUnit: StatUnit.Number,
					inputRatio: 1,
					inputFreeRatio: 0,
					inputMinReserve: 0,
					outputStatType: StatType.projectileChainCount,
					outputStatUnit: StatUnit.Number,
					outputRatio: 1,
					outputStatOperator: StatOperator.SUM
				}
				state.converter1 = converter1
				player.primaryWeapon.statList.addConverter(state.converter1)

				const converter2: StatConverter = {
					inputStatType: StatType.projectileSplitCount,
					inputStatUnit: StatUnit.Number,
					inputRatio: 1,
					inputFreeRatio: 0,
					inputMinReserve: 0,
					outputStatType: StatType.projectileChainCount,
					outputStatUnit: StatUnit.Number,
					outputRatio: 1,
					outputStatOperator: StatOperator.SUM
				}
				state.converter2 = converter2
				player.primaryWeapon.statList.addConverter(state.converter2)
			},
			simpleRemoveFn(player, state) {
				const boomer = player.primaryWeapon as Boomerang
				boomer.projectileEffectType = ParticleEffectType.PROJECTILE_RICOCHETRANG_02
				player.setWeaponSkin('boomerang-ricochetrang-02')
				// boomer.trajectory = boomer.getTrajectory()

				player.primaryWeapon.statList.removeConverter(state.converter1)
				player.primaryWeapon.statList.removeConverter(state.converter2)
				state.converter1 = null
				state.converter2 = null
			},
		},
		left: {
			name: 'Overdrive',
			desc: 'Boomerang: Gain three {glossary.chain} and moderately increase {glossary.damage} and {glossary.projectile_speed}',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.projectileChainCount, StatOperator.SUM, 3],
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.2],
				[['Boomerang'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.4],
			],
			// TODO placeholder icon
			icon: 'upgrade-overdrive',
		},
		right: {
			name: 'Bounce House',
			desc: 'Boomerang: Gain one {glossary.ammo}, two {glossary.chain} and moderately increase {glossary.damage} and {glossary.knockback}',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			statBonuses: [
				[['Boomerang'], StatType.maxAmmo, StatOperator.SUM, 1],
				[['Boomerang'], StatType.projectileChainCount, StatOperator.SUM, 2],
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.2],
				[['Boomerang'], StatType.attackKnockback, StatOperator.SUM, 150],
			],
			// TODO placeholder icon
			icon: 'upgrade-bounce-house',
		},
		leftLeaf: {
			name: 'Arc Reactor',
			desc: 'Boomerang: Every hit summons a {glossary.lightning_strike} on a nearby enemy, and your {glossary.chain} distance is doubled',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			binaryFlags: ['boomerang-lightning-strike-on-hit'],
			unlocks: ['ricochetRangFinalForm'],
			// TODO placeholder icon
			icon: 'upgrade-arc-reactor',
			statBonuses: [
				[['Boomerang'], StatType.projectileChainDistanceMultiplier, StatOperator.SUM_THEN_MULTIPLY, 1.0],
			],
			simpleApplyFn(player: Player, state) {
				if (!LightningStrike.pool) {
					LightningStrike.pool = new ObjectPoolTyped<LightningStrike, LightningStrikeParams>(() => new LightningStrike(), {}, 8, 2)
				}
			},
		},
		middleLeaf: {
			name: 'Hard Knocks',
			desc: 'Boomerang: Massively increase {glossary.damage}, {glossary.knockback} and your Boomerangs have a chance to {glossary.stun} enemies',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			unlocks: ['ricochetRangFinalForm'],
			// TODO placeholder icon
			icon: 'upgrade-hard-knocks',
			statBonuses: [
				[['Boomerang'], StatType.baseDamage, StatOperator.SUM, 3],
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.6],
				[['Boomerang'], StatType.attackKnockback, StatOperator.SUM, 300],
				[['Boomerang'], StatType.stunChance, StatOperator.SUM, 0.25],
			],
		},
		rightLeaf: {
			name: 'Razorang',
			desc: 'Boomerang: Gain one {glossary.chain} and every hit releases shrapnel',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			// TODO placeholder icon
			icon: 'upgrade-razorang',
			unlocks: ['ricochetRangFinalForm'],
			binaryFlags: ['primary-weapon-emits-shrapnel'],
			statBonuses: [
				[['Boomerang'], StatType.projectileChainCount, StatOperator.SUM, 1],
			],
		}
	},

	'ricochetRangFinalForm': {
		shape: 'single',
		name: 'Boomerang: Ricochet-Rang',
		desc: "Transform your Ricochet-Rang into its final form",
		upgradeEvolutionTier: 'tier3',
		isUnlock: true,
		top: {
			name: 'Evolve: Ricochet-Rang',
			desc: 'Boomerang: Gain one {glossary.ammo}, one {glossary.chain}, and moderately increase {glossary.damage} and {glossary.projectile_speed}',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Boomerang],
			// TODO placeholder icon
			icon: 'upgrade-ricochet-rang-final-form',
			statBonuses: [
				[['Boomerang'], StatType.maxAmmo, StatOperator.SUM, 1],
				[['Boomerang'], StatType.projectileChainCount, StatOperator.SUM, 1],
				[['Boomerang'], StatType.projectileSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.2],
				[['Boomerang'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.35],
			],
		}
	},

	'spearBase': {
		shape: 'tree',
		name: 'Spear Base Upgrades',
		desc: '',
		requireWeapon: AllWeaponTypes.Spear,
		upgradeEvolutionTier: 'tier1',
		top: {
			name: 'Better Grips',
			desc: 'Spear: Greatly increase {glossary.attack_rate}, slightly increase {glossary.damage} and {glossary.attack_size}.',
			icon: 'upgrade-better-grips',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Spear'], StatType.baseDamage, StatOperator.SUM, 5],
				[['Spear'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.15],
			]
		},
		left: {
			name: 'Stunning Strikes',
			desc: 'Spear: Adds a small chance to {glossary.stun} enemies',
			icon: 'upgrade-stunning-strikes',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.stunChance, StatOperator.SUM, 0.3],
			]
		},
		right: {
			name: 'Slicing and Dicing',
			desc: 'Spear: Enemies hit {glossary.bleed} for a portion of your {glossary.damage}',
			icon: 'upgrades-slicing-and-dicing',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.bleedChance, StatOperator.SUM, 1],
			]
		},
		leftLeaf: {
			name: 'Whirling Dervish Style',
			desc: 'Spear: Moderately increase {glossary.attack_rate}, and {glossary.damage}, but decrease {glossary.attack_size}',
			icon: 'upgrade-whirling-dervish-style',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.35],
				[['Spear'], StatType.baseDamage, StatOperator.SUM, 8],
				[['Spear'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, -0.2],
			],
			unlocks: ['spearNaginata']
		},
		middleLeaf: {
			name: 'Stalwart Soldier Style',
			desc: 'Spear: Slightly Increase {glossary.attack_size}, greatly increase {glossary.damage}, but slightly decrease {glossary.attack_rate}',
			icon: 'upgrade-stalwart-soldier-style',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.2],
				[['Spear'], StatType.baseDamage, StatOperator.SUM, 22],
				[['Spear'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, -0.15],
			],
			unlocks: ['spearGrandPike']
		},
		rightLeaf: {
			name: 'Praying Mantis Style',
			desc: 'Spear: Increase {glossary.knockback}, {glossary.attack_rate}, {glossary.bleed} damage, but slightly decrease {glossary.damage}',
			icon: 'upgrade-praying-mantis-style',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.attackKnockback, StatOperator.SUM, 300],
				[['Spear'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['Spear'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, -0.1],
				[['Spear'], StatType.bleedPotency, StatOperator.SUM, 0.25],
			],
			unlocks: ['spearHolySpear']
		}
	},

	'spearNaginata': {
		shape: 'tree',
		name: 'Spear Evolution Naginata Upgrades',
		desc: 'Transform your spear with the Naginata Upgrade tree.', // TODO: Better description
		requireWeapon: AllWeaponTypes.Spear,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',

		top: {
			name: 'Evolve: Naginata',
			desc: 'Spear: Your attack is now a cone, and you move with a small leap every time you attack',
			icon: 'upgrade-evolve-naginata',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Spear],
			simpleApplyFn(player: Player) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.hopOnAttack = true
				spear.changeColliderToCone()

				player.setWeaponSkin('spear-naginata-01')
			},
			simpleRemoveFn(player: Player) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.hopOnAttack = false

				player.setWeaponSkin('spear-starter-01')
			},
		},
		left: {
			name: 'Double Flurry',
			desc: 'Spear: Changes your attack to quickly strike twice. The second strike deals less damage',
			icon: 'upgrade-double-flurry',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.setComboMode(SpearComboMode.Double)
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.setComboMode(SpearComboMode.Single)
			},
		},
		right: {
			name: 'Explosive Rush',
			desc: 'Spear: Deal damage in a small area around your character as you leap',
			icon: 'upgrade-explosive-rush',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.aoeOnHop = true
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.aoeOnHop = false
			},
		},
		leftLeaf: {
			name: 'Whirlwind Blade',
			desc: 'Spear: Change your attack to quickly strike three times. Your attacks deal progressively less damage',
			icon: 'upgrade-whirlwind-blade',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.setComboMode(SpearComboMode.Triple)
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.setComboMode(SpearComboMode.Single) // this is only called from debug tools, and right after it all the upgrades are applied again; so if we should be double it will be set right away anyway
			},
			unlocks: ['spearNaginataFinalForm']
		},
		middleLeaf: {
			name: 'Naginata Mastery',
			desc: 'Spear: Moderately increase {glossary.attack_rate} and {glossary.damage}',
			icon: 'upgrade-naginata-mastery',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['Spear'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.4],
			],
			unlocks: ['spearNaginataFinalForm']
		},
		rightLeaf: {
			name: 'Booming Steps',
			desc: 'Spear: You hop twice, and deal damage in the area where you land.',
			icon: 'upgrade-booming-steps',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.hopTwice = true
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.hopTwice = false
			},
			unlocks: ['spearNaginataFinalForm']
		}
	},
	'spearNaginataFinalForm': {
		shape: 'single',
		name: 'Naginata: Final Form',
		desc: 'Transform your Naginata into its final form, greatly increasing damage and attack size, and igniting enemies.',
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Dragon Naginata', // can't change this name without change to unlock validator on griddle
			desc: 'Spear: Greatly increase {glossary.damage}, {glossary.ignite} enemies, and moderately increase {glossary.attack_size}',
			icon: 'upgrade-evolve-naginata',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Spear'], StatType.baseDamage, StatOperator.SUM, 16],
				[['Spear'], StatType.igniteChance, StatOperator.SUM, 1],
			],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('spear-dragon-naginata-01')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('spear-naginata-01')
			},
		}
	},

	'spearHolySpear': {
		shape: 'tree',
		name: 'Spear Evolution Holy Spear Upgrades',
		desc: 'Upgrade your spear even further',
		requireWeapon: AllWeaponTypes.Spear,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Holy Spear',
			desc: 'Spear: Adds an explosion at the end of your spear.',
			icon: 'upgrade-evolve-holy-spear',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Spear],
			binaryFlags: ['spear-holy-spear'],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('spear-holy-spear-01')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('spear-starter-01')
			},
		},
		left: {
			name: 'Holy Light',
			desc: 'Spear: Applies holy light to enemies within the Holy Spears explosion, dealing more damage',
			icon: 'upgrade-holy-light',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			binaryFlags: ['spear-holy-light'],
		},
		right: {
			name: 'Righteous Fervor',
			desc: 'Spear: Holy Spear explosion is now larger and moderately increase attack rate',
			icon: 'upgrade-righteous-fervor',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.2],
			],
			binaryFlags: ['spear-righteous-fervor'],
		},
		leftLeaf: {
			name: 'In The Eye of Mercy',
			desc: 'Spear: Enemies that die with Holy Light spread it to nearby enemies',
			icon: 'upgrade-in-the-eye-of-mercy',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			binaryFlags: ['spear-eye-of-mercy'],
			unlocks: ['spearHolySpearFinalForm']
		},
		middleLeaf: {
			name: 'Spear Mastery',
			desc: 'Spear: Greatly Increase {glossary.knockback} and moderately increase {glossary.damage}',
			icon: 'upgrade-naginata-mastery',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Spear'], StatType.attackKnockback, StatOperator.SUM, 500],
			],
			unlocks: ['spearHolySpearFinalForm']
		},
		rightLeaf: {
			name: 'Divine Punishment',
			desc: 'Spear: Create a second explosion at the tip of your weapon.',
			icon: 'upgrade-divine-punishment',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			binaryFlags: ['spear-divine-punishment'],
			unlocks: ['spearHolySpearFinalForm']
		}
	},
	'spearHolySpearFinalForm': {
		shape: 'single',
		name: 'Holy Spear: Final Form',
		desc: 'The final evolution of your Holy Spear',
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: "Saint's Spear", // can't change this name without change to unlock validator on griddle
			desc: 'Spear: Moderately Increase {glossary.damage}. Enemies have a chance to explode when they die, dealing a portion of their health as damage to nearby enemies.',
			icon: 'upgrade-holy-spear-final-form',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Spear],
			binaryFlags: ['spear-saints-spear'],
			statBonuses: [
				[['Spear'], StatType.baseDamage, StatOperator.SUM, 10],
				[['Spear'], StatType.saintsSpearChance, StatOperator.SUM, 0.2],
			],
			simpleApplyFn(player, state) {
				player.setWeaponSkin('spear-saints-spear-01')
			},
			simpleRemoveFn(player, state) {
				player.setWeaponSkin('spear-holy-spear-01')
			},
		}
	},
	'spearGrandPike': {
		shape: 'tree',
		name: 'Spear Evolution Grand Pike Upgrades',
		desc: 'Upgrade your spear even further',
		requireWeapon: AllWeaponTypes.Spear,
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',

		top: {
			name: 'Evolve: Grand Pike', // can't change this name without change to unlock validator on griddle
			desc: 'Spear: Your spear is longer, and deals massively increased {glossary.damage}',
			icon: 'grand-pike-01',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 1]
			],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.changeColliderLengthToGrandPike()

				player.setWeaponSkin('spear-grand-pike-01')
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.changeColliderLengthToNormal()

				player.setWeaponSkin('spear-starter-01')
			},
		},
		left: {
			name: 'Sweep Aside',
			desc: 'Spear: Your attacks shove enemies aside and apply {glossary.chill}, slowing them',
			icon: 'upgrade-sweep-aside',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			statBonuses: [
				[['Spear'], StatType.chillChance, StatOperator.SUM, 1],
				[['Spear'], StatType.attackKnockback, StatOperator.SUM, 200],
			],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.setKnockAside(true)
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.setKnockAside(false)
			},
		},
		right: {
			name: 'Piercing Force',
			desc: 'Spear: Hitting an enemy produces a small blood-projectile that deals a portion of your damage',
			icon: 'upgrade-piercing-force',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.bloodProjectilesOnHit = true
				spear.setGrandPikeBloodProjectileDamageScale(0.4)
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.bloodProjectilesOnHit = false
			},
		},
		leftLeaf: {
			name: 'Pike Shockwave',
			desc: 'Spear: A large after-shock follows your attacks, knocking aside and slowing enemies, but dealing no damage.',
			icon: 'upgrade-pike-shockwave',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			unlocks: ['spearGrandPikeFinalForm'],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.useGrandPikeAfterShock = true
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.useGrandPikeAfterShock = false
			},
		},
		middleLeaf: {
			name: 'Pike Master',
			desc: 'Spear: Greatly increase your {glossary.attack_rate} and moderately increase {glossary.attack_size}',
			icon: 'upgrade-pike-master',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			unlocks: ['spearGrandPikeFinalForm'],
			statBonuses: [
				[['Spear'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.3],
				[['Spear'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.25],
			],
		},
		rightLeaf: {
			name: 'Extreme Force',
			desc: 'Spear: Your blood-projectiles deal more damage and gain one {glossary.pierce}',
			icon: 'upgrade-extreme-force',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Spear],
			unlocks: ['spearGrandPikeFinalForm'],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				state.pierceBonus = spear.grandPikeBloodProjectileStatList.addStatBonus(StatType.attackPierceCount, StatOperator.SUM, 1)
				spear.setGrandPikeBloodProjectileDamageScale(0.6)
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.bloodProjectilesOnHit = false
				state.pierceBonus.remove()
				spear.setGrandPikeBloodProjectileDamageScale(0.4)
			},
		}
	},
	'spearGrandPikeFinalForm': {
		shape: 'single',
		name: 'Grand Pike: Final Form',
		desc: 'The final evolution of your spear',
		isUnlock: true,
		upgradeEvolutionTier: 'tier3',
		top: {
			name: 'Evolve: Gungnir',
			desc: 'Spear: Your attack is longer, and enchanted with lightning. Every other attack creates chaining lightning projectiles that deal a portion of your damage',
			icon: 'upgrade-grand-pike-final-form',
			pools: PRIMARY_TOP_POOL,
			affectedEntities: [AffectedEntities.Spear],
			simpleApplyFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.changeColliderLengthToGungir()
				spear.gungirElectricProjectiles = true

				player.setWeaponSkin('spear-gungnir-01')
			},
			simpleRemoveFn(player, state) {
				const spear = player.primaryWeapon as SpearWeapon
				spear.changeColliderLengthToGrandPike()
				spear.gungirElectricProjectiles = false

				player.setWeaponSkin('spear-grand-pike-01')
			},
		}
	},
}

