import { VectorXY } from "../utils/math"

export interface SpriteAnimationConfig {
    overrideSpeed?: number
    loop: boolean
    nextAnimation?: string
    anchor?: VectorXY
	useTrimOffset?: boolean
}

export type SpriteAnimationConfigs = Record<string, SpriteAnimationConfig>

// the anchors in this use -1 to 1 and not 0 to 1
export const SPRITE_ANIMTATION_CONFIGS: Record<string, SpriteAnimationConfig> = {
    'death': {
        loop: false,
		useTrimOffset: true
    },
	'lightning-strike': {
		loop: false,
		anchor: {
			x: 0,
			y: 1.0
		}
    },
	'lightning-strike-hit': {
        loop: false,
    },
	'lightning-strike-ground': {
        loop: false,
		anchor: {
			x: 0,
			y: -1
		}
    },
	'smoke': {
		loop: false
	},
	'spear-hit': {
		loop: false,
		anchor: {
			x: -1,
			y: 0
		}
	},
	'stomp-ground-pfx': {
		loop: false,
		overrideSpeed: 0.3
	},
	'stomp-horse-shoe': {
		loop: false,
		anchor: {
			x: 0,
			y: 0.8
		},
		overrideSpeed: 0.3
	},
	'bite-pfx': {
		loop: false,
	},
	'trigger' : {
		loop: false,
		anchor: {
			x: 0,
			y: 0.4
		},
	},
	'vine-spawn' : {
		loop: false,
		anchor: {
			x: 0,
			y: 0.5
		},
		nextAnimation: 'vine-idle'
	},
	'vine-idle' : {
		loop: true,
		anchor: {
			x: 0,
			y: 0.5
		},
		nextAnimation: 'vine-death'
	},
	'vine-death' : {
		loop: false,
		anchor: {
			x: 0,
			y: 0.5
		},
	},
	'longbow-pfx' : {
		loop: false,
		anchor: {
			x: 1.6,
			y: 0
		},
	},
	'back-idle': {
		loop: false,
		anchor: {
			x: 0,
			y: 1.5
		}
	},
	'front-idle': {
		loop: false,
		anchor: {
			x: 0,
			y: 0.5
		}
	},
	'holy-light-aoe-animation': {
		loop: false,
	},
	'pulsa-shock-pulse-shock': {
		loop: false,
	},
	'pulsa-shock-pulse-shock-poison': {
		loop: false,
	},
	'supply-crate-landing': {
		loop: true,
		anchor: {
			x: 0,
			y: 1,
		}
	},
	'supply-crate-opening': {
		loop: false,
		anchor: {
			x: 0,
			y: 1,
		}
	},
	'swipe': {
		loop: false,
		useTrimOffset: true,
		nextAnimation: 'idle'
	},
	'swipe-pfx': {
		loop: false,
	},
	'movement2': {
		loop: true
	},
	'spawn': {
		loop: false,
		nextAnimation: 'idle'
	},
	'despawn/poison-bottle-despawn': {
		loop: false
	},
	'despawn/poison-aoe-despawn': {
		loop: false
	},
	'spawn/poison-aoe-spawn': {
		loop: false,
		overrideSpeed: 0.5
	}

}

export const GravityPinDownConfigs: SpriteAnimationConfigs = {
	'back-idle': {
		loop: true,
		anchor: {
			x: 0,
			y: -0.2
		}
	},
	'front-idle': {
		loop: true,
		anchor: {
			x: 0,
			y: 0.75
		}
	},
}