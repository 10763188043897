import { Sprite } from "pixi.js"
import { Renderer } from "../engine/graphics/renderer"
import { ObjectPool } from "../utils/third-party/object-pool"

const STEP_DISTANCE = 150

type PooledSprite = {
	sprite: Sprite
	poolIndex: number
}

export class RangerTrapCrystalGraphic {
	static instancedSpritePools: ObjectPool[]

	sprites: PooledSprite[] = []

	constructor() {
		if (!RangerTrapCrystalGraphic.instancedSpritePools) {
			const spritePools = [
				new ObjectPool(
					() => {
						const sprite = Sprite.from('ranger-trap-crystal-01')
						sprite['update'] = () => {}
						sprite.anchor.x = 0.5
						sprite.anchor.y = 0.5
						sprite.zIndex = -99999

						return { sprite, poolIndex: 0 }
					}, {}, 10, 1),
				new ObjectPool(
					() => { 
						const sprite = Sprite.from('ranger-trap-crystal-02')
						sprite['update'] = () => {}
						sprite.anchor.x = 0.5
						sprite.anchor.y = 0.5
						sprite.zIndex = -99999

						return { sprite, poolIndex: 1 }
					}, {}, 10, 1),
			]

			RangerTrapCrystalGraphic.instancedSpritePools = spritePools
		}
	}

	addToScene(x: number, y: number, radius: number) {
		const renderer = Renderer.getInstance().mgRenderer

		const circumfrance = 2 * Math.PI * radius
		const numberOfSprites = circumfrance / STEP_DISTANCE

		const start = Math.random() * (2 * Math.PI)
		const end = start + (2 * Math.PI)

		const stepSize = (Math.PI * 2) / numberOfSprites

		for(let a = start; a < end - stepSize; a += stepSize) {

			const randomPool = RangerTrapCrystalGraphic.instancedSpritePools[Math.getRandomInt(0, RangerTrapCrystalGraphic.instancedSpritePools.length - 1)]
			const pooledSprite: PooledSprite = randomPool.alloc()
			const sprite = pooledSprite.sprite

			const circleX = Math.sin(a)
			const circleY = Math.cos(a)
			sprite.x = circleX * radius + x
			sprite.y = circleY * radius + y

			sprite.zIndex = sprite.y - (sprite.height / 2)

			renderer.addDisplayObjectToScene(sprite)
			this.sprites.push(pooledSprite)
		}
	}

	removeFromScene() {
		const renderer = Renderer.getInstance().mgRenderer

		for (let i = 0; i < this.sprites.length; ++i) {
			const pooledSprite = this.sprites[i]
			renderer.removeFromScene(pooledSprite.sprite)
			RangerTrapCrystalGraphic.instancedSpritePools[this.sprites[i].poolIndex].free(pooledSprite as any)
		}

		this.sprites.length = 0
	}
}
