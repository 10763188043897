import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const spider2: VariantAI = {
    extends: ENEMY_NAME.SPIDER_1,
    name: ENEMY_NAME.SPIDER_2,
    appearance: {
        asset: SpineDataName.SPIDER_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
}