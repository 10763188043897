<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div class="fullscreen-container container-formatting">
				<div class="label" :lang="this.$i18n.locale">{{ $t('settings.fullscreen') }}</div>
				<div class="fullscreen-on-off">
					<Toggle :label="''" :inline-label="true" @on-click-fn="toggleWindowMode" :checked="fullscreen" @select="() => {}" :font-size="'small'"></Toggle>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Toggle from '../form-inputs/toggle.vue'

export default {
	name: 'FullscreenModule',
	components: {
		EmbellishmentContainer,
		Toggle,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('settings', ['fullscreen']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('settings', ['checkFullscreen', 'toggleWindowMode']),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.fullscreen-container {
	display: flex;
	align-items: center;
	width: 660px;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 200px;
		margin-right: 175px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.fullscreen-on-off {
		display: flex;
		width: 70px;
		height: 40px;
	}
}
</style>
