import logger from './client-logger'

// WIP of trying to diff 2 objects
export function printDiff(object1: object, object2: object) {
	let s = ''

	function innerdiff(obj1: object, obj2: object, path: string) {
		Object.keys(obj1).forEach((key) => {
			const v1 = obj1[key]
			const v2 = obj2[key]
			const fullPath = path + '.' + key
			if (typeof v1 === 'object' && typeof v2 === 'object') {
				innerdiff(v1, v2, fullPath)
			} else if (v1 !== v2) {
				logger.debug(`${fullPath} [${v1} -> ${v2}]`)
				s += (`${fullPath} = ${v2}\n`)
			}
		})
	}

	innerdiff(object1, object2, 'root')
	return s
}

function isIterable(obj): obj is Iterable<any> {
	if (obj == null) {
		return false
	}
	return typeof obj[Symbol.iterator] === 'function'
}

export function findKey(objct: object, findName: string) {
	const visited = new Set()

	function findKeyInner(obj: object, oname: string, path: string, toFind: string, depth: number) {
		if (!obj) {
			return null
		}
		if (depth > 10) {
			return null
		}
		if (visited.has(obj)) {
			return null
		}
		//logger.debug(obj, oname, path)

		visited.add(obj)

		const fullPath = path + '*' + oname

		if (oname.includes(toFind)) {
			//logger.debug(`${fullPath} [${value}]`)
			return { path: fullPath, value: obj }
		}

		//logger.debug("----" + path);

		logger.debug(typeof obj)
		if (isIterable(obj)) {
			//logger.debug('iterating1', obj, obj.constructor.name)
			for (const kv of obj) {
				//logger.debug('iterating2', kv, obj)
				const found = findKeyInner(kv[1], kv[0], path, toFind, depth + 1)
				if (found) {
					return found
				}
			}
		} else {
			//logger.debug('object')
			const keys = Object.keys(obj)
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i]
				const value = obj[key]
				const found = findKeyInner(value, key, fullPath, toFind, depth + 1)
				if (found) {
					return found
				}
			}
		}
	}

	return findKeyInner(objct, 'root', 'root', findName, 0)
}
