import { percentage, timeInMilliseconds, timeInSeconds } from '../utils/primitive-types'
import { BuffIdentifier, MAX_VISIBLE_BUFFS } from './buff.shared'
import { InGameTime } from '../utils/time'
import { BuffableEntity, BuffSystem } from './buff-system'
import BuffData from './buff-map'
import { BuffTags, StackStyle } from './buff-enums'
import { throwIfNotFinite } from '../utils/math'
import { BuffDefinition } from './buff-definition'
import { getNID } from "../engine/game-state"
import { EntityType, IEntity, isEnemy } from "../entities/entity-interfaces"
import PlayerMetricsSystem from '../metrics/metric-system'

const FOREVER = Number.MAX_SAFE_INTEGER
export class Buff implements IEntity {
	nid: number
	entityType: EntityType = EntityType.Buff
	timeScale: number = 1

	/** The identifier of a type of buff */
	get identifier() {
		return this.definition.identifier
	}
	/** The identifier of an instance of a buff. Used to update the user client */
	buffId: number
	definition: BuffDefinition

	owner: any
	appliedTo: BuffableEntity

	appliedAtTime: timeInMilliseconds
	expiresAtTime: timeInMilliseconds
	get timeElapsedPercent(): percentage {
		return this.timeElapsed / (this.expiresAtTime - this.appliedAtTime)
	}
	get timeElapsed(): timeInMilliseconds {
		return InGameTime.highResolutionTimestamp() - this.appliedAtTime
	}
	get timeRemaining(): timeInMilliseconds {
		return this.expiresAtTime - InGameTime.highResolutionTimestamp()
	}
	nextTickAtTime: timeInMilliseconds

	appliedAtTimeAbsolute: timeInMilliseconds
	expiresAtTimeAbsolute: timeInMilliseconds

	stacks: number = 0
	rollingStacksApplied: Array<[number, number]>
	get rollingStackApplicationCount() {
		return this.rollingStacksApplied?.length || 1
	}

	state: any = {}

	constructor(buffDefinition: BuffDefinition) {
		this.nid = getNID(this)
		this.buffId = this.nid
		this.definition = buffDefinition
	}

	static apply(buffId: BuffIdentifier, owner: any, target: BuffableEntity, stacks?: number, duration?: timeInMilliseconds): Buff {
		//console.log(`Static apply() ${buffId}`)

		if (!(target as any).buffs) {
			console.error({ target, buffId })
			throw new Error('Tried to apply a buff to something without a buffs array')
		}

		if (isEnemy(target)) {
			PlayerMetricsSystem.getInstance().trackMetric('DEBUFF_APPLIED', buffId)
		}

		const existingBuff = Buff.getBuff(target, buffId, owner)
		if (existingBuff) {
			existingBuff._reapply(stacks, duration)
			return existingBuff
		}

		const definition = BuffData.map.get(buffId)
		if (!definition) {
			throw new Error(`buff: ${buffId} has no entry in BuffData`)
		}

		if (definition.canApplyFn) {
			if (!definition.canApplyFn(target)) {
				return undefined
			}
		}

		const buff = new Buff(definition)
		buff._apply(owner, target, stacks, duration)

		target.applyVisualBuff(buffId, stacks)

		return buff
	}

	/**
	 * @return buff if buffId exists, undefined otherwise
	 */
	static getBuff(target: BuffableEntity, buffId: BuffIdentifier, owner?: BuffableEntity): Buff | null {
		// TODO do we ever need to do instancing? for now ignoring owner, but good to get an answer on this
		return target.buffs.find((buff: Buff) => {
			return buff.identifier === buffId
		})
	}

	/**
	 * @return count of stacks of buff
	 */
	static getBuffStacks(target: BuffableEntity, buffId: BuffIdentifier, owner?: BuffableEntity): number {
		const buff = Buff.getBuff(target, buffId, owner)
		return buff ? buff.stacks : 0
	}

	static remove(target: BuffableEntity, buffId: BuffIdentifier, owner?: BuffableEntity) {
		const buff = this.getBuff(target, buffId, owner)
		if (buff) {
			buff.wearOff()
		}
	}

	static removeAll(target: BuffableEntity) {
		for (let i = target.buffs.length - 1; i >= 0; i--) {
			const buff = target.buffs[i]
			buff.wearOff()
		}
	}

	static removeAllOnPlayerDeath(target: BuffableEntity) {
		for (let i = target.buffs.length - 1; i >= 0; i--) {
			const buff = target.buffs[i]
			if (!buff.definition.persistOnPlayerDeath) {
				buff.wearOff()
			}
		}
	}

	static removeAllPlayerApplied(target: BuffableEntity) {
		for (let i = target.buffs.length - 1; i >= 0; i--) {
			const buff = target.buffs[i]
			if (
				buff.identifier === BuffIdentifier.Poison ||
				buff.identifier === BuffIdentifier.Ignite ||
				buff.identifier === BuffIdentifier.Bleed ||
				buff.identifier === BuffIdentifier.Chill ||
				buff.identifier === BuffIdentifier.Shock ||
				buff.identifier === BuffIdentifier.Stun
			) {
				buff.wearOff()
			}
		}
	}

	static cleanseBuffs(target: BuffableEntity) {
		for (let i = target.buffs.length - 1; i >= 0; i--) {
			const buff = target.buffs[i]
			if (buff.definition.tags.includes(BuffTags.CanCleanse)) {
				buff.wearOff()
			}
		}
	}

	/** Called to check counters and wearOff as necessary. Also checks the tickInterval (if specified) and triggers tickFn (if specified). */
	update(delta: timeInSeconds, now: timeInMilliseconds): void {
		// console.log(`${this.identifier} (${this.expiresAtTime} < ${now}), ${this.stacks} st, ${this.rollingStacksApplied?.length}`)
		if (!this.appliedTo) {
			console.warn('Updating a buff without an appliedTo')
			if (this.definition) {
				console.warn(this.definition.identifier)
			} else {
				console.warn('No buff definition')
			}
			return
		}

		if (this.definition.tickFn && this.definition.tickInterval && this.appliedTo && this.nextTickAtTime < now) {
			this.definition.tickFn(this)
			this.nextTickAtTime += this.definition.tickInterval
		}

		if (this.expiresAtTime < now) {
			return this.wearOff(true)
		}

		if (this.rollingStacksApplied?.length) {
			for (let i = this.rollingStacksApplied.length - 1; i >= 0; i--) {
				// console.log(`rolling ${i}`, this.rollingStacksApplied[i])
				// wearOffStacks can clear this list, so abort here if so
				if (!this.rollingStacksApplied[i]) {
					return
				}

				const [expiresAtTime, stackCount] = this.rollingStacksApplied[i]
				if (expiresAtTime < now) {
					// wearOffStacks can collect this buff, which will result in a crash, return if this buff was collected
					const woreOff = this.wearOffStacks(stackCount)
					if (woreOff) {
						return
					}
					this.rollingStacksApplied.splice(i, 1)
				}
			}
		}
	}

	/** Wear off the buff, removing all bonuses it provides. Will trigger wearOffFn. */
	wearOff(naturalWearOff?: boolean): void {
		if (this.appliedTo) {
			this.appliedTo.buffs.remove(this)
			this.appliedTo.removeVisualBuff(this.identifier, this.stacks)
		}

		BuffSystem.buffs.remove(this)

		if (this.appliedTo) {
			if (this.definition.wearOffFn) {
				this.definition.wearOffFn(this, naturalWearOff)
			}

			// this needs to be after `this.appliedTo.buffs.remove`, which smells
			this.appliedTo.onBuffWearOff(this.identifier)

			this.owner = null
		}
	}

	/** Wear off a certain number of stacks and modify the bonuses accordingly. Will trigger updateStacksFn.
	 * @returns true if this buff completely wore off, false if not
	 */
	wearOffStacks(stacks: number): boolean {
		throwIfNotFinite(stacks)
		const oldStacks = this.stacks
		this.stacks -= stacks

		if (this.stacks <= 0) {
			this.wearOff()
			return true
		}

		if (this.definition.updateStacksFn && oldStacks !== this.stacks) {
			this.definition.updateStacksFn(this, oldStacks, this.stacks)
		}
		return false
	}

	/** removes stacks without calling any wearoff or update functions */
	removeStacksDirect(stacks: number) {
		throwIfNotFinite(stacks)
		this.stacks -= stacks
		if (this.rollingStacksApplied) {
			for (let i = 0; i < this.rollingStacksApplied?.length; i++) {
				const entry = this.rollingStacksApplied[i]
				const [expiresAtTime, stackCount] = entry

				if (stackCount < stacks) {
					stacks -= stackCount
					entry[1] = 0
				} else {
					entry[1] -= stacks
					break
				}
			}
		}
	}

	setExpirationTimeRelativeToApplied(relativeTime: timeInMilliseconds) {
		this.expiresAtTime = this.appliedAtTime + relativeTime
		this.expiresAtTimeAbsolute = this.appliedAtTimeAbsolute + relativeTime
		if (this.definition.lastsForever) {
			// logger.info(`tried to set expiration for lastsForever buff: ${BuffIdentifier[this.definition.identifier]}`)
			this.expiresAtTime = FOREVER
			this.expiresAtTimeAbsolute = FOREVER
		}
	}

	setExpirationTimeAbsolutely(absoluteTime: timeInMilliseconds) {
		this.expiresAtTimeAbsolute = absoluteTime
		this.expiresAtTime = absoluteTime - this.appliedAtTimeAbsolute
		if (this.definition.lastsForever) {
			// logger.info(`tried to set expiration for lastsForever buff: ${BuffIdentifier[this.definition.identifier]}`)
			this.expiresAtTime = FOREVER
			this.expiresAtTimeAbsolute = FOREVER
		}
	}

	setDefaultValues(defaultValues: any, definition?: any): void {
		if (definition) {
			this.definition = definition
		}
	}

	cleanup(): void {
		this.state = undefined
		this.definition = undefined
		if (this.rollingStacksApplied) {
			this.rollingStacksApplied.length = 0
		}
	}

	/** Apply a buff, optionally overriding the stacks and/or duration. */
	private _apply(owner: any, target: BuffableEntity, stacks?: number, duration?: timeInMilliseconds): void {
		//console.log(`_apply() ${this.buffId}`)
		stacks = stacks || this.definition.startingStacks
		this.owner = owner
		this.appliedTo = target
		this.stacks = stacks
		if (this.definition.stackLimit) {
			this.stacks = Math.min(this.stacks, this.definition.stackLimit)
		}
		throwIfNotFinite(this.stacks)
		const now = InGameTime.highResolutionTimestamp()
		this.appliedAtTime = now
		this.appliedAtTimeAbsolute = Date.now()

		if (!duration && stacks > 1 && this.definition.stackStyle === StackStyle.IncreaseDuration) {
			duration = this.definition.duration * stacks
		}

		this.expiresAtTime = now + (duration || this.definition.duration)
		this.expiresAtTimeAbsolute = this.appliedAtTimeAbsolute + (duration || this.definition.duration)
		if (this.definition.lastsForever) {
			this.expiresAtTime = FOREVER
			this.expiresAtTimeAbsolute = FOREVER
		}
		if (this.definition.stackStyle === StackStyle.RollingStackDurationSeparately) {
			throwIfNotFinite(stacks)
			this.rollingStacksApplied = [[this.expiresAtTime, stacks]]
		}
		if (this.definition.tickFn && this.definition.tickInterval) {
			this.nextTickAtTime = now + this.definition.tickInterval
		}
		if (this.appliedTo) {
			this.appliedTo.buffs.push(this)
		} else {
			console.error(`Attempted to apply a buff (${this.identifier}) to a null entity!!! This should never happen!!! Likely bug!`)
		}
		BuffSystem.buffs.push(this)
		if (this.definition.applyFn) {
			this.definition.applyFn(this)
		}
		if (this.definition.updateStacksFn) {
			this.definition.updateStacksFn(this, 0, this.stacks)
		}
		if (this.appliedTo) {
			this.appliedTo.onBuffApplied(this.identifier)
		} else {
			console.error(`Attempted to apply a buff (${this.identifier}) to a null entity! This should never happen!`)
		}
	}

	/** Handles all re-application behavior. */
	private _reapply(stacks?: number, duration?: number) {
		// console.log(`_REapply() ${this.expiresAtTime}`)
		if (this.definition.stackStyle === StackStyle.None) {
			return
		}

		stacks = stacks || this.definition.reapplyStacks
		duration = duration || this.definition.reapplyDuration

		this.appliedTo.applyVisualBuff(this.identifier, stacks)

		const now = InGameTime.highResolutionTimestamp()
		const oldStacks = this.stacks

		throwIfNotFinite(this.stacks)
		throwIfNotFinite(stacks)
		if (this.definition.stackStyle === StackStyle.IncreaseDuration) {
			this.stacks += stacks
			if (this.definition.stackLimit) {
				this.stacks = Math.min(this.stacks, this.definition.stackLimit)
			}
			this.expiresAtTime += duration
			if (this.definition.durationLimit) {
				this.expiresAtTime = Math.min(now + this.definition.durationLimit, this.expiresAtTime)
			}
		} else if (this.definition.stackStyle === StackStyle.RefreshDuration) {
			this.stacks += stacks
			if (this.definition.stackLimit) {
				this.stacks = Math.min(this.stacks, this.definition.stackLimit)
			}
			this.expiresAtTime = Math.max(this.expiresAtTime, now + duration)
		} else if (this.definition.stackStyle === StackStyle.RollingStackDurationSeparately) {
			this.stacks += stacks
			if (this.definition.stackLimit) {
				this.stacks = Math.min(this.stacks, this.definition.stackLimit)
			}
			this.rollingStacksApplied.push([now + duration, stacks])
			this.expiresAtTime = Math.max(this.expiresAtTime, now + duration)
		}

		this.expiresAtTimeAbsolute = this.appliedAtTimeAbsolute + (this.expiresAtTime - this.appliedAtTime)

		if (this.definition.updateStacksFn) {
			this.definition.updateStacksFn(this, oldStacks, this.stacks)
		}
		if (this.appliedTo.onBuffUpdateStacks) {
			this.appliedTo.onBuffUpdateStacks(this.identifier, oldStacks, this.stacks)
		}
	}
}

export function updateSerializedBuffProperties(entity: BuffableEntity) {
	for (let i = 0; i < MAX_VISIBLE_BUFFS; i++) {
		entity[`buff${i + 1}`] = ''
	}

	for (let i = 0; i < entity.buffs.length; i++) {
		entity[`buff${i + 1}`] = entity.buffs[i].identifier
	}
}
