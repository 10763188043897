import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const demonMosquito: VariantAI = {
    extends: ENEMY_NAME.MOSQUITO,
    name: ENEMY_NAME.DEMON_SKEETER,
    appearance: {
        asset: SpineDataName.MOSQUITO,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
        scale: 1.2
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.15,
    maxHealth: 0.75
}

export const mosquito2: VariantAI = {
    extends: ENEMY_NAME.MOSQUITO,
    name: ENEMY_NAME.MOSQUITO_2,
    appearance: {
        asset: SpineDataName.MOSQUITO_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
        scale: 1.2
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.15,
    maxHealth: 0.75
}

export const mosquito3: VariantAI = {
    extends: ENEMY_NAME.MOSQUITO,
    name: ENEMY_NAME.MOSQUITO_3,
    appearance: {
        asset: SpineDataName.MOSQUITO_3,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
        scale: 1.2
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.15,
    maxHealth: 0.75
}