import { Vector } from "sat"
import { CooldownDefinition } from "../../cooldowns/cooldown"
import { Audio } from "../../engine/audio"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { Renderer } from "../../engine/graphics/renderer"
import { getDamageByPlayerLevel } from "../../game-data/player-formulas"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import { DEFAULT_AOE_EXPLOSION_DURATION, DEFAULT_AOE_EXPLOSION_PFX_SIZE, setExplosionColor } from "../../projectiles/explosions"
import EntityStatList, { StatBonus } from "../../stats/entity-stat-list"
import { StatOperator, StatType } from "../../stats/stat-interfaces-enums"
import { callbacks_addCallback } from "../../utils/callback-system"
import { gameUnits, timeInSeconds } from "../../utils/primitive-types"
import { waitUntil } from "../../utils/time"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { Pet, PetCollectionName } from "./pet"
import { Player } from "../player"

const DAMAGE_MULTIPLIER = 0.9
const PFX_SCALE = 1 / DEFAULT_AOE_EXPLOSION_PFX_SIZE

const ENEMY_SEARCH_FRAMES = 15

export class RotSonPet extends Pet {
	damageBonus: StatBonus
	aoeEffectConfig: EffectConfig

	targetCollisionLayer: CollisionLayerBits
	overrideSearchEnemies: boolean = false

	private enemySearchIndex: number

	constructor(name: PetCollectionName, statList: EntityStatList, position: Vector, uncaged?: boolean) {
		super(name, statList, position, uncaged)
		this.damageBonus = this.statList.addStatBonus("baseDamage", StatOperator.SUM, 0) as StatBonus
		this.aoeEffectConfig = AssetManager.getInstance().getAssetByName('aoe-explosion-white').data
		this.targetCollisionLayer = CollisionLayerBits.HitEnemyOnly
		this.enemySearchIndex = 0

        this.canDoubleAttack = true
	}

	resetStatsFn(statList: EntityStatList) {
		defaultStatAttribute(statList)
		statList._actualStatValues.attackSize = 180
		statList._actualStatValues.baseDamage = 0
		statList._actualStatValues.poisonChance = 1.0
		statList._actualStatValues.allDamageMult = 1

		statList._actualStatValues.maxAmmo = 1
        statList._actualStatValues.cooldownInterval = 3_000
        statList._actualStatValues.reloadInterval = 50
        statList._actualStatValues.reloadAmmoIncrement = 1
	}

	setTargetCollisionLayer(layer: CollisionLayerBits) {
		this.targetCollisionLayer = layer
	}

	async useAbility() {
		this.damageBonus.update(getDamageByPlayerLevel() * DAMAGE_MULTIPLIER)
		this.searchForEnemies()
	}

	update(delta: timeInSeconds): void {
		super.update(delta)

		if (this.usingAbility && ++this.enemySearchIndex % ENEMY_SEARCH_FRAMES === 0) {
			this.searchForEnemies()
		}
	}

	private searchForEnemies(): boolean {
		const attackSize = this.statList.getStat(StatType.attackSize)
		const groundEffectSize = attackSize * 2 / 3

		const enemies = CollisionSystem.getInstance().getEntitiesInArea(this.position, attackSize, this.targetCollisionLayer)
		if (enemies.length > 0 || this.overrideSearchEnemies) {
			// fire one AOE immediately
			this.doGroundEffect(this.position.x, this.position.y, groundEffectSize)

			// then cluster 4 more after short delay
			for (let i = 0; i < 4; i++) {
				callbacks_addCallback(this, () => {
					const randUnits = groundEffectSize
					const rx = this.position.x + Math.getRandomInt(-randUnits, randUnits)
					const ry = this.position.y + Math.getRandomInt(-randUnits, randUnits)
					this.doGroundEffect(rx, ry, groundEffectSize)
				}, 0.3 + i * 0.05)
			}

			this.finishedPetAbility()

			return true
		} else {
			return false
		}
	}

	async doGroundEffect(x: gameUnits, y: gameUnits, size: number) {
		const scale = size * PFX_SCALE
		const pfx = Renderer.getInstance().addOneOffEffectByConfig(this.aoeEffectConfig, x, y, -9999, scale, DEFAULT_AOE_EXPLOSION_DURATION, true)
		setExplosionColor(pfx, 'poison')
		Audio.getInstance().playSfx('SFX_Elemental_Poison')

		await waitUntil(100)

		const entities = CollisionSystem.getInstance().getEntitiesInArea(new Vector(x, y), size, this.targetCollisionLayer)
		for (let i = 0; i < entities.length; ++i) {
			if (entities[i].owner instanceof Enemy) {
				const enemy = entities[i].owner as Enemy
				enemy.onHitByDamageSource(this)
			} else if (entities[i].owner instanceof Player) {
				const player = entities[i].owner as Player
				player.takeDamage(1, this)
			}
		}
	}
}
