<template>
	<div
		class="panel"
		:style="{
			transform: `perspective(1px) translateZ(0) scale(1)`,
		}"
	>
		<div ref="panelRef" :class="{alt: altColor}" class="small-panel-inner" :style="{ height: height > 0 ? `${height}px` : '' }">
			<div class="content">
				<slot name="small-panel-content"></slot>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'

export default {
	name: 'SmallPanel',
	components: {},
	props: {
		height: {
			type: Number,
			required: false,
			default: 800,
		},
		underConstruction: {
			type: Boolean,
		},
		altColor: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		...mapGetters('uiScaling', ['currentScale']),
	},
	methods: {},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.panel {
	display: flex;
	align-items: center;
	transform-origin: right;
	perspective: 1px;
	.under-construction {
		position: absolute;
		transform: rotate(-35deg);
		top: 30%;
		left: 15px;
		font-family: 'Do Hyeon';
		font-size: 50px;
		font-weight: 1000;
		text-shadow: 0 0 10px black, 0 0 10px black, 0 0 30px white;
		color: #00ffc4;
	}
	.small-panel-inner {
		border-style: solid;
		// border-width: 6px 9px 6px;
		min-width: 55px;
		min-height: 55px;
		position: relative;
		pointer-events: all;
		perspective: 1px;
		transform: translateZ(0);
		border-image-source: url('~/assets/ui/panel/small-panel.png');
		border-image-slice: 41 73 41 94 fill;
		border-image-width: 41px 73px 41px 94px;
		&.alt{
			border-image-source: url('~/assets/ui/panel/small-panel-alt.png');
			filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.5));
		}
		.content {
			height: inherit;
			&.noBanner {
				margin-top: 90px;
				height: 100%;
			}
		}
	}
}
</style>
