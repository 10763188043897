import { PlayerBinaryFlags } from "../buffs/buff-system"
import { BuffIdentifier } from "../buffs/buff.shared"
import { GameState } from "../engine/game-state"
import AISystem from "../entities/enemies/ai-system"
import { EnemyAI, OnHitBehaviours } from "../entities/enemies/ai-types"
import { Enemy } from "../entities/enemies/enemy"
import EnemyEquilibriumSpawner, { EnemyPackStageConfig } from "../entities/enemies/enemy-equilibrium-spawner"
import { ChoreographedEvent, FALLING_FOLK_DANCE_EVENTS, SHRIEKER_CHAOS_EVENTS, SPRING_TIME_DANCING_EVENTS } from "../entities/enemies/enemy-events-config"
import { ENEMY_NAME } from "../entities/enemies/enemy-names"
import { EnemySpawnValues } from "../entities/enemies/enemy-spawn-config"
import { ElementalPoolType } from "../entities/hazards/elemental-pools-data"
import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"
import { PlantedXP, PlantedXPParams } from "../entities/pickups/planted-xp"
import { EventTypes } from "../events/event-types"
import { EventStartData } from "../events/gameplay-event-definitions"
import { StatBonusData, StatBonusValue } from "../stats/entity-stat-list"
import { StatName, StatOperator, StatType } from "../stats/stat-interfaces-enums"
import { UpgradeManager } from "../upgrades/upgrade-manager"
import { timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { InGameTime } from "../utils/time"
import { PropPlacer } from "../world-generation/prop-placement"

const SHRINE_DESPAWN_TIME: timeInSeconds = 45

export const mutatorShortNames = [
	'debug40rate',
	'debug50rate',
	'debug75rate',
	'debug25max',
	'debug40max',
	'debug50max',
	'debug75max',
	'debug50thicc',
	'debug75thicc',
	'debug100thicc',
	'debug200thicc',

	'zoomies',
	'whatsThatNoise',
	'surprisingClimax',
	'narrowFocus',
	'explosions',
	'elementalMaelstrom',
	'floorIsLava',
	'badEyes',
	'bigHead',
	'stopDropAndRoll',
	'pinball',
	'bloatedBlimpies',
	'infestation',
	'twiceDead',
	'splitPersonality',
	'deferredLearning',
	'petSurvival',
	'shriekerChaos',
	'destructiveTendencies',
	'hostageSituation',
	'giantShamblers',
	'chainedTogether',
	'butterfingers',
	'petJailBreak',
	'shriekersShrieking',
	'hardcoreSurvival',
	'bruteTrio',
	'fallingFolkDance',
	'springTimeDancing',
	'shamblingTowers',
	'combatArena',
	'paranormalExercise',
	'eyesFilledWithRage',

	'littlePlagueBearers',
	'fountainsOfMana',

	'headStart',
	'scrapyard',
	'intelligenceDump',
	'randomRicochet',
	'berserker',
	'enraged',
	'speedDrafting',
	'monsterMergeGame',
	'kingShriekers',
	'spookyGhosts',
	'insectFever',

	'dontDropTheEgg',
	'elixirOfSomething',
	'spicyPepper',
	'monsterWhistle',

	'betterGoFast',
	'spectralFarmer',
	'temporalDistortion',
	'wildRotSons',
	'roamingWildlings',
] as const

export function isMutatorShortName(s: string): s is MutatorShortName {
	return mutatorShortNames.includes(s as any)
}

export type MutatorShortName = typeof mutatorShortNames[number]

type GameLoopMod = 'speedZoomies' | number

type BaseMutatorDefinition = {
	id: MutatorShortName
	name: string
	description: string
	icon: string
	isDebug?: boolean
}

type EnemySpawnValuesDefinition = EnemySpawnValues
export type EnemySelector = 'all' | ENEMY_NAME[] //TODO: use EnemyDefinitions or ENEMY_NAMES instead?

export type EnemyMutation = 'explosion' | 'change-size' | 'change-health' | 'spawn-on-death' | 'drop-amount-mult'

type MutationArgument = number | string | Array<number | string>

type EnemyModifier = [EnemySelector, EnemyMutation, MutationArgument?, MutationArgument?, MutationArgument?]

type StatModifier = {
	/** These statChanges are applied to the GlobalStatList */
	statChanges?: Array<[StatName, StatOperator, StatBonusValue]>,
	/** These binaryFlags are applied to the Player */
	binaryFlags?: PlayerBinaryFlags[],
}

export type PropModifier = 'elemental-maelstrom' | 'floor-is-lava' | 'destructive-tendencies' | 'tundra-ice-fields' | 'insect-fever'

export type EventModifier = 'pet-poi-stand-in-circle' | 'pet-poi-jail-break' | 'shambling-towers'

export type CameraModifier = { act1StartValue: number, act2StartValue: number, act3StartValue: number }

export type ChoreoModifier = { choreoDef: ChoreographedEvent[] }

export type RunCodeMutatorDefinition = { target: 'function', modifications: () => void }

type AddPickupModifier = { enemyPickupModifier: Array<[GroundPickupConfigType, number]>, propPickupModifier: Array<[GroundPickupConfigType, number]> }
export type AddPickupMutatorDefinition = { target: 'pickups', modifications: AddPickupModifier }

type SpawnerMutatorDefinition = { target: 'enemySpawner', modifications: EnemyPackStageConfig[] }

type SpawnValuesMutatorDefinition = { target: 'enemySpawnValues', modifications: EnemySpawnValuesDefinition }

type GameLoopMutatorDefinition = { target: 'gameLoop', modifications: GameLoopMod }

export type BinaryFlagStateDefinition = { flag: PlayerBinaryFlags, values: any }
type PlayerMutatorDefinition = { target: 'player', modifications: PlayerBinaryFlags[] | StatBonusData[] | BinaryFlagStateDefinition[] }

export type BuffPlayerMutatorDefinition = { target: 'buffPlayer', modifications: BuffIdentifier }

type EnemyMutatorDefinition = { target: 'enemies', modifications: EnemyModifier[] }

type PropMutatorDefinition = { target: 'props', modifications: PropModifier }

type StatMutatorDefinition = { target: 'stats', modifications: StatModifier }

type EventMutatorDefinition = { target: 'events', modifications: EventModifier }

type AddEventMutatorDefinition = { target: 'addEvent', modifications: { event: EventTypes, startCondition: EventStartData } }

type RecyclerMutatorDefinition = { target: 'recycler', modifications: { shamblerCount: number, cooldown: timeInSeconds } }

export type ReplaceEnemyDefinition = { enemy: ENEMY_NAME, replacement: ENEMY_NAME }
export type ReplaceEnemyMutatorDefinition = { target: 'replaceEnemies', modifications: ReplaceEnemyDefinition }

export type GroupAmountModifier = {enemy: ENEMY_NAME, groupAmount: { min: number, max: number }}
export type GroupAmountMutatorDefinition = { target: 'modifyGrouping', modifications: GroupAmountModifier[] }

export type ModifyEnemyMutatorDefinition = { target: 'modifyEnemyDefinition', modifications: (enemyAi: EnemyAI) => void }

export type CameraMutatorDefinition = { target: 'camera', modifications: CameraModifier }

export type ChoreoMutatorDefinition = { target: 'choreo', modifications: ChoreoModifier }

export type MutatorDefinition = BaseMutatorDefinition & {
	mutationsToApply: Array<SpawnerMutatorDefinition
		| GameLoopMutatorDefinition
		| PlayerMutatorDefinition
		| EnemyMutatorDefinition
		| SpawnValuesMutatorDefinition
		| PropMutatorDefinition
		| CameraMutatorDefinition
		| EventMutatorDefinition
		| StatMutatorDefinition
		| ChoreoMutatorDefinition
		| RecyclerMutatorDefinition
		| ReplaceEnemyMutatorDefinition
		| RunCodeMutatorDefinition
		| AddEventMutatorDefinition
		| BuffPlayerMutatorDefinition
		| ModifyEnemyMutatorDefinition
		| GroupAmountMutatorDefinition
		| AddPickupMutatorDefinition
	>
}

export type MutatorDefinitions = Record<MutatorShortName, MutatorDefinition>

let lastPlagueBearerPoolTime: timeInSeconds
let nextPlagueBearerPoolTime: timeInSeconds

export const MUTATOR_DEFINITIONS: MutatorDefinitions = {
	'debug40rate': {
		id: 'debug40rate',
		name: "+40% Enemy Spawn Rate",
		description: "+40% Enemy Spawn Rate",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnRate: 1.40,
			},
		}],
		isDebug: true
	},
	'debug50rate': {
		id: 'debug50rate',
		name: "+50% Enemy Spawn Rate",
		description: "+50% Enemy Spawn Rate",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnRate: 1.50,
			},
		}],
		isDebug: true
	},
	'debug75rate': {
		id: 'debug75rate',
		name: "+75% Enemy Spawn Rate",
		description: "+75% Enemy Spawn Rate",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnRate: 1.75,
			},
		}],
		isDebug: true
	},
	'debug25max': {
		id: 'debug25max',
		name: "+25% Max Enemies",
		description: "+25% Max Enemies",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnMax: 1.25,
			},
		}],
		isDebug: true
	},
	'debug40max': {
		id: 'debug40max',
		name: "+40% Max Enemies",
		description: "+40% Max Enemies",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnMax: 1.40,
			},
		}],
		isDebug: true
	},
	'debug50max': {
		id: 'debug50max',
		name: "+50% Max Enemies",
		description: "+50% Max Enemies",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnMax: 1.50,
			},
		}],
		isDebug: true
	},
	'debug75max': {
		id: 'debug75max',
		name: "+75% Max Enemies",
		description: "+75% Max Enemies",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnMax: 1.75,
			},
		}],
		isDebug: true
	},
	'debug50thicc': {
		id: "debug50thicc",
		name: "50% Enemy Health",
		description: "+50% Enemy Health",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				['all', 'change-health', 1.5],
			],
		}],
		isDebug: true
	},
	'debug75thicc': {
		id: "debug75thicc",
		name: "75% Enemy Health",
		description: "+75% Enemy Health",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				['all', 'change-health', 1.75],
			],
		}],
		isDebug: true
	},
	'debug100thicc': {
		id: "debug100thicc",
		name: "100% Enemy Health",
		description: "+100% Enemy Health",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				['all', 'change-health', 2.00],
			],
		}],
		isDebug: true
	},
	'debug200thicc': {
		id: "debug200thicc",
		name: "200% Enemy Health",
		description: "+200% Enemy Health",
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				['all', 'change-health', 3.00],
			],
		}],
		isDebug: true
	},
	'explosions': {
		id: "explosions",
		name: "A Shocking Number of Explosions",
		description: "Occasionally enemies explode for a moderate amount of damage on death, hitting each other and you in a large area.",
		icon: 'twist-a-shocking-number-of-explosions',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				['all', 'explosion', 250, 0.333333],
			],
		}],
	},
	'bloatedBlimpies': {
		id: "bloatedBlimpies",
		name: "Bloated Blimpies",
		description: "Blimpies are now twice as large, and will explode just as big.",
		icon: 'twist-bloated-blimpies',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				[[ENEMY_NAME.BLIMPIE], 'change-size', 2.0],
				[[ENEMY_NAME.BLIMPIE], 'change-health', 3.0],
			],
		},
		{
			target: 'enemySpawner',
			modifications: [
				{
					min: 221,
					max: 240,
					packs: [
						{
							enemyName: ENEMY_NAME.BLIMPIE,
							equilibriumAmount: 0,
							maxAmount: 2,
							spawnAmount: 1,
							spawnRate: 3.5
						}
					]
				},
				{
					min: 351,
					max: 520,
					packs: [
						{
							enemyName: ENEMY_NAME.BLIMPIE,
							equilibriumAmount: 0,
							maxAmount: 3,
							spawnAmount: 1,
							spawnRate: 3.5
						}
					]
				},
				{
					min: 661,
					max: 740,
					packs: [
						{
							enemyName: ENEMY_NAME.BLIMPIE,
							equilibriumAmount: 0,
							maxAmount: 8,
							spawnAmount: 1,
							spawnRate: 3
						}
					]
				}
			]
		}],
	},
	'infestation': {
		id: "infestation",
		name: "Infestation",
		description: "When Blimpies explode, they spew a horde of Mosquitos",
		icon: 'twist-infestation',
		mutationsToApply: [
			{
				target: 'enemies',
				modifications: [
					[[ENEMY_NAME.BLIMPIE], 'spawn-on-death', ENEMY_NAME.MOSQUITO, 15, [200, 200]],
				],
			},
			{
				target: 'enemySpawner',
				modifications: [
					{
						min: 101,
						max: 140,
						packs: [
							{
								enemyName: ENEMY_NAME.BLIMPIE,
								equilibriumAmount: 0,
								maxAmount: 1,
								spawnAmount: 1,
								spawnRate: 9
							}
						]
					},
					{
						min: 521,
						max: 600,
						packs: [
							{
								enemyName: ENEMY_NAME.BLIMPIE,
								equilibriumAmount: 2,
								maxAmount: 3,
								spawnAmount: 1,
								spawnRate: 2.5
							}
						]
					},
					{
						min: 631,
						max: 690,
						packs: [
							{
								enemyName: ENEMY_NAME.BLIMPIE,
								equilibriumAmount: 3,
								maxAmount: 7,
								spawnAmount: 2,
								spawnRate: 3
							}
						]
					}
				]
			},
		],
	},
	'petSurvival': {
		id: "petSurvival",
		name: "Pet Survival",
		description: "To rescue a pet, you must survive in the ring a certain amount of time. Pet events spawn more often.",
		icon: 'twist-pet-survival',
		mutationsToApply: [{
			target: 'events',
			modifications: "pet-poi-stand-in-circle",
		}],
	},
	'twiceDead': {
		id: "twiceDead",
		name: "Twice Dead",
		description: "[Hard] Enemies rise again after dying!",
		icon: 'twist-twice-dead',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				['all', 'spawn-on-death', null, 1, 500],
			],
		}],
	},
	'splitPersonality': {
		id: "splitPersonality",
		name: "Split Personality",
		description: "[Hard] Enemies split into two after dying!",
		icon: 'twist-split-personality',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				['all', 'spawn-on-death', null, 2, 500],
			],
		}],
	},
	'zoomies': {
		id: "zoomies",
		name: "Zoomies",
		description: "All enemies move 50% faster. You move 50% faster. Skills and weapons are 50% faster. Time is 50% faster. Gotta go fast.",
		icon: 'twist-zoomies',
		mutationsToApply: [{
			target: 'gameLoop',
			modifications: 'speedZoomies'
		}],
	},
	'narrowFocus': {
		id: 'narrowFocus',
		name: "Narrow Focus",
		description: "Reduce the available upgrade options by one",
		icon: 'twist-narrow-focus',
		mutationsToApply: [{
			target: 'player',
			modifications: ['mutator-narrow-focus']
		}],
	},
	'whatsThatNoise': {
		id: 'whatsThatNoise',
		name: "What's that noise?",
		description: "+20% extra Enemies",
		icon: 'twist-whats-that-noise',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				allEnemySpawnRate: 1.20,
			},
		}],
	},
	'surprisingClimax': {
		id: 'surprisingClimax',
		name: "A Surprising Climax",
		description: "Act 3 has +30% extra Enemies",
		icon: 'twist-a-surprising-climax',
		mutationsToApply: [{
			target: 'enemySpawnValues',
			modifications: {
				act3EnemySpawnRate: 1.30,
			},
		}],
	},
	'elementalMaelstrom': {
		id: 'elementalMaelstrom',
		name: 'Elemental Maelstrom',
		description: 'Dangerous elemental pools spawn everywhere. Pools are more effective against you than enemies.',
		icon: 'twist-elemental-maelstrom',
		mutationsToApply: [{
			target: 'props',
			modifications: 'elemental-maelstrom'
		}],
	},
	'floorIsLava': {
		id: 'floorIsLava',
		name: 'Floor is Lava',
		description: 'Lava spawns EVERYWHERE. Pools are more effective against you than enemies.',
		icon: 'twist-floor-is-lava',
		mutationsToApply: [{
			target: 'props',
			modifications: 'floor-is-lava'
		}],
	},
	'stopDropAndRoll': {
		id: "stopDropAndRoll",
		name: "Stop, Drop and Roll",
		description: "Add chance to Ignite, increase ALL Ignite damage, but Ignited enemies move faster.",
		icon: 'twist-stop-drop-roll',
		mutationsToApply: [{
			target: 'stats',
			modifications: {
				statChanges: [
					[StatType.ignitePotency, StatOperator.MULTIPLY, 0.2],
					[StatType.igniteChance, StatOperator.SUM, 0.2],
				],
				binaryFlags: ['ignited-enemies-gain-movement-speed'],
			},
		}],
	},
	'pinball': {
		id: "pinball",
		name: "Pinball",
		description: "Knockback effects are increased to 300%, but enemies gain knockback.",
		icon: 'twist-pinball',
		mutationsToApply: [{
			target: 'stats',
			modifications: {
				statChanges: [
					[StatType.attackKnockback, StatOperator.SUM_THEN_MULTIPLY, 2.0],
				],
				binaryFlags: ['player-takes-knockback'],
			},
		}],
	},
	'badEyes': {
		id: 'badEyes',
		name: 'Bad Eyes',
		description: 'Your eyes become worse after each act.',
		icon: 'twist-bad-eyes',
		mutationsToApply: [{
			target: 'camera',
			modifications: {
				act1StartValue: 1,
				act2StartValue: 1.2, // higher value -> more zoomed in
				act3StartValue: 1.35
			}
		}],
	},
	'deferredLearning': {
		id: "deferredLearning",
		name: "Deferred Learning",
		description: "XP picked up is only awarded every 60 seconds.",
		icon: 'twist-deferred-learning',
		mutationsToApply: [{
			target: 'player',
			modifications: [
				"xp-awarded-every-60-seconds"
			],
		}],
	},
	'bigHead': {
		id: 'bigHead',
		name: 'Big Brain, Frail Body',
		description: 'Gain 30% cooldown reduction due to your big brain, but lose 2 hearts and 15% damage.',
		icon: 'twist-big-brain-frail-body',
		mutationsToApply: [{
			target: 'player',
			modifications: [
				{ statName: StatType.cooldownInterval, operatorType: StatOperator.MULTIPLY, value: -0.3 },
				{ statName: StatType.allDamageMult, operatorType: StatOperator.MULTIPLY, value: -0.15 },
				{ statName: StatType.maxHealth, operatorType: StatOperator.SUM, value: -4 }
			]
		}],
	},
	'shriekerChaos': {
		id: 'shriekerChaos',
		name: 'Summer-time Salsa',
		description: 'The second act is plagued with panicked shriekers!',
		icon: 'twist-shrieker-chaos',
		mutationsToApply: [{
			target: 'choreo',
			modifications: {
				choreoDef: SHRIEKER_CHAOS_EVENTS
			}
		}],
	},
	'destructiveTendencies': {
		id: 'destructiveTendencies',
		name: 'Destructive Tendencies',
		description: 'Triples the amount of destructible objects in the world, but enemies no longer drop hearts.',
		icon: 'twist-diabolical-furniture',
		mutationsToApply: [
			{
				target: 'props',
				modifications: 'destructive-tendencies',
			},
			{
				target: 'stats',
				modifications: {
					statChanges: [
						[StatType.heartDropMulti, StatOperator.MULTIPLY, -1.0],
					],
				},
			}
		],
	},
	'hostageSituation': {
		id: 'hostageSituation',
		name: 'Hostage Situation',
		description: '[Hard] Pet Ransom Notes are now delivered directly to you, but enemies do not drop XP during Pet Events.',
		icon: 'twist-ransom-situation',
		mutationsToApply: [
			{
				target: 'stats',
				modifications: {
					binaryFlags: ['auto-magnet-pet-ransom-notes', 'no-xp-drops-during-pet-events'],
				},
			}
		],
	},
	'giantShamblers': {
		id: 'giantShamblers',
		name: "Attack of the Giant Shamblers",
		description: "When 5 shambling mounds are off-screen, they may be sacrificed to summon a giant shambling mound!",
		icon: 'twist-attack-of-the-giant-shamblers',
		mutationsToApply: [
			{
				target: 'recycler',
				modifications: {
					shamblerCount: 5,
					cooldown: 0
				}
			}
		]
	},
	'chainedTogether': {
		id: 'chainedTogether',
		name: "Chained Together",
		description: "Enemies hit by a chaining attack are knocked towards the previous target in the chain.",
		icon: 'twist-chained-together',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['chained-together']
			},
			{
				target: 'player',
				modifications: [
					{
						flag: 'chained-together',
						values: {
							knockback: 750
						}
					}
				]
			}
		],
		isDebug: true
	},
	'butterfingers': {
		id: 'butterfingers',
		name: "Butterfingers",
		description: "When you get hit, scatter the XP you collected since your last level up. Enemies hit by your dropped XP take a small amount of damage.",
		icon: 'twist-butter-fingers',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['butterfingers']
			}
		]
	},
	'petJailBreak': {
		id: 'petJailBreak',
		name: "Pet Jail Break",
		description: "Pets are now caged in pairs with two overlapping rings, While inside a ring, enemy spawn rates are increased by 50% (stacks per ring).",
		icon: 'twist-pet-jail-break',
		mutationsToApply: [
			{
				target: 'events',
				modifications: 'pet-poi-jail-break'
			}
		]
	},
	'shriekersShrieking': {
		id: 'shriekersShrieking',
		name: "Shriekers Shrieking",
		description: "Shriekers will now shout a battle cry, causing the closest 3 enemies to Berserk. More Shriekers spawn in act 1",
		icon: 'twist-shriekers-shrieking',
		mutationsToApply: [
			{
				target: 'replaceEnemies',
				modifications: {
					enemy: ENEMY_NAME.SHRIEKER,
					replacement: ENEMY_NAME.SHRIEKER_VINES
				}
			},
			{
				target: 'enemySpawner',
				modifications: [
					{
						min: 60,
						max: 90,
						packs: [
							{
								enemyName: ENEMY_NAME.SHRIEKER_VINES,
								equilibriumAmount: 1,
								maxAmount: 1,
								spawnAmount: 1,
								spawnRate: 14.0,
							},
						]
					},
					{
						min: 130,
						max: 160,
						packs: [
							{
								enemyName: ENEMY_NAME.SHRIEKER_VINES,
								equilibriumAmount: 1,
								maxAmount: 2,
								spawnAmount: 1,
								spawnRate: 14.0,
							},
						]
					},
					{
						min: 210,
						max: 240,
						packs: [
							{
								enemyName: ENEMY_NAME.SHRIEKER_VINES,
								equilibriumAmount: 1,
								maxAmount: 2,
								spawnAmount: 1,
								spawnRate: 14.0,
							},
						]
					},
				]
			}
		]
	},
	'hardcoreSurvival': {
		id: 'hardcoreSurvival',
		name: "Hardcore Survival",
		description: `[Brutal] You gain a stamina bar.  Moving depletes stamina, if you reach 0 stamina you are slowed while the stamina bar refills. ` +
			`Standing still and health pickups refill the stamina bar.`,
		icon: 'twist-hardcore-survival',
		mutationsToApply: [
			{
				target: 'function',
				modifications() {
					const player = GameState.player
					player.applyHardcoreSurvivalMutator()
				}
			}
		]
	},
	'bruteTrio': {
		id: 'bruteTrio',
		name: "The Brute Trio",
		description: `The climax of act 2 is a team of brutes that will work together to defeat you.`,
		icon: 'twist-the-brute-trio',
		mutationsToApply: [
			{
				target: 'replaceEnemies',
				modifications: {
					enemy: ENEMY_NAME.PRISMFLY,
					replacement: ENEMY_NAME.BRUTE_SHAMBLER
				}
			},
			{
				target: 'enemySpawner',
				modifications: [
					{
						min: 601,
						max: 625,
						packs: [
							{
								enemyName: ENEMY_NAME.BRUTE_EYE_BAT,
								equilibriumAmount: 0,
								maxAmount: 1,
								spawnAmount: 1,
								spawnRate: 999.0,
								isEventSpawn: true,
								isBoss: true
							},
							{
								enemyName: ENEMY_NAME.BRUTE_SHRIEKER,
								equilibriumAmount: 0,
								maxAmount: 1,
								spawnAmount: 1,
								spawnRate: 999.0,
								isEventSpawn: true,
								isBoss: true
							},
						]
					},
				]
			},
			{
				target: 'function',
				modifications() {
					EnemyEquilibriumSpawner.getInstance().bruteTrioActive = true
				},
			}
		]
	},
	'fallingFolkDance': {
		id: 'fallingFolkDance',
		name: 'Falling Folk Dance',
		description: 'The third act features choreographed assaults of enemies!',
		icon: 'twist-falling-folk-dance',
		mutationsToApply: [{
			target: 'choreo',
			modifications: {
				choreoDef: FALLING_FOLK_DANCE_EVENTS
			}
		}],
	},
	'springTimeDancing': {
		id: 'springTimeDancing',
		name: 'Spring-Time Dancing',
		description: '[Hard] The first act is filled with tricky enemy formations!',
		icon: 'twist-spring-time-dancing',
		mutationsToApply: [{
			target: 'choreo',
			modifications: {
				choreoDef: SPRING_TIME_DANCING_EVENTS
			}
		}],
	},
	'shamblingTowers': {
		id: 'shamblingTowers',
		name: "Shambling Towers",
		description: "Shambling Towers will randomly appear around the world, endlessly spawning Mounds until they are defeated.",
		icon: 'twist-shambling-towers',
		mutationsToApply: [
			{
				target: 'addEvent',
				modifications: {
					event: EventTypes.ShamblingTower,
					startCondition: {
						excludedBy: [],
						coolDown: [0, 0],
						spawnWindow: [
							{
								min: 20,
								max: 45,
							},
						],
						// events with a freq of 0 will constantly spawn based on current time, cooldown, and random spawn window time
						frequency: 0,
						maxConcurrent: 3, // up to 3 towers at once
						timeLimit: SHRINE_DESPAWN_TIME, // towers disappear if they go off-screen at the end of this time, allowing more to spawn
					}
				}
			}
		]
	},
	'combatArena': {
		id: 'combatArena',
		name: "Combat Arena",
		description: "Fighting rings will randomly appear around the world, granting powerful buffs if you complete their challenge. Once you enter, you cannot leave.",
		icon: 'twist-combat-arena',
		mutationsToApply: [
			{
				target: 'addEvent',
				modifications: {
					event: EventTypes.CombatArena,
					startCondition: {
						excludedBy: [],
						coolDown: [0, 0],
						spawnWindow: [
							{
								min: 55,
								max: 75,
							},
						],
						frequency: 0,
						maxConcurrent: 1,
						timeLimit: SHRINE_DESPAWN_TIME, // shrines disappear at the end of this time, allowing more to spawn
					}
				}
			}
		]
	},
	'paranormalExercise': {
		id: 'paranormalExercise',
		name: 'Paranormal Exercise',
		description: "[Hard] Every 30 seconds, you become a ghost for 7 seconds. While ghosted, you cannot attack with any weapons, but you cannot take damage.",
		icon: 'twist-paranormal-exercise',
		mutationsToApply: [
			{
				target: 'buffPlayer',
				modifications: BuffIdentifier.ParanormalExerciseApplier
			}
		]
	},
	'littlePlagueBearers': {
		id: 'littlePlagueBearers',
		name: 'Little Plague Bearers',
		description: 'Poisoned enemies leave temporary poison pools when they die. These can affect you. Blimpies always spread poison that lasts forever.',
		icon: 'twist-little-plague-bearers', 
		mutationsToApply: [
			{
				target: 'modifyEnemyDefinition',
				modifications(enemy: EnemyAI) {
					if (!enemy.states.dead.onDeadFunctions) {
						enemy.states.dead.onDeadFunctions = []
					}

					let radius: number
					let isPermanentPoison
					if (enemy.name === ENEMY_NAME.BLIMPIE || enemy.name === ENEMY_NAME.BLIMPIE_2 || enemy.name === ENEMY_NAME.BLIMPIE_3) {
						radius = 300
						isPermanentPoison = true
					} else {
						radius = 150
						isPermanentPoison = false
					}

					enemy.states.dead.onDeadFunctions.push((enemy: Enemy) => {
						let duration = 0
						if (!isPermanentPoison) {
							if (InGameTime.timeElapsedInSeconds > lastPlagueBearerPoolTime && InGameTime.timeElapsedInSeconds < nextPlagueBearerPoolTime) {
								return
							}

							if (!enemy.hasBuff(BuffIdentifier.Poison)) {
								return
							}

							lastPlagueBearerPoolTime = InGameTime.timeElapsedInSeconds
							nextPlagueBearerPoolTime = Math.getRandomFloat(5, 7) + InGameTime.timeElapsedInSeconds

							switch (InGameTime.currentAct) {
								case 1:
									duration = 30
									break
								case 2:
									duration = 45
									break
								case 3:
									duration = 60
									break
							}
						}

						PropPlacer.getInstance().placeHazardAtPosition(ElementalPoolType.Poison, enemy.position, radius, duration, true)
					})
				}
			}
		]
	},
	'speedDrafting': {
		id: 'speedDrafting',
		name: 'Speed Drafting',
		description: 'Moving extremely close to enemies will give you a speed boost, but kiting too far from enemies will slow you down.',
		icon: 'twist-speed-drafting',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['speed-drafting']
			},
			{
				target: 'function',
				modifications() {
					const player = GameState.player
					player.binaryFlagState['speed-drafting'].speedMult = player.stats.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, -0.33)
				}
			}
		],
	},
	'eyesFilledWithRage': {
		id: 'eyesFilledWithRage',
		name: 'Eyes Filled With Rage',
		description: "Eyebats go Berserk after taking damage. Special Eyebats spawn in acts 2 and 3.",
		icon: 'twist-eyes-filled-with-rage',
		mutationsToApply: [
			{
				target: 'modifyEnemyDefinition',
				modifications(enemyAi: EnemyAI) {
					// modify all eyebats (except choreo eyebats)
					if (enemyAi.name === ENEMY_NAME.EYEBAT_1 || enemyAi.name === ENEMY_NAME.EYEBAT_2 || enemyAi.name === ENEMY_NAME.EYEBAT_3 || enemyAi.name === ENEMY_NAME.BRUTE_EYE_BAT) {
						if (!enemyAi.states.onHit) {
							enemyAi.states.onHit = []
						}

						enemyAi.states.onHit.push({
							behaviour: OnHitBehaviours.BUFF,
							buff: BuffIdentifier.Berserk
						})
					}
				},
			},
			{
				target: 'enemySpawner',
				modifications: [
					// act 2
					{
						min: 340,
						max: 400,
						packs: [
							{
								enemyName: ENEMY_NAME.EYEBAT_2,
								equilibriumAmount: 3,
								maxAmount: 6,
								spawnAmount: 3,
								groupAmount: { min: 1, max: 1 },
								spawnRate: 8.0,
							},
						]
					},
					{
						min: 470,
						max: 530,
						packs: [
							{
								enemyName: ENEMY_NAME.EYEBAT_3,
								equilibriumAmount: 3,
								maxAmount: 6,
								spawnAmount: 3,
								groupAmount: { min: 1, max: 1 },
								spawnRate: 8.0,
							},
						]
					},
					// act 3
					{
						min: 750,
						max: 810,
						packs: [
							{
								enemyName: ENEMY_NAME.EYEBAT_2,
								equilibriumAmount: 2,
								maxAmount: 4,
								spawnAmount: 2,
								groupAmount: { min: 1, max: 1 },
								spawnRate: 8.0,
							},
							{
								enemyName: ENEMY_NAME.EYEBAT_3,
								equilibriumAmount: 2,
								maxAmount: 6,
								spawnAmount: 2,
								groupAmount: { min: 1, max: 1 },
								spawnRate: 8.0,
							},
						]
					},
					{
						min: 840,
						max: 900,
						packs: [
							{
								enemyName: ENEMY_NAME.EYEBAT_2,
								equilibriumAmount: 2,
								maxAmount: 4,
								spawnAmount: 2,
								groupAmount: { min: 1, max: 1 },
								spawnRate: 8.0,
							},
							{
								enemyName: ENEMY_NAME.EYEBAT_3,
								equilibriumAmount: 2,
								maxAmount: 4,
								spawnAmount: 2,
								groupAmount: { min: 1, max: 1 },
								spawnRate: 8.0,
							},
						]
					},
				]
			}
		]
	},
	fountainsOfMana: {
		id: 'fountainsOfMana',
		name: 'Fountains Of Mana',
		description: 'A shrine will appear randomly around the world. Standing inside the shrine will grant the user more XP the longer they stand in it. Enemies drop less experience while inside the shrine.',
		icon: 'twist-fountains-of-mana',
		mutationsToApply: [
			{
				target: 'addEvent',
				modifications: {
					event: EventTypes.FountainsOfMana,
					startCondition: {
						excludedBy: [],
						coolDown: [0, 0],
						spawnWindow: [
							{
								min: 120,
								max: 120,
							},
						],
						frequency: 0,
						maxConcurrent: 1,
						timeLimit: SHRINE_DESPAWN_TIME, // shrines disappear at the end of this time, allowing more to spawn
					},
				},
			},
			{
				target: 'player',
				modifications: ['fountains-of-mana-flag'],
			},
		],
	},
		'headStart': {
		id: 'headStart',
		name: "Head Start",
		description: "Begin the game with 3 free levels.  XP required to level up after level 25 is substantially increased",
		icon: 'twist-head-start',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['head-start']
			},
		]
	},
	'scrapyard':{
		id: 'scrapyard',
		name: "Scrapyard",
		description: "Every Paper Scrap that an enemy drops has a large chance to turn into Experience or small chance to turn into Rotten Hearts, or remain as Currency.",
		icon: 'twist-scrapyard',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['scrapyard']
			}
		]
	},
	'intelligenceDump':{
		id: 'intelligenceDump',
		name: "INT is my Dump Stat",
		description: "You gain half XP, but every level grants 12% damage.",
		icon: 'twist-intelligence-was-my-dumb-stat',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['intelligence-dump']
			},
		]
	},
	'randomRicochet':{
		id: 'randomRicochet',
		name: "Random Ricochet",
		description: "Each projectile that pierces and or splits, rotates to a completely random direction after each hit.",
		icon: 'twist-random-ricochet',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['random-ricochet']
			},
		]
	},
	'berserker':{
		id: 'berserker',
		name: "Berserker",
		description: "When you have two or less hearts remaining, gain increased attack, charge, and cooldown rate. Start the chapter at half health.",
		icon: 'twist-berserker',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['berserker']
			},
			{
				target: 'function',
				modifications() {
					const player = GameState.player
					player.currentHealth = Math.ceil(player.stats.getStat('maxHealth') / 2)
				}
			}
		]
  	},
	monsterMergeGame: {
		id: "monsterMergeGame",
		name: "Monster Merge Game",
		description: "Enemies of the same kind will randomly fuse together becoming larger and stronger.",
		icon: 'twist-monster-merge-game',
		mutationsToApply: [{
			target: 'function',
			modifications: () => {
				const MERGE_AMOUNT = 3
				const COOLDOWN_IN_SECONDS = 3
				const MERGE_CHANCE = 0.25
				const MERGE_RADIUS_PADDING = 50
				const MERGE_SCALE = 2
				AISystem.getInstance().setMonsterMergeTwist(MERGE_AMOUNT, COOLDOWN_IN_SECONDS, MERGE_CHANCE, MERGE_RADIUS_PADDING, MERGE_SCALE)
			}
		}],
	},
	kingShriekers: {
		id: "kingShriekers",
		name: "King Shriekers",
		description: "Shriekers are massive but spawn one at a time. Shriekers spawn more often in acts 1 and 2.",
		icon: 'twist-king-shriekers',
		mutationsToApply: [{
			target: 'enemies',
			modifications: [
				[[ENEMY_NAME.SHRIEKER, ENEMY_NAME.SHRIEKER_VINES], 'change-size', 2.5],
				[[ENEMY_NAME.SHRIEKER, ENEMY_NAME.SHRIEKER_VINES], 'change-health', 3.0],
				[[ENEMY_NAME.SHRIEKER, ENEMY_NAME.SHRIEKER_VINES], 'drop-amount-mult', 3.0],
			],
		},
		{
			target: 'modifyGrouping',
			modifications: [
				{
					enemy: ENEMY_NAME.SHRIEKER,
					groupAmount: {max: 0, min: 0}
				},
				{
					enemy: ENEMY_NAME.SHRIEKER_VINES,
					groupAmount: {max: 0, min: 0}
				}
			]
		},
		{
			target: 'enemySpawner',
			modifications: [
				{
					min: 20,
					max: 22,
					packs: [
						{
							enemyName: ENEMY_NAME.SHRIEKER,
							equilibriumAmount: 1,
							maxAmount: 5,
							spawnAmount: 1,
							spawnRate: 14.0,
						},
					]
				},
				{
					min: 110,
					max: 125,
					packs: [
						{
							enemyName: ENEMY_NAME.SHRIEKER,
							equilibriumAmount: 1,
							maxAmount: 5,
							spawnAmount: 1,
							spawnRate: 14.0,
						},
					]
				},
				{
					min: 200,
					max: 229,
					packs: [
						{
							enemyName: ENEMY_NAME.SHRIEKER,
							equilibriumAmount: 0,
							maxAmount: 5,
							spawnAmount: 1,
							spawnRate: 14
						}
					]
				},
				{
					min: 325,
					max: 349,
					packs: [
						{
							enemyName: ENEMY_NAME.SHRIEKER,
							equilibriumAmount: 0,
							maxAmount: 5,
							spawnAmount: 1,
							spawnRate: 14
						}
					]
				},
				{
					min: 400,
					max: 429,
					packs: [
						{
							enemyName: ENEMY_NAME.SHRIEKER,
							equilibriumAmount: 0,
							maxAmount: 5,
							spawnAmount: 1,
							spawnRate: 14
						}
					]
				},
				{
					min: 510,
					max: 529,
					packs: [
						{
							enemyName: ENEMY_NAME.SHRIEKER,
							equilibriumAmount: 1,
							maxAmount: 5,
							spawnAmount: 1,
							spawnRate: 14.0,
						},
					]
				},
			]
		}],		
	},
	spookyGhosts: {
		id: 'spookyGhosts',
		name: 'Spooky Ghosts',
		description: "Enemies randomly turn into Ghosts. Ghosts cannot deal damage but move faster and have a chance to drop supernatural XP.",
		icon: 'twist-spooky-ghosts',
		mutationsToApply: [{
			target: 'function',
			modifications: () => {
				const COOLDOWN_IN_SECONDS = 6
				// Chance to ghost when cooldown is up (creates the slightest variation in how often this occurs unless set to an extremely small percent)
				const GHOST_CHANCE = 0.001
				const GHOST_RADIUS = 300
				const ENEMIES_TO_GHOST = 3
				AISystem.getInstance().setSpookyGhostsTwist(COOLDOWN_IN_SECONDS, GHOST_CHANCE, GHOST_RADIUS, ENEMIES_TO_GHOST)
			}
		}],
	},
	insectFever: {
		id: "insectFever",
		name: "Insect Fever",
		description: "When objects are destroyed, they spew a horde of Mosquitos. Double the amount of destructible objects.",
		icon: 'twist-insect-fever',
		mutationsToApply: [
			{
				target: 'props',
				modifications: 'insect-fever'
			},
		],
	},
	dontDropTheEgg: {
		id: 'dontDropTheEgg',
		name: `Don't Drop the Egg!`,
		description: `A new powerup is enabled! A brittle egg occasionally drops from enemies and objects. Picking up the egg starts a challenge with risk and reward: don't drop the egg for 30 seconds!`,
		icon: `twist-dont-drop-the-egg`,
		mutationsToApply: [
			{
				target: 'pickups',
				modifications: {
					enemyPickupModifier: [[GroundPickupConfigType.CreepyEgg, 150]],
					propPickupModifier: [[GroundPickupConfigType.CreepyEgg, 15]]
				}
			},
			{
				target: 'addEvent',
				modifications: {
					event: EventTypes.CreepyEgg,
					startCondition: {
						excludedBy: [],
						coolDown: [15, 60], // future instances at now + spawnWindow (60) + random between 15 and 60
						spawnWindow: [ // first instance at 60 EXACTLY
							{
								min: 60,
								max: 60,
							},
						],
						frequency: 0,
						maxConcurrent: 1,
					}
				}
			}
		]
	},
	'enraged': {
		id: 'enraged',
		name: 'Enraged',
		description: `Take double damage, but gain increased attack, charge, reload, and cooldown speed for a short duration after taking damage. Start with 'Have Heart' unlocked.`,
		icon: 'twist-enraged',
		mutationsToApply: [
			{
				target: 'player',
				modifications: ['enraged']
			},
			{
				target: 'function',
				modifications() {
					UpgradeManager.redeemUpgradeByName('Have Heart')
				},
			},
		],
	},
	elixirOfSomething: {
		id: 'elixirOfSomething',
		name: `Elixir of... Something?`,
		description: `A new powerup is enabled! Picking up the mystery elixir emparts a random effect that both enhances and diminishes your stats!`,
		icon: `twist-elixir-of-something`,
		mutationsToApply: [
			{
				target: 'pickups',
				modifications: {
					enemyPickupModifier: [[GroundPickupConfigType.BiggifyElixir, 110], [GroundPickupConfigType.DwindleyElixir, 110]],
					propPickupModifier: [[GroundPickupConfigType.BiggifyElixir, 11], [GroundPickupConfigType.DwindleyElixir, 11]]
				}
			}
		]
	},
	spicyPepper: {
		id: 'spicyPepper',
		name: `Spicy Pepper`,
		description: `New spicy peppers drop from enemies and objects. Picking up the pepper shoots you in the direction you are moving. Hitting an enemy during this dash does not damage you and ignites enemies on fire.`,
		icon: `twist-spicy-pepper`,
		mutationsToApply: [
			{
				target: 'pickups',
				modifications: {
					enemyPickupModifier: [[GroundPickupConfigType.SpicyPepper, 300]],
					propPickupModifier: [[GroundPickupConfigType.SpicyPepper, 30]]
				}
			}
		]
	},
	monsterWhistle: {
		id: 'monsterWhistle',
		name: `Monster Whistle`,
		description: `New Monster Whistle powerups occasionally drop from enemies and objects. Picking up a Monster Whistle immediately summons more enemies, with a chance of summoning a Loot Goblin.`,
		icon: `twist-monster-whistle`,
		mutationsToApply: [
			{
				target: 'pickups',
				modifications: {
					enemyPickupModifier: [[GroundPickupConfigType.MonsterWhistle, 220]],
					propPickupModifier: [[GroundPickupConfigType.MonsterWhistle, 22]]
				}
			}
		]
	},
	betterGoFast: {
		id: 'betterGoFast',
		name: `Better Go Fast`,
		description: `Start with a free Killstreak upgrade and full killstreak bar. If the bar ever depletes, you take a full heart of damage.`,
		icon: `twist-better-go-fast`,
		mutationsToApply: [
			{
				target: 'stats',
				modifications: {
					statChanges: [
					],
					binaryFlags: ['twist-killstreak-better-go-fast'],
				},
			},
			{
				target: 'function',
				modifications() {
					// give the player the first killstreak upgrade
					UpgradeManager.redeemUpgradeByName('Rapid Killer')
					GameState.player.setKillstreak(1)
					
					// make the second enemy pack the starting pack
					const packs = EnemyEquilibriumSpawner.getInstance().packsToAdd
					try {
						packs[0].max = -1
						packs[1].min = -1
					} catch (err) {
						// this is just for adding the twist through debug during the game
						// no error is thrown normally
						console.error(err)
					}
				},
			}
		]
	},
	spectralFarmer: {
		id: 'spectralFarmer',
		name: 'Spectral Farmer',
		description: `For 20 seconds every 50 seconds, you gain a Spectral Hoe that floats near you. Walking near XP with the hoe will now PLANT it instead of picking it up.`,
		icon: 'twist-spectral-farmer',
		mutationsToApply: [
			{
				target: 'function',
				modifications() {
					PlantedXP.pool = new ObjectPoolTyped<PlantedXP, PlantedXPParams>(() => {
						return new PlantedXP()
					}, {}, 100, 1)
				},
			},
			{
				target: 'buffPlayer',
				modifications: BuffIdentifier.SpectralFarmerApplier
			}
		]
	},
	temporalDistortion: {
		id: 'temporalDistortion',
		name: 'Temporal Distortion',
		description: `Time rings will randomly appear around the world, causing everything inside of them to move in slow motion.`,
		icon: `twist-temporal-distortion`,
		mutationsToApply: [
			{
				target: 'addEvent',
				modifications: {
					event: EventTypes.TemporalDistortion,
					startCondition: {
						excludedBy: [],
						coolDown: 0,
						spawnWindow: [{
							min: 0,
							max: 90
						}],
						frequency: 0,
						maxConcurrent: 1,
						timeLimit: 20
					}
				}
			}
		]
	},
	wildRotSons: {
		id: 'wildRotSons',
		name: 'Wild Rot Sons',
		description: 'A group of Wild Rot Sons will spawn randomly, spreading their poisonous spores against friend and foe alike.',
		icon: 'twist-wild-rot-sons',
		mutationsToApply: [
			{
				target: 'addEvent',
				modifications: {
					event: EventTypes.WildRotSons,
					startCondition: {
						excludedBy: [],
						coolDown: 60,
						spawnWindow: [
							{
								min: 0, // 14 min
								max: 0,
							},
						],
						frequency: 0,
						maxConcurrent: 1,
						timeLimit: 60,
					},
				},
			},
		],
	},
	roamingWildlings: {
		id: 'roamingWildlings',
		name: 'Roaming Wildlings',
		description: 'Wild, untamed Pets now roam the land. Standing near these pets long enough will tame them, adding them to your party.',
		icon: 'twist-roaming-wildlings',
		mutationsToApply: [
			{
				target: 'addEvent',
				modifications: {
					event: EventTypes.RoamingWildlings,
					startCondition: {
						excludedBy: [],
						coolDown: 90,
						spawnWindow: [
							{
								min: 105,
								max: 135,
							},
						],
						frequency: 0,
						maxConcurrent: 1,
					},
				},
			},
		],
	},
}
