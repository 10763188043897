/**
 * Sometimes it's useful to see the history of an object after some condition happens (ie pooled objects being double-freed)
 */

import { RealTime } from "./time"


const debugObjToHistoryMap: Map<any, string[]> = new Map()

/** adds entry to the history for obj */
export function debugAddToHistory(obj: any, message: string) {
	if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
		if (!debugObjToHistoryMap.get(obj)) {
			debugObjToHistoryMap.set(obj, [])
		}

		message += ` f:${RealTime.currentFrame}`

		if (obj.definition && obj.definition.identifier) {
			message += ' id:' + obj.definition.identifier
		}

		const owner = obj.owner
		if (owner && owner.name) {
			message += ' owner:' + owner.name
		}
		if (owner && owner.buffs) {
			message += ' c:' + owner.buffs.length
		}
		debugObjToHistoryMap.get(obj).push(message)
	}
}

/** print out all history entries for obj */
export function debugPrintHistory(obj: any) {
	if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
		if (!debugObjToHistoryMap.get(obj)) {
			debugObjToHistoryMap.set(obj, [])
		}

		const history = debugObjToHistoryMap.get(obj)
		history.forEach((key, value) => console.log(key))
	}
}