import AISystem from "../entities/enemies/ai-system"
import { IEntity } from "../entities/entity-interfaces"
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import ClientPlayerInput from "./client-player-input"
import { Camera } from "./graphics/camera-logic"
import { Renderer } from "./graphics/renderer"
import { CHARACTER_URL_KEY, GameClientDEBUG, WEAPON_URL_KEY } from "./game-client-DEBUG-ZONE"
import { GameState } from "./game-state"
import { PauseManager } from "./pause-manager"
import { InGameTime, MAX_DELTA_MS, realTimeHighResolutionTimestamp, updateCountdownTimer } from "../utils/time"
import { ProjectileSystem } from "../projectiles/projectile-system"
import { BuffSystem } from "../buffs/buff-system"
import BuffData from "../buffs/buff-map"
import { RealTime } from '../utils/time'
import CollisionSystem from "./collision/collision-system"
import { CooldownSystem } from "../cooldowns/cooldown-system"
import { UpgradeManager } from "../upgrades/upgrade-manager"
import { UPGRADE_COLLECTIONS } from "../upgrades/upgrade-definitions"
import { PlayerProjectile, ProjectileInitialParams } from "../projectiles/projectile"
import { ObjectPool, ObjectPoolTyped } from "../utils/third-party/object-pool"
import { makeGroundPickupPools } from "../entities/pickups/ground-pickup"
import { Audio } from "./audio"
import { MutatorManager } from "../mutators/mutator-manager"
import { isMutatorShortName, MutatorShortName, MUTATOR_DEFINITIONS } from "../mutators/mutator-definitions"
import { UI } from "../ui/ui"
import { simpleAnimation_shutdown, simpleAnimation_update } from "../utils/simple-animation-system"
import { callbacks_shutdown, callbacks_update } from "../utils/callback-system"
import { PropPlacer } from "../world-generation/prop-placement"
import { EnemyProjectile, EnemyProjectileInitialParams } from "../projectiles/enemy-projectile"
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner"
import { ChoreographedEventSpawner } from "../entities/enemies/choreographed-event-spawner"
import { attachments_shutdown, attachments_update } from "../utils/attachments-system"
import PlayerMetricsSystem from "../metrics/metric-system"
import { GameplayTimedEventSystem } from "../events/gameplay-timed-event-system"
import { VictoryDeathManager } from "./victory-death-manager"
import { MetaPerkManager } from "../upgrades/meta/meta-perk-manager"
import { Player } from "../entities/player"
import { CharacterType } from "../game-data/characters"
import { AllWeaponTypes } from "../weapons/weapon-types"
import { GenerationType, WORLD_DATA, MapOption, MapNames } from "../world-generation/world-data"
import { getUnlocks } from "../utils/api/griddle-requests"
import { MetaUnlocksManager } from "../upgrades/meta/meta-unlocks-manager"
import { debugConfig } from "../utils/debug-config"
import { LocalSettings } from "../settings/local-settings"
import { ScrollingBackground } from "./graphics/scrolling-background"
import { TutorialFlagsEnum } from "../ftue/tutorial-flags"
import { GoblinGameplayEvent } from "../events/goblin-gameplay-event"
import { PetRescueEventSystem } from "../events/pet-rescue-gameplay-event"
import { GlobalStatList } from "../stats/entity-stat-list"
import { EnemyAI } from "../entities/enemies/ai-types"
import { EnemyDefinitions } from "../entities/enemies/enemy-definitions"
import { cloneDeepSerializable } from "../utils/object-util"
import { AssetManager } from "../web/asset-manager"
import { EffectConfig } from "./graphics/pfx/effectConfig"
import { Effect } from "./graphics/pfx/effect"
import { MapSystem } from "../world-generation/map-system"
import { WildRotSonEventSystem } from "../events/wild-rot-sons-gameplay-event"
import { RoamingWildlingsEventSystem } from "../events/roaming-wildlings-gameplay-event"

const LOGIC_TICKRATE = 60
const TIME_PER_LOGIC_TICK = 1000 / LOGIC_TICKRATE

let currentFrameId: number


export async function startGameClient(twists, mapSelected) {
	let gameClient: GameClient

	try {
		const unlocksAndPerks = await getUnlocks()

		const metaUnlocksInstance = MetaUnlocksManager.getInstance()
		metaUnlocksInstance.setUnlocks(unlocksAndPerks.unlocks)

		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			if (debugConfig.unlockEverything) {
				metaUnlocksInstance.unlockEverything()
			}
		}
		
		gameClient = new GameClient({ twists: twists, mapSelected: mapSelected, unlocksAndPerks })

		if (!debugConfig.benchmarkMode) {
			// already did this if benchmark mode
			metaUnlocksInstance.applyUnlocks()
		}

		Audio.getInstance().playBgm('MUS_RIPPLE_LP')

		if (!metaUnlocksInstance.isPlayerLoadoutValid(GameState.player)) {
			throw new Error(`Cheating loadout`)
		}
	} catch (err) {
		// sucks to suck I guess
		// @TODO show error before reloading
		if (process.env.NODE_ENV !== 'local') {
			window.location.reload() // @TODO don't do this in electron
		}
		console.error(err)
	}

	// Set previous frametime
	const now = InGameTime.highResolutionTimestamp()
	RealTime.previousFrameStartTimestamp = now
	RealTime.clampedDelta = 0
	GameState.player.invulnFromDamage(5_000) // a little buffer to get out of ground hazards at the start of the game
	InGameTime.unpause()
	PauseManager.unpauseGame('end-game')
	currentFrameId = requestAnimationFrame(() => gameClient.update(0, now))

}

type GameClientParams = { twists?: MutatorShortName[], mapSelected?: MapNames }

let instanceCount = 0

const debugFrameTimes: number[] = []
let startTimestamp = null
let recording = false

export class GameClient {
	static getInstance(params?: GameClientParams) {
		if (!GameClient.instance) {
			if (params === undefined) {
				throw new Error('Insufficient constructor parameters for GameClient getInstance(); aborting startup')
			} else {
				GameClient.instance = new GameClient(params)
			}
		}

		return GameClient.instance
	}

	static instance: GameClient
	renderer: Renderer
	mapSystem: MapSystem
	scrollingBackground: ScrollingBackground
	collisionSystem: CollisionSystem

	propPlacer: PropPlacer
	enemyEventSpawner: ChoreographedEventSpawner
	// goblinEventSystem: GoblinEventSystem

	enemyDefintions: EnemyAI[]

	ambiencePFXName: string
	currentAmbience: Effect

	private boundInvokeNextUpdate: () => void

	private boundOnBlur: () => void
	private boundOnFocus: () => void

	private _isShutDown: boolean

	static get isShutDown() {
		if (!GameClient.instance) {
			return true
		}
		return GameClient.instance._isShutDown
	}

	constructor(params) {
		GameClient.instance = this

		const searchParams = new URLSearchParams(window.location.search)
		if(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging' && searchParams.get('colliderDebugShortcut')) {
			debugConfig.hugeDamageBuff = true
			debugConfig.collisions.drawBeamColliders = true
			debugConfig.collisions.drawEnemyColliders = true
			debugConfig.collisions.drawProjectileColliders = true
			debugConfig.collisions.drawWackGridDebug = true
			debugConfig.collisions.showGrid = true
			debugConfig.props = false
			debugConfig.disablePickups = true
		}

		UI.getInstance().emitMutation('endChapter/reset')
		UI.getInstance().emitMutation('paused/reset')

		try {
			this.renderer = new Renderer()
		} catch (error) {
			if (error.message.includes('WebGL')) {
				UI.getInstance().emitMutation('ui/showStartupError', 'errors.startup_error_web_gl')
			} else {
				UI.getInstance().emitMutation('errors.startup_error_unknown')
			}
		}
		this.mapSystem = MapSystem.getInstance()
		this.mapSystem.setMap(params.mapSelected)

		

		this.collisionSystem = new CollisionSystem()

		this.propPlacer = PropPlacer.getInstance()
		this.propPlacer.setPropMapConfig(this.mapSystem.getMapConfig())

		this.enemyEventSpawner = new ChoreographedEventSpawner()
		// this.goblinEventSystem = GoblinEventSystem.getInstance()

		this.enemyDefintions = []
		for (let i = 0; i < EnemyDefinitions.length; ++i) {
			this.enemyDefintions.push(cloneDeepSerializable(EnemyDefinitions[i]) as EnemyAI)
		}

		InGameTime.start()
		InGameTime.pause()
		Audio.getInstance()

		let { character, weapon } = UI.getInstance().store.getters['characterSelect/selectedCharacterAndWeapon']

		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			// @TODO disable this stuff for release
			weapon = Number.parseInt(searchParams.get(WEAPON_URL_KEY)) || weapon
			character = Number.parseInt(searchParams.get(CHARACTER_URL_KEY)) || character
			console.log(`Character: ${CharacterType[character]}`)
			console.log(`Weapon:    ${AllWeaponTypes[weapon]}`)
		}
		
		const player = new Player(character, weapon)
		player.setMapConfig(this.mapSystem.getMapConfig())

		GameState.addEntity(player)
		GameState.setPlayer(player)

		//@ts-expect-error
		UpgradeManager.init(UPGRADE_COLLECTIONS)

		MetaPerkManager.getInstance().setPerks(params.unlocksAndPerks.perks, player)

		const map = this.mapSystem.getMapConfig()
		if (map.generationConfig.type === GenerationType.Infinite) {
			player.teleport(WORLD_DATA.infiniteWorldDimension / 2, WORLD_DATA.infiniteWorldDimension / 2)
		} else {
			player.teleport(map.generationConfig.width / 2, WORLD_DATA.infiniteWorldDimension / 2)
		}
		UI.getInstance().emitMutation('ui/updateSelectedMap', this.mapSystem.mapType)

		this.renderer.mgRenderer.addDisplayObjectToScene(player.model)

		Camera.getInstance().teleportCamera(player.x, player.y)

		this.scrollingBackground = new ScrollingBackground(player, this.mapSystem.getMapConfig().grassTexture, this.mapSystem.getMapConfig().groundZoom)
		this.createAmbience()



		ClientPlayerInput.getInstance(this.renderer)
		ClientPlayerInput.preventSpaceInput = true
		ProjectileSystem.getInstance()

		EnemyEquilibriumSpawner.getInstance().init()

		BuffData.initializeBuffMapData()
		

		// This has to init before the AISystem if we want to apply debug mutators because of enemy object pooling
		MutatorManager.init(MUTATOR_DEFINITIONS)
		PropPlacer.getInstance().addMapSpecificHazardProps()
		// Apply mutators from url search params
		if ((process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') || UI.getInstance().store.getters['user/isQa']) {
			const url = new URL(window.location.toString())
			let mutators = url.searchParams.getAll('mutators')
			if (mutators.length === 1 && mutators[0].startsWith('[')) {
				mutators = JSON.parse(mutators[0])
			}

			for (const mutator of mutators) {
				if (isMutatorShortName(mutator)) {
					MutatorManager.applyMutator(mutator, true)
				}
			}
		}

		if (params.twists) {
			for (const twist of params.twists) {
				if (isMutatorShortName(twist.id)) {
					console.log('Still applying twists via params, sorry', params.twists)
					MutatorManager.applyMutator(twist.id)
				}
			}
		}
		
		AISystem.getInstance()
		
		CooldownSystem.getInstance()
		EnemyEquilibriumSpawner.getInstance().initStages()
		PlayerMetricsSystem.getInstance()

		if (!PlayerProjectile.objectPool) {
			PlayerProjectile.objectPool = new ObjectPoolTyped<PlayerProjectile, ProjectileInitialParams>(() => new PlayerProjectile(), undefined, 256, 4, 'projectile-pool')
			EnemyProjectile.objectPool = new ObjectPoolTyped<EnemyProjectile, EnemyProjectileInitialParams>(() => new EnemyProjectile(), undefined, 256, 4, 'enemy-projectile-pool')
			makeGroundPickupPools()
		}

		this.boundOnBlur = this.onBlur.bind(this)
		this.boundOnFocus = this.onFocus.bind(this)

		window.addEventListener('blur', this.boundOnBlur)
		window.addEventListener('focus', this.boundOnFocus)

		if ((process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') || UI.getInstance().store.getters['user/isQa']) {
			UpgradeManager.applyURLUpgrades()
			GameClientDEBUG.initializeDebugTools()
		}

		const seed = debugConfig.benchmarkMode ? debugConfig.benchmarkSeed : Date.now()
		this.propPlacer.placeProps(seed)
		GameplayTimedEventSystem.getInstance()

		this.boundInvokeNextUpdate = this.invokeNextUpdate.bind(this)

		UI.getInstance().emitMutation('ftue/completeFlag', TutorialFlagsEnum.PlayedStory)

		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			GameClientDEBUG.addDummyStuff(this)
		}

		UI.getInstance().emitAction('settings/applySettings')

		UI.getInstance().emitAction('ui/showCanvas')
	}

	update(delta: timeInSeconds, now: timeInMilliseconds) {
		if (this._isShutDown) {
			console.error(`Shut down game client tried to update!!`)
			return
		}

		RealTime.frameStartTimestamp = realTimeHighResolutionTimestamp()

		const start = InGameTime.highResolutionTimestamp()
		if (!PauseManager.isPaused()) {
			// RealTime.timeElapsedSinceStartup += delta //no
			updateCountdownTimer()

			ClientPlayerInput.getInstance().update(delta)
			this.scrollingBackground.update(delta)
			this.renderer.update(delta)

			GameState.entityList.forEach((entity: IEntity) => {
				entity.update(delta * entity.timeScale, start)
			})

			simpleAnimation_update(delta)
			attachments_update(delta)
			callbacks_update(delta, start)

			CooldownSystem.getInstance().update(delta, start)
			Camera.getInstance().update(delta, start)
			BuffSystem.update(delta, now)

			ProjectileSystem.getInstance().update(delta)

			if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
				GameClientDEBUG.debugUpdate(delta, this)
			}

			this.enemyEventSpawner.update(delta)
			// this.goblinEventSystem.update(delta)
			EnemyEquilibriumSpawner.getInstance().update(delta)
			AISystem.getInstance().update(delta)

			this.collisionSystem.update(delta)
			this.propPlacer.update(delta)
			GameplayTimedEventSystem.getInstance().update(delta)
			VictoryDeathManager.update()
			Audio.getInstance().update()	
		} else {
			// stuff that happens even when paused, like audio or visual effects
		}


		UI.getInstance().emitMutation('ui/updateNowTimestamp', start)

		RealTime.currentFrame++
		RealTime.previousFrameStartTimestamp = RealTime.frameStartTimestamp

		currentFrameId = window.requestAnimationFrame(this.boundInvokeNextUpdate)

		// console.log(`paused overall: ${PauseManager.pauseState}, logic: ${PauseManager.isPaused(PauseState.LogicPaused)}, visual: ${PauseManager.isPaused(PauseState.VisualPaused)}`)
		const end = InGameTime.highResolutionTimestamp()
		if (end - start > TIME_PER_LOGIC_TICK) {
			const event = new CustomEvent('GameLoopHitched', {
				detail: {
					actualDuration: end - start,
					expectedDuration: TIME_PER_LOGIC_TICK,
					overage: end - start - TIME_PER_LOGIC_TICK,
				}
			})
			document.dispatchEvent(event)
		}
		this.updateAmbience()
	}

	shutDown() {
		this._isShutDown = true

		UI.getInstance().emitMutation('ui/reset')
		UI.getInstance().emitMutation('player/reset')
		UI.getInstance().emitMutation('levelUp/reset')

		GameState.cleanUp()

		ObjectPool.returnAllObjectsToPools()

		// background cleanup?
		this.scrollingBackground.remove()
		this.scrollingBackground = null

		// collision system cleanup + check ?
		CollisionSystem.getInstance().cleanUp()

		AISystem.getInstance().cleanUp()
		ChoreographedEventSpawner.getInstance().cleanUp()

		ProjectileSystem.getInstance().cleanUp()

		UpgradeManager.destroy()
		MutatorManager.destroy()
		PropPlacer.destroy()
		EnemyEquilibriumSpawner.destroy()
		this.enemyEventSpawner = null
		Camera.shutdown()
		PlayerMetricsSystem.destroy()

		PetRescueEventSystem.destroy()
		GoblinGameplayEvent.destroy()
		WildRotSonEventSystem.destroy()
		RoamingWildlingsEventSystem.destroy()
		GameplayTimedEventSystem.destroy()
		AISystem.destroy()

		ClientPlayerInput.shutdown()

		// ground pickups?

		VictoryDeathManager.cleanUp()

		Renderer.getInstance().cleanUp()

		callbacks_shutdown()
		simpleAnimation_shutdown()
		attachments_shutdown()

		InGameTime.reset()

		window.removeEventListener('blur', this.boundOnBlur)
		window.removeEventListener('focus', this.boundOnFocus)


		GlobalStatList.clearAllState()
		const children = GlobalStatList._childStatLists
		if (children.length > 0) {
			console.error(`Warning: possible memory leak, ${children.length} stat lists not removed from GlobalStatList`)
		}

		window.cancelAnimationFrame(currentFrameId)

		Audio.getInstance().stopBgm()

		document.exitPointerLock()
	}

	private invokeNextUpdate() {
		if (this._isShutDown) {
			return
		}

		const realTimeStamp = realTimeHighResolutionTimestamp()

		const msDiff = (realTimeHighResolutionTimestamp() - RealTime.previousFrameStartTimestamp)
		let nextDelta = msDiff * InGameTime.timeScale
		if (PauseManager.isPaused()) {
			nextDelta = 0
		}

		nextDelta = Math.clamp(nextDelta, 0, MAX_DELTA_MS)
		InGameTime.timeAccumulated += nextDelta
		nextDelta *= 0.001

		const nextNow = InGameTime.highResolutionTimestamp()

		if (debugConfig.benchmarkMode) {
			if (recording) {
				debugFrameTimes.push(msDiff)
			}

			if (!startTimestamp) {
				startTimestamp = realTimeStamp
				recording = true
			} else if (recording) {
				if (realTimeStamp - startTimestamp >= 20 * 1_000) {
					PauseManager.pauseGame('user')
					let sum = 0
					for (let i = 0; i < debugFrameTimes.length; ++i) {
						sum += debugFrameTimes[i]
					}
					console.log(`!!! FRAME TIMES !! avg: ${sum / debugFrameTimes.length}\n (${debugFrameTimes.length} total frames)`)
					recording = false
					Renderer.getInstance().clearRenderTimes()
				}
			}
		}

		this.update(nextDelta, nextNow)
	}

	private onBlur() {
		UI.getInstance().emitAction('ui/setPausedByUser', "focus")
	}

	private onFocus() {
		UI.getInstance().emitAction('ui/setUnpausedByUser', "focus")
	}

	updateAmbience(): void {
		if (this.currentAmbience && GameState.player) {
			this.currentAmbience.x = GameState.player.x
			this.currentAmbience.y = GameState.player.y
		}
	}

	createAmbience(): void {
		if (this.mapSystem.getMapConfig().ambienceEffect) {
			this.ambiencePFXName = this.mapSystem.getMapConfig().ambienceEffect

			const pfxAsset = AssetManager.getInstance().getAssetByName(this.ambiencePFXName).data as EffectConfig
			this.currentAmbience = new Effect(pfxAsset, this.renderer.cameraState)

			this.currentAmbience.x = GameState.player.x
			this.currentAmbience.y = GameState.player.y
			this.currentAmbience.zIndex = GameState.player.y

			this.renderer.fgRenderer.addEffectToScene(this.currentAmbience)
		}
	}
}
