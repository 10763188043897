<template>
	<div id="big-message">
		<transition name="fade">
			<div class="big-message-wrapper" v-if="currentBigMessage" :lang="this.$i18n.locale">
				<div class="decoration">
					<img src="../../../../assets/ui/divider/divider-graphic.png" />
				</div>
				<div class="text-container">
					<div class="title" :lang="this.$i18n.locale">{{ title }}</div>
					<div class="subtitle" :lang="this.$i18n.locale">{{ subtitle }}</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'BigMessage',
	components: {},
	props: {},
	data() {
		return {
			clearTimeout: null,
		}
	},
	computed: {
		...mapGetters('ui', ['currentBigMessage']),
		title() {
			return this.currentBigMessage?.title || ''
		},
		subtitle() {
			return this.currentBigMessage?.subtitle || ''
		},
	},
	watch: {
		currentBigMessage() {
			if (this.clearTimeout) {
				return
			}
			if (this.currentBigMessage) {
				const { title, subtitle } = this.currentBigMessage
				const wordCount = (title ? title.split(' ').length : 0) + (subtitle ? subtitle.split(' ').length : 0)
				const appearFor = 3200 + wordCount * 400
				this.clearTimeout = setTimeout(() => {
					this.removeCurrentBigMessage()
					this.clearTimeout = null
				}, appearFor)
			}
		},
	},
	methods: {
		...mapMutations('ui', ['removeCurrentBigMessage']),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#big-message {
	width: 100vw;
	left: 0px;
	right: 0px;
	position: absolute;
	top: 50px;

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.8s;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
}

.big-message-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	.decoration {
		height: 85px;
		margin-top: 50px;
		img {
			transform: scale(0.35);
			transform-origin: top center;
		}
	}

	div {
		color: @green;
		text-transform: uppercase;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}

	.text-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 25% 45%;
		background-color: rgba(5, 25, 45, 0.75);
		// offset-x / offset-y / blur-radius / spread-radius / color
		box-shadow: 0px 0px 40px 50px rgba(5, 25, 45, 0.75);
		width: fit-content;
		height: fit-content;

		.title {
			font-size: 50px;
			padding-right: 1.5em;
			padding-left: 1.5em;
		}

		.subtitle {
			font-size: 40px;
			margin-bottom: -0.5em;
		}
	}
}
</style>
