import { Enemy } from "../entities/enemies/enemy"
import { Player } from "../entities/player"
import EntityStatList from "../stats/entity-stat-list"
import { IStatListOwner } from "./stat-list-owner"
import { AllWeaponTypes } from "./weapon-types"

export abstract class PassiveSkill implements IStatListOwner {
    player: Player
    statList: EntityStatList

    abstract weaponType: AllWeaponTypes

    constructor(player: Player) {
        this.player = player
        this.statList = new EntityStatList(this.resetStatsFunction.bind(this), player.stats)
    }

    abstract onCreate(player: Player): void
    abstract onShot(): void
    abstract onEnemyHit(enemy: Enemy, damageDealt: number): void
    abstract onEnemyKilled(enemy: Enemy): void
    abstract update(delta: number): void

    abstract resetStatsFunction(statList: EntityStatList): void
}
