import { InGameTime } from "../../utils/time"
import { UI } from "../ui"

let nowInterval = null

export const timeUIStore = () => {
    return {
        namespaced: true,
        state: {
            inGameTimestamp: 0,
            date: new Date(),
            isPaused: false
        },
        getters: {
            inGameTimestamp(state) {
                return state.inGameTimestamp
            },
            date(state) {
                return state.date
            }
        },
        mutations: {
            updateInGameTimestamp(state, timestamp) {
                state.inGameTimestamp = timestamp
            },
            updateDate(state, date) {
                state.date = date
            },
            setIsPaused(state, isPaused) {
                state.isPaused = isPaused
            }
        },
        actions: {

        }
    }
}

export function startTimeUpdateInterval(uiInst: UI) {
    if(nowInterval) {
        clearInterval(nowInterval)
    }

    nowInterval = setInterval(() => {
        if (InGameTime.isInitialized()) {
            uiInst.emitMutation('time/updateInGameTimestamp', InGameTime.highResolutionTimestamp())
            uiInst.emitMutation('time/updateDate', new Date())
        }
    }, 50)
}
