import { Texture } from 'pixi.js'
import {Renderer} from '../engine/graphics/renderer'
import { InstancedSprite } from '../engine/graphics/instanced-sprite'
import { Vector } from 'sat'
import { radians } from '../utils/primitive-types'
import { AssetManager } from '../web/asset-manager'

// Stolen from https://github.dev/OtherOceanInteractive/Operation-Hotcakes/pull/70

const ARROW_TO_PLAYER_X_DIST = 400 // the default distance from the player to the arrow
const ARROW_SHRINK_DIST = 200 // the distance the arrow has to be from the target before the X dist starts shrinking

const MAP_DISTANCE_FROM_ARROW = 320
const MAP_ARROW_SHRINK_DIST = 160

const HALF_ARROW_PLAYER_X_DIST = ARROW_TO_PLAYER_X_DIST / 2
const DOUBLE_ARROW_PLAYER_X_DIST = ARROW_TO_PLAYER_X_DIST * 2
const ARROW_TO_PLAYER_Y_OFFSET = -75 // moves the arrow up so if it's pointing right, the arrow is the same height as the player

export default class NavigationArrow {

	arrowSprite: InstancedSprite
	mapIcon: InstancedSprite

	destinationX = 52000
	destinationY = 55000

	arrowToPlayerDistance = ARROW_TO_PLAYER_X_DIST
	arrowShrinkDistance = ARROW_SHRINK_DIST

	position: Vector = new Vector()

	isShowing: boolean = false
	showingIcon: boolean = false

	isBlinking: boolean = false
	private blinkTimer: number = 0
	private lerpAlphaTarget: number = 0
	private lerpTargetMod: number = 1

	private _alpha: number = 1
	private _arrowRotatedPosition = new Vector()
	private _mapRotatedPosition = new Vector()
	private _mapIconDistance: number = 0

	constructor(assetOverride?: Texture, iconOverride?: Texture) {
		this.makeGraphics(assetOverride, iconOverride)
	}


	setPosition(x: number, y: number) {
		this._updatePosition(x, y)

		if (this.isShowing) {
			this._updateRotation()
		}
	}

	setDestination(x, y) {
		this.destinationX = x
		this.destinationY = y

		if (!this.isShowing) {
			this.setIsShowing(true)
			this.setEventIcon()
		} else {
			this._updateRotation()
		}
	}

	updateDestination(x, y) {
		this.destinationX = x
		this.destinationY = y
		this._updateRotation()
	}

	private _updateRotation() {
		const yDiff = this.destinationY - this.position.y
		const xDiff = this.destinationX - this.position.x

		const angle = Math.atan2(yDiff, xDiff)

		const distToTarget = Math.sqrt(xDiff * xDiff + yDiff * yDiff)
		const newArrowDist = Math.min(distToTarget - this.arrowShrinkDistance, this.arrowToPlayerDistance)
		if (!this.isBlinking) {
			this._setAlpha(Math.clamp((distToTarget - this.arrowToPlayerDistance / 2) / this.arrowToPlayerDistance, 0, 1))
		}

		this._arrowRotatedPosition.x = newArrowDist
		this._arrowRotatedPosition.y = 0

		this._arrowRotatedPosition.rotate(angle)

		this.arrowSprite.x = this._arrowRotatedPosition.x + this.position.x
		this.arrowSprite.y = this._arrowRotatedPosition.y + this.position.y

		this.arrowSprite.rot = angle + (Math.PI/2)

		if (this.showingIcon) {
			this.updateIconPosition(angle, distToTarget)
		}
	}

	blink(delta: number, blinkSpeed: number) {
		if (!this.isBlinking) {
			return
		}
		this.blinkTimer += delta
		const maxTime = blinkSpeed
		const t = this.blinkTimer / maxTime

		this._setAlpha(Math.lerp(this._alpha, this.lerpAlphaTarget, t))
		if (this.blinkTimer >= maxTime) {
			this._setAlpha(this.lerpAlphaTarget)
			this.lerpTargetMod *= -1
			this.lerpAlphaTarget = this.lerpAlphaTarget - (this.lerpTargetMod)
			this.blinkTimer = 0
		}
	}

	setIsShowing(isShowing) {
		if (!this.isShowing && isShowing) {
			this._updateRotation()
			Renderer.getInstance().fgRenderer.addPropToScene(this.arrowSprite)
			if (this.mapIcon) {
				Renderer.getInstance().fgRenderer.addPropToScene(this.mapIcon)
			}
		} else if(this.isShowing && !isShowing) {
			if (this.mapIcon) {
				Renderer.getInstance().fgRenderer.removeFromScene(this.mapIcon)
			}
			Renderer.getInstance().fgRenderer.removeFromScene(this.arrowSprite)
		}

		this.isShowing = isShowing
	}

	setIsBlinking(isBlinking) {
		if (isBlinking && !this.isBlinking){
			this.blinkTimer = 0
		}
		
		this.isBlinking =  isBlinking
		if (!isBlinking) {
			this._setAlpha(1)
			this.blinkTimer = 0
			this.lerpAlphaTarget = 0
			this.lerpTargetMod = 1
		}
	}

	setEventIcon() {
		if (!this.showingIcon) {
			this.showingIcon = true
			const tex = AssetManager.getInstance().getAssetByName('pet-ransom-icon').texture
			this.mapIcon = new InstancedSprite(tex, 0, 0, 0)

			if (this.isShowing) {
				Renderer.getInstance().fgRenderer.addPropToScene(this.mapIcon)
			}
		}
	}

	updateIconPosition(angle: radians, distToTarget: number) {
		const newMapDistance = Math.min(distToTarget - MAP_ARROW_SHRINK_DIST,  MAP_DISTANCE_FROM_ARROW)

		this._mapRotatedPosition.x = newMapDistance
		this._mapRotatedPosition.y = 0
		this._mapRotatedPosition.rotate(angle)

		this.mapIcon.x = this._mapRotatedPosition.x + this.position.x
		this.mapIcon.y = this._mapRotatedPosition.y + this.position.y

		// this.mapIcon.rot = angle + (Math.PI/2)
	}

	makeGraphics(assetOverride?: Texture, iconOverride?: Texture) {
		let tex
		if (assetOverride) {
			tex = assetOverride
		} else {
			tex = AssetManager.getInstance().getAssetByName('pet-ransom-arrow').texture
		}
		
		this.arrowSprite = new InstancedSprite(tex, 0, 0, 0)

		if (iconOverride) {
			this.mapIcon = new InstancedSprite(iconOverride, 0, 0, 0)
			this.showingIcon = true
		}
	}

	private _setAlpha(alpha: number) {
		this.arrowSprite.setAlpha(alpha)
		if (this.mapIcon) {
			this.mapIcon.setAlpha(alpha)
		}

		this._alpha = alpha
	}

	private _updatePosition(x: number, y: number) {
		this.position.x = x
		this.position.y = y

		this.arrowSprite.x = x + this._arrowRotatedPosition.x
		this.arrowSprite.y = y + this._arrowRotatedPosition.y

		if (this.mapIcon) {
			this.mapIcon.x = x + this._mapRotatedPosition.x
			this.mapIcon.y = y + this._mapRotatedPosition.y
		}
	}
}
