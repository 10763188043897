import { Vector } from 'sat'
import { vecDamp, degToRad, shotInterceptVelocity, vecLerp, randomRange, withinDistanceVV, clampAngle } from '../../utils/math'
import { angleInDegreesFromVector, angleInRadsFromVector, vectorFromAngleInRads } from '../../utils/vector'
import { ShotLeadPrecision } from './ai-types'
import { Enemy } from './enemy'


export class ShotLeadConfig {
	leadRatio: number
	angleNoiseDegrees: number
	leadCapDegrees: number
	constructor(init?: Partial<ShotLeadConfig>) {
		Object.assign(this, init)
	}
}

export const ShotLeadConfigs = {
	NONE: new ShotLeadConfig({ leadRatio: 0, angleNoiseDegrees: 0, leadCapDegrees: 0 }),
	AVERAGE: new ShotLeadConfig({ leadRatio: 0.5, angleNoiseDegrees: 15, leadCapDegrees: 20 }),
	GOOD: new ShotLeadConfig({ leadRatio: 0.9, angleNoiseDegrees: 5, leadCapDegrees: 30 }),
	PERFECT: new ShotLeadConfig({ leadRatio: 1, angleNoiseDegrees: 0, leadCapDegrees: 30 }),
}

export function moveToPosition(enemy: Enemy, targetPos: Vector, maxSpeed: number, delta: number) {
	const log = false

	/* if (log) {
		logger.debug('/moveToPosition')
	} */

	const targetVelocity: Vector = targetPos
		.clone()
		.sub(enemy.position)
		.normalize()
		.scale(maxSpeed)

	const beforeVelX = enemy.velocity.x
	enemy.velocity = vecDamp(enemy.velocity, targetVelocity, 0.1, delta)
	/* if (log) {
		logger.debug(`${beforeVelX}, ${targetVelocity.x}, ${enemy.velocity.x}`)
	} */

	const distanceMovedThisFrame = enemy.velocity.len() * delta
	if (withinDistanceVV(targetPos, enemy.position, distanceMovedThisFrame)) {
		enemy.velocity.x = 0
		enemy.velocity.y = 0
		enemy.position = targetPos
	}

	// kill any aim angle and make the model face it's movement direction
	enemy.visualAimAngle = angleInRadsFromVector(enemy.velocity)

	/* if (log) {
		logger.debug('\\moveToPosition')
	} */
}

export function getAimVector(shotPos: Vector, shotSpeed: number, targetPos: Vector, targetVel: Vector, shotLeadPrecision: ShotLeadPrecision) {
	let dir: Vector = targetPos
		.clone()
		.sub(shotPos)
		.normalize()

	const shotLeadPrecisionS = ShotLeadPrecision[shotLeadPrecision]
	const leadConfig: ShotLeadConfig = ShotLeadConfigs[shotLeadPrecisionS]

	if (leadConfig.leadRatio > 0) {
		const intercept = shotInterceptVelocity(shotPos, shotSpeed, targetPos, targetVel)
		const leadToTarget = intercept.velocity.normalize()

		const targetAngle = angleInDegreesFromVector(dir)
		const leadAngle = angleInDegreesFromVector(vecLerp(dir, leadToTarget, leadConfig.leadRatio))
		dir = vectorFromAngleInRads(degToRad(clampAngle(leadAngle, targetAngle - leadConfig.leadCapDegrees, targetAngle + leadConfig.leadCapDegrees)))
	}

	if (leadConfig.angleNoiseDegrees > 0) {
		const noiseDegrees = randomRange(-leadConfig.angleNoiseDegrees, leadConfig.angleNoiseDegrees)
		dir.rotate(degToRad(noiseDegrees))
	}

	return dir
}

/* export function clampToWorld(entity: { _position: Vector; x: number; y: number }) {
	entity.x = entity._position.x = Math.clamp(entity.x, 0, WORLD_WIDTH)
	entity.y = entity._position.y = Math.clamp(entity.y, 0, WORLD_HEIGHT)
} */
