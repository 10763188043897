<template>
	<Panel id="forgot-password" :panel-identifier="'forgot-password'" :height="400">
		<template #panel-content>
            <div class="container">
                <div class="header" :lang="this.$i18n.locale">
					{{ $t('reset_password.reset_password') }}
				</div>
                <div class="input-container" v-show="!sentResetPassword">
                    <div class="email-container" :lang="this.$i18n.locale">
                        {{ $t('reset_password.email') }}
						<input type="text" id="email" :lang="this.$i18n.locale" v-model="emailAddress"/>
                        <div class="error" v-show="forgotPasswordError.length">
                            {{ $t(forgotPasswordError) }}
                        </div>
                    </div>
                </div>
                <div class="sent-text-container" v-show="sentResetPassword" :lang="this.$i18n.locale">
                    {{ $t('reset_password.sent_email') }}
                </div>
                <div class="button-container" v-show="!sentResetPassword">
					<TextButton :button-label="$t('reset_password.send')" :button-style="'blue'" @on-click-fn="() => {sendResetPasswordEmail(emailAddress)}" :disabled="sendingReset" :loading="sendingReset"></TextButton>
                </div>
                <div class="button-container">
					<TextButton :button-label="$t('reset_password.back')" :button-style="'blue'" @on-click-fn="goBack" :disabled="sendingReset" :loading="sendingReset"></TextButton>
                </div>
            </div>
        </template>
    </Panel>
</template>

<script lang="ts">
import { mapActions, mapState, mapMutations } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import { Views } from '../store/ui-store'

export default {
    name: 'ForgotPassword',
    components: {
        Panel,
        TextButton
    },
    data() {
        return {
            emailAddress: '',
        }
    },
    computed: {
        ...mapState('user', ['sentResetPassword', 'sendingReset', 'forgotPasswordError'])
    },
    methods: {
        ...mapActions('user', ['sendResetPasswordEmail']),
        ...mapMutations('user', ['backFromResetPassword']),
        ...mapMutations('ui', ['setActiveView']),
        goBack() {
            this.setActiveView(Views.LANDING)
            this.backFromResetPassword()
        }
    },
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#forgot-password {
    width: 500px;
    display: flex;
    flex-direction: column;
	align-items: center;

    .container {
        .header {
            color: @cream;
			text-align: center;
			margin-bottom: 10px;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 50px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
        }

        .email-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            color: @offWhite;

            &[lang='en'] {
                .regularFont(@enRegularFontPixl);
                font-size: 30px;
            }

            &[lang='zh'] {
                .regularFont(@zhHansHeaderFont);
            }

            input {
				outline: none;
				height: 35px;
				border: 0px solid red;
				width: 400px;
				background-color: #040e26 !important;
				font-size: 18px;
				color: @darkGreen;
				margin-bottom: 10px;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 24px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
					font-size: 24px;
				}
				&.error {
					border: 2px solid red;
					border-radius: 2px;
					filter: drop-shadow(0px 0px 2px red);
				}
			}

            .error {
                color: @red;
                font-size: 24px;
            }
        }

        .button-container {
            width: 100%;
            display: flex;
	        align-items: center;
            justify-content: center;
            margin-top: 20px;
        }

        .sent-text-container {
            color: @offWhite;
            text-align: center;

            &[lang='en'] {
                .regularFont(@enRegularFontPixl);
                font-size: 30px;
            }

            &[lang='zh'] {
                .regularFont(@zhHansHeaderFont);
            }
        }
    }
}
</style>