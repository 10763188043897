import { Vector } from "sat"
import { PetGFX } from "./pet-gfx"
import { Container, Graphics } from "pixi.js"
import { EntityType, IEntity } from "../entity-interfaces"
import { SpineDataName } from '../../spine-config/spine-config'
import { gameUnits, timeInSeconds } from "../../utils/primitive-types"
import { PetRescueEventSystem } from "../../events/pet-rescue-gameplay-event"
import { ComponentOwner } from "src/engine/component-owner"
import { GameState, getNID } from "../../engine/game-state"
import { damp, subVXY } from "../../utils/math"
import { DamageSource } from "../../projectiles/damage-source"
import EntityStatList from "../../stats/entity-stat-list"
import { Cooldown } from "../../cooldowns/cooldown"
import { InGameTime } from "../../utils/time"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { CircleColliderConfig, ColliderType } from "../../engine/collision/colliders"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { UI } from "../../ui/ui"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { Renderer } from "../../engine/graphics/renderer"
import PlayerMetricsSystem from "../../metrics/metric-system"
import { AnimationTrack } from "../../spine-config/animation-track"

const RELOAD_MS_REMAINING = 2_000

const COOLDOWN_BAR_Y_OFFSET = 13

const petRarities = ['normal', 'epic', 'legendary'] as const
export type PetRarity = typeof petRarities[number]

export const PET_NAMES = [
	'pet-cat',
	'pet-horse',
	'pet-plant',
	'pet-rot-son',
	'pet-crystal',
	'pet-charlotte',
	'pet-painterly'
] as const
export type PetCollectionName = typeof PET_NAMES[number]

const COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		position: [0, -40],
		radius: 40,
	}
]

export type petDefinition = {
	appearance: petAppearance
	rarity: PetRarity
	name: string
	description: string
}

export type petAppearance = {
	asset: string,
	spriteSheet: boolean,
}

export type PetCollection = Record<PetCollectionName, petDefinition>

export const PET_DEFINITIONS: PetCollection = {
	'pet-cat': {
		appearance: {
			asset: SpineDataName.PET_CAT_SPRITESHEET,
			spriteSheet: true,
		},
		rarity: 'normal',
		name: 'Lil\' Ninja', 
		description: 'Pounces on many enemies dealing tons of damage.',
	},
	'pet-horse': {
		appearance: {
			asset: SpineDataName.PET_HORSE_SPRITESHEET,
			spriteSheet: true,
		},
		rarity: 'normal',
		name: 'Bucky the Unicorn',
		description: 'Attacks with a giant STOMP to damage enemies in a wide area.',
	},
	'pet-plant': {
		appearance: {
			asset: SpineDataName.PET_PLANT_SPRITESHEET,
			spriteSheet: true,
		},
		rarity: 'normal',
		name: 'Photocynthia',
		description: 'Channels natural energies to heal you periodically.',
	},
	'pet-rot-son': {
		appearance: {
			asset: SpineDataName.PET_ROT_SON_SPRITESHEET,
			spriteSheet: true,
		},
		rarity: 'normal',
		name: 'Rot Son',
		description: 'Constantly bombards enemies with poison gas.',
	},
	'pet-crystal': {
		appearance: {
			asset: SpineDataName.PET_CRYSTAL_SPRITESHEET,
			spriteSheet: true,
		},
		rarity: 'normal',
		name: 'Shiny',
		description: 'Flies around collecting pickups for you.',
	},
	'pet-charlotte': {
		appearance: {
			asset: SpineDataName.PET_CHARLOTTE_RAINBOW_PLANT_SPRITESHEET,
			spriteSheet: true,
		},
		rarity: 'normal',
		name: 'Charlotte',
		description: 'Grants a random buff! Green for pickup and XP bonuses, blue for movement speed, and red for damage.',
	},
	'pet-painterly': {
		appearance: {
			asset: SpineDataName.PET_SEA_TURTLE_SPRITESHEET,
			spriteSheet: true,
		},
		rarity: 'normal',
		name: 'Painterly',
		description: 'Releases a forceful tsunami that damages and knocks back enemies.',
	},
}

export const PET_OFFSET = 200
const PET_LERP_SPEED = 0.18

export abstract class Pet implements IEntity, DamageSource, ComponentOwner {
	entityType: EntityType = EntityType.Pet
	nid: number
	name: PetCollectionName
	gfx: PetGFX
	position: Vector
	timeScale: number = 1

	weaponType: AllWeaponTypes

	config: petDefinition
	petFollow: boolean = false
	recentDeltas: number[] = []
	targetDistance: gameUnits = PET_OFFSET

	followTarget: ComponentOwner
	petTargetPosition: Vector = new Vector()
	followPosition: Vector = new Vector()
	useFollowPosition: boolean = false

	statList: EntityStatList
	cooldown: Cooldown

	colliderComponent: ColliderComponent

	usingAbility: boolean = false

	numEntitiesChained: number = 0
	numEntitiesPierced: number = 0

	showCooldownBar = true
	cooldownBarContainer: Container
	cooldownBarGfx: Graphics
	

	showImmediateDamageNumber: boolean = false

	blockAbility: boolean = false

	dampedLerp: boolean = true

	canDoubleAttack: boolean = false
	usedDoubleAttack: boolean = false

	isWildPet: boolean = false

	private _isInScene: boolean = true

	constructor(name: PetCollectionName, parentStatList: EntityStatList, position: Vector, uncaged?: boolean) {
		this.nid = getNID(this)
		this.name = name

		this.weaponType = AllWeaponTypes.Pet

		this.config = PET_DEFINITIONS[name]
		this.position = position.clone()
		this.followPosition = position.clone()

		this.gfx = new PetGFX(this, uncaged)
		this.gfx.addToScene()

		this.statList = new EntityStatList(this.resetStatsFn.bind(this), parentStatList) // NOT a child of player's statlist
		this.cooldown = new Cooldown(this.statList)

		this.colliderComponent = new ColliderComponent(COLLIDER_CONFIG, this, CollisionLayerBits.Pet)

		this.makeCooldownGfx()

		GameState.addEntity(this)
	}

	abstract resetStatsFn(statList: EntityStatList)
	abstract useAbility()

	finishedPetAbility() {
		this.cooldown.useCharge()
		this.usingAbility = false

		// are we a double attacker?
		if (this.canDoubleAttack && GameState.player.binaryFlags.has('pet-double-attack') && !this.usedDoubleAttack) {
			this.usedDoubleAttack = true
			// reduce cooldown by a lot
			const normalInterval = this.cooldown.getCooldownInterval()

			this.cooldown.reduceNextCooldown(normalInterval * 0.95)
		} else {
			this.usedDoubleAttack = false
		}
	}

	releaseFromCage(noFollow?: boolean, immediate?: boolean) {
		const callback = () => {
			this.cooldown.useCharge()
			this.cooldown.setNextReload(RELOAD_MS_REMAINING)
	
			if (!noFollow) {
				const eventSystem = PetRescueEventSystem.getInstance()
				if (eventSystem.tailingPet) {
					this.followTarget = eventSystem.tailingPet
				} else {
					this.followTarget = GameState.player
				}
				eventSystem.tailingPet = this
	
				UI.getInstance().emitMutation('ui/addPet', this)
			}
			
			this.petFollow = true
			CollisionSystem.getInstance().addCollidable(this.colliderComponent)

			this.gfx.removeCage()
		}

		if (immediate) {
			callback()
		} else {
			this.gfx.cageSpriteAnimator.playAnimation(AnimationTrack.DEATH, undefined, callback)
		}
	}

	isPlayerOwned(): boolean {
		return this.petFollow && !this.isWildPet// should this apply player effects? idk they're like their own entity kinda
	}

	update(delta: timeInSeconds): void {
		if (this.petFollow) {
			const facingMod = Math.sign(this.petTargetPosition.x - this.position.x)
			this.updateFollow(delta)
			this.gfx.spriteSheetAnimator.scale.x = facingMod ? facingMod : this.gfx.spriteSheetAnimator.scale.x

			this.cooldown.update(delta, InGameTime.highResolutionTimestamp())
			if (this.cooldown.isUp() && !this.usingAbility && !this.blockAbility) {
				this.usingAbility = true
				this.useAbility()
			}
		}
		this.gfx.update(delta)
		this.updateCooldownGfx()
	}

	updateFollow(delta: timeInSeconds) {
		const position = this.useFollowPosition ? this.followPosition : this.position
		if (this.recentDeltas.length >= 10) {
			this.recentDeltas.shift()
		}
		this.recentDeltas.push(delta)
		let avgDelta = 0
		for (let i = 0; i < this.recentDeltas.length; ++i) {
			avgDelta += this.recentDeltas[i]
		}
		avgDelta /= this.recentDeltas.length

		const followTargetPosition = this.followTarget instanceof Pet && this.followTarget.useFollowPosition ? this.followTarget.followPosition : this.followTarget.position

		const petToTargetVector: Vector = subVXY(followTargetPosition, position.x, position.y)
		const petToTargetDistanceSquared = petToTargetVector.len2()

		if (petToTargetDistanceSquared > this.targetDistance ** 2) {
			this.petTargetPosition.x = followTargetPosition.x
			this.petTargetPosition.y = followTargetPosition.y

			if (this.dampedLerp) {
				position.x = damp(position.x, this.petTargetPosition.x, PET_LERP_SPEED, avgDelta)
				position.y = damp(position.y, this.petTargetPosition.y, PET_LERP_SPEED, avgDelta)
			} else {
				position.x = this.petTargetPosition.x
				position.y = this.petTargetPosition.y
			}
		}
	}

	setFollowPosition() {
		this.useFollowPosition = true
		this.followPosition.copy(this.position)
	}

	makeCooldownGfx() {
		this.cooldownBarContainer = new Container()
		this.cooldownBarContainer.visible = false
		this.cooldownBarContainer.scale.x = -1

		const barBackground = new Graphics()
		barBackground.beginFill(0x000000)
		barBackground.drawRect(-100, -5, 100, 10)
		barBackground.endFill()
		barBackground.position.x = 50

		this.cooldownBarContainer.addChild(barBackground)

		this.cooldownBarGfx = new Graphics()
		this.cooldownBarGfx.beginFill(0x00FF00)
		this.cooldownBarGfx.drawRect(-100, -5, 100, 10)
		this.cooldownBarGfx.endFill()

		barBackground.addChild(this.cooldownBarGfx)

		this.cooldownBarContainer['update'] = () => {
			this.cooldownBarContainer.position.x = this.position.x
			this.cooldownBarContainer.position.y = this.position.y + COOLDOWN_BAR_Y_OFFSET
			this.cooldownBarContainer.zIndex = this.position.y
		}

		Renderer.getInstance().fgRenderer.addDisplayObjectToScene(this.cooldownBarContainer)
	}

	updateCooldownGfx() {
		if (!this.showCooldownBar || !this.petFollow) {
			this.cooldownBarContainer.visible = false
			return
		}

		this.cooldownBarContainer.visible = true

		const percentDone = this.cooldown.percentToNextCooldown()

		this.cooldownBarGfx.scale.x = percentDone
	}

	removeCooldownGfx() {
		Renderer.getInstance().fgRenderer.removeFromScene(this.cooldownBarContainer)
	}

	refreshCooldown() {
		this.cooldown.reduceNextCooldown(999_999)
	}

	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		return mutableEntityPos.sub(this.position).normalize()
	}

	destroy() {
		CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
		this.gfx.removeFromScene()
		this.statList._parentStatList.removeChild(this.statList)
		this.statList = null
		GameState.removeEntity(this)
	}

	removeFromScene() {
		if (this._isInScene) {
			this._isInScene = false
			CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
			this.gfx.removeFromScene()
			GameState.removeEntity(this)
		}
	}

	addToScene() {
		if (!this._isInScene) {
			this._isInScene = true
			CollisionSystem.getInstance().addCollidable(this.colliderComponent)
			this.gfx.addToScene()
			GameState.addEntity(this)
		}
	}
}