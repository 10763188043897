import { Vector } from "sat"
import { GameState } from "../engine/game-state"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { EntityType, IEntity } from "./entity-interfaces"
import { SpritesheetAnimatorComponent } from "../engine/graphics/spritesheet-animator-component"
import { AssetManager } from "../web/asset-manager"
import { ComponentOwner } from "../engine/component-owner"
import { GravityPinDownConfigs } from "../spine-config/sprite-animation-config"
import { Container, Graphics } from "pixi.js"
import { Renderer } from "../engine/graphics/renderer"

const GRAVITY_PINDOWN_LIFETIME = 0.7
const EFFECT_RADIUS = 250
const POSITION_OFFSET = new Vector(0, 50)
const Z_OFSET = -200

export interface GravityPinDownParams {
	radius: number
	position: Vector
  	duration: number
	onTargetDeath?: () => boolean
}

export class GravityPinDown implements PoolableObject, ComponentOwner, IEntity {
	static pool: ObjectPoolTyped<GravityPinDown, GravityPinDownParams>
	triedToReturnToPool: boolean

	nid: number
	entityType: EntityType = EntityType.GroundHazard
	timeScale: number = 1

	targetPosition: Vector
	position: Vector
	radius: number

	remainingLifetime: number
	onTargetDeath?: () => boolean

	visuals: SpritesheetAnimatorComponent

  	debugVisuals: Container
	circleGraphics: Graphics

	static emitGravityPinDown(position: Vector, radius: number, duration: timeInSeconds, onTargetDeath?: () => boolean ): GravityPinDown {
		return GravityPinDown.pool.alloc({
			position: position,
			radius: radius,
			duration: duration,
			onTargetDeath: onTargetDeath
		})
	}

	constructor() {
		this.makeVisuals()
		this.position = new Vector()
		this.remainingLifetime = GRAVITY_PINDOWN_LIFETIME
		this.triedToReturnToPool = false
	}

	setDefaultValues(defaultValues: any, overrideValues?: GravityPinDownParams) {
		if(overrideValues) {
			this.radius = overrideValues.radius
			this.targetPosition =  overrideValues.position
			this.position.copy(overrideValues.position).add(POSITION_OFFSET)
			this.remainingLifetime = overrideValues.duration
			this.onTargetDeath = overrideValues.onTargetDeath
			this.triedToReturnToPool = false

			GameState.addEntity(this)
			this.setVisuals()
		}
	}

	cleanup() {
		this.visuals.removeFromScene()
		this.targetPosition = null
		GameState.removeEntity(this)

		if (this.debugVisuals) {
			Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)
			this.debugVisuals.destroy()
			this.debugVisuals = null
		}
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		if (this.onTargetDeath !== undefined && this.onTargetDeath()){
			this.remainingLifetime = 0
		}
		this.position.copy(this.targetPosition).add(POSITION_OFFSET)
		this.visuals.update(delta)
		this.remainingLifetime -= delta
		if(!this.triedToReturnToPool && this.remainingLifetime <= 0) {
			this.returnToPool()
			this.triedToReturnToPool = true
		}
		/* if (!this.debugVisuals) {
			this.makeDebugVisuals()
			Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.debugVisuals)
		} */

		if (this.debugVisuals) {
			this.debugVisuals['update'](delta)
		}
	}

	setRemainingTime(time: timeInSeconds) {
		this.remainingLifetime = time
	}

	makeVisuals() {
		const spriteSheet = AssetManager.getInstance().getAssetByName('gravity-pin-down').spritesheet
		this.visuals = new SpritesheetAnimatorComponent(this, spriteSheet, 'back-idle', undefined, true, true, GravityPinDownConfigs)
		this.visuals.zIndexOffset = Z_OFSET
	}

	setVisuals() {
		this.visuals.addToScene()
		const scale = this.radius / EFFECT_RADIUS
		this.visuals.spriteSheetAnimator.scale.x = scale
		this.visuals.spriteSheetAnimator.scale.y = scale
		this.visuals.spriteSheetAnimator.playAnimationsConcurrently()
	}

	isPlayerOwned(): boolean {
		return true
	}

	returnToPool() {
		GravityPinDown.pool.free(this)
	}

	makeDebugVisuals() {
		this.debugVisuals = new Container()
		this.debugVisuals['update'] = (delta: timeInSeconds) => {
			this.debugVisuals.x = this.targetPosition.x
			this.debugVisuals.y = this.targetPosition.y
		}

		this.circleGraphics = new Graphics()

		this.debugVisuals.addChild(this.circleGraphics)

		this.circleGraphics.clear()
		this.circleGraphics.lineStyle(3, 0x40B01A)
		this.circleGraphics.drawCircle(0, 0, this.radius)

		this.debugVisuals.position.x = this.position.x
		this.debugVisuals.position.y = this.position.y
	}
}