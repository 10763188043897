import { MapOption } from "../world-generation/world-data"
import { timeInSeconds } from "./primitive-types"

class DebugConfig {
	debug: boolean = true

	assets = {
		logging: true,
		logEachAsset: false,
	}

	audio = {
		disableAudio: false,
	}

	collisions = {
		showGrid: false,
		drawWackGridDebug: false,

		sampling: false,
		drawProjectileColliders: false,
		drawPropColliders: false,
		drawBeamColliders: false,
		drawEnemyColliders: false
	}

	camera = {
		debug: false
	}

	disableThrowIfNotFinite = false

	enemy = {
		disableSpawning: false,
		disableEvents: false,
		recycleOppositeOfPlayer: true,
		aggressiveRecycling: true,
		noTeleportOnRecycle: true,
	}

	pooling = {
		/** enables pool debugging reports/printing, also double-free checking */
		debug: false,
		/** disables spine sprite pools */
		disableSpinePools: false,
		/** checks certain allocated objects against canonical/pure reference objects */
		checkSomeCanonicals: false,
		/** disables EntityStatList pools */
		disableEntityStatListPools: true,
	}

	props: boolean = true
	disablePickups: boolean = false

	render = {
		rangeFinder: false,
	}

	reviveOnDeath: boolean = false
	hugeDamageBuff: boolean = false

	spearHopMode = 0
	spearHopTime = 1
	spearHopForce: number = 1_200
	spearHopDuration: number = 0.2

	enableOverrideMap: boolean = false
	overrideMap: MapOption = MapOption.Forest

	unlockEverything: boolean = false

	benchmarkMode: boolean = false
	benchmarkSeed: number = 123444
	benchmarkUpgradeSeed: number = 1
	benchmarkTimeSkip: timeInSeconds = 13 * 60
	benchmarkLevels: number = 70

	enableVueDevTools: boolean = false
}

export const debugConfig: DebugConfig = new DebugConfig()

export const REVIVE_ON_DEATH_URL_KEY = 'reviveOnDeath'

if (process.env.NODE_ENV === 'local') {
	switch (process.env.USERNAME) {
		// your machine username / setup stuff here
		case 'your-machine-name': 
			// debugConfig.reviveOnDeath = true
			break
	}
}
