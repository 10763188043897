import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, ENEMY_OBJECT_POOL_GROWTH_SIZE, ENEMY_OBJECT_POOL_INITIAL_SIZE, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"

const spider: EnemyAI = {
	name: ENEMY_NAME.SPIDER_1,
	type: EnemyType.BASIC,
	objectPoolInitialSize: ENEMY_OBJECT_POOL_INITIAL_SIZE,
	objectPoolGrowthSize: ENEMY_OBJECT_POOL_GROWTH_SIZE,
	appearance: {
		asset: SpineDataName.SPIDER_1,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 60,
				position: [0, -25],
			},
		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.333333,
			maxHealth: 0.6666,
			baseDamage: 1,
			attackKnockback: 0,
			knockbackResist: 0.75,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.NONE
			},
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 100,
			movementMinDistance: 40,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
		corpseTimeoutInSeconds: 0.5,
		},
	},
}

export default spider
