import { Cooldown } from "../../../cooldowns/cooldown"
import { CooldownSystem } from "../../../cooldowns/cooldown-system"
import { Enemy } from "../../../entities/enemies/enemy"
import { Player } from "../../../entities/player"
import { PassiveSkill } from "../../passive-skill"

export abstract class AutoFirePassiveSkill extends PassiveSkill {
    cooldown: Cooldown
	skillSuccessfullyUsed: boolean
    isUsingSkill: boolean

    override onCreate(player: Player) {
        this.cooldown = new Cooldown(this.statList)
        CooldownSystem.getInstance().addCooldown(this.cooldown)
    }

    override update(delta: number) {
        if (!this.isUsingSkill && this.cooldown.isUp()) {
			this.skillSuccessfullyUsed = false
            this.isUsingSkill = true
            this.firePassiveSkill()
        }
    }

    finishedUsingPassiveSkill() {
		if (this.skillSuccessfullyUsed) {
        	this.cooldown.useCharge()
		}
        this.isUsingSkill = false
    }

    abstract firePassiveSkill()
}
