import { to3DigitTruncatedString } from "../../utils/math"
import { endChapterEventPrettyNames } from "../../events/event-stat-types"
import PlayerMetricsSystem, { MetricBundle } from "../../metrics/metric-system"
import { secondsToPrettyString } from "../../utils/time"
import { getMutators, postBookReview, postCastVote } from "../../utils/api/griddle-requests"
import { UI } from "../ui"
import { showGenericYesNoUI } from "./generic-two-btn-prompt-store"
import { MUTATOR_DEFINITIONS } from "../../mutators/mutator-definitions"
import { uuid } from "../../utils/primitive-types"
import { sampleSize } from "lodash"
import { UpgradeManager, UpgradeWithId } from "../../upgrades/upgrade-manager"
import { MutatorManager } from "../../mutators/mutator-manager"
import { PlotTwistUI } from "./story-store"
import clientLogger from "../../utils/client-logger"
import { WeaponConfig } from '../../weapons/weapon-types'
import { GoogleAnalyticsHandler } from "../../analytics/google-analytics"


type Displayable = PlotTwistUI | UpgradeWithId

type BookReviewCategories = "upgrades" | "weaponsPets" | "twists"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { TutorialFlagsEnum } from "../../ftue/tutorial-flags"
import { VictoryDeathManager } from "../../engine/victory-death-manager"
import { PauseManager } from "../../engine/pause-manager"

export const STORIES_PAGE_PARAM_KEY = "backToStories"
export const STORY_PAGE_PARAM_KEY = "storyId"
export const NUMBER_OF_RUNS_LOCAL_KEY = 'runsCompleted'
export const NUMBER_OF_DEMO_RUNS_LOCAL_KEY = 'demoRunsCompleted'

export type Mutator = {
	"storyId": string
	"chapter": number
	"twistId": string
	"createdAt": string
}

interface BookReviewErrors {
	"difficulty": boolean
	"adjectives": boolean
	"favoriteThing": boolean
	"leastFavoriteThing": boolean
}

const ADJECTIVE_LIST = [
	'Zany',
	'Spooky',
	`I'm OP`,
	'Bullet Hell',
	'Bullet Heaven',
	'Frantic',
	'Cheesy',
	'Crowded',
	'Relentless',
	'Cakewalk',
	'Mowing Down',
	'Running Away',
	'Non-Stop',
	'Funny',
	'Sweaty',
	'Dicey',
	'Flashy',
	'Stressful',
	`I'm Juiced`,
	'Demanding',
	'Fast',
	'Calm',
	'Clown Fiesta',
	'Hilarious',
	'Relaxing',
]

const initialState: {
	endChapterData: MetricBundle,
	runId: uuid,
	twistList: any[],
	indexedTwists: any[],
	selectedTwist: any,
	endRunResults: any,
	adjectiveOptions: string[],
	favoriteOptions: Displayable[],
	leastFavoriteOptions: Displayable[]
	showBookReviewModal: boolean
	bookReviewErrors: BookReviewErrors
	bookReviewCategories: BookReviewCategories[]
	showVoteProgressModal: boolean
	bookReviewPosted: boolean
	voteScore: number
	voteScoreThreshold: number
	queueWishlistPrompt: boolean
	endingLevel: number
} = {
	endChapterData: null,
	runId: null,
	twistList: [],
	indexedTwists: [],
	selectedTwist: null,
	endRunResults: null,
	adjectiveOptions: [],
	favoriteOptions: [],
	leastFavoriteOptions: [],
	showBookReviewModal: false,
	bookReviewPosted: false,
	showVoteProgressModal: false,
	bookReviewErrors: {
		difficulty: false,
		adjectives: false,
		favoriteThing: false,
		leastFavoriteThing: false,
	},
	bookReviewCategories: [],
	voteScore: 0,
	voteScoreThreshold: 0,
	queueWishlistPrompt: false,
	endingLevel: 1
}

const store = {
	namespaced: true,
	state: initialState,
	getters: {
		queueWishlistPrompt(state: EndChapterStoreState) {
			if (!process.env.IS_WEB) {
				return false
			}
			return state.queueWishlistPrompt
		},
		getAllWeaponDamage(state: EndChapterStoreState) {
			let formattedWeaponDamageStats = []
			if (state.endChapterData !== null) {

				for (const weaponTypeKey in state.endChapterData.weaponDamage) {
					const type = Number.parseInt(weaponTypeKey) as AllWeaponTypes
					if (weaponTypeKey === undefined || type === AllWeaponTypes.WorldHazard) {
						continue
					}
					const weaponValue = state.endChapterData.weaponDamage[weaponTypeKey]
					const weaponDps = to3DigitTruncatedString(Math.round(weaponValue.dps))
					const totalDamage = to3DigitTruncatedString(Math.round(weaponValue.totalDamage))

					formattedWeaponDamageStats.push({ name: weaponValue.name, dps: weaponDps, totalDamage: totalDamage })
				}
				return formattedWeaponDamageStats
			}
		},
		getAllWeaponDamageWithIcons(state: EndChapterStoreState) {
			let formattedWeaponDamageStats = []
			if (state.endChapterData !== null) {

				for (const weaponTypeKey in state.endChapterData.weaponDamage) {
					const type = Number.parseInt(weaponTypeKey) as AllWeaponTypes
					if (weaponTypeKey === undefined || type === AllWeaponTypes.WorldHazard) {
						continue
					}
					const weaponValue = state.endChapterData.weaponDamage[weaponTypeKey]
					const weaponConfig = WeaponConfig[weaponValue.weaponType]
					if (!weaponConfig || weaponConfig.icon.length === 0) {
						continue
					}

					const weaponDps = to3DigitTruncatedString(Math.round(weaponValue.dps))
					const totalDamage = to3DigitTruncatedString(Math.round(weaponValue.totalDamage))

					if (totalDamage === '0') {
						continue
					}

					formattedWeaponDamageStats.push({ name: weaponValue.name, dps: weaponDps, totalDamage: totalDamage, icon: weaponConfig.icon })
				}
				return formattedWeaponDamageStats
			}
		},
		getAllEventData(state: EndChapterStoreState) {
			let formattedEventStats = []
			if (state.endChapterData !== null) {

				for (const eventKey in state.endChapterData.eventStats) {
					const eventValue = state.endChapterData.eventStats[eventKey]
					const eventName = endChapterEventPrettyNames.get(Number.parseInt(eventKey))
					if (eventName === undefined) {
						continue
					}
					formattedEventStats.push({ name: eventName, total: eventValue })
				}
				return formattedEventStats
			}
		},
		getEndGameTimeInMinutesSeconds(state: EndChapterStoreState) {
			if (state.endChapterData !== null) {
				const timeInSeconds = state.endChapterData.totalRunDurationInSeconds
				return secondsToPrettyString(timeInSeconds)
			}
		},
		getIsPlayerWinner(state: EndChapterStoreState) {
			if (state.endRunResults) {
				return state.endRunResults.isWin
			}
			return false
		},
		getPlayerMeetsVoteThreshold(state: EndChapterStoreState) {
			return state.voteScore > state.voteScoreThreshold
		}
	},
	mutations: {
		updateEndChapterData(state: EndChapterStoreState, playerData: MetricBundle) {
			state.endChapterData = playerData
		},
		updateTwistList(state: EndChapterStoreState, twistList) {
			let indexTwists = []
			let index = 0

			for (const [twistId, value] of Object.entries(twistList)) {
				let twistDef = MUTATOR_DEFINITIONS[twistId]
				if (twistDef) {
					indexTwists.push({ 'twist': twistId, 'count': parseInt(value as string, 10), 'id': index, icon: twistDef.icon, name: twistDef.name, desc: twistDef.description })
					index++
				} else {
					delete twistList[twistId]
				}
			}

			indexTwists.sort((a, b) => b.count - a.count);

			state.indexedTwists = indexTwists
			state.twistList = twistList
		},
		updateSelectedTwist(state: EndChapterStoreState, selectedTwist) {
			state.selectedTwist = selectedTwist
		},
		resetTwists(state: EndChapterStoreState) {
			state.twistList = []
			state.selectedTwist = null
		},
		updateEndRunResults(state: EndChapterStoreState, endRunResults) {
			state.endRunResults = endRunResults
		},
		updateRunId(state: EndChapterStoreState, runId: uuid) {
			state.runId = runId
		},
		updateShowBookReviewModal(state: EndChapterStoreState, showBookReviewModal: boolean) {
			state.showBookReviewModal = showBookReviewModal
		},
		updateBookReviewPosted(state: EndChapterStoreState, bookReviewPosted: boolean) {
			state.bookReviewPosted = bookReviewPosted
		},
		updateShowVoteProgressModal(state: EndChapterStoreState, showVoteProgressModal: boolean) {
			state.showVoteProgressModal = showVoteProgressModal
		},
		updateBookReviewErrors(state: EndChapterStoreState, error: ('difficulty' | 'adjectives' | 'favoriteThing' | 'leastFavoriteThing')) {
			state.bookReviewErrors[error] = true
		},
		updateVoteScore(state: EndChapterStoreState, voteScore: number) {
			state.voteScore = voteScore
		},
		updateVoteThreshold(state: EndChapterStoreState, voteThreshold: number) {
			state.voteScoreThreshold = voteThreshold
		},
		resetBookReviewErrors(state: EndChapterStoreState) {
			state.bookReviewErrors = {
				difficulty: false,
				adjectives: false,
				favoriteThing: false,
				leastFavoriteThing: false,
			}
		},
		updateBookReviewCategories(state: EndChapterStoreState, categories: BookReviewCategories[]) {
			state.bookReviewCategories = categories
		},
		setQueueWishlistPrompt(state: EndChapterStoreState, value: boolean) {
			state.queueWishlistPrompt = value
		},
		setEndLevel(state: EndChapterStoreState, level) {
			state.endingLevel = level
		},
		reset(state: EndChapterStoreState) {
			state.twistList = []
			state.indexedTwists = []
			state.selectedTwist = null
			state.endRunResults = null
			state.adjectiveOptions = []
			state.favoriteOptions = []
			state.leastFavoriteOptions = []
			state.bookReviewPosted = false
			state.bookReviewErrors = {
				difficulty: false,
				adjectives: false,
				favoriteThing: false,
				leastFavoriteThing: false,
			}
			state.bookReviewCategories = []
			state.voteScore = 0
			state.voteScoreThreshold = 0
			state.queueWishlistPrompt = false
			state.endingLevel = 1
		}
	},
	actions: {
		//TODO: deprecate this, let victory manager handle committing the mutation
		async fetchEndChapterData({ state, commit }: { state: EndChapterStoreState; commit: any }) {
			const playerData = PlayerMetricsSystem.getInstance().getFinalMetrics()
			commit('updateEndChapterData', playerData)
		},
		showBookReviewModal({ state, commit }: { state: EndChapterStoreState; commit: any }) {
			commit('ui/updateDarkenOverlay', true, { root: true })
			commit('updateShowBookReviewModal', true)
		},
		async fetchMutators({ state, commit, rootGetters }: { state: EndChapterStoreState; commit: any, rootGetters: any }) {
			const story = rootGetters['story/getSelectedStory']
			try {
				const mutatorData = await getMutators(story.id, story.chapter)
				commit('updateTwistList', mutatorData)
			} catch (error) {
				console.log('Error occurred when attempting to fetch story Mutators', error)
			}
		},
		async postMutatorVote({ state, commit, rootGetters, dispatch }: { state: EndChapterStoreState; commit: any, rootGetters: any, dispatch: any }, view) {
			const story = rootGetters['story/getSelectedStory']
			const playerMeetsVoteThreshold = rootGetters['endChapter/getPlayerMeetsVoteThreshold']
			if (state.endRunResults.isWin || playerMeetsVoteThreshold) {
				const body = {
					choice: state.selectedTwist.twist,
					runId: state.runId,
					chapter: story.chapter
				}
				try {
					const voteResult = await postCastVote(story.id, body)
					if (voteResult.tomes) {
						commit('user/updateMagicTomes', voteResult.tomes, { root: true })
					}
					await dispatch('fetchMutators')
					GoogleAnalyticsHandler.getInstance().trackVote(state.selectedTwist.twist, UI.getInstance().store.state.story.selectedStoryId)
				} catch (error) {
					console.log('Error occurred when attempting to fetch story Mutators', error)
				}
			}
		},
		setRun({ state, commit, rootGetters }: { state: EndChapterStoreState; commit: any, rootGetters: any }, payload) {
			commit('updateEndRunResults', payload)
			if (payload.isWin) {
				commit('ftue/completeFlag', TutorialFlagsEnum.GainedOneVote, { root: true })
			}
		},
		updateEndChapterReviewOptions({ state, commit, rootGetters, dispatch }: { state: EndChapterStoreState, commit: any, rootGetters: any, dispatch: any }) {
			// select random adjectives
			state.adjectiveOptions = sampleSize(ADJECTIVE_LIST, 8)
			console.log(`set adjectives`, {
				adjectiveOptions: state.adjectiveOptions,
				ADJECTIVE_LIST,
			})

			// compute favorite and least favorite categories
			const categories: BookReviewCategories[] = ['upgrades', 'upgrades'] // upgrades can appear for both favorite and least favorite, so it's here twice

			const weaponsPets = UpgradeManager.getAllocatedWeaponsPetsUpgrades()
			if (weaponsPets.length >= 3) {
				categories.push('weaponsPets')
			}

			const selectedStory = rootGetters['story/getSelectedStory']
			const twists = selectedStory.formattedTwists
			if (twists && Object.entries(twists).length >= 3) {
				categories.push('twists')
			}

			const chosenCategories = sampleSize(categories, 2)
			console.log(`chose categories: `, {
				chosenCategories,
			})

			commit('updateBookReviewCategories', chosenCategories)

			chosenCategories.forEach((cat, i) => {
				const arr = (i === 0) ? state.favoriteOptions : state.leastFavoriteOptions
				console.group(`${cat} ${i} => `, arr)
				if (cat === 'upgrades') {
					const upgrades = UpgradeManager.getAllocatedGenericUpgrades()
					arr.push(...sampleSize(upgrades, 3))
					console.log('upgrades!')
				} else if (cat === 'weaponsPets') {
					const upgrades = weaponsPets
					arr.push(...sampleSize(upgrades, 3))
					console.log('weapon pets!')
				} else if (cat === 'twists') {
					arr.push(...sampleSize(twists, 3))
					console.log('plot twists!')
				} else if (cat == 'enemies') {
					// Todo: add enemies once we have icons for them
				}
				console.log(arr)
				console.groupEnd()
			})
		},
		async finishBookReview({ state, rootState, commit }: { state: EndChapterStoreState, rootState: any, commit: any }, payload) {
			payload['runId'] = state.runId
			try {
				const apiResults = await postBookReview(rootState.story.selectedStoryId, payload)

				commit('updateBookReviewPosted', true)
				GoogleAnalyticsHandler.getInstance().trackSubmitBookReport(UI.getInstance().store.state.story.selectedStoryId)
			} catch (error) {
				clientLogger.error('Error in postBookReview', error)
			}
		},

		async leaveChapter({ rootGetters, dispatch }: { rootGetters: any, dispatch: any }) {
			const storyId = rootGetters['story/getSelectedStory'].id
			showGenericYesNoUI(
				'end_chapter.prompt_end_run_header',
				'end_chapter.prompt_end_run_description',
				'end_chapter.prompt_end_run_no',
				'end_chapter.prompt_end_run_yes',
				() => {
					UI.getInstance().emitAction('genericTwoButtonPrompt/closeActiveYesNoPanel')
				},
				() => {
					dispatch('settings/saveAndClose', undefined, { root: true })
					VictoryDeathManager.instantEndRound()
					// setEndChapterUrlParams(STORY_PAGE_PARAM_KEY, storyId)
				},
			)
		},
		setVoteScoring({ state, commit, rootState }: { state: EndChapterStoreState; commit: any, rootState: any }, score) {
			const thresholds = voteThreshold(rootState.story.selectedChapter)
			commit('updateVoteScore', score)
			commit('updateVoteThreshold', thresholds)

			if (score >= thresholds) {
				commit('ftue/completeFlag', TutorialFlagsEnum.GainedOneVote, { root: true })
			}
		}
	},
}

export type EndChapterStoreState = typeof initialState
export const EndChapterStore = typeof store

export const endChapterStore = () => {
	return store
}

export function setEndChapterUrlParams(urlParam: string, value: string) {
	const oldSearchParams = new URLSearchParams(window.location.search)
	oldSearchParams.set(urlParam, encodeURIComponent(value))

	if (urlParam === STORY_PAGE_PARAM_KEY) {
		oldSearchParams.delete(STORIES_PAGE_PARAM_KEY)
	}
	if (urlParam === STORIES_PAGE_PARAM_KEY) {
		oldSearchParams.delete(STORY_PAGE_PARAM_KEY)
	}

	const newURL = window.location.origin + '/?' + oldSearchParams.toString()

	if (process.env.IS_ELECTRON) {
		window.location.href = '?' + oldSearchParams.toString()
	} else {
		window.history.replaceState(null, null, newURL)
		window.location.reload()
	}
}

const URL_PARAMS_TO_REMOVE = ['skipToGame', 'mutators', 'weapon', 'character', 'upgrades', 'unlockEverything']

export function sanitizeUrlParams() {
	if (process.env.IS_ELECTRON) {
		return new URLSearchParams(window.location.search)
	}

	const oldSearchParams = new URLSearchParams(window.location.search)
	const newUrlParams = new URLSearchParams(window.location.search)

	// Since we probably need to debug, locking the removal of URL params behind this condition
	//TODO: Chat to figure out what params we need to keep or hide from the end user.
	if ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') && !UI.getInstance().store.getters['user/isQa']) {
		oldSearchParams.forEach((_k, v) => {
			// we no longer want to remove everything, because we have marketing/portal/advertising attribution parameters with unknown contents
			if (URL_PARAMS_TO_REMOVE.includes(v)) {
				newUrlParams.delete(v)
			}
		})
	}

	const newURL = window.location.origin + '/?' + newUrlParams.toString()
	window.history.replaceState(null, null, newURL)

	return newUrlParams
}

export function clearAllUrlParams() {
	if (!process.env.IS_ELECTRON) {
		const newURL = window.location.origin
		window.history.replaceState(null, null, newURL)
	}
}

export function voteThreshold(chapter) {
	const voteBase = 20_000
	const chapterIncrease = 5_000
	return voteBase + (chapterIncrease * chapter)
}