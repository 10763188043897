<template>
	<div id="ui-overlay">
		<div v-if="uiState === UICurrentState.MAIN_MENU" class="background" :class="[{ splashbg: hasSplashBg, basicbg: !hasSplashBg && Views.SPLASH !== activeView && activeView !== Views.CREDITS, forestbg: activeView === Views.CREDITS }]"></div>
		<Tooltip></Tooltip>
		<DebugTools v-if="!isEnvProductionLike || isQa" class="debug-tools"></DebugTools>
		<DebugIcons v-if="(!isEnvProductionLike || isQa) && showDebugIconScreen" class="debug-icons"></DebugIcons>
		<DebugLightingColor v-if="(!isEnvProductionLike || isQa) && showDebugLightingColor" class="debug-lighting"></DebugLightingColor>
		<transition name="main-menu-fade" @enter="mainMenuEntering">
			<div v-if="uiState === UICurrentState.MAIN_MENU" id="menu" :class="[{ menu: true }, aspectRatio]">
				<transition name="fade">
					<div v-if="Views.LANDING === activeView || Views.LOGGED_IN === activeView" :class="{ 'landing-page': Views.LANDING === activeView, 'logged-in': Views.LOGGED_IN === activeView }">
						<div class="left-quadrant" :class="aspectRatio">
							<LandingPageView
								v-if="Views.LANDING === activeView"
								:style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center top`, height: `calc(${currentSettings.height}px - 69px)` }"
								:class="aspectRatio"
							></LandingPageView>
							<LoggedIn v-if="Views.LOGGED_IN === activeView" :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center top`, height: `calc(${currentSettings.height}px - 67px)` }" :class="aspectRatio"></LoggedIn>
						</div>
						<div class="right-quadrant" :class="aspectRatio">
							<GameInfo v-if="!isElectron" :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `right top` }" :class="aspectRatio"></GameInfo>
						</div>
						<div class="social-tos" :style="getStyleObject">
							<SocialLinks :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `left bottom` }" />
						</div>
					</div>
				</transition>
				<transition name="splash-fade" style="z-index: 999999">
					<div v-if="Views.SPLASH === activeView" class="menu-panel">
						<div class="center-quadrant">
							<SplashScreen></SplashScreen>
						</div>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.TERMS_OF_USE === activeView" class="menu-panel">
						<div class="center-quadrant">
							<TermsOfUse :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center` }"></TermsOfUse>
						</div>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.PRIVACY_POLICY === activeView" class="menu-panel">
						<div class="center-quadrant">
							<PrivacyPolicy :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center` }"></PrivacyPolicy>
						</div>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.COOKIE_POLICY === activeView" class="menu-panel">
						<div class="center-quadrant">
							<CookiePolicy :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center` }"></CookiePolicy>
						</div>
					</div>
				</transition>
				<transition name="longfade">
					<div v-if="Views.CREDITS === activeView" class="menu-panel">
						<div class="center-quadrant">
							<Credits></Credits>
						</div>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.CREATE_ACCOUNT === activeView" class="menu-panel">
						<div class="center-quadrant">
							<CreateAccount :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center` }"></CreateAccount>
						</div>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.FORGOT_PASSWORD === activeView" class="menu-panel">
						<div class="center-quadrant">
							<ForgotPassword></ForgotPassword>
						</div>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.RESET_PASSWORD === activeView" class="menu-panel">
						<div class="center-quadrant">
							<ResetPassword></ResetPassword>
						</div>
					</div>
				</transition>

				<transition name="fade">
					<div :key="6" v-if="Views.END_CHAPTER === activeView && !showCallToAction" class="end-chapter" :class="{ active: Views.END_CHAPTER === activeView }">
						<EndChapter :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `0 0`, width: `${currentSettings.width}px`, height: `${currentSettings.height}px` }" :class="aspectRatio"></EndChapter>
					</div>
				</transition>
				<transition name="fade">
					<div :key="7" v-if="Views.CHARACTER_SELECT === activeView" class="character-select" :class="{ active: Views.CHARACTER_SELECT === activeView }">
						<CharacterSelect
							:chapter-selected="1"
							:style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `0 0`, width: `${currentSettings.width}px`, height: `${currentSettings.height}px` }"
							:class="aspectRatio"
						></CharacterSelect>
					</div>
				</transition>
				<transition name="fade">
					<div :key="8" v-if="Views.STORY_SELECT === activeView" class="story-select" :class="{ active: Views.STORY_SELECT === activeView }">
						<Stories :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateX(-50%) translateZ(0) scale(${responsiveScale})` }" :class="aspectRatio"></Stories>
					</div>
				</transition>
				<transition name="fade">
					<div :key="9" v-if="Views.CHAPTER_SELECT === activeView" class="chapter-select" :class="{ active: Views.CHAPTER_SELECT === activeView }">
						<ChapterSummary :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `0 0`, width: `${currentSettings.width}px`, height: `${currentSettings.height}px` }" :class="aspectRatio"></ChapterSummary>
					</div>
				</transition>
				<transition name="fade">
					<div :key="11" v-if="Views.SETTINGS === activeView" class="settings position-center" :class="{ active: Views.SETTINGS === activeView }">
						<Settings :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center`, height: `${currentSettings.height}px` }" :class="aspectRatio"></Settings>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.LINK_ACCOUNT_THROUGH_CODE === activeView" class="menu-panel" :class="{ active: Views.LINK_ACCOUNT_THROUGH_CODE === activeView }">
						<div class="center-quadrant">
							<LinkAccountThroughCode :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center`, height: `${currentSettings.height}px` }" :class="aspectRatio"></LinkAccountThroughCode>
						</div>
					</div>
				</transition>
				<transition name="fade">
					<div v-if="Views.CREATE_ACCOUNT_LINK_CODE === activeView" class="menu-panel" :class="{ active: Views.CREATE_ACCOUNT_LINK_CODE === activeView }">
						<div class="center-quadrant">
							<GenerateLinkCode :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center`, height: `${currentSettings.height}px` }" :class="aspectRatio"></GenerateLinkCode>
						</div>
					</div>
				</transition>

				<transition name="slide-down">
					<div :key="12" v-if="showPerks" class="perks-modal" :class="{ active: Views.META_PROGRESSION === activeView }">
						<PerksPrompt :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></PerksPrompt>
					</div>
				</transition>
				<transition name="fade">
					<div :key="13" v-if="showCallToAction" class="call-to-action" :class="{ active: showCallToAction }">
						<CallToAction :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, width: `${currentSettings.width}px`, height: `${currentSettings.height}px`, 'transform-origin': `top left` }" :class="aspectRatio"></CallToAction>
					</div>
				</transition>
				<transition name="fade">
					<div :key="14" v-if="Views.ACCOUNT_LINKING === activeView" class="account-linking menu-panel" :class="{ active: Views.ACCOUNT_LINKING === activeView }">
						<div class="center-quadrant">
							<AccountLinking :style="{ transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center` }"></AccountLinking>
						</div>
					</div>
				</transition>
			</div>
		</transition>
		<transition name="loading-fade" style="z-index: 999999">
			<div v-if="uiState === UICurrentState.LOADING" class="loading">
				<Loading></Loading>
			</div>
		</transition>
		<transition name="in-game-fade">
			<div v-if="uiState === UICurrentState.IN_GAME" class="in-game" id="menu">
				<Hud></Hud>
				<div v-if="!selectedStoryId" class="no-story-selected-warning">
					[DEBUG]: NO STORY ID!<br />
					This run will not be saved!<br />
				</div>
				<CurrencyDisplay :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `right top` }"></CurrencyDisplay>
				<Paused v-show="pauseToggledByUser"></Paused>
				<IconButton
					id="pause-button"
					:iconName="pauseToggledByUser ? 'play' : 'pause'"
					:buttonStyle="pauseToggledByUser ? 'bright-green' : 'dark-blue'"
					:onClickProp="pauseClicked"
					:style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `right top` }"
				></IconButton>
				<YouDied v-show="roundIsOver"></YouDied>
				<Victory v-show="roundWon"></Victory>
				<EventWarning :showBottom="showWarningBottom" :showTop="showWarningTop" :showLeft="showWarningLeft" :showRight="showWarningRight"></EventWarning>
				<BigMessage :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center top` }"></BigMessage>
			</div>
		</transition>
		<div v-show="enableDarkenOverlay" :class="{ active: enableDarkenOverlay }" class="darken"></div>
		<transition name="slide-up">
			<div v-if="getPromptVisible" :class="{ pointerEvents: getPromptVisible }" class="prompts">
				<GenericPrompt :class="[getPromptVisible === true ? 'active' : 'hidden']" class="center-panel" />
			</div>
		</transition>
		<transition name="slide-up">
			<div v-if="getYesNoPromptVisible" :class="{ pointerEvents: getYesNoPromptVisible }" class="prompts">
				<GenericTwoBtnPrompt :class="[getYesNoPromptVisible ? 'active' : 'hidden']" class="center-panel" />
			</div>
		</transition>
		<transition name="slide-down" @after-enter="onTransitionEntered" @enter="onTransitionEntering" @leave="onTransitionLeaving" @leave-to="onTransitionLeft">
			<div v-if="showLevelUpModal && !showMap" :class="{ pointerEvents: showLevelUpModal }" class="prompts level-up">
				<LevelUp :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></LevelUp>
			</div>
		</transition>
		<transition name="slide-down" appear>
			<div v-if="showMap && !pauseToggledByUser" :class="{ pointerEvents: showMap }" class="prompts">
				<MapEvent :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></MapEvent>
			</div>
		</transition>
		<transition name="slide-down-fade">
			<div v-if="showBookReviewModal" :class="{ pointerEvents: showBookReviewModal }" class="prompts book-report">
				<BookReview :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></BookReview>
			</div>
		</transition>
		<transition name="slide-down-fade">
			<div v-if="showVoteProgressModal" :class="{ pointerEvents: showVoteProgressModal }" class="prompts book-report">
				<VoteProgress :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></VoteProgress>
			</div>
		</transition>
		<transition name="slide-down">
			<div v-if="showCreateModal" :class="{ pointerEvents: showCreateModal }" class="prompts find-create">
				<FindCreateStory :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></FindCreateStory>
			</div>
		</transition>
		<transition name="slide-down-fade" appear>
			<div v-if="showActivityFeedModal" :class="{ pointerEvents: showActivityFeedModal }" class="prompts activity-feed">
				<ActivityFeedPrompt :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></ActivityFeedPrompt>
			</div>
		</transition>
		<transition name="slide-down-fade" appear>
			<div v-if="showErrorModal" :class="{ pointerEvents: showErrorModal }" class="prompts">
				<Error :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }"></Error>
			</div>
		</transition>
		<transition name="slide-up">
			<div v-if="showGameIdSearchModal" :class="{ pointerEvents: showGameIdSearchModal }" class="prompts">
				<GameIdSearch :style="{ transform: `perspective(1px) translateZ(0) rotate3d(0,1,0,0.00000000001deg) scale(${responsiveScale})`, 'transform-origin': `center` }" class="center-panel" />
			</div>
		</transition>
		<div v-if="showWishlistCorner" class="menu" :class="aspectRatio" style="z-index: 555555; pointer-events: none">
			<WishlistCorner></WishlistCorner>
		</div>
		<WebGLCheck></WebGLCheck>
		<div v-if="showCookiesUI" class="cookiePrompt">
			<Cookies></Cookies>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { UICurrentState, Views } from './store/ui-store'

// Modals
import GenericPrompt from './modal/generic-prompt.vue'
import GenericTwoBtnPrompt from './modal/generic-two-btn-prompt.vue'
import IconButton from './ui-components/buttons/icon-button.vue'
import LevelUp from './modal/level-up-prompt.vue'
import BookReview from './modal/book-review-prompt.vue'
import VoteProgress from './modal/vote-progress.vue'
import FindCreateStory from './modal/create-find-story-prompt.vue'
import PerksPrompt from './modal/perks-and-unlocks-prompt.vue'
import ActivityFeedPrompt from './modal/activity-feed-modal.vue'
import Error from './modal/error-prompt.vue'
import GameIdSearch from './modal/game-id-search-prompt.vue'

//Components
import PipBar from './ui-components/bars-sliders/pip-bar.vue'
import CooldownIndicator from './ui-components/cooldown-indicator.vue'
import EventWarning from './ui-components/event-warning.vue'
import SocialLinks from './ui-components/social-links.vue'
import MapEvent from './ui-components/maps/map-event.vue'
import { WARNING_TIME_MS } from '../entities/enemies/enemy-events-config'
import GameInfo from './ui-components/game-info.vue'
import Tooltip from './ui-components/tooltip/tooltip.vue'
import BigMessage from './ui-components/text/big-message.vue'
import CurrencyDisplay from './ui-components/currency/in-game-currency.vue'
import WishlistCorner from './ui-components/wishlist-corner.vue'
import WebGLCheck from './ui-components/webgl-check.vue'
import Cookies from './ui-components/cookies.vue'

// Views
import LandingPageView from './view/landing-page-view.vue'
import LoggedIn from './view/logged-in.vue'
import YouDied from './view/you-died.vue'
import Victory from './view/victory.vue'
import DebugTools from './view/debug-tools.vue'
import Paused from './view/paused.vue'
import CreateAccount from './view/create-account.vue'
import Hud from './view/hud.vue'
import EndChapter from './view/end-chapter.vue'
import AccountLinking from './view/account-linking.vue'
import Credits from './view/credits.vue'

//import EndChapterReview from './view/end-chapter-review.vue'
import CharacterSelect from './view/character-select.vue'
import ChapterSummary from './view/chapter-summary.vue'
import DebugIcons from './view/debug-icons.vue'
import DebugLightingColor from './view/debug-lighting-color.vue'
import ForgotPassword from './view/forgot-password.vue'
import ResetPassword from './view/reset-password.vue'
import Settings from './view/settings.vue'
import Loading from './view/loading.vue'
import Stories from './view/stories.vue'
import TermsOfUse from './view/terms-of-use.vue'
import PrivacyPolicy from './view/privacy-policy.vue'
import CookiePolicy from './view/cookie-policy.vue'
import CallToAction from './view/call-to-action.vue'
import SplashScreen from './view/splash-screen.vue'
import LinkAccountThroughCode from './view/link-account-through-code.vue'
import GenerateLinkCode from './view/generate-link-code.vue'
import { PauseManager } from '../engine/pause-manager'

export default {
	name: 'UIOverlay',
	components: {
		LandingPageView,
		GenericPrompt,
		GenericTwoBtnPrompt,
		IconButton,
		LevelUp,
		PipBar,
		YouDied,
		Victory,
		CooldownIndicator,
		DebugTools,
		EventWarning,
		SocialLinks,
		LoggedIn,
		GameInfo,
		CreateAccount,
		Hud,
		MapEvent,
		EndChapter,
		//EndChapterReview,
		BookReview,
		Paused,
		CharacterSelect,
		ChapterSummary,
		Tooltip,
		BigMessage,
		DebugIcons,
		DebugLightingColor,
		PerksPrompt,
		ForgotPassword,
		ResetPassword,
		Settings,
		Loading,
		VoteProgress,
		CurrencyDisplay,
		Stories,
		FindCreateStory,
		ActivityFeedPrompt,
		TermsOfUse,
		PrivacyPolicy,
		CookiePolicy,
		WishlistCorner,
		WebGLCheck,
		Error,
		Cookies,
		GameIdSearch,
		CallToAction,
		SplashScreen,
		AccountLinking,
		LinkAccountThroughCode,
		GenerateLinkCode,
		Credits
	},
	watch: {},
	data() {
		return {
			UICurrentState: UICurrentState,
			Views: Views,
			isElectron: process.env.IS_ELECTRON,
		}
	},
	computed: {
		...mapGetters('ui', ['isGamePaused', 'pauseToggledByUser', 'hasSplashBg', 'isEnvProductionLike', 'isEnvWeb', 'showCallToAction', 'showCookiesUI']),
		...mapGetters('genericPrompt', ['getPromptVisible', 'getPromptDimBackground']),
		...mapGetters('genericTwoButtonPrompt', ['getYesNoPromptVisible']),
		...mapGetters('levelUp', ['playerLevel']),
		...mapGetters('time', ['inGameTimestamp']),
		...mapGetters('characterSelect', ['characterSkinName', 'weaponSkinName']),
		...mapState('ui', [
			'uiState',
			'activeView',
			'currentHealth',
			'maxHealth',
			'roundIsOver',
			'totalAmmo',
			'currentAmmo',
			'roundWon',
			'enableDarkenOverlay',
			'showMap',
			'warningTopStartTimestamp',
			'warningBottomStartTimestamp',
			'warningRightStartTimestamp',
			'warningLeftStartTimestamp',
			'showDebugIconScreen',
			'showDebugLightingColor',
		]),
		...mapState('uiScaling', ['scale', 'newScale', 'frameWidth', 'frameHeight', 'aspectRatio', 'responsiveScale']),
		...mapState('settings', ['currentSettings']),
		...mapState('levelUp', ['showLevelUpModal', 'currentXP', 'nextLevelXP']),
		...mapState('story', ['selectedStoryId', 'showCreateModal', 'showGameIdSearchModal']),
		...mapState('characterSelect', ['characterData']),
		...mapState('endChapter', ['showBookReviewModal', 'showVoteProgressModal']),
		...mapState('metaProgression', ['showPerks']),
		...mapState('activityFeed', ['showActivityFeedModal']),
		...mapState('error', ['showErrorModal']),
		...mapState('user', ['isQa']),
		showWarningTop() {
			return this.warningTopStartTimestamp && this.warningTopStartTimestamp + WARNING_TIME_MS > this.inGameTimestamp
		},
		showWarningBottom() {
			return this.warningBottomStartTimestamp && this.warningBottomStartTimestamp + WARNING_TIME_MS > this.inGameTimestamp
		},
		showWarningLeft() {
			return this.warningLeftStartTimestamp && this.warningLeftStartTimestamp + WARNING_TIME_MS > this.inGameTimestamp
		},
		showWarningRight() {
			return this.warningRightStartTimestamp && this.warningRightStartTimestamp + WARNING_TIME_MS > this.inGameTimestamp
		},
		showWishlistCorner() {
			return (Views.LANDING === this.activeView || Views.LOGGED_IN === this.activeView || Views.STORY_SELECT === this.activeView || Views.CHAPTER_SELECT === this.activeView || Views.CHARACTER_SELECT === this.activeView) && !process.env.IS_ELECTRON
		},
		getStyleObject() {
			if (this.aspectRatio === 'ratio21By9') {
				let transform = `translateX(calc(-50% * ${this.responsiveScale}))`
				let leftPosition = `50%`
				return { transform: transform, left: leftPosition }
			}
		},
	},
	methods: {
		...mapActions('characterSelect', ['fetchCharacterData']),
		...mapActions('ui', ['togglePauseByUser', 'startGame']),
		...mapMutations('ui', ['updateAnimationTransitioning']),
		...mapActions('uiScaling', ['fetchResponsiveScale']),
		...mapActions('settings', ['setInitialSettingsConfig', 'changeWindowModeString', 'saveSettings']),

		onTransitionEntered() {
			this.updateAnimationTransitioning(false)
		},
		onTransitionEntering() {
			this.updateAnimationTransitioning(true)
		},
		mainMenuEntering() {
			this.fetchResponsiveScale()
		},
		onTransitionLeaving() {
			this.updateAnimationTransitioning(true)
		},
		onTransitionLeft() {
			this.updateAnimationTransitioning(false)
		},
		pauseClicked() {
			if (PauseManager.isPaused()) {
				this.saveSettings()
			}

			this.togglePauseByUser()
		},
	},
	beforeMount() {
		if (process.env.IS_ELECTRON) {
			window.bridge.getElectronStore().then((settings) => {
				this.setInitialSettingsConfig(settings)
			})
		}
		this.fetchCharacterData()
	},
	mounted() {
		if (process.env.IS_ELECTRON) {
			window.bridge.onWindowEvent((windowMode) => {
				this.changeWindowModeString(windowMode)
			})
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../web/global/fonts/fonts.less');

@keyframes vertical-slide-in {
	0% {
		transform: translateY(-100%);
	}

	60% {
		transform: translateY(20%);
	}

	100% {
		transform: translateY(0%);
	}
}

@keyframes vertical-slide-out {
	0% {
		transform: translateY(0%);
	}

	40% {
		transform: translateY(10%);
	}

	100% {
		transform: translateY(-100%);
	}
}

.slide-down-enter-active {
	animation: vertical-slide-in 0.5s;
}

.slide-down-leave-active {
	animation: vertical-slide-out 0.5s;
}
#ui-overlay {
	pointer-events: none;
	position: absolute;
	user-select: none;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
	.background {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		&.splashbg {
			background-image: url('~/assets/ui/splash-screens/landing-image.png');
		}
		&.basicbg {
			background-image: url('~/assets/ui/backgrounds/basic-bg.png');
		}
		&.forestbg {
			background-image: url('~/assets/ui/backgrounds/end-credits-bg.png');
		}
	}

	.menu {
		background-size: cover;
		pointer-events: all;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		max-height: 100%;

		.social-tos {
			position: absolute;
			bottom: 15px;
			left: 15px;
		}

		.landing-page,
		.logged-in,
		.sign-up,
		.menu-panel,
		.story,
		.end-chapter,
		.character-select,
		.story-select,
		.chapter-select,
		.settings,
		.account-link,
		.call-to-action {
			height: 100%;
			width: 100%;
			z-index: 100;
			position: absolute;
		}

		.landing-page,
		.logged-in {
			display: flex;
			flex-direction: row;

			.left-quadrant {
				width: 50%;
				&.ratio21By9 {
					width: 100%;
				}
			}
			.right-quadrant {
				width: 50%;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: center;
				position: absolute;
				right: 0;
				&.ratio21By9 {
					width: 15%;
				}
			}
		}

		.story {
			&.active {
			}
		}
		.menu-panel {
			display: flex;
			flex-direction: row;
			.center-quadrant {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.loading {
		/* temp code. Backgrounds will use JS random fn to get the image from
		an array at some point when we have more images */
		height: 100%;
		width: 100%;
		overflow: hidden;
		pointer-events: all;

		box-shadow: inset 0 0 300px black;
		position: absolute;
	}

	.in-game {
		position: relative;
		width: 100vw;
		height: 100vw;

		#pause-button {
			position: absolute;
			top: 80px;
			right: 20px;
			z-index: 9999999;
			pointer-events: all;
		}

		#experience-bar {
			position: absolute;
			top: 20px;
			left: 20px;
			right: 150px;
			height: 40px;
		}

		#health-pip-bar {
			position: absolute;
			top: 80px;
			left: calc(50% - 300px);
			width: 600px;
			height: 50px;
		}
		#currency-display {
		}

		.ammo-bar {
			position: absolute;
			top: 143px;
			left: calc(50% - 300px);
			width: 600px;
			height: 50px;
			display: flex;
		}

		.energy-bar {
			position: absolute;
			bottom: 50px;
			left: 50%;
		}

		#current-level {
			position: absolute;
			top: 10px;
			left: 5px;
			font-size: 50px;
			color: white;
		}

		#countdown {
			position: absolute;
			top: 80px;
			left: 5px;
		}
	}

	.debug-tools {
		position: absolute;
		z-index: 999;
		width: 100%;
		height: 100%;

		#cooldown {
			position: absolute;
			bottom: 40px;
			left: 40px;
		}
	}
	.debug-icons {
		z-index: 200;
		width: 100vw;
		height: 100vh;
		position: absolute;
		right: 0px;
		top: 0px;
		overflow-y: auto;
	}
	.debug-lighting {
		z-index: 999;
		width: 25vw;
		height: 25vh;
		position: absolute;
		right: 0px;
		top: 50%;
		overflow-y: visible;
	}

	#roller-button {
		position: absolute;
		top: 150px;
		right: 20px;
	}

	.darken {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		position: absolute;

		&.active {
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 300;
			pointer-events: all;
		}
	}
}

.no-story-selected-warning {
	position: absolute;
	background-color: crimson;
	border-radius: 10px;
	height: 50px;
	width: 240px;
	font-size: 20px;
	top: 100px;
	left: 200px;
	pointer-events: all;
	opacity: 0.75;

	&:hover {
		opacity: 0.1;
	}
}

.perks-modal {
	z-index: 999;
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}

.prompts {
	z-index: 99999999;
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;

	&.pointerEvents {
		pointer-events: all;
	}

	.center-panel {
		color: red;
		transform-origin: center;

		&.hidden {
			display: none !important;
		}
	}
}
.cookiePrompt {
	z-index: 99999;
	pointer-events: all;
}

.fade-enter-active {
	transition: all 0.25s ease-out;
}
.fade-leave-active {
	transition: all 0.15s ease-out;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.longfade-enter-active {
	transition: all 0.5s ease-out;
}
.longfade-leave-active {
	transition: all 0.15s ease-out;
}
.longfade-enter-from,
.longfade-leave-to {
	opacity: 0;
}


.main-menu-fade-enter-active {
	transition: all 0.5s ease-out;
	transition-delay: 0.5s;
}
.main-menu-fade-leave-active {
	transition: all 0.5s ease-out;
}

.main-menu-fade-enter-from,
.main-menu-fade-leave-to {
	opacity: 0;
}
///////////////////
.loading-fade-enter-active {
	transition: all 1s ease-out;
}
.loading-fade-leave-active {
	transition: all 1.5s ease-out;
}

.loading-fade-enter-from,
.loading-fade-leave-to {
	opacity: 0;
}
/////////////////
.splash-fade-enter-active {
	transition: all 1s ease-out;
}
.splash-fade-leave-active {
	transition: all 1.75s ease-out;
}

.splash-fade-enter-from,
.splash-fade-leave-to {
	opacity: 0;
}
/////////////////
.in-game-fade-enter-active {
	transition: all 1s ease-out;
}
.in-game-fade-leave-active {
	transition: all 1.5s ease-out;
}

.in-game-fade-enter-from,
.in-game-fade-leave-to {
	opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
	transition: all 0.25s ease-out;
}

.slide-up-enter-from {
	opacity: 0;
	transform: translateY(-30px);
}

.slide-up-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}

.slide-down-fade-enter-active {
	transition: all 0.25s ease-out;
	animation-delay: 1.5s;
	animation: vertical-slide-in 0.5s;
	transition-delay: 0.5s;
}

.slide-down-fade-enter-from {
	opacity: 0;
}
.slide-down-fade-leave-active {
	animation: vertical-slide-out 0.5s;
}
</style>
