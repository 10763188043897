<template>
	<div v-show="activeName" class="upgrade-information">
		<div class="upgrade-shape">
			<div v-if="getUpgrade.shape === 'diamond'" class="shape diamond">
				<div class="background diamond"></div>
				<div class="row top">
					<ItemUpdated
						:item="getUpgrade.top"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.top.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.top.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-lock-icon="upgradeBlocked(getUpgrade.top.upgradeIndex)"
						:disable-circle="false"
					></ItemUpdated>
				</div>
				<div class="row middle">
					<ItemUpdated
						:item="getUpgrade.left"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.left.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.left.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:dim-icon="dimIcon(getUpgrade.left.upgradeIndex)"
						:show-lock-icon="upgradeBlocked(getUpgrade.left.upgradeIndex)"
						:disable-circle="false"
					></ItemUpdated>
					<ItemUpdated
						:item="getUpgrade.right"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.right.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.right.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:dim-icon="dimIcon(getUpgrade.right.upgradeIndex)"
						:show-lock-icon="upgradeBlocked(getUpgrade.right.upgradeIndex)"
						:disable-circle="false"
					></ItemUpdated>
				</div>
				<div class="row bottom">
					<ItemUpdated
						:item="getUpgrade.bottom"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.bottom.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.bottom.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-final="true"
						:dim-icon="dimIcon(getUpgrade.bottom.upgradeIndex)"
						:show-lock-icon="upgradeBlocked(getUpgrade.bottom.upgradeIndex)"
						:disable-circle="false"
					></ItemUpdated>
				</div>
			</div>
			<div v-else-if="getUpgrade.shape === 'single'" class="shape single">
				<div class="row top">
					<ItemUpdated
						:item="getUpgrade.top"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.top.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.top.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:dim-icon="'false'"
						:show-final="true"
						:disable-circle="false"
					></ItemUpdated>
				</div>
			</div>
			<div v-else-if="getUpgrade.shape === 'line'" class="shape line">
				<div class="background line" :style="lineHeight"></div>
				<div class="row top">
					<ItemUpdated
						v-for="(upgrade, index) in getUpgrade.upgrades"
						:key="index"
						:item="getUpgrade.upgrades[index]"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.upgrades[index].upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.upgrades[index].upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-final="getUpgrade.upgrades.length - 1 === index ? true : false"
						:dim-icon="dimIcon(getUpgrade.upgrades[index].upgradeIndex)"
						:disable-circle="false"
					></ItemUpdated>
				</div>
			</div>
			<div v-else-if="getUpgrade.shape === 'tree'" class="shape tree-expanded">
				<div class="background-arrows">
					<div class="top-arrow">
						<div class="arrow left-position" :class="{ glow: upgradeIsSelected(getUpgrade.top.upgradeIndex),  'show-arrow': !upgradeBlocked(getUpgrade.left.upgradeIndex)}"></div>
						<div class="arrow right-position" :class="{ glow: upgradeIsSelected(getUpgrade.top.upgradeIndex), 'show-arrow': !upgradeBlocked(getUpgrade.right.upgradeIndex) }"></div>
					</div>
					<div class="left-arrow" :class="{ faded: upgradeBlocked(getUpgrade.left.upgradeIndex), glow: upgradeIsSelected(getUpgrade.left.upgradeIndex) }">
						<div class="arrow left-position" :class="{ glow: upgradeIsSelected(getUpgrade.left.upgradeIndex),  'show-arrow': !upgradeBlocked(getUpgrade.leftLeaf.upgradeIndex) && !upgradeIsSelected(getUpgrade.top.upgradeIndex)}"></div>
						<div class="arrow right-position" :class="{ glow: upgradeIsSelected(getUpgrade.left.upgradeIndex), 'show-arrow':  middleArrowsCheck('left')}"></div>
					</div>
					<div class="right-arrow" :class="{ faded: upgradeBlocked(getUpgrade.right.upgradeIndex), glow: upgradeIsSelected(getUpgrade.right.upgradeIndex) }">
						<div class="arrow left-position" :class="{ glow: upgradeIsSelected(getUpgrade.right.upgradeIndex), 'show-arrow': middleArrowsCheck('right') }"></div>
						<div class="arrow right-position" :class="{ glow: upgradeIsSelected(getUpgrade.right.upgradeIndex),  'show-arrow': !upgradeBlocked(getUpgrade.rightLeaf.upgradeIndex) && !upgradeIsSelected(getUpgrade.top.upgradeIndex)}"></div>
					</div>
					<div v-if="evolutionTreeBackground" class="expanded">
						<div class="expanded-arrow left" :class="{glow: !upgradeBlocked(getUpgrade.leftLeaf.upgradeIndex) && !upgradeIsSelected(getUpgrade.top.upgradeIndex)}"></div>
						<div class="expanded-arrow middle" :class="{glow: !upgradeBlocked(getUpgrade.middleLeaf.upgradeIndex) && !upgradeIsSelected(getUpgrade.top.upgradeIndex)}"></div>
						<div class="expanded-arrow right" :class="{glow: !upgradeBlocked(getUpgrade.rightLeaf.upgradeIndex) && !upgradeIsSelected(getUpgrade.top.upgradeIndex)}"></div>
					</div>
				</div>
				<div class="row top">
					<ItemUpdated
						:item="getUpgrade.top"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.top.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.top.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:dim-icon="dimIcon(getUpgrade.top.upgradeIndex)"
						:show-final="showFinal('')"
						:disable-circle="false"
					></ItemUpdated>
				</div>
				<div class="row middle">
					<ItemUpdated
						:item="getUpgrade.left"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.left.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.left.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-lock-icon="upgradeBlocked(getUpgrade.left.upgradeIndex)"
						:dim-icon="dimIcon(getUpgrade.left.upgradeIndex)"
						:show-final="showFinal('')"
						:disable-circle="false"
					></ItemUpdated>
					<ItemUpdated
						:item="getUpgrade.right"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.right.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.right.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-lock-icon="upgradeBlocked(getUpgrade.right.upgradeIndex)"
						:dim-icon="dimIcon(getUpgrade.right.upgradeIndex)"
						:show-final="showFinal('')"
						:disable-circle="false"
					></ItemUpdated>
				</div>
				<div class="row bottom">
					<ItemUpdated
						:item="getUpgrade.leftLeaf"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.leftLeaf.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.leftLeaf.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-lock-icon="upgradeBlocked(getUpgrade.leftLeaf.upgradeIndex)"
						:dim-icon="dimIcon(getUpgrade.leftLeaf.upgradeIndex)"
						:show-final="showFinal('leftLeaf')"
						:show-legendary="showLegendary('leftLeaf')"
						:disable-circle="false"
						:tooltipEnabled="showEvolutionTooltip('leftLeaf')"
						:component="'treeUpgrade'"
					></ItemUpdated>
					<ItemUpdated
						:item="getUpgrade.middleLeaf"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.middleLeaf.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.middleLeaf.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-lock-icon="upgradeBlocked(getUpgrade.middleLeaf.upgradeIndex)"
						:dim-icon="dimIcon(getUpgrade.middleLeaf.upgradeIndex)"
						:show-final="showFinal('middleLeaf')"
						:show-legendary="showLegendary('middleLeaf')"
						:disable-circle="false"
						:tooltipEnabled="showEvolutionTooltip('middleLeaf')"
						:component="'treeUpgrade'"
					></ItemUpdated>
					<ItemUpdated
						:item="getUpgrade.rightLeaf"
						:on-click-prop="() => {}"
						:selectable="false"
						:selected="upgradeIsSelected(getUpgrade.rightLeaf.upgradeIndex)"
						:hovered="upgradeIsHovered(getUpgrade.rightLeaf.upgradeIndex)"
						:on-hover-fn="upgradeOver"
						:show-lock-icon="upgradeBlocked(getUpgrade.rightLeaf.upgradeIndex)"
						:dim-icon="dimIcon(getUpgrade.rightLeaf.upgradeIndex)"
						:show-final="showFinal('rightLeaf')"
						:show-legendary="showLegendary('rightLeaf')"
						:disable-circle="false"
						:tooltipEnabled="showEvolutionTooltip('rightLeaf')"
						:component="'treeUpgrade'"
					></ItemUpdated>
				</div>
			</div>
		</div>
		<!-- <div v-show="!activeName" class="upgrade-information">
			<div class="description">
				<div class="description-header">
					<h2>Level Up!</h2>
					s
				</div>
				<div class="description-body">
					<p>Select an upgrade and a node from that upgrade tree to power up your character. Just pick something :D</p>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Description from '../../ui-components/text/description.vue'
import ItemUpdated from '../../ui-components/item/items-updated.vue'

export default {
	name: 'FocusedUpgrade',
	components: {
		Description,
		ItemUpdated,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('levelUp', ['getUpgrade', 'activeDesc', 'activeName']),
		...mapState('levelUp', ['selectedUpgrade', 'selectedUpgrade', 'hoveredUpgrade', 'excludedUpgrades', 'allocatedIds', 'upgradeTreeWithIds']),
		...mapState('ui', ['animationTransitioning']),
		evolutionTreeBackground() {
			if (this.getUpgrade.shape === 'diamond') {
				if (this.getUpgrade.upgradeEvolutionTier === 'tier1') {
					return this.getUpgrade.upgradeEvolutionTier
				}
			}
			if (this.getUpgrade.shape === 'tree') {
				if (this.getUpgrade.upgradeEvolutionTier === 'tier1') {
					return this.getUpgrade.upgradeEvolutionTier
				}
			}
			if (this.getUpgrade.shape === 'line') {
				if (this.getUpgrade.upgradeEvolutionTier === 'tier1') {
					return this.getUpgrade.upgradeEvolutionTier
				}
			}
			if (this.getUpgrade.shape === 'single') {
				if (this.getUpgrade.upgradeEvolutionTier === 'tier1') {
					return this.getUpgrade.upgradeEvolutionTier
				}
			}
		},
		lineHeight() {
			if (this.getUpgrade.upgrades.length === 2) {
				return 'height: 41px;'
			} else if (this.getUpgrade.upgrades.length === 3) {
				return 'height: 141px;'
			} else {
				return 'height: 241px;'
			}
		},
	},
	methods: {
		...mapActions('levelUp', ['upgradeOver']),
		upgradeIsSelected(upgradeIndex) {
			if (this.selectedUpgrade !== null) {
				return upgradeIndex === this.selectedUpgrade.id
			}
		},
		upgradeIsHovered(upgradeIndex) {
			if (this.hoveredUpgrade !== null && this.selectedUpgrade !== this.hoveredUpgrade) {
				return upgradeIndex === this.hoveredUpgrade.id
			}
		},
		upgradeBlocked(upgradeIndex) {
			for (const iterator of this.allocatedIds) {
				const excludedUpgrade = this.excludedUpgrades.find((upgrade) => {
					return upgrade.upgradeIndex === iterator
				})
				if (excludedUpgrade !== undefined) {
					for (const iterator of excludedUpgrade.excluded) {
						if (iterator == upgradeIndex) {
							return true
						}
					}
				}
			}
			if (this.selectedUpgrade !== null) {
				const excludedUpgrade = this.excludedUpgrades.find((upgrade) => {
					return upgrade.upgradeIndex === this.selectedUpgrade.id
				})
				if (excludedUpgrade) {
					for (const iterator of excludedUpgrade.excluded) {
						if (iterator == upgradeIndex) {
							return true
						}
					}
				}
			}
			return false
		},
		dimIcon(upgradeIndex) {
			if (this.selectedUpgrade.id === upgradeIndex || this.allocatedIds.includes(upgradeIndex)) {
				return false
			}
			return true
		},
		showLegendary(leaf) {
			if ((leaf === 'leftLeaf' || leaf === 'rightLeaf' || leaf === 'middleLeaf') && this.getUpgrade.upgradeEvolutionTier === 'tier2') {
				return true
			}
		},
		showFinal(leaf) {
			if (this.getUpgrade.upgradeEvolutionTier === 'tier2') {
				return true
			}
			if ((leaf === 'leftLeaf' || leaf === 'rightLeaf' || leaf === 'middleLeaf') && this.getUpgrade.upgradeEvolutionTier === 'tier1') {
				return true
			}
			if ((leaf === 'leftLeaf' || leaf === 'rightLeaf' || leaf === 'middleLeaf') && this.getUpgrade.upgradeEvolutionTier === undefined) {
				return true
			}
		},
		showEvolutionTooltip(leaf) {
			if (this.animationTransitioning) {
				return false
			}
			return this.getUpgrade[leaf]?.unlocks !== undefined
		},
		middleArrowsCheck(position){
			switch (position) {
				case 'left':
					return !this.upgradeBlocked(this.getUpgrade.left.upgradeIndex) && this.selectedUpgrade.id === this.getUpgrade.middleLeaf.upgradeIndex

				case 'right':
					return !this.upgradeBlocked(this.getUpgrade.right.upgradeIndex) && this.selectedUpgrade.id === this.getUpgrade.middleLeaf.upgradeIndex
			}
		}
	},
}
</script>

<style lang="less" scoped>
.upgrade-information {
	display: flex;
	.description {
		// remove these if you want to have a upgrade shape
		text-align: center;
		width: 100%;
		.description-header {
			h3 {
				color: white;
			}
		}
		.description-body {
			width: 400px;
			height: 90px;
			text-align: center;
			p {
				color: white;
			}
		}
		.stat-block {
			width: 400px;
			height: 180px;
			text-align: left;
			p {
				margin-left: 100px;
				color: white;
			}
		}
	}
	.upgrade-shape {
		.shape {
			position: relative;
			.background {
				position: absolute;
				right: 0;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				&.diamond {
					background-image: url('../../../../assets/ui/item/tree-arrows-diamond.png');
					width: 221px;
					height: 277px;
					top: 10px;
				}
				&.diamond-expanded {
					background-image: url('../../../../assets/ui/item/tree-arrows-diamond-cont.png');
					width: 152px;
					height: 342px;
					top: 154px;
				}
				&.line {
					background-image: url('../../../../assets/ui/item/tree-arrows-line.png');
					width: 35px;
					height: 241px;
					top: 1px;
				}
			}
			.background-arrows {
				position: absolute;
				width: 464px;
				height: 339px;
				.top-arrow,
				.left-arrow,
				.right-arrow {
					.arrow {
						position: absolute;
						width: 34px;
						height: 86px;
						background-image: url('../../../../assets/ui/item/tree-long-arrows.png');
						opacity: 0.25;
						&.left-position {
							transform: rotate(38deg);
						}
						&.right-position {
							transform: rotate(-38deg);
						}
						&.glow {
							background-image: url('../../../../assets/ui/item/tree-long-arrows-glow.png');
							opacity: 1;
							animation: fade 2s infinite ease-in-out;
						}
						&.show-arrow{
							opacity: 1;
						}
					}
				}
				.top-arrow {
					.left-position {
						top: 97px;
						left: 164px;
					}
					.right-position {
						top: 97px;
						left: 267px;
					}
					.glow {
						background-image: url('../../../../assets/ui/item/tree-long-arrows-glow.png');
						opacity: 1;
						animation: fade 2s infinite ease-in-out;
					}
				}
				.left-arrow {
					&.faded {
					}
					&.glow {
					}
					.left-position {
						top: 232px;
						left: 55px;
					}
					.right-position {
						top: 232px;
						left: 155px;
					}
				}

				.right-arrow {
					&.faded {
					}
					&.glow {
					}
					.left-position {
						top: 232px;
						left: 275px;
					}
					.right-position {
						top: 232px;
						left: 375px;
					}
				}

				.expanded {
					position: absolute;
					width: 34px;
					height: 53px;
					top: 400px;
					left: -3px;
					.expanded-arrow.left,
					.expanded-arrow.middle,
					.expanded-arrow.right {
						width: 34px;
						height: 53px;
						position: absolute;
						background-image: url('../../../../assets/ui/item/tree-single-arrow.png');
						opacity: 0.25;
					&.glow {
						background-image: url('../../../../assets/ui/item/tree-single-arrow-glow.png');
						opacity: 1;
						animation: fade 2s infinite ease-in-out;
					}

					}
					.expanded-arrow.left {
						top: 0px;
						left: 0px;
					}
					.expanded-arrow.middle {
						top: 0px;
						left: 218px;
					}
					.expanded-arrow.right {
						top: 0px;
						left: 437px;
					}
				}
			}

			&.diamond {
				width: 325px;
				.row {
					.item {
						padding: 20px 62px;
					}
				}
			}
			&.diamond-expanded {
				width: 325px;
				.row {
					.item {
						padding: 20px 62px;
					}
					&.bottom {
						.item {
							position: relative;
							top: 9px;
						}
					}
				}
			}

			&.tree {
				width: 393px;
				.row {
					.item {
						margin: 20px 62px;
					}
				}
			}
			&.tree-expanded {
				width: 393px;
				.row {
					.item {
						margin: 20px 62px;
					}
				}
			}
			&.line {
				margin-top: 40px;
				.row {
					.item {
						padding: 0px 0px;
						&:first-child {
							position: relative;
							top: -12px;
						}
						&:last-child {
							position: relative;
							top: 12px;
						}
						&:nth-child(even) {
							margin-right: 90px;
						}
						&:nth-child(odd) {
							margin-left: 103px;
						}
					}
				}
			}

			&.diamond,
			&.diamond-expanded,
			&.line,
			&.tree,
			&.tree-expanded {
				display: flex;
				flex-direction: column;
				align-items: center;
				.row.top {
				}
				.row.middle {
					display: flex;
				}
				.row.bottom {
					display: flex;
				}
			}
		}
	}

	@keyframes fade {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		}
	}
}
</style>
