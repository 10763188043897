{"frames": {

"event-a-broken-heart-weeps-open.png":
{
	"frame": {"x":76,"y":586,"w":59,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":13,"w":59,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"event-big-brain-learning.png":
{
	"frame": {"x":279,"y":749,"w":61,"h":44},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":11,"w":61,"h":44},
	"sourceSize": {"w":66,"h":64}
},
"event-bullet-heaven.png":
{
	"frame": {"x":275,"y":484,"w":61,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":3,"w":61,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"event-chain-reaction.png":
{
	"frame": {"x":74,"y":1723,"w":62,"h":36},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":16,"w":62,"h":36},
	"sourceSize": {"w":66,"h":64}
},
"event-close-and-bloody.png":
{
	"frame": {"x":3,"y":1951,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"event-demigod-of-thunder.png":
{
	"frame": {"x":234,"y":76,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"event-faster-than-before.png":
{
	"frame": {"x":209,"y":749,"w":66,"h":33},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":18,"w":66,"h":33},
	"sourceSize": {"w":66,"h":64}
},
"event-forest-fire.png":
{
	"frame": {"x":69,"y":639,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"event-hyper-pets.png":
{
	"frame": {"x":344,"y":749,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"event-icy-come-icy-go.png":
{
	"frame": {"x":166,"y":75,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"event-id-rather-be-rich.png":
{
	"frame": {"x":482,"y":749,"w":60,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":3,"w":60,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"event-jokers-wild.png":
{
	"frame": {"x":139,"y":774,"w":66,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":6,"w":66,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"event-master-of-elements.png":
{
	"frame": {"x":272,"y":546,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"event-more-gain-more-pain.png":
{
	"frame": {"x":410,"y":686,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"event-stronger-better-faster-longer.png":
{
	"frame": {"x":264,"y":143,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"grand-pike-01.png":
{
	"frame": {"x":304,"y":76,"w":60,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":60,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-shocking-number-of-explosions.png":
{
	"frame": {"x":69,"y":707,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-surprising-climax.png":
{
	"frame": {"x":413,"y":749,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-vast-arsenal.png":
{
	"frame": {"x":67,"y":962,"w":65,"h":50},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":50},
	"sourceSize": {"w":65,"h":50}
},
"twist-ace-of-scrolls.png":
{
	"frame": {"x":3,"y":81,"w":64,"h":77},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":77},
	"sourceSize": {"w":64,"h":77}
},
"twist-attack-of-the-giant-shamblers.png":
{
	"frame": {"x":69,"y":775,"w":66,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"twist-bad-eyes.png":
{
	"frame": {"x":209,"y":786,"w":66,"h":44},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":17,"w":66,"h":44},
	"sourceSize": {"w":66,"h":64}
},
"twist-berserker.png":
{
	"frame": {"x":73,"y":231,"w":60,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":68},
	"sourceSize": {"w":60,"h":68}
},
"twist-better-go-fast.png":
{
	"frame": {"x":73,"y":1763,"w":64,"h":53},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":53},
	"sourceSize": {"w":64,"h":53}
},
"twist-big-brain-frail-body.png":
{
	"frame": {"x":924,"y":204,"w":39,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":14,"y":1,"w":39,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-bloated-blimpies.png":
{
	"frame": {"x":265,"y":211,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-butter-fingers.png":
{
	"frame": {"x":279,"y":681,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-chained-together.png":
{
	"frame": {"x":328,"y":143,"w":55,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":55,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-combat-arena.png":
{
	"frame": {"x":331,"y":211,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-deferred-learning.png":
{
	"frame": {"x":131,"y":1216,"w":62,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":62,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"twist-diabolical-furniture.png":
{
	"frame": {"x":615,"y":749,"w":52,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":2,"w":52,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"twist-dont-drop-the-egg.png":
{
	"frame": {"x":387,"y":139,"w":48,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":48,"h":67},
	"sourceSize": {"w":48,"h":67}
},
"twist-eating-their-wheaties.png":
{
	"frame": {"x":200,"y":348,"w":64,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":64,"h":64}
},
"twist-elemental-maelstrom.png":
{
	"frame": {"x":130,"y":1278,"w":63,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":5,"w":63,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-elixir-of-something.png":
{
	"frame": {"x":140,"y":505,"w":56,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":59},
	"sourceSize": {"w":56,"h":59}
},
"twist-enraged.png":
{
	"frame": {"x":615,"y":3,"w":71,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":71,"h":61},
	"sourceSize": {"w":71,"h":61}
},
"twist-eyes-filled-with-rage.png":
{
	"frame": {"x":340,"y":688,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-falling-folk-dance.png":
{
	"frame": {"x":900,"y":74,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"twist-favorite-toy.png":
{
	"frame": {"x":3,"y":1310,"w":52,"h":70},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":52,"h":70},
	"sourceSize": {"w":52,"h":70}
},
"twist-floor-is-lava.png":
{
	"frame": {"x":200,"y":416,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-for-want-of-a-boom.png":
{
	"frame": {"x":3,"y":1080,"w":54,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":54,"h":74},
	"sourceSize": {"w":54,"h":74}
},
"twist-fountains-of-mana.png":
{
	"frame": {"x":3,"y":1881,"w":64,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":66},
	"sourceSize": {"w":64,"h":66}
},
"twist-goblin-season-is-open.png":
{
	"frame": {"x":3,"y":319,"w":68,"h":73},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":68,"h":73},
	"sourceSize": {"w":68,"h":73}
},
"twist-hardcore-survival.png":
{
	"frame": {"x":194,"y":143,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-head-start.png":
{
	"frame": {"x":546,"y":749,"w":65,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":61},
	"sourceSize": {"w":65,"h":61}
},
"twist-infestation.png":
{
	"frame": {"x":195,"y":211,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-insect-fever.png":
{
	"frame": {"x":3,"y":1601,"w":67,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":67,"h":66},
	"sourceSize": {"w":67,"h":66}
},
"twist-intelligence-was-my-dumb-stat.png":
{
	"frame": {"x":137,"y":222,"w":54,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":54,"h":67},
	"sourceSize": {"w":54,"h":67}
},
"twist-king-shriekers.png":
{
	"frame": {"x":71,"y":81,"w":44,"h":73},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":44,"h":73},
	"sourceSize": {"w":44,"h":73}
},
"twist-legends-never-die.png":
{
	"frame": {"x":554,"y":948,"w":58,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":61},
	"sourceSize": {"w":58,"h":61}
},
"twist-little-plague-bearers.png":
{
	"frame": {"x":3,"y":854,"w":60,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":71},
	"sourceSize": {"w":60,"h":71}
},
"twist-making-new-friends.png":
{
	"frame": {"x":864,"y":1146,"w":48,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":48,"h":62},
	"sourceSize": {"w":48,"h":62}
},
"twist-monster-merge-game.png":
{
	"frame": {"x":764,"y":3,"w":69,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":69,"h":63},
	"sourceSize": {"w":69,"h":63}
},
"twist-monster-whistle.png":
{
	"frame": {"x":3,"y":1004,"w":57,"h":72},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":72},
	"sourceSize": {"w":57,"h":72}
},
"twist-narrow-focus.png":
{
	"frame": {"x":204,"y":1691,"w":59,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":59,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"twist-not-quite-wings.png":
{
	"frame": {"x":825,"y":1081,"w":62,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":61},
	"sourceSize": {"w":62,"h":61}
},
"twist-oh-approaching-me.png":
{
	"frame": {"x":79,"y":3,"w":73,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":73,"h":71},
	"sourceSize": {"w":73,"h":71}
},
"twist-paranormal-exercise.png":
{
	"frame": {"x":924,"y":343,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-personal-mastery.png":
{
	"frame": {"x":3,"y":396,"w":68,"h":73},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":68,"h":73},
	"sourceSize": {"w":68,"h":73}
},
"twist-pet-jail-break.png":
{
	"frame": {"x":337,"y":417,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-pet-survival.png":
{
	"frame": {"x":204,"y":1822,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-pinball.png":
{
	"frame": {"x":685,"y":815,"w":54,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":2,"w":54,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"twist-power-overwhelming.png":
{
	"frame": {"x":200,"y":279,"w":63,"h":65},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":65},
	"sourceSize": {"w":63,"h":65}
},
"twist-punch-through.png":
{
	"frame": {"x":267,"y":279,"w":62,"h":65},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":65},
	"sourceSize": {"w":62,"h":65}
},
"twist-quick-hands.png":
{
	"frame": {"x":879,"y":680,"w":57,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":63},
	"sourceSize": {"w":57,"h":63}
},
"twist-random-ricochet.png":
{
	"frame": {"x":3,"y":625,"w":62,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":74},
	"sourceSize": {"w":62,"h":74}
},
"twist-ransom-situation.png":
{
	"frame": {"x":549,"y":1013,"w":63,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"twist-roaming-wildlings.png":
{
	"frame": {"x":837,"y":3,"w":69,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":69,"h":63},
	"sourceSize": {"w":69,"h":63}
},
"twist-scrap-ing-by.png":
{
	"frame": {"x":75,"y":303,"w":61,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":67},
	"sourceSize": {"w":61,"h":67}
},
"twist-scrapyard.png":
{
	"frame": {"x":3,"y":1158,"w":54,"h":73},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":54,"h":73},
	"sourceSize": {"w":54,"h":73}
},
"twist-shambling-towers.png":
{
	"frame": {"x":268,"y":348,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-shielded-lifestyle.png":
{
	"frame": {"x":333,"y":279,"w":56,"h":65},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":65},
	"sourceSize": {"w":56,"h":65}
},
"twist-shrieker-chaos.png":
{
	"frame": {"x":340,"y":484,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-shriekers-shrieking.png":
{
	"frame": {"x":268,"y":416,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-skillful-evasion.png":
{
	"frame": {"x":690,"y":3,"w":70,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":70,"h":62},
	"sourceSize": {"w":70,"h":62}
},
"twist-spectral-farmer.png":
{
	"frame": {"x":140,"y":293,"w":56,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":67},
	"sourceSize": {"w":56,"h":67}
},
"twist-speed-drafting.png":
{
	"frame": {"x":910,"y":3,"w":68,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":68,"h":67},
	"sourceSize": {"w":68,"h":67}
},
"twist-spicy-pepper.png":
{
	"frame": {"x":763,"y":1660,"w":53,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":53,"h":63},
	"sourceSize": {"w":53,"h":63}
},
"twist-split-personality.png":
{
	"frame": {"x":875,"y":1919,"w":54,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":7,"w":54,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-spooky-ghosts.png":
{
	"frame": {"x":480,"y":683,"w":58,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":62},
	"sourceSize": {"w":58,"h":62}
},
"twist-spring-time-dancing.png":
{
	"frame": {"x":139,"y":638,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-stop-drop-roll.png":
{
	"frame": {"x":276,"y":924,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-temporal-distortion.png":
{
	"frame": {"x":583,"y":1942,"w":52,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":52,"h":59},
	"sourceSize": {"w":52,"h":59}
},
"twist-the-brute-trio.png":
{
	"frame": {"x":344,"y":814,"w":66,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"twist-they-arent-stopping.png":
{
	"frame": {"x":200,"y":484,"w":71,"h":57},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":71,"h":57},
	"sourceSize": {"w":71,"h":57}
},
"twist-twice-dead.png":
{
	"frame": {"x":271,"y":1057,"w":64,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"twist-violence-rinse-repeat.png":
{
	"frame": {"x":970,"y":1544,"w":48,"h":56},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":48,"h":56},
	"sourceSize": {"w":48,"h":56}
},
"twist-violent-epiphany.png":
{
	"frame": {"x":76,"y":515,"w":59,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":59,"h":67},
	"sourceSize": {"w":59,"h":67}
},
"twist-whats-that-noise.png":
{
	"frame": {"x":139,"y":706,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-wild-rot-sons.png":
{
	"frame": {"x":234,"y":3,"w":70,"h":69},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":70,"h":69},
	"sourceSize": {"w":70,"h":69}
},
"twist-zoomies.png":
{
	"frame": {"x":335,"y":1987,"w":60,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":10,"w":60,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-2-way-split.png":
{
	"frame": {"x":392,"y":210,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-3-round-burst.png":
{
	"frame": {"x":340,"y":552,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-better-poison.png":
{
	"frame": {"x":690,"y":69,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-bigger-bolt.png":
{
	"frame": {"x":274,"y":613,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-horrible-night-for-a-curse.png":
{
	"frame": {"x":340,"y":620,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-lil-spice.png":
{
	"frame": {"x":393,"y":278,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-little-more-coating.png":
{
	"frame": {"x":134,"y":1016,"w":64,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":64,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-quick-reaction-to-a-good-burn.png":
{
	"frame": {"x":810,"y":680,"w":65,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":65,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-shockingly-good-time.png":
{
	"frame": {"x":141,"y":1856,"w":59,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":11,"w":59,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-very-personal-bottle-of-poison.png":
{
	"frame": {"x":588,"y":1203,"w":63,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-wind-named-doom.png":
{
	"frame": {"x":68,"y":833,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-absolute-units.png":
{
	"frame": {"x":393,"y":346,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-accelerated-technology.png":
{
	"frame": {"x":279,"y":797,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":5,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-acceleration.png":
{
	"frame": {"x":857,"y":1212,"w":59,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":59,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-acidic-purification.png":
{
	"frame": {"x":402,"y":414,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-acrobatics.png":
{
	"frame": {"x":880,"y":747,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aether-magnet.png":
{
	"frame": {"x":920,"y":1212,"w":58,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":58,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aggressive-posturing.png":
{
	"frame": {"x":760,"y":70,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aikido-darling.png":
{
	"frame": {"x":414,"y":814,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-all-about-that-size.png":
{
	"frame": {"x":639,"y":1712,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-amped-up.png":
{
	"frame": {"x":484,"y":814,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-an-acid-ent-waiting-to-happen.png":
{
	"frame": {"x":404,"y":550,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-angular-momentum.png":
{
	"frame": {"x":402,"y":482,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ankle-height-terror.png":
{
	"frame": {"x":202,"y":545,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-anything-but-basic.png":
{
	"frame": {"x":967,"y":210,"w":52,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":2,"w":52,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-arc-reactor.png":
{
	"frame": {"x":408,"y":618,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-arcane-rain.png":
{
	"frame": {"x":661,"y":1140,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aristotles-lack-of-motion.png":
{
	"frame": {"x":438,"y":71,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-assassins-arch-final-form.png":
{
	"frame": {"x":890,"y":1787,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-attack-of-the-killer-wasps.png":
{
	"frame": {"x":940,"y":680,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-balm-of-the-night-sky.png":
{
	"frame": {"x":3,"y":1811,"w":65,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":66},
	"sourceSize": {"w":65,"h":66}
},
"upgrade-banditry.png":
{
	"frame": {"x":267,"y":1759,"w":59,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":59,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-barbed-arrows.png":
{
	"frame": {"x":645,"y":1465,"w":62,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":9,"w":62,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bardcore.png":
{
	"frame": {"x":61,"y":1216,"w":66,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":6,"w":66,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-battery-quickcharge.png":
{
	"frame": {"x":919,"y":1277,"w":59,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":59,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bees-forever.png":
{
	"frame": {"x":439,"y":139,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-beestorm.png":
{
	"frame": {"x":60,"y":1278,"w":66,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-better-grips.png":
{
	"frame": {"x":933,"y":1919,"w":51,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":5,"w":51,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-better-living-through-poison.png":
{
	"frame": {"x":830,"y":70,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-big-stick.png":
{
	"frame": {"x":655,"y":1205,"w":63,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":6,"w":63,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bigger-beam.png":
{
	"frame": {"x":452,"y":207,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bigger-is-better.png":
{
	"frame": {"x":138,"y":892,"w":64,"h":52},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":8,"w":64,"h":52},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-biggest-beehive.png":
{
	"frame": {"x":512,"y":205,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-blazing-speed.png":
{
	"frame": {"x":457,"y":275,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-blood-soaked-bullets.png":
{
	"frame": {"x":885,"y":1853,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-body-builder.png":
{
	"frame": {"x":790,"y":1212,"w":63,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":7,"w":63,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-boiling-blood.png":
{
	"frame": {"x":457,"y":1519,"w":50,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":6,"w":50,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bone-conduction.png":
{
	"frame": {"x":3,"y":1671,"w":67,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":67,"h":66},
	"sourceSize": {"w":67,"h":66}
},
"upgrade-bonus-bullet.png":
{
	"frame": {"x":949,"y":1735,"w":50,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":6,"w":50,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-boom.png":
{
	"frame": {"x":469,"y":411,"w":60,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":64},
	"sourceSize": {"w":60,"h":64}
},
"upgrade-boomerang-final-form.png":
{
	"frame": {"x":139,"y":832,"w":66,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":8,"w":66,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-booming-steps.png":
{
	"frame": {"x":459,"y":343,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-boosted-lightning.png":
{
	"frame": {"x":469,"y":479,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bounce-a-rang.png":
{
	"frame": {"x":701,"y":1726,"w":58,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":58,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bounce-house.png":
{
	"frame": {"x":204,"y":613,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-brewers-choice.png":
{
	"frame": {"x":525,"y":273,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-burn-a-rang.png":
{
	"frame": {"x":701,"y":1660,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-burn-brighter.png":
{
	"frame": {"x":671,"y":748,"w":65,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":65,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-burn-burn-burn.png":
{
	"frame": {"x":119,"y":78,"w":43,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":43,"h":68},
	"sourceSize": {"w":43,"h":68}
},
"upgrade-careful shooter.png":
{
	"frame": {"x":279,"y":860,"w":61,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":61,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cast-a-cruel-net.png":
{
	"frame": {"x":527,"y":341,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-distance.png":
{
	"frame": {"x":533,"y":409,"w":50,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":50,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-impact.png":
{
	"frame": {"x":537,"y":477,"w":55,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":0,"w":55,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-infinitum.png":
{
	"frame": {"x":537,"y":545,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-recursion.png":
{
	"frame": {"x":470,"y":547,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chaining-attacks.png":
{
	"frame": {"x":573,"y":69,"w":47,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":47,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-charged-up.png":
{
	"frame": {"x":474,"y":615,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-bite-force.png":
{
	"frame": {"x":209,"y":834,"w":66,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-cant-keep-a-good-dog-down.png":
{
	"frame": {"x":760,"y":137,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-getting-rough.png":
{
	"frame": {"x":948,"y":747,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-relentless.png":
{
	"frame": {"x":821,"y":1015,"w":64,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":64,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-vicious.png":
{
	"frame": {"x":615,"y":815,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chonkers.png":
{
	"frame": {"x":542,"y":613,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cloudburst.png":
{
	"frame": {"x":140,"y":364,"w":56,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":67},
	"sourceSize": {"w":56,"h":67}
},
"upgrade-coil-tower.png":
{
	"frame": {"x":830,"y":137,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-comet-blast.png":
{
	"frame": {"x":3,"y":779,"w":61,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":71},
	"sourceSize": {"w":61,"h":71}
},
"upgrade-conflagration.png":
{
	"frame": {"x":542,"y":681,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-continuing-chains.png":
{
	"frame": {"x":573,"y":137,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cornucopia.png":
{
	"frame": {"x":849,"y":1341,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-corrosive-wasps.png":
{
	"frame": {"x":756,"y":1014,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-crowd-hopper.png":
{
	"frame": {"x":581,"y":205,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cupids-recycling.png":
{
	"frame": {"x":64,"y":1016,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-damage-damage-resolution.png":
{
	"frame": {"x":900,"y":137,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-damage-vault.png":
{
	"frame": {"x":639,"y":1907,"w":55,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":4,"w":55,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-damaging-mark.png":
{
	"frame": {"x":587,"y":273,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dance-forever-with-the-fairies.png":
{
	"frame": {"x":3,"y":1384,"w":62,"h":69},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":69},
	"sourceSize": {"w":62,"h":69}
},
"upgrade-death-on-rails.png":
{
	"frame": {"x":587,"y":341,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-death-storm.png":
{
	"frame": {"x":641,"y":1581,"w":60,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":60,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-deliberate-damage.png":
{
	"frame": {"x":740,"y":680,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-destructive-rollout.png":
{
	"frame": {"x":717,"y":1335,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-discharge-capacitor.png":
{
	"frame": {"x":596,"y":477,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-divine-punishment.png":
{
	"frame": {"x":209,"y":681,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-do-those-rats-have-knives.png":
{
	"frame": {"x":72,"y":1820,"w":65,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":65,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dog-of-all-trades.png":
{
	"frame": {"x":646,"y":204,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-doom-mark.png":
{
	"frame": {"x":874,"y":544,"w":48,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":48,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-double-flurry.png":
{
	"frame": {"x":645,"y":1518,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-double-strike.png":
{
	"frame": {"x":705,"y":1596,"w":60,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":60},
	"sourceSize": {"w":60,"h":60}
},
"upgrade-dripping-with-venom.png":
{
	"frame": {"x":701,"y":1787,"w":59,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":59,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dry-heat.png":
{
	"frame": {"x":587,"y":409,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-effective-mark.png":
{
	"frame": {"x":599,"y":545,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-electrical-discharge.png":
{
	"frame": {"x":3,"y":703,"w":62,"h":72},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":72},
	"sourceSize": {"w":62,"h":72}
},
"upgrade-elemental-consistency.png":
{
	"frame": {"x":624,"y":68,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ember-charm.png":
{
	"frame": {"x":368,"y":71,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-eminence-of-equinox.png":
{
	"frame": {"x":156,"y":3,"w":74,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":74,"h":68},
	"sourceSize": {"w":74,"h":68}
},
"upgrade-essence-loaded.png":
{
	"frame": {"x":740,"y":747,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-even-more-bullets.png":
{
	"frame": {"x":949,"y":1675,"w":50,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":7,"w":50,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-everyone-gets-bees.png":
{
	"frame": {"x":810,"y":747,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-assassins-arch.png":
{
	"frame": {"x":394,"y":1519,"w":59,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":59,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-gilded-greatbow.png":
{
	"frame": {"x":605,"y":613,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-holy-spear.png":
{
	"frame": {"x":608,"y":681,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-longbow.png":
{
	"frame": {"x":656,"y":476,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-naginata.png":
{
	"frame": {"x":710,"y":1533,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-ricochet-rang.png":
{
	"frame": {"x":647,"y":272,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-stormbreaker.png":
{
	"frame": {"x":874,"y":612,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-expert-marksmanship.png":
{
	"frame": {"x":73,"y":1451,"w":53,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":0,"w":53,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-expertise-in-action.png":
{
	"frame": {"x":392,"y":1784,"w":58,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":63},
	"sourceSize": {"w":58,"h":63}
},
"upgrade-explosive-rush.png":
{
	"frame": {"x":315,"y":1388,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-extra-deep-vials.png":
{
	"frame": {"x":982,"y":3,"w":25,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":21,"y":0,"w":25,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-extra-steps.png":
{
	"frame": {"x":763,"y":1850,"w":57,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":58},
	"sourceSize": {"w":57,"h":58}
},
"upgrade-extreme-force.png":
{
	"frame": {"x":394,"y":1586,"w":59,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":2,"w":59,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fairy-fireworks.png":
{
	"frame": {"x":3,"y":3,"w":72,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":74},
	"sourceSize": {"w":72,"h":74}
},
"upgrade-fairy-flame-on.png":
{
	"frame": {"x":3,"y":929,"w":60,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":71},
	"sourceSize": {"w":60,"h":71}
},
"upgrade-fairy-flames-forever.png":
{
	"frame": {"x":75,"y":374,"w":61,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":67},
	"sourceSize": {"w":61,"h":67}
},
"upgrade-fast-acting-poison.png":
{
	"frame": {"x":833,"y":1605,"w":60,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":4,"w":60,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fast-cuts.png":
{
	"frame": {"x":916,"y":1153,"w":61,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":9,"w":61,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fast-learner.png":
{
	"frame": {"x":769,"y":1596,"w":60,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":4,"w":60,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fast-rolls.png":
{
	"frame": {"x":344,"y":876,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-faster-attacks.png":
{
	"frame": {"x":936,"y":612,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-faster-shocks.png":
{
	"frame": {"x":71,"y":1884,"w":65,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":65,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fat-rat.png":
{
	"frame": {"x":503,"y":69,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fatal-speed.png":
{
	"frame": {"x":648,"y":340,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fearsome-roar.png":
{
	"frame": {"x":202,"y":1025,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-final-form-chakram.png":
{
	"frame": {"x":859,"y":476,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fire-storm.png":
{
	"frame": {"x":69,"y":1383,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-firebird-flock.png":
{
	"frame": {"x":503,"y":137,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-firepower-overload.png":
{
	"frame": {"x":648,"y":408,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-flame-bright.png":
{
	"frame": {"x":856,"y":340,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-flame-fast.png":
{
	"frame": {"x":74,"y":1519,"w":53,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":53,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-flames-burn-all-night.png":
{
	"frame": {"x":3,"y":1235,"w":53,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":53,"h":71},
	"sourceSize": {"w":53,"h":71}
},
"upgrade-flash-of-brilliance.png":
{
	"frame": {"x":308,"y":3,"w":75,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":75,"h":62},
	"sourceSize": {"w":75,"h":62}
},
"upgrade-fleet-flooted.png":
{
	"frame": {"x":722,"y":1212,"w":64,"h":51},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":8,"w":64,"h":51},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-focal-prism.png":
{
	"frame": {"x":178,"y":1090,"w":64,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":11,"w":64,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-for-doom-the-bell-tolls.png":
{
	"frame": {"x":924,"y":276,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fortississimo.png":
{
	"frame": {"x":775,"y":1533,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":4,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-frenetic-activity.png":
{
	"frame": {"x":74,"y":1587,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-full-auto.png":
{
	"frame": {"x":840,"y":1538,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gatling-sorceries.png":
{
	"frame": {"x":717,"y":408,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-getting-into-rat-trouble.png":
{
	"frame": {"x":484,"y":879,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gloom-and-doom.png":
{
	"frame": {"x":414,"y":877,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gotta-catch-em-all.png":
{
	"frame": {"x":335,"y":1920,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gotta-make-em-bleed.png":
{
	"frame": {"x":756,"y":1081,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-grand-finale.png":
{
	"frame": {"x":130,"y":1080,"w":44,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":0,"w":44,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-grand-pike-final-form.png":
{
	"frame": {"x":743,"y":814,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-grand-tower.png":
{
	"frame": {"x":856,"y":408,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-great-tone.png":
{
	"frame": {"x":381,"y":1390,"w":62,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":7,"w":62,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-greater-skyfire.png":
{
	"frame": {"x":3,"y":550,"w":69,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":69,"h":71},
	"sourceSize": {"w":69,"h":71}
},
"upgrade-growth-hormone.png":
{
	"frame": {"x":640,"y":136,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hard-hitting-rangs.png":
{
	"frame": {"x":737,"y":612,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hard-knocks.png":
{
	"frame": {"x":926,"y":544,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-have-heart.png":
{
	"frame": {"x":74,"y":1655,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-heavy-hitting.png":
{
	"frame": {"x":895,"y":946,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-heist.png":
{
	"frame": {"x":619,"y":1075,"w":63,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":63,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hemorrhagic-arrows.png":
{
	"frame": {"x":130,"y":1148,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-accuracy-shots.png":
{
	"frame": {"x":639,"y":1778,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-capacity-battery.png":
{
	"frame": {"x":329,"y":1651,"w":60,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":60,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-density-energy.png":
{
	"frame": {"x":965,"y":881,"w":54,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":54,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-efficacy-lasers.png":
{
	"frame": {"x":396,"y":1851,"w":56,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":1,"w":56,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-precision-torch.png":
{
	"frame": {"x":805,"y":612,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-priority-targets.png":
{
	"frame": {"x":783,"y":1336,"w":62,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":62,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-speed-plunderer.png":
{
	"frame": {"x":897,"y":1608,"w":60,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":6,"w":60,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-tide-blessing.png":
{
	"frame": {"x":447,"y":1390,"w":62,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":7,"w":62,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-holy-light.png":
{
	"frame": {"x":206,"y":894,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-holy-spear-final-form.png":
{
	"frame": {"x":615,"y":881,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hungry-hungry-doggo.png":
{
	"frame": {"x":763,"y":1727,"w":57,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":4,"w":57,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hustle-up.png":
{
	"frame": {"x":511,"y":1586,"w":56,"h":52},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":9,"w":56,"h":52},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-i-like-em-when-they-stopmoving.png":
{
	"frame": {"x":698,"y":1913,"w":55,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":4,"w":55,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-impatient.png":
{
	"frame": {"x":895,"y":881,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-improv.png":
{
	"frame": {"x":136,"y":948,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-in-the-eye-of-mercy.png":
{
	"frame": {"x":714,"y":204,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-index-warning.png":
{
	"frame": {"x":141,"y":1978,"w":64,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":64,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-intimidating-aura.png":
{
	"frame": {"x":554,"y":814,"w":57,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":2,"w":57,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-its-a-trap.png":
{
	"frame": {"x":73,"y":1951,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-its-raining-traps-hallelujah.png":
{
	"frame": {"x":525,"y":1967,"w":54,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":2,"w":54,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-just-walk-it-off.png":
{
	"frame": {"x":685,"y":947,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-kill-trigger.png":
{
	"frame": {"x":140,"y":435,"w":56,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":66},
	"sourceSize": {"w":56,"h":66}
},
"upgrade-laser-light-show.png":
{
	"frame": {"x":344,"y":942,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lasting-mark.png":
{
	"frame": {"x":922,"y":1982,"w":53,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":5,"w":53,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-latent-destruction.png":
{
	"frame": {"x":138,"y":1584,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-leading-the-fairy-dance.png":
{
	"frame": {"x":72,"y":158,"w":57,"h":69},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":69},
	"sourceSize": {"w":57,"h":69}
},
"upgrade-learning-practical-skills.png":
{
	"frame": {"x":883,"y":1668,"w":60,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":8,"w":60,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-legato.png":
{
	"frame": {"x":127,"y":1339,"w":66,"h":37},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":10,"w":66,"h":37},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-let-the-arrows-hit-the-floor.png":
{
	"frame": {"x":957,"y":1017,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lethal-sniper.png":
{
	"frame": {"x":260,"y":1188,"w":52,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":1,"w":52,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-light-it-up.png":
{
	"frame": {"x":554,"y":880,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-light-the-night-again.png":
{
	"frame": {"x":75,"y":445,"w":61,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":66},
	"sourceSize": {"w":61,"h":66}
},
"upgrade-lightning-always-strikes-twice.png":
{
	"frame": {"x":397,"y":1918,"w":57,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lightning-fast.png":
{
	"frame": {"x":139,"y":1652,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lightning-prowess.png":
{
	"frame": {"x":970,"y":74,"w":49,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":49,"h":64},
	"sourceSize": {"w":49,"h":64}
},
"upgrade-lightning-rod.png":
{
	"frame": {"x":3,"y":241,"w":66,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":74},
	"sourceSize": {"w":66,"h":74}
},
"upgrade-lightning-tempest.png":
{
	"frame": {"x":905,"y":1542,"w":61,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":62},
	"sourceSize": {"w":61,"h":62}
},
"upgrade-linked-chains.png":
{
	"frame": {"x":190,"y":1147,"w":55,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":55,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-living-bombs.png":
{
	"frame": {"x":414,"y":942,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-long-distance-power.png":
{
	"frame": {"x":339,"y":1005,"w":66,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-longer-chains.png":
{
	"frame": {"x":262,"y":1319,"w":49,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":3,"w":49,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-longer-forearm.png":
{
	"frame": {"x":409,"y":1006,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-longwave-radio.png":
{
	"frame": {"x":271,"y":989,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lumbering-burst.png":
{
	"frame": {"x":139,"y":568,"w":59,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":59,"h":66},
	"sourceSize": {"w":59,"h":66}
},
"upgrade-lunar-lady.png":
{
	"frame": {"x":484,"y":943,"w":66,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":59},
	"sourceSize": {"w":66,"h":59}
},
"upgrade-lye-ing-it-on-thick.png":
{
	"frame": {"x":399,"y":1985,"w":59,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":8,"w":59,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-make-a-thump.png":
{
	"frame": {"x":513,"y":1393,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-making-it-rain-arrows.png":
{
	"frame": {"x":891,"y":1075,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-mana-sphere.png":
{
	"frame": {"x":925,"y":408,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-mark-aim-fire.png":
{
	"frame": {"x":714,"y":272,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-markswitch-final-form.png":
{
	"frame": {"x":197,"y":1215,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-martial-tactics.png":
{
	"frame": {"x":784,"y":204,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-massive-attack.png":
{
	"frame": {"x":579,"y":1764,"w":56,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":7,"w":56,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-maximum-amperage.png":
{
	"frame": {"x":797,"y":1146,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":2,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-meteoric-pace.png":
{
	"frame": {"x":729,"y":1146,"w":64,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":64,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-minor-travesty.png":
{
	"frame": {"x":479,"y":1006,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-MOAR-BEES.png":
{
	"frame": {"x":784,"y":272,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-MORE-bees.png":
{
	"frame": {"x":339,"y":1067,"w":66,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":11,"w":66,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-more-mass-more-oomph.png":
{
	"frame": {"x":820,"y":1667,"w":59,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":9,"w":59,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-multi-facet-burst.png":
{
	"frame": {"x":197,"y":1283,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-multi-point-show.png":
{
	"frame": {"x":316,"y":1254,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-multi-strike.png":
{
	"frame": {"x":337,"y":348,"w":52,"h":65},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":52,"h":65},
	"sourceSize": {"w":52,"h":65}
},
"upgrade-naginata-final-form.png":
{
	"frame": {"x":383,"y":1256,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-naginata-mastery.png":
{
	"frame": {"x":957,"y":1085,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-not-just-a-one-trick-pony.png":
{
	"frame": {"x":577,"y":1460,"w":64,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":9,"w":64,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-noxious-leak.png":
{
	"frame": {"x":764,"y":1787,"w":58,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":5,"w":58,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-on-a-wave-of-mutilation.png":
{
	"frame": {"x":384,"y":1194,"w":64,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":64,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-one-arrow-intwain.png":
{
	"frame": {"x":457,"y":1581,"w":50,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":5,"w":50,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-one-arrow-to-rule-them-all.png":
{
	"frame": {"x":203,"y":1555,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-only-a-spark.png":
{
	"frame": {"x":974,"y":1409,"w":45,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":12,"y":0,"w":45,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-orbital-lasers.png":
{
	"frame": {"x":716,"y":340,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-out-and-back.png":
{
	"frame": {"x":579,"y":1393,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-out-for-blood.png":
{
	"frame": {"x":385,"y":1131,"w":65,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":65,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-overdrive.png":
{
	"frame": {"x":130,"y":1448,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pain-fountain.png":
{
	"frame": {"x":645,"y":1399,"w":62,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":2,"w":62,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pet-caller.png":
{
	"frame": {"x":949,"y":1793,"w":53,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":10,"w":53,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pet-fiesta.png":
{
	"frame": {"x":393,"y":1652,"w":60,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":60,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-petnip.png":
{
	"frame": {"x":59,"y":1336,"w":64,"h":43},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":15,"w":64,"h":43},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ph-0-acid.png":
{
	"frame": {"x":197,"y":1351,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-phoenix-flock.png":
{
	"frame": {"x":854,"y":204,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-phoenix-multiplier.png":
{
	"frame": {"x":204,"y":1623,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-phoenixpalooza.png":
{
	"frame": {"x":854,"y":272,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-photon.png":
{
	"frame": {"x":639,"y":1971,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pickup-sweep.png":
{
	"frame": {"x":208,"y":1887,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pierce.png":
{
	"frame": {"x":814,"y":1982,"w":46,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":4,"w":46,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-piercing-force.png":
{
	"frame": {"x":262,"y":1448,"w":48,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":2,"w":48,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pike-master.png":
{
	"frame": {"x":786,"y":340,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pike-shockwave.png":
{
	"frame": {"x":685,"y":881,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-plasma-overload.png":
{
	"frame": {"x":576,"y":1630,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-platos-ideal.png":
{
	"frame": {"x":786,"y":408,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-poison-coated-weapons.png":
{
	"frame": {"x":523,"y":1139,"w":65,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":6,"w":65,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-poison-magic.png":
{
	"frame": {"x":710,"y":136,"w":46,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":10,"y":0,"w":46,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-powerful-magic.png":
{
	"frame": {"x":824,"y":1850,"w":57,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":3,"w":57,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-powerful-toxin.png":
{
	"frame": {"x":450,"y":1261,"w":63,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":63,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-praying-mantis-style.png":
{
	"frame": {"x":517,"y":1263,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pretty-overpowered-laser.png":
{
	"frame": {"x":141,"y":1720,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-primary-specialist.png":
{
	"frame": {"x":462,"y":1985,"w":59,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":5,"w":59,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-prog-rock.png":
{
	"frame": {"x":719,"y":476,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pulverizing-arcana.png":
{
	"frame": {"x":197,"y":1419,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pythagoras-theorum-of-explosions.png":
{
	"frame": {"x":826,"y":1787,"w":60,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":2,"w":60,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quantum-ammo-chamber.png":
{
	"frame": {"x":813,"y":814,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-coil-placement.png":
{
	"frame": {"x":270,"y":1890,"w":61,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":3,"w":61,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-firing.png":
{
	"frame": {"x":511,"y":1521,"w":61,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":61,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-fuse.png":
{
	"frame": {"x":141,"y":1788,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-temper.png":
{
	"frame": {"x":316,"y":1189,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quickchange-artist.png":
{
	"frame": {"x":204,"y":1754,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quicktomancy.png":
{
	"frame": {"x":753,"y":947,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quietly-into-the-night.png":
{
	"frame": {"x":387,"y":3,"w":73,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":73,"h":64},
	"sourceSize": {"w":73,"h":64}
},
"upgrade-radiation-blast.png":
{
	"frame": {"x":711,"y":1400,"w":62,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":62,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ramping-bpm.png":
{
	"frame": {"x":698,"y":1977,"w":55,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":4,"w":55,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ranger-big-game-hunter.png":
{
	"frame": {"x":329,"y":1519,"w":61,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":2,"w":61,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-deployment.png":
{
	"frame": {"x":821,"y":947,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-engagement.png":
{
	"frame": {"x":965,"y":949,"w":54,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":54,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-fire-final-form.png":
{
	"frame": {"x":883,"y":814,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-killer.png":
{
	"frame": {"x":577,"y":1514,"w":64,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":8,"w":64,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-rebirth.png":
{
	"frame": {"x":131,"y":1516,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-recon.png":
{
	"frame": {"x":584,"y":1266,"w":63,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":63,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-stingers.png":
{
	"frame": {"x":197,"y":1487,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rats-are-making-all-the-rules.png":
{
	"frame": {"x":975,"y":1477,"w":43,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":13,"y":1,"w":43,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-razorang.png":
{
	"frame": {"x":270,"y":1822,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-regeneration.png":
{
	"frame": {"x":266,"y":1568,"w":59,"h":51},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":6,"w":59,"h":51},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-resilient-fire.png":
{
	"frame": {"x":133,"y":150,"w":57,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":68},
	"sourceSize": {"w":57,"h":68}
},
"upgrade-ricochet-rang-final-form.png":
{
	"frame": {"x":141,"y":1910,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ricochet.png":
{
	"frame": {"x":579,"y":1697,"w":56,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":56,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-right-in-the-soft-bits.png":
{
	"frame": {"x":209,"y":1955,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-righteous-fervor.png":
{
	"frame": {"x":777,"y":1402,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-roost-boost.png":
{
	"frame": {"x":889,"y":1007,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-secondary-specialist.png":
{
	"frame": {"x":576,"y":1568,"w":61,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":5,"w":61,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-shocked-to-death.png":
{
	"frame": {"x":925,"y":476,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-shocking-percussion.png":
{
	"frame": {"x":262,"y":1384,"w":49,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":49,"h":60},
	"sourceSize": {"w":49,"h":60}
},
"upgrade-skillful-collection.png":
{
	"frame": {"x":843,"y":1404,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-skillstrike-gadget.png":
{
	"frame": {"x":616,"y":947,"w":65,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":63},
	"sourceSize": {"w":65,"h":63}
},
"upgrade-sky-splitter-arrows.png":
{
	"frame": {"x":314,"y":1451,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-skyfire-fairy.png":
{
	"frame": {"x":3,"y":162,"w":65,"h":75},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":75},
	"sourceSize": {"w":65,"h":75}
},
"upgrade-slice-thru.png":
{
	"frame": {"x":757,"y":1913,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-slow-and-steady.png":
{
	"frame": {"x":446,"y":1451,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-soaking-it-in.png":
{
	"frame": {"x":316,"y":1124,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-socrates-revenge.png":
{
	"frame": {"x":249,"y":1120,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-solar-supremacy.png":
{
	"frame": {"x":3,"y":1741,"w":66,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":66},
	"sourceSize": {"w":66,"h":66}
},
"upgrade-some-more-bees.png":
{
	"frame": {"x":525,"y":1910,"w":48,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":11,"w":48,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-something-that-bigs-gotta-hurt.png":
{
	"frame": {"x":380,"y":1451,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-song-of-haste.png":
{
	"frame": {"x":511,"y":1458,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-spear-mastery.png":
{
	"frame": {"x":262,"y":1253,"w":50,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":2,"w":50,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-speed-boomerang.png":
{
	"frame": {"x":651,"y":1267,"w":63,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":63,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-speed-of-light.png":
{
	"frame": {"x":616,"y":1014,"w":66,"h":57},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":57},
	"sourceSize": {"w":66,"h":57}
},
"upgrade-stalwart-soldier-style.png":
{
	"frame": {"x":515,"y":1778,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-starburst.png":
{
	"frame": {"x":711,"y":1466,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-starry-malevolence.png":
{
	"frame": {"x":785,"y":1273,"w":63,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":59},
	"sourceSize": {"w":63,"h":59}
},
"upgrade-steal-and-heal.png":
{
	"frame": {"x":449,"y":1328,"w":63,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stormbreaker-final-form.png":
{
	"frame": {"x":686,"y":1014,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-straight-shooter.png":
{
	"frame": {"x":409,"y":1067,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-strength-of-the-storm.png":
{
	"frame": {"x":457,"y":1642,"w":54,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":54,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-strike-after-strike.png":
{
	"frame": {"x":454,"y":1784,"w":57,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":63},
	"sourceSize": {"w":57,"h":63}
},
"upgrade-strolling-minstrel.png":
{
	"frame": {"x":517,"y":1844,"w":56,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":56,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stronger-bees.png":
{
	"frame": {"x":479,"y":1070,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stunning-effectiveness.png":
{
	"frame": {"x":267,"y":1623,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stunning-strikes.png":
{
	"frame": {"x":961,"y":1608,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-summers-rage.png":
{
	"frame": {"x":3,"y":1457,"w":66,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":68},
	"sourceSize": {"w":66,"h":68}
},
"upgrade-sweep-aside.png":
{
	"frame": {"x":454,"y":1133,"w":65,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":65,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tactical-strike-final-form.png":
{
	"frame": {"x":67,"y":894,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-taking-names.png":
{
	"frame": {"x":718,"y":1267,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-TBD-pets.png":
{
	"frame": {"x":266,"y":1519,"w":57,"h":45},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":11,"w":57,"h":45},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tear-em-up.png":
{
	"frame": {"x":452,"y":1197,"w":64,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tempered-blades.png":
{
	"frame": {"x":864,"y":1980,"w":54,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":4,"w":54,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tempo-change.png":
{
	"frame": {"x":592,"y":1140,"w":65,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":65,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-terrified-approach.png":
{
	"frame": {"x":755,"y":881,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-best-of-living-through-acid.png":
{
	"frame": {"x":456,"y":1851,"w":57,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-crowd-controller.png":
{
	"frame": {"x":61,"y":1080,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-entire-symphony.png":
{
	"frame": {"x":330,"y":1717,"w":59,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":59,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-heart-of-an-assassin.png":
{
	"frame": {"x":852,"y":1273,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-size-on-that-thing.png":
{
	"frame": {"x":458,"y":1918,"w":51,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":51,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-stuff-that-really-lasts.png":
{
	"frame": {"x":583,"y":1333,"w":63,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-wise-one.png":
{
	"frame": {"x":777,"y":1470,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-they-stalk-at-night.png":
{
	"frame": {"x":664,"y":544,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thornapalooza.png":
{
	"frame": {"x":789,"y":476,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-aggressive-defense.png":
{
	"frame": {"x":267,"y":1691,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-natural-defense.png":
{
	"frame": {"x":825,"y":881,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-panic-option.png":
{
	"frame": {"x":549,"y":1075,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-spiney.png":
{
	"frame": {"x":639,"y":1645,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-thorn-dog.png":
{
	"frame": {"x":734,"y":544,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorny.png":
{
	"frame": {"x":515,"y":1642,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-three-fairies-are-better-than-one.png":
{
	"frame": {"x":540,"y":3,"w":71,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":71,"h":62},
	"sourceSize": {"w":71,"h":62}
},
"upgrade-thunderous-blows.png":
{
	"frame": {"x":577,"y":1817,"w":58,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":60},
	"sourceSize": {"w":58,"h":60}
},
"upgrade-thunderous-squall.png":
{
	"frame": {"x":3,"y":1529,"w":67,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":67,"h":68},
	"sourceSize": {"w":67,"h":68}
},
"upgrade-tighten-spread.png":
{
	"frame": {"x":639,"y":1844,"w":58,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":5,"w":58,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tough-skin.png":
{
	"frame": {"x":61,"y":1148,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-truly-shocking.png":
{
	"frame": {"x":816,"y":1912,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-twos-company.png":
{
	"frame": {"x":757,"y":1979,"w":53,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":3,"w":53,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ultra-rapid-fire.png":
{
	"frame": {"x":329,"y":1585,"w":61,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-bees.png":
{
	"frame": {"x":667,"y":612,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-dark-stormy-night.png":
{
	"frame": {"x":393,"y":1718,"w":60,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":62},
	"sourceSize": {"w":60,"h":62}
},
"upgrade-unlock-fire-fairy.png":
{
	"frame": {"x":464,"y":3,"w":72,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":62},
	"sourceSize": {"w":72,"h":62}
},
"upgrade-unlock-lute.png":
{
	"frame": {"x":843,"y":1471,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-nikolas-scope.png":
{
	"frame": {"x":315,"y":1320,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-phoenix-bombs.png":
{
	"frame": {"x":202,"y":957,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-poison-bottles.png":
{
	"frame": {"x":457,"y":1710,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-rat-parade.png":
{
	"frame": {"x":330,"y":1784,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-tesla-coil.png":
{
	"frame": {"x":272,"y":1955,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-up-tempo-the-fairy-beat.png":
{
	"frame": {"x":886,"y":1722,"w":59,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":59,"h":61},
	"sourceSize": {"w":59,"h":61}
},
"upgrade-venomous.png":
{
	"frame": {"x":944,"y":1853,"w":54,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":4,"w":54,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-visceral-magic.png":
{
	"frame": {"x":970,"y":142,"w":49,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":0,"w":49,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wasp-outbreak.png":
{
	"frame": {"x":382,"y":1322,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-we-are-the-rats.png":
{
	"frame": {"x":129,"y":1380,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-weight-training.png":
{
	"frame": {"x":686,"y":1079,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":7,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-when-is-the-next-gig.png":
{
	"frame": {"x":915,"y":1342,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-where-your-nightmares-end.png":
{
	"frame": {"x":670,"y":680,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-whirling-dervish-style.png":
{
	"frame": {"x":516,"y":1329,"w":63,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":63,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-whirlwind-blade.png":
{
	"frame": {"x":650,"y":1334,"w":63,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":63,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-whirlwind.png":
{
	"frame": {"x":519,"y":1710,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wide-spray.png":
{
	"frame": {"x":701,"y":1850,"w":58,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":58,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wider-authority.png":
{
	"frame": {"x":909,"y":1477,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-widespread-calamity.png":
{
	"frame": {"x":953,"y":814,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wild-fire.png":
{
	"frame": {"x":335,"y":1852,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wisdom-of-the-goddess.png":
{
	"frame": {"x":3,"y":473,"w":68,"h":73},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":68,"h":73},
	"sourceSize": {"w":68,"h":73}
},
"upgrade-you-call-that-a-critical.png":
{
	"frame": {"x":824,"y":1726,"w":58,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":58,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-you-call-that-a-hit.png":
{
	"frame": {"x":577,"y":1881,"w":58,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":58,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-you-should-be-dancing.png":
{
	"frame": {"x":909,"y":1409,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrades-slicing-and-dicing.png":
{
	"frame": {"x":520,"y":1198,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrde-shock-infused.png":
{
	"frame": {"x":804,"y":544,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.1",
	"image": "in-game-icons.png",
	"format": "RGBA8888",
	"size": {"w":1022,"h":2043},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:3aa58191b1c0f138d64daf2851f30f14:247ae69e23e0f2236e8a0fedc118f01c:4b2fac010a65861d4d8d6e0634c4bf05$"
}
}
