import { PrimaryWeapon } from "./primary-weapon"

export type BonusesPerMaxCharge = {
    splitting: number
    pierce: number
    projectiles: number
    damagePercent: number
}

export abstract class ChargePrimaryWeapon extends PrimaryWeapon {
    bonusesPerMaxCharge: BonusesPerMaxCharge = {
        splitting: 0,
        pierce: 0,
        projectiles: 0,
        damagePercent: 0
    }
}