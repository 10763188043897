<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div class="controller-selector-container container-formatting">
				<div class="label" :lang="this.$i18n.locale">{{ $t('settings.category_controllers') }}</div>
				<div class="controller-selector">
					<Pagination :disablePips="true" :title-string="selectCategoryString"
						@on-click-back-fn="previousCategory" @on-click-forward-fn="nextCategory"></Pagination>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Toggle from '../form-inputs/toggle.vue'
import Pagination from '../pagination/pagination.vue'

import { paginationMixins } from '../mixins/Pagination-mixin'

export default {
	name: 'ControllerSelectorModule',
	components: {
		EmbellishmentContainer,
		Pagination,
	},
	data() {
		return {
		}
	},
	props: {},

	computed: {
		// ...mapGetters('ui', ),
		...mapGetters('settings', ['getInputDevices', 'getSelectedInputDeviceId']),

		categories() {
			return this.getInputDevices
		},

		defaultSelectedCategory: {
			get() {
				return this.getSelectedInputDeviceId
			},
			set(value) {
				this.updateSelectedController(value)
			},
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('settings', ['checkFullscreen', 'toggleWindowMode', 'updateSelectedController']),
	},
	mixins: [paginationMixins],
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.controller-selector-container {
	display: flex;
	align-items: center;
	width: 660px;

	.label {
		color: @offWhite;
		font-size: 30px;
		width: 258px;
		padding-left: 50px;

		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}

	.controller-selector {
		display: flex;
		width: 60%;
		height: 40px;
	}
}
</style>
