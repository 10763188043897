import { InGameTime } from "../utils/time"

export enum PauseState {
	Running = 0,
	/** Pauses all game logic, physics engine, timers, most update loops. This is usually set when the user invokes pause. */
	LogicPaused = 1 << 0,
	/** Pauses all visual logic, animations, lerps, PFX, etc. This is usually NOT set when the user invokes pause, so that menu animations, accents, and other polish can continue running. */
	// VisualPaused = 1 << 1, // disabled because it's probably overkill for now, and most polish will be in HTML/CSS
}

export type PauseSource = 'focus' | 'upgrade' | 'end-game' | 'user' | 'map'

export class PauseManager {
	private static _pauseState: PauseState = PauseState.Running
	static get pauseState(): PauseState {
		return PauseManager._pauseState
	}

	private static _pauseSource: Record<PauseSource, boolean> = {
		focus: false,
		upgrade: false,
		'end-game': false,
		user: false,
		map: false,
	}

	static get pauseSource(): Record<PauseSource, boolean> {
		return PauseManager._pauseSource
	}

	static pauseGame(pauseSource: PauseSource, pauseState: PauseState = PauseState.LogicPaused) {
		console.log(`Paused by: ${pauseSource}`)
		this._pauseSource[pauseSource] = true
		if (this._pauseState != PauseState.LogicPaused) {
			// switch controller to menu mode
			if (process.env.IS_ELECTRON) {
				if (pauseSource === 'upgrade') {
					window.bridge.setControllerMode('UpgradeControls')
				} else {
					window.bridge.setControllerMode('MenuControls')
				}
			}
			InGameTime.pause()
			PauseManager._pauseState |= pauseState
		}
	}

	static unpauseGame(unpauseSource: PauseSource, pauseState?: PauseState) {
		console.log(`Unpaused by: ${unpauseSource}`)
		this._pauseSource[unpauseSource] = false

		if (this.allSourcesUnpaused()) {
			// I'm tired of losing my charge from upgrade pauses!!
			setTimeout(() => {
				if (!this.allSourcesUnpaused()) {
					return
				}

				if (pauseState) {
					PauseManager._pauseState &= ~pauseState
				} else {
					PauseManager._pauseState = PauseState.Running
				}
				// set controller to in game mode
				if (process.env.IS_ELECTRON) {
					window.bridge.setControllerMode('InGameControls')
				}
				InGameTime.unpause()
			}, 400)
		}
	}

	static isPaused(pauseState?: PauseState) {
		if (pauseState) {
			// console.log(`${pauseState} === (${PauseManager._pauseState} & ${pauseState}) => ${(PauseManager._pauseState & pauseState)} ==> ${pauseState === (PauseManager._pauseState & pauseState)}`)
			return pauseState === (PauseManager._pauseState & pauseState)
		}
		// console.log(`${PauseManager._pauseState} !== ${PauseState.Running} ==> ${PauseManager._pauseState !== PauseState.Running}`)
		return PauseManager._pauseState !== PauseState.Running
	}

	static allSourcesUnpaused() {
		for (const key in this._pauseSource) {
			if (this._pauseSource[key]) {
				return false
			}
		}

		return true
	}
}
