import axios from 'axios'
import { SearchOptions } from '../../ui/store/story-store'
import { UI } from '../../ui/ui'
import { nonSensitiveEnvVarsMapping } from '../env'

function getAuthToken(): string {
	return UI.getInstance().store.getters['user/authToken']
}

export async function registerRequest(registerBody) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/register`, registerBody)
	return apiResults.data
}

export async function createAnonymousUserRequest() {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/register`, {
		sourceGame: 'hotcakes',
		isAnonymous: true,
		agreedToTerms: true
	})
	return apiResults.data
}

export async function anonymousUserDetailsRequest(anonymousUserId) {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/anonymous-user?includePreferences=true&includeAuthToken=true&includeTutorialFlags=true`, {
		headers: {
			'anonymous-user-id': anonymousUserId,
		},
	})
	return apiResults.data
}

export async function meRequest(authToken) {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/me?includePreferences=true&includeAuthToken=true&includeTutorialFlags=true`,
		{
			headers: { 'auth-token': authToken },
		},
	)
	return apiResults.data
}

export async function meCurrencyRequest() {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/me/currency`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function checkEmailIsTaken(emailAddress) {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/email-address-taken?emailAddress=${encodeURIComponent(emailAddress)}`)

	return apiResults.data
}
export async function checkUsernameIsTaken(username) {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/username-taken?username=${encodeURIComponent(username)}`)

	return apiResults.data
}

export async function loginRequest(loginBody) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/login`, loginBody)
	return apiResults.data
}

export async function loginExternalKongregateRequest(loginBody) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/login/external`, { platform: 'kongregate', ...loginBody })
	return apiResults.data
}

export async function loginExternalArmorGamesRequest(loginBody) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/login/external`, { platform: 'armor_games', ...loginBody })
	return apiResults.data
}

export async function loginExternalSteamRequest(loginBody) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/login/external`, { platform: 'steam', ...loginBody })
	return apiResults.data
}


export async function postStoryRun(storyId, payload) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/run`, payload, {
		headers: { 'auth-token': getAuthToken() },

	})
	return apiResults.data
}

export async function postJoinStory(storyId) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/join`, {}, {
		headers: { 'auth-token': getAuthToken() },
	})
	return apiResults.status
}

export async function getStories() {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story?gameType=public&page=0`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function getMyStories(isArchived: boolean) {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story?gameType=mine&page=0&isArchived=${isArchived}`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function getStory(storyId) {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function getStoryActivities(storyId) {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/activities`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function postChat(storyId, message) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/chat`, { message }, {
		headers: { 'auth-token': getAuthToken() },
	})
	return apiResults.data
}

export function longPollGetStoryActivities(storyId, callback: (apiResults: any) => void, failureCallback: () => void): AbortController {
	const abortController = new AbortController()
	axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/new-activity-long-poll`,
		{
			headers: { 'auth-token': getAuthToken() },
			signal: abortController.signal,
		},
	).then(callback, failureCallback).catch((e) => { })

	return abortController
}

export async function getChapterRuns(storyId: string, chapter: number) {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/runs?chapter=${encodeURIComponent(chapter)}`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function postStory(payload) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story`, payload, {
		headers: { 'auth-token': getAuthToken() },
	})
	return apiResults.data
}

export async function postBookReview(storyId, payload) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/book-review`, payload, {
		headers: { 'auth-token': getAuthToken() },
	})
	return apiResults.data
}

export async function getMutators(storyId, chapter) {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/twist?chapter=${chapter}`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function postCastVote(storyId, payload) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/twist`,
		payload
		, {
			headers: { 'auth-token': getAuthToken() },
		})
	return apiResults.data
}

export async function getUnlocks() {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/unlocks`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function getPerks() {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/perks`,
		{
			headers: { 'auth-token': getAuthToken() },
		},
	)
	return apiResults.data
}

export async function postBuyUnlock(unlock: string) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/buy-unlock`,
		{
			unlock
		},
		{
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postBuyPerk(perkId: string, ranksToBuy: number) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/buy-perk`,
		{ // body
			perkId,
			ranksToBuy,
		},
		{ // request
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postUpdatePlayerIcon(playerIconTag: string, playerIconColor: string) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/update-player-icon`,
		{ // body
			playerIconTag,
			playerIconColor,
		},
		{ // request
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postRefundPerk(perkId: string, ranksToRefund: number) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/refund-perks`,
		[ // body
			{ perkId, ranksToRefund },
		],
		{ // request
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postRefundPerks(perks: Array<{ perkId: string, ranksToRefund: number }>) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/refund-perks`,
		perks, //body
		{ // request
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function sendForgotPasswordEmail(emailAddress: string) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/forgot-password`,
		{ emailAddress },
		{
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postResetPassword(body) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/reset-password`,
		body,
		{
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postArchiveStory(storyId: string) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/${storyId}/archive`,
		{},
		{
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postSearchStories(searchParams: SearchOptions) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/story/search`,
		searchParams,
		{
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function postSetTutorialFlags(flags: any) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/set-tutorial-flags`,
		flags,
		{
			headers: { 'auth-token': getAuthToken() },
		}
	)
	return apiResults.data
}

export async function getMaintenanceModeFlag() {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/maintenance-mode-status`)
	return apiResults.data
}

export async function postGenerateAccountLinkCode() {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/post-generate-account-link-code`, {}, {
		headers: { 'auth-token': getAuthToken() },
	})
	return apiResults.data
}

export async function postLinkAccountThroughCode(linkCode: string) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/post-link-account-through-code?linkCode=${linkCode}`, {}, {
		headers: { 'auth-token': getAuthToken() },
	})
	return apiResults.data
}

export async function getAccountInfoFromLinkCode(linkCode: string) {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GRIDDLE_API_ADDRESS}/griddle/get-account-info-from-link-code?linkCode=${linkCode}`, {
		headers: { 'auth-token': getAuthToken() },
	})
	return apiResults.data
}
