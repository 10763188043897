import { characterDefinitions, WISHLIST_CHARACTER } from "../../game-data/characters"
import { getTreeString } from "../../utils/debug"
import { WEAPON_STATS, WISHLIST_WEAPON } from "../../weapons/weapon-definitions"
import { AllWeaponTypes, PrimaryWeaponType } from "../../weapons/weapon-types"

const initialState = {
    characterData: [],
    weaponData: [],
    noWeaponData: null,
    selectedCharacter: null,
    selectedWeapon: null,
    characterAndWeaponRefreshIndex: 0,
}

const store = {
    namespaced: true,
    state: initialState,
    getters: {

        selectedCharacter(state: CharacterWeaponStoreState) {
            return state.characterData.find(character => character.icon === state.selectedCharacter.icon)
        },
        selectedWeapon(state: CharacterWeaponStoreState) {
            return state.selectedWeapon
        },
        getCharacterData(state: CharacterWeaponStoreState) {
            return state.characterData
        },
        getWeaponData(state: CharacterWeaponStoreState) {
            return state.weaponData
        },

        // Used in game-client.ts
        selectedCharacterAndWeapon(state: CharacterWeaponStoreState) {
            return { character: state.selectedCharacter.characterType, weapon: state.selectedWeapon.weaponType }
        },
    },
    mutations: {
        updateCharacterData(state: CharacterWeaponStoreState, characterData) {
            state.characterData = characterData
        },
        updateWeaponData(state: CharacterWeaponStoreState, weaponData) {
            state.weaponData = weaponData
        },
        updateSelectedCharacter(state: CharacterWeaponStoreState, characterSelected) {
            state.selectedCharacter = true
            state.selectedCharacter = characterSelected
        },
        resetSelectedCharacter(state: CharacterWeaponStoreState) {
            state.selectedCharacter = false
            state.selectedCharacter = null
        },
        updateSelectedWeapon(state: CharacterWeaponStoreState, weaponSelected) {
            state.selectedWeapon = weaponSelected
        },

        resetSelectedWeapon(state: CharacterWeaponStoreState) {
            state.selectedWeapon = null
        },
        initialSelectedData(state: CharacterWeaponStoreState, initialData) {
            state.selectedCharacter = true
            state.selectedCharacter = initialData.character
            state.selectedWeapon = initialData.weapon
        },
        forceCharacterAndWeaponRefresh(state: CharacterWeaponStoreState) {
            state.characterAndWeaponRefreshIndex++
        }
    },
    actions: {
        fetchCharacterData({ state, commit, getters }: { state: CharacterWeaponStoreState; commit: any, getters: any }) {
            let formattedCharacterData = []
            let formattedWeaponData = []

            if (process.env.IS_WEB) {
                formattedCharacterData = Object.values(characterDefinitions)
                formattedCharacterData = formattedCharacterData.filter((character) => {
                    return character.name !== characterDefinitions["solara-sun"].name
                        && character.name !== characterDefinitions.radar.name
                })

                console.log(formattedCharacterData)

                formattedCharacterData.push(WISHLIST_CHARACTER)
            } else {
                formattedCharacterData = Object.values(characterDefinitions)
            }

            for (const [key, value] of Object.entries(WEAPON_STATS)) {
                if (value.weaponType !== PrimaryWeaponType.None) {
                    formattedWeaponData.push(value)
                } else {
                    state.noWeaponData = value
                }
            }

            // TODO flag out primary weapons
            if (process.env.IS_WEB) {
                formattedWeaponData.push(WISHLIST_WEAPON)
            }

            commit('updateCharacterData', formattedCharacterData)
            commit('updateWeaponData', formattedWeaponData)
            commit('initialSelectedData', { character: formattedCharacterData[0], weapon: formattedWeaponData[0] })
        }
    },
}
export type CharacterWeaponStoreState = typeof initialState
export const CharacterWeaponStore = typeof store

export const characterWeaponStore = () => {
    return store
}
