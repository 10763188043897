import EntityStatList from "../stats/entity-stat-list";
import { StatName, StatType } from "../stats/stat-interfaces-enums"

/** These formulas can apply diminishing returns, logarithmic growth, or any other
	transformation to stats that:0, requires specialty curves.
	It's recommended to use StatClamps for ceilings and floors. */
export const StatCompoundFormulas = {} //TODO: type this, keyof StatName: (base, current) => number

// StatCompoundFormulas[StatTypeToPropName[StatType.ATTACK_RATE]] = (base: number, current: number) => {
// 	if (current > 7500) {
// 		current -= 7500
// 		current = Math.min(5000, current ** 0.95 * 1.2)
// 		current += 7500
// 	}
// 	return current
// }
StatCompoundFormulas[StatType.maxAmmo] = (base: number, current: number) => {
	return Math.floor(current)
}
StatCompoundFormulas[StatType.reloadAmmoIncrement] = (base: number, current: number) => {
	return Math.floor(current)
}


export function defaultStatAttribute(statList: EntityStatList) {
	for (const key of Object.keys(baseStats)) {
		statList._actualStatValues[key] = baseStats[key]
	}
}

export const baseStats: {[k in StatName]: number} = {
	maxHealth: 0,
	healthRegenInterval: 0,
	maxShields: 0,
	shieldRegenRate: 0,
	shieldRegenDelay: 0,
	damageTakenMult: 1.0,
	allDamageMult: 1.0,
	initialImpactDamageMult: 1.0,
	bossDamageMult: 1.0,
	baseDamage: 0,
	attackRate: 0,
	critChance: 0,
	critDamage: 0,
	movementSpeed: 0,
	buffDurationMult: 1.0,
	skillUsageSpeedMult: 1.0,
	randomAilmentChance: 0,
	stunChance: 0,
	bleedChance: 0,
	igniteChance: 0,
	chillChance: 0,
	shockChance: 0,
	poisonChance: 0,
	doomChance: 0,
	bleedPotency: 1,
	ignitePotency: 1,
	chillPotency: 1,
	shockPotency: 1,
	stunPotency: 1,
	poisonPotency: 1,
	doomPotency: 1,
	allAilmentPotencyMult: 1.0,
	damagingAilmentPotencyMult: 1.0,
	statusAilmentPotencyMult: 1.0,
	attackPierceCount: 0,
	attackSize: 1.0,
	attackKnockback: 0,
	projectileCount: 0,
	projectileSpeed: 0,
	projectileSpreadAngle: 0,
	projectileSpreadVariance: 0,
	projectileChainCount: 0,
	projectileChainDistanceMultiplier: 1.0,
	projectileSplashRadius: 0,
	projectileSplashDamage: 0,
	projectileRicochetCount: 0,
	projectileSplitCount: 0,
	beamRampUpDamageMult: 1.0,
	chargeRate: 0,
	chargedWeaponDamageMult: 1.0,
	maxEnergy: 0,
	energyRegen: 0,
	primaryFireEnergyCost: 0,
	placementRange: 0,
	pickupRange: 0,
	heartDropMulti: 1.0,
	xpReDropChance: 0.0,
	xpValueMulti: 1.0,
	skillDuration: 0,
	slowAmount: 0,
	maxAmmo: 0,
	reloadAmmoIncrement: 0,
	reloadInterval: 0,
	cooldownInterval: 0,
	walkSpeedScalar: 0.62,
	projectileLifeSpan: 5,
	maxCharge: 100,
	knockbackResist: 0,
	saintsSpearChance: 0,
}