/** Enum of buff identifiers and their l18n display strings */
export enum BuffIdentifier {

	// Damage1 Upgrades
	BigStick = 'upgradeBigStick',
	Thunky = 'upgradeThunky',
	SecondarySpecialist = 'upgradeSecondarySpecialist',
	Pierce2 = 'upgradePierce2',
	RapidKiller = 'upgradeRapidKiller',
	CarefulShooter = 'upgradeCarefulShooter',
	MarkswitchFinalForm = 'upgradeMarkswitchFinalForm',
	LongbowWalkItOff = 'longbowWalkItOff',
	LongbowTakingNames = 'longbowTakingNames',

	// Pickup Range Upgrades
	PickupTop = 'pickupTop',
	PickupLeft = 'pickupLeft',
	PickupRight = 'pickupRight',

	HealthRegeneration = 'upgradeHealthRegeneration',

	// Attack Rate Upgrades
	FasterAttacks = 'upgradeFasterAttacks',
	DeathTreadmill = 'upgradeDeathTreadmill',
	HighTideBlessing = 'upgradeHighTideBlessing',
	UltraRapidFire = 'upgradeUltraRapidFire',

	/** The speed boost that pickup left fires on XP pickup */
	Banditry = 'upgradeBanditry',
	PickupBottomXPHeal = 'pickupBottomXPHeal',
	QuickReloadAttackRate = 'upgradeQuickReloadAttackRate',

	ConstantMovingYoink = 'upgradeConstantMovingYoink',
	Phase = 'phase',

	BowPinDown = 'bowPinDown',
	BoomerangAccelerationPlayerBuff = 'boomerangAcceleration',

	LutePowerUp = 'lutePowerUp',

	// Ranger skill
	RangerTrapDebuff = 'RangerTrapDebuff',
	RangerMarkDebuff = 'RangerMarkDebuff',
	RangerMarkSlowDebuff = 'RangerMarkSlowDebuff',

	// Barbarian Skill
	BarbarianMovementSpeedBuff = 'BarbarianMovementSpeedBuff',
	BarbarianDamageBoost = 'BarbarianDamageBoost',

	// Dog
	GoodBoyBuff = 'GoodBoyBuff',
	ChompMoveSpeed = 'ChompMoveSpeed',

	// Solara
	SolarAttunement = 'SolarAttunement',
	LunarAttunement = 'LunarAttunement',
	SpeedOfLight = 'SpeedOfLight',
	SolarSupremecy = 'SolarSupremecy',
	SunSoul = 'SunSoul',
	
	// Radar passive
	WealthyEnemy = 'WealthyEnemy',

	// Players
	FlagDoubleTap = 'buffFlagDoubleTap',
	FlagTripleTap = 'buffFlagTripleTap',
	SuperBeam = 'buffSuperBeam',
	OnKillMovementSpeed = 'buffOnKillMovementSpeed',
	OnShieldBreakMovementSpeed = 'buffOnShieldBreakMovementSpeed',

	// Shared
	Invulnerable = 'buffInvulnerable',
	NoCollideWithProjectile = 'buffNoCollideWithProjectile',
	Confusion = 'buffConfusion',
	OnFire = 'buffOnFire',

	IcePool = 'debuffIcePool',
	Weakened = 'debuffWeakened',
	LavaPool = 'debuffLavaPool',

	// Elemental status ailments
	Poison = 'buffPoison',
	Ignite = 'buffIgnite',
	Bleed = 'buffBleed',
	BleedStackImmune = 'buffBleedStackImmune',
	Chill = 'buffChill',
	Shock = 'buffShock',
	Stun = 'buffStun',
	StunImmune = 'buffStunImmune',
	Doom = 'buffDoom',

	ProjectileShield = 'buffProjectileShield',
	AllDamageShield = 'buffAllDamageShield',

	StunMildPFX = 'buffStunMildPFX',

	// Acid bottles
	AcidBottleCarryOver = 'acidCarryOver',
	PrimaryWeaponVulnerable = 'primaryVulnerable',

	// Enemy choreo / events
	PermanentSmallSlow = 'permaSmallSlow',
	PermanentSlow = 'permaSlow',
	PermanentSlower = 'permaSlower',
	PermanentNoMove = 'permaNoMove',
	SlowlyRampMovementSpeed = 'SlowlyRampMovementSpeed',
	ChoreoSpeedy = 'ChoreoSpeedy',
	LikeABoss = 'LikeABoss',
	Sturdy = 'Sturdy',
	Immovable = 'Immovable',
	Berserk = 'Berserk',

	// Boomerang buffs
	CatchEmAll = 'catchEmAll',

	// Holy Spear
	HolyLight = 'holyLight',

	// Combat Arena
	CombatArenaAttackRate = 'combatArenaAttackRate',
	CombatArenaDamage = 'combatArenaDamage',
	CombatArenaMovement = 'combatArenaMovement',

	// Pet
	RainbowPetGreen = 'rainbowPetGreen',
	RainbowPetRed = 'rainbowPetRed',
	RainbowPetBlue = 'rainboPetBlue',

	// random twists
	ParanormalExerciseApplier = 'paranormalApplier',
	ParanormalGhosted = 'paranormalGhosted',

	Enraged = 'enraged',
	
	SpookyGhosted = 'spookyGhosted', 
	BiggifyElixir = 'biggifyElixir',
	DwindleyElixir = 'dwindleyElixir',
	CreepyEgg = 'creepyEggSlow',
	SpicyPepper = 'spicyPepper',
	SpectralFarmerApplier = 'spectralFarmerApplier',
	SpectralFarmer = 'spectralFarmer',
	SpectralFarmerSlow = 'spectralFarmerSlow',
	Berserker = 'berserker',
	BarbarianAura = 'barbarianAura'
}

export const MAX_VISIBLE_BUFFS = 6

export const AILMENT_DEBUFFS = [BuffIdentifier.Poison, BuffIdentifier.Ignite, BuffIdentifier.Bleed, BuffIdentifier.Chill, BuffIdentifier.Shock, BuffIdentifier.Stun, BuffIdentifier.Doom]