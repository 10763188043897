import { Vector } from "sat"
import { Audio } from "../../engine/audio"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { ComponentOwner } from "../../engine/component-owner"
import { GameState } from "../../engine/game-state"
import { Renderer } from "../../engine/graphics/renderer"
import { SpritesheetAnimatorComponent } from "../../engine/graphics/spritesheet-animator-component"
import { callbacks_addCallback } from "../../utils/callback-system"
import { timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../../utils/third-party/object-pool"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { EntityType } from "../entity-interfaces"
import { Pet } from "./pet"

const STOMP_EFFECT_LIFETIME = 1.5
export const STOMP_EFFECT_RADIUS = 650

export interface PetStompEffectParams {
	owner: Pet
	splashRadius: number
	damageDelay: timeInSeconds
}

export class PetStompEffect implements PoolableObject, ComponentOwner {
	static pool: ObjectPoolTyped<PetStompEffect, PetStompEffectParams>
	triedToReturnToPool: boolean

	nid: number
	entityType: EntityType = EntityType.GroundHazard

	position: Vector
	splashRadius: number

	owner: Pet
	remainingLifetime: number

	visuals: SpritesheetAnimatorComponent

	weaponType: AllWeaponTypes
	numEntitiesChained: number
	numEntitiesPierced: number

	damageDelay: timeInSeconds

	constructor() {
		this.makeVisuals()
		this.position = new Vector()
		this.remainingLifetime = STOMP_EFFECT_LIFETIME
		this.triedToReturnToPool = false
	}

	setDefaultValues(defaultValues: any, overrideValues?: PetStompEffectParams) {
		if(overrideValues) {
			this.splashRadius = overrideValues.splashRadius
			this.owner = overrideValues.owner

			this.position.copy(overrideValues.owner.position)
			this.damageDelay = overrideValues.damageDelay
			this.weaponType = AllWeaponTypes.Pet

			this.remainingLifetime = STOMP_EFFECT_LIFETIME
			this.triedToReturnToPool = false

			GameState.addEntity(this)
			this.setVisuals()
			this.hitEnemy()
		}
	}

	cleanup() {
		this.visuals.removeFromScene()
		
		GameState.removeEntity(this)
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		this.visuals.update(delta)
		this.remainingLifetime -= delta
		if(!this.triedToReturnToPool && this.remainingLifetime <= 0) {
			this.returnToPool()
			this.triedToReturnToPool = true
		}
	}

	hitEnemy() {
		Audio.getInstance().playSfx('SFX_Elemental_Fire')
		const enemiesNearby = CollisionSystem.getInstance().getEntitiesInArea(this.position, this.splashRadius, CollisionLayerBits.HitEnemyOnly)
		// Renderer.getInstance().drawCircle({ x: this.position.x, y: this.position.y, radius: this.splashRadius, destroyAfterSeconds: 0.3, scale: 1, permanent: false, color: 0xFF0000 })
		callbacks_addCallback(this, () => {
			for (let i = 0; i < enemiesNearby.length; ++i) {
				const enemy = enemiesNearby[i].owner as Enemy
				enemy.onHitByDamageSource(this.owner)
			}
			this.owner.finishedPetAbility()
		}, this.damageDelay)
	}

	// Not used (yet)
	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		return mutableEntityPos.sub(this.position).normalize()
	}

	makeVisuals() {
		const spriteSheet = AssetManager.getInstance().getAssetByName('pet-stomp').spritesheet
		this.visuals = new SpritesheetAnimatorComponent(this, spriteSheet, 'stomp-ground-pfx', undefined, true)
	}

	setVisuals() {
		this.visuals.addToScene()
		const magicScalingValue = 400
		const scale = this.splashRadius / magicScalingValue
		this.visuals.setScale(scale, scale)
		this.visuals.spriteSheetAnimator.playAnimationsConcurrently()
	}

	isPlayerOwned(): boolean {
		return true
	}

	returnToPool() {
		PetStompEffect.pool.free(this)
	}
}