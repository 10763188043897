<template>
	<div id="divider">
		<div class="embellishment" :class="{ flip: flipHorizontal }">
			<img src="../../../../assets/ui/divider/separator-embellishment.png" alt="" />
		</div>
		<div class="text-container">
			<div class="header" :lang="this.$i18n.locale" :style="[{ 'text-align': textAlignment }, headerFontSize]">{{ $t(label) }}</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
const TEXT_POSITIONS = ['left', 'right', 'center']
const FONT_SIZE = ['small', 'medium', 'large']

export default {
	name: 'Divider',
	components: {},
	data() {
		return {
			fontSizes: {
				small: { fontSize: '32px' },
				medium: { fontSize: '40px' },
				large: { fontSize: '50px' },
			},
		}
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		flipHorizontal: {
			type: Boolean,
			required: true,
		},
		textAlignment: {
			type: String,
			required: true,
			default: 'left',
			validator: function (value) {
				return TEXT_POSITIONS.includes(value)
			},
		},
		fontSize: {
			type: String,
			required: true,
			default: 'medium',
			validator: function (value: string) {
				return FONT_SIZE.includes(value)
			},
		},
	},
	computed: {
		headerFontSize() {
			if (this.fontSizes.hasOwnProperty(this.fontSize)){
				return this.fontSizes[this.fontSize]
			}
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
#divider {
	position: relative;
	height: 90px;
	.embellishment {
		&.flip {
			transform: scaleX(-1);
		}
	}
	.text-container {
		position: relative;
		top: -20px;
		margin: 0px 60px;
		.header {
			position: relative;
			color: @cream;
			filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.5));
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
	}
}
</style>
