<template>
	<div id="countdown">
		<div :lang="this.$i18n.locale" class="timer">{{ getCountdownToWin }}</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'Countdown',
	components: {},
	data() {
		return {}
	},
	props: {
		countDown:{
			type: String,
			required: true,
		}
	},
	computed: {
			...mapGetters('ui', ['getCountdownToWin']),
		...mapGetters('time', ['inGameTimestamp']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#countdown {
	.timer {
		color: @darkBlue;
		filter: drop-shadow(0px 0px 3px green);
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
			font-size: 45px;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
		
	}
}

@supports (-webkit-text-stroke: 1px @green) {
	#countdown {
	.timer {
		-webkit-text-stroke: 1px @green;
		-webkit-text-fill-color: @darkBlue;
	}
}
}
</style>
