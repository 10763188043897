import { chain } from 'lodash'
import { throwIfNotFinite, mapToRange } from "../utils/math"
import { percentage, timeInMilliseconds, gameUnits } from "../utils/primitive-types"
import { StatType, StatName, StatConverter, StatClampConstraint, StatOperator, AllStatPropNames, StatUnit } from "./stat-interfaces-enums"
import { defaultStatAttribute, StatCompoundFormulas } from '../game-data/stat-formulas'

// if we exceed this number of bonuses, there's probably a mistake somewhere
// it also increases our list sorting time which could cause a performance issue
const MAX_BONUSES_PER_STAT_TYPE_WARNING = 150

const ALL_AILMENT_STATS = ['ignitePotency', 'chillPotency', 'shockPotency', 'poisonPotency', 'bleedPotency', 'doomPotency']
const DAMAGING_AILMENT_POTENCY = ['ignitePotency', 'poisonPotency', 'bleedPotency', 'doomPotency']
const STATUS_AILMENTS_POTENCY = ['chillPotency', 'shockPotency']

export type StatBonusData = { statName: StatName, operatorType: StatOperator, value: StatBonusValue }

export type StatBonusValue = number | percentage | timeInMilliseconds | gameUnits
type PhasedStatTransform = [StatType, StatBonusValue, StatOperator]

class StatBonus {
	private _statList: EntityStatList = undefined
	private _statName: StatName = undefined
	private _value: StatBonusValue = undefined
	private _operatorType: StatOperator = undefined

	get statList() {
		return this._statList
	}
	get statName() {
		return this._statName
	}

	get operator() {
		return this._operatorType
	}

	get value() {
		return this._value
	}

	set value(newValue: StatBonusValue) {
		this._value = newValue
		this._statList.markStatDirty(this._statName)
	}
	get operatorType() {
		return this._operatorType
	}

	constructor(statList: EntityStatList, statName: StatName, operatorType: StatOperator, value: StatBonusValue) {
		this._statList = statList
		this._statName = statName
		this._value = value
		this._operatorType = operatorType
	}

	/**
	 * Updates the value of a StatBonus. Identical to simply setting .value
	 *
	 * @param {(StatBonusValue)} newValue
	 * @memberof StatBonus
	 */
	update(newValue: StatBonusValue) {
		this._value = newValue
		this._statList.markStatDirty(this._statName)
	}

	/**
	 * Removes this bonus entirely, causing the owning StatList to be marked as dirty.
	 *
	 * @returns
	 * @memberof StatBonus
	 */
	remove(): void {
		this._statList?.removeStatBonus(this)
	}
}
class EntityStatList {
	/** Quickly inspect the multipliers for every stat */
	get statBonusMultiplier() {
		return this._statBonusMultiplier
	}

	_actualStatValues: {
		[k in StatName]?: StatBonusValue
	} = {}

	private _converters: StatConverter[] = []
	_clamps: StatClampConstraint[] = []
	private _statBonusMultiplier = {}
	private _resetStatsCb: (statList: EntityStatList) => void = undefined
	// this could be performance optimized/memory optimized so that instead of being an object of N arrays, this could be a single array that is sorted by statName (or statType enum)
	private _statBonuses: {
		[k in StatName]?: StatBonus[]
	} = {}
	// This is a list of all the stats that are currently dirty (have been written to since the last recalculateStats().
	_statIsDirty: {
		[k in StatName]?: boolean
	} = {}

	_childStatLists: EntityStatList[] = []
	_parentStatList: EntityStatList

	/**
	 * @param {*} resetStatsCb Defines the stat reset behavior for this type of entity. Useful if you want to set default values for a stat prior to recalculation.
	 */
	constructor(resetStatsCb: (statList: EntityStatList) => void, parentStatList?: EntityStatList) {
		this._resetStatsCb = resetStatsCb

		for (const prop of AllStatPropNames) {
			this._actualStatValues[prop] = 0
			this._statBonusMultiplier[prop] = 1
			this._statBonuses[prop] = []
			this._statIsDirty[prop] = true
			Object.defineProperty(this, prop, {
				get: () => {
					return this.getStat(prop)
				}
				// get: makeGetter<typeof prop>(this, prop)
			})
		}

		if (parentStatList) {
			this._parentStatList = parentStatList
			parentStatList._childStatLists.push(this)
		}
	}

	removeChild(statList: EntityStatList) {
		this._childStatLists.remove(statList)
		statList._parentStatList = null
		statList.markAllStatsDirty()
	}

	markAllStatsDirty() {
		for (const statName of Object.keys(this._statIsDirty)) {
			this._statIsDirty[statName] = true
		}

		for (const child of this._childStatLists) {
			child.markAllStatsDirty()
		}
	}

	markAllStatsClean() {
		for (const statName of Object.keys(this._statIsDirty)) {
			this._statIsDirty[statName] = false
		}
	}

	markStatDirty(statName: StatName) {
		this._statIsDirty[statName] = true

		const converters = this._getConverters()
		for (const c of converters) {
			if (c.inputStatType === statName) {
				this._statIsDirty[c.outputStatType] = true
			}
		}

		for (const child of this._childStatLists) {
			child.markStatDirty(statName)
		}
	}

	addStatBonus(statName: StatName, statOperator: StatOperator, newValue: StatBonusValue): StatBonus | StatBonus[] {
		if (statName === 'allAilmentPotencyMult' || statName === 'damagingAilmentPotencyMult' || statName === 'statusAilmentPotencyMult') {
			let statNamesArray
			switch (statName) {
				case 'allAilmentPotencyMult':
					statNamesArray = ALL_AILMENT_STATS
					break
				case 'damagingAilmentPotencyMult':
					statNamesArray = DAMAGING_AILMENT_POTENCY
					break
				case 'statusAilmentPotencyMult':
					statNamesArray = STATUS_AILMENTS_POTENCY
					break
			}

			const bonuses = []
			for (let i = 0; i < statNamesArray.length; ++i) {
				const bonus = new StatBonus(this, statNamesArray[i], statOperator, newValue)
				bonuses.push(bonus)
				this._statBonuses[statNamesArray[i]].push(bonus)
				this.markStatDirty(statNamesArray[i])
			}

			const aliasBonus = new StatBonus(this, statName, statOperator, newValue)
			bonuses.push(aliasBonus)
			this.addStatBonusDirect(aliasBonus)
			return bonuses
		} else {
			const bonus = new StatBonus(this, statName, statOperator, newValue)
			this.addStatBonusDirect(bonus)
			return bonus
		}
	}

	addStatBonusDirect(bonus: StatBonus) {
		const statName = bonus.statName
		this._statBonuses[statName].push(bonus)
		this.markStatDirty(statName)
	}

	addConverter(converter: StatConverter) {
		this._converters.push(converter)
		this.markStatDirty(converter.inputStatType)
		this.markStatDirty(converter.outputStatType)
	}

	removeConverter(converter: StatConverter) {
		this._converters.remove(converter)
		this.markStatDirty(converter.inputStatType)
		this.markStatDirty(converter.outputStatType)
	}

	addClamp(clamp: StatClampConstraint) {
		this._clamps.push(clamp)
		this.markStatDirty(clamp.statType)
	}

	removeClamp(clamp: StatClampConstraint) {
		this._clamps.remove(clamp)
		this.markStatDirty(clamp.statType)
	}

	getStat(statName: StatName, ignoreMissingStat?: boolean): StatBonusValue {
		// only trigger recalculate if THIS stat is dirty.
		// we still have to recalculate the entire thing, because of the potential chaining of converters... probably?
		if (this._statIsDirty[statName]) {
			if (this._resetStatsCb) {
				this._resetStatsCb(this)
			}

			this._recalculateStats()

			if (this._hasConverters()) {
				this._applyConverters()
			}

			if (this._hasClamps()) {
				this._applyClamps()
			}

			this._applyStatCompoundFormulas()

			this.markAllStatsClean()
		}

		const statValue = this._actualStatValues[statName]
		if (!ignoreMissingStat) {
			throwIfNotFinite(statValue, `no stat called ${statName}`)
		}
		return statValue
	}

	getStatBonusesCount() {
		let count = 0
		for (const bonus of Object.values(this._statBonuses)) {
			count += bonus.length
		}
		return count
	}

	resetStats() {
		if (this._resetStatsCb) {
			this._resetStatsCb(this)
		}
	}

	removeStatBonus(statBonus: StatBonus): StatBonus {
		this._statBonuses[statBonus.statName].remove(statBonus)
		//TODO: free stat bonus, if pooled
		this.markStatDirty(statBonus.statName)
		return statBonus
	}

	clearAllState() {
		this.clearAllStatBonuses()
		this._converters.length = 0
		this._clamps.length = 0
		this.markAllStatsDirty()
	}

	clearAllStatBonuses() {
		for (const name of Object.keys(this._statBonuses)) {
			for (const bonus of this._statBonuses[name]) {
				this.removeStatBonus(bonus)
			}
		}
		this.markAllStatsDirty()
	}

	clearAllStatBonusesOfStat(statName: StatName) {
		if (this._statBonuses[statName]) {
			for (let i = this._statBonuses[statName].length - 1; i >= 0; i--) {
				this.removeStatBonus(this._statBonuses[statName][i])
			}
			this.markStatDirty(statName)
		}
	}

	removeAllChildren() {
		this._childStatLists.forEach(c => {
			c._parentStatList = null
			c.markAllStatsDirty()
		})
		this._childStatLists.length = 0
	}

	getAllStatsDebug(): string {
		this._resetStatsCb(this)
		let result = ''

		for (const name of Object.keys(this._statBonuses)) {
			this._statBonusMultiplier[name] = 1
			result += `${name}:\n  Base value: ${this._actualStatValues[name]}\n`
			let multi = 0
			this._statBonuses[name].forEach((stat) => {
				if (stat.operatorType === StatOperator.SUM) {
					result += `   ${stat.statName}: ${stat.value} (sum)\n`
					this._actualStatValues[stat.statName] += stat.value
				}
				if (stat.operatorType === StatOperator.SUM_THEN_MULTIPLY) {
					result += `   ${stat.statName}: ${stat.value} (sum then multiply)\n`
					multi += stat.value
				}
				if (stat.operatorType === StatOperator.MULTIPLY) {
					result += `   ${stat.statName}: ${stat.value} (multipy)\n`
					this._actualStatValues[stat.statName] = this._actualStatValues[stat.statName] * (1 + stat.value)
					this._statBonusMultiplier[name] *= 1 + stat.value
				}
			})
			this._actualStatValues[name] = this._actualStatValues[name] * (1 + multi)
			this._statBonusMultiplier[name] *= 1 + multi
			result += `  Result: ${this._actualStatValues[name]}\n`
		}

		return result
	}

	private _recalculateStats() {
		//console.log(`***_recalculateStats***`)
		const statsWithChangedValues = []
		for (const name of Object.keys(this._statBonuses)) {
			this._statBonusMultiplier[name] = 1
			//console.assert(this._actualStatValues[name] !== undefined, `${name} does not exist in EntityStatList, but is being assigned a value.`)
			//console.log(` modifying ${name}:${this._actualStatValues[name]}`)
			statsWithChangedValues.push(name)
			let multi = 0

			let bonuses = this.getStatBonuses(name)

			if (bonuses.length > MAX_BONUSES_PER_STAT_TYPE_WARNING) {
				if (process.env.NODE_ENV === 'local') {
					console.trace(`Stat bonuses for ${name} exceeded max bonuses of ${MAX_BONUSES_PER_STAT_TYPE_WARNING}.`)
				}
			}

			bonuses.sort(sortByStatNameThenOperator)

			bonuses.forEach((stat) => {
				//console.log(`  ${name}:${stat.value} ${stat.statName}`)
				if (stat.operatorType === StatOperator.SUM) {
					this._actualStatValues[stat.statName] += stat.value
				} else if (stat.operatorType === StatOperator.SUM_THEN_MULTIPLY) {
					multi += stat.value
				} else if (stat.operatorType === StatOperator.MULTIPLY) {
					this._actualStatValues[stat.statName] = this._actualStatValues[stat.statName] * (1 + stat.value)
					this._statBonusMultiplier[name] *= 1 + stat.value
				}
			})
			this._actualStatValues[name] = this._actualStatValues[name] * (1 + multi)
			this._statBonusMultiplier[name] *= 1 + multi
			//console.log(` result: ${this._actualStatValues[name]}`)
		}
		this._applyStatCompoundFormulasToStats(statsWithChangedValues)
	}

	private getStatBonuses(statName: string): StatBonus[] {
		if (this._parentStatList) {
			const parentBonuses = this._parentStatList.getStatBonuses(statName)
			if (parentBonuses.length > 0) {
				return this._statBonuses[statName].concat(parentBonuses)
			}
		}

		return this._statBonuses[statName]
	}

	private _applyConverters() {
		let converters = this._getConverters()

		converters.sort(sortByInputStatTypeThenOperator)

		let transformsToApply: PhasedStatTransform[] = []
		const groupedConverters = chain(converters).groupBy('inputStatType').values().value()

		for (const converters of groupedConverters) {
			const statTransform = this._applyConvertersToSpecificStat(converters, converters[0].inputStatType)
			transformsToApply.push(...statTransform)
		}

		transformsToApply = transformsToApply.sort(sortPhasedStatTransformsByStatTypeAndOperatorType)
		this._applyStatTransforms(transformsToApply)
	}

	private _getConverters(): StatConverter[] {
		if (this._parentStatList) {
			return this._parentStatList._getConverters().concat(this._converters)
		}

		return this._converters
	}

	private _hasConverters() : boolean {
		if (this._parentStatList) {
			return this._parentStatList._hasConverters() || this._converters.length > 0
		}

		return this._converters.length > 0
	}

	private _applyStatTransforms(transforms) {
		let multi = 0
		let lastStatType: StatType
		//TODO2: share this with the recalculateStats bit?
		transforms.forEach(([statType, value, operator]: PhasedStatTransform) => {
			if (multi && lastStatType && lastStatType !== statType) {
				this._actualStatValues[lastStatType] = this._actualStatValues[lastStatType] * (1 + multi)
				multi = 0
			}
			if (operator === StatOperator.SUM) {
				this._actualStatValues[statType] += value
			} else if (operator === StatOperator.SUM_THEN_MULTIPLY) {
				multi += value
			} else if (operator === StatOperator.MULTIPLY) {
				this._actualStatValues[statType] = this._actualStatValues[statType] * (1 + value)
			}
			lastStatType = statType
		})
		if (multi && lastStatType) {
			this._actualStatValues[lastStatType] = this._actualStatValues[lastStatType] * (1 + multi)
			multi = 0
		}
	}

	/**
	 *
	 * @param converters
	 * @param statType
	 * @param transformsToApply By-reference `out` array of transforms to pass back to caller
	 */
	private _applyConvertersToSpecificStat(converters: StatConverter[], statType: StatType): PhasedStatTransform[] {
		const transformsToApply: PhasedStatTransform[] = []
		const sumInputRatio = converters.reduce((prev, curr) => {
			return prev + curr.inputRatio
		}, 0)
		const inputStatValue = this._actualStatValues[statType]
		const inputStatMulti = this._statBonusMultiplier[statType]
		converters.forEach((converter) => {
			let inputStatAvailable
			if (converter.inputStatUnit === StatUnit.Percentage) {
				inputStatAvailable = inputStatMulti - converter.inputMinReserve
			} else if (converter.inputStatUnit === StatUnit.Number) {
				inputStatAvailable = inputStatValue - converter.inputMinReserve
			}
			// map an input ratio that could exceed 1.0 (100%) back to a range between 0-1.0
			const scaledInputRatio = sumInputRatio > 1 ? mapToRange(converter.inputRatio, 0, sumInputRatio, 0, 1) : converter.inputRatio
			const inputFreeRatio = converter.inputFreeRatio
			const statValueToConvertToOutput = inputStatAvailable * scaledInputRatio
			const statValueToAddToOutput = inputStatAvailable * inputFreeRatio

			const newInputValue = statValueToConvertToOutput
			const newOutputValue = statValueToConvertToOutput * converter.outputRatio + statValueToAddToOutput * converter.outputRatio

			transformsToApply.push([statType, -newInputValue, StatOperator.SUM])
			transformsToApply.push([converter.outputStatType, newOutputValue, converter.outputStatOperator])
		})

		return transformsToApply
	}

	private _applyClamps() {
		// Do these sorts and groups actually matter? 
		const clamps = this._getClamps()
		clamps.sort(sortByStatType)

		const groupedClamps = chain(clamps).groupBy('statType').entries().value()
		for (const [statType, clamps] of groupedClamps) {
			let min = Number.MIN_SAFE_INTEGER
			let max = Number.MAX_SAFE_INTEGER
			clamps.forEach((clamp) => {
				if (clamp.clampMin !== undefined) {
					min = Math.max(min, clamp.clampMin)
				}
				if (clamp.clampMax !== undefined) {
					max = Math.min(max, clamp.clampMax)
				}
			})
			this._actualStatValues[statType] = Math.clamp(this._actualStatValues[statType], min, max)
		}
	}

	private _getClamps() {
		if (this._parentStatList) {
			return this._parentStatList._getClamps().concat(this._clamps)
		}

		return this._clamps
	}

	private _hasClamps(): boolean {
		if (this._parentStatList) {
			return this._parentStatList._hasClamps() || this._clamps.length > 0
		}

		return this._clamps.length > 0
	}

	private _applyStatCompoundFormulas() {
		for (const name of Object.keys(this._statBonuses)) {
			if (StatCompoundFormulas[name]) {
				this._actualStatValues[name] = StatCompoundFormulas[name](0, this._actualStatValues[name])
			}
		}
	}

	private _applyStatCompoundFormulasToStats(statList: string[]) {
		statList.forEach((name) => {
			if (StatCompoundFormulas[name]) {
				this._actualStatValues[name] = StatCompoundFormulas[name](0, this._actualStatValues[name])
			}
		})
	}
}

function sortByStatNameThenOperator(a, b) {
	if (a.statName === b.statName) {
		return a.operatorType > b.operatorType ? 1 : -1
	} else {
		return a.statName > b.statName ? 1 : -1
	}
}

function sortByStatType(a, b) {
	return a.statType > b.statType ? 1 : -1
}

function sortByInputStatTypeThenOperator(a, b) {
	if (a.inputStatType === b.inputStatType) {
		return a.outputStatOperator > b.outputStatOperator ? 1 : -1
	} else {
		return a.inputStatType > b.inputStatType ? 1 : -1
	}
}

/** This is gross cause its a tuple, but potentially cheaper than destructuring all these params */
function sortPhasedStatTransformsByStatTypeAndOperatorType(a: PhasedStatTransform, b: PhasedStatTransform) {
	if (a[0] === b[0]) {
		return a[2] > b[2] ? 1 : -1
	} else {
		return a[0] > b[0] ? 1 : -1
	}
}

export const GlobalStatList = new EntityStatList(defaultStatAttribute)

export default EntityStatList
export { EntityStatList, StatBonus }
