import { Graphics } from "pixi.js";
import { Vector } from "sat"
import { Audio } from "../../../engine/audio"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers";
import { Renderer } from "../../../engine/graphics/renderer";
import { PLOT_TWIST_EXPLOSIONS_PER_BATCH, PLOT_TWIST_EXPLOSION_MAX_COOLDOWN, PLOT_TWIST_EXPLOSION_MIN_COOLDOWN, PLOT_TWIST_EXPLOSION_WARNING_TIME } from "../../../game-data/player-formulas"
import { callbacks_addCallback } from "../../../utils/callback-system"
import { InGameTime } from "../../../utils/time"
import { AllWeaponTypes } from "../../../weapons/weapon-types"
import { AssetManager } from "../../../web/asset-manager";
import { ExplosionGroundHazard } from "../../hazards/explosion-ground-hazard";
import AISystem from "../ai-system";
import { DeadBehaviours } from "../ai-types";
import { Enemy } from "../enemy";
import { BOSS_ENEMY_NAMES, ENEMY_NAME } from "../enemy-names";
import { maxBy } from "lodash"

const gfx = new Graphics()
gfx.beginFill(0xF00000)
gfx.drawCircle(0, 0, 1)
gfx.endFill()

export enum PostSpawnAfterDeathMod {
	HalfHealthAndSize,
	ReducedExp80,
}

export const deadBehaviours: Record<DeadBehaviours, (entity: Enemy, delta: number) => void> = {
	beACorpse(entity: Enemy, delta: number): void {
		if (entity.hasFinishedDeadBehaviour) {
			return
		}
		entity.hasFinishedDeadBehaviour = true

		spawnAfterDeath(entity, delta)
	},
	explode(entity: Enemy, delta: number): void {
		if (entity.hasFinishedDeadBehaviour) {
			return
		}
		entity.hasFinishedDeadBehaviour = true

		const damage = entity.maxHealth * entity.explosionDamageFromHealthMulti

		Audio.getInstance().playSfx('SFX_Boss_Crab_Explode')
		const pfxConfig = entity.config.states.dead.explosionPfxConfig
		ExplosionGroundHazard.pool.alloc({
			owner: entity,
			statList: entity.statList,
			damage: damage,
			targetColliderType: CollisionLayerBits.PlayerProjectile | CollisionLayerBits.EnemyProjectile,
			triggerRadius: entity.explosionRadius,
			position: entity.position,
			ignoreKnockback: true,
			explosionEffectConfigId: pfxConfig,
			weaponType: AllWeaponTypes.WorldHazard
		})
		spawnAfterDeath(entity, delta)
	},
	twistExplode(entity: Enemy, delta: number): void {
		if (entity.hasFinishedDeadBehaviour) {
			return
		}
		entity.hasFinishedDeadBehaviour = true

		const nextExplosionTime = AISystem.getInstance().twistExplosionNextCooldown
		const now = InGameTime.highResolutionTimestamp()
		let skipExplosion = false
		let resetTimer = false
		if (nextExplosionTime === -1) {
			// don't explode on the first enemy
			skipExplosion = true
			resetTimer = true
		} else {
			if (now < nextExplosionTime) {
				skipExplosion = true
			}
			if (now >= nextExplosionTime && AISystem.getInstance().twistExplosionsRemaining <= 0) {
				skipExplosion = true
				resetTimer = true
			}
		}

		if (resetTimer) {
			AISystem.getInstance().twistExplosionNextCooldown = now + Math.getRandomInt(PLOT_TWIST_EXPLOSION_MIN_COOLDOWN, PLOT_TWIST_EXPLOSION_MAX_COOLDOWN)
			AISystem.getInstance().twistExplosionsRemaining = PLOT_TWIST_EXPLOSIONS_PER_BATCH
		}
		if (skipExplosion) {
			spawnAfterDeath(entity, delta)
			return
		}

		AISystem.getInstance().twistExplosionsRemaining -= 1

		const damage = entity.maxHealth * entity.explosionDamageFromHealthMulti
		const pos = new Vector(entity.position.x, entity.position.y) // need a vector that's not the entity's position because the entity can get recycled during the delay

		const preExplodeConfig = AssetManager.getInstance().getAssetByName('explosion-warning').data
		Renderer.getInstance().addOneOffEffectByConfig(preExplodeConfig, entity.position.x, entity.position.y, entity.position.y + 600, 1, PLOT_TWIST_EXPLOSION_WARNING_TIME)

		callbacks_addCallback(entity, () => {
			Audio.getInstance().playSfx('SFX_Boss_Crab_Explode')
			const pfxConfig = entity.config.states.dead.explosionPfxConfig
			ExplosionGroundHazard.pool.alloc({
				owner: entity,
				statList: entity.statList,
				damage: damage,
				targetColliderType: CollisionLayerBits.PlayerProjectile | CollisionLayerBits.EnemyProjectile,
				triggerRadius: entity.explosionRadius,
				position: pos,
				ignoreKnockback: true,
				explosionEffectConfigId: pfxConfig,
				weaponType: AllWeaponTypes.WorldHazard
			})
		}, PLOT_TWIST_EXPLOSION_WARNING_TIME)
		
		spawnAfterDeath(entity, delta)
	}
}

function spawnAfterDeath(enemy: Enemy, delta: number) {
    if (!enemy.config.states.dead.spawnAfterDeath || !enemy.config.states.dead.spawnAfterDeath.length || enemy.recursiveRespawnDisabled || enemy.isChoreoSpawn || enemy.isBoss || enemy.isLootGoblin) {
        return
    }
    const position = enemy.position.clone()

    const singleEnemyEffectConfig = AssetManager.getInstance().getAssetByName('twice-dead-pfx').data
    const multipleEnemyEffectConfig = AssetManager.getInstance().getAssetByName('split-personality-pfx').data
    const enemyCount = maxBy(enemy.config.states.dead.spawnAfterDeath, ({ amount }) => {
        return amount
    }).amount

	//TODO: hardcoded hack because we don't "know" the mutator that caused this onDeath to occur
	let effectConfig
	if (enemyCount === 1) {
		effectConfig = singleEnemyEffectConfig
	} else if (enemyCount === 2) {
		effectConfig = multipleEnemyEffectConfig
	}
	if (effectConfig) {
		Renderer.getInstance().addOneOffEffectByConfig(effectConfig, enemy.x, enemy.y, 9999, 1, 0.7, true, true)
	}

	enemy.config.states.dead.spawnAfterDeath.forEach(({ name, amount, delay, delayPerSpawn, postSpawnMod }) => {
		// bosses don't get special behaviors for now
		if (BOSS_ENEMY_NAMES.includes(enemy.name)) {
			return
		}

		if (delayPerSpawn > 0) {
			for (let i = 0; i < amount; i++) {
				const calcDelay = delay / 1000 + i * delayPerSpawn / 1000
				callbacks_addCallback(AISystem, () => { // kinda expensive if the delayPerSpawn is real low
					spawnAndModifyEnemies(enemy.name, position, name, 1, postSpawnMod)
				}, calcDelay)
			}

		} else {
			callbacks_addCallback(AISystem, () => {
				spawnAndModifyEnemies(enemy.name, position, name, amount, postSpawnMod)
			}, delay / 1000)
		}
	})
}

function spawnAndModifyEnemies(parentName: ENEMY_NAME, parentPosition: Vector, name: ENEMY_NAME, amount: number, postSpawnMod?: PostSpawnAfterDeathMod) {
	const range = 100
	const enemies = AISystem.getInstance().spawnEnemiesInRectangle(name, amount, parentPosition.x - range, parentPosition.y - range, parentPosition.x + range, parentPosition.y + range)

	if (postSpawnMod === PostSpawnAfterDeathMod.HalfHealthAndSize) {
		enemies.forEach((enemy) => {
			enemy.maxHealth = Math.floor(enemy.maxHealth / 2)
			enemy.currentHealth = enemy.maxHealth
			enemy.gfx.setScale(0.65, 0.65)
			enemy.deathRewardRate = 0.10
		})
	}
	if (postSpawnMod === PostSpawnAfterDeathMod.ReducedExp80) {
		enemies.forEach((enemy) => {
			enemy.deathRewardRate = 0.2
		})
	}

	// hack to prevent enemies that respawns themselves from doing so infinitely
	if (name === parentName) {
		enemies.forEach((enemy) => {
			enemy.recursiveRespawnDisabled = true
		})
	}
}