import { ENEMY_NAME } from "../entities/enemies/enemy-names";
import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"
import { ShuffleBagEntryOverTime } from "./levelling";
//import { ACT_TIMER } from "../utils/time";

// TODO: Might need to change these if boss order at the end of each act changes
export const RARE_CURRENCY_DROP_CONFIG = {
	[ENEMY_NAME.CHAOS_SEER_BOSS]: {
		min: 12,
		max: 15
	},
	[ENEMY_NAME.PRISM_FLY_BOSS]: {
		min: 20,
		max: 25
	},
	[ENEMY_NAME.BRUTE_SHAMBLER]: {
		min: 20,
		max: 25
	},
	[ENEMY_NAME.DREAMER_BOSS]: {
		min: 30,
		max: 40
	},
	[ENEMY_NAME.LOOT_GOBLIN_JESTER]: {
		min: 5,
		max: 10
	},
	[ENEMY_NAME.MR_CUDDLES]: {
		min: 12,
		max: 15
	},
	[ENEMY_NAME.YETI]: {
		min: 20,
		max: 25
	},
	[ENEMY_NAME.ICE_DRAKE]: {
		min: 30,
		max: 40
	},
}

// TODO: Fix import order in game client so we can use ACT_1_TIMER in here
export const CURRENCY_DROP_SHUFFLE_BAG_ENTRIES: ShuffleBagEntryOverTime[] = [
	{
		start: 0, //ACT_TIMER.ACT_1_START_TIME,
		entries: [
			{ pickupType: GroundPickupConfigType.None, count: 63 },
			{ pickupType: GroundPickupConfigType.CommonCurrencySmall, count: 5 },
			{ pickupType: GroundPickupConfigType.CommonCurrencyLarge, count: 1 },
			{ pickupType: GroundPickupConfigType.CommonCurrencyMedium, count: 1 },

		]
	},
	{ 
		start: 301, //ACT_TIMER.ACT_2_START_TIME,
		entries: [
			{ pickupType: GroundPickupConfigType.None, count: 103 },
			{ pickupType: GroundPickupConfigType.CommonCurrencySmall, count: 5 },
			{ pickupType: GroundPickupConfigType.CommonCurrencyLarge, count: 1 },
			{ pickupType: GroundPickupConfigType.CommonCurrencyMedium, count: 1 },
		]
	},
	{
		start: 601,//ACT_TIMER.ACT_3_START_TIME,
		entries: [
			{ pickupType: GroundPickupConfigType.None, count: 143 },
			{ pickupType: GroundPickupConfigType.CommonCurrencySmall, count: 5 },
			{ pickupType: GroundPickupConfigType.CommonCurrencyLarge, count: 1 },
			{ pickupType: GroundPickupConfigType.CommonCurrencyMedium, count: 1 },
		]
	},
]

export interface Wallet {
	commonCurrency: number
	rareCurrency: number
}