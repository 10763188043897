import { ColliderComponent } from "../../engine/collision/collider-component"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import { InstancedSprite } from "../../engine/graphics/instanced-sprite"
import { Renderer } from "../../engine/graphics/renderer"
import { BorderSpriteData } from "../../pois/poi"
import EntityStatList from "../../stats/entity-stat-list"
import { VectorXY } from "../../utils/math"
import { ObjectPool, ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { AssetManager } from "../../web/asset-manager"
import { EntityType } from "../entity-interfaces"
import { Player } from "../player"
import { GroundHazard, GroundHazardParams } from "./ground-hazard"
import { TimedGroundHazard, TimedGroundHazardParams } from "./timed-ground-hazard"

const GFX_TILE_LENGTH = 120
const PROJECTILE_TIMESCALE = 0.25
const ENEMY_TIMESCALE = 0.5
const PLAYER_TIMESCALE = 0.4
const GENERIC_TIMESCALE = 0.5

export interface TemporalDistortionParams extends TimedGroundHazardParams {
    slowAmount: number
}

export class TemporalDistortionHazard extends TimedGroundHazard {
    static pool: ObjectPoolTyped<TemporalDistortionHazard, TemporalDistortionParams>

    static crystalSprites: ObjectPool
    static hourglassSprites: ObjectPool
    static glowCrystalSprites: ObjectPool
    static glowHourglassSprites: ObjectPool

    statList: EntityStatList
    numEntitiesChained: number = 0

    slowAmount: number

    private isPlayerInZone: boolean = false
    private borderSprites: BorderSpriteData[]

    constructor() {
        super()

        if (!TemporalDistortionHazard.crystalSprites) {
			const crystal = AssetManager.getInstance().getAssetByName('temporal-distortion-crystal').texture
			const crystalGlow = AssetManager.getInstance().getAssetByName('temporal-distortion-crystal-glow').texture
			const hourGlass = AssetManager.getInstance().getAssetByName('temporal-distortion-hourglass').texture
			const hourGlassGlow = AssetManager.getInstance().getAssetByName('temporal-distortion-hourglass-glow').texture

            TemporalDistortionHazard.crystalSprites = new ObjectPool(() => new InstancedSprite(crystal, 0, 0, -99_995), {}, 25, 1)
            TemporalDistortionHazard.glowCrystalSprites = new ObjectPool(() => new InstancedSprite(crystalGlow, 0, 0, -99_995), {}, 25, 1)
            TemporalDistortionHazard.hourglassSprites = new ObjectPool(() => new InstancedSprite(hourGlass, 0, 0, -99_995), {}, 25, 1)
            TemporalDistortionHazard.glowHourglassSprites = new ObjectPool(() => new InstancedSprite(hourGlassGlow, 0, 0, -99_995), {}, 25, 1)
        }

        this.borderSprites = []

        this.colliderComponent.setLayer(CollisionLayerBits.TemporalDistortion)
    }

    setDefaultValues(defaultValues: any, overrideValues?: TemporalDistortionParams): void {
        super.setDefaultValues(defaultValues, overrideValues)
        if (overrideValues) {
            this.slowAmount = overrideValues.slowAmount

            this.makeGfx()
        }
    }
	getTimescaleByEntityType(entityType: EntityType) {
		switch (entityType) {
			case EntityType.Player:
				return 1 / PLAYER_TIMESCALE
			case EntityType.Enemy:
				return 1 / ENEMY_TIMESCALE
			case EntityType.EnemyProjectile:
			case EntityType.Projectile:
				return 1 / PROJECTILE_TIMESCALE
			default:
				return 1 / GENERIC_TIMESCALE
		}
	}

    onEntityEnterTrigger(entity: ColliderComponent) {
		const timescale = this.getTimescaleByEntityType(entity.owner.entityType)
        if (entity.owner.entityType === EntityType.Player) {
            const player = entity.owner as Player
            this.isPlayerInZone = true

            this.playerInZoneChangeGlowBorderChange()
        }

        if (entity.owner.timeScale !== undefined) {
            entity.owner.timeScale /= timescale
        }
    }
    
    onEntityLeaveTrigger(entity: ColliderComponent) {
		const timescale = this.getTimescaleByEntityType(entity.owner.entityType)
        if (entity.owner.entityType === EntityType.Player) {
            const player = entity.owner as Player
            this.isPlayerInZone = false

            this.playerInZoneChangeGlowBorderChange()
        }

        if (entity.owner.timeScale !== undefined) {
            entity.owner.timeScale *= timescale
        }
    }

    cleanup() {
        super.cleanup()

        this.freeBorderGraphics()
        this.isPlayerInZone = false
    }

    returnToPool() {
        TemporalDistortionHazard.pool.free(this)
    }

    isPlayerOwned() {
        return false
    }

    private makeGfx() {
        const circumference = Math.PI * 2 * this.triggerRadius
		const numTiles = Math.floor(circumference / GFX_TILE_LENGTH)
		const radStep = (Math.PI * 2) / numTiles
		const renderer = Renderer.getInstance().bgRenderer

		for (let i = 0; i < numTiles; ++i) {
			const rotation = i * radStep
			const xPos = Math.sin(rotation) * this.triggerRadius + this.position.x
			const yPos = Math.cos(rotation) * this.triggerRadius + this.position.y

			let normalPool: ObjectPool
			let glowPool: ObjectPool

			if (i % 2) {
                normalPool = TemporalDistortionHazard.crystalSprites
                glowPool = TemporalDistortionHazard.glowCrystalSprites
            } else {
                normalPool = TemporalDistortionHazard.hourglassSprites
                glowPool = TemporalDistortionHazard.glowHourglassSprites
            }

			const sprite = normalPool.alloc() as InstancedSprite
			sprite.x = xPos
			sprite.y = yPos
			sprite.rot = -rotation

			const glowSprite = glowPool.alloc() as InstancedSprite
			glowSprite.x = xPos
			glowSprite.y = yPos
			glowSprite.rot = -rotation

			renderer.addPropToScene(sprite)

			this.borderSprites.push({
				normalSprite: sprite,
				normalPool,
				glowSprite,
				glowPool
			})
		}
    }

    private playerInZoneChangeGlowBorderChange() {
		const renderer = Renderer.getInstance().bgRenderer
		for (let i = 0; i < this.borderSprites.length; ++i) {
			const data = this.borderSprites[i]
			if (this.isPlayerInZone) {
				renderer.removeFromScene(data.normalSprite)
				renderer.addPropToScene(data.glowSprite)
			} else {
				renderer.removeFromScene(data.glowSprite)
				renderer.addPropToScene(data.normalSprite)
			}
		}
	}

    freeBorderGraphics() {
		const renderer = Renderer.getInstance().bgRenderer
		
		for (let i = 0; i < this.borderSprites.length; ++i) {
			const data = this.borderSprites[i]
			if (this.isPlayerInZone) {
				renderer.removeFromScene(data.glowSprite)
			} else {
				renderer.removeFromScene(data.normalSprite)
			}
			
			data.glowPool.free(data.glowSprite as any)
			data.normalPool.free(data.normalSprite as any)
		}

		this.borderSprites.length = 0
	}
}
