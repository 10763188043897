import { GameState } from "../engine/game-state"
import { CreepyEggGfx } from "../entities/creepy-egg-gfx"
import { timeInSeconds } from "../utils/primitive-types"
import { EventTypes } from "./event-types"
import { EventStartData, GameplayEvent } from "./gameplay-event-definitions"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"

export class CreepyEggGameplayEvent implements GameplayEvent {
    static getInstance() {
		if (!CreepyEggGameplayEvent.instance) {
			CreepyEggGameplayEvent.instance = new CreepyEggGameplayEvent()
		}

		return CreepyEggGameplayEvent.instance
	}
    static destroy() {
        CreepyEggGameplayEvent.instance = null
    }
    private static instance: CreepyEggGameplayEvent

    isEggPickupInWorld: boolean = false

    private isPlayerHoldingEgg: boolean = false
    private isEventActive: boolean
    private eggGfx: CreepyEggGfx

    constructor() {
        this.eggGfx = new CreepyEggGfx(GameState.player)
    }

    startEvent(): void {
        this.isEventActive = true
    }

    isEggDropable() {
        return this.isEventActive && !this.isEggPickupInWorld && !this.isPlayerHoldingEgg
    }

    setPlayerHoldingEgg(isHolding: boolean, victory?: boolean) {
        if (!isHolding && this.isPlayerHoldingEgg) {
            this.endEvent(victory) // (we set isPlayerHolding in here)
        } else if (isHolding && !this.isPlayerHoldingEgg) {
            this.isPlayerHoldingEgg = true
            this.eggGfx.addEgg()
        }
    }

    setStartData(data: EventStartData) {

    }

    update(delta: timeInSeconds) {
        if (this.isEventActive) {
            this.eggGfx.update(delta)
        }
    }

    // call this
    endEvent(victory: boolean) {
        this.isEggPickupInWorld = false
        this.isPlayerHoldingEgg = false
        this.isEventActive = false

        this.eggGfx.removeEgg(victory)

		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.CreepyEgg)
    }
}