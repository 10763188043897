import { Vector } from "sat"
import { CooldownDefinition } from "../../cooldowns/cooldown"
import { Audio } from "../../engine/audio"
import { GameState } from "../../engine/game-state"
import { Effect } from "../../engine/graphics/pfx/effect"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { Renderer } from "../../engine/graphics/renderer"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import EntityStatList from "../../stats/entity-stat-list"
import { timeInSeconds } from "../../utils/primitive-types"
import { AssetManager } from "../../web/asset-manager"
import { Pet, PetCollectionName } from "./pet"

export class HealingPet extends Pet {

    aoeEffectConfig: EffectConfig
	pfx: Effect

    constructor(name: PetCollectionName, parentStatList: EntityStatList, position: Vector, uncaged?: boolean) {
        super(name, parentStatList, position, uncaged)

        this.aoeEffectConfig = AssetManager.getInstance().getAssetByName('pet-healing').data
    }

    resetStatsFn(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.maxAmmo = 1
        statList._actualStatValues.cooldownInterval = 30_000
        statList._actualStatValues.reloadInterval = 50
        statList._actualStatValues.reloadAmmoIncrement = 1
    }
	
	override update(delta: timeInSeconds): void {
		super.update(delta)
		if(this.pfx){
			const player = GameState.player
			this.pfx.x = player.x
			this.pfx.y = player.y
		}
	}

    useAbility() {
		const player = GameState.player
        player.heal(2)
        this.pfx = Renderer.getInstance().addOneOffEffectByConfig(this.aoeEffectConfig, player.position.x, player.position.y, -9999, 1, 1)
        Audio.getInstance().playSfx('SFX_Player_Fully_Healed')

        this.finishedPetAbility()
    }
}