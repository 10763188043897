import { Container, Sprite } from "pixi.js"
import { Vector } from "sat"
import { GameState, getNID } from "../../engine/game-state"
import { Renderer } from "../../engine/graphics/renderer"
import EntityStatList from "../../stats/entity-stat-list"
import { StatType } from "../../stats/stat-interfaces-enums"
import { ObjectPoolTyped, PoolableObject } from "../../utils/third-party/object-pool"
import { DamageableEntityType, EntityType, IEntity } from "../entity-interfaces"
import { EnemyHazard } from "./enemy-hazard"
import { Audio } from "../../engine/audio"
import { timeInMilliseconds } from "../../utils/primitive-types"

const DEFAULT_WARNING_SCALE_X = 0.01
const DEFAULT_WARNING_SCALE_Y = 0.015

export interface EnemyHazardWarningParams {
    targetX: number
    targetY: number

    warningTime: number
	enemyStatList: EntityStatList

}

export class EnemyHazardWarning implements PoolableObject, IEntity {
    
    static pool: ObjectPoolTyped<EnemyHazardWarning, EnemyHazardWarningParams>

    container: Container

	warningRadius: number

    enemyStatList: EntityStatList

    position: Vector = new Vector()

    aliveTime: number = 0
    warningTime: number

    nid: number
    entityType: EntityType = EntityType.GroundHazard
	timeScale: number = 1

    constructor() {
        this.nid = getNID(this)
        this.makeGraphics()
    }

    setDefaultValues(defaultValues: any, overrideValues?: EnemyHazardWarningParams) {
        if(overrideValues) {
            this.position.x = overrideValues.targetX
            this.position.y = overrideValues.targetY

            this.warningTime = overrideValues.warningTime
			this.enemyStatList = overrideValues.enemyStatList

            this.container.position.x = this.position.x
            this.container.position.y = this.position.y
			this.warningRadius = this.enemyStatList.getStat('attackSize')
            this.container.scale.x = DEFAULT_WARNING_SCALE_X * this.warningRadius
            this.container.scale.y = DEFAULT_WARNING_SCALE_Y * this.warningRadius

            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.container)

            GameState.addEntity(this)
        }
    }
    
    cleanup() {
        this.enemyStatList = null
        this.position.x = 0
        this.position.y = 0
        this.aliveTime = 0

        Renderer.getInstance().mgRenderer.removeFromScene(this.container)

        GameState.removeEntity(this)
    }

    update(delta: number, now?: timeInMilliseconds) {
        this.aliveTime += delta
        if(this.aliveTime >= this.warningTime) {
            this.createGroundHazard()
            return
        }
    }

    createGroundHazard() {
        // make a ground hazard
        EnemyHazard.pool.alloc({
            statList: this.enemyStatList,
			damageTargetType: DamageableEntityType.Player,
            lifeTime: this.enemyStatList.getStat(StatType.skillDuration),
            triggerRadius: this.enemyStatList.getStat(StatType.attackSize),
            position: this.position
        })

        EnemyHazardWarning.pool.free(this)

        Audio.getInstance().playSfx('UI_Click_Minor')
    }

    makeGraphics() {
        this.container = new Container()
        this.container['update'] = () => {}

        //const sprite = Sprite.from('prism-aoe')
		const sprite = Sprite.from('prism-aoe')
        sprite.anchor.x = 0.5
        sprite.anchor.y = 0.5

        sprite.scale.x = 1
        sprite.scale.y = 1

        this.container.addChild(sprite)
    }
}