import { timeInSeconds } from '../../utils/primitive-types'
import { UI } from '../ui'
import logger from '../../utils/client-logger'

interface GenericYesNoState {
	title: string
	description: string | string[]
	replacements: any
	yesButtonText: string
	noButtonText: string
	yesCallback: () => {} | void
	noCallback: () => {} | void
	yesButtonColor: string
	noButtonColor: string
	disableYesForSeconds: timeInSeconds
	disableNoForSeconds: timeInSeconds
	promptVisible?: boolean | string
	keystrokeHandler?: EventListenerOrEventListenerObject
	panelIdentifier: string
}

function enterAndEscapeBindings(state: GenericYesNoState, event: KeyboardEvent) {
	if (!state.promptVisible) {
		logger.error('Yes/No modal: Enter or escape binding was triggered, but modal was not visible. This indicates invalid keybind state and should be fixed.', event)
		return
	}
	if (event.keyCode === 13) {
		// enter
		console.log('Yes/No modal: enter, saying yes!')
		event.stopPropagation()
		event.preventDefault()
		UI.getInstance().emitAction('genericTwoButtonPrompt/callYesCallbackAndCloseMenu')
	} else if (event.keyCode === 27) {
		// escape
		console.log('Yes/No modal: escape, saying no.')
		event.stopPropagation()
		event.preventDefault()
		UI.getInstance().emitAction('genericTwoButtonPrompt/callNoCallbackAndCloseMenu')
	}
}
export const showGenericYesNoUI = (
	title: string,
	description: string | string[],
	yesButtonText: string,
	noButtonText: string,
	yesCallback: () => void,
	noCallback?: () => void,
	yesButtonColor = 'bright-green',
	noButtonColor = 'blue',
	disableYesForSeconds = 0,
	disableNoForSeconds = 0,
	panelIdentifier = 'genericTwoButtonPrompt',
	replacements?: any,
) => {
	const params: GenericYesNoState = {
		title,
		description,
		yesButtonText,
		noButtonText,
		yesCallback,
		noCallback,
		yesButtonColor,
		noButtonColor,
		disableYesForSeconds,
		disableNoForSeconds,
		panelIdentifier,
		replacements,
	}
	UI.getInstance().emitAction('genericTwoButtonPrompt/showMenu', params)
}

const store = {
	namespaced: true,
	state: {
		title: '',
		description: ['default_two_btn_prompt.example_description_1', 'default_two_btn_prompt.example_description_2'],
		replacements: null,
		promptVisible: false,
		noButtonText: 'default_two_btn_prompt.no_default',
		yesButtonText: 'default_two_btn_prompt.yes_default',
		noButtonColor: 'destroy',
		yesButtonColor: 'default',
		noCallback: () => {
			console.log(`default no handler`)
		},
		yesCallback: () => {
			console.log(`default yes handler`)
		},
		disableNoForSeconds: 0,
		disableYesForSeconds: 0,
		keystrokeHandler: undefined,
		panelIdentifier: 'genericTwoButtonPrompt',
	} as GenericYesNoState,
	getters: {
		getTitle(state: GenericYesNoState) {
			return state.title
		},
		getDescription(state: GenericYesNoState) {
			return state.description
		},
		getReplacements(state: GenericYesNoState) {
			return state.replacements
		},
		getYesNoPromptVisible(state: GenericYesNoState) {
			return state.promptVisible
		},
		getNoButtonText(state: GenericYesNoState) {
			return state.noButtonText
		},
		getYesButtonText(state: GenericYesNoState) {
			return state.yesButtonText
		},
		getNoButtonColor(state: GenericYesNoState) {
			return state.noButtonColor
		},
		getYesButtonColor(state: GenericYesNoState) {
			return state.yesButtonColor
		},
		noDisabled(state: GenericYesNoState) {
			if (state.disableNoForSeconds > 0) {
				setTimeout(() => {
					state.disableNoForSeconds = 0
				}, state.disableNoForSeconds * 1000)
			}

			return state.disableNoForSeconds > 0
		},
		yesDisabled(state: GenericYesNoState) {
			if (state.disableYesForSeconds > 0) {
				setTimeout(() => {
					state.disableYesForSeconds = 0
				}, state.disableYesForSeconds * 1000)
			}

			return state.disableYesForSeconds > 0
		},
		getPanelIdentifier(state: any) {
			return state.panelIdentifier
		},
	},
	mutations: {
		registerKeystrokeHandlers(state: GenericYesNoState) {
			if (state.keystrokeHandler) {
				document.removeEventListener('keydown', state.keystrokeHandler)
			}
			const closure = enterAndEscapeBindings.bind(this, state)
			document.addEventListener('keydown', closure)
			state.keystrokeHandler = closure
		},
		unregisterKeystrokeHandlers(state: GenericYesNoState) {
			if (state.keystrokeHandler) {
				document.removeEventListener('keydown', state.keystrokeHandler)
			}
		},
		setInitialMenuState(state: GenericYesNoState, param: GenericYesNoState) {
			state.title = param.title
			state.description = typeof param.description === 'string' ? [param.description] : param.description
			state.noButtonText = param.noButtonText
			state.yesButtonText = param.yesButtonText
			state.noCallback = param.noCallback
			state.yesCallback = param.yesCallback
			state.disableNoForSeconds = param.disableNoForSeconds
			state.disableYesForSeconds = param.disableYesForSeconds
			state.replacements = param.replacements ?? null

			if (state.promptVisible !== true) {
				state.promptVisible = 'yesno'
			}
			if (param.yesButtonColor) {
				state.yesButtonColor = param.yesButtonColor
			} else {
				state.yesButtonColor = 'default'
			}
			if (param.noButtonColor) {
				state.noButtonColor = param.noButtonColor
			} else {
				state.noButtonColor = 'destroy'
			}
		},
		closeActiveYesNoPanel(state: GenericYesNoState, screenId: string) {
			state.promptVisible = false
			if (state.keystrokeHandler) {
				document.removeEventListener('keydown', state.keystrokeHandler)
			}
		},
	},
	actions: {
		showMenu({ state, commit }: { state: GenericYesNoState; commit: any }, param: GenericYesNoState) {
			commit('setInitialMenuState', param)
			commit('registerKeystrokeHandlers')
		},
		callYesCallbackAndCloseMenu({ state, commit }: { state: GenericYesNoState; commit: any }) {
			state.promptVisible = false
			commit('unregisterKeystrokeHandlers')
			// callback after promptVisible=false, in case callback wants to show the prompt again
			if (state.yesCallback) {
				state.yesCallback()
			}
		},
		callNoCallbackAndCloseMenu({ state, commit }: { state: GenericYesNoState; commit: any }) {
			state.promptVisible = false
			commit('unregisterKeystrokeHandlers')
			// callback after promptVisible=false, in case callback wants to show the prompt again
			if (state.noCallback) {
				state.noCallback()
			}
		},
		closeActiveYesNoPanel({ state, commit }: { state: GenericYesNoState; commit: any }) {
			state.promptVisible = false
			commit('unregisterKeystrokeHandlers')
			// when user presses X, I'm calling callNoCallbackAndCloseMenu instead of closeActiveYesNoPanel,
			//  however, could just call callback here instead of doing that? dunno
		},
	},
}

export const GenericTwoButtonPromptStore = typeof store

export const genericTwoButtonPromptStore = () => {
	return store
}
