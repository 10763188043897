import { AdvancedBloomFilter, AsciiFilter, BevelFilter, BloomFilter, ColorMapFilter, ColorOverlayFilter, CRTFilter, DropShadowFilter, EmbossFilter, GlowFilter, GodrayFilter, MultiColorReplaceFilter, OldFilmFilter, OutlineFilter, RGBSplitFilter, TwistFilter } from 'pixi-filters'
import { simpleAnimation_addPropertyAnimation } from '../../utils/simple-animation-system'

export function filterFromString(filterName: string, filterParams: string) {
	let params: any = {}
	if (filterParams && filterParams !== '') {
		params = JSON.parse(filterParams)
	}

	//see: https://pixijs.io/pixi-filters/docs/
	switch (filterName) {
		case 'ascii': {
			return new AsciiFilter()
		}
		case 'advanced-bloom': {
			//const options = { threshold: 0.5, bloomScale: 1, brightness: 1, blur: 8, quality: 4, pixelSize: 1, resolution: PIXI.settings.FILTER_RESOLUTION }
			//DevToolsManager.getInstance().setDebugObject({ filter: params })
			return new AdvancedBloomFilter(params)
		}
		case 'bevel': {
			return new BevelFilter()
		}
		case 'bloom': {
			return new BloomFilter(params.blur, params.quality, params.resolution, params.kernelSize)
		}
		case 'blur':
			return new PIXI.filters.BlurFilter(params.strength, params.quality, params.resolution, params.kernelSize)
		case 'color-map': {
			// I don't know what the color map filter should look like :cry:
			const c = new ColorMapFilter()
			return c
		}
		case 'color-matrix': {
			const colorMatrix = new PIXI.filters.ColorMatrixFilter()
			const matrix = [0.5, 0, 0, 0, 0, 0, 1.5, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 1, 0]
			colorMatrix._loadMatrix(matrix, false)
			return colorMatrix
		}
		case 'color-overlay': {
			const filter = new ColorOverlayFilter()
			return filter
		}
		case 'crt': {
			const filter = new CRTFilter()
			return filter
		}
		case 'displacement': {
			const displacementSprite = PIXI.Sprite.from(params.image ?? 'displacement_map')
			const filter = new PIXI.filters.DisplacementFilter(displacementSprite, params.scale)
			simpleAnimation_addPropertyAnimation(filter.scale, 'x', (t) => Math.sin(t) * 50)
			simpleAnimation_addPropertyAnimation(filter.scale, 'y', (t) => Math.sin(t) * 50)
			return filter
		}
		case 'ghost': {
			const filter = new OldFilmFilter({ sepia: 0.01, vignetting: -1, noise: 0.0, scratch: 0, vignettingBlur: 0.13 })
			return filter
		}
		case 'drop-shadow': {
			return new DropShadowFilter()
		}
		case 'emboss': {
			const filter = new EmbossFilter(params.strength)
			return filter
		}
		case 'glow': {
			const filter = new GlowFilter()
			if (params.color) {
				// Handling string representing hex colors
				if (typeof params.color === 'string'){
					const color = parseInt(params.color)
					if (!Number.isNaN(color)) {
						filter.color = color
					}	
				} else if (typeof params.color === 'number'){
					filter.color = params.color
				}
			}
			if (params.outerStrength) {
				filter.outerStrength = params.outerStrength
			}
			return filter
		}
		case 'godray': {
			const filter = new GodrayFilter()
			return filter
		}
		case 'multi-color-replace': {
			return new MultiColorReplaceFilter([
				[0xff0000, 0xc0a832],
				[0x00ff00, 0xc0a832],
			])
		}
		case 'noise':
			return new PIXI.filters.NoiseFilter(params.noise, params.seed)
		case 'outline':
			return new OutlineFilter()
		case 'rgb-splitter': {
			const filter = new RGBSplitFilter()
			return filter
		}
		case 'twist': {
			const filter = new TwistFilter()
			filter.offset.x = window.innerWidth * 0.5
			filter.offset.y = window.innerHeight * 0.5
			return filter
		}
		default:
			break
	}
}
