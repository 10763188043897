<template>
	<EmbellishmentContainer>
		<template #embellishment-content>
			<div class="window-mode-container container-formatting">
				<div class="label" :lang="this.$i18n.locale">{{ $t('settings.window_mode') }}</div>
				<div class="mode-selector">
					<Pagination :disablePips="true" :title-string="selectCategoryString" @on-click-back-fn="previousCategory" @on-click-forward-fn="nextCategory"></Pagination>
				</div>
			</div>
		</template>
	</EmbellishmentContainer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmbellishmentContainer from '../embellishment-container.vue'
import Toggle from '../form-inputs/toggle.vue'
import Pagination from '../pagination/pagination.vue'

import { paginationMixins } from '../mixins/Pagination-mixin'
import { th } from 'date-fns/locale'

export default {
	name: 'WindowModeModule',
	components: {
		EmbellishmentContainer,
		Pagination,
	},
	data() {
		return {
			categories: [
				{ id: 'windowed', name: 'settings.category_windowed' },
				{ id: 'fullscreen', name: 'settings.category_fullscreen' },
				{ id: 'windowedFullscreen', name: 'settings.category_windowed_fullscreen'}
			],
			
		}
	},
	props: {},

	computed: {
		// ...mapGetters('ui', ),
		...mapState('settings', ['fullscreen', 'currentSettings']),
		defaultSelectedCategory: {
			get() {
				return this.currentSettings.windowMode
			}, 
			set(value){
				this.toggleWindowMode(value)
				this.changeWindowModeString(value)
			}
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('settings', ['checkFullscreen', 'toggleWindowMode', 'changeWindowModeString']),
	},
	mixins: [paginationMixins],
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.window-mode-container {
	display: flex;
	align-items: center;
	width: 660px;
	.label {
		color: @offWhite;
		font-size: 30px;
		width: 258px;
		padding-left: 50px;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
		}
		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
	.mode-selector {
		display: flex;
		width: 60%;
		height: 40px;
	}
}
</style>
