import { Howl, Howler, HowlOptions } from 'howler'
// this can be wall clock time
import { flatMap, entries, keys, map, sample, fromPairs } from 'lodash'
import { percentage } from '../utils/primitive-types'
import { UI } from '../ui/ui'
import { debugConfig } from '../utils/debug-config'
import sfxData from '../../assets/sounds/data/SFX.json'
import bgmData from '../../assets/sounds/data/BGM.json'

export function realTimeHighResolutionTimestamp() {
	return performance.now()
}

interface AudioParams {
	volume?: number
	rate?: number
}
class PlayLimiter {
	// We don't use sfx name or timestamp for now, but may be interested in it later if it becomes necessary to throttle sfx by group
	soundsInSlice: number
	timeBucketSize: number
	queueLimit: number
	lastTimeWindow: number
	lastStrictTimeWindow: number
	strictlyLimitedSounds: Set<string>
	activeLimitedSounds: Set<string>
	strictTimeBucketSize: number

	perFrameSounds: Set<string>

	constructor(timeBucketSize: number, queueLimit: number, strictlyLimitedSounds: string[], strictTimeBucketSize: number) {
		this.lastTimeWindow = realTimeHighResolutionTimestamp()
		this.lastStrictTimeWindow = this.lastTimeWindow
		this.timeBucketSize = timeBucketSize
		this.queueLimit = queueLimit
		this.soundsInSlice = 0
		this.strictTimeBucketSize = strictTimeBucketSize
		this.strictlyLimitedSounds = new Set(strictlyLimitedSounds)
		this.activeLimitedSounds = new Set<string>()
		this.perFrameSounds = new Set<string>()
	}

	canPlay(sfxName: string): boolean {
		const now = realTimeHighResolutionTimestamp()
		// advance the time bucket
		if (now - this.lastTimeWindow >= this.timeBucketSize) {
			this.soundsInSlice = 0
			this.lastTimeWindow = now
		}
		// advance last time bucket, clear active limited sounds
		if (now - this.lastStrictTimeWindow >= this.strictTimeBucketSize) {
			this.activeLimitedSounds.clear()
			this.lastStrictTimeWindow = now
		}

		if (this.perFrameSounds.has(sfxName)) {
			return false
		}

		if (this.strictlyLimitedSounds.has(sfxName) && this.activeLimitedSounds.has(sfxName)) {
			return false
		} else if (this.strictlyLimitedSounds.has(sfxName) && !this.activeLimitedSounds.has(sfxName)) {
			this.activeLimitedSounds.add(sfxName)
			return true
		} else if (this.soundsInSlice === this.timeBucketSize) {
			return false
		} else {
			this.soundsInSlice++
			return true
		}
	}
}

/* Currently, we require SFX data directly from the source tree. This may become unwieldy but
is presently handy. The type system assumes that the arrays we use for sprite offsets are arrays
but the howler types want a [number, number, boolean?] tuple. tuple asserts that the sprite array
is a tuple, and toSpriteTuple asserts that the tuple types are correct.

A nice side effect of directly requiring the SFX file is that the type system should catch malformed
SFX.
*/


function tuple<Args extends any[]>(...args: Args): Args {
	return args
}

function toSpriteTuple(arr: any[]): [number, number, boolean?] {
	if (arr.length === 2) {
		return tuple(arr[0], arr[1])
	} else {
		return tuple(arr[0], arr[1], arr[2])
	}
}

function loadBgm(): HowlOptions {
	const placeHolderFile = 'sounds/audio/BGM.mp3'
	const bgm = entries(bgmData)
	const soundSprites = map(bgm, ([bgmName, bgmParams]) => {
		if ('loop' in bgmParams) {
			return toSpriteTuple([bgmName, [...bgmParams.offset, true]])
		} else {
			return toSpriteTuple([bgmName, bgmParams.offset])
		}
	})

	const spriteMap = fromPairs(soundSprites)
	return {
		src: [placeHolderFile],
		sprite: spriteMap as { [name: string]: [number, number] | [number, number, boolean] },
		onloaderror: (soundId, error) => {
			console.error(`Error loading background music ${soundId}:`, error)
		},
		onload: (soundId) => {
			console.debug(`Background music ${soundId} loaded`)
		},
		onend: (soundId) => {
			const audio = Audio.getInstance()
			if (audio.isFanfare(soundId)) {
				audio.fadeInBgm()
				audio.endFanfare(soundId)
			}
		},
	}
}

function loadSfx(): HowlOptions {
	const placeHolderFile = 'sounds/audio/SFX.mp3'

	const soundSprites = flatMap(sfxData, (sound) => entries(sound.effectNames))
	const conformedSoundSprites = map(soundSprites, ([spriteName, spriteParams]) => [spriteName, toSpriteTuple(spriteParams)])

	const spriteObj = {}
	const spriteEntries = entries(conformedSoundSprites)
	for (const [name, spriteTuple] of conformedSoundSprites) {
		spriteObj[name as string] = spriteTuple
	}

	return {
		src: [placeHolderFile],
		sprite: spriteObj,
		volume: 0,
		onloaderror: (soundId, error) => {
			console.error(`Error loading sound ${soundId}:`, error)
		},
		onload: (soundId) => {
			console.debug(`sound ${soundId} loaded`)
		},
	}
}

// This defines the map of enemy sound effects for an enemy definition.
export interface EnemySoundEffects {
	attack: string
}

// The parameters for each sound effect from an SFX json file.
interface SoundEffect {
	volume: number
	rate: number
	volumeInterval?: [number, number]
	rateInterval?: [number, number]
}

interface BgmState {
	id: number
	bgmSprite: string
	volume: number
}

type FanfareHandle = number
export class Audio {
	static getInstance(headless?: boolean): Audio {
		if (!Audio.instance) {
			Audio.instance = new Audio(headless)
		}

		return Audio.instance
	}
	static shutdown() {
		Audio.instance.bgmHowl.unload()
		Audio.instance.sfxHowl.unload()
		Howler.unload()
		Audio.instance = null
	}
	private static instance: Audio
	private sfxHowl: Howl
	private bgmHowl: Howl
	private currentBgm: BgmState = { id: undefined, volume: 0.0, bgmSprite: '' }
	private fanFareHandles: Set<number>
	private muted: boolean = false
	private sfxLocked: boolean

	private playLimiter: PlayLimiter

	private masterBgmVolume: percentage
	private masterSFXVolume: percentage

	private constructor(headless?: boolean) {
		console.debug('Initializing audio module...')
		const sfxOptions = loadSfx()
		this.sfxHowl = new Howl(sfxOptions)
		this.sfxLocked = true
		this.sfxHowl.on('unlock', () => {
			this.sfxLocked = false
		})
		const bgmOptions = loadBgm()
		this.bgmHowl = new Howl(bgmOptions)
		this.bgmHowl.volume(0.0)

		if (headless !== true) {
			const uiInst = UI.getInstance()
			if (uiInst) {
				this.masterBgmVolume = uiInst.store.getters['settings/getCurrentBGMVolume'] / 100
				this.masterSFXVolume = uiInst.store.getters['settings/getCurrentSFXVolume'] / 100
			} else {
				this.masterBgmVolume = 1.0
				this.masterSFXVolume = 1.0
			}
		} else {
			this.masterBgmVolume = 1.0
			this.masterSFXVolume = 1.0
		}

		this.bgmHowl.on('unlock', () => {
			this.bgmHowl.fade(0.0, this.masterBgmVolume, 500)
		})
		/** Handles for currently playing fanfares, look up to fade bgm back in after a fanfare plays */
		this.fanFareHandles = new Set<number>()

		/** Rate limiter for sfx requests */
		this.playLimiter = new PlayLimiter(30, 6, ['SFX_Elemental_Thunder', 'SFX_Elemental_Poison', 'SFX_Elemental_Ice', 'SFX_Elemental_Fire', 'SFX_Elemental_Blood', 'SFX_Item_Drop_Default', 'SFX_Item_Drop_Epic', 'SFX_Item_Drop_Legendary', 'SFX_Item_Drop_Astronomical', 'SFX_Boss_Crab_Explode', 'SFX_Acid_Bottle', 'SFX_Doom_Explosion'], 500)
	}

	isFanfare(soundHandle: number): boolean {
		return this.fanFareHandles.has(soundHandle)
	}

	endFanfare(soundHandle: number) {
		this.fanFareHandles.delete(soundHandle)
	}

	soundVolume(soundEffect: SoundEffect): number {
		if (soundEffect.volumeInterval === undefined) {
			return soundEffect.volume
		} else {
			const [minVolume, maxVolume] = soundEffect.volumeInterval
			return Math.random() * (maxVolume - minVolume) + minVolume
		}
	}

	muteAll(muteState: boolean) {
		this.sfxHowl.mute(muteState)
		this.bgmHowl.mute(muteState)
		this.muted = muteState
	}

	toggleMute() {
		this.muteAll(!this.muted)
		//logger.debug(`Toggling mute, muted: ${this.muted}`)
	}

	getMasterBGMVolume() {
		return this.masterBgmVolume
	}

	setMasterBGMVolume(volume: percentage) {
		//update current bgm
		if (this.currentBgm.id) {
			const oldVolume = bgmData[this.currentBgm.bgmSprite].volume * this.masterBgmVolume
			const newVolume = bgmData[this.currentBgm.bgmSprite].volume * volume
			this.bgmHowl.fade(oldVolume, newVolume, 200, this.currentBgm.id)
		}

		this.masterBgmVolume = volume
	}

	getMasterSFXVolume() {
		return this.masterSFXVolume
	}

	setMasterSFXVolume(volume: percentage) {
		this.masterSFXVolume = volume
	}

	soundRate(soundEffect: SoundEffect): number {
		if (soundEffect.rateInterval === undefined) {
			return soundEffect.rate
		} else {
			const [minRate, maxRate] = soundEffect.rateInterval
			return Math.random() * (maxRate - minRate) + minRate
		}
	}

	/*
	We'll have to update sounds on the fly in the future, play returns an id that functions as
	a handle for that sound in howler. To control that sound we can define new functions that
	delegate calls to howler.
	*/
	playSfx(soundId: string, params: AudioParams = {}): number {
		if (debugConfig.audio.disableAudio) {
			return
		}

		const canPlay = this.playLimiter.canPlay(soundId)
		if (!canPlay) {
			return
		}

		if (debugConfig.audio.disableAudio && this.sfxLocked) {
			return
		}

		const soundData = sfxData[soundId]
		if (soundData) {
			const sounds = keys(soundData.effectNames)
			let volume = this.soundVolume(soundData) * this.masterSFXVolume
			if (params.volume) {
				volume *= params.volume
			}
			let rate = this.soundRate(soundData)

			if (params.rate) {
				rate *= params.rate
			}
			const soundHandle = this.sfxHowl.play(sample(sounds))
			this.sfxHowl.volume(volume, soundHandle)
			this.sfxHowl.rate(rate, soundHandle)

			this.playLimiter.perFrameSounds.add(soundId)

			return soundHandle
		} else {
			console.warn('no sfx found for ' + soundId)
		}
	}

	stopSfx() {
		this.sfxHowl.stop()
	}

	playBgm(bgmId: string): number {
		if (debugConfig.audio.disableAudio) {
			return
		}

		console.log(bgmId)

		if (this.currentBgm.id) {
			this.bgmHowl.fade(this.currentBgm.volume * this.masterBgmVolume, 0, 2000, this.currentBgm.id)
		}

		const bgmHandle = this.bgmHowl.play(bgmId)
		const volume = bgmData[bgmId].volume
		this.bgmHowl.fade(0, volume * this.masterBgmVolume, 2000, bgmHandle)
		this.currentBgm = { id: bgmHandle, volume, bgmSprite: bgmId }


		return bgmHandle
	}

	stopBgm() {
		this.bgmHowl.stop()
	}

	fadeInBgm(): void {
		this.bgmHowl.fade(0, this.currentBgm.volume, 2000, this.currentBgm.id)
	}

	playFanfare(bgmId: string): number {
		if (debugConfig.audio.disableAudio) {
			return
		}

		if (this.currentBgm.id) {
			this.bgmHowl.fade(this.currentBgm.volume, 0, 2000, this.currentBgm.id)
		}

		const fanFareHandle = this.bgmHowl.play(bgmId)
		const volume = bgmData[bgmId].volume * this.masterBgmVolume
		this.bgmHowl.fade(0, volume, 2000, fanFareHandle)
		this.fanFareHandles.add(fanFareHandle)
	}

	update() {
		this.playLimiter.perFrameSounds.clear()
	}
}
