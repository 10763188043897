import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const wisp2: VariantAI = {
    extends: ENEMY_NAME.WISP_1,
    name: ENEMY_NAME.WISP_2,
    appearance: {
        asset: SpineDataName.WISP_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
}