<template>
	<div class="items">
		<div class="square-items-grid">
			<div v-if="pipCounter > 1" class="pagination-container">
				<Pagination :pip-count="pipCounter" :selected-pip="currentItemsPage" @on-click-back-fn="decreaseItemPage" @on-click-forward-fn="increaseItemPage"></Pagination>
			</div>
			<div v-if="iconStyle !== 'player-icon'">
				<TransitionGroup name="list" tag="div" :class="'items-list'" v-if="this.items.length >= 1">
					<ItemUpdated v-for="i in itemsPagination" :key="i" :item="i" :disable-circle="true" :small-icons="true" :enable-glow="false" :selected-item="false" :on-click-prop="() => {}" :disable-book="false" :component="iconStyle" :show-book-background="true" :tooltip-enabled="true"></ItemUpdated>
				</TransitionGroup>
			</div>
			<div v-if="iconStyle == 'player-icon'">
				<div class="icons-fixed-container">
					<div class="icons-resized-container">
						<div v-for="i in itemsPagination" :key="i" class="icon-row">
							<SquareItem :item="{ icon: i }" :playerIcon="i" @click.left="onClickPropFn(i)" :selected="onSelectedPropFn(i)" :iconColor="iconColor" :disabled="false" :faded="false" :small-icons="true" :goldtrimEnabled="false" selected-colour="'#ffe35f'" :goldHighlight="false"></SquareItem>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Pagination from '../pagination/pagination.vue'
import ItemUpdated from '../item/items-updated.vue'
import SquareItem from '../item/square-items.vue'

export default {
	name: 'ItemPagination',
	components: {
		Pagination,
		ItemUpdated,
		SquareItem,
	},
	data() {
		return {
			itemPerPage: 21,
			currentItemsPage: 1,
		}
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using :items default value. Ensure you passing <TwistsContainer /> a twists array')
				return []
			},
		},
		iconStyle: {
			type: String,
			required: false,
		},
    iconColor: {
      type: String,
      required: false,
      default: 'orange',
    },
    onClickPropFn: {
      type: Function,
      required: false,
      default: (i) => {},
    },
    onSelectedPropFn: {
      type: Function,
      required: false,
      default: (i) => {},
    },
	},
	computed: {
		itemsPagination() {
			return this.items.slice((this.currentItemsPage - 1) * this.itemPerPage, this.currentItemsPage * this.itemPerPage)
		},
		pipCounter() {
			return Math.ceil(this.items.length / this.itemPerPage)
		},
	},
	methods: {
		increaseItemPage() {
			if (this.currentItemsPage < this.pipCounter) {
				this.currentItemsPage++
			} else {
				this.currentUpgradesPage = 1
			}
		},
		decreaseItemPage() {
			if (this.currentItemsPage > 1) {
				this.currentItemsPage--
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.items {
	width: 480px;
	
	height: 100%; // Make sure the check the book report that you haven't had any regressions
	position: absolute;
	// right: 0px;

	&.square-items {
		width: 615px;
		.square-items-grid{
			height: calc(200px + 62px); // 135px is the height of the items grid row, 62 is the height of the pagination container + padding
		}
	}

	.items-grid,
	.upgrades-grid,
	.square-items-grid {
		border-radius: 7px;
		background-color: @darkBlue;
		widows: 100%;
		height: calc(135px + 62px); // 135px is the height of the items grid row, 62 is the height of the pagination container + padding
	}

	.pagination-container {
		padding: 10px 10px;
	}
	.upgrade-items-list,
	.items-list {
		height: 135px;
		overflow-y: auto;
		display: flex;
		place-content: flex-start; // this removed the extra height for each item when only 1 or 2 rows out of 3 are present
		flex-wrap: wrap;
		overflow: hidden;
		position: relative;
		left: 9px;
		.item-wrapper {
			display: flex;
			//flex-direction: column;
			height: 171px;
			margin: 0px 0px;
			:deep(.square-item) {
				margin: 0 15px;
				//transform: scale(0.5);
				//transform-origin: top left;
			}
		}
	}
	.icons-fixed-container {
		transform-origin: center;
		width: 678px;
		height: 160px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		.icons-resized-container {
			width: 1329px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			transform: scale(0.47);
			transform-origin: top left;
			position: absolute;
		}
	}

	.list-move,
	.list-enter-active,
	.list-leave-active {
		transition: all 0.25s ease;
	}

	.list-enter-active {
		transition-delay: 0.25s;
	}

	.list-enter-from {
		opacity: 0;
		transform: translateX(30px);
	}
	.list-leave-to {
		opacity: 0;
		transform: translateX(-30px);
	}
}
</style>
