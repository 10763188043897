// these are best-guesses at 1920 res
//  people with 4k monitors will get non-ideal pool sizes, but hopefully those are better computers (not low-spec)

// map of asset to recommended pool size
// these numbers generated by running around world and calling printObjectPoolSizes
//TODO: delete a bunch of this stuff
export const spinePoolConfig = {
	['beach/outpost-campfire,default']: 1,
	['beach/outpost-torches,default']: 1,
	['beach/outpost-bubba,default']: 1,
	['beach/poi-02-lighthouse,default']: 1,
	['beach/small-rare-01,default']: 3,
	['beach/small-rare-02,default']: 3,
	['beach/large-uncommon-01-var01,default']: 1,
	['beach/large-uncommon-01-var02,default']: 1,
	['beach/large-uncommon-01-var03,default']: 1,
	['beach/large-rare-01-var01,default']: 3,
	['beach/large-rare-01-var02,default']: 1,
	['beach/large-rare-01-var03,default']: 1,
	['beach/beach-alter,default']: 1,
	['forest/outpost-campfire,default']: 1,
	['forest/outpost-smoke,default']: 1,
	['forest/outpost-lanterns,default']: 1,
	['forest/outpost-bubba,default']: 1,
	['forest/outpost-bat,default']: 1,
	['forest/poi-01-cocoon-01,default']: 1,
	['forest/poi-01-cocoon-02,default']: 1,
	['forest/poi-01-cocoon-03,default']: 1,
	['forest/boss-arena-glow-particle,default']: 1,
	['forest/boss-arena-tree-animations,default']: 1,
	['forest/boss-arena-mushroom-a,default']: 1,
	['forest/boss-arena-mushroom-b,default']: 1,
	['forest/boss-arena-mushroom-c,default']: 1,
	['forest/small-rare-01,default']: 2,
	['forest/large-rare-01-var01,default']: 1,
	['forest/large-rare-01-var02,default']: 1,
	['forest/large-rare-01-var03,default']: 1,
	['forest/large-rare-02-var01,default']: 1,
	['forest/large-rare-02-var02,default']: 1,
	['forest/forest-alter,default']: 1,
	['fungi/boss-arena-spores,default']: 1,
	['fungi/boss-arena-spores-02,default']: 1,
	['fungi/outpost-campfire,default']: 1,
	['fungi/outpost-smoke,default']: 1,
	['fungi/outpost-lanterns,default']: 1,
	['fungi/outpost-bubba,default']: 1,
	['fungi/fungi-swamp,default']: 1,
	['fungi/small-rare-01,default']: 1,
	['fungi/large-rare-01-var01,default']: 1,
	['fungi/large-rare-01-var02,default']: 1,
	['fungi/large-rare-01-var03,default']: 1,
	['fungi/large-rare-02-var02,default']: 1,
	['fungi/fungi-alter,default']: 1,
	['prism/boss-arena-glints,default']: 1,
	['prism/boss-arena-lanterns,default']: 1,
	['prism/poi-02-crystal-glow,default']: 1,
	['prism/poi-02-glints,default']: 1,
	['prism/poi-02-lanterns,default']: 1,
	['prism/outpost-base,default']: 1,
	['prism/outpost-lanterns,default']: 1,
	['prism/outpost-fence,default']: 1,
	['prism/outpost-props,default']: 1,
	['prism/outpost-bubba,default']: 1,
	['prism/small-rare-01,default']: 2,
	['prism/small-rare-02,default']: 1,
	['prism/small-rare-03,default']: 1,
	['prism/large-uncommon-01-var01,default']: 1,
	['prism/large-uncommon-01-var02,default']: 1,
	['prism/large-uncommon-01-var03,default']: 1,
	['prism/large-rare-01-var01,default']: 1,
	['prism/large-rare-01-var02,default']: 1,
	['prism/large-rare-01-var03,default']: 1,
	['prism/large-rare-02-var01,default']: 1,
	['prism/large-rare-02-var02,default']: 1,
	['prism/large-rare-02-var03,default']: 1,
	['prism/prism-alter,default']: 1,
	['highlands/boss-arena-animated,default']: 1,
	['highlands/boss-arena-glow-01,default']: 1,
	['highlands/boss-arena-glow-02,default']: 1,
	['highlands/boss-arena-glow-03,default']: 1,
	['highlands/boss-arena-glow-04,default']: 1,
	['highlands/boss-arena-glow-05,default']: 1,
	['highlands/boss-arena-glow-06,default']: 1,
	['highlands/boss-arena-glow-07,default']: 1,
	['highlands/boss-arena-flag,default']: 1,
	['highlands/outpost-lanterns,default']: 1,
	['highlands/outpost-campfire,default']: 1,
	['highlands/outpost-bubba,default']: 1,
	['highlands/outpost-sign,default']: 1,
	['highlands/outpost-bottom-rock-glow,default']: 1,
	['highlands/outpost-top-rock-glow,default']: 1,
	['highlands/small-rare-01,default']: 2,
	['highlands/med-uncommon-01,default']: 1,
	['highlands/med-uncommon-02,default']: 2,
	['highlands/med-uncommon-03,default']: 2,
	['highlands/large-uncommon-01-var01,default']: 1,
	['highlands/large-uncommon-01-var02,default']: 1,
	['highlands/large-uncommon-01-var03,default']: 1,
	['highlands/large-rare-01-var02,default']: 1,
	['highlands/large-rare-01-var03,default']: 1,
	['highlands/highlands-alter,default']: 1,
	['town/bg-animated,default']: 1,
	['town/docks-animated,default']: 1,
	['town/furnace-animated,default']: 1,
	['town/pit-of-chances-animated,default']: 1,
	['town/plaza-animated,default']: 1,
	['town/test-range-animated,default']: 1,
	['test-range-animated2,default']: 1,
	['test-range-animated3,default']: 1,
	['town/test-range-animated4,default']: 1,
	['test-range-animated5,default']: 1,
	['test-range-animated6,default']: 1,
	['test-range-animated7,default']: 1,
	['test-range-animated8,default']: 1,
	['test-range-animated9,default']: 1,
	['test-range-animated10,default']: 1,
	['town/water,default']: 1,
	['shared/water,default']: 6,
	['shared/seagull,default']: 1,
	['shared/boat,default']: 1,
	['shared/lights,default']: 1,
	['shared/Connection-Road,default']: 1,
	['shared/Waterfall-animated,default']: 1,
	['shared/End-Island-Tree,default']: 1,
	['player-skins,default']: 1,
	['biped-enemy,default']: 1,
	['bubba,default']: 1,
	['merchant-npc,default']: 1,
	['merchant-npc-b,default']: 1,
	['merchant-npc-c,default']: 1,
	['stash-npc,default']: 1,
	['wisp-npc,default']: 1,
	['worm,default']: 1,
	['furnace-npc-a,default']: 1,
	['furnace-npc-b,default']: 1,
	['ent,default']: 2,
	['wasp,default']: 5,
	['shrieker,default']: 9,
	['sporekid,default']: 3,
	['mushie,default']: 5,
	['blimpie,default']: 4,
	['shambling-mound,default']: 2,
	['shambling-mound-aoe,default']: 1,
	['eyebat,default']: 7,
	['fungi-boss,default']: 1,
	['fungi-aoe,default']: 1,
	['beach-boss,default']: 1,
	['asp,default']: 4,
	['skeleton-beach,default']: 5,
	['beach-aoe,default']: 1,
	['mosquito,default']: 12,
	['sliver,default']: 8,
	['crab,default']: 15,
	['spider,default']: 10,
	['thorn-wolf,default']: 2,
	['wisp,default']: 5,
	['goblin,default']: 4,
	['bone-spirit,default']: 5,
	['death-drake,default']: 2,
	['skeletal-whelp,default']: 10,
	['skeleton-knight,default']: 10,
	['dreamer-fungi,default']: 2,
	['dreamer-prism,default']: 9,
	['crab-nest-a,default']: 2,
	['crab-nest-b,default']: 1,
	['crab-nest-c,default']: 1,
	['crab-nest-d,default']: 1,
	['crab-nest-e,default']: 1,
	['crab-nest-f,default']: 2,
	['hermit-crab,default']: 15,
	['shambling-tower,default']: 1,
	['stranglevine,default']: 3,
	['dragon-skull,default']: 3,
	['golem-prism,default']: 3,
	['prism-golem-aoe,default']: 1,
	['crystal-spider,default']: 7,
	['forest-boss,default']: 1,
	['mtx-npc,default']: 1,
	['docks-npc,default']: 1,
	['identification-npc,default']: 1,
	['ant-npc-a,default']: 1,
	['ant-npc-b,default']: 1,
	['island-npc,default']: 1,
	['balloon-npc,default']: 1,
	['pit-of-chances-npc,default']: 1,
	['highlands-boss,default']: 1,
	['highlands-aoe,default']: 1,
	['prism-boss,default']: 1,
	['prism-aoe,default']: 2,
	['prism-aoe-md,default']: 1,
	['prism-aoe-lg,default']: 1,
	['fungi-wolf,default']: 3,
	['prism-wisp,default']: 8,
	['prismfly,default']: 5,
	['wasp-forest,default']: 4,
	['golem-highlands,default']: 3,
	['highlands-golem-aoe,default']: 2,
	['skill-bulwark-model,default']: 4,
	['alter-spawn-animation,default']: 1
}

export const doNotPool = [
	//'worm,default' // TODO3: the worm is invisible if pooling and coming from hub
	'fungi-swamp,default', // this asset isn't loaded, but it's list of props for collider purposes
]
