import { ColliderType, EllipseColliderConfig } from "../../engine/collision/colliders"
import { DamagingGroundHazardParams } from "./damaging-ground-hazard"
import { GroundHazardParams } from "./ground-hazard"

export interface EllipseGroundHazardParams extends GroundHazardParams {
    radiusX: number
    radiusY: number
}

export const HAZARD_ELIPSE_COLLIDER_CONFIG: EllipseColliderConfig[] = [
    {
        type: ColliderType.Ellipse,
        position: [0, 0],
        rX: 40,
		rY: 40
    }
]
