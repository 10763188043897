import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { Enemy } from "../enemy"
import { Prop } from "../../prop"
import { GroundPickupConfigType } from "../../pickups/ground-pickup-types"

const snowball: EnemyAI = {
	name: ENEMY_NAME.SNOWBALL,
	type: EnemyType.BASIC,
	objectPoolInitialSize: 300,
	objectPoolGrowthSize: 8,
	appearance: {
		asset: SpineDataName.SNOWBALL,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
		scale: 0.25,
		zOffset: 200
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 230,
				position: [0, -25],
			},
		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.60,
			maxHealth: 1.0,
			knockbackResist: 9999,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		avoidanceRadius: 300,
		animationSpeeds: 0.25,

	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.EXPLODE_ON_CONTACT,
			},
			onPropCollisionFn: (enemy: Enemy, prop: Prop, collisionVX: number, collisionVY: number) => {
				enemy.setCircularScale(enemy.scale, enemy.scale * 5/6)
				if (enemy.scale <= snowball.appearance.scale * 0.8) {
					enemy.transitionToDead(false)
				}
			},
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 100,
			movementMinDistance: 0,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
			excludeGroundDrops: [GroundPickupConfigType.HealingSmall, GroundPickupConfigType.HealingLarge]
		},
		scaleWithDistanceRate: 0.000075
	},
}

export default snowball