import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { GameState } from "../../engine/game-state"
import EntityStatList from "../../stats/entity-stat-list"
import { StatType } from "../../stats/stat-interfaces-enums"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { Enemy } from "../enemies/enemy"
import { RangerTrapCrystalGraphic } from "../ranger-trap-crystal-graphic"
import { RangerTrapVineGraphics, VINE_ANIMATIONS } from "../ranger-trap-vine-graphics"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"

export interface RangerSlowHazardParams extends DamagingGroundHazardParams {
    skillStatList: EntityStatList
}

const FADE_OUT_TIME = 0.3

const debugFilter = new PIXI.filters.ColorMatrixFilter()
debugFilter.desaturate()
const filterArray = [debugFilter]

const emptyArray = []

export class RangerSlowHazard extends DamagingGroundHazard {    
    static pool: ObjectPoolTyped<RangerSlowHazard, RangerSlowHazardParams>

    //debugVisuals: Container
    //debugGfx: Graphics
	borderGraphics: RangerTrapCrystalGraphic
	vineGraphics: RangerTrapVineGraphics
    
    skillStatList: EntityStatList

    constructor() {
        super()
        //this.makeDebugVisuals()
		this.makeVisuals()
    }

    override setDefaultValues(defaultValues: any, overrideValues?: RangerSlowHazardParams) {
        if(overrideValues) {
            this.skillStatList = overrideValues.skillStatList

            /* this.debugVisuals.position.x = overrideValues.position.x
            this.debugVisuals.position.y = overrideValues.position.y
            this.debugVisuals.zIndex = -999999999

            this.debugGfx.clear()
            this.debugGfx.lineStyle(3, 0xC77838)
            this.debugGfx.drawCircle(0, 0, overrideValues.triggerRadius)

            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.debugVisuals) */
        }

        super.setDefaultValues(defaultValues, overrideValues)

        if (overrideValues) {
            this.setVisuals()
        }
    }

    override cleanup(): void {
        super.cleanup()

        this.skillStatList = null
        this.borderGraphics.removeFromScene()
		this.vineGraphics.removeFromScene()
    }

    returnToPool() {
        RangerSlowHazard.pool.free(this)
        //Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)	
    }

	override update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		super.update(delta, now)
		if (this.remainingLifeTime <= FADE_OUT_TIME) {
			this.vineGraphics.playAnimation(VINE_ANIMATIONS.DEATH)
		}
		this.vineGraphics.update(delta)
	}

    override onEntityEnterTrigger(entity: ColliderComponent) {
        const enemy = entity.owner as Enemy
		if (enemy.isEnemy) {
			Buff.apply(BuffIdentifier.RangerTrapDebuff, GameState.player, enemy, this.skillStatList.getStat(StatType.slowAmount) * 100)
		}
        
        // temp debug
        // enemy.gfx.model.filters = filterArray
    }

    override onEntityLeaveTrigger(entity: ColliderComponent) {
        const enemy = entity.owner as Enemy
		if (enemy.isEnemy) {
			Buff.remove(enemy, BuffIdentifier.RangerTrapDebuff, GameState.player)
		}
        
        // temp debug
        // enemy.gfx.model.filters = emptyArray
    }

    override getAttackTickTime(): number {
        return (1 / this.skillStatList.getStat(StatType.attackRate)) * 1_000
    }

    override onHitEnemy(enemy: Enemy) {

    }

    /* makeDebugVisuals() {
        this.debugVisuals = new Container()
        this.debugVisuals['update'] = () => {}

        this.debugGfx = new Graphics()

        this.debugVisuals.addChild(this.debugGfx)
    } */

	makeVisuals() {
		this.borderGraphics =  new RangerTrapCrystalGraphic()
		this.vineGraphics = new RangerTrapVineGraphics()
	}

	setVisuals() {
		this.borderGraphics.addToScene(this.position.x, this.position.y, this.triggerRadius)
		this.vineGraphics.addToScene(this.position.x, this.position.y, this.triggerRadius)
	}

    isPlayerOwned(): boolean {
        return true
    }
}