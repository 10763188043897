// This table is only used for the End run screen for the table that has the weapon icon in it // Temp and will be replaced!
<template>
	<div class="end-run-weapons-tab">
		<table id="table">
			<thead>
				<tr class="header">
					<th v-for="(header, index) in colData" :key="index" class="col-header" :lang="this.$i18n.locale">{{ $t(header.headerName) }}</th>
				</tr>
			</thead>
			<tbody class="table-body">
				<tr v-for="(rowItem, rowIndex) in rowData" :key="rowIndex" class="table-row">
					<td class="table-cell large-font" :lang="this.$i18n.locale">
						<div class="icon">
							<SquareItem :item="rowItem" @on-click-fn="() => {}" :selected="false" :disabled="false" :small-icons="false" :goldtrim-enabled="false"></SquareItem>
						</div>
					</td>
					<td :lang="this.$i18n.locale" class="table-cell">
						{{ rowItem.dps }}
					</td>
					<td :lang="this.$i18n.locale" class="table-cell">
						{{ rowItem.totalDamage }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script lang="ts">
import SquareItem from '../item/square-items.vue'

export default {
	name: 'IconTable',
	components: {SquareItem},
	data() {
		return {}
	},
	props: {
		colData: {
			type: Array,
			default: () => [],
		},
		rowData: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),

	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.end-run-weapons-tab {
	#table {
		table-layout: fixed;
		width: 480px;
		thead,
		tbody tr {
			width: 100%;
			display: table;
			table-layout: fixed;
		}
		.header {
			font-weight: bold;
			font-size: 25px;
			.col-header {
				padding: 15px 0px;
				text-align: center;
				color: @offWhite;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 23px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				&:first-child {
					text-align: left;
					padding-left: 17px;
				}
				&:last-child {
					padding-right: 17px;
				}
			}
		}
		.table-body {
			display: block;
			height: 440px;
			overflow: auto;
			.table-row {
				padding: 5px 0px;
				.table-cell {
					text-align: center;
					color: @cream;
					border-bottom: 6px solid transparent;
					.icon {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 74px;
						height: 74px;
						transform: scale(0.55);
						position: relative;
						.square-item {
							margin: unset;
						}
					}
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						font-size: 30px;
						letter-spacing: 2px;
					}

					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
					}
					&:first-child {
						text-align: left;
						color: @green;
					}
					&.large-font {
						font-size: 30px;
					}
				}
			}
		}
	}
}
</style>
