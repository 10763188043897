<template>
	<div class="perks-store">
		<Panel id="perks-category" :height="806">
			<template #panel-content>
				<div class="perks-buttons">
					<TextButton class="button" :button-label="$t('meta_progression_store.perks')" :button-style="'blue'" @on-click-fn="() => setStoreTab('perks')" :selected="selectedStoreTab === 'perks'"></TextButton>
					<TextButton class="button" :button-label="$t('meta_progression_store.characters_and_weapons')" :button-style="'blue'" @on-click-fn="() => setStoreTab('charactersAndWeapons')" :selected="selectedStoreTab === 'charactersAndWeapons'"></TextButton>
					<TextButton class="button" :button-label="$t('meta_progression_store.plot_twists')" :button-style="'blue'" @on-click-fn="() => setStoreTab('twists')"></TextButton>
					<TextButton class="button" :button-label="$t('meta_progression_store.special')" :button-style="'blue'" @on-click-fn="() => {}" :disabled="true"></TextButton>
					<TextButton class="button" :button-label="$t('meta_progression_store.refund_everything')" :button-style="'green'" @click="refundAllClicked()" :disabled="disableTransactionButtons"></TextButton>
					<TextButton class="button" :button-label="$t('meta_progression_store.back')" :button-style="'blue'" @on-click-fn="showOrHidePerks(false) || updateDarkenOverlay(false)"></TextButton>
				</div>
			</template>
		</Panel>
		<Panel id="perks-collection" :height="906">
			<template #panel-content>
				<div class="perks-collections">
					<div class="header">
						<Header :header-text="`Unlocks`" :text-alignment="'left'" :font-face="'boldPT'" :font-size="'medium'" :enable-drop-shadow="false"></Header>
						<Currency :icon-name="'paper_scraps'" :currency="currencies.paperScraps" :tooltip-data="{ name: $t('currency.scrap_label'), description: $t('currency.scrap_description') }"></Currency>
						<Currency :icon-name="'lost_scrolls'" :currency="currencies.lostScrolls" :tooltip-data="{ name: $t('currency.scroll_label'), description: $t('currency.scroll_description') }"></Currency>
						<Currency :icon-name="'magic_tomes'" :currency="currencies.magicTomes" :tooltip-data="{ name: $t('currency.tome_label'), description: $t('currency.tome_description') }"></Currency>
					</div>
					<div class="collections">
						<SquarePerkContainer :perks="metaItems"></SquarePerkContainer>
					</div>
					<div class="perk-unlock-container" v-if="selectedPerk !== null">
						<Header :header-text="`Unlock`" :text-alignment="'center'" :font-face="'pixl'" :font-size="'large'" :enable-drop-shadow="false"></Header>
						<div class="perk-info-container">
							<div class="perk-text">
								<div class="description" :lang="this.$i18n.locale">{{ selectedPerk.name }}</div>
								<div class="unlock-condition" :lang="this.$i18n.locale" v-show="selectedPerk.mission">
									Mission: <span>{{ selectedPerk.mission }}</span>
								</div>
								<div class="unlock-state" :lang="this.$i18n.locale">
									{{ selectedPerk.description }}<br />
									{{ refundableText }}
								</div>
							</div>
							<div class="button-wrapper">
								<div v-if="selectedPerk.mission || !unlockConditionMet(selectedPerk)" class="mission-container">{{ $t('meta_progression_store.mission') }}</div>
								<Currency v-else class="float-above" :icon-name="selectedPerk.currency" :currency="selectedPerkCost" :disableBackground="false"></Currency>
								<TextButton class="purchase-perk" :button-label="$t('meta_progression_store.buy_perk')" :button-style="'bright-green'" @click.left.stop.prevent="buyPerk(selectedPerk)" :disabled="!canBuyPerk(currencies, selectedPerk) || !unlockConditionMet(selectedPerk) || disableTransactionButtons"></TextButton>
								<TextButton class="purchase-perk" :button-label="$t('meta_progression_store.refund_perk')" :button-style="'bright-green'" @click.left.stop.prevent="refundPerk(selectedPerk)" :disabled="!canRefundPerk(selectedPerk) || disableTransactionButtons"></TextButton>
							</div>
						</div>
					</div>
					<div class="checkbox-container">
						<Checkbox v-if="false" :label="'meta_progression_store.hide_unlocks'" :inline-label="true" @on-click-fn="() => {}" :checked="false"></Checkbox>
					</div>
				</div>
			</template>
		</Panel>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import Currency from '../ui-components/currency/currency.vue'
import SquarePerkContainer from '../ui-components/container/square-perk-container.vue'
import Checkbox from '../ui-components/form-inputs/checkbox.vue'
import { canBuyPerk, canRefundPerk, StoreTab } from '../store/meta-progression-store'
import { Views } from '../store/ui-store'
import { showGenericYesNoUI } from '../store/generic-two-btn-prompt-store'

export default {
	name: 'PerksPrompt',
	components: {
		Panel,
		TextButton,
		Header,
		Currency,
		SquarePerkContainer,
		Checkbox,
	},
	data() {
		return {
			Views: Views,
		}
	},
	props: {},
	computed: {
		...mapGetters('metaProgression', ['allPerks', 'selectedPerkCost', 'unlockConditionMet']),
		...mapState('metaProgression', ['selectedPerk', 'disableTransactionButtons', 'selectedStoreTab', 'unlockPurchaseOptions', 'twistUnlocks']),
		...mapState('user', ['currencies']),
		metaItems() {
			switch (this.selectedStoreTab) {
				case StoreTab.Perks:
					return this.allPerks
				case StoreTab.CharactersAndWeapons:
					return this.unlockPurchaseOptions
				case StoreTab.Twists:
					return this.twistUnlocks
			}

			return this.allPerks
		},
		refundableText() {
 			if (this.selectedPerk.isWishlistCTA) {
				return 
			}
			
			return this.selectedPerk.refundable ? 'Refundable. ' : 'Non-Refundable. '
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		...mapActions('metaProgression', ['buyPerk', 'refundPerk', 'refundAllPerks']),
		...mapMutations('metaProgression', ['setStoreTab', 'showOrHidePerks']),
		...mapMutations('ui', ['updateDarkenOverlay']),
		...mapActions('ui', ['startGame', 'changeActiveView']),
		...mapActions('user', ['fetchCurrencies']),
		canBuyPerk: canBuyPerk,
		canRefundPerk: canRefundPerk,
		changeView(params) {
			const { view } = params
			this.changeActiveView(view)
		},
		refundAllClicked() {
			showGenericYesNoUI('meta_progression_store.refund_everything', 'meta_progression_store.refund_confirm', 'meta_progression_store.yes', 'meta_progression_store.back', this.refundAllPerks)
		},
	},
	mounted() {
		this.fetchCurrencies()
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.perks-store {
	display: flex;
	flex-direction: row;
	margin: 0px 40px;
	justify-content: space-around;
	align-items: center;
	width: 1920px;
	height: 1080px;
	#perks-category {
		width: 580px;
		align-items: center;
		display: flex;
		flex-direction: column;
		.perks-buttons {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.button {
				width: 300px;
				height: 103px;
				margin: 5px 0px;
				&:last-child {
					margin-top: 30px;
				}
			}
		}
	}
	#perks-collection {
		width: 1000px;
		:deep(.panel-inner) {
			border-width: 38px 35px 15px 30px;
		}

		.perks-collections {
			width: 932px;
			height: 845px;

			.header {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
			}
			.collections {
				margin-bottom: 20px;
			}

			.perk-unlock-container {
				.perk-info-container {
					display: flex;
					height: 150px;
					padding: 4px 8px;
					background-color: @darkBlue;
					.perk-text {
						width: 632px;
						height: 147px;
						overflow-y: auto;
						.description {
							margin-bottom: 15px;
							&[lang='en'] {
								.regularFont(@enRegularFontPixl);
								font-size: 39px;
								color: @offWhite;
							}

							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
							}
						}
						.unlock-condition {
							margin-bottom: 15px;
							&[lang='en'] {
								.regularFont(@enRegularFontPixl);
								font-size: 29px;
								color: @offWhite;
							}

							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
							}
							span {
								color: @green;
							}
						}
						.unlock-state {
							margin-bottom: 6px;
							&[lang='en'] {
								.regularFont(@enRegularFontPixl);
								font-size: 29px;
								color: @cream;
							}

							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
							}
						}
					}
					.button-wrapper {
						width: 300px;
						display: flex;
						position: relative;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						// forgive me, Greeley (┳ _ ┳)
						.mission-container {
							background-color: #060a23;
							padding: 0 8px 0 4px;
							position: absolute;
							top: -58px;
							right: -8px;
							font-variant: small-caps;
							font-family: sl_pixlregular;
							font-size: 45px;
							color: @offWhite;
							width: 219px;
							height: 48px;
							display: flex;
							justify-content: center;
						}
						.purchase-perk {
						}

						.float-above {
							position: absolute;
							top: -58px;
							right: -8px;
							padding: 0 8px 0 4px;
							background-color: @darkBlue;
						}
						.text-button{	
							width: 243px;
							margin: 5px;
						}
					}
				}
			}
			.checkbox-container {
				display: flex;
				justify-content: center;
			}
		}
	}
}
</style>