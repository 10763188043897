import { AllWeaponTypes } from "../../weapons/weapon-types"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { DamageSource } from "../../projectiles/damage-source"
import EntityStatList from "../../stats/entity-stat-list"
import { StatType } from "../../stats/stat-interfaces-enums"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { InGameTime } from "../../utils/time"
import { Enemy } from "../enemies/enemy"
import { DamageableEntityType, EntityType } from "../entity-interfaces"
import { Player } from "../player"
import { TimedGroundHazard, TimedGroundHazardParams } from "./timed-ground-hazard"
import { Vector } from "sat"

export interface DamagingGroundHazardParams extends TimedGroundHazardParams {
	statList: EntityStatList
	damageTargetType: DamageableEntityType
	noDamage?: boolean
	damagePercent?: number
	permanent?: boolean
	weaponType?: AllWeaponTypes
	ignoreKnockback?: boolean
}

export abstract class DamagingGroundHazard extends TimedGroundHazard implements DamageSource {

	statList: EntityStatList
	damageTargetType: DamageableEntityType
	numEntitiesChained: number = 0
	numEntitiesPierced: number = 0
	nextDamageTick: number = 0

	damaging: boolean
	damagePercent: number

	updateLife: boolean
	weaponType: AllWeaponTypes

	ignoreKnockback: boolean

	override setDefaultValues(defaultValues: any, overrideValues?: DamagingGroundHazardParams): void {
		super.setDefaultValues(defaultValues, overrideValues)

		if (overrideValues) {
			this.statList = overrideValues.statList ?? this.statList
			this.damageTargetType = overrideValues.damageTargetType
			this.nextDamageTick = InGameTime.highResolutionTimestamp() + this.getAttackTickTime()

			this.damaging = !Boolean(overrideValues.noDamage)
			this.damagePercent = overrideValues.damagePercent === undefined ? 1 : overrideValues.damagePercent

			this.weaponType = overrideValues.weaponType === undefined ? null : overrideValues.weaponType

			this.ignoreKnockback = Boolean(overrideValues.ignoreKnockback)

			this.updateLife = !Boolean(overrideValues.permanent)
		}
	}

	override cleanup(): void {
		super.cleanup()

		this.statList = null
		this.updateLife = false
	}

	getAttackTickTime(): number {
		return (1 / this.statList.getStat(StatType.attackRate)) * 1_000
	}

	override update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		if (this.damaging && this.entitiesInZone.size > 0 && now > this.nextDamageTick) {
			this.nextDamageTick = now + this.getAttackTickTime()

			this.entitiesInZone.forEach((entity) => {
				if (entity.collider.owner.entityType === EntityType.Enemy && (this.damageTargetType === DamageableEntityType.Enemy || this.damageTargetType === DamageableEntityType.PlayerAndEnemy)) {
					const enemy = entity.collider.owner as Enemy
					enemy.onHitByDamageSource(this, this.damagePercent, this.ignoreKnockback)
					this.onHitEnemy(enemy)
				} else if (entity.collider.owner.entityType === EntityType.Player && (this.damageTargetType === DamageableEntityType.Player || this.damageTargetType === DamageableEntityType.PlayerAndEnemy)) {
					const player = entity.collider.owner as Player
					const playerDamage = this.statList.getStat(StatType.baseDamage)
					player.takeDamage(playerDamage, this, true)
				}
			})
		}

		if (this.updateLife) {
			super.update(delta, now)
		}
	}

	abstract onHitEnemy(enemy: Enemy)

	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		return mutableEntityPos.sub(this.position).normalize()
	}

	onEntityEnterTrigger(entity: ColliderComponent) {

	}

	onEntityLeaveTrigger(entity: ColliderComponent) {

	}
}