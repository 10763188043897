import { Vector } from "sat"
import EntityStatList from "../stats/entity-stat-list"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { EntityType } from "../entities/entity-interfaces"
import { GameState, getNID } from "../engine/game-state"
import { StatType } from "../stats/stat-interfaces-enums"
import { timeInSeconds } from "../utils/primitive-types"
import { AssetManager } from "../web/asset-manager"
import { SpritesheetAnimatorComponent } from "../engine/graphics/spritesheet-animator-component"
import { ComponentOwner } from "../engine/component-owner"
import { distanceSquaredVV } from "../utils/math"
import { Renderer } from "../engine/graphics/renderer"

const DEFAULT_EFFECT_DURATION = 1.5
const DEFAULT_ANIMATION_SPEED = 0.3
export interface EnemyMeleeParams {
		targetX: number
		targetY: number
		enemyStatList: EntityStatList
}
// This is really only applicable to Mr. Cuddles, unless other enemies share the exact same effect spritesheet
export class EnemyMelee implements PoolableObject, ComponentOwner {
		static pool: ObjectPoolTyped<EnemyMelee, EnemyMeleeParams>

		visuals: SpritesheetAnimatorComponent
		animationSpeed: number
		attackRadius: number
		damage: number

		position: Vector = new Vector(0, 0)
		duration: timeInSeconds
		aliveTime: timeInSeconds = 0

		nid: number
		entityType: EntityType = EntityType.GroundHazard

		playerHitSuccess: boolean

		constructor() {
				this.nid = getNID(this)
				this.makeVisuals()
		}

		setDefaultValues(defaultValues: any, overrideValues?: EnemyMeleeParams) {
			if(overrideValues) {
				this.position.x = overrideValues.targetX
				this.position.y = overrideValues.targetY
				this.attackRadius = overrideValues.enemyStatList.getStat(StatType.attackSize)
				this.damage = overrideValues.enemyStatList.getStat(StatType.baseDamage)
				this.duration = DEFAULT_EFFECT_DURATION
				this.animationSpeed = DEFAULT_ANIMATION_SPEED
				this.aliveTime = 0
				this.playerHitSuccess = false
				
				GameState.addEntity(this)

				this.setVisuals()
			}
		}

		cleanup() {
			this.visuals.removeFromScene()
			GameState.removeEntity(this)
		}

		update(delta: number, now?: timeInMilliseconds) {

				this.visuals.update(delta)

				if (!this.playerHitSuccess) {
					this.tryHitPlayer()
				}			
				this.aliveTime += delta
				if(this.aliveTime >= this.duration) {
						this.visuals.removeFromScene()
						this.returnToPool()
				}
		}

		tryHitPlayer() {
			const player = GameState.player
			if (distanceSquaredVV(player.position, this.position) <= this.attackRadius ** 2){
				player.takeDamage(this.damage, this)
				this.playerHitSuccess = true
			}
		}

		makeVisuals() {
			const spriteSheet = AssetManager.getInstance().getAssetByName('mr-cuddles-swipe-pfx').spritesheet
			this.visuals = new SpritesheetAnimatorComponent(this, spriteSheet, 'swipe-pfx', this.animationSpeed, true, true)
	}

		setVisuals() {
			this.visuals.addToScene()
			this.visuals.spriteSheetAnimator.restartCurrentAnim()
			/* Renderer.getInstance().drawCircle({
				x: this.position.x,
				y: this.position.y,
				radius: this.attackRadius,
				permanent: false,
				destroyAfterSeconds: this.duration,
				color: 0xFF0000,
				scale: 1
			}) */
		}
	
		isPlayerOwned(): boolean {
			return false
		}
	
		returnToPool() {
			EnemyMelee.pool.free(this)
		}
}