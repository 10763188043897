<template>
	<div class="range-container">
		<div class="left-button">
			<button class="left" @click="$emit('decrease')">
				<img src="../../../../assets/ui/buttons/settings-arrow-left.png" width="36" height="38" alt="" />
			</button>
		</div>
		<div class="text-input" :lang="this.$i18n.locale">
			<input type="number" :value="amount" :placeholder="this.selectedAmount" @change="onTextChange($event.target.value)" :min="minAmount" :max="maxAmount" />
		</div>
		<div class="right-button">
			<button class="right" @click="$emit('increase')">
				<img src="../../../../assets/ui/buttons/settings-arrow-right.png" width="36" height="38" alt="" />
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
const range = ['min', 'max']
export default {
	name: 'Amount',
	components: {},
	data() {
		return {
			amount: 0,
		}
	},
	watch: {
		selectedAmount: function (val) {
			this.amount = val
		},
	},
	props: {
		selectedAmount: {
			type: Number,
		},
		minAmount: {
			type: Number,
			required: false,
		},
		maxAmount: {
			type: Number,
			required: false,
		},
		range: {
			type: String,
			required: false,
			default: null,
		},
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		onTextChange(value) {
			let parsedValue = parseInt(value, 10)

			if (this.range == 'min') {
				if (parsedValue <= this.minAmount || parsedValue >= this.maxAmount) {
					parsedValue = this.minAmount
				}
			} else if (this.range == 'max') {
				if (parsedValue > this.maxAmount || parsedValue <= this.minAmount) {
					parsedValue = this.maxAmount
				}
			}

			this.$emit('update-text-input', parsedValue)
		},
	},
	beforeMount() {
		this.amount = this.selectedAmount
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.range-container {
	display: flex;
	.left-button {
		height: 38px;
		button {
			height: inherit;
		}
		.left {
			&:hover {
			}
			&:active {
			}
			&:focus {
			}
		}
	}
	.text-input {
		input {
			width: 45px;
			font-size: 30px;
			background-color: @darkBlue;
			color: @green;
			text-align: center;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
			border-width: 2px;
			border-style: unset;
			border-color: unset;
			border-image: unset;
		}
		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		input[type='number'] {
			-moz-appearance: textfield;
		}
	}
	.right-button {
		height: 38px;
		button {
			height: inherit;
		}
		.right {
			&:hover {
			}
			&:active {
			}
			&:focus {
			}
		}
	}
}
</style>
