<template>
	<div id="pip">
		<div class="pip-background">
			<div class="inner-pip"></div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'pip',
	components: {

	},
	data() {
		return {}
	},
	props: {},
	computed: {
	},
	methods: {
	},
}
</script>

<style lang="less" scoped>

  #pip {
	&.selected{
		.pip-background{
			.inner-pip{
				opacity: 1;
			}
		}
	}
    .pip-background {
		background-image: url('../../../../assets/ui/pips/pip-empty.png');
		width: 30px;
		height: 30px;
		position: relative;
      .inner-pip {
		background-image: url('../../../../assets/ui/pips/pip-green-fill.png');
		width: 20px;
		height: 20px;
		position: absolute;
		top: 5px;
		left: 5px;
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
      }
    }
  }
</style>
