import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { AllWeaponTypes, SecondaryWeaponType } from "../../weapon-types"
import { ThrownAcidBottle } from "./thrown-acid-bottle"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { DamageSource } from "../../../projectiles/damage-source"
import { EntityType } from "../../../entities/entity-interfaces"

export class AcidBottlesWeapon extends AutoFireSecondaryWeapon implements DamageSource {
    numEntitiesChained: number = 0
    numEntitiesPierced: number = 0
   
    nid: number
    entityType: EntityType
    weaponType: AllWeaponTypes = AllWeaponTypes.AcidBottles
	timeScale: number = 1

    carryOverAcid: boolean = false
    acidBottleRechargeInAcidSpeed: number = 1
    applyPrimaryWeaponDamageDebuff: boolean = false

    standingInAcidPoolCount: number = 0

    showImmediateDamageNumber: boolean = false

    fire() {
        const count = this.statList.getStat(StatType.projectileCount)
        const range = this.statList.getStat(StatType.placementRange)

        for(let i=0; i < count; ++i) {
            const randomX = Math.getRandomFloat(-range, range)
            const randomY = Math.getRandomFloat(-range, range)

            // chuck a bottle there
            ThrownAcidBottle.pool.alloc({
                targetX: randomX + this.player.position.x,
                targetY: randomY + this.player.position.y,

                originPosition: this.player.position,
                weapon: this,
                weaponType: this.weaponType
            })
        }
    }

    forceStopFiring() {
        // don't need to do anything
    }

    isPlayerOwned(): boolean {
        return true
    }
    
    getKnockbackDirection(mutableEntityPos: SAT.Vector): SAT.Vector {
        mutableEntityPos.x = 0
        mutableEntityPos.y = 0
        return mutableEntityPos
    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
        statList._actualStatValues.projectileCount = 1
        statList._actualStatValues.baseDamage = 8
        statList._actualStatValues.attackRate = 4
        statList._actualStatValues.skillDuration = 3
        statList._actualStatValues.attackSize = 190
        statList._actualStatValues.placementRange = 340

        statList._actualStatValues.allDamageMult = 1

        statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 7_500
		statList._actualStatValues.reloadInterval = 50
    }
}
