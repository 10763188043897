import { Vector } from "sat"
import CollisionSystem from "../../../engine/collision/collision-system"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { radians, timeInSeconds } from "../../../utils/primitive-types"
import { SecondaryWeapon } from "../../secondary-weapon"
import { AllWeaponTypes } from "../../weapon-types"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { Player } from "../../../entities/player"
import { angleBetweenVectors, vectorLerpMutate } from "../../../utils/vector"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { Cooldown } from "../../../cooldowns/cooldown"
import { CooldownSystem } from "../../../cooldowns/cooldown-system"
import { PlayerProjectile, ProjectileInitialParams } from "../../../projectiles/projectile"
import { ResourceType } from "../../weapon-definitions"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { ProjectileSystem } from "../../../projectiles/projectile-system"
import { AssetManager } from "../../../web/asset-manager"
import { InstancedSpriteSheetAnimator } from "../../../engine/graphics/instanced-spritesheet-animator"
import { Enemy } from "../../../entities/enemies/enemy"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { ColliderComponent } from "../../../engine/collision/collider-component"
import ClientPlayerInput from "../../../engine/client-player-input"
import { angleOfVector } from "../../../utils/math"

const NEARBY_ENEMY_SEARCH_DISTANCE = 1_500

const MIN_TIME_FOR_FAIRY_ROT_CHANGE: timeInSeconds = 7 // how long they stay rotating in a single direction
const MAX_TIME_FOR_FAIRY_ROT_CHANGE: timeInSeconds = 17.5

const FAIRY_DIST_TO_PLAYER = 150

const FAIRY_POSITION_OFFSETS = [
    new Vector(FAIRY_DIST_TO_PLAYER, 0).rotate(Math.PI * 2 * 1/3),
    new Vector(FAIRY_DIST_TO_PLAYER, 0).rotate(Math.PI * 2 * 2/3),
    new Vector(FAIRY_DIST_TO_PLAYER, 0).rotate(Math.PI * 2 * 3/3),
]

const GFX_WOBBLE_TIME_OFFSETS = [
    1,
    2,
    3
]

const GFX_WOBBLE_TIME = 0.25
const GFX_WOBBLE_OFFSET = 30

const FAIRY_POS_Y_OFFST = -70

const GFX_ROT_SPEED = (Math.PI * 2) / 7

export class FireFairiesWeapon extends AutoFireSecondaryWeapon {
    
    weaponType: AllWeaponTypes = AllWeaponTypes.FireFairies

    aimAtCursor: boolean = false
    targetNonBurning: boolean = false

    private attackCounters: timeInSeconds[]
    private cooldowns: Cooldown[]

    private fairyOffsetPositions: Vector[]
    private fairyGfx: InstancedSpriteSheetAnimator[]

    private numFairies: number

    private projectileCreationParams: ProjectileInitialParams

    private wobbleAcc: timeInSeconds
    private rotationAcc: radians

    private rotDirection: number
    private timeToRotChange: timeInSeconds

    private reuseAimVector: Vector

    constructor(player: Player, statList: EntityStatList) {
        super(player, statList)
        
        this.numFairies = 1
        this.attackCounters = [0, 0, 0]

        this.cooldowns = [
            new Cooldown(this.statList),
            new Cooldown(this.statList),
            new Cooldown(this.statList),
        ]

        this.fairyOffsetPositions = [
            new Vector().copy(FAIRY_POSITION_OFFSETS[0]),
            new Vector().copy(FAIRY_POSITION_OFFSETS[1]),
            new Vector().copy(FAIRY_POSITION_OFFSETS[2]),
        ]

        this.reuseAimVector = new Vector()

        CooldownSystem.getInstance().addCooldown(this.cooldowns[0])

        const splashDamageEffect = AssetManager.getInstance().getAssetByName('fire-fairy-explosion').data

        this.projectileCreationParams = {
			owningEntityId: player.nid,
			position: null,
			speed: this.statList.getStat(StatType.projectileSpeed),
			aimAngleInRads: 0,
			radius: this.statList.getStat(StatType.attackSize),
			trajectoryMods: [],
			collisionLayer: CollisionLayerBits.PlayerProjectile,
			statList: this.statList,
			resourceType: ResourceType.NONE,
			player: this.player,
			weaponType: this.weaponType,
            effectType: ParticleEffectType.FIRE_FAIRY_PROJECTILE,
            trailType: ParticleEffectType.PROJECTILE_NONE,
            damageScale: 1,
            splashDamageEffect,
		}

        this.makeBaseGfx()
        this.fairyGfx[0].addToScene()
        this.wobbleAcc = 0
        this.rotationAcc = 0
        this.rotDirection = Math.getRandomInt(0, 1) ? 1 : -1
        this.timeToRotChange = Math.getRandomFloat(MIN_TIME_FOR_FAIRY_ROT_CHANGE, MAX_TIME_FOR_FAIRY_ROT_CHANGE)
    }

    override update(delta: number): void {
        // intentionally not calling super.update()
        
        for (let i =0; i < this.numFairies; ++i) {
            if (this.attackCounters[i] > 0) {
                this.attackCounters[i] -= delta
            }
        }
        
        if (!this.player.noFireWeapons) {
            for (let i = 0; i < this.numFairies; ++i) {
                if(this.attackCounters[i] <= 0 && this.cooldowns[i].isUp()) {
                    this.fireFireFairy(i)

                    this.cooldowns[i].useCharge()
                    this.attackCounters[i] += this.getAttackTime()
                }
            }
        }

        this.updateFairyPositions(delta)
    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.baseDamage = 12
		statList._actualStatValues.projectileCount = 1
        statList._actualStatValues.projectileSpreadAngle = 10
		statList._actualStatValues.attackRate = 1
		statList._actualStatValues.projectileSpeed = 800
        statList._actualStatValues.attackSize = 10

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 10
		statList._actualStatValues.reloadInterval = 3000
    }

    addTwoFairies() {
        this.numFairies = 3
        this.fairyGfx[1].addToScene()
        this.fairyGfx[2].addToScene()

        for (let i =0; i < this.numFairies; ++i) {
            this.attackCounters[i] = 0
            this.cooldowns[i].useCharge()
        }

        this.cooldowns[0].setNextReload(0)
        this.cooldowns[1].setNextReload(250)
        this.cooldowns[2].setNextReload(500)

        CooldownSystem.getInstance().addCooldown(this.cooldowns[1])
        CooldownSystem.getInstance().addCooldown(this.cooldowns[2])
    }

    removeTwoFairies() {
        this.numFairies = 1
        this.fairyGfx[1].removeFromScene()
        this.fairyGfx[2].removeFromScene()

        CooldownSystem.getInstance().removeCooldown(this.cooldowns[1])
        CooldownSystem.getInstance().removeCooldown(this.cooldowns[2])
    }

    setGfx(assetName: string) {
        const spriteSheet = AssetManager.getInstance().getAssetByName(assetName).spritesheet
        for (let i = 0; i < this.fairyGfx.length; ++i) {
            this.fairyGfx[i].removeFromScene()
            this.fairyGfx[i] = new InstancedSpriteSheetAnimator(spriteSheet, 'idle')
        }

        for (let i = 0; i < this.numFairies; ++i) {
            this.fairyGfx[i].addToScene()
        }
    }

    private fireFireFairy(index: number) {
        let angle: radians = 0
        if (this.aimAtCursor) {
            const fairyPos = this.fairyGfx[index].position
            if (this.player.autoAimEnabled && this.player.autoAimNearestEnemy) {
                this.reuseAimVector.x = ClientPlayerInput.worldMouseX - this.player.autoAimNearestEnemy.x 
                this.reuseAimVector.y = ClientPlayerInput.worldMouseY - this.player.autoAimNearestEnemy.y
            } else {
                if (ClientPlayerInput.controllerActive) {
                    this.reuseAimVector.copy(ClientPlayerInput.controllerAimVector)
                } else {
                    this.reuseAimVector.x = ClientPlayerInput.worldMouseX - fairyPos.x
                    this.reuseAimVector.y = ClientPlayerInput.worldMouseY - fairyPos.y
                }
            }
           

            angle = angleOfVector(this.reuseAimVector)
        } else {
            angle = this.getRandomNearbyEnemyAngle(this.fairyGfx[index].position)
        }

        this.shootProjectile(this.fairyGfx[index].position, angle)
    }

    private getRandomNearbyEnemyAngle(position: Vector): radians  {
        const entities = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, NEARBY_ENEMY_SEARCH_DISTANCE, CollisionLayerBits.HitEnemyOnly)
        if (entities.length) {
            if (this.targetNonBurning) {
                entities.sort(this.sortByDistToPlayerFunc)
                for (let i = 0; i < entities.length; ++i) {
                    const enemy = entities[i].owner as Enemy
                    if (!enemy.hasBuff(BuffIdentifier.Ignite)) {
                        return angleBetweenVectors(position, enemy.position)
                    }
                }

                return angleBetweenVectors(position, entities[0].position)
            } else {
                const randomEntity = entities[Math.getRandomInt(0, entities.length - 1)]
                return angleBetweenVectors(position, randomEntity.position)
            }
        }
        return Math.getRandomFloat(0, Math.PI * 2)
    }

    private shootProjectile(origin: Vector, angle: radians) {
        this.projectileCreationParams.position = origin
        this.projectileCreationParams.aimAngleInRads = angle
        this.projectileCreationParams.radius = this.statList.getStat(StatType.attackSize)
        this.projectileCreationParams.speed = this.statList.getStat(StatType.projectileSpeed)

        const spread = this.statList.getStat(StatType.projectileSpreadAngle)
        const numProjectiles = this.statList.getStat(StatType.projectileCount)
        for (let i=0; i < numProjectiles; ++i) {
            const angleOffset = ProjectileSystem.projectileSpreadAngle(i, numProjectiles, spread)
            this.projectileCreationParams.aimAngleInRads = angle + angleOffset
            PlayerProjectile.objectPool.alloc(this.projectileCreationParams)
        }
    }

    private makeBaseGfx() {
        const baseSpritesheet = AssetManager.getInstance().getAssetByName('fire-fairy-base').spritesheet
        this.fairyGfx = [
            new InstancedSpriteSheetAnimator(baseSpritesheet, 'idle'),
            new InstancedSpriteSheetAnimator(baseSpritesheet, 'idle'),
            new InstancedSpriteSheetAnimator(baseSpritesheet, 'idle')
        ]
    }

    private updateFairyPositions(delta: timeInSeconds) {
        this.timeToRotChange -= delta
        if (this.timeToRotChange <= 0) {
            this.timeToRotChange = Math.getRandomFloat(MIN_TIME_FOR_FAIRY_ROT_CHANGE, MAX_TIME_FOR_FAIRY_ROT_CHANGE)
            this.rotDirection *= -1
        }

        for (let i = 0; i < this.numFairies; ++i) {
            this.fairyOffsetPositions[i].copy(FAIRY_POSITION_OFFSETS[i])
            this.fairyOffsetPositions[i].rotate(this.rotationAcc)


            this.fairyGfx[i].position.copy(this.player.position)
            this.fairyGfx[i].position.add(this.fairyOffsetPositions[i])
            // wobble 
            this.fairyGfx[i].position.y += FAIRY_POS_Y_OFFST + (Math.sin((GFX_WOBBLE_TIME_OFFSETS[i] + this.wobbleAcc)/GFX_WOBBLE_TIME) * GFX_WOBBLE_OFFSET)
        }

        this.wobbleAcc += delta
        this.rotationAcc += delta * GFX_ROT_SPEED * this.rotDirection
    }

    private sortByDistToPlayerFunc(a: ColliderComponent, b: ColliderComponent) {
        return a.owner.distanceToPlayer2 - b.owner.distanceToPlayer2
    }

    fire() {
        // force all fairies to fire at once
        for (let i =0; i < this.numFairies; ++i) {
            this.fireFireFairy(i)

            this.attackCounters[i] = 0
            this.cooldowns[i].useCharge()
        }

        this.cooldowns[0].setNextReload(0)
        this.cooldowns[1].setNextReload(250)
        this.cooldowns[2].setNextReload(500)
    }

    forceStopFiring() {
        // don't need to do anything
    }

    private getAttackTime(): number {
        return 1 / this.statList.getStat(StatType.attackRate)
    }
}