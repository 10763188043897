import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { Enemy } from "../enemy"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"

const dreamerBoss: EnemyAI = {
	name: ENEMY_NAME.DREAMER_BOSS,
	baseVariant: ENEMY_NAME.DREAMER_BOSS,
	type: EnemyType.BOSS,
	objectPoolInitialSize: 1,
	objectPoolGrowthSize: 1,
	appearance: {
		asset: SpineDataName.DREAMER,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
		scale: 1.5,
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 125,
				position: [0, -30],
			},
		],
		colliderLayer: CollisionLayerBits.FlyingEnemy,
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.6,
			maxHealth: 50.0,
			projectileSpeed: 760,
			attackSize: 40,
			projectileCount: 4,
			projectileSpreadAngle: 120,
			knockbackResist: 9999,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 720,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		animationSpeeds: 0.25
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	rage: [
		{
			enrageStart: 90, // 90 seconds after spawn
			cooldownBonus: 3, // get 3x statetime 
			movementBonus: 2, // 2x movement speed
			damageBonus: 2, // 2x damage
		},
		{
			enrageStart: 120, // 120 seconds after spawn
			cooldownBonus: 10, // get 10x statetime 
			movementBonus: 4, // 20x movement speed
			damageBonus: 4, // 4x damage
			applyFunction(enemy: Enemy) {
				enemy.fightingBehaviour = FightingBehaviours.CHASE_AND_ATTACK
				enemy.movementMinDistance = 0
				enemy.movementMaxDistance = 100
			},
		},
	],
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHARGE_ATTACK,
				params: {
					holdDirectionDistance: 500, // how far away from the player we must be, before our charge is over
					holdDirectionTime: 1.25, // How long we must spend charging at minimum (can be 0)
					aimTime: 1.75, // how long they spend standing still aiming
					chargeLerpSpeed: [
						{
							targetSpeedMult: 3.2, // effects their speed when actually charging
							maxTime: 4 // how quickly we get to max speed of our charge
						},
					]
				},
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_HEAD_DREAMER,
				cooldownDef: {
					// name: "enemyProjectile",
					// Keep this set as "a possible mutator" to create a super hard final boss worth a big boost in resources? 
					// maxAmmo: 3,
					// cooldownInterval: 85,
					// reloadInterval: 700,
					// numAmmoPerIncrement: 3, 

					// This should present a much more dodgeable attack
					maxAmmo: 1,
					cooldownInterval: 0,
					reloadInterval: 700,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 700,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 380, // how close should the enemy be before charging?
			// Max distance here could go as low as 300 and be difficult but possible. 
			movementMinDistance: 130,
			shotLeadPrecision: ShotLeadPrecision.PERFECT,
			visualAimLockSeconds: 0.0,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default dreamerBoss
