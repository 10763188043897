import { ColliderComponent } from "../../engine/collision/collider-component"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { Renderer } from "../../engine/graphics/renderer"
import EntityStatList from "../../stats/entity-stat-list"
import { timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { ObjectPool } from "../../utils/third-party/object-pool"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { EntityType, IEntity } from "../entity-interfaces"
import { Player } from "../player"
import { GroundHazard, GroundHazardParams } from "./ground-hazard"

const BASE_ATTACK_SIZE =  200
const BASE_EXPLOSION_SCALE = BASE_ATTACK_SIZE / 200

export interface ExplosionGroundHazardParams extends GroundHazardParams {
	owner: IEntity
	statList: EntityStatList
	damage: number
	weaponType?: AllWeaponTypes
	targetColliderType: CollisionLayerBits
	ignoreKnockback: boolean
	explosionEffectConfigId?: string
}
export class ExplosionGroundHazard extends GroundHazard {
	
	static pool: ObjectPool

	owner: IEntity
	statList: EntityStatList
	damage: number
	weaponType: AllWeaponTypes = AllWeaponTypes.WorldHazard
	numEntitiesChained: number = 0
	exploded: boolean
	targetColliderType: CollisionLayerBits
	ignoreKnockback: boolean

	explosionEffectConfig?: EffectConfig	

	constructor() {
		super()
		this.exploded = false
	}

	override setDefaultValues(defaultValues: any, overrideValues?: ExplosionGroundHazardParams): void {
		super.setDefaultValues(defaultValues, overrideValues)

		if(overrideValues) {
			this.owner = overrideValues.owner
			this.statList = overrideValues.statList
			this.damage = overrideValues.damage
			this.weaponType = overrideValues.weaponType
			this.targetColliderType = overrideValues.targetColliderType
			this.ignoreKnockback = overrideValues.ignoreKnockback
			if (overrideValues.explosionEffectConfigId) {
				this.explosionEffectConfig = AssetManager.getInstance().getAssetByName(overrideValues.explosionEffectConfigId).data
			}
			
		}
		this.explode()
	}

	override cleanup(): void {
		super.cleanup()

		this.exploded = false
		if (this.explosionEffectConfig) {
			this.explosionEffectConfig = undefined
		}
		this.statList = null
	}

	override update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		super.update(delta, now)
		if (this.exploded) {
			this.returnToPool()
		}
	}

	explode() {
		this.exploded = true

		if (this.explosionEffectConfig) {
			const explosionScale = this.triggerRadius / BASE_ATTACK_SIZE
			Renderer.getInstance().addOneOffEffectByConfig(this.explosionEffectConfig, this.position.x, this.position.y, this.position.y + 200, BASE_EXPLOSION_SCALE * explosionScale , 1.5, true, true)
			// Renderer.getInstance().drawCircle({ x: this.position.x, y: this.position.y, radius: this.triggerRadius, destroyAfterSeconds: 0.3, scale: 1, permanent: false, color: 0xFF0000 })
		}

		const nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(this.position, this.triggerRadius, this.targetColliderType)

		for (const collider of nearbyEntities) {
			const targetEntity = collider.owner
			if (targetEntity.entityType === EntityType.Player) {
				const player = targetEntity as Player
				player.takeDamage(1, this, true)
			} else if (targetEntity.entityType === EntityType.Enemy && targetEntity.nid !== this.owner.nid && !targetEntity.isDead()) {
				const enemy = targetEntity as Enemy
				enemy.onHitByDamageSource(this, this.damage, this.ignoreKnockback)
			}
		}
		
	}

	isPlayerOwned(): boolean {
		return false
	}

	onEntityEnterTrigger(entity: ColliderComponent) {
	}
	
	onEntityLeaveTrigger(entity: ColliderComponent) {
	}
	
	returnToPool() {
		ExplosionGroundHazard.pool.free(this)
	}

	override setOffset(offset: SAT.Vector): void {
		super.setOffset(offset)
	}
}