<template>
	<div id="logo">
		<img src="../../../assets/ui/logos/diebrary.png" alt="" class="logo">
		<div :lang="this.$i18n.locale" class="author-subtitle" v-if="Views.LOGGED_IN === activeView">Author: {{ username }}</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { Views } from '../store/ui-store'

export default {
	name: 'Logo',
	components: {},
	data() {
		return {
			Views: Views,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('user', ['username']),
		...mapState('ui', ['activeView']),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#logo {
	text-align: center;
	&.logged-in{
		.logo{
			width: 620px;
		}
	}
	.logo{
		width: 578px;
	}
	.title {
		filter: drop-shadow(4px 5px 1px black);
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 106px;
			color: @green;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 64px;
		}
	}
	.subtitle {
		filter: drop-shadow(2px 3px 1px black);
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 70px;
			color: @green;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 44px;
		}
	}
	.author-subtitle {
		margin: 30px;
		filter: drop-shadow(2px 3px 1px black);
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 64px;
			color: @green;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 44px;
		}
	}
}
</style>
