export enum SpineDataName {
	PLAYER_SKINS = 'player-skins',
	PLAYER_SKINS_BOW = 'player-bow',
	PLAYER_SKIN_DOG = 'player-quadruped',

	// enemies
	BLIMPIE_1 = 'blimpie-01-spritesheet',
	BLIMPIE_2 = 'blimpie-02-spritesheet',
	BLIMPIE_3 = 'blimpie-03-spritesheet',
	CRAB = 'crab-spritesheet',
	CRYSTAL_SPIDER_1 = 'crystal-spider-01-spritesheet',
	CRYSTAL_SPIDER_2 = 'crystal-spider-02-spritesheet',
	CRYSTAL_SPIDER_3 = 'crystal-spider-03-spritesheet',
	CRYSTAL_SPIDER_4 = 'crystal-spider-04-spritesheet',
	EYEBAT_OLD = 'eyebat-spritesheet',
	EYEBAT_1 = 'eyebat-01-spritesheet',
	EYEBAT_2 = 'eyebat-02-spritesheet',
	LOOT_GOBLIN_JESTER = 'goblin-jester-spritesheet',
	MOSQUITO = 'mosquito-spritesheet',
	MOSQUITO_1 = 'mosquito-01-spritesheet',
	MOSQUITO_2 = 'mosquito-02-spritesheet',
	MOSQUITO_3 = 'mosquito-03-spritesheet',
	MUSHIE = 'mushie-spritesheet',
	MUSHIE_1 = 'mushie-01-spritesheet',
	MUSHIE_2 = 'mushie-02-spritesheet',
	MUSHIE_3 = 'mushie-03-spritesheet',
	PRISMFLY_1 = 'prism-fly-01-spritesheet',
	PRISMFLY_2 = 'prism-fly-02-spritesheet',
	SHAMBLING_MOUND_1 = 'shambling-mound-01-spritesheet',
	SHAMBLING_MOUND_2 = 'shambling-mound-02-spritesheet',
	SHAMBLING_MOUND_3 = 'shambling-mound-03-spritesheet',
	SHAMBLING_MOUND_4 = 'shambling-mound-04-spritesheet',
	SHAMBLING_MOUND_5 = 'shambling-mound-05-spritesheet',
	SHAMBLING_TOWER = 'shambling-tower-spritesheet',
	SHRIEKER = 'shrieker-spritesheet',
	SHRIEKER_VINES = 'shrieker-vines-spritesheet',
	SHRIEKER_KNIGHT = 'shrieker-knight-spritesheet',
	SHRIEKER_KNIGHT_RED_PLUME = 'shrieker-knight-red-plume-spritesheet',
	SHRIEKER_KNIGHT_BLUE_PLUME = 'shrieker-knight-blue-plume-spritesheet',
	SHRIEKER_KNIGHT_RED_SPEAR = 'shrieker-knight-red-spear-spritesheet',
	SHRIEKER_KNIGHT_BLUE_SPEAR = 'shrieker-knight-blue-spear-spritesheet',
	SKELETON_MAGE_1 = 'skeleton-mage-01-spritesheet',
	SKELETON_MAGE_2 = 'skeleton-mage-02-spritesheet',
	SKELETON_MAGE_3 = 'skeleton-mage-02-spritesheet',
	SPIDER_1 = 'spider-01-spritesheet',
	SPIDER_2 = 'spider-02-spritesheet',
	WASP_1 = 'wasp-01-spritesheet',
	WASP_2 = 'wasp-02-spritesheet',
	WASP_3 = 'wasp-03-spritesheet',
	WISP_1 = 'wisp-01-spritesheet',
	WISP_2 = 'wisp-02-spritesheet',
	SNOW_FURY_1 = 'snow-fury-blue-spritesheet',
	SNOW_FURY_2 = 'snow-fury-green-spritesheet',
	SNOW_FURY_3 = 'snow-fury-pink-spritesheet',
	EYESICLE_1 = 'eyesicle-01-spritesheet',
	EYESICLE_2 = 'eyesicle-02-spritesheet',
	WINTER_GOBLIN_1 = 'winter-golblin-01-spritesheet',
	WINTER_GOBLIN_2 = 'winter-golblin-02-spritesheet',
	WINTER_GOBLIN_3 = 'winter-golblin-03-spritesheet',
	WINTER_GOBLIN_4 = 'winter-golblin-04-spritesheet',
	WINTER_GOBLIN_5 = 'winter-golblin-05-spritesheet',

	SNOWBALL = 'snow-ball-spritesheet',
	ICE_ELEMENTAL = 'ice-elemental-spritesheet',
	ICE_ELEMENTAL_2 = 'ice-elemental-02-spritesheet',

	CHAOS_SEER_BOSS = "chaos-seer-boss-spritesheet",
	PRISMFLY = 'prismfly',
	PRISM_FLY_BOSS = 'prism-fly-boss-spritesheet',
	HERMIT_CRAB = 'hermit-crab-spritesheet',
	DREAMER = 'dreamer-boss',
	ICE_DRAKE = 'ice-drake',
	YETI = 'yeti',
	MR_CUDDLES = 'mr-cuddles',

	// pets
	PET_CAT_SPRITESHEET = 'pet-cat-spritesheet',
	PET_HORSE_SPRITESHEET = 'pet-horse-spritesheet',
	PET_PLANT_SPRITESHEET = 'pet-plant-spritesheet',
	PET_ROT_SON_SPRITESHEET = 'pet-rot-son-spritesheet',
	PET_CRYSTAL_SPRITESHEET = 'pet-crystal-spritesheet',
	PET_CHARLOTTE_RAINBOW_PLANT_SPRITESHEET = 'pet-rainbow-plant-spritesheet',
	PET_SEA_TURTLE_SPRITESHEET = 'pet-sea-turtle-spritesheet',
}

export enum PlayerSkinType {
	RANGER = 'ranger',
	RANGER_02 = 'ranger-02',
	RANGER_03 = 'ranger-03',

	DWARF = 'dwarf',
	DWARF_02 = 'dwarf-02',
	DWARF_03 = 'dwarf-03',

	BARBARIAN = 'animal-barbarian',
	BARBARIAN_02 = 'animal-barbarian-02',
	BARBARIAN_03 = 'animal-barbarian-03',

	SORCERESS = 'sorceress',
	SORCERESS_02 = 'sorceress-02',
	SORCERESS_03 = 'sorceress-03',

	WIZARD = 'wizard',
	WIZARD_02 = 'wizard-02',
	WIZARD_03 = 'wizard-03',

	KNIGHT = 'human-knight',
	KNIGHT_02 = 'human-knight-02',
	KNIGHT_03 = 'human-knight-03',

	ELF = 'elf-ranger',
	ELF_02 = 'elf-ranger-02',
	ELF_03 = 'elf-ranger-03',

	AURUM_SOLDIER = "aurum-alliance-soldier",
	SCION_SOLDIER = "scions-of-dawn-soldier",
	ORDER_SOLDIER = "order-of-iron-soldier",
	
	AURUM_BLACKMARK = "aurum-alliance-blackmark",
	SCION_SOLARA = "scions-of-dawn-solara",
	ORDER_REHKOSH = "order-of-iron-rehkosh",

}

export enum EnemyBipedSkinType {
	DEFAULT = 'default',
	SKELEMAGE = 'skelemage',
}
