import { get, has, set } from 'lodash'
import { Container } from 'pixi.js'
import { ContainerTreeAdaptor } from '../engine/graphics/container-tree-adaptor'
import { RealTime } from './time'
import { timeInMilliseconds } from './primitive-types'

export interface ITree {
	name: string
	childCount: number
	getChild(i: number): ITree
}


export function convertStringToType(value: string, type: string, logger: { warn: (s) => void }) {
	if (type === 'boolean') {
		return value === 'true' || value === 't' || value === '1' || value === 'y'
	} else if (type === 'number') {
		return parseFloat(value)
	} else if (type === 'string') {
		return value
	} else {
		logger.warn(`unknown type during conversion:${type}`)
		return value
	}
}

export function logMemberChanges(that, member) {
	const backingMember = '__' + member
	that[backingMember] = that[member]
	Object.defineProperty(that, member, {
		get: function() {
			return that[backingMember]
		},
		set: function(value) {
			if (value !== that[backingMember]) {
				console.log(`[${RealTime.currentFrame}] setting`, member, value)
			}
			that[backingMember] = value
			return that[backingMember]
		}
	});
}

export function getCircularReplacer() {
	const seen = new WeakSet()
	return (key, value) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return
			}
			seen.add(value)
		}
		return value
	}
}

export function ensureEnumUnique(enumeration, errorLogger) {
	const enumMap: Map<string, string> = new Map()
	for (const enumElement in enumeration) {
		if (isNaN(Number(enumElement))) {
			const value = enumeration[enumElement]
			if (enumMap.has(value)) {
				errorLogger.error(`value ${enumElement} shares value with ${enumMap.get(value)}`)
			}
			enumMap.set(value, enumElement)
		}
	}
}

export const printGraph = function(o: object, logger) {
	let s: string = 'digraph dep {\n'
	visitObject(o, (name, element) => (s += `    "${name}" -> "${element}"\n`), logger, false)
	s += '}'
	logger.debug(s)
}

export function setObjectPropWithString(obj: object, propertyPath: string, value: any) {
	if (has(obj, propertyPath)) {
		const valueWas = get(obj, propertyPath)
		set(obj, propertyPath, convertStringToType(value, typeof valueWas, console))
		const valueNow = get(obj, propertyPath)
		console.log(`changed .${propertyPath}: ${valueWas} -> ${valueNow}`)
	} else {
		console.log(`no property: .${propertyPath}`)
	}
}

export const addTraceToMethod = function(o, method, toCall, returnValues?) {
	console.log(addTraceToMethod.name, o.constructor.name, method)
	const methodBackup = o[method] || o.prototype[method]

	const newMethod = function(...args) {
		toCall(this, ...args)
		const r = methodBackup.bind(this)(...args)
		if (returnValues) {
			returnValues(r)
		}
		return r
	}

	if (o[method]) {
		o[method] = newMethod
	} else {
		o.prototype[method] = newMethod
	}
}

// TODO3 Mike: make this more generic and handle more cases (WIP)
export const visitObject = function(obj: object, onVisit, logger: { debug: (s) => void }, visitObjects: boolean) {
	const _visitObjectInner = function(o: object, name: string, depth: number) {
		if (name == null) {
			name = _getName(o)
		}

		if (o instanceof Map) {
			if (visitObjects) {
				onVisit(name, o)
			}

			o.forEach((value, key) => {
				logger.debug(` Map ${name} -> ${value} (${depth})`)
			})
		} else if (typeof o === 'object') {
			if (visitObjects) {
				onVisit(name, o)
			}

			Object.keys(o).forEach((element) => {
				const p = o[element]

				onVisit(name, element)

				if (typeof p === 'object') {
					if (p && !visited[element]) {
						visited[element] = true
						_visitObjectInner(p, element, depth + 1)
					}
				}
			})
		} else {
			onVisit(name, o)
		}
	}

	const _getName = function(o: object) {
		return o.constructor.name
	}

	const visited = {}
	_visitObjectInner(obj, null, 0)
}

export function debugString(myObject) {
	let s = '{'
	for (const key in myObject) {
		if (myObject.hasOwnProperty(key)) {
			const value = myObject[key]
			s += `${key}:${value}, `
		}
	}
	return s + '}'
}

export function debugStringToFixedNumbers(obj, toFixedDigits = 2) {
	let s = '{'
	for (const key in obj) {
		const value = obj[key]
		if (typeof value === 'number') {
			s += `${key}:${value.toFixed(toFixedDigits)}, `
		} else {
			s += `${key}:${value}, `
		}
	}

	return s + '}'
}

export const printTree = function(o: Container, maxDepth: number = undefined) {
	console.log(getTreeString(new ContainerTreeAdaptor(o), maxDepth))
}

export const getTreeString = function(t: ITree, maxDepth: number, depth: number = 0, lastChild: boolean = false) {
	let s: string = ''

	if (maxDepth !== undefined) {
		if (depth >= maxDepth) {
			return ''
		}
	}

	let d: number = depth
	while (d > 1) {
		s += '│   '
		d--
	}

	if (depth > 0) {
		if (lastChild === false) {
			s += '├───'
		} else {
			s += '└───'
		}
	}

	s += `${t.name}\n`

	for (let i: number = 0; i < t.childCount; i++) {
		const child = t.getChild(i)
		s += getTreeString(child, maxDepth, depth + 1, i === t.childCount - 1)
	}
	return s
}

export const assertLight = function(test: any, message: string, logger) {
	if (!test) {
		logger.error(`failed assertion`, message)
	}
}

// from: https://github.com/substack/deep-freeze/blob/master/index.js
export function deepFreeze(o) {
	Object.freeze(o)

	Object.getOwnPropertyNames(o).forEach(function(prop) {
		if (o.hasOwnProperty(prop) && o[prop] !== null && (typeof o[prop] === 'object' || typeof o[prop] === 'function') && !Object.isFrozen(o[prop])) {
			deepFreeze(o[prop])
		}
	})

	return o
}

/**
 * Compares two objects (shallowly) and determine if any keys changed.
 * @returns an array of all changed keys
 */
export function shallowCompareChangedValues(obj1, obj2): string[] {
	const changedKeys = []
	Object.keys(obj1).forEach(key => {
		const equal = obj1[key] === obj2[key]
		if (!equal) {
			changedKeys.push(key)
		}
	})
	return changedKeys
}

interface IDebugToggle {
	obj: any
	prop: string
	code: string
	changeOnServerAndClient: boolean
}
export const debugToggles: IDebugToggle[] = []

export function debugAddClientToggle(obj: any, prop: string, code: string, changeOnServerAndClient: boolean = false) {
	debugToggles.push({ obj, prop, code, changeOnServerAndClient })
}