import { ColliderComponent } from "../../engine/collision/collider-component"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { Enemy } from "../enemies/enemy"
import { GroundHazard, GroundHazardParams } from "./ground-hazard"

export interface TrapParams extends GroundHazardParams {
    activationTime: number
    effectRadius: number

    expiryTime: number
    removalTime: number
}

export abstract class TrapGroundHazard extends GroundHazard {
    effectRadius: number
    activationTime: number

    armed: boolean
    aliveTime: number
    expiryTime: number
    removalTime: number

    removeAtTime: number

    toBeDeleted: boolean

    triggered: boolean = false

    protected _removeAfterTrigger: boolean = true

    override setDefaultValues(defaultValues: any, overrideValues?: TrapParams): void {
        super.setDefaultValues(defaultValues, overrideValues)

        if(overrideValues) {
            this.colliderComponent.isColliderActive = false

            this.effectRadius = overrideValues.effectRadius
            this.activationTime = overrideValues.activationTime

            this.aliveTime = 0
            this.armed = false
            this.toBeDeleted = false

            this.triggered = false

            this.expiryTime = overrideValues.expiryTime
            this.removalTime = overrideValues.removalTime

            this.removeAtTime = Number.MAX_SAFE_INTEGER
        }
    }

    override update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        super.update(delta, now)

        this.aliveTime += delta
        if(!this.triggered) {
            if(!this.armed && this.aliveTime >= this.activationTime) {
                this.armed = true
                this.onArmed()
            }
    
            if(this.aliveTime >= this.expiryTime && !this.toBeDeleted) {
                if(!this.toBeDeleted) {
                    this.onExpired()
                }
            } 
        } else {
            if(this.aliveTime >= this.removeAtTime) {
                this.returnToPool()
            }
        }
    }

    onEntityEnterTrigger(entity: ColliderComponent) {
        this.colliderComponent.isColliderActive = false

        if(!this.triggered) {
            this.onTriggered(entity)
        }
    }

    onEntityLeaveTrigger(entity: ColliderComponent) {
        
    }

    onArmed() {
        this.colliderComponent.isColliderActive = true
    }

    expire() {
        if(!this.toBeDeleted) {
            this.aliveTime = this.expiryTime
            this.toBeDeleted = true

            this.onExpired()
        }
    }

    onExpired() {
        this.toBeDeleted = true
    }

    onTriggered(triggeringEntity: ColliderComponent) {
        this.triggered = true

        const enemyCollidersInArea = CollisionSystem.getInstance().getEntitiesInArea(this.position, this.effectRadius, CollisionLayerBits.PlayerGroundHazard)
        for(let i = 0; i < enemyCollidersInArea.length; ++i) {
            const enemy = enemyCollidersInArea[i].owner as Enemy
            this.applyEffect(enemy)
        }

        if(this._removeAfterTrigger) {
            this.returnToPool()
        } else {
            this.removeAtTime = this.aliveTime + this.removalTime
        }
    }

    abstract applyEffect(enemy: Enemy)
}