import { Vector } from "sat"
import { GameState } from "../engine/game-state"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { EntityType } from "./entity-interfaces"
import { SpritesheetAnimatorComponent } from "../engine/graphics/spritesheet-animator-component"
import { AssetManager } from "../web/asset-manager"
import { ComponentOwner } from "../engine/component-owner"
import { Container, Graphics } from "pixi.js"
import { Renderer } from "../engine/graphics/renderer"

const HOLY_LIGHT_LIFETIME = 0.7
const EFFECT_RADIUS = 80

export interface HolyLightParams {
	radius: number
	position: Vector
}

export class HolyLight implements PoolableObject, ComponentOwner {
	static pool: ObjectPoolTyped<HolyLight, HolyLightParams>
	triedToReturnToPool: boolean

	nid: number
	entityType: EntityType = EntityType.GroundHazard

	position: Vector
	radius: number

	remainingLifetime: number

	visuals: SpritesheetAnimatorComponent

	debugVisuals: Container
	circleGraphics: Graphics

	static emitHolyLight(position: Vector, radius: number) {
		HolyLight.pool.alloc({
			position: position,
			radius: radius,
		})
	}

	constructor() {
		this.makeVisuals()
		this.position = new Vector()
		this.remainingLifetime = HOLY_LIGHT_LIFETIME
		this.triedToReturnToPool = false
	}

	setDefaultValues(defaultValues: any, overrideValues?: HolyLightParams) {
		if(overrideValues) {
			this.radius = overrideValues.radius
			this.position.copy(overrideValues.position)
			this.remainingLifetime = HOLY_LIGHT_LIFETIME
			this.triedToReturnToPool = false

			GameState.addEntity(this)
			this.setVisuals()
			/* if (!this.debugVisuals) {
				this.makeDebugVisuals()
				Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.debugVisuals)
			} */
		}
	}

	cleanup() {
		this.visuals.removeFromScene()
		
		GameState.removeEntity(this)

    if (this.debugVisuals) {
			Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)
			this.debugVisuals.destroy()
			this.debugVisuals = null
		}
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		this.visuals.update(delta)
		this.remainingLifetime -= delta
		if(!this.triedToReturnToPool && this.remainingLifetime <= 0) {
				this.returnToPool()
				this.triedToReturnToPool = true
		}
	}

	makeVisuals() {
		const spriteSheet = AssetManager.getInstance().getAssetByName('spear-holy-light-aoe').spritesheet
		this.visuals = new SpritesheetAnimatorComponent(this, spriteSheet, 'holy-light-aoe-animation', .25)
	}

	setVisuals() {
		this.visuals.overrideZindex(Number.MIN_SAFE_INTEGER)
		this.visuals.addToScene()
		const scale = this.radius / EFFECT_RADIUS
		this.visuals.spriteSheetAnimator.scale.x = scale
		this.visuals.spriteSheetAnimator.scale.y = scale
		this.visuals.spriteSheetAnimator.playAnimationsConcurrently()
	}

	isPlayerOwned(): boolean {
		return true
	}

	returnToPool() {
		HolyLight.pool.free(this)
	}

	makeDebugVisuals() {
		this.debugVisuals = new Container()
		this.debugVisuals['update'] = (delta: timeInSeconds) => {
			this.debugVisuals.x = this.position.x
			this.debugVisuals.y = this.position.y
		}

		this.circleGraphics = new Graphics()

		this.debugVisuals.addChild(this.circleGraphics)

		this.circleGraphics.clear()
		this.circleGraphics.lineStyle(3, 0x40B01A)
		this.circleGraphics.drawCircle(0, 0, this.radius)

		this.debugVisuals.position.x = this.position.x
		this.debugVisuals.position.y = this.position.y
	}
}