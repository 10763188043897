import { BuffIdentifier } from './buff.shared'
import { timeInMilliseconds } from '../utils/primitive-types'
import { BuffTags, StackStyle } from './buff-enums'

/** Immutable data definition class describing the properties of a given buff, used by actual Buff instances. */
export class BuffDefinition {
	identifier: BuffIdentifier

	/** Length of duration when buff is first applied. Can be overridden by apply(). */
	duration: timeInMilliseconds
	/** Should buff last forever or expire? */
	lastsForever?: boolean = false
	/** Should buff stay on the player after they die? */
	persistOnPlayerDeath?: boolean = false
	/** Tags for categorization */
	tags: BuffTags[] = []

	/** Number of stacks to apply when buff is first applied. Can be overridden by apply(). */
	startingStacks: number = 1

	/** Stacking behavior for the buff. */
	stackStyle: StackStyle
	/** Maximum number of stacks that can be applied at once. (doesn't function/makes sense for StackStyle.RollingStackDurationSeparately... I guess) */
	stackLimit?: number
	/** Number of stacks to apply when buff is reapplied. Can be overridden by apply(). */
	reapplyStacks?: number
	/** Length of duration to add/emplace when buff is reapplied. Can be overridden by apply(). */
	reapplyDuration?: timeInMilliseconds
	/** Maximum length that duration can get to if applied numerous times. (only functions/makes sense for StackStyle.IncreaseDuration) */
	durationLimit?: timeInMilliseconds

	/** Whether the buff should be communicated to the player's client. */
	showToClient: boolean

	/** Time between firing the tickFn. */
	tickInterval?: timeInMilliseconds

	/** Called before the buff is applied, checks if this buff can be applied to the entity (currently not called for reapply) */
	canApplyFn?: (entity) => boolean
	/** Called at the tickInterval, or not at all. */
	tickFn?: (buff) => void
	/** Called right after the buff is successfully applied. Useful for stateful buffs who need initialization. */
	applyFn?: (buff) => void
	/** Called when the buff wears off entirely. */
	wearOffFn?: (buff, naturalWearOff?: boolean) => void
	/** Called when the buff's stacks are updated, either through a re-apply(), or from rolling stacks wearing off. */
	updateStacksFn?: (buff, oldStacks: number, newStacks: number) => void

	constructor(config: BuffDefinition) {
		Object.assign(this, config)
	}
}
