import { steamCall } from "../electron-bridge";
import { UI } from "../ui/ui";
import { loginExternalArmorGamesRequest, loginExternalSteamRequest } from "../utils/api/griddle-requests"
import clientLogger from "../utils/client-logger";
import { removeFromLocalStorage, saveToLocalStorage, saveToSessionStorage } from "../utils/local-storage";
import { waitUntilDefined } from "../utils/object-util";
import { timeInMilliseconds } from "../utils/primitive-types";
import { Kongregate } from "./kongregate/kongregate";

export const WebPortalConfig = {
	'none': {
		allowFullscreen: true,
		useCustomAuth: false,
		supportsDeepLinks: true,
		iframeMaxWidth: Number.MAX_SAFE_INTEGER,
		iframeMaxHeight: Number.MAX_SAFE_INTEGER,
		enableCookiePolicy: true,
	},
	'steam': {
		allowFullscreen: true,
		useCustomAuth: true,
		supportsDeepLinks: false, //TODO: investigate how to make this true by intercepting some scheme like diebrary://
		iframeMaxWidth: 1280,
		iframeMaxHeight: 800,
		enableCookiePolicy: false,
	},
	'kongregate': {
		allowFullscreen: false,
		useCustomAuth: true,
		supportsDeepLinks: false,
		iframeMaxWidth: 1280,
		iframeMaxHeight: 800,
		enableCookiePolicy: false,
	},
	'armorgames': {
		allowFullscreen: false,
		useCustomAuth: true,
		supportsDeepLinks: false,
		iframeMaxWidth: 1280,
		iframeMaxHeight: 800,
		enableCookiePolicy: true,
	},
	'addictinggames': {
		allowFullscreen: false,
		useCustomAuth: false,
		supportsDeepLinks: false,
		iframeMaxWidth: 1280,
		iframeMaxHeight: 800,
		enableCookiePolicy: true,
	},
	'iogamesspace': {
		allowFullscreen: false,
		useCustomAuth: false,
		supportsDeepLinks: false,
		iframeMaxWidth: 1280,
		iframeMaxHeight: 800,
		enableCookiePolicy: true,
	},
	'itchio': {
		allowFullscreen: true,
		useCustomAuth: false,
		supportsDeepLinks: false,
		iframeMaxWidth: 1280,
		iframeMaxHeight: 800,
		enableCookiePolicy: true,
	},
	'crazygames': {
		allowFullscreen: true,
		useCustomAuth: false, // crazygames supports custom auth, but we are not integrating for now
		supportsDeepLinks: false,
		iframeMaxWidth: 1128,
		iframeMaxHeight: 615,
		enableCookiePolicy: true,
	},
}
let webPortalType: WebPortalType

export function getWebPortalConfig() {
	return WebPortalConfig[webPortalType]
}

export type WebPortalType = keyof typeof WebPortalConfig

export async function initializeWebPortalSpecificOverrides(portal: string) {
	clientLogger.info(`Web portal detected from URL: ${portal}`)
	webPortalType = portal as unknown as WebPortalType
	if (WebPortalConfig[webPortalType] === undefined) {
		console.error(`Web portal type not found: ${portal}, defaulting to 'none'`)
		webPortalType = "none"
	}

	// set cookie UI by portal type TODO fix for steam
	UI.getInstance().emitMutation('ui/updateShowCookiesUI', WebPortalConfig[webPortalType].enableCookiePolicy ? true : false)

	UI.getInstance().emitMutation('ui/setWebPortalType', webPortalType)
	UI.getInstance().emitMutation('ui/setWebPortalConfig', WebPortalConfig[webPortalType])

	if (webPortalType === "steam") {
		removeFromLocalStorage('auth-token')
		UI.getInstance().emitMutation('user/setExternalUserAccountType', 'steam')
		UI.getInstance().emitMutation('user/setExternalUserAccountLoggedIn', false)
		UI.getInstance().emitMutation('user/setExternalConnectionsLoading', true)
		console.log('Web portal init to steam')

		// Electron has already kicked off the Steam auth flow, so we just need to wait for the events

	} else if (webPortalType === "kongregate") {
		removeFromLocalStorage('auth-token')
		// initialize UI to loading state for kong
		UI.getInstance().emitMutation('user/setExternalUserAccountType', 'kongregate')
		UI.getInstance().emitMutation('user/setExternalUserAccountLoggedIn', false)
		UI.getInstance().emitMutation('user/setExternalConnectionsLoading', true)

		// init their kong API, our kong singleton, then try to login to our game if user is logged into kong
		setTimeout(async () => {
			const kongAPI: any = await waitUntilDefined({ value: window['kongregateAPI'] }, 100, 60 * 1000)
			kongAPI.loadAPI(async () => {
				const api = kongAPI.getAPI()
				const kong = Kongregate.getInstance(api)
				// console.log({ ourKong: kong, kongAPI, api, kongregate: kong.API })
				await kong.tryImmediateLogin()
				clientLogger.info(`Kongregate user: ${kong.username}${kong.API.services.isGuest() ? ' (guest)' : ''}`, kong.username)
			})
		}, 0)
	} else if (webPortalType === 'armorgames') {
		removeFromLocalStorage('auth-token')
		UI.getInstance().emitMutation('user/setExternalUserAccountType', 'armorgames')
		UI.getInstance().emitMutation('user/setExternalUserAccountLoggedIn', false)

		const searchParams = new URLSearchParams(window.location.search)
		const userId = searchParams.get('user_id')
		const authToken = searchParams.get('auth_token')

		if (authToken && userId && authToken.length > 0 && userId.length > 0) {
			// let's try to login
			UI.getInstance().emitMutation('user/setExternalConnectionsLoading', true)
			try {
				console.log(`Attempting to login through Armor Games...`)
				const result = await loginExternalArmorGamesRequest({ externalUserId: userId, externalAuthToken: authToken })
				console.log("Login through Armor Games successful!")
				saveToLocalStorage('auth-token', result.authentication.token)
				saveToSessionStorage('auth-token', result.authentication.token)
				UI.getInstance().emitAction('user/fetchInitialUserState')
				UI.getInstance().emitMutation('user/setExternalUserAccountLoggedIn', true)
			} catch (err) {
				console.error(`Error when trying to login with Armor Games`, err)
				UI.getInstance().emitMutation('user/setExternalError', true)
			}

			UI.getInstance().emitMutation('user/setExternalConnectionsLoading', false)
		}
	}
}

export async function handleReceivedSteamAuthTicket(username: string, userId, authToken: string, appId: number) {
	console.log(`handleReceivedSteamAuthTicket`)
	try {
		console.log(`Attempting to login through Steam...`)
		const result = await loginExternalSteamRequest({ username, externalUserId: userId, externalAuthToken: authToken, appId })
		console.log("Login through Steam successful!")
		saveToLocalStorage('auth-token', result.authentication.token)
		saveToSessionStorage('auth-token', result.authentication.token)
		UI.getInstance().emitAction('user/fetchInitialUserState')
		UI.getInstance().emitMutation('user/setExternalUserAccountLoggedIn', true)
	} catch (err) {
		console.error(`Error when trying to login with Steam `, err)
		UI.getInstance().emitMutation('user/setExternalError', true)
		UI.getInstance().emitAction('user/fetchInitialUserState', true)
	}

	UI.getInstance().emitMutation('user/setExternalConnectionsLoading', false)
}