import { Vector } from "sat"
import { TemporalDistortionHazard, TemporalDistortionParams } from "../entities/hazards/temporal-distortion-hazard"
import { timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { PropPlacer } from "../world-generation/prop-placement"
import { EventStartData, GameplayEvent } from "./gameplay-event-definitions"
import { InGameTime } from "../utils/time"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"
import { EventTypes } from "./event-types"

const SLOW_RADIUS = 700
const SLOW_AMOUNT = 0.35

export class TemporalDistortionGameplayEvent implements GameplayEvent {
    private startData: EventStartData
    
    private eventPosition: Vector
    private startTime: timeInSeconds
    private isActive: boolean

    constructor() {
        this.eventPosition = new Vector()
        this.isActive = false

        if (!TemporalDistortionHazard.pool) {
            TemporalDistortionHazard.pool = new ObjectPoolTyped<TemporalDistortionHazard, TemporalDistortionParams>(() => {
                return new TemporalDistortionHazard()
            }, {}, 1, 1)
        }
    }

    setStartData(data: EventStartData) {
        this.startData = data
    }
    
    startEvent() {
        // get random position nearby
		const minDist = SLOW_RADIUS + 400
		const maxDist = SLOW_RADIUS + 600
		PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, SLOW_RADIUS, this.eventPosition)

        // plop a zone
        TemporalDistortionHazard.pool.alloc({
            position: this.eventPosition,
            slowAmount: SLOW_AMOUNT,
            triggerRadius: SLOW_RADIUS,
            lifeTime: this.startData.timeLimit
        })

        this.startTime = InGameTime.timeElapsedInSeconds

        this.isActive = true
    }

    update(delta: timeInSeconds) {
        if (this.isActive) {
            if (InGameTime.timeElapsedInSeconds >= this.startTime + this.startData.timeLimit) {
                GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.TemporalDistortion)
                this.isActive = false
            }
        }
        
    }
}
