import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { PersistedBehaviours, VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const shriekerVines: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_VINES,
    appearance: {
        asset: SpineDataName.SHRIEKER_VINES,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    states: {
        persisted: [
            {
                behaviour: PersistedBehaviours.SHRIEK,
                enemyCount: 3,
                range: 375
            }
        ]
    }
}

export const shriekerKnight: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_KNIGHT,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
}

export const shriekerKnightRedPlume: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_KNIGHT_RED_PLUME,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT_RED_PLUME,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: 500
}

export const shriekerKnightBluePlume: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT_BLUE_PLUME,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: 500
}

export const shriekerKnightBluePlumeFlying: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME_FLYING,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT_BLUE_PLUME,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: 500,
    layer: CollisionLayerBits.FlyingEnemy
}

export const shriekerKnightRedSpear: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_KNIGHT_RED_SPEAR,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT_RED_SPEAR,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: 500
}

export const shriekerKnightBlueSpear: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT_BLUE_SPEAR,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: 500
}

export const shriekerKnightBlueSpearFlying: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT_BLUE_SPEAR,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: 500,
    layer: CollisionLayerBits.FlyingEnemy
}
