function addDeviceScalingToWidthHeight(args, scale) {
	return {
		...args,
		width: Math.ceil(args.width / scale),
		height: Math.ceil(args.height / scale),
	}
}

function removeScalingFromWidthHeight(ScaledArgs, scale) {
	return {
		...ScaledArgs,
		width: Math.floor(ScaledArgs.width * scale),
		height: Math.floor(ScaledArgs.height * scale),
	}
}

module.exports = {
	addDeviceScalingToWidthHeight,
	removeScalingFromWidthHeight,
}
