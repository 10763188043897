
import { AnimatedSprite, Container, Spritesheet } from "pixi.js"
import { SPRITE_ANIMTATION_CONFIGS, SpriteAnimationConfig } from "../../spine-config/sprite-animation-config"

export class SpriteSheetAnimator extends Container {

    spriteSheet: Spritesheet
    animatedSprites: Map<string, AnimatedSprite>
    
    activeSprite: AnimatedSprite
    activeAnimation: string

    playConcurrently: boolean


    constructor(spriteSheet: Spritesheet, defaultAnimation: string, overrideAnimationSpeed?: number, playAnimationsConcurrently?: boolean) {
        super()

        this.spriteSheet = spriteSheet

        this.animatedSprites = new Map()

        const animSpeed = overrideAnimationSpeed !== undefined ? overrideAnimationSpeed : 0.5

        this.playConcurrently = playAnimationsConcurrently


        for(const animationName in spriteSheet.animations) {
            const frames = spriteSheet.animations[animationName]
            
            const animatedSprite = new AnimatedSprite(frames, false)
            animatedSprite.updateAnchor = false

            const config = SPRITE_ANIMTATION_CONFIGS[animationName] as SpriteAnimationConfig
            if(config) {
                if(config.overrideSpeed) {
                    animatedSprite.animationSpeed = config.overrideSpeed
                } else {
                    animatedSprite.animationSpeed = animSpeed
                }

                animatedSprite.loop = config.loop

				if (this.playConcurrently) {
					animatedSprite.onComplete = () => {
						animatedSprite.visible = false
					}
				} else if(config.nextAnimation) {
					animatedSprite.onComplete = () => {
						this.playAnimation(config.nextAnimation)
					}
				}

                if(config.anchor) {
                    animatedSprite.anchor.x = config.anchor.x
                    animatedSprite.anchor.y = config.anchor.y
                } else {
                    animatedSprite.anchor.x = 0.5
                    animatedSprite.anchor.y = 0.5
                }

                if(config.anchor) {
                    animatedSprite.anchor.x = config.anchor.x
                    animatedSprite.anchor.y = config.anchor.y
                } else {
                    animatedSprite.anchor.x = 0.5
                    animatedSprite.anchor.y = 0.5
                }
            } else {
                animatedSprite.animationSpeed = animSpeed
                animatedSprite.anchor.x = 0.5
                animatedSprite.anchor.y = 0.5
            }

            animatedSprite.visible = false
            this.addChild(animatedSprite)

            this.animatedSprites.set(animationName, animatedSprite)
        }

        this.activeSprite = this.animatedSprites.get(defaultAnimation)
        this.activeSprite.visible = true
        this.activeSprite.play()

        this.activeAnimation = defaultAnimation
        
        // Debug for where the center is
		// const gfx = new Graphics()
        // gfx.beginFill(0x0000FF, 1)
        // gfx.drawCircle(0,0, 15)
        // gfx.endFill()
        // this.addChild(gfx)
    }

    playAnimation(name: string, nextAnim?: string, callback?: () => void) {
        // would be great if we didn't have to do a string comparison here
        if(this.activeAnimation === name || this.playConcurrently) {
            return
        }

        this.activeSprite.stop()
        this.activeSprite.visible = false

        this.activeSprite = this.animatedSprites.get(name)
        this.activeSprite.visible = true
        this.activeSprite.gotoAndPlay(0)

        if (nextAnim) {
            this.activeSprite.loop = false
            
            this.activeSprite.onComplete = () => {
                this.playAnimation(nextAnim)

                if (callback) {
                    callback()
                }
            }
        } else if (callback) {
            const active = this.activeSprite // just in case we play another anim before looping
            // will be really hard to debug if that happens too
            this.activeSprite.onLoop = () => {
                active.onLoop = null
                callback()
            }
        }

        this.activeAnimation = name
    }

	playAnimationsConcurrently() {
		this.activeSprite.stop()
        this.activeSprite.visible = false
		this.animatedSprites.forEach((value, key) => {
			value.visible = true
			value.gotoAndPlay(0)
		})
	}

    update(delta) {
		if (this.playConcurrently) {
			this.animatedSprites.forEach((value, key) => {
				value.update(delta * 100)
			})
		} else {
			this.activeSprite.update(delta * 100)
		}
	}

    restartCurrentAnim() {
        this.activeSprite.stop()
        this.activeSprite.gotoAndPlay(0)
    }
}
