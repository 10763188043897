import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const hugeShamblingMound: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND,
    name: ENEMY_NAME.HUGE_SHAMBLING_MOUND,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_1,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 2.5
    },
	knockbackResist: 6,
	maxHealth: 8,
}

export const shamblingMound2: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND,
    name: ENEMY_NAME.SHAMBLING_MOUND_2,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT
    },
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.65,
}

export const hugeShamblingMound2: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND_2,
    name: ENEMY_NAME.HUGE_SHAMBLING_MOUND_2,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 2.5
    },
	maxHealth: 8,
	knockbackResist: 6
}

export const shamblingMound3: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND,
    name: ENEMY_NAME.SHAMBLING_MOUND_3,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_3,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT
    },
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.70,
}

export const hugeShamblingMound3: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND_3,
    name: ENEMY_NAME.HUGE_SHAMBLING_MOUND_3,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_3,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 2.5
    },
	maxHealth: 8,
	knockbackResist: 6
}

export const shamblingMound4: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND,
    name: ENEMY_NAME.SHAMBLING_MOUND_4,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_4,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT
    },
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.75,
}

export const hugeShamblingMound4: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND_4,
    name: ENEMY_NAME.HUGE_SHAMBLING_MOUND_4,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_4,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 2.5
    },
	maxHealth: 8,
	knockbackResist: 6
}


export const shamblingMound5: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND,
    name: ENEMY_NAME.SHAMBLING_MOUND_5,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_5,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT
    },
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.80,
}

export const hugeShamblingMound5: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND_5,
    name: ENEMY_NAME.HUGE_SHAMBLING_MOUND_5,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_5,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 2.5
    },
	maxHealth: 8,
	knockbackResist: 6
}