{
  "Level_Up": {
    "effectNames": {
      "Level_Up": [
        0,
        925
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "Player_Die": {
    "effectNames": {
      "Player_Die_01": [
        1425,
        473
      ],
      "Player_Die_02": [
        2398,
        469
      ],
      "Player_Die_03": [
        3367,
        286
      ],
      "Player_Die_04": [
        4153,
        308
      ]
    },
    "volume": 0.7,
    "volumeInterval": [
      0.5,
      0.7
    ],
    "rate": 1.0,
    "rateInterval": [
      0.85,
      1.0
    ]
  },
  "Projectile_Blip": {
    "effectNames": {
      "Projectile_Blip_01": [
        4961,
        310
      ],
      "Projectile_Blip_02": [
        5771,
        242
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.65,
      0.8
    ],
    "rate": 1.0,
    "rateInterval": [
      0.75,
      1.2
    ]
  },
  "Projectile_Fireball": {
    "effectNames": {
      "Projectile_Fireball_01": [
        6513,
        620
      ],
      "Projectile_Fireball_02": [
        7633,
        561
      ],
      "Projectile_Fireball_03": [
        8694,
        504
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.65,
      0.8
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.1
    ]
  },
  "Projectile_Pulse": {
    "effectNames": {
      "Projectile_Pulse_01": [
        9698,
        103
      ],
      "Projectile_Pulse_02": [
        10301,
        103
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.65,
      0.8
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.1
    ]
  },
  "Projectile_Zap": {
    "effectNames": {
      "Projectile_Zap": [
        10904,
        305
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.1
    ]
  },
  "UI_Menu_Select": {
    "effectNames": {
      "UI_Menu_Select": [
        11709,
        251
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Receive_Pit": {
    "effectNames": {
      "UI_Receive_Pit": [
        12460,
        1620
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Throw_Pit": {
    "effectNames": {
      "UI_Throw_Pit": [
        14580,
        1670
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "AMB_Smelting_LP": {
    "effectNames": {
      "AMB_Smelting_LP": [
        16750,
        6030
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Slider_Tick": {
    "effectNames": {
      "UI_Slider_Tick": [
        23280,
        134
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Dialog_Major_Open": {
    "effectNames": {
      "UI_Dialog_Major_Open": [
        23914,
        635
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Dialog_Minor_Open": {
    "effectNames": {
      "UI_Dialog_Minor_Open": [
        25049,
        186
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Toggle": {
    "effectNames": {
      "UI_Toggle": [
        25735,
        56
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Smelting_Reward": {
    "effectNames": {
      "UI_Smelting_Reward": [
        26291,
        1224
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Smelting": {
    "effectNames": {
      "UI_Click_Smelting": [
        28015,
        675
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Number_Tick": {
    "effectNames": {
      "UI_Number_Tick": [
        29190,
        283
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Close_Minor": {
    "effectNames": {
      "UI_Menu_Close_Minor": [
        29973,
        499
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Open_Minor": {
    "effectNames": {
      "UI_Menu_Open_Minor": [
        30972,
        468
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Close_Major": {
    "effectNames": {
      "UI_Menu_Close_Major": [
        31940,
        649
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Open_Major": {
    "effectNames": {
      "UI_Menu_Open_Major": [
        33089,
        731
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Minor": {
    "effectNames": {
      "UI_Click_Minor": [
        34320,
        218
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Major": {
    "effectNames": {
      "UI_Click_Major": [
        35038,
        700
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Daily_Reward": {
    "effectNames": {
      "UI_Click_Daily_Reward": [
        36238,
        2324
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Throw_In_Pit": {
    "effectNames": {
      "UI_Throw_In_Pit": [
        39062,
        1548
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Pit_Reward": {
    "effectNames": {
      "UI_Pit_Reward": [
        41110,
        1318
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Impact_Enemy_Bone": {
    "effectNames": {
      "SFX_Impact_Enemy_Bone_01": [
        42928,
        290
      ],
      "SFX_Impact_Enemy_Bone_02": [
        43718,
        290
      ],
      "SFX_Impact_Enemy_Bone_03": [
        44508,
        326
      ],
      "SFX_Impact_Enemy_Bone_04": [
        45334,
        290
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Crystal": {
    "effectNames": {
      "SFX_Impact_Enemy_Crystal_01": [
        46124,
        383
      ],
      "SFX_Impact_Enemy_Crystal_02": [
        47007,
        378
      ],
      "SFX_Impact_Enemy_Crystal_03": [
        47885,
        259
      ],
      "SFX_Impact_Enemy_Crystal_04": [
        48644,
        275
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Flesh": {
    "effectNames": {
      "SFX_Impact_Enemy_Flesh_01": [
        49419,
        203
      ],
      "SFX_Impact_Enemy_Flesh_02": [
        50122,
        245
      ],
      "SFX_Impact_Enemy_Flesh_03": [
        50867,
        264
      ],
      "SFX_Impact_Enemy_Flesh_04": [
        51631,
        278
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Generic": {
    "effectNames": {
      "SFX_Impact_Enemy_Generic_01": [
        52409,
        353
      ],
      "SFX_Impact_Enemy_Generic_02": [
        53262,
        342
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Spear": {
    "effectNames": {
      "SFX_Spear_01": [
        54104,
        430
      ],
      "SFX_Spear_02": [
        55034,
        417
      ],
      "SFX_Spear_03": [
        55951,
        398
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Bow": {
    "effectNames": {
      "SFX_Bow_01": [
        56849,
        368
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.2
    ]
  },
  "SFX_Boomerang": {
    "effectNames": {
      "SFX_Boomerang_01": [
        57717,
        229
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_XP_Pickup": {
    "effectNames": {
      "SFX_XP_Pickup": [
        58446,
        380
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Impact_Enemy_Insect": {
    "effectNames": {
      "SFX_Impact_Enemy_Insect_01": [
        59326,
        287
      ],
      "SFX_Impact_Enemy_Insect_02": [
        60113,
        267
      ],
      "SFX_Impact_Enemy_Insect_03": [
        60880,
        296
      ],
      "SFX_Impact_Enemy_Insect_04": [
        61676,
        367
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Mush": {
    "effectNames": {
      "SFX_Impact_Enemy_Mush_01": [
        62543,
        228
      ],
      "SFX_Impact_Enemy_Mush_02": [
        63271,
        250
      ],
      "SFX_Impact_Enemy_Mush_03": [
        64021,
        191
      ],
      "SFX_Impact_Enemy_Mush_04": [
        64712,
        203
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Plant": {
    "effectNames": {
      "SFX_Impact_Enemy_Plant_01": [
        65415,
        462
      ],
      "SFX_Impact_Enemy_Plant_02": [
        66377,
        445
      ],
      "SFX_Impact_Enemy_Plant_03": [
        67322,
        394
      ],
      "SFX_Impact_Enemy_Plant_04": [
        68216,
        404
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Rock": {
    "effectNames": {
      "SFX_Impact_Enemy_Rock_01": [
        69120,
        328
      ],
      "SFX_Impact_Enemy_Rock_02": [
        69948,
        297
      ],
      "SFX_Impact_Enemy_Rock_03": [
        70745,
        362
      ],
      "SFX_Impact_Enemy_Rock_04": [
        71607,
        418
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Shell": {
    "effectNames": {
      "SFX_Impact_Enemy_Shell_01": [
        72525,
        260
      ],
      "SFX_Impact_Enemy_Shell_02": [
        73285,
        231
      ],
      "SFX_Impact_Enemy_Shell_03": [
        74016,
        240
      ],
      "SFX_Impact_Enemy_Shell_04": [
        74756,
        214
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Attack": {
    "effectNames": {
      "SFX_Boss_Fungi_Attack_01": [
        75470,
        1414
      ],
      "SFX_Boss_Fungi_Attack_02": [
        77384,
        1366
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Death_Vox": {
    "effectNames": {
      "SFX_Boss_Fungi_Death_Vox": [
        79250,
        1676
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Emerge": {
    "effectNames": {
      "SFX_Boss_Fungi_Emerge": [
        81426,
        1045
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Growl": {
    "effectNames": {
      "SFX_Boss_Fungi_Growl_01": [
        82971,
        2661
      ],
      "SFX_Boss_Fungi_Growl_02": [
        86132,
        1994
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Hit": {
    "effectNames": {
      "SFX_Boss_Fungi_Hit_01": [
        88626,
        873
      ],
      "SFX_Boss_Fungi_Hit_02": [
        89999,
        670
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Slime_Bubbles": {
    "effectNames": {
      "SFX_Boss_Fungi_Slime_Bubbles_01": [
        91169,
        1689
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Splashes": {
    "effectNames": {
      "SFX_Boss_Fungi_Splashes_01": [
        93358,
        1080
      ],
      "SFX_Boss_Fungi_Splashes_02": [
        94938,
        443
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Spore_Pop": {
    "effectNames": {
      "SFX_Boss_Fungi_Spore_Pop_01": [
        95881,
        497
      ],
      "SFX_Boss_Fungi_Spore_Pop_02": [
        96878,
        557
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Augment": {
    "effectNames": {
      "SFX_Item_Augment": [
        97935,
        329
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Discard": {
    "effectNames": {
      "SFX_Item_Discard": [
        98764,
        560
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Drop_Astronomical": {
    "effectNames": {
      "SFX_Item_Drop_Astronomical": [
        99824,
        495
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Drop_Default": {
    "effectNames": {
      "SFX_Item_Drop_Default_01": [
        100819,
        313
      ],
      "SFX_Item_Drop_Default_02": [
        101632,
        302
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Drop_Epic": {
    "effectNames": {
      "SFX_Item_Drop_Epic": [
        102434,
        927
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Drop_Legendary": {
    "effectNames": {
      "SFX_Item_Drop_Legendary": [
        103861,
        846
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Equip": {
    "effectNames": {
      "SFX_Item_Equip": [
        105207,
        292
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Place": {
    "effectNames": {
      "SFX_Item_Place": [
        105999,
        173
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Swap": {
    "effectNames": {
      "SFX_Item_Swap": [
        106672,
        362
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Loot_Pickup": {
    "effectNames": {
      "SFX_Loot_Pickup": [
        107534,
        855
      ]
    },
    "volume": 0.8,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Player_Footfall_Dirt": {
    "effectNames": {
      "Player_Footfall_Dirt_01": [
        108889,
        112
      ],
      "Player_Footfall_Dirt_02": [
        109501,
        135
      ],
      "Player_Footfall_Dirt_03": [
        110136,
        168
      ],
      "Player_Footfall_Dirt_04": [
        110804,
        163
      ],
      "Player_Footfall_Dirt_05": [
        111467,
        139
      ],
      "Player_Footfall_Dirt_06": [
        112106,
        147
      ],
      "Player_Footfall_Dirt_07": [
        112753,
        135
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.9
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Hit_Critical": {
    "effectNames": {
      "Player_Hit_Critical_01": [
        113388,
        325
      ],
      "Player_Hit_Critical_02": [
        114213,
        349
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Hit_Health": {
    "effectNames": {
      "Player_Hit_Health_01": [
        115062,
        263
      ],
      "Player_Hit_Health_02": [
        115825,
        241
      ],
      "Player_Hit_Health_03": [
        116566,
        255
      ],
      "Player_Hit_Health_04": [
        117321,
        265
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Instadeath": {
    "effectNames": {
      "Player_Instadeath_01": [
        118086,
        1322
      ],
      "Player_Instadeath_02": [
        119908,
        1247
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Shield_Block": {
    "effectNames": {
      "Player_Shield_Block_01": [
        121655,
        534
      ],
      "Player_Shield_Block_02": [
        122689,
        408
      ],
      "Player_Shield_Block_03": [
        123597,
        394
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Teleport": {
    "effectNames": {
      "Player_Teleport": [
        124491,
        1000
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Area_Buff": {
    "effectNames": {
      "SFX_Player_Area_Buff": [
        125991,
        1017
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Area_Debuff": {
    "effectNames": {
      "SFX_Player_Area_Debuff": [
        127508,
        1216
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Buff": {
    "effectNames": {
      "SFX_Player_Buff": [
        129224,
        951
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Fully_Healed": {
    "effectNames": {
      "SFX_Player_Fully_Healed": [
        130675,
        1118
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Player_Roll": {
    "effectNames": {
      "SFX_Player_Roll_01": [
        132293,
        310
      ],
      "SFX_Player_Roll_02": [
        133103,
        333
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Speed_Buff": {
    "effectNames": {
      "SFX_Player_Speed_Buff": [
        133936,
        893
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Shield_Regen_Pip": {
    "effectNames": {
      "SFX_Shield_Regen_Pip": [
        135329,
        381
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Level_Up": {
    "effectNames": {
      "SFX_Level_Up": [
        136210,
        1662
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Player_Dodge": {
    "effectNames": {
      "SFX_Player_Dodge_01": [
        138372,
        433
      ],
      "SFX_Player_Dodge_02": [
        139305,
        525
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Shield_Regen": {
    "effectNames": {
      "SFX_Shield_Regen": [
        140330,
        433
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Enemy_Asp_Death": {
    "effectNames": {
      "SFX_Enemy_Asp_Death": [
        141263,
        961
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Asp_Hit": {
    "effectNames": {
      "SFX_Enemy_Asp_Hit_01": [
        142724,
        355
      ],
      "SFX_Enemy_Asp_Hit_02": [
        143579,
        381
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Asp_Shoot": {
    "effectNames": {
      "SFX_Enemy_Asp_Shoot_01": [
        144460,
        802
      ],
      "SFX_Enemy_Asp_Shoot_02": [
        145762,
        653
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Blimpie_Death": {
    "effectNames": {
      "SFX_Enemy_Blimpie_Death": [
        146915,
        476
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Blimpie_Hit": {
    "effectNames": {
      "SFX_Enemy_Blimpie_Hit": [
        147891,
        287
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Blimpie_Shoot": {
    "effectNames": {
      "SFX_Enemy_Blimpie_Shoot_01": [
        148678,
        88
      ],
      "SFX_Enemy_Blimpie_Shoot_02": [
        149266,
        81
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_BoneSpirit_Death": {
    "effectNames": {
      "SFX_Enemy_BoneSpirit_Death": [
        149847,
        1204
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_BoneSpirit_Hit": {
    "effectNames": {
      "SFX_Enemy_BoneSpirit_Hit_01": [
        151551,
        545
      ],
      "SFX_Enemy_BoneSpirit_Hit_02": [
        152596,
        595
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_BoneSpirit_Shoot": {
    "effectNames": {
      "SFX_Enemy_BoneSpirit_Shoot_01": [
        153691,
        642
      ],
      "SFX_Enemy_BoneSpirit_Shoot_02": [
        154833,
        667
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Crab_Death": {
    "effectNames": {
      "SFX_Enemy_Crab_Death": [
        156000,
        1039
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Crab_Hit": {
    "effectNames": {
      "SFX_Enemy_Crab_Hit_01": [
        157539,
        540
      ],
      "SFX_Enemy_Crab_Hit_02": [
        158579,
        585
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Crab_Shoot": {
    "effectNames": {
      "SFX_Enemy_Crab_Shoot_01": [
        159664,
        181
      ],
      "SFX_Enemy_Crab_Shoot_02": [
        160345,
        83
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_CrystalSpider_Death": {
    "effectNames": {
      "SFX_Enemy_CrystalSpider_Death": [
        160928,
        463
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_CrystalSpider_Hit": {
    "effectNames": {
      "SFX_Enemy_CrystalSpider_Hit_01": [
        161891,
        353
      ],
      "SFX_Enemy_CrystalSpider_Hit_02": [
        162744,
        320
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_CrystalSpider_Shoot": {
    "effectNames": {
      "SFX_Enemy_CrystalSpider_Shoot_01": [
        163564,
        548
      ],
      "SFX_Enemy_CrystalSpider_Shoot_02": [
        164612,
        573
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Death": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Death": [
        165685,
        999
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Fall": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Fall": [
        167184,
        455
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Hit": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Hit_01": [
        168139,
        675
      ],
      "SFX_Enemy_DeathDrake_Hit_02": [
        169314,
        732
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Shoot": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Shoot_01": [
        170546,
        1062
      ],
      "SFX_Enemy_DeathDrake_Shoot_02": [
        172108,
        956
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Death": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Death": [
        173564,
        1710
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Hit": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Hit_01": [
        175774,
        747
      ],
      "SFX_Enemy_DragonSkull_Hit_02": [
        177021,
        850
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Open": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Open": [
        178371,
        414
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Rumble": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Rumble": [
        179285,
        1338
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Shoot": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Shoot_01": [
        181123,
        864
      ],
      "SFX_Enemy_DragonSkull_Shoot_02": [
        182487,
        759
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Death": {
    "effectNames": {
      "SFX_Enemy_Ent_Death": [
        183746,
        1190
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Fall": {
    "effectNames": {
      "SFX_Enemy_Ent_Fall_01": [
        185436,
        584
      ],
      "SFX_Enemy_Ent_Fall_02": [
        186520,
        790
      ],
      "SFX_Enemy_Ent_Fall_03": [
        187810,
        517
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Hit": {
    "effectNames": {
      "SFX_Enemy_Ent_Hit_01": [
        188827,
        530
      ],
      "SFX_Enemy_Ent_Hit_02": [
        189857,
        630
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Shoot": {
    "effectNames": {
      "SFX_Enemy_Ent_Shoot_01": [
        190987,
        600
      ],
      "SFX_Enemy_Ent_Shoot_02": [
        192087,
        620
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Swipe": {
    "effectNames": {
      "SFX_Enemy_Ent_Swipe_01": [
        193207,
        340
      ],
      "SFX_Enemy_Ent_Swipe_02": [
        194047,
        319
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Walk": {
    "effectNames": {
      "SFX_Enemy_Ent_Walk_01": [
        194866,
        604
      ],
      "SFX_Enemy_Ent_Walk_02": [
        195970,
        584
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_EyeBat_Death": {
    "effectNames": {
      "SFX_Enemy_EyeBat_Death": [
        197054,
        795
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_EyeBat_Hit_02": {
    "effectNames": {
      "SFX_Enemy_EyeBat_Hit_01": [
        198349,
        483
      ],
      "SFX_Enemy_EyeBat_Hit_02": [
        199332,
        639
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_EyeBat_Shoot": {
    "effectNames": {
      "SFX_Enemy_EyeBat_Shoot_01": [
        200471,
        447
      ],
      "SFX_Enemy_EyeBat_Shoot_02": [
        201418,
        525
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Goblin_Death": {
    "effectNames": {
      "SFX_Enemy_Goblin_Death_01": [
        202443,
        737
      ],
      "SFX_Enemy_Goblin_Death_02": [
        203680,
        830
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Goblin_Hit": {
    "effectNames": {
      "SFX_Enemy_Goblin_Hit_01": [
        205010,
        609
      ],
      "SFX_Enemy_Goblin_Hit_02": [
        206119,
        639
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Goblin_Throw": {
    "effectNames": {
      "SFX_Enemy_Goblin_Throw": [
        207258,
        186
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Golem_Death": {
    "effectNames": {
      "SFX_Enemy_Golem_Death": [
        207944,
        632
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Golem_Hit": {
    "effectNames": {
      "SFX_Enemy_Golem_Hit_01": [
        209076,
        341
      ],
      "SFX_Enemy_Golem_Hit_02": [
        209917,
        390
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Golem_Shoot": {
    "effectNames": {
      "SFX_Enemy_Golem_Shoot_01": [
        210807,
        959
      ],
      "SFX_Enemy_Golem_Shoot_02": [
        212266,
        831
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_HermitCrab_ArmorUp": {
    "effectNames": {
      "SFX_Enemy_HermitCrab_ArmorUp": [
        213597,
        334
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mosquito_Death": {
    "effectNames": {
      "SFX_Enemy_Mosquito_Death": [
        214431,
        482
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mosquito_Hit": {
    "effectNames": {
      "SFX_Enemy_Mosquito_Hit_01": [
        215413,
        454
      ],
      "SFX_Enemy_Mosquito_Hit_02": [
        216367,
        483
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mosquito_Shoot": {
    "effectNames": {
      "SFX_Enemy_Mosquito_Shoot_01": [
        217350,
        484
      ],
      "SFX_Enemy_Mosquito_Shoot_02": [
        218334,
        490
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Death": {
    "effectNames": {
      "SFX_Enemy_Mushie_Death": [
        219324,
        1069
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Hit": {
    "effectNames": {
      "SFX_Enemy_Mushie_Hit_01": [
        220893,
        647
      ],
      "SFX_Enemy_Mushie_Hit_02": [
        222040,
        689
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Shoot": {
    "effectNames": {
      "SFX_Enemy_Mushie_Shoot_01": [
        223229,
        548
      ],
      "SFX_Enemy_Mushie_Shoot_02": [
        224277,
        619
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Squish": {
    "effectNames": {
      "SFX_Enemy_Mushie_Squish": [
        225396,
        504
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ShamblingMound_Death": {
    "effectNames": {
      "SFX_Enemy_ShamblingMound_Death": [
        226400,
        1298
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ShamblingMound_Hit": {
    "effectNames": {
      "SFX_Enemy_ShamblingMound_Hit_01": [
        228198,
        340
      ],
      "SFX_Enemy_ShamblingMound_Hit_02": [
        229038,
        312
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Shrieker_Death": {
    "effectNames": {
      "SFX_Enemy_Shrieker_Death": [
        229850,
        731
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Shrieker_Hit": {
    "effectNames": {
      "SFX_Enemy_Shrieker_Hit_01": [
        231081,
        568
      ],
      "SFX_Enemy_Shrieker_Hit_02": [
        232149,
        504
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SkeletalWhelp_Death": {
    "effectNames": {
      "SFX_Enemy_SkeletalWhelp_Death": [
        233153,
        466
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SkeletalWhelp_Shoot": {
    "effectNames": {
      "SFX_Enemy_SkeletalWhelp_Shoot_01": [
        234119,
        226
      ],
      "SFX_Enemy_SkeletalWhelp_Shoot_02": [
        234845,
        240
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Spider_Death": {
    "effectNames": {
      "SFX_Enemy_Spider_Death": [
        235585,
        520
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Spider_Hit": {
    "effectNames": {
      "SFX_Enemy_Spider_Hit_01": [
        236605,
        266
      ],
      "SFX_Enemy_Spider_Hit_02": [
        237371,
        270
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Spider_Shoot": {
    "effectNames": {
      "SFX_Enemy_Spider_Shoot": [
        238141,
        270
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SporeKid_Death": {
    "effectNames": {
      "SFX_Enemy_SporeKid_Death": [
        238911,
        799
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SporeKid_Hit": {
    "effectNames": {
      "SFX_Enemy_SporeKid_Hit_01": [
        240210,
        580
      ],
      "SFX_Enemy_SporeKid_Hit_02": [
        241290,
        685
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SporeKid_Shoot": {
    "effectNames": {
      "SFX_Enemy_SporeKid_Shoot_01": [
        242475,
        116
      ],
      "SFX_Enemy_SporeKid_Shoot_02": [
        243091,
        88
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_StrangleVine_Death": {
    "effectNames": {
      "SFX_Enemy_StrangleVine_Death": [
        243679,
        1529
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_StrangleVine_LongAttack": {
    "effectNames": {
      "SFX_Enemy_StrangleVine_LongAttack": [
        245708,
        900
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_StrangleVine_ShortAttack": {
    "effectNames": {
      "SFX_Enemy_StrangleVine_ShortAttack": [
        247108,
        850
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ThornWolf_Death": {
    "effectNames": {
      "SFX_Enemy_ThornWolf_Death": [
        248458,
        365
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ThornWolf_Hit": {
    "effectNames": {
      "SFX_Enemy_ThornWolf_Hit_01": [
        249323,
        191
      ],
      "SFX_Enemy_ThornWolf_Hit_02": [
        250014,
        234
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ThornWolf_Shoot": {
    "effectNames": {
      "SFX_Enemy_ThornWolf_Shoot_01": [
        250748,
        414
      ],
      "SFX_Enemy_ThornWolf_Shoot_02": [
        251662,
        447
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wasp_Death": {
    "effectNames": {
      "SFX_Enemy_Wasp_Death": [
        252609,
        450
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wasp_Hit": {
    "effectNames": {
      "SFX_Enemy_Wasp_Hit_01": [
        253559,
        277
      ],
      "SFX_Enemy_Wasp_Hit_02": [
        254336,
        277
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wasp_Shoot": {
    "effectNames": {
      "SFX_Enemy_Wasp_Shoot_01": [
        255113,
        230
      ],
      "SFX_Enemy_Wasp_Shoot_02": [
        255843,
        240
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wisp_Death": {
    "effectNames": {
      "SFX_Enemy_Wisp_Death": [
        256583,
        924
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wisp_Hit": {
    "effectNames": {
      "SFX_Enemy_Wisp_Hit_01": [
        258007,
        566
      ],
      "SFX_Enemy_Wisp_Hit_02": [
        259073,
        623
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wisp_Shoot": {
    "effectNames": {
      "SFX_Enemy_Wisp_Shoot_01": [
        260196,
        1393
      ],
      "SFX_Enemy_Wisp_Shoot_02": [
        262089,
        1504
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Elemental_Blood": {
    "effectNames": {
      "SFX_Elemental_Blood": [
        264093,
        631
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Fire": {
    "effectNames": {
      "SFX_Elemental_Fire": [
        265224,
        815
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Ice": {
    "effectNames": {
      "SFX_Elemental_Ice": [
        266539,
        708
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Poison": {
    "effectNames": {
      "SFX_Elemental_Poison": [
        267747,
        1055
      ]
    },
    "volume": 0.6,
    "volumeInterval": [
      0.5,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Thunder": {
    "effectNames": {
      "SFX_Elemental_Thunder": [
        269302,
        1215
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Bubba_Grunt": {
    "effectNames": {
      "SFX_Bubba_Grunt_01": [
        271017,
        303
      ],
      "SFX_Bubba_Grunt_02": [
        271820,
        247
      ],
      "SFX_Bubba_Grunt_03": [
        272567,
        267
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Bubba_Purchase": {
    "effectNames": {
      "SFX_Bubba_Purchase": [
        273334,
        763
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Chicken_Shame": {
    "effectNames": {
      "SFX_Chicken_Shame": [
        274597,
        1167
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Death_Fanfare": {
    "effectNames": {
      "SFX_Death_Fanfare": [
        276264,
        2169
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Worm_Gurgle": {
    "effectNames": {
      "SFX_Worm_Gurgle_01": [
        278933,
        578
      ],
      "SFX_Worm_Gurgle_02": [
        280011,
        570
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Boss_Crab_Claw_Impact": {
    "effectNames": {
      "SFX_Boss_Crab_Claw_Impact": [
        281081,
        760
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Dig": {
    "effectNames": {
      "SFX_Boss_Crab_Dig": [
        282341,
        1020
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Emerge": {
    "effectNames": {
      "SFX_Boss_Crab_Emerge": [
        283861,
        800
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Explode": {
    "effectNames": {
      "SFX_Boss_Crab_Explode": [
        285161,
        1224
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Land": {
    "effectNames": {
      "SFX_Boss_Crab_Land": [
        286885,
        602
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Skeleton_Shoot": {
    "effectNames": {
      "SFX_Boss_Crab_Skeleton_Shoot": [
        287987,
        1037
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Attack": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Attack_01": [
        289524,
        830
      ],
      "SFX_Boss_Crab_Vox_Attack_02": [
        290854,
        802
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Dance": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Dance_01": [
        292156,
        453
      ],
      "SFX_Boss_Crab_Vox_Dance_02": [
        293109,
        468
      ],
      "SFX_Boss_Crab_Vox_Dance_03": [
        294077,
        388
      ],
      "SFX_Boss_Crab_Vox_Dance_04": [
        294965,
        394
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Death": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Death": [
        295859,
        1462
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Hit": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Hit_01": [
        297821,
        409
      ],
      "SFX_Boss_Crab_Vox_Hit_02": [
        298730,
        413
      ],
      "SFX_Boss_Crab_Vox_Hit_03": [
        299643,
        413
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Appear_A": {
    "effectNames": {
      "SFX_Boss_Forest_Appear_A": [
        300556,
        1100
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Appear_B": {
    "effectNames": {
      "SFX_Boss_Forest_Appear_B": [
        302156,
        990
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Appear_C": {
    "effectNames": {
      "SFX_Boss_Forest_Appear_C": [
        303646,
        808
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Fade_A": {
    "effectNames": {
      "SFX_Boss_Forest_Fade_A": [
        304954,
        569
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Fade_B": {
    "effectNames": {
      "SFX_Boss_Forest_Fade_B": [
        306023,
        550
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Fade_C": {
    "effectNames": {
      "SFX_Boss_Forest_Fade_C": [
        307073,
        600
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Poof_A": {
    "effectNames": {
      "SFX_Boss_Forest_Poof_A": [
        308173,
        1256
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Poof_B": {
    "effectNames": {
      "SFX_Boss_Forest_Poof_B": [
        309929,
        1202
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Shoot_A": {
    "effectNames": {
      "SFX_Boss_Forest_Shoot_A": [
        311631,
        2865
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Vox_Death": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Death": [
        314996,
        1190
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Vox_Hit": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Hit_01": [
        316686,
        752
      ],
      "SFX_Boss_Forest_Vox_Hit_02": [
        317938,
        733
      ],
      "SFX_Boss_Forest_Vox_Hit_03": [
        319171,
        658
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Vox_Laugh": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Laugh_01": [
        320329,
        1822
      ],
      "SFX_Boss_Forest_Vox_Laugh_02": [
        322651,
        2100
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Vox_Laugh_Short": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Laugh_Short_01": [
        325251,
        772
      ],
      "SFX_Boss_Forest_Vox_Laugh_Short_02": [
        326523,
        772
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Whoosh_A": {
    "effectNames": {
      "SFX_Boss_Forest_Whoosh_A": [
        327795,
        891
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Zap": {
    "effectNames": {
      "SFX_Boss_Forest_Zap_01": [
        329186,
        503
      ],
      "SFX_Boss_Forest_Zap_02": [
        330189,
        710
      ],
      "SFX_Boss_Forest_Zap_03": [
        331399,
        650
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Magic_A": {
    "effectNames": {
      "SFX_Boss_Highlands_Magic_A": [
        332549,
        1633
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Magic_B": {
    "effectNames": {
      "SFX_Boss_Highlands_Magic_B": [
        334682,
        1687
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Magic_C": {
    "effectNames": {
      "SFX_Boss_Highlands_Magic_C": [
        336869,
        1687
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Shard": {
    "effectNames": {
      "SFX_Boss_Highlands_Shard": [
        339056,
        1387
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Shield": {
    "effectNames": {
      "SFX_Boss_Highlands_Shield": [
        340943,
        2050
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Snake_Hiss": {
    "effectNames": {
      "SFX_Boss_Highlands_Snake_Hiss_01": [
        343493,
        1175
      ],
      "SFX_Boss_Highlands_Snake_Hiss_02": [
        345168,
        1053
      ],
      "SFX_Boss_Highlands_Snake_Hiss_03": [
        346721,
        1308
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Thunder": {
    "effectNames": {
      "SFX_Boss_Highlands_Thunder": [
        348529,
        1776
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Turn_Stone": {
    "effectNames": {
      "SFX_Boss_Highlands_Turn_Stone": [
        350805,
        886
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Vox_Scream": {
    "effectNames": {
      "SFX_Boss_Highlands_Vox_Scream": [
        352191,
        1914
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Breath": {
    "effectNames": {
      "SFX_Boss_Prism_Breath": [
        354605,
        2320
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Cocoon_In": {
    "effectNames": {
      "SFX_Boss_Prism_Cocoon_In": [
        357425,
        829
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Cocoon_Out": {
    "effectNames": {
      "SFX_Boss_Prism_Cocoon_Out": [
        358754,
        1158
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Death_Explode": {
    "effectNames": {
      "SFX_Boss_Prism_Death_Explode": [
        360412,
        1048
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Death_Freeze": {
    "effectNames": {
      "SFX_Boss_Prism_Death_Freeze": [
        361960,
        2054
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Flap": {
    "effectNames": {
      "SFX_Boss_Prism_Flap_01": [
        364514,
        530
      ],
      "SFX_Boss_Prism_Flap_02": [
        365544,
        500
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Land": {
    "effectNames": {
      "SFX_Boss_Prism_Land": [
        366544,
        734
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Vox_Attack": {
    "effectNames": {
      "SFX_Boss_Prism_Vox_Attack_01": [
        367778,
        1351
      ],
      "SFX_Boss_Prism_Vox_Attack_02": [
        369629,
        1379
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Vox_Death": {
    "effectNames": {
      "SFX_Boss_Prism_Vox_Death": [
        371508,
        1294
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Vox_Hurt": {
    "effectNames": {
      "SFX_Boss_Prism_Vox_Hurt_01": [
        373302,
        1350
      ],
      "SFX_Boss_Prism_Vox_Hurt_02": [
        375152,
        1129
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Wind": {
    "effectNames": {
      "SFX_Boss_Prism_Wind": [
        376781,
        1278
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Battery": {
    "effectNames": {
      "SFX_Gear_Battery": [
        378559,
        1565
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Battle_Cry": {
    "effectNames": {
      "SFX_Gear_Battle_Cry": [
        380624,
        3209
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Bulwark_Start": {
    "effectNames": {
      "SFX_Gear_Bulwark_Start": [
        384333,
        943
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Bulwark_Break": {
    "effectNames": {
      "SFX_Gear_Bulwark_Break": [
        385776,
        633
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Gravity_Start": {
    "effectNames": {
      "SFX_Gear_Gravity_Start": [
        386909,
        3028
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Gear_Gravity_Loop": {
    "effectNames": {
      "SFX_Gear_Gravity_Loop": [
        390437,
        4270
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overcharged_Start": {
    "effectNames": {
      "SFX_Gear_Overcharged_Start": [
        395207,
        1942
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overcharged_Loop": {
    "effectNames": {
      "SFX_Gear_Overcharged_Loop": [
        397649,
        2337
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overshield_Start": {
    "effectNames": {
      "SFX_Gear_Overshield_Start": [
        400486,
        1407
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overshield_Pop": {
    "effectNames": {
      "SFX_Gear_Overshield_Pop": [
        402393,
        1075
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Sickening_Nova": {
    "effectNames": {
      "SFX_Gear_Sickening_Nova": [
        403968,
        2181
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Stone_Start": {
    "effectNames": {
      "SFX_Gear_Stone_Start": [
        406649,
        1618
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Stone_Loop": {
    "effectNames": {
      "SFX_Gear_Stone_Loop": [
        408767,
        1476
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Lute": {
    "effectNames": {
      "SFX_Lute_Loop": [
        410743,
        8896
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Choreo_Warning": {
    "effectNames": {
      "SFX_Choreo_Warning": [
        420139,
        1600
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Knockback": {
    "effectNames": {
      "SFX_Knockback": [
        422239,
        812
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Acid_Bottle": {
    "effectNames": {
      "SFX_Acid_Bottle": [
        423551,
        504
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Hateful_Grace": {
    "effectNames": {
      "SFX_Boss_Hateful_Grace": [
        424555,
        1833
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Tesla_Coil": {
    "effectNames": {
      "SFX_Tesla_Coil_01": [
        426888,
        985
      ],
      "SFX_Tesla_Coil_02": [
        428373,
        1179
      ],
      "SFX_Tesla_Coil_03": [
        430052,
        861
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Hunters_Mark": {
    "effectNames": {
      "SFX_Hunters_Mark": [
        431413,
        1422
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Reload_Start": {
    "effectNames": {
      "SFX_Enemy_Golem_Hit_01": [
        433335,
        341
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.2,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Reload_Finish": {
    "effectNames": {
      "SFX_Reload_Finish": [
        434176,
        556
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Doom_Explosion": {
    "effectNames": {
      "SFX_Doom_Explosion": [
        435232,
        2263
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Upgrade": {
    "effectNames": {
      "SFX_Upgrade": [
        437995,
        2780
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Trap_Trigger": {
    "effectNames": {
      "SFX_Trap_Trigger": [
        441275,
        1500
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Enemy_Hit": {
    "effectNames": {
      "SFX_Enemy_Hit": [
        443275,
        252
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  }
}