import { getProjectileAssetFromEnum, ParticleEffectType, PARTICLE_EFFECT_SCALES } from '../../engine/graphics/pfx/particle-config'
import { Effect } from './pfx/effect'
import { IParticleRendererCamera } from './pfx/sprite-particle-renderer'
import { ObjectPool } from '../../utils/third-party/object-pool'
import { AssetIdentifier, AssetManager } from '../../web/asset-manager'
import { nid } from '../../utils/primitive-types'
import logger from '../../utils/client-logger'
import { forEach } from 'lodash'
import { debugConfig } from '../../utils/debug-config'
import { LayerRenderer } from './renderers/layer-renderer'
import { PlayerProjectile } from '../../projectiles/projectile'
import { Renderer } from './renderer'
import { EnemyProjectile } from '../../projectiles/enemy-projectile'
import { Projectile } from '../../projectiles/projectile-types'
import { GameClient } from '../game-client'
import { callbacks_addCallback } from '../../utils/callback-system'

const MAX_EFFECTS = 50
const PROJECTILE_Z_OFFSET = 200

type ProjectileEffect = Effect & { isTrail: boolean }

//TODO2: each effect needs a default size mapping, then scale based on colliderRadius / defaultEffectSize.get(my effect)
const DEFAULT_COLLIDER_RADIUS = 20

export const NONLOCAL_PLAYER_ALPHA = 0.3
const ENEMY_Z_OFFSET = 200
const ENEMY_BATCH_ZINDEX = 131137 // making unique so another object doesn't batch the same

export default class ProjectileEffectManager {
	// TODO: Combine common properties of Projectile and EnemyProjectile into one superclass / interface ?
	private projectiles: Map<nid, Projectile>
	/** nid => [head, trail] */
	private projectileEffects: Map<nid, ProjectileEffect[]>
	static effectPools: Map<AssetIdentifier, ObjectPool>
	static effectNameMap: Map<Effect, AssetIdentifier>
	// we used to have a more specific type for this but i'm not sure it's very useful
	private mgRenderer: LayerRenderer
	static cameraState: IParticleRendererCamera

	constructor(mgRenderer: LayerRenderer, cameraState: IParticleRendererCamera) {
		this.projectiles = new Map<number, Projectile>()
		this.projectileEffects = new Map<nid, ProjectileEffect[]>()
		this.mgRenderer = mgRenderer
		ProjectileEffectManager.cameraState = cameraState

		if (!ProjectileEffectManager.effectPools) {
			ProjectileEffectManager.effectPools = new Map<string, ObjectPool>()
			ProjectileEffectManager.effectNameMap = new Map()
		}
	}
	registerProjectile(projectile: Projectile): Effect[] {
		this.projectiles.set(projectile.nid, projectile)

		this.projectileEffects.set(projectile.nid, [])

		// NOTE: these need to be in effect->effectTrail order, see zOrder-- below
		const effectIds: number[] = [projectile.particleEffectType, projectile.bulletTrailParticleEffectType]
		const scale = PARTICLE_EFFECT_SCALES.get(projectile.particleEffectType)
		const toReturn = []

		for (let i = 0; i < effectIds.length; i++) {
			const effectId = effectIds[i]
			if (effectId === 0) {
				continue
			}
			const effectName = getProjectileAssetFromEnum(effectId)
			this.getOrCreateEffectPool(effectName, false)
			const pool = this.getEffectPool(effectName)
			const effect: ProjectileEffect = pool.alloc()
			effect.emitters.forEach((e) => {
				e.reset()
				e.ensureParticleEmitted()
			})
			effect.isTrail = i === 1
			//discussed with Ben on Jan 14th, elemental trails look bad scaled
			effect.scale = effect.isTrail ? 1 : scale * (projectile.radius / DEFAULT_COLLIDER_RADIUS)
			effect.effectId = effectId
			this.projectileEffects.get(projectile.nid).push(effect)
			this.mgRenderer.addEffectToScene(effect)
			toReturn.push(effect)
		}
		return toReturn
	}

	unregisterProjectile(nid: number): void {
		if (this.projectiles.has(nid)) {
			const isShutDown = GameClient.isShutDown
			this.projectileEffects.get(nid).forEach((e) => {
				if (!e.isTrail) {
					const name = ProjectileEffectManager.effectNameMap.get(e)
					const pool = this.getEffectPool(name)
					pool.free(e)
					this.mgRenderer.removeFromScene(e)
				} else {
					e.enabled = false
					
					const callbackFunction = () => {
						e.enabled = true					
						const name = ProjectileEffectManager.effectNameMap.get(e)
						const pool = this.getEffectPool(name)
						pool.free(e)
						this.mgRenderer.removeFromScene(e)
					}

					if (isShutDown) {
						callbackFunction()
					} else {
						callbacks_addCallback(this, callbackFunction, 0.2)
					}
				}
			})
			this.projectiles.delete(nid)
		} else {
			// TODO2: unregisterProjectile is happening twice
		}
	}

	onAssetsLoaded() {
		forEach(initialPfxPools, (size, assetName) => {
			this.getOrCreateEffectPool(assetName, true, size)
		})
	}

	onFileChange(assetName: AssetIdentifier, contents: any) {
		ProjectileEffectManager.effectPools.delete(assetName)
	}

	getEffectPool(effectName: string): ObjectPool {
		const pool = ProjectileEffectManager.effectPools.get(effectName)
		return pool
	}

	static createEffectPool(effectName: AssetIdentifier, initialSize: number = MAX_EFFECTS) {
		//console.log(`creating:${effectName} size:${initialSize}`)
		const pfxAsset = AssetManager.getInstance().getAssetByName(effectName)
		const effects = new ObjectPool(
			() => {
				const effect = new Effect(pfxAsset.data, ProjectileEffectManager.cameraState)
				ProjectileEffectManager.effectNameMap.set(effect, effectName)
				return effect
			},
			null,
			initialSize,
			10,
			`effect-${effectName}`,
		)
		ProjectileEffectManager.effectPools.set(effectName, effects)
	}

	update(delta: number) {
		if (ProjectileEffectManager.effectPools.size === 0) {
			return
		}
		this.projectiles.forEach((projectile: Projectile, nid: number) => {
			const isEnemyProjectile = !projectile.isPlayerOwned()

			let zOffset = PROJECTILE_Z_OFFSET + (!projectile.isPlayerOwned() ? ENEMY_Z_OFFSET : 0)
			// slight hack, but in order to batch, set all the z indices to the same value so
			//  when the render queue sorts them, they're all at the same sort order
			// tails will be 1 sort order earlier (see zOffset-- below)
			if (isEnemyProjectile) {
				zOffset = ENEMY_BATCH_ZINDEX
			}

			const effects = this.projectileEffects.get(nid)

			for (let effectIdx = 0; effectIdx < effects.length; effectIdx++) {
				const effect: ProjectileEffect = effects[effectIdx]
				effect.x = projectile.x
				effect.y = projectile.y
				effect.zIndex = isEnemyProjectile ? zOffset : effect.y + zOffset
				
				if (!projectile.isBoomerang && !projectile.noEffectRotation) {
					effect.rot = projectile.aimAngleInRads
				}
				
				if (!effect.isTrail) {
					const scale = PARTICLE_EFFECT_SCALES.get(effect.effectId)
					effect.scale = scale * (projectile.radius / DEFAULT_COLLIDER_RADIUS)
				} else {
					//discussed with Ben on Jan 14th, elemental trails look bad scaled
					effect.scale = 1
				}

				effect.emitters.forEach((e) => {
					e.alpha = 1
				})

				zOffset-- // render tail under head
			}
		})
	}

	cleanUp() {
		this.projectiles.clear()
		this.projectileEffects.forEach((effectAr) => {
			effectAr.forEach((e) => {
				e.parent = null
				if (!e.isTrail) {
					e.enabled = true
				}
			})
		})
		this.projectileEffects.clear()
		this.mgRenderer = null
	}

	// private printEffectPools() {
	// 	let s = ''
	// 	this.effectPools.forEach((pool, assetName) => {
	// 		const size = pool.status.totalAllocated
	// 		const maxSize = size - pool.status.minTotalFreeReached
	// 		const poolSize = Math.ceil(maxSize / 10) * 10
	// 		s += `['${assetName}']: ${poolSize})\n`
	// 	})
	// 	console.log(s)
	// }

	private getOrCreateEffectPool(effect: ParticleEffectType | string, isLoadTime: boolean, initialSize?: number) {
		let pfx

		if (typeof effect === 'string') {
			pfx = effect
		} else {
			pfx = getProjectileAssetFromEnum(effect as ParticleEffectType)
		}

		if (ProjectileEffectManager.effectPools.get(pfx) == null) {
			if (!isLoadTime && debugConfig.pooling.debug) {
				logger.warn(`creating pool not during load time for:${effect}`)
			}
			ProjectileEffectManager.createEffectPool(pfx, initialSize)
		}
	}
}

const initialPfxPools = {
}
