<template>
	<div id="map-event" @click="closeMap">
		<div class="pfx" @click="closeMap"></div>
		<div :class="getMapEventClass" class="background" @click="closeMap"></div>
		<button class="close-btn" :class="{ disabled: disabledBtn }" :disabled="enableButton" @click="closeMap"></button>
	</div>
</template>

<script lang="ts">
import { INPUT_DOWN_ACTION_EVENT_NAME, InputAction } from '../../../engine/client-player-input'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
const DISABLE_INTERACTIONS_MS = 500
export default {
	name: 'MapEvent',
	components: {},
	mounted() {
		document.addEventListener('keydown', this.keypressEventHandler)
		document.addEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.inputActionEventHandler)
		const context = this
		setTimeout(() => {
			context.disabledBtn = false
		}, DISABLE_INTERACTIONS_MS);
	},
	unmounted() {
		document.removeEventListener('keydown', this.keypressEventHandler)
		document.removeEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.inputActionEventHandler)
	},
	data() {
		return {
			disabledBtn: true,
		}
	},
	computed: {
		...mapGetters('ui', ['getMapEventClass']),
		...mapState('ui', ['currentMapShown'])
	},
	methods: {
		...mapActions('ui', ['dismissMapCollectedUI']),
		closeMap() {
			if (!this.disabledBtn) {
				this.dismissMapCollectedUI()
				this.disabledBtn = true
			}
		},
		keypressEventHandler(event) {
			if (event.code === 'Escape') {
				this.closeMap()
			}
		},
		inputActionEventHandler(event) {
			if (event.detail === InputAction.SKILL) {
				this.closeMap()
			}
		}
	},
}
</script>

<style lang="less" scoped>
#map-event {
	position: relative;
	top: 60px;
	pointer-events: all;

	.background {
		position: relative;
		background-image: url('~/assets/ui/event-maps/ransom_note_large.png');
		width: 400px;
		height: 424px;
		background-size: contain;
		background-repeat: no-repeat;
		filter: drop-shadow(5px 10px 5px rgba(0, 0, 0, 0.5));
		animation: fadeIn 0.5s;
	}

	.close-btn {
		position: absolute;
		width: 61px;
		height: 58px;
		background-image: url('~/assets/ui/event-maps/map-x-button.png');
		background-size: contain;
		top: -19px;
		right: -50px;
		animation: fadeIn 500ms;
		animation-delay: 1000ms;
		animation-fill-mode: forwards;
		opacity: 0;

		&:hover {
			background-image: url('~/assets/ui/event-maps/map-x-button-hover.png');
		}

		&:active {
			background-image: url('~/assets/ui/event-maps/map-x-button-pressed.png');
		}

		&.disabled {
			cursor: default;
			background-image: url('~/assets/ui/event-maps/map-x-button-pressed.png');
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
