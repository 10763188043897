import clientLogger from "../../utils/client-logger"
import { loadFromLocalStorage, loadFromSessionStorage, removeFromLocalStorage, removeFromSessionStorage, saveToLocalStorage, saveToSessionStorage } from "../../utils/local-storage"
import { anonymousUserDetailsRequest, checkEmailIsTaken, checkUsernameIsTaken, createAnonymousUserRequest, getAccountInfoFromLinkCode, getStory, loginRequest, meCurrencyRequest, meRequest,  postGenerateAccountLinkCode, postLinkAccountThroughCode, postResetPassword, registerRequest, sendForgotPasswordEmail } from '../../utils/api/griddle-requests'
import { uuid } from "../../utils/primitive-types"
import { UICurrentState, Views } from "./ui-store"
import { UI } from "../ui"
import { Audio } from "../../engine/audio"
import { showGenericInfoPromptUI } from "./generic-prompt"
import { sanitizeUrlParams, STORIES_PAGE_PARAM_KEY, STORY_PAGE_PARAM_KEY } from './end-chapter-store'
import { MetaUnlockTag } from "../../upgrades/meta/meta-unlocks-definitions"
import { MetaUnlocksManager } from "../../upgrades/meta/meta-unlocks-manager"
import { debugConfig } from "../../utils/debug-config"
import { Kongregate } from "../../web-portals/kongregate/kongregate"
import { GoogleAnalyticsHandler } from "../../analytics/google-analytics"

const MAX_STORY_CHAR_LENGTH = 6
export const SAW_SPLASH_KEY = 'splashed'

// Note These Are the errors Griddle Is throwing
export enum ErrorCodes {
	EMAIL_TAKEN = 'emailAddressAlreadyTaken',
	INVALID_EMAIL_FORMAT = 'invalidEmailFormat',
	USERNAME_TAKEN = 'usernameAlreadyTaken',
	INVALID_USERNAME = 'invalidUsername',
	INVALID_USERNAME_LENGTH = 'invalidUsernameLength',
	INVALID_PASSWORD_LENGTH = 'invalidPasswordLength',
	PASSWORD_DONT_MATCH = 'passwordAndPasswordConfirmationDontMatch',
	MUST_AGREE_TOS = 'mustAgreeToTermsOfService',
	MISSING_REQUIRED_FIELDS = 'missingRequiredFields',
	N0_MATCHING_USER_FOUND = 'noMatchingUserFoundForProvidedEmailAddress',
	INVALID_PASSWORD = 'invalidPassword',
	NO_DATA_FOUND = 'noDataFound',
	LINK_CODE_EXPIRED = 'linkCodeExpired',
	LINK_CODE_USED = 'linkCodeUsed',
	WEB_ACCOUNT_ALREADY_LINKED = 'webAccountAlreadyLinked',
	NO_USER_FOUND = 'noUserFound',
	STEAM_ACCOUNT_LINKED = 'steamAccountAlreadyLinked',
}

const getInitialUserState = async (makeAnonIfNoAnon) => {
	let existingAuthToken = loadFromLocalStorage('auth-token')
	if (!existingAuthToken) {
		existingAuthToken = loadFromSessionStorage('auth-token')
	}

	let existingAnonymousUserId = loadFromLocalStorage('anonymous-user-id')
	if (!existingAnonymousUserId) {
		existingAnonymousUserId = loadFromSessionStorage('anonymous-user-id')
	}

	console.log(`getInitialUserState`, {
		existingAuthToken,
		existingAnonymousUserId,
	})

	if (existingAuthToken === undefined) {
		clientLogger.debug("User isn't signed in; check for existing anonymous user identifier... ")

		if (existingAnonymousUserId === undefined) {

			if (makeAnonIfNoAnon) {
				const createAnonymousUserResults = await createAnonymousUserRequest()

				const { id } = createAnonymousUserResults
				saveToLocalStorage('anonymous-user-id', id)
				saveToSessionStorage('anonymous-user-id', id)

				clientLogger.debug(`Created new anonymous user: ${id}`)
				return {
					userType: 'anonymous',
					...createAnonymousUserResults,
				}

			} else {
				return {
					userType: 'none',
				}
			}
		} else {
			clientLogger.debug('Existing anonymous user identifier found, dispatching anon user info call...')
			const anonymousUserDetailsResults = await anonymousUserDetailsRequest(existingAnonymousUserId)

			clientLogger.debug(`Got details for anonymous user: ${anonymousUserDetailsResults.id}`)
			return {
				userType: 'anonymous',
				...anonymousUserDetailsResults,
			}
		}
	} else {
		clientLogger.debug('User is signed in, dispatching login call...')
		const meResults = await meRequest(existingAuthToken)
		const { token } = meResults.authentication

		saveToLocalStorage('auth-token', token)
		saveToSessionStorage('auth-token', token)
		return {
			userType: 'registered',
			...meResults,
		}
	}
}

type UserModuleState = UserState

interface UserState {
	userType: UserType
	externalUserAccountType: false | 'kongregate' | 'steam' | 'armorgames'
	externalUserAccountLoggedIn: boolean
	externalConnectionsLoading: boolean
	externalError: boolean
	userDataFetching: boolean
	id: uuid
	username: string
	emailAddress: string
	authentication: {
		token: string
	}
	currencies: Currencies,
	preferences: UserPreferences
	utmCampaign: string
	makingAnonUser: boolean
	errors: FormErrors
	unlocks: MetaUnlockTag[]
	unlockStore: any
	fetchingUsernameStatus: boolean,
	fetchingEmailStatus: boolean,
	attemptLogIn: boolean,
	forgotPasswordError: string,
	sendingReset: boolean,
	sentResetPassword: boolean,
	resetPasswordError: string,
	sendingSetPassword: boolean,
	passwordWasReset: boolean,
	forgotPasswordToken: string
	tutorialFlags: any
	isQa: boolean
	iconTag: string
	iconColor: string
	isPlayerCodeInfoLoading: boolean
	hasPlayerCodeInfo: boolean
	accountLinkCode: string
	accountLinkUserInfo: {
		username: string,
		createdAt: string,
		paperScraps: number,
		lostScrolls: number,
		magicTomes: number,
	}
	accountLinkSuccess: boolean
	accountLinkError: string
	generatedLinkCode: string
	linkCodeGenerationError: string
}

type UserType = 'registered' | 'anonymous' | 'none'

interface UserPreferences {
	bgmVolume: number
	sfxVolume: number
	preferredLanguage: string
	fullScreen: boolean
	timestamps: boolean
}

interface Currencies {
	lostScrolls: number
	magicTomes: number
	paperScraps: number
}

interface FormErrors {
	passwordLength: boolean,
	passwordMatch: boolean,
	termsOfUse: boolean,
	usernameTaken: boolean,
	emailTaken: boolean,
	usernameLength: boolean,
	invalidUsername: boolean,
	invalidEmailFormat: boolean,
	passwordLengthMessage: string[],
	passwordMessage: string[],
	termsOfUseMessage: string[],
	usernameMessage: string[],
	emailMessage: string[],
}


const initialState = {
	externalUserAccountType: false,
	externalUserAccountLoggedIn: false,
	externalConnectionsLoading: false,
	externalError: false,
	userType: 'none',
	userDataFetching: true,
	errors: {
		passwordLength: false,
		passwordMatch: false,
		termsOfUse: false,
		usernameTaken: false,
		usernameLength: false,
		emailTaken: false,
		invalidUsername: false,
		invalidEmailFormat: false,
		passwordLengthMessage: [],
		passwordMessage: [],
		termsOfUseMessage: [],
		usernameMessage: [],
		emailMessage: [],
	},
	fetchingUsernameStatus: false,
	fetchingEmailStatus: false,
	attemptLogIn: false,
	currencies: {
		lostScrolls: 0,
		magicTomes: 0,
		paperScraps: 0
	},
	forgotPasswordError: '',
	sendingReset: false,
	sentResetPassword: false,
	resetPasswordError: '',
	sendingSetPassword: false,
	passwordWasReset: false,
	forgotPasswordToken: '',
	id: '',
	authentication: {
		token: ''
	},
	isQa: false,
	isPlayerCodeInfoLoading: false,
	accountLinkCode: '',
	accountLinkUserInfo: {},
	accountLinkError: '',
	hasPlayerCodeInfo: false,
	accountLinkSuccess: false,
	generatedLinkCode: '',
	linkCodeGenerationError: ''

} as UserModuleState

const store = {
	namespaced: true,
	state: initialState,
	getters: {
		bgmVolume(state) {
			return 100
		},
		sfxVolume(state) {
			return 100
		},
		authToken(state) {
			return state.authentication.token
		},
		userType(state) {
			return state.userType
		},
		isQa(state) {
			return state.isQa
		}
	},
	mutations: {
		setExternalUserAccountType(state: UserAccountState, param) {
			state.externalUserAccountType = param
		},
		setExternalUserAccountLoggedIn(state: UserAccountState, isLoggedIn: boolean) {
			state.externalUserAccountLoggedIn = isLoggedIn
		},
		setExternalConnectionsLoading(state: UserAccountState, val: boolean) {
			state.externalConnectionsLoading = val
		},
		setExternalError(state: UserAccountState, error: boolean) {
			state.externalError = error
		},
		userDataFetching(state: UserAccountState) {
			state.userDataFetching = true
		},
		userDataNoLongerFetching(state: UserAccountState) {
			state.userDataFetching = false
		},
		updateUserDetails(state: UserAccountState, userDetails) {
			const {
				username,
				preferences,
				id,
				authentication,
				utmCampaign,
				currencies,
			} = userDetails
			state.username = username
			state.preferences = preferences
			state.id = id
			state.authentication = authentication
			state.utmCampaign = utmCampaign
			state.currencies = currencies
			state.userType = userDetails.userType
			state.isQa = userDetails.qaUser
		},
		updateCurrencies(state: UserAccountState, currencies) {
			if (currencies) {
				state.currencies = currencies
			}
		},
		updateMagicTomes(state: UserAccountState, tomes: number) {
			// why is this an array
			state.currencies.magicTomes = tomes
		},
		resetFormErrors(state: UserAccountState, errorCode) {
			state.errors = {
				passwordLength: false,
				passwordMatch: false,
				termsOfUse: false,
				usernameTaken: false,
				usernameLength: false,
				emailTaken: false,
				invalidUsername: false,
				invalidEmailFormat: false,
				passwordLengthMessage: [],
				passwordMessage: [],
				termsOfUseMessage: [],
				usernameMessage: [],
				emailMessage: []
			}
		},
		updateFormErrors(state: UserAccountState, errorCode) {
			switch (errorCode) {
				case ErrorCodes.EMAIL_TAKEN:
					state.errors.emailTaken = true
					state.errors.emailMessage.push('errors.email_taken')
					break;
				case ErrorCodes.INVALID_EMAIL_FORMAT:
					state.errors.invalidEmailFormat = true
					state.errors.emailMessage.push('errors.invalid_email_format')
					break;
				case ErrorCodes.INVALID_USERNAME:
					state.errors.invalidUsername = true
					state.errors.usernameMessage.push('errors.invalid_username')
					break;
				case ErrorCodes.INVALID_USERNAME_LENGTH:
					state.errors.usernameLength = true
					state.errors.usernameMessage.push('errors.invalid_username_length')
					break;
				case ErrorCodes.INVALID_PASSWORD_LENGTH:
					state.errors.passwordLength = true
					state.errors.passwordMessage.push('errors.invalid_password_length')
					break;
				case ErrorCodes.USERNAME_TAKEN:
					state.errors.usernameTaken = true
					state.errors.usernameMessage.push('errors.username_taken')
					break;
				case ErrorCodes.PASSWORD_DONT_MATCH:
					state.errors.passwordMatch = true
					state.errors.passwordMessage.push('errors.passwords_dont_match')
					break;
				case ErrorCodes.MUST_AGREE_TOS:
					state.errors.termsOfUse = true
					state.errors.termsOfUseMessage.push('errors.must_agree_tos')
					break;
			}
		},
		fetchingUsername(state: UserAccountState) {
			state.fetchingUsernameStatus = true
		},
		fetchingEmail(state: UserAccountState) {
			state.fetchingEmailStatus = true
		},
		finishFetchingUsername(state: UserAccountState) {
			state.fetchingUsernameStatus = false
		},
		finishFetchingEmail(state: UserAccountState) {
			state.fetchingEmailStatus = false
		},
		attemptToLogIn(state: UserAccountState) {
			state.attemptLogIn = true
		},
		finishLogInAttempt(state: UserAccountState) {
			state.attemptLogIn = false
		},
		backFromResetPassword(state: UserAccountState) {
			state.sentResetPassword = false
			state.sendingReset = false
			state.forgotPasswordError = ''
		},
		popupKongregateLogin(state: UserAccountState) {
			Kongregate.getInstance().API.services.showRegistrationBox()
		},
		setHasPlayerCodeInfo(state: UserAccountState, hasInfo: boolean) {
			state.hasPlayerCodeInfo = hasInfo
		},
		clearLinkError(state: UserAccountState) {
			state.accountLinkError = ''
		},
	},
	actions: {
		async fetchInitialUserState({ state, rootState, commit, dispatch }: { state: UserAccountState; rootState: any; commit: any, dispatch: any }, makeAnonIfNoAnon) {
			const maintenanceMode = UI.getInstance().store.state.ui.maintenanceMode

			const searchParams = new URLSearchParams(window.location.search)

			const sawSplash = loadFromSessionStorage(SAW_SPLASH_KEY)

			try {
				commit('userDataFetching')

				if (!maintenanceMode) {
					const initialUserState: UserModuleState = await getInitialUserState(makeAnonIfNoAnon)
					clientLogger.info(`Fetched initial user state for ${initialUserState.username}`)

					commit('updateUserDetails', initialUserState)

					// Initial Load of the URL, remove all params but storyId, and redirect depending on Env
					const searchParams = sanitizeUrlParams()

					if (initialUserState.tutorialFlags) {
						commit('ftue/loadAllFlags', initialUserState.tutorialFlags.flags, { root: true })
					}

					if (initialUserState.iconTag && initialUserState.iconColor) {
						commit('settings/updateSelectedIcon', initialUserState.iconTag, { root: true })
						commit('settings/updateSelectedColor', initialUserState.iconColor, { root: true })
					}

					// TODO setup Verify email logic: searchParams.has('verify-email')
					if (searchParams.has('reset-password')) {
						const resetPasswordToken = searchParams.get('forgotPasswordToken')
						state.forgotPasswordToken = resetPasswordToken
						UI.getInstance().emitAction('ui/changeActiveView', Views.RESET_PASSWORD)
					}


					if (!searchParams.has('reset-password') && initialUserState.userType !== 'none') {
						// User has Story ID in the URL
						if (searchParams.has(STORY_PAGE_PARAM_KEY)) {
							try {

								const storyId = decodeURIComponent(searchParams.get('storyId'))
								console.log(`Loading directly to storyId: ${storyId}`)
								await dispatch('story/fetchStoryById', storyId, { root: true })

								if (rootState.story.selectedStoryId !== '') {
									UI.getInstance().emitAction('ui/changeActiveView', Views.CHARACTER_SELECT)
								} else {
									if (sawSplash) {
										UI.getInstance().emitAction('ui/changeActiveView', Views.LOGGED_IN)
									}
								}

							} catch (error) {
								console.log('Error occurred when attempting to fetch story', error)
							}

						} else if (searchParams.get(STORIES_PAGE_PARAM_KEY) === 'true') {
							// User has the redirect param in the URL
							UI.getInstance().emitAction('ui/changeActiveView', Views.STORY_SELECT)

						} else if (initialUserState.userType === 'registered') {
							// No special params found so go to the logged in screen
							if (sawSplash || process.env.IS_WEB) {
								UI.getInstance().emitAction('ui/changeActiveView', Views.LOGGED_IN)
							}
						}
					}

					if (initialUserState.userType === 'none') {
						commit('userDataNoLongerFetching')
						commit('ui/updateUiState', UICurrentState.MAIN_MENU, { root: true })
						if (sawSplash) {
							UI.getInstance().emitAction('ui/changeActiveView', Views.LANDING)
						}
						return
					}


					dispatch('metaProgression/fetchPerks', null, { root: true })

					if (initialUserState.preferences.preferredLanguage) {
						this.i18n.locale = initialUserState.preferences.preferredLanguage
					}

					const userPref: UserPreferences = initialUserState.preferences

					if (userPref) {
						//set preferences
						// const audioInst = Audio.getInstance()
						// audioInst.setMasterBGMVolume(userPref.bgmVolume / 100)
						// audioInst.setMasterSFXVolume(userPref.sfxVolume / 100)
					}

					if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
						const urlParams = new URLSearchParams(window.location.search)
						const unlock = urlParams.get('unlockEverything')
						if (Boolean(unlock)) {
							debugConfig.unlockEverything = true
						}
					}

					if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging' && debugConfig.unlockEverything) {
						MetaUnlocksManager.getInstance().unlockEverything()
					} else {
						MetaUnlocksManager.getInstance().setUnlocks(initialUserState.unlocks)
					}

					console.log('Initial Fetch of perk Unlocks', UI.getInstance().store.state.metaProgression.twistUnlocks)
					commit('metaProgression/setPurchasableUnlocks', initialUserState.unlockStore, { root: true })
					console.log('Finish fetching perk Unlocks', UI.getInstance().store.state.metaProgression.twistUnlocks)

					commit('userDataNoLongerFetching')

					// I don't know if I should add this to a timeout or not.
					// I feel the quicker you get into the game the better retention might be?
					commit('ui/updateUiState', UICurrentState.MAIN_MENU, { root: true })
				}
			} catch (error) {
				clientLogger.error('Caught error in fetchInitialUserState', error)
				if (process.env.NODE_ENV === 'local') {
					console.error(error)
				}

				removeFromLocalStorage('auth-token')
				removeFromLocalStorage('anonymous-user-id')

				removeFromSessionStorage('auth-token')
				removeFromSessionStorage('anonymous-user-id')
				if (process.env.NODE_ENV !== 'local') {
					window.location.reload() //@TODO don't do this in electron
				}
			}
		},

		async submitRegister({ state }: { state: UserAccountState }, registerPayload) {
			try {
				const apiResults = await registerRequest(registerPayload)

				saveToLocalStorage('auth-token', apiResults.authentication.token)
				saveToSessionStorage('auth-token', apiResults.authentication.token)

				removeFromLocalStorage('anonymous-user-id')
				removeFromSessionStorage('anonymous-user-id')

				GoogleAnalyticsHandler.getInstance().trackRegisterAccount()

				window.location.reload() // @TODO don't do this
			} catch (error) {
				clientLogger.error('Error in submitRegister', error)

				showGenericInfoPromptUI({
					title: 'errors.error_title',
					description: [getErrorDescription(error.response.data.errorCode)],
					okButtonText: 'errors.server_error_dismiss',
					dimBackground: true
				}, null, () => {
					UI.getInstance().emitAction('genericPrompt/closePromptPanel')
				}
				)
			}
		},
		async submitLogIn({ state, commit }: { state: UserAccountState; commit: any }, loginPayload) {
			commit('attemptToLogIn')
			try {
				const apiResults = await loginRequest(loginPayload)
				saveToLocalStorage('auth-token', apiResults.authentication.token)
				saveToSessionStorage('auth-token', apiResults.authentication.token)

				removeFromLocalStorage('anonymous-user-id')
				removeFromSessionStorage('anonymous-user-id')

				commit('ui/updateMenuMusicStarted', false)
				window.location.reload() // @TODO don't do this
			} catch (error) {
				commit('finishLogInAttempt')
				console.log(error.response.data.errorCode)
				clientLogger.error('Error occurred when attempting to log in', error)
				showGenericInfoPromptUI({
					title: 'errors.error_title',
					description: [getErrorDescription(error.response.data.errorCode)],
					okButtonText: 'errors.server_error_dismiss',
					dimBackground: true
				}, null, () => {
					UI.getInstance().emitAction('genericPrompt/closePromptPanel')
				}
				)
			}
		},

		async logOut({ state, commit }: { state: UserAccountState; commit: any }) {
			try {
				removeFromLocalStorage('auth-token')
				removeFromSessionStorage('auth-token')
				window.location.href = '/'
			} catch (error) {
				commit('userDataNoLongerFetching')
				clientLogger.error('Error occurred when attempting to log out', error)
			}
		},

		async checkEmailTakenStatus({ state, commit }: { state: UserAccountState; commit: any }, emailAddress) {
			commit('fetchingEmail')
			try {
				const apiResults = await checkEmailIsTaken(emailAddress)

				if (apiResults.emailFoundStatus) {
					commit('updateFormErrors', ErrorCodes.EMAIL_TAKEN)
				}
			} catch (error) {
				clientLogger.error('Error in checkEmailTakenStatus', error)
				showGenericInfoPromptUI({
					title: 'errors.server_error_header',
					description: ['errors.server_error_description'],
					okButtonText: 'errors.server_error_dismiss',
					dimBackground: true
				}, null, () => {
					UI.getInstance().emitAction('genericPrompt/closePromptPanel')
				}
				)
			} finally {
				commit('finishFetchingEmail')
			}
		},

		async checkUsernameTakenStatus({ state, commit }: { state: UserAccountState; commit: any }, username) {
			commit('fetchingUsername')
			try {
				const apiResults = await checkUsernameIsTaken(username)

				if (apiResults.usernameFoundStatus) {
					commit('updateFormErrors', ErrorCodes.USERNAME_TAKEN)
				}
				if (apiResults.usernameIsProfane) {
					commit('updateFormErrors', ErrorCodes.INVALID_USERNAME)
				}

			} catch (error) {
				clientLogger.error('Error in checkUsernameTakenStatus', error)
				showGenericInfoPromptUI({
					title: 'errors.server_error_header',
					description: ['errors.server_error_description'],
					okButtonText: 'errors.server_error_dismiss',
					dimBackground: true
				}, null, () => {
					UI.getInstance().emitAction('genericPrompt/closePromptPanel')
				}
				)
			} finally {
				commit('finishFetchingUsername')
			}
		},

		async sendResetPasswordEmail({ state }, emailAddress: string) {
			try {
				state.forgotPasswordError = ''
				state.sendingReset = true
				const sendResult = await sendForgotPasswordEmail(emailAddress)
				state.sentResetPassword = true
			} catch (err) {
				if (err.isAxiosError && err.response.status === 400) {
					switch (err.response.data.errorCode) {
						case 'missingRequiredFields':
							state.forgotPasswordError = 'reset_password.error_enter_email'
							break
						case 'invalidEmailFormat':
							state.forgotPasswordError = 'reset_password.error_email_format'
							break
						case 'noMatchingUserFoundForProvidedEmailAddress':
							state.forgotPasswordError = 'reset_password.error_forgot_pw_email'
							break
						default:
							state.forgotPasswordError = 'reset_password.error_email_validation'
					}
				} else {
					state.forgotPasswordError = 'reset_password.error_unknown'
					console.error(err)
				}
			}

			state.sendingReset = false
		},
		async sendResetPassword({ state }, { newPassword, newPasswordConfirmation }) {
			const token = state.forgotPasswordToken
			try {
				state.sendingSetPassword = true
				const sendResult = await postResetPassword({ newPassword, newPasswordConfirmation, forgotPasswordToken: token })
				state.passwordWasReset = true
				window.history.replaceState(null, null, window.location.origin)
			} catch (err) {
				if (err.isAxiosError && err.response.status === 400) {
					switch (err.response.data.errorCode) {
						case 'missingRequiredFields':
							state.resetPasswordError = 'reset_password.error_missing_fields'
							break
						case 'invalidPasswordLength':
							state.resetPasswordError = 'reset_password.error_pw_length'
							break
						case 'passwordAndPasswordConfirmationDontMatch':
							state.resetPasswordError = 'reset_password.error_pw_match'
							break
						case 'noUserFoundWithThatToken':
							state.resetPasswordError = 'reset_password.error_reset_pw_invalid'
							break
						default:
							state.resetPasswordError = 'reset_password.error_unknown_validation'
					}
				} else {
					state.resetPasswordError = 'reset_password.error_unknown'
					console.error(err)
				}
			}

			state.sendingSetPassword = false
		},

		// Do we want an API call for just getting the latest currency data?
		async fetchMe({ state, commit }: { state: UserAccountState; commit: any }) {
			let existingAuthToken = loadFromLocalStorage('auth-token')
			if (!existingAuthToken) {
				existingAuthToken = loadFromSessionStorage('auth-token')
			}

			try {
				if (existingAuthToken) {
					const meResults = await meRequest(existingAuthToken)
					commit('updateUserDetails', meResults)
				}
			} catch (error) {
				console.error('Error in fetchMe()', error)
			}
		},

		async fetchCurrencies({ state, commit }: { state: UserAccountState, commit: any }) {
			try {
				const result = await meCurrencyRequest()
				commit('updateCurrencies', result)
			} catch (error) {
				console.error('Error in fetchCurrencies()', error)
			}
		},

		async fetchAccountLinkInfo({state}: {state: UserAccountState}, code: string) {
			if (code) {
				code = code.trim()
			}

			state.isPlayerCodeInfoLoading = true
			state.accountLinkCode = code
			state.accountLinkUserInfo = {
				username: '',
				createdAt: '',
				paperScraps: -1,
				lostScrolls: -1,
				magicTomes: -1,
			}
			state.accountLinkError = ''

			try {
				const result = await getAccountInfoFromLinkCode(code)

				state.accountLinkUserInfo = result
				state.accountLinkUserInfo.createdAt = new Date(state.accountLinkUserInfo.createdAt).toLocaleDateString()

				state.hasPlayerCodeInfo = true
			} catch (error) {
				const errorCode = error.response.data.errorCode
				state.accountLinkError = getErrorDescription(errorCode)
			} finally {
				state.isPlayerCodeInfoLoading = false
			}
		},

		async tryLinkAccount({state}: {state: UserAccountState}) {
			state.isPlayerCodeInfoLoading = true
			state.accountLinkError = ''

			try {
				const result = await postLinkAccountThroughCode(state.accountLinkCode)
				state.accountLinkSuccess = true
			} catch (error) {
				const errorCode = error.response.data.errorCode
				state.accountLinkError = getErrorDescription(errorCode)
				state.accountLinkSuccess = false
			} finally {
				state.isPlayerCodeInfoLoading = false
			}
		},

		async generateAccountLinkCode({state, commit}: {state: UserAccountState, commit: any}) {
			state.isPlayerCodeInfoLoading = true

			try {
				const data = await postGenerateAccountLinkCode()
				state.generatedLinkCode = data.code
				commit('ui/setActiveView', Views.CREATE_ACCOUNT_LINK_CODE, {root: true})
			} catch (error) {
				const errorCode = error.response.data.errorCode
				state.linkCodeGenerationError = getErrorDescription(errorCode)
				showGenericInfoPromptUI({
					title: 'errors.generic_title',
					description: [state.linkCodeGenerationError]
				})
			} finally {
				state.isPlayerCodeInfoLoading = false
			}
		},
	}
}

export type UserAccountState = typeof initialState
export type UserAccountStore = typeof store

export const userAccountStore = () => {
	return store
}

function getErrorDescription(errorCode: string): string {

	switch (errorCode) {
		case ErrorCodes.INVALID_EMAIL_FORMAT:
			return 'errors.invalid_email_format'
		case ErrorCodes.INVALID_PASSWORD:
			return 'errors.invalid_password'
		case ErrorCodes.N0_MATCHING_USER_FOUND:
			return 'errors.no_matching_user'
		case ErrorCodes.MISSING_REQUIRED_FIELDS:
			return 'errors.missing_fields'
		case ErrorCodes.USERNAME_TAKEN:
			return 'errors.username_taken'
		case ErrorCodes.EMAIL_TAKEN:
			return 'errors.email_taken'
		case ErrorCodes.MUST_AGREE_TOS:
			return 'errors.must_agree_tos'
		case ErrorCodes.PASSWORD_DONT_MATCH:
			return 'errors.passwords_dont_match'
		case ErrorCodes.INVALID_PASSWORD_LENGTH:
			return 'errors.invalid_password_length'
		case ErrorCodes.INVALID_USERNAME_LENGTH:
			return 'errors.invalid_username_length'
		case ErrorCodes.NO_DATA_FOUND:
			return 'errors.no_data_found'
		case ErrorCodes.LINK_CODE_EXPIRED:
			return 'errors.link_code_expired'
		case ErrorCodes.LINK_CODE_USED: 
			return 'errors.link_code_used'
		case ErrorCodes.WEB_ACCOUNT_ALREADY_LINKED:
			return 'errors.web_account_linked'
		case ErrorCodes.NO_USER_FOUND:
			return 'errors.no_user_found'
		case ErrorCodes.STEAM_ACCOUNT_LINKED:
			return 'errors.steam_account_linked'
		default:
			return 'errors.generic_error'
	}
}
