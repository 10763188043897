import { Vector } from "sat"
import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { EnemyDefaults } from "./enemy-defaults"

const shamblingTower: EnemyAI = {
	name: ENEMY_NAME.SHAMBLING_TOWER,
	type: EnemyType.BASIC,
	objectPoolInitialSize: 10,
	objectPoolGrowthSize: 2,
	appearance: {
		asset: SpineDataName.SHAMBLING_TOWER,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Box,
				width: 250,
				height: 380,
				position: [-125, 0],
			},
		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: 0,
			maxHealth: 20.0,
		},
		decelerationRate: 0,
		turningRatePerSecondInDegrees: 0,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		knockbackImmune: true,
		colliderIsKinematic: true,
		animationSpeeds: 0.25,
		playHitAnimation: true,
		playSpawnAnimation: true,
		isSpecial: true
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.SPAWNER,
				spawnEnemy: ENEMY_NAME.SHAMBLING_MOUND,
				spawnTime: 2.5,
				minNumToSpawn: 3,
				spawnExtraPerGameSeconds: 120,
				minSpawnDistance: 200,
				maxSpawnDistance: 400,
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 0,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 0,
			movementMinDistance: 0,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
			excludeFromModifications: true
		},
	},
}

export default shamblingTower