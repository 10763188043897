<template>
	<Panel id="generic-prompt" :height="-1">
		<template #panel-content>
			<div class="prompt">
				<div class="header" :lang="this.$i18n.locale">{{ $t(prompts[0].title) }}</div>
				<div :lang="this.$i18n.locale" v-for="description in prompts[0].description" :key="description" class="description" v-html="$t(description, prompts[0].replacements)"></div>
				<div class="button-container">
					<TextButton :button-label="$t(prompts[0].okButtonText)" :button-style="'blue'" @on-click-fn="closePromptPanel"></TextButton>
				</div>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import { mapActions, mapState } from 'vuex'
import { InputAction, INPUT_DOWN_ACTION_EVENT_NAME } from '../../engine/client-player-input'
import TextButton from '../ui-components/buttons/text-button.vue'

import Panel from '../ui-components/panel/panel.vue'
export default {
	name: 'GenericPrompt',
	components: {
		Panel,
		TextButton,
	},
	mounted() {
		document.addEventListener('keydown', this.keypressEventHandler)
		document.addEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.inputActionEventHandler)
	},
	unmounted() {
		document.removeEventListener('keydown', this.keypressEventHandler)
		document.removeEventListener(INPUT_DOWN_ACTION_EVENT_NAME, this.inputActionEventHandler)
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapState('genericPrompt', ['prompts']),
	},
	methods: {
		...mapActions('genericPrompt', ['closePromptPanel']),
		inputActionEventHandler(event) {
			if (event.detail === InputAction.SKILL) {
				this.closePromptPanel()
			}
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#generic-prompt {
	.prompt {
		display: flex;
		flex-direction: column;
		align-items: center;
		.header {
			color: @cream;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 45;
				font-variant: small-caps;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
		.description {
			color: @cream;
			padding: 10px 30px;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 32px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
		.button-container {
			margin: 30px 0;
			.text-button {
			}
		}
	}
}
</style>
