import { EffectConfig } from './effectConfig'
import { Emitter } from './emitter'
import { Container, Texture, Loader } from 'pixi.js'
import { IParticleRendererCamera } from './sprite-particle-renderer'
import { AffectorConfig, EmitterConfig } from './emitterConfig'
import { RenderQueue } from '../render-queue'

interface AtlasCacheEntry {
	atlas: PIXI.spine.core.TextureAtlas
	texture: Texture
	loader: Loader
}

export class Effect {
	static PreloadAtlases(atlases: Array<{ atlas: string; atlasTexture: string }>, callback: () => void) {
		this._loadRequiredAtlases(atlases, callback)
	}

	static GetAtlas(name: string) {
		return Effect.atlasCache.get(name).atlas
	}

	static GetAtlasTexture(name: string) {
		return Effect.atlasCache.get(name).texture
	}

	private static _loadRequiredAtlases(atlases: Array<{ atlas: string; atlasTexture: string }>, then: () => void) {
		const uniqueUncachedAtlases = new Map<string, string>()
		const cachedButUnloadedAtlases = new Set<string>()
		atlases.forEach((atlas) => {
			if (!Effect._atlasCached(atlas.atlas)) {
				uniqueUncachedAtlases.set(atlas.atlas, atlas.atlasTexture)
			} else {
				const entry = Effect.atlasCache.get(atlas.atlas)
				if (!entry.atlas) {
					cachedButUnloadedAtlases.add(atlas.atlas)
				}
			}
		})

		let numRemaining = uniqueUncachedAtlases.size + cachedButUnloadedAtlases.size

		const callbackIfLast = () => {
			numRemaining--

			if (numRemaining === 0) {
				then()
			}
		}

		if (numRemaining === 0) {
			then()
		} else {
			// Kick off any new ones
			if (uniqueUncachedAtlases.size > 0) {
				uniqueUncachedAtlases.forEach((atlasTexture, atlas) => {
					Effect._loadAtlasIntoCache(atlas, atlasTexture, (atlasLoaded: string) => {
						callbackIfLast()
					})
				})
			}

			// Latch onto existing loads
			if (cachedButUnloadedAtlases.size > 0) {
				cachedButUnloadedAtlases.forEach((atlas) => {
					const entry = Effect.atlasCache.get(atlas)
					entry.loader.onComplete.add(() => {
						callbackIfLast()
					})
				})
			}
		}
	}

	private static _atlasCached(atlas: string) {
		return Effect.atlasCache.has(atlas)
	}

	private static _getAtlasTexture(atlas: string) {
		// Assuming higher level code will check existence
		return Effect.atlasCache.get(atlas).texture
	}

	private static _loadAtlasIntoCache(atlas: string, atlasTexture: string, loadCallback: (atlas: string) => void) {
		const cacheEntry = {
			atlas: null,
			texture: null,
			loader: new Loader(),
		}
		Effect.atlasCache.set(atlas, cacheEntry)
		cacheEntry.loader.onError.add((e, l, r) => {console.error(e, r.url)})
		cacheEntry.loader.add(atlas)
		cacheEntry.loader.add(atlasTexture)
		cacheEntry.loader.load((loader, res) => {
			Effect._onAtlasLoaded(atlas, atlasTexture)
			loadCallback(atlas)
		})
	}

	private static _onAtlasLoaded(atlas: string, atlasTexture: string) {
		const cacheEntry = Effect.atlasCache.get(atlas)
		if (!cacheEntry.atlas) {
			//console.log(`atlas loaded:`, atlas, atlasTexture)
			const atlasTxt = cacheEntry.loader.resources[atlas].data
			cacheEntry.texture = cacheEntry.loader.resources[atlasTexture].texture
			cacheEntry.atlas = new PIXI.spine.core.TextureAtlas(atlasTxt, (path, loaderFunction) => {
				loaderFunction(cacheEntry.texture.baseTexture)
			})
		}
	}

	get name() {
		return this.cfg.name
	}

	get zIndex() {
		return this.z
	}

	set zIndex(newZ) {
		this.z = newZ + this.offset
	}

	get zOffset() {
		return this.offset
	}

	set zOffset(newOffset) {
		const tempZ = this.z - this.offset
		this.offset = newOffset
		this.z = tempZ + newOffset
	}

	set alpha(newAlpha) {
		for(const emitter of this.emitters) {
			emitter.alpha = newAlpha
		}
	}

	static forceWorldSpace = false // for editor only
	x: number = 0
	y: number = 0
	rot: number = 0
	timeScale: number = 1
	scale: number = 1
	scaleX: number = 1
	scaleY: number = 1
	effectId: number = 0
	emitters: Emitter[] = []
	camera: IParticleRendererCamera
	parent: Container
	enabled = true
	visible = true

	private static atlasCache = new Map<string, AtlasCacheEntry>()
	private z: number = 0
	private offset: number = 0

	private cfg: EffectConfig

	static LoadRequiredAtlases(cfg: EffectConfig, callback?: () => void) {
		const atlases = cfg.emitters.map((emitter) => {
			return { atlas: emitter.atlas, atlasTexture: emitter.atlasTexture }
		})

		const doNothing = () => {}
		Effect._loadRequiredAtlases(atlases, callback || doNothing)
	}

	constructor(cfg: EffectConfig, camera: IParticleRendererCamera) {
		this.cfg = cfg
		this.camera = camera
		this.enabled = true
		this.visible = true

		// Wait until all atlases loaded before creating renderers and emitters
		Effect.LoadRequiredAtlases(this.cfg, () => {
			// create emitters
			cfg.emitters.forEach((emitterCfg) => {
				this.emitters.push(new Emitter(this, emitterCfg))
			})

			if (this.parent) {
				this.addToContainer(this.parent)
			}
		})
	}

	update(dt: number) {
		dt *= this.timeScale
		this.emitters.forEach((emitter) => {
			if (emitter.worldSpace || Effect.forceWorldSpace) {
				emitter.rot = this.rot
				emitter.scale = this.scale
				emitter.scaleX = this.scaleX
				emitter.scaleY = this.scaleY

				const cosRot = Math.cos(emitter.rot)
				const sinRot = Math.sin(emitter.rot)
				const scaledOfsX = emitter.ofsX * emitter.scale * emitter.scaleX
				const scaledOfsY = emitter.ofsY * emitter.scale * emitter.scaleY
				const ofsX = scaledOfsX * cosRot - scaledOfsY * sinRot
				const ofsY = scaledOfsX * sinRot + scaledOfsY * cosRot
				emitter.x = this.x + ofsX
				emitter.y = this.y + ofsY
			} else {
				emitter.x = emitter.ofsX
				emitter.y = emitter.ofsY
				emitter.rot = this.rot
				emitter.scale = 1
			}

			emitter.update(dt)
		})
	}

	render(renderQueue: RenderQueue) {
		if (!this.visible) {
			return
		}
		
		this.emitters.forEach((emitter) => {
			emitter.render(renderQueue)
		})
	}

	prewarm() {
		this.emitters.forEach((e) => {
			e.ensureParticleEmitted()
		})
	}

	getNumDrawCalls() {
		return 99 // FIXME
		/*
		let num = 0
		let currRenderer: ParticleRenderer = null
		this.emitters.forEach((emitter, idx) => {
			const thisRenderer = this.rendererPerEmitter[idx]
			if (thisRenderer !== currRenderer) {
				if (currRenderer) {
					num++
				}
				currRenderer = thisRenderer
			}
		})
		if (currRenderer) {
			num++
		}
		return num
		*/
	}

	whenReady(cb: (effect: Effect) => void) {
		let numReady = 0
		const that = this
		this.emitters.forEach((emitter) => {
			emitter.whenReady((e) => {
				numReady++
				if (numReady === that.emitters.length) {
					cb(that)
				}
			})
		})
	}

	addToContainer(container: Container) {
		if (this.parent) {
			this.removeFromContainer(this.parent)
		}

		this.parent = container
	}

	removeFromContainer(container?: Container) {
		this.parent = null
	}

	setEmitterConfig(idx: number, cfg: EmitterConfig, reset: boolean) {
		if (reset) {
			const lastParent = this.parent
			this.removeFromContainer()

			this.cfg.emitters[idx] = cfg

			const atlases = this.cfg.emitters.map((emitter) => {
				return { atlas: emitter.atlas, atlasTexture: emitter.atlasTexture }
			})

			Effect._loadRequiredAtlases(atlases, () => {
				// create emitters
				this.emitters = []
				this.cfg.emitters.forEach((emitterCfg) => {
					this.emitters.push(new Emitter(this, emitterCfg))
				})

				if (lastParent) {
					this.addToContainer(lastParent)
				}
			})
		} else {
			this.emitters[idx].setConfig(cfg)
		}
	}

	getNumAffectors() {
		let num = 0
		this.emitters.forEach((emitter) => {
			num += emitter.getNumAffectors()
		})
		return num
	}

	addAffector(aff: AffectorConfig) {
		this.emitters.forEach((emitter) => {
			emitter.addAffector(aff)
		})
	}

	getNumActiveParticles() {
		let num = 0
		this.emitters.forEach((emitter) => {
			num += emitter.getNumActiveParticles()
		})
		return num
	}

	getAverageTimeEmittingSinceLastCheck() {
		let num = 0
		this.emitters.forEach((emitter) => {
			num += emitter.getAverageTimeEmittingSinceLastCheck()
		})
		return num
	}

	getAverageTimeUpdatingSinceLastCheck() {
		let num = 0
		this.emitters.forEach((emitter) => {
			num += emitter.getAverageTimeUpdatingSinceLastCheck()
		})
		return num
	}

	setDefaultValues(defaultValues, overrideValues) {}

	cleanup() {
		this.parent = null
	}
}
