import { Buff } from "../buffs/buff"
import { BuffIdentifier } from "../buffs/buff.shared"
import { getIgniteStacks } from "../buffs/generic-buff-definitions"
import { GameState } from "../engine/game-state"
import { Enemy } from "../entities/enemies/enemy"
import { ENEMY_NAME } from "../entities/enemies/enemy-names"
import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { InGameTime } from "../utils/time"
import { MapSystem } from "../world-generation/map-system"
import { MapOption } from "../world-generation/world-data"
import { getDamageByPlayerLevel } from "./player-formulas"

const MONSTER_WHISTLE_ENEMY_BY_TIME = {
    // start time in seconds, then enemy
    [MapOption.Forest]: [
        [0, ENEMY_NAME.SHAMBLING_MOUND_3],
        [520, ENEMY_NAME.SHAMBLING_MOUND_5],
    ],
    [MapOption.Tundra]: [
        [0, ENEMY_NAME.WINTER_GOBLIN_3],
        [520, ENEMY_NAME.WINTER_GOBLIN_5],
    ]
}

export function getMonsterWhistleEnemyName(): ENEMY_NAME {
    const now = InGameTime.timeElapsedInSeconds
    const map = MapSystem.getInstance().mapType

    const mapMonsters = MONSTER_WHISTLE_ENEMY_BY_TIME[map]
    for (let i = mapMonsters.length - 1; i >= 0; --i) {
        const pair = mapMonsters[i]
        if (now >= pair[0]) {
            return pair[1]
        }
    }

    throw new Error(`Could not get monster by time for monster whistle`)
}

export const MONSTER_WHISTLE_LOOT_GOBLIN_CHANCE = 0.1 // 10%

export function getNumMonsterWhistleEnemies(): number {
    return Math.round(8 + (InGameTime.timeElapsedInSeconds/60) * 3)
}

export const SPICY_PEPPER_SPEED = 6
export const SPICY_PEPPER_DURATION = 1.25
export const SPICY_PEPPER_KNOCKBACK_STRENGTH = 400

export function spicyPepperApplyIgnite(enemy: Enemy) {
    const fakeDamage = getDamageByPlayerLevel() * 2
    const stacks = getIgniteStacks(fakeDamage)

    Buff.apply(BuffIdentifier.Ignite, GameState.player, enemy, stacks)
}

export const PLANTED_XP_DURATION: timeInSeconds = 30
export const SPECTRAL_FARMER_SLOW_DURATION: timeInMilliseconds = 100
export const SPECTRAL_FARMER_SLOW_BONUS: number = -0.5

export function getPlantGrowthXpType(currentType: GroundPickupConfigType) {
    switch(currentType) {
        case GroundPickupConfigType.XPSmall:
            return GroundPickupConfigType.XPMedium
        case GroundPickupConfigType.XPMedium:
            return GroundPickupConfigType.XPLarge
        case GroundPickupConfigType.XPLarge:
            return GroundPickupConfigType.XPHuge
        case GroundPickupConfigType.XPHuge:
            return GroundPickupConfigType.XPHuge
    }

    return GroundPickupConfigType.XPHuge
}