import { AdjustmentFilter, AdvancedBloomFilter, BulgePinchFilter, EmbossFilter, GlowFilter, MotionBlurFilter, RGBSplitFilter } from "pixi-filters"
import { Vector } from "sat"
import { mapToRange } from "../utils/math"
import { simpleAnimation_addAnimation, simpleAnimation_addPropertyAnimation } from "../utils/simple-animation-system"
import { AssetIdentifier } from "../web/asset-manager"
import { Buff } from "./buff"
import { BuffableEntity } from "./buff-system"
import { BuffIdentifier } from "./buff.shared"

type FilterFunc = (duration) => PIXI.Filter

const buffConfig = {
	stone: {
		embossStrength: 0.5,
	},
	poison: {
		wobblyMag: 5,
		rgbSplitMag: 5,
	},
	stun: {
		bloom: {
			threshold: 0.9,
			bloomScale: 0.8,
			brightness: 0.7,
			blur: 11,
			quality: 4,
			pixelSize: 1,
			resolution: PIXI.settings.FILTER_RESOLUTION,
		},
	},
	ignite: {
		red: 1.5,
		green: 0.8,
		blue: 0.8,
		pulseMag: 0.3,
		pulseFreq: 1,
	},
	berserking: {
		red: 1.0,
		green: 0.3,
		blue: 0.3,
		pulseMag: 0.3,
		pulseFreq: 0.33,
	},
	cold: {
		screen: {
			red: 0.8,
			green: 1,
			blue: 2,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
		entity: {
			red: 2,
			green: 2,
			blue: 2,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
	},
	bleed: {
		screen: {
			red: 1.5,
			green: 0.5,
			blue: 0.5,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
		entity: {
			red: 0.5,
			green: 0,
			blue: 0,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
		bulgeStrength: 0.1,
	},
	confusion: {
		wobblyMag: 20,
		rgbSplitMag: 10,
	},
}

export class VisualBuffDefinition {
	identifier: BuffIdentifier

	pfxConfig?: PfxConfig | PfxConfig[]
	screenFilters?: FilterFunc[]
	entityFilters?: FilterFunc[]
	replaceFeetPfx?: boolean
	duration?: number

	shouldApplyFn?: (entity: BuffableEntity, buff: Buff) => boolean
	modifyEntityFilterFn?: (filter, entity: BuffableEntity, buff: Buff) => void
}

export const VisualBuffData: Map<BuffIdentifier, VisualBuffDefinition> = new Map<BuffIdentifier, VisualBuffDefinition>()

export interface PfxConfig {
	pfx: AssetIdentifier
	scale?: number // scale pfx multiplicatively
	boneWIP?: string // name of bone to attach to (doesn't work, WIP)
	scaleFn?: (time: number) => number
	offset?: (time: number) => Vector
	renderBehind?: boolean
}

export interface FilterConfig {
	filter: string
}

const VisualBuffs: VisualBuffDefinition[] = [

	{
		identifier: BuffIdentifier.OnKillMovementSpeed,
		pfxConfig: [
			{
				pfx: 'quick-feet',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [lowSpeedFilter],
		replaceFeetPfx: true,
	},
	// disabling blur effect
	// {
	// 	identifier: BuffIdentifier.PickupLeftSpeedBoost,
	// 	pfxConfig: [
	// 		{
	// 			pfx: 'quick-feet',
	// 			offset: () => new Vector(0, 0),
	// 			renderBehind: true,
	// 		},
	// 	],
	// 	entityFilters: [lowSpeedFilter],
	// 	replaceFeetPfx: true,
	// },
	{
		identifier: BuffIdentifier.Confusion,
		screenFilters: [confusionSplitRgbFilter, confusionWobblyFilter],
	},
	{
		identifier: BuffIdentifier.Poison,
		pfxConfig: {
			pfx: 'status-poison',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [poisonSplitRgbFilter, poisonWobblyFilter],
		entityFilters: [greenFilter],
	},
	{
		identifier: BuffIdentifier.Shock,
		pfxConfig: {
			pfx: 'status-shock',
			offset: () => new Vector(0, -50),
		},
		entityFilters: [goldFilter],
	},
	{
		identifier: BuffIdentifier.HolyLight,
		pfxConfig: {
			pfx: 'status-holy-light',
			offset: () => new Vector(0, -50),
		},
		entityFilters: [goldFilter, battlecryFilter],
	},
	{
		identifier: BuffIdentifier.Stun,
		pfxConfig: {
			pfx: 'status-stun',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [stunAdvancedBloomFilter],
		entityFilters: [blueFilter],
	},
	{
		identifier: BuffIdentifier.StunMildPFX,
		screenFilters: [stunAdvancedBloomFilter],
		entityFilters: [goldFilter],
	},
	{
		identifier: BuffIdentifier.Berserk,
		entityFilters: [entityBerserkingFilter, glowFilter],
	},
	{
		identifier: BuffIdentifier.Ignite,
		pfxConfig: {
			pfx: 'status-ignite',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [screenFireFilter, bulgePinchFilter],
		entityFilters: [entityFireFilter],
		duration: 2,
	},
	{
		identifier: BuffIdentifier.Chill,
		pfxConfig: {
			pfx: 'status-chill',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [screenIceFilter],
		entityFilters: [entityIceFilter, skyBlueFilter],
		duration: 2,
	},
	{
		identifier: BuffIdentifier.Bleed,
		pfxConfig: {
			pfx: 'status-bleed',
			offset: () => new Vector(0, -50),
		},
		duration: 6,
	},
	{
		identifier: BuffIdentifier.ProjectileShield,
		pfxConfig: {
			pfx: 'status-overshield',
			offset: () => new Vector(0, -50),
		},
		duration: 1,
	},
	{
		identifier: BuffIdentifier.AllDamageShield,
		pfxConfig: {
			pfx: 'status-overshield',
			offset: () => new Vector(0, -50),
		},
		duration: 1,
	},
	{
		identifier: BuffIdentifier.LikeABoss,
		entityFilters: [bossFilter],
	},
	{
		identifier: BuffIdentifier.CarefulShooter,
		entityFilters: [carefulShooterFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			let strength = Math.clamp(buff.stacks / 6, 0, 2)
			if (buff.stacks >= 13) {
				strength = 4
			}
			filter.outerStrength = strength
		},
	},
	{
		identifier: BuffIdentifier.CombatArenaAttackRate,
		entityFilters: [combatArenaFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0x0F00F0
		},
	},
	{
		identifier: BuffIdentifier.CombatArenaDamage,
		entityFilters: [combatArenaFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0xF00F00			
		},
	},
	{
		identifier: BuffIdentifier.BarbarianDamageBoost,
		entityFilters: [combatArenaFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0xF00F00			
		},
	},
	{
		identifier: BuffIdentifier.CombatArenaMovement,
		entityFilters: [combatArenaFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0x00F00F
		},
	},
	{
		identifier: BuffIdentifier.RainbowPetBlue,
		entityFilters: [rainbowPetFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0x0000FF
		},
	},
	{
		identifier: BuffIdentifier.RainbowPetRed,
		entityFilters: [rainbowPetFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0xFF0000
		},
	},
	{
		identifier: BuffIdentifier.RainbowPetGreen,
		entityFilters: [rainbowPetFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0x00FF00
		},
	},
	{
		identifier: BuffIdentifier.BarbarianMovementSpeedBuff,
		entityFilters: [combatArenaFilter],
		modifyEntityFilterFn: (filter, entity, buff: Buff) => {
			filter.color = 0x00F00F
		},
	},
	{
		identifier: BuffIdentifier.Phase,
		entityFilters: [superFilter],
		duration: 6, // The actual buff lasts for 7 seconds, but only showing for 6 as a warning
	},
	{
		identifier: BuffIdentifier.SpicyPepper,
		entityFilters: [spicyPepperFilter],
	},
	{
		identifier: BuffIdentifier.Berserker,
		pfxConfig: {
			pfx: 'status-berserking',
			offset: () => new Vector(0, 10),
			renderBehind: true,
		}
	}
	// {
	// 	identifier: BuffIdentifier.Doom,
	// 	entityFilters: [bossFilter],
	// 	modifyEntityFilterFn: (filter: GlowFilter, entity, buff: Buff) => {
	// 		const colors = [
	// 			0x000000,
	// 			0xFF0000,
	// 			0x00FF00,
	// 			0x0000FF,
	// 			0xFFFF00,
	// 			0xFFFFFF,
	// 		]

	// 		filter.color = colors[Math.clamp(buff.stacks, 0, 5)]
	// 	},
	// },
	/* {
		identifier: BuffIdentifier.SkillBattleCry,
		pfxConfig: [
			{
				pfx: 'status-battle-cry',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [battlecryFilter],
	}, */
	/* {
		identifier: BuffIdentifier.SkillBerserk,
		pfxConfig: [
			{
				pfx: 'status-berserking',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [entityBerserkingFilter, glowFilter],
	}, */
]

// for tweaking of these, play with:
// https://pixijs.io/pixi-filters/tools/demo/

function stoneFilter() {
	const filter = new AdjustmentFilter()
	filter.saturation = 0
	return filter
}
function armorbreakFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()
	const matrix = [0.6, 0, 0, 0, 0, 0, 0.6, 0, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 0, 1, 0]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}
function shardarmorupFilter() {
	const filter = new GlowFilter()
	filter.color = 0x16b7ff
	filter.outerStrength = 3
	return filter
}
function weakenedFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()
	const matrix = [0.2, 0, 0, 0, 0, 0, 0.2, 0, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 0, 1, 0]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

function petrifyFilter() {
	const filter = new EmbossFilter(buffConfig.stone.embossStrength)
	return filter
}

function highSpeedFilter() {
	const filter = new MotionBlurFilter([5, 0], 5, 5)
	filter.padding = 10
	filter.velocity.x = 15
	return filter
}

function lowSpeedFilter() {
	const filter = new MotionBlurFilter([5, 0], 5, 5)
	filter.padding = 10
	filter.velocity.x = 5
	return filter
}

function glowFilter() {
	const filter = new GlowFilter()
	filter.color = 0xe60026
	filter.outerStrength = 3
	return filter
}
function battlecryFilter() {
	const filter = new GlowFilter()
	filter.color = 0xffea00
	filter.outerStrength = 3
	return filter
}
function bossFilter() {
	const filter = new GlowFilter()
	filter.color = 0xffea00
	filter.outerStrength = 4
	return filter
}
function carefulShooterFilter() {
	const filter = new GlowFilter()
	filter.color = 0x00f6ff
	filter.outerStrength = 1 // dynamic
	return filter
}
function combatArenaFilter() {
	const filter = new GlowFilter()
	filter.outerStrength = 4
	return filter
}
function rainbowPetFilter() {
	const filter = new GlowFilter()
	filter.outerStrength = 1.5
	return filter
}
function spicyPepperFilter() {
	const filter = new GlowFilter()
	filter.outerStrength = 3
	filter.innerStrength = 3
	filter.color = 0xf89a1d
	return filter
}
function healingGlowFilter() {
	const filter = new GlowFilter()
	filter.color = 0x5fffbd
	filter.outerStrength = 3
	return filter
}
function confusionWobblyFilter() {
	return wobblyFilter(buffConfig.confusion.wobblyMag)
}

function confusionSplitRgbFilter() {
	return rgbSplitFilter(buffConfig.confusion.rgbSplitMag)
}

function poisonWobblyFilter() {
	return wobblyFilter(buffConfig.poison.wobblyMag)
}

function poisonSplitRgbFilter() {
	return rgbSplitFilter(buffConfig.poison.rgbSplitMag)
}

function stunAdvancedBloomFilter() {
	return new AdvancedBloomFilter(buffConfig.stun.bloom)
}

function bulgePinchFilter(duration: number) {
	const filter = new BulgePinchFilter()
	filter.radius = 500
	simpleAnimation_addAnimation(filter, (t) => {
		const pulse = inPulseOut(t, duration, 0, 1)
		filter.strength = (Math.sin(t) + 1) * pulse * 0.5 * buffConfig.bleed.bulgeStrength
		return 0
	})
	return filter
}

function rgbSplitFilter(magnitude: number) {
	const filter = new RGBSplitFilter()
	simpleAnimation_addAnimation(filter, (t) => {
		const f = filter as any

		const uniforms = f.uniformGroup.uniforms
		uniforms.red[0] = Math.sin(t) * magnitude
		uniforms.red[1] = Math.sin(t * 1.1) * magnitude
		uniforms.green[0] = Math.sin(t * 1.3) * magnitude
		uniforms.green[1] = Math.sin(t * 1.4) * magnitude
		uniforms.blue[0] = Math.sin(t * 1.5) * magnitude
		uniforms.blue[1] = Math.sin(t * 1.6) * magnitude
		return 1
	})
	return filter
}

function wobblyFilter(magnitude: number) {
	const displacementSprite = PIXI.Sprite.from('displacement_map')
	const filter = new PIXI.filters.DisplacementFilter(displacementSprite, magnitude)
	// not a fan of this, as removal of filter.scale is done elsewhere (handleBuffChange in buff.client.ts)
	simpleAnimation_addPropertyAnimation(filter.scale, 'x', (t) => Math.sin(t) * magnitude)
	simpleAnimation_addPropertyAnimation(filter.scale, 'y', (t) => Math.sin(t * 1.1 + 0.2) * magnitude)
	return filter
}

function screenFireFilter(duration: number) {
	return colorPulseFilter(buffConfig.ignite, duration)
}
function entityFireFilter(duration: number = 10) {
	return colorPulseFilter(buffConfig.ignite, duration)
}
function entityBerserkingFilter(duration: number = 10) {
	return colorPulseFilter(buffConfig.berserking, duration)
}

function screenIceFilter(duration: number) {
	return colorPulseFilter(buffConfig.cold.screen, duration)
}
function entityIceFilter(duration: number) {
	return colorPulseFilter(buffConfig.cold.entity, duration)
}

function screenBleedFilter(duration: number) {
	return colorPulseFilter(buffConfig.bleed.screen, duration)
}
function entityBleedFilter(duration: number) {
	return colorPulseFilter(buffConfig.bleed.entity, duration)
}

function superFilter(duration: number) {
	const filter = new PIXI.filters.ColorMatrixFilter()

	const matrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0]

	// the following numbers are just experimented with until giving the desired effect
	const redOffset = 0
	const blueOffset = 0.333
	const greenOffset = 0.666

	const magnitude = 1

	const redFrequency = 1
	const blueFrequency = 1
	const greenFrequency = 4

	const colorMag = 0.5

	simpleAnimation_addAnimation(filter, (t) => {
		const redPulse = inPulseOut(t + redOffset, duration, magnitude, redFrequency)
		const bluePulse = inPulseOut(t + blueOffset, duration, magnitude, blueFrequency)
		const greenPulse = inPulseOut(t + greenOffset, duration, magnitude, greenFrequency)
		matrix[0] = mapToRange(redPulse, 0, 1, 1, colorMag)
		matrix[6] = mapToRange(bluePulse, 0, 1, 1, colorMag)
		matrix[12] = mapToRange(greenPulse, 0, 1, 1, colorMag)
		return 0
	}, duration)

	filter._loadMatrix(matrix, false)
	return filter
}

function colorPulseFilter(colorTarget: { red: number; green: number; blue: number; pulseMag; pulseFreq }, duration: number) {
	const filter = new PIXI.filters.ColorMatrixFilter()

	const matrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0]

	simpleAnimation_addAnimation(filter, (t) => {
		const pulse = inPulseOut(t, duration, colorTarget.pulseMag, colorTarget.pulseFreq)
		matrix[0] = mapToRange(pulse, 0, 1, 1, colorTarget.red)
		matrix[6] = mapToRange(pulse, 0, 1, 1, colorTarget.green)
		matrix[12] = mapToRange(pulse, 0, 1, 1, colorTarget.blue)
		//console.log(`pulse: ${matrix[0].toFixed(1)}, ${matrix[6].toFixed(1)}, ${matrix[12].toFixed(1)} `)
		return 0
	})

	filter._loadMatrix(matrix, false)
	return filter
}

function greenFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()

	const matrix = [
		0.5, 0, 0, 0,
		0, 0, 1.5, 0,
		0, 0, 0, 0,
		0.5, 0, 0, 0,
		0, 0, 1, 0
	]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

function blueFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()

	const matrix = [
		0.5, 0, 0, 0,
		0, 0, 0.5, 0,
		0, 0, 0, 0,
		1.5, 0, 0, 0,
		0, 0, 1, 0
	]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

function skyBlueFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()

	const matrix = [
		0.5, 0, 0, 0,
		0, 0.25, 0, 0,
		0, 0.5, 1.2, 0.75,
		0, 0, 0, 0,
		1, 1, 1, 0
	]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

function goldFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()

	const matrix = [
		1, 0.25, 0, 0,
		0, 0, 1, 0.25,
		0, 0, 0, 0,
		0, 0, 0, 0,
		1, 1, 1, 0
	]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

VisualBuffs.forEach((buff) => {
	console.assert(!VisualBuffData.get(buff.identifier), `duplicated identifier:${buff.identifier} in client side buff visual definitions`)
	VisualBuffData.set(buff.identifier, buff)
})

function inPulseOut(t, duration, pulseMag, pulseFreq) {
	const s = mapToRange(t, 0, 0.5, 0, 1, true) * mapToRange(t, duration - 0.5, duration, 1, 0, true)
	return s + Math.sin(t * Math.PI * 2 * pulseFreq) * pulseMag * s
}
