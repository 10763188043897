import { event } from 'vue-gtag'

export enum EventTypes {
	voteSubmit = 'vote_submit',
	changeView = 'change_view',
	perksTabs = 'perks_tabs',
	perkPurchase = 'perk_purchase',
	perkRefund = 'perk_refund',
	perkRefundAll = 'perk_refund_all',
	summaryView = 'summary_view',
	steamWishList = 'wishlist',
	submitBookReport = 'submit_book_report',
	portalAccountRegister = 'register_portal_account',
	accountRegister = 'register_account',
	anonAccount = 'anon_account'
}

export class GoogleAnalyticsHandler {
	static getInstance() {
		if (!GoogleAnalyticsHandler.instance) {
			GoogleAnalyticsHandler.instance = new GoogleAnalyticsHandler()
		}

		return GoogleAnalyticsHandler.instance
	}
	private static instance: GoogleAnalyticsHandler

	constructor() {
		console.log('Analytics Handler Initialized')
	}

	trackEvent(eventType: EventTypes, payload: { [key: string]: any }) {
		event(eventType, {
			...payload,
		});
	}

	trackVote(twist: string, storyId: string ) {
		this.trackEvent(EventTypes.voteSubmit, { twist_id: twist, story_id: storyId });
	}

	trackSubmitBookReport( storyId: string){
		this.trackEvent(EventTypes.submitBookReport, {story_id: storyId });
	}

	trackChangeView(view: string) {
		this.trackEvent(EventTypes.changeView, { view: view });
	}

	trackPerkTabs(tab: string, storyId: string) {
		this.trackEvent(EventTypes.perksTabs, { tab: tab, story_id: storyId });
	}

	trackPerkPurchase(perk: string, storyId: string) {
		this.trackEvent(EventTypes.perkPurchase, { perk_id: perk, story_id: storyId});
	}

	trackPerkRefund(perk: string, storyId: string) {
		this.trackEvent(EventTypes.perkRefund, { perk_id: perk, story_id: storyId });
	}

	trackPerkRefundAll(storyId: string) {
		this.trackEvent(EventTypes.perkRefund, { story_id: storyId });
	}

	trackSummaryView(storyId: string) {
		this.trackEvent(EventTypes.summaryView, { story_id: storyId });
	}

	trackWishlist() {
		this.trackEvent(EventTypes.steamWishList, {});
	}

	trackRegisterAccount(){
		this.trackEvent(EventTypes.accountRegister, {});
	}

	trackRegisterPortalAccount(portal: string){
		this.trackEvent(EventTypes.portalAccountRegister, {portal: portal});
	}

	trackAnonAccount(){
		this.trackEvent(EventTypes.anonAccount, {});
	}
}