import { IGNITE_TICK_INTERVAL } from "../../buffs/generic-buff-definitions"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { SpritesheetAnimatorComponent } from "../../engine/graphics/spritesheet-animator-component"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import EntityStatList, { StatBonus } from "../../stats/entity-stat-list"
import { StatOperator, StatType } from "../../stats/stat-interfaces-enums"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { ObjectPool } from "../../utils/third-party/object-pool"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"
import { HAZARD_ELIPSE_COLLIDER_CONFIG } from "./ellipse-hazard-params"

export const FIERY_POOL_DAMAGE_RADIUS_X = 250
export const FIERY_POOL_DAMAGE_RADIUS_Y = 100

export interface IgniteFieryPoolHazardParams extends DamagingGroundHazardParams {
    damagePerTick: number
}

export class IgniteFieryPool extends DamagingGroundHazard {
	
	static pool: ObjectPool

	spriteSheet: SpritesheetAnimatorComponent

    jankStatList: EntityStatList
    damageBonus: StatBonus

	constructor() {
		super()

		const spriteSheet = AssetManager.getInstance().getAssetByName('fire-fairy-pool').spritesheet
		this.spriteSheet = new SpritesheetAnimatorComponent(this, spriteSheet, 'idle', 1)
		this.spriteSheet.spriteSheetAnimator.activeSprite.activeFrame = Math.getRandomInt(0, this.spriteSheet.spriteSheetAnimator.activeSprite.frames.length - 1)
		this.spriteSheet.overrideZindex(-9_999_999)

		this.colliderComponent.setColliders(HAZARD_ELIPSE_COLLIDER_CONFIG)

        this.jankStatList = new EntityStatList(this._resetStats)
        this.damageBonus = this.jankStatList.addStatBonus(StatType.baseDamage, StatOperator.SUM, 0) as StatBonus
	}

	override setDefaultValues(defaultValues: any, overrideValues?: IgniteFieryPoolHazardParams): void {
		this.statList = this.jankStatList

        super.setDefaultValues(defaultValues, overrideValues)

		if(overrideValues) {
			this.spriteSheet.spriteSheetAnimator.position.x = overrideValues.position.x
			this.spriteSheet.spriteSheetAnimator.position.y = overrideValues.position.y
			this.spriteSheet.spriteSheetAnimator.zIndex = -9_999_999

			this.spriteSheet.addToScene()

            this.damageBonus.update(overrideValues.damagePerTick)
		}
	}

	override cleanup(): void {
		super.cleanup()
		
		this.spriteSheet.spriteSheetAnimator.scale.x = 1
		this.spriteSheet.spriteSheetAnimator.scale.y = 1
		this.spriteSheet.spriteSheetAnimator.position.x = 0
		this.spriteSheet.spriteSheetAnimator.position.y = 0
		this.spriteSheet.removeFromScene()
	}

	onEntityEnterTrigger(entity: ColliderComponent) {

	}

	onEntityLeaveTrigger(entity: ColliderComponent) {

	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		super.update(delta, now)

		this.spriteSheet.update(delta)
	}

	returnToPool() {
		IgniteFieryPool.pool.free(this)
	}

	isPlayerOwned(): boolean {
		return true
	}

	onHitEnemy(enemy: Enemy) {
		
	}

    private _resetStats(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.baseDamage = 0
    }

    override getAttackTickTime() {
        return IGNITE_TICK_INTERVAL
    }
}