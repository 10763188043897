import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyAIBaseAttributes } from "../ai-types"
import { Vector } from "sat"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"

export const EnemyDefaults: EnemyAIBaseAttributes = {
	colliders: [
		{
			type: ColliderType.Circle,
			radius: 50,
			position: [0, -60],
		},
	],
	colliderLayer: CollisionLayerBits.Enemy,
	baseStats: {
		movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED,
		allDamageMult: 1.0,
		initialImpactDamageMult: 1.0,

		attackRate: 1000,

		maxHealth: 10,
		// damageTakenMult: 1,
		baseDamage: 1,

		allAilmentPotencyMult: 1,
		damagingAilmentPotencyMult: 1,
		statusAilmentPotencyMult: 1,
		attackSize: 1,
		attackKnockback: 0,

		projectileCount: 1,
		projectileSpeed: 1,
		knockbackResist: 1,
		projectileSpreadAngle: 0,
		projectileLifeSpan: 10,
		chillChance: 0,
		chillPotency: 0,
		igniteChance: 0,
		ignitePotency: 0,
		poisonChance: 0,
		poisonPotency: 0,
		shockChance: 0,
		shockPotency: 0,
		stunChance: 0,
		stunPotency: 0,
		bleedChance: 0,
		bleedPotency: 0
	},

	decelerationRate: 1,
	knockbackDecelerationRate: 4,
	turningRatePerSecondInDegrees: 1000,
	avoidanceRadius: 100,

	attackOffset: new Vector(0, 0),
	lootDropOffset: new Vector(0, 0),
	damageConfig: null,
	colliderIsKinematic: false,
	deathRewardRate: undefined,
}

//TODO figure out why I'm not able to make EnemyDefaults readonly without this (something to do with AnyColliderConfig being a mutable type)
Object.freeze(EnemyDefaults)
