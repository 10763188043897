import { Container, Graphics } from "pixi.js"
import { Vector } from "sat"
import { AllWeaponTypes } from "../weapons/weapon-types"
import { ColliderComponent } from "../engine/collision/collider-component"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import CollisionSystem, { getClosestEntity, getClosestNotHitEntity } from "../engine/collision/collision-system"
import { GameState } from "../engine/game-state"
import { Renderer } from "../engine/graphics/renderer"
import { DamageSource } from "../projectiles/damage-source"
import EntityStatList from "../stats/entity-stat-list"
import { StatType } from "../stats/stat-interfaces-enums"
import { distanceSquaredVV } from "../utils/math"
import { timeInSeconds, timeInMilliseconds, nid } from "../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { ChainLightningGraphic } from "./chain-lightning-graphic"
import { Enemy } from "./enemies/enemy"
import { EntityType } from "./entity-interfaces"
import { debugtool } from "../utils/decorators"

export interface ChainLightningParams {
    weaponStatList: EntityStatList
    searchRadius: number
    startingPosition: Vector
    targetEntity: Enemy
    splitIndex?: number
    alreadyHitEntity?: nid
    isArc?: boolean
    weaponType?: AllWeaponTypes
}

const NEW_TARGET_SEARCH_TIME = 0.05

const ORIGINAL_CHAIN_COLOR = 0xF0F000
const SPLIT_CHAIN_COLORS = [0xFFAC1C, 0xCD7F32, 0xCC5500, 0xE97451, 0xFF7F50, 0xFAD5A5]
const ARC_COLOR = 0xE8E7C3

export class ChainLightning implements PoolableObject, DamageSource {
    static pool: ObjectPoolTyped<ChainLightning, ChainLightningParams>     

    nid: number
    entityType: EntityType = EntityType.GroundHazard
    timeScale: number = 1

    originPosition: Vector
    targetEnemyPosition: Vector

    lastHitEnemy: Enemy
    entitiesHit: nid[] = []

    elapsedSearchTime: number = 0

    searchRadius: number

    numEntitiesChained: number = 0
	numEntitiesPierced: number = 0
    maxChainCount: number

    isArc: boolean

    statList: EntityStatList

    visuals: Container

    lightningGraphic: ChainLightningGraphic

    debugGraphics: Graphics
    debugColor: number

    weaponType: AllWeaponTypes

    showImmediateDamageNumber: boolean = false

    private lastChainHitVector: Vector

    constructor() {
        this.makeVisuals()
        // this.makeDebugVisuals()

        this.targetEnemyPosition = new Vector()
        this.originPosition = new Vector()
        this.lastChainHitVector = new Vector()
    }

    setDefaultValues(defaultValues: any, overrideValues?: ChainLightningParams) {
        if(overrideValues) {
            this.originPosition.copy(overrideValues.startingPosition)
            this.searchRadius = overrideValues.searchRadius
            this.statList = overrideValues.weaponStatList
            if(overrideValues.alreadyHitEntity !== undefined) {
                this.entitiesHit.push(overrideValues.alreadyHitEntity)
            }

            this.maxChainCount = overrideValues.weaponStatList.getStat(StatType.projectileChainCount)

            this.targetEnemyPosition.copy(overrideValues.targetEntity.position)

            this.weaponType = overrideValues.weaponType === undefined ? null : overrideValues.weaponType
            this.hitEnemy(overrideValues.targetEntity)

            this.isArc = Boolean(overrideValues.isArc)
            if(!overrideValues.isArc) {
                if(!overrideValues.splitIndex) {
                    const splitCount = overrideValues.weaponStatList.getStat(StatType.projectileSplitCount)
                    if(splitCount > 0) {
						overrideValues.weaponType = this.weaponType
                        overrideValues.splitIndex = 1
                        overrideValues.alreadyHitEntity = overrideValues.targetEntity.nid
                        // get <splitCount> closest enemies to split new chains to
                        const splitToEnemies = this.getClosestEnemiesForSplit(splitCount, overrideValues.targetEntity.colliderComponent)
                        for(let i = 0; i < splitToEnemies.length; ++i) {
                            const enemy = splitToEnemies[i].owner as Enemy
                            overrideValues.targetEntity = enemy
                            overrideValues.startingPosition = this.targetEnemyPosition
                            ChainLightning.pool.alloc(overrideValues)
    
                            this.entitiesHit.push(enemy.nid)
                            overrideValues.splitIndex++
                        }
                    }

                    this.debugColor = ORIGINAL_CHAIN_COLOR
                } else {
                    this.debugColor =  SPLIT_CHAIN_COLORS[overrideValues.splitIndex % SPLIT_CHAIN_COLORS.length]
                }
            } else {
                this.debugColor = ARC_COLOR
            }
            

            // this.setDebugVisuals()
            this.setVisuals()
            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.visuals)

            GameState.addEntity(this)
        }
    }

    cleanup() {
        this.elapsedSearchTime = 0
        this.numEntitiesChained = 0
        this.entitiesHit.length = 0
        this.lastHitEnemy = null
        this.lightningGraphic.removeFromScene()
        this.statList = null

        Renderer.getInstance().mgRenderer.removeFromScene(this.visuals)
        GameState.removeEntity(this)
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        this.elapsedSearchTime += delta
        if(this.elapsedSearchTime >= NEW_TARGET_SEARCH_TIME) {
            this.elapsedSearchTime = 0
            if(!this.isArc && this.numEntitiesChained < this.maxChainCount) {
                this.nextChain()
            } else {
                ChainLightning.pool.free(this)
            }
        }
    }

    nextChain() {
        // get all entities near us
        const nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(this.targetEnemyPosition, this.searchRadius, CollisionLayerBits.HitEnemyOnly)

        let minDistEntity
        if(GameState.player.binaryFlags.has('chain-same-target')) {
            nearbyEntities.remove(this.lastHitEnemy.colliderComponent)
            minDistEntity = getClosestEntity(nearbyEntities, this.originPosition)
        } else {
            minDistEntity = getClosestNotHitEntity(nearbyEntities, this.originPosition, this.entitiesHit)
        }

        if(!minDistEntity) {
            //we're done here
            ChainLightning.pool.free(this)
        } else {
            const foundEnemy = minDistEntity.owner as Enemy
            this.hitEnemy(foundEnemy)

            if (GameState.player.binaryFlags.has('chained-together')) {
                const knockbackValue = GameState.player.binaryFlagState['chained-together'].knockback

                this.lastChainHitVector.copy(this.originPosition)
                this.lastChainHitVector.sub(foundEnemy.position)
                this.lastChainHitVector.normalize()
                this.lastChainHitVector.scale(knockbackValue)

                foundEnemy.addKnockBack(this.lastChainHitVector)
            }

            this.originPosition.copy(this.targetEnemyPosition)
            this.targetEnemyPosition.copy(foundEnemy.position)

            this.drawLineToNextTarget()

            this.numEntitiesChained++

            // should this stuff be on the weapon?
            if(GameState.player.binaryFlags.has('tesla-coil-arc-on-chain')) {
                const nearbyChainTargets = CollisionSystem.getInstance().getEntitiesInArea(this.targetEnemyPosition, this.searchRadius, CollisionLayerBits.HitEnemyOnly)

                // not super sure the best target to arc to to make it look good; but here's the current:
                // if this is the last chain, hit the closest entity
                // if this is not the last, second closest entity.
                let arcEntity = null
                if(this.numEntitiesChained < this.maxChainCount) {
                    // not the last in chain
                    const closestEntities = this.getClosestEnemiesForSplit(2, minDistEntity, true)
                    if(closestEntities.length > 0) {
                        arcEntity = closestEntities[closestEntities.length - 1]
                    }
                } else {
                    // last in chain
                    arcEntity = getClosestNotHitEntity(nearbyChainTargets, this.originPosition, this.entitiesHit)
                }

                if(arcEntity) {
                    const enemy = arcEntity.owner

                    ChainLightning.pool.alloc({
                        weaponStatList: this.statList,
                        searchRadius: 0,
                        startingPosition: this.targetEnemyPosition,
                        targetEntity: enemy,
                        isArc: true,
                        weaponType: this.weaponType
                    })
                }
            }
        }
    }

    getClosestEnemiesForSplit(numEnemies: number, ignoreCollider: ColliderComponent, filterHitEnemies?: boolean) {
        let nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(this.targetEnemyPosition, this.searchRadius, CollisionLayerBits.HitEnemyOnly)
        nearbyEntities.remove(ignoreCollider)


        if(filterHitEnemies) {
            nearbyEntities = nearbyEntities.filter((v) => this.entitiesHit.findIndex((e) => e === v.owner.nid) === -1)
        }

        nearbyEntities.sort((a, b) => {
            const aDistance = distanceSquaredVV(this.originPosition, a.position)
            const bDistance = distanceSquaredVV(this.originPosition, b.position)
            return aDistance - bDistance
        })

        nearbyEntities.splice(numEnemies)
        return nearbyEntities
    }

    hitEnemy(enemy: Enemy) {
        this.entitiesHit.push(enemy.nid)
        
        enemy.onHitByDamageSource(this)

        this.lastHitEnemy = enemy
    }

    getKnockbackDirection(mutableEntityPos: Vector): Vector {
		return mutableEntityPos.sub(this.originPosition).normalize()
	}

    makeVisuals() {
        this.visuals = new Container()
        this.visuals['update'] = () => {}

        this.lightningGraphic = new ChainLightningGraphic()
    }

    @debugtool
    makeDebugVisuals() {
        this.debugGraphics = new Graphics()
        this.debugGraphics.lineStyle(5, this.debugColor)
        this.visuals.addChild(this.debugGraphics)
    }
    
    @debugtool
    setDebugVisuals() {
        this.debugGraphics.clear()
        this.debugGraphics.lineStyle(5, this.debugColor)
        this.debugGraphics.moveTo(this.originPosition.x, this.originPosition.y)
        this.debugGraphics.lineTo(this.targetEnemyPosition.x, this.targetEnemyPosition.y)
    }

    setVisuals() {
        this.visuals.position.x = this.originPosition.x
        this.visuals.position.y = this.originPosition.y

        this.lightningGraphic.addToScene(this.originPosition, this.targetEnemyPosition)
    }

    drawLineToNextTarget() {
        this.lightningGraphic.addToScene(this.originPosition, this.targetEnemyPosition)

        // this.debugGraphics.moveTo(this.originPosition.x, this.originPosition.y)
        // this.debugGraphics.lineTo(this.targetEnemyPosition.x, this.targetEnemyPosition.y)
    }

    isPlayerOwned(): boolean {
        return true
    }
}
