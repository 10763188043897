import { Container, Graphics } from "pixi.js"
import { Renderer } from "../../engine/graphics/renderer"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { Enemy } from "../enemies/enemy"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"

export class EnemyHazard extends DamagingGroundHazard {
    
    static pool: ObjectPoolTyped<EnemyHazard, DamagingGroundHazardParams>

    debugVisuals: Container
    debugGfx: Graphics

    constructor() {
        super()
        this.makeDebugVisuals()
    }

    override setDefaultValues(defaultValues: any, overrideValues?: DamagingGroundHazardParams): void {
        super.setDefaultValues(defaultValues, overrideValues)

        if(overrideValues) {
            this.debugGfx.clear()
            this.debugGfx.lineStyle(3, 0xFFA500)
			this.debugGfx.beginFill(0xFFFF00, 0.1)
            this.debugGfx.drawCircle(0, 0, this.triggerRadius)
			this.debugGfx.endFill()
            this.debugVisuals.position.x = this.position.x
            this.debugVisuals.position.y = this.position.y
            
            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.debugVisuals)
        }
    }

    override cleanup(): void {
        super.cleanup()

        Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)
    }

    returnToPool() {
        EnemyHazard.pool.free(this)
    }

    makeDebugVisuals() {
        this.debugVisuals = new Container()
        this.debugVisuals['update'] = () => {}

        this.debugGfx = new Graphics()

        this.debugVisuals.addChild(this.debugGfx)
    }

    onHitEnemy(enemy: Enemy) {
    }

    isPlayerOwned(): boolean {
        return false
    }
}