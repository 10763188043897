<template>
	<div id="header">
		<div :class="[fontFace, fontColor, {'drop-shadow': enableDropShadow}]" class="header" :lang="this.$i18n.locale" :style="[{ 'text-align': textAlignment }, headerFontSize]">{{ headerText }}</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
const TEXT_POSITIONS = ['left', 'right', 'center']
const FONT_FACE = ['pixl', 'boldPT']
const FONT_SIZE = ['small', 'medium', 'large', 'extraLarger']
const FONT_COLOR = ['offWhite', 'cream', 'green']

export default {
	name: 'Header',
	components: {},
	data() {
		return {
			fontSizes: {
				small: { fontSize: '32px' },
				medium: { fontSize: '40px' },
				large: { fontSize: '50px' },
				extraLarger: { fontSize: '75px'}
			},
		}
	},
	props: {
		headerText: {
			type: String,
			required: true,
		},
		textAlignment: {
			type: String,
			required: true,
			default: 'left',
			validator: function (value: string) {
				return TEXT_POSITIONS.includes(value)
			},
		},
		fontFace: {
			type: String,
			default: 'pixl',
			validator: function (value: string) {
				return FONT_FACE.includes(value)
			},
		},
		fontSize: {
			type: String,
			required: true,
			default: 'small',
			validator: function (value: string) {
				return FONT_SIZE.includes(value)
			},
		},
		fontColor:{
			type: String,
			required: false,
			default: 'cream',
			validator: function (value: string) {
				return FONT_COLOR.includes(value)
			},
		},
		enableDropShadow: {
			type: Boolean,
			required: false,
			default: true
		},
	},
	computed: {
		headerFontSize() {
			if (this.fontSizes.hasOwnProperty(this.fontSize)){
				return this.fontSizes[this.fontSize]
			}
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#header {
	&.in-game{
			// TODO Reposition header to be half way between top of screen and settings menu when displaying in-game
			//position: absolute;
			//margin-top: -60px;

	}
	.header {

		&.offWhite {
			color: @offWhite;
		}
		&.green {
			color: @green;
		}
		&.cream {
			color: @cream;
		}
		
		&.drop-shadow{
			filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.5));
		}
		&[lang='en'] {
			&.pixl {
				.regularFont(@enRegularFontPixl);
			}
			&.boldPT {
				.regularFont(@enSecondaryFontPTBold);
			}
			font-variant: small-caps;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 64px;
		}
	}
}
</style>
