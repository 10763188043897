import { Container, Graphics } from "pixi.js"
import { Audio } from "../../engine/audio"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { ComponentOwner } from "../../engine/component-owner"
import { GameState } from "../../engine/game-state"
import { Renderer } from "../../engine/graphics/renderer"
import { SpritesheetAnimatorComponent } from "../../engine/graphics/spritesheet-animator-component"
import EntityStatList from "../../stats/entity-stat-list"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { RANGER_SKILL_BASE_STATS_DATA } from "../../weapons/actual-weapons/skill/ranger-skill-weapon"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { DamageableEntityType } from "../entity-interfaces"
import { RangerSlowHazard } from "./ranger-slow-hazard"
import { TrapGroundHazard, TrapParams } from "./trap-ground-hazard"

export const RANGER_TRAP_ANIMATION_TIME: timeInSeconds = 0.2
const RANGER_TRAP_ANIMATION_TIME_SCALE: timeInSeconds = 0.25
const GFX_SCALE = 0.75

export interface RangerTrapHazardParams extends TrapParams {
    primaryWeaponStatList: EntityStatList
    skillStatList: EntityStatList
}

export class RangerTrapGroundHazard extends TrapGroundHazard implements ComponentOwner {

    static pool: ObjectPoolTyped<RangerTrapGroundHazard, RangerTrapHazardParams>

    trapTriggerAnimation: SpritesheetAnimatorComponent

    //debugVisuals: Container

    statList: EntityStatList
    skillStatList: EntityStatList

    numEntitiesChained: number = 0

    constructor() {
        super()
        //this.makeDebugVisuals()
        this.makeVisuals()
        // Gotta keep the trap around long enough for its trigger animation to finish
        this._removeAfterTrigger = false;
    }

    returnToPool() {
        RangerTrapGroundHazard.pool.free(this)
        //Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)
    }

    applyEffect(enemy: Enemy) {
        // We actually just make a zone on the ground that applies effects
        // but we could deal damage or something here for the enemies in the initial triggering
    }

    override update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        if (this.triggered) {
            this.trapTriggerAnimation.update(delta)
        }
        super.update(delta, now)
    }

    override onTriggered(entity: ColliderComponent) {

        const slowHazard = RangerSlowHazard.pool.alloc({
            lifeTime: 8,
            triggerRadius: this.effectRadius,
            position: this.position,
            statList: this.statList,
            skillStatList: this.skillStatList,
            damagePercent: RANGER_SKILL_BASE_STATS_DATA.damagePercent,
            damageTargetType: DamageableEntityType.Enemy,
            noDamage: !GameState.player.binaryFlags.has('ranger-trap-bleed'),
            weaponType: AllWeaponTypes.ElfRangerSkill,
            ignoreKnockback: true
        })

        Audio.getInstance().playSfx('SFX_Trap_Trigger')

        super.onTriggered(entity)
    }

    override onExpired() {
        // future: fade / shrink away or something
        this.returnToPool()
    }

    setDefaultValues(defaultValues: any, overrideValues?: RangerTrapHazardParams) {
        super.setDefaultValues(defaultValues, overrideValues)

        if (overrideValues) {
            /* this.debugVisuals.position.x = this.position.x
            this.debugVisuals.position.y = this.position.y
            this.debugVisuals.scale.x = this.triggerRadius
            this.debugVisuals.scale.y = this.triggerRadius
            Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.debugVisuals) */

            this.statList = overrideValues.primaryWeaponStatList
            this.skillStatList = overrideValues.skillStatList

            this.trapTriggerAnimation.spriteSheetAnimator.position.x = this.position.x
            this.trapTriggerAnimation.spriteSheetAnimator.position.y = this.position.y
            this.trapTriggerAnimation.addToScene()
            this.trapTriggerAnimation.spriteSheetAnimator.restartCurrentAnim()
        }
    }

    cleanup(): void {
        super.cleanup()

        this.trapTriggerAnimation.removeFromScene()
        this.statList = null
    }

    /* makeDebugVisuals() {
        this.debugVisuals = new Container()
        this.debugVisuals['update'] = () => {}

        const gfx = new Graphics()
        gfx.beginFill(0xF00000)
        gfx.drawCircle(0, 0, 1)
        gfx.endFill()

        this.debugVisuals.addChild(gfx)

        this.debugVisuals.zIndex = -999999999
    } */

    makeVisuals() {
        const trapSpritesheet = AssetManager.getInstance().getAssetByName('ranger-trap').spritesheet
        this.trapTriggerAnimation = new SpritesheetAnimatorComponent(this, trapSpritesheet, 'idle', RANGER_TRAP_ANIMATION_TIME_SCALE, false, true)
        this.trapTriggerAnimation.spriteSheetAnimator.scale.x = GFX_SCALE
        this.trapTriggerAnimation.spriteSheetAnimator.scale.y = GFX_SCALE
    }

    isPlayerOwned(): boolean {
        return true
    }
}