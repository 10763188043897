import { Buff } from "../buffs/buff"
import { BuffDefinition } from "../buffs/buff-definition"
import { BuffTags, StackStyle } from "../buffs/buff-enums"
import { BuffIdentifier } from "../buffs/buff.shared"
import { GameState } from "../engine/game-state"
import { Player } from "../entities/player"
import { CharacterType } from "../game-data/characters"
import { StatType, StatOperator } from "../stats/stat-interfaces-enums"
import { ConeDogThornsWeapon } from "../weapons/actual-weapons/secondary/cone-dog-thorns-weapon"
import { AllWeaponTypes, SecondaryWeaponType } from "../weapons/weapon-types"
import { UpgradeCollections } from "./upgrade-definitions"
import { AffectedEntities } from './upgrade-entities'
import { DogSkillWeapon } from "../weapons/actual-weapons/skill/dog-chomp-skill-weapon"
import { isPlayer } from "../entities/entity-interfaces"
import { SUN_SOUL_DURATION_IN_MILLIS, SolaraSkillWeapon } from "../weapons/actual-weapons/skill/solara-skill-weapon"
import { PRIMARY_MIDDLE_AND_BOTTOM_POOL, PRIMARY_TOP_POOL } from "./primary-weapon-upgrades"
import { RadarSkillWeapon } from "../weapons/actual-weapons/skill/radar-skill-weapon"
import { RadarPassiveSkill, RadarSkillOption } from "../weapons/actual-weapons/passives/radar-passive-skill"


export const CHARACTER_UPGRADES: Partial<UpgradeCollections> = {
	"rangerPassiveUpgrades": {
		name: "Elf Ranger Passive Upgrades",
		desc: "",
		shape: 'line',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.ElfRanger
		},
		upgrades: [
			{
				name: 'Impatient',
				desc: "Ranger: Increase the frequency of Ranger's Mark.",
				icon: 'upgrade-impatient',
				statBonuses: [
					[['ElfRangerPassive'], StatType.cooldownInterval, StatOperator.SUM, -3_000]
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Effective Mark',
				desc: "Ranger: The attack size of Ranger's Mark is greatly increased.",
				icon: 'upgrade-effective-mark',
				statBonuses: [
					[['ElfRangerPassive'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.75]
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Lasting Mark',
				desc: "Ranger: Ranger's Mark stays on enemies longer.",
				icon: 'upgrade-lasting-mark',
				statBonuses: [
					[['ElfRangerPassive'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 1.333333]
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: "Damaging Mark",
				desc: "Ranger: Ranger's Mark deals greatly increased damage to surrounding enemies. Immediately mark a new enemy when your mark dies.",
				icon: 'upgrade-damaging-mark',
				binaryFlags: ['rangers-mark-spreads-on-death'],
				statBonuses: [
					[['ElfRangerPassive'], StatType.damagingAilmentPotencyMult, StatOperator.SUM, 0.5]
				],
				affectedEntities: [AffectedEntities.Player],
			},
		]
	},


	"rangerSkillUpgrades": {
		name: "Elf Ranger Skill Upgrades",
		desc: "",
		shape: 'tree',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.ElfRanger
		},
		top: {
			name: `It's a Trap!`,
			desc: 'Trap: Moderately increase {glossary.attack_size}',
			icon: 'upgrade-its-a-trap',
			statBonuses: [
				[['ElfRangerSkill'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.Player],
		},
		left: {
			name: 'Big Game Hunter',
			desc: 'Trap: Moderately increase {glossary.attack_size}, moderately increase {glossary.skill_duration}',
			icon: 'upgrade-ranger-big-game-hunter',
			binaryFlags: ['ranger-trap-toss'],
			statBonuses: [
				[['ElfRangerSkill'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['ElfRangerSkill'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.Player],
		},
		right: {
			name: 'Not just a one-trick pony',
			desc: 'Trap: Moderately reduce trap {glossary.cooldown} and gets one more {glossary.ammo}',
			icon: 'upgrade-not-just-a-one-trick-pony',
			statBonuses: [
				[['ElfRangerSkill'], StatType.maxAmmo, StatOperator.SUM, 1],
				[['ElfRangerSkill'], StatType.reloadInterval, StatOperator.SUM, -5_000],
			],
			affectedEntities: [AffectedEntities.Player],
		},
		leftLeaf: {
			name: 'The Size on that Thing!',
			desc: 'Trap: Moderately increase {glossary.attack_size} and {glossary.skill_duration}',
			icon: 'upgrade-the-size-on-that-thing',
			statBonuses: [
				[['ElfRangerSkill'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['ElfRangerSkill'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.5]
			],
			affectedEntities: [AffectedEntities.Player],
		},
		middleLeaf: {
			name: `Gotta Make 'Em Bleed`,
			desc: 'Trap: Trapped enemies {glossary.bleed}',
			icon: 'upgrade-gotta-make-em-bleed',
			binaryFlags: ['ranger-trap-bleed'], // enemies effected by the slow gain bleed for 10% of weapon damage per second they are in zone
			affectedEntities: [AffectedEntities.Player],
		},
		rightLeaf: {
			name: `It's Raining Traps, Hallelujah`,
			desc: 'Trap: Massively increase the {glossary.slow} effect from traps, add 2 {glossary.ammo}, and greatly reduce trap {glossary.cooldown}.',
			icon: 'upgrade-its-raining-traps-hallelujah',
			statBonuses: [
				[['ElfRangerSkill'], 'slowAmount', StatOperator.SUM, 0.2],
				[['ElfRangerSkill'], 'maxAmmo', StatOperator.SUM, 2],
				[['ElfRangerSkill'], 'reloadInterval', StatOperator.SUM, -5_000],
			],
			affectedEntities: [AffectedEntities.Player],
		},
	},

	"barbarianPassiveUpgrades": {
		name: "Barbarian Passive Upgrades",
		desc: "",
		shape: 'line',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.Barbarian
		},
		upgrades: [
			{
				name: 'Intimidating Aura',
				desc: "Barbarian: Get a moderate {glossary.damage} bonus to enemies in {glossary.melee_range}.",
				icon: 'upgrade-intimidating-aura',
				binaryFlags: ['barbarian-striker'],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Learning Practical Skills',
				desc: "Barbarian: Enemies killed in your {glossary.melee_range} have moderately increased {glossary.xp_drop_rate}.",
				icon: 'upgrade-learning-practical-skills',
				binaryFlags: ['barbarian-practical-skills'],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Terrified Approach',
				desc: "Barbarian: Enemies in your {glossary.melee_range} are affected by {glossary.chill}.",
				icon: 'upgrade-terrified-approach',
				binaryFlags: ['barbarian-intimidating-aura'],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: "Wider Authority",
				desc: "Barbarian: Your {glossary.melee_range} is massively increased.",
				icon: 'upgrade-wider-authority',
				binaryFlags: ['barbarian-wider-authority'],
				affectedEntities: [AffectedEntities.Player],
			},
		]
	},

	"barbarianSkillUpgrades": {
		name: "Barbarian Skill Upgrades",
		desc: "",
		shape: 'tree',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.Barbarian
		},
		top: {
			name: 'Acrobatics',
			desc: 'Tumble Roll {glossary.cooldown_speed} is 1 second faster',
			icon: 'upgrade-acrobatics',
			statBonuses: [
				[['BarbarianSkill'], StatType.reloadInterval, StatOperator.SUM, -1_000],
			],
			affectedEntities: [AffectedEntities.Player],
		},
		left: {
			name: 'Tough Skin',
			desc: 'You are {glossary.invuln} while tumbling.',
			icon: 'upgrade-tough-skin',
			binaryFlags: ['barbarian-tough-skin'],
			affectedEntities: [AffectedEntities.Player],
		},
		right: {
			name: 'Aggressive Posturing',
			desc: 'Tumbling slightly increases the current {glossary.cooldown_speed} of all weapons.',
			icon: 'upgrade-aggressive-posturing',
			binaryFlags: ['barbarian-aggessive-posturing'],
			affectedEntities: [AffectedEntities.Player],
		},
		leftLeaf: {
			name: 'Fast Rolls',
			desc: 'Tumble farther and gain a quick burst of {glossary.movement_speed} after tumbling.',
			icon: 'upgrade-fast-rolls',
			statBonuses: [
				[['BarbarianSkill'], StatType.reloadInterval, StatOperator.SUM, -1_000],
				[['BarbarianSkill'], StatType.skillDuration, StatOperator.SUM, 0.15],
			],
			affectedEntities: [AffectedEntities.Player],
			binaryFlags: ['barbarian-fast-rolls'],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.BarbarianMovementSpeedBuff,
					tags: [BuffTags.Movement],
					duration: 1_000,
					lastsForever: false,
					startingStacks: 1,
					stackStyle: StackStyle.None,
					showToClient: true,
					applyFn(buff: Buff) {
						const player = buff.appliedTo
						if (isPlayer(player)) {
							buff.state.bonus = player.stats.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.35)
						}
					},
					wearOffFn(buff: Buff) {
						const { bonus } = buff.state
						bonus.remove()
					}
				})
			]
		},
		middleLeaf: {
			name: `Fearsome Roar`,
			desc: 'After tumbling, emit a shout that deals {glossary.damage} and {glossary.knockback} to enemies.',
			icon: 'upgrade-fearsome-roar',
			binaryFlags: ['barbarian-fearsome-roar'],
			affectedEntities: [AffectedEntities.Player],
		},
		rightLeaf: {
			name: `Destructive Rollout`,
			desc: 'Tumbling grants a short {glossary.damage} buff, and further increases the current {glossary.cooldown_speed} of all weapons.',
			icon: 'upgrade-destructive-rollout',
			binaryFlags: ['barbarian-destructive-rollout'],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.BarbarianDamageBoost,
					tags: [BuffTags.Buff, BuffTags.Offensive],
					duration: 2_000,
					lastsForever: false,
					startingStacks: 1,
					stackStyle: StackStyle.None,
					showToClient: true,
					applyFn(buff: Buff) {
						const player = buff.appliedTo
						if (isPlayer(player)) {
							buff.state.bonus = player.stats.addStatBonus('allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.35)
						}
					},
					wearOffFn(buff: Buff) {
						const { bonus } = buff.state
						bonus.remove()
					}
				})
			],
			affectedEntities: [AffectedEntities.Player],
		},
	},

	"coneDogUpgrades": {
		name: "Cone Dog Upgrades",
		desc: "",
		shape: 'tree',
		upgradeEvolutionTier: 'tier1',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.ConeDog
		},
		top: {
			name: `Cone Dog: Thorny`,
			desc: 'Shoot thorns in all directions whenever you take damage. Thorns are affected by secondary weapon bonuses',
			icon: 'upgrade-thorny',
			pools: PRIMARY_TOP_POOL,
			simpleApplyFn(player, state) {
				const weapon = player.equipSecondaryWeapon(SecondaryWeaponType.ConeDogThorns) as ConeDogThornsWeapon
				player.coneDogThornWeapon = weapon
				player.maxSecondaryWeapons++
			},
			simpleRemoveFn(player, state) {
				player.unequipSecondaryWeapon(AllWeaponTypes.ConeDogThorns)
				player.coneDogThornWeapon = null
				player.coneDogThornBerserkStatBonus = null
				player.maxSecondaryWeapons--
			},
			affectedEntities: [AffectedEntities.Player],
		},
		left: {
			name: 'Chomp: Vicious',
			desc: 'Chomp shocks enemies. Shocked enemies take increased {glossary.damage}',
			icon: 'upgrade-chomp-vicious',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			statBonuses: [
				[['ConeDogSkill'], StatType.shockChance, StatOperator.SUM, 1],
				[['ConeDogSkill'], StatType.shockPotency, StatOperator.SUM, 10]
			],
			affectedEntities: [AffectedEntities.Player],
		},
		right: {
			name: `Thorns: Aggressive Defense`,
			desc: 'Your thorns activate every 10 seconds',
			icon: 'upgrade-thorns-aggressive-defense',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			simpleApplyFn(player: Player) {
				player.coneDogThornWeapon.autoFire = true
			},
			simpleRemoveFn(player: Player) {
				if (player.coneDogThornWeapon) {
					player.coneDogThornWeapon.autoFire = false
				}
			},
			affectedEntities: [AffectedEntities.Player],
		},
		leftLeaf: {
			name: 'Chomp: Relentless',
			desc: 'Your chomp skill gains 1 more {glossary.ammo} and slightly increased {glossary.attack_size}',
			icon: 'upgrade-chomp-relentless',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Player],
			unlocks: ['coneDogChompUpgrades'],
			statBonuses: [
				[['ConeDogSkill'], StatType.maxAmmo, StatOperator.SUM, 1],
				[['ConeDogSkill'], StatType.reloadAmmoIncrement, StatOperator.SUM, 1],
				[['ConeDogSkill'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.15] // this skill scales hard with attack size multipliers, careful
			],
		},
		middleLeaf: {
			name: `Chomp: Can't Keep a Good Dog Down`,
			desc: 'Reload chomp when your thorns activate',
			icon: 'upgrade-chomp-cant-keep-a-good-dog-down',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Player],
			unlocks: ['coneDogSynergyUpgrades'],
			simpleApplyFn(player: Player, state) {
				player.coneDogThornWeapon.reloadChompOnShootThorns = true
			},
			simpleRemoveFn(player: Player, state) {
				if (player.coneDogThornWeapon) {
					player.coneDogThornWeapon.reloadChompOnShootThorns = false
				}
			},
		},
		rightLeaf: {
			name: `Thorns: Thorn Dog`,
			desc: `Gain a 'Good Boy' stack as long as the enemy was hit by chomp.  Half of the damage from Chomp is converted into damage for your thorns.`,
			icon: 'upgrade-thorns-thorn-dog',
			pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
			affectedEntities: [AffectedEntities.Player],
			binaryFlags: ['cone-dog-thorn-stacks'],
			unlocks: ['coneDogThornsUpgrades'],
			simpleApplyFn(player: Player, state) {
				const chomp = player.skillWeapon as DogSkillWeapon
				chomp.markAliveEnemiesToStackGoodBoy = true

				const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
				if (buff) {
					buff.state.stackThornDamage = true
					buff.state.forceUpdate = true
					buff.state.thornWeapon = GameState.player.getWeapon(AllWeaponTypes.ConeDogThorns)

					Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1) // freebie to update the buff
				}
			},
			simpleRemoveFn(player: Player) {
				const chomp = player.skillWeapon as DogSkillWeapon
				chomp.markAliveEnemiesToStackGoodBoy = true

				const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
				if (buff) {
					buff.state.stackThornDamage = false
					buff.state.forceUpdate = true

					Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1) // freebie to update the buff
				}
			},
		},
	},
	'coneDogChompUpgrades': {
		name: 'Cone Dog Chomp Upgrades',
		desc: "",
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Chomp: Bite Force',
				desc: `The {glossary.damage} bonus from 'Good Boy' stacks is doubled`,
				icon: 'upgrade-chomp-bite-force',
				pools: PRIMARY_TOP_POOL,
				affectedEntities: [AffectedEntities.Player],
				simpleApplyFn(player: Player, state) {
					const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
					if (buff) {
						buff.state.doubleDamageScale = true
						buff.state.forceUpdate = true

						Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1) // freebie to update the buff
					}
				},
				simpleRemoveFn(player: Player) {
					const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
					if (buff) {
						buff.state.doubleDamageScale = false
						buff.state.forceUpdate = true

						Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1)
					}
				},
			},
			{
				name: 'Chomp: Getting Ruff',
				desc: `The {glossary.attack_size} bonus from 'Good Boy' stacks is doubled`,
				icon: 'upgrade-chomp-getting-rough',
				pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
				affectedEntities: [AffectedEntities.Player],
				simpleApplyFn(player: Player, state) {
					const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
					if (buff) {
						buff.state.doubleSizeScale = true
						buff.state.forceUpdate = true

						Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1)
					}
				},
				simpleRemoveFn(player: Player) {
					const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
					if (buff) {
						buff.state.doubleSizeScale = false
						buff.state.forceUpdate = true

						Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1)
					}
				},
			},
			{
				name: 'Chomp: Hungry Hungry Doggo',
				desc: `Increase ammo for 'Chomp!' at high stacks of 'Good Boy'`,
				icon: 'upgrade-hungry-hungry-doggo',
				pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
				affectedEntities: [AffectedEntities.Player],
				simpleApplyFn(player: Player, state) {
					const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
					if (buff) {
						buff.state.stackAmmo = true
						buff.state.forceUpdate = true

						Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1)
					}
				},
				simpleRemoveFn(player: Player) {
					const buff = Buff.getBuff(player, BuffIdentifier.GoodBoyBuff)
					if (buff) {
						buff.state.stackAmmo = false
						buff.state.forceUpdate = true

						Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1)
					}
				},
			},
		]
	},
	'coneDogSynergyUpgrades': {
		name: 'Cone Dog Synergy Upgrades',
		desc: "",
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Cone Dog: Thornapalooza',
				desc: `Release a thorn when killing an enemy with 'Chomp!'`,
				icon: 'upgrade-thornapalooza',
				pools: PRIMARY_TOP_POOL,
				affectedEntities: [AffectedEntities.Player],
				binaryFlags: ['cone-dog-chomp-thorn-kill']
			},
			{
				name: 'Cone Dog: Frenetic Activity',
				desc: `Gain a brief burst of movement speed after hitting an enemy with 'Chomp!' and increase the damage of your thorns.`,
				icon: 'upgrade-frenetic-activity',
				pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
				affectedEntities: [AffectedEntities.Player],
				binaryFlags: ['cone-dog-chomp-movement'],
				statBonuses: [
					[['Thorns'], StatType.baseDamage, StatOperator.SUM, 15]
				]
			},
			{
				name: 'Cone Dog: Dog of all Trades',
				desc: `Increase your secondary weapons {glossary.cooldown_speed} when hitting 3 or more enemies with 'Chomp!' and your thorns gain one {glossary.split}`,
				icon: 'upgrade-dog-of-all-trades',
				pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
				affectedEntities: [AffectedEntities.Player],
				binaryFlags: ['cone-dog-cooldown-reduction'], //see CONE_DOG_CHOMP_CDR_AMOUNT in characters.ts
				statBonuses: [
					[['Thorns'], StatType.projectileSplitCount, StatOperator.SUM, 1]
				]
			},
		]
	},
	'coneDogThornsUpgrades': {
		name: 'Cone Dog Thorns Upgrades',
		desc: "",
		shape: 'line',
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		upgrades: [
			{
				name: 'Thorns: Spiney',
				desc: `Your thorns travel further before disappearing`,
				icon: 'upgrade-thorns-spiney',
				pools: PRIMARY_TOP_POOL,
				affectedEntities: [AffectedEntities.Player],
				statBonuses: [
					[['Thorns'], StatType.projectileLifeSpan, StatOperator.SUM_THEN_MULTIPLY, 0.6]
				]
			},
			{
				name: 'Thorns: Natural Defense',
				desc: `Your thorns apply {glossary.knockback} and {glossary.chill}`,
				icon: 'upgrade-thorns-natural-defense',
				pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
				affectedEntities: [AffectedEntities.Player],
				statBonuses: [
					[['Thorns'], StatType.attackKnockback, StatOperator.SUM, 750],
					[['Thorns'], StatType.chillChance, StatOperator.SUM, 1],
				]
			},
			{
				name: 'Thorns: Panic Option',
				desc: `Your thorns' {glossary.cooldown_speed} is increased the more missing health you have.`,
				icon: 'upgrade-thorns-panic-option',
				pools: PRIMARY_MIDDLE_AND_BOTTOM_POOL,
				affectedEntities: [AffectedEntities.Player],
				binaryFlags: ['cone-dog-missing-health-thorn-cooldowns']
			},
		]
	},

	"solarAttunementUpgrades": {
		name: "Solar Attunement",
		desc: "",
		shape: 'line',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.SolaraSun || GameState.player.characterType === CharacterType.SolaraMoon
		},
		upgrades: [
			{
				name: "Summer's Rage",
				desc: "Solara: Increase the range of {glossary.solar_flare} and moderately increase {glossary.ignite} potency for all weapons.",
				icon: 'upgrade-summers-rage',
				statBonuses: [
					[['Player'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 0.30]
				],
				binaryFlags:[
					'summers-rage'
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Speed of light',
				desc: "Solara: While in {glossary.solar_aspect}, gain a moderate increase to {glossary.attack_rate} and {glossary.reload} with your main weapon, and {glossary.projectile_speed} for all projectiles.",
				icon: 'upgrade-speed-of-light',
				binaryFlags:[
					'speed-of-light'
				],
				extraBuffs: [
					new BuffDefinition({
						identifier: BuffIdentifier.SpeedOfLight,
						tags: [BuffTags.Upgrade, BuffTags.Buff],
						duration: 0,
						lastsForever: true,
						startingStacks: 1,
						stackStyle: StackStyle.None,
						reapplyStacks: 0,
						reapplyDuration: 0,
						showToClient: true,
						applyFn(buff: Buff) {
							if (isPlayer(buff.appliedTo)) {
								const player = buff.appliedTo
								buff.state.attackRate = player.stats.addStatBonus('attackRate', StatOperator.SUM_THEN_MULTIPLY, 0.2)
								buff.state.reload = player.stats.addStatBonus('reloadInterval', StatOperator.MULTIPLY, -0.2)
								buff.state.projSpeed = player.stats.addStatBonus('projectileSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.20)
							}
						},
						wearOffFn(buff: Buff) {
							buff.state.attackRate.remove()
							buff.state.reload.remove()
							buff.state.projSpeed.remove()
						}
					}),
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Flash of Brilliance',
				desc: "Solara: {glossary.solar_flare} now instantly reloads all primary and secondary weapons, causing all secondaries to fire instantly.",
				icon: 'upgrade-flash-of-brilliance',
				binaryFlags:[
					'flash-of-brilliance'
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: "Resilient Fire",
				desc: "Solara: Only Take Double Damage on every second strike when in {glossary.solar_aspect}.",
				icon: 'upgrade-resilient-fire',
				binaryFlags: ['resilient-fire'],
				affectedEntities: [AffectedEntities.Player],
				unlocks: ['solarUltimate', 'equinoxUltimate']
			},
		]
	},
	"lunarAttunementUpgrades": {
		name: "Lunar Attunement",
		desc: "While in Lunar Aspect, Solara gains a slight movement speed increase, as well as a moderate amount of attack size. However, XP pick-up values are reduced by 20%.",
		shape: 'line',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.SolaraSun || GameState.player.characterType === CharacterType.SolaraMoon
		},
		upgrades: [
			{
				name: "Starry Malevolence",
				desc: "Solara: {glossary.lunar_crush} will now {glossary.shock} enemies and slightly increase the {glossary.stun} duration",
				icon: 'upgrade-starry-malevolence',
				binaryFlags:[
					'starry-malevolence'
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Quietly Into The Night',
				desc: "Solara: While in {glossary.lunar_aspect}, enemies killed nearby leave behind a {glossary.moon_soul} damaging any enemies who make contact.",
				icon: 'upgrade-quietly-into-the-night',
				binaryFlags:[
					'quietly-into-the-night'
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: 'Balm of the Night Sky',
				desc: "The first enemy killed within 5 seconds of switching to {glossary.lunar_aspect} is guaranteed to drop a large heart.",
				icon: 'upgrade-balm-of-the-night-sky',
				binaryFlags:[
					'balm-of-the-night'
				],
				affectedEntities: [AffectedEntities.Player],
			},
			{
				name: "Wisdom of the Goddess",
				desc: "Solara: XP loss while in {glossary.lunar_aspect} is reduced to 10%",
				icon: 'upgrade-wisdom-of-the-goddess',
				binaryFlags: ['wisdom-of-the-goddess'],
				affectedEntities: [AffectedEntities.Player],
				unlocks: ['lunarUltimate', 'equinoxUltimate']
			},
		]
	},

	'solarUltimate': {
		shape: 'single',
		name: 'Solara: Solar Supremecy',
		desc: "Embrace the Solar aspect wholly, losing access to the Lunar aspect but gaining the ultimate power of the sun.",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Solar Supremecy',
			desc: "Removes ability to change to {glossary.lunar_aspect}, but grants an additional {glossary.solar_flare} charge. Solar Flare's radius is greatly increased along with {glossary.ignite} potency. Enemies killed by ignite have a chance to drop a {glossary.sun_soul} which further boosts {glossary.ignite} potency by 10% for 8s stacking up to 10 times.",
			icon: 'upgrade-solar-supremacy',
			binaryFlags: ['solar-supremacy'],
			statBonuses: [
				[['Player'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 0.30],
				[['SolaraSkill'], StatType.maxAmmo, StatOperator.SUM, 1],
				[['SolaraSkill'], StatType.reloadAmmoIncrement, StatOperator.SUM, 1],
			],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.SunSoul,
					tags: [BuffTags.Upgrade, BuffTags.Buff],
					duration: SUN_SOUL_DURATION_IN_MILLIS,
					lastsForever: false,
					startingStacks: 1,
					stackStyle: StackStyle.RefreshDuration,
					reapplyStacks: 1,
					stackLimit: 10,
					reapplyDuration: SUN_SOUL_DURATION_IN_MILLIS,
					showToClient: true,
					applyFn(buff: Buff) {
						if (isPlayer(buff.appliedTo)) {
							const player = buff.appliedTo
							buff.state.ignitePotency = player.stats.addStatBonus('ignitePotency', StatOperator.SUM_THEN_MULTIPLY, 0.1)
						}
					},
					wearOffFn(buff: Buff) {
						buff.state.ignitePotency.remove()
					}
				}),
			],
			simpleApplyFn(player: Player, state: any) {
				if (player.characterType === CharacterType.SolaraSun || player.characterType === CharacterType.SolaraMoon) {
					const skillWeapon = player.skillWeapon as SolaraSkillWeapon
					skillWeapon.ultimateForm = 'solar-supremacy'
					skillWeapon.aspectTransform(CharacterType.SolaraSun)
				}
			},
			simpleRemoveFn(player: Player, state: any) {
				if (player.characterType === CharacterType.SolaraSun || player.characterType === CharacterType.SolaraMoon) {
					const skillWeapon = player.skillWeapon as SolaraSkillWeapon
					skillWeapon.ultimateForm = undefined
				}
			},
			affectedEntities: [AffectedEntities.Player],
			locks: ['lunarAttunementUpgrades', 'lunarUltimate', 'equinoxUltimate']
		}
	},
	'lunarUltimate': {
		shape: 'single',
		name: 'Solara: Lunar Lady',
		desc: "Embrace the Lunar aspect wholly, losing access to the Solar aspect but gaining the ultimate power of the moon.",
		isUnlock: true,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Lunar Lady',
			desc: "Removes ability to change to {glossary.solar_aspect}, but grants an additional {glossary.lunar_crush} charge. {glossary.knockback} is greatly increased and a {glossary.moon_soul} will now fly toward the nearest enemy.",
			icon: 'upgrade-lunar-lady',
			binaryFlags: ['lunar-lady'],
			statBonuses: [
				[['Player'], StatType.attackKnockback, StatOperator.SUM_THEN_MULTIPLY, 0.50],
				[['SolaraSkill'], StatType.maxAmmo, StatOperator.SUM, 1],
				[['SolaraSkill'], StatType.reloadAmmoIncrement, StatOperator.SUM, 1],
			],
			extraBuffs: [
			],
			simpleApplyFn(player: Player, state: any) {
				if (player.characterType === CharacterType.SolaraSun || player.characterType === CharacterType.SolaraMoon)
				{
					const skillWeapon = player.skillWeapon as SolaraSkillWeapon
					skillWeapon.ultimateForm = 'lunar-lady'
					skillWeapon.aspectTransform(CharacterType.SolaraMoon)
				}
			},
			simpleRemoveFn(player: Player, state: any) {
				if (player.characterType === CharacterType.SolaraSun || player.characterType === CharacterType.SolaraMoon)
				{
					const skillWeapon = player.skillWeapon as SolaraSkillWeapon
					skillWeapon.ultimateForm = undefined
				}
			},
			affectedEntities: [AffectedEntities.Player],
			locks: ['solarAttunementUpgrades', 'solarUltimate', 'equinoxUltimate']
		}
	},
	'equinoxUltimate': {
		shape: 'single',
		name: 'Solara: Eminence of Equinox',
		desc: "Embrace the path of balance, using the powers of both sun and moon to their full effect.",
		isUnlock: true,
		unlockPrereqs: 2,
		upgradeEvolutionTier: 'tier2',
		top: {
			name: 'Evolve: Eminence of Equinox',
			desc: "Skill {glossary.cooldown} is reduced, increase the size and potency of {glossary.solar_flare}. When spawned, a {glossary.moon_soul} will fly toward the nearest enemy.",
			icon: 'upgrade-eminence-of-equinox',
			binaryFlags: ['eminence-of-equinox'],
			statBonuses: [
				[['SolaraSkill'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.3],
				[['Player'], StatType.ignitePotency, StatOperator.SUM_THEN_MULTIPLY, 0.30],
			],
			extraBuffs: [
			],
			simpleApplyFn(player: Player, state: any) {
				if (player.characterType === CharacterType.SolaraSun || player.characterType === CharacterType.SolaraMoon)
				{
					const skillWeapon = player.skillWeapon as SolaraSkillWeapon
					skillWeapon.ultimateForm = 'eminence-of-equinox'
				}
			},
			simpleRemoveFn(player: Player, state: any) {
				if (player.characterType === CharacterType.SolaraSun || player.characterType === CharacterType.SolaraMoon)
				{
					const skillWeapon = player.skillWeapon as SolaraSkillWeapon
					skillWeapon.ultimateForm = undefined
				}
			},
			affectedEntities: [AffectedEntities.Player],
			locks: ['solarUltimate', 'lunarUltimate']
		}
	},
	'radarShockPulseUpgrades': {
		name: "Radar Shock Pulse Upgrades",
		desc: "",
		shape: 'tree',
		upgradeEvolutionTier: 'tier1',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.Radar
		},
		top: {
			name: 'Pickup Sweep',
			desc: 'Shock Pulse now collects pickups that are hit.',
			icon: 'upgrade-pickup-sweep',
			affectedEntities: [AffectedEntities.Player],
			simpleApplyFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.setBeamCollectsPickups(true)
			},
			simpleRemoveFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.setBeamCollectsPickups(false)
			},
		},
		left: {
			name: `Discharge Capacitor`,
			desc: 'Widen your Shock Pulse, and reduce its {glossary.cooldown}',
			icon: 'upgrade-discharge-capacitor',
			statBonuses: [
				[['ShockPulse'], StatType.cooldownInterval, StatOperator.SUM, -3_000]
			],
			simpleApplyFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.expandConeCollider(15)
			},
			simpleRemoveFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.contractConeCollider(15)
			},
			affectedEntities: [AffectedEntities.Player],
		},
		right: {
			name: `Truly Shocking`,
			desc: 'Shock Pulse now inflicts {glossary.shock} for a small duration, and applies {glossary.stun} for a shorter duration.',
			icon: 'upgrade-truly-shocking',
			statBonuses: [
				[['ShockPulse'], StatType.stunChance, StatOperator.SUM, 1],
				[['ShockPulse'], StatType.shockChance, StatOperator.SUM, 1],
				[['ShockPulse'], StatType.shockPotency, StatOperator.SUM, 3]
			],
			affectedEntities: [AffectedEntities.Player],
		},
		leftLeaf: {
			name: 'Longwave Radio',
			desc: 'Massively widen the area of Shock Pulse.',
			icon: 'upgrade-longwave-radio',
			simpleApplyFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.expandConeCollider(60)
			},
			simpleRemoveFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.contractConeCollider(60)
			},
			statBonuses: [
				[['ShockPulse'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.2], // also increase attack size (shh)
			],
			affectedEntities: [AffectedEntities.Player],
		},
		middleLeaf: {
			name: `Battery Quickcharge`,
			desc: 'Reduce the {glossary.cooldown} of Shock Pulse.',
			icon: 'upgrade-battery-quickcharge',
			statBonuses: [
				[['ShockPulse'], StatType.cooldownInterval, StatOperator.SUM, -4_000]
			],
			affectedEntities: [AffectedEntities.Player],
		},
		rightLeaf: {
			name: `Radiation Blast`,
			desc: `Shock Pulse now applies {glossary.poison} to enemies based on your level, and enemies shocked from Shock Pulse are now shocked forever.`,
			icon: 'upgrade-radiation-blast',
			simpleApplyFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.poisonEnemiesOnHit = true
			},
			simpleRemoveFn(player, state) {
				const skill = player.skillWeapon as RadarSkillWeapon
				skill.poisonEnemiesOnHit = false
			},
			statBonuses: [
				[['ShockPulse'], StatType.shockPotency, StatOperator.SUM, 1_000], // 1,000 seconds
			],
			affectedEntities: [AffectedEntities.Player],
		},
	},
	
	'radarGadgetUpgrades': {
		name: "Radar Gadget Upgrades",
		desc: "",
		shape: 'tree',
		upgradeEvolutionTier: 'tier1',
		upgradeAllowedFunction() {
			return GameState.player.characterType === CharacterType.Radar
		},
		top: {
			name: `High-Capacity Bettery`,
			desc: 'Increase the duration of your pickup radar, and summon an additional pet during Pet Bombardment.',
			icon: 'upgrade-high-capacity-battery',
			affectedEntities: [AffectedEntities.Player],
			statBonuses: [
				[['FieldOpGadget'], StatType.skillDuration, StatOperator.SUM_THEN_MULTIPLY, 0.5]
			],
			simpleApplyFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.petBombPetCount++
			},
			simpleRemoveFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.petBombPetCount--
			},
		},
		left: {
			name: 'Pet Fiesta',
			desc: 'Pet Bombardment summons an additional pet, and reduce the cooldown of all of your pets.',
			icon: 'upgrade-pet-fiesta',
			affectedEntities: [AffectedEntities.Player],
			statBonuses: [
				[['Pets'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.2]
			],
			simpleApplyFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.petBombPetCount++
			},
			simpleRemoveFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.petBombPetCount--
			},
		},
		right: {
			name: `High-Priority Targets`,
			desc: 'You mark nearby enemies as wealthy each time your gadget activates. Wealthy enemies drop more XP.',
			icon: 'upgrade-high-priority-targets',
			affectedEntities: [AffectedEntities.Player],
			simpleApplyFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.markWealthyEnemies = true
			},
			simpleRemoveFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.markWealthyEnemies = false
			},
		},
		leftLeaf: {
			name: 'Rapid Recon',
			desc: 'Drastically decrease the cooldown of your gadget, and disable Supply Drops.',
			icon: 'upgrade-rapid-recon',
			affectedEntities: [AffectedEntities.Player],
			statBonuses: [
				[['FieldOpGadget'], StatType.cooldownInterval, StatOperator.SUM, -10_000]
			],
			simpleApplyFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.skillOptions.remove(RadarSkillOption.SupplyDrop)
			},
			simpleRemoveFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.skillOptions.push(RadarSkillOption.SupplyDrop)
			},
		},
		middleLeaf: {
			name: `Improved Pet Targeting`,
			desc: 'All pets do two rounds of attacks back-to-back. Pet bombardment summons two additional pets.',
			icon: 'upgrade-TBD-pets',
			affectedEntities: [AffectedEntities.Player],
			binaryFlags: ['pet-double-attack'],
			simpleApplyFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.petBombPetCount += 2
			},
			simpleRemoveFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.petBombPetCount -= 2
			},
		},
		rightLeaf: {
			name: `Cornucopia`,
			desc: `Supply drops now contain bonus goodies, and your gadget marks twice as many wealthy enemies. Disables Pickup Radar.`,
			icon: 'upgrade-cornucopia',
			affectedEntities: [AffectedEntities.Player],
			simpleApplyFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.skillOptions.remove(RadarSkillOption.Radar)

				skill.wealthyEnemyMarkCountScalar = 2

				skill.useBonusSupplyDrops = true
				skill.supplyDropCurrencyBonusScalar = 1.5
				skill.supplyDropXPMin = 10
				skill.supplyDropXPMax = 15
			},
			simpleRemoveFn(player, state) {
				const skill = player.passiveSkill as RadarPassiveSkill
				skill.skillOptions.push(RadarSkillOption.Radar)

				skill.wealthyEnemyMarkCountScalar = 1
				skill.useBonusSupplyDrops = false
			},
		},
	}
}