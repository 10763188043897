<template>
	<div class="pip-bar-wrapper">
		<div v-if="useHalves">
			<!-- anybody got a more clever way to do this? only want pips/2 divs rendered, and apply the half class to "half filled" divs -->
			<div v-for="i in halfPips" :key="i" class="pip-bar-segment" :class="{ filled: i*2 <= value, half: (i*2 === value+1) }">
			</div>
		</div>
		<div v-else>
			<div v-for="i in pips" :key="i" class="pip-bar-segment" :class="{ filled: i <= value }">
			</div>
		</div>
	</div>	
</template>

<script lang="ts">
// PROP-ONLY COMPONENT!
// This component is not intended to pluck state directly from Vuex, unless it is related to
// screen size, layout, rendering, or other device-specific/responsive concerns.

export default {
	name: 'PipBar',
	components: {

	},
	data() {
		return {}
	},
	props: {
		useHalves: {
			type: Boolean,
			required: true,
		},
		pips: {
			type: Number,
			required: false,
			default: 1,
		},
		value: {
			type: Number,
			required: true,
		},
	},
	computed: {
		halfPips() {
			return Math.round(this.pips / 2)
		}
	}
}
</script>

<style lang="less" scoped>
.pip-bar-wrapper {
	position: relative;

	div {
		display: flex;
	}

	.pip-bar-segment {
		margin: 0 5px;
		height: 50px;
		width: 50px;
		background-color: #011025;
		color: white;

		&.filled {
			background-color: #00c45a; //TODO: replace this with prop-driven icons/whatever, so we can insert heart containers, coins, ranks, etc.
		}
		&.half {
			background-color: #005a2a;
		}
	}
}

</style>
