export enum AllEventTypes {
	ACT_COMPLETE,
    BOSS,
    LOOT_GOBLINS,
    PETS_RESCUE
}

// Used in the End Chapter Store/ UI html table
export const endChapterEventPrettyNames = new Map<AllEventTypes, string>()
endChapterEventPrettyNames.set(AllEventTypes.ACT_COMPLETE, 'Acts Completed')
endChapterEventPrettyNames.set(AllEventTypes.BOSS, 'Bosses Killed')
endChapterEventPrettyNames.set(AllEventTypes.LOOT_GOBLINS, 'Loot Goblins Killed')
endChapterEventPrettyNames.set(AllEventTypes.PETS_RESCUE, 'Pets Rescued')