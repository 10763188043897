export enum TutorialFlagsEnum {
    MadeFirstStory = 'firstRun',
    PlayedStory = 'playedStory', // just a flag, no tutorial
    Stories = 'stories',
    GainedOneVote = 'gainedOneVote',
    ArchivedStories = 'archivedStories',
    FirstChapterWinCharacterSelect = 'firstWinCharacterSelect',
}
const tutorialFlagsValues = Object.values(TutorialFlagsEnum)
type TutorialFlagsKeys = typeof tutorialFlagsValues[number]

export type FlagRecord = Record<TutorialFlagsKeys, boolean>

export default class TutorialFlags  {
    flagValues: FlagRecord

    constructor(incomingFlags?: object) {
        this.flagValues = {} as any
        tutorialFlagsValues.forEach(flag => {
            this.flagValues[flag] = false
        })

        if (incomingFlags) {
            this.loadFromJson(incomingFlags)
        }
    }

    loadFromJson(incomingFlags: object) {
        const keys = Object.keys(this.flagValues)
        for (let i = 0; i < keys.length; ++i) {
            const key = keys[i]
            if (incomingFlags[key] !== null && incomingFlags[key] !== undefined) {
                if (!this.flagValues[key]) {
                    this.flagValues[key] = incomingFlags[key]
                }
            }
        }
    }
}
