import { Vector } from "sat"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { CircleColliderConfig, ColliderType } from "../../engine/collision/colliders"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import CollisionSystem from "../../engine/collision/collision-system"
import { GameState } from "../../engine/game-state"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import EntityStatList from "../../stats/entity-stat-list"
import { angleOfVector, distanceSquaredVV, vecAngle } from "../../utils/math"
import { radians, timeInSeconds } from "../../utils/primitive-types"
import { vectorLerpMutate } from "../../utils/vector"
import { GroundPickup } from "../pickups/ground-pickup"
import { Player } from "../player"
import { Pet, PetCollectionName } from "./pet"
import { GroundPickupType } from "../pickups/ground-pickup-types"
import { EntityType } from "../entity-interfaces"

const COLLECTION_TIME: timeInSeconds = 10 

const DEPOSIT_RATE: timeInSeconds = 0.2
const DEPOSIT_INCREASE_RATE: timeInSeconds = 0.01

const CIRCLE_RADIUS = 700
const ONE_CIRCLE_REVOLUTION_TIME: timeInSeconds = 2
const CIRCLE_RADS_PER_SECOND = (Math.PI * 2) / ONE_CIRCLE_REVOLUTION_TIME

const MOVE_OUT_SPEED = 1_000 * 1_000 // (radius * 2) squared = half a second to move out to circle when at max range

const RETURN_TIME: timeInSeconds = 0.33

enum AbilityState {
    Waiting,
    MoveOutToCircle,
    Circling,
    Returning,
    Depositing
}

const PICKUP_COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		position: [0, -40],
		radius: 80,
	}
]

export class CollectorPet extends Pet {
    
    abilityState: AbilityState = AbilityState.Waiting
    timeInState: timeInSeconds = 0

    player: Player

    moveOutDirection: Vector = new Vector()
    startingPosition: Vector = new Vector()
    lerpTime: number = 0

    startingCircleAngle: radians = 0
    fullCircleAngle: radians = 0

    reuseVector: Vector = new Vector()

    depositRateBonus: number
    depositAcc: timeInSeconds = 0

    collectedPickups: GroundPickup[] = []

    pickupCollider: ColliderComponent

    constructor(name: PetCollectionName, parentStatList: EntityStatList, position: Vector, uncaged?: boolean) {
        super(name, parentStatList, position, uncaged)
        this.player = GameState.player

        this.pickupCollider = new ColliderComponent(PICKUP_COLLIDER_CONFIG, this, CollisionLayerBits.PetPickup, this.onPickupCollision.bind(this), false, true, true)
        this.pickupCollider.isColliderActive = false        
    }

    override releaseFromCage(noFollow?: boolean, immediate?: boolean): void {
        super.releaseFromCage(noFollow, immediate)
		CollisionSystem.getInstance().addCollidable(this.pickupCollider)
    }

    resetStatsFn(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.maxAmmo = 1
        statList._actualStatValues.cooldownInterval = 10_000
        statList._actualStatValues.reloadInterval = 50
        statList._actualStatValues.reloadAmmoIncrement = 1
    }

    useAbility() {
        this.switchState(AbilityState.MoveOutToCircle)
        this.setFollowPosition()
        this.startingPosition.copy(this.position)

        this.moveOutDirection.copy(this.position)
        this.moveOutDirection.sub(this.player.position)
        if (this.moveOutDirection.x === 0 && this.moveOutDirection.y === 0) {
            this.moveOutDirection.x = 1
        } else {
            this.moveOutDirection.normalize()
        }

        this.moveOutDirection.scale(CIRCLE_RADIUS)

        this.reuseVector.copy(this.position)
        this.reuseVector.add(this.moveOutDirection)

        const distSquared = distanceSquaredVV(this.reuseVector, this.position)
        this.lerpTime =  distSquared / MOVE_OUT_SPEED

        this.startingCircleAngle = angleOfVector(this.moveOutDirection)
        this.fullCircleAngle = this.startingCircleAngle + (Math.PI * 2)

        this.colliderComponent.isColliderActive = false
        this.pickupCollider.isColliderActive = true
    }

    override update(delta: timeInSeconds): void {
        if (this.abilityState === AbilityState.Waiting) {
            this.useFollowPosition = false
            super.update(delta)
        } else {
            this.updateFollow(delta)
            this.timeInState += delta
            switch (this.abilityState) {
                case AbilityState.MoveOutToCircle:
                    const playerPos = this.player.position
                    this.reuseVector.copy(playerPos)
                    this.reuseVector.add(this.moveOutDirection)
                    vectorLerpMutate(this.position, this.startingPosition, this.reuseVector, Math.clamp(this.timeInState / this.lerpTime, 0, 1))

                    if (this.timeInState >= this.lerpTime) {
                        this.switchState(AbilityState.Circling)
                    }
                    break
                case AbilityState.Circling:
                    this.reuseVector.copy(this.moveOutDirection)
                    const rot = 0 + (CIRCLE_RADS_PER_SECOND * this.timeInState)
                    this.reuseVector.rotate(rot)

                    this.position.copy(this.player.position)
                    this.position.add(this.reuseVector)

                    if (this.timeInState >= COLLECTION_TIME) {
                        this.startingPosition.copy(this.position)

                        this.switchState(AbilityState.Returning)
                        this.colliderComponent.isColliderActive = true
                        this.pickupCollider.isColliderActive = false
                    }
                    break
                case AbilityState.Returning:
                    vectorLerpMutate(this.position, this.startingPosition, this.player.position, Math.clamp(this.timeInState / RETURN_TIME, 0, 1))

                    if (this.timeInState >= RETURN_TIME) {
                        this.switchState(AbilityState.Depositing)
                        this.depositRateBonus = 1
                    }
                    break
                case AbilityState.Depositing:
                    this.position.copy(this.player.position)

                    this.depositRateBonus += (DEPOSIT_INCREASE_RATE * delta)
                    this.depositAcc += this.depositRateBonus * delta

                    if (this.depositAcc >= DEPOSIT_RATE) {
                        const pickup = this.collectedPickups.pop()
                        if (pickup) {
                            pickup.collectImmediately(this.player)
                        }
                    }

                    if (this.collectedPickups.length === 0) {
                        this.finishedPetAbility()
                        this.switchState(AbilityState.Waiting)
                    }
                    break
            }

            this.gfx.update(delta)
        }
    }

	onPickupCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
        if (otherEntity.owner.entityType !== EntityType.Pickup) {
            return // temporal distortion
        }

        const pickup = otherEntity.owner as GroundPickup
        if (pickup.pickupType === GroundPickupType.Healing) {
            if (this.player.currentHealth === this.player.lastMaxHealth) {
                return
            }
        }

        pickup.onPickedUp(this)
        pickup.onPickupDone() // don't bother chasing us
        this.collectedPickups.push(pickup)
    }

    switchState(state: AbilityState) {
        this.timeInState = 0
        this.abilityState = state
    }
}