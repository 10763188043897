// Resolution Related items are all in ui-scaling.ts for the sake of keeping all that code together.

import { LocalSettings } from '../../settings/local-settings'
import { debounce, isEqual } from 'lodash'
import { UI } from '../ui'
import { UICurrentState, Views } from './ui-store'
import { loadFromLocalStorage, loadFromSessionStorage, saveToLocalStorage, saveToSessionStorage } from '../../utils/local-storage'
import { PLAYER_ICON_NAMES, PlayerIconColor, PlayerIconTag } from '../../settings/player-icon-tags'
import { postUpdatePlayerIcon } from '../../utils/api/griddle-requests'
import ClientPlayerInput, { InputAction } from '../../engine/client-player-input'
import _ from 'lodash'
import { vuei18n } from '../../utils/i18n'
const { addDeviceScalingToWidthHeight } = require('../../electron-required/add-remove-scaling-helper')

window.addEventListener('fullscreenchange', fullscreenHandler)


if (process.env.IS_ELECTRON) {
	window.bridge.ElectronToDiebrary(async (event, eventType, payload) => {
		if (eventType === 'updateControllers') {
			UI.getInstance().emitMutation('settings/updateControllers', payload)
		}
	})
}

function fullscreenHandler() {
	if (document.fullscreenElement === null) {
		saveToLocalStorage('settings.fullscreen', 'false')
		saveToSessionStorage('settings.fullscreen', 'false')
		UI.getInstance().emitMutation('settings/updateFullscreen', false)
	}
}

interface InputButton {
	action: InputAction
	code: number | string
	controllerInput?: string //TODO: add controller mapping here or break out into separate interface and map
}
interface InputDevice {
	id: string
	name: string
}
const debouncedUpdateSettings = debounce((state: SettingsInterface) => {
	LocalSettings.applySettings(state.currentSettings)
}, 250)

const initialState: SettingsInterface = {
	fullscreen: false,
	isRebindingKey: false,
	currentSettings: {
		sfxVolume: 100,
		bgmVolume: 100,
		selectedIcon: 'bees',
		selectedColor: 'orange',
		autoAim: false,
		autoShoot: false,
		keyboardMappings: {
			shoot: "MouseL",
			skill: "Space",
			moveUp: "KeyW",
			moveDown: "KeyS",
			moveLeft: "KeyA",
			moveRight: "KeyD",
			pause: "KeyP",
		},
		width: 1280,
		height: 800,
		windowMode: 'windowed',
	},
	oldSettings: {
		sfxVolume: 100,
		bgmVolume: 100,
		selectedIcon: 'bees',
		selectedColor: 'orange',
		autoAim: false,
		autoShoot: false,
		keyboardMappings: {
			shoot: "MouseL",
			skill: "Space",
			moveUp: "KeyW",
			moveDown: "KeyS",
			moveLeft: "KeyA",
			moveRight: "KeyD",
			pause: "KeyP",
		},
		width: 1280,
		height: 800,
		windowMode: 'windowed',
	},
	windowMode: 'windowed', //@TODO: Greeley This needs to be removed and replaced with currentSettings.windowMode where appropriate
	inputDevices: [{ id: 'keyboard', name: 'settings.category_keyboard' }],
	selectedInputDevice: { id: 'keyboard', name: 'settings.category_keyboard' },
}

interface SettingsInterface {
	fullscreen: boolean
	isRebindingKey: boolean
	currentSettings: {
		sfxVolume: number
		bgmVolume: number
		selectedIcon: string
		selectedColor: string
		autoAim: boolean
		autoShoot: boolean
		keyboardMappings: {
			[InputAction.SHOOT]: string
			[InputAction.SKILL]: string
			[InputAction.MOVE_UP]: string
			[InputAction.MOVE_DOWN]: string
			[InputAction.MOVE_LEFT]: string
			[InputAction.MOVE_RIGHT]: string
			[InputAction.PAUSE]: string
		}
		width: number
		height: number
		windowMode: string
	}
	oldSettings: {
		sfxVolume: number
		bgmVolume: number
		selectedIcon: string
		selectedColor: string
		autoAim: boolean
		autoShoot: boolean
		keyboardMappings: {
			[InputAction.SHOOT]: string
			[InputAction.SKILL]: string
			[InputAction.MOVE_UP]: string
			[InputAction.MOVE_DOWN]: string
			[InputAction.MOVE_LEFT]: string
			[InputAction.MOVE_RIGHT]: string
			[InputAction.PAUSE]: string
		}
		width: number
		height: number
		windowMode: string
	}
	windowMode: string
	inputDevices: InputDevice[]
	selectedInputDevice: any
}

const store = {
	namespaced: true,
	modules: {},
	state: initialState,
	getters: {
		getIsSettingsEqual(state: SettingsInterface) {
			return isEqual(state.currentSettings, state.oldSettings)
		},
		getCurrentBGMVolume(state: SettingsInterface) {
			return state.currentSettings.bgmVolume
		},
		getCurrentSFXVolume(state: SettingsInterface) {
			return state.currentSettings.sfxVolume
		},
		getSelectedIcon(state: SettingsInterface) {
			return state.currentSettings.selectedIcon
		},
		getSelectedColor(state: SettingsInterface) {
			return state.currentSettings.selectedColor
		},
		getAutoAim(state: SettingsInterface) {
			return state.currentSettings.autoAim
		},
		getAutoShoot(state: SettingsInterface) {
			return state.currentSettings.autoShoot
		},
		getInputDevices(state: SettingsInterface) {
			// When ready get a list of currently attached input devices, add them to the list
			// with a id and a localized string name
			return state.inputDevices
		},
		getSelectedInputDeviceId(state: SettingsInterface) {
			return state.selectedInputDevice.id
		},
		getNameFromAction: () => (action: InputAction) => {
			switch (action) {
				case InputAction.SHOOT:
					return 'settings.input_shoot'
				case InputAction.SKILL:
					return 'settings.input_skill'
				case InputAction.MOVE_UP:
					return 'settings.input_move_up'
				case InputAction.MOVE_DOWN:
					return 'settings.input_move_down'
				case InputAction.MOVE_LEFT:
					return 'settings.input_move_left'
				case InputAction.MOVE_RIGHT:
					return 'settings.input_move_right'
				case InputAction.PAUSE:
					return 'settings.input_pause'
			}
		},
		getIsRebindingKey(state: SettingsInterface) {
			return state.isRebindingKey
		}
	},
	mutations: {
		toggleFullscreen(state: SettingsState) {
			if (!document.fullscreenElement) {
				document.documentElement.requestFullscreen()
			} else {
				if (document.exitFullscreen) {
					document.exitFullscreen()
				}
			}
		},
		updateFullscreen(state: SettingsState, toggle: boolean) {
			state.fullscreen = toggle
		},
		changeBGMVolume(state: SettingsState, volume: string) {
			console.log('changing bgm volume')
			console.log(volume)
			state.currentSettings.bgmVolume = parseFloat(volume)
			debouncedUpdateSettings(state)
		},
		changeSFXVolume(state: SettingsState, volume: string) {
			state.currentSettings.sfxVolume = parseFloat(volume)
			debouncedUpdateSettings(state)
		},
		updateControllers(state: SettingsState, controllers) {
			const keyboard = _.find(state.inputDevices, (device) => device.id === 'keyboard')
			const updatedControllers = _.map(controllers, (controller) => {
				const { id, name } = controller
				const { i18nTag, controllerNumber } = name
				return {
					id,
					name: vuei18n.global.t(i18nTag, { controllerNumber })
				}
			})
			state.inputDevices = [keyboard, ...updatedControllers]
		},

		updateValues(state: SettingsState, setting: { key: string; value: any }) {
			const { key, value } = setting

			switch (key) {
				case 'sfxVolume':
					state.currentSettings.sfxVolume = parseInt(value)
					state.oldSettings.sfxVolume = parseInt(value)
					break
				case 'bgmVolume':
					state.currentSettings.bgmVolume = parseInt(value)
					state.oldSettings.bgmVolume = parseInt(value)
					break
				case 'selectedIcon':
					state.currentSettings.selectedIcon = value
					state.oldSettings.selectedIcon = value
					break
				case 'selectedColor':
					state.currentSettings.selectedColor = value
					state.oldSettings.selectedColor = value
					break
				case 'autoAim':
					state.currentSettings.autoAim = value === true || value === 'true'
					state.oldSettings.autoAim = value === true || value === 'true'
					break
				case 'autoShoot':
					state.currentSettings.autoShoot = value === true || value === 'true'
					state.oldSettings.autoShoot = value === true || value === 'true'
					break
			}
		},
		updateSelectedIcon(state: SettingsInterface, icon: PlayerIconTag) {
			state.currentSettings.selectedIcon = icon
		},
		updateSelectedColor(state: SettingsInterface, color: PlayerIconColor) {
			state.currentSettings.selectedColor = color
		},
		updateKeyboardMapping(state: SettingsInterface, payload: { action: string; code: number | string }) {
			const { action, code } = payload
			state.currentSettings.keyboardMappings[action] = code
		},
		updateResponsiveWidthHeight(state: SettingsInterface, scale: any) {
			state.currentSettings.width = Math.ceil(window.innerWidth / scale)
			state.currentSettings.height = Math.ceil(window.innerHeight / scale)
		},
		updateWindowMode(state: SettingsInterface, mode: string) {
			state.currentSettings.windowMode = mode
		},
		setIsRebindingKey(state: SettingsInterface, rebinding: boolean) {
			state.isRebindingKey = rebinding
		},
		toggleAutoAim(state: SettingsInterface) {
			state.currentSettings.autoAim = !state.currentSettings.autoAim
			debouncedUpdateSettings(state)

			if (process.env.IS_ELECTRON) {
				window.bridge.updateElectronStore('autoAim', state.currentSettings.autoAim)
			}
		},
		toggleAutoShoot(state: SettingsInterface) {
			state.currentSettings.autoShoot = !state.currentSettings.autoShoot
			debouncedUpdateSettings(state)

			if (process.env.IS_ELECTRON) {
				window.bridge.updateElectronStore('autoShoot', state.currentSettings.autoShoot)
			}
		}
	},
	actions: {
		saveAndClose({ state, dispatch }: { state: SettingsState; dispatch: any }) {
			dispatch('saveSettings')
			dispatch('closeSettings')
		},
		async saveSettings({ state, commit }: { state: SettingsState; commit: any }) {
			// Post Icon stuff selection to DB
			if (state.currentSettings.selectedIcon !== state.oldSettings.selectedIcon || state.currentSettings.selectedColor !== state.oldSettings.selectedColor) {
				try {
					const response = await postUpdatePlayerIcon(state.currentSettings.selectedIcon, state.currentSettings.selectedColor)
				} catch (err) {
					console.error(`Error while posting settings`)
					console.error(err)
				}
			}
			if (process.env.IS_ELECTRON) {
			} else {
				// Update local state
				for (const [key, value] of Object.entries(state.currentSettings)) {
					// Update UI and Backend Audio values
					commit('updateValues', { key: key, value: value })
					// Icons are stored in the DB
					if (key === 'selectedIcon' || key === 'selectedColor') {
						continue
					}
					// Save to local storage
					const storageKey = `settings.${key}`
					saveToLocalStorage(storageKey, value.toString())
					saveToSessionStorage(storageKey, value.toString())

					// Apply Changes to backend
					LocalSettings.applySettings(state.currentSettings)
				}
			}
		},
		updateSelectedController({ state, dispatch }, controllerId) {
			if (process.env.IS_ELECTRON) {
				const selectedDevice = _.find(state.inputDevices, (device) => device.id === controllerId)
				if (selectedDevice) {
					state.selectedInputDevice = selectedDevice
					if (controllerId === 'keyboard') {
						ClientPlayerInput.controllerActive = false
					} else {
						ClientPlayerInput.controllerActive = true
						window.bridge.setActiveController(controllerId)
					}
				}
			}
		},
		closeSettings({ state, rootState, dispatch }: { state: SettingsState; rootState: any; dispatch: any }) {
			const uiState = rootState.ui.uiState
			if (uiState === UICurrentState.IN_GAME) {
				dispatch('ui/togglePauseByUser', 'user', { root: true })
			} else {
				dispatch('ui/changeActiveView', Views.LOGGED_IN, { root: true })
			}
		},

		fetchLocalWebSettings({ state, commit }: { state: SettingsState; commit: any }) {
			for (const key of Object.keys(state.currentSettings)) {
				const storageKey = `settings.${key}`
				try {
					// Icons are stored in the DB
					if (key === 'selectedIcon' || key === 'selectedColor') {
						continue
					}
					let val: any = loadFromLocalStorage(storageKey)
					if (val === undefined || val === null) {
						val = loadFromSessionStorage(storageKey)
					}

					if (val !== null && val !== undefined) {
						// Update frontend settings with local storage values
						commit('updateValues', { key: key, value: val })
					}
				} catch (e) {
					console.warn('Could not get local storage due to security settings')
				}
			}
		},
		async changeWindowModeString({ state, commit, rootState, dispatch }: { state: SettingsState; commit: any; rootState: any; dispatch: any }, payload) {
			commit('updateWindowMode', payload)
			window.bridge.updateElectronStore('windowMode', payload);
		},
		async toggleWindowMode({ state, commit, rootState, dispatch }: { state: SettingsState; commit: any; rootState: any; dispatch: any }, payload) {
			if (process.env.IS_ELECTRON) {
				await window['bridge'].exitElectronFullscreen()
				switch (payload) {
					case 'fullscreen':
						await window['bridge'].setElectronWindowResizable(true)
						await window['bridge'].setElectronWindowUnmaximized()
						await window['bridge'].toggleElectronFullscreen()
						break
					case 'windowed':
						await window['bridge'].setElectronWindowResizable(false)
						await window['bridge'].setElectronWindowUnmaximized()
						// The refresh is needed since myWindow.restore() in the above function call does not always have an accurate record of the previous resolution
						await dispatch('uiScaling/refreshResolution', undefined, { root: true })
						break
					case 'windowedFullscreen':
						await window['bridge'].setElectronWindowResizable(true)
						await window['bridge'].setElectronWindowMaximize()
						break
				}
			} else {
				// Web fullscreen mode
				const newFullscreenValue = !state.fullscreen
				commit('updateFullscreen', newFullscreenValue)
				commit('toggleFullscreen')
			}
		},
		async upgradeBindings({ state, commit }: { state: SettingsState; commit: any }, payload) {
			const { action, code } = payload
			commit('updateKeyboardMapping', payload)
			window.bridge.updateElectronStore(`keyboardMappings.${action}`, code)
			LocalSettings.applySettings(state.currentSettings)
		},
		updateBGMVolume({ state, commit }: { state: SettingsState; commit: any }, volume) {
			if (process.env.IS_ELECTRON) {
				window.bridge.updateElectronStore('bgmVolume', parseFloat(volume))
			}
			commit('changeBGMVolume', volume)
		},
		updateSFXVolume({ state, commit }: { state: SettingsState; commit: any }, volume) {
			if (process.env.IS_ELECTRON) {
				window.bridge.updateElectronStore('sfxVolume', parseFloat(volume))
			}
			commit('changeSFXVolume', volume)
		},
		setInitialSettingsConfig({ state, commit, rootGetters, rootState }: { state: SettingsState; commit: any; rootGetters: any; rootState: any }, payload) {
			const electronStore = addDeviceScalingToWidthHeight(payload, rootState.uiScaling.devicePixelRatio)
			state.currentSettings = electronStore
			if (electronStore.bgmVolume !== undefined && electronStore.bgmVolume !== null) {
				commit('changeBGMVolume', electronStore.bgmVolume)
			}
			if (electronStore.sfxVolume !== undefined && electronStore.sfxVolume !== null) {
				commit('changeSFXVolume', electronStore.sfxVolume)
			}
			state.oldSettings = electronStore

			window['bridge'].setElectronWindowResizable(false)

			const supportedResolutions = rootGetters['uiScaling/getSupportedResolutions']
			const matchedResolution = supportedResolutions.find((resolution) => resolution.width === electronStore.width && resolution.height === electronStore.height)
			// This may never occur naturally but during debugging matchedResuolution can return undefined, freezing the game (the result of a broken electron store state).
			// If that occurs, just provide an arbitrary resolution from the supportedResolutions list
			if (!matchedResolution) {
				const fallbackResolution = supportedResolutions[0]
				commit('uiScaling/updateWindowResolution', fallbackResolution, { root: true }) 
			} else {
				commit('uiScaling/updateWindowResolution', matchedResolution, { root: true })
			}
		},
		applySettings({state}) {
			LocalSettings.applySettings(state.currentSettings)
		}
	},
}

export function getIconTags() {
	const iconNames = Object.values(PlayerIconTag)
	return iconNames
}

export function getIconFromTag(tag: PlayerIconTag) {
	return PLAYER_ICON_NAMES[tag]
}

export function getIconColors() {
	const iconNames = Object.values(PlayerIconColor)
	return iconNames
}

export function getIconColorHex(color: PlayerIconColor) {
	switch (color) {
		case PlayerIconColor.Orange:
			return '#f2bf6e'
		case PlayerIconColor.Blue:
			return '#6eedf2'
		case PlayerIconColor.Green:
			return '#c8f26e'
		default:
			return '#f2bf6e'
	}
}

type SettingsState = typeof initialState
export type SettingsStore = typeof store

export const settingsStore = () => {
	return store
}
