<template>
    <div class="control-scheme">
        <div class="mouse-control center-items">
            <Header :header-text="'Shoot'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'"
                :enable-drop-shadow="false" :font-color="'green'"></Header>
            <div class="outline-mouse">
                <div class="fill-mouse right"></div>
            </div>
        </div>
        <div class="pause-control">
            <Header :header-text="'Pause'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'"
                :enable-drop-shadow="false" :font-color="'green'"></Header>
            <div class="pause-keyboard">
                <div class="outline-square text-align" :lang="this.$i18n.locale"><span class="drop-shadow">P</span></div>
            </div>
        </div>
        <div class="move-control">
            <Header :header-text="'Movement'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'"
                :enable-drop-shadow="false" :font-color="'green'"></Header>
            <div class="up-keyboard">
                <div class="outline-square text-align" :lang="this.$i18n.locale">
                    <span class="drop-shadow"> {{ keyCodeToChar(currentSettings.keyboardMappings['moveUp']) }}</span>
                </div>
            </div>
            <div class="left-keyboard">
                <div class="outline-square text-align" :lang="this.$i18n.locale">
                    <span class="drop-shadow"> {{ keyCodeToChar(currentSettings.keyboardMappings['moveLeft']) }}</span>
                </div>
            </div>
            <div class="bottom-keyboard">
                <div class="outline-square text-align" :lang="this.$i18n.locale">
                    <span class="drop-shadow"> {{ keyCodeToChar(currentSettings.keyboardMappings['moveDown']) }}</span>
                </div>
            </div>
            <div class="right-keyboard">
                <div class="outline-square text-align" :lang="this.$i18n.locale">
                    <span class="drop-shadow"> {{ keyCodeToChar(currentSettings.keyboardMappings['moveRight']) }}</span>
                </div>
            </div>
        </div>
        <div class="skill-control">
            <!-- At some point you may bind skill to something other then space bar -->
            <Header :header-text="'Skill'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'"
                :enable-drop-shadow="false" :font-color="'green'"></Header>
            <div v-if="currentSettings.keyboardMappings['skill'] === 'Space'" class="rectangle">
                <div class="outline-rectangle">
                    <div class="fill-rectangle"></div>
                </div>
            </div>
            <div v-else class="skill-square">
                <div class="outline-square text-align" :lang="this.$i18n.locale">
                    <span class="drop-shadow"> {{ keyCodeToChar(currentSettings.keyboardMappings['skill']) }}</span>
                </div>
            </div>
            <Header :header-text="currentSettings.keyboardMappings['skill']" :text-alignment="'center'" :font-face="'pixl'"
                :font-size="'large'" :enable-drop-shadow="false" :font-color="'green'"></Header>
        </div>
    </div>
</template>

<script lang = "ts">
import Header from './dividers-headers/header.vue'
import { mapState } from 'vuex'

import { keycodeToCharMixin } from './mixins/KeycodeToChar'

export default {
    name: 'Controls',
    components: {
        Header
    },
    mounted() {

    },
    unmounted() {

    },
    props: {},
    computed: {
        ...mapState('settings', ['currentSettings']),
        keyboardMappings() {
            return this.currentSettings.keyboardMappings
        }
    },
    mixins: [keycodeToCharMixin],
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.control-scheme {

    .mouse-control,
    .pause-control,
    .move-control,
    .skill-control {
        display: flex;
        flex-wrap: wrap;
        width: 200px;
        justify-content: center;

        .pause-keyboard,
        .up-keyboard,
        .right-keyboard,
        .bottom-keyboard,
        .left-keyboard,
        .skill-square {
            margin: 10px 5px;

            .outline-square {
                background-image: url('../../../assets/ui/control-graphics/controls-outline-square.png');
                width: 52px;
                height: 49px;

                &.text-align {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: @green;

                    &[lang='en'] {
                        .regularFont(@enRegularFontPixl);
                        font-variant: small-caps;
                        font-size: 30px;
                    }

                    &[lang='zh'] {
                        .regularFont(@zhHansHeaderFont);
                    }
                }
            }

            .fill-rectangle {}
        }

        // Puts one key on a row of it's own
        .pause-keyboard,
        .up-keyboard,
        .skill-square {
            flex: 0 0 100%;
            display: flex;
            justify-content: center;
        }
    }

    .mouse-control {
        &.center-items {
            flex-direction: column;
            align-content: center;

            .outline-mouse {
                background-image: url('../../../assets/ui/control-graphics/controls-outline-mouse.png');
                width: 108px;
                height: 160px;
                position: relative;

                .fill-mouse {
                    position: relative;
                    top: 10px;
                    left: 15px;
                    background-image: url('../../../assets/ui/control-graphics/controls-inside-mouse-1.png');
                    width: 31px;
                    height: 44px;
                }
            }
        }

        .fill-mouse.right {}
    }

    .skill-control {
        .rectangle {
            .outline-rectangle {
                background-image: url('../../../assets/ui/control-graphics/controls-outline-rectangle.png');
                width: 169px;
                height: 50px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .fill-rectangle {
                    background-image: url('../../../assets/ui/control-graphics/controls-inside-rectangle.png');
                    width: 150px;
                    height: 31px;
                    position: relative;
                }
            }
        }
    }
}
</style>