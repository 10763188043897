<template>
	<div id="landing-page">
		<Logo></Logo>
		<Panel id="landing-page-panel" :panel-identifier="'sign-in'" :height="panelHeight">
			<template #panel-content>
				<div class="sign-in-content" v-if="!externalUserAccountType">
					<div class="header" :lang="this.$i18n.locale">{{ $t('sign_in.log_in') }}</div>
					<div class="user-inputs">
						<div>
							<label for="email" :lang="this.$i18n.locale">{{ $t('sign_in.email_label') }}</label>
							<input type="text" id="email" :class="{ error: errors.invalidEmailFormat }" :lang="this.$i18n.locale" v-model="emailAddress" @input="resetFormErrors" />
						</div>
						<div>
							<label for="password" :lang="this.$i18n.locale">{{ $t('sign_in.password_label') }}</label>
							<input type="password" id="password" :class="{ error: errors.passwordLength }" minlength="8" required v-model="password" @input="resetFormErrors" />
						</div>
						<div class="reset-pass-container">
							<div
								class="text-link"
								v-on:click="
									() => {
										changeActiveView(Views.FORGOT_PASSWORD)
									}
								"
								:lang="this.$i18n.locale"
							>
								{{ $t('sign_in.reset_password') }}
							</div>
						</div>
					</div>
					<div :lang="this.$i18n.locale" :class="[errors.passwordLength | errors.invalidEmailFormat ? 'error' : '']" class="text-error">
						<div v-for="errorMessage in errors.passwordMessage.concat(errors.emailMessage)" :key="errorMessage">{{ $t(errorMessage) }}</div>
					</div>

					<div class="button-container">
						<TextButton :button-label="$t('sign_in.button_log_in')" :button-style="'blue'" @on-click-fn="logIn" :optional-param="{ view: Views.SIGN_UP }" :disabled="attemptLogIn" :loading="attemptLogIn"></TextButton>
						<TextButton :button-label="$t('sign_in.button_create_account')" :button-style="'blue'" @on-click-fn="changeView" :optional-param="{ view: Views.CREATE_ACCOUNT }" :disabled="attemptLogIn" :loading="attemptLogIn"></TextButton>
						<TextButton :button-label="$t('sign_in.button_anon_account')" :button-style="'blue'" @on-click-fn="playWithoutAccount" :disabled="attemptLogIn"></TextButton>
						<div v-if="!isChrome" class="chrome-message" :lang="this.$i18n.locale">{{ $t('error.use_chrome') }}</div>
					</div>
				</div>
				<div class="sign-in-content kongregate" v-if="externalUserAccountType === 'kongregate'">
					<div class="button-container" v-if="!externalConnectionsLoading">
						<TextButton :button-label="$t('sign_in.button_log_in_kongregate')" :button-style="'blue'" @on-click-fn="popupKongregateLogin" :optional-param="{ view: Views.SIGN_UP }" :disabled="attemptLogIn" :loading="attemptLogIn"></TextButton>
					</div>
				</div>
				<div class="sign-in-content armorgames" v-if="externalUserAccountType === 'armorgames'">
					<div class="only-text-container" v-if="externalConnectionsLoading" :lang="this.$i18n.locale">
						{{ $t('sign_in.loading_armorgames') }}
					</div>
					<div class="only-text-container" v-if="!externalConnectionsLoading && externalError" :lang="this.$i18n.locale">
						{{ $t('sign_in.error_log_in_armorgames') }}
					</div>
					<div v-else class="only-text-container" :lang="this.$i18n.locale">
						{{ $t('sign_in.log_in_armorgames') }}
					</div>
				</div>
				<div class="sign-in-content steam" v-if="externalUserAccountType === 'steam'">
					<div class="only-text-container" :lang="this.$i18n.locale">
						{{ $t('sign_in.error_log_in_steam') }}
					</div>
				</div>
			</template>
		</Panel>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextButton from '../ui-components/buttons/text-button.vue'
import Panel from '../ui-components/panel/panel.vue'
import Logo from '../ui-components/logo.vue'

import { ErrorCodes } from '../store/user-account-store'
import { Views } from '../store/ui-store'
import { showGenericInfoPromptUI } from '../store/generic-prompt'
import { GoogleAnalyticsHandler } from '../../analytics/google-analytics'
import { Audio } from '../../engine/audio'

export default {
	name: 'LandingPageView',

	components: {
		TextButton,
		Panel,
		Logo,
	},
	data() {
		return {
			Views: Views,
			emailAddress: '',
			password: '',
			showPassword: false,
			ErrorCodes: ErrorCodes,
		}
	},
	mounted() {
		if (!this.menuMusicStarted) {
			Audio.getInstance().playBgm('MUS_FLOATING_LP')
			this.updateMenuMusicStarted(true)
		}

		if (this.userType === 'registered') {
			this.changeActiveView(Views.LOGGED_IN)
		} else {
			this.changeActiveView(Views.LANDING)
		}
	},

	props: {},
	computed: {
		...mapState('ui', ['mainMenuScreen', 'isChrome', 'cookiesRejected', 'menuMusicStarted']),
		...mapState('user', ['accountFormData', 'errors', 'attemptLogIn', 'externalUserAccountType', 'externalUserAccountLoggedIn', 'externalConnectionsLoading', 'externalError']),
		...mapGetters('user', ['userType']),
		panelHeight() {
			if (this.isChrome) {
				return 655
			} else {
				return 655 + 70
			}
		},
	},
	methods: {
		...mapMutations('user', ['updateFormErrors', 'resetFormErrors', 'popupKongregateLogin']),
		...mapActions('ui', ['startGame', 'changeActiveView']),
		...mapActions('user', ['submitLogIn']),
		...mapMutations('ui', ['updateMenuMusicStarted']),
		changeView(params) {
			const { view } = params
			this.changeActiveView(view)
		},
		logIn() {
			this.resetFormErrors()

			if (this.emailAddress.length <= 0) {
				this.updateFormErrors(ErrorCodes.INVALID_EMAIL_FORMAT)
				return
			}

			if (this.password.length < 10) {
				this.updateFormErrors(ErrorCodes.INVALID_PASSWORD_LENGTH)
				return
			}

			let normalizedForm = {
				emailAddress: this.emailAddress.toLowerCase(),
				password: this.password,
			}
			this.submitLogIn(normalizedForm)
		},
		playWithoutAccount() {
			if (this.cookiesRejected) {
				showGenericInfoPromptUI(
					{
						title: 'cookies.heads_up',
						description: ['cookies.no_account_warning'],
					},
					undefined,
					() => this.changeActiveView(Views.STORY_SELECT),
				)
			} else {
				this.changeActiveView(Views.STORY_SELECT)
				GoogleAnalyticsHandler.getInstance().trackAnonAccount()
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#landing-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 692px; // main panel width
	margin: 0 auto; // center left right
	.title {
		filter: drop-shadow(4px 5px 1px black);
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 106px;
			color: @green;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 64px;
		}
	}

	.sign-in-content {
		&.kongregate {
			.button-container {
				margin-top: 20%;
			}
		}

		&.armorgames {
			.only-text-container {
				color: @cream;
				text-align: center;
				margin: 10px;

				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-variant: small-caps;
					font-size: 50px;
				}

				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
			}
		}

		&.steam {
			.only-text-container {
				color: @cream;
				text-align: center;
				margin: 10px;

				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-variant: small-caps;
					font-size: 50px;
				}

				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
			}
		}

		width: 670px;
		.header {
			color: @cream;
			text-align: center;
			margin-bottom: 10px;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 50px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
		.user-inputs {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			label {
				display: block;
				margin: 10px 10px 5px 0px;
				color: @offWhite;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 30px;
					font-variant: small-caps;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
					font-size: 24px;
				}
			}
			input {
				outline: none;
				height: 35px;
				border: none;
				width: 400px;
				background-color: #040e26 !important;
				font-size: 18px;
				color: @darkGreen;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 24px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
					font-size: 24px;
				}
				&.error {
					border: 2px solid red;
					border-radius: 2px;
					filter: drop-shadow(0px 0px 2px red);
				}
			}
			.reset-pass-container {
				display: flex;
				margin-right: auto;
				margin-left: 130px;
				.text-link {
					padding: 4px;
					margin-top: 15px;
					color: @green;
					text-decoration: underline;
					font-size: 20px;
					&[lang='en'] {
						.regularFont(@enSecondaryFontPT);
						font-variant: small-caps;
					}

					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
						font-size: 16px;
					}

					&:hover {
						color: @offWhite;
					}
				}
			}
		}
		.text-error {
			width: 410px;
			color: red;
			min-height: 26px;
			opacity: 0;
			transition: 500ms;
			display: flex;
			margin-left: 130px;
			margin-right: auto;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
				font-size: 20px;
				font-variant: small-caps;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 24px;
			}
			&.error {
				opacity: 1;
			}
		}

		.button-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 25px;
			.text-button {
				margin: 5px;
				width: 450px;
			}
			.chrome-message {
				text-align: center;
				margin-top: 10px;
				color: @failureRed;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-size: 35px;
					font-variant: small-caps;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
					font-size: 35px;
				}
			}
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #040e26 inset !important;
	-webkit-text-fill-color: @darkGreen;
}
</style>
