import { Container, Sprite, Text } from "pixi.js"
import { Vector } from "sat"
import { GameState, getNID } from "../engine/game-state"
import { InstancedSprite } from "../engine/graphics/instanced-sprite"
import { Renderer } from "../engine/graphics/renderer"
import { PauseManager } from "../engine/pause-manager"
import { EntityType, IEntity } from "../entities/entity-interfaces"
import { ShrineGameplayEventPOI } from "../events/shrine-gameplay-event"
import { distanceSquaredVV } from "../utils/math"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { ObjectPool, PoolableObject } from "../utils/third-party/object-pool"
import { InGameTime } from "../utils/time"
import { AssetManager } from "../web/asset-manager"
import { GameClient } from "../engine/game-client"

const BRICK_BORDER_ONE_BRICK_LENGTH = 120
const CHAIN_BORDER_ONE_CHAIN_LENGTH = 150 / 2

const CHAIN_BORDER_SCALE = 0.5

export interface POIParams {
	xPosition: number
	yPosition: number
	radius: number
	timeLimit?: timeInSeconds
	state?: any
	lockPlayerInZone?: boolean
	onPlayerEnteredFn?: (poi: any, state: any) => void
	onPlayerExitedFn?: (poi: any, state: any) => void
	whilePlayerInZoneFn?: (poi: any, delta: timeInSeconds, state: any) => void
	onEventDone?: (poi: any, state: any) => void
}

export type BorderSpriteData = {
	normalSprite: InstancedSprite
	normalPool: ObjectPool
	glowSprite: InstancedSprite
	glowPool: ObjectPool
}

export abstract class POI implements IEntity, PoolableObject, ShrineGameplayEventPOI {
    nid: number
    entityType: EntityType = EntityType.POI
	timeScale: number = 1

    position: Vector

    isActive: boolean
    poiEnding: boolean
    isPlayerInZone: boolean
	playerWon: boolean

    state: any

    radius: number
    radiusSquared: number

    winDelayTime: timeInMilliseconds
	timeLimit?: timeInSeconds
	timeStarted: timeInSeconds
	timeEnding: timeInSeconds

	lockPlayerInZone: boolean
	lockActivated: boolean

    // gfx
    showGraphics: boolean
	textContainer: Container
	bannerText: Text
	borderSprites: BorderSpriteData[]

	static stoneBrickSprites: ObjectPool
	static stoneBrick2Sprites: ObjectPool
	static glowStoneBrickSprites: ObjectPool
	static glowStoneBrick2Sprites: ObjectPool
	static chainSprites: ObjectPool
	static glowChainSprites: ObjectPool

    onPlayerEnteredFn?: (poi: any, state: any) => void
	onPlayerExitedFn?: (poi: any, state: any) => void
	whilePlayerInZoneFn?: (poi: any, delta: timeInSeconds, state: any) => void
	onEventDone?: (poi: any, state: any) => void

    constructor() {
		this.nid = getNID(this)

        this.position = new Vector()
        this.isActive = false
        this.isPlayerInZone = false
		this.borderSprites = []

		if (!POI.stoneBrickSprites) {
			const brick01 = AssetManager.getInstance().getAssetByName('shrine-brick-01').texture
			const glowBrick01 = AssetManager.getInstance().getAssetByName('shrine-glow-brick-01').texture
			const brick02 = AssetManager.getInstance().getAssetByName('shrine-brick-02').texture
			const glowBrick02 = AssetManager.getInstance().getAssetByName('shrine-glow-brick-02').texture

			POI.stoneBrickSprites = new ObjectPool(() => new InstancedSprite(brick01, 0, 0, -99_995), {}, 200, 1)
			POI.stoneBrick2Sprites = new ObjectPool(() => new InstancedSprite(brick02, 0, 0, -99_995), {}, 200, 1)
			POI.glowStoneBrickSprites = new ObjectPool(() => new InstancedSprite(glowBrick01, 0, 0, -99_995), {}, 200, 1)
			POI.glowStoneBrick2Sprites = new ObjectPool(() => new InstancedSprite(glowBrick02, 0, 0, -99_995), {}, 200, 1)

			const chain = AssetManager.getInstance().getAssetByName('shrine-chain').texture
			const glowChain = AssetManager.getInstance().getAssetByName('shrine-glow-chain').texture

			POI.chainSprites = new ObjectPool(() => new InstancedSprite(chain, 0, 0, -99_995, CHAIN_BORDER_SCALE, CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.glowChainSprites = new ObjectPool(() => new InstancedSprite(glowChain, 0, 0, -99_995, CHAIN_BORDER_SCALE, CHAIN_BORDER_SCALE), {}, 200, 1)
		}

		this.makeGraphicsContainers()
    }

    setDefaultValues(defaultValues: any, overrideValues?: POIParams) {
        if (overrideValues) {
            this.position.x = overrideValues.xPosition
			this.position.y = overrideValues.yPosition
			this.onPlayerEnteredFn = overrideValues.onPlayerEnteredFn
			this.whilePlayerInZoneFn = overrideValues.whilePlayerInZoneFn
			this.onPlayerExitedFn = overrideValues.onPlayerExitedFn
			this.onEventDone = overrideValues.onEventDone
			this.state = overrideValues.state
			this.lockPlayerInZone = Boolean(overrideValues.lockPlayerInZone)
			this.lockActivated = false

			this.isActive = true
			this.playerWon = false

			this.radius = overrideValues.radius
			this.radiusSquared = this.radius * this.radius

			this.timeLimit = overrideValues.timeLimit
			this.timeStarted = InGameTime.timeElapsedInSeconds
			if (overrideValues.timeLimit) {
				this.timeEnding = this.timeStarted + overrideValues.timeLimit
			}

            if (this.showGraphics) {
				this.makeBorderGraphics()

                this.textContainer.position.x = overrideValues.xPosition
                this.textContainer.position.y = overrideValues.yPosition
    			
                Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.textContainer)
            }

			GameState.addEntity(this)
        }
    }

    cleanup() {
		this.isActive = false
		this.state = null
		this.poiEnding = false

        if (this.showGraphics) {
            Renderer.getInstance().mgRenderer.removeFromScene(this.textContainer)

			this.freeBorderGraphics()
        }

		this.isPlayerInZone = false

		GameState.removeEntity(this)
    }
    
    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		const playerPos = GameState.player.position
		const xDiff = playerPos.x - this.position.x
		const yDiff = playerPos.y - this.position.y
		const distSquared = xDiff ** 2 + yDiff ** 2

		let isInZone = distSquared <= this.radiusSquared
        if (!isInZone && this.lockActivated) {
			isInZone = true

			const dist = Math.sqrt(distSquared)
			const xNorm = xDiff / dist
			const yNorm = yDiff / dist

			const xScaled = xNorm * this.radius
			const yScaled = yNorm * this.radius

			playerPos.x = xScaled + this.position.x
			playerPos.y = yScaled + this.position.y
		}
		
        if (this.isActive && isInZone !== this.isPlayerInZone) {
			if (isInZone) {
				if (this.lockPlayerInZone) {
					this.lockActivated = true
				}

				if (this.onPlayerEnteredFn) {
					this.onPlayerEnteredFn(this, this.state)
				}
			} else if (!isInZone && this.onPlayerExitedFn) {
				this.onPlayerExitedFn(this, this.state)
			}

			this.isPlayerInZone = isInZone

			if (this.showGraphics) {
				this.playerInZoneChangeGlowBorderChange()
			}
		}
		
        if (this.isPlayerInZone) {
            if (this.isActive && this.whilePlayerInZoneFn) {
				this.whilePlayerInZoneFn(this, delta, this.state)
			}
        }
		
		if (!this.poiEnding) {
			if (this.timeLimit && InGameTime.timeElapsedInSeconds >= this.timeEnding && !this.isPlayerInZone) {
				this.onComplete(false)
			}
		} else {
			if (now >= this.winDelayTime && !PauseManager.isPaused()) {
				this.freeFromPool()
			}
		}
    }

    onComplete(victory: boolean) {
		this.playerWon = victory
		this.isActive = false
		this.poiEnding = true

		if (this.isPlayerInZone && this.onPlayerExitedFn) {
			this.onPlayerExitedFn(this, this.state)
		}

		if (this.onEventDone) {
			this.onEventDone(this, this.state)
		}

		this.winDelayTime = InGameTime.highResolutionTimestamp() + 2_000
	}

    setBannerText(text: string) {
        this.bannerText.text = text
		this.bannerText.x = -this.bannerText.width / 2
    }

    makeGraphicsContainers() {
		this.textContainer = new Container()
		this.textContainer['update'] = () => {}

		this.bannerText = new Text('', {
			fontSize: 34,
			fontWeight: 900,
			letterSpacing: 1,
			align: 'center',
			fill: 'white',
			dropShadow: true,
			dropShadowAngle: 2,
			dropShadowColor: '#2a313e',
			dropShadowDistance: 4,
			lineJoin: 'bevel',
			miterLimit: 5,
			padding: 8,
			stroke: '#2a313e',
			strokeThickness: 2,
		})
		this.bannerText.y -= 200

		this.textContainer.addChild(this.bannerText)
		this.textContainer.zIndex = 999_999
	}

	makeBorderGraphics() {
		const circumference = Math.PI * 2 * this.radius
		const useChains = this.lockPlayerInZone
		const numTiles = Math.floor(circumference / (useChains ? CHAIN_BORDER_ONE_CHAIN_LENGTH : BRICK_BORDER_ONE_BRICK_LENGTH))
		const radStep = (Math.PI * 2) / numTiles
		const renderer = Renderer.getInstance().bgRenderer

		for (let i = 0; i < numTiles; ++i) {
			const rotation = i * radStep
			const xPos = Math.sin(rotation) * this.radius + this.position.x
			const yPos = Math.cos(rotation) * this.radius + this.position.y

			let normalPool: ObjectPool
			let glowPool: ObjectPool

			if (useChains) {
				normalPool = POI.chainSprites
				glowPool = POI.glowChainSprites
			} else {
				if (Math.random() < 0.5) {
					normalPool = POI.stoneBrickSprites
					glowPool = POI.glowStoneBrickSprites
				} else {
					normalPool = POI.stoneBrick2Sprites
					glowPool = POI.glowStoneBrick2Sprites
				}
			}

			const sprite = normalPool.alloc() as InstancedSprite
			sprite.x = xPos
			sprite.y = yPos
			sprite.rot = -rotation

			const glowSprite = glowPool.alloc() as InstancedSprite
			glowSprite.x = xPos
			glowSprite.y = yPos
			glowSprite.rot = -rotation

			renderer.addPropToScene(sprite)

			this.borderSprites.push({
				normalSprite: sprite,
				normalPool,
				glowSprite,
				glowPool
			})
		}
	}

	freeBorderGraphics() {
		const renderer = Renderer.getInstance().bgRenderer
		
		for (let i = 0; i < this.borderSprites.length; ++i) {
			const data = this.borderSprites[i]
			if (this.isPlayerInZone) {
				renderer.removeFromScene(data.glowSprite)
			} else {
				renderer.removeFromScene(data.normalSprite)
			}
			
			data.glowPool.free(data.glowSprite as any)
			data.normalPool.free(data.normalSprite as any)
		}

		this.borderSprites.length = 0
	}

	// names are hard
	private playerInZoneChangeGlowBorderChange() {
		const renderer = Renderer.getInstance().bgRenderer
		for (let i = 0; i < this.borderSprites.length; ++i) {
			const data = this.borderSprites[i]
			if (this.isPlayerInZone) {
				renderer.removeFromScene(data.normalSprite)
				renderer.addPropToScene(data.glowSprite)
			} else {
				renderer.removeFromScene(data.glowSprite)
				renderer.addPropToScene(data.normalSprite)
			}
		}
	}

    abstract freeFromPool(): void
}