import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyType, FightingBehaviours, ShotLeadPrecision, VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const bruteShambler: VariantAI = {
    extends: ENEMY_NAME.SHAMBLING_MOUND,
    name: ENEMY_NAME.BRUTE_SHAMBLER,
	type: EnemyType.BOSS,
    appearance: {
        asset: SpineDataName.SHAMBLING_MOUND_5,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.25,
    },
    maxHealth: 40,
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.85,
	knockbackResist: 25
}

export const bruteShrieker: VariantAI = {
    extends: ENEMY_NAME.SHRIEKER,
    name: ENEMY_NAME.BRUTE_SHRIEKER,
	type: EnemyType.BOSS,
    appearance: {
        asset: SpineDataName.SHRIEKER_KNIGHT,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 1.25,
    },
    maxHealth: 35,
    knockbackResist: 0,
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED
}

export const bruteEyeBat: VariantAI = {
    extends: ENEMY_NAME.EYEBAT_1,
    name: ENEMY_NAME.BRUTE_EYE_BAT,
	type: EnemyType.BOSS,
    appearance: {
        asset: SpineDataName.EYEBAT_1,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
		scale: 2.0,
    },
    states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.PROJECTILE,
				particleEffectType: ParticleEffectType.PROJECTILE_ENEMY0,
				cooldownDef: {
					maxAmmo: 1,
					cooldownInterval: 1_250,
					reloadInterval: 0,
					numAmmoPerIncrement: 1,
				},
			},
			engagementMaxDistance: 750,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 800,
			movementMinDistance: 500,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
    maxHealth: 40,
	knockbackResist: 7.5,
	movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.85,
	layer: CollisionLayerBits.FlyingEnemy
}
