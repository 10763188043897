import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"

const mushie: EnemyAI = {
	name: ENEMY_NAME.MUSHIE_1,
	type: EnemyType.BASIC,
	objectPoolInitialSize: 300,
	objectPoolGrowthSize: 8,
	appearance: {
		asset: SpineDataName.MUSHIE_1,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 30,
				rY: 75,
				position: [0, 0],
			},
		],
		colliderLayer: CollisionLayerBits.FlyingEnemy,
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.0, // this is a charger, so also tune chargeLerpSpeed values below
			maxHealth: 0.5,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 360,
		attackOffset: new Vector(0, -70),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		immuneToRecycle: true,
		avoidanceRadius: 50,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHARGE_ATTACK,
				params: {
					chargeLerpSpeed: [
						{ targetSpeedMult: 1.49, maxTime: 1 }
					],
					holdDirectionTime: 1,
					holdDirectionDistance: 3000,
					aimTime: 0
				}
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 1300,
			engagementMinDistance: 0,
			modelCenterOffset: 60,
			movementMaxDistance: 3000,
			movementMinDistance: 80,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default mushie
