import { isFunction } from "lodash"
import { timeInMilliseconds } from "./primitive-types"

export function cloneDeepSerializable(obj: any): object {
	if (Object(obj) !== obj) {
		return obj
	} else if (Array.isArray(obj)) {
		return obj.map(cloneDeepSerializable)
	}

	let newObj = {}

	Object.entries(obj).forEach(
        ([k,v]) => {
			let clonedValue
			if (isFunction(v)) {
				clonedValue = v.bind(newObj)
			} else {
				clonedValue = cloneDeepSerializable(v)
			}

			newObj[k] = clonedValue
		}
    )

	return newObj
}

export function getPropWithFallback(obj: Object, key: any, fallback: any) {
	if (obj[key] === undefined) {
		obj[key] = fallback
	}
	return obj[key]
}

/**
 * Wait until a value inside the valueContainer is defined, then resolve the promise.
 * @param valueContainer Since we pass an undefined variable, we need a wrapping object for referential integrity
 * @param pollRate Time to wait between checking if the value is deifned
 * @param maximumPollTime Maximum time to wait before rejecting the promise
 * @returns 
 */
export async function waitUntilDefined(valueContainer: { value: any }, pollRate: timeInMilliseconds, maximumPollTime: timeInMilliseconds) {
	let pollTimeAcc: timeInMilliseconds = 0
	return new Promise((resolve, reject) => {
		const int = setInterval(() => {
			pollTimeAcc += pollRate
			if (pollTimeAcc >= maximumPollTime) {
				clearInterval(int)
				reject(undefined)
				return
			}
			if (valueContainer.value !== undefined) {
				clearInterval(int)
				resolve(valueContainer.value)
				return
			}
		}, pollRate)
	})
}
