import EntityStatList from "../../../stats/entity-stat-list"
import { PrimaryWeapon } from "../../primary-weapon"
import { WEAPON_STATS } from '../../weapon-definitions'
import { AllWeaponTypes } from "../../weapon-types"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { NoxiousLeakHazard } from "../../../entities/hazards/noxious-leak-hazard"
import { ObjectPoolTyped } from "../../../utils/third-party/object-pool"
import { DamagingGroundHazardParams } from "../../../entities/hazards/damaging-ground-hazard"
import { LightningStrike, LightningStrikeParams } from "../../../entities/lightning-strike"

export class Wand extends PrimaryWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.Wand

    projectileEffectType: ParticleEffectType = ParticleEffectType.PROJECTILE_WAND
    projectileTrailType: ParticleEffectType = ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL

	init(player, playerStatList: EntityStatList) {
		if(!NoxiousLeakHazard.pool) {
			NoxiousLeakHazard.pool = new ObjectPoolTyped<NoxiousLeakHazard, DamagingGroundHazardParams>(() => new NoxiousLeakHazard(), {}, 2, 1)
		}
		if(!LightningStrike.pool) {
			LightningStrike.pool = new ObjectPoolTyped<LightningStrike, LightningStrikeParams>(() => new LightningStrike(), {}, 4, 1)
		}
	}

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
        for (const stat of Object.keys(WEAPON_STATS.wand.stats)) {
            statList._actualStatValues[stat] = WEAPON_STATS.wand.stats[stat]
        }

        statList._actualStatValues.projectileChainDistanceMultiplier = 1
        statList._actualStatValues.projectileSplitCount = 0 // the definition doesn't exist in this branch and I don't want conflict shenanigans
        
    }

    update(delta: number) {

    }
}
