<template>
	<div id="you-died">
		<div>You Died</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: '',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#you-died {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;

	animation: fadeToBlack linear 4s;

	background-color: rgba(0, 0, 0, 0.666);

	div {
		position: absolute;
		left: 0;
		top: 20%;
		width: 100vw;

		text-align: center;

		color: @red;
		font-variant: small-caps;
		font-size: 120px;

		.regularFont(@enRegularFontPixl);
	}
}

@keyframes fadeToBlack {
	0% {
		background-color: rgba(0, 0, 0, 0);
	}
	100% {
		background-color: rgba(0, 0, 0, 0.666);
	}
}
</style>
