import { AnimationTrack } from '../../spine-config/animation-track'
import { RiggedSpineModel } from './spine-model'

function configureAnimationTracks(model: RiggedSpineModel, mixSettings: MixSettings = null) {
	const animState = model.stateData
	if (!mixSettings) {
		mixSettings = DEFAULT_MIX_SETTINGS
	}
	mixSettings.forEach((mix) => {
		animState.setMix(mix.from, mix.to, mix.duration)
	})
}

export const DEFAULT_MIX_SETTINGS: MixSettings = [
	{ from: AnimationTrack.IDLE, to: AnimationTrack.MOVEMENT, duration: 0.5 },
	{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE, duration: 0.5 },
	{ from: AnimationTrack.IDLE, to: AnimationTrack.SHOOT, duration: 0.1 },
	{ from: AnimationTrack.SHOOT, to: AnimationTrack.MOVEMENT, duration: 0.1 },
]

export const PLAYER_MIX_SETTINGS: MixSettings = [
	{ from: AnimationTrack.IDLE, to: AnimationTrack.MOVEMENT, duration: 0.5 },
	// { from: AnimationTrack.IDLE, to: AnimationTrack.MOVEMENT_NO_WEAPON, duration: 0.5 },
	// { from: AnimationTrack.IDLE_NO_WEAPON, to: AnimationTrack.MOVEMENT, duration: 0.5 },
	// { from: AnimationTrack.IDLE_NO_WEAPON, to: AnimationTrack.MOVEMENT_NO_WEAPON, duration: 0.5 },

	{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE, duration: 0.5 },
	// { from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE_NO_WEAPON, duration: 0.5 },
	// { from: AnimationTrack.MOVEMENT_NO_WEAPON, to: AnimationTrack.IDLE, duration: 0.5 },
	// { from: AnimationTrack.MOVEMENT_NO_WEAPON, to: AnimationTrack.IDLE_NO_WEAPON, duration: 0.5 },

	{ from: AnimationTrack.IDLE, to: AnimationTrack.SHOOT_FAST_RECOIL, duration: 0.0 },
	{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.SHOOT_FAST_RECOIL, duration: 0.0 },
	{ from: AnimationTrack.SHOOT_FAST_RECOIL, to: AnimationTrack.SHOOT_FAST_RETURN, duration: 0.0 },
	{ from: AnimationTrack.SHOOT_FAST_RETURN, to: AnimationTrack.IDLE, duration: 0.0 },
	{ from: AnimationTrack.SHOOT_FAST_RETURN, to: AnimationTrack.MOVEMENT, duration: 0.0 },

	{ from: AnimationTrack.IDLE, to: AnimationTrack.SHOOT_SLOW_RECOIL, duration: 0.0 },
	{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.SHOOT_SLOW_RECOIL, duration: 0.0 },
	{ from: AnimationTrack.SHOOT_SLOW_RECOIL, to: AnimationTrack.SHOOT_SLOW_RETURN, duration: 0.0 },
	{ from: AnimationTrack.SHOOT_SLOW_RETURN, to: AnimationTrack.IDLE, duration: 0.0 },
	{ from: AnimationTrack.SHOOT_SLOW_RETURN, to: AnimationTrack.MOVEMENT, duration: 0.0 },
]

export const PLAYER_BOW_MIX_SETTINGS: MixSettings = [
	{ from: AnimationTrack.IDLE, to: AnimationTrack.MOVEMENT, duration: 0.5 },
	// { from: AnimationTrack.IDLE, to: AnimationTrack.IDLE_BOW_DRAW_BACK, duration: 0.5 },
	// { from: AnimationTrack.IDLE_NO_WEAPON, to: AnimationTrack.MOVEMENT, duration: 0.5 },
	// { from: AnimationTrack.IDLE_NO_WEAPON, to: AnimationTrack.MOVEMENT_NO_WEAPON, duration: 0.5 },

	{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE, duration: 0.5 },
	// { from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE_BOW_DRAW_BACK, duration: 0.5 },
	// { from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE_NO_WEAPON, duration: 0.5 },
	// { from: AnimationTrack.MOVEMENT_NO_WEAPON, to: AnimationTrack.IDLE, duration: 0.5 },
	// { from: AnimationTrack.MOVEMENT_NO_WEAPON, to: AnimationTrack.IDLE_NO_WEAPON, duration: 0.5 },
]

export const PLAYER_DOG_MIX_SETTINGS: MixSettings = [
	{ from: AnimationTrack.IDLE, to: AnimationTrack.MOVEMENT, duration: 0.5 },
	{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE, duration: 0.5 },
]

export const WORM_MAIL_MIX_SETTINGS: MixSettings = [
	{ from: AnimationTrack.IDLE, to: AnimationTrack.HIDE_WORM, duration: 0.5 },
	{ from: AnimationTrack.WORMMAIL_IDLE, to: AnimationTrack.SHOW_WORM, duration: 0.5 },
	{ from: AnimationTrack.SHOW_WORM, to: AnimationTrack.WORMMAIL_IDLE, duration: 0.25 },
]

export type MixSettings = Array<{
	from: AnimationTrack
	to: AnimationTrack
	duration: number
}>

export default configureAnimationTracks
