import { Vector } from "sat"
import { ObjectPool, PoolableObject } from "../../utils/third-party/object-pool"
import { PET_OFFSET, Pet, PetCollectionName } from "./pet"
import { createNewPet } from "./pet-util"
import { ComponentOwner } from "../../engine/component-owner"
import { GameState, getNID } from "../../engine/game-state"
import { EntityType, IEntity } from "../entity-interfaces"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { distanceSquaredVV, getRandomPointInRectRange } from "../../utils/math"
import { IAttachee } from "../../utils/attachments-system"
import { PetRescueEventSystem } from "../../events/pet-rescue-gameplay-event"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { AssetManager } from "../../web/asset-manager"
import { Renderer } from "../../engine/graphics/renderer"
import { callbacks_addCallback } from "../../utils/callback-system"

export interface WildPetParams {
	spawnX: number,
	spawnY: number,
	movementBehavior: WildPetMovementBehaviour // todo make type
	movementSpeed: number
	maxWanderTime: timeInSeconds
	maxDistanceFromPlayer: timeInSeconds
}

export type WildPetMovementBehaviour = 'wanderAttack' | 'wander'
type MovementStates = 'wandering' | 'aiming'

const ATTACK_COOLDOWN = 3

const AIM_TIME_IN_SECONDS = 1

const MIN_WANDER_OFFSET =  200
const MAX_WANDER_OFFSET = 400

export class WildPet implements PoolableObject, ComponentOwner, IEntity, IAttachee{
	entityType: EntityType = EntityType.PetWrapper
	nid: number

	pet: Pet
	name: PetCollectionName

	position: Vector
	
	get x () {
		return this.position.x
	}

	get y () {
		return this.position.y
	}

	get zIndex () {
		return this.pet.position.y
	}

	movementSpeed: number
	timeScale: number = 1

	targetPos: Vector
	targetDirection: Vector
	distanceFromPlayer2: number

	maxWanderTime: timeInSeconds
	maxDistanceFromPlayer2: timeInSeconds

	movementBehaviour: WildPetMovementBehaviour
	currentState: MovementStates
	timeInState: timeInSeconds
	lastAttackTime: timeInSeconds

	smokeEffectConfig: EffectConfig

	// Pool gets set outwardly by the event that this pet belongs to
	pool: ObjectPool

	constructor(petName: PetCollectionName) {
		this.nid = getNID(this)
		this.position = new Vector()
		this.targetDirection = new Vector()
		this.targetPos = new Vector()
		this.timeInState =  0
		this.lastAttackTime = 0

		this.movementBehaviour = 'wander'

		this.name = petName
		this.setNewPet(petName)

		this.smokeEffectConfig = AssetManager.getInstance().getAssetByName('enemy-death-explosion').data
	}

	setDefaultValues(defaultValues: any, overrideValues?: WildPetParams) {
		if (overrideValues) {
			this.position.x = overrideValues.spawnX
			this.position.y = overrideValues.spawnY
			this.movementBehaviour =  overrideValues.movementBehavior
			this.movementSpeed = overrideValues.movementSpeed

			this.maxWanderTime = overrideValues.maxWanderTime
			this.maxDistanceFromPlayer2 = overrideValues.maxDistanceFromPlayer ** 2

			this.currentState = 'aiming'
			this.timeInState = 0
			this.lastAttackTime = 0
			this.setTargetDirection()

			this.pet.blockAbility = true
			this.pet.position.copy(this.position)
			this.pet.addToScene()
			this.pet.showCooldownBar = false

			GameState.addEntity(this)
		}
	}

	private setNewPet(petName: PetCollectionName) {
		this.pet = createNewPet(petName, new Vector())
		this.pet.releaseFromCage(true, true)
		this.pet.removeFromScene()
		this.pet.removeCooldownGfx()

		this.pet.isWildPet = true
		this.pet.followTarget = this
		this.pet.blockAbility = true
		this.pet.targetDistance = 0
		this.pet.dampedLerp = false
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds) {
		this.timeInState += delta
		const playerPos = GameState.player.position
		this.distanceFromPlayer2 = distanceSquaredVV(this.position, playerPos)
		//petMovementBehaviour[this.movementBehaviour](this, delta)
		this.wanderAttack()

		this.position.x = this.position.x + this.targetDirection.x * delta
		this.position.y = this.position.y + this.targetDirection.y * delta
	}

	joinPlayerPets() {		
		// Pass ownership from this wrapper to the Pet Event system (following the player)
		PetRescueEventSystem.getInstance().tameWildPet(this)
		
		// Create a new pet instance for this wrapper object so it may be reused
		this.setNewPet(this.name)
	}

	useAttack() {
		this.pet.useAbility()
		this.lastAttackTime = this.timeInState
	}

	setTargetDirection() {
		const playerPos = GameState.player.position
		// Pick a new movement target around player
		getRandomPointInRectRange(playerPos.x, playerPos.y, MIN_WANDER_OFFSET, MAX_WANDER_OFFSET, MIN_WANDER_OFFSET, MAX_WANDER_OFFSET, this.targetPos)
		this.targetDirection.x = this.targetPos.x - this.position.x
		this.targetDirection.y = this.targetPos.y - this.position.y
		this.targetDirection.normalize()
		this.targetDirection.scale(this.movementSpeed)
	}

	wanderAttack(): void {
		if (this.currentState === 'wandering') {
			const continueWandering = this.timeInState < this.maxWanderTime && this.distanceFromPlayer2 < this.maxDistanceFromPlayer2
			if (!continueWandering) {
				this.currentState =  'aiming'
				this.timeInState = 0
			} else if(this.movementBehaviour === 'wanderAttack' && this.timeInState - this.lastAttackTime >= ATTACK_COOLDOWN) {
				this.useAttack()
			}

		} else if (this.currentState === 'aiming') {
			if (this.timeInState >= AIM_TIME_IN_SECONDS) {
				this.setTargetDirection()
				
				this.currentState = 'wandering'
				this.timeInState = 0
			}
		}
	}

	despawn(playPFX?: boolean) {
		if (playPFX) {
			Renderer.getInstance().addOneOffEffectByConfig(this.smokeEffectConfig, this.position.x, this.position.y, this.position.y + 200)
			callbacks_addCallback(this, () => this.pool.free(this), 0.1)
		} else {
			this.pool.free(this)
		}
	}

	cleanup() {
		GameState.removeEntity(this)
		this.pet.removeFromScene()
	}
}
