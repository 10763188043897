import { Audio } from '../engine/audio'
import { loadFromLocalStorage, loadFromSessionStorage } from '../utils/local-storage'
import ClientPlayerInput, { InputAction } from '../engine/client-player-input'
import { UI } from '../ui/ui'
import { GameState } from '../engine/game-state'

const webPortalDefaults = {
    sfxVolume: 100,
    bgmVolume: 100,
    selectedIcon: "bees",
    selectedColor: "orange",
    autoAim: false,
    autoShoot: false
}

interface SettingsInterface {
    sfxVolume: number
    bgmVolume: number
    selectedIcon: string
    selectedColor: string
    autoAim: boolean
    autoShoot: boolean
    width?: number,
    height?: number,
    windowMode?: string,
    keyboardMappings?: {
        [key in InputAction]?: string;
    }
}

export class LocalSettings {
    static settings: SettingsInterface = {} as any

    static init() {
        if (process.env.IS_ELECTRON) {
            const electronSettings = UI.getInstance().store.state.settings.currentSettings
            console.log('electron settings')
            console.log(JSON.stringify(electronSettings))
            for (const key of Object.keys(electronSettings)) {
                this.change(key, electronSettings[key])
            }
        } else {
            for (const key of Object.keys(webPortalDefaults)) {
                const storageKey = `settings.${key}`
                let val: any = loadFromLocalStorage(storageKey)
                if (val === undefined || val === null) {
                    val = loadFromSessionStorage(storageKey)
                }

                if (val === null || val === undefined) {
                    this.change(key, webPortalDefaults[key])
                } else {
                    switch (key) {
                        case 'sfxVolume':
                            val = parseFloat(val)
                            break
                        case 'bgmVolume':
                            val = parseFloat(val)
                            break
                        case 'autoAim':
                            val = val === 'true'
                            break
                        case 'autoShoot':
                            val = val === 'true'
                            break
                    }
                    this.change(key, val)
                }
            }
        }

    }

    static change(key: string, val: any) {
        switch (key) {
            case 'sfxVolume':
                Audio.getInstance().setMasterSFXVolume(val / 100)
                break
            case 'bgmVolume':
                Audio.getInstance().setMasterBGMVolume(val / 100)
                break
            case 'selectedIcon':
                // Nothing to do here...
                break
            case 'selectedColor':
                // Nothing to do here...
                break
            case 'autoAim':
                if (GameState.player) {
                    GameState.player.autoAimEnabled = val
                }
                break
            case 'autoShoot':
                if (GameState.player) {
                    GameState.player.autoShootEnabled = val
                }
                break
            case 'keyboardMappings':
                if (ClientPlayerInput.hasInstance()) {
                    ClientPlayerInput.getInstance().updateActionToControlMap(val)
                }
                break
            default:
                console.warn(`Trying to set invalid key ${key}`)
                return
        }
    }

    static applySettings(values: SettingsInterface) {
        for (const [key, value] of Object.entries(values)) {
            this.change(key, value)
        }
    }
}