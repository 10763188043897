import AISystem from "../entities/enemies/ai-system"
import { Enemy } from "../entities/enemies/enemy"
import { ENEMY_NAME } from "../entities/enemies/enemy-names"
import { EntityType } from "../entities/entity-interfaces"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { POI, POIParams } from "./poi"

export interface KillSpawnedEnemiesPOIParams extends POIParams {
	enemyNames: ENEMY_NAME[]
	enemySpawnRadius?: number
	keepSpawnedEnemiesOnFailure?: boolean
}

export class KillSpawnedEnemiesPOI extends POI {
	static pool: ObjectPoolTyped<KillSpawnedEnemiesPOI, KillSpawnedEnemiesPOIParams>
	
    nid: number
    entityType: EntityType = EntityType.POI

	private killedCount: number
	private toKillCount: number

	private keepSpawnedEnemiesOnFailure: boolean
	private spawnedEnemies: Enemy[]

    constructor() {
		super()

		this.showGraphics = false
		this.spawnedEnemies = []
	}

    override setDefaultValues(defaultValues: any, overrideValues?: KillSpawnedEnemiesPOIParams) {
		super.setDefaultValues(defaultValues, overrideValues)
		if (overrideValues) {
			this.killedCount = 0
			this.toKillCount = overrideValues.enemyNames.length

			const spawnRadius = overrideValues.enemySpawnRadius ?? 0
			let rotDelta = (Math.PI * 2) / overrideValues.enemyNames.length

			for (let i = 0; i < overrideValues.enemyNames.length; ++i) {
				let xPos: number
				let yPos: number
				if (spawnRadius > 0) {
					const rot = rotDelta * i
					const circleX = Math.sin(rot)
					const circleY = Math.cos(rot)
					xPos = circleX * spawnRadius + this.position.x
					yPos = circleY * spawnRadius + this.position.y
				} else {
					xPos = this.position.x
					yPos = this.position.y
				}

				const enemy = AISystem.getInstance().spawnEnemyAtPos(overrideValues.enemyNames[i], xPos, yPos)
				enemy.immuneToRecycle = true

				enemy.onKilled = this.onSpecificEnemyKilled.bind(this)
				this.spawnedEnemies.push(enemy)
			}
		}
    }

	override onComplete(victory: boolean): void {
		super.onComplete(victory)

		if (!victory) {
			for (let i =0; i < this.spawnedEnemies.length; ++i) {
				AISystem.getInstance().removeEnemy(this.spawnedEnemies[i])
			}
		}
		
		this.spawnedEnemies.length = 0
	}

	private onSpecificEnemyKilled(enemy: Enemy) {
		this.killedCount++
		this.spawnedEnemies.remove(enemy)
		
		if (this.killedCount >= this.toKillCount) {
			this.onComplete(true)
		}
	}

	freeFromPool() {
		KillSpawnedEnemiesPOI.pool.free(this)
	}
}
