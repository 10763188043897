<template>
	<div class="item" @click="onClick" @mouseover="onMouseEnter" @mouseleave="onMouseLeave" :class="{ selectable: selectable }">
		<div class="item-container" :class="{ small: smallIcons, locked: showLockIcon, 'not-available': dimIcon }">
			<div v-if="component === 'twist'" class="book" :class="{ disabled: grayOutBooks, selected: selected, glow: enableGlow }">
				<div class="book-graphics" :class="{ small: smallIcons }">
					<div class="book-cover"></div>
					<div class="book-overlay" :style="{ backgroundColor: `#${hexColor}` }"></div>
					<div class="book-accent"></div>
				</div>
			</div>

			<div v-else class="background" :class="{ 'final-tier': showFinal, 'legendary-tier': showLegendary }"></div>
			<div class="circle" :class="{ selected: selected || hovered, disable: disableCircle, small: smallIcons }"></div>
			<div class="item-icon icon" :style="iconStyleFromItem()" :class="[{ locked: showLockIcon, 'not-available': dimIcon, small: smallIcons, twist: component === 'twist' }]"></div>
			<div class="lock-icon" :class="{ active: showLockIcon, small: smallIcons }"></div>
		</div>
	</div>
</template>

<script  lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Description from '../../ui-components/text/description.vue'
import { SpriteSheets, getIconFromSpriteSheet } from '../../store/ui-store'

const COMPONENT_TYPES = ['twist', 'upgrade']

export default {
	name: 'ItemUpdated',
	components: {
		Description,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		selected: {
			type: Boolean,
			required: false,
			default: false,
		},
		hovered: {
			type: Boolean,
			required: false,
			default: false,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onClickProp: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-click handler for the <Item /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		onHoverFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-hover handler for the <Item /> component has been called. Did you forget to pass an :on-hover-fn handler to the component?')
			},
		},
		tooltipEnabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		disableCircle: {
			type: Boolean,
			required: false,
			default: true,
		},
		showLegendary: {
			type: Boolean,
			required: false,
		},
		showFinal: {
			type: Boolean,
			required: false,
		},
		showLockIcon: {
			type: Boolean,
			required: false,
			default: false,
		},
		dimIcon: {
			type: Boolean,
			required: false,
			default: false,
		},
		smallIcons: {
			type: Boolean,
			required: false,
			default: false,
		},
		enableGlow: {
			type: Boolean,
			required: false,
			default: false,
		},
		smallBooks: {
			type: Boolean,
			required: false,
			default: false,
		},
		grayOutBooks: {
			type: Boolean,
			required: false,
			default: false,
		},
		component: {
			type: String,
			required: false,
			default: 'book',
			validator: function (value: string) {
				return COMPONENT_TYPES.includes(value)
			},
		},
	},
	data() {
		return {}
	},
	computed: {},
	methods: {
		...mapMutations('tooltip', ['showTooltip', 'currentComponentHovered', 'updateTooltipPosition']),
		iconStyleFromItem() {
			if (this.item) {
				const style = getIconFromSpriteSheet(SpriteSheets.UPGRADE_ICONS, this.item.icon)
				return style
			}
		},
		onClick() {
			this.onClickProp(this.item)
		},
		onMouseEnter(event) {
			if (this.tooltipEnabled) {
				this.showTooltip({ item: this.item, component: this.component })
				this.updateTooltipPosition(event)
			}
			this.onHoverFn(this.item)
		},
		onMouseLeave() {
			if (this.tooltipEnabled) {
				this.updateTooltipPosition(event)
				this.showTooltip({ item: null, component: null })
			}
			this.onHoverFn(null)
		},
		randomColor() {
			if (!this.item) {
				return 0x000000
			}

			const itemId = this.item.twist !== undefined ? this.item.twist : this.item.id !== undefined ? this.item.id : 'unknown'
			let hash = 0
			for (let i = 0; i < itemId.length; i++) {
				hash = itemId.charCodeAt(i) + ((hash << 5) - hash)
			}
			let color = ''
			for (let i = 0; i < 3; i++) {
				let value = (hash >> (i * 8)) & 0xff
				color += ('00' + value.toString(16)).substr(-2)
			}
			return color
		},
	},
	created() {
		this.hexColor = this.randomColor()
	},
	unmounted() {
		this.showTooltip({ item: null, component: null })
		this.onHoverFn(null)
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.item {
	.item-container {
		width: 95px;
		height: 95px;
		position: relative;
		pointer-events: all;
		&:hover > .circle {
			&:not(.disabled) {
				filter: drop-shadow(0px 0px 5px #9afe8b);
				transition: all 0.3s ease;
			}
		}
		&.small {
			width: 66px;
			height: 66px;
		}
		.book {
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			&.disabled {
				filter: grayscale(1) drop-shadow(0px 0px 10px gray) !important;
			}
			&.selected {
				&:not(.disabled) {
					filter: drop-shadow(0px 0px 6px @green) !important;
					transform: scale(1.12);
				}
			}
			&.glow {
				&:not(.disabled) {
					filter: drop-shadow(0px 0px 6px @wildStrawberry);
				}
			}
			.book-graphics {
				position: absolute;
				right: 0;
				left: -7px;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 108px;
				height: 101px;
				&.small {
					width: 54px;
					height: 50px;
					.book-overlay {
						-webkit-mask-size: 54px;
					}
				}
				& > div {
					width: inherit;
					height: inherit;
				}
				.book-cover {
					background-image: url('~/assets/ui/item/book-gray.png');
					background-size: contain;
					position: absolute;
				}
				.book-overlay {
					mix-blend-mode: overlay;
					background-size: contain;
					-webkit-mask-image: url('~/assets/ui/item/book-gray.png');
					mask-image: url('~/assets/ui/item/book-gray.png');
					mask-size: cover;
					position: absolute;
				}
				.book-accent {
					background-size: contain;
					background-image: url('~/assets/ui/item/book-accent.png');
					position: absolute;
				}
			}
		}
		.background {
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			&.final-tier {
				background-image: url('../../../../assets/ui/item/upgrade-container-epic.png');
				width: 116px;
				height: 131px;
				top: 4px;
				left: -11px;
			}
			&.legendary-tier {
				background-image: url('../../../../assets/ui/item/upgrade-container-legendary.png');
				width: 134px;
				height: 158px;
				left: -19px;
				top: 3px;
			}
		}
		.circle {
			position: absolute;
			width: 87px;
			height: 87px;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			&.small {
				transform: scale(0.7);
				transform-origin: center center;
			}
			&:not(.disable) {
				background-image: url('../../../../assets/ui/item/upgrade-container-normal.png');
			}
			&.selected {
				&:not(.disable) {
					background-image: url('../../../../assets/ui/item/upgrade-container-selecteds.png');
					width: 95px;
					height: 95px;
					filter: drop-shadow(0px 0px 5px #9afe8b);
				}
			}
		}
		.item-icon {
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			&.not-available {
				opacity: 0.5;
			}
			&.locked {
				opacity: 0.2;
			}
			&.icon {
				background-image: url('~/assets/ui/item/in-game-icons.png');
				background-repeat: no-repeat;
				display: flex;
				align-self: center;
				&.twist {
					top: -16px;
				}
				&.small {
					transform: scale(0.67);
					transform-origin: right;
					&.twist {
						top: -10px;
						left: -7px;
						transform: scale(0.48) !important;
						transform-origin: center !important;
					}
				}
			}
		}
		.lock-icon {
			inset: 0;
			margin: auto;
			width: 80px;
			height: 80px;
			position: absolute;
			background-size: cover;
			opacity: 0.6;
			&.active {
				background-image: url('../../../../assets/ui/locked-invert.png');
			}
		}
	}
}
</style>
