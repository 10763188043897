import { InstancedSpriteSheetAnimator } from "../../engine/graphics/instanced-spritesheet-animator"
import { AnimationTrack, MOVEMENT_EPSILON, MOVEMENT_TRACK_END_ADJUST } from "../../spine-config/animation-track"
import { timeInSeconds } from "../../utils/primitive-types"
import { AssetManager } from "../../web/asset-manager"
import { AIStates, FightingBehaviours } from "./ai-types"
import { FightingSubstate } from "./behaviours/fighting"
import { Enemy } from "./enemy"
import { GraphicsComponent } from "../../engine/graphics/graphics-component"
import { ComponentOwner } from "../../engine/component-owner"
import { SpriteSheetAnimator } from "../../engine/graphics/spritesheet-animator"
import { Renderer } from "../../engine/graphics/renderer"
import { ENEMY_NAME } from "./enemy-names"

export class EnemyGFX extends GraphicsComponent {
	owner: ComponentOwner

	instancedSpriteSheetAnimator?: InstancedSpriteSheetAnimator
	spriteSheetAnimator?: SpriteSheetAnimator

	idleAnimation: AnimationTrack
	moveAnimation: AnimationTrack
	changeAnimation: AnimationTrack

	useIdleAnimation: boolean = false
	isRendering: boolean = false

	get activeAnimator() {
		return this.usingAnimator
	}
	
	private usingAnimator: InstancedSpriteSheetAnimator | SpriteSheetAnimator

	constructor(parent: Enemy) {
		super()
		this.owner = parent

		this.idleAnimation = AnimationTrack.IDLE
		this.moveAnimation = AnimationTrack.MOVEMENT
		this.changeAnimation = AnimationTrack.IDLE

		this.makeAnimatedSprite()
	}

	getAssetName(): string {
		const parent = this.owner as Enemy
		return parent.config.appearance.asset
	}

	getSkinName(): string {
		const parent = this.owner as Enemy
		return parent.config.appearance.skin
	}

	update(delta: timeInSeconds): void {
		const parent = this.owner as Enemy
		if (!parent.isDead()) {
			const skipAnimChange = this.usingAnimator.activeAnimation === AnimationTrack.SPAWN || parent.shooting

			if (!skipAnimChange) {
				const hasMoved = Math.abs(parent.position.x - this.usingAnimator.position.x) > MOVEMENT_EPSILON || Math.abs(parent.position.y - this.usingAnimator.position.y) > MOVEMENT_EPSILON
				if (hasMoved) {
					this.playMovementAnim()
				} else {
					this.playIdleAnim()
				}
			}
		}

		this.usingAnimator.position.x = parent.position.x
		this.usingAnimator.position.y = parent.position.y
		this.usingAnimator.zIndex = parent.position.y + parent.zOffset
	}

	makeAnimatedSprite() {
		const parent = this.owner as Enemy

		const asset = AssetManager.getInstance().getAssetByName(this.getAssetName())
		const sheet = asset.spritesheet as PIXI.Spritesheet

		if (parent.isBoss) {
			this.spriteSheetAnimator = new SpriteSheetAnimator(sheet, AnimationTrack.MOVEMENT, parent.config.baseAttributes.animationSpeeds)
			this.usingAnimator = this.spriteSheetAnimator

			this.spriteSheetAnimator.filters = []
		} else {
			this.instancedSpriteSheetAnimator = new InstancedSpriteSheetAnimator(sheet, AnimationTrack.MOVEMENT, parent.config.baseAttributes.animationSpeeds)
			this.usingAnimator = this.instancedSpriteSheetAnimator
		}
	}

	resetModel() {
		this.usingAnimator.position.x = this.owner.position.x
		this.usingAnimator.position.y = this.owner.position.y
	}

	setScale(x: number, y: number) {
		this.usingAnimator.scale.x = x
		this.usingAnimator.scale.y = y
	}

	scale(x: number, y: number) {
		this.usingAnimator.scale.x *= x
		this.usingAnimator.scale.y *= y
	}

	playMovementAnim() {
		const enemy =  this.owner as Enemy
		if (enemy.isLootGoblin && enemy.currentState === AIStates.FLEEING || enemy.isYeti && enemy.substateData.currentSubstate === FightingSubstate.Charge) {
			if (this.usingAnimator.activeAnimation !== AnimationTrack.RUN) {
				this.usingAnimator.playAnimation(AnimationTrack.RUN)
			}
		} else if (enemy.name === ENEMY_NAME.SNOWBALL) {
				if (this.usingAnimator.activeAnimation !== AnimationTrack.MOVEMENT2) {
					this.usingAnimator.playAnimation(AnimationTrack.MOVEMENT2)
				}
		} else {
			if (this.usingAnimator.activeAnimation !== AnimationTrack.MOVEMENT) {
				this.usingAnimator.playAnimation(AnimationTrack.MOVEMENT)
			}
		}
	}

	playIdleAnim() {
		const enemy =  this.owner as Enemy
		if (this.useIdleAnimation && (enemy.currentState === AIStates.IDLE || enemy.substateData.currentSubstate === FightingSubstate.Aim || enemy.substateData.currentSubstate === FightingSubstate.Wait)) {
			if (this.usingAnimator.activeAnimation !== AnimationTrack.IDLE) {
				this.usingAnimator.playAnimation(AnimationTrack.IDLE)
			}
		}
	}

	playHitAnim() {
		this.usingAnimator.playAnimation(AnimationTrack.HIT, AnimationTrack.MOVEMENT)
	}

	playSpawnAnim() {
		this.usingAnimator.playAnimation(AnimationTrack.SPAWN, AnimationTrack.MOVEMENT)
	}

	playAttackAnim(track: AnimationTrack, callback?: () => void) {
		this.usingAnimator.playAnimation(track, undefined, callback)
	}

	die() {
		this.usingAnimator.playAnimation(AnimationTrack.DEATH)
	}

	addToScene() {
		if (this.instancedSpriteSheetAnimator) {
			this.instancedSpriteSheetAnimator.addToScene()
		} else {
			Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.spriteSheetAnimator)
		}
		this.isRendering = true
	}

	removeFromScene() {
		if (this.instancedSpriteSheetAnimator) {
			this.instancedSpriteSheetAnimator.removeFromScene()
		} else {
			Renderer.getInstance().mgRenderer.removeFromScene(this.spriteSheetAnimator)
		}
		this.isRendering = false
	}

	addColorModifier(r: number, g: number, b: number, a: number) {
		if (this.instancedSpriteSheetAnimator) {
			const colors = this.instancedSpriteSheetAnimator.getColors()
			this.instancedSpriteSheetAnimator.setColor(colors[0] + r, colors[1] + g, colors[2] + b, colors[3] + a)
		}
	}

	removeColorModifiers() {
		if (this.instancedSpriteSheetAnimator) {
			this.instancedSpriteSheetAnimator.setColor(1, 1, 1, 1)
		}
	}

	removeFilter(filter: PIXI.Filter) {
		this.spriteSheetAnimator.filters.remove(filter)
	}

	addFilter(filter: PIXI.Filter) {
		this.spriteSheetAnimator.filters.push(filter)
	}
}
