import { Vector } from 'sat'
import { EntityType, IEntity } from '../../../entities/entity-interfaces'
import { ObjectPoolTyped, PoolableObject } from '../../../utils/third-party/object-pool'
import { PhoenixBombs } from './phoenix-bomb-weapon'
import { AllWeaponTypes } from '../../weapon-types'
import { GameState, getNID } from '../../../engine/game-state'
import { timeInSeconds } from '../../../utils/primitive-types'
import { distanceVV, withinDistanceVV } from '../../../utils/math'
import EntityStatList from '../../../stats/entity-stat-list'
import { AnimationTrack } from '../../../spine-config/animation-track'
import { AssetManager } from '../../../web/asset-manager'
import { StatType } from '../../../stats/stat-interfaces-enums'
import { dealAOEDamageDamageSource } from '../../../projectiles/explosions'
import { CollisionLayerBits } from '../../../engine/collision/collision-layers'
import { InstancedSpriteSheetAnimator } from '../../../engine/graphics/instanced-spritesheet-animator'

export interface ThrownPhoenixBombParams {
	targetX: number
	targetY: number

	originPosition: Vector
	weapon: PhoenixBombs
	weaponType: AllWeaponTypes
	angle: number

	forcedFire: boolean
}

export enum PhoenixState {
	Flying,
	Bomb,
	Dead,
	Resurrection,
}

const PHOENIX_RADIUS_RANGE = 100
const GFX_Z_OFFSET = 50

export class ThrownPhoenixBomb implements PoolableObject, IEntity {
	static pool: ObjectPoolTyped<ThrownPhoenixBomb, ThrownPhoenixBombParams>

	nid: number
	entityType: EntityType = EntityType.Projectile

	private reusePositionVector: Vector = new Vector()
	targetPosition: Vector = new Vector()
	originPosition: Vector = new Vector()
	position: Vector = new Vector()

	weapon: PhoenixBombs
	weaponType: AllWeaponTypes
	statList: EntityStatList

	phoenixState: PhoenixState
	animationFlying: AnimationTrack
	animationBomb: AnimationTrack
	animationResurrection: AnimationTrack

	showImmediateDamageNumber: boolean = false

	spriteSheetAnimator: InstancedSpriteSheetAnimator

	timeElapsed: number = 0

	aliveTime: number = 0

	initialAttackSize: number

	forcedFire: boolean

	timeScale: number = 1

	constructor() {
		this.nid = getNID(this)
		this.animationFlying = AnimationTrack.PHOENIX_BOMB_FLYING
		this.animationBomb = AnimationTrack.PHOENIX_BOMB_BOMB
		this.animationResurrection = AnimationTrack.PHOENIX_BOMB_RESURRECTION
		this.makeVisual()
	}

	setDefaultValues(defaultValues: any, overrideValues?: ThrownPhoenixBombParams) {
		if (overrideValues) {
			this.targetPosition.x = overrideValues.targetX
			this.targetPosition.y = overrideValues.targetY

			this.originPosition.copy(overrideValues.originPosition)
			this.position.copy(overrideValues.originPosition)

			this.phoenixState = PhoenixState.Flying
			this.spriteSheetAnimator.playAnimation(this.animationFlying)

			this.weapon = overrideValues.weapon
			this.weaponType = overrideValues.weaponType
			this.statList = overrideValues.weapon.statList
			this.initialAttackSize = overrideValues.weapon.statList.getStat(StatType.attackSize)
			this.forcedFire = overrideValues.forcedFire

			this.spriteSheetAnimator.addToScene()

			GameState.addEntity(this)
		}
	}

	update(delta: timeInSeconds) {
		const targetPos = this.phoenixState === PhoenixState.Resurrection ? GameState.player.position : this.targetPosition
		this.moveSprite(targetPos, delta)
		this.phoenixStateCheck(delta, targetPos, PHOENIX_RADIUS_RANGE)
	}

	phoenixStateCheck(delta, targetPos, range) {
		switch (this.phoenixState) {
			case PhoenixState.Flying:
				if (withinDistanceVV(this.position, targetPos, range)) {
					this.phoenixBombAnimation(delta)
				}
				break
			case PhoenixState.Bomb:
				this.lightFuse(delta)
				break
			case PhoenixState.Resurrection:
				if (withinDistanceVV(this.position, targetPos, range)) {
					ThrownPhoenixBomb.pool.free(this)
				}
				break
		}
	}

	moveSprite(targetPos, delta) {
		if (this.phoenixState == PhoenixState.Flying || this.phoenixState == PhoenixState.Resurrection) {
			const distPerFrame = this.statList.getStat(StatType.projectileSpeed) * delta
			this.reusePositionVector.copy(targetPos).sub(this.position).normalize();

			this.position.add(this.reusePositionVector.scale(distPerFrame));
			this.spriteSheetAnimator.position.x = this.position.x
			this.spriteSheetAnimator.position.y = this.position.y
			this.spriteSheetAnimator.zIndex = this.position.y + GFX_Z_OFFSET

			const facing = Math.sign(targetPos.x - this.position.x)
			this.spriteSheetAnimator.scale.x = facing ? facing : this.spriteSheetAnimator.scale.x
		}
	}

	phoenixBombAnimation(delta) {
		if (this.phoenixState == PhoenixState.Flying) {
			this.phoenixState = PhoenixState.Bomb
			this.spriteSheetAnimator.playAnimation(this.animationBomb)
		}
	}

	lightFuse(delta) {
		this.timeElapsed += delta
		if (this.timeElapsed > this.weapon.fuseTime) {
			this.phoenixState = this.forcedFire ? PhoenixState.Resurrection : PhoenixState.Dead

			dealAOEDamageDamageSource(CollisionLayerBits.HitEnemyOnly, this.statList.getStat(StatType.attackSize), this.position, this.weapon, 1, null, null, true, 'fire')
			this.spriteSheetAnimator.playAnimation(this.animationResurrection)
			this.timeElapsed = 0
		}
	}

	resurrect() {
		this.phoenixState = PhoenixState.Resurrection
	}

	cleanup() {
		this.spriteSheetAnimator.removeFromScene()
		GameState.removeEntity(this)

		const oldWeapon = this.weapon

		this.weapon = null
		this.statList = null

		// make sure this is LAST
		if (!this.forcedFire) {
			oldWeapon.onBombCollected(this)
		}
	}

	makeVisual() {
		const asset = AssetManager.getInstance().getAssetByName('phoenix-bombs-spritesheet').spritesheet as PIXI.Spritesheet
		this.spriteSheetAnimator = new InstancedSpriteSheetAnimator(asset, this.animationFlying, 0.5)
		// This may have to be changed depending on if we have overlap
		this.phoenixState = PhoenixState.Flying
	}

	isPlayerOwned(): boolean {
		return true
	}

	getKnockbackDirection(mutableEntityPos: SAT.Vector): SAT.Vector {
		mutableEntityPos.x = 0
		mutableEntityPos.y = 0
		return mutableEntityPos
	}
}
