import { GameState } from "../engine/game-state"
import { ParticleEffectType } from "../engine/graphics/pfx/particle-config"
import { percentage, timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { ACT_TIMER, InGameTime } from "../utils/time"
import { CONE_DOG_GOOD_BOY_MOVEMENT_SPEED_PER_STACK, CONE_DOG_GOOD_BOY_SIZE_PER_STACK } from "./characters"

// Used to intiialize default enemy speeds as well
export const PLAYER_DEFAULT_MOVEMENTSPEED = 400

export const PLAYER_MAX_SECONDARY_WEAPONS = 3

export const PLAYER_CHARGE_MISFIRE_PERCENT = 19

export const PLAYER_SHOOT_CAM_SHAKE = 0.4

export const PLAYER_LEVEL_UP_KNOCKBACK_SEARCH_DISTANCE = 450
export const PLAYER_LEVEL_UP_KNOCKBACK_STRENGTH = 1_400

export const PLAYER_HIT_KNOCKBACK_SEARCH_DISTANCE = 350
export const PLAYER_HIT_KNOCKBACK_STRENGTH = 1_300

export const PLAYER_KILLSTREAK_DURATION = 4250
export const PLAYER_KILLSTREAK_DURATION_KILLS_THRESHOLD = 500
export function getAdjustedKillstreakDuration(kills: number): timeInMilliseconds {
	return PLAYER_KILLSTREAK_DURATION * (1 - (kills / (kills + PLAYER_KILLSTREAK_DURATION_KILLS_THRESHOLD)))
}
export function getKillstreakExplosionDamageScale(player) {
	return 2 + getDamageMultiplierByTime() * 2
}
export const PLAYER_KILLSTREAK_EXPLOSION_RADIUS = 350
export const PLAYER_KILLSTREAK_MINOR_INTERVAL = 25
export const PLAYER_KILLSTREAK_MAJOR_INTERVAL = 150

export const PLAYER_ESTIMATED_MAX_COMMON_CURRENCY_PER_RUN = 1200

export const PLAYER_IGNITE_SPREAD_ON_DEATH_TARGETS = 3
export const PLAYER_IGNITE_SPREAD_ON_DEATH_RADIUS = 225
export const PLAYER_POISON_SPREAD_ON_DEATH_TARGETS = 5
export const PLAYER_POISON_POOL_RADIUS = 85
export const PLAYER_POISON_POOL_DURATION = 12

export const CONEDOG_FRONT_FOOT_DUST_OFFSET = 50
export const CONEDOG_BACK_FOOT_DUST_OFFSET = -80

// nikola scope 'prety overpowered laser' zone
export const RANDOM_CHANCE_FOR_MASSIVE_BEAM_DAMAGE: percentage = 0.03
export const MASSIVE_DAMAGE_AMOUNT = 47_361
export const MASSIVE_DAMAGE_BOSS_AMOUNT = 473
// end

export const CLARITY_AURA_ENEMY_COUNT_MIN = 100
export const CLARITY_AURA_COLOR =  0x70e0dd
export const CLARITY_AURA_MAX_STRENGTH = 1.75
export const CLARITY_AURA_LERP_TIME = 0.5 // how long it takes in seconds to go from 0% to 100% aura

export const BOOMERANG_ACCELERATION_SPEED_INCREASE = 1.05

export const CHARGE_WEAPON_MAX_VISUAL_PIPS = 15

export const RADAR_PICKUP_GROUP_RADIUS = 400
export const RADAR_WEALTHY_ENEMY_XP_AMOUNT = 4 // drop this amount of xp instead of 1
export const RADAR_WEALTHY_DROP_FORCE_MIN = 600
export const RADAR_WEALTHY_DROP_FORCE_MAX = 800

export const DEMIGOD_OF_THUNDER_RADIUS = 100
export const DEMIGOD_OF_THUNDER_DAMAGE_MULTIPLIER = 3.0

export const IGNITE_EXPLODE_RADIUS = 250
export const IGNITE_EXPLODE_DAMAGE_MAX_HP_SCALE = 0.1
export const IGNITE_EXPLODE_PFX_SIZE = 70

export const INTELLIGENCE_DUMP_STAT_LEVEL_DAMAGE_BONUS = 0.12

export enum ShrapnelConfig {
	// 0 bad, is false
	StormbreakerRazorang = 1,
	LongbowWalkItOff = 2,
}

export const SHRAPNEL_NUM_PIERCE = 3

export const ShrapnelConfigMap: {
	[K in ShrapnelConfig]: {
		damageScale: percentage,
		minProjectileSize: number,
		projectileCount: number,
		attackPierceCount: number,
		particleEffectType?: ParticleEffectType,
	}
} = {
	[ShrapnelConfig.LongbowWalkItOff]: {
		damageScale: 0,
		minProjectileSize: 5,
		projectileCount: 2,
		attackPierceCount: 2,
		particleEffectType: ParticleEffectType.PROJECTILE_STUN_SHRAPNEL
	},
	[ShrapnelConfig.StormbreakerRazorang]: {
		damageScale: 0.5,
		minProjectileSize: 5,
		projectileCount: 3,
		attackPierceCount: 3,
	},
}

export const BOILING_BLOOD_RADIUS = 150
export const BOILING_BLOOD_DAMAGE_SCALE = 0.5

export const PLOT_TWIST_EXPLOSION_MIN_COOLDOWN: timeInMilliseconds = 4_500
export const PLOT_TWIST_EXPLOSION_MAX_COOLDOWN: timeInMilliseconds = 6_000
export const PLOT_TWIST_EXPLOSIONS_PER_BATCH: number = 5
export const PLOT_TWIST_EXPLOSION_WARNING_TIME: timeInSeconds = 1

export const HUGE_XP_VALUE = 10
export const SUPERNATURAL_XP_VALUE = 10
export const LARGE_XP_VALUE = 6
export const MEDIUM_XP_VALUE = 3
export const SMALL_XP_VALUE = 1

export const PLOT_TWIST_BUTTERFINGERS_MIN_FORCE: number = 4_500
export const PLOT_TWIST_BUTTERFINGERS_MAX_FORCE: number = 6_000

export const PLOT_TWIST_HARDCORE_SURVIVAL_EXHAUSTED_MOVEMENT_DEBUFF_AMOUNT = -0.55
export const PLOT_TWIST_HARDCORE_SURVIVAL_MAX_STAMINA = 100
export const PLOT_TWIST_HARDCORE_SURVIVAL_EXHAUSTED_STAMINA_RECOVERY_RATE = 12 // stamina per second 
export const PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_CONSUMPTION_RATE = 9.5 // stamina per second consumed while moving
export const PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_CONSUMPTION_RATE_DOG = 12.5 // DOG (yes dog was too good): stamina per second consumed while moving 
export const PLOT_TWIST_HARDCORE_SURVIVAL_NORMAL_STAMINA_RECOVERY_RATE = 22 // stamina per second
export const PLOT_TWIST_HARDCORE_SURVIVAL_SMALL_HEART_STAMINA_RECOVERY = 40
export const PLOT_TWIST_HARDCORE_SURVIVAL_LARGE_HEART_STAMINA_RECOVERY = 75
export const PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_MISSING_TO_PICK_UP_SMALL_HEART = 33
export const PLOT_TWIST_HARDCORE_SURVIVAL_STAMINA_MISSING_TO_PICK_UP_LARGE_HEART = 50

export const PLOT_TWIST_HEAD_START_LEVEL_BOOST_START_TIME = 4
export const PLOT_TWIST_HEAD_START_LEVEL_CAP = 4
export const PLOT_TWIST_HEAD_START_XP_SLOWDOWN_LEVEL = 25
export const PLOT_TWIST_HEAD_START_XP_SLOWDOWN_MULT = 1.35

export interface WeightedDrop {
	type: 'currency'| 'rottenHeart' | 'xp';
	weight: number;
  }

export const scrapyardWeightList: WeightedDrop[] = [
	{ type: 'currency', weight: 50 },
	{ type: 'rottenHeart', weight: 10 },
	{ type: 'xp', weight: 40 },
  ];

export const PLOT_TWIST_SPOOKY_GHOSTS_SUPERNATURAL_XP_DROP_CHANCE = 0.2

export function getRampingDamageBonus(totalEntitiesHit: number) {
    if (totalEntitiesHit < 1) {
        return 1
    }

    return 1 + 0.05 + (0.05 * (totalEntitiesHit - 1)) ** 1.05
}

export function getConeDogThornCooldownReduction(currentHealth: number, maxHealth: number): number {
    return -(1 - Math.pow(currentHealth / maxHealth, 0.7))
}

export function getGoodBoySizeBonus(stacks: number): number {
    return Math.pow(stacks, 0.95) * CONE_DOG_GOOD_BOY_SIZE_PER_STACK
}

export function getGoodBoyMoveSpeedBonus(stacks: number): number {
    return Math.pow(stacks, 0.95) * CONE_DOG_GOOD_BOY_MOVEMENT_SPEED_PER_STACK
}

export function getDamageMultiplierByTime() {
	let percentOfRunPassed = InGameTime.percentOfRunPassed()
	percentOfRunPassed = percentOfRunPassed * percentOfRunPassed * percentOfRunPassed // apply a cubic DR

	const multi = Math.lerp(1, 6, percentOfRunPassed)
	return multi
}

/**
 * Gets damage based on player level and current game time. Useful for effects that don't have their own damage tracking, like debuffs, pets, events, or other sources of damage.
 * damage = 5 + (level/2 * game time multiplier), where multiplier is a value between 1.0x and 6.0x
 *  5:00 minutes ~=  20 damage
 * 10:00 minutes ~=  63 damage
 * 15:00 minutes ~= 150 damage
 * @param level 
 * @returns raw damage value
 */
export function getDamageByPlayerLevel(level?: number) {
	level ??= GameState.player.level

	const damage = 5 + (level/2 * getDamageMultiplierByTime())
	return damage
}

export function getEffectMultiplierBasedOnSkill(skillCooldown: timeInMilliseconds, skillAmmo: number): percentage {
	return Math.clamp(0.5 + skillCooldown / skillAmmo / 5000 / 2, 0.01, 100.0)
}
