import { Vector } from "sat"
import { VectorXY } from "../../utils/math"
import { timeInSeconds } from "../../utils/primitive-types"

export interface IPhysicsObject {
	externalForces: ExternalPhysicsForce[]
}

export class ExternalPhysicsForce {
	force: Vector
	durationSeconds: timeInSeconds
	counterSeconds: timeInSeconds = 0
	decaying: boolean
	onFinish: (force: ExternalPhysicsForce) => void

	constructor(force: Vector, durationSeconds: timeInSeconds, onFinish?: (force: ExternalPhysicsForce) => void, decaying?: boolean) {
		this.force = force
		this.durationSeconds = durationSeconds
		this.onFinish = onFinish
		this.decaying = Boolean(decaying)
	}

	reset() {
		this.counterSeconds = 0
	}
}

export function updatePhysics(player: IPhysicsObject, position: VectorXY, delta: timeInSeconds, forceIsMutable: boolean) {
	player.externalForces.forEach((force: ExternalPhysicsForce) => {
		let vector: Vector
		if (force.decaying) {
			const multi = 1 - force.counterSeconds / force.durationSeconds
			vector = new Vector(force.force.x, force.force.y).scale(multi)
		} else {
			vector = force.force
		}

		position.x = position.x + vector.x * delta
		position.y = position.y + vector.y * delta

		if (forceIsMutable) {
			force.counterSeconds += delta
		}

		if (force.counterSeconds >= force.durationSeconds) {
			if (force.onFinish) {
				force.onFinish(force)
			}
			player.externalForces.remove(force)
		}
	})
}