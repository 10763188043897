import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { getIgniteStacks } from "../../buffs/generic-buff-definitions"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { SpritesheetAnimatorComponent } from "../../engine/graphics/spritesheet-animator-component"
import { getDamageByPlayerLevel } from "../../game-data/player-formulas"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { ObjectPool } from "../../utils/third-party/object-pool"
import { AllWeaponTypes } from "../../weapons/weapon-types"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"
import { HAZARD_ELIPSE_COLLIDER_CONFIG } from "./ellipse-hazard-params"

const IGNITE_PLAYER_DAMAGE_STACKS_MOD = 6.0

export class LavaPoolHazard extends DamagingGroundHazard {
	
	static pool: ObjectPool
	static gfxContainerWidth: number
	static gfxContainerHeight: number

	spriteSheet: SpritesheetAnimatorComponent

	constructor() {
		super()

		const spriteSheet = AssetManager.getInstance().getAssetByName('elemental-maelstrom').spritesheet
		this.spriteSheet = new SpritesheetAnimatorComponent(this, spriteSheet, 'lava-idle', 0.1)
		this.spriteSheet.spriteSheetAnimator.activeSprite.activeFrame = Math.getRandomInt(0, this.spriteSheet.spriteSheetAnimator.activeSprite.frames.length - 1)
		this.spriteSheet.overrideZindex(-9_999_999)

		this.colliderComponent.setColliders(HAZARD_ELIPSE_COLLIDER_CONFIG)

		if (!LavaPoolHazard.gfxContainerWidth) {
			LavaPoolHazard.gfxContainerWidth = spriteSheet.textures['lava-idle_00.png'].width
			LavaPoolHazard.gfxContainerHeight = spriteSheet.textures['lava-idle_00.png'].height
		}
	}

	override setDefaultValues(defaultValues: any, overrideValues?: DamagingGroundHazardParams): void {
		super.setDefaultValues(defaultValues, overrideValues)

		if(overrideValues) {
			this.spriteSheet.spriteSheetAnimator.position.x = overrideValues.position.x
			this.spriteSheet.spriteSheetAnimator.position.y = overrideValues.position.y
			this.spriteSheet.spriteSheetAnimator.zIndex = -9_999_999

			this.spriteSheet.spriteSheetAnimator.scale.x = overrideValues.triggerRadius / (LavaPoolHazard.gfxContainerWidth / 2.2)
			this.spriteSheet.spriteSheetAnimator.scale.y = overrideValues.triggerRadius / (LavaPoolHazard.gfxContainerHeight / 1)

			this.spriteSheet.addToScene()
		}
	}

	override cleanup(): void {
		super.cleanup()
		
		this.spriteSheet.spriteSheetAnimator.scale.x = 1
		this.spriteSheet.spriteSheetAnimator.scale.y = 1
		this.spriteSheet.spriteSheetAnimator.position.x = 0
		this.spriteSheet.spriteSheetAnimator.position.y = 0
		this.spriteSheet.removeFromScene()
	}

	 onEntityEnterTrigger(entity: ColliderComponent): void {
		// Buff.apply(BuffIdentifier.LavaPool, null, entity.owner, 1, Number.MAX_SAFE_INTEGER)
	}

	onEntityLeaveTrigger(entity: ColliderComponent): void {
		// Buff.remove(entity.owner, BuffIdentifier.LavaPool)
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		super.update(delta, now)

		this.spriteSheet.update(delta)
	}

	returnToPool() {
		LavaPoolHazard.pool.free(this)
	}

	isPlayerOwned(): boolean {
		return false
	}

	onHitEnemy(enemy: Enemy) {
		const stacks = getIgniteStacks(getDamageByPlayerLevel() * IGNITE_PLAYER_DAMAGE_STACKS_MOD)
		Buff.apply(BuffIdentifier.Ignite, this, enemy, stacks)
	}

	override setOffset(offset: SAT.Vector): void {
		super.setOffset(offset)
		
		this.spriteSheet.spriteSheetAnimator.position.x = this.position.x
		this.spriteSheet.spriteSheetAnimator.position.y = this.position.y
	}
}