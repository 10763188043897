{
	"menu": {
		"header": "DIEBRARY",
		"beta_fnf_disclaimer": "Closed Beta",
		"play_button": "Play",
		"wire_frame": "Wireframe",
		"options": "options",
		"log_out": "Log Out"
	},
	"error": {
		"use_chrome": "This game plays best in Google Chrome browser",
		"header": "Some Error",
		"description": "This is some example error description!"
	},
	"default_two_btn_prompt": {
		"example_description_1": "Description line 1.",
		"example_description_2": "Description line 2",
		"no_default": "no-default",
		"yes_default": "yes-default"
	},
	"generic_modal": {
		"okay": "Okay",
		"awesome": "Awesome!"
	},
	"level_up": {
		"title": "Level Up"
	},
	"sign_in": {
		"log_in": "Log In",
		"email_label": "email address",
		"password_label": "password",
		"button_log_in": "Log In",
		"button_log_in_kongregate": "Log in with Kongregate",
		"loading_armorgames": "Attempting to log in through Armor Games",
		"error_log_in_armorgames": "An error occurred when attempting to log in through Armor Games, refresh the page and ensure you are signed in correctly on Armor Games.",
		"log_in_armorgames": "Please log in through Armor Games and then refresh the page.",
		"no_account_warning": "(Your progress may be lost!)",
		"button_create_account": "Create an Account",
		"button_anon_account": "Play Without An Account",
		"reset_password": "RESET PASSWORD",
		"error_log_in_steam": "An error occurred when attempting to log in through Steam, please restart the game to try again."
	},
	"sign_up": {
		"button_back": "Back",
		"button_next": "Next",
		"button_create_account": "Create Account",
		"email_label": "email address",
		"password_label": "password",
		"confirm_password_label": "confirm password",
		"username": "username",
		"create_account": "Create Account",
		"tou": "terms of use",
		"terms": "i agree to the terms of use",
		"ooi_contact": "allow other ocean interactive to contact me"
	},
	"logged_in": {
		"button_play": "Play",
		"button_settings": "Settings",
		"button_log_out": "Log Out",
		"button_quit": "Quit"
	},
	"links": {
		"terms_of_use": "TERMS OF USE",
		"privacy_policy": "PRIVACY POLICY",
		"cookie_policy": "COOKIE POLICY",
		"credits": "CREDITS"
	},
	"game_info": {
		"the_game": "The Game",
		"game_welcome": "Welcome to the Diebrary!",
		"game_description": "An arena roguelite packed with action, enemies, and more! Do you have what it takes to survive?",
		"controls_label": "Controls",
		"controls_description_a": "W/A/S/D - movement",
		"controls_description_b": "Mouse 1 - shoot your primary weapon",
		"controls_description_c": "Spacebar - use your character skill",
		"controls_description_d": "Secondary weapons will auto-fire!",
		"controls_description_e": "P - used to pause and unpause the game",
		"updates_label": "Updates",
		"updates_description": "Wow it's the final milestone! Let's go! 🏆",
		"third_label": "The Game",
		"third_description_a": "Survive and kill enemies to gain power.",
		"third_description_b": "Author a unique Story and complete all the Chapters!",
		"third_description_c": "Invite your friends or community to write the Story together.",
		"kills": "KILLS",
		"timer": "TIMER"
	},
	"errors": {
		"server_error_header": "Connection Error",
		"server_error_description": "Error connecting to the server. Please refresh and try again.",
		"server_error_dismiss": "Close",
		"end_run_submit_error_description": "Error sending information about your run to the server. Please refresh and try again.",
		"end_run_cast_vote_header": "You should cast a vote!",
		"end_run_cast_vote_description": "Please vote on the next Plot Twist for this story.",
		"end_run_cast_vote_ok": "Stay",
		"end_run_cast_vote_dismiss": "Leave",
		"error_title": "An Error Occurred",
		"invalid_email_format": "Invalid Email Format: Please check your email address and try again.",
		"invalid_password": "Invalid Password: Please check your password and try again.",
		"no_matching_user": "No user account with that email was found. Please check your email address and try again.",
		"missing_fields": "One or more of the text fields are empty. Please check that all input fields are filled and try again.",
		"generic_error": "Something went wrong. Please refresh and try again.",
		"invalid_username": "Invalid Username: Usernames with bad words/phrases are not allowed.",
		"invalid_username_length": "Invalid Username Length: Usernames must be between 3 and 18 Characters.",
		"invalid_password_length": "Invalid Password Length: Passwords must be between 10 and 64 Characters.",
		"passwords_dont_match": "Passwords Don't Match: Please check that your passwords match and try again.",
		"must_agree_tos": "Must agree to the terms of use",
		"username_taken": "Username already taken",
		"email_taken": "Email already taken",
		"generic_title": "Error!",
		"startup_error_web_gl": "Your browser does not support WebGL. WebGL is needed to run this game. We recommend Google Chrome for the best experience.",
		"startup_error_unknown": "Something went wrong when starting the game. If this is a recurring issue please contact support.",
		"maintenance_title": "Server Maintenance",
		"maintenance_description": "The server is currently undergoing maintenance. Please try again later.",
		"no_data_found": "Could not find an account matching the provided link code.",
		"link_code_expired": "The provided link code has expired.",
		"link_code_used": "The provided link code has already been used.",
		"web_account_linked": "This web account has already been linked to a steam account.",
		"no_user_found": "The user associated with the link code could not be found. Please try generating a new code.",
		"steam_account_linked": "This steam acccount has already been linked to a web account."
	},
	"progress_bar": {
		"level": "LVL"
	},
	"weapon_damage_table": {
		"weapon_header": "Weapon",
		"dps_header": "DPS",
		"damage_dealt_header": "Damage Dealt"
	},
	"event_table": {
		"event_category": "Category",
		"event_total": "Total"
	},
	"end_chapter": {
		"weapon_label": "Weapon",
		"upgrades": "Upgrades",
		"button_character_select": "Character Select",
		"button_perk_store": "Perks & Unlocks",
		"button_main_menu": "Main Menu",
		"button_chapter_summary": "Chapter Summary",
		"popular_vote_header": "Plot Twist: Popular Vote - Most votes wins!",
		"raffle_header": "Plot Twist: Raffle - Random raffle ticket wins!",
		"prompt_end_run_header": "Abandon Current Chapter",
		"prompt_end_run_description": "Are you sure you want to end your chapter run?",
		"prompt_end_run_yes": "Leave",
		"prompt_end_run_no": "Stay",
		"current_twist": "Current Twist",
		"vote_earned_vote": "You have earned a vote for a new Plot Twist!",
		"vote_plot_twist_explanation": "These are Plot Twists, and they drastically affect the gameplay of future chapters in your story.",
		"vote_plot_twist_explanation_split_1": "These are Plot Twists,",
		"vote_plot_twist_explanation_split_2": "and they drastically affect the gameplay of future chapters in your story.",
		"vote_place_cta": "Place your vote and author the story's future now!",
		"vote_earned_progress": "You have earned progress toward voting on a new Plot Twist!",
		"vote_not_earned": "You have not made enough progress to vote on a new Plot Twist.",
		"vote_try_again": "Try this chapter again to earn more points and vote!"
	},
	"story_select": {
		"button_back": "Back",
		"button_play": "play",
		"player_story_id": "{playerId}'s Story",
		"player_solo_story_id": "{playerId}'s Solo Story",
		"latest_chapter": "Currently on Chapter {latestChapter}",
		"protagonist": "{protagonists} Protagonists",
		"show_votes": "Show / Hide Votes",
		"enable_chat": "Enable Chat",
		"enable_meta_prog": "Enable Meta Progression",
		"start_with_plot_twist": "Start With Plot Twist",
		"no_stories": "No Stories Found",
		"prompt_title_max_active_stories": "You Have Maxed Out Your Active Stories",
		"prompt_description_max_active_stories": "In order to play this story, please archive one of your current active stories then try again",
		"prompt_button_ok": "Okay",
		"button_return": "Return",
		"button_archived_stories": "Archived Stories",
		"button_active_stories": "Active Stories",
		"button_leave": "Archive Story",
		"button_summary": "Summary",
		"button_create_story": "Create Story",
		"button_find_story": "Find Story",
		"explain_solo_mode": "Solo Stories are played by yourself, and will instantly move to the next Chapter after you successfully vote on a Plot Twist.",
		"explain_friends_mode": "Friends Stories can be played by anyone that you share the link with. Once you create the Story, click the link icon to copy the game link to your clipboard, then share it with your friends.",
		"explain_community_mode": "Community Stories can be played by anyone who joins the story via 'Find Stories'. These are public games that can grow to have a huge number of players.",
		"no_twists": "This story has no twists yet!",
		"no_active_stories": "Create a new story or find an existing one.",
		"slots_remaining": " Slots Remaining",
		"button_clear": "Clear all",
		"button_join": "Join",
		"map_forest": "Forest",
		"map_hollow": "Hollow",
		"map_tundra": "Tundra",
		"button_find_story_id": "Find Story By <span style=\"font-variant: normal;\">ID"
	},
	"chapter_select": {
		"button_character_select": "Character Select",
		"button_return_to_story": "Return to Story",
		"button_return_to_end_run": "Return",
		"button_return": "Return",
		"button_prelude": "Prelude",
		"no_mutators": "No Plot Twists Present",
		"no_leaderboard_data": "No Leaderboard Data",
		"chapter_number": "{chapter}"
	},
	"character_select": {
		"story_select_header": "Story Select",
		"player_id_story_header": "{playerId}'s Story",
		"chapter_number_label": "Chapter Number",
		"total_players_label": "Players",
		"mutators_label": "Plot Twists",
		"button_meta_progression": "Perks & Unlocks",
		"button_story_select": "Back to Stories",
		"button_chapter_select": "Chapter Summary",
		"button_back": "Return",
		"button_start_story": "Play"
	},
	"meta_progression_store": {
		"characters_and_weapons": "Characters & Weapons",
		"plot_twists": "Plot Twists",
		"unlocked": "Unlocked",
		"purchasable": "Purchasable",
		"perks": "Perks",
		"special": "Special",
		"refund_everything": "Refund Everything",
		"refund_category": "Refund Category",
		"buy_perk": "Buy Perk",
		"refund_perk": "Refund Perk",
		"hide_unlocks": "Hide Unlocks",
		"back": "Back",
		"refund_confirm": "Are you sure you want to refund all of your perks?",
		"yes": "Yes",
		"mission": "Mission",
		"unlockable": "Unlockable"
	},
	"currency": {
		"scrap_label": "Paper Scraps",
		"scrap_description": "Earn Scraps by defeating normal enemies",
		"scroll_label": "Lost Scrolls",
		"scroll_description": "Earn Scrolls by defeating bosses and special enemies",
		"tome_label": "Magic Tomes",
		"tome_description": "Earn magic tomes by successfully completing chapters. Tomes are awarded when voting completes."
	},
	"reset_password": {
		"reset_password": "Reset Password",
		"email": "Email Address",
		"send": "Send Email",
		"set_password": "Set Password",
		"sent_email": "An email has been sent with a link to reset your password.",
		"back": "Back",
		"new_password": "New Password",
		"confirm_password": "Confirm Password",
		"password_requirement": "Your password must be between 10 and 64 characters in length",
		"password_was_changed": "Your password has been updated.",
		"error_unknown": "Unknown Validation Error",
		"error_enter_email": "You must enter an e-mail address.",
		"error_email_format": "E-mail address is not in correct format.",
		"error_forgot_pw_email": "There is no account matching that e-mail address.",
		"error_missing_fields": "You must complete both fields.",
		"error_pw_match": "Password and confirmation password do not match.",
		"error_pw_length": "Password does not meet length requirements",
		"error_reset_pw_invalid": "Invalid token, try sending a new 'forgot password' email.",
		"error_unknown_validation": "Unknown validation error"
	},
	"settings": {
		"fullscreen": "Fullscreen",
		"auto_aim": "Auto Aim",
		"auto_shoot": "Auto Shoot",
		"auto_shoot_explain": "Press 'Fire' to toggle your character automatically shooting with this option enabled!",
		"music": "Music",
		"sfx": "Game Volume",
		"abandon_story": "Abandon Story",
		"window_mode": "Window Mode",
		"resolution": "Resolution",
		"return": "Return",
		"save_settings": "Save Settings",
		"category_general": "General",
		"category_video": "Video",
		"category_controls": "Controls",
		"category_controllers": "Controllers",
		"category_fullscreen": "Fullscreen",
		"category_windowed": "Windowed",
		"category_windowed_fullscreen": "Windowed Fullscreen",
		"category_keyboard": "Keyboard",
		"controller": "Controller {controllerNumber}",
		"input_move_up": "Up",
		"input_move_down": "Down",
		"input_move_left": "Left",
		"input_move_right": "Right",
		"input_shoot": "Shoot",
		"input_skill": "Skill",
		"input_pause": "Pause",
		"rebind_input": "Press Key to Bind",
		"rebind_input_error": "Key Already Bound",
		"rebind_input_pause_error": "Input Incompatible",
		"rebind_countdown": "seconds",
		"link_account_through_code": "Link Account (Code)",
		"generate_link_code": "Generate Link Code",
		"visit_steam_controller_settings": "Change controller settings via Steam"
	},
	"vote_progress": {
		"button_book_report": "Book Report",
		"button_confirm": "OK",
		"button_skip": "Skip Vote",
		"button_cancel": "Cancel",
		"skip_warning_title": "Are you sure?",
		"skip_warning_description": "Are you sure you want to not place a vote?"
	},
	"book_review": {
		"button_submit": "Submit",
		"button_skip": "Skip Review"
	},
	"activityFeed": {
		"feed_msg_won": "{player} defeated chapter {chapter} with a score of {score}!",
		"feed_msg_lost": "{player} lost chapter {chapter} after {time} seconds.",
		"feed_msg_voted": "{player} voted for {twist}.",
		"feed_msg_twist_won": "The vote is in: {twist} wins!",
		"feed_msg_chat": "{player}: {message}",
		"feed_msg_wishlist": "Buy the full game on Steam!",
		"button_return": "Close"
	},
	"loading": {
		"title_1": "Diebrarian's Tip:",
		"description_text_1": "Remain close to enemies: any dropped XP will already be within pickup range.",
		"title_2": "Diebrarian's Tip:",
		"description_text_2": "Upgrading one or two weapons many times is often stronger than upgrading more weapons fewer times.",
		"title_3": "Diebrarian's Tip:",
		"description_text_3": "Place Elph N. Ranjar's trap before you get in trouble to minimize its downtime!",
		"title_4": "Diebrarian's Tip:",
		"description_text_4": "You move slower while shooting your primary weapon. Stop shooting when you need to squeeze through a gap of enemies.",
		"title_5": "Diebrarian's Tip:",
		"description_text_5": "You can unlock more characters and weapons in the 'Perks & Unlocks' page."
	},
	"summary_table": {
		"header_character": "Character",
		"header_sum_of_enemies_killed": "Enemies Killed",
		"header_total_run_duration_in_seconds": "Run Duration",
		"header_total_Score": "Total Score",
		"header_is_win": "Victory / Defeat"
	},
	"cookies": {
		"heads_up": "Heads Up!",
		"no_account_warning": "Without cookies enabled and an account, leaving the page will result in the loss of your progress."
	},
	"discord": {
		"join_community": "Join Our Community",
		"report_issue": "Report an Issue",
		"share_feedback": "Share More Feedback"
	},
	"splash": {
		"photo_warning": "PHOTOSENSITIVITY WARNING",
		"photo_text1": "This game contains bright flashing lights. Please immediately stop playing and consult a doctor if you experience lightheadedness, altered vision, eye or face twitching, jerking or shaking of arms or legs, disorientation, confusion, or momentary loss of awareness.",
		"photo_text2": "If you or any of your relatives have a history of seizures or epilepsy, consult a doctor before playing.",
		"press_any_button": "[Press Any Button]"
	},
	"account_link": {
		"input_code": "Input Player Code",
		"input_code_description": "Input the player code provided to you on the web version in the field below.",
		"paste_here": "Paste link code here",
		"next": "Next",
		"back": "Back",
		"link_account": "Link Account",
		"username": "Username:",
		"created_time": "Created time:",
		"paper_scraps": "Paper Scraps:",
		"lost_scrolls": "Lost Scrolls:",
		"magic_tomes": "Magic Tomes:",
		"link_warning": "All progress made on your Steam account will be lost upon linking.",
		"proceed": "Proceed?",
		"successful_link": "Successful Link!",
		"successful_link_description": "Accounts linked successfully! Thank you for playing Diebrary!",
		"back_to_menu": "Back To Menu",
		"reload_game": "Reload Game",
		"generate_link_code": "Generated Player Code",
		"link_code_description": "The code has been copied to your clipboard. Link your account to Steam by going to Settings, selecting the link with code button, and pasting the code into the provided field.",
		"get_code": "Get Code"
	},
	"glossary": {
		"pierce_name": "pierce",
		"pierce_def": "Each point of Pierce allows a projectile to go through one more enemy before disappearing",
		"damage_name": "damage",
		"damage_def": "A percent bonus or penalty across all player damage",
		"pick_up_range_name": "pick-up range",
		"pick_up_range_def": "The higher your pick-up range, the further pick-ups will travel to get to you",
		"movement_speed_name": "movement speed",
		"movement_speed_def": "Increases or decreases your movement speed by a percent",
		"attack_rate_name": "attack rate",
		"attack_rate_def": "A percent bonus to your primary weapon attack speed",
		"charge_rate_name": "charge rate",
		"charge_rate_def": "A percent bonus to how fast your primary weapon charges (if it's a charge weapon)",
		"xp_drop_rate_name": "XP drop rate",
		"xp_drop_rate_def": "how likely XP is to drop off an enemy",
		"primary_weapon_damage_name": "primary weapon damage",
		"primary_weapon_damage_def": "A percent bonus to your primary weapon damage",
		"attack_size_name": "attack size",
		"attack_size_def": "Increases or decreases the size of your attacks and projectiles by a percentage",
		"secondary_weapon_damage_name": "secondary weapon damage",
		"secondary_weapon_damage_def": "A percent modifier to the damage of all secondary weapons",
		"cooldown_name": "cooldown",
		"cooldown_def": "The time between uses of a weapon or skill",
		"cooldown_speed_name": "cooldown speed",
		"cooldown_speed_def": "A percent modifier to secondary weapon cooldown",
		"bleed_name": "bleed",
		"bleed_def": "a bleeding enemy takes damage over 3 seconds. The more stacks of bleed, the more damage is dealt.",
		"projectile_speed_name": "projectile speed",
		"projectile_speed_def": "A percentage bonus to the speed of all weapon projectiles",
		"ignite_name": "ignite",
		"ignite_def": "An ignited enemy takes damage over 2 seconds. Only the strongest Ignite deals damage",
		"ammo_name": "ammunition",
		"ammo_def": "The number of shots before reload if this is a primary weapon, or the number of charges a skill has",
		"rangers_mark_name": "Ranger's Mark",
		"rangers_mark_def": "Every several seconds, the enemy with the most HP near you will gain \"Ranger's Mark\" for 3 seconds. Shooting the mark damages and slows nearby enemies.",
		"skill_duration_name": "skill duration",
		"skill_duration_def": "How long your skill lasts",
		"slow_name": "slow",
		"slow_def": "Reduces enemy movement speed",
		"chain_name": "chain",
		"chain_def": "Chaining attacks bounce to a nearby enemy",
		"split_name": "split",
		"split_def": "Each point of Split causes projectiles to split to an extra projectile after hitting an enemy",
		"projectile_spread_name": "spread",
		"projectile_spread_def": "Controls how close together multiple projectiles fire.",
		"range_name": "range",
		"range_def": "The maximum distance a projectile can travel",
		"knockback_name": "knockback",
		"knockback_def": "Attacks with knockback move enemies away from you on hit",
		"projectile_count_name": "projectile count",
		"projectile_count_def": "The number of projectiles this weapon fires",
		"burst_name": "burst",
		"burst_def": "A weapon with burst will fire multiple projectiles in quick succession",
		"reload_name": "reload rate",
		"reload_def": "A percentage modifier changing how fast you reload",
		"ailment_name": "ailment",
		"ailment_def": "Negative effects that harm or hinder enemies or players",
		"chill_name": "chill",
		"chill_def": "Chilled enemies move slower",
		"stun_name": "stun",
		"stun_def": "Stunned enemies momentarily stop moving, but can still deal damage",
		"poison_name": "poison",
		"poison_def": "Poisoned enemies take damage over 6 seconds. Each stack increases the damage and duration.",
		"doom_name": "doom",
		"doom_def": "When an enemy has 5 stacks of Doom, they explode and spread Doom stacks to nearby enemies. This can cause chain explosions.",
		"sacrifice_name": "SACRIFICE",
		"sacrifice_def": "Permanently sacrifice the following effects/stats",
		"shock_name": "shock",
		"shock_def": "Shocked enemies take double damage.",
		"melee_range_name": "melee range",
		"melee_range_def": "The distance at which close-range bonuses take effect.",
		"invuln_name": "invulnerable",
		"invuln_def": "You take no damage.",
		"lightning_strike_name": "lightning strike",
		"lightning_strike_def": "Lightning crashes down from above, damaging all enemies in close range.",
		"autofire_name": "autofire",
		"autofire_def": "When you fire your primary weapon, all projectiles fire",
		"critchance_name": "critical hit chance",
		"critchance_def": "Increase the chance of inflicting extra damage on the target.",
		"crit_damage_name": "critical hit",
		"crit_damage_def": "Critical hits inflict extra damage on the target.",
		"kill_streak_name": "Killstreak",
		"kill_streak_def": "Killing enemies one after another temporarily increases the strength of related killstreak upgrades.",
		"lunar_aspect_name": "Lunar Aspect",
		"lunar_aspect_def": "Solara embraces the power of the moon, gaining movement speed and increased attack size, but reducing XP pickup value.",
		"solar_aspect_name": "Solar Aspect",
		"solar_aspect_def": "Solara embraces the power of the sun, gaining increased attack power for all weapons, but taking double damage from enemies.",
		"solar_flare_name": "Solar Flare",
		"solar_flare_def": "A fiery attack that instantly ignites all nearby enemies.",
		"lunar_crush_name": "Lunar Crush",
		"lunar_crush_def": "Instantly pull enemies toward the player, stunning them and giving the player temporary invulnerability",
		"moon_soul_name": "Moon Soul",
		"moon_soul_def": "A globe of concentrated moonlight dropped by fallen enemies. A Moon Soul deals a portion of the fallen enemy's health as damage.",
		"sun_soul_name": "Sun Soul",
		"sun_soul_def": "A globe of concentrated sunlight dropped by fallen enemies. Collecting a Sun Soul makes your ignite attacks burn more fiercely."
	},
	"account_linking": {
		"header_link_account": "Link Account",
		"button_link": "Link",
		"button_link_code": "Link with Code",
		"button_link_generate": "Generate Link Code",
		"header_main": "Link Diebrary Account?",
		"content_main": "We recommend linking accounts immediately as <span style=\"color: #0DD14D;\">all progress made with your Steam account will be lost upon linking.</span> You can also access this option in settings if you choose to link your account at a later date.",
		"button_back": "No Thanks",
		"button_continue": "Continue"
	}
}