import { Player } from "../../../entities/player"
import EntityStatList from "../../../stats/entity-stat-list"
import { PrimaryWeapon } from "../../primary-weapon"
import { WEAPON_STATS } from '../../weapon-definitions'
import { AllWeaponTypes } from "../../weapon-types"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { StatConverter, StatOperator, StatType, StatUnit } from "../../../stats/stat-interfaces-enums"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { PlayerProjectile } from "../../../projectiles/projectile"
import { TrajectoryModPreset, TrajectoryModPresets } from "../../../projectiles/trajectory-presets"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { Projectile } from "../../../projectiles/projectile-types"

export const STARTING_OUTBACK_ORBIT_DURATION = 3
export const OUTBACK_ORBIT_DAMAGE_SCALE = 0.75 // while orbiting apply this as a scalar to damage
export const OUTBACK_ORBIT_RADIUS_UPGRADE_AMOUNT = 1.66666 // 1.66666x speed while orbiting = 1.66666x radius

const OUTBACK_ORBIT_SIZE_CHECK_UPDATE_COUNTER = 10

export class Boomerang extends PrimaryWeapon {
	weaponType: AllWeaponTypes = AllWeaponTypes.Boomerang

	projectileEffectType: ParticleEffectType = ParticleEffectType.PROJECTILE_BOOMERANG
	projectileTrailType: ParticleEffectType = ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL

	increaseOrbitSpeed: boolean = false // outback 'angular momentum' upgrade
	countToAmmoConverter: StatConverter = null

	// change the firing direction every other projectile
	trajectoryParity: boolean

	blockOrbitBoomerang: boolean = false
	orbitBoomerangQueued: boolean = false
	hasOrbitRang: boolean = false
	orbitRangs: PlayerProjectile[]

	private updateCounter: number = 0
	private oldOrbitSize: number = 0

	init(player: Player, playerStatList: EntityStatList) {
		player.binaryFlags.add('recover-ammo-when-primary-projectiles-are-deleted')
		this.trajectoryParity = false

		const weaponStatList = this.statList

		// boomerang gets partial value from projectile speed, to keep the trajectory on-screen even at higher values; it still feels impactful relative to other weapons
		weaponStatList.addStatBonus(StatType.projectileSpeed, StatOperator.SUM, weaponStatList._actualStatValues.projectileSpeed * 0.5)
		weaponStatList.addStatBonus(StatType.projectileSpeed, StatOperator.MULTIPLY, -0.333333)
		// boomerangs can't use multiple projectiles

		// weaponStatList.addClamp({
		// 	statType: StatType.projectileCount,
		// 	clampMax: 1,
		// })
		// we clamp pierce to give them infinite pierce *after* converters that affect pierce
		weaponStatList.addClamp({
			statType: StatType.attackPierceCount,
			clampMin: 999,
		})

		weaponStatList.addConverter({
			inputStatType: StatType.attackSize,
			inputStatUnit: StatUnit.Percentage,
			inputMinReserve: 1,
			inputRatio: 1,
			inputFreeRatio: 0,
			outputStatType: StatType.projectileSpreadAngle,
			outputStatUnit: StatUnit.Number,
			outputRatio: 40,
			outputStatOperator: StatOperator.SUM
		})

		this.addCountToAmmoConverter()
	}

	addCountToAmmoConverter() {
		if (this.countToAmmoConverter) {
			this.removeCountToAmmoConverter()
		}
		this.countToAmmoConverter = {
			inputStatType: StatType.projectileCount,
			inputStatUnit: StatUnit.Number,
			inputMinReserve: 1,
			inputRatio: 1,
			inputFreeRatio: 0,
			outputStatType: StatType.maxAmmo,
			outputStatUnit: StatUnit.Number,
			outputRatio: 1,
			outputStatOperator: StatOperator.SUM
		}
		this.statList.addConverter(this.countToAmmoConverter)
	}

	removeCountToAmmoConverter() {
		if (this.countToAmmoConverter) {
			this.statList.removeConverter(this.countToAmmoConverter)
		}
		this.countToAmmoConverter = null
	}

	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		for (const stat of Object.keys(WEAPON_STATS.boomerang.stats)) {
			statList._actualStatValues[stat] = WEAPON_STATS.boomerang.stats[stat]
		}

		statList._actualStatValues.skillDuration = STARTING_OUTBACK_ORBIT_DURATION
		statList._actualStatValues.projectileLifeSpan = 999_999_999 //for outback orbiting final form upgrade
		
		statList._actualStatValues.projectileChainDistanceMultiplier = 1
		statList._actualStatValues.projectileSplitCount = 0
	}

	startOrbitBoomerang() {
		if (this.blockOrbitBoomerang) {
			this.orbitBoomerangQueued = true
			return
		}

		this.hasOrbitRang = true
		this.oldOrbitSize = this.statList.getStat(StatType.attackSize)

		const params = {
			owningEntityId: this.player.nid,
			position: this.player.position,
			speed: this.statList.getStat(StatType.projectileSpeed),
			aimAngleInRads: 0,
			trajectoryMods: [TrajectoryModPresets.get(TrajectoryModPreset.Orbit)],
			radius: this.oldOrbitSize / 2,
			collisionLayer: CollisionLayerBits.HitEnemyOnly,
			statList: this.statList,
			isPrimaryWeaponProjectile: true,
			resourceType: this.resourceType,
			energy: this.player.currentEnergy,
			effectType: this.projectileEffectType,
			trailType: this.projectileTrailType,
			player: this.player,
			weaponType: this.weaponType,
			isSplit: true, //aka, don't split
			noChaining: true
		}

		this.orbitRangs = [
			PlayerProjectile.objectPool.alloc(params),
		]

		params.aimAngleInRads = Math.PI
		this.orbitRangs.push(PlayerProjectile.objectPool.alloc(params))

		this.orbitRangs[0].maxPierceCount = Number.MAX_SAFE_INTEGER
		this.orbitRangs[1].maxPierceCount = Number.MAX_SAFE_INTEGER
	}

	stopOrbitBoomerang() {
		this.hasOrbitRang = false
		this.orbitRangs[0].toBeDeleted = true
		this.orbitRangs[1].toBeDeleted = true
		this.orbitRangs.length = 0
	}

	update(delta: number) {
		if (this.hasOrbitRang && ++this.updateCounter % OUTBACK_ORBIT_SIZE_CHECK_UPDATE_COUNTER) {
			const attackSize = this.statList.getStat(StatType.attackSize)
			if (attackSize !== this.oldOrbitSize) {
				this.oldOrbitSize = attackSize
				this.orbitRangs[0].setRadius(attackSize / 2)
				this.orbitRangs[1].setRadius(attackSize / 2)
			}
		}
	}
}
