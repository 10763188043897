<template>
	<div class="progress-bar-wrapper">
		<div id="inner-bar" class="inner-bar">
			<div class="progress-bar-inner" :style="{ width: Math.clamp((value / maxValue) * 100, 0, 100) + '%', left: barPosition + 'px' }"></div>
			<div class="swipe-inner"></div>
		</div>
		<div class="inner-text-wrapper">
			<div :lang="this.$i18n.locale" class="text">{{ title }} {{ subtitle }}</div>
		</div>
		<div class="stars-container">
			<div class="star-image star-1"></div>
			<div class="star-image star-2"></div>
			<div class="star-image star-3"></div>
			<div class="star-image star-4"></div>
		</div>
	</div>
</template>

<script lang="ts">
// Put CSS classes outside this component to customize its appearance
// small, medium, large, huge
// green, red, cream

// PROP-ONLY COMPONENT!
// This component is not intended to pluck state directly from Vuex, unless it is related to
// screen size, layout, rendering, or other device-specific/responsive concerns.

export default {
	name: 'ThemedProgressBar',
	components: {},
	data() {
		return {
			barPosition: -50,
		}
	},
	watch: {
		value(newValue, oldValue) {
			this.innerBarStartingPosition(newValue)
		},
	},
	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			required: true,
		},
		maxValue: {
			type: Number,
			required: false,
			default: 1,
		},
		value: {
			type: Number,
			required: true,
			default: 0,
		},
	},
	computed: {},
	methods: {
		innerBarStartingPosition(newValue) {
			if (newValue > 0) {
				this.barPosition = 0
			} else {
				this.barPosition = -50
			}
		},
	},
	mounted() {
		this.innerBarStartingPosition(this.value)
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.progress-bar-wrapper {
	position: relative;
	background-color: @darkBlue;
	width: 100%;

	&.pulsate {
		animation: pulsate-drop-shadow 3s linear 0s infinite alternate;
	}
	&.glow{
		filter: drop-shadow(0px 0px 10px @skyBlue);
	}

	&.huge {
		height: 60px;
		border-radius: 4px;
		.inner-bar,
		.inner-text-wrapper {
			height: 60px;
			width: calc(100% - 10px);
			margin: 0px 5px;
			.progress-bar-inner {
				height: 50px;
			}
			.swipe-inner {
				width: 446px;
				height: 50px;
			}
		}

		.inner-text-wrapper {
			.text {
				&[lang='en'] {
					font-size: 40px;
				}
			}
		}
	}

	&.large {
		height: 40px;
		border-radius: 4px;
		.inner-bar,
		.inner-text-wrapper {
			height: 40px;
			width: calc(100% - 8px);
			margin: 0px 4px;
			.progress-bar-inner {
				height: 32px;
			}

			.swipe-inner {
				width: 286px;
				height: 32px;
			}
		}

		.inner-text-wrapper {
			.text {
				&[lang='en'] {
					font-size: 32px;
				}
			}
		}
	}

	&.medium {
		height: 30px;
		border-radius: 4px;
		.inner-bar,
		.inner-text-wrapper {
			height: 30px;
			width: calc(100% - 8px);
			margin: 0px 4px;
			.progress-bar-inner {
				height: 22px;
			}
			.swipe-inner {
				width: 196px;
				height: 22px;
			}
		}

		.inner-text-wrapper {
			.text {
				&[lang='en'] {
					font-size: 22px;
				}
			}
		}
	}

	&.small {
		height: 20px;
		border-radius: 3px;
		.inner-bar,
		.inner-text-wrapper {
			height: 20px;
			width: calc(100% - 6px);
			margin: 0px 3px;
			.progress-bar-inner {
				height: 14px;
			}
			.swipe-inner {
				width: 125px;
				height: 14px;
			}
		}

		.inner-text-wrapper {
			.text {
				display: none;
			}
		}
	}

	&.extra-small{
		height: 12px;
		border-radius: 2px;
		.inner-bar,
		.inner-text-wrapper {
			height: 12px;
			width: calc(100% - 6px);
			margin: 0px 3px;
			.progress-bar-inner {
				height: 9px;
			}
			.swipe-inner {
				width: 125px;
				height: 14px;
			}
		}

		.inner-text-wrapper {
			.text {
				display: none;
			}
		}
	}

	&.green {
		.inner-bar,
		.inner-text-wrapper {
			.progress-bar-inner {
				background-color: @green;
				&::after {
					border-color: transparent transparent transparent @green;
				}
			}
		}
	}

	&.red {
		.inner-bar,
		.inner-text-wrapper {
			.progress-bar-inner {
				background-color: @red;
				&::after {
					border-color: transparent transparent transparent @red;
				}
			}
		}
	}

	&.cream {
		.inner-bar,
		.inner-text-wrapper {
			.progress-bar-inner {
				background-color: @cream;
				&::after {
					border-color: transparent transparent transparent @cream;
				}
			}
		}
	}
	&.swipe {
		.swipe-inner {
			visibility: visible !important;
		}
	}
	&.twinkle {
		.stars-container {
			visibility: visible;
		}
	}

	.inner-bar,
	.inner-text-wrapper {
		position: absolute;
		display: flex;
		align-items: center;
		overflow: hidden;

		.progress-bar-inner {
			transition: all 75ms ease-in;
			position: relative;
			width: 0%;
			margin: 0px;
			&::after {
				content: ' ';
				position: absolute;
				border-style: solid;
			}
		}
		.swipe-inner {
			position: absolute;
			background-image: url('../../../../assets/ui/progress-bar/progress-bar-shine.png');
			background-size: cover;
			visibility: hidden;
			animation: swipe 4.5s infinite;
		}
	}

	.inner-text-wrapper {
		justify-content: center;
		.text {
			color: @offWhite;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
	}

	.stars-container {
		visibility: hidden;
		.star-image {
			background-image: url('../../../../assets/ui/progress-bar/sparkle-large.png');
			width: 19px;
			height: 29px;
			position: absolute;
		}
		.star-1 {
			animation: twinkle-large 1s infinite;
			right: 200px;
			top: -8px;
		}
		.star-2 {
			animation: twinkle-large 1s infinite;
			animation-delay: -1.6s;
			right: 125px;
			bottom: -5px;
		}
		.star-3 {
			animation: twinkle-large 1s infinite;
			animation-delay: -1.3s;
			right: 50px;
			top: -10px;
		}
		.star-4 {
			animation: twinkle-small 1s infinite;
			animation-delay: -1.5s;
			right: 80px;
			bottom: 10px;
		}
	}
}

@keyframes swipe {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(200%);
	}
}

@keyframes twinkle-large {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes twinkle-small {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(0.8);
	}
}

@keyframes pulsate-drop-shadow {
	0% {
		filter: drop-shadow(0px 0px 5px black);
	}
	50% {
		filter: drop-shadow(0px 0px 10px rgb(185, 185, 185));
	}
	100% {
		filter: drop-shadow(0px 0px 5px black);
	}
}
</style>
