import { Vector } from "sat"
import { ComponentOwner } from "../engine/component-owner"
import { GameState, getNID } from "../engine/game-state"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { EntityType, IEntity } from "./entity-interfaces"
import { InstancedSpriteSheetAnimator } from "../engine/graphics/instanced-spritesheet-animator"
import { AssetManager } from "../web/asset-manager"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { splayGroundPickupsInRadius } from "./pickups/ground-pickup"
import { InstancedSprite } from "../engine/graphics/instanced-sprite"
import { Renderer } from "../engine/graphics/renderer"
import { GroundPickupConfigType } from "./pickups/ground-pickup-types"

export interface RadarSupplyDropParams {
    landingPosition: Vector
    dropItems: GroundPickupConfigType[]
}

const LANDING_Y_OFFSET = -500
const LANDING_TIME = 4
const OPENING_TIME = 0

const FADE_IN_TIME = 1

const ITEM_FORCE_MIN = 800
const ITEM_FORCE_MAX = 1_200

const LANDING_POSITION_OFFSET = new Vector(85, 0) // offset added to make the sprite stay in one place when switching animations
const SHADOW_OFFSET = new Vector(10, -25)

const SHADOW_FADE_OUT_TIME = 0.2
const SHADOW_FADE_OUT_START = 0.65

const SPLAY_DELAY = 0.55

const SKY_Z_INDEX_OFFSET = 500

export class RadarSupplyDrop implements ComponentOwner, IEntity, PoolableObject {
    static pool: ObjectPoolTyped<RadarSupplyDrop, RadarSupplyDropParams>

    entityType: EntityType = EntityType.Prop //kinda true
    nid: number
	timeScale: number = 1


    position: Vector
    startYPosition: number

    landingPosition: Vector
    dropItems: GroundPickupConfigType[]

    sprite: InstancedSpriteSheetAnimator
    shadow: InstancedSprite

    private _splayedItems: boolean

    private _isLanding: boolean
    private _timeInState: number
    private _boundOnCrateOpened: () => void

    constructor() {
        this.nid = getNID(this)
        this.position = new Vector()
        this.landingPosition = new Vector()

        this._makeGfx()
        this._boundOnCrateOpened = this._onCrateOpened.bind(this)
    }

    setDefaultValues(defaultValues: any, overrideValues?: RadarSupplyDropParams) {
        if (overrideValues) {
            this.landingPosition.copy(overrideValues.landingPosition)
            this.dropItems = overrideValues.dropItems
            
            this.position.copy(overrideValues.landingPosition)
            this.position.y += LANDING_Y_OFFSET
            this.startYPosition = this.position.y

            this._splayedItems = false
            this._isLanding = true
            this._timeInState = 0

            GameState.addEntity(this)
            this.sprite.addToScene()

            this.sprite.playAnimation('supply-crate-landing')
            this.sprite.alpha = 0

            this.shadow.setAlpha(0)
            this.shadow.x = overrideValues.landingPosition.x + SHADOW_OFFSET.x
            this.shadow.y = overrideValues.landingPosition.y + SHADOW_OFFSET.y
            Renderer.getInstance().mgRenderer.addPropToScene(this.shadow)
        }
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds) {
        if (this._isLanding) {
            if (this._timeInState >= LANDING_TIME) {
                this.position.copy(this.landingPosition)
                this.position.add(LANDING_POSITION_OFFSET)

                this.sprite.playAnimation('supply-crate-opening', undefined, this._boundOnCrateOpened)
                this.sprite.alpha = 1
                this.sprite.zIndex = this.position.y
                
                this.shadow.setAlpha(1)

                this._isLanding = false
                this._timeInState = 0 
            } else {
                const percentLanded = this._timeInState / LANDING_TIME
                this.position.y = this.startYPosition + (percentLanded * -LANDING_Y_OFFSET)
                this.sprite.zIndex = this.position.y + ((1-percentLanded) * SKY_Z_INDEX_OFFSET)

                const percentFaded = Math.clamp(this._timeInState / FADE_IN_TIME, 0, 1)
                this.sprite.alpha = percentFaded
                this.shadow.setAlpha(percentFaded)
            }

            this.sprite.position.copy(this.position)
        } else {
            if (!this._splayedItems && this._timeInState >= SPLAY_DELAY) {
                splayGroundPickupsInRadius(this.dropItems, this.landingPosition.x, this.landingPosition.y, ITEM_FORCE_MIN, ITEM_FORCE_MAX, OPENING_TIME)
                this._splayedItems = true
            }

            if (this._timeInState >= SHADOW_FADE_OUT_START) {
                const fadePercent = Math.clamp(1 - ((this._timeInState - SHADOW_FADE_OUT_START) / SHADOW_FADE_OUT_TIME), 0, 1)
                this.shadow.setAlpha(fadePercent)
            }
        }

        this._timeInState += delta
    }

    cleanup() {
        GameState.removeEntity(this)
        this.sprite.removeFromScene()
        Renderer.getInstance().mgRenderer.removeFromScene(this.shadow)
    }

    private _onCrateOpened() {
        RadarSupplyDrop.pool.free(this)
    }

    private _makeGfx() {
        const spriteSheet = AssetManager.getInstance().getAssetByName('supply-crate').spritesheet
        this.sprite = new InstancedSpriteSheetAnimator(spriteSheet, 'supply-crate-landing')

        const shadow = AssetManager.getInstance().getAssetByName('supply-crate-shadow').texture
        this.shadow = new InstancedSprite(shadow, 0, 0, -555_555)
    }
}