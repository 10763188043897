import { Vector } from "sat"
import { Pet, PetCollectionName } from "./pet"
import { RotSonPet } from "./rot-son-pet"
import { PouncingPet } from "./pouncing-pet"
import { StompingPet } from "./stomping-pet"
import { HealingPet } from "./healing-pet"
import { CollectorPet } from "./collector-pet"
import EntityStatList from "../../stats/entity-stat-list"
import { RainbowPet } from "./rainbow-pet"
import { TsunamiPet } from "./tsunami-pet"

export function createNewPet(name: PetCollectionName, position: Vector, statList?: EntityStatList, uncaged?: boolean): Pet {
    switch (name) {
        case 'pet-rot-son':
            return new RotSonPet(name, statList, position, uncaged)
        case 'pet-cat':
            return new PouncingPet(name, statList, position, uncaged)
        case 'pet-horse':
            return new StompingPet(name, statList, position, uncaged)
        case 'pet-plant':
            return new HealingPet(name, statList, position, uncaged)
        case 'pet-crystal':
            return new CollectorPet(name, statList, position, uncaged)
        case 'pet-charlotte':
            return new RainbowPet(name, statList, position, uncaged)
        case 'pet-painterly':
            return new TsunamiPet(name, statList, position, uncaged)
    }
}