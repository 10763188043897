import { SpineDataName } from '../../spine-config/spine-config'
import { debugConfig } from '../../utils/debug-config'
import { ObjectPool, PoolableObject } from '../../utils/third-party/object-pool'
import { doNotPool, spinePoolConfig } from '../../utils/third-party/pool-config'
import { realTimeHighResolutionTimestamp, InGameTime } from '../../utils/time'
import { AssetManager } from '../../web/asset-manager'
import logger from '../../utils/client-logger'
import { forIn } from 'lodash'
import * as PIXI from 'pixi.js'
globalThis.PIXI = PIXI
require('pixi-spine')

type PoolKey = string //key format: "${asset},${skin}"

const PROP_POOL_SIZE = 1
const ITEM_POOL_SIZE = 4
const MODEL_POOL_SIZE = 8
const DEFAULT_SKIN_NAME = 'default'

// TODO2: spinePoolConfig has a similar list, but only has the entries I encounted while running through the game world a few times
//  this list has *all* props (some of which might not actually show up in game). We could combine these lists and be slightly more
//  efficient if we didn't pool things we never view.
const propPools = [
]

const itemIconPools = [
	'pickup-icons,health-drop-large',
	'pickup-icons,health-drop',
	'pickup-icons,lucky-shard', //TODO: temporarily used for exp pickup
	'pickup-icons,legendary-trinket', //TODO: temporarily used for nuke pickup
	'pickup-icons,epic-arcane', //TODO: temporarily used for vacuum pickup
	'pickup-icons,rarityshard-epic',
	'pickup-icons,rarityshard-legendary',
	'pickup-icons,rarityshard-astronomical',
]

export class RiggedSpineModel extends PIXI.spine.Spine implements PoolableObject {
	static pools: Map<PoolKey, ObjectPool> = null

	static allocFromPool(assetName: string, skin: string) {
		if (debugConfig.pooling.disableSpinePools) {
			const asset = AssetManager.getInstance().getAssetByName(assetName)
			const model = new RiggedSpineModel(asset.spineData)
			model.skeleton.setSkinByName(skin)
			model.skeleton.setToSetupPose()
			return model
		}

		if (!RiggedSpineModel.pools) {
			RiggedSpineModel.pools = initPools()
		}

		// see if there's a pool available for this model, and return a model from there if so
		const key: PoolKey = assetName + ',' + skin
		const pool = RiggedSpineModel.pools.get(key)
		if (!pool) {
			const asset = AssetManager.getInstance().getAssetByName(assetName)
			//logger.error(`no pool for key ${key}, add to list`)
			const model = new RiggedSpineModel(asset.spineData)
			model.skeleton.setSkinByName(skin)
			model.skeleton.setToSetupPose()
			return model
		}

		const pooledModel = pool.alloc() as RiggedSpineModel

		return pooledModel
	}
	entity: any

	poolKey: PoolKey

	constructor(spriteData: PIXI.spine.core.SkeletonData) {
		super(spriteData)

		this.skeleton.setToSetupPose()
		this.update(0)
		this.autoUpdate = false

		this.state.addListener({
			event(entry: PIXI.spine.core.TrackEntry, event: PIXI.spine.core.Event) {
				if (event.data.audioPath) {
					// Audio.getInstance().playSfx(event.data.audioPath)
				}
			},
		})

		// if (clientConfig.renderSpineDebugVisuals) {
		// 	this.enableSpineDebug()
		// }
	}

	cleanup() {
		// clear out old animation poses. If we don't do this then some of the death animation is still being applied to the pose
		for (let i = 0; i < this.state.tracks.length; i++) {
			this.state.setEmptyAnimation(i, 0)
		}
		// NOTE: I want to call model.state.clearTracks here, however this stops the empty anim from being applied above
		//  Upshot is recycled dead enemies have 6 tracks upon spawn (instead of usual 3)

		this.x = 0
		this.y = 0

		this.scale.x = 1
		this.scale.y = 1

		this.skeleton.scaleX = 1

		this.visible = true
		this.alpha = 1
	}

	static create(spriteData: PIXI.spine.core.SkeletonData) {
		return new RiggedSpineModel(spriteData)
	}

	returnToPoolIfPooled() {
		if (!RiggedSpineModel.pools) {
			return
		}
		const pool = RiggedSpineModel.pools.get(this.poolKey)
		if (pool) {
			pool.free(this)
		}
	}

	setDefaultValues(defaultValues: any, overrideValues?: any) { }

	attachSpineSprite(slotName: string, sprite: RiggedSpineModel) {
		sprite.scale.set(1, -1)
		super.attachSpineSprite(slotName, sprite)
	}

	detachSpineSprite(slotName: string) {
		super.detachSpineSprite(slotName)
	}

	private enableSpineDebug() {
		const that = this as any
		that.drawDebug = true
		that.drawBones = true
		that.drawRegionAttachments = false
		that.drawClipping = false
		that.drawMeshHull = false
		that.drawMeshTriangles = false
		that.drawPaths = false
		that.drawBoundingBoxes = false
	}
}

function initPools(): Map<PoolKey, ObjectPool> {
	const start = InGameTime.highResolutionTimestamp()

	const pools: Map<PoolKey, ObjectPool> = new Map()

	propPools.forEach((assetName) => {
		const key = `${assetName},${DEFAULT_SKIN_NAME}`
		let poolSize = PROP_POOL_SIZE
		if (spinePoolConfig[key] !== undefined) {
			poolSize = spinePoolConfig[key]
		}
		pools.set(key, createPool(key, poolSize))
	})

	itemIconPools.forEach((key) => {
		let poolSize = ITEM_POOL_SIZE
		if (spinePoolConfig[key] !== undefined) {
			poolSize = spinePoolConfig[key]
		}
		pools.set(key, createPool(key, poolSize))
	})

	for (const value in SpineDataName) {
		if (Object.prototype.hasOwnProperty.call(SpineDataName, value)) {
			const element = SpineDataName[value]
			const key = `${element},${DEFAULT_SKIN_NAME}`

			if (doNotPool.includes(key)) {
				continue
			}

			try {
				let poolSize = MODEL_POOL_SIZE
				if (spinePoolConfig[key] !== undefined) {
					poolSize = spinePoolConfig[key]
				} else if (debugConfig.pooling.debug) {
					console.warn(`no pool config for ${key}, using default of ${MODEL_POOL_SIZE}`)
				}
				pools.set(key, createPool(key, poolSize))
			} catch {
				logger.error(`error creating pool for:${key}`)
			}
		}
	}

	//debugPrintAnimatedProps()
	const secondsTaken = (InGameTime.highResolutionTimestamp() - start) * 0.001
	logger.debug(`Initializing ${pools.size} pools took ${secondsTaken.toFixed(3)} seconds`)

	return pools
}

function createPool(poolKey: PoolKey, poolSize: number) {
	if (debugConfig.pooling.debug) {
		console.log(`creating pool:${poolKey} size:${poolSize}`)
	}

	const words = poolKey.split(',')
	const asset = words[0]
	const skin = words[1]
	const modelAsset = AssetManager.getInstance().getAssetByName(asset)
	const pool = new ObjectPool(
		() => {
			const model = RiggedSpineModel.create(modelAsset.spineData)
			model.skeleton.setSkinByName(skin)
			model.skeleton.setToSetupPose()
			model.poolKey = poolKey
			return model
		},
		undefined,
		poolSize,
		1,
		`${poolKey}`,
	)
	return pool
}

// export function debugPrintAnimatedProps() {
// 	forIn(propConfigs, (biome, biomeId) => {
// 		forIn(biome, (propConfig, propName) => {
// 			const propId = `${biomeId}/${propName}`
// 			const asset = getSpineAsset(propId)
// 			if (asset) {
// 				const spineData = asset.spineData as PIXI.spine.core.SkeletonData

// 				const animCount = spineData.animations.length

// 				if (animCount > 0 || propConfig.rigged) {
// 					console.log(`'${propId}',`)
// 				}
// 			} else {
// 				//console.error(`no asset for ${propId}`)
// 			}
// 		})
// 	})
// }
