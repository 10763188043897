import { Response, Vector } from "sat"
import { EntityType } from "../../entities/entity-interfaces"
import { distanceSquaredVV, distanceVV, VectorXY } from "../../utils/math"
import { ColliderComponent } from "./collider-component"
import { updateColliderPositions } from "./colliders"
import { CollisionLayerBits } from "./collision-layers"
import { testCollidersColliders } from "./collision-routines"
import SpatialGrid from "./spatial-grid"

const DISTANCE_PER_ITERATION = 35
const COLLISION_SYSTEM_DELTA_TIME = 0.02

export default class CollisionSystem {
    static getInstance() {
        return CollisionSystem.instance
    }

    private static instance: CollisionSystem

    colliderGrid: SpatialGrid

    reusableSATCollisionResponseObject: Response

    accumulatedDeltaTime: number = 0

    constructor() {
        CollisionSystem.instance = this

        this.colliderGrid = new SpatialGrid()

        this.reusableSATCollisionResponseObject = new Response()
    }

    addCollidable(entity: ColliderComponent) {
        if (!entity.isInScene) {
            this.colliderGrid.addEntity(entity)

            entity.previousPosition.x = entity.position.x
            entity.previousPosition.y = entity.position.y

            entity.forceDirty = true
            entity.isInScene = true
        }
    }

    removeCollidable(entity: ColliderComponent) {
        if (entity.isInScene) {
            this.colliderGrid.removeEntity(entity)
            entity.isInScene = false
        }
    }

    getEntitiesInArea(position: VectorXY, radius: number, collidesWithLayer: CollisionLayerBits) {
        return this.colliderGrid.findNearToPos(position, radius, (entity, foundAlready) => {
            return !Boolean(entity.collidesVsLayers & collidesWithLayer) || !entity.isColliderActive || (foundAlready.findIndex((o) => o.owner.nid === entity.owner.nid) > -1)
        })
    }

    reinsertEntity(entity: ColliderComponent) {
        if (!entity.isInScene) {
            return
        }
        
        this.colliderGrid.removeEntity(entity)

        entity.previousPosition.x = entity.position.x
        entity.previousPosition.y = entity.position.y

        entity.forceDirty = true

        this.colliderGrid.addEntity(entity)
    }

	knockbackAOEfromPoint(pos: Vector, radius: number, force: number) {
		const enemies = this.getEntitiesInArea(pos, radius, CollisionLayerBits.HitEnemyOnly)
		const reuseVector = new Vector()
		for (let i = 0; i < enemies.length; ++i) {
			const enemy = enemies[i].owner

			reuseVector.copy(enemy.position)
			reuseVector.sub(pos)
			reuseVector.normalize()
			reuseVector.scale(force, force)

			enemy.addKnockBack(reuseVector)
		}
	}

    update(delta: number) {
        this.accumulatedDeltaTime += delta

        if (this.accumulatedDeltaTime >= COLLISION_SYSTEM_DELTA_TIME) {
            this.accumulatedDeltaTime = 0

            this.colliderGrid.update(COLLISION_SYSTEM_DELTA_TIME)

            this.colliderGrid.entities.forEach((entity) => {
                if (!entity.isStatic || entity.forceDirty) {
                    let breakingOut = false
                    if (entity.isColliderActive) {
                        // @TODO it would be great if we just had like a 'velocity' or 'awake' property
                        // instead of having to compute this each frame
                        const distanceThisFrame = distanceVV(entity.previousPosition, entity.position)

                        const targetPosition = entity.position.clone()

                        if (distanceThisFrame > 0 || entity.forceDirty || entity.collidedLastFrame) {
                            const iterations = (entity.forceDirty || entity.collidedLastFrame) ? 1 : Math.ceil(distanceThisFrame / DISTANCE_PER_ITERATION)
                            entity.forceDirty = false

                            let collision = false
                            for (let i = 0; i < iterations && !collision; ++i) {
                                const t = (i + 1) / iterations
                                entity.position.x = Math.lerp(entity.previousPosition.x, targetPosition.x, t)
                                entity.position.y = Math.lerp(entity.previousPosition.y, targetPosition.y, t)
                                updateColliderPositions(entity.colliderConfigs, entity.colliders, entity.position)

                                for (let ec = 0; ec < entity.cells.length; ++ec) {
                                    const cell = entity.cells[ec]
                                    for (let c = 0; c < cell.length; ++c) {
                                        const otherEntity = cell[c]
                                        if (entity.id !== otherEntity.id && otherEntity.isColliderActive && entity.isColliderActive) {
                                            // bitwise intentional
                                            if (entity.collidesVsLayers & otherEntity.layer) {
                                                if (entity.isKinematic || entity.isTrigger || otherEntity.isTrigger) {
                                                    
                                                    const collided = testCollidersColliders(entity.colliders, otherEntity.colliders, this.reusableSATCollisionResponseObject)
                                                    if (collided) {
                                                        const overlapV = this.reusableSATCollisionResponseObject.overlapV

                                                        // @TODO do something to not call this multiple times when we CCD into the same object
                                                        entity.onCollision(otherEntity, overlapV.x, overlapV.y)
                                                        otherEntity.onCollision(entity, overlapV.x, overlapV.y)

                                                        if (!entity.isColliderActive || !entity.isInScene) {
                                                            breakingOut = true
                                                            break
                                                        }
                                                    }
                                                } else {
                                                    // @TODO cakes; do we need to use the more complex version?  
                                                    // const offset = resolveCollidersColliders(entity.colliders, otherEntity.colliders)
                                                    // if(offset.len2() > 1) {
                                                    //     entity.position.add(offset)                                                    

                                                    //     collision = true

                                                    //     entity.onCollision(otherEntity)
                                                    //     otherEntity.onCollision(entity)
                                                    // }
                                                    const collided = testCollidersColliders(entity.colliders, otherEntity.colliders, this.reusableSATCollisionResponseObject)
                                                    if (collided) {
                                                        const overlapV = this.reusableSATCollisionResponseObject.overlapV

                                                        collision = true

                                                        entity.onCollision(otherEntity, overlapV.x, overlapV.y)
                                                        otherEntity.onCollision(entity, overlapV.x, overlapV.y)

                                                        if (entity.isColliderActive && entity.isInScene) {
                                                            // sometimes entities will just wait for a collision, then disable their collider
                                                            // they don't want to actually move their positions that frame
                                                            entity.position.sub(overlapV)
                                                        } else {
                                                            breakingOut = true
                                                            break
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (breakingOut) {
                                        break
                                    }
                                }
                                if (breakingOut) {
                                    break
                                }
                            }
                        }

                        entity.previousPosition.copy(entity.position)
                    }
                }
            })

            this.colliderGrid.entities.forEach((entity) => {
                entity.onCollisionChecksDone()
            })
        }
    }

    cleanUp()  {
        this.colliderGrid.entities.forEach(e => {
            this.removeCollidable(e)
        })
        this.colliderGrid.entities.length = 0
        
        this.colliderGrid.cells.forEach(c => {
            c.length = 0
        })
        this.accumulatedDeltaTime = 0
    }
}

export function getClosestNotHitEntity(entities: ColliderComponent[], originPosition: VectorXY, hitEntities: number[]): ColliderComponent {
    let minDistEntity: ColliderComponent
    let minDistance: number = Number.MAX_SAFE_INTEGER

    for(let i = 0; i < entities.length; ++i) {
        const entity = entities[i]
        if(hitEntities.findIndex((e) => e === entity.id) === -1) {
            const distance = distanceSquaredVV(originPosition, entity.position)
            if(distance < minDistance) {
                minDistance = distance
                minDistEntity = entity
            }
        }
    }

    return minDistEntity
}

export function getClosestEntity(entities: ColliderComponent[], originPosition: VectorXY): ColliderComponent {
    let minDistEntity: ColliderComponent
    let minDistance: number = Number.MAX_SAFE_INTEGER

    for(let i = 0; i < entities.length; ++i) {
        const entity = entities[i]
        const distance = distanceSquaredVV(originPosition, entity.position)
        if(distance < minDistance) {
            minDistance = distance
            minDistEntity = entity
        }
    }

    return minDistEntity
}