import { SpatialGridCell, SpatialEntity } from './spatial-entity'
import { gridUnits } from '../../utils/primitive-types'
import { distanceSquaredVV, VectorXY, withinDistanceVV } from '../../utils/math'
import { ColliderComponent } from './collider-component'
import { WORLD_DATA } from '../../world-generation/world-data'
import { debugConfig } from '../../utils/debug-config'
import { Renderer } from '../graphics/renderer'

export const SPATIAL_GRID_CELL_SIZE = 512
const STARTING_GRID_DIMENSION = Math.ceil(WORLD_DATA.infiniteWorldDimension / SPATIAL_GRID_CELL_SIZE)

export class SpatialGrid {
	type: string
	cellSize: number
	cellSizeInverse: number
	name: string
	cells: SpatialGridCell[]
	gridWidth: number
	gridHeight: number

	entities: ColliderComponent[]

	constructor() {
		this.cellSize = SPATIAL_GRID_CELL_SIZE
		this.cellSizeInverse = 1 / this.cellSize
		this.gridWidth = STARTING_GRID_DIMENSION
		this.gridHeight = STARTING_GRID_DIMENSION
		this.cells = []
		this.entities = []

		for (let i = 0; i < this.gridWidth * this.gridHeight; i++) {
			this.cells[i] = []
		}
	}

	addEntity(entity: ColliderComponent) {
		let cells: SpatialGridCell[]

		if (!entity.originCell) {
			// origin cell is already set if this is a reinsert
			const currentOriginCell = this.getGridCellByWorldXY(entity.position.x, entity.position.y)

			entity.originCell = currentOriginCell
		}

		if (entity.isStatic) {
			cells = this.getCellsCoveredByEntity(entity)
		} else {
			// getCellsAroundEntity function call is incorrect, does not account for colliders that are not centered
			cells = this.getCellsCoveredByEntity(entity)//this.getCellsAroundEntity(entity.position, (entity.bounds.width * 0.5) / SPATIAL_GRID_CELL_SIZE, (entity.bounds.height * 0.5) / SPATIAL_GRID_CELL_SIZE)
		}

		entity.cells = cells
		entity.cells.forEach((c) => {
			c.push(entity)
		})

		this.entities.push(entity)
	}

	removeEntity(entity: ColliderComponent) {
		entity.cells.forEach((cell) => {
			cell.remove(entity)
		})

		this.entities.remove(entity)

		entity.cells.length = null
		entity.originCell = null 
	}

	findNearby(entity: ColliderComponent, dist: gridUnits, optionalSkipCriteriaFn?: (e: ColliderComponent) => boolean): ColliderComponent[] {
		return this.findNearbyWithPos(entity, entity.position, dist, optionalSkipCriteriaFn)
	}

	findNearbyWithPos(entity: ColliderComponent, position: VectorXY, dist: gridUnits, optionalSkipCriteriaFn?: (e: ColliderComponent) => boolean): ColliderComponent[] {
		const cellsAroundEntity = this.getCellsAroundEntity(position, dist)
		const nearby: ColliderComponent[] = []

		cellsAroundEntity.forEach((cell) => {
			cell.forEach((entityInCell) => {
				if (optionalSkipCriteriaFn) {
					if (optionalSkipCriteriaFn(entityInCell)) {
						return
					}
				}

				// don't consider yourself to be "nearby"
				if (entity.id === entityInCell.id) {
					return
				}

				nearby.push(entityInCell)
			})
		})

		return nearby
	}

	findNearToPos(position: VectorXY, worldDist: number, optionalSkipCriteriaFn?: (e: ColliderComponent, foundAlready: ColliderComponent[]) => boolean): ColliderComponent[] {
		const cellsAroundEntity = this.getCellsAroundEntity(position, Math.ceil(worldDist / SPATIAL_GRID_CELL_SIZE))
		const nearby: ColliderComponent[] = []

		const distSquared = worldDist * worldDist

		cellsAroundEntity.forEach((cell) => {
			cell.forEach((entityInCell) => {
				if (optionalSkipCriteriaFn) {
					if (optionalSkipCriteriaFn(entityInCell, nearby)) {
						return
					}
				}

				// gross, should i dig into the circle colliders or is this approximation good enough
				if (distanceSquaredVV(position, entityInCell.position) <= (distSquared + Math.min(entityInCell.bounds.width / 2, entityInCell.bounds.height / 2) ** 2)) {
					nearby.push(entityInCell)
				}
			})
		})

		return nearby
	}

	getGridCellsInRectangle(gridX: number, gridY: number, width: number, height: number) {
		const cells: SpatialGridCell[] = []
		for (let x = gridX; x < gridX + width; x++) {
			for (let y = gridY; y < gridY + height; y++) {
				cells.push(this.getGridCellByGridXY(x, y))
			}
		}
		return cells
	}

	update(delta: number) {
		let reinsertsNecessaryThisFrame = 0


		this.entities.forEach((entity) => {
			if (!entity.isStatic) {
				if (entity.previousPosition.x !== entity.position.x || entity.previousPosition.y !== entity.position.y || entity.forceDirty) {
					const currentOriginCell = this.getGridCellByWorldXY(entity.position.x, entity.position.y)
					entity.originCell = currentOriginCell
					this.reinsertEntity(entity)
					reinsertsNecessaryThisFrame++
				}
			}
		})

		if (debugConfig.collisions.drawWackGridDebug) {
			for (let x = 0; x < this.gridWidth; ++x) {
				for (let y = 0; y < this.gridHeight; ++y) {
					const cell = this.getGridCellByGridXY(x, y)
					if (cell.length > 0) {
						// draw something this frame
						const worldX = this.cellSize * x + (this.cellSize /2)
						const worldY = this.cellSize * y + (this.cellSize / 2)

						Renderer.getInstance().drawCircle({
							x: worldX,
							y: worldY,
							radius: 10,
							color: 0x000000,
							permanent: false,
							destroyAfterSeconds: 0.08,
							scale: 3
						})
					}
				}
			}
		}
	}

	private getCellsCoveredByEntity(entity: ColliderComponent): SpatialGridCell[] {
		const cells = []

		//could go through each collider here and see what cells they occupy
		//but I'm (callum) not sure how to convert ColliderWithTraits to the correct collider type
		//also that sounds like some work to write code for each collider type

		const bounds = entity.bounds
		const minXCell = Math.floor((entity.position.x + bounds.minX) * this.cellSizeInverse)
		const maxXCell = Math.floor((entity.position.x + bounds.maxX) * this.cellSizeInverse)
		const minYCell = Math.floor((entity.position.y + bounds.minY) * this.cellSizeInverse)
		const maxYCell = Math.floor((entity.position.y + bounds.maxY) * this.cellSizeInverse)

		for (let ix = minXCell; ix <= maxXCell; ix++) {
			for (let iy = minYCell; iy <= maxYCell; iy++) {
				const cell = this.getGridCellByGridXY(ix, iy)
				if (cell && !cells.includes(cell)) {
					cells.push(cell)
				}
			}
		}

		return cells
	}

	private getCellsAroundEntity(entity: VectorXY, cellRadius: number, optionalWidth?: number) {
		const cellsAroundEntityCell: SpatialGridCell[] = []
		const sizex: number = cellRadius
		const sizey: number = optionalWidth || sizex

		const entityCellX = entity.x * this.cellSizeInverse
		const entityCellY = entity.y * this.cellSizeInverse

		const startGridCellX = Math.floor(entityCellX - sizex)
		const startGridCellY = Math.floor(entityCellY - sizey)
		const endGridCellX = Math.floor(entityCellX + sizex)
		const endGridCellY = Math.floor(entityCellY + sizey)

		//const rectanglesToDraw = []

		for (let ix = startGridCellX; ix <= endGridCellX; ix++) {
			for (let iy = startGridCellY; iy <= endGridCellY; iy++) {
				const cell = this.getGridCellByGridXY(ix, iy)
				if (cell && !cellsAroundEntityCell.includes(cell)) {
					// if (global.sampleThisFrame) {
					// 	rectanglesToDraw.push({ x: ix, y: iy })
					// }
					cellsAroundEntityCell.push(cell)
				}
			}
		}

		//Uncomment to send a msg that causes grid cell checks to render every {sampleRate} frames
		//Also look at the function 'getCellsCoveredByProp' (above this) if you want more rectangles drawn
		// if (global.sampleThisFrame && entity.client) {
		// 	rectanglesToDraw.forEach((gridCoordinates) => {
		// 		const cs = this.cellSize
		// 		global.nengiInstance.message(new DrawRectangle(gridCoordinates.x * cs, gridCoordinates.y * cs, cs, cs, 0xcccccc, false, 0.1), entity.client)
		// 	})
		// }

		return cellsAroundEntityCell
	}

	private reinsertEntity(entity: ColliderComponent) {
		this.removeEntity(entity)
		this.addEntity(entity)
	}

	private getGridCellByWorldXY(x: number, y: number) {
		const gridCellX = Math.floor(x * this.cellSizeInverse)
		const gridCellY = Math.floor(y * this.cellSizeInverse)

		return this.getGridCellByGridXY(gridCellX, gridCellY)
	}

	private getGridCellByGridXY(gridX, gridY) {
		let index = gridX + this.gridWidth * gridY
		if(index < 0) {
			index = this.cells.length - index
		}
		return this.cells[index % this.cells.length]
	}
}

export default SpatialGrid
