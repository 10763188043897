import { GameState } from "../engine/game-state";
import { ISystem } from "../engine/isystem";
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types";
import { Cooldown } from "./cooldown";

export class CooldownSystem implements ISystem {
    private static instance: CooldownSystem

    static getInstance() {
        if (!this.instance) {
            this.instance = new CooldownSystem()
        }
        return this.instance
    }

    addCooldown(cooldown: Cooldown) {
        GameState.addEntity(cooldown, false)
    }

    removeCooldown(cooldown) {
        GameState.removeEntity(cooldown)
    }

    update(delta: timeInSeconds, now: timeInMilliseconds) {
        for (const cooldown of GameState.cooldownList) {
            cooldown.update(delta, now)
        }
    }
}