<template>
	<div class="square-item-container">
		<div class="items">
			<div v-for="perk in perks" :key="perk" class="item-wrapper">
				<SquareItem
					:item="perk"
					@click="selectPerk(perk)"
					:selected="isPerkSelected(perk)"
					:disabled="false"
					:faded="fadePerk(perk)"
					:small-icons="false"
					:goldtrim-enabled="false"
					:selected-colour="'#ffe35f'"
					:gold-highlight="perk.ranks > 0 || isUnlockPurchased(perk)"
					:twist-unlock="perk.twistUnlock"
				></SquareItem>
				<div class="text-container" :lang="this.$i18n.locale" v-if="perk.maxRanks">
					<div :class="{ 'highlight-text': perk.ranks > 0 }">{{ perk.ranks }} / {{ perk.maxRanks }}</div>
				</div>
				<div class="text-container" :class="getUnlockState(perk)" :lang="this.$i18n.locale" v-else>
					<div>{{ $t(unlockPurchaseState(perk)) }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { MetaUnlocksManager } from '../../../upgrades/meta/meta-unlocks-manager'
import { canBuyPerk } from '../../store/meta-progression-store'
import SquareItem from '../item/square-items.vue'

export default {
	name: 'SquarePerkContainer',
	components: {
		SquareItem,
	},
	data() {
		return {}
	},
	props: {
		perks: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState('metaProgression', ['selectedPerk']),
		...mapGetters('metaProgression', ['unlockPurchaseState', 'getUnlockState']),
		...mapState('user', ['currencies']),
	},
	methods: {
		...mapMutations('metaProgression', ['selectPerk']),
		// ...mapActions('ui', ),
		fadePerk(perk) {
			if (perk.mission) {
				return !this.isUnlockPurchased(perk)
			}
			if (this.isUnlockPurchased(perk)) {
				return false
			}
			if (perk.ranks > 0) {
				return false
			}
			return !canBuyPerk(this.currencies, perk)
		},
		isUnlockPurchased(perk) {
			if (!perk.unlockTag) {
				return false
			}
			return MetaUnlocksManager.getInstance().isUnlocked(perk.unlockTag)
		},
		isPerkSelected(perk) {
			if (this.selectedPerk) {
				return perk.name === this.selectedPerk.name
			}
			return false
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.square-item-container {
	display: flex;
	justify-content: center;
	.items {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: flex-start;
		width: 900px;
		height: 511px;
		overflow-y: auto;
		overflow-x: hidden;
		padding-top: 11px;

		.item-wrapper {
			display: flex;
			flex-direction: column;
			height: 185px;
			margin: 0px 0px;
			align-items: center;
			:deep(.square-item) {
				margin: 0 15px;
			}

			.text-container {
				padding-top: 4px;
				color: @offWhite;
				&.unlocked {
					color: @gold;
				}
				&.unlockable {
				}
				&.purchasable {
					color: @green;
				}
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-variant: small-caps;
					font-size: 32px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}

				.highlight-text {
					color: @gold;
				}
			}
		}
	}
}
</style>
