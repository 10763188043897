import { Circle, Polygon, Response, testCirclePolygon, testPolygonCircle, testPolygonPolygon, Vector } from "sat"
import { closest, distance, distanceSquaredVV, distanceVV, ellipseRadius, isBetweenInclusive, lengthXY, makeTangent, scale, sub, Vectors, VectorXY } from "../../utils/math"
import p5 from "./p5.collide2d"
import { AnyCollider, BoxCollider, Capsule, CapsuleCollider, CircleCollider, ColliderType, Ellipse, EllipseCollider, Line, PolygonCollider, SatCollider, setPolygonFromBox } from "./colliders"
import { chain, flatten, intersection, min, range, uniq } from "lodash"

const SAMPLING = false
const MAX_RESOLVE_DEPTH = 5 // how many collision resolution passes are done
const PENETRATION_EPSILON = 0.01 // how much objects can penetrate before considered a collision (ratio)
const RESPONSE_RATIO = 0.5 // how much each collision repels the object (1 being complete repulsion)
// WIP: setting this to 0 for now (it's causing beam to hit things beside player)
const BEAM_ORIGIN_OFFSET = 0
const CCD = true

// double-dispatch table of Type Vs Type
const collisionTable = createCollisionTable()
const temp_polygonCapsulePoints = [new Vector(), new Vector(), new Vector(), new Vector()]
const temp_polygonCapsule = new Polygon()

export const colliderCounts: Map<Function, number> = SAMPLING ? new Map() : null
export const PLAYER_PROJECTILE_PROP_COLLIDER_FACTOR = 0.5

function createCollisionTable() {
	/* tslint:disable */
	// disabling to remove this rule:
	//   Don't use 'Function' as a type. Avoid using the `Function` type. Prefer a specific function type, like `() => void`.
	// I don't know what the rule name is to disable and don't want to figure out with X husky passes

	type ColliderCheck = (c1: SatCollider, c2: SatCollider, r: Response) => boolean

	const circleVs: Map<ColliderType, ColliderCheck> = new Map()
	circleVs.set(ColliderType.Circle, testCircleCircle2)
	circleVs.set(ColliderType.Box, testCircleBox)
	circleVs.set(ColliderType.Ellipse, testCircleEllipse)
	circleVs.set(ColliderType.Polygon, testCirclePolygon2)

	const boxVs: Map<ColliderType, ColliderCheck> = new Map()
	boxVs.set(ColliderType.Circle, testBoxCircle)
	boxVs.set(ColliderType.Box, testBoxBox)
	boxVs.set(ColliderType.Ellipse, testBoxEllipse)
	boxVs.set(ColliderType.Polygon, testBoxPolygon)

	const ellipseVs: Map<ColliderType, ColliderCheck> = new Map()
	ellipseVs.set(ColliderType.Circle, testEllipseCircle)
	ellipseVs.set(ColliderType.Box, testEllipseBox)
	ellipseVs.set(ColliderType.Ellipse, testEllipseEllipse)
	ellipseVs.set(ColliderType.Polygon, testEllipsePolygon)

	const polygonVs: Map<ColliderType, ColliderCheck> = new Map()
	polygonVs.set(ColliderType.Circle, testPolygonCircle2)
	polygonVs.set(ColliderType.Box, testPolygonBox)
	polygonVs.set(ColliderType.Ellipse, testPolygonEllipse)
	polygonVs.set(ColliderType.Polygon, testPolygonPolygon)

	const capsuleVs: Map<ColliderType, ColliderCheck> = new Map()
	capsuleVs.set(ColliderType.Circle, testCapsuleCircle)
	capsuleVs.set(ColliderType.Box, testCapsuleBox)
	capsuleVs.set(ColliderType.Ellipse, testCapsuleEllipse)
	capsuleVs.set(ColliderType.Polygon, testCapsulePolygon)

	const collisionTable: Map<ColliderType, Map<ColliderType, ColliderCheck>> = new Map()

	collisionTable.set(ColliderType.Circle, circleVs)
	collisionTable.set(ColliderType.Box, boxVs)
	collisionTable.set(ColliderType.Ellipse, ellipseVs)
	collisionTable.set(ColliderType.Polygon, polygonVs)
	collisionTable.set(ColliderType.Capsule, capsuleVs)

	/* tslint:enable */

	return collisionTable
}

function getCollisionFunc(c1: AnyCollider, c2: AnyCollider) {
	try {
		const func = collisionTable.get(c1.type).get(c2.type)
		if (SAMPLING) {
			if (!colliderCounts.has(func)) {
				colliderCounts.set(func, 1)
			} else {
				colliderCounts.set(func, colliderCounts.get(func) + 1)
			}
		}

		if (!func) {
			console.log(c1, c2)
		}

		return func
	} catch (e) {
		console.log(`Collision lookup failed:`, c1, c2)
		console.log(e)
		throw e
	}
}

export function testCollidersColliders(collidersA: AnyCollider[], collidersB: AnyCollider[], response: Response): boolean {
	for (let i = 0; i < collidersA.length; ++i) {
		for (let j = 0; j < collidersB.length; ++j) {
			const a = collidersA[i] as any
			const b = collidersB[j] as any
			const testFunc = getCollisionFunc(a, b)
			if (testFunc(a, b, response)) {
				return true
			}
		}
	}
}

export function testColliderColliders(a: AnyCollider, collidersB: AnyCollider[], response: Response, checkDisabledColliders: boolean = false): AnyCollider {
	if (!a) {
		console.error('Passed null/undefined \'a\' in testCollider Colliders. Stack:\n' + new Error().stack)
		return undefined
	}

	if (checkDisabledColliders === false) {
		if (a.disabled) {
			return undefined
		}
	}

	for (let j = 0; j < collidersB.length; ++j) {
		const b = collidersB[j] as AnyCollider
		if (checkDisabledColliders === false) {
			if (b.disabled) {
				continue
			}
		}
		const testFunc = getCollisionFunc(a, b)
		if (testFunc(a as any, b, response)) {
			return b
		}
	}
	return undefined
}


export function testColliderCollider(a: AnyCollider, b: AnyCollider, response: Response): AnyCollider {
	if (a.disabled || b.disabled) {
		return undefined
	}

	const testFunc = getCollisionFunc(a, b)
	if (testFunc(a as any, b as any, response)) {
		return b
	}
	return undefined
}

export function resolveCollidersColliders(objectsA: AnyCollider[], objectsB: AnyCollider[]): Vector {
	const objsA = objectsA.map(cloneCollider)
	const objsB = objectsB

	// objectsA.forEach((c) => sendDrawObjectMessage(c, 0xff0000))
	// objectsB.forEach((c) => sendDrawObjectMessage(c, 0x0000ff))

	const start: Vector = objectsA[0].pos.clone()

	function resolve(depth: number): Vector {
		if (depth > MAX_RESOLVE_DEPTH) {
			return sub(start, objsA[0].pos)
		}

		const responses: Response[] = []
		for (let i = 0; i < objsA.length; ++i) {
			const objA = objsA[i]
			if (objA.disabled) {
				continue
			}
			for (let j = 0; j < objsB.length; ++j) {
				const objB = objsB[j]
				if (objB.disabled) {
					continue
				}
				const response = new Response()
				const testFunc = getCollisionFunc(objA, objB)
				if (testFunc((objA as any) as SatCollider, (objB as any) as SatCollider, response)) {
					responses.push(response)
				}
			}
		}

		if (responses.length > 0) {
			const sum = new Vector(0, 0)
			responses.forEach((r) => {
				// divide each responses overlap by the number of responses
				//  this is so when X multiple things are pushing we don't get X times the amount of pushe we should
				sum.sub(scale(r.overlapV, RESPONSE_RATIO / responses.length))
			})

			objsA.forEach((circle) => circle.pos.add(sum))

			return resolve(depth + 1)
		}
	}

	resolve(0)

	const diff = sub(objsA[0].pos, start)
	return diff
}

export function testCircleCircle2(a: Circle, b: Circle, response?: Response): boolean {
	// NOTE: I tried getting rid of this sqrt by comparing dist*dist to r*r and moving into if below
	//  but ended up being slightly slower
	const d = distance(a.pos.x, a.pos.y, b.pos.x, b.pos.y) - (a.r + b.r)

	if (d <= PENETRATION_EPSILON) {
		if (response) {
			response.overlapV.copy(a.pos)
			response.overlapV.sub(b.pos)
			response.overlapV.normalize()
			response.overlapV.scale(d, d)
		}

		return true
	}

	return false
}

export function testBoxCircle(a: BoxCollider, circle: CircleCollider, response?: Response): boolean {
	// from, 2nd answer: https://stackoverflow.com/questions/401847/circle-rectangle-collision-detection-intersection#402010

	// return testPolygonCircle(a.polygon, circle, response)

	const cp = circle.pos.clone()

	if (a.angle !== undefined) {
		// if the box is rotated, rotate circle so that it can check against unrotated box
		cp.sub(a.pos)
		cp.rotate(-a.angle)
		cp.add(a.pos)
	}

	// Find the closest point to the circle within the rectangle
	const closestPoint = new Vector()
	closestPoint.x = Math.clamp(cp.x, a.pos.x, a.pos.x + a.w)
	closestPoint.y = Math.clamp(cp.y, a.pos.y - a.h/2, a.pos.y + a.h/2)

	// Calculate the distance between the circle's center and this closest point
	const distanceX = cp.x - closestPoint.x
	const distanceY = cp.y - closestPoint.y

	// If the distance is less than the circle's radius, an intersection occurs
	const distanceSquared = distanceX * distanceX + distanceY * distanceY

	const collision = distanceSquared <= circle.r * circle.r

	if (!response) {
		return collision
	}

	if (collision) {
		const d = Math.sqrt(distanceSquared)

		const overlapV = response.overlapV

		if (distanceSquared < 0.001) {
			// handle-case where circle is inside the rectangle
			const closestX = closest(cp.x, a.pos.x, a.pos.x + a.w)
			const closestY = closest(cp.y, a.pos.y - a.h/2, a.pos.y + a.h/2)
			if (Math.abs(cp.x - closestX) < Math.abs(cp.y - closestY)) {
				if (closestX > cp.x) {
					overlapV.x = closestX + circle.r - cp.x
				} else {
					overlapV.x = closestX - circle.r - cp.x
				}
			} else {
				if (closestY > cp.y) {
					overlapV.y = closestY + circle.r - cp.y
				} else {
					overlapV.y = closestY - circle.r - cp.y
				}
			}
		} else {
			overlapV.copy(cp)
			overlapV.sub(closestPoint)
			overlapV.normalize()
			overlapV.scale(circle.r - d)
		}

		if (a.angle !== undefined) {
			overlapV.rotate(a.angle)
		}

		return true
	}

	return false
}

export function testBoxBox(a: BoxCollider, b: BoxCollider, response: Response): boolean {
	return testPolygonPolygon(a.polygon, b.polygon, response);
}

export function testCirclesCircles(circleA: CircleCollider[], circleB: CircleCollider[], response: Response): boolean {
	for (let i = 0; i < circleA.length; ++i) {
		for (let j = 0; j < circleB.length; ++j) {
			if (testCircleCircle2(circleA[i], circleB[j], response)) {
				return true
			}
		}
	}

	return false
}

export function testCirclesCircle(circleA: CircleCollider[], circleB: CircleCollider, response: Response): boolean {
	const a = circleA as CircleCollider[]
	const b = circleB as CircleCollider
	for (let i = 0; i < a.length; ++i) {
		if (testCircleCircle2(a[i], b, response)) {
			return true
		}
	}

	return false
}

export function testCircleCircles(circleA: CircleCollider, circleB: CircleCollider[], response: Response): boolean {
	for (let i = 0; i < circleB.length; ++i) {
		if (testCircleCircle2(circleA, circleB[i], response)) {
			return true
		}
	}

	return false
}


export function testCircleBox(a: CircleCollider, b: BoxCollider, response?: Response): boolean {
	const result = testBoxCircle(b, a, response)
	if (response) {
		response.overlapV.scale(-1)
	}
	return result
}

export function testEllipseEllipse(a: Ellipse, b: Ellipse, response?: Response): boolean {
	const angle = Math.atan2(b.pos.y - a.pos.y, b.pos.x - a.pos.x)
	const d = distance(a.pos.x, a.pos.y, b.pos.x, b.pos.y) - (ellipseRadius(a, angle) + ellipseRadius(b, angle))

	if (d <= 0) {
		if (response) {
			response.overlapV.copy(a.pos)
			response.overlapV.sub(b.pos)
			response.overlapV.normalize()
			response.overlapV.scale(d, d)
		}

		return true
	} else {
		return false
	}
}

export function testEllipseCircle(a: Ellipse, b: CircleCollider, response?: Response): boolean {
	const angle = Math.atan2(b.pos.y - a.pos.y, b.pos.x - a.pos.x)
	const d = distance(a.pos.x, a.pos.y, b.pos.x, b.pos.y) - (ellipseRadius(a, angle) + b.r)

	if (d <= 0) {
		if (response) {
			response.overlapV.copy(a.pos)
			response.overlapV.sub(b.pos)
			response.overlapV.normalize()
			response.overlapV.scale(d, d)
		}

		return true
	} else {
		return false
	}
}

export function testCircleEllipse(a: CircleCollider, b: Ellipse, response?: Response): boolean {
	const result = testEllipseCircle(b, a, response)
	if (response?.overlap) {
		response.overlapV.scale(-1)
	}
	return result
}

export function testCapsuleBox(capsule: CapsuleCollider, box: BoxCollider, response?: Response) {
	setPolygonFromCapsuleOBB(capsule, temp_polygonCapsule, temp_polygonCapsulePoints)

	const r = testPolygonPolygon(box.polygon, temp_polygonCapsule, response)
	return r
}

export function testCapsulePolygon(capsule: CapsuleCollider, polygon: PolygonCollider) {
	// CAVEAT!!! - TODO2
	// this will not work for small polygons in combination with massive capsules
	// if (debugConfig.log.colliderIssues) {
	// 	console.assert(capsule.r < 100, `testCapsulePolygon makes assumptions about capsules and ${capsule.r} is too large`)
	// }
	const cp1 = capsule.pos.clone()
	const cp2 = capsule.pos2.clone()
	cp1.x -= polygon.pos.x
	cp1.y -= polygon.pos.y
	cp2.x -= polygon.pos.x
	cp2.y -= polygon.pos.y

	if (p5.collideCirclePoly(cp1.x, cp1.y, capsule.r * 2, polygon.points, true)) {
		return true
	}
	if (p5.collideCirclePoly(cp2.x, cp2.y, capsule.r * 2, polygon.points, true)) {
		return true
	}

	const capDir = sub(cp2, cp1).normalize()
	makeTangent(capDir)
	capDir.scale(capsule.r)

	// instead of testing the whole capsule, test 3 lines: center/right/left (see assert above!!!)
	if (p5.collideLinePoly(cp1.x, cp1.y, cp2.x, cp2.y, polygon.points)) {
		return true
	}
	if (p5.collideLinePoly(cp1.x + capDir.x, cp1.y + capDir.y, cp2.x + capDir.x, cp2.y + capDir.y, polygon.points)) {
		return true
	}
	if (p5.collideLinePoly(cp1.x - capDir.x, cp1.y - capDir.y, cp2.x - capDir.x, cp2.y - capDir.y, polygon.points)) {
		return true
	}
}

export function testPointColliders(p: Vector, colliders: AnyCollider[]) {
	testPointColliders_point.pos = p
	return testColliderColliders(testPointColliders_point, colliders, null)
}
export function testPointCollider(p: Vector, collider: AnyCollider) {
	testPointColliders_point.pos = p
	return testColliderCollider(testPointColliders_point, collider, null)
}
export function testCircleColliders(p: Vector, r: number, colliders: AnyCollider[]) {
	testCircleColliders_circle.pos = p
	testCircleColliders_circle.r = r
	return testColliderColliders(testCircleColliders_circle, colliders, null)
}
export function testCircleCollider(p: Vector, r: number, colliders: AnyCollider) {
	testCircleColliders_circle.pos = p
	testCircleColliders_circle.r = r
	return testColliderCollider(testCircleColliders_circle, colliders, null)
}

const testPointColliders_point = new CircleCollider(new Vector(0, 0), 0)
const testCircleColliders_circle = new CircleCollider(new Vector(0, 0), 0)


export function testEllipseBox(a: EllipseCollider, b: BoxCollider, response?: Response): boolean {
	// basic plan: ellipse vs box is hard, so, transform the box so we can compare box vs ellipse
	//  we do this by using the boxes polygon and then transforming the points
	testEllipseX_circle_hack.pos = Vectors.Zero

	const boxAsPolygon = checkCreatePolygonOnBox(b)

	let xTransform = 1
	let yTransform = 1

	// Scale the larger axis to the smaller, choose the radius of the smaller
	if (a.rX > a.rY) {
		xTransform = a.rY / a.rX
		testEllipseX_circle_hack.r = a.rY
	} else {
		yTransform = a.rX / a.rY
		testEllipseX_circle_hack.r = a.rX
	}

	// backup boxes polygon properties we'll be changing
	const oldPoints = [...boxAsPolygon.points]
	const oldPos = boxAsPolygon.pos
	const oldAngle = boxAsPolygon.angle

	const points = boxAsPolygon.calcPoints.map(
		(p) =>
			p
				.clone()
				.sub(a.pos) // transform position into ellipse position
				.add(b.pos) // add box position, since it's not part of calcPoints
				.scale(xTransform, yTransform), // scale into space of ellipse
	)
	boxAsPolygon.angle = 0
	boxAsPolygon.pos = Vectors.Zero
	boxAsPolygon.setPoints(points)

	const result = testCirclePolygon(testEllipseX_circle_hack, boxAsPolygon, response)

	// restore the box back to original form
	boxAsPolygon.pos = oldPos
	boxAsPolygon.angle = oldAngle
	boxAsPolygon.setPoints(oldPoints)

	return result
}

export function testBoxEllipse(a: BoxCollider, b: EllipseCollider, response?: Response): boolean {
	const result = testEllipseBox(b, a, response)
	if (response?.overlap) {
		response.overlapV.scale(-1)
	}
	return result
}

export function testEllipsePolygon(a: Ellipse, b: PolygonCollider, response?: Response): boolean {
	testEllipseX_circle_hack.pos = a.pos
	testEllipseX_circle_hack.r = Math.max(a.rX, a.rY) // HACK: fake as circle
	return testCirclePolygon2(testEllipseX_circle_hack, b, response)
}
const testEllipseX_circle_hack = new CircleCollider()

export function testPolygonEllipse(a: PolygonCollider, b: Ellipse, response?: Response): boolean {
	const result = testEllipsePolygon(b, a, response)
	if (response?.overlap) {
		response.overlapV.scale(-1)
	}
	return result
}

export function testCirclePolygon2(a: CircleCollider, b: PolygonCollider, response?: Response) {
	const boundingCircle: Circle = b.boundingCircle
	if (!testCircleCircle2(a, boundingCircle)) {
		return false
	}
	return testCirclePolygon(a, b, response)
}
export function testPolygonCircle2(a: Polygon, b: Circle, response?: Response) {
	const boundingCircle: Circle = (a as any).boundingCircle
	if (boundingCircle && !testCircleCircle2(boundingCircle, b)) {
		return false
	}
	return testPolygonCircle(a, b, response)
}

export function testPolygonBox(a: PolygonCollider, b: BoxCollider, response?: Response) {
	return testPolygonPolygon(a, b.polygon, response)
}


export function testBoxPolygon(a: BoxCollider, b: PolygonCollider, response: Response) {
	const result = testPolygonBox(b, a, response)
	if (response?.overlap) {
		response.overlapV.scale(-1)
	}
	return result
}

export function testLineCircle(line: Line, circle: Circle) {
	return p5.collideLineCircle(line.pos.x, line.pos.y, line.pos2.x, line.pos2.y, circle.pos.x, circle.pos.y, circle.r)
}

export function testCapsuleCircle(capsule: Capsule, circle: Circle) {
	const r = p5.collideLineCircle(capsule.pos.x, capsule.pos.y, capsule.pos2.x, capsule.pos2.y, circle.pos.x, circle.pos.y, (circle.r + capsule.r) * 2)
	return r
}
export function testCapsulePosRadius(capsule: Capsule, pos: VectorXY, radius: number) {
	return p5.collideLineCircle(capsule.pos.x, capsule.pos.y, capsule.pos2.x, capsule.pos2.y, pos.x, pos.y, (radius + capsule.r) * 2)
}

export function testCapsuleEllipse(capsule: Capsule, ellipse: Ellipse) {
	// TODO2: this is not correct
	const maxEllipseRadius = Math.max(ellipse.rX, ellipse.rY)
	// check one big circle that encapsulates the ellipse
	if (testCapsulePosRadius(capsule, ellipse.pos, maxEllipseRadius)) {
		const p1 = ellipse.pos.clone()
		const p2 = ellipse.pos.clone()
		p1.x += maxEllipseRadius * 0.5
		p2.x -= maxEllipseRadius * 0.5
		//debugDrawCircle(p1, maxEllipseRadius * 0.5, Colors.red)
		//debugDrawCircle(p2, maxEllipseRadius * 0.5, Colors.red)

		// then check 2 smaller circles, see:
		//  https://cdn.discordapp.com/attachments/657291315955892224/765905479385350144/unknown.png
		if (testCapsulePosRadius(capsule, p1, maxEllipseRadius * 0.5) || testCapsulePosRadius(capsule, p2, maxEllipseRadius * 0.5)) {
			return true
		}
	}
	return false
}

export function checkCreatePolygonOnBox(box: BoxCollider): Polygon {
	const boxAsAny = box as any
	if (!boxAsAny.polygon) {
		boxAsAny.polygon = new Polygon()
		setPolygonFromBox(box, (box as any).polygon, [new Vector(), new Vector(), new Vector(), new Vector()])
	}
	return boxAsAny.polygon
}

/** OBB = oriented bounding box */
export function setPolygonFromCapsuleOBB(capsule: Capsule, polygon: Polygon, points: Vector[]) {
	const cp1 = capsule.pos
	const cp2 = capsule.pos2
	const cpr = capsule.r
	polygon.pos = cp1 //create bounds with origin at cp1 (so as not to create another vector)
	const diffx = cp2.x - cp1.x
	const diffy = cp2.y - cp1.y
	const oneOverLen = 1 / lengthXY(diffx, diffy)
	const dirx = diffx * oneOverLen * cpr
	const diry = diffy * oneOverLen * cpr
	const perpx = diry
	const perpy = -dirx

	points[0].x = diffx + dirx - perpx
	points[0].y = diffy + diry - perpy
	points[1].x = diffx + dirx + perpx
	points[1].y = diffy + diry + perpy
	points[2].x = -dirx + perpx
	points[2].y = -diry + perpy
	points[3].x = -dirx - perpx
	points[3].y = -diry - perpy
	polygon.setPoints(points)
}

function cloneCollider(collider: AnyCollider) {
	switch (collider.type) {
		case ColliderType.Circle: {
			return new CircleCollider(collider.pos.clone(), collider.r)
		}
		case ColliderType.Polygon: {
			return new PolygonCollider(collider.pos.clone(), collider.points)
		}
		case ColliderType.Box: {
			return new BoxCollider(collider.pos.clone(), collider.w, collider.h)
		}
		case ColliderType.Ellipse: {
			return new EllipseCollider(collider.pos.clone(), collider.rX, collider.rY)
		}
	}
}

// export function propHasNoColliders(prop) {
// 	const asProp = prop as TerrainProp
// 	if (!asProp.colliders) {
// 		return true
// 	}
// 	return asProp.colliders.length === 0
// }

type findNearToPosFunc = (position: VectorXY, dist: number, optionalSkipCriteriaFn?: (e) => boolean) => IColliderEntity[]
interface ISpatialColliders {
	spatialEnemies: { findNearToPos: findNearToPosFunc }
	spatialProps: { findNearToPos: findNearToPosFunc }
	spatialColliderEntities: { findNearToPos: findNearToPosFunc }
}

interface IColliderEntity {
	colliders: AnyCollider[]
}

/// returns the closest intersection point
export function closestIntersectingBeamCollider(p1: Vector, p2: Vector, colliders: AnyCollider[]) {
	// Find all intersection points, filter out any failed intersections, return the closest one to beam origin
	const intersectionPoints =
		chain(colliders)
			//.forEach(c => debugDrawShape(c, Colors.green, false, 0.1, 0.95))
			//.forEach(c => debugDrawShape(c, Colors.yellow, false, 0.1, 1.05))
			.map((collider) => findIntersection(p1, p2, collider))
			.compact()
			.minBy((intersectionPoint: any) => distance(p1.x, p1.y, intersectionPoint.x, intersectionPoint.y))
			.value() || false
	return intersectionPoints
}

/// returns the closest intersection points collider or undefined (if none)
export function closestIntersectingColliderBeamCollider(p1: Vector, p2: Vector, colliders: AnyCollider[]) {
	// Find all intersection points, filter out any failed intersections, return the closest collider to beam origin
	const closestColliderByIntersection = chain(colliders)
		.minBy((collider) => {
			if (isInsideCollider(p1, collider)) {
				return 0
			}
			const intersectionPoint = findIntersection(p1, p2, collider)
			if (intersectionPoint) {
				return distance(p1.x, p1.y, intersectionPoint.x, intersectionPoint.y)
			}
		})
		.value()
	return closestColliderByIntersection
}

export function findIntersection(p1: VectorXY, p2: VectorXY, collider: AnyCollider): false | Vector {
	switch (collider.type) {
		case ColliderType.Box: {
			return intersectLinePolygon(p1, p2, collider.polygon)
		}
		case ColliderType.Circle: {
			return intersectLineCircle2(p1, p2, collider)
		}
		case ColliderType.Polygon: {
			return intersectLinePolygon(p1, p2, collider)
		}
		case ColliderType.Ellipse: {
			return intersectLineEllipse(p1, p2, collider)
		}
		default: {
			throw Error('Trying to find intersection on unknown collider type.')
		}
	}
}

// Get the nearest intersection point from a polygon by testing each line, adapted from p5 collision
export function intersectLinePolygon(p1: VectorXY, p2: VectorXY, collider: Polygon) {
	const vertices = collider.calcPoints
	const segmentIndices = slidingWindow([...range(0, vertices.length), 0])
	const intersections =
		chain(segmentIndices)
			.map(([v1, v2]) => p5.collideLineLine(p1.x, p1.y, p2.x, p2.y, vertices[v1].x + collider.pos.x, vertices[v1].y + collider.pos.y, vertices[v2].x + collider.pos.x, vertices[v2].y + collider.pos.y, true))
			.filter((intersectionPoint) => intersectionPoint.x !== false && intersectionPoint.y !== false)
			.minBy((intersectionPoint) => distance(p1.x, p1.y, intersectionPoint.x, intersectionPoint.y))
			.value() || false
	if (intersections) {
		// debugDrawLine(p1, intersections, Colors.cyan, false, 10)
	}
	return intersections
}

export function isInsideCollider(p1: VectorXY, _collider: AnyCollider): boolean {
	const collider = _collider as any
	if (collider.w && collider.h) {
		return p5.collidePointRect(p1.x, p1.y, collider.pos.x, collider.pos.y, collider.w, collider.h)
	} else if (collider.r) {
		return p5.collidePointCircle(p1.x, p1.y, collider.pos.x, collider.pos.y, collider.r * 2)
	} else if (collider.polygon) {
		return p5.collidePointPoly(p1.x, p1.y, collider.polygon.calcPoints)
	} else if (collider.rX && collider.rY) {
		return p5.collidePointEllipse(p1.x, p1.y, collider.pos.x, collider.pos.y, collider.rX * 2, collider.rY * 2)
	}
}

export function intersectLineBox(p1: Vector, p2: Vector, collider: any) {
	const intersections = p5.collideLineRect(p1.x, p1.y, p2.x, p2.y, collider.pos.x, collider.pos.y, collider.w, collider.h, true)
	if (intersections === false) {
		return false
	} else {
		const intersectionPoints = Object.values(intersections) as any[]
		return (
			chain(intersectionPoints)
				.filter((point) => point.x && point.y)
				.minBy((intersectionPoint) => distance(p1.x, p1.y, intersectionPoint.x, intersectionPoint.y))
				.value() || false
		)
	}
}

function distToSegmentSquared(p, line1: VectorXY, line2: VectorXY) {
	const l2 = distanceSquaredVV(line1, line2);
	if (l2 == 0) return distanceSquaredVV(p, line1);
	let t = ((p.x - line1.x) * (line2.x - line1.x) + (p.y - line1.y) * (line2.y - line1.y)) / l2;
	t = Math.max(0, Math.min(1, t));
	return distanceSquaredVV(p, {
		x: line1.x + t * (line2.x - line1.x),
		y: line1.y + t * (line2.y - line1.y)
	});
}

export function distanceToSegment(p: VectorXY, line1: VectorXY, line2: VectorXY) {
	return Math.sqrt(distToSegmentSquared(p, line1, line2));
}

export function distanceToPolyline(p: VectorXY, line: VectorXY[]) {
	let min = Number.POSITIVE_INFINITY
	for (let i = 1; i < line.length; i++) {
		const l1 = line[i - 1];
		const l2 = line[i];
		const d = distanceToSegment(p, l1, l2)
		if (d < min) {
			min = d
		}
	}
	return min
}

export function slidingWindow(collection) {
	const accumulator = []
	for (let i = 0; i <= collection.length; i++) {
		if (i + 1 === collection.length) {
			return accumulator
		} else {
			accumulator.push([collection[i], collection[i + 1]])
		}
	}
	return accumulator
}


interface IntersectionPoint {
	x: number
	y: number
}

// Instead of trying to solve line-ellipse, transform the ellipse into a circle,
// solve line-circle, retransform the the collision point
export function intersectLineEllipse(p1: VectorXY, p2: VectorXY, collider: Ellipse) {
	// this is a circle, delegate to intersectLineCircle
	if (collider.rX === collider.rY) {
		return intersectLineCircle2(p1, p2, { pos: collider.pos, r: collider.rX })
	}
	// Translate the line segment to the origin (treat the ellipse as being centered at 0,0)
	const x1 = p1.x - collider.pos.x
	const y1 = p1.y - collider.pos.y
	const x2 = p2.x - collider.pos.x
	const y2 = p2.y - collider.pos.y

	let xTransform = 1
	let yTransform = 1
	let radius = 0
	// Scale the larger axis to the smaller, choose the radius of the smaller
	if (collider.rX > collider.rY) {
		xTransform = collider.rY / collider.rX
		radius = collider.rY
	} else {
		yTransform = collider.rX / collider.rY
		radius = collider.rX
	}

	const intersectingPoint = intersectLineCircle2(new Vector(x1 * xTransform, y1 * yTransform), new Vector(x2 * xTransform, y2 * yTransform), { pos: { x: 0, y: 0 }, r: radius })

	if (intersectingPoint === false) {
		return intersectingPoint
	} else {
		intersectingPoint.x *= 1 / xTransform
		intersectingPoint.y *= 1 / yTransform
		intersectingPoint.x += collider.pos.x
		intersectingPoint.y += collider.pos.y
		// debugDrawCircle(intersectingPoint, 3, Colors.pink)
		return intersectingPoint
	}
}

// cribbed from wolfram https://mathworld.wolfram.com/Circle-LineIntersection.html
export function intersectLineCircle2(p1: VectorXY, p2: VectorXY, collider: any) {
	// Translate the line points to 0,0
	const x1 = p1.x - collider.pos.x
	const x2 = p2.x - collider.pos.x
	const y1 = p1.y - collider.pos.y
	const y2 = p2.y - collider.pos.y

	const sgn = (x) => {
		if (x < 0) {
			return -1
		} else {
			return 1
		}
	}

	const distX = x2 - x1
	const distY = y2 - y1
	const distGammaSquared = distX ** 2 + distY ** 2
	const xCrossY = x1 * y2 - x2 * y1

	const discriminant = collider.r ** 2 * distGammaSquared - xCrossY ** 2

	const xRightHandSide = sgn(distY) * distX * Math.sqrt(collider.r ** 2 * distGammaSquared - xCrossY ** 2)
	const yRightHandSide = Math.abs(distY) * Math.sqrt(collider.r ** 2 * distGammaSquared - xCrossY ** 2)

	if (discriminant < 0) {
		return false
		// TODO3 float precision?
		// point is tangent to the circle, only one solution
	} else if (discriminant === 0) {
		const x = (xCrossY * distY + xRightHandSide) / distGammaSquared
		const y = (-xCrossY * distX + yRightHandSide) / distGammaSquared
		if (distance(x1, y1, x, y) > distance(x1, y1, x2, y2)) {
			return false
		} else {
			return new Vector(x + collider.pos.x, y + collider.pos.y)
		}
	} else {
		const ix1 = (xCrossY * distY + xRightHandSide) / distGammaSquared
		const iy1 = (-xCrossY * distX + yRightHandSide) / distGammaSquared
		const ix2 = (xCrossY * distY - xRightHandSide) / distGammaSquared
		const iy2 = (-xCrossY * distX - yRightHandSide) / distGammaSquared

		const i1 = new Vector(ix1 + collider.pos.x, iy1 + collider.pos.y)
		const i2 = new Vector(ix2 + collider.pos.x, iy2 + collider.pos.y)

		//debugDrawCircle(i1, 5, Colors.white)
		//debugDrawCircle(i2, 5, Colors.red)
		if (distance(x1, y1, ix1, iy1) < distance(x1, y1, ix2, iy2)) {
			if (distance(x1, y1, ix1, iy1) > distance(x1, y1, x2, y2)) {
				// Closest point isn't on the line segment
				return false
			} else {
				return isBetweenInclusive(i1.x, p1.x, p2.x) ? i1 : false
			}
		} else {
			if (distance(x1, y1, ix2, iy2) > distance(x1, y1, x2, y2)) {
				// Closest point isn't on the line segment
				return false
			} else {
				return isBetweenInclusive(i2.x, p1.x, p2.x) ? i2 : false
			}
		}
	}
}

// A modified version of p5 collision's collideLineCircle, found here:
// https://github.com/bmoren/p5.collide2D/blob/master/p5.collide2d.js#L152
export function intersectLineCircle(p1: Vector, p2: Vector, collider: CircleCollider) {
	const x1 = p1.x
	const y1 = p1.y

	const x2 = p2.x
	const y2 = p2.y

	const distX = x1 - x2
	const distY = y1 - y2

	const dot = ((collider.pos.x - x1) * (x2 - x1) + (collider.pos.y - y1) * (y2 - y1)) / (distX * distX + distY * distY)

	// Closest point to the circle
	const closestX = x1 + dot * (x2 - x1)
	const closestY = y1 + dot * (y2 - y1)

	const closestToCircleX = closestX - collider.pos.x
	const closestToCircleY = closestY - collider.pos.y

	const distToCenter = p5.sqrt(closestToCircleX * closestToCircleX + closestToCircleY * closestToCircleY)

	// Hopefully this collides nicely
	if (distToCenter <= collider.r) {
		return new Vector(closestX, closestY)
	} else {
		return false
	}
}
export function sortProjectileCollisionsByCollider(entityA: { pos: VectorXY }, entityB: { pos: VectorXY }, pos: VectorXY): number {
	if (entityA && entityB) {
		const distA: number = distanceSquaredVV(entityA.pos, pos)
		const distB: number = distanceSquaredVV(entityB.pos, pos)
		return distA - distB
	} else if (entityA) {
		return 1
	} else if (entityB) {
		return -1
	}
	return 0
}

export function sortProjectileCollisionsByEntity(entityA: { position: VectorXY }, entityB: { position: VectorXY }, pos: VectorXY, collidersHit: AnyCollider[]): number {
	if (entityA && entityB) {
		const entityAWithColliders = (entityA as any) as { colliders: AnyCollider[] }
		const entityBWithColliders = (entityB as any) as { colliders: AnyCollider[] }
		const entityAHitColliders = intersection(collidersHit, entityAWithColliders.colliders)
		const entityBHitColliders = intersection(collidersHit, entityBWithColliders.colliders)
		const adist = entityAHitColliders.map((c) => distanceSquaredVV(c.pos, pos))
		const bdist = entityBHitColliders.map((c) => distanceSquaredVV(c.pos, pos))
		const minDistA = min(adist)
		const minDistB = min(bdist)
		return minDistA - minDistB
	} else if (entityA) {
		return 1
	} else if (entityB) {
		return -1
	}
	return 0
}