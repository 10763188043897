import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { GOBLIN_STRAFE_TIME } from "../../../events/goblin-gameplay-event"

const lootGoblin: EnemyAI = {
	name: ENEMY_NAME.LOOT_GOBLIN_JESTER,
	type: EnemyType.BASIC,
	objectPoolInitialSize: 5,
	objectPoolGrowthSize: 1,
	appearance: {
		asset: SpineDataName.LOOT_GOBLIN_JESTER,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		useIdleAnim: true,
		mixSettings: [],
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 40,
				position: [0, 0],
			},
			{
				type: ColliderType.Circle,
				radius: 40,
				position: [0, 60],
			},
		],
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.70,
			maxHealth: 10,
			baseDamage: 4,
			knockbackResist: 10,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		immuneToRecycle: true,
		isSpecial: true
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 2500,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 900,
			movementMinDistance: 600,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		fleeing: {
			timeToFlee: GOBLIN_STRAFE_TIME
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default lootGoblin
