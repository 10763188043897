<template>
	<div class="icons" :class="{ visible: showDebugIconScreen }">
		<h3>Upgrades Large</h3>
		<div class="container upgrades">
			<div v-for="item in getAllUpgradeIcons" :key="item">
				<ItemUpdated :item="item" :on-click-prop="() => {}" :selectable="false" :selected="false" :hovered="false" :on-hover-fn="() => {}" :dim-icon="false" :show-lock-icon="false" :disable-circle="false" :tooltipEnabled="true" :component="'upgrade'"></ItemUpdated>
			</div>
		</div>
		<h3>Upgrades Small</h3>
		<div class="container upgrades">
			<div v-for="item in getAllUpgradeIcons" :key="item">
				<ItemUpdated :item="item" :on-click-prop="() => {}" :selectable="false" :selected="false" :hovered="false" :on-hover-fn="() => {}" :small-icons="true" :dim-icon="false" :show-lock-icon="false" :disable-circle="false" :tooltipEnabled="true" :component="'upgrade'"></ItemUpdated>
			</div>
		</div>

		<h3>Twists Large</h3>
		<div class="container twist">
			<div v-for="twist in getAllTwistIcons" :key="twist">
				<ItemUpdated :item="twist" :gray-out-books="false" :selected="false" :enable-glow="true" :on-click-prop="() => {}" :on-hover-fn="() => {}" :disable-circle="true" :small-icons="false" :component="'twist'" :tooltip-enabled="true"></ItemUpdated>
			</div>
		</div>
        <h3>Twist Small</h3>
		<div class="container twist">
			<div v-for="twist in getAllTwistIcons" :key="twist">
				<ItemUpdated :item="twist" :gray-out-books="false" :selected="false" :enable-glow="true" :on-click-prop="() => {}" :on-hover-fn="() => {}" :disable-circle="true" :small-icons="true" :component="'twist'" :tooltip-enabled="true"></ItemUpdated>
			</div>
		</div>() => {}
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { UPGRADE_COLLECTIONS } from '../../upgrades/upgrade-definitions'
import { MUTATOR_DEFINITIONS } from '../../mutators/mutator-definitions'
import ItemUpdated from '../ui-components/item/items-updated.vue'

export default {
	name: 'DebugIcons',
	components: {
		ItemUpdated,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('ui', ['showDebugIconScreen']),

		getAllUpgradeIcons() {
			const allUpgrades = []
			for (const [upgradeKey, coll] of Object.entries(UPGRADE_COLLECTIONS)) {
				if (coll.shape === 'diamond') {
					const { top, left, right, bottom } = coll
					// NOTE: We either look up the buff shape based on Buff name,
					// Or we also take on the shape around here.
					allUpgrades.push(top)
					allUpgrades.push(left)
					allUpgrades.push(right)
					allUpgrades.push(bottom)
				} else if (coll.shape === 'tree') {
					const { top, left, right, leftLeaf, middleLeaf, rightLeaf } = coll

					allUpgrades.push(top)
					allUpgrades.push(left)
					allUpgrades.push(right)
					allUpgrades.push(leftLeaf)
					allUpgrades.push(middleLeaf)
					allUpgrades.push(rightLeaf)
				} else if (coll.shape === 'single') {
					// @TODO: same as line?
					allUpgrades.push(coll.top)
				} else if (coll.shape === 'line') {
					for (let i = 0; i < coll.upgrades.length; ++i) {
						const upgrade = coll.upgrades[i]
						allUpgrades.push(upgrade)
					}
				}
			}
			return allUpgrades
		},
		getAllTwistIcons() {
			const allUpgrades = []

			for (const [mutatorKey, mutator] of Object.entries(MUTATOR_DEFINITIONS)) {
				allUpgrades.push(mutator)
			}
			return allUpgrades
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
.icons {
	display: none;
    flex-direction: column;
	pointer-events: none;
	background-color: white;
	padding: 20px;
	&.visible {
		display: flex;
		pointer-events: all;
	}
    .container{
        display: flex;
        flex-wrap: wrap;
        &.twist{
            .item{
                margin: 15px 10px;
            }
           
        }
    }
}
</style>
