
// This MIXIN is used is multiple components. The naming conventions are strict and should not be changed
// unless every other component naming matches your change.

export const paginationMixins = {
    computed:{
        selectCategoryString(){
			return this.categories.find((header) => header.id === this.defaultSelectedCategory).name
		},
    },
    methods: {

        previousCategory() {
            const currentIndex = this.categories.findIndex(header => header.id === this.defaultSelectedCategory)
            const lastIndex = this.categories.length - 1
            this.defaultSelectedCategory = this.categories[(currentIndex - 1 + this.categories.length) % this.categories.length].id;
		},
		nextCategory() {
            const currentIndex = this.categories.findIndex(header => header.id === this.defaultSelectedCategory)
            this.defaultSelectedCategory = this.categories[(currentIndex + 1) % this.categories.length].id
		},
    },
}
