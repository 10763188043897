import { Renderer } from "../engine/graphics/renderer"
import { getVisibleWorldHeight, getVisibleWorldWidth } from "../engine/graphics/camera-logic"
import { InGameTime } from "../utils/time"
import { Enemy } from "../entities/enemies/enemy"
import { GameState } from "../engine/game-state"
import { ENEMY_NAME } from "../entities/enemies/enemy-names"
import { AIStates } from "../entities/enemies/ai-types"
import { EffectConfig } from "../engine/graphics/pfx/effectConfig"
import { AssetManager } from "../web/asset-manager"
import NavigationArrow from "./navigation-arrow"
import AISystem from "../entities/enemies/ai-system"
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import PlayerMetricsSystem from "../metrics/metric-system"
import { AllEventTypes } from "./event-stat-types"
import { EventStartData, GameplayEvent } from "./gameplay-event-definitions"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"
import { Audio } from "../engine/audio"
import { EventTypes } from "./event-types"

export const GOBLIN_STRAFE_TIME = 10_000
export const GOBLIN_FLEE_TIME = 25_000
export const GOBLIN_DISAPPEARANCE_TIME = 1_000

export const GOBLIN_XP_DROP_AMOUNT = 20

const PERCENTAGE_OF_SCREEN = 0.65 // What percentage of screen should the goblin radius be

const ARROW_BLINK_SPEED = 0.5
const ARROW_BLINK_TIME = 5_000 // The amount of time the arrow should blink before goblin despawns

export class GoblinGameplayEvent implements GameplayEvent {

	static getInstance() {
		if (!GoblinGameplayEvent.instance) {
			GoblinGameplayEvent.instance = new GoblinGameplayEvent()
		}

		return GoblinGameplayEvent.instance
	}
	static destroy() {
		GoblinGameplayEvent.instance = null
	}

	private static instance: GoblinGameplayEvent
	goblinEventActive: boolean = false

	lootGoblin: Enemy
	smokeEffectConfig: EffectConfig
	eventArrow: NavigationArrow

	kickOffEvent: boolean = false

	constructor() {
		// Placeholder effect
		this.smokeEffectConfig = AssetManager.getInstance().getAssetByName('enemy-death-explosion').data
		this.eventArrow = new NavigationArrow()
	}

	update(delta: timeInSeconds) {
		const elapsedTime = InGameTime.timeElapsedInSeconds

		if (this.eventArrow.isShowing) {
			this.eventArrow.setPosition(GameState.player.x, GameState.player.y)
		}

		if (this.eventArrow.isBlinking) {
			this.eventArrow.blink(delta, ARROW_BLINK_SPEED)
		}

		if (this.kickOffEvent) {
			this.spawnGoblin()
		}

		if (this.goblinEventActive) {
			if (this.lootGoblin) {
				const player = GameState.player
				// TODO make sure screen widths are accurate
				const halfScreenWidth = getVisibleWorldWidth(Renderer.getInstance().cameraState.zoom) / 2
				const halfScreenHeight = getVisibleWorldHeight(Renderer.getInstance().cameraState.zoom) / 2
				const enemyPlayerDistanceX = Math.abs(player.position.x - this.lootGoblin.position.x)
				const enemyPlayerDistanceY = Math.abs(player.position.y - this.lootGoblin.position.y)
				if ((enemyPlayerDistanceX > halfScreenWidth || enemyPlayerDistanceY > halfScreenHeight) && this.lootGoblin.currentState === AIStates.FLEEING) {
					this.eventArrow.setIsShowing(true)
				}
				this.eventArrow.updateDestination(this.lootGoblin.position.x, this.lootGoblin.position.y)

				if (this.lootGoblin.currentState === AIStates.FLEEING && this.lootGoblin.timeInStateMs() >= GOBLIN_FLEE_TIME - ARROW_BLINK_TIME && !this.eventArrow.isBlinking) {
					this.eventArrow.setIsBlinking(true)
				}

				if (this.lootGoblin.currentState === AIStates.FLEEING && this.lootGoblin.timeInStateMs() >= GOBLIN_FLEE_TIME) {
					this.lootGoblin.transitionToIdle()
				}

				if (this.lootGoblin.isDead() || ((this.lootGoblin.currentState === AIStates.IDLE && this.lootGoblin.timeInStateMs() >= GOBLIN_DISAPPEARANCE_TIME))) {
					this.endEvent()
				}
			}
		}
	}
	
	startEvent(): void {
		this.kickOffEvent = true
	}

	setStartData(data: EventStartData) {
		
	}

	private endEvent() {
		if (!this.lootGoblin.isDead()) {
			Audio.getInstance().playSfx('SFX_Boss_Forest_Vox_Laugh_Short')
			Renderer.getInstance().addOneOffEffectByConfig(this.smokeEffectConfig, this.lootGoblin.position.x, this.lootGoblin.position.y, -9999, 1.5, 1)
			// Override default corpse timeout so he disappears in a poof of smoke
			this.lootGoblin.corpseTimeoutInSeconds = 0
			this.lootGoblin.transitionToDead(false)
		} else if (this.lootGoblin.isDead()) {
			PlayerMetricsSystem.getInstance().trackMetric('EVENT_COMPLETED', AllEventTypes.LOOT_GOBLINS)
		}
		this.goblinEventActive = false
		this.lootGoblin = undefined
		this.eventArrow.setIsBlinking(false)
		this.eventArrow.setIsShowing(false)
		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.Goblin)
	}

	private spawnGoblin() {
		const screenHalfWidth = getVisibleWorldWidth(Renderer.getInstance().cameraState.zoom) / 2
		const screenHalfHeight = getVisibleWorldHeight(Renderer.getInstance().cameraState.zoom) / 2
		const radiusX = screenHalfWidth + (screenHalfWidth * 0.15)
		const radiusY = screenHalfHeight + (screenHalfHeight * 0.15)
		const randomAngle = Math.random() * 2 * Math.PI
		const posX = (Math.cos(randomAngle) * radiusX) + GameState.player.position.x
		const posY = (Math.sin(randomAngle) * radiusY) + GameState.player.position.y
		this.lootGoblin = AISystem.getInstance().spawnEnemiesInRectangle(ENEMY_NAME.LOOT_GOBLIN_JESTER, 1, posX, posY, posX, posY)[0]
		this.lootGoblin.currentHealth = this.lootGoblin.maxHealth
		this.lootGoblin.strafeDistance = PERCENTAGE_OF_SCREEN
		this.goblinEventActive = true
		this.kickOffEvent = false
	}


	private getGoblinHealthBasedOnTime(): number {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		if (currentTimeInSeconds <= 150) {
			return 500
		} else if (currentTimeInSeconds <= 300) {
			return 800
		} else if (currentTimeInSeconds <= 600) {
			return 1500
		} else if (currentTimeInSeconds <= 900) {
			return 2400
		}
	}
}