import { Geometry, Mesh } from "pixi.js"
import { Player } from "../../entities/player"
import { timeInSeconds } from "../../utils/primitive-types"
import { AssetManager } from "../../web/asset-manager"
import { Renderer } from "./renderer"

const HALF_QUAD_SIZE = 3_000 // Half the size of the quad that tracks the player as they move around
const QUAD_SIZE = 2 * HALF_QUAD_SIZE

interface ScrollingUniforms {
    uSampler2: PIXI.Texture,
    offsetX: number,
    offsetY: number
}

export class ScrollingBackground {
    uniforms: ScrollingUniforms

    scrollingImage: Mesh

    private followingPlayer: Player
    private uvScale: number

    constructor(followingPlayer: Player, textureName: string, zoom: number) {
        this.followingPlayer = followingPlayer

        const asset = AssetManager.getInstance().getAssetByName(textureName)
        const width = asset.texture.width // sure hope height and width are the same!
        this.uvScale = zoom * (HALF_QUAD_SIZE / width)

        const geometry = new Geometry()
        geometry.addAttribute(
            "aVertexPosition",
            [-HALF_QUAD_SIZE, -HALF_QUAD_SIZE, HALF_QUAD_SIZE, -HALF_QUAD_SIZE, HALF_QUAD_SIZE, HALF_QUAD_SIZE, -HALF_QUAD_SIZE, HALF_QUAD_SIZE],
            2
        )

        geometry
            .addAttribute("aUvs", [0, 0, this.uvScale, 0, this.uvScale, this.uvScale, 0, this.uvScale], 2)
            .addIndex([0, 1, 2, 0, 2, 3])
        
        
        // @TODO move these shaders to their own files
        const vertexSrc = `
            precision mediump float;

            attribute vec2 aVertexPosition;
            attribute vec2 aUvs;

            uniform mat3 translationMatrix;
            uniform mat3 projectionMatrix;

            varying vec2 vUvs;

            void main() {
                vUvs = aUvs;
                gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
            }
        `

        const fragmentSrc = `
            precision mediump float;

            varying vec2 vUvs;

            uniform sampler2D uSampler2;

            uniform float offsetX;
            uniform float offsetY;

            void main() {
                gl_FragColor = texture2D(uSampler2, vec2(vUvs.x + offsetX, vUvs.y + offsetY));

            }
        `

        asset.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT

        this.uniforms = {
            offsetX: 10,
            offsetY: 10,

            uSampler2: asset.texture,
        }

        const scrollingShader = PIXI.MeshMaterial.from(vertexSrc, fragmentSrc, this.uniforms) as PIXI.MeshMaterial //bruh wtf pixi
        this.scrollingImage = new Mesh(geometry, scrollingShader)
        this.scrollingImage['update'] = () => {}
        this.scrollingImage.zIndex = -999_999

        Renderer.getInstance().bgRenderer.addDisplayObjectToScene(this.scrollingImage)
    }

    update(deltaTime: timeInSeconds) {
        this.scrollingImage.x = this.followingPlayer.x
        this.scrollingImage.y = this.followingPlayer.y

        this.uniforms.offsetX = (this.followingPlayer.x / QUAD_SIZE) * this.uvScale
        this.uniforms.offsetY = (this.followingPlayer.y / QUAD_SIZE) * this.uvScale
    }

    remove() {
        Renderer.getInstance().bgRenderer.removeFromScene(this.scrollingImage)
    }
}