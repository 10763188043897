import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { Player } from "../../../entities/player"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import EntityStatList from "../../../stats/entity-stat-list"
import { PrimaryWeapon } from "../../primary-weapon"
import { AllWeaponTypes } from "../../weapon-types"

export class EmptyPrimaryWeapon extends PrimaryWeapon {
    projectileEffectType: ParticleEffectType
    projectileTrailType: ParticleEffectType

    weaponType: AllWeaponTypes = AllWeaponTypes.None


    init(player: Player, playerStatList: EntityStatList) {

    }
    
    update(delta: number) {

    }
    
    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)
    }

}