<template>
	<div class="webgl-check">
		<canvas id="webgl-check" width="1" height="1"></canvas>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { autoDetectRenderer } from 'pixi.js'

export default {
	name: 'WebGLCheck',
	components: {},
	data() {
		return {
			check: null,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('ui', ['showStartupError']),
		...mapActions('error', ['ErrorDescriptions']),
		checkWebGl() {
			try {
				this.check = new WebGLCheck()
			} catch (error) {
				if (error.message.includes('WebGL')) {
					this.ErrorDescriptions({title: 'errors.generic_title', description: 'errors.startup_error_web_gl'})
				} else {
					this.ErrorDescriptions({title: 'errors.generic_title', description: 'errors.startup_error_unknown'})
				}
			}
		},
	},
	mounted() {
		this.checkWebGl()
	},
}

class WebGLCheck {
	pixiRenderer: PIXI.Renderer

	constructor() {
		const canvas: HTMLCanvasElement = document.getElementById('main-canvas') as HTMLCanvasElement

		this.pixiRenderer = autoDetectRenderer({
			width: 1,
			height: 1,
			view: canvas,
			antialias: false,
			transparent: true,
			resolution: 1,
		})
	}
}
</script>

<style lang="less" scoped>
.webgl-check {
	position: absolute;
}
</style>
