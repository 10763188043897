import { Enemy } from "../../../entities/enemies/enemy"
import EntityStatList from "../../../stats/entity-stat-list"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { AllWeaponTypes } from "../../weapon-types"
import { AutoFirePassiveSkill } from "./auto-fire-passive-skill"
import { Player } from "../../../entities/player"
import { timeInSeconds } from "../../../utils/primitive-types"
import { Vector } from "sat"
import { GroundPickup } from "../../../entities/pickups/ground-pickup"
import NavigationArrow from "../../../events/navigation-arrow"
import { AssetManager } from "../../../web/asset-manager"
import { Texture } from "pixi.js"
import { GameState } from "../../../engine/game-state"
import { distanceSquaredVV, getRandomPointInCircle, getRandomPointInRect, getRandomPointInRectRange } from "../../../utils/math"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { PetCollectionName } from "../../../entities/pets/pet"
import { ObjectPoolTyped } from "../../../utils/third-party/object-pool"
import { PooledPet, PooledPetParams } from "../../../entities/pets/pooled-pet"
import { RadarSupplyDrop, RadarSupplyDropParams } from "../../../entities/radar-supply-drop"
import { InGameTime } from "../../../utils/time"
import CollisionSystem from "../../../engine/collision/collision-system"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { MarkGfx, MarkGfxParams } from "../../../entities/mark-gfx"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import AISystem from "../../../entities/enemies/ai-system"
import { GroundPickupConfigType, GroundPickupType } from "../../../entities/pickups/ground-pickup-types"
import { NO_MORE_XP_TIME } from "../../../game-data/levelling"

export enum RadarSkillOption {
    Radar,
    PetBomb,
    SupplyDrop
}

const PICKUP_RADAR_UPDATE_RATE = 1.25
const PET_BOMB_RADIUS = 750
const PET_BOMB_STARTING_PET_COUNT = 2

const PET_START_DIST = 2_100
const PET_LERP_IN_TIME = 1.75

const SUPPLY_DROP_MIN_X_OFFSET = 400
const SUPPLY_DROP_MAX_X_OFFSET = 1_100
const SUPPLY_DROP_MIN_Y_OFFSET = 200
const SUPPLY_DROP_MAX_Y_OFFSET = 500

const SUPPLY_DROP_CONTENTS = [
    {
        item: GroundPickupConfigType.HealingSmall,
        minCount: 1,
        maxCount: 2
    },
    {
        item: GroundPickupConfigType.CommonCurrencySmall,
        minCount: 12,
        maxCount: 20
    },
    {
        item: GroundPickupConfigType.RareCurrency,
        minCount: 2,
        maxCount: 3
    }
]

const WEALTHY_ENEMY_SEARCH_RANGE = 1_200

export class RadarPassiveSkill extends AutoFirePassiveSkill {
    static wealthyEnemyMarkGfxPool: ObjectPoolTyped<MarkGfx, MarkGfxParams>

    weaponType: AllWeaponTypes = AllWeaponTypes.RadarPassive
    
    skillOptions: RadarSkillOption[]
    petOptions: PetCollectionName[]

    petPools: Map<PetCollectionName, ObjectPoolTyped<PooledPet, PooledPetParams>>

    petBombPetCount: number

    markWealthyEnemies: boolean
    wealthyEnemyMarkCountScalar: number

    useBonusSupplyDrops: boolean
    supplyDropCurrencyBonusScalar: number
    supplyDropXPMin: number
    supplyDropXPMax: number

    private isRadarActive: boolean

    private pickupRadarTimeAcc: timeInSeconds
    private radarActiveTotalTime: timeInSeconds

    private xpArrow: NavigationArrow
    private specialArrow: NavigationArrow

    onCreate(player: Player): void {
        super.onCreate(player)

        this.skillOptions = []
        this.skillOptions.push(RadarSkillOption.Radar)
        this.skillOptions.push(RadarSkillOption.PetBomb)
        this.skillOptions.push(RadarSkillOption.SupplyDrop)

        this.petOptions = []
        this.petOptions.push('pet-cat')
        this.petOptions.push('pet-horse')
        this.petOptions.push('pet-rot-son')

        this.makePetPools()
        this.makeSupplyPool()

        this.pickupRadarTimeAcc = 0

        const passiveImages = AssetManager.getInstance().getAssetByName('radar-passives').spritesheet
        const xpArrowImage = passiveImages.textures['pickup-radar-arrow.png'] as Texture
        const xpArrowIcon = passiveImages.textures['pickup-radar-icon.png'] as Texture

        this.xpArrow = new NavigationArrow(xpArrowImage, xpArrowIcon)
        this.specialArrow = new NavigationArrow(xpArrowImage)

        this.petBombPetCount = PET_BOMB_STARTING_PET_COUNT
        this.wealthyEnemyMarkCountScalar = 1
        this.markWealthyEnemies = false

        if (!RadarPassiveSkill.wealthyEnemyMarkGfxPool) {
            const wealthyMarkImage = passiveImages.textures['high-priority-targets.png']
            RadarPassiveSkill.wealthyEnemyMarkGfxPool = new ObjectPoolTyped<MarkGfx, MarkGfxParams>(() => {
                return new MarkGfx(wealthyMarkImage)
            }, {}, 5, 1)
        }
    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.cooldownInterval = 30_000
        statList._actualStatValues.reloadAmmoIncrement = 1
        statList._actualStatValues.maxAmmo = 1
        statList._actualStatValues.reloadInterval = 1_000

        statList._actualStatValues.skillDuration = 13 // 13 second pickup radar
    }

    firePassiveSkill() {
        if (this.markWealthyEnemies) {
            this.activateWealthyEnemyMark()
        }

        const skill = this.skillOptions.pickRandom()

        switch(skill) {
            case RadarSkillOption.Radar:
                this.activatePickupRadar()
                return
            case RadarSkillOption.PetBomb:
                this.activatePetBombardment()
                return
            case RadarSkillOption.SupplyDrop:
                this.activateSupplyDrop()
                return
        }
    }
    
    update(delta: number) {
        super.update(delta)

        if (this.isRadarActive) {
            this.updatePickupRadar(delta)
        }
    }

    private activatePickupRadar() {
        // Display a navigation arrow to the largest clump of XP pickups on the map. Lasts 13s. 
        // Display a second navigation arrow to the nearest special pick-up (Magnets, Lost Scrolls, event drops such as Pet Ransom notes)
        this.isRadarActive = true
        this.pickupRadarTimeAcc = 0
        this.radarActiveTotalTime = 0

        this.getNewPickupLocations()
        this.updatePickupRadar(0)

        this.skillSuccessfullyUsed = true
        this.finishedUsingPassiveSkill()
    }

    private activatePetBombardment() {
        // Offensive pet(s) fly in from offscreen, perform their attacks in a random space around the player, then fly away.
        const playerPos = this.player.position
        for (let i = 0; i < this.petBombPetCount; ++i) {
            const point = getRandomPointInCircle(0, 0, 1)
            const startPos = new Vector().copy(point)
            startPos.scale(PET_START_DIST)
            startPos.add(playerPos)

            point.scale(PET_BOMB_RADIUS)
            point.add(playerPos)

            const pet = this.getRandomPet({
                lerpFromPos: startPos,
                lerpToPos: point,
                lerpTime: PET_LERP_IN_TIME,

                activeDuration: 2,
            })
        }

        this.skillSuccessfullyUsed = true
        this.finishedUsingPassiveSkill()    
    }

    private activateSupplyDrop() {
        // Crate contains 1-2 hearts and EITHER 12-20 Paper Scraps OR 2-3 Lost Scrolls. Crate gets placed at a random location on-screen ≥500 units away from the player.
        const landingPosition = new Vector()
        
        getRandomPointInRectRange(this.player.position.x, this.player.position.y, 
            SUPPLY_DROP_MIN_X_OFFSET, SUPPLY_DROP_MAX_X_OFFSET, SUPPLY_DROP_MIN_Y_OFFSET, SUPPLY_DROP_MAX_Y_OFFSET, landingPosition)
        
        const crateContents = SUPPLY_DROP_CONTENTS.pickRandom()
        let amount = Math.getRandomInt(crateContents.minCount, crateContents.maxCount)
        if (this.useBonusSupplyDrops) {
            if (crateContents.item === GroundPickupConfigType.RareCurrency || crateContents.item === GroundPickupConfigType.CommonCurrencySmall) {
                amount = Math.round(amount * this.supplyDropCurrencyBonusScalar)
            }
        }

        const dropItems: GroundPickupConfigType[] = new Array(amount).fill(crateContents.item)

        if (this.useBonusSupplyDrops) {
            // add even more stuff
            const xpAmount = Math.getRandomInt(this.supplyDropXPMin, this.supplyDropXPMax)
            let gotXp = 0
            while (gotXp < xpAmount) {
              if (InGameTime.timeElapsedInSeconds < NO_MORE_XP_TIME) {
                const xp = AISystem.getInstance().xpDropShuffleBag.next()
                if (xp !== GroundPickupConfigType.None) {
                    dropItems.push(xp)
                    gotXp++
                }
              } else {
                // At this point only large XP drop naturally in game anyway
                const xp = GroundPickupConfigType.XPLarge
                dropItems.push(xp)
                gotXp++
              }
            }
        }

        RadarSupplyDrop.pool.alloc({
            landingPosition,
            dropItems
        })

        this.skillSuccessfullyUsed = true
        this.finishedUsingPassiveSkill()
    }

    private updatePickupRadar(delta: timeInSeconds) {
        this.pickupRadarTimeAcc += delta
        this.radarActiveTotalTime += delta

        const duration = this.statList.getStat(StatType.skillDuration)
        if (this.radarActiveTotalTime >= duration) {
            this.isRadarActive = false
            // @TODO blink out a few seconds before turning off?
            this.xpArrow.setIsShowing(false)
            this.specialArrow.setIsShowing(false)
            return
        }

        if (this.pickupRadarTimeAcc >= PICKUP_RADAR_UPDATE_RATE) {
            this.getNewPickupLocations()
        }
        
        this.xpArrow.setPosition(this.player.x, this.player.y)
        this.specialArrow.setPosition(this.player.x, this.player.y)
    }

    private getNewPickupLocations() {
        const sortedPickups = GroundPickup.allActiveXpPickups.sort(this.sortPickup)
        if (sortedPickups.length > 0) {
            const position = sortedPickups[0].position
            this.xpArrow.updateDestination(position.x, position.y)
            this.xpArrow.setIsShowing(true)
        } else {
            this.xpArrow.setIsShowing(false)
        }

        // get closest special pickup
        const playerPos = this.player.position
        const pickups = GameState.pickupList
        let minDist: number = Number.MAX_SAFE_INTEGER
        let minSpecialPickup: GroundPickup

        for (let i = 0; i < pickups.length; ++i) {
            const p = pickups[i]
            if (p.pickupType === GroundPickupType.RareCurrency || p.pickupType === GroundPickupType.Magnet || p.pickupType === GroundPickupType.Map) {
                const dist = distanceSquaredVV(playerPos, p.position)
                if (dist < minDist) {
                    minDist = dist
                    minSpecialPickup = p
                }
            }
        }

        if (minSpecialPickup) {
            this.specialArrow.updateDestination(minSpecialPickup.position.x, minSpecialPickup.position.y)
            this.specialArrow.setIsShowing(true)
        } else {
            this.specialArrow.setIsShowing(false)
        }
    }

    private sortPickup(a: GroundPickup, b: GroundPickup) {
        return b.nearbyXPPickups.length - a.nearbyXPPickups.length
    }

    private activateWealthyEnemyMark() {
        const enemyList = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, WEALTHY_ENEMY_SEARCH_RANGE, CollisionLayerBits.HitEnemyOnly)

        const enemiesWantMark = Math.round((2 + ((InGameTime.timeElapsedInSeconds/60) / 3)) * this.wealthyEnemyMarkCountScalar)
        const enemiesCanMark = Math.min(enemyList.length, enemiesWantMark)

        let markedEnemies = 0
        let attempts = 0
        const maxAttempts = enemiesCanMark * 2

        while (markedEnemies < enemiesCanMark && attempts < maxAttempts) {
            attempts++

            const enemy = enemyList.pickRandom().owner as Enemy
            if (!enemy.isBoss && !enemy.isSpecial && !enemy.isWealthy) {
                Buff.apply(BuffIdentifier.WealthyEnemy, this, enemy)
                markedEnemies++
            }
        }
    }

    private makePetPools() {
        this.petPools = new Map()

        this.petOptions.forEach(v => {
            const objectPool = new ObjectPoolTyped<PooledPet, PooledPetParams>(() => {
                return new PooledPet(v)
            }, {}, 2, 1, `pool-${v}`)

            this.petPools.set(v, objectPool)
        })
    }

    private getRandomPet(params: PooledPetParams) {
        const petName = this.petOptions.pickRandom()
        const pool = this.petPools.get(petName)
        const pet = pool.alloc(params)
        pet.pool = pool
        return pet
    }

    private makeSupplyPool() {
        if (!RadarSupplyDrop.pool) {
            RadarSupplyDrop.pool = new ObjectPoolTyped<RadarSupplyDrop, RadarSupplyDropParams>(() => {
                return new RadarSupplyDrop()
            }, {}, 1, 1)
        }
    }

    onEnemyKilled(enemy: Enemy) {}
    onShot() {}
    onEnemyHit(enemy: Enemy, damageDealt: number) {}
}