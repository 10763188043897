<template>
	<div id="social-links">
		<a href="https://discord.gg/xbXhxe7Dxs" target="_blank" class="social-link"><img src="../../../assets/ui/social-media-icons/icon-discord.png" alt="discord" /></a>
		<a href="https://twitter.com/diebraryRIP" target="_blank" class="social-link"><img src="../../../assets/ui/social-media-icons/icon-twitter.png" alt="twitter" /></a>
		<a href="https://www.tiktok.com/@diebrary" target="_blank" class="social-link"><img src="../../../assets/ui/social-media-icons/icon-tiktok.png" alt="twitter" /></a>
		<a href="https://www.youtube.com/@OtherOceanInteractive" target="_blank" class="social-link"><img src="../../../assets/ui/social-media-icons/icon-youtube.png" alt="facebook" /></a>
		<a href="https://www.facebook.com/OtherOceanInteractive/" target="_blank" class="social-link"><img src="../../../assets/ui/social-media-icons/icon-facebook.png" alt="facebook" /></a>
		<div v-on:click="openTermsOfUse" class="text-link" :lang="this.$i18n.locale">{{ $t('links.terms_of_use') }}</div>
		<div v-on:click="openPrivacyPolicy" class="text-link" :lang="this.$i18n.locale">{{ $t('links.privacy_policy') }}</div>
		<div v-if="webPortalConfig.enableCookiePolicy" v-on:click="openCookiePolicy" class="text-link" :lang="this.$i18n.locale">{{ $t('links.cookie_policy') }}</div>
		<div v-on:click="openCredits" class="text-link" :lang="this.$i18n.locale">{{ $t('links.credits') }}</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { Views } from '../store/ui-store'
export default {
	name: 'SocialLinks',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapState('ui', ['webPortalConfig']),
	},
	methods: {
		...mapActions('ui', ['changeActiveView']),
		openTermsOfUse() {
			this.changeActiveView(Views.TERMS_OF_USE)
		},
		openPrivacyPolicy() {
			this.changeActiveView(Views.PRIVACY_POLICY)
		},
		openCookiePolicy() {
			this.changeActiveView(Views.COOKIE_POLICY)
		},
		openCredits() {
			this.changeActiveView(Views.CREDITS)
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#social-links {
	display: flex;
	align-items: center;
	.text-link {
		padding: 4px;
		margin: 3px;
		color: @green;
		text-decoration: underline;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 24px;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 24px;
		}
		&:hover {
			color: @offWhite;
		}
	}

	.social-link {
		padding: 4px;
		margin: 3px;

		&:hover {
			filter: brightness(20);
		}
	}
}
</style>
