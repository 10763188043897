import EntityStatList from "../../stats/entity-stat-list"

export enum ElementalPoolType {
    Ice,
    Lava,
    Poison
}

export const ELEMENTAL_POOL_RADIUS_X = 200
export const ELEMENTAL_POOL_RADIUS_Y = 80
export const ELEMENTAL_POOL_RADIUS_RATIO = ELEMENTAL_POOL_RADIUS_Y / ELEMENTAL_POOL_RADIUS_X // assumes Y is smaller than X (reverse if this changes and change alloc in prop-placement)
export const ELEMENTAL_POOL_TRIGGER_RADIUS = Math.max(ELEMENTAL_POOL_RADIUS_X, ELEMENTAL_POOL_RADIUS_Y) // doesn't really matter atm

export const POISON_WEAKENED_DURATION = 4_000
export const LAVA_POOL_PERCENT_HEALTH_DAMAGE = 0.1 // twice per second
export const LAVA_POOL_MINIMUM_DAMAGE = 1

export function resetLavaPoolHazardStatListStats(statList: EntityStatList) {
    statList._actualStatValues.baseDamage = 1
    statList._actualStatValues.attackRate = 2

    statList._actualStatValues.allDamageMult = 1
}

export function resetPoisonPoolHazardStatListStats(statList: EntityStatList) {
    statList._actualStatValues.baseDamage = 1
    statList._actualStatValues.attackRate = 2

    statList._actualStatValues.allDamageMult = 1
}
