import { GameState } from "../../engine/game-state"
import { EnemyPackStage } from "../../entities/enemies/enemy-equilibrium-spawner"
import { GroundPickupType } from "../../entities/pickups/ground-pickup-types"

const initialState = {
	playerDPS: 0,
	enemiesInWorld: 0,
	enemiesSpawnedLifetime: 0,
	enemiesInWorldHealth: 0,
	pickupsInWorld: 0,
	expInWorld: 0,
	levelVsExpectationPercent: 'soon™',
	enemySpawnerStages: [],
	stragglerEnemies: [],
}

const store = {
	namespaced: true,
	modules: {
	},
	state: initialState,
	getters: {
	},
	mutations: {
		updatePlayerDPS(state: State, val: number) {
			state.playerDPS = val
		},
		updateEnemiesInWorld(state: State, val: number) {
			state.enemiesInWorld = val
			state.enemiesInWorldHealth = GameState.enemyList.reduce((prev, enemy) => {
				return prev + (enemy as unknown as any).currentHealth
			}, 0)
		},
		updateEnemiesSpawnedLifetime(state: State, val: number) {
			state.enemiesSpawnedLifetime = val
		},
		updatePickupsInWorld(state: State, val: number) {
			state.pickupsInWorld = val
			state.expInWorld = GameState.pickupList.reduce((prev, pickup) => {
				if (pickup.pickupType === GroundPickupType.Experience) {
					return prev + 1 //TODO: use exp amount inside pickupConfig
				}
				return prev
			}, 0)
		},
		updateSpawnerStages(state: State, stages: EnemyPackStage[]) {
			state.enemySpawnerStages = stages
		},
		updateStragglerEnemies(state: State, stragglers: Array<{ count: number, enemyName: string }>) {
			state.stragglerEnemies = stragglers
		},
	},
	actions: {
	}
}

type State = typeof initialState
export type DebugStore = typeof store

export const debugStore = () => {
	return store
}
