<template>
	<div class="book-container">
		<div class="books">
			<div v-for="(book, index) in items" :key="index" class="book-wrapper">
				<div v-if="showProgressbar" class="progress-bar">
					<ProgressBarWrapper :class="'extra-small green'" :value="book.count" :max-value="progressBarMaxVotes" :subtitle="''" :title="''"></ProgressBarWrapper>
				</div>
				<ItemUpdated :item="book" :selected="false" :enable-glow="true" :on-click-prop="() => {}" :disable-circle="true" :small-icons="true" :component="'twist'" :tooltip-enabled="true"></ItemUpdated>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ItemUpdated from '../item/items-updated.vue'
import ProgressBarWrapper from '../bars-sliders/progress-bar-wrapper.vue'

export default {
	name: 'BookContainer',
	components: {
		ItemUpdated,
		ProgressBarWrapper,
	},
	data() {
		return {}
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
		votingVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		disableBooks: {
			type: Boolean,
			required: false,
			default: false,
		},
		isWinner: {
			type: Boolean,
			required: false,
			default: false,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-click handler for the <BookContainer /> component has been called. Did you forget to pass an Fn to this component?')
			},
		},
		progressBarMaxVotes: {
			type: Number,
			required: false,
			default: 10,
		},
		selectedFn: {
			type: Function,
			required: false,
		},
		progressbarValue: {
			type: Function,
			required: false,
		},
	},
	computed: {
		showProgressbar() {
			if (this.votingVisible && !this.disableBooks) {
				return true
			}
			return false
		},
	},
	methods: {},
}
</script>

<style lang="less" scoped>
.book-container {
	display: flex;
	justify-content: center;
	margin-top: 8px;
	.books {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 460px;
		.book-wrapper {
			height: 115px;
			margin: 8px 5px;
			:deep(.item-container) {
				left: 3px;
			}
			.progress-bar {
				margin: 2px 5px;
			}
		}
	}
}
</style>
