import { Texture, Container, BaseTexture } from 'pixi.js'

export interface ParticleRendererConfig {
	texture: BaseTexture
	maxParticles: number
	blendMode: PIXI.BLEND_MODES
}

export interface SpriteParticleRenderable {
	pos: number[]
	rot: number
	scale: number[]
	color: number[]
	uvExtents: number[]
}

export interface IParticleRendererCamera {
	x: number
	y: number
	zoom: number
	halfWidth: number
	halfHeight: number
}

export class SpriteParticleRenderer {
	x: number = 0
	y: number = 0
	visible: boolean = true

	private cfg: ParticleRendererConfig
	private ready = false
	private parent: Container
	private sprites: PIXI.Sprite[]

	constructor(cfg: ParticleRendererConfig) {
		this.cfg = cfg
		this._init()
	}

	renderParticles(particles: SpriteParticleRenderable[], numParticles: number) {
		const isVisible = this.visible
		for (let i = 0; i < numParticles; ++i) {
			const p = particles[i]
			const sprite = this.sprites[i]
			sprite.position.set(p.pos[0], p.pos[1])
			sprite.scale.set(p.scale[0] / sprite.texture.width, p.scale[1] / sprite.texture.height)
			sprite.rotation = p.rot
			sprite.tint = PIXI.utils.rgb2hex(p.color)
			sprite.alpha = p.color[3]
			sprite.visible = isVisible

			const spriteAny: any = sprite
			const uvs = spriteAny.uvs ? spriteAny.uvs : spriteAny.texture._uvs.uvsFloat32
			const uvExtents = p.uvExtents

			uvs[0] = uvExtents[0]
			uvs[1] = uvExtents[1]
			uvs[2] = uvExtents[0] + uvExtents[2]
			uvs[3] = uvExtents[1]
			uvs[4] = uvExtents[0] + uvExtents[2]
			uvs[5] = uvExtents[1] + uvExtents[3]
			uvs[6] = uvExtents[0]
			uvs[7] = uvExtents[1] + uvExtents[3]
		}
		const maxParticles = this.cfg.maxParticles
		for (let i = numParticles; i < maxParticles; ++i) {
			const sprite = this.sprites[i]
			sprite.visible = false
		}
	}

	addToContainer(container: Container) {
		if (this.ready) {
			for (const s of this.sprites) {
				container.addChild(s)
			}
		}
		this.parent = container
	}

	removeFromContainer(container: Container) {
		for (const s of this.sprites) {
			container.removeChild(s)
		}
		this.parent = null
	}

	getBlendMode() {
		return this.cfg.blendMode
	}

	private _init() {
		const texture = this.cfg.texture
		const maxParticles = this.cfg.maxParticles

		this.sprites = []
		this.sprites.length = maxParticles

		for (let i = 0; i < maxParticles; ++i) {
			const sprite = new PIXI.Sprite(new Texture(texture))
			sprite.anchor.set(0.5, 0.5)
			sprite.visible = false
			sprite.blendMode = this.cfg.blendMode
			this.sprites[i] = sprite
		}

		if (this.parent) {
			for (const s of this.sprites) {
				this.parent.addChild(s)
			}
		}

		this.ready = true
	}
}
