import { percentage, timeInSeconds } from "../../utils/primitive-types";
import { MapConfig, MapNames, mapNames } from "../../world-generation/world-data";
import { EnemyPackStageConfig } from "./enemy-equilibrium-spawner";
import { ENEMY_NAME } from "./enemy-names";
import { FOREST_ACT_1_STAGES, FOREST_ACT_2_STAGES, FOREST_ACT_3_STAGES } from "./forest-act-spawn-config";
import { TUNDRA_ACT_1_STAGES, TUNDRA_ACT_2_STAGES, TUNDRA_ACT_3_STAGES } from "./tundra-act-spawn-config";

const LOGGING = true

export const ENEMY_SPAWN_DISTANCE = 2_500
export const AGGRESSIVE_ENEMY_SPAWN_DISTANCE = 1_900
export const STANDARD_ENEMY_SIZE = 50 // used to place spawned enemies apart 
export const ENEMY_GROUP_RAD_OFFSET = 1.0472 // each enemy group spawned will be at least <this var> radians apart
export const ENEMY_SPAWNER_MIN_DIST_TO_PLAYER_2 = 2_000 ** 2

export const ENEMY_RECYCLE_TIME = 0//1.5

export const ENEMY_RECYCLE_SPAWN_DISTANCE = 3_500
export const ENEMY_RECYCLE_DISTANCE_FROM_PLAYER = 3_650 ** 2

export const AGGRESSIVE_RECYCLE_SPAWN_DISTANCE = 1_900
export const AGGRESSIVE_RECYCLE_DISTANCE_FROM_PLAYER = 2_075 ** 2
export const AGGRESSIVE_RECYCLE_CULL_TIME: timeInSeconds = 0.5
export const RENDERABLE_ENEMY_CULL_DISTANCE = 2100 ** 2

export interface EnemySpawnValues {
	allEnemySpawnRate?: percentage
	act1EnemySpawnRate?: percentage
	act2EnemySpawnRate?: percentage
	act3EnemySpawnRate?: percentage

	allEnemySpawnMax?: percentage
	act1EnemySpawnMax?: percentage
	act2EnemySpawnMax?: percentage
	act3EnemySpawnMax?: percentage
}

export enum Directions {
	None = 0,
	North = 1 << 0,
	East = 1 << 1,
	South = 1 << 2,
	West = 1 << 3,
	NorthEast = 1 << 4,
	NorthWest = 1 << 5,
	SouthEast = 1 << 6,
	SouthWest = 1 << 7,
}

export const AllDirections = [Directions.North, Directions.East, Directions.South, Directions.West, Directions.NorthEast, Directions.NorthWest, Directions.SouthEast, Directions.SouthWest]




type EnemySpawnConfig = Record<MapNames, { ENEMY_SPAWN_TIME_STAGES: EnemyPackStageConfig[] }>;

export const ENEMY_SPAWN_CONFIG: EnemySpawnConfig = {
	[mapNames.FOREST]: {
		ENEMY_SPAWN_TIME_STAGES:[
			...FOREST_ACT_1_STAGES,
			// Boss
			...FOREST_ACT_2_STAGES,
			// Boss
			...FOREST_ACT_3_STAGES,
		]
	}, 
	[mapNames.TUNDRA]: {
		ENEMY_SPAWN_TIME_STAGES:[
		...TUNDRA_ACT_1_STAGES, 
		// Boss
		...TUNDRA_ACT_2_STAGES, 
		// Boss
		...TUNDRA_ACT_3_STAGES
		]
	},
	[mapNames.FUNGI]: {
		ENEMY_SPAWN_TIME_STAGES:[
			...FOREST_ACT_1_STAGES,
			// Boss
			...FOREST_ACT_2_STAGES,
			// Boss
			...FOREST_ACT_3_STAGES,
		]
	}, 
	[mapNames.HOLLOW]: {
		ENEMY_SPAWN_TIME_STAGES:[
			...FOREST_ACT_1_STAGES,
			// Boss
			...FOREST_ACT_2_STAGES,
			// Boss
			...FOREST_ACT_3_STAGES,
		]
	}, 
}


export function getEnemyTargetHealth(currentTimeInSeconds: timeInSeconds) {
	// act 1
	if (currentTimeInSeconds < 60) {
		return 10
	} else if (currentTimeInSeconds < 150) {
		return 12
	} else if (currentTimeInSeconds < 325) {
		return 34
	} else if (currentTimeInSeconds < 390) {
		return 51
	} else if (currentTimeInSeconds < 450) {
		return 64
	} else if (currentTimeInSeconds < 510) {
		return 81
	} else if (currentTimeInSeconds < 625) {
		return 168
	} else  if (currentTimeInSeconds < 750 ) {
		return 418
	} else if (currentTimeInSeconds < 870) {
		return 800
	} else {
		return 1400
	}
}
