<template>
	<button class="icon-button" :class="{ [buttonStyle]: true && disabled === false, disabled: disabled, hovered: hovered }" @click="onClick()">
		<div v-if="loading">
			<div :class="{ loading: loading }" class="icon"></div>
		</div>
		<div v-else>
			<div :class="{ [iconName]: true, disabled: disabled }" class="icon"></div>
		</div>
	</button>
</template>

<script lang="ts">
export const validIconNames = ['stash', 'furnace', 'market', 'pit-of-chance', 'delete', 'loading', 'boat', 'key', 'augment', 'timer', 'page', 'add', 'worm', 'login', 'logout', 'party', 'no', 'worm', 'yes', 'copy', 'refresh', 'back', 'forward', 'createaccount', 'hub', 'invite', 'coin', 'scale', 'gift', 'faction-standing', 'leave-faction', 'join-faction', 'wave', 'lock', 'chat'] 
import { VALID_BUTTON_STYLES } from './button-constants'

export default {
	name: 'IconButton',
	props: {
		iconName: {
			type: String,
			required: true,
			// validator: function(value: string) {
			// 	return validIconNames.includes(value)
			// },
		},
		buttonStyle: {
			type: String,
			default: 'default',
			// validator: function(value: string) {
			// 	return VALID_BUTTON_STYLES.includes(value)
			// },
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		hovered: {
			type: Boolean,
			default: false,
			required: false,
		},
		onClickProp: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-click handler for the <IconButton /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		loading: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	methods: {
		onClick() {
			if (this.disabled) {
				return
			} else {
				this.onClickProp()
			}
		},
	},
}
</script>

<style lang="less" scoped>
.icon-button {
	width: 132px;
	height: 87px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.disabled {
		cursor: default;
		border-image: url('~/assets/ui/buttons/button-disable.png') 26 fill / 26px !important;
	}

	&:active {
		.icon:not(.disabled) {
			transform: scale(0.9);
		}
	}


	&.bright-green {
		border-image: url('~/assets/ui/buttons/bright-green-button.png') 26 fill / 26px;

		&:hover,
		&.hovered {
			border-image: url('~/assets/ui/buttons/bright-green-button-hover.png') 26 fill / 26px;
		}

		&:active {
			border-image: url('~/assets/ui/buttons/bright-green-button-active.png') 26 fill / 26px;
		}
	}

	&.dark-blue {
		border-image: url('~/assets/ui/buttons/basic-button-dark.png') 26 fill / 26px;
		opacity: 0.5;
		&:hover,
		&.hovered {
			border-image: url('~/assets/ui/buttons/basic-button-dark-hover.png') 26 fill / 26px;
		}

		&:active {
			border-image: url('~/assets/ui/buttons/basic-button-dark-active.png') 26 fill / 26px;
		}
	}
	&.green {
		border-image: url('~/assets/ui/buttons/basic-button-alt.png') 26 fill / 26px;
		opacity: 0.5;
		&:hover,
		&.hovered {
			border-image: url('~/assets/ui/buttons/basic-button-alt-hover.png') 26 fill / 26px;
		}

		&:active {
			border-image: url('~/assets/ui/buttons/basic-button-alt-active.png') 26 fill / 26px;
		}
	}


	.icon {
		background-size: contain;
		width: 77px;
		height: 51px;

		&.disabled {
			opacity: 0.3;
		}

		&.stash {
			background-image: url('~/assets/ui/buttons/stash-icon.png');
		}

		&.furnace {
			background-image: url('~/assets/ui/buttons/furnace-icon.png');
		}

		&.market {
			background-image: url('~/assets/ui/buttons/market-icon.png');
		}
		&.pit-of-chance {
			background-image: url('~/assets/ui/buttons/pit-of-chances-icon.png');
		}
		&.delete {
			background-image: url('~/assets/ui/buttons/delete-icon.png');
		}

		&.key {
			background-image: url('~/assets/ui/buttons/key-icon.png');
		}

		&.boat {
			background-image: url('~/assets/ui/buttons/depart-icon.png');
		}
		&.back {
			background-image: url('~/assets/ui/buttons/back-icon.png');
		}
		&.createaccount {
			background-image: url('~/assets/ui/buttons/create-account-icon.png');
		}
		&.add {
			background-image: url('~/assets/ui/buttons/add-icon.png');
		}
		&.adventure {
			background-image: url('~/assets/ui/buttons/adventure-icon.png');
		}
		&.augment {
			background-image: url('~/assets/ui/buttons/augment-icon.png');
		}
		&.forward {
			background-image: url('~/assets/ui/buttons/forward-icon.png');
		}
		&.hub {
			background-image: url('~/assets/ui/buttons/hub-icon.png');
		}
		&.logout {
			background-image: url('~/assets/ui/buttons/login-icon.png');
			transform: scale(-1, 1);
		}
		&.login {
			background-image: url('~/assets/ui/buttons/login-icon.png');
		}
		&.page {
			background-image: url('~/assets/ui/buttons/mylistings-icon.png');
		}
		&.no {
			background-image: url('~/assets/ui/buttons/no-icon.png');
		}
		&.refresh {
			background-image: url('~/assets/ui/buttons/refresh-icon.png');
		}
		&.worm {
			background-image: url('~/assets/ui/buttons/worm-icon.png');
		}
		&.yes {
			background-image: url('~/assets/ui/buttons/yes-button.png');
		}
		&.party {
			background-image: url('~/assets/ui/buttons/create-party-icon.png');
		}
		&.copy {
			background-image: url('~/assets/ui/buttons/copy-icon.png');
		}
		&.sale {
			background-image: url('~/assets/ui/buttons/login-icon.png');
		}
		&.invite {
			background-image: url('~/assets/ui/buttons/send-invite-button.png');
			width: 71px;
		}
		&.coin {
			background-image: url('~/assets/ui/buttons/coin-button.png');
			background-repeat: no-repeat;
			background-position-x: 10px;
		}
		&.gift {
			background-image: url('~/assets/ui/buttons/free-listing-button.png');
		}
		&.faction-standing {
			background-image: url('~/assets/ui/buttons/faction-standings-button.png');
		}
		&.leave-faction {
			background-image: url('~/assets/ui/buttons/leave-faction-button.png');
		}
		&.join-faction {
			background-image: url('~/assets/ui/buttons/join-faction-button.png');
		}
		&.wave {
			background-image: url('~/assets/ui/buttons/leave-faction-button.png');
		}
		&.lock {
			background-image: url('~/assets/ui/buttons/lock-button.png');
		}
		&.play {
			background-image: url('~/assets/ui/buttons/play-icon.png');
			background-repeat: no-repeat;
			background-position: center;
		}
		&.pause {
			background-image: url('~/assets/ui/buttons/pause-icon.png');
			background-repeat: no-repeat;
			background-position: center;
		}
		&.timer {
			filter: contrast(0) brightness(2);
			background-image: url('~/assets/ui/buttons/timer-icon.png');
			width: 32px;
			height: 32px;
		}

		&.loading {
			background-image: url('~/assets/ui/buttons/loading-spinner.gif');
			width: 51px;
			height: 51px;
		}
		&.chat {
			background-image: url('~/assets/ui/buttons/chat-icon.png');
			background-repeat: no-repeat;
			background-position: center;
		}

		
	}
}
</style>
